import React from 'react';
import { compose } from 'recompose';
import classnames from 'classnames';
import propTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import Paragraph from '../../../components/library/paragraph';
import ViaDate from '../../../components/library/viaDate';
import ViaIcon from '../ViaIcon';
import sItineraryToggle from './itineraryToggle.scss';

export const enhance = compose(withStyles(sItineraryToggle));

function ItineraryToggle(props) {
  const buttonClass = classnames(
    sItineraryToggle['itinerary-toggle'],
    props.isActive ? sItineraryToggle['active'] : '',
  );
  const iconClass = classnames(sItineraryToggle['item-icon'], sItineraryToggle[props.display]);

  return (
    <button onClick={props.onClick} className={buttonClass}>
      <div className={sItineraryToggle.content}>
        <div className={sItineraryToggle['item-status']}>
          {props.isActive ? (
            <span className={sItineraryToggle.added}>{props.type === 'add' ? 'ADDED' : 'REMOVED'}</span>
          ) : (
            <span className={sItineraryToggle.add}>{props.type === 'add' ? 'ADD' : 'REMOVE'}</span>
          )}
        </div>
        <div className={iconClass}>
          <ViaIcon
            color="darkText"
            transportationId={props.detailType === 'transportation' ? props.icon : -1}
            activityId={props.detailType === 'activity' ? props.icon : -1}
            housingId={props.detailType === 'housing' ? props.icon : -1}
          />
        </div>
        <div className={sItineraryToggle['item-name']}>
          <Paragraph size="large">{props.iName}</Paragraph>
          <Paragraph color="gray">{props.iType}</Paragraph>
        </div>
        <div className={sItineraryToggle['item-location']}>
          <Paragraph>
            <ViaDate date={props.iStart} /> - <ViaDate date={props.iEnd} />
          </Paragraph>
          <Paragraph>{props.iLocations}</Paragraph>
        </div>
      </div>
    </button>
  );
}

ItineraryToggle.propTypes = {
  icon: propTypes.number,
  display: propTypes.oneOf(['primary', 'black']),
  isActive: propTypes.bool,
  iName: propTypes.string,
  iType: propTypes.string,
  iStart: propTypes.string,
  iEnd: propTypes.string,
  iLocations: propTypes.string,
  type: propTypes.oneOf(['add', 'remove']).isRequired,
  detailType: propTypes.oneOf(['transportation', 'housing', 'activity']),
};

ItineraryToggle.defaultProps = {
  icon: '',
  display: 'black',
  isActive: false,
  iName: '',
  iType: '',
  iStart: '',
  iEnd: '',
  iLocations: '',
};

export default enhance(ItineraryToggle);
