import React from 'react';
import styled from 'styled-components';
import StyledIcon from './styles/Icon.styled';

const FormCardDiv = styled.div`
  background-color: ${({ theme }) => theme.formCard.backgroundColor};
  border: ${({ theme }) => theme.formCard.border};
  font-family: ${({ theme,fontFamily  }) => fontFamily ? fontFamily : theme.formCard.fontFamily };
  font-size: ${({ theme }) => theme.formCard.fontSize};
  min-height: ${({ theme }) => theme.formCard.minHeight};
`;

const HeaderDiv = styled.div`
  color: ${({ theme }) => theme.formCard.header.fontColor};
  background-color: ${({ theme }) => theme.formCard.header.backgroundColor};
  display: flex;
  font-family: ${({ theme, fontFamily  }) => ( fontFamily ? fontFamily : theme.formCard.fontFamily) };
  font-size: ${({ theme }) => theme.formCard.header.fontSize};
  font-weight: 500;
  height: ${({ theme }) => theme.formCard.header.height};
  justify-content: ${props => (props.addTitle ? 'space-between' : null)};
  padding: ${({ theme }) => theme.formCard.header.padding};
  align-self: center;
  span {
    align-self: center;
  }
  svg {
    align-self: center;
    display: inline-flex;
    margin-right: 10px;
    position: relative;
  }
`;

const AddContainer = styled.span`
  border-bottom: ${props => (props.isDisabled ? '1px solid #646464' : '1px solid #1c748a')};
  color: ${props => (props.isDisabled ? '#646464' : '#1c748a')};
  cursor: ${props => (props.isDisabled ? 'not-allowed' : 'pointer')};
  margin-right: 20px;

  svg {
    align-self: center;
    margin-right: 2px;
  }
`;

const ComponentDiv = styled.div`
  align-self: center;
  width: 30%;
`;

const TitleSpan = styled.span`
  text-transform: uppercase;
  width: 40%;
`;

const ContentDiv = styled.div`
  padding: ${({ theme }) => theme.formCard.content.padding};
`;

const FormCard = ({
  children,
  title,
  icon = null,
  iconSize = '20px',
  addTitle,
  onClick,
  headerComponent,
  isDisabled,
  fontFamily,
}) => (
  <FormCardDiv fontFamily={fontFamily}>
    <HeaderDiv addTitle={addTitle} fontFamily={fontFamily}>
      {icon && <StyledIcon type={icon} size={iconSize} />}
      <TitleSpan>{title.toUpperCase()}</TitleSpan>
      {headerComponent && <ComponentDiv>{headerComponent} </ComponentDiv>}

      {!!addTitle ? (
        <AddContainer isDisabled={isDisabled}>
          <span
            onClick={e => {
              isDisabled ? null : onClick(e);
            }}
            tabIndex={0}
            onKeyDown={e => {
              if (e.keyCode === 32 || e.keyCode === 13) {
                isDisabled ? null : onClick(e);
              }
            }}
          >
            <StyledIcon type={'Add'} size={'16px'} /> {addTitle}
          </span>
        </AddContainer>
      ) : null}
    </HeaderDiv>
    <ContentDiv>{children}</ContentDiv>
  </FormCardDiv>
);

export default FormCard;
