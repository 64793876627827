import PropTypes from 'prop-types';
import styled from 'styled-components';
import React, { useState } from 'react';
import _omit from 'lodash/omit';

import InputError from './InputError';
import LockIcon from '../../icons/LockIcon';
import SyncIcon from '../../icons/SyncIcon';
import OpenEyeIcon from '../../icons/OpenEyeIcon';
import ClosedEyeIcon from '../../icons/ClosedEyeIcon';
import InputErrorRequired from './InputErrorRequired';
import MagnifyingGlassIcon from '../../icons/MagnifyingGlassIcon';

import { formInputFocus, buttonFocus } from '../../../utils/shared/focusStyles';

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const InputLabel = styled.label`
  margin-bottom: 0.5rem;
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  color: #373737;
  line-height: 120%;
  letter-spacing: 0.015em;

  svg {
    margin-top: -2px;
    margin-left: 7px;
  }

  ${props => {
    if (props.hideLabel) {
      return `
        position: absolute;
        width: 0.0625rem;
        height: 0.0625rem;
        padding: 0;
        margin: -0.0625rem;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        white-space: nowrap;
        border-width: 0;
      `;
    }
  }};
`;

const InputLabelHelperText = styled.span`
  font-family: 'Nunito', sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  font-style: italic;
  color: #373737;
  line-height: 120%;
  letter-spacing: 0.015em;
`;

const InputWrapper = styled.div`
  position: relative;
`;

const Input = styled.input`
  box-sizing: border-box;
  display: block;
  width: 100%;
  padding: 0.875rem;
  background-color: #ffffff;
  border: 1px solid #bbbbbb;
  border-radius: 2px;
  font-family: 'Open Sans', sans-serif;
  font-size: 0.875rem;
  color: #373737;
  line-height: 120%;
  letter-spacing: 0.015em;

  &::placeholder {
    font-style: italic;
    color: #555555;
  }

  &:disabled {
    background-color: #eeeeee;
    color: #555555;
    cursor: not-allowed;
  }

  ${props => {
    if (props.hasError) {
      return `
        border-color: #8b1313;
      `;
    }
  }};

  ${props => {
    if (props.locked) {
      return `
        padding-right: 2.5rem;
      `;
    }
  }};

  ${formInputFocus};
`;

const IconContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  padding-right: 0.8rem;

  ${props => {
    if (props.forSearch) {
      return `
        color: var(--theme_color_dark);
      `;
    }
  }};
`;

const ObfuscateButton = styled.button`
  padding: 0;
  background: transparent;
  border: none;
  border-radius: 2px;

  ${buttonFocus};
`;

const Astrik = styled.span`
  color: #8b1313;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: 0.21px;
`;

export default function ProfileTextInput({
  id,
  type,
  error,
  label,
  value,
  locked,
  search,
  synced,
  required,
  className,
  hideLabel,
  placeholder,
  handleOnChange,
  onBlur,
  labelHelperText,
  showHiddenValue,
  showAstrik,
  ...props
}) {
  const [showingValue, setShowingValue] = useState(showHiddenValue);
  const title = synced ? 'Synced Field' : locked ? 'Locked Field' : null;
  const inputType = type === 'password' ? (showingValue ? 'text' : 'password') : type;

  return (
    <InputContainer className={className}>
      <InputLabel htmlFor={id} hideLabel={hideLabel}>
        {label} {showAstrik && <Astrik>*</Astrik>}{' '}
        {labelHelperText && <InputLabelHelperText>{labelHelperText}</InputLabelHelperText>}
        {required && error && <InputErrorRequired />}
        {synced && <SyncIcon />}
      </InputLabel>
      <InputWrapper>
        <Input
          id={id}
          value={value}
          type={inputType}
          name={id}
          title={title}
          placeholder={placeholder}
          onChange={handleOnChange}
          onBlur={onBlur}
          hasError={!!error}
          disabled={locked || synced}
          locked={locked}
          autoComplete="off"
          // required={required}
          aria-describedby={error ? `error-${id}` : ""}
          {..._omit(props, [
            'errorMessage',
            'labelText',
            'helpText',
            'isRequired',
            'labelSize',
            'searchBar',
            'searchClear',
            'hourGlassCustom',
            'subLabelText',
            'greenCheck',
            'checkInput',
          ])}
        />
        {locked && (
          <IconContainer forSearch={search}>
            <LockIcon />
          </IconContainer>
        )}
        {search && (
          <IconContainer>
            <MagnifyingGlassIcon />
          </IconContainer>
        )}
        {type === 'password' && (
          <IconContainer>
            <ObfuscateButton
              type="button"
              aria-label={`${showingValue ? 'Hide' : 'Show'} input value`}
              onClick={() => {
                setShowingValue(!showingValue);
              }}
            >
              {showingValue ? <ClosedEyeIcon /> : <OpenEyeIcon />}
            </ObfuscateButton>
          </IconContainer>
        )}
      </InputWrapper>
      {error && <InputError message={error} id={`error-${id}`} />}
    </InputContainer>
  );
}

ProfileTextInput.propTypes = {
  locked: PropTypes.bool,
  search: PropTypes.bool,
  synced: PropTypes.bool,
  type: PropTypes.string,
  error: PropTypes.string,
  required: PropTypes.bool,
  hideLabel: PropTypes.bool,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  handleOnChange: PropTypes.func,
  showHiddenValue: PropTypes.bool,
  id: PropTypes.string.isRequired,
  labelHelperText: PropTypes.string,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

ProfileTextInput.defaultProps = {
  error: '',
  type: 'text',
  className: '',
  locked: false,
  search: false,
  synced: false,
  required: false,
  placeholder: '',
  hideLabel: false,
  labelHelperText: '',
  showHiddenValue: false,
  handleOnChange: () => {},
};
