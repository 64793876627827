import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import { compose } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import history from '../../../../../../../history';
import sModal from 'react-responsive-modal/styles.css';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import StyledIcon from '../../../../../../../styledComponents/styles/Icon.styled';
import { AccentButtonMedium, ButtonMedium } from '../../../../../../../styledComponents/styles/Buttons.styled';
import { AccentButtonSpinner } from '../../../../../../../styledComponents/styles/spinner.styled';
import { deleteProgramModify } from '../../../../../../../actions/programModifyActions';
import { Paragraph } from '../../../../../../../styledComponents/styles/Paragraph.styled';
import {  TrashCanIcon } from '../../../../../../../styledComponents/styles/IconCustom.styled';
import TextInput from '../../../../../../../styledComponents/styles/TextInput.styled';

import Modal from 'react-responsive-modal';
import CloseIcon from '../../../../../../travelerProfile/components/icons/CloseIcon';

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 25px 25px;
  border-bottom: 5px solid #DDAF64;
`;

const Heading = styled.h2`
  color: #373737;
  text-align: center;

  /* Headline 5 */
  font-family: Nunito;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 30px */
`;
const CloseButton = styled.button`
  outline: none;
  border: none;
  padding: 0;
  background: none;
`;

const Content = styled.div`
  align-self: center;
  margin: 0;
  padding: 0;
  width: 75%;
  text-align: center;
  text-align: center;
  display:flex;
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Footer = styled.div`
  background: #ebebeb;
  display: flex;
  //height: 111px;
  justify-content: center;
  left: 0;
  top: 273px;
  width: 100%;
  flex-direction:column;
  padding-top:20px;
  padding-bottom:20px;
`;

const ButtonItem = styled.div`
  margin-top: auto;
  margin-bottom:auto;
`;

const TextInputParentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const TextInputContainer = styled.div`
  width: 300px;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 50px;
  justify-content: center;
  margin-top: 20px;
`;

const ErrorWrapper = styled.div`
  align-self: center;
  color: #a82425;
  font-family: AvenirNextRegular, sans-serif;
  font-size: 14px;

  svg {
    justify-content: center;
    margin-bottom: 2px;
    margin-right: 5px;
  }
`;

const IconWrapper = styled.div`
  padding-bottom: 25px;
  margin:auto;
`;
const ParagraphContainer = styled.div`
  margin-left:38px;
  padding-top:20px;
`;

const ParagraphWarning = styled(Paragraph)`
  color: #373737;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 22.4px */
  letter-spacing: 0.08px;
  text-align:left;
`;

function DeleteDraftModal(props) {
  const dispatch = useDispatch();
  const deletedProgram = useSelector(state => state.programModify.programDeleteDraft);
  const [deleteInput, setDeleteInput] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (deletedProgram && deletedProgram.loading) {
      setIsSaving(true);
    }

    if (deletedProgram && !deletedProgram.loading && (deletedProgram.error || deletedProgram.success)){
      if (deletedProgram.error) {
        setIsError(true);
        setIsSaving(false);
      }

      if (deletedProgram.success) {
        setTimeout(() => {
          history.push('/client/program-dashboard/internal');
          setIsSaving(false);
          props.onClose(false);
        }, 700);
      }

    }
  }, [deletedProgram]);
  
  const deleteProgram = () => {
    if (props.programId) {
      setIsSaving(true);
      setIsError(false);
      dispatch(deleteProgramModify(props.programId));
    }
  }

  return (
    <Modal
      blockScroll={false}
      open={props.show}
      onClose={() => props.onClose(false)}
      center
      closeOnEsc
      closeOnOverlayClick
      showCloseIcon={false}
      styles={{
        modal: {
          padding: '0px',
          borderRadius: '5px',
          width: '550px',
        },
      }}
    >
      <Header>
        <Heading>Confirm Deletion</Heading>
        <CloseButton onClick={()=>props.onClose(false)}>
          <CloseIcon fillColor={`#9FABAE`} />
        </CloseButton>
      </Header>
      <ModalContainer>
        <Content>
          <IconWrapper>
            <TrashCanIcon size={'100px'} />
          </IconWrapper>
          <ParagraphContainer>
            <ParagraphWarning>You are about to permanently delete this draft.</ParagraphWarning>
            <ParagraphWarning style={{fontWeight:"700"}}>Once deleted, {props.programName} cannot be recovered.</ParagraphWarning>
          </ParagraphContainer>
        </Content>
        <Footer>
        
          <TextInputParentContainer>
            <TextInputContainer>
              <TextInput
                id={'delete'}
                name={'delete'}
                label={'Type DELETE to remove this program.'}
                placeholder={'Type DELETE to confirm'}
                errorMsg={''}
                value={deleteInput}
                onChange={e => {
                  setDeleteInput(e.target.value);
                }}
              />
            </TextInputContainer>
          </TextInputParentContainer>
          <ButtonContainer>
            <ButtonItem>
              <ButtonMedium
                aria-label="Cancel"
                disabled={isSaving}
                onClick = {() => props.onClose(false)}
              >
                Cancel
              </ButtonMedium>
            </ButtonItem>
            <ButtonItem>
              <AccentButtonMedium
                aria-label={`Delete ${props.programAlias} Draft`}
                onClick={deleteProgram}
                saving={isSaving}
                disabled={isSaving || deleteInput !== 'DELETE'} 
              >
                {isSaving ? <AccentButtonSpinner displayText={'Delete'} /> : ' Delete '}
              </AccentButtonMedium>
            </ButtonItem>
          </ButtonContainer>
        </Footer>
        {isError && (
          <ErrorWrapper><StyledIcon type={'Warning'} size={'16px'} /> Error deleting {props.programAlias.toLowerCase()}</ErrorWrapper>
        )}
      </ModalContainer>
    </Modal>
  );
}

DeleteDraftModal.propTypes = {
  programAlias: propTypes.string.isRequired,
  programId: propTypes.string.isRequired,
  onClose: propTypes.func.isRequired,
  show: propTypes.bool.isRequired,
}

export default compose(withStyles(sModal))(DeleteDraftModal);