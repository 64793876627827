exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1cTCu~label{top:2px;font-size:12px;font-size:.75rem;color:#9b9b9b}._2O4x6{display:block;position:relative;padding-top:20px;background:#fff}._35aXq{font-family:AvenirNextRegular,sans-serif;position:absolute;top:20px;left:0;padding-left:20px;font-size:24px;font-size:1.5rem;color:hsla(0,0%,61%,.6);-webkit-transition:all .3s ease;-o-transition:all .3s ease;transition:all .3s ease;z-index:0;pointer-events:none;cursor:text}#_2HeCE{background-color:#fff!important;border:5px solid hsla(0,0%,61%,.4)!important}", ""]);

// exports
exports.locals = {
	"fieldAdded": "_1cTCu",
	"inputWrapper": "_2O4x6",
	"label": "_35aXq",
	"eligibilityDropdown": "_2HeCE"
};