import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import filterDateRangeOptions from '../utils/options/filterDateRangeOptions';

export default function useDatesOptions() {
  const { selectedFilters } = useSelector(state => state.programDashboard);

  const [endDate, setEndDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDateInclusive, setEndDateInclusive] = useState(null);
  const [startDateInclusive, setStartDateInclusive] = useState(null);
  const [endRange, setEndRange] = useState(filterDateRangeOptions[0]);
  const [startRange, setStartRange] = useState(filterDateRangeOptions[0]);

  useEffect(() => {
    if (selectedFilters.startDate.date) {
      setStartDate(selectedFilters.startDate.date);
    }

    if (selectedFilters.startDate.range) {
      setStartRange(selectedFilters.startDate.range);
    }

    if (selectedFilters.startDate.dateInclusive) {
      setStartDateInclusive(selectedFilters.startDate.dateInclusive);
    }

    if (selectedFilters.endDate.date) {
      setEndDate(selectedFilters.endDate.date);
    }

    if (selectedFilters.endDate.range) {
      setEndRange(selectedFilters.endDate.range);
    }

    if (selectedFilters.endDate.dateInclusive) {
      setEndDateInclusive(selectedFilters.endDate.dateInclusive);
    }
  }, []);

  const resetDateValues = () => {
    setEndDate(null);
    setStartDate(null);
    setEndDateInclusive(null);
    setStartDateInclusive(null);
    setEndRange(filterDateRangeOptions[0]);
    setStartRange(filterDateRangeOptions[0]);
  };

  return [
    endDate,
    setEndDate,
    startDate,
    setStartDate,
    endDateInclusive,
    setEndDateInclusive,
    startDateInclusive,
    setStartDateInclusive,
    endRange,
    setEndRange,
    startRange,
    setStartRange,
    resetDateValues,
  ];
}
