const AmenityCategories = [
  {
    title: 'Accommodations',
    icon: 'accommodations',
    all: ['Excursions', 'Housing', 'Laundry', 'Meals', 'Meals on excursions', 'Some meals', 'Meals on activities'],
    included: [],
    excluded: [],
  },
  {
    title: 'Transportation',
    icon: 'transportation',
    all: [
      'Excursion transportation',
      'Airfare',
      'Airport drop-off',
      'Airport pickup',
      'Local transportation',
      'Travel insurance',
      'Flight insurance',
    ],
    included: [],
    excluded: [],
  },
  {
    title: 'Fees/Permits',
    icon: 'fees',
    all: [
      'Refundable deposit',
      'Administrative fees',
      'Application fees',
      'Country visa or permits',
      'Passport application expenses',
      'Personal expenses',
      'International medical insurance',
      'Virus testing fees',
      'Program fees',
      'Per diem rate',
      'Scholarship or fellowship fees',
      'Refundable application fees',
      'Medical expenses',
    ],
    included: [],
    excluded: [],
  },
  {
    title: 'Academics',
    icon: 'academics',
    all: [
      'Pre-departure advising',
      'Books & supplies',
      'Custom internship placement',
      'Official transcript',
      'On-site orientation',
      'On-site staff support',
      'Tuition',
      'Re-entry advising',
    ],
    included: [],
    excluded: [],
  },
  {
    title: 'Miscellaneous',
    icon: 'miscellaneous',
    all: [
      'Internet access in housing',
      'Carbon offset',
      'Language training',
      'Tips for local guides',
      'Cell/mobile phone',
      'Internet access on site',
      'Passport application advising',
      'Visa application advising',
      'Miscellaneous reimbursement',
      'Scholarship opportunities',
      'Program proposal advising',
      'Staff medical supervision in the event of sickness',
    ],
    included: [],
    excluded: [],
  },
];

export default AmenityCategories;
