import React from 'react'; 
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import styles from './index.module.css'; 
import warningIcon from './warning-icon.png'; 
import { useSelector } from 'react-redux';

function WarningStrip() {

    const customAliases = useSelector(state => state.profile.customAliases); 

    return (<div className={styles.container}>
        <div className={styles.imgContainer}>
            <img src={warningIcon} className={styles.warningIconImg} />
        </div>

        <div>
            <div className={styles.heading}>The following plan(s) were not created.</div>
            <div className={styles.message}>To create travel plan(s), add valid location(s) to your {customAliases?.alias_program}(s) by clicking the links below.</div>
        </div>


    </div>)

}

export default withStyles(styles)(WarningStrip);
