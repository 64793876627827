import styled from 'styled-components';

export const Chip = styled.button.attrs({ type: 'button' })`
  background-color: ${(props) =>
  props.primary ? ({ theme }) =>
      theme.chip.backgroundColorPrimary :
        props.toggleOn ? ({ theme }) =>
          theme.chip.backgroundColorToggled : ({ theme }) => theme.chip.backgroundColor
  };
  border: 1px solid  ${({ theme }) => theme.chip.backgroundColor};
  border-radius: ${({ theme }) => theme.chip.borderRadius};
  color: ${({ theme }) => theme.chip.fontColor};
  display: inline-block;
  font-family: ${({ theme }) => theme.chip.fontFamily};
  font-size: ${({ theme }) => theme.chip.fontSize};
  line-height: ${({ theme }) => theme.chip.lineHeight};
  margin: 0 15px 21px 0;
  padding: ${({ theme }) => theme.chip.padding};
  text-align: center;

  svg {
    align-self: center;
    display: inline-flex;
    margin-right: 5px;
    position: relative;
  }

  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }

  &:active {
    opacity: 0.9;
  }

  &:disabled,
  &:disabled:active {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:focus {
    border: ${({ theme }) => theme.chip.focus.focusBorder};
    outline-color: ${({ theme }) => theme.chip.focus.outlineColor};
  }
`;
