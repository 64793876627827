import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import styles from "./travelerPlanDetail.scss"
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { compose } from 'recompose';
import grouplocation from '../../../shared/images/group-location.png';
import groupdate from '../../../shared/images/group-date.png';
import ChevronRightIcon from '../../../sites/travelerProfile/components/icons/ChevronRightIcon';
import EllipseIcon from '../../../sites/travelerProfile/components/icons/Ellipse';
import { defaultPlanLocationImage } from '../../../shared/helpers/plans';
import PlanDetailsBreadcrumbs from './PlanDetailsBreadcrumbs';
import { getTravelerPlan } from '../../../actions/plans';
import ViaDate from '../../../sites/components/library/viaDate';
export const enhance = compose(withStyles(styles));

const TravelerPlanDetail = (props) => {
  const dispatch = useDispatch();
  const planData = useSelector(state => state.plans.travelerPlan);
  const [plan, setPlan] = useState({});
  const [allLocs, setAllLocs] = useState('');
  const [img, setImg] = useState({ src: defaultPlanLocationImage(), alt: 'travel location' });
  
  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      const urlParams = new URLSearchParams(window.location.search);
      const planId = urlParams.get('plan_id');
      dispatch(getTravelerPlan(props.id,planId));
    }
    return () => {
      unmounted = true;
    };
  }, []);

  useEffect(()=>{
    let locsString = '';
    if (planData.data?.attributes?.locations && planData.data?.attributes?.locations.length > 0) {
      planData.data?.attributes?.locations.map(loc => {
        let locationStr = ""; 

        if(loc.locality && loc.country_common_name){
          locationStr = loc.locality + ', ' + loc.country_common_name; 
        }
        else{
          locationStr = loc.formatted_address; 
        }
        locsString = locsString + locationStr + '; ';
        //locsString = locsString + loc.locality + ', ' + loc.country_common_name + '; ';
      });
      locsString = locsString.slice(0, -2);

      setImg({
        src: planData.data?.attributes?.locations[0].image_url,
        alt: `Location image for ${planData.data?.attributes?.locations[0].locality}, ${planData.data?.attributes?.locations[0].country_common_name}`,
      });
    }
    setAllLocs(locsString);
    setPlan(planData.data);
  },[planData])

  return (
    <div>
      {/* {props.planID} */}
      <div className={styles['plan-container']}>
        <PlanDetailsBreadcrumbs onClick={props.onClick} planName = {plan?.attributes?.name} />
        <div className={styles['plan-title']}>{plan?.attributes?.name}</div>
        <div className={styles['plan-header']}>
          <img alt={`${img.alt}`} src={img.src} />
        </div>
        <div className={styles['plan-details']}>
          <div className={styles['infoContainer']}>
            <div className={styles['info']}>
              <img src={groupdate} alt="Plan Date" />
              <span className={styles['planDate']}>
                <ViaDate date={plan?.attributes?.start_date} /> - <ViaDate date={plan?.attributes?.end_date} />
              </span>
            </div>
            <div className={styles['info']}>
              <img src={grouplocation} alt="Location" />
              <span className={styles['planLocation']}>{allLocs}</span>
            </div>
          </div>
          <div>
            <button onClick={()=>{
              const url = `/plans/admin-plan-overview/${plan?.attributes?.id}`;
              window.open(url, '_blank'); 
            }} className={styles['view-plan-button']}><span>View Plan</span> <ChevronRightIcon/></button>            
          </div>
        </div>
        {plan?.attributes?.plan_forms.length>0 &&
          <div className={styles['plan-forms']}>
            <div className={styles['plan-forms-heading']}>Plan Forms</div>
            <table className={styles['form-table']}>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {plan?.attributes?.plan_forms?.map((planForm)=>(
                  <tr key={planForm.id}>
                  <td>
                    <div className={styles.planDetailContainer}>
                      <div className={styles.planFormContainer}>
                        <div className={styles['templateName']}>{planForm.form_name}</div>
                        <div className={styles['form-status']}>
                          {planForm?.status?.toLowerCase() === "not started" ? (
                            <EllipseIcon color="#CCCCCC" colorOuterCircle="#666666" />
                          ) : (planForm?.status?.toLowerCase() === "submitted") ? (
                              <EllipseIcon color="#DAFDD7" colorOuterCircle="#0E5B2F" />
                          ) : (planForm?.status?.toLowerCase() === "accepted") ? (
                            <EllipseIcon color="#0E5B2F" colorOuterCircle="#0E5B2F" />
                          ) : (
                              <EllipseIcon color="#DDAF64" colorOuterCircle="#DDAF64" />
                          )} 
                          <span>{planForm.status}</span>
                        </div>
                      </div>
                      <div className={styles['planDeadline']}>
                        {planForm?.deadline ?
                          <>
                          <span className={styles.label}>Due:</span>
                          <span className={styles.value}><ViaDate date={planForm?.deadline} /></span>
                          </>
                          :
                          <div className={styles.deadlineNoDate}>Not Provided</div>
                        }
                      </div>
                    </div>
                  </td>
                  <td>
                    <a className={styles['view-action']} href={`/client/form-review/${planForm?.id}`} target='_blank'>View</a>
                  </td>
                </tr>            
                ))}
                
              </tbody>
            </table>
          </div>
        }
      </div>
    </div>
  )
}

export default enhance(TravelerPlanDetail)
