import React from "react";
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import styles from "./index.module.css";
import { Table } from "react-bootstrap";
import confirmIcon from "./confirm-icon.png";
import WarningStrip from "./WarningStrip";
import { useSelector } from "react-redux";
import moment from 'moment-timezone';

function Confirm() {
  const { createPlanTerm } = useSelector((state) => state.plans);
  const customAliases = useSelector(state => state.profile.customAliases); 
  const profile = useSelector(state => state.profile);

  return (
    <div className={createPlanTerm?.failures?.length === 0 ? styles.center : ""}>
      <div className={styles.planCreatedContainer}>
        <img src={confirmIcon} />
        <div className={styles.planCreatedText}>{createPlanTerm.message}</div>
      </div>

      {createPlanTerm?.failures?.length > 0 && (
        <>
          <div className={styles.warningContainer}>
            <WarningStrip />
          </div>

          <Table striped bordered size="sm">
            <thead>
              <tr>
                <th className={styles.tableheader}>Term Name</th>
                <th className={styles.tableheader}>{customAliases?.alias_program} Name</th>
                <th className={styles.tableheader}>Term Start Date</th>

                <th className={styles.tableheader}>Term End Date</th>

                <th className={styles.tableheader}>Reason</th>
              </tr>
            </thead>
            <tbody>
              {createPlanTerm?.failures?.map((item, index) => (
                <tr
                  className={styles.tableRow}
                  key={`failure-program-${index}`}
                >
                  <td className={styles.divtext2}>{item.term_name}</td>
                  <td className={styles.divtext2}>
                    <a target="_blank" href={`/client/program_brochure/${item?.program_id}`} className={styles.programName}>
                      {item.program_name}
                    </a>
                  </td>
                  <td className={styles.divtext2}>{moment(item.term_start_date).tz(profile?.orgTimezone).format("MMM DD, YYYY")} </td>

                  <td className={styles.divtext2}>{moment(item.term_end_date).tz(profile?.orgTimezone).format("MMM DD, YYYY")} </td>

                  <td className={styles.divtext2}>{item.reason} </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}
    </div>
  );
}

export default withStyles(styles)(Confirm); 
