const parseURI = (str = '') => {
  const keys = [
    'source',
    'protocol',
    'authority',
    'userInfo',
    'user',
    'password',
    'host',
    'port',
    'relative',
    'path',
    'directory',
    'file',
    'query',
    'anchor'
  ]

  const parser = /^(?:(?![^:@]+:[^:@/]*@)([^:/?#.]+):)?(?:\/\/)?((?:(([^:@]*)(?::([^:@]*))?)?@)?([^:/?#]*)(?::(\d*))?)(((\/(?:[^?#](?![^?#/]*\.[^?#/.]+(?:[?#]|$)))*\/?)?([^?#/]*))(?:\?([^#]*))?(?:#(.*))?)/
  const matches = parser.exec(str)
  const uri = {}
  let i = 14

  while (i--) uri[keys[i]] = matches[i] || ''

  uri.queryKey = {}
  uri[keys[12]].replace(/(?:^|&)([^&=]*)=?([^&]*)/g, ($0, $1, $2) => {
    if ($1) uri.queryKey[$1] = $2
  })

  return uri
}

const getProductUrl = (product = "") => {

   // Get current URL from window object
   const currentUrl = window.location.href;

   // Regular expression to extract subdomain and domain
   const subdomainRegex = /\/\/([^/]+)\//;

   // Extract the subdomain and domain
   const match = currentUrl.match(subdomainRegex);
   const subdomain = match ? match[1] : null;

   // Split the domain into second-level domain and top-level domain
   const domainParts = subdomain ? subdomain.split('.') : [];
   const secondLevelDomain = domainParts.length >= 2 ? domainParts[0] : null;
   const topLevelDomain = domainParts.length >= 2 ? domainParts.slice(1).join('.') : null;

   let url = `https://${secondLevelDomain}.${topLevelDomain}`; 

   if(product){
    url = `https://${secondLevelDomain}.${product}.${topLevelDomain}`; 
   }

   return url; 

}

export { parseURI, getProductUrl }

