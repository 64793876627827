exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@-webkit-keyframes _1RQzH{to{-webkit-transform:rotate(1turn)}}@keyframes _1RQzH{to{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}._26XU3{width:100%}._26XU3 a{color:#043544;text-decoration:underline}", ""]);

// exports
exports.locals = {
	"ov-itinerary-by-type": "_26XU3",
	"spin": "_1RQzH"
};