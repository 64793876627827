import React from 'react';

class AlertsOverlay extends React.Component {
  render() {
    var { alerts, children, style } = this.props;

    var renderAlerts = function() {
      if (alerts.length > 0) {
        return alerts.map(function(alert) {
          return React.cloneElement(children, { alert: alert, key: alert.id });
        });
      } else {
        return null;
      }
    };

    return <div>{renderAlerts()}</div>;
  }
}

export default AlertsOverlay;
