import React, { useState } from 'react';
import { compose } from 'recompose';
import propTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import ViaModal from '../../../../components/library/viaModal';
import Headline from '../../../../components/library/headline';
import Button from '../../../../components/library/button';
import Paragraph from '../../../../components/library/paragraph';
import { getPlan, patchPlanUser } from '../../../../../actions/plans';
import sRejoinGroupPlan from './rejoinGroupPlan.scss';

export const enhance = compose(withStyles(sRejoinGroupPlan));

function RejoinGroupPlan(props) {
  const dispatch = useDispatch();
  const selectedPlan = useSelector(state => state.plans.plan);
  const [saving, setSaving] = useState(false);

  const rejoinGroup = () => {
    const submitData = {
      plans_user: { removed: false },
    };
    dispatch(
      patchPlanUser(
        selectedPlan.me.plans_user_id,
        submitData,
      ),
    );
    setTimeout(() => {
      setSaving(false);
      dispatch(getPlan(props.planId));
      props.onClose(false);
    }, 500);

  };

  return (
    <>
      <ViaModal showing={props.show} onClose={() => props.onClose(false)}>
        <div className={sRejoinGroupPlan['rejoin-group']}>
          <div className={sRejoinGroupPlan.subHeader}>
            <Headline centered tag="h1" as="h1">
              Rejoin the Group Plan
            </Headline>
          </div>
          <div className={sRejoinGroupPlan.content}>
            <Paragraph centered size="large">
              You are about to rejoin this Group Travel Plan, which will
              <br />
              notify Group Leaders and Administrators.
              <br />
              You will be able to reinstate any transportation, housing, and
              <br />
              activity details added to Your Plan.
            </Paragraph>
          </div>
          <div className={sRejoinGroupPlan.footer}>
            <div className={sRejoinGroupPlan['footer-confirm']}>
              <Headline tag="h2" as="h3" centered>
                WOULD YOU STILL LIKE TO REJOIN THIS PLAN?
              </Headline>
            </div>
            <div className={sRejoinGroupPlan['footer-button-wrapper']}>
              <div className={sRejoinGroupPlan['button-content']}>
                <Button
                  display="primary"
                  kind="solid"
                  size="medium"
                  type="submit"
                  onClick={() => {
                    rejoinGroup();
                    setSaving(true);
                  }}
                  loading={saving}
                  disabled={saving}
                >
                  Yes, Rejoin Plan
                </Button>
              </div>
              <div className={sRejoinGroupPlan['button-content']}>
                <Button
                  display="secondary"
                  kind="solid"
                  size="medium"
                  onClick={() => {
                    props.onClose(false);
                  }}
                >
                  No, Do Not Rejoin
                </Button>
              </div>
            </div>
          </div>
        </div>
      </ViaModal>
    </>
  );
}

RejoinGroupPlan.propTypes = {
  show: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
  planId: propTypes.string.isRequired,
};

export default enhance(RejoinGroupPlan);
