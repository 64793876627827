import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import ProfileFormGrid from '../../base/ProfileFormGrid.styled';
import ProfileTextInput from '../../base/inputs/ProfileTextInput';
import GridColumnPlaceholder from '../../base/GridColumnPlaceholder.styled';
import ProfilePhoneNumberInput from '../../base/inputs/ProfilePhoneNumberInput';

export default function PersonalContactInfoForm({ form, setForm }) {
  const { profile, info, readonly } = useSelector(state => state.travelerProfile);

  useEffect(() => {
    setForm({
      personal_email: info.personal_email,
      on_site_phone_number: info.on_site_phone_number,
      phone_number: info.country_code ? `${info.country_code}${info.phone_number}` : info.phone_number,
    });
  }, [info]);

  return (
    <ProfileFormGrid>
      <ProfileTextInput id="email" label="Email" value={profile.email} locked />
      <ProfileTextInput
        id="secondary_email"
        type="email"
        label="Secondary Email"
        placeholder="Enter secondary email"
        value={form.personal_email}
        synced={readonly.includes('personal_email')}
        handleOnChange={event => {
          setForm({ ...form, personal_email: event.target.value });
        }}
      />
      <GridColumnPlaceholder />

      {readonly.includes('phone_number') ? (
        <ProfileTextInput id="phone_number" label="Phone" value={form.phone_number} synced={true} />
      ) : (
        <ProfilePhoneNumberInput
          id="phone_number"
          label="Phone"
          value={form.phone_number}
          handleOnChange={event => {
            setForm({ ...form, phone_number: event });
          }}
        />
      )}

      <ProfilePhoneNumberInput
        id="mobile_phone_number"
        label="Mobile / On-site Phone"
        value={form.on_site_phone_number}
        handleOnChange={event => {
          setForm({ ...form, on_site_phone_number: event });
        }}
      />
      <ProfilePhoneNumberInput
        id="safe_check_phone_number"
        label="SafeCheck Phone"
        value={info.safe_check_phone_number || ''}
        locked
      />
    </ProfileFormGrid>
  );
}

PersonalContactInfoForm.propTypes = {
  form: PropTypes.shape({
    phone_number: PropTypes.string,
    personal_email: PropTypes.string,
    on_site_phone_number: PropTypes.string,
  }).isRequired,
  setForm: PropTypes.func.isRequired,
};
