import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import SvgFooterLandscape from '../../../components/svgs/svgFooterLandscape';

import { breakpoints } from '../../utils/breakpoints';
import { ACCENT_DEFAULT } from '../../../../shared/helpers/ViaDefaults';

const Footer = styled.div`
  margin-top: auto;

  ${props => {
    if (props.useBackgroundOpacity) {
      return `
        opacity: 0.7;
      `;
    }
  }};
`;

const Filler = styled.div`
  height: 2px;
  background-color: #043544;
`;

const SvgContainer = styled.div`
  height: 100px;
  margin-bottom: -2px;

  @media ${breakpoints.tablet} {
    height: inherit;
  }
`;

export default function TravelerFormFooter({ useBackgroundOpacity }) {
  const colorAccent = document.documentElement.style.getPropertyValue(`--theme_color_accent`) || ACCENT_DEFAULT;

  return (
    <Footer useBackgroundOpacity={useBackgroundOpacity}>
      <SvgContainer>
        <SvgFooterLandscape color={colorAccent} />
      </SvgContainer>
      <Filler />
    </Footer>
  );
}

TravelerFormFooter.propTypes = {
  useBackgroundOpacity: PropTypes.bool,
};

TravelerFormFooter.defaultProps = {
  useBackgroundOpacity: false,
};
