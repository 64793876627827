import clip from 'text-clipper';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';

import Map from './components/map';
import Apply from '../../header/Apply';
import Locations from './components/locations';
import Testimonials from './components/testimonials';
import ViewMoreInformation from './components/ViewMoreInformation';
import OrganizationalInformation from './components/organizationalInformation';

import { breakpoints } from '../../../utils/breakpoints';
import { buttonFocus } from '../../../utils/shared/focusStyles';
import { isAdminType, programType } from '../../../utils/types';

import {
  TabCard,
  TabHeading,
  TabCardPanel,
  TabLabelList,
  TabReadMoreLink,
  TabContentContainer,
  TabHeadingContainer,
  VideoContainer
} from '../TabContent.styled';
import EmbeddedVideoView from '../../../../../components/EmbeddedVideoView';

const OverviewList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  padding-left: 0;
  list-style-type: none;

  @media ${breakpoints.tablet} {
    gap: 1.6875rem;
  }

  p {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 120%;
    letter-spacing: 0.005em;
    color: #373737;
    margin-bottom: 0;
  }
`;

const OverflowButton = styled.button`
  border: none;
  margin-left: 0.5rem;
  background-color: transparent;
`;

const Content = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 120%;
  letter-spacing: 0.0025em;
  color: #373737;
  //white-space: pre-wrap;

  .intro {
    font-size: 1rem;

    p,
    li {
      font-weight: 600;
    }

    p {
      margin-top: 0.25rem;
      padding: 0;
      margin-bottom: 0;
    }

    ul {
      margin-bottom: 0;
      margin-top: 0px;
    }
  }

  .overview {
    ul {
      margin-bottom: 0;
      margin-top: 0rem;
    }

    p {
      margin-top: 0.25rem;
      padding: 0;
      margin-bottom: 0;
    }
  }

  a {
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 120%;
    letter-spacing: 0.015em;
    text-decoration-line: underline;
    color: #446372;
    border: 0.125rem solid transparent;

    @media ${breakpoints.tablet} {
      font-size: 1rem;
    }

    &:focus {
      outline: none;
    }

    &:focus-visible {
      border: 0.125rem solid #255a94;
      border-radius: 2px;
    }
  }
`;

const ProgramContact = styled.p`
  color: rgb(55, 55, 55);
  font-family: 'Open Sans', sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.005em;
  line-height: 120%;
  margin-bottom: 10px;
  margin-top: 10px;
`;

const ListLink = styled(Link)`
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 120%;
  letter-spacing: 0.015em;
  text-decoration-line: underline;
  color: #446372;
  margin-top: 0.3125rem;
  display: inline-block;
  border: 2px solid transparent;
  border-radius: 2px;

  ${buttonFocus};
`;

const Button = styled.button`
  cursor: pointer;
  textDecoration: underline;
  background: none;
  border: none;
  padding: 0;
  color: var(--Theme-Info, #255A94);
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  margin-top: 20px;
`;

export default function ProgramOverviewTab({ isAdmin }) {
  const {
    meta: { id: programId, intro, description, externalApplicationUrl, authorized },
    types,
    terms,
    showMap,
    program_brochure_section,
    program_info_video_url,
    contacts: { primary: contacts },
    academic: { subjects },
    location: { coordinates },
    managers,
  } = useSelector(state => state.programBrochure);

  //const programInfo = useSelector(state => state.programBrochure); 
  const managerList = managers?.filter((item)=>item.visibility === true);
  
  const programAlias = useSelector(state => state.auth.organization.info.alias_program);
  const primaryContacts = contacts.filter(contact => contact.program_contact);
  const [visibleSubjects, setVisibleSubjects] = useState('');
  const [showAllSubjects, setShowAllSubjects] = useState(false);
  const [overflowSubjectCount, setOverflowSubjectCount] = useState(0);
  const [overviewText, setOverviewText] = useState('');
  const [showReadMore, setShowReadMore] = useState(false);
  const [brochureDescription, setBrochureDescription] = useState('');

  const [showAll, setShowAll] = useState(false);
  const handleToggle = () => setShowAll(prevState => !prevState);
  const displayedContacts = showAll ? managerList : managerList?.slice(0, 3);
  const remainingCount = managerList?.length - 3;

  useEffect(() => {
    if (program_brochure_section?.description) {
      const updatedDescription = !!program_brochure_section?.description
        ? program_brochure_section?.description.replace(/<p><\/p>/g, '<br />')
        : '';
      setBrochureDescription(updatedDescription);
    } else {
      setBrochureDescription('');
    }
  }, [program_brochure_section]);

  const updateEditorContent = data => {
    const lines = data ? data.split('\n') : [];
    // Initialize a variable to store the modified data
    let updatedData = '';
    // Iterate through the lines
    for (let i = 0; i < lines.length; i++) {
      const line = lines[i];
      // Check if the line contains a closing </p> tag and no <br /> tag
      if (line.includes('</p>') && !line.includes('<br />')) {
        // Add <br /> tag after the closing </p> tag
        updatedData += line.replace('</p>', '</p><br />') + '\n';
      } else {
        // Otherwise, keep the line as it is
        updatedData += line + '\n';
      }
    }
    return updatedData;
  };

  let updatedIntro = intro ? intro.replace(/<p><\/p>/g, '<br />') : '';
  updatedIntro = updateEditorContent(updatedIntro);

  function showOverflowSubjects() {
    setShowAllSubjects(true);
    setVisibleSubjects(subjects.map(subject => subject.name).join('; '));
  }
  function handleReadMore() {
    setShowReadMore(false);
    let descriptionContent = description.replace(/<p><\/p>/g, '<br />');
    descriptionContent = updateEditorContent(descriptionContent);
    setOverviewText(descriptionContent);
  }

  useEffect(() => {
    const subjectLimit = 3;
    const subjectCount = subjects.length;

    if (subjectCount > subjectLimit) {
      setVisibleSubjects(
        subjects
          .slice(0, subjectLimit)
          .map(subject => subject.name)
          .join('; '),
      );
      setOverflowSubjectCount(subjectCount - subjectLimit);
    } else {
      setVisibleSubjects(subjects.map(subject => subject.name).join('; '));
    }
  }, [subjects]);

  useEffect(() => {
    if (description) {
      const clippedText = clip(description, 800, { html: true });
      let updatedClippedText = clippedText ? clippedText.replace(/<p><\/p>/g, '<br />') : '';
      updatedClippedText = updateEditorContent(updatedClippedText);
      setOverviewText(updatedClippedText);
      setShowReadMore(description.length > updatedClippedText.length);
    }
  }, [description]);

  return (
    <>
      <TabContentContainer>
        <TabHeadingContainer>
          <TabHeading level={2}>{programAlias} Overview</TabHeading>
          {/* && authorized removed this condition for ticket 4616 */}
          {externalApplicationUrl && <ViewMoreInformation url={externalApplicationUrl} />}
        </TabHeadingContainer>
        <TabCard tabIndex={0}>
          <TabCardPanel className="left">
            <Content>
              <div className="intro" dangerouslySetInnerHTML={{ __html: updatedIntro }} />
              <div className="overview" dangerouslySetInnerHTML={{ __html: overviewText }} />
              {showReadMore && <TabReadMoreLink onClick={handleReadMore}>Read more</TabReadMoreLink>}
            </Content>
          </TabCardPanel>
          <TabCardPanel className="right">
            <TabHeading level={3}>{programAlias} Highlights</TabHeading>
            <OverviewList>
              <li>
                <TabHeading level={4}>{programAlias} Type</TabHeading>
                <p>{types.join('; ')}</p>
              </li>
              <li>
                <TabHeading level={4}>{programAlias} Locations</TabHeading>
                <Locations />
              </li>
              <li>
                <TabHeading level={4}>Subject Areas</TabHeading>
                <p>
                  {visibleSubjects}
                  {overflowSubjectCount > 0 && !showAllSubjects && (
                    <OverflowButton onClick={showOverflowSubjects}>+ {overflowSubjectCount} more</OverflowButton>
                  )}
                </p>
              </li>
              <li>
                <TabHeading level={4}>Available Terms</TabHeading>
                <p>{terms.length === 1 ? '1 Term' : `${terms.length} Terms`}</p>
                {terms.length > 0 && (
                  <ListLink
                    to={
                      isAdmin
                        ? `/client/program_brochure/${programId}/term-information`
                        : `/program_brochure/${programId}/term-information`
                    }
                  >
                    {terms.length === 1 ? 'View Term' : `View Terms`}
                  </ListLink>
                )}
              </li>
              <li style={{ display: 'none' }}>
                <TabHeading level={4}>Eligibility</TabHeading>
                <TabLabelList>
                  <li>3.2 GPA</li>
                  <li>Engineering</li>
                  <li>Via University</li>
                </TabLabelList>
                <ListLink to="#">View More</ListLink>
              </li>
              {managerList?.length > 0 && (
                <li>
                  <TabHeading level={4}>{programAlias} Contact(s)</TabHeading>
                  {displayedContacts.map(contact => (
                    <ProgramContact key={`contact-${contact.id}`}>
                      {
                        contact.name === contact.email ? 
                        `${contact.email} ${contact.program_contact_title ? `, ${contact.program_contact_title}` : ''}`
                        : 
                        <>
                        {contact.program_contact_title ? (
                          <>
                            <div>{`${contact.name}, ${contact.program_contact_title};`}</div>
                            <span>{`${contact.email}`}</span>
                          </>
                        ) : (
                          <span>{`${contact.name}, ${contact.email}`}</span>
                        )}
                      </>
                      }
                    </ProgramContact>
                  ))}
                  {remainingCount > 0 && (
                    <div>
                      <Button onClick={handleToggle} style={{  }}>
                        {showAll ? '- View Less' : `+ ${remainingCount} more`}
                      </Button>
                    </div>
                  )}
                </li>
              )}
              <li style={{ display: 'none' }}>
                <TabHeading level={4}>Secondary {programAlias} Contact</TabHeading>
                <ProgramContact>Sam Ple, sample@sample.com</ProgramContact>
              </li>
            </OverviewList>
            <div>{!isAdmin && <Apply />}</div>
          </TabCardPanel>
        </TabCard>
      </TabContentContainer>
      {(program_brochure_section?.title || brochureDescription) && (
        <TabContentContainer>
          <div>
            <TabHeading level={2}>{program_brochure_section?.title}</TabHeading>
            <TabCard tabIndex={0} isBrochureSection={true}>
              <div dangerouslySetInnerHTML={{ __html: brochureDescription }} />
            </TabCard>
          </div>
        </TabContentContainer>
      )}


      {program_info_video_url && <VideoContainer style={{ marginBottom: "1rem" }}><EmbeddedVideoView url={program_info_video_url} /></VideoContainer>}

      {showMap && <Map coordinates={coordinates} />}
      <Testimonials />
      <OrganizationalInformation />
    </>
  );
}

ProgramOverviewTab.propTypes = {
  isAdmin: isAdminType,
  program: programType.isRequired,
};
