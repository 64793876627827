import React,{useEffect} from 'react';
import propTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { withCookies, Cookies } from 'react-cookie';
import { compose } from 'redux';
import GlobalStyles from './styles/GlobalStyles';
import loadedFonts from '../shared/fonts/loadFonts.css';
import SvgFooterLandscape from '../sites/components/svgs/svgFooterLandscape';
import { ACCENT_DEFAULT } from '../shared/helpers/ViaDefaults';
import { themeVia } from './settings/theme';
import { useThemeColors } from '../shared/hooks/useThemeColors';
import ViaLogo from '../sites/components/svgs/viaLogo';

const size = {
  mobile: '500px',
  tablet: '768px',
  laptop: '1024px',
};

export const breakpoints = {
  mobile: `(min-width: ${size.mobile})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
};

const Filler = styled.div`
  background-color: #446372;
`;

const Footer = styled.footer`
  font-family: Open Sans, sans-serif;
  margin-top: auto;
`;

const LayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Main = styled.main`
  margin: 100px 30px 0 30px;
  max-width: 1080px;

  @media ${breakpoints.laptop} {
    margin: 100px auto 0 auto;
  }
`;

const Svg = styled.div`
  height: 100px;
  margin-bottom: -2px;

  @media ${breakpoints.tablet} {
    height: inherit;
  }
`;

const Actions = styled.div`
  display: flex;
  background-color: #446372;
  flex-direction: column;
  padding: 10px 25px;
  height: 100px;

  @media ${breakpoints.mobile} {
    flex-direction: row;
    height: 60px;
  }

  @media ${breakpoints.tablet} {
    height: 60px;
    flex-direction: row;
  }

  @media ${breakpoints.laptop} {
    flex-direction: row;
    height: 60px;
  }
`;

const Logo = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  text-decoration-line: underline;
  color: #ffffff;
  background-color: #446372;
  flex: 0 1 85%;
  align-self: center;
  padding-bottom: 15px;
  cursor: pointer;

  @media ${breakpoints.mobile} {
    flex: 0 1 70%;
    padding-bottom: 0;
  }

  @media ${breakpoints.tablet} {
    flex: 0 1 75%;
    padding-bottom: 0;
  }

  @media ${breakpoints.laptop} {
    flex: 0 1 85%;
    padding-bottom: 0;
  }
`;

const Terms = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  text-decoration-line: underline;
  color: #ffffff;
  background-color: #446372;
  flex: 1;
  align-self: center;
  cursor: pointer;
`;

function PublicLayoutStyled(props) {
  const { children } = props;
  const isBrandingSet = useThemeColors();
  const colorAccent = document.documentElement.style.getPropertyValue(`--theme_color_accent`) || ACCENT_DEFAULT;
  const { cookies } = props;
  const token = cookies.get('token');
  
  useEffect(()=>{
    const queryParameters = new URLSearchParams(window.location.search)
    const redirectUrl = queryParameters.get("jmp")
    
    if(token && redirectUrl){
      window.location.href = redirectUrl;
    }
  },[]);

  return (
    <>
      {isBrandingSet ? (
        <ThemeProvider theme={themeVia}>
          <GlobalStyles />
          <LayoutWrapper>
            <Main>{children}</Main>
            <Footer>
              <Svg>
                <SvgFooterLandscape color={colorAccent} />
              </Svg>
              <Actions>
                <Logo
                  tabIndex={0}
                  onClick={() => {
                    window.open('https://www.viatrm.com/', '_blank');
                  }}
                  onKeyDown={e => {
                    if (e.keyCode === 32 || e.keyCode === 13) {
                      window.open('https://www.viatrm.com/', '_blank');
                    }
                  }}
                >
                  <ViaLogo size="small" alt="Via Logo" />
                </Logo>
                <Terms
                  tabIndex={0}
                  onClick={() => {
                    window.open('https://www.viatrm.com/terms/', '_blank');
                  }}
                  onKeyDown={e => {
                    if (e.keyCode === 32 || e.keyCode === 13) {
                      window.open('https://www.viatrm.com/terms/', '_blank');
                    }
                  }}
                >
                  Terms & Conditions
                </Terms>
              </Actions>

              <Filler />
            </Footer>
          </LayoutWrapper>
        </ThemeProvider>
      ) : null}
    </>
  );
}

PublicLayoutStyled.propTypes = {
  children: propTypes.node,
};

export default compose(withCookies, withStyles(loadedFonts))(PublicLayoutStyled);
