import React from 'react';

function WarningImageIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="125" height="125" viewBox="0 0 125 125" fill="none">
      <path
        d="M18 110.25H106.917C115 110.25 119.833 101.5 115.583 94.75L71.1667 19.4166C67.1667 13.0833 57.75 13.0833 53.75 19.4166L9.25 94.8333C5.08333 101.583 10 110.25 18 110.25Z"
        fill="#C4665A"
      />
      <path d="M62.5 25.75L19.1666 99.1667H105.917L62.5 25.75Z" fill="white" stroke="white" stroke-miterlimit="10" />
      <path
        d="M62.5 90.0834C64.9167 90.0834 67 88.0834 67 85.5834C67 83.1667 65 81.0834 62.5 81.0834C60 81.0834 58 83.0834 58 85.5834C58 88.0834 60.0833 90.0834 62.5 90.0834Z"
        fill="#FFE8C3"
      />
      <path
        d="M67 53.8334C67 56.25 65 72.4167 62.5 72.4167C60 72.4167 58 56.3334 58 53.8334C58 51.4167 60 49.3334 62.5 49.3334C65 49.3334 67 51.4167 67 53.8334Z"
        fill="#FFE8C3"
      />
      <path
        d="M17.9167 112.583C16.1667 112.583 14.5 112.167 12.9167 111.583C11.5 111 10 110 9.00004 108.833C7.83337 107.667 7.00004 106.417 6.41671 104.917C5.83337 103.5 5.58337 101.75 5.58337 100.333C5.58337 98 6.16671 95.75 7.41671 93.8334L51.9167 18.4167V18.3334C53.0834 16.5 54.6667 15 56.5 14C58.3334 13 60.4167 12.5834 62.4167 12.5834C64.4167 12.5834 66.4167 13.1667 68.3334 14C70.1667 15 71.9167 16.5834 72.9167 18.3334V18.5L101.25 66.5834C101.5 67.1667 101.667 67.75 101.5 68.1667C101.333 68.75 100.917 69.3334 100.5 69.5834C100.083 69.75 99.75 69.8334 99.3334 69.8334C98.5 69.8334 97.75 69.4167 97.5 68.6667L69.25 20.5834C67.8334 18.25 65.25 16.8334 62.3334 16.8334C59.4167 16.8334 57 18.25 55.5834 20.5834L11.1667 95.8334V96C10.3334 97.3334 10 98.75 10 100.167C10 102.167 10.8334 104.167 12.3334 105.75C13.9167 107.333 15.9167 108.167 18.0834 108.167H107C109.333 108.167 111.167 107.333 112.75 105.75C114.167 104.333 115.083 102.333 115.083 100.167C115.083 98.75 114.667 97.25 113.917 96V95.8334L108 85.75C107.75 85.1667 107.583 84.5834 107.75 84C107.917 83.4167 108.333 82.8334 108.75 82.5834C109.167 82.4167 109.5 82.3334 109.917 82.3334C110.75 82.3334 111.5 82.75 111.75 83.5L117.667 93.5834C119 95.5834 119.5 97.75 119.5 100.083C119.5 101.667 119.25 103.25 118.667 104.667C118.083 106.083 117.25 107.583 116.083 108.583C114.917 109.75 113.667 110.75 112.167 111.333C110.583 112.083 108.833 112.333 107.167 112.333H17.9167V112.583Z"
        fill="#373737"
      />
      <path
        d="M105.833 101.333H19.0833C18.25 101.333 17.5 100.917 17.25 100.167C16.8333 99.4167 16.8333 98.5834 17.25 97.8334L60.5833 24.4167C61 23.8333 61.75 23.25 62.4167 23.25C63.1667 23.25 64 23.6667 64.25 24.4167L107.583 97.8334C108 98.5834 108 99.4167 107.583 100.167C107.417 101 106.667 101.333 105.833 101.333ZM23.0833 96.8333H102.083L62.5 30.0833L23.0833 96.8333Z"
        fill="#373737"
      />
      <path
        d="M62.5 92.25C58.9166 92.25 55.9166 89.25 55.9166 85.6667C55.9166 82.0834 58.9166 79.0834 62.5 79.0834C66.0833 79.0834 69.0833 82.0834 69.0833 85.6667C69.0833 89.25 66.0833 92.25 62.5 92.25ZM62.5 83.3334C61.3333 83.3334 60.3333 84.3334 60.3333 85.5C60.3333 86.6667 61.3333 87.6667 62.5 87.6667C63.6666 87.6667 64.6666 86.6667 64.6666 85.5C64.6666 84.3334 63.6666 83.3334 62.5 83.3334Z"
        fill="#373737"
      />
      <path
        d="M62.5 74.5833C60.0834 74.5833 59.1667 71.6666 58.5834 70.5833C58 68.9999 57.5834 67 57.1667 64.5C56.3334 60 55.8334 55.4166 55.8334 54C55.8334 50.4166 58.8334 47.4166 62.4167 47.4166C66 47.4166 69 50.4166 69 54C69 55.4166 68.4167 60.0833 67.6667 64.5C67.25 67.0833 66.6667 69.0833 66.25 70.5833C65.8334 71.6666 64.9167 74.5833 62.5 74.5833ZM62.5 51.5C61.3334 51.5 60.3334 52.5 60.3334 53.6666C60.3334 54.5 60.75 58.25 61.5 62.3333C61.9167 64.9166 62.3334 66.6666 62.6667 67.8333C63.0834 66.6666 63.4167 64.9166 63.8334 62.3333C64.5834 58.3333 65 54.6666 65 53.6666C64.6667 52.5 63.6667 51.5 62.5 51.5Z"
        fill="#373737"
      />
      <path
        d="M104.667 78.3333C105.833 78.3333 106.833 77.3333 106.833 76.1667C106.833 75 105.833 74 104.667 74C103.5 74 102.5 75 102.5 76.1667C102.5 77.25 103.333 78.3333 104.667 78.3333Z"
        fill="#373737"
      />
    </svg>
  );
}

export default WarningImageIcon;
