import React from 'react';
import styled from 'styled-components';

import { breakpoints } from '../../utils/breakpoints';
import { buttonFocus } from '../../utils/shared/focusStyles';

export const Container = styled.div`
  position: absolute;
  z-index: 50;

  ${props => {
    if (!props.show) {
      return `
        display: none;
      `;
    }
  }};
`;

export const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 50;
`;

export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-items: center;
  align-items: center;
  z-index: 100;
  padding: 0 1rem;

  @media ${breakpoints.desktop} {
    padding: 0;
  }
`;

export const ModalPanel = styled.div`
  border-radius: 10px;
  box-shadow: 0 0.625rem 1.25rem rgba(32, 37, 38, 0.1), 0 1.25rem 3.125rem rgba(32, 37, 38, 0.1);
  width: 100%;
  max-width: 46.875rem;
  overflow: hidden;
  flex-grow: 1;
  margin: 0 auto;
  background: #ffffff;

  @media ${breakpoints.desktop} {
    min-width: 34.125rem;
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 1.5625rem 2.1875rem;
  justify-content: space-between;
  border-bottom: ${props => (props.showBorder ? `solid 0.0625rem #e7e7e7` : `'none'`)};
  background: #ffffff;

  h2 {
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.5625rem;
    line-height: 120%;
    color: #373737;
    margin-bottom: 0;
  }
`;

export const ModalTitle = styled.div`
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.5625rem;
    line-height: 120%;
    color: #373737;
    margin-bottom: 0;
`;

export const ModalCloseButton = styled.button`
  background-color: transparent;
  background-image: none;
  border: 2px solid transparent;
  border-radius: 2px;

  svg {
    height: 1.5rem;
    width: 1.5rem;
  }

  ${buttonFocus};
`;

export const ModalBody = styled.div`
  padding-left: 2.1875rem;
  padding-right: 2.1875rem;
  padding-top: 1.8125rem;
  padding-bottom: 3.5rem;
  background: #ffffff;

  h2 {
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.5625rem;
    line-height: 120%;
    color: #373737;
    margin-bottom: 0;
  }

  p {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 140%;
    letter-spacing: 0.005em;
    color: #373737;
    margin-bottom: 0;
  }

  &.success {
    display: flex;
    flex-direction: column;
    gap: 2.125rem;
    text-align: center;
    align-items: center;
    margin: 0 auto;
    max-width: 41.5rem;
    padding: 3.5rem;
  }

  @media ${breakpoints.tablet} {
    padding-left: 3.375rem;
    padding-right: 4.0625rem;
  }
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 2rem;
  padding: 1.875rem;
  align-items: center;
  background-color: #ebebeb;
`;

export const ModalButton = styled.button`
  display: inline-flex;
  align-items: center;
  align-self: flex-start;
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  line-height: 120%;
  letter-spacing: 0.06em;
  padding: 0.75rem 1.75rem;
  font-weight: 600;
  background-color: transparent;
  background-image: none;
  border: 2px solid transparent;
  border-radius: 5px;

  &.primary {
    background: var(--theme_color_accent);
    color: var(--theme_color_accent_font);
  }

  &.secondary {
    color: #373737;
  }

  ${buttonFocus};

  &:disabled {
    cursor: not-allowed;
    opacity: 0.8;
  }
`;
