import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import styled from 'styled-components';

import { breakpoints } from '../../../utils/breakpoints';

const Deadline = styled.span`
  display: ${props => (props.hideOnMobile ? 'none' : 'block')};
  padding: 0.1rem 0.5rem;
  background-color: ${props => (props.isOverdue ? '#FFEFD4' : '#DEECFF')};
  border-width: 1px;
  border-style: solid;
  border-color: ${props => (props.isOverdue ? '#6D4603' : '#255A94')};
  border-radius: 3px;
  font-family: 'Nunito', sans-serif;
  font-style: normal;
  font-weight: ${props => (props.isOverdue ? '700' : '500')};
  font-size: 0.875rem;
  color: ${props => (props.isOverdue ? '#6D4603' : '#255A94')};
  line-height: 120%;

  strong {
    font-weight: 700;
  }

  @media ${breakpoints.tablet} {
    display: ${props => (props.hideOnMobile ? 'block' : 'none')};
  }
`;

export default function FormDeadline({ deadline, hideOnMobile }) {
  

  const currentDate = moment().startOf('day');
  const targetDate = moment(deadline).startOf('day');
  
  //const isOverdue = moment(deadline).isBefore(moment());
  const isOverdue = !targetDate.isSameOrAfter(currentDate)

  const formattedDeadline = moment(deadline).format('MMM D, YYYY');

  return (
    <Deadline isOverdue={isOverdue} hideOnMobile={hideOnMobile}>
      {isOverdue ? (
        <span>Overdue</span>
      ) : (
        <span>
          <strong>Due:</strong> {formattedDeadline}
        </span>
      )}
    </Deadline>
  );
}

FormDeadline.propTypes = {
  hideOnMobile: PropTypes.bool,
  deadline: PropTypes.string.isRequired,
};

FormDeadline.defaultProps = {
  hideOnMobile: false,
};
