import React, { useEffect, useState,  } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

const SwitchInput = styled.input`
  height: 0;
  visibility: hidden;
  width: 0;
  &:disabled {
    cursor: not-allowed;
  }
`;

const SwitchLabel = styled.label`
  align-items: center;
  border: ${({ theme }) => theme.toggle.border};
  border-radius: 50px;
  color: ${({ theme }) => theme.toggle.fontColor};
  cursor: pointer;
  display: flex;
  font-family: ${({ theme }) => theme.toggle.fontFamily};
  font-size: ${({ theme }) => theme.toggle.fontSize};
  height: 30px;
  justify-content: space-between;
  padding: 0 6px;
  position: relative;
  transition: background-color 0.3s;
  width: 73px;
  ${props =>
    props.disabled &&
    `
    cursor: not-allowed;
    opacity: 0.4;
  `};
  &:focus {
    border: ${({ theme }) => theme.toggle.focus.focusBorder};
    outline-color: ${({ theme }) => theme.toggle.focus.outlineColor};
  }
`;

const SwitchButton = styled.span`
  border-radius: 50px;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
  content: '';
  height: 22px;
  left: 4px;
  position: absolute;
  top: 3px;
  transition: 0.2s;
  width: 22px;
  ${props =>
    props.checked &&
    `
    background: var(--theme_color_accent, ${({ theme }) => theme.toggle.onBackgroundColor});
    left: calc(100% - 4px);
    transform: translateX(-100%);
  `};
  ${props =>
    props.disabled &&
    `
      cursor: not-allowed;
  `};
  ${({ customBranding, theme }) =>
    customBranding ? `background: ${customBranding};` : `background: ${theme.toggle.offBackgroundColor};`};
`;

const OptionsSpan = styled.span``;

const Toggle = ({ disabled, id, onChange, toggled, defaultChecked, label, name, type, value,isTogglePeventSwitching=false, ...rest }) => {
  const [checked, setChecked] = useState(defaultChecked || value || false);
  const customBranding = useSelector(state => state.profile.customBranding.themes.theme_color_dark);
 
  useEffect(
    () => {
      if (onChange) {
        onChange(checked);
      }
    },
    [checked],
  );

  useEffect(
    () => {
      if (value !== checked) {
        setChecked(value);
      }
    },
    [value],
  );

  return (
    <>
      <SwitchInput
        defaultChecked={checked}
        disabled={disabled}
        id={id}
        name={name}
        onChange={e => {
          if (!disabled && !isTogglePeventSwitching) {
            setChecked(e.target.checked);
          }
        }}
        value={value}
        type={'checkbox'}
        {...rest}
      />
      <SwitchLabel
        className="switch-label"
        htmlFor={id}
        disabled={disabled}
        tabIndex="0"
        onClick={e => {
          if (!disabled) {
            setChecked(!checked);
          }
        }}
        onKeyDown={e => {
          if (e.keyCode === 13) {
            if (!disabled) {
              setChecked(!checked);
            }
          }
        }}
      >
        <OptionsSpan>On</OptionsSpan>
        <OptionsSpan>Off</OptionsSpan>
        <SwitchButton className="switch-button" disabled={disabled} checked={checked} customBranding={customBranding} />
      </SwitchLabel>
    </>
  );
};

export default Toggle;
