import React, { useState, useEffect } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { useDispatch, useSelector } from 'react-redux';
import propTypes from 'prop-types';
import _get from 'lodash/get';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ViaIcon from '../../ViaIcon';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import history from '../../../../../history';
import Arrow from '../../../../../../public/img/arrow.svg';
import Headline from '../../../../components/library/headline';
import sAccordion from '../../../../../../public/css/accordion.scss';
import Paragraph from '../../../../components/library/paragraph';
import AccessDenied from '../../../../viaGlobal/components/pages/accessDenied';
import Button from '../../../../components/library/button';
import ItineraryBackground from '../../../../../../public/img/itinerary-background.svg';
import { getPlan } from '../../../../../actions/plans';
import DetailChoiceModal from '../../modals/detailChoiceModal';
import TransportationItemView from '../../itineraryItems/transportationItemView';
import HousingItemView from '../../itineraryItems/housingItemView';
import ActivityItemView from '../../itineraryItems/activityItemView';
import sGroupItinerary from './groupItinerary.scss';

function GroupItinerary(props) {
  const dispatch = useDispatch();
  const selectedPlan = useSelector(state => state.plans.plan);
  const currentUser = useSelector(state => state.profile.currentUser);
  const permissions = useSelector(state => state.profile.permissions);
  const [showHide, setShowHide] = useState(false);
  const [seenInstructions, setSeenInstructions] = useState();
  const [showAddDetail, setShowAddDetail] = useState(false);
  const [transportationItems, setTransportationItems] = useState();
  const [housingItems, setHousingItems] = useState();
  const [activityItems, setActivityItems] = useState();

  let localStorageSeenInstructions = JSON.parse(localStorage.getItem('seenInstructions'));

  seenInstructions ? localStorage.setItem('seenInstructions', seenInstructions) : null;

  useEffect(() => {
    let unmounted = false;

    if (!!localStorageSeenInstructions) {
      setShowHide(false);
    } else if (localStorageSeenInstructions === null || !localStorageSeenInstructions) {
      setShowHide(true);
    }

    if (!unmounted) {
      dispatch(getPlan(props.planId));
    }
    return () => {
      unmounted = true;
    };
  }, []);

  useEffect(
    () => {
      if (!!selectedPlan && !!selectedPlan.plan) {
        setTransportationItems(_get(selectedPlan.group_itinerary_byType, 'plan_transportation') || null);
        setHousingItems(_get(selectedPlan.group_itinerary_byType, 'plan_housing') || null);
        setActivityItems(_get(selectedPlan.group_itinerary_byType, 'plan_activity') || null);
      }
    },
    [selectedPlan],
  );

  return currentUser['client_user_role?'] &&
    !permissions.travel_plans_settings.includes('create_or_edit_travel_plan_and_itinerary') ? (
    <AccessDenied />
  ) : (
    <div className={sGroupItinerary['group-itinerary']}>
      {props.as === 'leader' && (
        <div
          className={sGroupItinerary.back}
          onClick={() => {
            history.push(`/plans/plan-overview/${props.planId}`, {
              tab: 'groupPlan',
            });
          }}
        >
          <img src={Arrow} className={sGroupItinerary.backButton} alt="back arrow" />
        </div>
      )}
      {props.as === 'admin' && (
        <div
          className={sGroupItinerary.back}
          onClick={() => {
            history.push(`/plans/admin-plan-overview/${props.planId}`, {
              tab: 'groupPlan',
            });
          }}
        >
          <img src={Arrow} className={sGroupItinerary.backButton} alt="back arrow" />
        </div>
      )}
      <div className={showHide ? sGroupItinerary.hiddenContainerActive : sGroupItinerary.hiddenContainerHidden}>
        {showHide ? (
          <div className={sGroupItinerary.accodionWrapper}>
            <div className={sGroupItinerary.subHeader}>
              <Headline tag="h2" as="h2" centered>
                {currentUser.first_name && currentUser.last_name
                  ? `Hello ${currentUser.first_name} ${currentUser.last_name}! `
                  : ''}
                Here's how to create a Group itinerary.
              </Headline>
            </div>
            <div className={sGroupItinerary.backgroundImage}>
              <img src={ItineraryBackground} alt="itinerary background image" />
            </div>
            <div className={sGroupItinerary.instructionsContainer}>
              <div className={sGroupItinerary.stepInstructions}>
                <div className={sGroupItinerary.step}>1</div>
                <div className={sGroupItinerary.headlineContainer}>
                  <Headline tag="h2" as="h3" centered>
                    Add Group Details
                  </Headline>
                  <Paragraph>
                    Add flight, transportation, housing, and activities details to the Group Itinerary.
                  </Paragraph>
                </div>
              </div>
              <hr className={sGroupItinerary.dashedLine} />
              <div className={sGroupItinerary.stepInstructions}>
                <div className={sGroupItinerary.step}>2</div>
                <div className={sGroupItinerary.headlineContainer}>
                  <Headline tag="h2" as="h3" centered>
                    Add Group Details to Travelers
                  </Headline>
                  <Paragraph>
                    After adding Travelers to the Group Plan, you can add group travel details to Travelers.
                  </Paragraph>
                </div>
              </div>
              <hr className={sGroupItinerary.dashedLine} />
              <div className={sGroupItinerary.stepInstructions}>
                <div className={sGroupItinerary.step}>3</div>
                <div className={sGroupItinerary.headlineContainer}>
                  <Headline tag="h2" as="h3" centered>
                    Update Changes to Itinerary
                  </Headline>
                  <Paragraph>Edit and remove travel details from the Group Itinerary as needed.</Paragraph>
                </div>
              </div>
            </div>
            <div
              className={sGroupItinerary.gotItToggle}
              onClick={() => {
                setSeenInstructions(true);
                setShowHide(!showHide);
              }}
            >
              <FontAwesomeIcon icon="caret-up" size="lg" />
              <Paragraph color="secondary">I Got It! Hide Steps.</Paragraph>
            </div>
          </div>
        ) : (
          <div
            className={sGroupItinerary.gotItToggle}
            onClick={() => {
              setShowHide(!showHide);
            }}
          >
            <FontAwesomeIcon icon="caret-down" size="lg" />
            <Paragraph color="secondary">Help! Show Steps.</Paragraph>
          </div>
        )}
      </div>
      <div className={sGroupItinerary['header-wrapper']}>
        <div className={sGroupItinerary['header-title']}>
          <Headline tag="h2" as="h2">
            Edit Group Itinerary
            <Paragraph size="large">Add details to the group itinerary</Paragraph>
          </Headline>
        </div>
        <div className={sGroupItinerary['header-action']}>
          <Button
            kind="solid"
            display="primary"
            size="medium"
            onClick={() => {
              setShowAddDetail(true);
            }}
          >
            <ViaIcon name="plus" color="white" size="xsmall" ariaLabel="Create new group plan" />
            &nbsp; Add Detail
          </Button>
        </div>
      </div>
      <div className={sGroupItinerary['accordion-wrapper']}>
        <Accordion allowMultipleExpanded allowZeroExpanded preExpanded={['transportation', 'activity', 'housing']}>
          <AccordionItem uuid="transportation">
            <AccordionItemHeading>
              <AccordionItemButton>
                <div className="wrapper-line-center">
                  <Headline tag="h2">
                    <span className="line-center">Transportation&nbsp;&nbsp;</span>
                  </Headline>
                </div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              {!transportationItems && (
                <Paragraph italic size="large">
                  Add transportation details to the group itinerary.
                </Paragraph>
              )}
              {!!transportationItems &&
                transportationItems.map(detail => (
                  <TransportationItemView
                    key={detail.id}
                    detail={detail}
                    planId={props.planId}
                    groupRemove
                    groupReinstate
                  />
                ))}
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem uuid="housing">
            <AccordionItemHeading>
              <AccordionItemButton>
                <div className="wrapper-line-center">
                  <Headline tag="h2">
                    <span className="line-center">Housing&nbsp;&nbsp;</span>
                  </Headline>
                </div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              {!housingItems && (
                <Paragraph italic size="large">
                  Add housing details to the group itinerary.
                </Paragraph>
              )}
              {!!housingItems &&
                housingItems.map(detail => (
                  <HousingItemView key={detail.id} detail={detail} planId={props.planId} groupRemove groupReinstate />
                ))}
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem uuid="activity">
            <AccordionItemHeading>
              <AccordionItemButton>
                <div className="wrapper-line-center">
                  <Headline tag="h2">
                    <span className="line-center">Activities&nbsp;&nbsp;</span>
                  </Headline>
                </div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              {!activityItems && (
                <Paragraph italic size="large">
                  Add activity details to the group itinerary.
                </Paragraph>
              )}
              {!!activityItems &&
                activityItems.map(detail => (
                  <ActivityItemView key={detail.id} detail={detail} planId={props.planId} groupRemove groupReinstate />
                ))}
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
      </div>
      <DetailChoiceModal
        show={showAddDetail}
        onClose={() => {
          setShowAddDetail(false);
        }}
        defaultSelection={0}
        planId={props.planId}
        group
      />
    </div>
  );
}

GroupItinerary.propTypes = {
  planId: propTypes.string.isRequired,
  as: propTypes.oneOf(['admin', 'leader']),
};

export default withStyles(sGroupItinerary, sAccordion)(GroupItinerary);
