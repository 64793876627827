import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import Checkbox from '../../../../../styledComponents/styles/Checkbox.styled';
import { Paragraph } from '../../../../../styledComponents/styles/Paragraph.styled';
import StyledIcon from '../../../../../styledComponents/styles/Icon.styled';
import ScreenReaderLabelStyled from '../../../../travelerForms/components/base/inputs/ScreenReaderLabel.styled';

const Container = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 0.015em;
  color: #373737;
`;

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: text-top;
  margin-top: 5px;
`;


const Icon = styled.svg`
  fill: none;
  padding-bottom: 11px;
  stroke: white;
  stroke-width: 2px;
`;

const StyledCheckboxNotChecked = styled.div`
  background-color: #fff;
  border: 1px solid ${({ theme }) => theme.checkbox.borderColor};
  border-radius: 2px;
  cursor: pointer;
  display: inline-block;
  height: 16px;
  transition: all 150ms;
  width: 16px;
`;

const StyledCheckboxChecked = styled.div`
  background-color: ${({ theme }) => theme.checkbox.checkedColor};
  border: 1px solid ${({ theme }) => theme.checkbox.borderColor};
  cursor: pointer;
  border-radius: 2px;
  display: inline-block;
  height: 16px;
  transition: all 150ms;
  width: 16px;
`;

const InputContainer = styled.div`
  flex: 1;
  margin: 13px 0;
  text-align: left;
  font-family: ${({ theme }) => theme.textInput.label.fontFamily};
`;

const Label = styled.div`
  color: ${({ theme }) => theme.textInput.label.fontColor};
  font-family: ${({ theme }) => theme.textInput.label.fontFamily};
  font-size: ${({ theme }) => theme.textInput.label.fontSize};
  font-weight: ${({ theme }) => theme.textInput.label.fontWeight};
`;

const Required = styled.span`
  color: ${({ theme }) => theme.defaults.errorColor};
  padding-left: 5px;
`;

const OptionContainer = styled.span`
  display: flex;
  align-items: baseline;
`;

const Option = styled(Paragraph)`
  font-size: 14px;
  font-family: ${({ theme }) => theme.textInput.label.fontFamily};
  padding-left: 10px;
  ${props =>
    props.disabled &&
    `
    cursor: not-allowed;
    opacity: 0.4;
  `};
`;

const Error = styled.div`
  color: ${({ theme }) => theme.defaults.errorColor};
  display: inline-block;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 400;
  padding-top: 3px;
  vertical-align: middle;

  svg {
    justify-content: center;
    margin-right: 5px;

    vertical-align: middle;
    display: inline-block;
  }

  span {
    vertical-align: middle;
    display: inline-block;
  }
`;

export default function QuestionMultipleChoiceDisplayForm({
  question,
  formState,
  errors = {},
  setValue,
  value,
  control,
  defaultValue,
  isReadonly = false,
  ...rest
}) {
  const [userOptions, setUserOptions] = useState(null);
  const id = question.question_id.toString();
  const errorMsg = _get(errors[id], 'message') || '';

  question.multiple_choice_question_options.sort((a, b) => {
    if (a.order < b.order) return -1;
    else if (a.order > b.order) return 1;
    return 0;
  });

  useEffect(
    () => {
      let buildOptions = [];
      if (question && question.multiple_choice_question_options) {
        question.multiple_choice_question_options.forEach(opt => {
          let isSaved = false;
          if (defaultValue && defaultValue.multiple_choice_responses) {
            isSaved = defaultValue.multiple_choice_responses.find(v => v.multiple_choice_question_option_id === opt.id);
          }

          opt.checked = isSaved ? true : false;
          opt.saved_id = isSaved ? isSaved.id : '';
          buildOptions.push(opt);
        });

        updateUserOptions(buildOptions, true);
        setUserOptions(buildOptions);
      }
    },
    [question],
  );

  const updateUserOptions = (options, firstLoad = false) => {
    if (options) {
      let submitValues = [];

      options.forEach(item => {
        if (item.checked) {
          submitValues.push({
            id: item.saved_id,
            _destroy: false,
            multiple_choice_question_option_id: item.id.toString(),
          });
        } else {
          if (item.saved_id) {
            submitValues.push({
              id: item.saved_id,
              _destroy: true,
              multiple_choice_question_option_id: item.id.toString(),
            });
          }
        }
      });

      if (submitValues?.length > 0 && !firstLoad) {
        setTimeout(() => {
          setValue(question.question_id.toString(), submitValues, {
            shouldValidate: true,
            shouldTouch: true,
            shouldDirty: true,
          });
        }, 1);
      } else {
        setTimeout(() => {
          setValue(question.question_id.toString(), submitValues, {
            shouldValidate: false,
            shouldTouch: false,
            shouldDirty: false,
          });
        }, 1);
      }
    }
  };
  
  const updatedInstructions = !!question.instructions ? question.instructions.replace(/<p><\/p>/g, '<br />') : '';
  return (
    <Container>
      <InputContainer>
        <Label 
          tabIndex={0}
          id={`label-mcq-${id}`}
        >
          {question.label}
          {question.required && <Required>*</Required>}
        </Label>
        <Paragraph
          role='contentinfo'
          aria-label={question.label}
          id={'multiple-choice-instructions'}
          openSans
          dangerouslySetInnerHTML={{
            __html: updatedInstructions,
          }}
        />
        <div>
          <Paragraph>Options</Paragraph>
          <div>
            {userOptions &&
              userOptions.map((option, index) => (
                <OptionContainer key={option.id}>
                  <ScreenReaderLabelStyled id={`label-option-${option.id}`}>{option.name}</ScreenReaderLabelStyled>
                   <CheckboxContainer 
                    tabIndex={0} 
                    aria-label={option.name} 
                    role='checkbox'
                    aria-checked={option.checked}
                    aria-labelledby={`label-option-${option.id}`}
                    onKeyUp={(e) => {
                          if (e.key === 'Enter') {
                            let updateOptions = _cloneDeep(userOptions);
                            updateOptions[index].checked = !option.checked;
                            updateUserOptions(updateOptions, false);
                            setUserOptions(updateOptions);
                          }
                        }}>
                    {
                      option.checked ?
                      <StyledCheckboxChecked 
                        checked={option.checked} 
                        onClick={() => {
                            let updateOptions = _cloneDeep(userOptions);
                            updateOptions[index].checked = !option.checked;
                            updateUserOptions(updateOptions, false);
                            setUserOptions(updateOptions);
                        }}
                        onKeyUp={(e) => {
                          if (e.key === 'Enter') {
                            let updateOptions = _cloneDeep(userOptions);
                            updateOptions[index].checked = !option.checked;
                            updateUserOptions(updateOptions, false);
                            setUserOptions(updateOptions);
                          }
                        }}
                        
                        disabled={isReadonly}>
                        <Icon viewBox="0 0 24 24">
                          <polyline points="20 6 9 17 4 12" />
                        </Icon>
                      </StyledCheckboxChecked>
                      :
                      <StyledCheckboxNotChecked 
                        checked={option.checked} 
                        onClick={() => {
                            let updateOptions = _cloneDeep(userOptions);
                            updateOptions[index].checked = !option.checked;
                            updateUserOptions(updateOptions, false);
                            setUserOptions(updateOptions);
                        }}
                        disabled={isReadonly}>
                        <Icon viewBox="0 0 24 24">
                          <polyline points="20 6 9 17 4 12" />
                        </Icon>
                      </StyledCheckboxNotChecked>
                    }
                  </CheckboxContainer>
                  <Option
                    // aria-label={question.label}
                    // role='option'
                    id="multiple-choice-options"
                    openSans
                    dangerouslySetInnerHTML={{
                      __html: option.name,
                    }}
                  />
                </OptionContainer>
              ))}
          </div>
          {errorMsg &&
            errorMsg.length > 0 && (
              <Error>
                <StyledIcon type="Warning" size="14px" />
                <span aria-live="assertive" role="alert">{errorMsg}</span>
              </Error>
            )}
        </div>
      </InputContainer>
    </Container>
  );
}
