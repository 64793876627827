import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import s from './ContactsModal.css';

class ContactsModal extends React.Component {
  constructor(props) {
    super(props);
  }

  getContactName = function(contact) {
    if(!contact.info[1][0].first_name || !contact.info[1][0].last_name)
      return contact.info[0].email;
    return contact.info[1][0].first_name + ' ' +   contact.info[1][0].last_name;
  }

  render() {
    const aliasProgram = this.props.aliasProgram? this.props.aliasProgram : "Program"
    const contacts = this.props.contacts.map((contact, index) => (
      <div className={s.contactBlock} key={index}>
        <div className={s.contactInfo}>
          <div className={s.contactName}>{`${this.getContactName(contact)}`}</div>
          <div className={s.contactTitle}>{contact.info[2]}</div>
        </div>
        <img
          src={ contact.info[0].avatar }
          className={s.profileImg}
          alt="Admin profile"
        />
      </div>
    ));
    const showHideClassName = this.props.show
      ? s.modalDisplay
      : s.modalDisplayNone;
    const background = this.props.show ? s.backgroundShow : s.backgroundNone;
    return (
      <div id={showHideClassName} className={s.contactsModal}>
        <p className={s.closeButton} onClick={this.props.handleClose}>
          X
        </p>
        <p className={s.orgDesc}>{this.props.organizationDescription}</p>
        {this.props.role !== 'Visitor' && (
          <div className={s.contactsContainer}>
            <p className={s.contactsHeaderTitle}>{aliasProgram} Contacts</p>
            {contacts}
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(s)(ContactsModal);
