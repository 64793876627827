import React from 'react';
import styled, { css } from 'styled-components';
import StyledIcon from '../../../../../styledComponents/styles/Icon.styled';
import StarRatingOptionFieldCard from './question.starRating.optionFieldCard';

const InputContainer = styled.div`
  flex: 1;
  margin: 20px 0;
  text-align: left;
`;

const OptionContainer = styled.span`
  display: flex;
  margin-bottom: 12px;
`;

const OtherOptionContainer = styled.div`
  font-family: 'Nunito', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  display: flex;
  align-items: center;
  letter-spacing: 0.0015em;
  text-decoration-line: underline;
  color: #446372;
  margin: 22px 0 34px 30px;
  cursor: pointer;
`;

const RemoveOption = styled.main`
  align-items: center;
  cursor: pointer;
  display: flex;
  margin-left: 20px;
`;

const Number = styled.div`
  font-family: 'Nunito', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #373737;
  display: flex;
  align-items: center;
  margin-right: 13px;
}
`;

function StartRatingOptionFields({ fields, setValue, setFocusId, createOption, moveCard }) {
  return (
    <>
      <>
        {fields
          // .sort((a, b) => (a.order > b.order ? 1 : -1))
          .map((item, index) => {
            let errorMessage;
            return (
              <>
                <StarRatingOptionFieldCard
                  key={`${index}-${item.id}`}
                  index={index}
                  item={item}
                  errorMessage={errorMessage}
                  setValue={setValue}
                  fields={fields}
                  setFocusId={setFocusId}
                  moveCard={moveCard}
                />
              </>
            );
          })}
      </>

      {fields.filter(f => !f._destroy).length === 5 ? null : (
        <OtherOptionContainer
          onClick={() => {
            createOption(fields.length + 1);
          }}
          onKeyDown={e => {
            if (e.keyCode === 13) {
              createOption(fields.length + 1);
            }
          }}
          tabIndex={0}
        >
          <StyledIcon type="Add" color="#446372" size="16px" />
          Add Option
        </OtherOptionContainer>
      )}
    </>
  );
}
export default StartRatingOptionFields; 
