import React, { useRef } from 'react';
import styled from 'styled-components';
import StyledIcon from '../styles/Icon.styled';
import Tooltip from './Tooltip.styled';

const InputGroup = styled.div`
  position: relative;
`;

const InputInnerGroup = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const InputLabel = styled.label`
  color: ${({ theme }) => theme.textAreaExpanding.label.fontColor};
  font-family: ${({ theme }) => theme.textAreaExpanding.label.fontFamily};
  font-size: ${({ theme }) => theme.textAreaExpanding.label.fontSize};
  font-weight: 500;
`;

const TextAreaInput = styled.textarea`
  border: ${(props) =>
    props.errorMsg && props.errorMsg.length > 0 ? ({ theme }) =>
      theme.textAreaExpanding.error.border : ({ theme }) => theme.textAreaExpanding.border
  };
  box-sizing: border-box;
  color: ${({theme}) => theme.textAreaExpanding.fontColor};
  font-family: ${({theme}) => theme.textAreaExpanding.fontFamily};
  font-size: ${({theme}) => theme.textAreaExpanding.fontSize};
  height: ${props => props.idealHeight || '152px'};
  line-height: ${({theme}) => theme.textAreaExpanding.lineHeight};
  margin-bottom: 0;
  //max-height: ${({theme}) => theme.textAreaExpanding.maxHeight};

  max-height: ${(props) => 
          props.maxHeight && props.maxHeight.length > 0 ? props.maxHeight : ({ theme }) => theme.textAreaExpanding.maxHeight
  };
  
  
  min-height: ${({theme}) => theme.textAreaExpanding.height};
  padding: ${({theme}) => theme.textAreaExpanding.padding};
  resize: vertical;
  text-size-adjust: 100%;
  width: 100%;

  &:disabled {
    border: ${({theme}) => theme.textAreaExpanding.disabled.border};
    background-color: ${({theme}) => theme.textAreaExpanding.disabled.backgroundColor};
    color: ${({theme}) => theme.textAreaExpanding.disabled.fontColor};
    cursor: not-allowed;
  }

  &:focus {
    outline-color:  ${({theme}) => theme.textAreaExpanding.focus.outlineColor};
  }

  &::placeholder {
    color: ${({theme}) => theme.textAreaExpanding.placeholder.fontColor};
    font-family: ${({theme}) => theme.textAreaExpanding.placeholder.fontFamily};
    font-size: ${({theme}) => theme.textAreaExpanding.placeholder.fontSize};
    font-style: ${({theme}) => theme.textAreaExpanding.placeholder.fontStyle};
  }
`;

const Error = styled.span`
  color: ${({theme}) => theme.textAreaExpanding.error.fontColor};
  font-family: ${({theme}) => theme.textAreaExpanding.error.fontFamily};
  font-size: ${({theme}) => theme.textAreaExpanding.error.fontSize};

  svg {
    justify-content: center;
    margin-right: 5px;
  }
`;

const Required = styled.span`
  color: ${({ theme }) => theme.textAreaExpanding.error.fontColor};
  padding-left: 5px;
`;

const TooltipContainer = styled.span`
  padding-left: 5px;
`;

const IconDiv = styled.div`
  color: ${({theme}) => theme.textAreaExpanding.disabled.fontColor};
  position: relative;
  right: 28px;
  top: 9px;
  width: 0;
`;

export default function TextAreaExpanding(props) {
  const idealHeight = useRef(45);
  const lastScrollHeight = useRef(42);
  const textAreaRef = useRef(null);

  if (textAreaRef.current != null && textAreaRef.current != undefined) {
    textAreaRef.current.style.height = '0px';
    let scrollHeight = textAreaRef.current.scrollHeight;
    textAreaRef.current.removeAttribute('style');
    let delta = scrollHeight-lastScrollHeight.current;
    lastScrollHeight.current = scrollHeight;
    idealHeight.current = idealHeight.current + delta;
  }

  return (
    <InputGroup>
      <InputLabel htmlFor={props.id}>
        {props.label}
        {props.required && <Required>*</Required>}
        {props.tooltip &&
        <TooltipContainer>
          <Tooltip toolTipText={props.tooltip}>
            <StyledIcon type={'InformationCircle'} size={'14px'} />
          </Tooltip>
        </TooltipContainer>}
      </InputLabel>
      <InputInnerGroup>
        <TextAreaInput
          aria-invalid={props.errorMsg && props.errorMsg.length > 0 ? 'true' : 'false'}
          aria-label={props.label || 'Text area'}
          disabled={props.disabled}
          errorMsg={props.errorMsg}
          id={props.id}
          idealHeight={idealHeight.current + "px"}
          maxHeight={props.maxHeight}
          // onChange={e => {props.setValue(e.target.value)}}
          placeholder={props.placeholder}
          ref={textAreaRef}
          // value={props.value}
          {...props}
        />
        {props.icon && props.icon.length > 0 &&
          <IconDiv>
            <StyledIcon type={props.icon} size={'16px'} />
          </IconDiv>
        }
      </InputInnerGroup>

      {props.errorMsg && props.errorMsg.length > 0 && (
        <Error>
          <StyledIcon type={'Warning'} size={'16px'} />{props.errorMsg}
        </Error>
      )}
    </InputGroup>
  );
}
