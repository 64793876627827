exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@-webkit-keyframes HiNJ7{to{-webkit-transform:rotate(1turn)}}@keyframes HiNJ7{to{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}._33SyZ ._1W0Pl{-ms-flex:1 1;flex:1 1;-ms-flex-direction:row;flex-direction:row;display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;padding:16px}@media only screen and (max-width:599px){._33SyZ ._1W0Pl{-ms-flex-direction:column;flex-direction:column}}._33SyZ ._2yW9l{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;padding:40px 100px;-ms-flex-pack:center;justify-content:center}._33SyZ ._2yW9l ._3RUT4{padding:12px}._33SyZ .bIYNg{-ms-flex:1 1;flex:1 1;-ms-flex-direction:column;flex-direction:column;display:-ms-flexbox;display:flex;border-top:2px solid #ebebeb;padding:32px 20px 0 0;-ms-flex-pack:center;justify-content:center}@media only screen and (max-width:599px){._33SyZ .bIYNg{-ms-flex-direction:column;flex-direction:column}}._33SyZ .bIYNg .sKV8F{padding:20px 0;display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-pack:center;justify-content:center}._33SyZ .bIYNg .sKV8F ._27j06{padding-left:12px}@media only screen and (max-width:599px){._33SyZ .bIYNg .sKV8F ._27j06{padding-top:12px}}._33SyZ .bIYNg ._3epoU{-ms-flex:1 1;flex:1 1}", ""]);

// exports
exports.locals = {
	"reinstate-detail": "_33SyZ",
	"subHeader": "_1W0Pl",
	"content": "_2yW9l",
	"toggle": "_3RUT4",
	"footer": "bIYNg",
	"footer-button-wrapper": "sKV8F",
	"button-content": "_27j06",
	"footer-confirm": "_3epoU",
	"spin": "HiNJ7"
};