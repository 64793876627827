import React from 'react';

export default function SimpleClockIcon() {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
      <path
        d="M8.25 0.25C3.83192 0.25 0.25 3.83192 0.25 8.25C0.25 12.6681 3.83192 16.25 8.25 16.25C12.6681 16.25 16.25 12.6681 16.25 8.25C16.25 3.83192 12.6681 0.25 8.25 0.25ZM11.9423 9.48077H8.25C8.08679 9.48077 7.93026 9.41594 7.81486 9.30053C7.69945 9.18512 7.63462 9.02859 7.63462 8.86539V3.32692C7.63462 3.16371 7.69945 3.00719 7.81486 2.89178C7.93026 2.77637 8.08679 2.71154 8.25 2.71154C8.41321 2.71154 8.56974 2.77637 8.68514 2.89178C8.80055 3.00719 8.86539 3.16371 8.86539 3.32692V8.25H11.9423C12.1055 8.25 12.262 8.31483 12.3775 8.43024C12.4929 8.54565 12.5577 8.70218 12.5577 8.86539C12.5577 9.02859 12.4929 9.18512 12.3775 9.30053C12.262 9.41594 12.1055 9.48077 11.9423 9.48077Z"
        fill="currentColor"
      />
    </svg>
  );
}
