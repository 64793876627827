exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3map3{font-family:Open Sans;font-size:16px;font-weight:400;line-height:22.4px;letter-spacing:.005em;text-align:left}.V3p3o{padding:45px}._3hPxa{padding:0}", ""]);

// exports
exports.locals = {
	"text": "_3map3",
	"container": "V3p3o",
	"modelBody": "_3hPxa"
};