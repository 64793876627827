import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import propTypes from 'prop-types';
import styled from 'styled-components';
import UserPilotScript from '../../../../../../../components/UserPilotScript';
import { PageHeader } from '../../../../../../../styledComponents/styles/PageHeader.styled';
import { Paragraph } from '../../../../../../../styledComponents/styles/Paragraph.styled';
import ArchiveModal from '../modals/archive.modal';
import DeleteDraftModal from '../modals/delete.draft.modal';
import history from '../../../../../../../history';
import { AccentButtonMedium } from '../../../../../../../styledComponents/styles/Buttons.styled';
import { ButtonContainer } from '../../../../../../../styledComponents/styles/Containers.styled';
import { Grid } from '../../../../../../../styledComponents/styles/FlexGrid.styled';
import { AccentButtonSpinner } from '../../../../../../../styledComponents/styles/spinner.styled';
import sProgram from '../../program.scss';
import {
  fetchProgramObject,
  programDuplicate,
  updateExternalUrl,
} from '../../../../../../../actions/programSettingsActions';
import CopyOrOpenUrlTextComponent from '../../../../../../../styledComponents/styles/CopyOrOpenUrlTextComponent.styled';
import { ExternalLinkInput } from '../input.fields';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import TextInput from '../../../../../../../styledComponents/styles/TextInput.styled';
import { getProgramModify } from '../../../../../../../actions/programModifyActions';
import ArchiveIcon from './ArchiveIcon';
import SettingIcon from './SettingIcon';
import PreviewIcon from './PreviewIcon';
import DuplicateIcon from './DuplicateIcon';
import TrashIcon from './TrashIcon';
import EditIcon from './EditIcon';
import _get from 'lodash/get';

const InformationContainer = styled(Paragraph)`
  font-size: 16px;
`;

const TermSettingsHeading = styled.h1`
  font-family: Nunito;
  font-size: 18px;
  font-weight: 600;
  line-height: 21.6px;
  letter-spacing: 0.001em;
  text-align: left;
  color: #446372;
`;

function HeaderActionsSection({
  control,
  errors,
  customAliases,
  isDirty,
  isValid,
  handleSubmit,
  handleSaveTermsSettings,
  program,
  isSaving,
  allowArchive,
  userPermissions,
  source = 'PROGRAM_MODIFY',
  ...rest
}) {
  const dispatch = useDispatch();
  const [showArchive, setShowArchive] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [externalUrl, setExternalUrl] = useState('');
  const [isArchiveButtonHovered, setIsArchiveButtonHovered] = useState(false);
  const [isEditProgramButtonHovered, setIsEditProgramButtonHovered] = useState(false);
  const [isProgramSettingButtonHovered, setIsProgramSettingButtonHovered] = useState(false);
  const [isPreviewButtonHovered, setIsPreviewButtonHovered] = useState(false);
  const [isDuplicateButtonHovered, setIsDuplicateButtonHovered] = useState(false);
  const [isDeleteButtonHovered, setIsDeleteButtonHovered] = useState(false);
  const extUrl = useSelector(state => state?.programSettings?.programObject?.program?.external_application_url);

  useEffect(() => {
    setExternalUrl(extUrl || '');
  }, [extUrl]);

  const duplicateProgram = () => {
    if (program && program?.id) {
      dispatch(programDuplicate(program?.id));
    }
  };

  const deleteProgram = () => {
    if (program && program?.id) {
      setShowDelete(true);
    }
  };
  let programStatus = '';
  if (program?.status) {
    programStatus = program?.status;
  } else if (program?.attributes?.status) {
    programStatus = program?.attributes?.status;
  }

  let programTitle = '';
  if (program?.title) {
    programTitle = program?.title;
  } else if (program?.attributes?.title) {
    programTitle = program?.attributes?.title;
  }

  let textColors = '#FFFFFF';
  let backgroundColors = '#666666';
  if (programStatus.toLowerCase() === 'draft') {
    textColors = '#6D4603';
    backgroundColors = '#DDAF64';
  } else if (programStatus.toLowerCase() === 'published') {
    textColors = '#FFFFFF';
    backgroundColors = '#2A8A53';
  }
  return (
    <Grid
      style={{
        width: '100%',
        // height: '150px',
        position: 'sticky',
        zIndex: '2',
        margin: 'auto',
        top: '0px',
        background: source === 'TERM_SETTINGS' ? 'var(--ag-data-color, #FFF)' : '',
        boxShadow: source === 'TERM_SETTINGS' ? '1px 4px 20px 0px rgba(0, 0, 0, 0.10)' : '',
        padding: source === 'TERM_SETTINGS' ? '0px 40px' : 'unset',
      }}
    >
      <UserPilotScript />
      <div className={sProgram.header}>
        {source === 'TERM_SETTINGS' && <TermSettingsHeading>Term Settings</TermSettingsHeading>}

        {source === 'PROGRAM_MODIFY' && (
          <>
            <div className={sProgram.pageHeaderContainer}>
              <div>
                <PageHeader>Edit {customAliases.alias_program} Brochure</PageHeader>
              </div>
              <div
                style={{
                  color: textColors,
                  background: backgroundColors,
                }}
                className={sProgram.programStatus}
              >
                {programStatus}
              </div>
            </div>
            <InformationContainer>
              Enter the information below to create or edit your {customAliases.alias_program.toLowerCase()} brochure.
              Save all edits before exiting.
            </InformationContainer>
          </>
        )}

        {source === 'TERM_SETTINGS' && (
          <>
            {!!program && (
              <div className={sProgram.pageHeaderContainer}>
                <div>
                  <div className={sProgram.programTitle}>{programTitle}</div>
                </div>
                <div
                  style={{
                    color: textColors,
                    background: backgroundColors,
                  }}
                  className={sProgram.programStatus}
                >
                  {programStatus}
                </div>
              </div>
            )}
          </>
        )}

        <div className={sProgram.buttonContainer}>
          <div className={sProgram.buttonContainerSetting}>
            {!!program && (
              <>
                <div className={sProgram.buttonDiv}>
                  {source === 'PROGRAM_MODIFY' ? (
                    <button
                      className={`${sProgram.button} ${sProgram.buttonHover}`}
                      type="button"
                      disabled={isDirty || isSaving}
                      onMouseEnter={() => setIsProgramSettingButtonHovered(true)}
                      onMouseLeave={() => setIsProgramSettingButtonHovered(false)}
                      onClick={() => {
                        window.location.href = `/client/programs/${program?.id}/program_settings`;
                      }}
                    >
                      {isProgramSettingButtonHovered ? (
                        <SettingIcon size="16px" color="white" innerColor="#446372" />
                      ) : (
                        <SettingIcon size="16px" color="#446372" innerColor="white" />
                      )}
                      Manage Terms
                    </button>
                  ) : (
                    <button
                      className={`${sProgram.button} ${sProgram.buttonHover}`}
                      type="button"
                      //disabled={isDirty || isSaving}
                      onMouseEnter={() => setIsEditProgramButtonHovered(true)}
                      onMouseLeave={() => setIsEditProgramButtonHovered(false)}
                      onClick={() => {
                        window.location.href = `/client/programs/${program?.id}/modify`;
                      }}
                    >
                      {isEditProgramButtonHovered ? (
                        <EditIcon size="16px" color="white" innerColor="#446372" />
                      ) : (
                        <EditIcon size="16px" color="#446372" innerColor="white" />
                      )}
                      Edit Program
                    </button>
                  )}
                </div>

                <div className={sProgram.buttonDiv}>
                  <button
                    className={`${sProgram.button} ${!isDirty ? sProgram.buttonHover : ''}`}
                    disabled={isDirty || isSaving}
                    onMouseEnter={() => setIsPreviewButtonHovered(true)}
                    onMouseLeave={() => setIsPreviewButtonHovered(false)}
                    type="button"
                    onClick={() => {
                      history.push(`/client/programs/${program?.id}`);
                    }}
                  >
                    {isPreviewButtonHovered ? (
                      <PreviewIcon size="16px" color="white" innerColor="#446372" />
                    ) : (
                      <PreviewIcon size="16px" color="#446372" innerColor="white" />
                    )}
                    Preview
                  </button>
                </div>
              </>
            )}
            {!!program && (
              <>
                {userPermissions.canEdit && (
                  <div className={sProgram.buttonDiv}>
                    <button
                      className={`${sProgram.button} ${sProgram.buttonHover}`}
                      onClick={duplicateProgram}
                      type="button"
                      onMouseEnter={() => setIsDuplicateButtonHovered(true)}
                      onMouseLeave={() => setIsDuplicateButtonHovered(false)}
                    >
                      {isDuplicateButtonHovered ? (
                        <DuplicateIcon size="16px" color="white" innerColor="#446372" />
                      ) : (
                        <DuplicateIcon size="16px" color="#446372" innerColor="white" />
                      )}
                      Duplicate
                    </button>
                  </div>
                )}
              </>
            )}
            {allowArchive && userPermissions.inactivate && programStatus !== 'inactive' && (
              <div className={sProgram.buttonDiv}>
                <button
                  className={`${sProgram.button} ${sProgram.buttonArchive}`}
                  onClick={() => setShowArchive(true)}
                  type="button"
                  onMouseEnter={() => setIsArchiveButtonHovered(true)}
                  onMouseLeave={() => setIsArchiveButtonHovered(false)}
                >
                  {isArchiveButtonHovered ? (
                    <ArchiveIcon size="16px" color="white" innerColor="#861D1E" />
                  ) : (
                    <ArchiveIcon size="16px" color="#A82425" innerColor="white" />
                  )}
                  Archive
                </button>
              </div>
            )}
            {!!program && programStatus.toLowerCase() === 'draft' && (
              <>
                {userPermissions.canEdit && (
                  <div className={sProgram.buttonDiv}>
                    <button
                      className={`${sProgram.button} ${sProgram.buttonArchive}`}
                      onClick={deleteProgram}
                      type="button"
                      onMouseEnter={() => setIsDeleteButtonHovered(true)}
                      onMouseLeave={() => setIsDeleteButtonHovered(false)}
                    >
                      {isDeleteButtonHovered ? (
                        <TrashIcon size="16px" color="white" innerColor="#861D1E" />
                      ) : (
                        <TrashIcon size="16px" color="#A82425" innerColor="white" />
                      )}
                      Delete
                    </button>
                  </div>
                )}
              </>
            )}
          </div>
          <ButtonContainer>
            {source === 'TERM_SETTINGS' ? (
              <AccentButtonMedium
                type={'button'}
                disabled={!isDirty || isSaving || !userPermissions.canEdit || !isValid}
                saving={isSaving}
                onClick={handleSaveTermsSettings}
                form="program-form"
              >
                {isSaving ? <AccentButtonSpinner displayText={'Save'} /> : ' Save '}
              </AccentButtonMedium>
            ) : (
              <AccentButtonMedium
                type={'submit'}
                disabled={!isDirty || isSaving || !userPermissions.canEdit || !isValid}
                saving={isSaving}
                form="program-form"
              >
                {isSaving ? <AccentButtonSpinner displayText={'Save'} /> : ' Save '}
              </AccentButtonMedium>
            )}
          </ButtonContainer>
        </div>
        <div className={sProgram.fieldContainer}>
          <CopyOrOpenUrlTextComponent
            id={'program-url-copy'}
            linkUrl={`${window.location.host}/program_brochure/${program?.id}`}
            label={`Direct ${customAliases.alias_program} Link`}
            labelFontWeight={'600'}
            buttonText={'Copy'}
            showIcon={true}
            copyOnly
          />
          <div className={sProgram.externalLinkContainer}>
            {source === 'PROGRAM_MODIFY' && (
              <ExternalLinkInput
                control={control}
                errors={errors}
                customAliases={customAliases}
                isDirty={isDirty}
                isValid={isValid}
                handleSubmit={handleSubmit}
                program={program}
                isSaving={isSaving}
                allowArchive={allowArchive}
                userPermissions={userPermissions}
                {...rest}
              />
            )}

            {source === 'TERM_SETTINGS' && (
              <TextInput
                disabled={!userPermissions.canEdit || isSaving}
                icon={!userPermissions.canEdit || isSaving ? 'LockClosed' : ''}
                label={`Enter external link`}
                type={'text'}
                placeholder={'Enter external link'}
                value={externalUrl}
                onChange={e => {
                  setExternalUrl(e.target.value);
                  dispatch(updateExternalUrl(e.target.value));
                }}
                //onBlur={e => dispatch(updateExternalUrl(e.target.value))}
              />
            )}
          </div>
        </div>
      </div>
      {showArchive && (
        <ArchiveModal
          onClose={() => {
            setShowArchive(false);
          }}
          onSuccess={() => {
            dispatch(fetchProgramObject(program?.id));
            dispatch(getProgramModify(program?.id));
          }}
          source={source}
          programAlias={customAliases.alias_program}
          programsAlias={customAliases.alias_programs}
          customAliases={customAliases}
          programId={program?.id}
          show={showArchive}
        />
      )}
      {showDelete && (
        <DeleteDraftModal
          onClose={() => {
            setShowDelete(false);
          }}
          programAlias={customAliases.alias_program}
          programId={program?.id}
          programName={program?.title}
          show={showDelete}
        />
      )}
      {/* <HeaderRow>
        <Col size={4}>
          <ButtonContainer>
            <AccentButtonMedium
              disabled={isDirty || isSaving}
              onClick={() => {
                history.push(`/client/programs/${program.id}/program_settings`);
              }}
            >
              {customAliases.alias_program} Settings
            </AccentButtonMedium>
          </ButtonContainer>
        </Col>
        <Col size={6}>
          {allowArchive &&
            userPermissions.inactivate &&
            program.status !== 'inactive' && (
              <>
                <ArchiveLink onClick={() => setShowArchive(true)} type="button">
                  Archive Program
                </ArchiveLink>
                <ArchiveText>Archiving is an action that cannot be undone</ArchiveText>
              </>
            )}
        </Col>
        <SaveCol size={1}>
          <PreviewContainer>
            <ButtonMedium
              disabled={isDirty || isSaving}
              onClick={() => {
                history.push(`/client/programs/${program.id}`);
              }}
            >
              Preview
            </ButtonMedium>
          </PreviewContainer>
        </SaveCol>
        <SaveCol size={1}>
          <ButtonContainer>
            <AccentButtonMedium
              type={'submit'}
              disabled={!isDirty || isSaving || !userPermissions.canEdit || !isValid}
              saving={isSaving}
              form="program-form"
            >
              {isSaving ? <AccentButtonSpinner displayText={'Save'} /> : ' Save '}
            </AccentButtonMedium>
          </ButtonContainer>
        </SaveCol>
      </HeaderRow>
       */}
    </Grid>
  );
}

HeaderActionsSection.propTypes = {
  program: propTypes.object.isRequired,
};

export default withStyles(sProgram)(HeaderActionsSection);
