exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ":root{--dark:var(--theme_color_dark);--accent:var(--theme_color_accent);--ocean:var(--theme_color_light)}._2IQaA{height:34px;width:248px;font-size:16px;font-size:1rem;font-family:AvenirNextRegular,sans-serif;background-color:transparent;color:#000;border:1px solid #ccc;border-radius:4px}._2IQaA:focus{outline:none}._2IQaA::-webkit-input-placeholder{color:#aaa}._2IQaA::-moz-placeholder{color:#aaa}._2IQaA::-ms-input-placeholder{color:#aaa}._2IQaA::placeholder{color:#aaa}._2s-lz{color:#e65757}._2gKX5{color:var(--theme_color_accent);color:var(--accent,#2996da);text-decoration:underline}", ""]);

// exports
exports.locals = {
	"input": "_2IQaA",
	"inputInvalid": "_2s-lz",
	"link": "_2gKX5"
};