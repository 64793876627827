import { upperFirst } from 'lodash';

export default function getFormStatus(forms) {
  if (forms.length === 1) {
    return forms[0].status
      .split('_')
      .map(upperFirst)
      .join(' ');
  }

  let acceptedCount = 0;
  let submittedCount = 0;

  forms.forEach(form => {
    if (form.status === 'accepted') {
      acceptedCount += 1;
    } else if (form.status === 'submitted') {
      submittedCount += 1;
    }
  });

  return acceptedCount > 0
    ? `${acceptedCount} of ${forms.length} Accepted`
    : `${submittedCount} of ${forms.length} Submitted`;
}
