import React from 'react';

export default function MyProgramIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
      <g clip-path="url(#clip0_5549_9159)">
        <path d="M10.5 0C4.71012 0 0 4.71012 0 10.5C0 16.2899 4.71012 21 10.5 21C16.2899 21 21 16.2899 21 10.5C21 4.71012 16.2899 0 10.5 0ZM17.3941 7.98088C16.9015 8.4735 16.2339 8.75 15.5382 8.75H13.5634L14.4322 9.61888C14.7166 9.90325 14.8759 10.2882 14.8759 10.6899V11.83C14.8759 12.1048 14.8015 12.3743 14.6597 12.6096L12.4766 16.2487C12.3366 16.4824 12.0846 16.625 11.8125 16.625H11.1064C10.7511 16.625 10.4414 16.3835 10.3547 16.0379L9.74837 13.6106C9.67663 13.3245 9.42025 13.1241 9.12537 13.1241H7.87675C6.90987 13.1241 6.12675 12.341 6.12675 11.3741V10.4598C6.12675 9.93738 6.3875 9.45 6.82237 9.16037L8.35888 8.13663C8.61525 7.966 8.91712 7.87412 9.22513 7.87412H11.529C11.9289 7.87412 12.2526 7.55037 12.2526 7.1505V6.42338C12.2526 6.23175 12.1765 6.04712 12.0409 5.9115L11.34 5.21062C11.1055 4.97612 10.7406 4.93062 10.4563 5.10212L9.1035 5.91412C8.87425 6.0515 8.61263 6.12413 8.34487 6.12413H7.70962C7.31938 6.12413 7.00263 5.80737 7.00263 5.41712V4.69438C7.00263 4.4905 6.91425 4.29712 6.76113 4.16237L5.67175 3.2095C7.05687 2.28813 8.71762 1.74825 10.5017 1.74825C14.0446 1.74825 17.0957 3.86925 18.4704 6.90462L17.395 7.98L17.3941 7.98088Z" />
      </g>
      <defs>
        <clipPath id="clip0_5549_9159">
          <rect width="21" height="21" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
