import React from 'react';
import styled from 'styled-components';

const Link = styled.a`
  position: absolute;
  top: -50px;
  left: 5px;
  z-index: 100;
  padding: 0.5rem;
  background-color: #f1f1f1;
  border: 2px solid #255a94;
  border-radius: 2px;
  color: #255a94;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;

  &:focus {
    top: 7px;
    outline: none;
  }
`;

export default function SkipLink() {
  return <Link href="#main">Skip to main content</Link>;
}
