exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@-webkit-keyframes uQvxO{to{-webkit-transform:rotate(1turn)}}@keyframes uQvxO{to{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}._3cneH{background-color:#ebebeb;min-height:calc(100vh - 167px);padding-top:50px}._3cneH ._2byDP{width:100%;cursor:pointer;border-bottom:1px solid #fff}._3cneH ._2byDP ._1iWsQ{padding:16px 0 16px 24px}._3cneH ._2byDP ._1iWsQ ._1dh7b{width:25px;height:25px}._3cneH ._2byDP ._2e0RA{background-color:#1c748a;text-decoration:underline;-webkit-text-decoration-color:#fff;text-decoration-color:#fff}._3cneH ._2byDP ._2e0RA div,._3cneH ._2byDP ._2e0RA h1{color:#fff}._3cneH ._2byDP:hover{background-color:#767676}._3cneH ._2byDP:hover h1{color:#fff}@media only screen and (max-width:767px){._3cneH{min-height:100%}}", ""]);

// exports
exports.locals = {
	"ov-left-nav": "_3cneH",
	"nav-option": "_2byDP",
	"selection": "_1iWsQ",
	"backButton": "_1dh7b",
	"isSelected": "_2e0RA",
	"spin": "uQvxO"
};