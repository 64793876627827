import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import ClockReturnIcon from '../icons/ClockReturnIcon';
import ChevronRightIcon from '../icons/ChevronRightIcon';
import HeartHandPressIcon from '../icons/HeartHandPressIcon';
import GlobeMagnifyingGlassIcon from '../icons/GlobeMagnifyingGlassIcon';

import { breakpoints } from '../../utils/breakpoints';
import { buttonFocus } from '../../utils/shared/focusStyles';

const OuterContainer = styled.div`
  margin: 0 1.8125rem;
  padding-bottom: 3rem;

  @media ${breakpoints.tablet} {
    margin: 0 auto;
    padding-bottom: 0;
  }

  @media ${breakpoints.desktop} {
    margin-top: 1rem;
  }
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 472px;
  padding: 2.24rem 2.5rem;
  background-color: #ffffff;
  border: 1px solid #d7d7d7;
  border-radius: 15px;
  box-shadow: 0px 10px 20px rgba(132, 131, 131, 0.3);

  @media ${breakpoints.tablet} {
    padding: 2.24rem 4.875rem;
  }
`;

const Heading = styled.h2`
  margin-top: 1.155rem;
  margin-bottom: 0.375rem;
  border-width: 75%;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 1rem;
  color: #373737;
  text-align: center;
  letter-spacing: 0.0025em;
  line-height: 120%;

  &::after {
    content: '';
    display: block;
    margin: 0 auto;
    width: 75%;
    padding-top: 0.4375rem;
    border-bottom: 1px solid #bbbbbb;
  }
`;

const Info = styled.p`
  margin-bottom: 1.92625rem;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  color: #373737;
  text-align: center;
  letter-spacing: 0.0025em;
  line-height: 120%;

  @media ${breakpoints.tablet} {
    padding: 0 1rem;
  }
`;

const ExploreLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5625rem;
  padding: 0.5625rem 0.8125rem;
  background-color: var(--theme_color_accent);
  border-radius: 5px;
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
  font-size: 1rem;
  color: var(--theme_color_accent_font);
  letter-spacing: 0.06em;
  line-height: 120%;

  &:hover {
    opacity: 0.9;
    color: var(--theme_color_accent_font);
    text-decoration-line: none;
  }

  ${buttonFocus};
`;

export default function EmptyPrograms({ programsType, layout, isAdmin }) {
  const {
    customAliases: { 
      alias_programs: programsAlias, 
      alias_favorite: favoriteAlias = 'Favorite'
    },
  } = useSelector(state => state.profile);

  const tabAlias = programsType === 'favorites' ? favoriteAlias : programsType;

  const iconMap = {
    past: ClockReturnIcon,
    favorites: HeartHandPressIcon,
    upcoming: GlobeMagnifyingGlassIcon,
  };

  const Icon = iconMap[programsType];

  const findProgramURL = isAdmin ? '/client/program-dashboard/internal' : layout === 'full' ? '/traveler/program-dashboard-v2' : '/traveler/program-dashboard';

  return (
    <OuterContainer>
      <InnerContainer>
        <Icon />
        <Heading>
          {`You have no ${tabAlias?.toLowerCase()} ${programsAlias?.toLowerCase()}.`}
        </Heading>
        <Info>{`Explore ${programsAlias.toLowerCase()} to view, ${favoriteAlias?.toLowerCase()}, and apply to new opportunities.`}</Info>
        <ExploreLink to={findProgramURL}>
          <span>Explore {programsAlias}</span>
          <ChevronRightIcon />
        </ExploreLink>
      </InnerContainer>
    </OuterContainer>
  );
}

EmptyPrograms.propTypes = {
  programsType: PropTypes.string.isRequired,
  layout: PropTypes.string,
  isAdmin: PropTypes.bool,
};

EmptyPrograms.defaultProps = {
  layout: 'full',
  isAdmin: false,
}
