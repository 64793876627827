import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import StyledIcon from '../../../../styledComponents/styles/Icon.styled';

const Error = styled.div`
  color: ${({ theme }) => theme.selectOption.error.fontColor};
  display: inline-block;
  font-family: ${({ theme }) => theme.selectOption.error.fontFamily};
  font-size: ${({ theme }) => theme.selectOption.error.fontSize};
  font-weight: ${({ theme }) => theme.selectOption.error.fontWeight};
  padding-top: 3px;
  vertical-align: middle;

  svg {
    justify-content: center;
    margin-right: 5px;

    vertical-align: middle;
    display: inline-block;
  }

  span {
    vertical-align: middle;
    display: inline-block;
  }
`;
Error.displayName = 'Error';

const QuestionErrorText = ({ errorMsg = '' }) => {
  return (errorMsg?.length > 0 && 
    (
      <Error>
        <StyledIcon type="Warning" size="16px" />
        <span aria-live="assertive" role="alert">{errorMsg}</span>
      </Error>
    )
  );
}

QuestionErrorText.displayName = 'QuestionErrorText';

QuestionErrorText.propTypes = {
  errorMsg: PropTypes.string,
};

export default QuestionErrorText;



