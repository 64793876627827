import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { PropTypes } from 'prop-types';
import _uniqueId from 'lodash/uniqueId';

import s from './Checkbox.css';

const Checkbox = ({ name, value, onChange, disabled, checked,label }) => {
  const uniqID = _uniqueId('radio_')
  return (
    <span className={s.checkboxWrapper}>
      <input
        type="checkbox"
        name={name}
        value={value}
        disabled={disabled}
        checked={checked}
        onChange={onChange}
        className={s.checkboxInput}
        id={uniqID}
      />
      <label htmlFor={uniqID}>{label}</label>
    </span>
  )
};
Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  alternateColor: PropTypes.bool,
};

export default withStyles(s)(Checkbox);
