import { createTheme } from '@material-ui/core/styles';

const viaMuiTheme = (customBranding, hideChip = false) =>
  createTheme({
    overrides: {
      MUIDataTable: {
        root: {},
        paper: {
          boxShadow: 'none',
          border: '1px #ebebeb solid',
        },
      },
      MuiCheckbox: {
        colorPrimary: {
          color: customBranding ? `${customBranding.theme_color_light}` : '#1C748A',
          '&$checked': {
            color: customBranding ? `${customBranding.theme_color_light}` : '#1C748A',
          },
        },
      },
      MuiGrid: {
        container: {},
      },
      MuiFormControl: {
        root: {
          fontSize: '16px',
          fontFamily: 'AvenirNextRegular',
          color: '#043544',
        },
        marginNormal: {
          marginTop: 0,
          marginBottom: 0,
        },
      },
      MuiInputBase: {
        input: {
          fontSize: '14px',
          fontFamily: 'AvenirNextRegular',
          color: '#043544',
        },

        root: {
          fontSize: '14px',
          fontFamily: 'AvenirNextRegular',
          color: '#ebebeb',
          height: '44px',
          maxWidth: '240px',
          border: '1px solid #ebebeb',
          padding: '8px 12px',
          background: 'white',
        },
      },

      MuiInput: {
        underline: {
          '&:before': {
            borderBottom: 'none',
          },
          '&:after': {
            borderBottom: 'none',
          },
          '&:hover': {
            border: '1px solid #ebebeb',
          },
          '&:focus': {
            border: '1px solid #ebebeb',
          },
        },
      },

      MuiPickersToolbar: {
        toolbar: {
          backgroundColor: customBranding ? `${customBranding.theme_color_light}` : '#1C748A',
        },
      },
      MUIDataTableFilterList: {
        chip: {
          display: hideChip ? 'none' : 'flex',
        },
      },
      MUIDataTableBodyRow: {
        root: {
          '&.Mui-selected, &.Mui-selected:hover': {
            backgroundColor: '#DEEBFF !important',
          },
          '&:nth-child(odd)': {
            backgroundColor: '#ebebeb',
          },
        },
      },
      MuiButton: {
        root: {
          fontSize: '16px',
          fontFamily: 'AvenirNextRegular',
          color: '#043544',
        },
        label: {
          fontSize: '16px',
          fontFamily: 'AvenirNextRegular',
          color: '#043544',
        },
      },
      MUIDataTableHeadCell: {
        root: {
          fontSize: '16px',
          fontFamily: 'AvenirNextRegular',
          fontWeight: 'bold',
          color: '#043544',
        },
      },
      MUIDataTableBodyCell: {
        root: {
          fontSize: '16px',
          fontFamily: 'AvenirNextRegular',
          color: '#043544',
        },
      },
      MuiTableRow: {
        root: {
          '&.MuiTableRow-hover': {
            '&:hover': {
              backgroundColor: '#DEEBFF',
              cursor: 'pointer',
            },
          },
        },
      },
    },
  });

export { viaMuiTheme };
