import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import styles from './index.module.css';
import { Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import toastMessageStyles from '../../sites/viaGlobal/components/toastMessage/toastMessage.scss';
import { toast } from 'react-toastify';
import SelectInput from '../../sites/travelerForms/components/base/inputs/SelectInput';
import DatePickerDropDownInput from '../../sites/travelerForms/components/base/inputs/DatePickerDropDownInput';
import { Controller, useForm } from 'react-hook-form';
import { addPlanFormsToTravelers, getForms } from '../../actions/formsActions';
import BaseModalHeader from '../baseModal/BaseModalHeader';
import BaseModalFooter from '../baseModal/BaseModalFooter';

function AddPlanFormsToTravelersModal({ show, handleClose, userAndPlanIds = [], type = '', action="", handleAddForm }) {
  const dispatch = useDispatch();
  const { loading } = useSelector(state => state.forms.addPlanFormsToTravelers);
  const [formOptions, setFormOptions] = useState([]);
  const [totalUserCount, setTotalUserCount] = useState(0);
  const data = useSelector(state => state.forms);
  // const customAliases = useSelector(state => state.profile.customAliases);
  const { control, handleSubmit, watch } = useForm({
    values: {
      planForms: [],
      formDeadline: null,
    },
  });

  useEffect(() => {
    dispatch(getForms('Plan Form'));
  }, []);

  useEffect(() => {
    if (data?.forms?.data && Array.isArray(data?.forms?.data)) {
      buildFormOptions();
    }
  }, [data?.forms?.data]);

  useEffect(() => {
    // Use a Set to store unique user_ids
    const uniqueUsers = new Set(userAndPlanIds.map(item => item.user_id));

    // Get the count of unique users
    const uniqueUserCount = uniqueUsers.size;
    setTotalUserCount(uniqueUserCount);
  }, [userAndPlanIds]);

  const buildFormOptions = () => {
    let options = data?.forms?.data.map(item => {
      return {
        label: item.name,
        value: item.id,
      };
    });

    setFormOptions([...options]);
  };

  const onSubmit = data => {
    dispatch(
      addPlanFormsToTravelers(
        {
          template_ids: data.planForms.map(i => i.value),
          users: userAndPlanIds,
          deadline: data.formDeadline,
        },
        (status, message) => {
          if (status) {
            toast(message, {
              type: 'success',
              className: toastMessageStyles['successContainer'],
              toastClassName: toastMessageStyles['wrapper'],
              bodyClassName: toastMessageStyles['successBody'],
              progressClassName: toastMessageStyles['progress-bar'],
            });
            if(action==="travelerGrid"){
              handleAddForm();
            }
            handleClose();
          } else {
            toast(message, {
              type: 'error',
              className: toastMessageStyles['failureContainer'],
              toastClassName: toastMessageStyles['wrapper'],
              bodyClassName: toastMessageStyles['failureBody'],
              progressClassName: toastMessageStyles['progress-bar'],
            });
          }
        },
      ),
    );
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} centered size={'lg'}>
        <BaseModalHeader heading={'Add Plan Form(s) to Traveler(s)'} onClose={handleClose} />

        <Modal.Body className={styles.modalBody}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Container className={styles.container}>
              {action=='formReviewMode' ? 
                <div className={styles.text}>
                  Select plan form(s) to add to this traveler’s plan. 
                  If this traveler’s plan already has the selected form(s), 
                  a duplicate form will not be added but the deadline will be updated.
                </div>
              :
                <div className={styles.text}>
                  Select plan form(s) to add to {totalUserCount} travelers. If a traveler already has the selected
                  form(s), a duplicate form will not be added but the deadline will be updated.
                </div>
              }

              <div>
                <Controller
                  control={control}
                  name="planForms"
                  rules={{
                    validate: value => value.length > 0 || 'Plan form is required',
                  }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <SelectInput
                      label="Plan Forms"
                      placeholder={'Select forms'}
                      required={true}
                      options={formOptions}
                      value={value}
                      handleOnChange={onChange}
                      isMulti={true}
                      error={error?.message}
                    />
                  )}
                />
              </div>

              <div>
                <Controller
                  control={control}
                  name="formDeadline"
                  render={({ field: { onChange, value } }) => (
                    <DatePickerDropDownInput label={'Set Form Deadline'} onChange={onChange} value={value} />
                  )}
                />
              </div>
            </Container>

            {/* <div className={styles.footer}>
              <TextButton text={'Cancel'} type="button" />
              <PrimaryButton text={'Add'} loading={false} type="submit" />
            </div> */}
            <BaseModalFooter
              mainBtnType="submit"
              mainBtnText="Add"
              isBtnGroupRight={true}
              onCancel={handleClose}
              loadingBtn={loading}
            />
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default withStyles(styles)(AddPlanFormsToTravelersModal);
