import fetch from 'node-fetch';
import { authHeader } from '../shared/helpers/auth-header';
import {
  FETCH_TRAVEL_PLANS,
  FETCH_ADMIN_TRAVEL_PLAN_DATA,
  FETCH_TRAVEL_PLAN_LOGISTICS,
  SELECT_TRAVEL_PLAN_TAB,
  ADD_HOUSING_LOGISTIC,
} from './types';
import devUri from '../config/keys_dev';
import { Cookies } from 'react-cookie';

let baseUri;
let headers;
if (process.env.NODE_ENV === 'production' && process.env.AUCKLAND !== 'true') {
  baseUri = 'https://api.via-trm.com';
} else {
  baseUri = devUri.baseUri;
}

export function fetchTravelPlans(id) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${baseUri}/api/client/travel_plans/`, { headers: token })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: FETCH_TRAVEL_PLANS,
          payload: data,
        }),
      );
  };
}

export function fetchAdminTravelPlanData(id) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${baseUri}/api/client/travel_plans/${id}`, { headers: token })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: FETCH_ADMIN_TRAVEL_PLAN_DATA,
          payload: data,
        }),
      );
  };
}

export function fetchTravelPlanLogistics(id) {
  return function(dispatch) {
    fetch(`${baseUri}/api/client/travel_plans/${id}/travel_plan_logistics`, {
      headers,
    })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: FETCH_TRAVEL_PLAN_LOGISTICS,
          payload: data,
        }),
      );
  };
}

export function selectTravelPlanTab(tab) {
  return {
    type: SELECT_TRAVEL_PLAN_TAB,
    tab,
  };
}

export function addHousingLogistic(id, params) {
  return function(dispatch) {
    fetch(`${baseUri}/api/client/travel_plans/${id}/add_housing_logistic`, {
      headers,
    })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: ADD_HOUSING_LOGISTIC,
          payload: data,
        }),
      );
  };
}
