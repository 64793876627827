import fetch from 'node-fetch';
import _clone from 'lodash/clone';
import { keys } from '../config/keys';

import {
  FETCH_CURRENT_USER,
  FETCH_PROFILE_DATA,
  UPDATE_PROFILE_DATA,
  FETCH_TRAVELER_PREFERENCES,
  FETCH_TRAVELER_DETAILS,
  FETCH_TRAVELER_NOTES,
  FETCH_TRAVELER_HEADER,
  FETCH_TRAVELER_INSIGHT,
  CREATE_TRAVELER_NOTE,
  FETCH_TRAVELER_MESSAGES,
  FETCH_TRAVELER_MESSAGES_REQUEST,
  FETCH_TRAVELER_MESSAGES_FAILURE,
  CREATE_TRAVELER_MESSAGE,
  CREATE_TRAVELER_MESSAGE_LOADING,
  CHECK_TRAVELER_MESSAGE,
  FETCH_ASSIGNED_ADMINS,
  UPDATE_ASSIGNED_ADMINS,
  SELECT_TRAVELER_PROFILE_TAB,
  SELECT_MESSAGE_TAB,
  RESET_PROFILE_CODE,
  FETCH_NEW_MESSAGES,
  FETCH_NEW_MESSAGES_TRAVELER,
  MARK_ALL_READ_MESSAGE_REQUEST,
  MARK_ALL_READ_MESSAGE_SUCCESS,
  MARK_ALL_READ_MESSAGE_FAILURE,
  RESET_MESSAGE_CREATE_CODE,
  ADD_NEW_FORM,
  FETCH_GENDER_IDENTITIES,
  MANUAL_SYNC_TRAVELER,
  CLEAR_MANUAL_SYNC_TRAVELER,
  DELETE_TRAVELER_REQUEST,
  DELETE_TRAVELER_SUCCESS,
  DELETE_TRAVELER_CLEAR,
  DELETE_TRAVELER_FAILURE,
  CHECK_USER_FAVORITE_DISCLAIMER,
  SET_CURRENT_USER_LOADING,
  FETCH_TERM_POLICY_REQUEST,
  FETCH_TERM_POLICY_SUCCESS,
  FETCH_TERM_POLICY_FAILURE,
  ACCEPT_TERM_REQUEST,
  ACCEPT_TERM_SUCCESS,
  ACCEPT_TERM_FAILURE,
} from './types';
import { Cookies } from 'react-cookie';

export function getUserDetails(id) {
  let payload;
  const cookies = new Cookies();
  const token = cookies.get('token');
  return dispatch => {
    dispatch({
      type: SET_CURRENT_USER_LOADING,
      payload: true,
    });

    // Attempt to authenticate as a traveler first.
    fetch(`${keys.baseUri}/api/traveler/users/${id}/get_current_user`, { headers: token }).then(response => {
      if (response.status === 401 || response.status === 403) {
        window.location.assign(`/clear_cookies`);
      } else {
        response.json().then(data => {
          payload = data;

          // Check if the attempt was initiated by an admin by inspecting the response flags
          if (payload.admin_access && payload.admin_sign_in) {
            // Attempt to re-authenticate as an admin
            fetch(`${keys.baseUri}/api/client/traveler_profiles/${id}/get_current_user`, { headers: token }).then(
              response => {
                if (response.status === 401 || response.status === 403) {
                  window.location.assign(`/clear_cookies`);
                } else {
                  response.json().then(data => {
                    // payload = data;

                    dispatch({
                      type: FETCH_CURRENT_USER,
                      payload: data,
                    });

                    // if (data?.user?.tap_agreement === false && !window.location.href.includes('/terms')) {
                    //   window.location.assign('/terms');
                    // }
                  });

                  dispatch({
                    type: SET_CURRENT_USER_LOADING,
                    payload: false,
                  });
                }
              },
            );
          } else {
            dispatch({
              type: SET_CURRENT_USER_LOADING,
              payload: false,
            });

            // Populate the profile reducer
            dispatch({
              type: FETCH_CURRENT_USER,
              payload,
            });
          }
        });
      }
    });
  };
}

export function fetchCurrentUser(id) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  if (!id || typeof id === 'undefined') {
    window.location.assign(`/`);
  } else {
    return function(dispatch) {
      fetch(`${keys.baseUri}/api/client/traveler_profiles/${id}/get_current_user`, {
        headers: token,
      }).then(response => {
        if (response.status == 401 || response.status == 403) {
          window.location.assign(`/clear_cookies`);
        } else {
          response.json().then(data => {
            // if (data?.user?.tap_agreement === false && !window.location.href.includes('/terms')) {
            //   window.location.assign('/terms');
            // }

            dispatch({
              type: FETCH_CURRENT_USER,
              payload: data,
            });
          });
        }
      });
    };
  }
}

export function fetchCurrentTravelerUser(id) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  if (!id || typeof id === 'undefined') {
    window.location.assign(`/`);
  } else {
    return function(dispatch) {
      fetch(`${keys.baseUri}/api/traveler/users/${id}/get_current_user`, {
        headers: token,
      }).then(response => {
        if (response.status == 401 || response.status == 403) {
          window.location.assign(`/clear_cookies`);
        } else {
          response.json().then(data => {
            // if (data?.user?.tap_agreement === false && !window.location.href.includes('/terms')) {
            //   window.location.assign('/terms');
            // }

            dispatch({
              type: FETCH_CURRENT_USER,
              payload: data,
            });
            dispatch(updateUserFavoriteFlag(data));
          });
        }
      });
    };
  }
}

export function updateUserFavoriteFlag(data) {
  return function(dispatch) {
    dispatch({
      type: CHECK_USER_FAVORITE_DISCLAIMER,
      payload: data.program_favorite_disclaimer_accepted,
    });
  };
}

export function fetchProfileData(id, userType) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  if (!id || typeof id === 'undefined') {
    window.location.assign(`/`);
  } else {
    return function(dispatch) {
      fetch(`${keys.baseUri}/api/${userType}/traveler_profiles/${id}`, {
        headers: token,
      })
        .then(res => res.json())
        .then(data =>
          dispatch({
            type: FETCH_PROFILE_DATA,
            payload: data,
          }),
        );
    };
  }
}

export function fetchGenderIdentities(id) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  if (!id || typeof id === 'undefined') {
    window.location.assign(`/`);
  } else {
    return function(dispatch) {
      fetch(`${keys.baseUri}/api/gender_identities`, {
        headers: token,
      })
        .then(res => res.json())
        .then(data =>
          dispatch({
            type: FETCH_GENDER_IDENTITIES,
            payload: data,
          }),
        );
    };
  }
}

export function updateProfileData(
  id,
  data,
  passport,
  profile,
  custom_fields,
  userType,
  toggleSource,
  showProgramMatch,
  showIntake,
) {
  userType = userType == 'traveler' ? userType : 'client';
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/${userType}/traveler_profiles/${id}`, {
      method: 'PUT',
      body: JSON.stringify({
        traveler_profile: data,
        passport,
        profile,
        custom_fields,
      }),
      headers: token,
    })
      .then(res => res.json())
      .then(res => {
        if (res[0].status == 200) {
          dispatch({
            type: UPDATE_PROFILE_DATA,
            success: true,
            payload: res,
          });
          if (toggleSource) {
            if (toggleSource === 'register') {
              window.location = 'plans/my-travel';
            } else if (toggleSource === 'travelerView') {
              window.location = '/traveler/programs';
            } else {
              if (showIntake) {
                window.location = '/intake';
              } else if (!showIntake && showProgramMatch) {
                window.location = '/program-match';
              } else if (!showIntake && !showProgramMatch) {
                window.location = '/traveler/programs';
              }
            }
          }
        } else {
          dispatch({
            type: 'ADD_ALERT',
            text: res.errors,
            style: 'danger',
          });
        }
      })
      .then(() => {
        dispatch(resetProfileCode());
        dispatch(fetchProfileData(id, userType));
      });
  };
}

export function fetchTravelerProfile(id) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  if (!id || typeof id === 'undefined') {
    window.location.assign(`/`);
  } else {
    return function(dispatch) {
      fetch(`${keys.baseUri}/api/client/traveler_profiles/${id}/insights`, {
        headers: token,
      })
        .then(res => res.json())
        .then(data =>
          dispatch({
            type: FETCH_TRAVELER_INSIGHT,
            payload: data,
          }),
        )
        .catch(error => console.error(error));
    };
  }
}

export function fetchProfileHeader(id, userType) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  if (!id || typeof id === 'undefined') {
    window.location.assign(`/`);
  } else {
    return function(dispatch) {
      fetch(`${keys.baseUri}/api/${userType}/traveler_profiles/${id}/get_header`, {
        headers: token,
      })
        .then(res => res.json())
        .then(data =>
          dispatch({
            type: FETCH_TRAVELER_HEADER,
            payload: data,
          }),
        )
        .catch(error => console.error(error));
    };
  }
}

export function fetchTravelerDetails(id, userType) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  if (!id || typeof id === 'undefined') {
    window.location.assign(`/`);
  } else {
    return function(dispatch) {
      fetch(`${keys.baseUri}/api/${userType}/traveler_profiles/${id}/get_traveler_details`, {
        headers: token,
      }).then(response => {
        if (response.status == 401) {
          response.json().then(data => {
            dispatch({
              type: 'ADD_ALERT',
              text: data.message,
              style: 'danger',
            });
            window.location.assign('/client/traveler-dashboard');
          });
        } else {
          response.json().then(data => {
            dispatch({
              type: FETCH_TRAVELER_DETAILS,
              payload: data,
            });
          });
        }
      });
    };
  }
}

export function fetchTravelerPreferences(id, userType) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  if (!id || typeof id === 'undefined') {
    window.location.assign(`/`);
  } else {
    return function(dispatch) {
      fetch(`${keys.baseUri}/api/${userType}/traveler_profiles/${id}/get_traveler_preference.json`, { headers: token })
        .then(res => res.json())
        .then(data =>
          dispatch({
            type: FETCH_TRAVELER_PREFERENCES,
            payload: data,
          }),
        );
    };
  }
}

export function fetchTravelerNotes(id) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/traveler_profiles/${id}/get_notes`, {
      headers: token,
    })
      .then(res => res.json())
      .then(response => {
        return response;
      })
      .then(data =>
        dispatch({
          type: FETCH_TRAVELER_NOTES,
          payload: data,
        }),
      );
  };
}

export function fetchTravelerMessages(id, userType) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  if (!id || typeof id === 'undefined') {
    window.location.assign(`/`);
  } else {
    return function(dispatch) {
      dispatch({
        type: FETCH_TRAVELER_MESSAGES_REQUEST,
      });

      fetch(`${keys.baseUri}/api/${userType}/traveler_profiles/${id}/get_messages`, {
        headers: token,
      })
        .then(res => res.json())
        .then(data =>
          dispatch({
            type: FETCH_TRAVELER_MESSAGES,
            payload: data,
          }),
        )
        .catch(error => {
          dispatch({
            type: FETCH_TRAVELER_MESSAGES_FAILURE,
            payload: error.message,
          });
        });
    };
  }
}

export function createTravelerMessage(id, role, data, callback = null) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  const formPayLoad = new FormData();
  formPayLoad.append('message[subject]', data.subject);
  formPayLoad.append('message[body]', data.body);
  formPayLoad.append('message[attachment_count]', data.attachmentCount);

  data.attachments.forEach((attachment, index) => {
    formPayLoad.append(`message[attachment${index}]`, attachment);
  });

  let messageToken = _clone(token);
  delete messageToken['Accept'];
  delete messageToken['Content-Type'];

  return function(dispatch) {
    dispatch({
      type: CREATE_TRAVELER_MESSAGE_LOADING,
      payload: true,
    });

    if (role == 'traveler') {
      fetch(`${keys.baseUri}/api/traveler/traveler_profiles/${id}/add_message`, {
        method: 'POST',
        headers: messageToken,
        body: formPayLoad,
      })
        .then(res => res.json())
        .then(data => {
          dispatch({
            type: CREATE_TRAVELER_MESSAGE_LOADING,
            payload: false,
          });

          dispatch({
            type: CREATE_TRAVELER_MESSAGE,
            payload: data,
          });

          if (callback && typeof callback === 'function') {
            callback(true, 'Message created');
          }
        })
        .catch(error => {
          dispatch({
            type: CREATE_TRAVELER_MESSAGE_LOADING,
            payload: false,
          });

          if (callback && typeof callback === 'function') {
            callback(false, error.message);
          }
        });
    } else {
      fetch(`${keys.baseUri}/api/client/traveler_profiles/${id}/add_message`, {
        method: 'POST',
        headers: messageToken,
        body: formPayLoad,
      })
        .then(res => res.json())
        .then(data => {
          dispatch({
            type: CREATE_TRAVELER_MESSAGE,
            payload: data,
          });

          dispatch({
            type: CREATE_TRAVELER_MESSAGE_LOADING,
            payload: false,
          });

          if (callback && typeof callback === 'function') {
            callback(true, 'Message created');
          }
        })
        .catch(error => {
          dispatch({
            type: CREATE_TRAVELER_MESSAGE_LOADING,
            payload: false,
          });

          if (callback && typeof callback === 'function') {
            if (error.name === "TypeError") {
              callback(false, "File size is too large. Please compress or reduce file size and try again.");
            } else {
              callback(false, error.message);
            }
          }
        });
    }
  };
}

export function checkTravelerMessage(id, message_id, role) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    if (role == 'traveler') {
      fetch(`${keys.baseUri}/api/traveler/traveler_profiles/${id}/check_message/${message_id}`, {
        method: 'PUT',
        headers: token,
      })
        .then(res => res.json())
        .then(data =>
          dispatch({
            type: CHECK_TRAVELER_MESSAGE,
            success: true,
            payload: data,
          }),
        );
    } else {
      fetch(`${keys.baseUri}/api/client/traveler_profiles/${id}/check_message/${message_id}`, {
        method: 'PUT',
        headers: token,
      })
        .then(res => res.json())
        .then(data =>
          dispatch({
            type: CHECK_TRAVELER_MESSAGE,
            success: true,
            payload: data,
          }),
        );
    }
  };
}

export function fetchAssignedAdmins(id) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/traveler_profiles/${id}/get_assigned_admins`, {
      headers: token,
    })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: FETCH_ASSIGNED_ADMINS,
          payload: data,
        }),
      );
  };
}

export function updateAssignedAdmins(id, data, callback = null) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/traveler_profiles/${id}/update_assigned_admins`, {
      method: 'POST',
      headers: token,
      body: JSON.stringify({
        assigned_admin_ids: data,
      }),
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: UPDATE_ASSIGNED_ADMINS,
          payload: data,
        });

        if (callback && typeof callback === 'function') {
          callback(true, data);
        }
      });
  };
}

export function createTravelerNote(id, data) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  const formPayLoad = new FormData();
  formPayLoad.append('note[subject]', data.subject);
  formPayLoad.append('note[body]', data.body);
  formPayLoad.append('note[attachment_count]', data.attachmentCount);

  data.attachments.forEach((attachment, index) => {
    formPayLoad.append(`note[attachment${index}]`, attachment);
  });

  let noteToken = _clone(token);
  delete noteToken['Accept'];
  delete noteToken['Content-Type'];

  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/traveler_profiles/${id}/add_note`, {
      method: 'POST',
      headers: noteToken,
      body: formPayLoad,
    })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: CREATE_TRAVELER_NOTE,
          payload: data,
        }),
      )
      .then(() => {
        dispatch(fetchTravelerNotes(id));
      });
  };
}

export function fetchNewMessages(id) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/users/${id}/new_client_messages`, {
      headers: token,
    })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: FETCH_NEW_MESSAGES,
          payload: data,
        }),
      );
  };
}

export function fetchNewMessagesTraveler(id) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/traveler/users/${id}/new_messages`, {
      headers: token,
    })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: FETCH_NEW_MESSAGES_TRAVELER,
          payload: data,
        }),
      );
  };
}

export function markAsRead(msgId, userId) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/users/${userId}/mark_as_read`, {
      method: 'POST',
      headers: token,
      body: JSON.stringify({
        message_id: msgId,
        user_id: userId,
      }),
    })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: FETCH_NEW_MESSAGES,
          payload: data,
        }),
      );
  };
}

export function markAsReadTraveler(msgId, userId) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/traveler/users/${userId}/mark_as_read`, {
      method: 'POST',
      headers: token,
      body: JSON.stringify({
        message_id: msgId,
        user_id: userId,
      }),
    })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: FETCH_NEW_MESSAGES_TRAVELER,
          payload: data,
        }),
      );
  };
}

export function markAllAsReadTraveler(userId, callback = null) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({
      type: MARK_ALL_READ_MESSAGE_REQUEST,
    });

    fetch(`${keys.baseUri}/api/traveler/users/${userId}/mark_all_as_read`, {
      method: 'POST',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: MARK_ALL_READ_MESSAGE_SUCCESS,
        });

        if (callback && typeof callback === 'function') {
          callback(true, 'Read successfully');
        }
      })
      .catch(error => {
        dispatch({
          type: MARK_ALL_READ_MESSAGE_FAILURE,
          payload: error.message,
        });
      });
  };
}

export function selectTravelerProfileTab(tab) {
  return {
    type: SELECT_TRAVELER_PROFILE_TAB,
    tab,
  };
}

export function selectMessageTab(tab) {
  return {
    type: SELECT_MESSAGE_TAB,
    tab,
  };
}

export function resetProfileCode() {
  return {
    type: RESET_PROFILE_CODE,
  };
}

export function resetMessageCreateCode() {
  return {
    type: RESET_MESSAGE_CREATE_CODE,
  };
}

export function attachFormToIndividual(data) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  let trmSubmission = {
    trm_submission_id: data.trmSubmissionId,
    trm_template_id: data.trmTemplateId,
  };
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/trm_submissions.json`, {
      method: 'POST',
      headers: token,
      body: JSON.stringify(trmSubmission),
    }).then(response => {
      response.json().then(res => {
        dispatch({
          type: ADD_NEW_FORM,
          payload: res,
        });
      });
    });
  };
}

export function manualSyncTraveler(user_id) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/traveler_profiles/${user_id}/sync_user`, {
      method: 'POST',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: MANUAL_SYNC_TRAVELER,
          payload: data,
        });
      });
  };
}

export function clearManualSyncTraveler() {
  return {
    type: CLEAR_MANUAL_SYNC_TRAVELER,
  };
}

export function deleteTraveler(id) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({ type: DELETE_TRAVELER_REQUEST });
    fetch(`${keys.baseUri}/api/client/traveler_profiles/${id}`, {
      method: 'DELETE',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: DELETE_TRAVELER_SUCCESS,
          payload: data,
        });
        dispatch({
          type: DELETE_TRAVELER_CLEAR,
        });
      })
      .catch(error => {
        dispatch({
          type: DELETE_TRAVELER_FAILURE,
          payload: error.toString(),
        });
      });
  };
}

export function fetchTermPolicy() {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({
      type: FETCH_TERM_POLICY_REQUEST,
    });
    fetch(`${keys.baseUri}/api/term_policy/term_policies`, {
      headers: token,
    }).then(response => {
      if (response.status == 401 || response.status == 403) {
        window.location.assign(`/clear_cookies`);
        dispatch({
          type: FETCH_TERM_POLICY_FAILURE,
          payload: 'Something went wrong',
        });
      } else {
        response.json().then(data => {
          let policyData = data?.data;

          if (policyData.length > 0) {
            policyData = policyData[policyData.length - 1];

            dispatch({
              type: FETCH_TERM_POLICY_SUCCESS,
              payload: {
                id: policyData.id,
                type: policyData.type,
                term_of_services: policyData?.attributes?.term_of_services,
                client_account_id: policyData?.attributes?.client_account_id,
                privacy_policy: policyData?.attributes?.privacy_policy,
              },
            });
          } else {
            dispatch({
              type: FETCH_TERM_POLICY_SUCCESS,
              payload: {
                id: '',
                type: '',
                term_of_services: '',
                client_account_id: '',
                privacy_policy: '',
              },
            });
          }
        });
      }
    });
  };
}

export function acceptTermsPolicy(callback = null) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({
      type: ACCEPT_TERM_REQUEST,
    });
    fetch(`${keys.baseUri}/api/users/accept_terms_and_policy?user[tap_agreement]=true`, {
      headers: token,
      method: 'PATCH',
    }).then(response => {
      if (response.status == 401 || response.status == 403) {
        window.location.assign(`/clear_cookies`);
        dispatch({
          type: ACCEPT_TERM_FAILURE,
          payload: 'Something went wrong',
        });

        if (callback && typeof callback === 'function') {
          callback(false, 'Something went wrong');
        }
      } else if (response.status === 400) {
        dispatch({
          type: ACCEPT_TERM_FAILURE,
          payload: 'Something went wrong, please try after sometime',
        });

        if (callback && typeof callback === 'function') {
          callback(false, 'Something went wrong');
        }
      } else {
        response.json().then(data => {
          dispatch({
            type: ACCEPT_TERM_SUCCESS,
          });

          if (callback && typeof callback === 'function') {
            callback(true, 'Terms accepted');
          }
        });
      }
    });
  };
}
