import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'recompose';
import { Formik, Form, Field } from 'formik';
import { updateFormStatuses, getFormStatusOptions } from '../../../../../actions/reportsActions';
import propTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import ViaModal from '../../../../components/library/viaModal';
import Button from '../../../../components/library/button';
import InputSelect from '../../../../components/library/inputSelect';
import Paragraph from '../../../../components/library/paragraph';
import ToastMessage from '../../toastMessage';
import sBatchUpdateFormStatus from './batchUpdateFormStatus.scss';

export const enhance = compose(withStyles(sBatchUpdateFormStatus));

function BatchUpdateFormStatus(props) {
  const dispatch = useDispatch();

  const [submitValues, setSubmitValues] = useState();
  const [showConfirm, setShowConfirm] = useState(false);
  const formStatus = useSelector(state => state.reports.updateFormStatus);
  const statusOptions = useSelector(state => state.reports.fetchFormStatusOptions);
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);
  const [saving, setSaving] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [failureMessage, setFailureMessage] = useState('');
  const [formStatusOptions, setFormStatusOptions] = useState(null);

  const resetForm = () => {
    setDefaultValues(() => {
      let values = {
        status: '',
      };
      return values;
    });
  };

  const [defaultValues, setDefaultValues] = useState(() => {
    let values = {
      status: '',
    };
    return values;
  });

  useEffect(() => {
    dispatch(getFormStatusOptions());
  }, []);

  useEffect(() => {
    if (statusOptions?.data && !statusOptions?.error && !statusOptions.loading) {
      const options = statusOptions?.data.map(obj => ({
        value: obj.id,
        label: obj.attributes.name,
      }));

      setFormStatusOptions(options);
    }
  }, [statusOptions]);

  useEffect(() => {
    if (formStatus) {
      if (formStatus.loading) {
        setSaving(true);
      }

      if (!formStatus.loading) {
        if (formStatus.data) {
          setSaving(false);
          setSuccessMessage(formStatus.data);
          setSuccess(true);
        }
        if (formStatus.error) {
          setSaving(false);
          setFailureMessage(formStatus.error);
          setFailure(true);
        }
      }
    }
    resetForm();
    props.onClose(false);
  }, [formStatus]);

  return (
    <>
      <ViaModal
        showing={props.show}
        width="700px"
        onClose={() => {
          props.onClose(false);
        }}
        headerText="Update Status"
      >
        <div className={sBatchUpdateFormStatus['batch-update-form-status']}>
          <Formik
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={defaultValues}
            validate={(values, index) => {
              const errors = {};
              if (values.status === '') {
                errors.status = 'Status required';
              }

              return errors;
            }}
            onSubmit={values => {
              const submitValues = {
                submission_ids: props?.formIds,
                submission_status_id: values?.status?.value,
                type: 'form',
              };

              setSubmitValues(submitValues);
              props.onClose(false);
              setShowConfirm(true);
            }}
          >
            {({ values, handleSubmit, errors }) => (
              <Form>
                <div className={sBatchUpdateFormStatus.row}>
                  <div className={sBatchUpdateFormStatus.item}>
                    <Field name="status">
                      {({ form }) => (
                        <InputSelect
                          id={values.status}
                          name={values.status}
                          labelText="General Form Status"
                          aria-label="General Form Status"
                          errorMessage={errors.status}
                          isRequired
                          helpText=""
                          isMulti={false}
                          styles={{
                            menu: provided => ({ ...provided, zIndex: 99999 }),
                            placeholder: provided => ({ ...provided, color: '#767676', fontStyle: 'italic' }),
                          }}
                          value={values.status}
                          options={formStatusOptions}
                          onChange={e => form.setFieldValue('status', e)}
                        />
                      )}
                    </Field>
                  </div>
                </div>

                <div className={sBatchUpdateFormStatus.footer}>
                  <div className={sBatchUpdateFormStatus['button-content']}>
                    <Button
                      display="secondary"
                      kind="solid"
                      size="medium"
                      aria-label="Cancel Button"
                      onClick={() => {
                        props.onClose(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                  <div className={sBatchUpdateFormStatus['button-content']}>
                    <Button
                      display="primary"
                      kind="solid"
                      size="medium"
                      type="submit"
                      aria-label="Update General Form Status Button"
                    >
                      Update
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </ViaModal>
      <ViaModal
        headerText="Update Status"
        showing={showConfirm}
        width="700px"
        onClose={() => {
          resetForm();
          setShowConfirm(false);
        }}
      >
        <div className={sBatchUpdateFormStatus['batch-update-form-status']}>
          <div className={sBatchUpdateFormStatus.row}>
            <Paragraph size="normal">
              {`You are about to update the status of ${props.userIds.length} ${
                props.userIds.length === 1 ? 'form' : 'forms'
              }. ${
                props.customAliases.alias_travelers
              }, ${props.customAliases.alias_program.toLowerCase()} contact and default message recipients will receive email notifications of this change.`}
            </Paragraph>
          </div>

          <div className={sBatchUpdateFormStatus.row}>
            <Paragraph size="normal">Ready to update?</Paragraph>
          </div>
          <div className={sBatchUpdateFormStatus.footer}>
            <div className={sBatchUpdateFormStatus['button-content']}>
              <Button
                display="secondary"
                kind="solid"
                size="medium"
                onClick={() => {
                  setShowConfirm(false);
                }}
              >
                Cancel
              </Button>
            </div>
            <div className={sBatchUpdateFormStatus['button-content']}>
              <Button
                display="primary"
                kind="solid"
                size="medium"
                type="submit"
                onClick={() => {
                  dispatch(
                    updateFormStatuses(submitValues, props.selectedTemplateId, props.dayOffset, props.fetchApps),
                  );
                  props.setSelectedPrograms();
                  setShowConfirm(false);
                }}
              >
                Update
              </Button>
            </div>
          </div>
        </div>
      </ViaModal>

      {success ? (
        <ToastMessage
          message={successMessage}
          show={success}
          isSuccess
          newestOnTop={false}
          onClose={() => {
            setSuccess(false);
          }}
        />
      ) : null}
      {failure ? (
        <ToastMessage
          message={failureMessage}
          isSuccess={false}
          show={failure}
          newestOnTop={false}
          onClose={() => {
            setFailure(false);
          }}
        />
      ) : null}
    </>
  );
}

BatchUpdateFormStatus.propTypes = {
  show: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
};

BatchUpdateFormStatus.defaultProps = {
  fetchApps: true,
};

export default enhance(BatchUpdateFormStatus);
