import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import propTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import sFilterCheckBox from './filterCheckBox.scss';

export const enhance = compose(
  withStyles(sFilterCheckBox),
);

function FilterCheckBox(props) {
  const [filterOptions, setFilterOptions] = useState();
  const [internalOptions, setInternalOptions] = useState([]);

  const buildAndSetOptions = (selected) => {
    let buildOptions = [];
    if (props.sourceData) {
      props.sourceData.forEach(item => {
        buildOptions.push({...item, selected: selected.includes(item.value)});
      });

      setInternalOptions(buildOptions);
    }
  };

  const changeValue = (value) => {
    const i = filterOptions.findIndex(item => item.value === value.target.value);
    filterOptions[i].selected = value.target.checked;

    let filterValues = [];
    if (filterOptions && filterOptions.length > 0) {
      filterOptions.forEach((item) => {
        if (item.selected) {
          filterValues.push(item.value);
        }
      });
    }

    buildAndSetOptions(filterValues);

    props.change(filterValues);
  }

  useEffect(() => {
    buildAndSetOptions(props.selectedValues);
  }, [props]);

  useEffect(() => {
    if (internalOptions && internalOptions.length > 0) {
      setFilterOptions(internalOptions)
    }
  }, [internalOptions]);

  return (
    <>
      {!!filterOptions && filterOptions.length > 0 && filterOptions.map((opt) => {
        return (
          <label
            className={sFilterCheckBox.container}
            htmlFor={opt.value}
            key={opt.value}
          >
            <input
              type="checkbox"
              name={opt.value}
              value={opt.value}
              aria-label={opt.value}
              checked={opt.selected}
              onChange={(e) => changeValue(e)}
            />
            <span className={sFilterCheckBox.checkmark} />
            <span className={sFilterCheckBox.label}>{opt.label}</span>
          </label>
        );
      })}
    </>
  );
}

FilterCheckBox.propTypes = {
  id: propTypes.string.isRequired,
  sourceData: propTypes.array.isRequired,
  selectedValues: propTypes.array,
  change: propTypes.func.isRequired,
};

FilterCheckBox.defaultProps = {
  labelText: 'Filter Options',
  selectedValues: [],
};

export default enhance(FilterCheckBox);
