import React from 'react';

export default function TeacherIcon() {
  return (
    <svg width="34" height="36" viewBox="0 0 34 36" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
      <path
        d="M6.586 30.6055H2.4629C1.30162 30.6055 0.351562 31.5556 0.351562 32.7168V35.2214C0.351562 35.4572 0.542812 35.6484 0.778639 35.6484H8.27026C8.50609 35.6484 8.69734 35.4573 8.69734 35.2214V32.7168C8.69734 31.5556 7.74728 30.6055 6.586 30.6055Z"
        fill="#6DA6C2"
      />
      <path
        d="M14.9316 30.6055H10.8086C9.64732 30.6055 8.69727 31.5556 8.69727 32.7168V35.2214C8.69727 35.4572 8.88852 35.6484 9.12434 35.6484H16.616C16.8518 35.6484 17.043 35.4573 17.043 35.2214V32.7168C17.0429 31.5556 16.0928 30.6055 14.9316 30.6055Z"
        fill="#5A869B"
      />
      <path
        d="M23.2774 30.6055H19.1543C17.993 30.6055 17.043 31.5556 17.043 32.7168V35.2214C17.043 35.4572 17.2342 35.6484 17.47 35.6484H24.9617C25.1975 35.6484 25.3887 35.4573 25.3887 35.2214V32.7168C25.3887 31.5556 24.4387 30.6055 23.2774 30.6055Z"
        fill="#FBC56D"
      />
      <path
        d="M18.7955 27.6846C18.7955 26.7961 19.2757 26.0216 19.9894 25.6005C19.7195 25.4747 19.4204 25.4024 19.1044 25.4024H14.9815C14.6655 25.4024 14.3662 25.4747 14.0965 25.6005C14.8102 26.0216 15.2904 26.796 15.2904 27.6846C15.2904 29.0212 14.2069 30.1047 12.8701 30.1047V30.6055H14.9317C16.0929 30.6055 17.043 31.5555 17.043 32.7168C17.043 31.5555 17.9931 30.6055 19.1543 30.6055H21.2159V30.1047C19.879 30.1049 18.7955 29.0212 18.7955 27.6846Z"
        fill="#71AD81"
      />
      <path
        d="M10.4499 27.6846C10.4499 26.7961 10.9301 26.0216 11.6438 25.6005C11.3739 25.4747 11.0747 25.4024 10.7589 25.4024H6.63575C6.31977 25.4024 6.02052 25.4747 5.7508 25.6005C6.46447 26.0216 6.9447 26.796 6.9447 27.6846C6.9447 29.0212 5.86119 30.1047 4.52441 30.1047V30.6055H6.58597C7.74724 30.6055 8.6973 31.5555 8.6973 32.7168C8.6973 31.5555 9.64736 30.6055 10.8086 30.6055H12.8702V30.1047C11.5334 30.1049 10.4499 29.0212 10.4499 27.6846Z"
        fill="#FF7B9E"
      />
      <path
        d="M4.52478 25.2642C3.18814 25.2642 2.10449 26.3479 2.10449 27.6845C2.10449 29.0211 3.188 30.1046 4.52478 30.1046C5.86155 30.1046 6.94507 29.021 6.94507 27.6845C6.94507 26.3479 5.86141 25.2642 4.52478 25.2642Z"
        fill="#F9D0B4"
      />
      <path
        d="M12.8695 25.2642C11.5329 25.2642 10.4492 26.3479 10.4492 27.6845C10.4492 29.0211 11.5329 30.1046 12.8695 30.1046C14.2061 30.1046 15.2898 29.021 15.2898 27.6845C15.2898 26.3479 14.2061 25.2642 12.8695 25.2642Z"
        fill="#F9D0B4"
      />
      <path
        d="M17.0433 20.0611C15.7067 20.0611 14.623 21.1447 14.623 22.4814C14.623 23.818 15.7067 24.9015 17.0433 24.9015C18.38 24.9015 19.4636 23.8179 19.4636 22.4814C19.4636 21.1447 18.38 20.0611 17.0433 20.0611Z"
        fill="#F9D0B4"
      />
      <path
        d="M8.69763 20.0611C7.361 20.0611 6.27734 21.1447 6.27734 22.4814C6.27734 23.818 7.361 24.9015 8.69763 24.9015C10.0343 24.9015 11.1179 23.8179 11.1179 22.4814C11.1178 21.1447 10.0343 20.0611 8.69763 20.0611Z"
        fill="#F9D0B4"
      />
      <path
        d="M21.2162 25.2642C19.8796 25.2642 18.7959 26.3479 18.7959 27.6845C18.7959 29.0211 19.8794 30.1046 21.2162 30.1046C22.5528 30.1046 23.6365 29.021 23.6365 27.6845C23.6365 26.3479 22.553 25.2642 21.2162 25.2642Z"
        fill="#F9D0B4"
      />
      <path
        d="M17.5545 7.10156H16.2717C15.8124 7.10156 15.4365 7.47731 15.4365 7.93673V9.21951C15.4365 9.67893 15.8123 10.0547 16.2717 10.0547H17.5545C18.0137 10.0547 18.3896 9.67893 18.3896 9.21951V7.93673C18.3896 7.47731 18.0139 7.10156 17.5545 7.10156Z"
        fill="#F9D0B4"
      />
      <path
        d="M32.4154 15.333C32.3847 14.7825 31.9997 14.3161 31.4652 14.1815L29.7369 13.7464C28.8209 13.5158 28.1006 13.1208 28.1099 13.1254C27.7333 12.9398 27.4948 12.5563 27.4948 12.1364V11.617C27.0539 11.8477 26.5539 11.9792 26.0241 11.9792C25.4945 11.9792 24.9943 11.8477 24.5536 11.617V12.1388C24.5536 12.5594 24.3136 12.943 23.9354 13.127C23.0975 13.5346 22.1778 13.7464 21.246 13.7464H18.4173C18.2662 13.7464 18.1437 13.6238 18.1437 13.4728V9.80993C17.9924 9.96096 17.784 10.0546 17.5546 10.0546H16.2717C16.0759 10.0546 15.8958 9.98599 15.7529 9.87209V13.9465C15.7529 15.1386 16.7159 16.1067 17.9079 16.1131L20.5721 16.1342C21.1741 16.1375 21.6604 16.6264 21.6604 17.2283V21.3697C21.6604 21.6009 21.8479 21.7883 22.0791 21.7883H32.4183C32.6194 21.7883 32.7792 21.6194 32.7679 21.4188L32.4154 15.333Z"
        fill="#D74B73"
      />
      <path
        d="M20.572 2.70451L17.6162 1.22668V4.82034L20.572 3.34238C20.8348 3.21089 20.8348 2.83599 20.572 2.70451Z"
        fill="#D74A73"
      />
      <path
        d="M16.9131 0.351562C16.5247 0.351562 16.21 0.66628 16.21 1.05468V7.10463C16.2304 7.10308 16.2509 7.10153 16.2717 7.10153H17.5546C17.5754 7.10153 17.5958 7.10322 17.6163 7.10463V1.05468C17.6162 0.66628 17.3015 0.351562 16.9131 0.351562Z"
        fill="#D8A380"
      />
      <path
        d="M17.5546 10.0547H16.2717C16.2509 10.0547 16.2305 10.053 16.21 10.0516V10.8985C16.21 11.2869 16.5247 11.6016 16.9131 11.6016C17.3015 11.6016 17.6162 11.2869 17.6162 10.8985V10.0516C17.5958 10.053 17.5754 10.0547 17.5546 10.0547Z"
        fill="#D8A380"
      />
      <path
        d="M28.1095 13.1253C27.7329 12.9397 27.4944 12.5562 27.4944 12.1363V11.6168C27.0535 11.8476 26.5535 11.9791 26.0237 11.9791C25.4941 11.9791 24.9939 11.8476 24.5532 11.6168V12.1387C24.5532 12.5593 24.3132 12.9429 23.935 13.1269C23.8924 13.1475 23.8487 13.1655 23.8057 13.1854C24.3811 13.7432 25.1638 14.0885 26.0237 14.0885C26.8808 14.0885 27.6613 13.7455 28.2363 13.1907C28.1526 13.1492 28.1071 13.1242 28.1095 13.1253Z"
        fill="#F9D0B4"
      />
      <path
        d="M27.3288 16.8932H24.7187C24.5197 16.8932 24.3584 17.0545 24.3584 17.2537V20.0708C24.3584 20.2699 24.5197 20.4312 24.7187 20.4312H27.3288C27.5278 20.4312 27.6891 20.2699 27.6891 20.0708V17.2537C27.6891 17.0545 27.5278 16.8932 27.3288 16.8932Z"
        fill="#EDF4FC"
      />
      <path
        d="M27.3288 16.8932H24.7187C24.5197 16.8932 24.3584 17.0545 24.3584 17.2537V18.3515H27.6892V17.2537C27.6891 17.0545 27.5278 16.8932 27.3288 16.8932Z"
        fill="#6DA6C2"
      />
      <path
        d="M4.59335 20.8408C4.15742 20.7564 3.80585 20.4189 3.72148 19.9829C3.72148 19.9689 3.67929 19.9689 3.67929 19.9829C3.59492 20.4189 3.24336 20.7564 2.80742 20.8408C2.79336 20.8408 2.79336 20.8689 2.80742 20.8829C3.24336 20.9673 3.59492 21.3048 3.67929 21.7407C3.67929 21.7548 3.72148 21.7548 3.72148 21.7407C3.80585 21.3048 4.15742 20.9673 4.59335 20.8829C4.60741 20.8689 4.60741 20.8408 4.59335 20.8408Z"
        fill="#71AD81"
      />
      <path
        d="M29.7792 28.3359H29.4417V28.0125C29.4417 27.7594 29.2448 27.5625 28.9917 27.5625C28.7527 27.5625 28.5417 27.7594 28.5417 27.9984V28.3359H28.2183C27.9652 28.3359 27.7683 28.5328 27.7683 28.7859C27.7542 29.025 27.9511 29.2359 28.2183 29.2359H28.5417V29.5594C28.5417 29.8125 28.7386 30.0094 28.9917 30.0094C29.2308 30.0094 29.4417 29.8125 29.4417 29.5734V29.2359H29.7652C30.0183 29.2359 30.2152 29.039 30.2152 28.7859C30.2152 28.5469 30.0183 28.3359 29.7792 28.3359Z"
        fill="#6DA6C2"
      />
      <path
        d="M27.7115 32.2031C27.4474 32.2031 27.2334 32.4171 27.2334 32.6812C27.2334 32.9453 27.4474 33.1594 27.7115 33.1594C27.9756 33.1594 28.1896 32.9453 28.1896 32.6812C28.1896 32.4171 27.9756 32.2031 27.7115 32.2031Z"
        fill="#FBC56D"
      />
      <path
        d="M13.3678 0C13.1037 0 12.8896 0.21403 12.8896 0.478123C12.8896 0.742216 13.1037 0.956246 13.3678 0.956246C13.6319 0.956246 13.8459 0.742216 13.8459 0.478123C13.8459 0.21403 13.6319 0 13.3678 0Z"
        fill="#D74A73"
      />
      <path
        d="M12.3275 3.4733C11.6947 3.3608 11.2025 2.86862 11.076 2.24987C11.076 2.22174 11.0197 2.22174 11.0197 2.24987C10.8932 2.86862 10.401 3.3608 9.76816 3.4733C9.74004 3.4733 9.74004 3.51549 9.76816 3.52955C10.401 3.64205 10.8932 4.13424 11.0197 4.75298C11.0197 4.78111 11.076 4.78111 11.076 4.75298C11.2025 4.13424 11.6947 3.64205 12.3275 3.52955C12.3557 3.51549 12.3557 3.4733 12.3275 3.4733Z"
        fill="#FBC56D"
      />
      <path
        d="M28.7601 4.96913C28.5514 4.75018 28.212 4.73007 27.9741 4.91682C27.5979 5.21213 26.947 5.57705 26.0276 5.57705C25.1059 5.57705 24.4516 5.2103 24.0739 4.91471C23.8362 4.72866 23.4975 4.74947 23.2892 4.968L23.2881 4.96913C22.9954 5.27611 22.832 5.68392 22.832 6.10819V8.78708C22.832 10.5426 24.2684 11.9791 26.0241 11.9791C27.7796 11.9791 29.2161 10.5426 29.2161 8.78708V6.10819C29.216 5.68392 29.0527 5.27611 28.7601 4.96913Z"
        fill="#F9D0B4"
      />
      <path
        d="M29.4209 6.35445C29.3512 6.35445 29.2828 6.36035 29.216 6.37062V8.78711C29.216 8.85911 29.2128 8.9304 29.208 9.00114C29.2775 9.01239 29.3482 9.0197 29.4209 9.0197C30.1569 9.0197 30.7536 8.42303 30.7536 7.687C30.7536 6.95097 30.1569 6.35445 29.4209 6.35445Z"
        fill="#F9D0B4"
      />
      <path
        d="M22.8315 8.78711V6.37062C22.7647 6.36021 22.6964 6.35445 22.6266 6.35445C21.8906 6.35445 21.2939 6.95112 21.2939 7.68714C21.2939 8.42317 21.8906 9.01984 22.6266 9.01984C22.6993 9.01984 22.7701 9.01239 22.8395 9.00128C22.8349 8.9304 22.8315 8.85911 22.8315 8.78711Z"
        fill="#F9D0B4"
      />
      <path
        d="M29.204 3.63143C29.0971 3.63143 29.0044 3.54903 29.0029 3.44215C28.9784 1.66353 26.9472 1.32941 24.9245 1.67858C23.2096 1.97474 21.9561 3.46184 21.9561 5.20221V6.53673C22.1532 6.42142 22.382 6.35434 22.627 6.35434C22.6967 6.35434 22.7651 6.36025 22.8319 6.37051V6.10811C22.8319 5.68399 22.9951 5.27603 23.2879 4.96905L23.289 4.96793C23.4973 4.7494 23.8361 4.72858 24.0737 4.91463C24.4516 5.21022 25.1059 5.57697 26.0274 5.57697C26.9468 5.57697 27.5978 5.21191 27.9739 4.91674C28.2119 4.72999 28.5512 4.7501 28.7599 4.96905C29.0527 5.27603 29.2159 5.68385 29.2159 6.10811V6.37051C29.2827 6.36011 29.3511 6.35434 29.4208 6.35434C29.6687 6.35434 29.8999 6.42325 30.0988 6.54109C30.0988 6.16872 30.0988 5.78608 30.0988 5.44535C30.0986 3.85263 29.5235 3.63143 29.204 3.63143Z"
        fill="#5B5D60"
      />
      <path
        d="M29.0146 4.72652C28.681 4.37693 28.1405 4.33952 27.757 4.64046C27.4169 4.90736 26.8409 5.2256 26.0276 5.2256C25.2124 5.2256 24.633 4.9061 24.2904 4.63793C23.9075 4.3384 23.3677 4.37594 23.0335 4.72652C22.6769 5.10058 22.4805 5.59122 22.4805 6.10816V8.78705C22.4805 10.741 24.0702 12.3306 26.0241 12.3306C27.9779 12.3306 29.5675 10.7409 29.5675 8.78705V6.10816C29.5677 5.59136 29.3713 5.10058 29.0146 4.72652ZM28.8645 8.78705C28.8645 10.3533 27.5903 11.6275 26.0242 11.6275C24.4581 11.6275 23.1837 10.3532 23.1837 8.78705V6.10816C23.1837 5.77277 23.3111 5.45453 23.5437 5.21069C23.5883 5.16386 23.6484 5.13996 23.7094 5.13996C23.7609 5.13996 23.8132 5.15697 23.8573 5.19157C24.2871 5.5278 25.0123 5.92858 26.0279 5.92858C27.0422 5.92858 27.7644 5.52864 28.1914 5.19325C28.2881 5.11718 28.4235 5.12519 28.5059 5.21167C28.7374 5.45439 28.8648 5.77263 28.8648 6.10801V8.78705H28.8645Z"
        fill="#373737"
      />
      <path
        d="M29.4209 6.00288C29.3766 6.00288 29.3326 6.00485 29.2892 6.00823C29.0957 6.02384 28.9515 6.19343 28.967 6.38693C28.9825 6.58043 29.1519 6.72344 29.3457 6.7091C29.3705 6.70699 29.3955 6.70601 29.4208 6.70601C29.9618 6.70601 30.4019 7.14616 30.4019 7.68728C30.4019 8.22827 29.9618 8.66842 29.4208 8.66842C29.3934 8.66842 29.3664 8.66687 29.3398 8.66434C29.1446 8.64381 28.9742 8.78584 28.9549 8.97892C28.9355 9.17214 29.0764 9.34454 29.2695 9.36381C29.3194 9.36887 29.3698 9.37154 29.4208 9.37154C30.3495 9.37154 31.1051 8.61597 31.1051 7.68728C31.1052 6.75846 30.3496 6.00288 29.4209 6.00288Z"
        fill="#373737"
      />
      <path
        d="M23.1055 8.97482C23.0848 8.78203 22.9125 8.64337 22.7183 8.66292C22.6885 8.66601 22.6583 8.66798 22.6275 8.66798C22.0865 8.66798 21.6463 8.22783 21.6463 7.68684C21.6463 7.14572 22.0865 6.70557 22.6275 6.70557C22.6577 6.70557 22.6875 6.70725 22.7169 6.70993C22.9111 6.72779 23.0818 6.5866 23.1003 6.39338C23.1188 6.20002 22.9769 6.02846 22.7837 6.01004C22.7324 6.00512 22.6804 6.0023 22.6276 6.0023C21.6989 6.0023 20.9434 6.75788 20.9434 7.6867C20.9434 8.61539 21.6989 9.37096 22.6276 9.37096C22.6836 9.37096 22.7391 9.36773 22.7937 9.36182C22.9865 9.34129 23.1261 9.1679 23.1055 8.97482Z"
        fill="#373737"
      />
      <path
        d="M27.1613 9.3605C27.0126 9.23591 26.7909 9.25517 26.6661 9.40409C26.5395 9.55513 26.3114 9.64906 26.0708 9.64906C25.8302 9.64906 25.602 9.55513 25.4754 9.40409C25.3504 9.25503 25.1288 9.23577 24.9801 9.3605C24.8315 9.48524 24.8119 9.707 24.9365 9.85578C25.1972 10.1666 25.6212 10.352 26.0708 10.352C26.5204 10.352 26.9444 10.1666 27.2049 9.85578C27.3295 9.707 27.31 9.48538 27.1613 9.3605Z"
        fill="#373737"
      />
      <path
        d="M27.5109 7.4055C27.2741 7.4055 27.082 7.59745 27.082 7.83427C27.082 8.07122 27.274 8.26317 27.5109 8.26317C27.7479 8.26317 27.9398 8.07122 27.9398 7.83427C27.9398 7.59745 27.7477 7.4055 27.5109 7.4055Z"
        fill="#373737"
      />
      <path
        d="M24.6301 7.4055C24.3933 7.4055 24.2012 7.59745 24.2012 7.83427C24.2012 8.07122 24.3931 8.26317 24.6301 8.26317C24.867 8.26317 25.059 8.07122 25.059 7.83427C25.059 7.59745 24.8669 7.4055 24.6301 7.4055Z"
        fill="#373737"
      />
      <path
        d="M29.3483 3.28932C29.3072 2.70474 29.07 2.22873 28.6416 1.87309C27.6068 1.01373 25.8312 1.16561 24.8647 1.33225C22.9756 1.65836 21.6045 3.28608 21.6045 5.20237V6.46602C21.6045 6.66022 21.7619 6.81758 21.9561 6.81758C22.1503 6.81758 22.3076 6.66022 22.3076 6.46602V5.20237C22.3076 3.62907 23.4333 2.29299 24.9844 2.0251C26.4258 1.77606 27.5949 1.91781 28.1925 2.41393C28.4951 2.66523 28.6454 3.00315 28.6515 3.44682C28.6553 3.74255 28.9032 3.98302 29.2041 3.98302C29.5441 3.98302 29.7471 4.52976 29.7471 5.44537V6.48866C29.7471 6.68287 29.9044 6.84022 30.0986 6.84022C30.2928 6.84022 30.4502 6.68287 30.4502 6.48866V5.44537C30.45 3.74536 29.8144 3.35007 29.3483 3.28932Z"
        fill="#373737"
      />
      <path
        d="M30.2852 16.351C30.091 16.351 29.9336 16.5083 29.9336 16.7026V21.7207C29.9336 21.9149 30.091 22.0723 30.2852 22.0723C30.4794 22.0723 30.6367 21.9149 30.6367 21.7207V16.7026C30.6367 16.5085 30.4794 16.351 30.2852 16.351Z"
        fill="#373737"
      />
      <path
        d="M17.5543 6.75H16.2715C15.6172 6.75 15.085 7.28226 15.085 7.93659V9.2195C15.085 9.87383 15.6172 10.4061 16.2715 10.4061H17.5543C18.2087 10.4061 18.7411 9.87383 18.7411 9.2195V7.93659C18.7412 7.28226 18.2088 6.75 17.5543 6.75ZM18.0381 9.21965C18.0381 9.48627 17.8211 9.70311 17.5545 9.70311H16.2717C16.0051 9.70311 15.7882 9.48613 15.7882 9.21965V7.93673C15.7882 7.67011 16.0052 7.45326 16.2717 7.45326H17.5545C17.8211 7.45326 18.0381 7.67025 18.0381 7.93673V9.21965Z"
        fill="#373737"
      />
      <path
        d="M24.5534 11.3598C24.3591 11.3598 24.2018 11.5171 24.2018 11.7113V12.1387C24.2018 12.4228 24.0367 12.6864 23.7815 12.8106C22.9959 13.1927 22.1191 13.3946 21.2459 13.3946H18.4951V9.86171C18.4951 9.66751 18.3378 9.51015 18.1436 9.51015C17.9494 9.51015 17.792 9.66751 17.792 9.86171V13.4727C17.792 13.8175 18.0724 14.0979 18.4172 14.0979H21.2457C22.2249 14.0979 23.2081 13.8715 24.089 13.443C24.5846 13.2018 24.9049 12.6899 24.9049 12.1388V11.7115C24.9049 11.5171 24.7476 11.3598 24.5534 11.3598Z"
        fill="#373737"
      />
      <path
        d="M32.7658 15.3135C32.7263 14.6056 32.2378 14.0137 31.5502 13.8404L29.8221 13.4055C28.9876 13.1955 28.319 12.8392 28.2828 12.8196C28.2769 12.8164 28.2709 12.8132 28.2648 12.8102C28.0064 12.6828 27.8459 12.4246 27.8459 12.1365V11.7074C27.8459 11.5132 27.6886 11.3559 27.4944 11.3559C27.3002 11.3559 27.1428 11.5132 27.1428 11.7074V12.1365C27.1428 12.6879 27.4464 13.1824 27.9365 13.4321C28.0332 13.4927 28.1878 13.5633 28.4423 13.6731C28.7213 13.7936 29.1575 13.9633 29.6507 14.0875L31.3788 14.5225C31.7664 14.6199 32.0417 14.9536 32.0639 15.3534L32.4183 21.4368H22.0791C22.0421 21.4368 22.012 21.4067 22.012 21.3697V17.2285C22.012 16.4355 21.3669 15.7871 20.5749 15.7827L17.9098 15.7616C16.9143 15.7564 16.1045 14.9422 16.1045 13.9467V9.94105C16.1045 9.74685 15.9471 9.58949 15.7529 9.58949C15.5587 9.58949 15.4014 9.74685 15.4014 9.94105V13.9465C15.4014 15.3275 16.525 16.4572 17.9052 16.4645L20.5703 16.4856C20.9775 16.4878 21.3089 16.821 21.3089 17.2282V21.3695C21.3089 21.7941 21.6544 22.1397 22.0791 22.1397H32.4183C32.6103 22.1397 32.7962 22.0598 32.9281 21.9201C33.06 21.7805 33.1296 21.5907 33.1189 21.398L32.7658 15.3135Z"
        fill="#373737"
      />
      <path
        d="M28.3406 13.0052C28.1534 12.9566 27.9608 13.0688 27.9118 13.2571L26.9889 16.8049C26.9401 16.9928 27.0528 17.1847 27.2408 17.2337C27.2704 17.2414 27.3001 17.2451 27.3295 17.2451C27.4856 17.2451 27.6283 17.1401 27.6695 16.9818L28.5925 13.434C28.6413 13.2461 28.5286 13.0542 28.3406 13.0052Z"
        fill="#373737"
      />
      <path
        d="M25.0593 16.805L24.1363 13.2572C24.0874 13.0691 23.8952 12.9568 23.7076 13.0053C23.5196 13.0542 23.4069 13.2462 23.4557 13.4341L24.3786 16.9819C24.4198 17.1402 24.5626 17.2451 24.7187 17.2451C24.7481 17.2451 24.7777 17.2415 24.8074 17.2337C24.9954 17.1848 25.1081 16.9928 25.0593 16.805Z"
        fill="#373737"
      />
      <path
        d="M4.52478 24.9126C2.99647 24.9126 1.75293 26.1562 1.75293 27.6845C1.75293 29.2128 2.99647 30.4563 4.52478 30.4563C6.05308 30.4563 7.29662 29.2128 7.29662 27.6845C7.29662 26.1562 6.05308 24.9126 4.52478 24.9126ZM4.52478 29.7532C3.38417 29.7532 2.45605 28.8251 2.45605 27.6845C2.45605 26.5439 3.38417 25.6158 4.52478 25.6158C5.66538 25.6158 6.5935 26.5439 6.5935 27.6845C6.5935 28.8251 5.66538 29.7532 4.52478 29.7532Z"
        fill="#373737"
      />
      <path
        d="M6.586 30.2539H2.46304C1.10503 30.2539 0 31.3587 0 32.7168V35.2214C0 35.6507 0.349311 36 0.778637 36H8.27026C8.69959 36 9.0489 35.6507 9.0489 35.2214V32.7168C9.0489 31.3587 7.94401 30.2539 6.586 30.2539ZM8.34578 35.2212C8.34578 35.2628 8.31189 35.2967 8.27026 35.2967H0.778637C0.737012 35.2967 0.703122 35.2628 0.703122 35.2212V32.7167C0.703122 31.7464 1.49259 30.9569 2.46304 30.9569H6.586C7.55631 30.9569 8.34578 31.7463 8.34578 32.7167V35.2212Z"
        fill="#373737"
      />
      <path
        d="M6.9873 32.6199C6.7931 32.6199 6.63574 32.7772 6.63574 32.9714V35.4487C6.63574 35.6429 6.7931 35.8002 6.9873 35.8002C7.18151 35.8002 7.33886 35.6429 7.33886 35.4487V32.9714C7.33886 32.7772 7.18151 32.6199 6.9873 32.6199Z"
        fill="#373737"
      />
      <path
        d="M2.06152 32.6199C1.86732 32.6199 1.70996 32.7772 1.70996 32.9714V35.4487C1.70996 35.6429 1.86732 35.8002 2.06152 35.8002C2.25572 35.8002 2.41308 35.6429 2.41308 35.4487V32.9714C2.41308 32.7772 2.25572 32.6199 2.06152 32.6199Z"
        fill="#373737"
      />
      <path
        d="M12.8705 24.9126C11.3422 24.9126 10.0986 26.1562 10.0986 27.6845C10.0986 29.2128 11.3422 30.4563 12.8705 30.4563C14.3988 30.4563 15.6422 29.2128 15.6422 27.6845C15.6422 26.1562 14.3988 24.9126 12.8705 24.9126ZM12.8705 29.7532C11.7299 29.7532 10.8018 28.8251 10.8018 27.6845C10.8018 26.5439 11.7299 25.6158 12.8705 25.6158C14.0111 25.6158 14.9391 26.5439 14.9391 27.6845C14.9391 28.8251 14.0111 29.7532 12.8705 29.7532Z"
        fill="#373737"
      />
      <path
        d="M14.9316 30.2539H10.8086C9.45059 30.2539 8.3457 31.3587 8.3457 32.7168V35.2214C8.3457 35.6507 8.69487 36 9.1242 36H16.6158C17.0452 36 17.3943 35.6507 17.3943 35.2214V32.7168C17.3945 31.3587 16.2896 30.2539 14.9316 30.2539ZM16.6913 35.2212C16.6913 35.2628 16.6576 35.2967 16.616 35.2967H9.1242C9.08258 35.2967 9.04883 35.2628 9.04883 35.2212V32.7167C9.04883 31.7464 9.83815 30.9569 10.8086 30.9569H14.9316C15.9019 30.9569 16.6913 31.7463 16.6913 32.7167V35.2212Z"
        fill="#373737"
      />
      <path
        d="M15.333 32.6199C15.1388 32.6199 14.9814 32.7772 14.9814 32.9714V35.4487C14.9814 35.6429 15.1388 35.8002 15.333 35.8002C15.5272 35.8002 15.6846 35.6429 15.6846 35.4487V32.9714C15.6846 32.7772 15.5272 32.6199 15.333 32.6199Z"
        fill="#373737"
      />
      <path
        d="M10.4072 32.6199C10.213 32.6199 10.0557 32.7772 10.0557 32.9714V35.4487C10.0557 35.6429 10.213 35.8002 10.4072 35.8002C10.6014 35.8002 10.7588 35.6429 10.7588 35.4487V32.9714C10.7588 32.7772 10.6014 32.6199 10.4072 32.6199Z"
        fill="#373737"
      />
      <path
        d="M17.0432 19.7095C15.5149 19.7095 14.2715 20.9531 14.2715 22.4814C14.2715 24.0097 15.5149 25.2532 17.0432 25.2532C18.5715 25.2532 19.815 24.0097 19.815 22.4814C19.815 20.9531 18.5715 19.7095 17.0432 19.7095ZM17.0432 24.5501C15.9026 24.5501 14.9746 23.622 14.9746 22.4814C14.9746 21.3408 15.9026 20.4126 17.0432 20.4126C18.1838 20.4126 19.1119 21.3408 19.1119 22.4814C19.1119 23.622 18.1838 24.5501 17.0432 24.5501Z"
        fill="#373737"
      />
      <path
        d="M8.69763 19.7095C7.16932 19.7095 5.92578 20.9531 5.92578 22.4814C5.92578 24.0097 7.16932 25.2532 8.69763 25.2532C10.2259 25.2532 11.4695 24.0097 11.4695 22.4814C11.4695 20.9531 10.2259 19.7095 8.69763 19.7095ZM8.69763 24.5501C7.55702 24.5501 6.6289 23.622 6.6289 22.4814C6.6289 21.3408 7.55702 20.4126 8.69763 20.4126C9.83823 20.4126 10.7664 21.3408 10.7664 22.4814C10.7664 23.622 9.83823 24.5501 8.69763 24.5501Z"
        fill="#373737"
      />
      <path
        d="M21.2162 24.9126C19.6879 24.9126 18.4443 26.1562 18.4443 27.6845C18.4443 29.2128 19.6879 30.4563 21.2162 30.4563C22.7445 30.4563 23.988 29.2128 23.988 27.6845C23.988 26.1562 22.7446 24.9126 21.2162 24.9126ZM21.2162 29.7532C20.0756 29.7532 19.1475 28.8251 19.1475 27.6845C19.1475 26.5439 20.0756 25.6158 21.2162 25.6158C22.3568 25.6158 23.2849 26.5439 23.2849 27.6845C23.2849 28.8251 22.3569 29.7532 21.2162 29.7532Z"
        fill="#373737"
      />
      <path
        d="M23.2773 30.2539H19.1543C17.7963 30.2539 16.6914 31.3587 16.6914 32.7168V35.2214C16.6914 35.6507 17.0407 36 17.47 36H24.9615C25.3909 36 25.7402 35.6507 25.7402 35.2214V32.7168C25.7402 31.3587 24.6353 30.2539 23.2773 30.2539ZM25.037 35.2212C25.037 35.2628 25.0032 35.2967 24.9615 35.2967H17.47C17.4284 35.2967 17.3945 35.2628 17.3945 35.2212V32.7167C17.3945 31.7464 18.1839 30.9569 19.1543 30.9569H23.2773C24.2476 30.9569 25.0372 31.7463 25.0372 32.7167V35.2212H25.037Z"
        fill="#373737"
      />
      <path
        d="M23.6787 32.6199C23.4845 32.6199 23.3271 32.7772 23.3271 32.9714V35.4487C23.3271 35.6429 23.4845 35.8002 23.6787 35.8002C23.8729 35.8002 24.0303 35.6429 24.0303 35.4487V32.9714C24.0303 32.7772 23.8729 32.6199 23.6787 32.6199Z"
        fill="#373737"
      />
      <path
        d="M18.7529 32.6199C18.5587 32.6199 18.4014 32.7772 18.4014 32.9714V35.4487C18.4014 35.6429 18.5587 35.8002 18.7529 35.8002C18.9471 35.8002 19.1045 35.6429 19.1045 35.4487V32.9714C19.1045 32.7772 18.9471 32.6199 18.7529 32.6199Z"
        fill="#373737"
      />
      <path
        d="M20.0495 25.2428C19.7468 25.1154 19.4286 25.0508 19.104 25.0508H14.981C14.6473 25.0508 14.3209 25.1189 14.0107 25.2533C13.8325 25.3305 13.7507 25.5375 13.8279 25.7157C13.8854 25.8483 14.0148 25.9276 14.1506 25.9276C14.1973 25.9276 14.2447 25.9183 14.2903 25.8985C14.5116 25.8025 14.7442 25.7539 14.981 25.7539H19.104C19.3345 25.7539 19.5607 25.8001 19.7767 25.8911C19.9555 25.9661 20.1618 25.8822 20.2371 25.7033C20.3127 25.5243 20.2286 25.3181 20.0495 25.2428Z"
        fill="#373737"
      />
      <path
        d="M11.7443 25.2601C11.4297 25.1213 11.098 25.0508 10.7585 25.0508H6.63557C6.30145 25.0508 5.9745 25.1192 5.66386 25.254C5.48569 25.3312 5.40398 25.5382 5.48133 25.7164C5.55839 25.8944 5.76553 25.976 5.9437 25.8989C6.16547 25.8026 6.3982 25.7539 6.63557 25.7539H10.7585C10.9996 25.7539 11.2358 25.8041 11.4603 25.9034C11.5065 25.9238 11.5546 25.9335 11.6021 25.9335C11.7371 25.9335 11.866 25.8552 11.9239 25.7238C12.0024 25.546 11.9219 25.3385 11.7443 25.2601Z"
        fill="#373737"
      />
      <path
        d="M20.7289 2.38999L17.832 0.941563C17.6581 0.854516 17.4473 0.92511 17.3604 1.09878C17.2736 1.27245 17.3441 1.48353 17.5176 1.57044L20.4145 3.02759L17.5201 4.47475C17.3466 4.56166 17.2761 4.77273 17.3629 4.9464C17.4245 5.06959 17.5487 5.14089 17.6776 5.14089C17.7305 5.14089 17.7841 5.12879 17.8346 5.10362L20.7288 3.65646C20.9704 3.5358 21.1203 3.29308 21.1203 3.02308C21.1203 2.75309 20.9704 2.51065 20.7289 2.38999Z"
        fill="#373737"
      />
      <path
        d="M16.9131 0C16.3315 0 15.8584 0.47306 15.8584 1.05468V7.04289C15.8584 7.23709 16.0158 7.39445 16.21 7.39445C16.4042 7.39445 16.5615 7.23709 16.5615 7.04289V1.05468C16.5615 0.860762 16.7192 0.703122 16.9131 0.703122C17.107 0.703122 17.2646 0.860762 17.2646 1.05468V7.04289C17.2646 7.23709 17.422 7.39445 17.6162 7.39445C17.8104 7.39445 17.9678 7.23709 17.9678 7.04289V1.05468C17.9678 0.47306 17.4946 0 16.9131 0Z"
        fill="#373737"
      />
      <path
        d="M17.6162 9.80297C17.422 9.80297 17.2646 9.96033 17.2646 10.1545V10.8984C17.2646 11.0924 17.107 11.25 16.9131 11.25C16.7192 11.25 16.5615 11.0924 16.5615 10.8984V10.1486C16.5615 9.95442 16.4042 9.79707 16.21 9.79707C16.0158 9.79707 15.8584 9.95442 15.8584 10.1486V10.8984C15.8584 11.4801 16.3315 11.9531 16.9131 11.9531C17.4947 11.9531 17.9678 11.4801 17.9678 10.8984V10.1545C17.9678 9.96033 17.8104 9.80297 17.6162 9.80297Z"
        fill="#373737"
      />
      <path
        d="M28.442 13.0018C28.3104 12.8592 28.0879 12.8501 27.9452 12.9817C27.4176 13.4687 26.7351 13.7367 26.0237 13.7367C25.31 13.7367 24.6262 13.4673 24.0983 12.9781C23.9556 12.8462 23.7334 12.8547 23.6015 12.9971C23.4696 13.1395 23.478 13.362 23.6204 13.4939C24.2788 14.1039 25.1324 14.4399 26.0239 14.4399C26.9125 14.4399 27.7642 14.1056 28.4222 13.4987C28.5647 13.367 28.5737 13.1446 28.442 13.0018Z"
        fill="#373737"
      />
      <path
        d="M27.3291 16.5417H24.7188C24.3263 16.5417 24.0068 16.861 24.0068 17.2537V20.0708C24.0068 20.4633 24.3262 20.7826 24.7188 20.7826H27.3291C27.7216 20.7826 28.0411 20.4633 28.0411 20.0708V17.2537C28.0411 16.861 27.7216 16.5417 27.3291 16.5417ZM27.3291 20.0794H24.7188L24.71 20.0706L24.7188 17.2447L27.3378 17.2535L27.3291 20.0794Z"
        fill="#373737"
      />
      <path
        d="M27.5869 18H24.4697C24.2755 18 24.1182 18.1574 24.1182 18.3516C24.1182 18.5458 24.2755 18.7031 24.4697 18.7031H27.5869C27.7812 18.7031 27.9385 18.5458 27.9385 18.3516C27.9385 18.1574 27.7812 18 27.5869 18Z"
        fill="#373737"
      />
    </svg>
  );
}
