import React from 'react';

export default function SuccessMessageIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100" fill="none">
      <g clip-path="url(#clip0_244_702)">
        <path
          d="M14.8438 31.8359L1.95312 42.1875V90.0391C1.95312 94.4618 5.53818 98.0469 9.96094 98.0469H90.0391C94.4618 98.0469 98.0469 94.4618 98.0469 90.0391V42.1875L85.1562 32.1487"
          fill="#6DA6C2"
        />
        <path
          d="M80.8594 1.95312H19.1406C16.983 1.95312 15.2344 3.70178 15.2344 5.85938V73.6084H84.7656V5.85938C84.7656 3.70178 83.017 1.95312 80.8594 1.95312Z"
          fill="white"
        />
        <path
          d="M63.0859 77.2636H35.8452L1.95312 42.1875V90.0391C1.95312 94.4618 5.53818 98.0469 9.96094 98.0469H90.0391C94.4618 98.0469 98.0469 94.4618 98.0469 90.0391V42.1875L63.0859 77.2636Z"
          fill="#A9CADA"
        />
        <path
          d="M63.0781 70.9548C60.3292 68.4578 57.0425 66.8884 53.6184 66.2422L51.7187 65.9782C46.4773 65.5159 41.0841 67.1737 36.9222 70.9548L7.7749 97.7424C8.46994 97.9392 9.20236 98.0468 9.96072 98.0468H90.0388C90.9414 98.0468 91.8073 97.8957 92.6168 97.6203L63.0781 70.9548Z"
          fill="#6DA6C2"
        />
        <path d="M30.0781 14.6339H69.9219V25.7744H30.0781V14.6339Z" fill="#85AC82" />
        <path
          d="M99.2477 40.6494C99.2477 40.6487 99.247 40.6479 99.247 40.6464L86.7188 30.8907V5.85938C86.7188 2.62833 84.0904 0 80.8594 0H19.1406C15.9096 0 13.2812 2.62833 13.2812 5.85938V30.5855L0.730133 40.6647L0.730896 40.6654C0.285339 41.0233 0 41.5718 0 42.1875V90.0391C0 95.5315 4.46854 100 9.96094 100H41.2109C42.2897 100 43.1641 99.1257 43.1641 98.0469C43.1641 96.9681 42.2897 96.0938 41.2109 96.0938H12.1544L38.2355 72.4007C44.9425 66.3078 55.0575 66.3078 61.7645 72.4007L87.8456 96.0938H58.7891C57.7103 96.0938 56.8359 96.9681 56.8359 98.0469C56.8359 99.1257 57.7103 100 58.7891 100H90.0391C95.5315 100 100 95.5315 100 90.0391V42.1875C100 41.5627 99.7047 41.0072 99.2477 40.6494ZM95.1027 42.3698L86.7188 50.7538V35.8414L95.1027 42.3698ZM13.2812 50.753L4.87442 42.3462L13.2812 35.5957V50.753ZM3.90625 90.0391V46.9025L30.8426 73.8396L7.29141 95.2339C7.23572 95.2843 7.1846 95.3377 7.13654 95.3926C5.21774 94.3756 3.90625 92.3584 3.90625 90.0391ZM50 63.9488C44.6671 63.9488 39.5561 65.9233 35.6087 69.5099L33.7372 71.2097L17.1875 54.6593V5.85938C17.1875 4.7821 18.0634 3.90625 19.1406 3.90625H80.8594C81.9366 3.90625 82.8125 4.7821 82.8125 5.85938V54.66L66.2628 71.2097L64.3913 69.5099C60.4439 65.9233 55.3329 63.9488 50 63.9488ZM92.8635 95.3926C92.8154 95.3377 92.7643 95.2843 92.7094 95.2339L69.1574 73.8396L96.0938 46.9032V90.0391C96.0938 92.3576 94.7823 94.3756 92.8635 95.3926Z"
          fill="#373737"
        />
        <path
          d="M50 96.0938C49.4865 96.0938 48.9822 96.3028 48.6191 96.666C48.2559 97.0291 48.0469 97.5334 48.0469 98.0469C48.0469 98.5603 48.2559 99.0646 48.6191 99.4278C48.9822 99.791 49.4865 100 50 100C50.5135 100 51.0178 99.791 51.3809 99.4278C51.7441 99.0646 51.9531 98.5603 51.9531 98.0469C51.9531 97.5334 51.7441 97.0291 51.3809 96.666C51.0178 96.3028 50.5135 96.0938 50 96.0938Z"
          fill="#373737"
        />
        <path
          d="M30.0781 37.2207H69.9219C71.0007 37.2207 71.875 36.3464 71.875 35.2676C71.875 34.1888 71.0007 33.3145 69.9219 33.3145H30.0781C28.9993 33.3145 28.125 34.1888 28.125 35.2676C28.125 36.3464 28.9993 37.2207 30.0781 37.2207Z"
          fill="#373737"
        />
        <path
          d="M69.9218 42.8864H55.9043C54.8263 42.8864 53.9512 43.7607 53.9512 44.8395C53.9512 45.9183 54.8263 46.7926 55.9043 46.7926H69.9218C71.0006 46.7926 71.8749 45.9183 71.8749 44.8395C71.8749 43.7607 71.0006 42.8864 69.9218 42.8864Z"
          fill="#373737"
        />
        <path
          d="M30.0781 46.7926H43.8385C44.9173 46.7926 45.7916 45.9183 45.7916 44.8395C45.7916 43.7607 44.9173 42.8864 43.8385 42.8864H30.0781C28.9993 42.8864 28.125 43.7607 28.125 44.8395C28.125 45.9183 28.9993 46.7926 30.0781 46.7926Z"
          fill="#373737"
        />
        <path
          d="M61.1328 52.4574H30.3589C29.2801 52.4574 28.4058 53.3325 28.4058 54.4105C28.4058 55.4893 29.2801 56.3636 30.3589 56.3636H61.1328C62.2116 56.3636 63.0859 55.4893 63.0859 54.4105C63.0859 53.3325 62.2116 52.4574 61.1328 52.4574Z"
          fill="#373737"
        />
        <path
          d="M69.9219 52.4567C69.4084 52.4567 68.9041 52.6657 68.541 53.0296C68.1778 53.3928 67.9688 53.8963 67.9688 54.4098C67.9688 54.9255 68.1778 55.4276 68.541 55.7907C68.9041 56.1562 69.4084 56.3629 69.9219 56.3629C70.4353 56.3629 70.9396 56.1562 71.3028 55.7907C71.666 55.4276 71.875 54.9255 71.875 54.4098C71.875 53.8963 71.666 53.3928 71.3028 53.0296C70.9396 52.6657 70.4353 52.4567 69.9219 52.4567Z"
          fill="#373737"
        />
        <path
          d="M30.0781 27.7275H69.9219C71.0007 27.7275 71.875 26.8524 71.875 25.7744V14.6339C71.875 13.5551 71.0007 12.6808 69.9219 12.6808H30.0781C28.9993 12.6808 28.125 13.5551 28.125 14.6339V25.7744C28.125 26.8524 28.9993 27.7275 30.0781 27.7275ZM32.0312 16.587H67.9688V23.8212H32.0312V16.587Z"
          fill="#373737"
        />
      </g>
      <defs>
        <clipPath id="clip0_244_702">
          <rect width="100" height="100" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
