exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".gtJKf{-ms-flex-pack:start;justify-content:start;width:100%;gap:20px;margin-bottom:50px}._2t3qp,.gtJKf{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center}._2t3qp{text-align:center;-ms-flex-pack:center;justify-content:center;gap:10px}._2t3qp img{max-width:100px;max-height:100px}._2t3qp p{margin:8px 0;margin:.5rem 0}._2P_pA{width:200px;height:1px;background-color:#999;-ms-flex-item-align:center;align-self:center}.ywyYl{font-family:Nunito,sans-serif;font-weight:600;font-size:14px;line-height:16.8px;letter-spacing:1.5%;color:#666}._3fpGN{color:#373737}._2R2_T{background-color:#999;color:#fff;max-width:30px;min-width:30px;height:30px;display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center;border-radius:50%}.GWeOZ{background-color:#d74b73}._2kwOq{width:30px;height:30px}", ""]);

// exports
exports.locals = {
	"container": "gtJKf",
	"item": "_2t3qp",
	"straightLine": "_2P_pA",
	"stepText": "ywyYl",
	"stepTextActive": "_3fpGN",
	"stepNumber": "_2R2_T",
	"stepNumberActive": "GWeOZ",
	"checkIcon": "_2kwOq"
};