import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import MobileTabs from './MobileTabs';

import { programIdType } from '../../utils/types';
import { breakpoints } from '../../utils/breakpoints';

const NavWrapper = styled.nav`
  max-width: 60.375rem;
  margin: 0 auto;
  padding: 0 0.75rem;

  @media ${breakpoints.desktop} {
    padding-left: 0;
    padding-right: 0;
  }

  ul {
    border-bottom: solid 0.125rem #ebecf0;
    border-radius: 1px;
    display: flex;
    list-style-type: none;
    padding-left: 0;
    height: 1.9375rem;
    width: 100%;

    li {
      line-height: 1rem;
      display: inline-flex;
      align-items: center;
      align-self: stretch;

      a {
        font-family: 'Nunito', sans-serif;
        font-weight: 400;
        font-size: 0.875rem;
        display: inline-flex;
        color: #373737;
        align-self: stretch;
        padding: 0.125rem 0.125rem 0.625rem;
        background-color: transparent;
        background-image: none;
        transform: translateY(0.125rem);

        &:hover,
        &.active {
          color: #446372;
          padding: 0.125rem 0.125rem 0.5rem;
          border-bottom: 0.125rem solid #446372;
          text-decoration: none;
        }

        &:focus {
          outline: none;
        }

        &:focus-visible {
          padding: 0 0 0.5rem;
          border: 0.125rem solid #255a94;
          border-radius: 2px;
        }
      }
    }
  }
`;

const NavListContainer = styled.div`
  display: none;
  width: 100%;
  max-width: 100%;

  @media only screen and ${breakpoints.desktop} {
    display: flex;
    align-items: center;
  }
`;

export default function ProgramTabs({ programId, currentTab, activeTabs, isAdmin }) {
  const handleLinkClick = () => {
    localStorage.setItem('scrollY', window.scrollY);
  };

  return (
    <NavWrapper aria-label="Program Brochure Navigation">
      <NavListContainer>
        <ul style={activeTabs.length > 3 ? { justifyContent: 'space-between' } : { gap: '2em' }}>
          {activeTabs.map(tab => (
            <li key={tab.component}>
              <NavLink
                exact
                to={
                  isAdmin
                    ? `/client/program_brochure/${programId}/${tab.path}`
                    : `/program_brochure/${programId}/${tab.path}`
                }
                onClick={handleLinkClick}
              >
                {tab.title}
              </NavLink>
            </li>
          ))}
        </ul>
      </NavListContainer>
      <MobileTabs programId={programId} currentTab={currentTab} activeTabs={activeTabs} isAdmin={isAdmin} />
    </NavWrapper>
  );
}

ProgramTabs.propTypes = {
  programId: programIdType,
  currentTab: PropTypes.shape({
    path: PropTypes.string,
    title: PropTypes.string.isRequired,
    component: PropTypes.string.isRequired,
  }).isRequired,
  activeTabs: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string,
      title: PropTypes.string.isRequired,
      component: PropTypes.string.isRequired,
    }),
  ),
};

ProgramTabs.defaultProps = {
  programId: '',
  activeTabs: [],
};
