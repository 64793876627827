import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import DragAndDropList from '../../base/drag-and-drop/DragAndDropList';
import TravelerFormQuestionContainer from '../../base/TravelerFormQuestionContainer';

import userId from '../../../../../shared/helpers/userId';
import priorityOptions from '../../../utils/programMatch/priorityOptions';
import { addProgramMatchComplete, addProgramMatchPriorities } from '../../../actions/programMatchFormActions';

export default function ProgramMatchPriorities() {
  const dispatch = useDispatch();
  const programMatch = useSelector(state => state.programMatchForm);
  const programAlias = useSelector(state => state.auth.organization.info.alias_program);

  const [value, setValue] = useState(priorityOptions);

  const question = {
    overline: `${programAlias} Priorities`,
    heading: 'Finally, what is most important to you?',
    subheading: 'Rearrange the topics in order of importance with the first topic as your highest priority.',
    isRequired: false,
    isSubheadingRead:true,
  };

  useEffect(
    () => {
      if (programMatch.priorities.length > 0) {
        const orderedPriorities = programMatch.priorities.map(priority =>
          priorityOptions.find(option => option.id === priority.id),
        );

        setValue(orderedPriorities);
      }
    },
    [programMatch.priorities],
  );

  const handleNext = () => {
    const payload = value.map((priority, index) => ({
      priority_id: priority.id,
      ranking: index + 1,
    }));

    dispatch(addProgramMatchPriorities(userId, payload, value));
    dispatch(addProgramMatchComplete(userId, true));
  };

  return (
    <TravelerFormQuestionContainer question={question} handleNext={handleNext} toProcessing>
      <DragAndDropList listItems={value} setListItems={setValue} />
    </TravelerFormQuestionContainer>
  );
}
