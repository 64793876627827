import React from 'react';
const PhotoIcon = (props) => {
  return(
  <div>
    <svg width="92px" height="73px" viewBox="0 0 92 73" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Admin-View" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Program-Brochure-Page-Create-Textlink-URL" transform="translate(-329.000000, -264.000000)" fill={props.color} fillRule="nonzero">
            <g id="+-ADD-PHOTOS" transform="translate(222.000000, 264.000000)">
                <g id="Group-12">
                    <path d="M107,73 L107,0 L199,0 L199,73 L107,73 Z M193,5 L112,5 L112,49 L130.395957,34.103139 L147.250892,43.7713004 L171.233056,19.5022422 L193,36.470852 L193,5 Z M126,23 C122.134007,23 119,19.8659932 119,16 C119,12.1340068 122.134007,9 126,9 C129.865993,9 133,12.1340068 133,16 C133,19.8659932 129.865993,23 126,23 Z" id="photo"></path>
                </g>
            </g>
        </g>
    </g>
</svg>
  </div>
  )
}
export default (PhotoIcon);