import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import moment from 'moment-timezone';

import Note from './Note';
import Loader from '../../../../../components/Loader/Loader';

import s from './NoteList.css';

class NoteList extends React.Component {
  componentWillReceiveProps(nextProps) {
    if (nextProps.noteCreate) {
      !!this.props.notes && !!this.props.notes.data && this.props.notes.data.unshift(nextProps.noteCreate);
    }
  }

  render() {
    if (this.props.notesLoading == true) {
      return <Loader />;
    } else if (this.props.notes == null) {
      return <div>error</div>;
    }
    const notes =
      !!this.props.notes &&
      !!this.props.notes.data &&
      this.props.notes.data.map(note => (
        <Note
          key={note.id}
          body={note.attributes.body}
          subject={note.attributes.subject}
          author={note.attributes.author}
          avatar={note.attributes.poster_avatar}
          date={moment(new Date(note.attributes.created_at)).format('MMMM D, YYYY')}
          time={moment(new Date(note.attributes.created_at)).format('h:mma')}
        />
      ));

    return <div className={s.container}>{notes}</div>;
  }
}

export default withStyles(s)(NoteList);
