import * as yup from 'yup';
import _get from 'lodash/get';

export const FIELD_NAMES = {
  first_name: 'profile.first_name',
  last_name: 'profile.last_name',
  email: 'profile.email',
  title: 'profile.title',
  suffix: 'profile.suffix',
  job_title: 'client_user_info.job_title',
  department: 'client_user_info.department',
  experience: 'client_user_info.experience',
  avatar: 'profile.avatar',
};

export const schema = yup.object().shape({
  profile: yup.object().shape({
    first_name: yup.string().required('First name is required'),
    last_name: yup.string().required('Last name is required'),
  }),
});

export const buildDefaultValues = defaultData => {
  const client_user_info = {
    profile: {
      first_name: _get(defaultData, ['first_name']) || '',
      last_name: _get(defaultData, ['last_name']) || '',
      avatar: _get(defaultData, ['avatar']) || [],
      email: _get(defaultData, ['email']) || '',
      title: _get(defaultData, ['title']) || '',
      suffix: _get(defaultData, ['suffix']) || '',
    },
    client_user_info: {
      job_title: _get(defaultData, ['job_title']) || '',
      department: _get(defaultData, ['department']) || '',
      experience: _get(defaultData, ['experience']) || '',
    },
  };

  return client_user_info;
};

export const passwordSchema = yup.object().shape({
    existing_password: yup.string().required('Existing password is required'),
    password: yup.string()
    .required('New password is required')
    .min(8, 'New password must be at least 8 characters')
    .matches(
      /^(?=.*[A-Z])/,
      'New password must contain an uppercase letter'
    )
    .matches(
      /^(?=.*[!\"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~])/,
      'New password must contain a special character'
    ),
    confirm_password: yup.string()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .required('Confirm Password is required'),
});
