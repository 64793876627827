exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".O0_gh{display:block;margin:0}", ""]);

// exports
exports.locals = {
	"checkMark": "O0_gh"
};