import { css } from 'styled-components';

export const buttonFocus = css`
  &:focus {
    outline: none;
  }

  &:focus-visible {
    border: 2px solid #255a94;
  }
`;

export const buttonFocusOutline = css`
  &:focus {
    outline: none;
  }

  &:focus-visible {
    outline: 2px solid #255a94;
    outline-offset: 1px;
  }
`;

export const formInputFocus = css`
  &:focus {
    outline: none;
    border: 1px solid #5a869b;
    box-shadow: 0px 5px 10px rgba(94, 140, 190, 0.25);
  }
`;

export const formRadioFocus = css`
  &:focus {
    outline: none;
    border: 1px solid #5180b1;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  }
`;
