import React from 'react';
import defaultView from './viewFiles/default.json';
import upcomingForms from './viewFiles/upcoming_forms.json';
import groupByTimingStatus from './viewFiles/grouped_by_timing_status.json';
import pivotCreatedByStatus from './viewFiles/pivot_created_status.json';

import ViaIcon from '../../../../../plans/components/ViaIcon';
import pivotTableIcon from './pivotTable.svg';
import standardIcon from './analysis.svg';

const views = {
  default: defaultView,
  upcomingForms: upcomingForms,
  groupByTimingStatus: groupByTimingStatus,
  pivotCreatedByStatus: pivotCreatedByStatus,
};

const definedFilters = () => {
  const groupFilters = [
    {
      icon: <img src={standardIcon} alt="Standard Views" width="20" height="20" />,
      label: 'Standard',
      options: [
        { label: 'Upcoming Forms', value: 'upcomingForms' },
        { label: 'Grouped by Timing/Status', value: 'groupByTimingStatus' },
      ],
    },
    {
      icon: <img src={pivotTableIcon} alt="Pivot Views" width="20" height="20" />,
      label: 'Pivot',
      options: [{ label: 'Created by Status', value: 'pivotCreatedByStatus' }],
    },
    {
      icon: <ViaIcon name="restore" color="gray" size="mini" />,
      label: 'Reset',
      options: [{ label: '-- Reset to Default --', value: 'default' }],
    },
  ];

  return groupFilters;
};

export default { views, definedFilters };
