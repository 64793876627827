import PropTypes from 'prop-types';
import styled from 'styled-components';
import React, { useState } from 'react';
import FocusTrap from 'focus-trap-react';
import { useDispatch, useSelector } from 'react-redux';

import CloseIcon from '../../icons/CloseIcon';
import FilterMenuIcon from '../../icons/FilterMenuIcon';
import MobileDatesFilter from './dates/MobileDatesFilter';
import MobileTermNameFilter from './term-name/MobileTermNameFilter';
import MobileLocationsFilter from './locations/MobileLocationsFilter';
import MobileProgramTypeFilter from './program-type/MobileProgramTypeFilter';
import MobileSubjectAreasFilter from './subject-areas/MobileSubjectAreasFilter';
import MobileFavoritesOnlyFilter from './favorites-only/MobileFavoritesOnlyFilter';

import useDatesOptions from '../../../hooks/useDatesOptions';
import { buttonFocus } from '../../../utils/shared/focusStyles';
import useLocationsOptions from '../../../hooks/useLocationsOptions';
import { toggleProgramsFilter } from '../../../actions/programDashboardActions';

const OuterContainer = styled.nav`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 20;
  overflow-y: scroll;
  background-color: #ffffff;
`;

const InnerContainer = styled.div`
  max-height: 90%;
  overflow-y: scroll;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.25rem;
  padding: 0.75rem 1.4375rem;
  border-bottom: 3px solid var(--theme_color_accent);
`;

const Heading = styled.span`
  display: flex;
  align-items: center;
  gap: 0.625rem;
  font-family: 'Nunito', sans-serif;
  font-weight: 400;
  font-size: 1.125rem;
  color: #373737;
  line-height: 120%;
`;

const CloseButton = styled.button`
  padding: 0;
  background-color: transparent;
  border: none;

  svg {
    width: 13px;
    height: 13px;
  }

  &:focus {
    outline: none;
  }

  &:focus-visible {
    svg {
      outline: 2px solid #255a94;
      outline-offset: 1px;
    }
  }
`;

const MenuFooter = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.25rem 1.5625rem;
  background-color: #e7e7e7;
  border-top: 1px solid #d7d7d7;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
`;

const ClearAllButton = styled.button`
  background-color: transparent;
  border: none;
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  color: #373737;
  letter-spacing: 0.015em;
  text-decoration-line: underline;
  line-height: 120%;

  ${buttonFocus};
`;

const FilterButton = styled.button`
  padding: 0.6875rem 2.875rem;
  background-color: var(--theme_color_accent);
  border: none;
  border-radius: 5px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 1rem;
  color: var(--theme_color_accent_font);
  letter-spacing: 0.06em;
  line-height: 120%;

  ${buttonFocus};
`;

export default function MobileFilters({ show, handleClose }) {
  const dispatch = useDispatch();
  const { role } = useSelector(state => state.travelerProfile);
  const { selectedFilters } = useSelector(state => state.programDashboard);
  const {
    customAliases: { alias_programs: programsAlias },
  } = useSelector(state => state.currentOrganization);

  const [termNames, setTermNames] = useState([]);
  const [trapPaused, setTrapPaused] = useState(false);
  const [programTypes, setProgramTypes] = useState([]);
  const [subjectAreas, setSubjectAreas] = useState([]);
  const [favoritesOnly, setFavoritesOnly] = useState(selectedFilters.favorites);
  const [selectedCities, setSelectedCities, selectedCountries, setSelectedCountries] = useLocationsOptions();
  const [
    endDate,
    setEndDate,
    startDate,
    setStartDate,
    endDateInclusive,
    setEndDateInclusive,
    startDateInclusive,
    setStartDateInclusive,
    endRange,
    setEndRange,
    startRange,
    setStartRange,
    resetDateValues,
  ] = useDatesOptions();

  const isTraveler = role === 'traveler';

  const handleKeyDown = event => {
    if (event.key === 'Escape') {
      const button = document.getElementById('close_mobile_filters_button');

      button?.focus();
    }
  };

  const handleFavoritesChange = () => {
    setFavoritesOnly(!favoritesOnly);
  };

  const handleTermNameChange = event => {
    if (event) {
      setTermNames(event);
    } else {
      setTermNames([]);
    }
  };

  const handleSubjectAreasChange = event => {
    if (event) {
      setSubjectAreas(event);
    } else {
      setSubjectAreas([]);
    }
  };

  const handleProgramTypesChange = event => {
    if (event) {
      setProgramTypes(event);
    } else {
      setProgramTypes([]);
    }
  };

  const handleCountriesChange = event => {
    if (event) {
      setSelectedCountries(event);
    } else {
      setSelectedCountries([]);
    }
  };

  const handleCitiesChange = event => {
    if (event) {
      setSelectedCities(event);
    } else {
      setSelectedCities([]);
    }
  };

  const handleClearAll = () => {
    const filters = [
      { name: 'cities', value: [] },
      { name: 'termName', value: [] },
      { name: 'countries', value: [] },
      { name: 'programType', value: [] },
      { name: 'favorites', value: false },
      { name: 'subjectAreas', value: [] },
      {
        name: 'startDate',
        value: {
          date: null,
          range: null,
          dateInclusive: null,
        },
      },
      {
        name: 'endDate',
        value: {
          date: null,
          range: null,
          dateInclusive: null,
        },
      },
    ];

    filters.forEach(filter => {
      dispatch(toggleProgramsFilter(filter));
    });

    setTermNames([]);
    resetDateValues();
    setProgramTypes([]);
    setSubjectAreas([]);
    setSelectedCities([]);
    setFavoritesOnly(false);
    setSelectedCountries([]);
  };

  const handleFilter = () => {
    const filters = [
      { name: 'cities', value: selectedCities },
      { name: 'favorites', value: favoritesOnly },
      { name: 'countries', value: selectedCountries },
      { name: 'termName', value: termNames.map(termName => termName.value) },
      { name: 'programType', value: programTypes.map(programType => programType.value) },
      { name: 'subjectAreas', value: subjectAreas.map(subjectArea => subjectArea.value) },
      { name: 'startDate', value: { date: startDate, range: startRange, dateInclusive: startDateInclusive } },
      { name: 'endDate', value: { date: endDate, range: endRange, dateInclusive: endDateInclusive } },
    ];

    filters.forEach(filter => {
      dispatch(toggleProgramsFilter(filter));
    });

    handleClose();
  };

  return (
    <FocusTrap active={show} paused={trapPaused} focusTrapOptions={{ escapeDeactivates: false }}>
      <OuterContainer onKeyDown={handleKeyDown}>
        <InnerContainer>
          <Header>
            <Heading>
              <FilterMenuIcon />
              <span>Filter {programsAlias}</span>
            </Heading>
            <CloseButton
              id="close_mobile_filters_button"
              type="button"
              aria-label="Close Filter Menu"
              onClick={handleClose}
            >
              <CloseIcon fillColor="#373737" />
            </CloseButton>
          </Header>
          {isTraveler && <MobileFavoritesOnlyFilter isSelected={favoritesOnly} handleClick={handleFavoritesChange} />}
          <MobileTermNameFilter value={termNames} setValue={setTermNames} handleOnChange={handleTermNameChange} />
          <MobileDatesFilter
            setFocusTrapPaused={setTrapPaused}
            endRange={endRange}
            startRange={startRange}
            startDate={startDate}
            endDate={endDate}
            startDateInclusive={startDateInclusive}
            endDateInclusive={endDateInclusive}
            handleEndRangeChange={setEndRange}
            handleStartRangeChange={setStartRange}
            handleStartDateChange={setStartDate}
            handleEndDateChange={setEndDate}
            handleStartDateInclusiveChange={setStartDateInclusive}
            handleEndDateInclusiveChange={setEndDateInclusive}
          />
          <MobileSubjectAreasFilter
            value={subjectAreas}
            setValue={setSubjectAreas}
            handleOnChange={handleSubjectAreasChange}
          />
          <MobileLocationsFilter
            selectedCities={selectedCities}
            selectedCountries={selectedCountries}
            handleCitiesChange={handleCitiesChange}
            handleCountriesChange={handleCountriesChange}
          />
          <MobileProgramTypeFilter
            value={programTypes}
            setValue={setProgramTypes}
            handleOnChange={handleProgramTypesChange}
          />
        </InnerContainer>
        <MenuFooter>
          <ClearAllButton type="button" onClick={handleClearAll}>
            Clear All
          </ClearAllButton>
          <FilterButton type="button" onClick={handleFilter}>
            Filter
          </FilterButton>
        </MenuFooter>
      </OuterContainer>
    </FocusTrap>
  );
}

MobileFilters.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};
