import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import MobileFilterContainer from '../MobileFilterContainer.styled';
import ProfileSelectInput from '../../../base/inputs/ProfileSelectInput';

import useTermNameOptions from '../../../../hooks/useTermNameOptions';

export default function MobileTermNameFilter({ value, setValue, handleOnChange }) {
  const { selectedFilters } = useSelector(state => state.programDashboard);

  const [allOptions] = useTermNameOptions();

  useEffect(() => {
    if (selectedFilters.termName.length > 0) {
      const mappedValues = selectedFilters.termName.map(termName => ({
        label: termName,
        value: termName,
      }));

      setValue(mappedValues);
    }
  }, []);

  return (
    <MobileFilterContainer bottomBorder>
      <ProfileSelectInput
        id="term_name"
        value={value}
        label="Term Name"
        placeholder="Select Term Name(s)"
        options={allOptions}
        isMulti
        handleOnChange={handleOnChange}
      />
    </MobileFilterContainer>
  );
}

MobileTermNameFilter.propTypes = {
  setValue: PropTypes.func.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
};
