exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2ENHT{font-family:Open Sans;font-size:16px;font-weight:400;line-height:22.4px;letter-spacing:.005em;text-align:left}.BgZLA{padding:45px}._2WakF{padding:0}", ""]);

// exports
exports.locals = {
	"text": "_2ENHT",
	"container": "BgZLA",
	"modelBody": "_2WakF"
};