import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Modal from 'react-responsive-modal';
import modalCss from 'react-responsive-modal/styles.css';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { compose } from 'recompose';
import { toast } from 'react-toastify';
import CloseIcon from '../../sites/travelerProfile/components/icons/CloseIcon';
import ConfirmDeleteIcon from '../../sites/travelerProfile/components/icons/ConfirmDeleteIcon';
import TextInput from '../../styledComponents/styles/TextInput.styled';
import toastMessageStyles from '../../sites/viaGlobal/components/toastMessage/toastMessage.scss';
import { CircularProgress } from '@material-ui/core';
import styles from './index.module.css';
import { deletePlanFormsToPlans } from '../../actions/formsActions';

const enhance = compose(withStyles(styles, modalCss, toastMessageStyles));

function DeletePlanFormsToPlanModal({ show, onClose, handleUpdatePlan, templateID, templateName }) {
  const dispatch = useDispatch();
  const [deleteInput, setDeleteInput] = useState('');
  const { loading } = useSelector(state => state.forms.deletePlanFormsToPlans);
  let payload = {
    id: templateID,
  };
  const handleSubmit = e => {
    e.preventDefault();
    dispatch(
      deletePlanFormsToPlans(payload, (status, message) => {
        if (status) {
          toast(message, {
            type: 'success',
            className: toastMessageStyles['successContainer'],
            toastClassName: toastMessageStyles['wrapper'],
            bodyClassName: toastMessageStyles['successBody'],
            progressClassName: toastMessageStyles['progress-bar'],
            position: 'bottom-right'
          });
          handleUpdatePlan();
          onClose();
        } else {
          toast(message, {
            type: 'error',
            className: toastMessageStyles['failureContainer'],
            toastClassName: toastMessageStyles['wrapper'],
            bodyClassName: toastMessageStyles['failureBody'],
            progressClassName: toastMessageStyles['progress-bar'],
            position: 'bottom-right'
          });
        }
      }),
    );
  };

  return (
    <Modal
      blockScroll={false}
      open={show}
      onClose={onClose}
      center
      closeOnEsc
      closeOnOverlayClick
      showCloseIcon={false}
      styles={{
        modal: {
          padding: '0px',
          borderRadius: '5px',
          width: '550px',
        },
      }}
    >
      <div className={styles.header}>
        <div className={styles.heading} >Confirm Deletion</div>

        <button className={styles.closeButton} onClick={onClose}>
          <CloseIcon fillColor={`#9FABAE`} />
        </button>
      </div>

      <div className={styles.container} >
        <div className={styles.imageContainer}  >
          <ConfirmDeleteIcon />
        </div>

        <div className={styles.textContainer}>
          <div className={styles.description}>
            You are about to delete {templateName} from 1 traveler on this plan.
          </div>
          <div className={styles.descriptionBold}>All plan form submission data will be deleted and cannot be recovered. Are you sure?</div>
        </div>
      </div>

      <div className={styles.footer}>
        <form onSubmit={handleSubmit}>
          <div className={styles.textInputParentContainer}>
            <div className={styles.textInputContainer}>
              <TextInput
                id={'delete'}
                name={'delete'}
                label={`Type DELETE to delete ${templateName}`}
                placeholder={'Type DELETE to confirm'}
                errorMsg={''}
                value={deleteInput}
                onChange={e => {
                  setDeleteInput(e.target.value);
                }}
              />
            </div>
          </div>

          <div className={styles.buttonContainer}>
            <button className={styles.cancelBtn} onClick={onClose} type="button">
              Cancel
            </button>
            <button className={styles.primaryBtn} disabled={deleteInput !== 'DELETE' || loading} type="submit">
              Delete {loading && <CircularProgress size={15} color="inherit" />}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default enhance(DeletePlanFormsToPlanModal);