import fetch from 'node-fetch';
import { keys } from '../config/keys';
import _clone from 'lodash/clone';
import {
  FETCH_ADMIN_APPLICATIONS,
  SEARCH_ADMIN_APPLICATIONS,
  INACTIVATE_APPLICATION,
  DELETE_APPLICATION,
  PUBLISH_APPLICATION,
  DUPLICATE_APPLICATION,
  CLIENT_FETCH_TRAVELER_APPLICATION,
  CLIENT_UPDATE_TRAVELER_APPLICATION,
  UPDATE_STATUS_APPLICATION,
  ERROR_STATUS_APPLICATION,
  GET_TRAVELER_APPLICATION,
  SET_TRANSFER_APPLICATION_ERROR,
  SET_TRANSFER_APPLICATION_SUCCESS,
  GET_PROGRAM_RANGES_WITH_SAME_TEMPLATE,
  SUBMITTED_APPLICATIONS,
  SELECTED_APPLICATION,
  UPDATED_STATUS_RESPONSE,
  CLEAR_FLASH,
  FETCH_APPLICATION_DASHBOARD_FILTERS,
  DATA_LOADING,
  DATA_LOADED,
  RESET_FILTER,
  QUESTION_TYPES,
  LOAD_MORE_SUBMITTED_APPLICATIONS,
  CLEAR_PAGE,
  LOAD_FILTER_DATA,
  ADD_FILTER,
  DELETE_SEARCH_FILTER,
  SET_SELECTED_ROWS,
  ACCESS_DENIED,
} from './types';
import { Cookies } from 'react-cookie';

export function getTrmSubmissionApplications(page = 1, params = {}, path: 'Application') {
  return function(dispatch) {
    const cookies = new Cookies();
    const token = cookies.get('token');
    dispatch({ type: DATA_LOADING });
    fetch(`${keys.baseUri}/api/client/trm_submissions?page=${page}&q=${JSON.stringify(params)}&path=${path}`, {
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: SUBMITTED_APPLICATIONS,
          payload: data,
        });
        dispatch({ type: DATA_LOADED });
      });
  };
}

export function getMoreTrmSubmissionApplications(page = 1, params = {}, path: 'Application') {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(
      `${keys.baseUri}/api/client/trm_submissions?page=${page}&q=${encodeURIComponent(
        JSON.stringify(params),
      )}&path=${path}`,
      { headers: token },
    )
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: LOAD_MORE_SUBMITTED_APPLICATIONS,
          payload: data,
        });
      });
  };
}

export function loadFilterData(page = 1, params = {}, path: 'Application') {
  return function(dispatch) {
    fetch(
      `${keys.baseUri}/api/client/trm_submissions?page=${page}&q=${encodeURIComponent(
        JSON.stringify(params),
      )}&path=${path}`,
      { headers: token },
    )
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: LOAD_FILTER_DATA,
          payload: data,
        });
      });
  };
}

export function fetchApplicationDashboardFilters(path = 'Application') {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/trm_submissions/initialize_filters?path=${path}`, { headers: token })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: FETCH_APPLICATION_DASHBOARD_FILTERS,
          payload: data,
        }),
      );
  };
}
export function fetchApplications(id) {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/applications/`, { headers: token })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: FETCH_ADMIN_APPLICATIONS,
          payload: data,
        }),
      );
  };
}

export function clientFetchTravelerApplication(id) {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/applications/${id}/traveler_application`, { headers: token }).then(res => {
      if (res.status === 403) {
        dispatch({
          type: ACCESS_DENIED,
          error: res.status,
        });
      } else {
        res.json().then(data => {
          dispatch({
            type: CLIENT_FETCH_TRAVELER_APPLICATION,
            payload: data,
          });
          dispatch({
            type: GET_TRAVELER_APPLICATION,
            payload: data.submission,
          });
        });
      }
    });
  };
}

export function clientUpdateTravelerApplication(applicationId, data, submit = '', source = null, travelerId) {
  let formData = new FormData();
  const objKeys = Object.keys(data);

  objKeys.map(obj => {
    let answerValue = data[obj];

    formData.append(`answers[${obj}]`, Array.isArray(answerValue) ? JSON.stringify(answerValue) : answerValue);
  });

  formData.append('submit', submit);

  let cloneToken = _clone(token);
  delete cloneToken['Accept'];
  delete cloneToken['Content-Type'];

  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/applications/${applicationId}/update_traveler_application`, {
      method: 'PUT',
      headers: cloneToken,
      body: formData,
    }).then(response => {
      if (response.status === 200) {
        response.json().then(window.location.assign(`/client/travelers/${travelerId}?tab=applications`));
      }
    });
  };
}

export const getApplicationForTransfer = application_id => {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/applications/${application_id}/get_traveler_application`, { headers: token })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: GET_TRAVELER_APPLICATION,
          payload: data,
        });
      });
  };
};

export function searchApplications(query) {
  return {
    type: SEARCH_ADMIN_APPLICATIONS,
    query,
  };
}

export function inactivateApplication(id) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/applications/${id}/inactivate`, {
      method: 'PUT',
      headers: token,
    }).then(data =>
      dispatch({
        type: INACTIVATE_APPLICATION,
        code: data.status,
        app_id: id,
      }),
    );
  };
}

export function deleteApplication(id) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/applications/${id}`, {
      method: 'DELETE',
      headers: token,
    }).then(data =>
      dispatch({
        type: DELETE_APPLICATION,
        code: data.status,
        app_id: id,
      }),
    );
  };
}

export function publishApplication(id) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/applications/${id}/publish`, {
      method: 'PUT',
      headers: token,
    })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: PUBLISH_APPLICATION,
          code: data.status,
          app_id: id,
        }),
      );
  };
}

export function duplicateApplication(id) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/applications/${id}/duplicate`, {
      method: 'POST',
      headers: token,
    })
      .then(res => res.json())
      .then(res =>
        dispatch({
          type: DUPLICATE_APPLICATION,
          applicationDup: res,
          code: 200,
        }),
      )
      .catch(error =>
        dispatch({
          type: DUPLICATE_APPLICATION,
          applicationDup: res,
          code: 400,
        }),
      );
  };
}

export const updateApplicationStatus = (application_id, status) => dispatch => {
  const cookies = new Cookies();
  const token = cookies.get('token');
  fetch(`${keys.baseUri}/api/client/applications/${application_id}/application_status`, {
    method: 'PUT',
    headers: token,
    body: JSON.stringify({ status: status }),
  })
    .then(res => res.json())
    .then(data => {
      if (data.error === undefined) {
        dispatch({
          type: UPDATE_STATUS_APPLICATION,
          payload: data,
        });
      } else {
        dispatch({
          type: ERROR_STATUS_APPLICATION,
          payload: data.error.split('_').join(' '),
        });
      }
    });
};

export const transferApplication = (data, application_id) => dispatch => {
  const cookies = new Cookies();
  const token = cookies.get('token');
  fetch(`${keys.baseUri}/api/client/applications/${application_id}/transfer_application`, {
    method: 'POST',
    headers: token,
    body: JSON.stringify(data),
  })
    .then(res => res.json())
    .then(data => {
      if (data.error) {
        dispatch({
          type: SET_TRANSFER_APPLICATION_ERROR,
          payload: data.error,
        });
      } else {
        window.scrollTo(0, 0);
        dispatch({
          type: SET_TRANSFER_APPLICATION_SUCCESS,
          payload: data.success,
        });
        setTimeout(() => {
          window.location.assign(`/client/travelers/${data.submission.user_id}?tab=applications`);
        }, 2000);
      }
    });
};

export const getApplicationWithSameTemplate = application_id => {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/applications/${application_id}/get_program_terms_with_same_template`, {
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: GET_PROGRAM_RANGES_WITH_SAME_TEMPLATE,
          payload: data,
        });
      });
  };
};

export const updateStatuses = (application_ids, status, path: 'Application', reasons: reasons) => {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/trm_submissions/update_statuses`, {
      method: 'POST',
      headers: token,
      body: JSON.stringify({
        application_ids: application_ids,
        status: status,
        path: path,
        reasons: reasons,
      }),
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: UPDATED_STATUS_RESPONSE,
          data: data,
        });
      });
  };
};

export const selectedApplication = (application_id, status) => dispatch => {
  dispatch({
    type: SELECTED_APPLICATION,
    payload: application_id,
    status: status,
  });
};

export const clearFlash = () => dispatch => {
  dispatch({
    type: CLEAR_FLASH,
  });
};

export const resetFilter = () => dispatch => {
  dispatch({
    type: RESET_FILTER,
  });
};

export const fetchQuestionTypes = id => dispatch => {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return fetch(`${keys.baseUri}/api/client/trm_submissions/questions_for_templates?id=${id}`, {
    method: 'GET',
    headers: token,
  })
    .then(res => res.json())
    .then(data => {
      dispatch({
        type: QUESTION_TYPES,
        payload: data,
      });
    });
};

export const clearPage = () => dispatch => {
  dispatch({
    type: CLEAR_PAGE,
  });
};

export function addFilter(data) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/travelers/create_search_filter`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: token,
    })
      .then(res => res.json())
      .then(res => {
        dispatch({
          type: ADD_FILTER,
          payload: res,
        });
      });
  };
}

export function deleteSearchFilter(appliedFilterId) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/travelers/delete_search_filter`, {
      method: 'POST',
      body: JSON.stringify({ applied_filter_id: appliedFilterId }),
      headers: token,
    })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: DELETE_SEARCH_FILTER,
          payload: {
            appliedFilterId: appliedFilterId,
            code: data.code,
            message: data.message,
          },
        }),
      )
      .catch(error => console.error(error));
  };
}

export const setSelectedRowsAction = data => dispatch => {
  dispatch({
    type: SET_SELECTED_ROWS,
    payload: data,
  });
};

export function deleteFile(applicationId, answerId, type) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/${type}/applications/${applicationId}/delete_file/${answerId}`, {
      method: 'DELETE',
      headers: token,
    });
  };
}

export function adminWithdrawApplication(applicationId, data, source) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  if (source === 'applications_tab') {
    return function(dispatch) {
      fetch(`${keys.baseUri}/api/client/applications/${applicationId}/withdraw_traveler_application`, {
        method: 'PUT',
        headers: token,
        body: JSON.stringify({ reasons: data }),
      }).then(response => {
        response.json().then(res => {
          if (response.status === 200) {
            window.location.reload();
          }
        });
      });
    };
  } else {
    return function(dispatch) {
      const cookies = new Cookies();
      const token = cookies.get('token');
      fetch(`${keys.baseUri}/api/client/applications/${applicationId}/withdraw_traveler_application`, {
        method: 'PUT',
        headers: token,
        body: JSON.stringify({ reasons: data }),
      });
    };
  }
}

export function adminDeferApplication(applicationId, data, source) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  if (source === 'applications_tab') {
    return function(dispatch) {
      fetch(`${keys.baseUri}/api/client/applications/${applicationId}/defer_traveler_application`, {
        method: 'PUT',
        headers: token,
        body: JSON.stringify({ reasons: data }),
      }).then(response => {
        response.json().then(res => {
          if (response.status === 200) {
            window.location.reload();
          }
        });
      });
    };
  } else {
    return function(dispatch) {
      fetch(`${keys.baseUri}/api/client/applications/${applicationId}/defer_traveler_application`, {
        method: 'PUT',
        headers: token,
        body: JSON.stringify({ reasons: data }),
      });
    };
  }
}
