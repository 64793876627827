import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { HeaderActionDiv, HeaderLabel } from './Header.styled';

import Mobile from './share/Mobile';
import WhatsApp from './share/WhatsApp';
import Facebook from './share/Facebook';

import CopyLink from './share/CopyLink';
import { programIdType } from '../../utils/types';

const ShareLinkList = styled.ul`
  list-style-type: none;
  margin-bottom: 0;
  padding-left: 0;
  display: flex;
  gap: 0.5rem;
`;

export default function Share({ programId, programTitle }) {
  const programAlias = useSelector(state => state.auth.organization.info.alias_program);
  const url = `${window.location.host}/program_brochure/${programId}`;
  const title = `I thought you might want to check out ${programTitle}`;

  function handleClick(shareUrl) {
    const width = 550;
    const height = 400;
    const left = window.outerWidth / 2 + (window.screenX || window.screenLeft || 0) - width / 2;
    const top = window.outerHeight / 2 + (window.screenY || window.screenTop || 0) - height / 2;

    const config = {
      height: height,
      width: width,
      left: left,
      top: top,
      location: 'no',
      toolbar: 'no',
      status: 'no',
      directories: 'no',
      menubar: 'no',
      scrollbars: 'yes',
      resizable: 'no',
      centerscreen: 'yes',
      chrome: 'yes',
    };

    window.open(
      shareUrl,
      '',
      Object.keys(config)
        .map(key => key + '=' + config[key])
        .join(', '),
    );
  }

  return (
    <HeaderActionDiv>
      <HeaderLabel>Share {programAlias}:</HeaderLabel>
      <ShareLinkList>
        <li className="share-btn share-btn--desktop">
          <Facebook url={url} hashtag="#ViaTRM" clickHandler={handleClick} />
        </li>
        <li className="share-btn share-btn--desktop">
          <WhatsApp url={url} title={title} clickHandler={handleClick} />
        </li>
        <li className="share-btn share-btn--desktop">
          <CopyLink url={url} position="right" />
        </li>
        <li className="share-btn share-btn--mobile">
          <Mobile>
            <Facebook url={url} hashtag="#ViaTRM" clickHandler={handleClick} />
            <WhatsApp url={url} title={title} clickHandler={handleClick} />
            <CopyLink url={url} position="bottom" />
          </Mobile>
        </li>
      </ShareLinkList>
    </HeaderActionDiv>
  );
}

Share.propTypes = {
  programId: programIdType,
  programTitle: PropTypes.string,
};

Share.defaultProps = {
  programId: 0,
  programTitle: '',
};
