import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

export default function SubmissionSuccess() {
  const {
    meta: { title },
  } = useSelector(state => state.programBrochure);
  const programAlias = useSelector(state => state.auth.organization.info.alias_program);

  const contactText = `Your message about ${title} has been sent to the ${programAlias} Administrators and/or ${programAlias} Contacts.`;
  useEffect(()=>{
    document.getElementById("successMessageModal").focus();
  },[])
  return (
    <div id="successMessageModal" tabIndex={0}>
      <svg
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 87 100"
        aria-hidden="true"
        style={{ width: '5.4375rem', marginBottom: '2.875rem' }}
      >
        <path
          d="M15 54.999H6.667a5 5 0 0 0-5 5V93.33a5 5 0 0 0 5 5H15a5 5 0 0 0 5-5V60a5 5 0 0 0-5-5Z"
          fill="#D74B73"
        />
        <path d="M15 54.999h-5a5 5 0 0 1 5 5V93.33a5 5 0 0 1-5 5h5a5 5 0 0 0 5-5V60a5 5 0 0 0-5-5Z" fill="#C74368" />
        <path
          d="M74.998 78.332h-3.333a5 5 0 1 1 0 10h-3.333a5 5 0 1 1 0 9.999h-30c-2.55.04-5.084-.407-7.466-1.317-1.633-.65-3.067-1.7-4.65-2.466a12.469 12.469 0 0 0-5.383-1.217H20V60a17.722 17.722 0 0 0 9.566-2.817c6.017-3.833 15.133-10.8 13.166-19.666-.666-2.983-.916-5.9 2.267-7.517 3.533-1.8 6.916.484 8.566 3.367 5.55 9.716-4.15 24.966-4.15 24.966h23.917a5 5 0 1 1 0 10h1.666a5 5 0 0 1 0 10Z"
          fill="#FFE0B2"
        />
        <path
          d="M78.532 76.865a5 5 0 0 0-3.534-8.533h-1.666a5 5 0 0 0 0-10h-5a5 5 0 0 1 0 10h1.666a5 5 0 1 1 0 10h-3.333a5 5 0 0 1 0 10h-3.333a5 5 0 1 1 0 10h5a5 5 0 1 0 0-10h3.333a5 5 0 0 0 0-10h3.333a4.988 4.988 0 0 0 3.534-1.467ZM48.566 33.366c3.703 6.483.616 15.431-1.849 20.666a3 3 0 0 0 2.699 4.3s9.7-15.25 4.15-24.966c-1.639-2.865-4.989-5.132-8.5-3.397a8.473 8.473 0 0 1 3.5 3.397Z"
          fill="#DFC49C"
        />
        <path
          d="M80.03 18.333c-15.016 0-15.016-16.666-15.016-16.666s0 16.666-15.015 16.666c15.015 0 15.015 16.666 15.015 16.666s0-16.666 15.016-16.666ZM30 33.333c-10 0-10-11.667-10-11.667s0 11.667-10 11.667c10 0 10 11.666 10 11.666s0-11.666 10-11.666Z"
          fill="#FBC56D"
        />
        <path
          d="M81.665 73.332a6.645 6.645 0 0 0-3.277-5.709 6.588 6.588 0 0 0 1.61-4.291 6.667 6.667 0 0 0-6.666-6.667H52.279c2.606-4.963 7.343-16.058 2.733-24.127-2.028-3.545-6.267-6.319-10.768-4.025-4.697 2.386-3.645 7.101-3.138 9.364 1.291 5.807-2.89 11.834-12.43 17.9a16.166 16.166 0 0 1-7.278 2.453A6.655 6.655 0 0 0 15 53.332H6.667A6.667 6.667 0 0 0 0 60V93.33a6.667 6.667 0 0 0 6.667 6.667H15a6.667 6.667 0 0 0 6.42-4.967c1.415.075 2.802.424 4.083 1.03.643.305 1.258.667 1.875 1.023.92.562 1.878 1.057 2.868 1.484 2.58.984 5.325 1.47 8.087 1.43h29.999a6.667 6.667 0 0 0 5.523-10.395 6.636 6.636 0 0 0 3.333-10 6.598 6.598 0 0 0 4.477-6.271ZM15 96.664H6.667a3.333 3.333 0 0 1-3.334-3.333V60a3.333 3.333 0 0 1 3.334-3.334H15A3.333 3.333 0 0 1 18.333 60V93.33A3.333 3.333 0 0 1 15 96.664Zm62.353-20.977c-.622.628-1.47.98-2.355.978H63.332a1.667 1.667 0 1 0 0 3.333h8.333a3.333 3.333 0 0 1 0 6.667h-8.333a1.667 1.667 0 1 0 0 3.333h5a3.333 3.333 0 0 1 0 6.666h-30a18.416 18.416 0 0 1-6.833-1.186 19.946 19.946 0 0 1-2.448-1.277c-.693-.403-1.39-.803-2.112-1.148a14.253 14.253 0 0 0-5.273-1.334V61.584a19.534 19.534 0 0 0 8.798-3C41.314 51.68 45.99 44.469 44.36 37.146c-.753-3.372-.415-4.748 1.397-5.667 2.94-1.5 5.385 1 6.363 2.709 4.981 8.723-4.018 23.1-4.11 23.246a1.666 1.666 0 0 0 1.407 2.565h23.916a3.333 3.333 0 1 1 0 6.666h-10a1.667 1.667 0 1 0 0 3.333h11.666a3.333 3.333 0 0 1 2.355 5.689ZM65.014 0c-.902 0-1.64.717-1.667 1.618v.049c0 .613-.152 15-13.348 15a1.667 1.667 0 0 0 0 3.332c13.145 0 13.346 14.39 13.348 15a1.667 1.667 0 0 0 3.333.045V35c0-.613.152-15 13.35-15a1.667 1.667 0 1 0 0-3.333c-13.147 0-13.347-14.39-13.35-15C66.68.747 65.934 0 65.014 0Zm7.418 18.333a15.833 15.833 0 0 0-7.418 8.393 15.823 15.823 0 0 0-7.419-8.393 15.823 15.823 0 0 0 7.419-8.393 15.833 15.833 0 0 0 7.418 8.393Z"
          fill="#373737"
        />
        <path
          d="M21.666 21.666v-.01a1.667 1.667 0 0 0-3.333.01c0 .409-.14 10-8.333 10a1.667 1.667 0 0 0 0 3.333c8.193 0 8.333 9.595 8.333 10v.01a1.667 1.667 0 0 0 3.333-.01c0-.408.14-10 8.333-10a1.667 1.667 0 0 0 0-3.333c-8.193 0-8.333-9.595-8.333-10Zm2.24 11.667A11.202 11.202 0 0 0 20 37.854a11.202 11.202 0 0 0-3.907-4.521A11.19 11.19 0 0 0 20 28.81a11.192 11.192 0 0 0 3.906 4.522ZM79.998 36.666a1.667 1.667 0 1 0-3.333 0v3.333a1.667 1.667 0 1 0 3.333 0v-3.333ZM78.332 51.666c.92 0 1.666-.747 1.666-1.667v-3.333a1.667 1.667 0 1 0-3.333 0v3.333c0 .92.746 1.666 1.667 1.666ZM84.998 44.999a1.667 1.667 0 1 0 0-3.333h-3.333a1.667 1.667 0 1 0 0 3.333h3.333ZM74.998 41.666h-3.333a1.667 1.667 0 1 0 0 3.333h3.333a1.667 1.667 0 1 0 0-3.333ZM36.32 15.345l2.5 2.5a1.667 1.667 0 0 0 2.357-2.357l-2.5-2.5a1.667 1.667 0 1 0-2.356 2.357ZM31.32 10.345a1.667 1.667 0 0 0 2.358-2.357l-2.5-2.5a1.667 1.667 0 0 0-2.357 2.357l2.5 2.5ZM28.821 17.845c.65.65 1.706.65 2.357 0l2.5-2.5a1.667 1.667 0 1 0-2.357-2.357l-2.5 2.5c-.65.65-.65 1.706 0 2.357ZM37.5 10.833c.441 0 .865-.176 1.177-.488l2.5-2.5a1.667 1.667 0 1 0-2.356-2.357l-2.5 2.5a1.667 1.667 0 0 0 1.178 2.845Z"
          fill="#373737"
        />
      </svg>
      <h2 id="modal_title" aria-label='Message Sent'>Message Sent</h2>
      <p>{contactText}</p>
    </div>
  );
}
