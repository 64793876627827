import React, { lazy, Suspense, useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import _get from 'lodash/get';
import { Controller, useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import LogicTab from './logic.tab';
import DropdownSelect from '../../../../../styledComponents/styles/DropdownSelect.styled';
import { AccentButtonMediumNunito, ButtonMediumNunito } from '../../../../../styledComponents/styles/Buttons.styled';
import { AccentButtonSpinner } from '../../../../../styledComponents/styles/spinner.styled';
import StyledIcon from '../../../../../styledComponents/styles/Icon.styled';
import TextInput from '../../../../../styledComponents/styles/TextInput.styled';
import Toggle from '../../../../../styledComponents/styles/Toggle.styled';
import DeleteQuestionModal from '../../../pages/admin/modals/DeleteQuestion.modal';
import { updateQuestion } from '../../../../../actions/formsActions';

const EditTextArea = lazy(() => import('../../../../../styledComponents/styles/EditTextArea.styled'));

const InputContainer = styled.div`
  flex: 1;
  padding: 20px 0;
  text-align: left;
`;

const SubmitContainer = styled.main`
  display: flex;
  justify-content: flex-end;
  margin-top: 35px;
`;

const SubmitLogicContainer = styled.main`
  display: flex;
  justify-content: space-between;
  margin-top: 35px;
`;

const ToggleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: inherit;
`;

const ToggleLabel = styled.span`
  align-self: center;
  flex: 1;
  justify-content: center;
  padding-left: 10px;
  padding-bottom: 7px;
`;

export default function LogicForm({ questionOptions, question }) {
  const MAX_LENGTH = 90;
  const dispatch = useDispatch();
  const [logicStep, setLogicStep] = useState(1);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const fetchForm = useSelector(state => state.forms.fetchForm);
  const ifAnswerOptions = [{ label: 'Equals', value: 'equals' }, { label: 'Does Not Equal', value: 'does_not_equal' }];

  const thenDisplayOptions = [
    { label: 'Short Text', value: 'short_text', save: 'ShortTextQuestion' },
    { label: 'Long Text', value: 'long_text', save: 'LongTextQuestion' },
    { label: 'File Upload', value: 'file_upload', save: 'FileUploadQuestion' },
  ];
  const defaultLogicQuestion = question?.dropdown_question_logic || question?.single_choice_question_logic || null;
  const [defaultQuestionType, setDefaultQuestionType] = useState(
    defaultLogicQuestion?.id ? defaultLogicQuestion.child_question.question_type_identifier : 'short_text',
  );
  const defaultSelectedType = thenDisplayOptions.filter(option => option.value === defaultQuestionType);
  const [defaultQuestionTypeText, setDefaultQuestionTypeText] = useState(defaultSelectedType[0].label);
  const [defaultTrigger, setDefaultTrigger] = useState(
    defaultLogicQuestion?.id ? defaultLogicQuestion.trigger : 'equals',
  );
  const [defaultOptionId, setDefaultOptionId] = useState(
    defaultLogicQuestion?.id
      ? defaultLogicQuestion?.dropdown_question_option_id || defaultLogicQuestion?.correct_option
      : null,
  );

  const schema = yup.object().shape({
    trigger: yup.string().required('If answer is required'),
    question_option_id: yup.string().required('Question option is required'),
    child_question_type: yup.string().required('Then display is required'),
    label: yup
      .string()
      .required('Question must have label')
      .max(MAX_LENGTH, `Label can not be more than ${MAX_LENGTH} characters`),
    instructions: yup.string(),
    char_limit: yup
      .number()
      .integer('Limit must be positive numerical (i.e. 5000)')
      .typeError('Limit must be positive numerical (i.e. 5000)')
      .min(0, 'Limit must be positive numerical (i.e. 5000)')
      .nullable()
      .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value)),
    required: yup.boolean(),
  });

  const { control, handleSubmit, formState, reset, setValue } = useForm({
    shouldUnregister: false,
    resolver: yupResolver(schema),
    defaultValues: {
      trigger: defaultTrigger,
      question_option_id: defaultOptionId,
      child_question_type: defaultQuestionType,
      label: defaultLogicQuestion?.id ? defaultLogicQuestion.child_question.label : '',
      instructions: defaultLogicQuestion?.id ? defaultLogicQuestion.child_question.instructions : '',
      char_limit: defaultLogicQuestion?.id ? defaultLogicQuestion.child_question.character_limit : 90,
      required: defaultLogicQuestion?.id ? defaultLogicQuestion.child_question.required : true,
    },
    mode: 'onChange',
  });

  const onSubmitHandler = data => {
    const questionType = thenDisplayOptions.filter(option => option.value === data.child_question_type);
    let parentQuestionEndpoint = 'dropdown_questions';
    parentQuestionEndpoint =
      question.question_type_identifier === 'single_choice' ? 'single_choice_questions' : parentQuestionEndpoint;

    //New logic question
    if (!defaultLogicQuestion?.id) {
      const logicValues = {
        child_question_attributes: {
          character_limit: data.char_limit,
          instructions: data.instructions,
          label: data.label,
          required: data.required,
          template_id: question?.template_id,
        },
        child_question_type: questionType[0].save,
        dropdown_question_option_id: data.question_option_id,
        correct_option: data.question_option_id,
        trigger: data.trigger,
      };

      if (data.child_question_type === 'file_upload') {
        delete logicValues.child_question_attributes.character_limit;
      }

      let values = {};
      if (parentQuestionEndpoint === 'dropdown_questions') {
        delete logicValues.correct_option;
        values = {
          dropdown_question_logic_attributes: logicValues,
        };
      } else if (parentQuestionEndpoint === 'single_choice_questions') {
        delete logicValues.dropdown_question_option_id;
        values = {
          single_choice_question_logic_attributes: logicValues,
        };
      }
      setDisableSubmit(true);
      // new logic add
      dispatch(updateQuestion(question?.template_id, parentQuestionEndpoint, question.id, values));
    } else {
      //existing logic question
      let values = {
        admin_only: false,
        character_limit: data.char_limit,
        instructions: data.instructions,
        label: data.label,
        required: data.required,
      };
      let questionEndpoint =
        data.child_question_type === 'long_text'
          ? 'long_text_questions'
          : data.child_question_type === 'short_text'
            ? 'short_text_questions'
            : 'file_upload_questions';

      if (data.child_question_type === 'file_upload') {
        delete values.character_limit;
      }
      setDisableSubmit(true);
      //edit logic question
      dispatch(
        updateQuestion(question?.template_id, questionEndpoint, defaultLogicQuestion?.child_question?.id, values),
      );
    }
  };

  useEffect(
    () => {
      if (!fetchForm?.loading && fetchForm?.data) {
        setDisableSubmit(false);
      }
    },
    [fetchForm],
  );

  return (
    <form id={'logicQuestion'} onSubmit={handleSubmit(onSubmitHandler)}>
      {logicStep === 1 && (
        <>
          {!!defaultLogicQuestion && (
            <DeleteQuestionModal
              clearLogic
              question={defaultLogicQuestion.child_question}
              formId={question?.template_id?.toString()}
            />
          )}
          <Controller
            control={control}
            name={'trigger'}
            render={({ field: { onChange, value, name, ref, ...rest } }) => (
              <InputContainer>
                <DropdownSelect
                  aria-label="If answer"
                  errorMsg={_get(formState.errors.trigger, 'message') || ''}
                  label={`If answer`}
                  id={'trigger'}
                  options={ifAnswerOptions}
                  onChange={value => {
                    setDefaultTrigger(value);
                    setValue('trigger', value, {
                      shouldValidate: true,
                      shouldDirty: true,
                      shouldTouch: true,
                    });
                    onChange(value);
                  }}
                  defaultValue={ifAnswerOptions.filter(option => option.value === defaultTrigger)}
                  openSans
                  readOnly={defaultLogicQuestion?.id}
                  {...rest}
                />
              </InputContainer>
            )}
          />
          <Controller
            control={control}
            name={'question_option_id'}
            render={({ field: { onChange, value, name, ref, ...rest } }) => (
              <InputContainer>
                <DropdownSelect
                  aria-label="Select Option"
                  errorMsg={_get(formState.errors.question_option_id, 'message') || ''}
                  placeholder={`Select Option`}
                  label={``}
                  id={'question_option_id'}
                  options={questionOptions}
                  onChange={value => {
                    setDefaultOptionId(value);
                    setValue('question_option_id', value, {
                      shouldValidate: true,
                      shouldDirty: true,
                      shouldTouch: true,
                    });
                    onChange(value);
                  }}
                  defaultValue={questionOptions.filter(option => option.value === defaultOptionId)}
                  readOnly={defaultLogicQuestion?.id}
                  {...rest}
                  nunitoFont
                />
              </InputContainer>
            )}
          />
          <Controller
            control={control}
            name={'child_question_type'}
            render={({ field: { onChange, value, name, ref, ...rest } }) => (
              <InputContainer>
                <DropdownSelect
                  aria-label="Then display Option"
                  errorMsg={_get(formState.errors.child_question_type, 'message') || ''}
                  placeholder={`Select Question`}
                  label={`Then display`}
                  id={'child_question_type'}
                  options={thenDisplayOptions}
                  onChange={value => {
                    const selected = thenDisplayOptions.filter(option => option.value === value);
                    setDefaultQuestionType(value);
                    setDefaultQuestionTypeText(selected[0].label);
                    setValue('child_question_type', value, {
                      shouldValidate: true,
                      shouldDirty: true,
                      shouldTouch: true,
                    });
                    onChange(value);
                  }}
                  defaultValue={thenDisplayOptions.filter(option => option.value === defaultQuestionType)}
                  readOnly={defaultLogicQuestion?.id}
                  {...rest}
                  nunitoFont
                />
              </InputContainer>
            )}
          />
          <SubmitContainer>
            <AccentButtonMediumNunito
              aria-label="Next"
              disabled={!defaultOptionId}
              onClick={() => setLogicStep(2)}
              type={'button'}
            >
              Next
              <StyledIcon type={'ChevronForward'} color={'#fff'} />
            </AccentButtonMediumNunito>
          </SubmitContainer>
        </>
      )}
      {logicStep === 2 && (
        <>
          <Controller
            control={control}
            name={'label'}
            render={({ field: { ref, ...rest } }) => (
              <InputContainer>
                <TextInput
                  aria-label={`${defaultQuestionTypeText} Label`}
                  errorMsg={_get(formState.errors.label, 'message') || ''}
                  id={'label'}
                  label={`${defaultQuestionTypeText} Label`}
                  openSans
                  placeholder={`Enter ${defaultQuestionTypeText} Label`}
                  required
                  {...rest}
                />
              </InputContainer>
            )}
          />
          <Controller
            control={control}
            name={'instructions'}
            render={({ field: { ref, ...rest } }) => (
              <InputContainer>
                <Suspense fallback={<div />}>
                  <EditTextArea
                    aria-label="Instructions"
                    errorMsg={_get(formState.errors.instructions, 'message') || ''}
                    placeholder={`Enter Instructions`}
                    label={`Instructions`}
                    id={'dropdown_instructions'}
                    {...rest}
                  />
                </Suspense>
              </InputContainer>
            )}
          />
          {defaultQuestionType !== 'file_upload' && (
            <Controller
              control={control}
              name={'char_limit'}
              render={({ field: { ref, ...rest } }) => (
                <InputContainer>
                  <TextInput
                    aria-label={'Short Text Label'}
                    errorMsg={_get(formState.errors.char_limit, 'message') || ''}
                    id={'char_limit'}
                    label={'Set Character Limit'}
                    openSans
                    placeholder={'Enter character limit'}
                    type={'number'}
                    {...rest}
                  />
                </InputContainer>
              )}
            />
          )}
          <Controller
            control={control}
            name={'required'}
            render={({ field: { onChange, register, ref, ...rest } }) => (
              <ToggleContainer>
                <Toggle
                  id={'required'}
                  label={'Required'}
                  onChange={value => {
                    setValue('required', value, {
                      shouldValidate: true,
                      shouldDirty: true,
                      shouldTouch: true,
                    });
                  }}
                  ref={register}
                  {...rest}
                />
                <ToggleLabel>Required Field</ToggleLabel>
              </ToggleContainer>
            )}
          />

          <SubmitLogicContainer>
            <ButtonMediumNunito aria-label="Next" type={'button'} onClick={() => setLogicStep(1)}>
              <StyledIcon type={'ChevronBack'} color={'#373737'} />
              Back
            </ButtonMediumNunito>
            <AccentButtonMediumNunito aria-label="Next" form="logicQuestion" type={'submit'} disabled={disableSubmit} >
              {disableSubmit ? <AccentButtonSpinner displayText='Saving ...' /> : 'Save'}
              {!disableSubmit && <StyledIcon type='ChevronForward' color='#fff' />}
            </AccentButtonMediumNunito>
          </SubmitLogicContainer>
        </>
      )}
    </form>
  );
}

LogicTab.LogicForm = {
  questionOptions: PropTypes.array.isRequired,
  question: PropTypes.object,
};
