import React from 'react';

export default function ShortTermLength() {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
      <path
        d="M6.01074 23.5859H2.42188V18.8893C2.42188 18.6048 2.6416 18.3721 2.91016 18.3721H5.52246C5.79102 18.3721 6.01074 18.6048 6.01074 18.8893V23.5859Z"
        fill="#EE7A9B"
      />
      <path d="M2.42188 22.6504H6.01562V23.5814H2.42188V22.6504Z" fill="#EE7A9B" />
      <path
        d="M19.126 4.7017L20.6543 5.82929L20.1416 0.418945L15.1758 1.78963L16.7041 2.91722C16.7041 2.91722 13.9063 8.69998 3.78907 12.0052C3.26661 12.1758 3.4131 12.9931 3.95509 12.9517C8.00782 12.6517 14.6973 11.1207 19.126 4.7017Z"
        fill="#A82425"
      />
      <path
        d="M18.6914 4.37077C18.584 4.28801 18.4326 4.31387 18.3545 4.42766C13.8037 10.9087 7.96388 11.7208 3.95509 12.0156C3.8965 12.0208 3.84279 12.0156 3.79396 12.0001H3.78908C3.2715 12.1708 3.41798 12.988 3.95997 12.9466C8.01271 12.6466 14.7022 11.1156 19.1309 4.69663L18.6914 4.37077Z"
        fill="#A82425"
      />
      <path
        d="M6.4209 23.1982H6.37695V18.8895C6.37695 18.3878 5.99121 17.9844 5.52246 17.9844H2.91016C2.43652 17.9844 2.05566 18.393 2.05566 18.8895V23.1982H0.366211C0.161133 23.1982 0 23.374 0 23.5861C0 23.8033 0.166016 23.974 0.366211 23.974H6.4209V23.1982ZM5.64453 23.1982H2.78809V18.8895C2.78809 18.8171 2.8418 18.7602 2.91016 18.7602H5.52246C5.59082 18.7602 5.64453 18.8171 5.64453 18.8895V23.1982Z"
        fill="#373737"
      />
      <path
        d="M3.91602 13.3394C3.93556 13.3394 3.95509 13.3394 3.97462 13.3394C6.6797 13.1377 9.22364 12.517 11.5381 11.4825C14.6533 10.0912 17.2315 7.99116 19.2041 5.23944L20.4395 6.14978C20.6836 6.34116 21.0547 6.1084 21.0156 5.79288L20.5078 0.377366C20.4932 0.144607 20.2588 -0.0260823 20.0488 0.0411591L15.083 1.41185C14.7852 1.48426 14.7119 1.93426 14.9658 2.10495L16.2109 3.02564C15.9619 3.43944 15.415 4.25668 14.4629 5.26012C14.3213 5.41012 14.3164 5.6584 14.4629 5.8084C14.6045 5.9584 14.8389 5.96357 14.9805 5.8084C16.4502 4.26185 17.0068 3.13426 17.0313 3.08771C17.1143 2.91702 17.0654 2.69978 16.9141 2.59116L16.04 1.94978L19.8242 0.904952L20.2149 5.02219L19.3359 4.38081C19.1699 4.26185 18.9502 4.29806 18.8281 4.46875C16.9043 7.25668 14.3555 9.37219 11.2549 10.7636C8.46681 12.0101 5.73243 12.4291 3.92579 12.5636C3.81837 12.5946 3.78907 12.3877 3.89161 12.3774C7.91017 11.0636 11.2695 9.21702 13.8721 6.88426C14.0283 6.74461 14.0479 6.5015 13.916 6.33599C13.7842 6.17047 13.5547 6.14978 13.3984 6.28943C10.874 8.54978 7.60255 10.3498 3.67677 11.6325C3.27149 11.767 3.03224 12.186 3.11036 12.6308C3.1836 13.0498 3.52052 13.3394 3.91602 13.3394Z"
        fill="#373737"
      />
    </svg>
  );
}
