exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@-webkit-keyframes zguTT{to{-webkit-transform:rotate(1turn)}}@keyframes zguTT{to{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}._3d2OL{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;width:100%;border-left:2px solid #ebebeb;padding:0 16px;margin-bottom:52px}._3d2OL ._1LKWD{-ms-flex:1 1;flex:1 1}._3d2OL ._1d9wx,._3d2OL ._2fYsQ,._3d2OL ._3qdWD{-ms-flex:1 1;flex:1 1;padding-top:32px}._3d2OL ._3qdWD ._3mQSv{text-decoration:underline;cursor:pointer}._3d2OL ._1Ip4n{-ms-flex:1 1;flex:1 1;padding:32px 12px 0 0}.h-vhB{display:-ms-flexbox;display:flex;margin-top:10px;gap:20px;width:100%;border-bottom:1px solid #ccc;border-bottom:1px solid var(--gray-light,#ccc);padding-bottom:10px}._6qkBN{width:75%}._146pG{font-size:14px;font-weight:700;letter-spacing:.035px}._1EHvU,._146pG{color:#373737;color:var(--black,#373737);font-family:Open Sans;font-style:normal;line-height:120%}._1EHvU{font-size:12px;font-weight:400;letter-spacing:.048px}._15pa1{display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center}._1ozdF{margin-top:5px}._1zj7y{border:none;background:none}._1zj7y span{color:#255a94;color:var(--info-text,#255a94);font-family:Nunito;font-size:15px;font-style:normal;font-weight:700;line-height:normal;-webkit-text-decoration-line:underline;text-decoration-line:underline;-webkit-text-decoration-style:solid;text-decoration-style:solid;text-decoration-skip-ink:none;text-decoration-thickness:auto;text-underline-offset:auto;text-underline-position:from-font;margin-left:10px}", ""]);

// exports
exports.locals = {
	"ov-right-nav": "_3d2OL",
	"ov-right-nav-dates": "_1LKWD",
	"ov-right-nav-locations": "_2fYsQ",
	"ov-right-nav-type": "_1d9wx",
	"ov-right-nav-program": "_3qdWD",
	"ov-right-nav-associate-program": "_3mQSv",
	"ov-right-nav-action": "_1Ip4n",
	"planList": "h-vhB",
	"planInfoContainer": "_6qkBN",
	"planTitle": "_146pG",
	"planDeadline": "_1EHvU",
	"planAction": "_15pa1",
	"buttonContainer": "_1ozdF",
	"addFormBotton": "_1zj7y",
	"spin": "zguTT"
};