import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';

import InfoIcon from '../../icons/InfoIcon';
import ProfilePictureCropperModal from '../../modals/ProfilePictureCropperModal';

import { breakpoints } from '../../../utils/breakpoints';
import { buttonFocus } from '../../../utils/shared/focusStyles';
import InputError from '../../base/inputs/InputError';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.8125rem;

  @media ${breakpoints.tablet} {
    flex-direction: row;
  }
`;

const Avatar = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
`;

const UploadContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 1.11rem;
`;

const LabelContainer = styled.div`
  display: flex;
  align-items: baseline;
  gap: 1.1875rem;
`;

const ErrorContainer = styled.div`
  @media ${breakpoints.tablet} {
    position: absolute;
    top: 100%;
  }
`;

const InputLabel = styled.label`
  padding: 0.75rem 1rem;
  background-color: #446372;
  border: 1px solid transparent;
  border-radius: 5px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  color: #ffffff;
  line-height: 120%;
  letter-spacing: 0.06em;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }

  ${buttonFocus};
`;

const FileInput = styled.input`
  display: none;
`;

const Tooltip = styled.button`
  position: relative;
  background-color: transparent;
  border: none;

  &:hover,
  &:focus {
    span {
      visibility: visible;
    }
  }

  ${buttonFocus};
`;

const TooltipText = styled.span`
  position: absolute;
  right: -90%;
  bottom: 90%;
  z-index: 1;
  visibility: hidden;
  width: 200px;
  margin-left: 0.3125rem;
  padding: 0.625rem;
  background-color: #ffffff;
  border: 1px solid #d7d7d7;
  border-radius: 3px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
  font-family: 'Nunito', sans-serif;
  font-weight: 400;
  font-size: 0.8125rem;
  text-align: center;
  color: #373737;
  line-height: 18px;

  @media ${breakpoints.tablet} {
    left: 50%;
  }
`;

export default function ProfilePictureForm({ avatarForm, setAvatarForm }) {
  const { profile } = useSelector(state => state.travelerProfile);

  const [error, setError] = useState('');
  const [croppedImage, setCroppedImage] = useState('');
  const [showCropper, setShowCropper] = useState(false);

  useEffect(
    () => {
      setAvatarForm({
        url: profile.avatar.url,
      });
    },
    [profile.avatar.url],
  );

  useEffect(
    () => {
      if (croppedImage) {
        setAvatarForm({
          url: croppedImage,
        });
      }
    },
    [croppedImage],
  );

  const triggerHiddenInput = keyboardEvent => {
    if ([13, 32].includes(keyboardEvent.keyCode)) {
      keyboardEvent.preventDefault();

      const clickEvent = new MouseEvent('click');

      document.getElementById('avatar').dispatchEvent(clickEvent);
    }
  };

  const handleOnChange = event => {
    if (!event.target.files.length) {
      return;
    }

    const avatar = event.target.files[0];

    if (avatar.size > 10000000) {
      setError('Image is too large, max is 10MB.');

      return;
    }

    const reader = new FileReader();

    reader.readAsDataURL(avatar);

    reader.onload = event => {
      setError('');

      setAvatarForm({
        url: event.target.result,
      });

      setShowCropper(true);
    };
  };

  return (
    <>
      <Container>
        <Avatar src={avatarForm.url} alt="" />
        <UploadContainer>
          <LabelContainer>
            <InputLabel htmlFor="avatar" tabIndex={0} onKeyDown={triggerHiddenInput}>
              <span>Upload Photo</span>
              <FileInput id="avatar" type="file" name="avatar" accept="image/*" onChange={handleOnChange} />
            </InputLabel>
            <Tooltip type="button" aria-describedby="image_hint" aria-label="Learn about image requirements">
              <InfoIcon />
              <TooltipText id="image_hint">
                {`Image's ideal size: 300px by 300px. Compatible file types: .png, .jpeg, .jpg. File size cannot be larger than 10mb.`}
              </TooltipText>
            </Tooltip>
          </LabelContainer>
          {error && (
            <ErrorContainer>
              <InputError message={error} />
            </ErrorContainer>
          )}
        </UploadContainer>
      </Container>
      <ProfilePictureCropperModal
        show={showCropper}
        setShow={setShowCropper}
        previewImage={avatarForm.url}
        setCroppedImage={setCroppedImage}
      />
    </>
  );
}

ProfilePictureForm.propTypes = {
  avatarForm: PropTypes.shape({
    url: PropTypes.string,
  }).isRequired,
  setAvatarForm: PropTypes.func.isRequired,
};
