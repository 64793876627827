import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import s from './InputField.css';
import classnames from 'classnames';

const InputField = ({
  type,
  name,
  value,
  label,
  placeholder,
  onChange,
  disabled,
  onKeyPress,
  onFocus,
  className,
  min,
  max,
  inputClass,
  title,
  autoFocus,
}) => {
  return (
    <div className={ classnames(s[className], s.inputWrapper) }>
      <input
        name={name}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        disabled={disabled}
        onKeyPress={onKeyPress}
        onFocus={onFocus}
        className={inputClass}
        title={title}
        min={min}
        autoFocus={autoFocus}
      />
      <label className={s.label}>{label}</label>
    </div>
  );
};

InputField.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  onKeyPress: PropTypes.func,
  onFocus: PropTypes.func,
  className: PropTypes.string,
};

InputField.defaultProps = {
  type: 'text',
  disabled: false,
  className: '',
  min: '',
  max: '',
};

export default withStyles(s)(InputField);
