import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';

import IntakeSection from './IntakeSection';

import participationCertaintyOptions from '../../utils/options/participationCertaintyOptions';
import certaintyOptions from '../../../travelerForms/utils/intake/certaintyOptions';

export default function IntakeCertainty() {
  const intake = useSelector(state => state.intakeForm);

  const [certainty, setCertainty] = useState('');

  useEffect(
    () => {
      if (intake.travel_certainty !== null) {
        const value = certaintyOptions.find(option => [option.numberValue, option.value].includes(intake.travel_certainty));

      if (value) {
        setCertainty(value.label);
      }
    }
  }, [intake.travel_certainty]);

  return (
    <IntakeSection heading="Participation Certainty">
      <p>{certainty || 'None selected'}</p>
    </IntakeSection>
  );
}
