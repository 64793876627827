import React from 'react';
import { compose } from 'redux';
import PhoneInput from 'react-phone-input-2';
import s from './CellPhoneInput.scss';
import telephone from 'react-phone-input-2/lib/style.css';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

function CellPhoneInput(props) {
  return (
    <div
      className={s.inputWrapper}
      style={!props.value ? { marginTop: '40px' } : { marginTop: '' }}
    >
      {props.value ? (
        <label className={s.visibleLabel}>{props.label}</label>
      ) : (
        <label className={s.hiddenLabel}>{props.label}</label>
      )}
      <PhoneInput
        id={props.name}
        name={props.name}
        country={''}
        enableAreaCodes={false}
        disableDropdown={false}
        jumpCursorToEnd={true}
        value={props.value}
        placeholder={props.value ? props.value : props.label}
        onChange={phone => {
          props.onChange(phone);
        }}
      />
    </div>
  );
}
export default compose(withStyles(s, telephone))(CellPhoneInput);
