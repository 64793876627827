import React from 'react';
import Modal from 'react-bootstrap/Modal';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import styles from './index.module.css';
import warningIcon from '../../shared/images/warningIcon.png';
import BaseModalHeader from '../baseModal/BaseModalHeader';
import BaseModalFooter from '../baseModal/BaseModalFooter';

function WarningModalContent({
  onCancel,
  onSubmit,
  submitBtnText = 'Continue',
  cancelBtnText = 'Cancel',
  text = '',
  text2 = '',
  textBold = '',
  loadingBtn = false,
}) {
  return (
    <>
      <BaseModalHeader heading="Warning" onClose={onCancel} showYellowBorderStripe={true} />
      <Modal.Body className={styles.modalBody}>
        <div className={styles.container}>
          <div className={styles.imageContainer}>
            <img src={warningIcon} className={styles.img} />
          </div>
          <div>
            <div className={styles.text}>{text}</div>

            {text2 && <>
              <br/>
              <div className={styles.text}>{text2}</div>
            </>}

            <div className={styles.textBold}>{textBold}</div>
          </div>
        </div>


        <BaseModalFooter mainBtnText={submitBtnText} cancelBtnText={cancelBtnText} onSubmit={onSubmit} onCancel={onCancel} loadingBtn={loadingBtn} />
      </Modal.Body>
    </>
  );
}

export default withStyles(styles)(WarningModalContent);
