exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ":root{--dark:var(--theme_color_dark);--accent:var(--theme_color_accent);--ocean:var(--theme_color_light)}.mcA8Q{display:block;position:relative;margin-bottom:14px}input[type=tel]:focus{outline:none;padding-bottom:5px;background:#f6f6f6;border-bottom:3px dashed hsla(0,0%,61%,.4)!important}::-webkit-input-placeholder{font-family:AvenirNextRegular,sans-serif;color:hsla(0,0%,61%,.6)!important;font-size:20px;font-size:1.25rem}::-moz-placeholder{font-family:AvenirNextRegular,sans-serif;color:hsla(0,0%,61%,.6)!important;font-size:20px;font-size:1.25rem}::-ms-input-placeholder{font-family:AvenirNextRegular,sans-serif;color:hsla(0,0%,61%,.6)!important;font-size:20px;font-size:1.25rem}::placeholder{font-family:AvenirNextRegular,sans-serif;color:hsla(0,0%,61%,.6)!important;font-size:20px;font-size:1.25rem}._3opjO,._3Ow9S{font-family:AvenirNextRegular,sans-serif;top:2px;left:0;padding-left:20px;font-size:12px;font-size:.75rem;color:#9b9b9b;z-index:0;pointer-events:none;cursor:text;text-transform:capitalize}._3Ow9S{display:none}input:disabled{background-color:hsla(0,0%,61%,.1)!important}", ""]);

// exports
exports.locals = {
	"inputWrapper": "mcA8Q",
	"visibleLabel": "_3opjO",
	"hiddenLabel": "_3Ow9S"
};