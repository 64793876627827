import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import MountainFlagIcon from '../../icons/MountainFlagIcon';

import { breakpoints } from '../../../utils/breakpoints';
import { buttonFocus } from '../../../utils/shared/focusStyles';
import { toggleCommitModal } from '../../../actions/travelerApplicationsActions';
import ToastMessage from '../../../../viaGlobal/components/toastMessage';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 0.5rem;
  padding: 1rem 1.0625rem 1rem 1.4375rem;
  border-left-width: 20px;
  border-left-style: solid;

  @media ${breakpoints.desktop} {
    flex-direction: row;
    align-items: center;
    gap: 0;
    padding: 0.75rem 2.8575rem 0.75rem 1.435rem;
  }

  ${props => {
    if (props.status === 'Accepted') {
      return `
        background-color: #DAFDD7;
        border-left-color: #2A8A53;
      `;
    } else {
      return `
        background-color: #DEECFF;
        border-left-color: #255A94;
      `;
    }
  }};
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.75rem;

  svg {
    width: 53px;
    height: 49px;
  }

  @media ${breakpoints.desktop} {
    flex-direction: row;
    align-items: center;
    gap: 1.588125rem;
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.375rem;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  color: #4d4d4d;
  letter-spacing: 0.005em;
  line-height: 120%;

  p {
    margin-bottom: 0;
  }
`;

const InfoHeading = styled.p`
  font-weight: 600;
`;

const CommitButton = styled.button`
  padding: 0;
  background-color: transparent;
  border: none;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 1rem;
  color: #446372;
  text-decoration-line: underline;
  letter-spacing: -0.02em;
  line-height: 22px;

  &:hover {
    text-decoration-line: none;
  }

  ${buttonFocus};
`;

export default function CommitBanner({isAdmin}) {
  const dispatch = useDispatch();
  const {
    customAliases: { 
      alias_program: programAlias,
      alias_traveler: travelerAlias,
    },
  } = useSelector(state => state.currentOrganization);

  const { travelerSubmission } = useSelector(state => state.forms);
  const [applicationStatus, setApplicationStatus] = useState(travelerSubmission?.data?.submission_status?.name);
  useEffect(() => {
    if (!travelerSubmission.loading && travelerSubmission.data) {
      setApplicationStatus(travelerSubmission.data.submission_status.name);
    }
  }, [travelerSubmission]);  

  const [forceShowBanner, setForceShowBanner] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  const [submitInProgress, setSubmitInProgress] = useState(false);

  const handleClick = () => {
    setSubmitInProgress(true);
    dispatch(toggleCommitModal());
  };

  useEffect(() => {
    if (applicationStatus === 'Committed' && submitInProgress) {
      //Show the success banner only "once"
      setForceShowBanner(true);
      if (isAdmin) {
        //Toast should only show for Admin
        setToastMessage(`${travelerAlias} committed`);
        setShowSuccess(true);
      }
      setSubmitInProgress(false);
    }
  }, [applicationStatus]);

  const adminString = applicationStatus === 'Committed' ? `This application has been committed.` : `This application has been accepted, select ‘Commit’ to commit on behalf of this ${travelerAlias.toLowerCase()}.`;
  
  return (
    (forceShowBanner || applicationStatus === 'Accepted') && (
      <Container status={applicationStatus}>
        <InfoContainer>
          <MountainFlagIcon />
          <Info>
            <InfoHeading>
              {isAdmin ? adminString : applicationStatus === 'Accepted'
                ? `Congratulations, you've been accepted! Next step: Commit to this ${programAlias.toLowerCase()}.`
                : `You're committed!`}
            </InfoHeading>
            {!isAdmin && (
              <p>
                {applicationStatus === 'Accepted'
                  ? `Select 'Commit' to confirm you're going.`
                  : 'Keep an eye out for messages, forms, and other tasks to complete.'}
              </p>
            )}
          </Info>
        </InfoContainer>
        {applicationStatus === 'Accepted' && (
          <CommitButton type="button" onClick={handleClick}>
            Commit
          </CommitButton>
        )}
        {toastMessage?.length > 0 && (
          <ToastMessage
            message={toastMessage}
            show={showSuccess}
            toastId="traveler-form-save-success"
            isSuccess
            onClose={() => {
              setShowSuccess(false);
              setToastMessage('');
            }}
          />
        )}
      </Container>
    )
  );
}
