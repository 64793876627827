import React from 'react';
import styled from 'styled-components';

import { breakpoints } from '../../../../utils/breakpoints';

export const TermInfoContainer = styled.ul`
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 1.25rem;
  list-style-type: none;
  padding-left: 0;

  @media ${breakpoints.tablet} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  @media ${breakpoints.desktop} {
    column-gap: 5.0625rem;
    row-gap: 1.9375rem;
    padding: 1rem 5rem;
  }

  li {
    grid-column: span 1 / span 1;
  }
`;

export const TermInfoCard = styled.div`
  box-shadow: 0 0.625rem 1.25rem rgba(132, 131, 131, 0.3);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  min-width: 20rem;

  > div:nth-of-type(1) {
    background-color: #ffffff;
    padding-top: 1.375rem;
    padding-left: 1.875rem;
    padding-right: 1.5rem;
    padding-bottom: 0.3125rem;
    flex-grow: 1;
  }
`;

export const TermInfoCardImage = styled.img`
  border-radius: 50px;
  object-fit: cover;
  height: 3.875rem;
  width: 4.0625rem;
`;

export const TermInfoCardHeading = styled.h3`
  font-family: 'Nunito', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 120%;
  letter-spacing: 0.0015em;
  color: #373737;
  margin-top: 1.6875rem;
  margin-bottom: 1.0625rem;
`;

export const TermInfoCardProviderHeading = styled.h4`
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 600;
  font-size: 0.9rem;
  line-height: 120%;
  letter-spacing: 0.0015em;
  color: #474747;
`;

export const TermInfoCardInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.125rem;
  margin-bottom: 0.9375rem;

  p {
    font-size: 0.75rem;
    line-height: 120%;
    letter-spacing: 0.0025em;
    color: #373737;
    margin-bottom: 0;
  }

  .label {
    font-family: 'Nunito', sans-serif;
    font-weight: 700;
  }

  .text {
    font-family: 'Open Sans', sans-serif;
  }
`;

export const TermInfoCardCTA = styled.div`
  background-color: #e7e7e7;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 120%;
  display: flex;
  align-items: center;
  padding-top: 1.3125rem;
  padding-bottom: 1.125rem;
  justify-content: center;
  color: #373737;
  letter-spacing: 0.0015em;
  border: solid 0.125rem transparent;
  text-transform: uppercase;

  a {
    color: #446372;

    &:focus {
      outline: none;
    }

    &:focus-visible {
      border: 0.125rem solid #255a94;
      border-radius: 2px;
    }
  }

  .active {
    text-decoration-line: underline;
  }

  ${props =>
    props.disabled &&
    `cursor: not-allowed;

  `};
`;
