import React from 'react';

export default function Clock() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
      <g clipPath="url(#clip0_993_18261)">
        <path
          d="M12 23.6485C18.4333 23.6485 23.6485 18.4333 23.6485 12C23.6485 5.56675 18.4333 0.351562 12 0.351562C5.56675 0.351562 0.351562 5.56675 0.351562 12C0.351562 18.4333 5.56675 23.6485 12 23.6485Z"
          fill="#DDAF64"
        />
        <path
          d="M12.0001 22.5207C17.8107 22.5207 22.5212 17.8102 22.5212 11.9996C22.5212 6.18897 17.8107 1.47852 12.0001 1.47852C6.18946 1.47852 1.479 6.18897 1.479 11.9996C1.479 17.8102 6.18946 22.5207 12.0001 22.5207Z"
          fill="#DDAF64"
        />
        <path
          d="M15.3818 20.2667C8.94853 20.2667 3.73336 15.0515 3.73336 8.61826C3.73336 6.26682 4.43245 4.07978 5.63072 2.24902C2.45274 4.3291 0.351562 7.91822 0.351562 12C0.351562 18.4332 5.56673 23.6484 12 23.6484C16.0818 23.6484 19.6709 21.5473 21.751 18.3692C19.9203 19.5676 17.7333 20.2667 15.3818 20.2667Z"
          fill="#DDAF64"
        />
        <path
          d="M1.479 11.9998C1.479 17.8104 6.18947 22.521 12.0002 22.521C14.8282 22.521 17.3935 21.4031 19.2842 19.5879C18.0635 20.0229 16.7522 20.2664 15.382 20.2664C8.94872 20.2664 3.73355 15.0512 3.73355 8.61803C3.73355 7.24787 3.97702 5.9364 4.41221 4.71582C2.59693 6.60643 1.479 9.1718 1.479 11.9998Z"
          fill="#BC8C3F"
        />
        <path
          d="M12.0002 21.3939C17.1882 21.3939 21.3939 17.1882 21.3939 12.0002C21.3939 6.81216 17.1882 2.60645 12.0002 2.60645C6.81216 2.60645 2.60645 6.81216 2.60645 12.0002C2.60645 17.1882 6.81216 21.3939 12.0002 21.3939Z"
          fill="#FFEFD4"
        />
        <path
          d="M11.9999 19.5154C16.1503 19.5154 19.5149 16.1508 19.5149 12.0004C19.5149 7.84994 16.1503 4.48535 11.9999 4.48535C7.84946 4.48535 4.48486 7.84994 4.48486 12.0004C4.48486 16.1508 7.84946 19.5154 11.9999 19.5154Z"
          fill="white"
        />
        <path
          d="M11.9998 13.8786C13.0374 13.8786 13.8786 13.0374 13.8786 11.9998C13.8786 10.9622 13.0374 10.1211 11.9998 10.1211C10.9622 10.1211 10.1211 10.9622 10.1211 11.9998C10.1211 13.0374 10.9622 13.8786 11.9998 13.8786Z"
          fill="#FFEFD4"
        />
        <path
          d="M11.9999 12.7518C12.4149 12.7518 12.7514 12.4154 12.7514 12.0004C12.7514 11.5854 12.4149 11.249 11.9999 11.249C11.585 11.249 11.2485 11.5854 11.2485 12.0004C11.2485 12.4154 11.585 12.7518 11.9999 12.7518Z"
          fill="#DDAF64"
        />
        <path
          d="M20.4853 3.51469C18.2187 1.24824 15.2053 0 12 0C8.79469 0 5.78123 1.24824 3.51469 3.51469C1.24824 5.78123 0 8.79469 0 12C0 15.2053 1.24824 18.2187 3.51469 20.4853C5.78123 22.7518 8.79469 24 12 24C15.2053 24 18.2187 22.7518 20.4853 20.4853C22.7518 18.2187 24 15.2053 24 12C24 8.79469 22.7518 5.78123 20.4853 3.51469ZM19.9882 19.9882C17.8544 22.1218 15.0175 23.2968 12 23.2968C8.98246 23.2968 6.1456 22.1218 4.01189 19.9882C1.87819 17.8544 0.703125 15.0175 0.703125 12C0.703125 8.98246 1.87819 6.1456 4.01189 4.01189C6.1456 1.87819 8.98246 0.703125 12 0.703125C15.0175 0.703125 17.8544 1.87819 19.9882 4.01189C22.1218 6.1456 23.2968 8.98246 23.2968 12C23.2968 15.0175 22.1218 17.8544 19.9882 19.9882Z"
          fill="#373737"
        />
        <path
          d="M12.3516 5.61242V4.10936C12.3516 3.91522 12.1942 3.75781 12 3.75781C11.8058 3.75781 11.6484 3.91522 11.6484 4.10936V5.61237C11.6484 5.80653 11.8058 5.96394 12 5.96394C12.1942 5.96394 12.3516 5.80658 12.3516 5.61242Z"
          fill="#373737"
        />
        <path
          d="M11.6484 18.3877V19.8907C11.6484 20.0848 11.8058 20.2422 12 20.2422C12.1942 20.2422 12.3516 20.0848 12.3516 19.8907V18.3877C12.3516 18.1934 12.1942 18.0361 12 18.0361C11.8058 18.0361 11.6484 18.1934 11.6484 18.3877Z"
          fill="#373737"
        />
        <path
          d="M20.2422 12C20.2422 11.8058 20.0849 11.6484 19.8907 11.6484H18.3877C18.1936 11.6484 18.0361 11.8058 18.0361 12C18.0361 12.1942 18.1936 12.3516 18.3877 12.3516H19.8907C20.0849 12.3516 20.2422 12.1942 20.2422 12Z"
          fill="#373737"
        />
        <path
          d="M4.10936 11.6484C3.91522 11.6484 3.75781 11.8058 3.75781 12C3.75781 12.1942 3.91522 12.3516 4.10936 12.3516H5.61237C5.80653 12.3516 5.96392 12.1942 5.96392 12C5.96392 11.8058 5.80653 11.6484 5.61237 11.6484H4.10936Z"
          fill="#373737"
        />
        <path
          d="M6.12133 8.60629C6.21841 8.43814 6.16081 8.22313 5.99266 8.12606L5.34185 7.7503C5.17365 7.65318 4.95869 7.71083 4.86161 7.87897C4.76453 8.04712 4.82215 8.26214 4.99028 8.35921L5.64109 8.73496C5.69645 8.76693 5.75692 8.78213 5.81655 8.78213C5.93805 8.78213 6.05621 8.71902 6.12133 8.60629Z"
          fill="#373737"
        />
        <path
          d="M18.3586 15.265C18.1904 15.1678 17.9754 15.2255 17.8782 15.3936C17.7811 15.5618 17.8387 15.7768 18.0069 15.8739L18.6578 16.2496C18.7131 16.2816 18.7736 16.2968 18.8331 16.2968C18.9547 16.2968 19.0728 16.2337 19.1379 16.1209C19.235 15.9528 19.1774 15.7378 19.0093 15.6407L18.3586 15.265Z"
          fill="#373737"
        />
        <path
          d="M15.5695 6.1685C15.691 6.1685 15.8092 6.10541 15.8743 5.99268L16.2501 5.34186C16.3471 5.17372 16.2895 4.95871 16.1213 4.86164C15.9533 4.7645 15.7382 4.82217 15.6412 4.99031L15.2654 5.64111C15.1683 5.80925 15.226 6.02428 15.3941 6.12135C15.4494 6.15332 15.5099 6.1685 15.5695 6.1685Z"
          fill="#373737"
        />
        <path
          d="M8.12603 18.0069L7.75028 18.6578C7.65321 18.8259 7.71081 19.041 7.87895 19.1379C7.93431 19.1699 7.99478 19.1851 8.05441 19.1851C8.17591 19.1851 8.29407 19.1221 8.35919 19.0093L8.73494 18.3586C8.83201 18.1904 8.77441 17.9754 8.60626 17.8782C8.43807 17.7811 8.22307 17.8387 8.12603 18.0069Z"
          fill="#373737"
        />
        <path
          d="M18.6581 7.75028L18.0074 8.12603C17.8392 8.22311 17.7816 8.43812 17.8787 8.60626C17.9437 8.71905 18.0619 8.78209 18.1834 8.78209C18.2431 8.78209 18.3035 8.7669 18.3589 8.73494L19.0098 8.35919C19.1778 8.2621 19.2355 8.0471 19.1384 7.87895C19.0413 7.71081 18.8263 7.65321 18.6581 7.75028Z"
          fill="#373737"
        />
        <path
          d="M6.12133 15.3936C6.02425 15.2255 5.80924 15.1678 5.64109 15.265L4.99028 15.6407C4.82215 15.7378 4.76453 15.9528 4.86161 16.1209C4.92673 16.2337 5.04489 16.2968 5.16639 16.2968C5.22602 16.2968 5.28649 16.2816 5.34185 16.2496L5.99266 15.8739C6.16081 15.7768 6.21841 15.5618 6.12133 15.3936Z"
          fill="#373737"
        />
        <path
          d="M8.12603 5.99269C8.19115 6.10548 8.3093 6.16852 8.43081 6.16852C8.49044 6.16852 8.5509 6.15333 8.60626 6.12137C8.77441 6.02428 8.83201 5.80927 8.73494 5.64113L8.35919 4.99031C8.2621 4.82213 8.0471 4.76452 7.87895 4.86164C7.71081 4.95873 7.65321 5.17373 7.75028 5.34188L8.12603 5.99269Z"
          fill="#373737"
        />
        <path
          d="M15.3941 17.8782C15.226 17.9754 15.1683 18.1904 15.2654 18.3586L15.6412 19.0093C15.7063 19.1221 15.8245 19.1851 15.946 19.1851C16.0057 19.1851 16.066 19.1699 16.1213 19.1379C16.2895 19.041 16.3471 18.8259 16.2501 18.6578L15.8743 18.0069C15.7773 17.8387 15.5622 17.7811 15.3941 17.8782Z"
          fill="#373737"
        />
        <path
          d="M17.2325 11.1953C17.2029 11.0034 17.0239 10.8717 16.8315 10.9013L12.9797 11.4939C12.7959 11.1398 12.426 10.8972 12.0002 10.8972C11.8241 10.8972 11.6576 10.9389 11.5098 11.0126L7.73977 7.24262C7.60248 7.10532 7.37987 7.10532 7.24262 7.24262C7.10532 7.37992 7.10532 7.60252 7.24262 7.73977L11.0126 11.5098C10.9389 11.6576 10.8972 11.8241 10.8972 12.0002C10.8972 12.6085 11.392 13.1033 12.0002 13.1033C12.5442 13.1033 12.9971 12.7074 13.0868 12.1888L16.9386 11.5962C17.1304 11.5667 17.2619 11.3872 17.2325 11.1953ZM12.0002 12.4002C11.7796 12.4002 11.6003 12.2207 11.6003 12.0002C11.6003 11.7797 11.7797 11.6003 12.0002 11.6003C12.2207 11.6003 12.4001 11.7797 12.4001 12.0002C12.4001 12.2207 12.2207 12.4002 12.0002 12.4002Z"
          fill="#373737"
        />
        <path
          d="M2.96522 11.6602C3.04975 9.37457 3.98762 7.2245 5.60602 5.60605C7.22444 3.98761 9.37453 3.04978 11.6602 2.96525C13.9337 2.88121 16.1351 3.64522 17.8598 5.11658C18.0074 5.24258 18.2294 5.22501 18.3554 5.0773C18.4814 4.92961 18.4638 4.7077 18.3161 4.5817C16.457 2.99564 14.0842 2.1718 11.6342 2.26265C9.17063 2.35373 6.85317 3.36458 5.10887 5.1089C3.36455 6.85321 2.3537 9.17066 2.26261 11.6343C2.17201 14.0841 2.99561 16.4572 4.58167 18.3161C4.65117 18.3977 4.74989 18.4396 4.84927 18.4396C4.92999 18.4396 5.01109 18.4119 5.07727 18.3554C5.22498 18.2295 5.24255 18.0076 5.11655 17.8598C3.64519 16.1353 2.88113 13.9335 2.96522 11.6602Z"
          fill="#373737"
        />
        <path
          d="M19.4183 5.68395C19.2924 5.53625 19.0705 5.51862 18.9228 5.64467C18.7751 5.77067 18.7575 5.99257 18.8834 6.14028C20.3548 7.86491 21.119 10.0666 21.0348 12.3399C20.9503 14.6255 20.0124 16.7756 18.394 18.394C16.7756 20.0126 14.6255 20.9503 12.3399 21.0348C10.0668 21.119 7.86488 20.355 6.14025 18.8836C5.99261 18.7575 5.77065 18.7751 5.64465 18.9228C5.51865 19.0705 5.53622 19.2924 5.68393 19.4185C7.45051 20.9255 9.6811 21.7442 12.0008 21.7442C12.1222 21.7442 12.244 21.742 12.3659 21.7375C14.8295 21.6463 17.147 20.6356 18.8913 18.8913C20.6356 17.147 21.6465 14.8295 21.7375 12.3658C21.8281 9.91606 21.0044 7.54302 19.4183 5.68395Z"
          fill="#373737"
        />
      </g>
      <defs>
        <clipPath id="clip0_993_18261">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
