exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".OBYSB{margin-top:8px;margin-top:.5rem}.LqHpM{text-align:left;font-size:16px;margin-bottom:0}", ""]);

// exports
exports.locals = {
	"errorsWrapper": "OBYSB",
	"errorsUl": "LqHpM"
};