import React from 'react';
import styled from 'styled-components';
import { ButtonLargeOpenSans } from '../../../../../styledComponents/styles/Buttons.styled';
import { Paragraph } from '../../../../../styledComponents/styles/Paragraph.styled';

import StyledIcon from '../../../../../styledComponents/styles/Icon.styled';
import Tooltip from '../../../../../styledComponents/styles/Tooltip.styled';

const Container = styled.div`
  font-family: 'Nunito', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 0.015em;
  color: #373737;
`;

const InfoContainer = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  letter-spacing: 0.06em;
  text-decoration: underline;
  color: #255a94;
  flex: 1;
  margin: 20px 0;
  text-align: left;
`;

const InputContainer = styled.div`
  flex: 1;
  margin: 13px 0;
  text-align: left;
`;

const Label = styled.label`
  color: ${({ theme }) => theme.textInput.label.fontColor};
  font-family: ${({ theme }) => theme.textInput.label.fontFamily};
  font-size: ${({ theme }) => theme.textInput.label.fontSize};
  font-weight: ${({ theme }) => theme.textInput.label.fontWeight};
`;

const Required = styled.span`
  color: ${({ theme }) => theme.defaults.errorColor};
  padding-left: 5px;
`;
const Underline = styled.span`
  text-decoration: underline;
`;

export default function QuestionFileUploadDisplayPreview({ question }) {
  const updatedInstruction = !!question.instructions ? question.instructions.replace(/<p><\/p>/g, '<br />') : ''; 
  return (
    <Container>
      <InputContainer>
        <Label htmlFor={question.id}>
          {question.label}
          {question.required && <Required>*</Required>}
        </Label>
        <Paragraph
          aria-label={question.label}
          id={'file-upload-instructions'}
          openSans
          dangerouslySetInnerHTML={{
            __html: updatedInstruction,
          }}
        />
        <InfoContainer>
          <Tooltip
            toolTipText={`Compatible File Types: .csv, .doc, .docx, .gif, .jpeg, .jpg, .mov, .mp3, .mp4, .mpeg, .mpg, .msg, .pdf, .png, .ppt, .pptx, .rtf, .svg, .txt, .xls, .xlsx, .wmv, .zip`}
          >
            <StyledIcon type={'InformationCircle'} size={'14px'} />
            <Underline>Compatible File Types</Underline>
          </Tooltip>
        </InfoContainer>
        <ButtonLargeOpenSans>
          <StyledIcon type={'Add'} size={'20px'} />
          Upload File
        </ButtonLargeOpenSans>
      </InputContainer>
    </Container>
  );
}
