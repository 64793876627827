import React from 'react';
import Header from '../components/Header';
import { StyledHeader } from './styles/Header.styled';

export default function AdminHeader() {
  return (
    <StyledHeader>
      <Header role="Admin" />
    </StyledHeader>
  );
}
