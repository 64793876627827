import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import trash from './trash.svg';
import s from './ProgramViewTestimonial.css';

class ProgramViewTestimonial extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      new: {
        testimonial: null,
      },
      edit: {
        testimonial: this.props.review,
      },
    };
    this.yesTestimony = this.yesTestimony.bind(this);
    this.noTestimony = this.noTestimony.bind(this);
    this.removeTestimony = this.removeTestimony.bind(this);
    this.yesTestimonyEdit = this.yesTestimonyEdit.bind(this);
    this.noTestimonyEdit = this.noTestimonyEdit.bind(this);
    this.removeTestimonyEdit = this.removeTestimonyEdit.bind(this);
    this.extendInput = this.extendInput.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    // console.log(nextProps);
  }

  yesTestimony() {
    this.setState({ new: { testimonial: true } });
  }
  noTestimony() {
    this.setState({ new: { testimonial: false } });
  }

  removeTestimony() {
    this.setState({ new: { testimonial: false } });
    this.props.removeReviewDetails();
  }

  yesTestimonyEdit() {
    this.setState({ edit: { testimonial: true } });
    this.props.addReviewDetails();
  }

  noTestimonyEdit() {
    this.setState({ edit: { testimonial: false } });
  }

  removeTestimonyEdit() {
    this.setState({ edit: { testimonial: false } });
    this.props.removeReviewDetails();
  }

  extendInput(e) {
    const height = document.querySelector('textarea').scrollHeight;
    e.target.style.height = `${height}px`;
  }

  render() {
    const { status } = this.props;
    const alias_program = this.props.customAliases
      ? this.props.customAliases.alias_program
      : 'Program';
    const alias_traveler = this.props.customAliases
      ? this.props.customAliases.alias_traveler
      : 'Traveler';
    if (status == 'view') {
      // View Section
      return (
        <div className={s.wrapper}>
          <div className={s.quoteWrapper}>
            <p className={this.props.reviewText ? s.quote : s.displayNone}>“</p>
            <p className={s.introText}>{this.props.reviewText}</p>
          </div>
          <div className={s.profileCard}>
            <div className={s.speaker}>
              <p className={s.speakerName}>{this.props.name}</p>
              <p className={s.speakerSchool}>{this.props.details}</p>
            </div>
            {/* <img
            className={s.testimonialAvatar}
            src={avatarUrl}
            width="141"
            height="141"
            alt="Testimonial avatar"
          /> */}
          </div>
        </div>
      );
    } else if (status == 'edit') {
      // Edit Section
      if (
        this.state.edit.testimonial == false ||
        this.state.edit.testimonial == null
      ) {
        return (
          <div className={s.testimonySelection}>
            <div className={s.testimonyText}>
              <span className={s.testimonyHeader}>
                Hey you! We see you’re editing a {alias_program.toLowerCase()}{' '}
                page. Good work! This is a really good place to put a traveler
                testimonial. Want to add one?
              </span>
            </div>
            <div className={s.testimonyButtonSection}>
              <button
                className={s.yesButton}
                onClick={this.yesTestimonyEdit}
                style={{
                  background:
                    this.state.edit.testimonial == true ? `#DD2160` : `white`,
                  color:
                    this.state.edit.testimonial == true ? `white` : `#DD2160`,
                }}
              >
                Yes!
              </button>
              <button
                className={s.noButton}
                onClick={this.removeTestimonyEdit}
                style={{
                  background:
                    this.state.edit.testimonial == false ? `#D7D6D7` : `white`,
                  color:
                    this.state.edit.testimonial == false ? `white` : `#D7D6D7`,
                }}
              >
                Nah, I'm Good
              </button>
            </div>
          </div>
        );
      }
      return (
        <div className="row no-gutters">
          <div className="col-sm-8">
            <div className={s.newWrapper}>
              <div className={s.profileCardEdit}>
                <textarea
                  className={s.newQuoteInput}
                  value={this.props.quote || ''}
                  name="quote"
                  placeholder="Quote..."
                  onInput={this.extendInput}
                  onChange={this.props.quoteEdit}
                />
                <input
                  className={s.newTravelerInput}
                  value={this.props.travelerName || ''}
                  name="travelerName"
                  placeholder="Traveler Name..."
                  onChange={this.props.travelerNameEdit}
                />
                <input
                  className={s.newTravelerInput}
                  value={this.props.travelerDetails || ''}
                  name="travelerDetails"
                  placeholder="Traveler Details (School, Graduation Year, etc.)"
                  onChange={this.props.travelerDetailsEdit}
                />
              </div>
              <img
                className={s.trashCan}
                src={trash}
                onClick={this.removeTestimonyEdit}
              />
            </div>
          </div>
        </div>
      );
    } else if (status == 'new') {
      if (
        this.state.new.testimonial == false ||
        this.state.new.testimonial == null
      ) {
        return (
          <div className={s.testimonySelection}>
            <div className={s.testimonyText}>
              <span className={s.testimonyHeader}>
                Hey you! We see you’re building a {alias_program.toLowerCase()}{' '}
                page. Good work! This is a really good place to put a{' '}
                {alias_traveler.toLowerCase()} testimonial. Want to add one?
              </span>
            </div>
            <div className={s.testimonyButtonSection}>
              <button
                className={s.yesButton}
                onClick={this.yesTestimony}
                style={{
                  background:
                    this.state.new.testimonial == true ? `#DD2160` : `white`,
                  color:
                    this.state.new.testimonial == true ? `white` : `#DD2160`,
                }}
              >
                Yes!
              </button>
              <button
                className={s.noButton}
                onClick={this.noTestimony}
                style={{
                  background:
                    this.state.new.testimonial == false ? `#D7D6D7` : `white`,
                  color:
                    this.state.new.testimonial == false ? `white` : `#D7D6D7`,
                }}
              >
                Nah, I'm Good
              </button>
            </div>
          </div>
        );
      }
      return (
        <div className="row no-gutters">
          <div className="col-sm-8">
            <div className={s.newWrapper}>
              <div className={s.profileCardEdit}>
                <textarea
                  className={s.newQuoteInput}
                  value={this.props.quote || ''}
                  name="quote"
                  placeholder="Quote..."
                  onInput={this.extendInput}
                  onChange={this.props.quoteEdit}
                />
                <input
                  className={s.newTravelerInput}
                  value={this.props.travelerName || ''}
                  name="travelerName"
                  placeholder="Traveler Name..."
                  onChange={this.props.travelerNameEdit}
                />
                <input
                  className={s.newTravelerInput}
                  value={this.props.travelerDetails || ''}
                  name="travelerDetails"
                  placeholder="Traveler Details (School, Graduation Year, etc.)"
                  onChange={this.props.travelerDetailsEdit}
                />
              </div>
              <img
                className={s.trashCan}
                src={trash}
                onClick={this.removeTestimony}
              />
            </div>
          </div>
        </div>
      );
    }
  }
}

export default withStyles(s)(ProgramViewTestimonial);
