import React from 'react';
import PropTypes from 'prop-types';

export default function ChevronLeftIcon({ fillColor }) {
  return (
    <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.47541 0.712087C7.84153 1.0782 7.84153 1.6718 7.47541 2.03791L2.51333 7L7.47541 11.9621C7.84153 12.3282 7.84153 12.9218 7.47541 13.2879C7.1093 13.654 6.5157 13.654 6.14959 13.2879L0.524587 7.66291C0.158471 7.2968 0.158471 6.7032 0.524587 6.33709L6.14959 0.712087C6.5157 0.345971 7.1093 0.345971 7.47541 0.712087Z"
        fill={fillColor}
      />
    </svg>
  );
}

ChevronLeftIcon.propTypes = {
  fillColor: PropTypes.string,
};

ChevronLeftIcon.defaultProps = {
  fillColor: 'currentColor',
};