import fetch from 'node-fetch';
import { keys } from '../config/keys';
import {
  SEND_SAFECHECK_MESSAGE_REQUEST,
  SEND_SAFECHECK_MESSAGE_SUCCESS,
  SEND_SAFECHECK_FAILURE,
  SEND_SAFECHECK_CLEAR,
  FETCH_SAFECHECK_RECEIPT_REQUEST,
  FETCH_SAFECHECK_RECEIPT_SUCCESS,
  FETCH_SAFECHECK_RECEIPT_FAILURE,
  FETCH_SAFECHECK_RECEIPT_CLEAR,
  SUBMIT_SAFECHECK_RECEIPT_REQUEST,
  SUBMIT_SAFECHECK_RECEIPT_SUCCESS,
  SUBMIT_SAFECHECK_RECEIPT_FAILURE,
  SUBMIT_SAFECHECK_RECEIPT_CLEAR,
  FETCH_SAFECHECK_MESSAGES_REQUEST,
  FETCH_SAFECHECK_MESSAGES_SUCCESS,
  FETCH_SAFECHECK_MESSAGES_FAILURE,
  FETCH_SAFECHECK_MESSAGES_CLEAR,
  FETCH_SAFECHECK_MESSAGE_REQUEST,
  FETCH_SAFECHECK_MESSAGE_SUCCESS,
  FETCH_SAFECHECK_MESSAGE_FAILURE,
  FETCH_SAFECHECK_MESSAGE_CLEAR,
  RESEND_SAFECHECK_MESSAGE_REQUEST,
  RESEND_SAFECHECK_MESSAGE_SUCCESS,
  RESEND_SAFECHECK_MESSAGE_FAILURE,
  RESEND_SAFECHECK_MESSAGE_CLEAR,
  VALIDATE_PHONE_NUMBER,
  UNENROLL_SAFECHECK_REQUEST,
  UNENROLL_SAFECHECK_SUCCESS,
  UNENROLL_SAFECHECK_FAILURE,
  SEND_SAFECHECK_ENROLLMENT_REQUEST,
  SEND_SAFECHECK_ENROLLMENT_SUCCESS,
  SEND_SAFECHECK_ENROLLMENT_FAILURE,
  SEND_SAFECHECK_ENROLLMENT_CLEAR,
  SET_VALIDATE_PHONE_NUMBER_LOADING,
} from './types';
import { Cookies } from 'react-cookie';

export function sendSafecheckMessage(values) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({ type: SEND_SAFECHECK_MESSAGE_REQUEST });
    fetch(`${keys.baseUri}/api/safe_check_messages`, {
      method: 'POST',
      headers: token,
      body: JSON.stringify(values),
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: SEND_SAFECHECK_MESSAGE_SUCCESS,
          payload: data,
        });
        dispatch({
          type: SEND_SAFECHECK_CLEAR,
        });
      })
      .catch(error => {
        dispatch({
          type: SEND_SAFECHECK_FAILURE,
          payload: error.toString(),
        });
      });
  };
}

export function fetchSafecheckReceipt(values) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({ type: FETCH_SAFECHECK_RECEIPT_REQUEST });
    fetch(`${keys.baseUri}/api/safe_check_message_receipts/${values}`, {
      method: 'GET',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: FETCH_SAFECHECK_RECEIPT_SUCCESS,
          payload: data,
        });
        dispatch({
          type: FETCH_SAFECHECK_RECEIPT_CLEAR,
        });
      })
      .catch(error => {
        dispatch({
          type: FETCH_SAFECHECK_RECEIPT_FAILURE,
          payload: error.toString(),
        });
      });
  };
}

export function submitSafecheckReceipt(values, receiptId) {
  return function(dispatch) {
    dispatch({ type: SUBMIT_SAFECHECK_RECEIPT_REQUEST });
    fetch(`${keys.baseUri}/api/safe_check_message_receipts/${receiptId}`, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(values),
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: SUBMIT_SAFECHECK_RECEIPT_SUCCESS,
          payload: data,
        });
        dispatch({
          type: SUBMIT_SAFECHECK_RECEIPT_CLEAR,
        });
      })
      .catch(error => {
        dispatch({
          type: SUBMIT_SAFECHECK_RECEIPT_FAILURE,
          payload: error.toString(),
        });
      });
  };
}

export function fetchSafecheckMessages() {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({ type: FETCH_SAFECHECK_MESSAGES_REQUEST });
    fetch(`${keys.baseUri}/api/safe_check_messages`, {
      method: 'GET',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: FETCH_SAFECHECK_MESSAGES_SUCCESS,
          payload: data,
        });
        dispatch({
          type: FETCH_SAFECHECK_MESSAGES_CLEAR,
        });
      })
      .catch(error => {
        dispatch({
          type: FETCH_SAFECHECK_MESSAGES_FAILURE,
          payload: error.toString(),
        });
      });
  };
}

export function fetchSafecheckMessage(msgId) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({ type: FETCH_SAFECHECK_MESSAGE_REQUEST });
    fetch(`${keys.baseUri}/api/safe_check_messages/${msgId}`, {
      method: 'GET',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: FETCH_SAFECHECK_MESSAGE_SUCCESS,
          payload: data,
        });
        dispatch({
          type: FETCH_SAFECHECK_MESSAGE_CLEAR,
        });
      })
      .catch(error => {
        dispatch({
          type: FETCH_SAFECHECK_MESSAGE_FAILURE,
          payload: error.toString(),
        });
      });
  };
}

export function resendSafecheckMessage(receiptId) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({ type: RESEND_SAFECHECK_MESSAGE_REQUEST });
    fetch(`${keys.baseUri}/api/safe_check_message_receipts/${receiptId}`, {
      method: 'POST',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: RESEND_SAFECHECK_MESSAGE_SUCCESS,
          payload: data,
        });
        dispatch({
          type: RESEND_SAFECHECK_MESSAGE_CLEAR,
        });
      })
      .catch(error => {
        dispatch({
          type: RESEND_SAFECHECK_MESSAGE_FAILURE,
          payload: error.toString(),
        });
      });
  };
}

export function validatePhoneNumber(number, callback = null) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({
      type: SET_VALIDATE_PHONE_NUMBER_LOADING,
      payload: true,
    });

    fetch(`${keys.baseUri}/api/phone_number_validations/${number}`, {
      headers: token,
    }).then(res => {
      if (res.status === 400) {
        if (callback && typeof callback === 'function') {
          callback(false, 'Phone number entered is invalid');
        }

        res.json().then(data => {
          dispatch({
            type: VALIDATE_PHONE_NUMBER,
            payload: data,
          });

          dispatch({
            type: SET_VALIDATE_PHONE_NUMBER_LOADING,
            payload: false,
          });
        });
      } else if (res.status === 200) {
        if (callback && typeof callback === 'function') {
          callback(true, 'Phone number validated successfully');
        }

        res.json().then(res => {
          dispatch({
            type: VALIDATE_PHONE_NUMBER,
            payload: res,
          });

          dispatch({
            type: SET_VALIDATE_PHONE_NUMBER_LOADING,
            payload: false,
          });
        });
      }
    });
  };
}

export function sendSafeCheckEnrollmentRequest(values) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({ type: SEND_SAFECHECK_ENROLLMENT_REQUEST });
    fetch(`${keys.baseUri}/api/traveler_batch_actions/send_safe_check_enrollment_requests`, {
      method: 'POST',
      headers: token,
      body: JSON.stringify({ traveler_ids: values }),
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: SEND_SAFECHECK_ENROLLMENT_SUCCESS,
          payload: data,
        });
        dispatch({
          type: SEND_SAFECHECK_ENROLLMENT_CLEAR,
        });
      })
      .catch(error => {
        dispatch({
          type: SEND_SAFECHECK_ENROLLMENT_FAILURE,
          payload: error.toString(),
        });
      });
  };
}

export function unenrollSafeCheck(id, data, callback = null) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({
      type: UNENROLL_SAFECHECK_REQUEST,
    });

    fetch(`${keys.baseUri}/api/traveler/traveler_profiles/${id}`, {
      method: 'PUT',
      body: JSON.stringify({
        traveler_profile: data,
        custom_fields: {},
        passport: {},
        profile: {},
      }),
      headers: token,
    })
      .then(res => {
        if (res.status === 200) {
          if (callback && typeof callback === 'function') {
            callback(true, 'Unenrolled successfully');
          }

          dispatch({
            type: UNENROLL_SAFECHECK_SUCCESS,
          });
        } else {
          callback(false, 'Something went wrong, try again later');

          dispatch({
            type: UNENROLL_SAFECHECK_FAILURE,
            payload: 'Something went wrong',
          });
        }
      })
      .catch(error => {
        callback(false, 'Something went wrong, try again later');

        dispatch({
          type: UNENROLL_SAFECHECK_FAILURE,
          payload: error?.message,
        });
      });
  };
}
