import styled from 'styled-components';

import { breakpoints } from '../../utils/breakpoints';

const GridColumnPlaceholder = styled.div`
  display: none;

  @media ${breakpoints.desktop} {
    display: block;
  }
`;

export default GridColumnPlaceholder;
