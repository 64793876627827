exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@-webkit-keyframes _CHyX{to{-webkit-transform:rotate(1turn)}}@keyframes _CHyX{to{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}:root{--dark:var(--theme_color_dark,#043544);--accent:var(--theme_color_accent,#dd2160);--theme_light:var(--theme_color_light,#76ccce)}._2WCAv{display:inline-block;position:relative;padding-left:30px;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;-ms-flex-positive:0;flex-grow:0;width:100%}._2WCAv input{position:absolute;opacity:0;cursor:pointer;top:0;left:0;height:12px;width:12px;z-index:5;vertical-align:middle}._3EZ3L{font-family:AvenirNextRegular,sans-serif;color:#043544;font-weight:400;font-size:14px;line-height:19.6px;vertical-align:top}.bOKLC{position:absolute;top:3px;left:0;height:12px;width:12px;background-color:#fff;cursor:pointer;vertical-align:top}._2WCAv input:checked~.bOKLC,.bOKLC{border:1px solid var(--theme_color_light,#76ccce);border:1px solid var(--theme_light)}._2WCAv input:checked~.bOKLC{background-color:var(--theme_color_light,#76ccce);background-color:var(--theme_light)}.bOKLC:after{content:\"\";position:absolute;display:none}._2WCAv .bOKLC:after{left:4px;top:0;width:3px;height:8px;border:solid #fff;border-width:0 1px 1px 0;-webkit-transform:rotate(45deg);-ms-transform:rotate(45deg);transform:rotate(45deg)}._2WCAv input:checked~.bOKLC:after{display:block}", ""]);

// exports
exports.locals = {
	"container": "_2WCAv",
	"label": "_3EZ3L",
	"checkmark": "bOKLC",
	"spin": "_CHyX"
};