import React from 'react';
import { compose } from 'recompose';
import propTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import ViaModal from '../../../../../components/library/viaModal';
import Headline from '../../../../../components/library/headline';
import Button from '../../../../../components/library/button';
import Paragraph from '../../../../../components/library/paragraph';
import sSendSafecheckSuccess from './sendSafecheckSuccess.scss';

export const enhance = compose(
  withStyles(sSendSafecheckSuccess),
  withRouter,
);

function SendSafecheckSuccess(props) {
  const msgLink = `/safecheck/messages/${props.msgId}`;
  const backTo = props.planId.length > 0 ? `plans/admin-plan-overview/${props.planId}` : 'plans/traveler-list';
  return (
    <>
      <ViaModal
        showing={props.show}
        onClose={() => {
          props.onClose(false);
        }}
      >
        <div className={sSendSafecheckSuccess['send-safecheck-message-success']}>
          <div className={sSendSafecheckSuccess.subHeader}>
            <Headline centered tag="h1" as="h1">
              Success!
            </Headline>
          </div>
          <div className={sSendSafecheckSuccess.subHeader}>
            {!!props.locCheck ? (
              <Headline centered tag="h1" as="h2">
                You sent a SafeCheck and a LocationCheck to {props.userCount} Travelers.
              </Headline>
            ) : (
              <Headline centered tag="h1" as="h2">
                You sent a SafeCheck to {props.userCount} Travelers.
              </Headline>
            )}
          </div>
          <div className={sSendSafecheckSuccess.content}>
            <Paragraph centered size="large">
              Click below to check the status of your message, view responses, location, and additional information.
            </Paragraph>
          </div>
          <div className={sSendSafecheckSuccess.footer}>
            <div className={sSendSafecheckSuccess['footer-button-wrapper']}>
              <div className={sSendSafecheckSuccess['button-content']}>
                <Button
                  display="primary"
                  kind="solid"
                  size="medium"
                  onClick={() => {
                    props.onClose(false);
                    props.history.push(`${msgLink}`, {
                      backTo: `${backTo}`,
                    });
                  }}
                >
                  View SafeCheck Responses
                </Button>
              </div>
            </div>
          </div>
        </div>
      </ViaModal>
    </>
  );
}

SendSafecheckSuccess.propTypes = {
  show: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
  locCheck: propTypes.bool.isRequired,
  userCount: propTypes.number.isRequired,
  msgId: propTypes.string.isRequired,
  planId: propTypes.string.isRequired,
};

export default enhance(SendSafecheckSuccess);
