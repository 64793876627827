exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@-webkit-keyframes _3KDbV{to{-webkit-transform:rotate(1turn)}}@keyframes _3KDbV{to{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}._3I9zS{display:-ms-inline-flexbox;display:inline-flex}._2FKf4{height:36px}.Tz0Q9{height:48px}._3WFtW{height:104px}", ""]);

// exports
exports.locals = {
	"via-logo": "_3I9zS",
	"small": "_2FKf4",
	"medium": "Tz0Q9",
	"large": "_3WFtW",
	"spin": "_3KDbV"
};