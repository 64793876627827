import React, { useState } from 'react';
import propTypes from 'prop-types';
import InputSelect from '../../../../components/library/inputSelect';

function PlanAvailability(props) {
  const [options, setOptions] = useState([
    {value: false, label: 'Public'},
    {value: true, label: 'Private'},
  ]);

  return (
    <>
      <InputSelect {...props} options={options} />
    </>
  );
}

PlanAvailability.propTypes = {
  errorMessage: propTypes.string,
  labelText: propTypes.string,
  helpText: propTypes.string,
  placeholder: propTypes.string,
  isRequired: propTypes.bool,
};


PlanAvailability.defaultProps = {
  errorMessage: '',
  labelText: 'PLAN AVAILABILITY',
  helpText: '',
  placeholder: '',
  isRequired: false,
};

export default (PlanAvailability);
