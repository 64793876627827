import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import HouseIcon from '../icons/HouseIcon';
import ProgramMatchSection from './ProgramMatchSection';

import { fetchProgramHousingTypes } from '../../../../actions/lookupActions';

export default function ProgramHousingPreferences() {
  const dispatch = useDispatch();
  const programMatch = useSelector(state => state.programMatchForm);
  const { programHousingTypes } = useSelector(state => state.lookup.allProgramHousingTypes);

  const [housingTypes, setHousingTypes] = useState('');

  useEffect(() => {
    dispatch(fetchProgramHousingTypes());
  }, []);

  useEffect(
    () => {
      if (programHousingTypes) {
        const housingValues = programMatch.programHousing.reduce((accumulator, type) => {
          const value = programHousingTypes.find(programHousingType => Number(programHousingType.id) === type);

          if (value) {
            accumulator.push(value.attributes.name);
          }

          return accumulator;
        }, []);

        if (housingValues.length > 0) {
          setHousingTypes(housingValues.join('; '));
        }
      }
    },
    [programHousingTypes, programMatch.programHousing],
  );

  return (
    <ProgramMatchSection heading="Housing Types" icon={HouseIcon}>
      <p>{housingTypes || 'None selected'}</p>
      {programMatch.otherProgramHousingText && (
        <p>
          <strong>Other:</strong> {programMatch.otherProgramHousingText}
        </p>
      )}
    </ProgramMatchSection>
  );
}
