exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2v6OG{width:100%;padding:25px 88px 20px}._3ZKqQ{height:24px}._2G3eU{margin-top:36px}._2G3eU,.M7syn{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;gap:20px}.M7syn{margin-top:20px}._1AbaF{width:288px}._19ajz{width:98px;height:51px;background-color:#d74b73}._26GQ5{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;gap:55px;padding:30px;background-color:#ebebeb}.HgElI{color:#373737;Width:57px;Height:19px;border:none;background-color:transparent}._33Xp1{gap:64px;padding:25px 26px}._3RHVF{font-family:Open Sans,sans-serif;font-weight:400;font-size:14px;letter-spacing:.25%;line-height:16.8px;margin-top:6px}.jVSvd{font-size:25px;width:693px;height:30px}._1T6qi{font-family:Nunito,sans-serif;font-weight:400;font-size:25px;line-height:30px}._2y3Uu{font-weight:700;line-height:16.8%}._2Cwto,._2y3Uu{font-family:Open Sans,sans-serif;font-size:14px;letter-spacing:.25%}._2Cwto{font-weight:400}._1CoOb{font-family:Nunito,sans-serif;font-weight:600;font-size:16px;line-height:19.2px;letter-spacing:.1%}.gnPef{margin-top:10px}._2wcc-{font-family:Open Sans,sans-serif;font-weight:600;font-size:14px;letter-spacing:.25%}._37gBr{height:18px}._3Sjiz{font-family:Nunito,sans-serif;font-weight:600;font-size:16px;line-height:19.2px;letter-spacing:.1%;color:#373737}.UCCZu{font-family:Open Sans,sans-serif;font-weight:400;font-size:14px;line-height:16.8px;letter-spacing:.25%}._2fm6V{margin-top:25px}", ""]);

// exports
exports.locals = {
	"custom": "_2v6OG",
	"tableRow": "_3ZKqQ",
	"formDiv": "_2G3eU",
	"formDiv2": "M7syn",
	"formSelect": "_1AbaF",
	"nextBtn": "_19ajz",
	"btnDiv": "_26GQ5",
	"cancelBtn": "HgElI",
	"headerTitle": "_33Xp1",
	"fontSize": "_3RHVF",
	"modaltextsize": "jVSvd",
	"createGrouptext": "_1T6qi",
	"divtext1": "_2y3Uu",
	"divtext2": "_2Cwto",
	"plansettingtext": "_1CoOb",
	"plansettingdiv": "gnPef",
	"tableheader": "_2wcc-",
	"tableheight": "_37gBr",
	"plandetails": "_3Sjiz",
	"plandetailsp": "UCCZu",
	"Plan": "_2fm6V"
};