import styled from 'styled-components';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ProfileFormContainer from '../base/ProfileFormContainer';
import ProfileFormSubheading from '../base/ProfileFormSubheading';
import NotificationSettingsAccordion from '../notification-settings/NotificationSettingsAccordion';

import { getNotificationOptOuts, getNotificationTypes } from '../../actions/travelerProfileActions';

const Info = styled.p`
  margin-top: -1rem;
  margin-bottom: 1.5275rem;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  color: #474747;
  text-align: center;
  letter-spacing: 0.015em;
  line-height: 120%;
`;

const Fieldset = styled.div`
  margin-bottom: 2.215625rem;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export default function NotificationsTab() {
  const dispatch = useDispatch();
  const { notifications } = useSelector(state => state.travelerProfile);

  useEffect(() => {
    dispatch(getNotificationTypes());

    dispatch(getNotificationOptOuts());
  }, []);

  return (
    <form>
      <ProfileFormContainer heading="My Notifications">
        <Info tabIndex={0}>
          Below are all of the notifications that you are able to receive by email. For any notification you do not wish
          to receive, turn the notification setting off; turning the setting off will cease all future notifications for
          that type from that day forward. For any notifications you do wish to receive, turn the notification setting
          on; turning the setting on will resume sending the notifications from that day forward.
        </Info>
        {notifications.length > 0 &&
          notifications.map(notification => (
            <Fieldset key={`notification-category-${notification.id}`}>
              <ProfileFormSubheading text={notification.attributes.display_name} />
              <NotificationSettingsAccordion
                minorCategoryItems={notification.attributes.send_grid_mailer_minor_categories.filter(
                  category => category.send_grid_mailer_types.length > 0,
                )}
              />
            </Fieldset>
          ))}
      </ProfileFormContainer>
    </form>
  );
}
