import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const TabNav = styled.div`
  cursor: pointer;
  padding: 10px 15px;
  margin-right: 20px;

  ${props => props.active && `background-color: #e7e7e7;`};
`;

const TabNavContainer = styled.div`
  align-items: center;
  border-bottom: 1px solid #c4c4c4;
  color: #373737;
  display: flex;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  justify-content: center;
  letter-spacing: 0.06em;
  line-height: 120%;
  margin-bottom: 30px;
  width: 100%;

  @media (max-width: 778px) {
    display: block;
  }
`;

export default function LogicTab({ isDirty, onTabChange }) {
  const [showQuestion, setShowQuestion] = useState(true);

  return (
    <TabNavContainer>
      <TabNav
        active={showQuestion}
        onClick={() => {
          setShowQuestion(true);
          onTabChange('question');
        }}
        onKeyDown={e => {
          if (e.keyCode === 32 || e.keyCode === 13) {
            setShowQuestion(true);
            onTabChange('question');
          }
        }}
        tabIndex={0}
      >
        Question
      </TabNav>
      <TabNav
        active={!showQuestion}
        onClick={() => {
          if (!isDirty) {
            setShowQuestion(false);
            onTabChange('logic');
          }
        }}
        onKeyDown={e => {
          if (e.keyCode === 32 || e.keyCode === 13) {
            if (!isDirty) {
              setShowQuestion(false);
              onTabChange('logic');
            }
          }
        }}
        tabIndex={0}
      >
        Logic
      </TabNav>
    </TabNavContainer>
  );
}

LogicTab.propTypes = {
  isDirty: PropTypes.bool.isRequired,
  onTabChange: PropTypes.func.isRequired,
};
