import React, { useEffect } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './index.css';

function ScreenReaderHeadingComponent({ text, type = '' }) {
  const id = new Date().valueOf();

  useEffect(() => {
    document.getElementById(`screen-reader-heading-${id}`)?.focus();
  }, []);

  if (type === 'div') {
    return (
      <div id={`screen-reader-heading-${id}`} tabIndex={0} className={s.heading}>
        {text}
      </div>
    );
  }

  return (
    <h1 id={`screen-reader-heading-${id}`} tabIndex={0} className={s.heading}>
      {text}
    </h1>
  );
}

export default withStyles(s)(ScreenReaderHeadingComponent);
