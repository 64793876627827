import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import StyledIcon from '../../../../../styledComponents/styles/Icon.styled';

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const Span = styled.span`

`;

const AllProgramsLink = styled(Link)`
  font-family: ${({ theme }) => theme.button.fontFamilyNunito};
  font-weight: ${({ theme }) => theme.button.fontWeight};
  display: inline-block;
  color: ${({ theme }) => theme.button.fontColor};
  font-size: ${({ theme }) => theme.button.fontSize};
  line-height: ${({ theme }) => theme.button.lineHeight};
  padding: ${({ theme }) => theme.button.medium.padding};
  padding-left: 0;
  padding-right: 0;
  text-decoration: underline;
  text-align: center;

  svg {
    align-self: center;
    display: inline-flex;
    margin-bottom: 3px;
    margin-right: 5px;
    position: relative;
  }

  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }

  &:active {
    opacity: 0.9;
  }

  &:disabled,
  &:disabled:active {
    opacity: ${props => (props.saving ? '.7' : '0.3')};
    cursor: not-allowed;
  }

  &:focus {
    border: ${({ theme }) => theme.button.focus.focusBorder};
    outline-color: ${({ theme }) => theme.button.focus.outlineColor};
  }
`;

const ProgramDetailsBreadcrumbs = ({isAdmin, programName}) => {
  const { customAliases } = useSelector(state => state.currentOrganization);
  
  const buildUrl = () => {
    let path = `/traveler/my-programs`;
    if (isAdmin) {
      const pathname = window.location.pathname.split('/');
      const travelerId = pathname[3];
      path = `/client/travelers/${travelerId}?tab=programs`;
    }
    return path;
  }

  return (
    <Container>
      <AllProgramsLink to={buildUrl()}>
        My {customAliases.alias_programs}
      </AllProgramsLink>
      {
        programName &&
        <Span>
        / {programName}
        </Span>
      }
      
      
    </Container>
  );
}

ProgramDetailsBreadcrumbs.propTypes = {
  isAdmin: PropTypes.bool,
}

ProgramDetailsBreadcrumbs.defaultProps = {
  isAdmin: false,
}

export default ProgramDetailsBreadcrumbs;
