import PropTypes from 'prop-types';

import sendGridMailerType from './sendGridMailerType';

const minorCategoryItem = PropTypes.shape({
  id: PropTypes.number,
  order: PropTypes.number,
  display_name: PropTypes.string,
  send_grid_mailer_types: PropTypes.arrayOf(sendGridMailerType),
});

export default minorCategoryItem;
