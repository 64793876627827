import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Modal from '../base/Modal';
import InfoBubbleIcon from '../icons/InfoBubbleIcon';
import MountainFlagIcon from '../icons/MountainFlagIcon';
import { ModalBody, ModalButton, ModalFooter } from '../base/Modal.styled';

import showConfetti from '../../utils/showConfetti';
import { breakpoints } from '../../utils/breakpoints';
import { toggleCommitModal, getApplicationStatuses } from '../../actions/travelerApplicationsActions';
import { updateTravelerForm } from '../../../../actions/formsActions';
import { CircularProgress } from '@material-ui/core';

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3.125rem;

  svg {
    flex-shrink: 0;
  }

  @media ${breakpoints.tablet} {
    flex-direction: row;
  }
`;

const InfoText = styled.p`
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 1rem !important;
  color: #373737;
  letter-spacing: 0.005em;
  line-height: 140%;
`;

const CommitModal = ({ isAdmin }) => {
  const dispatch = useDispatch();

  const {
    customAliases,
    organizationName,
    brandingTheme: { themes },
  } = useSelector(state => state.currentOrganization);

  const { showCommitModal: show, applicationStatuses } = useSelector(state => state.travelerApplicationSubmissions);

  const travelerSubmission = useSelector(state => state.forms.travelerSubmission);
  const patchTravelerForm = useSelector(state => state.forms.patchTravelerForm);
  const [applicationStatus, setApplicationStatus] = useState('');
  const [applicationSubmission, setApplicationSubmission] = useState();

  useEffect(() => {
    if (!travelerSubmission.loading && travelerSubmission.data) {
      setApplicationSubmission(travelerSubmission.data);
      setApplicationStatus(travelerSubmission.data.submission_status.name);
    }
  }, [travelerSubmission]);

  const [hasCommitted, setHasCommitted] = useState(false);

  useEffect(() => {
    if (applicationStatuses.length === 0) {
      dispatch(getApplicationStatuses());
    }
  }, [applicationStatuses]);

  useEffect(() => {
    if (isAdmin && show && applicationStatus === 'Accepted') {
      //skip modal entirely (no confirm, no success)
      handleCommit();
    }
  }, [show]);

  useEffect(() => {
    if (!isAdmin && applicationStatus === 'Committed' && show) {
      setHasCommitted(true);
    }
  }, [applicationStatus]);

  const close = () => {
    if (hasCommitted) {
      setHasCommitted(false);
      showConfetti(themes.theme_color_dark, themes.theme_color_accent);
    }

    dispatch(toggleCommitModal());
  };

  const handleCommit = () => {
    dispatch(
      updateTravelerForm(
        'Application',
        applicationSubmission.id,
        {},
        applicationStatuses.find(status => status.attributes.name === 'Committed').id,
      ),
    );
  };

  return (
    <Modal
      show={show && !isAdmin}
      close={close}
      title={hasCommitted ? 'Success' : `Commit to ${customAliases.alias_program}`}
      maxWidth="520px"
    >
      <ModalBody>
        <BodyContainer>
          {hasCommitted ? <MountainFlagIcon /> : <InfoBubbleIcon />}
          <InfoText>
            {hasCommitted
              ? `You're committed! Keep an eye out for future communication by ${organizationName} for the next steps.`
              : `By clicking Commit, you are confirming your participation in this opportunity. After commitment, your organization may have additional tasks to complete. Review your ${customAliases.alias_program.toLowerCase()} and messages for more info.`}
          </InfoText>
        </BodyContainer>
      </ModalBody>
      <ModalFooter>
        {!hasCommitted && (
          <>
            <ModalButton type="button" className="secondary" onClick={close}>
              Cancel
            </ModalButton>
            <ModalButton type="button" className="primary" onClick={handleCommit} disabled={patchTravelerForm.loading}>
              {patchTravelerForm.loading ? (
                <>
                  Loading...&nbsp;
                  <CircularProgress size={20} color="inherit" />
                </>
              ) : (
                <>Commit</>
              )}
            </ModalButton>
          </>
        )}
        {hasCommitted && (
          <ModalButton type="button" className="primary center" onClick={close}>
            Okay
          </ModalButton>
        )}
      </ModalFooter>
    </Modal>
  );
};

CommitModal.propTypes = {
  isAdmin: PropTypes.bool,
};

CommitModal.defaultProps = {
  isAdmin: false,
};

export default CommitModal;
