import React, { useState, useEffect } from 'react';
import { compose } from 'recompose';
import propTypes from 'prop-types';
import { useSelector } from 'react-redux';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { defaultPlanLocationImage } from '../../../../../../../shared/helpers/plans';
import Headline from '../../../../../../components/library/headline';
import Paragraph from '../../../../../../components/library/paragraph';
import ViaDate from '../../../../../../components/library/viaDate';
import sOvHeader from './ovHeader.scss';
import GenericActionsSelect from '../../../../selects/genericActionsSelect';
import DeletePlanDialog from '../../DeletePlanDialog';

export const enhance = compose(withStyles(sOvHeader));

function OvHeader(props) {
  const selectedPlan = useSelector(state => state.plans.plan);
  const permissions = useSelector(state => state.profile.permissions);
  const [allLocs, setAllLocs] = useState('');
  const [img, setImg] = useState({ src: defaultPlanLocationImage(), alt: 'travel location' });

  const [selectedAction, setSelectedAction] = useState(null);
  const actionOptions = [
    {
      label: 'Delete Plan',
      value: '1',
    },
  ];

  useEffect(() => {
    if (!!selectedPlan && !!selectedPlan.plan) {
      let locsString = '';
      if (selectedPlan.plan.attributes.locations && selectedPlan.plan.attributes.locations.length > 0) {
        selectedPlan.plan.attributes.locations.map(loc => {
          locsString = locsString + loc.locality + ', ' + loc.country_common_name + '; ';
        });
        locsString = locsString.slice(0, -2);

        setImg({
          src: selectedPlan.plan.attributes.locations[0].image_url,
          alt: `Banner image of ${selectedPlan.plan.attributes.locations[0].locality}, ${selectedPlan.plan.attributes.locations[0].country_common_name}`,
        });
      }
      setAllLocs(locsString);
    }
  }, [selectedPlan]);

  return (
    <div className={sOvHeader['ov-header']}>
      {props.type === 'image' && !!selectedPlan && !!selectedPlan.plan && (
        <div className={sOvHeader['img-wrapper']}>
          
          {
            props?.as==="admin" &&
            <div className={sOvHeader['action-btn']}>
              <GenericActionsSelect
                options={actionOptions}
                isDisabled={!permissions?.travel_plans_settings?.includes('delete_plans')}
                placeholder="Actions"
                value={selectedAction}
                onChange={e => {
                  setSelectedAction(e.value);
                }}
              />
            </div>
          }
        

          <img
            className={sOvHeader['img-fit']}
            alt={`${img.alt}`}
            src={`${img.src}&auto=format&fit=crop&w=2200&h=500&q=80`}
            longdesc={`${img.alt}`}
          />
        </div>
      )}
      {props.type === 'card' && !!selectedPlan && !!selectedPlan.plan && (
        <div className={sOvHeader['header-info-wrapper']}>
          <div className={sOvHeader['header-info-title']}>
            <Headline
              style={{
                fontFamily: 'IntroBoldItalic, sans-serif',
                fontSize: '32px',
                lineHeight: '40px',
              }}
              tag="div"
              as="div"
            >
              {selectedPlan.plan.attributes.name}
            </Headline>
          </div>
          <div className={sOvHeader['header-info-details-wrapper']}>
            <div className={sOvHeader['header-info-details-block']}>
              <Paragraph size="large">
                <ViaDate date={selectedPlan.plan.attributes.start_date} />
                &nbsp;-&nbsp;
                <ViaDate date={selectedPlan.plan.attributes.end_date} />
              </Paragraph>
              <Paragraph size="large">{allLocs}</Paragraph>
              <Paragraph size="large">{selectedPlan.plan.attributes.plan_type_name}</Paragraph>
            </div>
            <div className={sOvHeader['header-info-details-block']} />
          </div>
        </div>
      )}

      <DeletePlanDialog
        show={selectedAction === '1'}
        planName={selectedPlan?.plan?.attributes?.name}
        planId={selectedPlan?.plan?.id}
        handleClose={() => {
          setSelectedAction(null);
        }}
      />
    </div>
  );
}

OvHeader.propTypes = {
  type: propTypes.oneOf(['image', 'card']).isRequired,
};

export default enhance(OvHeader);
