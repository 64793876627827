import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import {
  AccordionItem,
  AccordionItemPanel,
  AccordionItemState,
  AccordionItemButton,
  AccordionItemHeading,
} from 'react-accessible-accordion';

import ChevronUpIcon from '../icons/ChevronUpIcon';
import ChevronDownIcon from '../icons/ChevronDownIcon';
import ScreenReaderText from '../base/ScreenReaderText.styled';
import NotificationSettingsChildItem from './NotificationSettingsChildItem';

import { breakpoints } from '../../utils/breakpoints';
import userId from '../../../../shared/helpers/userId';
import { buttonFocus } from '../../utils/shared/focusStyles';
import minorCategoryItem from '../../types/minorCategoryItem';
import { updateNotificationOptOut } from '../../actions/travelerProfileActions';
import { replaceCustomAliasesHandlebars } from '../../../../shared/helpers/General';

const ItemHeadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  gap: 0.5rem;
  padding: 0.51rem 0.806875rem 0.51rem 1.04625rem;
  background-color: #ffffff;
  border-radius: 3px;
  box-shadow: 0px 10px 20px rgba(132, 131, 131, 0.25);
  font-family: 'Nunito', sans-serif;
  font-weight: 700;
  font-size: 0.875rem;
  color: #373737;
  letter-spacing: 0.06em;
  line-height: 120%;

  @media ${breakpoints.tablet} {
    flex-direction: row;
    align-items: center;
    gap: 0;
  }
`;

const ItemActionsContainer = styled.div`
  display: flex;
  align-items: center;
  align-self: stretch;
  gap: 2.8125rem;
`;

const ToggleButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  gap: 0.25rem;

  span {
    color: #888888;
  }
`;

const ToggleAllButton = styled.button`
  padding: 0;
  background-color: transparent;
  border: none;
  font-family: 'Nunito', sans-serif;
  font-weight: 700;
  font-size: 0.875rem;
  color: #446372;
  text-decoration: underline;
  letter-spacing: 0.06em;
  line-height: 120%;

  ${buttonFocus};
`;

const ItemButton = styled(AccordionItemButton)`
  ${buttonFocus};
`;

const ItemPanel = styled(AccordionItemPanel)`
  padding: 0.6875rem 0.875rem;
  background-color: #ffffff;
  border-width: 0px 1px 1px 1px;
  border-style: solid;
  border-color: #d7d7d7;
  border-radius: 0px 0px 3px 3px;
  box-shadow: 0px 10px 20px rgba(132, 131, 131, 0.25);
`;

export default function NotificationSettingsParentItem({ minorCategoryItem }) {
  const dispatch = useDispatch();
  const { customAliases } = useSelector(state => state.currentOrganization);

  const displayName = replaceCustomAliasesHandlebars(minorCategoryItem.display_name, customAliases);

  const handleToggleAll = toggleOn => {
    minorCategoryItem.send_grid_mailer_types.forEach(mailerType => {
      dispatch(
        updateNotificationOptOut({
          mailer_opt_out: {
            userId: userId,
            archived: toggleOn,
            send_grid_mailer_type_id: mailerType.id,
          },
        }),
      );
    });
  };

  return (
    <AccordionItem>
      <ItemHeadingContainer>
        <span>{displayName}</span>
        <ItemActionsContainer>
          <ToggleButtonsContainer>
            <ToggleAllButton
              type="button"
              onClick={() => {
                handleToggleAll(true);
                const liveRegion = document.getElementById('liveRegion');
                liveRegion.textContent = `Notification ON for all ${displayName}`;
              }}
            >
              turn all on
              <ScreenReaderText> for {displayName}</ScreenReaderText>
            </ToggleAllButton>
            <span>|</span>
            <ToggleAllButton
              type="button"
              onClick={() => {
                handleToggleAll(false);
                const liveRegion = document.getElementById('liveRegion');
                liveRegion.textContent = `Notification OFF for all ${displayName}`;
              }}
            >
              turn all off
              <ScreenReaderText> for {displayName}</ScreenReaderText>
            </ToggleAllButton>
            <div id="liveRegion" aria-live="assertive" style={{position: "absolute", left: "-9999px"}}></div>
          </ToggleButtonsContainer>
          
          <AccordionItemHeading aria-level={3}>
            
            <ItemButton aria-label={`Toggle viewing notifications available for ${displayName}`}>
              <AccordionItemState>
                {({ expanded }) => (expanded ? <ChevronUpIcon /> : <ChevronDownIcon />)}
              </AccordionItemState>
            </ItemButton>
          </AccordionItemHeading>
        </ItemActionsContainer>
      </ItemHeadingContainer>
      <ItemPanel>
        {minorCategoryItem.send_grid_mailer_types.map(mailerType => (
          <NotificationSettingsChildItem key={`mailer-type-${mailerType.id}`} mailerType={mailerType} />
        ))}
      </ItemPanel>
    </AccordionItem>
  );
}

NotificationSettingsParentItem.propTypes = {
  minorCategoryItem: minorCategoryItem.isRequired,
};
