import React from 'react'; 

export default function DownloadIcon({width = "none", height = "none", color="#446372"}) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" style={{
        height,
        width
    }}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.35331 1.14562C8.44708 1.23939 8.49976 1.36657 8.49976 1.49918V4.99918H7.49976V1.49918C7.49976 1.36657 7.55243 1.23939 7.6462 1.14562C7.73997 1.05185 7.86715 0.999176 7.99976 0.999176C8.13236 0.999176 8.25954 1.05185 8.35331 1.14562ZM7.49976 4.99918H4.24976C3.78578 4.99967 3.34095 5.18421 3.01287 5.51229C2.68479 5.84037 2.50025 6.2852 2.49976 6.74918V13.2492C2.50025 13.7132 2.68479 14.158 3.01287 14.4861C3.34095 14.8141 3.78578 14.9987 4.24976 14.9992H11.7498C12.2137 14.9987 12.6586 14.8141 12.9866 14.4861C13.3147 14.158 13.4993 13.7132 13.4998 13.2492V6.74918C13.4993 6.2852 13.3147 5.84037 12.9866 5.51229C12.6586 5.18421 12.2137 4.99967 11.7498 4.99918H8.49976V9.79199L10.1463 8.14574C10.2408 8.05593 10.3667 8.0066 10.4971 8.00827C10.6275 8.00994 10.7521 8.06247 10.8443 8.15467C10.9365 8.24688 10.989 8.37145 10.9907 8.50183C10.9923 8.63221 10.943 8.75808 10.8532 8.85261L8.35319 11.3526C8.25943 11.4463 8.13231 11.4989 7.99976 11.4989C7.8672 11.4989 7.74008 11.4463 7.64632 11.3526L5.14632 8.85261C5.05651 8.75808 5.00718 8.63221 5.00885 8.50183C5.01052 8.37145 5.06305 8.24688 5.15525 8.15467C5.24746 8.06247 5.37203 8.00994 5.50241 8.00827C5.63279 8.0066 5.75866 8.05593 5.85319 8.14574L7.49976 9.79199V4.99918Z"
        fill={color}
      />
    </svg>
  );
}
