import React from 'react';

export default function ToggleCheckBoxIcon({ checked = false }) {
  if (checked) {
    return (
      <svg width="36" height="20" viewBox="0 0 36 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_2655_1154)">
          <rect width="36" height="20" rx="10" fill="#446372" />
          <g filter="url(#filter0_dd_2655_1154)">
            <rect x="18" y="2" width="16" height="16" rx="8" fill="#FCFCFD" />
            <path
              d="M30 7L24.5 12.5L22 10"
              stroke="#446372"
              stroke-width="1.6666"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
        </g>
        <defs>
          <filter
            id="filter0_dd_2655_1154"
            x="15"
            y="0"
            width="22"
            height="22"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="1" />
            <feGaussianBlur stdDeviation="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.06 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2655_1154" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="1" />
            <feGaussianBlur stdDeviation="1.5" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.1 0" />
            <feBlend mode="normal" in2="effect1_dropShadow_2655_1154" result="effect2_dropShadow_2655_1154" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_2655_1154" result="shape" />
          </filter>
          <clipPath id="clip0_2655_1154">
            <rect width="36" height="20" rx="10" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  }
  return (
    <svg width="36" height="20" viewBox="0 0 36 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_2655_2371)">
        <rect width="36" height="20" rx="10" fill="#D2E4ED" />
        <g filter="url(#filter0_dd_2655_2371)">
          <rect x="2" y="2" width="16" height="16" rx="8" fill="#FCFCFD" />
          <path
            d="M13 7L7 13M7 7L13 13"
            stroke="#446372"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_dd_2655_2371"
          x="-1"
          y="0"
          width="22"
          height="22"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.06 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2655_2371" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1.5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.1 0" />
          <feBlend mode="normal" in2="effect1_dropShadow_2655_2371" result="effect2_dropShadow_2655_2371" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_2655_2371" result="shape" />
        </filter>
        <clipPath id="clip0_2655_2371">
          <rect width="36" height="20" rx="10" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
