import React, { lazy, Suspense, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import * as yup from 'yup';
import _get from 'lodash/get';
import { Controller, useForm, useFieldArray } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import StyledIcon from '../../../../../styledComponents/styles/Icon.styled';
import TextInput from '../../../../../styledComponents/styles/TextInput.styled';
import Toggle from '../../../../../styledComponents/styles/Toggle.styled';
import { AccentButtonMediumNunito } from '../../../../../styledComponents/styles/Buttons.styled';
import { AccentButtonSpinner } from '../../../../../styledComponents/styles/spinner.styled';
import { updateForm, updateQuestion } from '../../../../../actions/formsActions';
import StartRatingOptionFields from './question.starRating.optionFields'; 


const EditTextArea = lazy(() => import('../../../../../styledComponents/styles/EditTextArea.styled'));

const openSansFont = css`
  font-family: ${({ theme }) => theme.textInput.fontFamilyOpenSans};
`;

const FormContainer = styled.div`
  font-family: 'Nunito', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 0.015em;
  color: #373737;
`;

const InputContainer = styled.div`
  flex: 1;
  margin: 20px 0;
  text-align: left;
`;

const Number = styled.div`
  font-family: 'Nunito', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #373737;
  display: flex;
  align-items: center;
  margin-right: 13px;
}
`;

const OptionContainer = styled.span`
  display: flex;
  margin-bottom: 12px;
`;

const OtherOptionContainer = styled.div`
  font-family: 'Nunito', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  display: flex;
  align-items: center;
  letter-spacing: 0.0015em;
  text-decoration-line: underline;
  color: #446372;
  margin: 22px 0 34px 30px;
  cursor: pointer;
`;

const RemoveOption = styled.main`
  align-items: center;
  cursor: pointer;
  display: flex;
  margin-left: 20px;
`;

const SubmitContainer = styled.main`
  display: flex;
  justify-content: flex-end;
  margin-top: 35px;
`;

const ToggleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: inherit;
`;

const ToggleLabel = styled.span`
  align-self: center;
  flex: 1;
  justify-content: center;
  padding-left: 10px;
  padding-bottom: 7px;
`;

const Error = styled.div`
  color: ${({ theme }) => theme.textInput.error.fontColor};
  display: inline-block;
  font-family: ${({ theme }) => theme.textInput.error.fontFamily};
  font-size: ${({ theme }) => theme.textInput.error.fontSize};
  font-weight: ${({ theme }) => theme.textInput.error.fontWeight};
  padding-bottom: 20px;
  vertical-align: middle;
  ${({ openSans }) => (openSans ? openSansFont : null)} svg {
    justify-content: center;
    margin-right: 5px;

    vertical-align: middle;
    display: inline-block;
  }

  span {
    vertical-align: middle;
    display: inline-block;
  }
`;

export default function QuestionStarRatingAddEdit({ question, edit, onClose }) {
  const MAX_LENGTH = 90;
  const qIdentifier = 'star_rating';
  const dispatch = useDispatch();
  const fetchForm = useSelector(state => state.forms.fetchForm);
  const questionTypesInfo = useSelector(state => state.forms.questionTypes);
  const updateFormSubmit = useSelector(state => state.forms.updateForm);
  const updateQuestionSubmit = useSelector(state => state.forms.updateQuestion);
  const [formId, setFormId] = useState(null);
  const [focusId, setFocusId] = useState(null);
  const [disableSubmit, setDisableSubmit] = useState(false);

  function isValidOptions(message) {
    return this.test('isValidOptions', message, function(value) {
      const { path, createError } = this;
      const realOptions = value.filter(opt => !opt._destroy);
      const emptyValue = realOptions.filter(opt => opt.value === '');

      if (emptyValue && emptyValue.length > 0) {
        return createError({ path, message: '* All ratings must have a value' });
      }

      if (realOptions && realOptions.length > 2 && realOptions.length < 6) {
        return true;
      } else {
        return createError({ path, message: '* Must have between 3 and 5 ratings' });
      }
    });
  }
  yup.addMethod(yup.array, 'isValidOptions', isValidOptions);

  const schema = yup.object().shape({
    star_rating_label: yup
      .string()
      .required('Question must have label')
      .max(MAX_LENGTH, `Label can not be more than ${MAX_LENGTH} characters`),
    star_rating_instructions: yup.string(),
    star_rating_question_options_attributes: yup.array().isValidOptions(),
    star_rating_admin_only: yup.boolean(),
    star_rating_required: yup.boolean(),
  });
  const { control, handleSubmit, formState, setValue } = useForm({
    shouldUnregister: false,
    resolver: yupResolver(schema),
    defaultValues: {
      star_rating_label: edit ? question.label : '',
      star_rating_instructions: edit ? question.instructions || '' : '',

      star_rating_question_options_attributes: edit
        ? question.star_rating_question_options.map(opt => {
            return { value: opt.name, order: opt.order, optId: opt.id };
          }).sort((a, b) => (a.order > b.order ? 1 : -1))
        : [
            { value: 'Do Not Agree', order: 0, optId: -1111 },
            { value: 'Agree', order: 1, optId: -2222 },
            { value: 'Strongly Agree', order: 2, optId: -3333 },
          ],
      star_rating_admin_only: false,
      star_rating_required: edit ? question.required : true,
    },
    mode: 'onChange',
  });

  const { fields, append, swap } = useFieldArray({
    control,
    name: 'star_rating_question_options_attributes',
  });

  useEffect(() => {
    if (!fetchForm?.loading && fetchForm?.data) {
      setDisableSubmit(false);
      setFormId(fetchForm.data.id);
    }
  }, [fetchForm]);

  useEffect(() => {
    if (
      (!updateFormSubmit?.loading && updateFormSubmit?.data) ||
      (!updateQuestionSubmit?.loading && updateQuestionSubmit?.data)
    ) {
      setDisableSubmit(false);
      onClose();
    }
  }, [updateFormSubmit, updateQuestionSubmit]);

  useEffect(() => {
    if (updateQuestionSubmit && updateQuestionSubmit.data && !updateQuestionSubmit.loading) {
      let options = [];
      updateQuestionSubmit.data.attributes.question_options.forEach(opt => {
        options.push({ value: opt.name, order: opt.order, optId: opt.id });
      });
      options.sort((a, b) => {
        if (a.order < b.order) return -1;
        else if (a.order > b.order) return 1;
        return 0;
      });

      setValue(`star_rating_question_options_attributes`, options);
    }
  }, [updateQuestionSubmit]);

  useEffect(() => {
    // Focus on the newly added rating option list item
    if (focusId) {
      document.getElementById(focusId)?.focus();
      //clear it out of state
      setFocusId(null);
    }
  }, [focusId]);

  useEffect(() => {
    //Focus on the first element in the editor
    setFocusId('star_rating_label');
  }, []);

  const onSubmitHandler = data => {
    if (questionTypesInfo && questionTypesInfo.data) {
      const questionType = questionTypesInfo.data.reduce((prev, curr) => {
        return prev || curr.attributes.question_types.find(question => question.identifier === qIdentifier);
      }, undefined);

      const deletedOptions = data.star_rating_question_options_attributes
        .filter(item => item._destroy && item.optId > 0)
        .map(item => {
          return {
            id: item.optId < 1 ? '' : item.optId,
            order: 0,
            _destroy: true,
          };
        });

      let updateOptions = data.star_rating_question_options_attributes
        .filter(item => !item._destroy)
        .map(item => {
          return {
            name: item.value,
            order: item.order,
            id: item.optId < 1 ? '' : item.optId,
          };
        });

      const optsToSave = updateOptions.concat(deletedOptions);

      let orderCount = 1;
      optsToSave.forEach((item, index) => {
        item.order = orderCount;
        orderCount++;
      });

      const values = {
        admin_only: false,
        star_rating_question_options_attributes: optsToSave,
        instructions: data.star_rating_instructions,
        label: data.star_rating_label,
        required: data.star_rating_required,
      };

      if (formId && data && (questionType?.id || question?.id)) {
        //update or save new
        const actionToCall = edit ? updateQuestion : updateForm;
        const idToUpdate = edit ? question.id : questionType.id;
        setDisableSubmit(true);
        dispatch(actionToCall(formId, questionType.endpoint, idToUpdate, values));
      }
    }
  };

  const createOption = nextOrder => {
    append({ optId: -nextOrder, value: '', order: nextOrder });
    setFocusId(`star_rating_question_options_attributes[${nextOrder - 1}]`);
  };

  return (
    <FormContainer>
      <form id="starRatingAdd" onSubmit={handleSubmit(onSubmitHandler)}>
        <Controller
          control={control}
          name="star_rating_label"
          render={({ field: { ref, ...rest } }) => (
            <InputContainer>
              <TextInput
                aria-label="Star Rating Label"
                errorMsg={_get(formState.errors.star_rating_label, 'message') || ''}
                id="star_rating_label"
                label="Star Rating Label"
                openSans
                placeholder="Enter Label"
                required
                {...rest}
              />
            </InputContainer>
          )}
        />
        <Controller
          control={control}
          name="star_rating_instructions"
          render={({ field: { ref, ...rest } }) => (
            <InputContainer>
              <Suspense fallback={<div />}>
                <EditTextArea
                  aria-label="Instructions"
                  errorMsg={_get(formState.errors.star_rating_instructions, 'message') || ''}
                  placeholder="Enter Instructions"
                  label="Instructions"
                  id="star_rating_instructions"
                  {...rest}
                />
              </Suspense>
            </InputContainer>
          )}
        />

        <StartRatingOptionFields
          fields={fields}
          setValue={setValue}
          setFocusId={setFocusId}
          createOption={createOption}
          moveCard={(dragIndex, hoverIndex) => {
            swap(dragIndex, hoverIndex)
          }}
        />

        <Error>{_get(formState.errors.star_rating_question_options_attributes, 'message')}</Error>

        <Controller
          control={control}
          name="star_rating_required"
          render={({ field: { onChange, register, ref, ...rest } }) => (
            <ToggleContainer>
              <Toggle
                id="star_rating_required"
                label="Required"
                onChange={value => {
                  setValue('star_rating_required', value);
                }}
                ref={register}
                {...rest}
              />
              <ToggleLabel>Required Field</ToggleLabel>
            </ToggleContainer>
          )}
        />
        <SubmitContainer>
          <AccentButtonMediumNunito
            aria-label={edit ? 'Save Star Rating Text' : 'Add Star Rating to Form'}
            form="starRatingAdd"
            type="submit"
            disabled={disableSubmit}
          >
            {disableSubmit ? <AccentButtonSpinner displayText="Saving ..." /> : edit ? 'Save' : 'Add to Form'}
            {!disableSubmit && <StyledIcon type="ChevronForward" color="#fff" />}
          </AccentButtonMediumNunito>
        </SubmitContainer>
      </form>
    </FormContainer>
  );
}
