import { sortBy } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchProgramTypes } from '../../../actions/lookupActions';

export default function useProgramTypeOptions() {
  const dispatch = useDispatch();
  const { programTypes } = useSelector(state => state.lookup.allProgramTypes);

  const [allOptions, setAllOptions] = useState([]);

  useEffect(() => {
    if (!programTypes) {
      dispatch(fetchProgramTypes());
    }
  }, []);

  useEffect(
    () => {
      if (programTypes) {
        const types = sortBy(programTypes, type => type.attributes.order).map(programType => ({
          value: programType.attributes.name,
          label: programType.attributes.name,
        }));

        setAllOptions(types);
      }
    },
    [programTypes],
  );

  return [allOptions];
}
