import React from 'react';

export default function HamburgerMenuIcon({width = "26", height = "18"}) {
  return (
    <svg width={width} height={height} viewBox="0 0 26 18" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.475586 2.45068C0.475586 1.58774 1.17514 0.888184 2.03809 0.888184H23.9131C24.776 0.888184 25.4756 1.58774 25.4756 2.45068C25.4756 3.31363 24.776 4.01318 23.9131 4.01318H2.03809C1.17514 4.01318 0.475586 3.31363 0.475586 2.45068Z"
        fill="#446372"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.475586 9.22156C0.475586 8.35861 1.17514 7.65906 2.03809 7.65906H23.9131C24.776 7.65906 25.4756 8.35861 25.4756 9.22156C25.4756 10.0845 24.776 10.7841 23.9131 10.7841H2.03809C1.17514 10.7841 0.475586 10.0845 0.475586 9.22156Z"
        fill="#446372"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.475586 15.9923C0.475586 15.1294 1.17514 14.4298 2.03809 14.4298H23.9131C24.776 14.4298 25.4756 15.1294 25.4756 15.9923C25.4756 16.8553 24.776 17.5548 23.9131 17.5548H2.03809C1.17514 17.5548 0.475586 16.8553 0.475586 15.9923Z"
        fill="#446372"
      />
    </svg>
  );
}
