import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _isString from 'lodash/isString';
import styled from 'styled-components';

//import Modal from '../base/Modal';
import ProfileSelectInput from '../base/inputs/ProfileSelectInput';
import ToastMessage from '../../../viaGlobal/components/toastMessage';
import { ModalBody, ModalButton, ModalFooter,ModalCloseButton } from '../base/Modal.styled';
import CheckboxInput from '../../../travelerForms/components/base/inputs/CheckboxInput';
import { AccentButtonSpinner } from '../../../../styledComponents/styles/spinner.styled';
import ScreenReaderLabel from '../../../travelerForms/components/base/inputs/ScreenReaderLabel.styled';
import NoBorderTextInput from '../../../travelerForms/components/base/inputs/NoBorderTextInput.styled';
import { updateTravelerForm } from '../../../../actions/formsActions';
import { breakpoints } from '../../utils/breakpoints';
//import getWithdrawalOptions from '../../utils/options/withdrawalOptions';
import { reasonOptions } from '../../../../helpers';
import {
  toggleWithdrawModal,
  getApplicationStatuses,
} from '../../actions/travelerApplicationsActions';
import Modal from 'react-responsive-modal';
const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  h2 {
    display: none;
  }

  @media ${breakpoints.desktop} {
    h2 {
      display: block;
    }
  }
`;

const InfoText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  color: #373737;
  letter-spacing: 0.005em;
  line-height: 140%;

  @media ${breakpoints.desktop} {
    text-align: center;
  }
`;

const DesktopContent = styled.fieldset`
  display: none;

  legend {
    margin-bottom: 1rem;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 0.875rem;
    color: #373737;
    letter-spacing: 0.0025em;
    line-height: 120%;
  }

  @media ${breakpoints.desktop} {
    display: block;
    width: 100%;
    margin-top: 0.5625rem;
  }
`;

const RequiredField = styled.span`
  margin-left: 0.2rem;
  color: #770711;
`;

const CheckboxGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 0.5rem;
`;

const CheckboxGridColumn = styled.div`
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-column: span 2 / span 2;
  gap: 0.5rem;

  div,
  input[type='text'] {
    grid-column: span 1 / span 1;
  }

  @media ${breakpoints.tablet} {
    grid-template-rows: repeat(8, minmax(0, 1fr));
    grid-column: span 1 / span 1;
  }
`;

const MobileContent = styled.div`
  display: block;
  width: 100%;

  #other_option_text {
    width: 100%;
    margin-top: 1rem;
  }

  @media ${breakpoints.desktop} {
    display: none;
  }
`;

const NoWrapButton = styled(ModalButton)`
  white-space: nowrap;
`;

const WithdrawApplicationModal = () => {
  const dispatch = useDispatch();

  const { customAliases } = useSelector(state => state.currentOrganization);
  const { admin_sign_in: isAdmin } = useSelector(state => state.profile.currentUser);
  const travelerSubmission = useSelector(state => state.forms.travelerSubmission);
  const patchTravelerForm = useSelector(state => state.forms.patchTravelerForm);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [submission, setSubmission] = useState(travelerSubmission?.data || {});
  const [submissionId, setSubmissionId] = useState();
  useEffect(() => {
    if (!travelerSubmission.loading && travelerSubmission.data) {
      setSubmission(travelerSubmission.data);
      setSubmissionId(travelerSubmission.data.id);
    }
  }, [travelerSubmission]);

  const {
    applicationStatuses,
    showWithdrawModal: show,
  } = useSelector(state => state.travelerApplicationSubmissions);

  const [value, setValue] = useState([]);
  const [showToast, setShowToast] = useState(false);
  const [showToastError, setShowToastError] = useState(false);
  const [otherOption, setOtherOption] = useState('');
  const [showOtherOption, setShowOtherOption] = useState(false);
  const[toastMessage, setToastMessage] = useState('');
  const options = reasonOptions(customAliases?.alias_program ? customAliases?.alias_program : "Program");

  useEffect(
    () => {
      if (applicationStatuses.length === 0) {
        dispatch(getApplicationStatuses());
      }
    },
    [applicationStatuses],
  );

  const close = () => {
    dispatch(toggleWithdrawModal());

    setValue([]);
    setOtherOption('');
  };

  const handleOnChange = reason => {
    if(showOtherOption===true) {
      setOtherOption('');
    }
    if (value.includes(reason)) {
      setValue(value.filter(element => element !== reason));

      if (reason === 'Other') {
        setOtherOption('');
      }
    } else {
      setValue([...value, reason]);
    }
  };

  const handleSelectChange = event => {
    setValue(event || []);
  };

  const handleWithdraw = () => {
    setDisableSubmit(true);
    dispatch(updateTravelerForm('Application', submissionId, {
        reasons: _isString(value[0]) ? [...value, otherOption] : [...value.map(reason => reason.value), otherOption],
      }, 
      applicationStatuses.find(status => status.attributes.name === 'Withdrawn').id));
  };

  useEffect(()=>{
    if (!patchTravelerForm?.loading) {
      setDisableSubmit(false);
      if (patchTravelerForm?.data && !patchTravelerForm?.error) {
        setShowToastError(false);
        setShowToast(true);
        setTimeout(()=>{
          close();
        },0)
      } else if (patchTravelerForm?.error) {
        setShowToast(false);
        setShowToastError(true);
        //setToastMessage(patchTravelerForm.message[0]);
        setToastMessage("Can't withdraw application, Please refresh the page.");
        setTimeout(()=>{
          close();
        },0)
      }
      
    }
  },[patchTravelerForm])

  const withdrawTitle = isAdmin ? `Withdraw ${customAliases.alias_traveler} Application` : `Withdraw Application`;
  const withdrawCopy = isAdmin ? `Are you sure you want to withdraw the ${submission.program_range.program_title} application for this ${customAliases.alias_traveler}?` : `Are you sure you want to withdraw the ${submission.program_range.program_title} application?`;
  const withdrawLabel = isAdmin ? `Why are you choosing to withdraw this ${customAliases.alias_traveler}?` : 'Why are you choosing to withdraw?';
  //Make the left list of options longer for style reasons
  const halfOptionsLength = Math.ceil(options?.length / 2);

  return (submission && (
    <>
      {/* <Modal show={show} close={close} title="Withdraw Application" maxWidth="750px" hideTitleOnDesktop showOverflow> */}
      <Modal
        blockScroll={false}
        open={show}
        onClose={close}
        center
        closeOnEsc
        closeOnOverlayClick
        showCloseIcon={false}
        styles={{
          modal: {
            padding: '0px',
           // borderRadius: '5px',
            width: '750px',
          },
        }}
      >
        <div style={{display:"flex",justifyContent:"end", padding:"20px"}}>
          <ModalCloseButton type="button" aria-label="Close Modal" onClick={close}>
              <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24" aria-hidden="true">
                <path
                  d="M24.5 2.417 22.083 0 12.5 9.583 2.917 0 .5 2.417 10.083 12 .5 21.583 2.917 24l9.583-9.583L22.083 24l2.417-2.417L14.917 12 24.5 2.417Z"
                  fill="#9FABAE"
                />
              </svg>
          </ModalCloseButton>
        </div>
        <ModalBody className="no-padding-top">
          <BodyContainer>
            <h2>{withdrawTitle}</h2>
            <InfoText>
              <p>{withdrawCopy}</p>
              {!isAdmin && (
                <p>
                  After confirming your withdrawal request, you must contact your advisor to reinstate your application
                  for a current or future term.
                </p>
              )}
            </InfoText>
            <MobileContent>
              <ProfileSelectInput
                id="withdrawal_reasons"
                label={withdrawLabel}
                placeholder="Select your reasoning"
                value={value}
                options={options}
                isMulti
                required
                alwaysShowRequired
                handleOnChange={handleSelectChange}
              />
              {!!value.find(option => option.label === 'Other') && (
                <>
                  <ScreenReaderLabel htmlFor="other_option_text">Other Option</ScreenReaderLabel>
                  <NoBorderTextInput
                    id="other_option_text"
                    type="text"
                    placeholder="Enter other reason for withdrawing"
                    value={otherOption}
                    onChange={event => {
                      setOtherOption(event.target.value);
                    }}
                  />
                </>
              )}
            </MobileContent>
            <DesktopContent>
              <legend>
                Why are you choosing to withdraw?
                <RequiredField>&#42;</RequiredField>
              </legend>
              <CheckboxGrid>
                <CheckboxGridColumn>
                  {options?.slice(0, halfOptionsLength)?.map(option => (
                    <CheckboxInput
                      key={`withdrawal-option-${option.id}`}
                      id={`withdrawal_option_${option.id}`}
                      value={option.value}
                      label={option.label}
                      checked={value.includes(option.value)}
                      handleOnChange={() => {
                        handleOnChange(option.value);
                      }}
                    />
                  ))}
                </CheckboxGridColumn>
                <CheckboxGridColumn>
                  {options?.slice(halfOptionsLength).map(option => (
                    <CheckboxInput
                      key={`withdrawal-option-${option.id}`}
                      id={`withdrawal_option_${option.id}`}
                      value={option.value}
                      label={option.label}
                      checked={value.includes(option.value)}
                      handleOnChange={() => {
                        if(option.label==="Other") {
                          setShowOtherOption(!showOtherOption)
                        }
                        handleOnChange(option.value);
                      }}
                    />
                  ))}
                  {
                    // value.includes('Other') && (
                    showOtherOption && (
                    <>
                      <ScreenReaderLabel htmlFor="other_option_text">Other Option</ScreenReaderLabel>
                      <NoBorderTextInput
                        id="other_option_text"
                        type="text"
                        placeholder="Enter other reason for withdrawing"
                        value={otherOption}
                        onChange={event => {
                          setOtherOption(event.target.value);
                        }}
                      />
                    </>
                  )}
                </CheckboxGridColumn>
              </CheckboxGrid>
            </DesktopContent>
          </BodyContainer>
        </ModalBody>
        <ModalFooter>
          <div>
            <ModalButton type="button" className="secondary" onClick={close}>
              Cancel
            </ModalButton>
          </div>
          <div>
            <NoWrapButton
              type="button"
              className="primary"
              onClick={handleWithdraw}
              disabled={disableSubmit || value.length === 0 || (value.includes('Other') && !otherOption)}
            >
              {disableSubmit ? <AccentButtonSpinner displayText='Withdrawing Application ...' /> : 'Withdraw Application'}
            </NoWrapButton>
          </div>
        </ModalFooter>
      </Modal>
      {showToast && (
        <ToastMessage
          message="Application withdrawn"
          isSuccess
          show={showToast}
          onClose={() => {
            setShowToast(false);
          }}
        />
      )}
      {showToastError && (
        <ToastMessage
          message={toastMessage}
          isSuccess={false}
          show={showToastError}
          onClose={() => {
            setShowToastError(false);
          }}
        />
      )}
    </>
  ));
}

export default WithdrawApplicationModal;
