exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@-webkit-keyframes Y_sHx{to{-webkit-transform:rotate(1turn)}}@keyframes Y_sHx{to{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}._1uoOs{background-color:#ebebeb;color:#043544;width:100%;padding:16px 0;font-size:16px;line-height:1;font-weight:500}@media only screen and (max-width:599px){._1uoOs{padding:16px 4px;font-size:12px}}._1uoOs ._3OY-7{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-wrap:wrap;flex-wrap:wrap;width:100%;-ms-flex-align:center;align-items:center}._1uoOs ._3OY-7 ._2oB-G{padding:0 16px}._1uoOs ._3OY-7 ._16H-o{color:#043544;text-decoration:none}._1uoOs ._3OY-7 ._28QOP{color:#1c748a;text-decoration:underline}", ""]);

// exports
exports.locals = {
	"subheader": "_1uoOs",
	"subheader-content": "_3OY-7",
	"item": "_2oB-G",
	"selection": "_16H-o",
	"selected": "_28QOP",
	"spin": "Y_sHx"
};