import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';

import IntakeSection from './IntakeSection';

import advisingOptions from '../../utils/options/advisingOptions';

export default function IntakeQuestions() {
  const intake = useSelector(state => state.intakeForm);

  const [advising, setAdvising] = useState('');
  const [otherOption, setOtherOption] = useState('');

  useEffect(
    () => {
      if (intake.advising) {
        const selectedAdvisingOptions = [];

        for (const [key, value] of Object.entries(intake.advising)) {
          if (key === 'other_concern_question_text') {
            setOtherOption(value || '');
          }

          if (value === true || value === 't') {
            selectedAdvisingOptions.push(key);
          }
        }

        const advisingValues = selectedAdvisingOptions.reduce((accumulator, advisingOption) => {
          const value = advisingOptions.find(option => option.value === advisingOption);

          if (value) {
            accumulator.push(value.label);
          }

          return accumulator;
        }, []);

        if (advisingValues.length > 0) {
          setAdvising(advisingValues.join('; '));
        }
      }
    },
    [intake.advising],
  );

  return (
    <IntakeSection heading="Questions/Concerns">
      <p>{advising || 'None selected'}</p>
      {otherOption && (
        <p>
          <strong>Other:</strong> {otherOption}
        </p>
      )}
    </IntakeSection>
  );
}
