import styled from 'styled-components';

import { breakpoints } from '../../utils/breakpoints';
import { buttonFocus } from '../../utils/shared/focusStyles';

export const Container = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-top: 0.75rem;
  background-color: #ffffff;
  border: 1px solid #bbbbbb;
  border-radius: 3px;
  box-shadow: 0px 10px 20px rgba(132, 131, 131, 0.3);

  @media ${breakpoints.desktop} {
    flex-direction: row;
    gap: 0;
    padding: 1.0625rem 2.47rem 1.0625rem 1.22rem;
  }

  ${props => {
    if (props.isRegistered) {
      return `
        gap: 0.6875rem;
      `;
    } else {
      return `
        gap: 2.8125rem;
      `;
    }
  }};
`;

export const EventInfoContainer = styled.div`
  display: flex;
  align-items: baseline;
  gap: 1.209375rem;
  width: 100%;
  padding: 0 0.75rem;

  @media ${breakpoints.desktop} {
    width: auto;
    align-items: center;
    padding: 0;
  }
`;

export const EventDay = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.375rem;
  background-color: var(--theme_color_dark);
  border-radius: 5px;
  font-family: 'Nunito', sans-serif;
  font-weight: 800;
  font-size: 1.125rem;
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 0.015em;
  line-height: 120%;
`;

export const EventDateContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.6875rem;

  @media ${breakpoints.desktop} {
    gap: 0.265625rem;
  }
`;

export const EventHeading = styled.div`
  display: flex;
  align-items: center;
  gap: 0.875rem;
`;

export const EventName = styled.span`
  margin-bottom: 0;
  font-family: 'Nunito';
  font-weight: 500;
  font-size: 1.125rem;
  color: #373737;
  letter-spacing: 0.06em;
  line-height: 120%;
`;

export const RegisteredPill = styled.div`
  padding: 0.125rem 0.4375rem;
  background-color: #deecff;
  border: 1px solid #255a94;
  border-radius: 3px;
  font-family: 'Nunito', sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  color: #255a94;
  line-height: 120%;

  ${props => {
    if (props.forDesktop) {
      return `
        display: none;

        @media ${breakpoints.desktop} {
          display: block;
        }
      `;
    } else {
      return `
        display: block;
        align-self: flex-start;

        @media ${breakpoints.desktop} {
          display: none;
        }
      `;
    }
  }};
`;

export const TodayContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-family: 'Nunito', sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  color: #585858;
  letter-spacing: 0.0015em;
  line-height: 120%;
`;

export const TodayIndicator = styled.div`
  width: 10px;
  height: 10px;
  background-color: #0e5b2f;
  border-radius: 50%;
`;

export const FullDateContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.690625rem;
`;

export const FullDate = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  color: #474747;
  letter-spacing: 0.06em;
  line-height: 120%;

  ${props => {
    if (props.category === 'cancelled') {
      return `
        font-style: italic;
      `;
    }
  }};
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1.4375rem;
  width: 100%;

  @media ${breakpoints.desktop} {
    width: auto;
  }
`;

export const RegisterButton = styled.button`
  flex-grow: 1;
  padding: 0.875rem 0;
  background-color: var(--theme_color_accent);
  border: none;
  border-top: 1px solid #bbbbbb;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 1rem;
  color: var(--theme_color_accent_font);
  letter-spacing: 0.06em;
  line-height: 120%;

  &:hover {
    opacity: 0.8;
  }

  @media ${breakpoints.desktop} {
    flex-grow: unset;
    padding: 0.6875rem 1.75rem;
    border: 1px solid var(--theme_color_accent);
    border-radius: 5px;
  }

  ${buttonFocus};
`;

export const ViewLink = styled.a`
  flex-grow: 1;
  padding: 0.875rem 0;
  background-color: #eeeeee;
  border: none;
  border-top: 1px solid #bbbbbb;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 1rem;
  color: #373737;
  text-align: center;
  letter-spacing: 0.06em;
  line-height: 120%;

  &:hover {
    opacity: 0.8;
    color: #373737;
    text-decoration: none;
  }

  @media ${breakpoints.desktop} {
    flex-grow: unset;
    padding: 0.6875rem 1.75rem;
    border: 1px solid #373737;
    border-radius: 5px;
  }

  ${buttonFocus};

  ${props => {
    if (!props.isRegistered) {
      return `
        //display: none;

        @media ${breakpoints.desktop} {
          display: block;
        }
      `;
    }
  }};
`;
