import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 0.625rem;
  margin-top: 0.3125rem;
`;

const Message = styled.span`
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  color: #8b1313;
  line-height: 140%;
`;

export default function Error({ message }) {
  return (
    <Container className="error-message">
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
        <path
          d="M15.7875 14.0302L9.7958 2.9032C9.37111 2.1143 8.23978 2.1143 7.81474 2.9032L1.82341 14.0302C1.7312 14.2014 1.68497 14.3937 1.68924 14.5881C1.6935 14.7826 1.74811 14.9726 1.84773 15.1397C1.94736 15.3068 2.0886 15.4451 2.25767 15.5413C2.42673 15.6375 2.61785 15.6882 2.81236 15.6885H14.7968C14.9914 15.6885 15.1828 15.638 15.3521 15.542C15.5214 15.4459 15.6629 15.3076 15.7627 15.1404C15.8625 14.9733 15.9173 14.7832 15.9216 14.5886C15.926 14.394 15.8798 14.2016 15.7875 14.0302ZM8.80544 13.9658C8.66638 13.9658 8.53044 13.9246 8.41481 13.8473C8.29918 13.7701 8.20906 13.6602 8.15584 13.5318C8.10262 13.4033 8.0887 13.2619 8.11583 13.1255C8.14296 12.9891 8.20993 12.8638 8.30826 12.7655C8.40659 12.6672 8.53188 12.6002 8.66827 12.5731C8.80466 12.546 8.94604 12.5599 9.07452 12.6131C9.203 12.6663 9.31281 12.7564 9.39007 12.8721C9.46733 12.9877 9.50857 13.1236 9.50857 13.2627C9.50857 13.355 9.49038 13.4465 9.45505 13.5318C9.41971 13.6171 9.36792 13.6946 9.30263 13.7599C9.23734 13.8252 9.15982 13.877 9.07452 13.9123C8.98921 13.9476 8.89778 13.9658 8.80544 13.9658ZM9.56904 6.89414L9.36724 11.1832C9.36724 11.3324 9.30798 11.4755 9.20249 11.5809C9.097 11.6864 8.95393 11.7457 8.80474 11.7457C8.65556 11.7457 8.51248 11.6864 8.40699 11.5809C8.3015 11.4755 8.24224 11.3324 8.24224 11.1832L8.04044 6.8959C8.03591 6.79345 8.05205 6.69114 8.0879 6.59506C8.12374 6.49898 8.17857 6.4111 8.24911 6.33666C8.31964 6.26222 8.40444 6.20274 8.49845 6.16178C8.59246 6.12081 8.69376 6.09919 8.7963 6.0982H8.80369C8.90693 6.09815 9.00911 6.11901 9.10407 6.15954C9.19903 6.20006 9.28479 6.2594 9.35619 6.33399C9.42758 6.40857 9.48312 6.49684 9.51946 6.59347C9.55581 6.69011 9.57219 6.79311 9.56763 6.89625L9.56904 6.89414Z"
          fill="#8B1313"
        />
      </svg>
      <Message role="alert" aria-live="assertive" >{message}</Message>
    </Container>
  );
}

Error.propTypes = {
  message: PropTypes.string.isRequired,
};
