exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@-webkit-keyframes _390CW{to{-webkit-transform:rotate(1turn)}}@keyframes _390CW{to{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}._2vW7B{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column}._2vW7B .dMuNd{padding:35px 40px 0;color:#373737;color:var(--black,#373737);font-family:Open Sans;font-size:16px;font-style:normal;font-weight:400;line-height:120%;letter-spacing:.08px}._2vW7B ._3zGOU{padding:24px 40px}._2vW7B ._3zGOU p{padding-bottom:24px}._2vW7B ._22Hty{-ms-flex:1 1;flex:1 1;-ms-flex-direction:row;flex-direction:row;display:-ms-flexbox;display:flex;padding:12px 20px;padding-bottom:0}@media only screen and (max-width:599px){._2vW7B ._22Hty{-ms-flex-direction:column;flex-direction:column}}._2vW7B ._22Hty ._2nbig{-ms-flex:1 1;flex:1 1;padding:12px 20px}._2vW7B ._5P29{-ms-flex:1 1;flex:1 1;-ms-flex-direction:row;flex-direction:row;display:-ms-flexbox;display:flex;border-top:2px solid #ebebeb;padding:12px 40px;-ms-flex-pack:end;justify-content:flex-end;background:#ebebeb;margin-top:40px;border-radius:0 0 15px 15px}@media only screen and (max-width:599px){._2vW7B ._5P29{-ms-flex-direction:column;flex-direction:column}}._2vW7B ._5P29 ._3Mhrn{padding-left:12px}@media only screen and (max-width:599px){._2vW7B ._5P29 ._3Mhrn{padding-top:12px}}._3OVUM{display:-ms-flexbox;display:flex;padding-left:40px}._1vU1d{color:#8b1313;color:var(--error-text,#8b1313);-webkit-font-feature-settings:\"clig\" off,\"liga\" off;font-feature-settings:\"clig\" off,\"liga\" off;font-family:Open Sans;font-size:14px;font-style:normal;font-weight:400;line-height:140%}", ""]);

// exports
exports.locals = {
	"batch-add-forms-modal": "_2vW7B",
	"paragraph": "dMuNd",
	"content": "_3zGOU",
	"row": "_22Hty",
	"item": "_2nbig",
	"footer": "_5P29",
	"button-content": "_3Mhrn",
	"errors": "_3OVUM",
	"error-select": "_1vU1d",
	"spin": "_390CW"
};