export default [
  {
    id: 1,
    label: 'Yes',
    value: 'yes',
  },
  {
    id: 2,
    label: 'No',
    value: 'no',
  },
  {
    id: 3,
    label: `I'm not sure`,
    value: 'not_sure',
  },
];
