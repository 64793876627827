import React from 'react';
import { compose } from 'recompose';
import moment from 'moment-timezone';
import propTypes from 'prop-types';

export const enhance = compose();

function ViaDate(props) {
  if (props.style === 'full') {
    return (
      <>
        {/* {moment(props.date).format('MMM D, YYYY')} */}
        {moment(props.date).isValid() ? moment(props.date).format('MMM D, YYYY') : 'Not Provided'}
      </>
    );
  }
  return (
    <>
      {props.date}
    </>
  );
}
ViaDate.propTypes = {
  date: propTypes.string.isRequired,
  style: propTypes.oneOf(['full']),
};

ViaDate.defaultProps = {
  style: 'full',
};

export default enhance(ViaDate);
