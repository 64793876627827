exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ":root{--dark:var(--theme_color_dark);--accent:var(--theme_color_accent);--ocean:var(--theme_color_light);--button-border:var(--theme_border_color)}._2Or7d{width:100%;height:100%;position:absolute;background-color:var(--theme_color_dark);background-color:var(--dark,#043544);text-align:center}._2Or7d:after{content:\"\";position:absolute;z-index:2;pointer-events:none;top:0;left:0;right:0;bottom:0;border:20px solid hsla(0,0%,100%,.3)}._2Or7d p{margin:0}._1l6Ee{position:absolute;right:0;top:0;padding:32px;padding:2rem}.o7gcV{font-size:14px;font-size:.875rem;color:#fff;font-family:AvenirNextBold,sans-serif}.gc8D6,.o7gcV{cursor:pointer}._1vPA9{color:#fff;font-size:92px;font-size:5.75rem;font-family:IntroBoldItalic,sans-serif;padding:160px 224px;padding:10rem 14rem;padding-bottom:0}.wmIyB{font-size:24px;font-size:1.5rem;margin:0;padding:44px 270px;padding:2.75rem 16.875rem}.EQmmc,.wmIyB{color:#fff;font-family:IntroBold,sans-serif}.EQmmc{font-size:18px;font-size:1.125rem;border:10px solid hsla(0,0%,100%,.8);background-color:transparent;text-align:center;padding:16px 64px;padding:1rem 4rem;cursor:pointer}#_2k5AX{display:block}#_8tZLd{display:none}#V1tK1{position:fixed;top:0;left:0;width:100%;height:100%;background:rgba(0,0,0,.6);z-index:1000}#_2Azs1{display:none}@media only screen and (max-width:768px){._1vPA9{padding:100px 10px 10px;font-size:20px}.wmIyB{padding:20px 0 10px;font-size:16px;width:80%;margin-left:40px!important}.EQmmc{width:76%;margin-bottom:10px;font-size:14px}}@media only screen and (min-device-width:600px) and (max-device-width:1024px) and (orientation:landscape){._1vPA9{padding:30px 10px 10px;font-size:20px}.wmIyB{padding:10px 0;margin-left:60px!important;margin-right:60px!important}.EQmmc,.wmIyB{font-size:14px}.EQmmc{width:76%;margin-bottom:10px}}", ""]);

// exports
exports.locals = {
	"wrapper": "_2Or7d",
	"closeWrapper": "_1l6Ee",
	"closeText": "o7gcV",
	"closeX": "gc8D6",
	"header": "_1vPA9",
	"description": "wmIyB",
	"button": "EQmmc",
	"modalDisplay": "_2k5AX",
	"modalDisplayNone": "_8tZLd",
	"backgroundShow": "V1tK1",
	"backgroundNone": "_2Azs1"
};