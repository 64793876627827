const getBaseLinks = programsAlias => [
  {
    id: 1,
    path: '/traveler/dashboard',
    label: 'Dashboard',
    name: 'dashboard',
  },
  {
    id: 2,
    path: '/traveler/messages',
    label: 'Messages',
    name: 'message',
  },
  {
    id: 3,
    path: '/traveler/my-programs',
    label: `My ${programsAlias}`,
    onlyForLegacy: true,
    name: 'my-programs',
  },
  {
    id: 4,
    path: '#',
    label: 'My Travel',
    forTravelPlans: true,
    submenu: true,
    menuList: [
      {
        id: 7,
        path: '/plans/my-travel',
        label: 'My Plans',
        forTravelPlans: true,
        name: 'my-travel',
      },
      {
        id: 8,
        path: '/traveler/safecheck',
        label: 'SafeCheck',
        forTravelPlans: true,
        name: 'safecheck',
      },
    ],
  },
  {
    id: 5,
    path: '/traveler/events',
    label: 'My Events',
    onlyForLegacy: true,
    name: 'my-events',
  },
  {
    id: 6,
    path: '/traveler/profile',
    label: 'My Profile',
    name: 'my-profile',
  },
];

export default function getSidebarLinks(forLegacy, showTravelPlans, programsAlias) {
  let sidebarLinks = [...getBaseLinks(programsAlias)];

  if (!forLegacy) {
    sidebarLinks = sidebarLinks.filter(link => !link.onlyForLegacy);
  }

  if (!showTravelPlans) {
    sidebarLinks = sidebarLinks.filter(link => !link.forTravelPlans);
  }

  return sidebarLinks;
}
