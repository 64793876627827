import React from 'react';
import { compose } from 'recompose';
import { useSelector } from 'react-redux';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import Headline from '../../../../../../components/library/headline';
import Paragraph from '../../../../../../components/library/paragraph';
import sOvDetails from './ovDetails.scss';

export const enhance = compose(withStyles(sOvDetails));

function OvDetails() {
  const selectedPlan = useSelector(state => state.plans.plan);
  const customAliases = useSelector(state => state.profile.customAliases);

  return (
    <div className={sOvDetails['ov-details']}>
      {!!selectedPlan &&
        !!selectedPlan.plan && (
          <>
            <div className={sOvDetails['ov-details-headline']}>
              <Headline style={{
                fontFamily:"IntroBoldItalic, sans-serif",
                fontSize:"32px",
                lineHeight:"40px",
                color:"#043544"
              }} tag="div" as="div">
                {selectedPlan.plan.attributes.name}
              </Headline>
            </div>
            <div className={sOvDetails['ov-details-description']}>
              <div
                dangerouslySetInnerHTML={{
                  __html: selectedPlan.plan.attributes.description,
                }}
              />
            </div>
            {selectedPlan.plan.attributes.program_range ? (
              <div className={sOvDetails['ov-details-program']}>
                <div className={sOvDetails.program}>
                  <Paragraph>Associated {customAliases.alias_program}</Paragraph>
                  {selectedPlan.plan.attributes.program_range ? (
                    <Headline tag="h2" as="h2">
                      {selectedPlan.plan.attributes.program_range.program_title}
                    </Headline>
                  ) : null}
                </div>
                <div
                  className={sOvDetails.program}
                  dangerouslySetInnerHTML={{
                    __html: selectedPlan.plan.attributes.program_range.program_highlight,
                  }}
                />
              </div>
            ) : null}
          </>
        )}
    </div>
  );
}

export default enhance(OvDetails);
