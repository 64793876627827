import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { PageHeader } from '../../../../../styledComponents/styles/PageHeader.styled';
import { Paragraph } from '../../../../../styledComponents/styles/Paragraph.styled';
import { ProtectedKeyIcon } from '../../../../../styledComponents/styles/IconCustom.styled';
import TextInput from '../../../../../styledComponents/styles/TextInput.styled';
import { AccentButtonMediumNunito } from '../../../../../styledComponents/styles/Buttons.styled';
import StyledIcon from '../../../../../styledComponents/styles/Icon.styled';

const AccessProtectedContainer = styled.div`
  margin-top: 60px;
  margin-left: 60px;
`;

const ButtonContainer = styled.div``;

const Button = styled(AccentButtonMediumNunito)`
  color: #fff;
`;

const ContentContainer = styled.div`
  margin-bottom: 15px;
  text-align: justify;
  width: 400px;
  display: inline-grid;
`;

const ImageContainer = styled.div``;

const Container = styled.div`
  margin-bottom: 30px;
`;

const BackContainer = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  color: #446372;
  text-decoration-line: underline;
  a {
    color: #446372;
    text-decoration-line: underline;
  }
`;

const Header = styled(PageHeader)`
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 120%;
  color: #373737;
  margin-top: 22px;
  margin-bottom: 15px;
`;

const MainContainer = styled.div`
  text-align: center;
`;

export default function AccessProtected(props) {
  const dispatch = useDispatch();
  const customAliases = useSelector(state => state.profile.customAliases);
  const [value, setValue] = useState('');
  const [error, setError] = useState(false);

  useEffect(
    () => {
      value.length > 0 && value !== props.accessCode ? setError(true) : setError(false);
    },
    [value],
  );

  return (
    <>
      <AccessProtectedContainer>
        <BackContainer>
          <NavLink
            aria-current="page"
            aria-label="Go back to Form Dashboard"
            to={props.visitor ? `/visitor-programs` : `/traveler/program-dashboard`}
          >
            <StyledIcon type={'ChevronBack'} size={'16px'} />
            Go back to all programs
          </NavLink>
        </BackContainer>

        <MainContainer>
          <ImageContainer>
            <ProtectedKeyIcon size={90} />
          </ImageContainer>

          <Header> Protected Page</Header>
          <Paragraph>
            Please enter the access code to view this {customAliases.alias_program.toLowerCase()}. Access code is
            case-sensitive.
          </Paragraph>
          <ContentContainer>
            <Container>
              <TextInput
                icon={'Sync'}
                id={'access-code-input'}
                label={`Access Code`}
                sensitive
                required
                placeholder={'Enter Access Code'}
                errorMsg={error ? 'Access Code is incorrect, try again' : null}
                onChange={e => {
                  setValue(e.target.value);
                }}
              />
            </Container>
          </ContentContainer>
          <ButtonContainer>
            <Button
              disabled={value.length === 0 || error}
              onClick={() => {
                props.setShowProgram();
              }}
            >
              Enter
            </Button>
          </ButtonContainer>
        </MainContainer>
      </AccessProtectedContainer>
    </>
  );
}
