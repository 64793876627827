import fetch from 'node-fetch';
import history from '../history';
import { keys } from '../config/keys';
import { addAlert, clearAlerts } from './alertActions';

import {
  FETCH_ADMIN_EVENTS,
  FETCH_ADMIN_EVENT_DATA,
  FETCH_ADMIN_EVENT_TRAVELERS,
  TOGGLE_SELECTED_ADMIN_EVENT,
  CANCEL_EVENT,
  DELETE_EVENT,
  DUPLICATE_EVENT,
  CREATE_EVENT,
  EDIT_EVENT,
  TOGGLE_EVENT_TRAVELER,
  BULK_INVITE_TRAVELERS,
  INVITE_SINGLE_TRAVELER,
  SAME_DAY_REGISTER,
  DOWNLOAD_EVENT_TRAVELERS,
  CLEAR_EVENT_STATUSES_AND_ALERTS,
  REMOVE_EVENT_TRAVELER_REQUEST,
  REMOVE_EVENT_TRAVELER_SUCCESS,
  REMOVE_EVENT_TRAVELER_FAILURE,
  REMOVE_EVENT_TRAVELER_CLEAR,
  TOGGLE_EVENT_TRAVELER_REQUEST,
  TOGGLE_EVENT_TRAVELER_SUCCESS,
  TOGGLE_EVENT_TRAVELER_FAILURE,
  TOGGLE_EVENT_TRAVELER_CLEAR,
  ACTIVATE_EVENT,
} from './types';
import { Cookies } from 'react-cookie';

export function fetchAdminEvents(id) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/events/${id}/events`, {
      headers: token,
    })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: FETCH_ADMIN_EVENTS,
          payload: data,
        }),
      );
  };
}

export function fetchAdminEventData(id) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/events/${id}`, { headers: token }).then(response => {
      if (response.status == 401) {
        response.json().then(data => {
          dispatch({
            type: 'ADD_ALERT',
            text: data.message,
            style: 'danger',
          });
          window.location.assign('/client/events');
        });
      } else {
        response.json().then(data => {
          dispatch({
            type: FETCH_ADMIN_EVENT_DATA,
            payload: data,
          });
        });
      }
    });
  };
}

export function fetchAdminEventTravelers(id, showAlert = false) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/events/${id}/get_event_travelers`, {
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: FETCH_ADMIN_EVENT_TRAVELERS,
          payload: data,
        });
        if (showAlert) {
          dispatch(addAlert('User Registered For Same Day.', 'success'));
        }
      });
  };
}

export function toggleSelectedAdminEvent(event) {
  return {
    type: TOGGLE_SELECTED_ADMIN_EVENT,
    event,
  };
}

export function cancelEvent(id) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/events/${id}`, {
      method: 'DELETE',
      headers: token,
    }).then(data =>
      dispatch({
        type: CANCEL_EVENT,
        code: data.status,
      }),
    );
  };
}

export function activateEvent(id) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/events/reactivate_event/${id}`, {
      method: 'PATCH',
      headers: token,
    }).then(data =>
      dispatch({
        type: ACTIVATE_EVENT,
        code: data.status,
      }),
    );
  };
}

export function deleteEvent(id, callback) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/events/${id}/delete_event`, {
      method: 'DELETE',
      headers: token,
    }).then(data => {
      if (callback && typeof callback === 'function') {
        callback(true, `Event deleted`);
      }
      dispatch({
        type: DELETE_EVENT,
        code: data.status,
      });
    });
  };
}

export function createEvent(data, callback = null) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/events`, {
      method: 'POST',
      headers: token,
      body: JSON.stringify(data),
    }).then(response => {
      if (response.status == 401) {
        response.json().then(data => {
          dispatch({
            type: 'ADD_ALERT',
            text: data.message,
            style: 'danger',
          });
          window.location.assign('/client/events');
        });
      } else if (response.status === 200) {
        response.json().then(responseData => {
          callback(true, 'Event created successfully', response.status, responseData?.id);

          dispatch({
            type: CREATE_EVENT,
            code: response.status,
            data: responseData,
            errors: response.errors,
          });
        });
      } else if (response.status === 422) {
        callback(false, 'Please make sure you have filled out all required fields', response.status, '');
      } else if (response.status === 400) {
        callback(
          false,
          'Something went wrong, please ensure end date must be on or before the start date',
          response.status,
          '',
        );
      } else {
        callback(false, 'Something went wrong', response.status, '');
      }
    });
  };
}

export function editEvent(id, data, callback = null) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/events/${id}`, {
      method: 'PATCH',
      headers: token,
      body: JSON.stringify(data),
    }).then(response => {
      if (response.status == 401 || response.status == 400 || response.status == 500) {
        response.json().then(data => {
          dispatch({
            type: 'ADD_ALERT',
            text: data.message,
            style: 'danger',
          });

          if (callback && typeof callback === 'function') {
            if (response?.errors?.ending[0]) {
              callback(false, 'The start date cannot be later than the end date.');
            }
          }
        });
      } else {
        if (callback && typeof callback === 'function') {
          callback(true, response.errors);
        }

        dispatch({
          type: EDIT_EVENT,
          code: response.status,
          errors: response.errors,
        });
      }
    });
  };
}

export function duplicateEvent(id) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/events/${id}/duplicate_event`, {
      method: 'POST',
      headers: token,
    })
      .then(res => res.json())
      .then(res => {
        dispatch({
          type: DUPLICATE_EVENT,
          eventId: res,
        });
      });
  };
}

export function toggleEventTraveler(id, travelerId, attended) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/events/${id}/update_event_traveler/${travelerId}`, {
      method: 'PUT',
      headers: token,
      body: JSON.stringify({ attended }),
    }).then(data =>
      dispatch({
        type: TOGGLE_EVENT_TRAVELER,
        eventId: data.id,
      }),
    );
  };
}

export function clearBulkInviteTravelers() {
  return function(dispatch) {
    dispatch({
      type: 'BULK_INVITE_TRAVELERS',
      bulkInviteAlert: null,
      bulkInviteNotice: null,
    });
  };
}

export function bulkInviteTravelers(id, userId, file, attended = null, method, forceReload = true) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  let backendMethod = method ? 'upload_attendees' : 'invite_event_travelers';
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/events/${id}/${backendMethod}`, {
      method: 'POST',
      headers: token,
      body: JSON.stringify({ user_id: userId, file, attended }),
    }).then(res => {
      if (res.status == 400) {
        res.json().then(data => {
          dispatch({
            type: 'BULK_INVITE_TRAVELERS',
            bulkInviteAlert: data.alert,
            bulkInviteNotice: null,
          });
        });
      } else if (res.status == 422) {
        res.json().then(data => {
          dispatch({
            type: BULK_INVITE_TRAVELERS,
            bulkInviteNotice: data.notice,
            bulkInviteAlert: null,
            code: res.status,
          });
          // dispatch({
          //   type: 'ADD_ALERT',
          //   text: data.notice,
          //   style: 'warning',
          // });
        });
      } else {
        res.json().then(data => {
          dispatch({
            type: BULK_INVITE_TRAVELERS,
            bulkInviteNotice: data.notice,
            bulkInviteAlert: null,
            code: res.status,
          });
          // dispatch({
          //   type: 'ADD_ALERT',
          //   text: data.notice,
          //   style: 'success',
          // });
        });
      }

      if (forceReload) {
        setTimeout(() => {
          dispatch({ type: CLEAR_EVENT_STATUSES_AND_ALERTS });
          dispatch(clearAlerts());
          dispatch(fetchAdminEventTravelers(id));
        }, 8000);
      }
    });
  };
}

export function inviteSingleTraveler(id, userId, user, forceReload = true) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/events/${id}/create_event_traveler`, {
      method: 'POST',
      headers: token,
      body: JSON.stringify({ user_id: userId, user }),
    }).then(response => {
      if (response.status == 400) {
        response.json().then(data => {
          dispatch({
            type: INVITE_SINGLE_TRAVELER,
            notice: null,
            error: data.message,
          });
          // dispatch({
          //   type: 'ADD_ALERT',
          //   text: `${data.message}`,
          //   style: 'danger',
          // });
        });
      } else {
        response.json().then(data => {
          if (data.type == 'error') {
            dispatch({
              type: INVITE_SINGLE_TRAVELER,
              code: response.status,
              notice: null,
              error: data.message,
            });
          } else {
            dispatch({
              type: INVITE_SINGLE_TRAVELER,
              code: response.status,
              notice: data.message,
              error: null,
            });
          }

          // dispatch({
          //   type: 'ADD_ALERT',
          //   text: `${data.message}`,
          //   style: 'success',
          // });
        });
      }
      if (forceReload) {
        setTimeout(() => {
          dispatch({ type: CLEAR_EVENT_STATUSES_AND_ALERTS });
          dispatch(clearAlerts());
          dispatch({
            type: INVITE_SINGLE_TRAVELER,
            code: null,
            notice: null,
            error: null,
          });
          dispatch(fetchAdminEventTravelers(id));
        }, 2000);
      }
    });
  };
}

export function sameDayRegister(id, userId, user) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/events/${id}/same_day_registration`, {
      method: 'POST',
      headers: token,
      body: JSON.stringify({ user_id: userId, user }),
    })
      .then(res => res.json())
      .then(res => {
        dispatch({
          type: SAME_DAY_REGISTER,
          code: res.status,
        });
      })
      .catch(error => console.error(error));
  };
}

export function sameDayRegisterVisitor(id, user, forceReload = true) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(
      `${keys.baseUri}/api/visitor/events/${id}/same_day_registration?user=${encodeURIComponent(JSON.stringify(user))}`,
      {
        method: 'POST',
        headers: token,
        body: JSON.stringify({ user }),
      },
    )
      .then(res => res.json())
      .then(res => {
        dispatch({
          type: SAME_DAY_REGISTER,
          code: res.status,
        });
        dispatch({
          type: 'ADD_ALERT',
          text: `${res.event_traveler.email} has been registered.`,
          style: 'success',
        });
        if (forceReload) {
          setTimeout(() => {
            location.reload();
          }, 8000);
        }
      })
      .catch(error =>
        dispatch({
          type: 'ADD_ALERT',
          text: `${error}`,
          style: 'danger',
        }),
      );
  };
}

export function downloadEventTravelers(id) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/events/${id}/registered_and_attended`, {
      headers: token,
    })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: DOWNLOAD_EVENT_TRAVELERS,
          payload: data.csv,
        }),
      );
  };
}

export const deleteEventTravelers = (travelersID, id, callback) => dispatch => {
  const cookies = new Cookies();
  const token = cookies.get('token');
  dispatch({ type: REMOVE_EVENT_TRAVELER_REQUEST });
  let url = `${keys.baseUri}/api/client/events/delete_event_travelers`;
  fetch(url, {
    method: 'DELETE',
    headers: token,
    body: JSON.stringify({ event_traveler_ids: travelersID, id: id }),
  })
    .then(res => {
      if (res.status === 200 || res.status === 201) {
        res.json().then(data => {
          if (callback && typeof callback === 'function') {
            callback(true, `Travelers deleted`);
          }

          dispatch({
            type: REMOVE_EVENT_TRAVELER_SUCCESS,
            payload: data,
          });
          setTimeout(() => {
            dispatch({
              type: REMOVE_EVENT_TRAVELER_CLEAR,
            });
            dispatch(fetchAdminEventTravelers(id));
          }, 2000);
        });
      } else {
        res.json().then(message => {
          if (callback && typeof callback === 'function') {
            callback(false, 'Something went wrong, please try again later');
          }

          dispatch({
            type: REMOVE_EVENT_TRAVELER_FAILURE,
            payload: message,
          });
        });
      }
    })
    .catch(error => {
      if (callback && typeof callback === 'function') {
        callback(false, 'Something went wrong, please try again later');
      }

      dispatch({
        type: REMOVE_EVENT_TRAVELER_FAILURE,
        payload: error.toString(),
      });
    });
};

export const updateEventTravelers = (value, id, callback) => dispatch => {
  const cookies = new Cookies();
  const token = cookies.get('token');
  dispatch({ type: TOGGLE_EVENT_TRAVELER_REQUEST });
  let url = `${keys.baseUri}/api/client/events/update_event_travelers`;
  fetch(url, {
    method: 'PATCH',
    headers: token,
    body: JSON.stringify(value),
  })
    .then(res => {
      if (res.status === 200 || res.status === 201) {
        res.json().then(data => {
          if (callback && typeof callback === 'function') {
            callback(true, `Travelers status updated`);
          }

          dispatch({
            type: TOGGLE_EVENT_TRAVELER_SUCCESS,
            payload: data,
          });
          setTimeout(() => {
            dispatch({
              type: TOGGLE_EVENT_TRAVELER_CLEAR,
            });
            dispatch(fetchAdminEventTravelers(id));
          }, 2000);
        });
      } else {
        res.json().then(message => {
          if (callback && typeof callback === 'function') {
            callback(false, 'Something went wrong, please try again later');
          }

          dispatch({
            type: TOGGLE_EVENT_TRAVELER_FAILURE,
            payload: message,
          });
        });
      }
    })
    .catch(error => {
      if (callback && typeof callback === 'function') {
        callback(false, 'Something went wrong, please try again later');
      }

      dispatch({
        type: TOGGLE_EVENT_TRAVELER_FAILURE,
        payload: error.toString(),
      });
    });
};
