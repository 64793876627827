import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import EventsListItem from './EventsListItem';

import eventType from '../../types/event';

const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 1.8475rem;
  margin: 0;
  padding: 0;
  list-style: none;
`;

export default function EventsList({ events, category }) {
  return (
    <List>
      {events.map(event => (
        <EventsListItem key={`event-${event.id}`} event={event} category={category} />
      ))}
    </List>
  );
}

EventsList.propTypes = {
  events: PropTypes.arrayOf(eventType).isRequired,
  category: PropTypes.oneOf(['upcoming', 'past', 'cancelled']).isRequired,
};
