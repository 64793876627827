export default [
  {
    id: 1,
    label: 'Male',
    value: 'male',
  },
  {
    id: 2,
    label: 'Female',
    value: 'female',
  },
  {
    id: 3,
    label: 'Other',
    value: 'other_sex',
  },
];
