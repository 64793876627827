// eslint-disable-next-line import/prefer-default-export
export const PAYMENT = {
  STATUS: {
    NOT_PAID: 'not_paid',
    PAID: 'paid',
    PAID_ADMIN_OVERRIDE: 'paid_admin_override',
    PARTIALLY_PAID: 'partially_paid',
    PARTIALLY_REFUNDED: 'partially_refunded',
    PENDING: 'pending',
    REFUNDED: 'refunded',
    WAIVED: 'waived',
    OTHER: 'other',
  },
};

export const FORMS = {
  DIVIDER_COLOR: {
    PRIMARY: 0,
    ACCENT: 1,
    GREY: 2,
  },
  DIVIDER_COLOR_VALUE: {
    PRIMARY: "",
    ACCENT: "accent",
    GREY: "grey",
  },
};

