import React from 'react';
import PropTypes from 'prop-types';
import { createTheme, MuiThemeProvider, Popover } from '@material-ui/core';
import { HeaderIconButton } from '../Header.styled';

export default function Mobile({props, children }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const id = open ? 'simple-popover' : undefined;

  const customTheme = createTheme({
    overrides: {
      MuiPopover: {
        paper: {
          border: 'none',
          borderRadius: 1,
        },
      },
    },
  });

  return (
    <MuiThemeProvider theme={customTheme}>
      <HeaderIconButton aria-describedby={id} onClick={handleClick} open={open} style={{ padding: '.5rem .6rem' }}>
        <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M16.0002 13.7493C15.5961 13.7491 15.1962 13.8309 14.8247 13.9896C14.4531 14.1483 14.1175 14.3807 13.8383 14.6727L6.90079 10.7694C7.03517 10.2648 7.03517 9.73376 6.90079 9.22912L13.8383 5.32584C14.3435 5.84959 15.0223 6.17119 15.7476 6.23037C16.4728 6.28956 17.1948 6.08228 17.7782 5.64737C18.3616 5.21245 18.7665 4.57974 18.9169 3.86777C19.0673 3.1558 18.9529 2.41342 18.5952 1.7797C18.2376 1.14599 17.6611 0.664421 16.9739 0.425222C16.2866 0.186023 15.5357 0.205604 14.8619 0.480295C14.188 0.754986 13.6375 1.26594 13.3133 1.91744C12.9891 2.56893 12.9136 3.31626 13.1009 4.01943L6.16344 7.92271C5.74904 7.49063 5.21485 7.19242 4.62957 7.06645C4.0443 6.94047 3.43471 6.99248 2.87924 7.2158C2.32377 7.43912 1.84782 7.82352 1.51262 8.31956C1.17741 8.81561 0.998291 9.40059 0.998291 9.99927C0.998291 10.598 1.17741 11.1829 1.51262 11.679C1.84782 12.175 2.32377 12.5594 2.87924 12.7827C3.43471 13.0061 4.0443 13.0581 4.62957 12.9321C5.21485 12.8061 5.74904 12.5079 6.16344 12.0758L13.1009 15.9791C12.9401 16.5847 12.9729 17.2254 13.1947 17.8114C13.4165 18.3974 13.8163 18.8992 14.3378 19.2464C14.8594 19.5937 15.4765 19.7689 16.1027 19.7475C16.7289 19.7262 17.3327 19.5093 17.8294 19.1273C18.326 18.7453 18.6906 18.2174 18.872 17.6176C19.0533 17.0179 19.0424 16.3764 18.8406 15.7832C18.6389 15.1901 18.2564 14.6749 17.747 14.3101C17.2376 13.9454 16.6267 13.7492 16.0002 13.7493Z"
            fill="#373737"
          />
        </svg>
      </HeaderIconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div style={{ position: 'relative', gap: '.5rem', display: 'flex', padding: '.25rem .5rem' }}>{children}</div>
      </Popover>
    </MuiThemeProvider>
  );
}

Mobile.propTypes = {
  children: PropTypes.node.isRequired,
};
