import React, {useState} from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { PropTypes } from 'prop-types';
import { ButtonMediumNunito, AccentButtonMediumNunito } from '../../../../../styledComponents/styles/Buttons.styled';
import GenericStyledModal from './GenericStyledModal.modal';
import { WarningIcon } from '../../../../../styledComponents/styles/IconCustom.styled';
import { CircularProgress } from '@material-ui/core';
import TextInput from '../../../../../styledComponents/styles/TextInput.styled';

const Container = styled.div``;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 25px;
`;

const IconWrapper = styled.div`
  align-self: center;
  padding-left: 34px;
`;

const ParagraphWrapper = styled.div`
  margin-left: 45px;
  margin-right: 37px;
`;

const Paragraph = styled.p`
  color: #373737;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 400;
`;

const ParagraphWarning = styled(Paragraph)`
  font-weight: 600;
`;

const Footer = styled.div`
  border-radius: 0px 0px 10px 10px;
  background: #ebebeb;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px;
  //   gap: 55px;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 50px;
  justify-content: center;
  margin-top: 20px;
  // background: #ebebeb;
  // display: flex;
  // height: 111px;
  // justify-content: center;
  // left: 0;
  // margin-top: 43px;
  // top: 273px;
  // width: 520px;
  // margin-bottom: -20px;
  // border-radius: 15px;
`;

const ButtonDiv = styled.div`
  margin-top: 30px;
  margin-right: 20px;
`;

const TextInputParentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const TextInputContainer = styled.div`
  width: 300px;
`;

export default function DeleteFormModal(props) {
  const [deleteInput, setDeleteInput] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = e => {
    e.preventDefault();
    setLoading(true);
    props.deleteAction(props.deleteModalData);
    setTimeout(()=>{  
      setLoading(false);
    },1000)
  };

  return (
    <GenericStyledModal
      header={`Delete ${props.status} ${props.submissionType}`}
      showing={props.showing}
      width='520px'
      paddingBottom= "0px"
      onClose={() => {
        props.onClose();
      }}
    >
      <Container>
        <Content>
          <IconWrapper>
            <WarningIcon size='100px' />
          </IconWrapper>
          <ParagraphWrapper>
            { props.status.toLowerCase()==="archived" ? 
            <>
              <Paragraph>{`You are about to delete an ${props?.status?.toLowerCase()} ${props.submissionType}.`}</Paragraph>
              <Paragraph>{`This action will permanently delete the ${props?.status?.toLowerCase()} form and all associated data.`}</Paragraph>
              <ParagraphWarning>{`Deleted data can not be recovered.`}</ParagraphWarning>
            </>
            : 
              <>
                <Paragraph>{`You are about to delete a ${props.status} ${props.submissionType}. This action cannot be undone.`}</Paragraph>
                <ParagraphWarning>{`Do you want to delete ${props.deleteModalData.name} and all its content?`}</ParagraphWarning>
              </>
            }
          </ParagraphWrapper>
        </Content>
        <Footer>
          <form onSubmit={handleSubmit}>
            <TextInputParentContainer>
              <TextInputContainer>
                <TextInput
                  id={'delete'}
                  name={'delete'}
                  label={`Type DELETE to delete 'ITEM' `}
                  placeholder={`Type DELETE to confirm`}
                  errorMsg={''}
                  value={deleteInput}
                  onChange={e => {
                    setDeleteInput(e.target.value);
                  }}
                />
              </TextInputContainer>
            </TextInputParentContainer>

            <ButtonContainer>
              <ButtonMediumNunito  onClick={() => {
                  props.onClose();
                }} type="button">
                Cancel
              </ButtonMediumNunito>
              <AccentButtonMediumNunito disabled={deleteInput !== 'DELETE' || loading} type="submit">
                Delete {loading && <CircularProgress size={15} color="inherit" />}
              </AccentButtonMediumNunito>
            </ButtonContainer>
          </form>
        </Footer>
      </Container>
    </GenericStyledModal>
  );
}

DeleteFormModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  showing: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  deleteModalData: PropTypes.object.isRequired,
  submissionType: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
};
