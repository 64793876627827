import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import ProfileFormGrid from '../../base/ProfileFormGrid.styled';
import ProfileTextInput from '../../base/inputs/ProfileTextInput';
import GridColumnPlaceholder from '../../base/GridColumnPlaceholder.styled';

export default function NameAndEmailForm({ profileForm, setProfileForm }) {
  const { profile, readonly } = useSelector(state => state.travelerProfile);

  useEffect(
    () => {
      setProfileForm({
        last_name: profile.last_name,
        first_name: profile.first_name,
      });
    },
    [profile],
  );

  return (
    <ProfileFormGrid>
      <ProfileTextInput
        id="first_name"
        label="First Name"
        placeholder="Enter first name"
        value={profileForm.first_name}
        synced={readonly.includes('first_name')}
        handleOnChange={event => {
          setProfileForm({ ...profileForm, first_name: event.target.value });
        }}
      />
      <ProfileTextInput
        id="last_name"
        label="Last Name"
        placeholder="Enter last name"
        value={profileForm.last_name}
        synced={readonly.includes('last_name')}
        handleOnChange={event => {
          setProfileForm({ ...profileForm, last_name: event.target.value });
        }}
      />
      <GridColumnPlaceholder />
      <ProfileTextInput id="email" label="Email" className="col-span-2" value={profile.email} locked />
    </ProfileFormGrid>
  );
}

NameAndEmailForm.propTypes = {
  profileForm: PropTypes.shape({
    last_name: PropTypes.string,
    first_name: PropTypes.string,
  }).isRequired,
  setProfileForm: PropTypes.func.isRequired,
};
