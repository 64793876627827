import styled from 'styled-components';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { markAmenitiesParsed } from '../../../../../actions/programBrochure';
import { breakpoints } from '../../../utils/breakpoints';
import { TabCard, TabHeading, TabCardPanel, TabContentContainer } from '../TabContent.styled';
import IncludedCard from './IncludedCard';
import amenityCategories from './AmenityCategories';

const IncludedCardBox = styled.div`
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  gap: 1.25rem;

  @media ${breakpoints.tablet} {
    gap: 2.25rem;
  }
`;

const IncludedCardContainer = styled.div`
  grid-column: span 12 / span 12;
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 1.25rem;
  place-items: center;

  @media ${breakpoints.tablet} {
    gap: 2.5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media ${breakpoints.desktop} {
    gap: 5rem;
    padding: 1rem 5rem;
  }
`;

export default function WhatsIncluded() {
  const {
    loading,
    amenities: { parsed, included, excluded },
  } = useSelector(state => state.programBrochure);
  const dispatch = useDispatch();

  useEffect(
    () => {
      if (!loading && !parsed) {
        {
          included.forEach(includedItem => {
            amenityCategories.forEach(category => {
              if (category.all.includes(includedItem)) {
                !category.included.includes(includedItem) ? category.included.push(includedItem) : null;
              }
            });
          });

          excluded.forEach(excludedItem => {
            amenityCategories.forEach(category => {
              if (category.all.includes(excludedItem)) {
                !category.excluded.includes(excludedItem) ? category.excluded.push(excludedItem) : null;
              }
            });
          });
          dispatch(markAmenitiesParsed());
        }
      }
    },
    [loading],
  );

  return (
    <TabContentContainer>
      <TabHeading level={2}>What&apos;s Included</TabHeading>
      <TabCard noTabCardMobile="true">
        <TabCardPanel className="full">
          <IncludedCardBox>
            <IncludedCardContainer>
              {amenityCategories.map((item, index) => (
                <Fragment key={`card-${index}`} tabIndex={0}>
                  {(item.included.length > 0 || item.excluded.length > 0) && (
                    <IncludedCard
                      title={item.title}
                      icon={item.icon}
                      included={item.included}
                      excluded={item.excluded}
                    />
                  )}
                </Fragment>
              ))}
            </IncludedCardContainer>
          </IncludedCardBox>
        </TabCardPanel>
      </TabCard>
    </TabContentContainer>
  );
}
