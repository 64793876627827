import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { isEmpty } from '../../../../helpers/replaceLodash';
import { Controller } from 'react-hook-form';
import QuestionRender from '../questions/QuestionRender';

const QuestionDiv = styled.div`
  align-items: center;
  display: flex;
  padding: 5px 10px;
  border: none;
`;

const RenderDiv = styled.div`
  padding-bottom: 8px;
  padding-top: 8px;
  width: 100%;
`;

const FormLayout = styled.div`
  max-width: 100%;
`;

const LayoutRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`;

const LayoutRowCol = styled.div`
  align-self: end;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const FormGridLayout = ({
  control,
  registerOptions,
  errors,
  uiLayout,
  setValue,
  formId,
  isReadonly,
  isAdmin = false,
  isVisitor = false,
  inFormBuilder = false,
}) => {
  const [ui, setUI] = useState([]);

  useEffect(() => {
    if (uiLayout) {
      setUI(uiLayout);
    }
  }, [uiLayout]);

  return (
    <FormLayout>
      {ui?.length > 0 &&
        ui.map((ele, index) => {
          if (ele?.q) {
            let validationRule = registerOptions[ele.q.question.question_id.toString()];

            return (
              <LayoutRow key={ele.id}>
                <LayoutRowCol>
                  <QuestionDiv allowEdit={false} key={ele.q.question.question_id}>
                    <RenderDiv>
                      <Controller
                        key={ele.q.question.question_id}
                        control={control}
                        name={ele.q.question.question_id.toString()}
                        defaultValue={!!ele.q.response && ele.q.response.value ? ele.q.response.value : ''}
                        rules={validationRule}
                        render={({ field: { ref, ...rest } }) =>
                          !isEmpty(ele.q.question) && (
                            <QuestionRender
                              cashNetCodes={ele.q.cash_net_codes}
                              question={ele.q.question}
                              defaultValue={!!ele.q.response ? ele.q.response : ''}
                              type="form"
                              inFormBuilder={inFormBuilder}
                              errors={errors}
                              setValue={setValue}
                              response={!!ele.q.response ? ele.q.response : {}}
                              childResponse={
                                !!ele.q?.question?.single_choice_question_logic?.child_question?.response
                                  ? ele.q.question.single_choice_question_logic.child_question.response
                                  : {}
                              }
                              formId={formId}
                              isReadonly={!!ele?.q?.question?.synced_field || isReadonly}
                              isSynced={!!ele?.q?.question?.synced_field}
                              isAdmin={isAdmin}
                              isVisitor={isVisitor}
                              control={control}
                              registerOptions={registerOptions}
                              {...rest}
                            />
                          )
                        }
                      />
                    </RenderDiv>
                  </QuestionDiv>
                </LayoutRowCol>
              </LayoutRow>
            );
          }
        })}
    </FormLayout>
  );
};
FormGridLayout.displayName = 'FormGridLayout';

export default FormGridLayout;
