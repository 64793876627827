import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  toggleProgramPublish,
  contactProgramContact,
  clearIsContacted,
} from '../../../../actions/programBrochureActions';
import { addAlert } from '../../../../actions/alertActions';
import PhotoIcon from './photoIcon';
import moment from 'moment-timezone';
import Dropzone from 'react-dropzone';
import { withCookies } from 'react-cookie';
import ProgramRequestModal from '../../../../components/ProgramRequestModal/ProgramRequestModal';
import { authorizePrograms, deauthorizePrograms } from '../../../../actions/programDashboardActions';
import Ability from '../../../../hoc/Ability/Ability';
import s from './carousel.scss';
import _isEmpty from 'lodash/isEmpty';
import _sortedUniq from 'lodash/sortedUniq';
import toastMessageStyles from '../../../../sites/viaGlobal/components/toastMessage/toastMessage.scss';
import { toast } from 'react-toastify';

class Carousel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      images: [
        'https://images.unsplash.com/photo-1515327866270-5801e8c8357f?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=041821293c63f918f46ebbfd1dd4a5fb&auto=format&fit=crop&w=1567&q=80',
        'https://images.unsplash.com/photo-1507442139473-042ef20b25c6?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=afc049bb20478b1cc700d908ce3121a9&auto=format&fit=crop&w=2767&q=80',
        'https://images.unsplash.com/photo-1501982368380-01ae00c26ee2?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=6ddee5060d0ff46f2c5a04e630cd12ce&auto=format&fit=crop&w=2018&q=80',
        'https://images.unsplash.com/photo-1506604900144-7360175909e2?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=d437158dff03180ed32cc39401b3a894&auto=format&fit=crop&w=1955&q=80',
        'https://images.unsplash.com/photo-1518717202715-9fa9d099f58a?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=fdb044577537ea70ef23d08f2d55a3e8&auto=format&fit=crop&w=2001&q=80',
      ],
      status: this.props.programStatus,
      new: {
        imageAttachment: [],
      },
      requestShow: false,
      authorizedButton: !this.props.authorized && !this.props.internal_program,
      deAuthorizeButton: this.props.authorized,
      skipMessage: this.props.skipMessage || false,
    };

    this.moveLeft = this.moveLeft.bind(this);
    this.moveRight = this.moveRight.bind(this);
    this.renderButtons = this.renderButtons.bind(this);
    this.togglePublish = this.togglePublish.bind(this);

    this.onFilesChange = this.onFilesChange.bind(this);
    this.onFilesError = this.onFilesError.bind(this);
    this.requestShowParent = this.requestShowParent.bind(this);
    this.requestHideParent = this.requestHideParent.bind(this);
    this.requestRedirectLogin = this.requestRedirectLogin.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isContacted !== null && !this.state.skipMessage) {
      nextProps.isContacted
        ? this.props.addAlert(`Message has been sent to ${this.props.customAliases.alias_program} Contact`, 'success')
        : this.props.addAlert('Something went wrong!', 'danger');
      this.props.clearIsContacted(); // isContacted needs to be clear out!
      window.scrollTo(0, 0);
    }
  }

  requestHideParent() {
    this.setState({ requestShow: false });
  }

  requestShowParent() {
    this.setState({ requestShow: true });
  }

  requestRedirectLogin() {
    window.location.href = `/traveler/sign_in?jmp=${window.location.href.replace('visitor', 'traveler')}`;
  }

  moveLeft() {
    const { images } = this.state;
    const image = images.splice(-1, 1)[0];

    images.splice(0, 0, image);

    this.setState({
      images,
    });
  }

  moveRight() {
    const { images } = this.state;
    const image = images.splice(0, 1)[0];

    images.splice(-1, 0, image);

    this.setState({
      images,
    });
  }

  togglePublish() {
    this.props.toggleProgramPublish(this.props.id);
    this.setState({
      status: this.state.status == 'published' ? 'draft' : 'published',
    });
  }

  onFilesChange(files) {
    this.setState({ new: { imageAttachment: files } });
  }

  onFilesError(error) {
    console.error(`error code ${error.code}: ${error.message}`);
  }

  handleAuthorizeProgram(id) {
    const { cookies } = this.props;
    const userId = cookies.get('user_id');

    this.props.authorizePrograms(userId, [id]);
    this.setState({ authorizedButton: false, deAuthorizeButton: true });
  }

  isSameOrAfterCurrentDate = date => {
    const nowDate = moment().format('MMMM D, YYYY h:mm a');
    const dateToCheck = moment(new Date(date)).format('MMMM D, YYYY h:mm a');
    return moment(dateToCheck).isSameOrAfter(nowDate);
  };

  isBeforeCurrentDate = date => {
    const nowDate = moment().format('MMMM D, YYYY h:mm a');
    const dateToCheck = moment(new Date(date)).format('MMMM D, YYYY h:mm a');
    return moment(dateToCheck).isSameOrBefore(nowDate);
  };

  handleDeauthorizeProgram(id) {
    const { cookies } = this.props;
    const userId = cookies.get('user_id');

    this.props.deauthorizePrograms(userId, [id], (status, message) => {
      if (status) {
        this.setState({ authorizedButton: true, deAuthorizeButton: false });
      } else {
        toast(message, {
          type: 'error',
          className: toastMessageStyles['failureContainer'],
          toastClassName: toastMessageStyles['wrapper'],
          bodyClassName: toastMessageStyles['failureBody'],
          progressClassName: toastMessageStyles['progress-bar'],
        });
      }
    });
  }

  renderUnAuthorizedButton = id => {
    return (
      <div className={s.buttonWrapper}>
        <button className={s.button} onClick={() => this.handleDeauthorizeProgram(id)}>
          Unauthorize
        </button>
      </div>
    );
  };

  renderAuthorizedButton = id => {
    return (
      <div className={s.buttonWrapper}>
        <button className={s.button} onClick={() => this.handleAuthorizeProgram(id)}>
          Authorize
        </button>
      </div>
    );
  };

  requestToApplyButton = () => (
    <div className={s.buttonWrapper}>
      <button className={`${s.button} ${s.buttonRed}`} onClick={this.requestShowParent}>
        Request To Apply
      </button>
    </div>
  );

  applyButton = () => (
    <div className={s.buttonWrapper}>
      <button onClick={this.props.openEligibility} className={`${s.button} ${s.buttonRed}`}>
        Apply Now
      </button>
    </div>
  );

  filteredRanges = () =>
    this.props.ranges.filter(current => {
      let range = current.range;

      if (
        (this.isBeforeCurrentDate(range.start_date) && this.isBeforeCurrentDate(range.end_date)) ||
        range.status === 'cancelled'
      ) {
        return false;
      } else {
        return true;
      }
    });

  termRequestToApply = () => {
    let totalTermCount = this.filteredRanges().length;
    let totalTermsBeforeDeadline;

    if (this.props.authorized) {
      let application = this.filteredRanges().map(range => (range.application === null ? true : false));

      totalTermsBeforeDeadline = this.filteredRanges().map(range => {
        return range.range.deadline == null || this.isSameOrAfterCurrentDate(range.range.deadline);
      }).length;

      return _sortedUniq(application).includes(false) === false || totalTermCount == 0 || totalTermsBeforeDeadline == 0;
    } else {
      let totalTermsWithApplications = this.filteredRanges().filter(range => range.application != null).length;

      totalTermsBeforeDeadline = this.filteredRanges().filter(
        range => range.range.deadline == null || this.isSameOrAfterCurrentDate(range.range.deadline),
      ).length;

      return totalTermCount == 0 || totalTermsBeforeDeadline == 0 || totalTermsWithApplications == 0;
    }
  };

  renderButtons = (role, status, id, alias_program) => {
    if (role == 'Admin') {
      if (status == 'view') {
        if (this.props.programStatus == 'inactive') {
          return <div />;
        }
        return (
          <div className={s.buttonWrapper}>
            <Ability module="program_settings" action="create_slash_edit_slash_duplicate">
              <a href={`/client/programs/${id}/edit`} className={s.button}>
                Edit {alias_program}
              </a>
              <div disable="true">
                <a href="#" className={s.button + ' ' + s.disabled}>
                  Edit {alias_program}
                </a>
              </div>
            </Ability>
          </div>
        );
      } else if (status == 'new' || status === 'edit') {
        return (
          <div className={s.buttonWrapper}>
            <button
              onClick={() => this.props.saveProgram('setting')}
              className={s.button}
              disabled={this.props.disableAfterClick}
            >
              Save & Settings
            </button>
          </div>
        );
      }
    } else if (this.props.role == 'Visitor') {
      return (
        <div className={s.buttonWrapper}>
          <button className={`${s.button} ${s.buttonRed}`} onClick={this.requestRedirectLogin}>
            Request to Apply
          </button>
        </div>
      );
    } else {
      let futureTerms = [];

      if (this.termRequestToApply()) {
        return this.requestToApplyButton();
      } else {
        if (this.props.authorized) {
          futureTerms = this.handleAuthorizedProgramLogic();
        } else {
          futureTerms = this.handleInternalProgramLogic();
        }

        if (futureTerms.length > 0) {
          return this.applyButton();
        }

        return this.requestToApplyButton();
      }
    }
  };

  handleAuthorizedProgramLogic() {
    const futureTerms = this.filteredRanges().filter(current => {
      if (
        current.application !== null &&
        this.isSameOrAfterCurrentDate(current.range.start_date) &&
        this.isSameOrAfterCurrentDate(current.range.deadline)
      ) {
        return true;
      } else if (
        current.application !== null &&
        current.range.deadline == null &&
        this.isSameOrAfterCurrentDate(current.range.start_date) &&
        this.isSameOrAfterCurrentDate(current.range.end_date)
      ) {
        return true;
      }
    });
    return futureTerms;
  }

  handleInternalProgramLogic() {
    const futureTerms = this.filteredRanges().filter(current => {
      if (
        current.application &&
        this.isSameOrAfterCurrentDate(current.range.start_date) &&
        this.isSameOrAfterCurrentDate(current.range.deadline)
      ) {
        return true;
      } else if (
        current.application &&
        current.range.deadline == null &&
        this.isSameOrAfterCurrentDate(current.range.start_date) &&
        this.isSameOrAfterCurrentDate(current.range.end_date)
      ) {
        return true;
      }
    });
    return futureTerms;
  }

  render() {
    const { alias_program } = this.props.customAliases;
    let accent =
      this.props.customBranding && this.props.customBranding.themes
        ? this.props.customBranding.themes.theme_color_accent
        : '#dd2160';
    if (this.props.background) {
      if (this.props.role == 'Admin') {
        if (this.props.status == 'view') {
          const backgroundUrl = this.props.background;
          return (
            <div className={`${s.wrapper} row no-gutters`}>
              <div className="col-sm-12">
                <div
                  className={s.carouselImage}
                  style={{
                    backgroundImage: `url(${backgroundUrl})`,
                  }}
                />
              </div>

              {this.props.internal_program &&
                this.renderButtons(this.props.role, this.props.status, this.props.id, alias_program)}

              {this.state.deAuthorizeButton && this.renderUnAuthorizedButton(this.props.id)}

              {this.state.authorizedButton && this.renderAuthorizedButton(this.props.id)}
            </div>
          );
        } else if (this.props.status == 'edit') {
          const backgroundUrl = this.props.background;
          const background =
            this.props.imageAttachment.length > 0
              ? this.props.imageAttachment.map(file => (file.preview !== undefined ? file.preview : null))
              : `${backgroundUrl}`;
          return (
            <div className={`${s.wrapper} row no-gutters`}>
              <div className="col-sm-12">
                <Dropzone onDrop={this.props.onFilesChange} className={s.carouselDropzone}>
                  <div
                    className={`${s.carouselImage} ${s.carousalResponsive}`}
                    style={{
                      backgroundImage: background ? `url(${background})` : null,
                      backgroundColor: `#D7D7D7`,
                      border: `#E3E3E3 solid 20px`,
                    }}
                  >
                    <div className={s.addPhotoIcons}>
                      <PhotoIcon color={accent} />
                      <br />
                      <br />
                      <span
                        className={s.addPhotoText}
                        style={{
                          color: background ? `white` : `black`,
                        }}
                      >
                        + ADD PHOTO
                      </span>
                    </div>
                  </div>
                </Dropzone>
              </div>
              {this.renderButtons(this.props.role, this.props.status, this.props.id)}
            </div>
          );
        } else if (this.props.status == 'new') {
          const background =
            this.props.imageAttachment !== null
              ? this.props.imageAttachment.map(file => (file.preview !== undefined ? file.preview : null))
              : '';
          return (
            <div className={`${s.wrapper} row no-gutters`}>
              <div className="col-sm-12">
                <div
                  className={s.carouselImage}
                  style={{
                    backgroundImage: this.props.imageAttachment.length > 0 ? `url(${background})` : null,
                    backgroundColor: `#D7D7D7`,
                    border: `#E3E3E3 solid 20px`,
                    cursor: `not-allowed`,
                  }}
                >
                  <div className={s.addPhotoIcons}>
                    <PhotoIcon color={accent} />
                    <br />
                    <br />
                    <span
                      className={s.addPhotoText}
                      cursor="not-allowed"
                      style={{
                        color: this.props.imageAttachment.length > 0 ? `white` : `black`,
                      }}
                    >
                      + ADD PHOTO AFTER SAVING
                    </span>
                  </div>
                </div>
              </div>
              {this.renderButtons(this.props.role, this.props.status)}
            </div>
          );
        }
      } else {
        if (!_isEmpty(this.props.background)) {
          const backgroundUrl = this.props.background;
          return (
            <div className={`${s.wrapper} row no-gutters`}>
              {this.state.requestShow && (
                <ProgramRequestModal requestHideParent={this.requestHideParent} programId={this.props.id} />
              )}
              <div className="col-sm-12">
                <div
                  className={s.carouselImage}
                  style={{
                    backgroundImage: `url(${backgroundUrl})`,
                  }}
                />
              </div>
              {this.renderButtons(this.props.role, this.props.status, this.props.id)}
            </div>
          );
        } else {
          return (
            <div className={`${s.noBackgroundWrapper} row no-gutters`}>
              {this.state.requestShow && (
                <ProgramRequestModal requestHideParent={this.requestHideParent} programId={this.props.id} />
              )}
              {this.renderButtons(this.props.role, this.props.status, this.props.id)}
            </div>
          );
        }
      }
    } else {
      return <div />;
    }
  }
}
const mapStateToProps = state => ({
  isContacted: state.program.isContacted,
  customBranding: state.profile.customBranding || {},
  authorizeCode: state.programs.authorizeCode,
  deauthorizeCode: state.programs.deauthorizeCode,
  customAliases: state.profile.customAliases || {},
});

export default compose(
  withCookies,
  withStyles(s),
  connect(mapStateToProps, {
    toggleProgramPublish,
    contactProgramContact,
    addAlert,
    clearIsContacted,
    authorizePrograms,
    deauthorizePrograms,
  }),
)(Carousel);
