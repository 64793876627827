exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1eAzr{font-family:Nunito;font-size:21px;font-weight:600;line-height:25.2px;letter-spacing:.0015em;margin-bottom:10px}", ""]);

// exports
exports.locals = {
	"label": "_1eAzr"
};