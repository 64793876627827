import React, { useState } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { compose } from 'recompose';
import propTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Headline from '../../../../components/library/headline';
import Button from '../../../../components/library/button';
import Paragraph from '../../../../components/library/paragraph';
import sEditDetailSuccessModal from './groupAddDetailSuccessModal.scss';

export const enhance = compose(
  withStyles(sEditDetailSuccessModal),
  withRouter,
);

function GroupAddDetailSuccessModal(props) {
  return (
    <>
      <div
        className={sEditDetailSuccessModal['group-add-detail-success-modal']}
      >
        <div
          className={
            sEditDetailSuccessModal['edit-detail-success-modal-content']
          }
        >
          <Headline tag="h1" as="h1" centered italic>
            Success!
          </Headline>
        </div>
        <div
          className={
            sEditDetailSuccessModal['edit-detail-success-modal-content']
          }
        >
          <Headline tag="h2" as="h3" centered>
            A DETAIL WAS ADDED TO THE GROUP ITINERARY
          </Headline>
        </div>
        <div
          className={
            sEditDetailSuccessModal['edit-detail-success-modal-content']
          }
        >
          <Paragraph size="large">
            Travelers going on this group plan have been notified that this
            group detail is available to add to their itinerary. If no Travelers
            are added to this Group Plan, you can add Travelers at any time.
          </Paragraph>
        </div>

        <div className={sEditDetailSuccessModal['button-row']}>
          <div className={sEditDetailSuccessModal['button-content']}>
            <Button
              display="primary"
              kind="solid"
              size="medium"
              onClick={() => {
                props.onClose(false);
              }}
            >
              View Detail In Group Itinerary
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

GroupAddDetailSuccessModal.propTypes = {
  onClose: propTypes.func,
  planId: propTypes.string.isRequired,
};

export default enhance(GroupAddDetailSuccessModal);
