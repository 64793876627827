import React from 'react';
import ReactTelephoneInput from 'react-telephone-input';
import styles from './TextInputStyles.scss';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import Flags from '../pages/TravelerProfile/Profile/images/flags.png';

class TelInput extends React.Component {
  handleChange = (telNumber, selectedCountry) => {
    this.props.handleChange(
      this.props.countryCodeField,
      this.props.phoneField,
      telNumber,
      selectedCountry,
    );
  };

  render() {
    return (
      <div className={styles.inputWrapper}>
        <label className={styles.telLabel}>{this.props.label}</label>
        <input
          type="hidden"
          name={this.props.countryCodeField}
          value={this.props.countryCodeField}
          onChange={this.props.onChange}
        />
        <input
          type="hidden"
          name={this.props.phoneField}
          value={this.props.phoneField}
          onChange={this.props.onChange}
        />
        <ReactTelephoneInput
          defaultCountry="us"
          preferredCountries={['us', 'ca', 'nz', 'au', 'uk', 'mx']}
          flagsImagePath={Flags}
          initialValue={this.props.value}
          className={this.props.value && styles.fieldAdded}
          onChange={this.handleChange}
          disabled={this.props.disabled}
        />
      </div>
    );
  }
}

export default withStyles(styles)(TelInput);
