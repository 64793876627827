exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".MqnMZ{padding-top:64px;padding-top:4rem;padding-left:128px;padding-left:8rem;padding-right:128px;padding-right:8rem;width:100%;background-color:#fff}.Jatu3{margin:0;max-width:72%;min-width:72%}.Jatu3 div,.Jatu3 p{color:#043544;font-family:Open Sans;font-size:16px;font-size:1rem;line-height:24px}._1jsBe{font-family:AvenirNextBold,sans-serif;color:#dd2160}._3XkG8{font-family:IntroBold,sans-serif;font-size:28px;font-size:1.75rem;width:100%;border-bottom:3px solid #f5f5f5;padding-bottom:24px;padding-bottom:1.5rem;margin-bottom:14px;margin-bottom:.875rem;color:#043544}._1alAA{float:right;margin-right:85px;margin-top:6%}._1alAA img{width:25px}._3y0jC{display:inline-block;color:#043544}.REXJE{width:100%;min-height:100px;height:auto;font-size:16px;font-family:Avenir,sans-serif;padding-left:5px;padding-top:5px;outline:none;border:none}.vvkuA{opacity:.1}._1YojG{cursor:pointer}.vTDKN a{color:#dd2160;font-weight:700}.vTDKN>p:after{content:\"\\A\";white-space:pre}", ""]);

// exports
exports.locals = {
	"wrapper": "MqnMZ",
	"section": "Jatu3",
	"bold": "_1jsBe",
	"header": "_3XkG8",
	"trashButton": "_1alAA",
	"testEdit": "_3y0jC",
	"newEligibility": "REXJE",
	"blur": "vvkuA",
	"eligibilityOption": "_1YojG",
	"eligibility": "vTDKN"
};