exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@-webkit-keyframes zJwkw{to{-webkit-transform:rotate(1turn)}}@keyframes zJwkw{to{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}:root{--dark:var(--theme_color_dark);--accent:var(--theme_color_accent);--ocean:var(--theme_color_light);--button-border:var(--theme_border_color)}._2vUj8{background:var(--theme_color_dark);background:var(--dark,#013545);color:#fff;width:100%;padding:8px 0}@media only screen and (max-width:599px){._2vUj8{padding:8px 18px}}._2vUj8 .G6R39{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-wrap:wrap;flex-wrap:wrap;width:100%;-ms-flex-align:center;align-items:center}@media only screen and (max-width:599px){._2vUj8 .G6R39{-ms-flex-direction:column;flex-direction:column}}._2vUj8 ._3_IZe{-ms-flex-direction:column;flex-direction:column;-ms-flex:1 1;flex:1 1;padding-left:24px;-ms-flex-align:start;align-items:start}._2vUj8 ._2Dw48,._2vUj8 ._3_IZe{display:-ms-flexbox;display:flex}._2vUj8 ._2Dw48{-ms-flex-direction:column;flex-direction:column;-ms-flex:1 1;flex:1 1;padding-right:80px;-ms-flex-align:end;align-items:flex-end}", ""]);

// exports
exports.locals = {
	"page-footer": "_2vUj8",
	"footer-content": "G6R39",
	"column-left": "_3_IZe",
	"column-right": "_2Dw48",
	"spin": "zJwkw"
};