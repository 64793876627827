import React from 'react';

export default function MyTravelIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g clip-path="url(#clip0_5549_9163)">
        <path d="M23 10H24V6C24 4.346 22.654 3 21 3H3C1.346 3 0 4.346 0 6V18C0 19.654 1.346 21 3 21H21C22.654 21 24 19.654 24 18V14H23C21.897 14 21 13.103 21 12C21 10.897 21.897 10 23 10ZM6 19H4V17H6V19ZM6 15H4V13H6V15ZM6 11H4V9H6V11ZM6 7H4V5H6V7ZM18 13H15.5L13.589 15.421C13.394 15.778 13.02 16 12.614 16H11.501L12.668 13H10.651L9.105 11.167C8.808 10.647 9.183 10 9.782 10C9.989 10 10.187 10.082 10.333 10.228L11.443 11H12.684L11.5 8H12.596C13.003 8 13.377 8.222 13.571 8.579L15.5 11H16.75C17.552 11 18 11.448 18 12V13Z" />
      </g>
      <defs>
        <clipPath id="clip0_5549_9163">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
