import React from 'react';
import styled from 'styled-components';

import IntakeGoals from './IntakeGoals';
import IntakeHistory from './IntakeHistory';
import IntakeCertainty from './IntakeCertainty';
import IntakeQuestions from './IntakeQuestions';
import IntakeSignUpDate from './IntakeSignUpDate';
import IntakeFinancialAid from './IntakeFinancialAid';
import IntakeSignUpSource from './IntakeSignUpSource';
import IntakeFundingSources from './IntakeFundingSources';
import GridColumnPlaceholder from '../base/GridColumnPlaceholder.styled';

import { breakpoints } from '../../utils/breakpoints';

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  row-gap: 1.5rem;

  @media ${breakpoints.tablet} {
    column-gap: 1.375rem;
    row-gap: 2.375rem;
  }

  strong {
    font-weight: 600;
  }
`;

export default function IntakePreferences() {
  return (
    <Container tabIndex={0}>
      <IntakeSignUpDate />
      <IntakeSignUpSource />
      <GridColumnPlaceholder />
      <IntakeCertainty />
      <IntakeGoals />
      <IntakeHistory />
      <IntakeFinancialAid />
      <IntakeFundingSources />
      <IntakeQuestions />
    </Container>
  );
}
