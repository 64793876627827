import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';

import IntakeSection from './IntakeSection';

import fundingSourceOptions from '../../utils/options/fundingSourceOptions';

export default function IntakeFundingSources() {
  const intake = useSelector(state => state.intakeForm);

  const [otherOption, setOtherOption] = useState('');
  const [fundingSources, setFundingSources] = useState('');

  useEffect(
    () => {
      if (intake.financial_info) {
        const selectedFundingSources = [];

        for (const [key, value] of Object.entries(intake.financial_info)) {
          if (key === 'other_financial_aid_text') {
            setOtherOption(value || '');
          }

          if (value === true || value === 't') {
            selectedFundingSources.push(key);
          }
        }

        const sourceValues = selectedFundingSources.reduce((accumulator, source) => {
          const value = fundingSourceOptions.find(option => option.value === source);

          if (value) {
            accumulator.push(value.label);
          }

          return accumulator;
        }, []);

        if (sourceValues.length > 0) {
          setFundingSources(sourceValues.join('; '));
        }
      }
    },
    [intake.financial_info],
  );

  return (
    <IntakeSection heading="Funding Sources">
      <p>{fundingSources || 'None selected'}</p>
      {otherOption && (
        <p>
          <strong>Other:</strong> {otherOption}
        </p>
      )}
    </IntakeSection>
  );
}
