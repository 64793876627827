export default [
  {
    id: 1,
    label: 'Internal A-Z to Affiliate A-Z',
    value: 'internal_to_affiliate',
  },
  {
    id: 2,
    label: 'Affiliate A-Z to Internal A-Z',
    value: 'affiliate_to_internal',
  },
  {
    id: 3,
    label: 'Program Name A-Z',
    value: 'program_name_asc',
  },
  {
    id: 4,
    label: 'Program Name Z-A',
    value: 'program_name_desc',
  },
  {
    id: 5,
    label: 'Match % (Highest to Lowest)',
    value: 'match_percentage',
  },
];
