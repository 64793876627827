import React from 'react';

export default function WarningIcon({color="currentColor"}) {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
      <path
        d="M15.8759 13.5891L9.21853 1.22578C8.74665 0.349219 7.48962 0.349219 7.01735 1.22578L0.360324 13.5891C0.257868 13.7794 0.206501 13.993 0.211238 14.209C0.215974 14.4251 0.276652 14.6363 0.387348 14.8219C0.498045 15.0075 0.654977 15.1613 0.842828 15.2681C1.03068 15.375 1.24303 15.4313 1.45915 15.4316H14.7752C14.9915 15.4317 15.2041 15.3756 15.3922 15.2688C15.5803 15.1621 15.7375 15.0084 15.8484 14.8227C15.9594 14.637 16.0202 14.4258 16.025 14.2095C16.0298 13.9933 15.9785 13.7795 15.8759 13.5891ZM8.11814 13.5176C7.96362 13.5176 7.81257 13.4718 7.6841 13.3859C7.55562 13.3001 7.45549 13.1781 7.39636 13.0353C7.33722 12.8925 7.32175 12.7355 7.3519 12.5839C7.38204 12.4324 7.45645 12.2932 7.56571 12.1839C7.67497 12.0746 7.81417 12.0002 7.96572 11.9701C8.11727 11.9399 8.27435 11.9554 8.41711 12.0145C8.55986 12.0737 8.68188 12.1738 8.76772 12.3023C8.85357 12.4308 8.89939 12.5818 8.89939 12.7363C8.89939 12.8389 8.87918 12.9405 8.83992 13.0353C8.80066 13.1301 8.74311 13.2162 8.67056 13.2888C8.59802 13.3613 8.51189 13.4188 8.41711 13.4581C8.32232 13.4974 8.22073 13.5176 8.11814 13.5176ZM8.96657 5.66016L8.74236 10.4258C8.74236 10.5915 8.67651 10.7505 8.5593 10.8677C8.44209 10.9849 8.28312 11.0508 8.11736 11.0508C7.95159 11.0508 7.79262 10.9849 7.67541 10.8677C7.5582 10.7505 7.49236 10.5915 7.49236 10.4258L7.26814 5.66211C7.2631 5.54828 7.28103 5.4346 7.32086 5.32784C7.36069 5.22109 7.42161 5.12344 7.49998 5.04073C7.57835 4.95802 7.67258 4.89194 7.77703 4.84642C7.88149 4.8009 7.99404 4.77688 8.10798 4.77578H8.11618C8.2309 4.77572 8.34444 4.79891 8.44995 4.84393C8.55545 4.88896 8.65075 4.95489 8.73007 5.03776C8.8094 5.12063 8.87111 5.21871 8.91149 5.32608C8.95187 5.43346 8.97008 5.5479 8.96501 5.6625L8.96657 5.66016Z"
        fill={color}
      />
    </svg>
  );
}
