import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import MobileFilters from './filters/MobileFilters';
import FilterMenuIcon from '../icons/FilterMenuIcon';
import ProfileTextInput from '../base/inputs/ProfileTextInput';

import { breakpoints } from '../../utils/breakpoints';
import { buttonFocus } from '../../utils/shared/focusStyles';
import { setProgramsSearchTerm } from '../../actions/programDashboardActions';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  width: 100%;
  padding-left: 1.375rem;
  padding-right: 1.8275rem;

  .program-search {
    flex-grow: 1;
    width: 100%;
    margin-left: 0.4375rem;
  }

  @media ${breakpoints.desktop} {
    flex-direction: row;
    align-items: center;
    gap: 1.6875rem;
    width: 50%;
    padding: 0;

    .program-search {
      margin-left: 0;
    }
  }
`;

const HeadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media ${breakpoints.desktop} {
    flex-shrink: 0;
    width: auto;
  }
`;

const Heading = styled.div`
  margin-bottom: 0;
  font-family: 'Nunito', sans-serif;
  font-weight: 400;
  font-size: 1.5625rem;
  color: #373737;
  line-height: 120%;
`;

const FilterMenuButton = styled.button`
  padding: 0;
  background-color: transparent;
  border: none;
  color: var(--theme_color_dark);

  ${buttonFocus};

  @media ${breakpoints.desktop} {
    display: none;
  }
`;

export default function ProgramSearch() {
  const dispatch = useDispatch();
  const { customAliases } = useSelector(state => state.currentOrganization);

  const [searchTerm, setSearchTerm] = useState('');
  const [showMobileFilters, setShowMobileFilters] = useState(false);

  useEffect(() => {
    dispatch(setProgramsSearchTerm(''));
  }, []);

  const handleOnChange = event => {
    let val = event.target.value
    setSearchTerm(event.target.value);

    if (!val) {
      dispatch(setProgramsSearchTerm(''));
    }else{
      if(val.length >= 3){
        dispatch(setProgramsSearchTerm(val));
      }
    }
  };

  const handleSearch = event => {
    event.preventDefault();

    dispatch(setProgramsSearchTerm(searchTerm));
  };

  const handleOpen = () => {
    setShowMobileFilters(true);

    document.body.style.setProperty('overflow', 'hidden');
  };

  const handleClose = () => {
    setShowMobileFilters(false);

    document.body.style.setProperty('overflow', 'scroll');
  };

  return (
    <Container>
      {showMobileFilters && <MobileFilters show={showMobileFilters} handleClose={handleClose} />}
      <HeadingContainer>
        <Heading>{customAliases.alias_program} Search</Heading>
        <FilterMenuButton type="button" aria-label="Show Filter Menu" onClick={handleOpen}>
          <FilterMenuIcon />
        </FilterMenuButton>
      </HeadingContainer>
      <form onSubmit={handleSearch} className="program-search">
        <ProfileTextInput
          id="program_search"
          value={searchTerm}
          label={`Search ${customAliases.alias_programs}`}
          placeholder={`Search ${customAliases.alias_programs}`}
          hideLabel
          search
          handleOnChange={handleOnChange}
        />
      </form>
    </Container>
  );
}
