import fetch from 'node-fetch';
import { keys } from '../config/keys';
import _clone from 'lodash/clone';
import {
  FETCH_GRAPH_INFORMATION,
  FETCH_GRAPH_PROGRAM,
  FETCH_POPULAR_PROGRAMS,
  FETCH_RECENT_CONNECTS,
  FETCH_ANALYTICS_REPORT,
  FETCH_ORGANIZATION_INSIGHTS,
  FETCH_ORGANIZATION_INFORMATION,
  FETCH_TRAVELER_TRACKER,
  FETCH_TERM_DEPARTING,
  FETCH_UPCOMING_APPLICATION_DEADLINE,
  FETCH_PROGRAM_DISTRIBUTION,
  FETCH_APPLICATION_STARTED,
  FETCH_FAVORITE_PROGRAM,
  FETCH_PARTICIPATION_BARRIERS
} from './types';
import { Cookies } from 'react-cookie';

export function fetchGraphInformation() {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/reporting_connects/connects/graph_information`, { headers: token })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: FETCH_GRAPH_INFORMATION,
          payload: data,
        }),
      );
  };
}

export function fetchProgramGraphInformation() {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/reporting_connects/connects/program_authorized_graph`, { headers: token })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: FETCH_GRAPH_PROGRAM,
          payload: data,
        }),
      );
  };
}

export function fetchOrganizationInsights() {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/reporting_connects/connects/organization_insights`, { headers: token })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: FETCH_ORGANIZATION_INSIGHTS,
          payload: data,
        }),
      );
  };
}

export function fetchOrganizationInformation(id) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/reporting_connects/connects/organization_info?org_id=${id}`, { headers: token })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: FETCH_ORGANIZATION_INFORMATION,
          payload: data,
        }),
      );
  };
}

export function fetchPopularPrograms() {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/reporting_connects/connects/popular_programs`, { headers: token })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: FETCH_POPULAR_PROGRAMS,
          payload: data,
        }),
      );
  };
}

export function fetchRecentConnects() {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/reporting_connects/connects/recent_connects`, { headers: token })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: FETCH_RECENT_CONNECTS,
          payload: data,
        }),
      );
  };
}

export function fetchAnalyticsReports() {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/reporting_connects/connects/`, { headers: token })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: FETCH_ANALYTICS_REPORT,
          payload: data,
        }),
      );
  };
}

export function fetchTravelerTrackers() {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/dashboard/traveler_tracker`, { headers: token })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: FETCH_TRAVELER_TRACKER,
          payload: data,
        }),
      );
  };
}

export function fetchTermsDepartingData() {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/dashboard/terms_deporting_data`, { headers: token })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: FETCH_TERM_DEPARTING,
          payload: data,
        }),
      );
  };
}


export function fetchUpcomingApplicationDeadline() {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/dashboard/upcoming_application_deadlines_data`, { headers: token })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: FETCH_UPCOMING_APPLICATION_DEADLINE,
          payload: data,
        }),
      );
  };
}


export function fetchProgramDistribution() {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/dashboard/program_distribution_data`, { headers: token })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: FETCH_PROGRAM_DISTRIBUTION,
          payload: data,
        }),
      );
  };
}

export function fetchApplicationStarted(startDate, endDate) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  //const startDate = '2024-09-01';  
  //const endDate = '2024-09-30';   
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/dashboard/application_started_data?start_date=${startDate}&end_date=${endDate}`, { headers: token })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: FETCH_APPLICATION_STARTED,
          payload: data,
        }),
      );
  };
}

export function fetchFavoritePrograms(startDate, endDate) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  //const startDate = '2023-04-01';  
  //const endDate = '2024-09-30';   
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/dashboard/most_favorite_programs?start_date=${startDate}&end_date=${endDate}`, { headers: token })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: FETCH_FAVORITE_PROGRAM,
          payload: data,
        }),
      );
  };
}

export function fetchParticipationBarriers(startDate, endDate) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  //const startDate = '2023-04-01';  
  //const endDate = '2024-09-30';   
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/dashboard/top_participation_barriers?start_date=${startDate}&end_date=${endDate}`, { headers: token })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: FETCH_PARTICIPATION_BARRIERS,
          payload: data,
        }),
      );
  };
}