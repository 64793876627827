import PropTypes from 'prop-types';

// There are other properties returned from the API.
// This type includes the properties used by the UI.
const program = PropTypes.shape({
  program_favorite: PropTypes.bool,
  types: PropTypes.string.isRequired,
  locations: PropTypes.string.isRequired,
  program_id: PropTypes.number.isRequired,
  program_name: PropTypes.string.isRequired,
  background_xl: PropTypes.string.isRequired,
  program_match_percentage: PropTypes.number,
  program_terms: PropTypes.string.isRequired,
  background_large: PropTypes.string.isRequired,
  organization_name: PropTypes.string.isRequired,
  program_subject_areas: PropTypes.string.isRequired,
});

export default program;
