import React,{lazy, Suspense,useEffect,useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Modal from 'react-responsive-modal';
import moment from 'moment-timezone';
import { ThemeProvider } from 'styled-components';
import { compose } from 'recompose';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import modalCss from 'react-responsive-modal/styles.css';
import CloseIcon from '../../../../travelerProfile/components/icons/CloseIcon';
import sCreateEvent from './createEventModal.scss';
import userId from '../../../../../shared/helpers/userId';
import { EventIcon } from '../../../../../styledComponents/styles/IconCustom.styled';
import { themeViaForms } from '../../../../../styledComponents/settings/theme';
import {Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import _get from 'lodash/get';
import TextInput from '../../../../../styledComponents/styles/TextInput.styled';
import StyledIcon from '../../../../../styledComponents/styles/Icon.styled';
import { AccentButtonMediumNunito, ButtonMedium } from '../../../../../styledComponents/styles/Buttons.styled';
import { LoadingIndicatorIcon } from '../../../../../styledComponents/styles/IconCustom.styled';
import CompletedStep from '../../../../travelerForms/components/icons/stepper/CompletedStep';

import toastMessageStyles from '../../../../../sites/viaGlobal/components/toastMessage/toastMessage.scss';
import { toast } from 'react-toastify';
import InputSelect from '../../../../components/library/inputSelect';
import { createEvent } from '../../../../../actions/adminEventsActions';
import { 
        getDaysArrayByMonth, 
        getYearArrayOptions, 
        monthsArrayOptions, 
        getTimeHour,
        getTimeMinute,
        timeMeridiem 
      } from '../../../../../helpers/CommonHelper';
      
const EditTextAreaQuill = lazy(() => import('../../../../../styledComponents/styles/EditTextAreaQuill.styled'));

export const enhance = compose(withStyles(modalCss,sCreateEvent,toastMessageStyles));

function CreateEventModal({ open, onClose}) {
  const dispatch = useDispatch();
  const orgTimezone = useSelector(state => state.profile?.orgTimezone);
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({});
  const [selectedMonth, setSelectedMonth] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  //const [selectedDate, setSelectedDate] = useState({});
  //const [selectedYear, setSelectedYear] = useState({});
  //const [hourValue, setHourValue] = useState({ label: 12, value: 12 })
  //const [minuteValue, setMinuteValue] = useState({ label: "00", value: "00" })
  //const [meridiemValue, setMeridiemValue] = useState({ label: 'PM', value: 'PM', ariaLabel: 'PM' })
  const [dayOptions, setDayOptions] = useState([]);
  const yearOptions = getYearArrayOptions(5,5);  
  useEffect(() => {
    const options = getDaysArrayByMonth(selectedMonth.value)
    setDayOptions(options)
  }, [selectedMonth]);

  const step1Schema = yup.object().shape({
    event_name: yup.string().required('Event Name is Required'),
  });

  const step2Schema = yup.object().shape({
    event_location: yup.string().required('Event Location is Required'),
  });

  const validationSchema = step === 1 ? step1Schema : step2Schema;

  const currentDate = moment();
  const currentMonth = (currentDate.month() + 1).toString().padStart(2, '0'); 
  const currentDay = currentDate.format('D');
  const currentYear = currentDate.format('YYYY');
  
  const defaultValues = {
    event_name: '',
    event_description: '',
    event_location: '',
    event_start_month: monthsArrayOptions.find(
      item=>currentMonth===item.value
    ),
    event_start_date: getDaysArrayByMonth(currentMonth).find(
      item=>(currentDay).toString().padStart(2, '0')==item.value
    ),
    event_start_year: getYearArrayOptions().find(
      item=>currentYear==item.value
    ),
    
    event_start_hour: { label: 12, value: 12 },
    event_start_minute: { label: "00", value: "00" },
    event_start_meridiem: { label: 'PM', value: 'PM', ariaLabel: 'PM' }
  };

  const { control, handleSubmit, formState, getValues, register, setValue ,reset } = useForm({
    shouldUnregister: false,
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues,
  });

  const prevStep = () => {
    setStep(1);
  }

  const nextStep = () => {
    if (step === 1) {
      handleSubmit((data) => {
        setFormData((prev) => ({ ...prev, ...data }));
        setStep(2);
      })();
    }
  }

  const onSubmitHandler = (data) => {
    if (step === 2) {
      const combinedData = { ...formData, ...data };
      let hour24;
      if (combinedData.event_start_meridiem.value === "PM" && parseInt(combinedData.event_start_hour.value) !== 12) {
        hour24 = parseInt(combinedData.event_start_hour.value) + 12;
      } else if (combinedData.event_start_meridiem.value === "AM" && parseInt(combinedData.event_start_hour.value) === 12) {
        hour24 = 0;
      } else {
        hour24 = parseInt(combinedData.event_start_hour.value);
      }

      const startDate = moment.tz(
        `${combinedData.event_start_year.value}-${combinedData.event_start_month.value}-${combinedData.event_start_date.value} ${hour24}:${combinedData.event_start_minute.value}`,
        'YYYY-MM-DD HH:mm',
        orgTimezone
      );
      const formattedStartDate = startDate.format('MMMM D, YYYY hh:mm A');
      const eventFormData = {
        user_id: userId,
        event: {
          name: combinedData.event_name,
          location: combinedData.event_location,
          start: formattedStartDate,
          ending: "",
          timezone: orgTimezone,
          description: combinedData.event_description,
          background_photo: "",
        },
      }
      setIsLoading(true);
      
      dispatch(createEvent(eventFormData,(result, message, statusCode, id) => {       
        if (!result) {
          toast('Issue saving event', {
            type: 'error',
            className: toastMessageStyles['failureContainer'],
            toastClassName: toastMessageStyles['wrapper'],
            bodyClassName: toastMessageStyles['failureBody'],
            progressClassName: toastMessageStyles['progress-bar'],
          });
          setStep(2);
        } else {
          toast('Event created', {
            type: 'success',
            className: toastMessageStyles['successContainer'],
            toastClassName: toastMessageStyles['wrapper'],
            bodyClassName: toastMessageStyles['successBody'],
            progressClassName: toastMessageStyles['progress-bar'],
          });         
          setTimeout(()=>{
            //setIsLoading(false);
          },[10000]);

          setTimeout(() => {
            window.location.href = `/client/events/${id}/edit`;
          }, 1000);
        }
      },));
    }
  };


  return (
    <ThemeProvider theme={themeViaForms}>
      
    <Modal
      blockScroll={false}
      open={open}
      onClose={onClose}
      center
      closeOnEsc
      closeOnOverlayClick
      showCloseIcon={false}
      styles={{
        modal: {
          padding: '0px',
          borderRadius: '5px',
          width: '720px',
        },
      }}
    >
      
      <div className={sCreateEvent.header}>
        <h1 className={sCreateEvent.heading}>Create New Event</h1>
        <button className={sCreateEvent.closeButton} onClick={onClose}>
          <CloseIcon fillColor={`#9FABAE`} />
        </button>
      </div>
      {isLoading ? <>
        <div className={sCreateEvent.loadingContainer}>
            <div className={sCreateEvent.loadingIndicator}><LoadingIndicatorIcon size={"202.03"} height={"40.55"} /></div>
            <div className={sCreateEvent.loadingLabel}>Creating your event.</div>
        </div>
      </> : 
      <div className={sCreateEvent.container}>
        <div className={sCreateEvent.leftContainer}>
            <div className={sCreateEvent.stepContainer}>
              {step===1 &&
                <div className={`${sCreateEvent.stepLayout} ${sCreateEvent.stepActive}`}>1</div>
              }
              {step===2 && 
                <CompletedStep width="24" height="24" />
              }
              <div className={`${sCreateEvent.stepLabel}`}>Event Detail</div>
              <div className={`${sCreateEvent.stepSeparator}`}></div>
              <div className={`${sCreateEvent.stepLayout} ${step === 2 ? sCreateEvent.stepActive : ''}`}>2</div>
              <div className={`${sCreateEvent.stepLabel}`}>Date & Location</div>
            </div>
            {step===1 && 
              <div className={`${sCreateEvent.formHeading}`}>
                <div className={`${sCreateEvent.formHeadingLabel}`}>Enter your Event Name.</div>
                <div className={`${sCreateEvent.formHeadingLabel}`}>This can be changed later.</div>
              </div> 
            }
            {step===2 && 
              <div className={`${sCreateEvent.formHeading}`}>
                <div className={`${sCreateEvent.formHeadingLabel}`}>Enter your Event Location, Start Date and Time. This can be changed later.</div>   
              </div> 
            }
            {step===1 && 
              <form id="event-step1" onSubmit={handleSubmit(nextStep)}>
                <div className={`${sCreateEvent.formContainer}`}>
                  <div className={`${sCreateEvent.formFieldContainer}`}>
                    <Controller
                      control={control}
                      name={'event_name'}
                      render={({ field: { onChange,  value, name, ref} }) => (
                          <TextInput
                            errorMsg={_get(formState.errors.event_name, 'message') || ''}
                            id={"event_name"}
                            aria-label="Event Name"
                            name={"event_name"}
                            openSans
                            label={`Event Name`}
                            placeholder={'Event name'}
                            required
                            control={control}
                            errors={formState.errors}
                            isDirty={formState.isDirty}
                            value={value}
                            getValues={getValues}
                            register={register}
                            onChange={(value) => { 
                                setValue("event_name", value, {
                                  shouldValidate: true,
                                  shouldDirty: true,
                                  shouldTouch: true,
                                });
                                onChange(value);
                              }
                            }
                          />
                      )}
                    />
                  </div>
                  <div className={`${sCreateEvent.formFieldContainer}`}>
                    <Suspense fallback={<span />}>
                    <Controller
                      control={control}
                      name={'event_description'}
                      render={({ field: { onChange,  value, name, ref} }) => (
                        <EditTextAreaQuill
                          aria-label={"Event description"}
                          id="event_description"
                          label="Event description"
                          openSans
                          value={value}
                          placeholder={`Enter event description`}
                          onChange={(value)=> {
                            setValue("event_description",value)
                          }}
                        />
                      )}
                    />
                    </Suspense>
                  </div>
                  <div className={`${sCreateEvent.buttonContainer}`}>
                    <div className={`${sCreateEvent.nextButton}`}>
                      <AccentButtonMediumNunito type="button" onClick={nextStep}>
                      <span>Next</span> {<StyledIcon type='ChevronForward' color='#fff' />}
                      </AccentButtonMediumNunito>
                    </div>
                  </div>
                </div>
              </form>
            }
            {step===2 && 
              <form id="event-step1" onSubmit={handleSubmit(onSubmitHandler)}>
                <div className={`${sCreateEvent.formContainer}`}>
                  <div className={`${sCreateEvent.formFieldContainer}`}>
                    <Controller
                      control={control}
                      name={'event_location'}
                      render={({ field: { onChange, defaultValue, value, name, ref} }) => (
                          <TextInput
                            errorMsg={_get(formState.errors.event_location, 'message') || ''}
                            id={"event_location"}
                            aria-label="Event Location"
                            name={"event_location"}
                            openSans
                            label={`Event Location`}
                            placeholder={'Enter event location'}
                            required
                            control={control}
                            errors={formState.errors}
                            isDirty={formState.isDirty}
                            value={value}
                            getValues={getValues}
                            register={register}
                            onChange={(value) => { 
                                setValue("event_location", value, {
                                  shouldValidate: true,
                                  shouldDirty: true,
                                  shouldTouch: true,
                                });
                                onChange(value);
                              }
                            }
                          />
                      )}
                    />
                  </div>
                  <div className={`${sCreateEvent.formFieldContainer}`}>
                    <label className={sCreateEvent.requiredLabel}>Start Date <span>*</span></label>
                    <div className={`${sCreateEvent.startDateContainer}`}>
                      <div className={`${sCreateEvent.dropdownContainer}`}>
                        <Controller
                          control={control}
                          name="event_start_month"
                          render={({ field: { onChange, value, name, ref } }) => (
                            <InputSelect
                              id="event_start_month"
                              name="event_start_month"
                              aria-label="Month"
                              options={monthsArrayOptions}
                              placeholder={'Month'}
                              placeholderFontStyle={"italic"}
                              placeholderColor={"#666"}
                              placeholderFontfamily={"Open Sans"}
                              value={value}
                              onChange={e => {
                                setSelectedMonth(e);
                                setValue('event_start_month', e);
                                onChange(e);
                              }}
                            />
                          )}
                        />
                      </div>
                      <div className={`${sCreateEvent.dropdownContainer}`}>
                        <Controller
                          control={control}
                          name="event_start_date"
                          render={({ field: { onChange, value, name, ref } }) => (
                            <InputSelect
                              id="event_start_date"
                              name="event_start_date"
                              //labelText="Day"
                              aria-label="Day"
                              options={dayOptions}
                              placeholder={'Day'}
                              placeholderFontStyle={"italic"}
                              placeholderColor={"#666"}
                              placeholderFontfamily={"Open Sans"}
                              value={value}
                              onChange={e => {
                                //setSelectedDate(e);
                                setValue('event_start_date', e);
                                onChange(e);
                              }}
                            />
                          )}
                        />
                      </div>
                      <div className={`${sCreateEvent.dropdownContainer}`}>
                        <Controller
                          control={control}
                          name="event_start_year"
                          render={({ field: { onChange, value, name, ref } }) => (
                            <InputSelect
                              id="event_start_year"
                              name="event_start_year"
                              aria-label="Year"
                              helpText=""
                              options={yearOptions}
                              placeholder={'Year'}
                              placeholderFontStyle={"italic"}
                              placeholderColor={"#666"}
                              placeholderFontfamily={"Open Sans"}
                              value={value}
                              onChange={(e) => {
                                //setSelectedYear(e);
                                setValue('event_start_year', e);
                                onChange(e);
                              }}
                            />
                          )}
                          />
                      </div>
                    </div>
                  </div>

                  <div className={`${sCreateEvent.formFieldContainer}`}>
                    <label className={sCreateEvent.requiredLabel}>Start Time <span>*</span></label>
                    <div className={`${sCreateEvent.startDateContainer}`}>
                      <div className={`${sCreateEvent.dropdownContainer}`}>
                        <Controller
                          control={control}
                          name="event_start_hour"
                          render={({ field: { onChange, value, name, ref } }) => (
                            <InputSelect
                              options={getTimeHour()}
                              placeholder={'Hour'}
                              value={value}
                              onChange={(e) => {
                                setValue('event_start_hour', e);
                                onChange(e);
                              }}
                            />
                          )}
                        />
                      </div>
                      <div className={`${sCreateEvent.dropdownContainer}`}>
                        <Controller
                          control={control}
                          name="event_start_minute"
                          render={({ field: { onChange, value, name, ref } }) => (
                            <InputSelect
                              options={getTimeMinute()}
                              label="Minute"
                              placeholder={'Min'}
                              value={value}
                              onChange={(e) => {
                                setValue('event_start_minute', e);
                                onChange(e);
                              }}
                            />
                          )}
                        />
                      </div>
                      <div className={`${sCreateEvent.dropdownContainer}`}>
                        <Controller
                          control={control}
                          name="event_start_meridiem"
                          render={({ field: { onChange, value, name, ref } }) => (
                            <InputSelect
                              options={timeMeridiem}
                              placeholder={''}
                              value={value}
                              onChange={(e) => {
                                setValue('event_start_meridiem', e);
                                onChange(e);
                              }}
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className={sCreateEvent.timezoneLabel}>{orgTimezone}</div>
                  </div>   
                  <div className={`${sCreateEvent.buttonContainer}`}>
                    <div className={`${sCreateEvent.backButton}`}>
                      <ButtonMedium onClick={prevStep}><StyledIcon type='ChevronBack' color='#373737' /> <span>Back</span></ButtonMedium>     
                    </div>        
                    <div className={`${sCreateEvent.nextButton}`}>
                      <AccentButtonMediumNunito type="submit">
                        <span>Create</span> {<StyledIcon type='ChevronForward' color='#fff' />}
                      </AccentButtonMediumNunito>
                    </div>
                  </div>     
                </div>
              </form>
            }
        </div>
        <div className={sCreateEvent.rightContainer}>
          <div className={sCreateEvent.iconContainer}><EventIcon size="100" /></div>
          <div  className={sCreateEvent.rightHeading}>Events</div>
          <div className={sCreateEvent.rightContent}>
            Events can be used for scheduled meetings, info sessions, orientations, advising appointments, webinars, office hours, and more.<a className={sCreateEvent.learnMoreLink} href="https://help.viatrm.com/events" target="_blank">Learn More </a>
          </div>
        </div>
      </div>
      }
    </Modal>
    </ThemeProvider>
  );
}

export default enhance(CreateEventModal);
