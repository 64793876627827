exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._39RXZ{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;gap:40px;background:#ebebeb;padding:30px;border-bottom-left-radius:4.8px;border-bottom-left-radius:.3rem;border-bottom-right-radius:4.8px;border-bottom-right-radius:.3rem}._7Hiy8{-ms-flex-pack:center;justify-content:center}.KdyP1{display:-ms-flexbox;display:flex;gap:50px}._11iwd{-ms-flex-pack:right;justify-content:right}.JxNRV{-ms-flex-pack:justify;justify-content:space-between}._2Pkr4{display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center;margin-left:20px}._1zXgx{color:#8b1313;color:var(--error-text,#8b1313);font-family:Open Sans;font-size:16px;font-style:normal;font-weight:600;line-height:120%;letter-spacing:.96px;-webkit-text-decoration-line:underline;text-decoration-line:underline;-webkit-text-decoration-style:solid;text-decoration-style:solid;text-decoration-skip-ink:none;text-decoration-thickness:auto;text-underline-offset:auto;text-underline-position:from-font}", ""]);

// exports
exports.locals = {
	"footer": "_39RXZ",
	"buttonsCenter": "_7Hiy8",
	"buttonContainer": "KdyP1",
	"buttonsRight": "_11iwd",
	"buttonsSpaceBetween": "JxNRV",
	"deleteButtonContainer": "_2Pkr4",
	"deleteBtton": "_1zXgx"
};