import React from 'react';
import styles from './TextInputStyles.scss';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import InputError from '../../sites/travelerProfile/components/base/inputs/InputError';

const CustomTextInput = ({ name, label, value, onChange, message = "", ...rest }) => {

  return (
    <div className={styles.inputWrapper}>
      <input
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        type="text"
        autoComplete="nope"
        className={value != '' ? `${styles.fieldAdded}` : null}
        {...rest}
      />
      <label>{label}</label>
      {message && <InputError message={message}/>}
      

    </div>
  );
};

export default withStyles(styles)(CustomTextInput)