import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import MobileFilterContainer from '../MobileFilterContainer.styled';
import ProfileSelectInput from '../../../base/inputs/ProfileSelectInput';

import useProgramTypeOptions from '../../../../hooks/useProgramTypeOptions';

export default function MobileProgramTypeFilter({ value, setValue, handleOnChange }) {
  const { selectedFilters } = useSelector(state => state.programDashboard);
  const {
    customAliases: { alias_program: programAlias },
  } = useSelector(state => state.currentOrganization);

  const [allOptions] = useProgramTypeOptions();

  useEffect(() => {
    if (selectedFilters.programType.length > 0) {
      const mappedValues = selectedFilters.programType.map(programType => ({
        label: programType,
        value: programType,
      }));

      setValue(mappedValues);
    }
  }, []);

  return (
    <MobileFilterContainer>
      <ProfileSelectInput
        id="program_type"
        value={value}
        label={`${programAlias} Type`}
        placeholder={`Select ${programAlias} Type(s)`}
        options={allOptions}
        isMulti
        handleOnChange={handleOnChange}
      />
    </MobileFilterContainer>
  );
}

MobileProgramTypeFilter.propTypes = {
  setValue: PropTypes.func.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
};
