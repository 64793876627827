const QuestionBuildSubmit = (formQuestions, responses) => {
  let submitValues = {
    submission: {
      long_text_responses_attributes: [],
      short_text_responses_attributes: [],
      file_upload_responses_attributes: [],
      signature_responses_attributes: [],
      multiple_choice_response_groupings_attributes: [],
      single_choice_responses_attributes: [],
      dropdown_response_groupings_attributes: [],
      date_responses_attributes: [],
      star_rating_responses_attributes: [],
      payment_responses_attributes: [],
    },
  };
  let long_text_responses_attributes = [];
  let short_text_responses_attributes = [];
  let file_upload_responses_attributes = [];
  let signature_responses_attributes = [];
  let multiple_choice_response_groupings_attributes = [];
  let single_choice_responses_attributes = [];
  let dropdown_response_groupings_attributes = [];
  let date_responses_attributes = [];
  let star_rating_responses_attributes = [];
  let payment_responses_attributes = [];

  formQuestions.forEach(question => {
    const responseId = question.response && question.response.id ? question.response && question.response.id : null;

    switch (question.question.question_type_identifier.toLowerCase()) {
      case 'long_text':
        long_text_responses_attributes.push({
          id: responseId,
          question_id: question.questionId,
          value: responses[question.questionId],
        });
        break;
      case 'short_text':
        short_text_responses_attributes.push({
          id: responseId,
          question_id: question.questionId,
          value: responses[question.questionId],
        });

        break;
      case 'file_upload':
          file_upload_responses_attributes.push({
              id: responseId,
              question_id: question.questionId,
              value: responses[question.questionId],
            });
      break;    
      case 'signature':
        if (responseId && !responses[question.questionId]) {
          signature_responses_attributes.push({
            id: responseId,
            question_id: question.questionId,
            value: responses[question.questionId],
            _destroy: true,
          });
        } else {
          signature_responses_attributes.push({
            id: responseId,
            question_id: question.questionId,
            value: responses[question.questionId],
          });
        }
        break;

      case 'multiple_choice':
        multiple_choice_response_groupings_attributes.push({
          id: responseId,
          question_id: question.questionId,
          multiple_choice_responses_attributes: responses[question.questionId] ? responses[question.questionId] : [],
        });

        break;
      case 'single_choice':
        single_choice_responses_attributes.push({
          id: responseId,
          question_id: question.questionId,
          value: responses[question.questionId],
        });
        break;
      case 'dropdown':
        dropdown_response_groupings_attributes.push({
          id: responseId,
          question_id: question.questionId,
          dropdown_responses_attributes: responses[question.questionId] || [],
        });

        break;
      case 'date':
        date_responses_attributes.push({
          id: responseId,
          question_id: question.questionId,
          value: responses[question.questionId],
        });
        break;
      case 'star_rating':
        if (responseId && !responses[question.questionId]) {
          star_rating_responses_attributes.push({
            id: responseId,
            question_id: question.questionId,
            star_rating_question_option_id: responses[question.questionId],
            _destroy: true,
          });
        } else {
          if (responses[question.questionId]) {
            star_rating_responses_attributes.push({
              id: responseId,
              question_id: question.questionId,
              star_rating_question_option_id: responses[question.questionId],
            });
          }
        }
        break;

      case 'fee':
      case 'cash_net':
      case 'cash_net_with_code':
      case 'cybersource':
      case 'converge_pay':
      case 'quik_pay':
      case 'flywire':
      case 'payflow':
      case 'touch_net':
        payment_responses_attributes.push({
          id: responseId,
          question_id: question.questionId,
          payment_status_id: responses[question.questionId],
        });
        break;
      default:
        break;
    }
  });

  submitValues.submission.long_text_responses_attributes = long_text_responses_attributes;
  submitValues.submission.short_text_responses_attributes = short_text_responses_attributes;
  submitValues.submission.file_upload_responses_attributes = file_upload_responses_attributes;
  submitValues.submission.signature_responses_attributes = signature_responses_attributes.filter(
    item => item?._destroy || item?.value?.length > 0,
  );

  submitValues.submission.multiple_choice_response_groupings_attributes = multiple_choice_response_groupings_attributes;
  submitValues.submission.single_choice_responses_attributes = single_choice_responses_attributes;
  submitValues.submission.dropdown_response_groupings_attributes = dropdown_response_groupings_attributes;
  submitValues.submission.date_responses_attributes = date_responses_attributes.filter(item => item.value !== '');
  submitValues.submission.star_rating_responses_attributes = star_rating_responses_attributes.filter(
    item => item?.star_rating_question_option_id !== '',
  );
  submitValues.submission.payment_responses_attributes = payment_responses_attributes.filter(
    payment => payment?.payment_status_id !== '',
  );

  return submitValues;
};

export { QuestionBuildSubmit };
