import React, { useEffect, useState, Suspense, lazy, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { compose } from 'recompose';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import {
  AccentButtonMediumOpenSans,
  ButtonMediumOpenSans,
} from '../../../../../styledComponents/styles/Buttons.styled';
import { themeVia } from '../../../../../styledComponents/settings/theme';
import ToastMessage from '../../../../viaGlobal/components/toastMessage';
import ViaModal from '../../../../components/library/viaModal';
import InputSelect from '../../../../components/library/inputSelect';
import mail from '../../../../../shared/images/mail.png';
import errowarning from '../../../../../shared/images/errorwarning.png';
import closeIcon from '../../../../../shared/images/svgs/close.svg';
import ReactHtmlParser from 'react-html-parser';
import _trim from 'lodash/trim';
import { useForm, Controller } from 'react-hook-form';
import EditorDropdown from './../../../../components/v2/EditorDropdown';
import {
  fetchAutomationTokens,
  updateAutomationDetail,
  fetchAutomationButtonTargets,
  getAutomationMailers,
} from '../../../../../actions/automationActions';

import sStyles from './EditTextArea.scss';

const WysiwygEditor = lazy(() => import('../../../../components/library/wysiwygEditor'));

export const enhance = compose(withStyles(sStyles));

function MailerFormModal(props) {
  const dispatch = useDispatch();

  const subjectLength = 100;
  const buttonTextLength = 30;
  const bodyLength = 6000;

  const mainDiv = useRef(null);
  const previewDiv = useRef(null);

  const organization = useSelector(state => state.auth.organization);
  const customAliases = useSelector(state => state.profile.customAliases);
  const { loading: automationMailerLoading } = useSelector(state => state.automation.automationMailers);

  const updateAutomation = useSelector(state => state.automation.updateAutomation);
  const automationDetail = props.currentAutomationDetail;
  const automationMailerInfo = props.automationMailerInfo;
  const automationID = automationDetail?.id;
  const automationTokens = useSelector(state => state.automation.tokens);
  const automationButtonTargets = useSelector(state => state.automation.buttonTargets);
  const [showToastSuccess, setShowToastSuccess] = useState(false);
  const [showToastFailure, setShowToastFailure] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [triggerDisplayText, setTriggerDisplayText] = useState('');
  const [editorScreen, setEditScreenShow] = useState(true);
  const [reset, setReset] = useState(false);
  const [loadingBody, setLoadingBody] = useState(true);
  const [sendBodyToken, setSendBodyToken] = useState('');

  const { control, handleSubmit, setValue, getValues, watch } = useForm({
    values: {
      editorButtonText: '',
      editorButtonUrl: '',
      editorSubject: '',
      editorBody: '',
      editorId: '',
      triggerDisplayText: '',
    },
    defaultValues: {
      editorButtonText: '',
      editorButtonUrl: '',
      editorSubject: '',
      editorBody: '',
      editorId: '',
      triggerDisplayText: '',
    },
  });

  useEffect(() => {
    if (props?.automationMailerInfo) {
      const type =
        props?.automationMailerInfo?.type ||
        automationDetail?.attributes?.automation_type?.identifier ||
        props?.type ||
        '';
      const subject = props?.automationMailerInfo?.subject;
      const body = props?.automationMailerInfo?.body;
      const id = props?.automationMailerInfo?.id;
      const targetButtonText = props?.automationMailerInfo?.targetButtonText;
      const targetObj = props?.automationMailerInfo?.targetObj;

      setValue('editorButtonText', targetButtonText);
      setValue('editorButtonUrl', targetObj);
      setValue('editorSubject', subject);
      setLoadingBody(true);
      setValue('editorBody', body);
      setLoadingBody(false);
      setValue('editorId', id);

      setTriggerDisplayText(props?.automationMailerInfo?.triggerDisplayText);

      dispatch(fetchAutomationTokens(type));
      dispatch(fetchAutomationButtonTargets(type));
    } else if (automationDetail) {
      const type = automationDetail?.attributes?.automation_type?.identifier || props?.type || '';
      const subject = automationDetail?.attributes?.automation_mailers?.[0]?.subject;
      const body = automationDetail?.attributes?.automation_mailers?.[0]?.body;
      const id = automationDetail?.attributes?.automation_mailers?.[0]?.id;
      const targetButtonURL = automationDetail?.attributes?.automation_mailers?.[0]?.automation_button_target;
      const targetButtonText = automationDetail?.attributes?.automation_mailers?.[0]?.button_text;
      const targetObj = {
        value: targetButtonURL?.id,
        label: targetButtonURL?.display_name,
      };

      setTriggerDisplayText(automationDetail?.attributes?.trigger_display_text);

      setValue('editorButtonText', targetButtonText);
      setValue('editorButtonUrl', targetObj);
      setValue('editorSubject', subject);
      setValue('editorBody', body);
      setValue('editorId', id);

      dispatch(fetchAutomationTokens(type));
      dispatch(fetchAutomationButtonTargets(type));
      setLoadingBody(false);
    }

    if (props?.automationMailerInfo?.triggerDisplayText) {
      setTriggerDisplayText(props?.automationMailerInfo?.triggerDisplayText);
    }
  }, []);

  const handleClose = () => {
    props.onClose(false);
  };

  const handleCloseOnKeyPress = e => {
    if (e.key === 'Enter') {
      handleClose();
    }
  };

  function replaceExtraSpacesWithNbsp(text) {
    // Use regular expression to replace extra white spaces with &nbsp;
    return text.replace(/ {2,}/g, function(match) {
        return match.replace(/ /g, '&nbsp;');
    });
  }


  const filterText = text => {
    let editorBodyText = text.replace(/<p><\/p>/g, '<br />');
    editorBodyText = editorBodyText.replace(/\n/g, '');
    editorBodyText = editorBodyText.replace(/<br><\/p>/g, '</p><br>');
    editorBodyText = editorBodyText.replace(/<p><\/p>/g, '<br />');
    editorBodyText = replaceExtraSpacesWithNbsp(editorBodyText); 
   
    return editorBodyText;
  };

  const submitMailer = () => {
    let data = getValues();

    let editorBodyText = data.editorBody.replace(/<p><\/p>/g, '<br />');
    editorBodyText = editorBodyText.replace(/\n/g, '');
    editorBodyText = editorBodyText.replace(/<br><\/p>/g, '</p><br>');
    editorBodyText = editorBodyText.replace(/<p><\/p>/g, '<br />');
    editorBodyText = replaceExtraSpacesWithNbsp(editorBodyText); 


    let obj = {
      automation: {
        automation_mailers_attributes: [
          {
            subject: data.editorSubject,
            body: editorBodyText,
            id: props?.automationMailerInfo?.id || data.editorId,
            button_text: data.editorButtonText,
            automation_button_target_id: data?.editorButtonUrl?.value,
          },
        ],
      },
    };

    //create mailer
    let payload = {
      subject: data.editorSubject,
      body: data.editorButtonText,
      id: data.editorId,
      button_text: data.editorButtonText,
      automation_button_target_id: data?.editorButtonUrl?.value,
      otherData: {
        ...automationMailerInfo,
        id: data.editorId,
        subject: data.editorSubject,
        body: editorBodyText,
        targetButtonText: data.editorButtonText,
        targetObj: data.editorButtonUrl,
        targetButtonURL: data.editorButtonUrl,
        triggerDisplayText: triggerDisplayText,
      },
    };

    if (props?.mode && props?.mode === 'create') {
      props.onSubmit(payload);
    } else {
      dispatch(
        updateAutomationDetail(automationID, obj, (status, message, data) => {
          if (status) {
            payload.id = data?.attributes?.automation_mailers?.[0]?.id;
            payload.otherData.id = data?.attributes?.automation_mailers?.[0]?.id;
            props.onSubmit(payload);
          }
        }),
      );
    }
  };

  const onSubmit = data => {
    setEditScreenShow(false);
  };

  useEffect(() => {
    if (updateAutomation?.status === 'success') {
      setShowToastSuccess(true);
      setToastMessage('Mailer updated');
      setTimeout(() => {
        setShowToastSuccess(false);
        setShowToastFailure(false);
        setToastMessage('');
      }, 2000);
    }
    if (updateAutomation?.status === 'fail') {
      setShowToastFailure(true);
      setToastMessage('Error updating mailer');
      setTimeout(() => {
        setShowToastSuccess(false);
        setShowToastFailure(false);
        setToastMessage('');
      }, 2000);
    }
  }, [updateAutomation]);

  const addSubjectToken = value => {
    let subject = getValues('editorSubject');
    let curPos = document.getElementById('subject').selectionStart;
    let updatedSubject = subject?.slice(0, curPos) + ' ' + value + ' ' + subject?.slice(curPos);
    let removedExtraSpaceSubject = removeExtraSpace(updatedSubject);
    if (removedExtraSpaceSubject?.length <= subjectLength) {
      setValue('editorSubject', removedExtraSpaceSubject);
    }
  };

  const addBodyToken = value => {
    setLoadingBody(true);
    setSendBodyToken(value);
    setLoadingBody(false);
  };

  useEffect(() => {
    if (sendBodyToken) {
      setSendBodyToken('');
    }
  }, [sendBodyToken]);

  useEffect(() => {
    setLoadingBody(false);
  }, [getValues('editorBody')]);

  const getBodyRemainingCharacters = message => {
    if (typeof message === 'undefined') {
      return bodyLength;
    }

    if (typeof message === 'string') {
      if (message.length > bodyLength) {
        return 0;
      }
      return bodyLength - message.length;
    }
  };

  const removeExtraSpace = s =>
    s
      .trim()
      .split(/ +/)
      .join(' ');

  return (
    <ThemeProvider theme={themeVia}>
      <ViaModal showing={props.show} onClose={() => handleClose()} width="90%" closeable={false}>
        {automationMailerLoading ? (
          <>Loading...</>
        ) : (
          <>
            {editorScreen ? (
              <form id="modalForm">
                <div className={sStyles.flexContainer}>
                  <div className={sStyles.formModalHeader}>
                    <div className={sStyles.header}>
                      <img className={sStyles.mailImage} src={mail} />
                      Edit Mailer
                    </div>
                    <div className={sStyles.rightContent}>
                      <div className={sStyles.dot}>1</div>
                      <div className={sStyles.text}>Edit</div>
                      <div className={sStyles.line} />
                      <div className={sStyles.dot2}>2</div>
                      <div className={sStyles.text2}>Preview</div>
                      <img
                        src={closeIcon}
                        className={sStyles.closeIconCls}
                        onKeyDown={e => handleCloseOnKeyPress(e)}
                        onClick={() => handleClose()}
                        ref={mainDiv}
                        tabIndex={0}
                      />
                    </div>
                  </div>
                  <div className={sStyles.instructions}>
                    Edit this mailer to reflect your organization's tone, style and content.
                  </div>

                  <div className={sStyles.mailContainer}>
                    <div className={sStyles.mailColumn}>
                      <div className={sStyles.mailRow1}>
                        Subject: <span className={sStyles.star}>*</span>
                      </div>

                      <div className={sStyles.mailRow2}>
                        <Controller
                          control={control}
                          name="editorSubject"
                          rules={{
                            required: 'Subject required',
                          }}
                          render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                            <>
                              <input
                                tabIndex="0"
                                className={sStyles.inputField}
                                style={error?.message ? { borderColor: '#8c1313' } : {}}
                                type="text"
                                id="subject"
                                value={value}
                                onBlur={onBlur}
                                onChange={e => {
                                  if (e.target.value.length <= subjectLength) {
                                    onChange(e);
                                  }
                                }}
                              />

                              <EditorDropdown
                                tabIndex="0"
                                title="+ Tokens"
                                type="subject"
                                onChange={addSubjectToken}
                                value={
                                  automationTokens &&
                                  automationTokens.data?.map((item, i) => {
                                    return {
                                      value: item.attributes.placeholder,
                                      label: item.attributes.display_name,
                                    };
                                  })
                                }
                              />
                              {error?.message && (
                                <span className={sStyles.subjectBodyErrorMessage}>
                                  <img src={errowarning} className={sStyles.warningErrorIcon} /> {error.message}
                                </span>
                              )}
                              <span className={sStyles.spanMessage}>
                                {subjectLength - (watch('editorSubject')?.length || 0)} Characters Remaining
                              </span>
                            </>
                          )}
                        />
                      </div>
                    </div>

                    <div className={sStyles.mailColumn}>
                      <div className={sStyles.mailRow1}>
                        Message: <span className={sStyles.star}>*</span>
                      </div>

                      <div className={sStyles.editorContainer}>
                        {/* !editorBody || editorBody?.length === 0 */}

                        <Controller
                          control={control}
                          name="editorBody"
                          rules={{
                            validate: value => {
                              if (!value || value.length === 0) {
                                return 'Email body required';
                              }

                              if (value?.length > bodyLength) {
                                return 'You are over the character limit, please reduce the message text';
                              }
                            },
                          }}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <>
                              <div style={error?.message ? { border: '1px solid #8c1313' } : {}}>
                                {!loadingBody && !automationMailerLoading && (
                                  <Suspense fallback={<h1>Still Loading…</h1>}>
                                    <WysiwygEditor
                                      tabIndex="0"
                                      id="body"
                                      name="body"
                                      labelText="Message"
                                      value={value}
                                      isRequired
                                      setFieldValue={(id, data) => {
                                        if (data === `<p></p>\n`) {
                                          onChange('');
                                        } else {
                                          onChange(data);
                                        }
                                      }}
                                      setUpdateToken={sendBodyToken}
                                      reset={reset}
                                      clearReset={() => {
                                        setReset(false);
                                      }}
                                      editorStyle={{ height: '150px' }}
                                      placeholder="Enter mailer body"
                                    />
                                  </Suspense>
                                )}

                                <EditorDropdown
                                  tabIndex="0"
                                  title="+ Tokens"
                                  onChange={addBodyToken}
                                  type="body"
                                  value={
                                    automationTokens &&
                                    automationTokens.data?.map((item, i) => {
                                      return {
                                        value: item.attributes.placeholder,
                                        label: item.attributes.display_name,
                                      };
                                    })
                                  }
                                />
                              </div>

                              {error?.message && (
                                <span className={sStyles.subjectBodyErrorMessage}>
                                  <img src={errowarning} className={sStyles.warningErrorIcon} /> {error.message}
                                </span>
                              )}
                            </>
                          )}
                        />

                        {bodyLength >= (watch('editorBody')?.length || 0) && (
                          <span className={sStyles.spanMessage}>
                            {getBodyRemainingCharacters(watch('editorBody'))} Characters Remaining
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  
                  
                  <div className={sStyles.mailContainer}>
                    <div className={sStyles.mailColumn}>
                      <div className={sStyles.mailRow1}>
                        Button URL: <span className={sStyles.star}>*</span>
                      </div>
                      <div className={sStyles.mailRow2}>
                        <Controller
                          control={control}
                          name="editorButtonUrl"
                          rules={{
                            validate: value => {
                              if (!value || !value?.value) {
                                return 'Button URL is required';
                              }
                            },
                          }}
                          render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                            <>
                              <InputSelect
                                tabIndex="0"
                                aria-label="Button URL Dropdown"
                                errorMessage=""
                                isRequired
                                helpText=""
                                isMulti={false}
                                // value={value}
                                value={automationButtonTargets?.data
                                  ?.map((item, i) => {
                                    return {
                                      value: item.id,
                                      label: item.attributes.display_name,
                                    };
                                  })
                                  ?.find(i => Number(i?.value) === Number(value?.value))}
                                styles={{
                                  control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderColor: '#9c9c9c',
                                    borderRadius: 0,
                                  }),
                                  option: (provided, state) => ({
                                    ...provided,
                                    backgroundColor: state.isSelected ? '#b8b6b6' : 'inherit',
                                    '&:hover': { backgroundColor: state.isSelected ? '#b8b6b6' : '#d6d4d4' },
                                  }),
                                }}
                                options={
                                  automationButtonTargets &&
                                  automationButtonTargets.data
                                    ?.map((item, i) => {
                                      return {
                                        value: item.id,
                                        label: item.attributes.display_name,
                                      };
                                    })
                                    .filter(obj => {
                                      if (Number(obj.value) === value?.value) {
                                        return false;
                                      }
                                      return true;
                                    })
                                }
                                placeholder=""
                                onChange={e => {
                                  onChange(e);
                                }}
                              />
                              {error?.message && (
                                <span className={sStyles.subjectBodyErrorMessage}>
                                  <img src={errowarning} className={sStyles.warningErrorIcon} /> {error.message}
                                </span>
                              )}
                            </>
                          )}
                        />
                      </div>
                    </div>
                    <div className={sStyles.mailColumn}>
                      <div className={sStyles.mailRow1}>
                        Button Text: <span className={sStyles.star}>*</span>
                      </div>

                      <Controller
                        control={control}
                        name="editorButtonText"
                        rules={{
                          validate: value => {
                            if (!value || value.length === 0) {
                              return 'Button text is required';
                            }

                            if (value.length > buttonTextLength) {
                              return 'You are over the character limit, please reduce the button text';
                            }
                          },
                        }}
                        render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                          <div className={sStyles.mailRow2}>
                            <input
                              tabIndex="0"
                              className={sStyles.inputField}
                              style={error?.message ? { borderColor: '#8c1313' } : {}}
                              type="text"
                              id="buttonText"
                              value={value}
                              onChange={e => {
                                onChange(e);
                              }}
                            />
                            <span className={sStyles.spanMessage}>
                              {buttonTextLength - value?.length > 0
                                ? buttonTextLength - value?.length + ' Characters Remaining'
                                : '0 Characters Remaining'}
                            </span>
                            {error?.message && (
                              <span className={sStyles.subjectBodyErrorMessage}>
                                <img src={errowarning} className={sStyles.warningErrorIcon} /> {error?.message}
                              </span>
                            )}
                          </div>
                        )}
                      />
                    </div>
                  </div>

                  <div className={sStyles.footerContainer}>
                    <div className={sStyles.footerButtonContainer}>
                      <ButtonMediumOpenSans aria-label="Cancel" onClick={() => handleClose()} tabIndex="0">
                        Cancel
                      </ButtonMediumOpenSans>
                      <AccentButtonMediumOpenSans
                        tabIndex="0"
                        aria-label="Preview Automation"
                        type={'button'}
                        onClick={() => {
                          handleSubmit(onSubmit)();
                        }}
                      >
                        Next
                      </AccentButtonMediumOpenSans>
                    </div>
                  </div>
                </div>
              </form>
            ) : (
              <div className={sStyles.flexContainer}>
                <div className={sStyles.formModalHeader}>
                  <div className={sStyles.header}>
                    <img className={sStyles.mailImage} src={mail} />
                    Preview Mailer
                  </div>
                  <div className={sStyles.rightContent}>
                    <div className={sStyles.dot2}>1</div>
                    <div className={sStyles.text2}>Edit</div>
                    <div className={sStyles.line} />
                    <div className={sStyles.dot}>2</div>
                    <div className={sStyles.text}>Preview</div>
                    <img
                      src={closeIcon}
                      className={sStyles.closeIconCls}
                      onClick={() => handleClose()}
                      ref={previewDiv}
                      tabIndex="0"
                    />
                  </div>
                </div>
                <div className={sStyles.instructions}>
                  <div className={sStyles.bold}>Mailer sends when</div>
                  {triggerDisplayText}
                </div>

                <div className={sStyles.previewContainer}>
                  <div className={sStyles.previewContent}>
                    <div className={sStyles.logoContainer}>
                      <img
                        className={sStyles.imageLogo}
                        alt="client logo"
                        src={organization.branding_theme.logo.theme_logo}
                      />
                    </div>
                    <div className={sStyles.messageContainer}>
                      {ReactHtmlParser(_trim(filterText(getValues('editorBody'))))}
                      <div className={sStyles.divCenter}>
                        <AccentButtonMediumOpenSans
                          style={{ background: '#D74B73', border: '1px solid #D74B73' }}
                          aria-label="Reply"
                        >
                          {getValues('editorButtonText')}
                        </AccentButtonMediumOpenSans>
                      </div>
                    </div>
                    <div className={sStyles.warningMessage}>
                      Do not reply to this message from your inbox. Log in to your Via TRM account to take actions or
                      message an administrator.
                    </div>
                  </div>
                </div>

                <div className={sStyles.footerContainer}>
                  <div className={sStyles.footerButtonContainer}>
                    <ButtonMediumOpenSans aria-label="Cancel" onClick={() => setEditScreenShow(true)} tabIndex="0">
                      Back
                    </ButtonMediumOpenSans>
                    <AccentButtonMediumOpenSans
                      type={'button'}
                      aria-label="Deactivate Automation"
                      onClick={() => {
                        submitMailer();
                      }}
                      tabIndex="0"
                    >
                      Save
                    </AccentButtonMediumOpenSans>
                  </div>
                </div>
              </div>
            )}
          </>
        )}

        <ToastMessage
          message={toastMessage}
          show={showToastSuccess}
          isSuccess
          onClose={() => {
            setShowToastSuccess(false);
            setToastMessage('');
          }}
        />
        <ToastMessage
          message={toastMessage}
          show={showToastFailure}
          isSuccess={false}
          onClose={() => {
            setShowToastFailure(false);
            setToastMessage('');
          }}
        />
      </ViaModal>
    </ThemeProvider>
  );
}

export default enhance(MailerFormModal);
