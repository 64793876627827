import React, { useEffect } from 'react';
import { compose } from 'recompose';
import { useSelector } from 'react-redux';
import propTypes from 'prop-types';
import _get from 'lodash/get';
import { Link } from 'react-router-dom';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import AccessDenied from '../../../../../../../sites/viaGlobal/components/pages/accessDenied';
import Paragraph from '../../../../../../components/library/paragraph';
import HousingItemView from '../../../../itineraryItems/housingItemView';
import ActivityItemView from '../../../../itineraryItems/activityItemView';
import TransportationItemView from '../../../../itineraryItems/transportationItemView';
import sAccordion from '../../../../../../../../public/css/accordion.scss';
import sOvItineraryByType from './ovItineraryByType.scss';
import Headline from '../../../../../../components/library/headline';

export const enhance = compose(withStyles(sOvItineraryByType, sAccordion));

function OvItineraryByType(props) {
  const selectedPlan = useSelector(state => state.plans.plan);
  const currentUser = useSelector(state => state.profile.currentUser);
  const permissions = useSelector(state => state.profile.permissions);
  let transportationItems = null;
  let housingItems = null;
  let activityItems = null;

  let link = '';

  switch (props.as) {
    case 'admin':
      link = `/plans/group-itinerary/${props.planId}`;
      break;
    case 'traveler':
      link = `/plans/my-itinerary/${selectedPlan.plan.id}`;
      break;
    case 'leader':
      link = `/plans/leader-group-itinerary/${props.planId}`;
      break;
    case '':
      link = '';
      break;
  }

  if (props.byGroup) {
    transportationItems = _get(selectedPlan.group_itinerary_byType, 'plan_transportation') || null;
    housingItems = _get(selectedPlan.group_itinerary_byType, 'plan_housing') || null;
    activityItems = _get(selectedPlan.group_itinerary_byType, 'plan_activity') || null;
  } else {
    transportationItems = _get(selectedPlan.traveler_itinerary_byType, 'plan_transportation') || null;
    housingItems = _get(selectedPlan.traveler_itinerary_byType, 'plan_housing') || null;
    activityItems = _get(selectedPlan.traveler_itinerary_byType, 'plan_activity') || null;
  }


  useEffect(() => {
    const accordianPanels = document.querySelectorAll(".accordion__panel"); 
    for(let i = 0; i < accordianPanels.length; i++){
      accordianPanels[i].role = "contentinfo"; 
    }
  }, []); 



  return (currentUser['client_user_role?'] && permissions.travel_plans_settings.includes('view_travelers')) ||
    currentUser['is_traveler?'] ? (
    <div className={sOvItineraryByType['ov-itinerary-by-type']}>
      {!!selectedPlan &&
        !!selectedPlan.plan && (
          <>
            <Accordion allowMultipleExpanded allowZeroExpanded preExpanded={['transportation', 'activity', 'housing']}>
              <>
                {!transportationItems && !housingItems && !activityItems ? (
                  <AccordionItem uuid="transportation">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        <div className="wrapper-line-center">
                          <Headline style={{
            fontFamily:"IntroBoldItalic, sans-serif",
            textTransform:"capitalize",
            fontSize:"24px",
            lineHeight:"32px"
          }} tag="div">
                            <span className="line-center">Transportation&nbsp;&nbsp;</span>
                          </Headline>
                        </div>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      {link.length > 0 && !selectedPlan.plan.attributes.removed ? (
                        <Paragraph>
                          <Link to={link}>Add transportation details </Link>
                          to your itinerary.
                        </Paragraph>
                      ) : (
                        <Paragraph>There are no transportation details for this group itinerary yet.</Paragraph>
                      )}
                    </AccordionItemPanel>
                  </AccordionItem>
                ) : (
                  !!transportationItems && (
                    <AccordionItem uuid="transportation">
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          <div className="wrapper-line-center">
                            <Headline style={{
            fontFamily:"IntroBoldItalic, sans-serif",
            textTransform:"capitalize",
            fontSize:"24px",
            lineHeight:"32px"
          }} tag="div">
                              <span className="line-center">Transportation&nbsp;&nbsp;</span>
                            </Headline>
                          </div>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        {transportationItems.map(detail => (
                          <TransportationItemView
                            key={detail.id}
                            detail={detail}
                            planId={selectedPlan.plan.id}
                            hideEdit
                            hideRemove
                          />
                        ))}
                      </AccordionItemPanel>
                    </AccordionItem>
                  )
                )}

                {!transportationItems && !housingItems && !activityItems ? (
                  <AccordionItem uuid="housing">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        <div className="wrapper-line-center">
                          <Headline style={{
            fontFamily:"IntroBoldItalic, sans-serif",
            textTransform:"capitalize",
            fontSize:"24px",
            lineHeight:"32px"
          }} tag="div">
                            <span className="line-center">Housing&nbsp;&nbsp;</span>
                          </Headline>
                        </div>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      {link.length > 0 && !selectedPlan.plan.attributes.removed ? (
                        <Paragraph>
                          <Link to={link}>Add housing details </Link>
                          to your itinerary.
                        </Paragraph>
                      ) : (
                        <Paragraph>There are no housing details for this group itinerary yet.</Paragraph>
                      )}
                    </AccordionItemPanel>
                  </AccordionItem>
                ) : (
                  !!housingItems && (
                    <AccordionItem uuid="housing">
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          <div className="wrapper-line-center">
                            <Headline style={{
            fontFamily:"IntroBoldItalic, sans-serif",
            textTransform:"capitalize",
            fontSize:"24px",
            lineHeight:"32px"
          }} tag="div">
                              <span className="line-center">Housing&nbsp;&nbsp;</span>
                            </Headline>
                          </div>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        {housingItems.map(detail => (
                          <HousingItemView
                            key={detail.id}
                            detail={detail}
                            planId={selectedPlan.plan.id}
                            hideEdit
                            hideRemove
                          />
                        ))}
                      </AccordionItemPanel>
                    </AccordionItem>
                  )
                )}

                {!transportationItems && !housingItems && !activityItems ? (
                  <AccordionItem uuid="activity">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        <div className="wrapper-line-center">
                          <Headline style={{
            fontFamily:"IntroBoldItalic, sans-serif",
            textTransform:"capitalize",
            fontSize:"24px",
            lineHeight:"32px"
          }} tag="div">
                            <span className="line-center">Activities&nbsp;&nbsp;</span>
                          </Headline>
                        </div>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      {link.length > 0 && !selectedPlan.plan.attributes.removed ? (
                        <Paragraph>
                          <Link to={link}>Add activity details</Link> to your itinerary.
                        </Paragraph>
                      ) : (
                        <Paragraph>There are no activity details for this group itinerary yet.</Paragraph>
                      )}
                    </AccordionItemPanel>
                  </AccordionItem>
                ) : (
                  !!activityItems && (
                    <AccordionItem uuid="activity">
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          <div className="wrapper-line-center">
                            <Headline style={{
            fontFamily:"IntroBoldItalic, sans-serif",
            textTransform:"capitalize",
            fontSize:"24px",
            lineHeight:"32px"
          }} tag="div">
                              <span className="line-center">Activities&nbsp;&nbsp;</span>
                            </Headline>
                          </div>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        {activityItems.map(detail => (
                          <ActivityItemView
                            key={detail.id}
                            detail={detail}
                            planId={selectedPlan.plan.id}
                            hideEdit
                            hideRemove
                          />
                        ))}
                      </AccordionItemPanel>
                    </AccordionItem>
                  )
                )}
              </>
            </Accordion>
          </>
        )}
    </div>
  ) : (
    <AccessDenied />
  );
}

OvItineraryByType.propTypes = {
  byGroup: propTypes.bool,
  as: propTypes.oneOf(['admin', 'traveler', 'leader', '']),
};

OvItineraryByType.defaultProps = {
  byGroup: false,
};

export default enhance(OvItineraryByType);
