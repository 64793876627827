import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import ProfileFormGrid from '../../base/ProfileFormGrid.styled';
import ProfileTextInput from '../../base/inputs/ProfileTextInput';
import ProfileSelectInput from '../../base/inputs/ProfileSelectInput';
import GridColumnPlaceholder from '../../base/GridColumnPlaceholder.styled';
import ProfilePhoneNumberInput from '../../base/inputs/ProfilePhoneNumberInput';

import countries from '../../../../../../public/data/countries.json';
import relationshipOptions from '../../../utils/options/relationshipOptions';

export default function PrimaryEmergencyContactForm({ form, setForm }) {
  const { info, readonly } = useSelector(state => state.travelerProfile);

  const omittedCountryValues = ['Countries Vary', 'Stateless/Refuge/Other', 'Unspecified Location'];
  const countryOptions = countries.filter(country => !omittedCountryValues.includes(country.label));
  const [countryOptionList, setCountryOptionList] = useState([...countryOptions]);
  const [relationshipOptionList, setRelationshipOptionList] = useState([...relationshipOptions]);

  useEffect(() => {
    let newCountryOptionList = [...countryOptionList];
    //country mapping
    if (info.emergency_contact_address_country) {
      let country = countryOptionList.find(
        option =>
          option.value === info.emergency_contact_address_country ||
          option.label === info.emergency_contact_address_country,
      );
      if (!country) {
        newCountryOptionList = [
          ...newCountryOptionList,
          {
            id: Date.now(),
            label: info.emergency_contact_address_country,
            value: info.emergency_contact_address_country,
          },
        ];

        setCountryOptionList([...newCountryOptionList]);
      }
    }

    //relationship mapping
    let newRelationshipOptionList = [...relationshipOptionList];
    if (info.emergency_contact_relationship) {
      let emergencyContactRelationship = relationshipOptionList.find(
        option =>
          option.value === info.emergency_contact_relationship || option.label === info.emergency_contact_relationship,
      );

      if (!emergencyContactRelationship) {
        newRelationshipOptionList = [
          ...newRelationshipOptionList,
          {
            id: Date.now(),
            label: info.emergency_contact_relationship,
            value: info.emergency_contact_relationship,
          },
        ];

        setRelationshipOptionList([...newRelationshipOptionList]);
      }
    }

    setForm({
      emergency_contact_email: info.emergency_contact_email,
      emergency_contact_last_name: info.emergency_contact_last_name,
      emergency_contact_first_name: info.emergency_contact_first_name,
      emergency_contact_phone_number: info.emergency_contact_country_code
        ? `${info.emergency_contact_country_code}${info.emergency_contact_phone_number}`
        : info.emergency_contact_phone_number,
      emergency_contact_address_city: info.emergency_contact_address_city,
      emergency_contact_address_state: info.emergency_contact_address_state,
      emergency_contact_address_line_1: info.emergency_contact_address_line_1,
      emergency_contact_address_line_2: info.emergency_contact_address_line_2,
      emergency_contact_address_zip_code: info.emergency_contact_address_zip_code,
      emergency_contact_relationship:
        newRelationshipOptionList.find(option => option.value === info.emergency_contact_relationship) || '',
      emergency_contact_address_country:
        newCountryOptionList.find(country => country.value === info.emergency_contact_address_country) || '',
    });
  }, [info]);

  return (
    <ProfileFormGrid>
      <ProfileTextInput
        id="emergency_contact_first_name"
        label="First Name"
        placeholder="Enter first name"
        value={form.emergency_contact_first_name}
        synced={readonly.includes('emergency_contact_first_name')}
        handleOnChange={event => {
          setForm({ ...form, emergency_contact_first_name: event.target.value });
        }}
      />
      <ProfileTextInput
        id="emergency_contact_last_name"
        label="Last Name"
        placeholder="Enter last name"
        value={form.emergency_contact_last_name}
        synced={readonly.includes('emergency_contact_last_name')}
        handleOnChange={event => {
          setForm({ ...form, emergency_contact_last_name: event.target.value });
        }}
      />
      <ProfileSelectInput
        id="emergency_contact_relationship"
        label="Relationship"
        placeholder="Select relationship"
        value={form.emergency_contact_relationship}
        options={relationshipOptionList}
        synced={readonly.includes('emergency_contact_relationship')}
        handleOnChange={event => {
          setForm({ ...form, emergency_contact_relationship: event });
        }}
      />
      <ProfileTextInput
        id="emergency_contact_email"
        type="email"
        label="Email"
        placeholder="Enter email"
        value={form.emergency_contact_email}
        synced={readonly.includes('emergency_contact_email')}
        handleOnChange={event => {
          setForm({ ...form, emergency_contact_email: event.target.value });
        }}
      />

      {readonly.includes('emergency_contact_phone_number') ? (
        <ProfileTextInput
          synced={true}
          id="emergency_contact_phone_number"
          label="Phone"
          value={form.emergency_contact_phone_number}
        />
      ) : (
        <ProfilePhoneNumberInput
          id="emergency_contact_phone_number"
          label="Phone"
          value={form.emergency_contact_phone_number}
          handleOnChange={event => {
            setForm({ ...form, emergency_contact_phone_number: event });
          }}
        />
      )}

      <GridColumnPlaceholder />
      <ProfileTextInput
        id="emergency_contact_address_line_1"
        className="col-span-2"
        label="Address"
        labelHelperText="(Street Address or P.O. Box)"
        placeholder="Enter address"
        value={form.emergency_contact_address_line_1}
        synced={readonly.includes('emergency_contact_address_line_1')}
        handleOnChange={event => {
          setForm({ ...form, emergency_contact_address_line_1: event.target.value });
        }}
      />
      <ProfileTextInput
        id="emergency_contact_address_line_2"
        className="col-span-2"
        label="Address Line 2"
        placeholder="Enter address line 2"
        value={form.emergency_contact_address_line_2}
        synced={readonly.includes('emergency_contact_address_line_2')}
        handleOnChange={event => {
          setForm({ ...form, emergency_contact_address_line_2: event.target.value });
        }}
      />
      <GridColumnPlaceholder />
      <ProfileTextInput
        id="emergency_contact_address_city"
        label="City"
        placeholder="Enter city"
        value={form.emergency_contact_address_city}
        synced={readonly.includes('emergency_contact_address_city')}
        handleOnChange={event => {
          setForm({ ...form, emergency_contact_address_city: event.target.value });
        }}
      />
      <ProfileTextInput
        id="emergency_contact_address_state"
        label="State / Province / Territory"
        placeholder="Enter state / province / territory"
        value={form.emergency_contact_address_state}
        synced={readonly.includes('emergency_contact_address_state')}
        handleOnChange={event => {
          setForm({ ...form, emergency_contact_address_state: event.target.value });
        }}
      />
      <GridColumnPlaceholder />
      <ProfileTextInput
        id="emergency_contact_address_zip_code"
        label="Zip / Postal Code"
        placeholder="Enter zip / postal code"
        value={form.emergency_contact_address_zip_code}
        synced={readonly.includes('emergency_contact_address_zip_code')}
        handleOnChange={event => {
          setForm({ ...form, emergency_contact_address_zip_code: event.target.value });
        }}
      />
      <ProfileSelectInput
        id="emergency_contact_address_country"
        label="Country"
        placeholder="Select country"
        value={form.emergency_contact_address_country}
        options={countryOptions}
        synced={readonly.includes('emergency_contact_address_country')}
        handleOnChange={event => {
          setForm({ ...form, emergency_contact_address_country: event });
        }}
      />
    </ProfileFormGrid>
  );
}

PrimaryEmergencyContactForm.propTypes = {
  form: PropTypes.shape({
    emergency_contact_email: PropTypes.string,
    emergency_contact_last_name: PropTypes.string,
    emergency_contact_first_name: PropTypes.string,
    emergency_contact_phone_number: PropTypes.string,
    emergency_contact_address_city: PropTypes.string,
    emergency_contact_address_state: PropTypes.string,
    emergency_contact_address_line_1: PropTypes.string,
    emergency_contact_address_line_2: PropTypes.string,
    emergency_contact_address_zip_code: PropTypes.string,
    emergency_contact_relationship: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    emergency_contact_address_country: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  }).isRequired,
  setForm: PropTypes.func.isRequired,
};
