exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3qWgK{width:100%;padding:25px 60px 20px}._1sm9K{height:24px}._2hGWn{gap:20px}._2hGWn,.kYav0{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row}.kYav0{gap:10px;margin-top:20px}._2QEA0{width:288px}._3D4ll{width:98px;height:51px;background-color:#d74b73}.us0zy{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;gap:55px;padding:30px;background-color:#ebebeb}._3ZfUP{color:#373737;Width:57px;Height:19px;border:none;background-color:transparent}._2sLF_{gap:64px;padding:25px 26px}._28yBp{font-family:Open Sans,sans-serif;font-weight:400;font-size:14px;letter-spacing:.25%;line-height:16.8px;margin-top:6px}._2P7q5{font-size:25px;width:693px;height:30px}._24lDj{font-family:Nunito,sans-serif;font-weight:400;font-size:25px;line-height:30px}._3kTi-{font-weight:700;line-height:16.8%}._3kTi-,._22_fx{font-family:Open Sans,sans-serif;font-size:14px;letter-spacing:.25%}._22_fx{font-weight:400}.yPqBh{font-family:Nunito,sans-serif;font-weight:600;font-size:16px;line-height:19.2px;letter-spacing:.1%}._228X7{margin-top:10px}._2S4AH{font-family:Open Sans,sans-serif;font-weight:600;font-size:14px;letter-spacing:.25%}.FsiBR{height:18px}._1PwSI{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center;gap:10px}._1PwSI img{width:100px}._3lKmZ{font-family:Nunito;font-size:25px;font-weight:400;line-height:30px;text-align:center}._3qyvl{margin-top:20px;margin-bottom:20px}._23De2{font-family:Open Sans;font-size:12px;font-weight:400;line-height:14.4px;letter-spacing:.0025em;text-align:left}._23De2,._23De2:hover{color:#255a94}.p_rKj{display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center;height:400px}", ""]);

// exports
exports.locals = {
	"custom": "_3qWgK",
	"tableRow": "_1sm9K",
	"formDiv": "_2hGWn",
	"formDiv2": "kYav0",
	"formSelect": "_2QEA0",
	"nextBtn": "_3D4ll",
	"btnDiv": "us0zy",
	"cancelBtn": "_3ZfUP",
	"headerTitle": "_2sLF_",
	"fontSize": "_28yBp",
	"modaltextsize": "_2P7q5",
	"createGrouptext": "_24lDj",
	"divtext1": "_3kTi-",
	"divtext2": "_22_fx",
	"plansettingtext": "yPqBh",
	"plansettingdiv": "_228X7",
	"tableheader": "_2S4AH",
	"tableheight": "FsiBR",
	"planCreatedContainer": "_1PwSI",
	"planCreatedText": "_3lKmZ",
	"warningContainer": "_3qyvl",
	"programName": "_23De2",
	"center": "p_rKj"
};