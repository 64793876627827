import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import InputSelect from '../../../../components/library/inputSelect';
import { fetchRegistrationStatusTypes } from '../../../../../actions/lookupActions';

function RegistrationStatusSelect(props) {
  const dispatch = useDispatch();
  const registrationStatusTypes = useSelector(state => state.lookup.allRegistrationStatusTypes);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (!registrationStatusTypes.registrationStatusTypes) {
      dispatch(fetchRegistrationStatusTypes());
    } else {
      setOptions(
        registrationStatusTypes.registrationStatusTypes.map(item => {
          return { label: item.attributes.name, value: item.id };
        }),
      );
    }
  }, [registrationStatusTypes]);

  return (
    <>
      {registrationStatusTypes && registrationStatusTypes.registrationStatusTypes && (
        <InputSelect {...props} options={options} />
      )}
    </>
  );
}

RegistrationStatusSelect.propTypes = {
  errorMessage: propTypes.string,
  labelText: propTypes.string,
  helpText: propTypes.string,
  placeholder: propTypes.string,
  isRequired: propTypes.bool,
};


RegistrationStatusSelect.defaultProps = {
  errorMessage: '',
  labelText: 'REGISTRATION STATUS',
  helpText: '',
  placeholder: '',
  isRequired: false,
};

export default (RegistrationStatusSelect);
