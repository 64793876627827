import React, { useState, useEffect } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
} from 'react-accessible-accordion';
import { breakpoints } from '../settings/breakpoints';

import sAccordionRow from '../../../public/css/_accordion-row-accordion.scss';
import styled from 'styled-components';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

const AccordionRowDiv = styled.div`
  background-color: ${({ theme }) => theme.accordionRow.backgroundColor};
  font-family: ${({ theme }) => theme.accordionRow.fontFamily};
  font-size: ${({ theme }) => theme.accordionRow.fontSize};
`;

const HeaderDiv = styled.div`
  color: ${({ theme }) => theme.accordionRow.header.fontColor};
  background-color: ${props =>
    props.index % 2 === 0 ? props.theme.accordionRow.backgroundColorEven : props.theme.accordionRow.backgroundColorOdd};
  display: flex;
  font-family: ${({ theme }) => theme.accordionRow.header.fontFamily};
  font-weight: 500;
  font-size: ${({ theme }) => theme.accordionRow.header.fontSize};
  min-height: ${({ theme }) => theme.accordionRow.header.height};
  justify-content: ${props => (props.addTitle ? 'space-between' : null)};
  padding: ${({ theme }) => theme.accordionRow.header.padding};
  align-self: center;

  svg {
    align-self: center;
    display: inline-flex;
    margin-right: 10px;
    position: relative;
  }
`;

const ContentDiv = styled.div`
  padding: ${({ theme }) => theme.accordionRow.content.padding};
  font-size: ${({ theme }) => theme.accordionRow.informationFontSize};
  background-color: ${({ theme }) => theme.defaults.body.backgroundColor};
  border-left: solid 2px #d6d6d6;
  margin-left: 25px;
`;

const ComponentDiv = styled.div`
  width: 25%;
  display: flex;
  padding-top: 5px;
  justify-content: center;
  align-items: end;
`;

const TitleSpan = styled.span`
  width: 75%;
  align-self: center;
  display: flex;
  flex-direction: column;

  @media ${breakpoints.laptop} {
    flex-direction: row;
  }
`;

const AccordionRow = ({ children, title, information, component, id, isDisabled, defaultOpen = true, index }) => {
  const [isVisible, setIsVisible] = useState([]);

  useEffect(
    () => {
      if (defaultOpen) {
        setIsVisible([id]);
      }
    },
    [isVisible.length],
  );

  return (
    <AccordionRowDiv>
      <Accordion allowMultipleExpanded allowZeroExpanded onChange={e => setIsVisible(e)}>
        <AccordionItem uuid={id}>
          <HeaderDiv index={index}>
            <TitleSpan>
              {title}
              <span className="accordionButtonRow">
                <AccordionItemHeading>
                  <AccordionItemButton />
                </AccordionItemHeading>
              </span>
            </TitleSpan>

            {component && isVisible.length > 0 && <ComponentDiv>{component} </ComponentDiv>}
          </HeaderDiv>

          <AccordionItemPanel>
            <ContentDiv>{information}</ContentDiv>
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    </AccordionRowDiv>
  );
};

export default withStyles(sAccordionRow)(AccordionRow);
