import PropTypes from 'prop-types';
import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Modal from '../base/Modal';
import FacultyIcon from '../icons/FacultyIcon';
import LuggageIcon from '../icons/LuggageIcon';
import InputError from '../base/inputs/InputError';
import { ModalBody, ModalButton, ModalFooter } from '../base/Modal.styled';
import { ViaLogoCircleIcon } from '../../../../styledComponents/styles/IconCustom.styled';

import { breakpoints } from '../../utils/breakpoints';
import { buttonFocus } from '../../utils/shared/focusStyles';
import { switchCurrentRole } from '../../actions/travelerProfileActions';
import { getLoginPath } from '../../../../shared/helpers/sessionManagement';

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  img {
    width: 75px;
    height: 75px;
  }
`;

const Heading = styled.h2`
  font-family: 'Open Sans', sans-serif !important;
  font-weight: 600 !important;
  font-size: 1.75rem !important;
  color: #0b3544 !important;
`;

const Info = styled.p`
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  color: #373737;
  text-align: center;
`;

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  width: 100%;
  margin-top: 1.5rem;

  @media ${breakpoints.tablet} {
    width: 60%;
  }
`;

const OptionButton = styled.button`
  display: flex;
  align-items: center;
  gap: 1.125rem;
  padding: 0.9375rem 0 0.9375rem 1.875rem;
  background-color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 3px;
  box-shadow: 0 5px 10px 0 rgba(55, 55, 55, 0.25);
  font-family: 'Open Sans', sans-serif;
  font-size: 0.875rem;
  color: #373737;
  line-height: 140%;

  &:disabled {
    background-color: #e6e6e6;
    box-shadow: none;
    cursor: not-allowed;
  }

  &:active {
    background-color: #d1dff1;
    border-color: #d1dff1;
  }

  ${buttonFocus};

  ${props => {
    if (props.selected) {
      return `
        background-color: #d1dff1;
        border-color: #d1dff1;
      `;
    }
  }};
`;

export default function ChangeRoleModal({ show, setShow }) {
  const dispatch = useDispatch();
  const { adminSignIn, freemium } = useSelector(state => state.travelerProfile);
  const { brandingTheme, clientFeatureList } = useSelector(state => state.currentOrganization);

  const [error, setError] = useState('');
  const [logo, setLogo] = useState(false);
  const [selectedRole, setSelectedRole] = useState('');
  const changeUserRole = useSelector(state => state.authentication.changeUserRole);

  useEffect(() => {
    if (brandingTheme && brandingTheme.logo) {
      setLogo(brandingTheme.logo.theme_logo);
    }
  }, [brandingTheme]);

  useEffect(() => {
    if (changeUserRole?.data && !changeUserRole?.loading && !changeUserRole?.error) {
      const path = getLoginPath({
        freemium,
        redirect_jmp: '',
        feature_legacy: clientFeatureList.legacy,
        feature_vt: clientFeatureList.travel_plans,
        admin_sign_in: selectedRole === 'faculty' ? true : false,
      });

      window.location = path;
    }
  }, [changeUserRole]);

  const close = () => {
    setShow(false);
  };

  const handleSwitchRole = () => {
    if (!selectedRole) {
      setError('You must select an access role to switch.');

      return;
    }

    dispatch(switchCurrentRole(selectedRole === 'faculty' ? true : false));
  };

  return (
    <Modal show={show} close={close}>
      <ModalBody>
        <BodyContainer>
          {logo ? <img src={logo} alt="" /> : <ViaLogoCircleIcon />}
          <Heading>Select your access</Heading>
          <Info>Note: You will be logged out of your current role.</Info>
          <OptionsContainer>
            <OptionButton
              type="button"
              disabled={adminSignIn}
              selected={selectedRole === 'faculty'}
              onClick={() => {
                setSelectedRole('faculty');
              }}
            >
              <FacultyIcon />
              Administrator access
            </OptionButton>
            <OptionButton
              type="button"
              disabled={!adminSignIn}
              selected={selectedRole === 'traveler'}
              onClick={() => {
                setSelectedRole('traveler');
              }}
            >
              <LuggageIcon />
              Traveler access
            </OptionButton>
          </OptionsContainer>
          {error && <InputError message={error} />}
        </BodyContainer>
      </ModalBody>
      <ModalFooter>
        <ModalButton type="button" className="secondary" onClick={close}>
          Cancel
        </ModalButton>
        <ModalButton type="button" className="primary" onClick={handleSwitchRole}>
          Switch Now
        </ModalButton>
      </ModalFooter>
    </Modal>
  );
}

ChangeRoleModal.propTypes = {
  show: PropTypes.bool,
  setShow: PropTypes.func.isRequired,
};

ChangeRoleModal.defaultProps = {
  show: false,
};
