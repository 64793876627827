import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import CalendarIcon from '../../icons/CalendarIcon';

import { breakpoints } from '../../../utils/breakpoints';
import { getProgramTermsFromRange } from '../../../utils/getProgramTerms';

const Terms = styled.div`
  display: flex;
  align-items: center;
  gap: 0.71125rem;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  color: #373737;
  letter-spacing: 0.0025em;
  line-height: 120%;

  svg {
    width: 22px;
    height: 22px;
  }

  @media ${breakpoints.desktop} {
    font-size: 1.125rem;
  }
`;

export default function ProgramDetailsTerms() {
  const travelerSubmission = useSelector(state => state.forms.travelerSubmission);
  const [submission, setSubmission] = useState(travelerSubmission?.data);
  useEffect(() => {
    if (!travelerSubmission.loading && travelerSubmission.data) {
      setSubmission(travelerSubmission.data);
    }
  }, [travelerSubmission]);
  const {
    brandingTheme: {
      themes: { theme_color_accent: accentColor },
    },
  } = useSelector(state => state.currentOrganization);

  return (submission && (
    <Terms className="program-details-terms">
      <CalendarIcon fillColor={accentColor} />
      <span tabIndex={0}>{getProgramTermsFromRange(submission.program_range, true)}</span>
    </Terms>
  ));
}
