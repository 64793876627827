import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import ProfileTextInput from '../base/inputs/ProfileTextInput';
import { breakpoints } from '../../utils/breakpoints';

const Container = styled.div`
  grid-column: span 6 / span 6;

  @media ${breakpoints.tablet} {
    grid-column: span 3 / span 3;
  }

  @media ${breakpoints.desktop} {
    grid-column: span 2 / span 2;
  }
`;

export default function IntakeSignUpDate() {
  const intake = useSelector(state => state.intakeForm);

  return (
    <Container>
      <ProfileTextInput
        id="sign_up_date"
        label="Sign Up Date"
        value={new Date(intake.signup_date).toLocaleDateString()}
        locked
      />
    </Container>
  );
}
