import React from 'react';
import styled from 'styled-components';

const ToggleContainer = styled.div`
  display: inline-flex;
`;

const TextToggleDiv = styled.div`
  text-decoration: ${({ theme }) => theme.textToggle.textDecoration};
  cursor: ${props => (props.isDisabled ? 'not-allowed' : 'pointer')};
  align-self: center;
  color: ${({ theme }) => theme.textToggle.color};
`;

const Divider = styled.div`
  color: ${({ theme }) => theme.textToggle.dividerColor};
  align-self: center;
  margin: 0 5px;
`;

const TextToggle = ({ isDisabled, id, handleOn, handleOff, showContent, onCopy, offCopy, ...rest }) => {
  return (
    <>
      {showContent || showContent === undefined ? (
        <ToggleContainer>
          <TextToggleDiv
            onClick={() => {
              isDisabled ? null : handleOn();
            }}
            isDisabled={isDisabled}
            onKeyDown={e => {
              if (e.keyCode === 32 || e.keyCode === 13) {
                isDisabled ? null : handleOn();
              }
            }}
            tabIndex={0}
          >
            {onCopy}
          </TextToggleDiv>

          <Divider>|</Divider>
          <TextToggleDiv
            onClick={() => {
              isDisabled ? null : handleOff();
            }}
            isDisabled={isDisabled}
            onKeyDown={e => {
              if (e.keyCode === 32 || e.keyCode === 13) {
                isDisabled ? null : handleOff();
              }
            }}
            tabIndex={0}
          >
            {offCopy}
          </TextToggleDiv>
        </ToggleContainer>
      ) : null}
    </>
  );
};

export default TextToggle;
