import React, { useEffect, useState } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './alertDetailPDF.css';
import ClockImg from './clock.svg';
import GoogleMapReact from 'google-map-react';
import { useSelector } from 'react-redux';
import {
  extractEmergencyNumbers,
  getLocationName,
  getRelativeTime,
  getTravelerPreferredName,
} from '../../../../../helpers/RiskAlertHelper';
import MarkerIcon from './Marker.png';

function AlertDetailPDF({ traveler_list }) {
  const { riskAlertDetail } = useSelector(state => state.riskAlert);
  const [remainingNumbers, setRemainingNumbers] = useState([]);
  const note = !!riskAlertDetail?.attributes?.alert_note?.body ? riskAlertDetail?.attributes?.alert_note?.body.replace(/<p><\/p>/g, '<br />') : '';
  
  useEffect(() => {
    if (riskAlertDetail?.attributes?.emergency_numbers) {
      let extractedNumbers = extractEmergencyNumbers(riskAlertDetail?.attributes?.emergency_numbers);
      let remNumbers = [];

      for (let i = 0; i < riskAlertDetail?.attributes?.emergency_numbers?.length; i++) {
        let matched = false;

        for (let j = 0; j < extractedNumbers.length; j++) {
          if (
            extractedNumbers[j].type === riskAlertDetail?.attributes?.emergency_numbers[i].type.toUpperCase() &&
            extractedNumbers[j].number === riskAlertDetail?.attributes?.emergency_numbers[i].number
          ) {
            matched = true;
          }
        }

        if (!matched) {
          remNumbers.push(riskAlertDetail?.attributes?.emergency_numbers[i]);
        }
      }
      setRemainingNumbers([...remNumbers]);
    }
  }, [riskAlertDetail?.attributes?.emergency_numbers]);

  const Marker = () => (
    <>
      <img src={MarkerIcon} />
    </>
  );

  const get_check_in_status = status => {
    if (status === 'help_required') {
      return 'Help Requested';
    }

    if (status === 'no_response') {
      return 'No Response';
    }

    if (status === 'safe') {
      return 'Marked Safe';
    }

    if (status === 'not_sent') {
      return 'Not Sent';
    }

    return '';
  };

  const get_row_color = (traveler, index) => {
    let row_color = '#ffffff';

    if (traveler.check_in_status === 'help_required') {
      row_color = '#FFDEDB';
    } else if (index % 2 === 0) {
      row_color = '#EEEEEE';
    }

    return row_color;
  };

  const getTravelerCount = total_travelers => {
    let txt = `${total_travelers} Travelers Affected`;

    if (total_travelers === 1) {
      txt = '1 Traveler Affected';
    }

    if (total_travelers === 0) {
      txt = '0 Traveler Affected';
    }

    return txt;
  };

  const getColumnCount = () => {
    let column_count = 1;
    let total_numbers = riskAlertDetail?.attributes?.emergency_numbers?.length;

    if (total_numbers > 3 && total_numbers <= 6) {
      column_count = 2;
    }

    if (total_numbers > 6 && total_numbers <= 9) {
      column_count = 3;
    }

    if (total_numbers > 9 && total_numbers <= 12) {
      column_count = 4;
    }

    if (total_numbers > 12 && total_numbers <= 15) {
      column_count = 5;
    }

    return column_count;
  };

  return (
    <div style={{ display: 'block' }}>
      <div className={s.pdfViewContainer} id="pdf-section">
        <div className={s.pdfView}>
          <div className={s.pdfViewContent}>
            {/* <span>PDF document view</span> */}
            <h2 className={s.slateGray}>
              {getTravelerCount(Number(riskAlertDetail?.attributes?.travler_admin_count || 0))}
            </h2>
            <div>
              <div className={s.flag}>
                {/* <div className={s.flagImageCircle}>
                  <img src={riskAlertDetail?.attributes?.country_flag_url} alt="Rome" />
                </div>
                */}
                {/* <div className={s.spacer}></div> */}
                <div className={s.countryName}>
                  <span>{getLocationName(riskAlertDetail?.attributes?.impacted_areas_json)}</span>
                </div>
              </div>
              <div className={s.titleAndTime}>
                <h2
                  style={{
                    fontSize: '20px',
                  }}
                >
                  {riskAlertDetail?.attributes?.title}
                </h2>
                <div className={s.time}>
                  <img src={ClockImg} alt="clock" />
                  <div className={s.spacer}></div>
                  <span>{getRelativeTime(riskAlertDetail?.attributes?.start_date)}</span>
                </div>
              </div>
              <div className={s.contentWrapper}>
                <p>{riskAlertDetail?.attributes?.risk_text}</p>
                <br />
                <span className={s.tealBlue}>Advice:</span> <br />
                <p className={s.paraBold}>{riskAlertDetail?.attributes?.advice}</p>
                {note &&
                  <>
                    <span className={s.tealBlue}>Organization Note:</span> <br />
                    <p className={s.paraBold}
                        dangerouslySetInnerHTML={{
                          __html: note,
                        }}
                      />
                  </>
                }
              </div>
            </div>
          </div>
          <hr
            style={{
              margin: '0px 72px',
            }}
          />

          <div
            className="row"
            style={{
              pageBreakInside: 'avoid',
              margin: '0px 72px',
            }}
          >
            <div className="col-md-6">
              <section className={s.mapViewContainer}>
                <div className={s.mapWrapper1} id="map-container">
                  <GoogleMapReact
                    bootstrapURLKeys={{ key: 'AIzaSyDyZnxFjwYKZv_QCVKkOAUpOxDOSMmFOJA' }} // Replace with your own API key
                    center={{
                      lat: riskAlertDetail?.attributes?.location?.coordinates[1],
                      lng: riskAlertDetail?.attributes?.location?.coordinates[0],
                    }}
                    defaultZoom={14}
                  >
                    <Marker
                      lat={riskAlertDetail?.attributes?.location?.coordinates[1]}
                      lng={riskAlertDetail?.attributes?.location?.coordinates[0]}
                    />
                  </GoogleMapReact>
                </div>
              </section>
            </div>
            {/* map */}
            <div className={`col-md-6 ${s.emergencySection}`}>
              <p className={s.tealBlue}>Local Emergency Services</p>
              <p>Numbers provided are based on this alert's country location.</p>

              <div className={s.contactNum}>
                {extractEmergencyNumbers(riskAlertDetail?.attributes?.emergency_numbers)?.map((num, index) => (
                  <div className={s.contactNumber} key={`emergency-number-${index}`}>
                    <span>
                      <b>{num.type}</b>
                    </span>
                    <span>{num.number}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div
            className="row"
            style={{
              margin: '0 72px',
            }}
          >
            {remainingNumbers?.map((num, index) => (
              <div className="col-6">
                <div className={s.contactNumber} key={`rem-emergency-number-${index}`}>
                  <span>
                    <b>
                      {num.type}
                      {num?.additional !== '-' && `(${num?.additional})`}
                    </b>
                  </span>
                  <span>{num.number}</span>
                </div>
              </div>
            ))}
          </div>

          {traveler_list?.length > 0 && (
            <div className={s.travelerList}>
              <div>
                <b>On-site Travelers Expected in Impacted Area</b>
              </div>

              <table>
                <thead>
                  <tr>
                    <th>Traveler</th>
                    <th>Check-in Status</th>
                    <th>Phone</th>
                    <th>Planned Location</th>
                    <th>Location Associated To</th>
                  </tr>
                </thead>
                <tbody>
                  {traveler_list &&
                    traveler_list.map((traveler, index) => (
                      <tr
                        key={`traveler-${index}`}
                        style={{
                          backgroundColor: get_row_color(traveler, index),
                        }}
                      >
                        <td>{getTravelerPreferredName(traveler)}</td>
                        <td>{get_check_in_status(traveler.check_in_status)}</td>
                        <td>{traveler.user_phone_number || traveler.safe_check_number}</td>
                        <td>{traveler.locations}</td>
                        <td>
                          {'plan_title' in traveler && traveler.plan_title}

                          {'programs_title' in traveler && traveler.programs_title}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default withStyles(s)(AlertDetailPDF);
