import styled from 'styled-components';

import { breakpoints } from '../../utils/breakpoints';

export const HeadingContainer = styled.div`
  padding: 1.1875rem 2.1875rem;
  background: #f7f7f7;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
`;

export const FlexRowHeadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1px 20px 10px 20px;
  padding: 0 2.1875rem;

  @media ${breakpoints.tablet} {
    padding: 0;
  }

  @media ${breakpoints.desktop} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  @media ${breakpoints.mobile} {
    padding: 0px;
  }
`;

export const Heading = styled.span`
  font-family: 'Nunito', sans-serif;
  font-weight: 700;
  font-size: 1.5625rem;
  color: #373737;
  line-height: 120%;
  margin-top: 12px;
`;