import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import ScreenReaderText from '../base/ScreenReaderText.styled';
import {
  Accordion,
  AccordionItem,
  AccordionItemPanel,
  AccordionItemState,
  AccordionItemButton,
  AccordionItemHeading,
} from 'react-accessible-accordion';

import ChevronUpIcon from '../icons/ChevronUpIcon';
import ChevronDownIcon from '../icons/ChevronDownIcon';
import NotificationSettingsToggleSwitch from './NotificationSettingsToggleSwitch';

import { breakpoints } from '../../utils/breakpoints';
import { buttonFocus } from '../../utils/shared/focusStyles';
import sendGridMailerType from '../../types/sendGridMailerType';
import { replaceCustomAliasesHandlebars } from '../../../../shared/helpers/General';

const ChildItem = styled(AccordionItem)`
  padding: 0.590625rem 0 0.590625rem 1.125rem;
  border-top: 1px solid #d7d7d7;
`;

const ChildItemHeadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  gap: 0.5rem;

  @media ${breakpoints.tablet} {
    flex-direction: row;
    align-items: center;
    gap: 0;
  }
`;

const ChildItemButton = styled(AccordionItemButton)`
  display: flex;
  align-items: center;
  gap: 1rem;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  color: #373737;
  letter-spacing: 0.06em;
  line-height: 120%;

  svg {
    flex-shrink: 0;
  }

  ${buttonFocus};
`;

const ChildItemPanel = styled(AccordionItemPanel)`
  margin-top: 0.590625rem;
  margin-bottom: -0.590625rem;
  margin-left: 1.1875rem;
  border-left: 1px solid #d7d7d7;
`;

const ChildItemPanelText = styled.p`
  margin-bottom: 0;
  padding: 1.395625rem 0 1.395625rem 1.1875rem;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  color: #474747;
  letter-spacing: 0.06em;
  line-height: 120%;
`;

export default function NotificationSettingsChildItem({ mailerType }) {
  const { customAliases } = useSelector(state => state.currentOrganization);

  const displayName = replaceCustomAliasesHandlebars(mailerType.display_name, customAliases);

  return (
    <Accordion allowMultipleExpanded allowZeroExpanded>
      <ChildItem>
        <ChildItemHeadingContainer>
          <AccordionItemHeading aria-level={4}>
            <ChildItemButton>
              <span>{displayName}</span>
              <ScreenReaderText>{mailerType.description}</ScreenReaderText>
              <AccordionItemState>
                {({ expanded }) => (expanded ? <ChevronUpIcon /> : <ChevronDownIcon />)}
              </AccordionItemState>
            </ChildItemButton>
          </AccordionItemHeading>
          <NotificationSettingsToggleSwitch mailerType={mailerType} />
        </ChildItemHeadingContainer>
        <ChildItemPanel>
          <ChildItemPanelText>{mailerType.description}</ChildItemPanelText>
        </ChildItemPanel>
      </ChildItem>
    </Accordion>
  );
}

NotificationSettingsChildItem.propTypes = {
  mailerType: sendGridMailerType.isRequired,
};
