exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1habo{-ms-flex-pack:justify;justify-content:space-between;padding:25px;border-bottom:5px solid var(--theme_color_accent)}._1habo,._1uv6U{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row}._1uv6U{padding:30px 70px;gap:14px}.eng6o{color:#373737;text-align:center;font-family:Nunito;font-size:25px;font-style:normal;font-weight:400;line-height:120%}._1ZfkE{border-radius:0 0 10px 10px;background:#ebebeb;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-pack:center;justify-content:center;padding:30px}._1ZZmA{color:#373737;border:none;padding:0}._1ZZmA,._2ZZ2F{font-family:Open Sans;font-size:16px;font-style:normal;font-weight:600;line-height:120%;letter-spacing:.96px}._2ZZ2F{border-radius:5px;background:var(--theme_color_accent);color:var(--theme_color_accent_font);text-align:center;border:1px #d74b73;padding:16px 28px}._2ZZ2F:disabled,._2ZZ2F:hover{opacity:.7}._3FMpV{outline:none;border:none;padding:0;background:none}._4n7Xy{min-height:150px;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center}._4n7Xy,.IilsG{display:-ms-flexbox;display:flex}.IilsG{-ms-flex-direction:column;flex-direction:column;-ms-flex-pack:justify;justify-content:space-between}.d4Rpu{color:#373737;font-family:Open Sans;font-size:16px;font-style:normal;font-weight:400;line-height:140%;letter-spacing:.08px}.wFE8X{font-weight:700}._25SIh{display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center}._1wdmx{width:300px}._18fSI{display:-ms-flexbox;display:flex;gap:50px;-ms-flex-pack:center;justify-content:center;margin-top:20px}", ""]);

// exports
exports.locals = {
	"header": "_1habo",
	"container": "_1uv6U",
	"heading": "eng6o",
	"footer": "_1ZfkE",
	"cancelBtn": "_1ZZmA",
	"primaryBtn": "_2ZZ2F",
	"closeButton": "_3FMpV",
	"imageContainer": "_4n7Xy",
	"textContainer": "IilsG",
	"description": "d4Rpu",
	"descriptionBold": "wFE8X",
	"textInputParentContainer": "_25SIh",
	"textInputContainer": "_1wdmx",
	"buttonContainer": "_18fSI"
};