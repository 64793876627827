import React, { useState } from 'react';
import { compose } from 'recompose';
import propTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import ViaModal from '../../../../components/library/viaModal';
import Headline from '../../../../components/library/headline';
import Button from '../../../../components/library/button';
import Paragraph from '../../../../components/library/paragraph';
import { getPlan, patchPlanUser } from '../../../../../actions/plans';
import sRemoveTraveler from './removeTraveler.scss';

export const enhance = compose(withStyles(sRemoveTraveler));

function RemoveTraveler(props) {
  const dispatch = useDispatch();
  const [saving, setSaving] = useState(false);

  const removeTravelers = () => {
    const submitData = {
      plans_user: { removed: true },
    };

    props.planUsers.forEach((user, index, array) => {
      setTimeout(() => {
        dispatch(patchPlanUser(user, submitData));
      }, 500 * index);
    });

    setTimeout(() => {
      setSaving(false);
      dispatch(getPlan(props.planId));
      props.onClose(false);
    }, 500 * (props.planUsers.length + 1));
  };

  return (
    <>
      <ViaModal showing={props.show} onClose={() => props.onClose(false)}>
        <div className={sRemoveTraveler['remove-traveler']}>
          <div className={sRemoveTraveler.subHeader}>
            <Headline centered tag="h1" as="h1">
              Remove Traveler from the Group Plan
            </Headline>
          </div>
          <div className={sRemoveTraveler.content}>
            <Paragraph size="large">
              You are about to remove one or more Travelers from the Group Plan. This will:
            </Paragraph>
          </div>
          <div className={sRemoveTraveler['content-list']}>
            <span>
              <ul>
                <li>Remove all travel details from Traveler itineraries</li>
                <li>Notify Travelers of these changes</li>
                <li>Traveler will be marked as 'Not Going' in plan</li>
              </ul>
            </span>
          </div>
          <div className={sRemoveTraveler.content}>
            <Paragraph size="large">
              You will be able to reinstate Travelers and travel details to this Travel Plan.
            </Paragraph>
          </div>
          <div className={sRemoveTraveler.footer}>
            <div className={sRemoveTraveler['footer-confirm']}>
              <Headline tag="h2" as="h3" centered>
                Would You Still Like To Remove Traveler Or Travelers From This Group Plan?
              </Headline>
            </div>
            <div className={sRemoveTraveler['footer-button-wrapper']}>
              <div className={sRemoveTraveler['button-content']}>
                <Button
                  display="primary"
                  kind="solid"
                  size="medium"
                  type="submit"
                  onClick={() => removeTravelers()}
                  loading={saving}
                  disabled={saving}
                >
                  Yes, Remove Traveler
                </Button>
              </div>
              <div className={sRemoveTraveler['button-content']}>
                <Button
                  display="secondary"
                  kind="solid"
                  size="medium"
                  onClick={() => {
                    props.onClose(false);
                  }}
                >
                  No, Keep Traveler
                </Button>
              </div>
            </div>
          </div>
        </div>
      </ViaModal>
    </>
  );
}

RemoveTraveler.propTypes = {
  show: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
  planId: propTypes.string.isRequired,
  planUsers: propTypes.array,
};

export default enhance(RemoveTraveler);
