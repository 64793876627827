exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1c3Fe{padding:0}._6sPxw{padding-bottom:300px}._30PMs{font-family:AvenirNextRegular,sans-serif;text-align:center;padding-top:20px;margin:0;color:#76ccce}", ""]);

// exports
exports.locals = {
	"noPadding": "_1c3Fe",
	"content": "_6sPxw",
	"successMessage": "_30PMs"
};