import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ProfileSelectInput from '../base/inputs/ProfileSelectInput';
import programSortOptions from '../../utils/options/programSortOptions';
import { setProgramsSort } from '../../actions/programDashboardActions';
import getSortValues from '../../utils/program-dashboard/getSortValues';

export default function ProgramSorter() {
  const dispatch = useDispatch();
  const {matchComplete, showMatch } = useSelector(state => state.travelerProfile);

  const { user_role } = useSelector(state => state.profile);

  const [selectedSort, setSelectedSort] = useState(programSortOptions[0]);

  const noMatchPercentageOptions = programSortOptions.filter(option => option.value !== 'match_percentage');

  const options =
    user_role === 'traveler' 
      ? showMatch && matchComplete
        ? programSortOptions
        : noMatchPercentageOptions
      : noMatchPercentageOptions;


  useEffect(
    () => {
      if (user_role === 'traveler' && showMatch && matchComplete) {
        setSelectedSort(programSortOptions[4]);
      }
    },
    [user_role, matchComplete],
  );

  useEffect(
    () => {
      const { sortProperties, sortDirections } = getSortValues(selectedSort.value);

      dispatch(setProgramsSort(sortProperties, sortDirections));
    },
    [selectedSort],
  );

  return (
      <ProfileSelectInput
      id="program_sort_by"
      value={selectedSort}
      label="Sort by:"
      className="program-sorter"
      options={options}
      labelOnLeft
      handleOnChange={setSelectedSort}
    />
  );
}
