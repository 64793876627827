import React from 'react';

export default function InfoBubbleIcon() {
  return (
    <svg
      width="125"
      height="125"
      viewBox="0 0 125 125"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
    >
      <path
        d="M21.176 113.765C24.0728 109.87 26.509 105.041 28.437 99.3796C15.4683 89.0195 7.82153 73.2612 7.82153 56.5662C7.82129 26.3672 32.3899 1.79858 62.5889 1.79858C92.7879 1.79858 117.356 26.3672 117.356 56.5662C117.356 86.7651 92.7879 111.334 62.5889 111.334C58.6668 111.334 54.7617 110.916 50.95 110.091C42.2397 116.828 32.9087 120.88 27.9272 122.754C22.459 124.812 17.6919 118.448 21.176 113.765Z"
        fill="#A9CADA"
      />
      <path
        d="M26.0476 113.765C28.6409 110.278 30.8652 106.042 32.6863 101.131C33.0645 100.111 32.7673 98.9551 31.9397 98.249C19.8035 87.8926 12.6931 72.6648 12.6931 56.5659C12.6931 27.1833 35.9519 3.13452 65.0247 1.85547C64.2168 1.81982 63.4055 1.79858 62.5889 1.79858C32.3899 1.79858 7.82129 26.3672 7.82129 56.5662C7.82129 73.2615 15.468 89.0195 28.4368 99.3796C26.509 105.041 24.0725 109.87 21.1758 113.765C18.2478 117.701 21.1482 123.136 25.8425 123.136C26.5369 123.136 27.2419 123.011 27.927 122.753C28.0505 122.707 28.1787 122.658 28.3076 122.609C25.0046 121.096 23.7483 116.856 26.0476 113.765Z"
        fill="#D7E9F2"
      />
      <path
        d="M62.5886 11.1377C37.5391 11.1377 17.1599 31.5168 17.1599 56.5664C17.1599 71.3748 24.4146 85.2959 36.5657 93.8049C38.207 94.9543 38.9321 97.0229 38.3674 98.9453C37.2405 102.784 35.9104 106.349 34.3872 109.623C38.3411 107.539 42.7278 104.827 46.8372 101.423C47.9966 100.463 49.5469 100.121 51.0032 100.503C54.769 101.493 58.667 101.995 62.5886 101.995C87.6382 101.995 108.018 81.616 108.018 56.5664C108.018 31.5168 87.6382 11.1377 62.5886 11.1377Z"
        fill="#F5F7F8"
      />
      <path
        d="M34.3874 109.622C36.4778 108.521 38.6895 107.241 40.917 105.779C41.7715 103.609 42.5503 101.33 43.251 98.9446C43.8157 97.0222 43.0906 94.9536 41.4492 93.8042C29.2979 85.2952 22.0435 71.3743 22.0435 56.5657C22.0435 32.3352 41.1118 12.4761 65.0305 11.2029C64.2219 11.1599 63.4082 11.1367 62.5889 11.1367C37.5393 11.1367 17.1602 31.5159 17.1602 56.5654C17.1602 71.3738 24.4148 85.2949 36.5659 93.804C38.2073 94.9534 38.9324 97.022 38.3677 98.9443C37.2405 102.783 35.9106 106.349 34.3874 109.622Z"
        fill="#C7DEEA"
      />
      <path
        d="M62.6245 41.1011C65.9116 41.1011 68.6885 38.3657 68.6885 35.1282C68.6885 31.9331 65.9114 29.2339 62.6245 29.2339C59.2415 29.2339 56.4893 31.8782 56.4893 35.1282C56.489 38.3655 59.2986 41.1011 62.6245 41.1011Z"
        fill="#A9CADA"
      />
      <path
        d="M62.6245 47.7126C59.2219 47.7126 56.8459 49.3604 56.8459 51.72V81.6802C56.8459 83.6531 59.168 85.7659 62.6245 85.7659C65.741 85.7659 68.4744 83.8567 68.4744 81.6802V51.72C68.4746 49.3979 66.0142 47.7126 62.6245 47.7126Z"
        fill="#A9CADA"
      />
      <path
        d="M61.3315 35.1277C61.3315 32.7163 62.8469 30.6401 65.0103 29.728C64.2737 29.4111 63.4661 29.2334 62.6245 29.2334C59.2412 29.2334 56.4893 31.8777 56.4893 35.1277C56.4893 38.3655 59.2991 41.1006 62.6245 41.1006C63.4741 41.1006 64.2891 40.9165 65.0317 40.5896C62.877 39.6519 61.3315 37.5286 61.3315 35.1277Z"
        fill="#7C99A8"
      />
      <path
        d="M61.6882 81.6802V51.72C61.6882 49.9758 62.9876 48.6213 65.0393 48.0332C64.314 47.8262 63.5007 47.7126 62.6243 47.7126C59.2217 47.7126 56.8457 49.3604 56.8457 51.72V81.6802C56.8457 83.6531 59.1677 85.7659 62.6243 85.7659C63.4695 85.7659 64.2859 85.6245 65.0283 85.3762C62.9636 84.6768 61.6882 83.1411 61.6882 81.6802Z"
        fill="#7C99A8"
      />
      <path
        d="M62.605 42.9646C66.8845 42.9646 70.5002 39.3906 70.5002 35.1604C70.5002 30.9729 66.8845 27.4348 62.605 27.4348C58.2122 27.4348 54.6382 30.9004 54.6382 35.1604C54.6382 39.3906 58.2866 42.9646 62.605 42.9646ZM62.605 31.0972C64.8994 31.0972 66.8379 32.9578 66.8379 35.1604C66.8379 37.4058 64.8997 39.3022 62.605 39.3022C60.2715 39.3022 58.3005 37.4055 58.3005 35.1604C58.3005 32.9199 60.2317 31.0972 62.605 31.0972Z"
        fill="#373737"
      />
      <path
        d="M70.2861 81.7126V51.7524C70.2861 48.3694 67.0559 45.9141 62.605 45.9141C58.1243 45.9141 54.9951 48.3149 54.9951 51.7524V81.7126C54.9951 84.5583 57.9045 87.6294 62.605 87.6294C66.7686 87.6294 70.2861 84.9199 70.2861 81.7126ZM58.6575 81.7126V51.7524C58.6575 50.4917 60.3176 49.5764 62.605 49.5764C64.8584 49.5764 66.6238 50.5322 66.6238 51.7524V81.7126C66.6238 82.6204 64.9363 83.9673 62.605 83.9673C60.0134 83.967 58.6575 82.4993 58.6575 81.7126Z"
        fill="#373737"
      />
      <path
        d="M66.9113 113.025C66.9594 113.025 67.008 113.023 67.0573 113.02C94.8864 110.825 117.071 88.4228 119.025 60.6006C119.096 59.5918 118.335 58.7165 117.327 58.6457C116.323 58.5754 115.443 59.3352 115.372 60.344C113.552 86.2515 92.6581 107.327 66.7697 109.368C65.7616 109.448 65.0087 110.33 65.0883 111.338C65.1635 112.297 65.9652 113.025 66.9113 113.025Z"
        fill="#373737"
      />
      <path
        d="M109.829 56.5984C109.829 30.5393 88.6284 9.33862 62.5693 9.33862C36.5103 9.33862 15.3093 30.5395 15.3093 56.5984C15.3093 72.0039 22.8557 86.4853 35.4961 95.3369C36.4946 96.0361 36.9348 97.2917 36.5911 98.4614C35.4949 102.195 34.188 105.701 32.7075 108.882C32.376 109.594 32.5359 110.439 33.1052 110.981C33.6748 111.523 34.5264 111.641 35.2212 111.275C39.9854 108.764 44.2798 105.935 47.9856 102.865C48.6882 102.284 49.635 102.075 50.5178 102.306C80.6392 110.225 109.829 87.3574 109.829 56.5984ZM45.6497 100.045C43.374 101.93 40.8586 103.722 38.134 105.401C38.8484 103.504 39.5071 101.53 40.105 99.4934C40.8918 96.8144 39.8838 93.9387 37.5964 92.3369C25.9341 84.1702 18.9717 70.8101 18.9717 56.5986C18.9717 32.5588 38.5295 13.0012 62.5691 13.0012C86.6086 13.0012 106.166 32.5591 106.166 56.5986C106.166 84.9822 79.2305 106.067 51.4482 98.7644C49.4277 98.2329 47.2588 98.7121 45.6497 100.045Z"
        fill="#373737"
      />
      <path
        d="M19.7114 121.935C21.8369 124.757 25.4226 125.677 28.5522 124.5C33.3608 122.691 42.617 118.705 51.3765 112.087C53.6231 112.537 55.9236 112.854 58.2283 113.03C59.2385 113.107 60.1162 112.352 60.1934 111.343C60.2703 110.335 59.5154 109.455 58.5068 109.378C56.0825 109.193 53.6638 108.842 51.3179 108.334C50.7896 108.22 50.2378 108.344 49.8098 108.675C41.2339 115.308 32.0022 119.289 27.2625 121.072C25.5879 121.702 23.7361 121.191 22.6367 119.731C21.5498 118.288 21.5452 116.343 22.6255 114.891C25.6306 110.85 28.1624 105.841 30.1504 100.003C30.3994 99.272 30.1631 98.4636 29.5598 97.9819C16.8958 87.8652 9.63257 72.7817 9.63257 56.5989C9.63281 27.4099 33.3801 3.66284 62.5693 3.66284C89.8394 3.66284 113.006 24.9312 115.311 52.082C115.397 53.0896 116.285 53.8367 117.29 53.7517C118.298 53.666 119.045 52.78 118.96 51.7725C116.501 22.8074 92.1541 0 62.5693 0C31.3606 0 5.97046 25.3901 5.97046 56.5984C5.97046 73.2573 13.376 89.2139 26.2647 100.016C24.4702 104.981 22.2605 109.244 19.687 112.705C17.6284 115.472 17.6382 119.182 19.7114 121.935Z"
        fill="#373737"
      />
    </svg>
  );
}
