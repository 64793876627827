import React from 'react';
import Select from 'react-select';
import withStyles from '../../../node_modules/isomorphic-style-loader/lib/withStyles';
import s from './Dropdown.css';
import reactSelectStyles from '../../../public/css/react-select.css';

class Dropdown extends React.Component {
  state = {
    selectedApplication: '',
  };

  handleChange = value => {
    this.props.onChange(this.props.name, value.value);
    this.setState({ selectedApplication: value.label });
  };

  render() {
    const customStyles = {
      menu: (provided, state) => ({ ...provided }),
      control: provided => ({ ...provided, border: 'none' }),
    };

    return (
      <div className={`${s.inputWrapper} eligibility-dropdown`}>
        <Select
          options={this.props.options}
          id={this.props.styleId}
          name={this.props.name}
          placeholder={this.props.value ? this.state.selectedApplication : ''}
          isClearable={false}
          value={this.props.value}
          className={`profile-select ${this.props.value != '' ? `${s.fieldAdded}` : null}`}
          onChange={this.handleChange}
          styles={customStyles}
        />
        <label htmlFor={this.props.styleId} className={s.label}>
          {this.props.placeholder}
        </label>
        {!!this.props.error &&
          this.props.touched && <div style={{ color: 'red', marginTop: '.5rem' }}>{this.props.error}</div>}
      </div>
    );
  }
}

export default withStyles(reactSelectStyles, s)(Dropdown);
