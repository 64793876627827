import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import Apply from './Apply';
import Share from './Share';
import Favorite from './Favorite';
import Question from './Question';

import { Header } from './Header.styled';
import { programIdType } from '../../utils/types';
import GenericActionsSelect from '../../../plans/components/selects/genericActionsSelect';
import BatchAddEditCustomBrouchureSection from '../../../viaGlobal/components/modals/batchAddEditCustomBrouchureSection';
import BatchDeleteCustomBrouchureSection from '../../../viaGlobal/components/modals/batchDeleteCustomBrouchureSection';

export default function Index({
  programId,
  programTitle,
  showSharing = true,
  toggleFavoriteModal,
  programBrochureSection,
}) {
  const [showAddEditCustomBrochureModal, setShowAddEditCustomBrochureModal] = useState(false);
  const [showDeleteCustomBrochureModal, setShowDeleteCustomBrochureModal] = useState(false);
  const [selectDefault, setSelectDefault] = useState();

  const permissions = useSelector(state => state.profile.permissions.program_settings);
  const { 'is_traveler?': isTraveler, admin_sign_in: adminSignIn } = useSelector(state => state.profile.currentUser);
  const {
    meta: { internal, authorized },
  } = useSelector(state => state.programBrochure);
  const selectOptions = [];

  useEffect(() => {
    if (permissions?.includes('create_edit_custom_brochure_sections')) {
      selectOptions.push({ label: 'Add/Edit Custom Brochure Section', value: '2' });
      selectOptions.push({
        label: 'Delete Custom Brochure Section',
        value: '3',
        textColor: '#A82425',
        isDisabled: programBrochureSection?.id ? false : true,
      });
    }
  }, [selectOptions]);

  const selectedAction = action => {
    setSelectDefault({ label: `Actions`, value: '0' });

    switch (action.toString()) {
      case '1':
        break;
      case '2':
        setShowAddEditCustomBrochureModal(true);
        setSelectDefault({ label: `Actions`, value: '0' });
        break;
      case '3':
        setShowDeleteCustomBrochureModal(true);
        setSelectDefault({ label: `Actions`, value: '0' });
        break;
    }
  };

  return (
    <Header>
      <div className="section section--left">
        <Question programId={programId} isTraveler={isTraveler} isAdmin={adminSignIn} />
        {showSharing && <Share programId={programId} programTitle={programTitle} />}
      </div>
      <div style={{ gap: adminSignIn ? '1.875rem' : '' }} className="section section--right">
        {isTraveler && showSharing && <Favorite programId={programId} onFavoriteClick={toggleFavoriteModal} />}
        {adminSignIn && permissions?.includes('create_edit_custom_brochure_sections') && (internal || authorized) && (
          <GenericActionsSelect
            value={selectDefault}
            options={selectOptions}
            onChange={e => {
              selectedAction(e.value);
            }}
            placeholder="Actions"
          />
        )}
        {showSharing && <Apply />}
      </div>
      {showAddEditCustomBrochureModal && (
        <BatchAddEditCustomBrouchureSection
          isBatchEdit={false}
          show={showAddEditCustomBrochureModal}
          programBrochureSection={programBrochureSection}
          selectedPrograms={programId}
          onClose={() => {
            setShowAddEditCustomBrochureModal(false);
          }}
          setSelectedPrograms={() => {}}
        />
      )}
      {showDeleteCustomBrochureModal && (
        <BatchDeleteCustomBrouchureSection
          show={showDeleteCustomBrochureModal}
          programBrochureSection={programBrochureSection}
          selectedPrograms={programId}
          sectionID={programBrochureSection?.id}
          isBatchEdit={false}
          onClose={() => {
            setShowDeleteCustomBrochureModal(false);
          }}
          setSelectedPrograms={() => {}}
        />
      )}
    </Header>
  );
}

Index.propTypes = {
  programId: programIdType,
  showSharing: PropTypes.bool,
  programTitle: PropTypes.string,
};

Index.defaultProps = {
  programId: '',
  showSharing: true,
  programTitle: '',
};
