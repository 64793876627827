import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import _sortBy from 'lodash/sortBy';
import Card from '@material-ui/core/Card';
import Checkbox from '@material-ui/core/Checkbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import IncludedIcon from './IncludedIcon.styled';
import NotIncludedIcon from './NotIncludedIcon.styled';
import leftArrowIcon from '../../../public/img/left_arrow.png';
import leftArrowUpIcon from '../../../public/img/left_arrow_up.png';
import rightArrowDownIcon from '../../../public/img/right_arrow_down.png';
import rightArrowIcon from '../../../public/img/right_arrow.png';
import { breakpoints } from '../settings/breakpoints';
import { Col, Grid, Row } from './FlexGrid.styled';

const ListName = styled.label`
  color: ${props => (props.excluded && props.column === 'right' ? '#373737' : '#043544')};
  font-family: AvenirNextRegular, sans-serif;
  font-size: 14px;
  text-decoration: ${props => (props.excluded && props.column === 'right' ? 'line-through' : 'none')};
  font-style: ${props => (props.excluded && props.column === 'right' ? 'italic' : 'none')};
`;

const CategoryName = styled.label`
  color: #373737;
  font-family: AvenirNextMedium, sans-serif;
  font-size: 14px;
`;

const ColumnHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  min-height: 40px;
  width: 255px;
  
  @media ${breakpoints.laptop} {
    width: 325px;
  }
`;

const ColumnHeader = styled.div`
  color: #373737;
  font-family: AvenirNextMedium, sans-serif;
  font-size: 12px;
  line-height: 120%;
  padding-bottom: 10px;
`;

const ButtonContainer = styled(Row)`
  display: flex;
  flex-direction: row;
  margin: 20px 20px 20px 40px;

  padding-bottom: 0;

  @media ${breakpoints.laptop} {
    flex-direction: column;
    margin: 20px;
  }
`;

const ButtonCol = styled(Col)`
  align-self: auto;
  margin-top: 0;

  @media ${breakpoints.laptop} {
    align-self: center;
    margin-top: 25px;
  }
`;

const ListButton = styled.button.attrs({ type: 'button' })`
  background-color: #fff;
  border: 1px solid #ebebeb;
  border-radius: 2px;
  box-sizing: border-box;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  color: #373737;
  font-family: AvenirNextRegular, sans-serif;
  font-size: 12px;
  margin: 0 10px;
  padding: 10px;
  text-transform: inherit;
  width: 100px;

  &:disabled,
  &:disabled:active {
    opacity: 0.3;
    cursor: not-allowed;
  }
  
  &:focus {
    border: 1px solid #007bff;
    outline-color: #007bff;
  }
  
  @media ${breakpoints.laptop} {
    margin: 10px 0;
  }
`;

const ButtonImg = styled.div`
  padding-bottom: 5px;
  display: none;

  @media ${breakpoints.laptop} {
    display: block;
  }
`;

const ButtonImgMobile = styled.div`
  padding-bottom: 5px;
  display: block;

  @media ${breakpoints.laptop} {
    display: none;
  }
`;

const ListItemDiv = styled.div`
  display: flex;
  align-items: center;

  
  &:focus-within {
    border: 1px solid #007bff;
    outline-color: #007bff;
  }
`;

const ListCard = styled.div`
  width: 255px;

  &:focus-within {
    border: 1px solid #007bff;
    outline-color: #007bff;
  }
  
  
  @media ${breakpoints.laptop} {
    width: 325px;
  }
`;

function not(a, b) {
  return a.filter(value => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter(value => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

export default function Amenities(props) {
  const { amenities, onChange } = props;
  const amenityListSorted = _sortBy(amenities, ['amenity_category_display_name', 'amenity_name'], ['asc']);
  const sortedRightList = _sortBy(amenities, ['amenity_name'], ['asc']);
  const [amenityList, setAmenityList] = useState(amenityListSorted);
  const [checked, setChecked] = useState([]);
  const customAliases = useSelector(state => state.profile.customAliases);
  const [left, setLeft] = useState(amenityList.filter(amenity => !amenity.included && !amenity.excluded));
  const [right, setRight] = useState(sortedRightList.filter(amenity => amenity.included || amenity.excluded));
  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = value => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleExcluded = (value) => {
    if (!props.disabled) {
      amenities.map(amenity => {
        if (value.id === amenity.id) {
          amenity.excluded = !value.excluded;
          amenity.included = !value.included;
        }

      });
    }
    setChecked([...checked]);
    onChange(amenities);
  };

  const handleToggleCategory = (items, category) => () => {
    const catList = items.filter(item => item.amenity_category_display_name === category);
    setChecked(union(checked, catList));
  };

  const handleCheckedRight = () => {
    leftChecked.forEach(item => {
      item.included = true;
      item.excluded = false;
    });

    let rightList = right.concat(leftChecked);
    rightList = _sortBy(rightList, ['amenity_name'], ['asc']);

    setRight(rightList);
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));

    const newList = right.concat(left);
    onChange(newList);
  };

  const handleCheckedLeft = () => {
    rightChecked.forEach(item => {
      item.included = false;
      item.excluded = false;
    });

    let leftList = left.concat(rightChecked);
    leftList = _sortBy(leftList, ['amenity_category_display_name', 'amenity_name'], ['asc']);

    setLeft(leftList);
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));

    const newList = right.concat(left);
    onChange(newList);
  };

  const CustomList = (title, items, column) => {
    let category = '';
    return (
      <ListCard>
        <Card
          style={{
            // width: '255px',
            minHeight: '300px',
            maxHeight: '300px',
            overflowY: 'auto',
            borderRadius: '2px',
            border: '1px solid #c2c2c2',
            boxShadow: 'none',
          }}
        >
          <List
            sx={{
              minHeight: '300px',
            }}
            component="div"
            role="list"
          >
            {items.map(value => {
              let showCategory = false;
              const labelId = `transfer-list-all-item-${value.id}-${value.amenity_name}-label`;

              if (category !== value.amenity_category_display_name){
                category = value.amenity_category_display_name;
                showCategory = true;
              }

              return (
                <div key={value.amenity_name}>
                  {column === 'left' && showCategory ? (
                    <ListItemDiv key={value.amenity_category_display_name}>
                      <ListItemIcon>
                        <Checkbox
                          disabled={props.disabled}
                          disableRipple
                          id={value.amenity_category_display_name}
                          onClick={handleToggleCategory(items, value.amenity_category_display_name)}
                          style={{color:'#56a3ba'}}
                          onKeyDown={(e)=>{
                            if (e.keyCode === 32) {
                              handleToggle(value);
                            }
                            if (e.keyCode === 13 ) {
                              e.preventDefault();
                            }
                          }}
                          onKeyPress={(e) => {
                            if (e.keyCode === 13) {
                              e.preventDefault();
                            }
                          }}
                          tabIndex={0}
                        />
                      </ListItemIcon>
                      <CategoryName
                        htmlFor={value.amenity_category_display_name}
                        primary={`${value.amenity_category_display_name}`}
                      >
                        {value.amenity_category_display_name}
                      </CategoryName>
                    </ListItemDiv>
                  ) : null}

                  <ListItem
                    role="listitem"
                    button
                    style={{ display: 'flex', justifyContent: 'space-between', paddingTop: 0, paddingBottom: 0 }}
                  >
                    <ListItemDiv>
                      <ListItemIcon>
                        <Checkbox
                          disabled={props.disabled}
                          checked={checked.indexOf(value) !== -1}
                          disableRipple
                          id={labelId}
                          onClick={handleToggle(value)}
                          style={{color:'#56a3ba'}}
                          onKeyDown={(e)=>{
                            if (e.keyCode === 32) {
                              handleToggle(value);
                            }
                            if (e.keyCode === 13 ) {
                              e.preventDefault();
                            }
                          }}
                          onKeyPress={(e) => {
                            if (e.keyCode === 13) {
                              e.preventDefault();
                            }
                          }}
                          tabIndex={0}
                        />
                      </ListItemIcon>
                      <ListName
                        excluded={value.excluded}
                        column={column}
                        htmlFor={labelId}
                        primary={`${value.amenity_name}`}
                        tabIndex={-1}
                      >
                        {value.amenity_name}
                      </ListName>
                    </ListItemDiv>
                    {!value.excluded && column === 'right' &&
                      <IncludedIcon
                        onClick={() => handleExcluded(value)}
                        onKeyDown={(e) => {
                          if (e.keyCode === 32) {
                            e.preventDefault();
                            handleExcluded(value);
                          }
                          if (e.keyCode === 13 ) {
                            e.preventDefault();
                          }
                        }}
                        onKeyPress={(e) => {
                          if (e.keyCode === 13) {
                            e.preventDefault();
                          }
                        }}
                        tabIndex={0}
                      />
                    }
                    {value.excluded && column === 'right' &&
                    <NotIncludedIcon
                      onClick={() => handleExcluded(value)}
                      onKeyDown={(e) => {
                        if (e.keyCode === 32) {
                          e.preventDefault();
                          handleExcluded(value);
                        }
                        if (e.keyCode === 13 ) {
                          e.preventDefault();
                        }
                      }}
                      onKeyPress={(e) => {
                        if (e.keyCode === 13) {
                          e.preventDefault();
                        }
                      }}
                      tabIndex={0}
                    />
                    }
                  </ListItem>
                </div>
              );
            })}
            <ListItem />
          </List>
        </Card>
      </ListCard>
    );
  }

  return (
    <Grid>
      <Row justify={'center'} style={{alignItems: 'center'}}>
        <Col>
          <ColumnHeaderContainer>
            <ColumnHeader><br />Items not displayed on {customAliases.alias_program.toLowerCase()} brochure</ColumnHeader>
          </ColumnHeaderContainer>
          {CustomList(`Items not displayed on ${customAliases.alias_program.toLowerCase()} brochure`, left, 'left')}
        </Col>
        <ButtonCol>
          <ButtonContainer>
            <ListButton
              onClick={handleCheckedRight}
              disabled={leftChecked.length === 0 || props.disabled}
              aria-label="move selected right"
            >
              <div>
                <ButtonImg><img src={rightArrowIcon} alt={'add to brochure'} /></ButtonImg>
                <ButtonImgMobile><img src={rightArrowDownIcon} alt={'add to brochure'} /></ButtonImgMobile>
                <div>Add to Brochure</div>
              </div>
            </ListButton>
            <ListButton
              onClick={handleCheckedLeft}
              disabled={rightChecked.length === 0 || props.disabled}
              aria-label="move selected left"
            >
              <div>
                <ButtonImg><img src={leftArrowIcon} alt={'remove from brochure'} /></ButtonImg>
                <ButtonImgMobile><img src={leftArrowUpIcon} alt={'remove from brochure'} /></ButtonImgMobile>
                <div>Remove from Brochure</div>
              </div>
            </ListButton>
          </ButtonContainer>
        </ButtonCol>
        <Col>
          <ColumnHeaderContainer>
            <ColumnHeader>Items displayed on<br /> {customAliases.alias_program.toLowerCase()} brochure</ColumnHeader>
            <ColumnHeader>Shown as Included or<br />Not Included in {customAliases.alias_program.toLowerCase()}</ColumnHeader>
          </ColumnHeaderContainer>
          {CustomList(`Items displayed on ${customAliases.alias_program.toLowerCase()} brochure`, right, 'right')}
        </Col>
      </Row>
    </Grid>
  );
}
