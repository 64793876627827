import React, { useState } from 'react';
import { compose } from 'recompose';
import propTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import ViaModal from '../../../../components/library/viaModal';
import Headline from '../../../../components/library/headline';
import Button from '../../../../components/library/button';
import Paragraph from '../../../../components/library/paragraph';
import { getPlan, patchPlanUser } from '../../../../../actions/plans';
import sRemoveGroupLead from './removeGroupLead.scss';

export const enhance = compose(withStyles(sRemoveGroupLead));

function RemoveGroupLead(props) {
  const dispatch = useDispatch();
  const [saving, setSaving] = useState(false);

  const removeLeads = () => {
    setSaving(true);
    const submitData = { plans_user:
        { group_lead: false }
    };

    props.planUsers.forEach((user, index, array) => {
      setTimeout(() => {
        dispatch(patchPlanUser(user, submitData));
      }, 500 * index);
    });

    setTimeout(() => {
      setSaving(false);
      dispatch(getPlan(props.planId));
      props.onClose(false);
    }, 500 * (props.planUsers.length + 1));
  };

  return (
    <>
      <ViaModal
        showing={props.show}
        onClose={() => props.onClose(false)}
      >
        <div className={sRemoveGroupLead['remove-group-lead']}>
          <div className={sRemoveGroupLead.subHeader}>
            <Headline centered tag="h1" as="h1">
              Remove Group Leader Access
            </Headline>
          </div>
          <div className={sRemoveGroupLead.content}>
            <Paragraph size="large">
              You are about to remove Group Leader access from one or more Travelers. They will no longer be able to:
            </Paragraph>
          </div>
          <div className={sRemoveGroupLead['content-list']}>
            <span>
              <ul>
                <li>View all Travelers on this plan</li>
                <li>View Traveler Itineraries on this plan</li>
                <li>Add, edit, or remove travel details in the Group Itinerary</li>
                <li>Remove Travelers from this plan</li>
                <li>Message Travelers on this plan</li>
                <li>Cancel this plan</li>
              </ul>
            </span>
          </div>
          <div className={sRemoveGroupLead.footer}>
            <div className={sRemoveGroupLead['footer-confirm']}>
              <Headline tag="h2" as="h3" centered>
                Would you still like to remove Group Leader access?
              </Headline>
            </div>
            <div className={sRemoveGroupLead['footer-button-wrapper']}>
              <div className={sRemoveGroupLead['button-content']}>
                <Button
                  display="primary"
                  kind="solid"
                  size="medium"
                  type="submit"
                  onClick={() => removeLeads()}
                  loading={saving}
                  disabled={saving}
                >
                  Yes, Remove Access
                </Button>
              </div>
              <div className={sRemoveGroupLead['button-content']}>
                <Button
                  display="secondary"
                  kind="solid"
                  size="medium"
                  onClick={() => {
                    props.onClose(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </div>
      </ViaModal>
    </>
  )
}

RemoveGroupLead.propTypes = {
  show: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
  planId: propTypes.string.isRequired,
  planUsers: propTypes.array,
};

export default enhance(RemoveGroupLead);
