import { compose } from 'redux';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import LoadingScreen from '../../../styledComponents/styles/LoadingScreen';
import userId from '../../../shared/helpers/userId';
import loadedFonts from '../../../shared/fonts/loadFonts.css';
import { themeVia } from '../../../styledComponents/settings/theme';
import { useThemeColors } from '../../../shared/hooks/useThemeColors';
import GlobalStyles from '../../../styledComponents/styles/GlobalStyles';
import { getTravelerPrograms } from '../actions/programDashboardActions';
import { getCurrentOrganization } from '../actions/currentOrganizationActions';
import { getCurrentUser, getTravelerProfile, getInboundOrganization } from '../actions/travelerProfileActions';
import { getTravelerApplicationSubmissions } from '../actions/travelerApplicationsActions';
import ErrorScreen from '../../forms/pages/ErrorScreen';
import { Cookies } from 'react-cookie';

function Layout({ children }) {
  const dispatch = useDispatch();
  const isBrandingSet = useThemeColors();
  const { programsLoading } = useSelector(state => state.programDashboard);
  const { travelerProfileLoading } = useSelector(state => state.travelerProfile);
  const { currentOrganizationLoading } = useSelector(state => state.currentOrganization);
  const { clientFeatureList, currentUser } = useSelector(state => state.profile);
  const { applicationSubmissionsLoading, applicationSubmissionsError } = useSelector(
    state => state.travelerApplicationSubmissions,
  );
  const travelerId = useSelector(state => state.profile.profile[0].profile.user_id);

  const [loading, setLoading] = useState(true);
  const [showErrorPage, setShowErrorPage] = useState(false);

  const cookies = new Cookies();
  const token = cookies.get('token');

  useEffect(() => {
    if (!userId) {
      window.location.assign('/clear_cookies');
    }

    if (token && userId) {
      dispatch(getTravelerProfile(userId, 'traveler'));
      dispatch(getCurrentOrganization());
      dispatch(getCurrentUser(userId));
      dispatch(getTravelerPrograms());
      dispatch(getTravelerApplicationSubmissions(travelerId));
    }
  }, []);

  useEffect(() => {
    let unmounted = false;
    if (!unmounted && token && userId && clientFeatureList?.inbound && currentUser?.via_international) {
      dispatch(getInboundOrganization(userId));
    }
    return () => {
      unmounted = true;
    };
  }, [clientFeatureList?.inbound, currentUser?.via_international]);

  useEffect(() => {
    if (applicationSubmissionsError) {
      setShowErrorPage(true);
    } else {
      const authLoadingCheck = token && userId;
      const baseInfoLoadingCheck =
        travelerProfileLoading || currentOrganizationLoading || programsLoading || applicationSubmissionsLoading;

      setLoading(authLoadingCheck ? baseInfoLoadingCheck : false);
    }
  }, [
    travelerProfileLoading,
    currentOrganizationLoading,
    programsLoading,
    applicationSubmissionsLoading,
    applicationSubmissionsError,
  ]);

  return (
    <ThemeProvider theme={themeVia}>
      <GlobalStyles />
      {!showErrorPage && (
        <div>
          {isBrandingSet && !loading ? children : <LoadingScreen displayText="Loading..." width="100%" height="50vh" />}
        </div>
      )}
      {showErrorPage && (
        <div>
          <ErrorScreen />
        </div>
      )}
    </ThemeProvider>
  );
}

export default compose(withStyles(loadedFonts))(Layout);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};
