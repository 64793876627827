import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import ProfileFormContainer from '../base/ProfileFormContainer';
import ProfileFormSubheading from '../base/ProfileFormSubheading';
import AdditionalInfoForm from '../forms/personal/AdditionalInfoForm';
import ToastMessage from '../../../viaGlobal/components/toastMessage';
import NameAndIdentityForm from '../forms/personal/NameAndIdentityForm';
import { ProfileFormButton, ProfileFormButtonContainer } from '../base/ProfileFormButton.styled';

import userId from '../../../../shared/helpers/userId';
import { updateTravelerProfile } from '../../actions/travelerProfileActions';

export default function PersonalTab() {
  const dispatch = useDispatch();

  const [showToast, setShowToast] = useState(false);

  const [profileForm, setProfileForm] = useState({
    last_name: '',
    first_name: '',
    middle_name: '',
  });

  const [identityInfoForm, setIdentityInfoForm] = useState({
    title: '',
    gender: '',
    pronoun: '',
    gender_identity_id: '',
    preferred_last_name: '',
    preferred_first_name: '',
    gender_identity_additional_text: '',
  });

  const [additionalInfoForm, setAdditionalInfoForm] = useState({
    dob: null,
    race: '',
    ethnicity: '',
    residency: '',
    disability: '',
    veteran_status: '',
  });

  const handleSubmit = event => {
    event.preventDefault();

    const identityInfoPayload = {
      ...identityInfoForm,
      title: identityInfoForm.title.value,
      gender: identityInfoForm.gender.value,
      gender_identity_id: identityInfoForm.gender_identity_id.value,
    };

    const additionalInfoPayload = {
      ...additionalInfoForm,
      race: additionalInfoForm.race.value,
      ethnicity: additionalInfoForm.ethnicity.value,
      residency: additionalInfoForm.residency.value,
      veteran_status: additionalInfoForm.veteran_status.value,
    };

    dispatch(
      updateTravelerProfile(userId, {
        profile: { ...profileForm },
        travelerProfile: { ...identityInfoPayload, ...additionalInfoPayload },
      }),
    );

    setShowToast(true);
  };

  return (
    <form method="POST" onSubmit={handleSubmit}>
      <ProfileFormContainer heading="Personal Information">
        <div>
          <div>
            <ProfileFormSubheading text="Name &amp; Identity" />
            <NameAndIdentityForm
              profileForm={profileForm}
              setProfileForm={setProfileForm}
              infoForm={identityInfoForm}
              setInfoForm={setIdentityInfoForm}
            />
          </div>
          <div>
            <ProfileFormSubheading text="Additional Information" />
            <AdditionalInfoForm form={additionalInfoForm} setForm={setAdditionalInfoForm} />
          </div>
        </div>
        <ProfileFormButtonContainer>
          <ProfileFormButton type="submit">Save</ProfileFormButton>
        </ProfileFormButtonContainer>
        {showToast && (
          <ToastMessage
            message="All changes saved"
            isSuccess
            show={showToast}
            onClose={() => {
              setShowToast(false);
            }}
          />
        )}
      </ProfileFormContainer>
    </form>
  );
}
