import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import { useDispatch, useSelector } from 'react-redux';
import propTypes from 'prop-types';
import _get from 'lodash/get';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { fetchOrganization } from '../../../../actions/authActions';
import { setOrgInfo } from '../../../../actions/orgSettingsActions';
import { getContrastColor } from '../../../../shared/helpers/General';
import sStyles from './eventPublicWrapper.scss';

export const enhance = compose(withStyles(sStyles));

function EventPublicWrapper(props) {
  const { children } = props;
  const dispatch = useDispatch();
  const organization = useSelector(state => state.auth.organization);
  const urlDomain = window.location.hostname.split('.')[0];

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      dispatch(fetchOrganization(urlDomain));
    }
    return () => {
      unmounted = true;
    };
  }, []);

  useEffect(
    () => {
      if (!!organization && organization.id) {
        const logo = _get(organization, 'info.logo.url') || '';
        const accentColor = _get(organization, 'branding_theme.themes.theme_color_accent') || '#dd2160';
        const darkColor = _get(organization, 'branding_theme.themes.theme_color_dark') || '#043544';
        const lightColor = _get(organization, 'branding_theme.themes.theme_color_light') || '#76ccce';
        document.documentElement.style.setProperty('--theme_color_dark', darkColor);
        document.documentElement.style.setProperty('--theme_color_accent', accentColor);
        document.documentElement.style.setProperty('--theme_color_light', lightColor);

        const setting = {
          logo: logo,
          accentColor: accentColor,
          accentTextColor: getContrastColor(accentColor),
          darkColor: darkColor,
          darkTextColor: getContrastColor(darkColor),
          lightColor: lightColor,
          lightTextColor: getContrastColor(lightColor),
          alias: {
            alias_enrollment: _get(organization, 'info.alias_enrollment') || 'Enrollment',
            alias_favorite: _get(organization, 'info.alias_favorite') || 'Favorite',
            alias_program: _get(organization, 'info.alias_program') || 'Program',
            alias_programs: _get(organization, 'info.alias_programs') || 'Programs',
            alias_traveler: _get(organization, 'info.alias_traveler') || 'Traveler',
            alias_travelers: _get(organization, 'info.alias_travelers') || 'Travelers',
            alias_traveling: _get(organization, 'info.alias_traveling') || 'Traveling',
          }
        }
        dispatch(setOrgInfo(setting));
      }
    },
    [organization],
  );

  return (
    <div className={sStyles.wrapper}>
      {children}
    </div>
  );

}

EventPublicWrapper.propTypes = {
  children: propTypes.node,
};

export default enhance(EventPublicWrapper);
