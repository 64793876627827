import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import CloseIcon from '../../icons/CloseIcon';
import { Spinner } from '../../../../../styledComponents/styles/spinner.styled';

import { buttonFocus } from '../../../utils/shared/focusStyles';
import ScreenReaderText from '../../base/ScreenReaderText.styled';

const Container = styled.div`
  position: absolute;
  top: 120%;
  z-index: 20;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: ${props => props.minWidth || '350px'};
  min-height: 359px;
  padding: 1rem 1.0625rem 1.6875rem 1.0625rem;
  background-color: #ffffff;
  border: 1px solid #d7d7d7;
  border-radius: 0px 0px 3px 3px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);

  ${props => {
    if (props.right) {
      return `
        right: ${props.right};
      `;
    }
  }};
`;

const SpinnerContainer = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 5px;
  right: 10px;
  padding: 0;
  background-color: transparent;
  border: none;

  svg {
    width: 13px;
    height: 13px;
  }

  &:focus {
    outline: none;
  }

  &:focus-visible {
    svg {
      outline: 2px solid #255a94;
      outline-offset: 1px;
    }
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: ${props => (props.justifyButtonsEnd ? 'flex-end' : 'space-between')};
  align-items: center;

  ${props => {
    if (props.justifyButtonsEnd) {
      return `
        gap: 6.875rem;
      `;
    }
  }};
`;

const ClearAllButton = styled.button`
  background-color: transparent;
  border: none;
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  color: #373737;
  letter-spacing: 0.015em;
  text-decoration-line: underline;
  line-height: 120%;

  ${buttonFocus};
`;

const FilterButton = styled.button`
  padding: 0.6875rem 2.875rem;
  background-color: var(--theme_color_accent);
  border: none;
  border-radius: 5px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 1rem;
  color: var(--theme_color_accent_font);
  letter-spacing: 0.06em;
  line-height: 120%;

  &:hover {
    opacity: 0.8;
  }

  ${buttonFocus};
`;

export default function ProgramFilterDropdown({
  right,
  loading,
  children,
  minWidth,
  handleClose,
  handleFilter,
  handleClearAll,
  justifyButtonsEnd,
  readerLabel,
}) {
  const [isClearFilter, setIsClearFilter] = useState(false);
  const handleKeyDown = event => {
    if (event.key === 'Escape') {
      const button = document.getElementById('close_filter_menu_button');

      button?.focus();
    }
  };
  
  const handleBlur = () => {
    if(isClearFilter) {
      setIsClearFilter(false);
    }
  }

  useEffect(()=>{
    document.getElementById("filterHeading").focus();
  },[])

  return (
    <Container onKeyDown={handleKeyDown} minWidth={minWidth} right={right}>
      <ScreenReaderText id="filterHeading" tabIndex={-1} role="alertdialog" aria-live='assertive'>{readerLabel}</ScreenReaderText>
      {loading && (
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      )}
      {!loading && (
        <>
          <CloseButton id="close_filter_menu_button" type="button" aria-label="Close filter menu" onClick={handleClose}>
            <CloseIcon fillColor="#373737" />
          </CloseButton>
          {children}
          <ButtonsContainer justifyButtonsEnd={justifyButtonsEnd}>
            <ClearAllButton type="button" aria-label={isClearFilter ? "Filter is cleared" : "Clear All"} onClick={()=> {
              setIsClearFilter(true); 
              handleClearAll();
            }}
            onBlur={handleBlur}
            >
              Clear All
            </ClearAllButton>
            <FilterButton type="button" onClick={handleFilter}>
              Filter
            </FilterButton>
          </ButtonsContainer>
        </>
      )}
    </Container>
  );
}

ProgramFilterDropdown.propTypes = {
  loading: PropTypes.bool,
  right: PropTypes.string,
  minWidth: PropTypes.string,
  handleClose: PropTypes.func,
  justifyButtonsEnd: PropTypes.bool,
  children: PropTypes.node.isRequired,
  handleFilter: PropTypes.func.isRequired,
  handleClearAll: PropTypes.func.isRequired,
};

ProgramFilterDropdown.defaultProps = {
  right: '',
  minWidth: '',
  loading: false,
  handleClose: () => {},
  justifyButtonsEnd: false,
};
