exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@-webkit-keyframes q8cqE{to{-webkit-transform:rotate(1turn)}}@keyframes q8cqE{to{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}._3q6H1 .-qCkY{-ms-flex:1 1;flex:1 1;-ms-flex-direction:row;flex-direction:row;display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;padding:16px}@media only screen and (max-width:599px){._3q6H1 .-qCkY{-ms-flex-direction:column;flex-direction:column}}._3q6H1 .jBgJl{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;padding:40px 100px;-ms-flex-pack:center;justify-content:center}._3q6H1 .jBgJl .Fw9zO{padding:12px}._3q6H1 .jBgJl p{margin:20px}._3q6H1 ._3zHMx{-ms-flex:1 1;flex:1 1;-ms-flex-direction:column;flex-direction:column;display:-ms-flexbox;display:flex;border-top:2px solid #ebebeb;padding:32px 20px 0 0;-ms-flex-pack:center;justify-content:center}@media only screen and (max-width:599px){._3q6H1 ._3zHMx{-ms-flex-direction:column;flex-direction:column}}._3q6H1 ._3zHMx ._21auo{padding:20px 0;display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-pack:center;justify-content:center}._3q6H1 ._3zHMx ._21auo ._1QaJQ{padding-left:12px}@media only screen and (max-width:599px){._3q6H1 ._3zHMx ._21auo ._1QaJQ{padding-top:12px}}._3q6H1 ._3zHMx ._3zIRL{-ms-flex:1 1;flex:1 1}", ""]);

// exports
exports.locals = {
	"remove-program-from-plan": "_3q6H1",
	"subHeader": "-qCkY",
	"content": "jBgJl",
	"toggle": "Fw9zO",
	"footer": "_3zHMx",
	"footer-button-wrapper": "_21auo",
	"button-content": "_1QaJQ",
	"footer-confirm": "_3zIRL",
	"spin": "q8cqE"
};