import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import moment from 'moment-timezone';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import styles from './index.module.css';
import { Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import toastMessageStyles from '../../sites/viaGlobal/components/toastMessage/toastMessage.scss';
import { toast } from 'react-toastify';
import DatePickerDropDownInput from '../../sites/travelerForms/components/base/inputs/DatePickerDropDownInput';
import { Controller, useForm } from 'react-hook-form';
import { updatePlanFormsToPlans } from '../../actions/formsActions';
import WarningModalContent from '../WarningModalContent';
import BaseModalHeader from '../baseModal/BaseModalHeader';
import BaseModalFooter from '../baseModal/BaseModalFooter';

function UpdatePlanFormsToPlanModal({ show, handleClose, templateID, handleUpdatePlan, deadline, handleDeleteModal }) {
  const dispatch = useDispatch();
  const data = useSelector(state => state.forms);
  const [formValues, setFormValues] = useState(null);
  const [showWarningContent, setShowWarningContent] = useState(false);

  const { control, handleSubmit, watch } = useForm({
    values: {
      formDeadline: null,
    },
  });

  const onSubmit = data => {
    setFormValues(data);
    setShowWarningContent(true);
  };

  const onSubmitForm = () => {
    let payload = {
      id: templateID,
      deadline: moment(formValues.formDeadline).format('YYYY/MM/DD')
    };
    
    dispatch(
      updatePlanFormsToPlans(payload, (status, message) => {
        if (status) {
          toast(message, {
            type: 'success',
            className: toastMessageStyles['successContainer'],
            toastClassName: toastMessageStyles['wrapper'],
            bodyClassName: toastMessageStyles['successBody'],
            progressClassName: toastMessageStyles['progress-bar'],
          });
          handleUpdatePlan();
          handleClose(); 

        } else {
          toast(message, {
            type: 'error',
            className: toastMessageStyles['failureContainer'],
            toastClassName: toastMessageStyles['wrapper'],
            bodyClassName: toastMessageStyles['failureBody'],
            progressClassName: toastMessageStyles['progress-bar'],
          });
        }
      }),
    );
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} centered size={showWarningContent ? 'md' : 'lg'}>
        {showWarningContent ? (
          <WarningModalContent
            text={`Editing the form deadline will change the form deadline for all traveler(s) on this plan.`}
            textBold={`Are you sure you want to continue?`}
            onCancel={() => setShowWarningContent(false)}
            onSubmit={onSubmitForm}
            loadingBtn={data?.addPlanFormsToPlans?.loading}
          />
        ) : (
          <>
            <BaseModalHeader heading={'Edit Plan Form'} onClose={handleClose} />
            <Modal.Body className={styles.modalBody}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Container className={styles.container}>
                  <div className={styles.text}>
                    Edit the form deadline or delete this form from all traveler(s) on this travel plan.
                  </div>
                 
                  <div>
                    <Controller
                      control={control}
                      name="formDeadline"
                      render={({ field: { onChange, value } }) => (
                        <DatePickerDropDownInput label={'Set Form Deadline'} onChange={onChange} defaultValue={deadline} value={value} />
                      )}
                    />
                  </div>
                </Container>
                <BaseModalFooter handleDeleteModal={handleDeleteModal} isDeleteButton={true} mainBtnType="submit" mainBtnText="Update" isBtnGroupRight={true} onCancel={handleClose} />
              </form>
            </Modal.Body>
          </>
        )}
      </Modal>
    </>
  );
}

export default withStyles(styles)(UpdatePlanFormsToPlanModal);
