import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import MobileProfileMenu from './MobileProfileMenu';

import { breakpoints } from '../../utils/breakpoints';

const NavWrapper = styled.nav`
  width: 100%;
  max-width: 60.375rem;

  @media ${breakpoints.desktop} {
    ${props => {
      if (props.autoWidth) {
        return `
          width: auto;
        `;
      }
    }};
  }
`;

const NavListContainer = styled.div`
  display: none;
  width: 100%;
  max-width: 100%;

  @media ${breakpoints.desktop} {
    display: flex;
    align-items: center;
  }

  ul {
    display: flex;
    width: 100%;
    padding-left: 0;
    border-bottom: 1px solid var(--secondary);
    border-radius: 1px;
    list-style-type: none;

    @media (min-width: 1100px) and (max-width: 1265px) {
      ${props => {
        if (props.tabCount > 3) {
          return `
            justify-content: space-between;
            gap: 0;
          `;
        }
      }};
    }

    @media ${breakpoints.desktop} {
      ${props => {
        if (props.containerBottomBorder) {
          return `
            margin-bottom: 0.46875rem;
          `;
        }
      }};
    }

    li {
      display: inline-flex;
      align-items: center;
      align-self: stretch;
      border-bottom: ${props => (props.individualBorders ? '2px solid #ebecf0' : 'none')};

      a {
        display: inline-flex;
        padding: 0.125rem 0.125rem 0.625rem;
        background-color: transparent;
        font-family: AvenirNextRegular, sans-serif;
        font-weight: 400;
        font-size: 16px;
        // color: var(--secondary);
        color: #373737;
        line-height: 114%;
        transform: translateY(0.125rem);
        padding: 8px 10px 10px 10px;

        &:hover,
        &.active {
          // border-bottom: 1px solid var(--gray);
          border-bottom: 1px solid #255a94;
          // color: var(--secondary);
          color: #255a94;
          text-decoration: none;
        }

        &:focus {
          outline: none;
        }

        &:focus-visible {
          padding: 0 0 0.5rem;
          border: 2px solid #255a94;
          border-radius: 2px;
        }
      }
    }
  }
`;

export default function ProfileMenu({
  tabs,
  label,
  autoWidth,
  currentTab,
  urlPathSection,
  individualBorders,
  containerBottomBorder,
  isAdmin,
}) {
  const handleLinkClick = () => {
    localStorage.setItem('scrollY', window.scrollY);
  };

  const buildUrl = tab => {
    let path = `/traveler/${urlPathSection}/${tab.path}`;
    if (isAdmin) {
      const pathname = window.location.pathname.split('/');
      const travelerId = pathname[3];
      path = `/client/travelers/${travelerId}/${urlPathSection}/${tab.path}`;
    }
    return path;
  };

  return (
    <NavWrapper aria-label={label} autoWidth={autoWidth} containerBottomBorder={containerBottomBorder}>
      <NavListContainer
        tabCount={tabs.length}
        individualBorders={individualBorders}
        containerBottomBorder={containerBottomBorder}
      >
        <ul>
          {tabs.map(tab => (
            <li key={`menu-tab-${tab.id}`}>
              <NavLink exact to={buildUrl(tab)} onClick={handleLinkClick}>
                {tab.title}
              </NavLink>
            </li>
          ))}
        </ul>
      </NavListContainer>
      <MobileProfileMenu
        tabs={tabs}
        currentTab={currentTab}
        label={label}
        urlPathSection={urlPathSection}
        isAdmin={isAdmin}
      />
    </NavWrapper>
  );
}

ProfileMenu.propTypes = {
  autoWidth: PropTypes.bool,
  urlPathSection: PropTypes.string,
  individualBorders: PropTypes.bool,
  label: PropTypes.string.isRequired,
  containerBottomBorder: PropTypes.bool,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string,
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      component: PropTypes.string.isRequired,
    }),
  ),
  currentTab: PropTypes.shape({
    path: PropTypes.string,
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    component: PropTypes.string.isRequired,
  }).isRequired,
  isAdmin: PropTypes.bool,
};

ProfileMenu.defaultProps = {
  tabs: [],
  autoWidth: false,
  individualBorders: false,
  urlPathSection: 'profile',
  containerBottomBorder: false,
  isAdmin: false,
};
