exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._39YGe{color:#373737;font-family:Open Sans;font-size:16px;font-style:normal;font-weight:600;line-height:120%;letter-spacing:.96px;border:none;padding:0;background:transparent}", ""]);

// exports
exports.locals = {
	"btn": "_39YGe"
};