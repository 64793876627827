export default function programBrochureTabs(programAlias) {
  return [
    {
      title: `${programAlias} Overview`,
      path: '',
      component: 'programOverview',
    },
    {
      title: `About ${programAlias}`,
      path: 'about-program',
      component: 'aboutProgram',
    },
    {
      title: 'Academics',
      path: 'academics',
      component: 'academics',
    },
    {
      title: 'Term Information',
      path: 'term-information',
      component: 'termInformation',
    },
    {
      title: `What's Included`,
      path: 'whats-included',
      component: 'whatsIncluded',
    },
    {
      title: 'Eligibility',
      path: 'eligibility',
      component: 'eligibility',
    },
    {
      title: 'Cost & Funding',
      path: 'cost-and-funding',
      component: 'costAndFunding',
    },
    {
      title: 'Resources',
      path: 'resources',
      component: 'resources',
    },
  ]
}
