import React, { useEffect } from 'react';
import { compose } from 'recompose';
import { useSelector } from 'react-redux';
import propTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { Link } from 'react-router-dom';
import AccessDenied from '../../../../../../../sites/viaGlobal/components/pages/accessDenied';
import ViaDate from '../../../../../../components/library/viaDate';
import Paragraph from '../../../../../../components/library/paragraph';
import HousingItemView from '../../../../itineraryItems/housingItemView';
import ActivityItemView from '../../../../itineraryItems/activityItemView';
import TransportationItemView from '../../../../itineraryItems/transportationItemView';
import sAccordion from '../../../../../../../../public/css/accordion.scss';
import sOvItineraryByDay from './ovItineraryByDay.scss';
import Headline from '../../../../../../components/library/headline';

export const enhance = compose(withStyles(sOvItineraryByDay, sAccordion));

function OvItineraryByDay(props) {
  const selectedPlan = useSelector(state => state.plans.plan);
  const permissions = useSelector(state => state.profile.permissions);
  const currentUser = useSelector(state => state.profile.currentUser);
  let link = '';

  switch (props.as) {
    case 'admin':
      link = `/plans/group-itinerary/${props.planId}`;
      break;
    case 'traveler':
      link = `/plans/my-itinerary/${selectedPlan.plan.id}`;
      break;
    case 'leader':
      link = `/plans/leader-group-itinerary/${props.planId}`;
      break;
    case '':
      link = '';
      break;
  }

  let itineraryItems = null;

  if (props.byGroup) {
    itineraryItems = selectedPlan.group_itinerary_byDate;
  } else {
    itineraryItems = selectedPlan.traveler_itinerary_byDate;
  }

  let expandAll = ['start_date', 'end_date'];

  useEffect(() => {
    const accordianPanels = document.querySelectorAll(".accordion__panel"); 
    for(let i = 0; i < accordianPanels.length; i++){
      accordianPanels[i].role = "contentinfo"; 
    }
  }, []); 


  return (currentUser['client_user_role?'] && permissions.travel_plans_settings?.includes('view_travelers')) ||
    currentUser['is_traveler?'] ? (
    <div className={sOvItineraryByDay['ov-itinerary-by-day']}>
      {!!selectedPlan &&
        !!selectedPlan.plan && (
          <>
            <Accordion allowMultipleExpanded allowZeroExpanded preExpanded={expandAll}>
              {!!itineraryItems &&
                Object.getOwnPropertyNames(itineraryItems) &&
                Object.getOwnPropertyNames(itineraryItems).length === 0 && (
                  <>
                    <AccordionItem uuid="start_date">
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          <div className="wrapper-line-center">
                            <Headline style={{
                              fontFamily:"IntroBoldItalic, sans-serif",
                              fontSize:"24px",
                              lineHeight:"32px"
                            }} tag="div">
                              <span className="line-center">
                                <ViaDate date={selectedPlan.plan.attributes.start_date} />
                                &nbsp;&nbsp;
                              </span>
                            </Headline>
                          </div>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        {link.length > 0 && !selectedPlan.plan.attributes.removed ? (
                          <Paragraph>
                            You arrive this day. <Link to={link}>Build your itinerary</Link> to add flights, housing,
                            and activities.
                          </Paragraph>
                        ) : (
                          <Paragraph>You arrive this day.</Paragraph>
                        )}
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem uuid="end_date">
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          <div className="wrapper-line-center">
                            <Headline style={{
                              fontFamily:"IntroBoldItalic, sans-serif",
                              fontSize:"24px",
                              lineHeight:"32px"
                            }} tag="div">
                              <span className="line-center">
                                <ViaDate date={selectedPlan.plan.attributes.end_date} />
                                &nbsp;&nbsp;
                              </span>
                            </Headline>
                          </div>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        {link.length > 0 && !selectedPlan.plan.attributes.removed ? (
                          <Paragraph>
                            You depart this day. <Link to={link}>Build your itinerary</Link> to add flights, housing,
                            and activities.
                          </Paragraph>
                        ) : (
                          <Paragraph>You depart this day.</Paragraph>
                        )}
                      </AccordionItemPanel>
                    </AccordionItem>
                  </>
                )}
              {!!itineraryItems &&
                Object.keys(itineraryItems).map(date => {
                  expandAll.push(date);
                  return (
                    <AccordionItem uuid={date} key={date}>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          <div className="wrapper-line-center">
                            <Headline style={{
                              fontFamily:"IntroBoldItalic, sans-serif",
                              fontSize:"24px",
                              lineHeight:"32px"
                            }} tag="div">
                              <span className="line-center">
                                <ViaDate date={date} />
                                &nbsp;&nbsp;
                              </span>
                            </Headline>
                          </div>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        {itineraryItems[date].map(itin => (
                          <div key={itin.id}>
                            {itin.type === 'plan_housing' && (
                              <HousingItemView
                                key={itin.id}
                                detail={itin}
                                planId={selectedPlan.plan.id}
                                hideEdit
                                hideRemove
                              />
                            )}
                            {itin.type === 'plan_activity' && (
                              <ActivityItemView
                                key={itin.id}
                                detail={itin}
                                planId={selectedPlan.plan.id}
                                readOnly
                                hideEdit
                                hideRemove
                              />
                            )}
                            {itin.type === 'plan_transportation' && (
                              <TransportationItemView
                                key={itin.id}
                                detail={itin}
                                planId={selectedPlan.plan.id}
                                hideEdit
                                hideRemove
                              />
                            )}
                          </div>
                        ))}
                      </AccordionItemPanel>
                    </AccordionItem>
                  );
                })}
            </Accordion>
          </>
        )}
    </div>
  ) : (
    <AccessDenied />
  );
}

OvItineraryByDay.propTypes = {
  byGroup: propTypes.bool,
  as: propTypes.oneOf(['admin', 'traveler', 'leader', '']),
};

OvItineraryByDay.defaultProps = {
  byGroup: false,
};

export default enhance(OvItineraryByDay);
