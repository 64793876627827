import React, { Component } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './ProgramOrganization.css';
import ReactHtmlParser from 'react-html-parser';

class ProgramOrganization extends Component {
  render() {
    return (
      <div className={s.wrapper}>
        <div className={s.section}>
          <h2 className={s.header}>{this.props.provider}</h2>
          {this.props.role == 'Admin' &&
          (this.props.status == 'edit' || this.props.status == 'new') &&
            <p className={s.contactHeader}>
              Admins with permission can edit organization information
              in organization settings.
            </p>
          }
          <p className={s.description}>{ReactHtmlParser(this.props.organizationDescription)}</p>
        </div>
      </div>
    );
  }
}

export default withStyles(s)(ProgramOrganization);
