import React, { lazy, Suspense } from 'react';
import { Controller } from 'react-hook-form';
import TextInput from '../../../../../../styledComponents/styles/TextInput.styled';
import { FIELD_NAMES } from './form.properties';
import { InputContainer } from '../../../../../../styledComponents/styles/Containers.styled';
import _get from 'lodash/get';
const EditTextArea = lazy(() => import('../../../../../../styledComponents/styles/EditTextArea.styled'));

export const ProgramNameInput = ({ isValid, control, errors, customAliases, userPermissions, isSaving, ...props }) => {
  const ProgramNameTooltip = `Field is required to publish
    ${customAliases.alias_program}. Name can be up to 60 characters and can create
    excitement or give ${customAliases.alias_traveler}
    quick info on ${customAliases.alias_program}.
  `;

  return (
    <Controller
      control={control}
      name={FIELD_NAMES['title']}
      render={({ field: { ref, ...rest } }) => (
        <InputContainer>
          <TextInput
            disabled={!userPermissions.canEdit || isSaving}
            icon={!userPermissions.canEdit || isSaving ? 'LockClosed' : ''}
            errorMsg={_get(errors.title, 'message') || ''}
            id={FIELD_NAMES['title']}
            label={`${customAliases.alias_program} Name`}
            required
            type={'text'}
            tooltip={ProgramNameTooltip}
            {...rest}
          />
        </InputContainer>
      )}
    />
  );
};

export const ProgramInfoInput = ({ control, customAliases, userPermissions, isSaving }) => {
  return (
    <Controller
      control={control}
      name={FIELD_NAMES['programInformation']}
      render={({ field: { ref, ...rest } }) => (
        <Suspense fallback={<div />}>
          <EditTextArea
            icon={!userPermissions.canEdit || isSaving ? 'LockClosed' : ''}
            id={FIELD_NAMES['programInformation']}
            label={`${customAliases.alias_program} Information`}
            readOnly={!userPermissions.canEdit || isSaving}
            {...rest}
          />
        </Suspense>
      )}
    />
  );
};

export const ProgramDescriptionInput = ({ control, customAliases, userPermissions, isSaving }) => {
  return (
    <Controller
      control={control}
      name={FIELD_NAMES['programDescription']}
      render={({ field: { ref, ...rest } }) => (
        <Suspense fallback={<div />}>
          <EditTextArea
            icon={!userPermissions.canEdit || isSaving ? 'LockClosed' : ''}
            readOnly={!userPermissions.canEdit || isSaving}
            id={FIELD_NAMES['programDescription']}
            label={`${customAliases.alias_program} Description`}
            {...rest}
          />
        </Suspense>
      )}
    />
  );
};

export const ExternalLinkInput = ({ isValid, control, errors, customAliases, userPermissions, isSaving, ...props }) => {
  return (
    <Controller
      control={control}
      name={FIELD_NAMES['external_application_url']}
      render={({ field: { ref, ...rest } }) => (
        <InputContainer>
          <TextInput
            disabled={!userPermissions.canEdit || isSaving}
            icon={!userPermissions.canEdit || isSaving ? 'LockClosed' : ''}
            errorMsg={_get(errors.external_application_url, 'message') || ''}
            id={FIELD_NAMES['external_application_url']}
            label={`Enter external link`}
            type={'text'}
            {...rest}
          />
        </InputContainer>
      )}
    />
  );
};