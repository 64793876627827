import React from 'react';
import { useSelector } from 'react-redux';

export default function Locations() {
  const { locations } = useSelector(state => state.programBrochure.location);
  const getLocationDisplayName = (location) => {
    // if (location.locality) {
    //   return location.locality;
    // } else 
    if (location.city && location.country_common_name) {
      return `${location.city}, ${location.country_common_name}`;
    } else if (location.country_common_name) {
      return `${location.country_common_name}`;
    } 
    return location.formatted_address || "Unknown Location";
  };

  return <p>{locations.map(location =>getLocationDisplayName(location)).join('; ')}</p>;
}
