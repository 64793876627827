import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import { useDispatch, useSelector } from 'react-redux';
import propTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import ViaModal from '../../../../components/library/viaModal';
import Headline from '../../../../components/library/headline';
import Button from '../../../../components/library/button';
import Paragraph from '../../../../components/library/paragraph';
import sRemoveProgramFromPlan from './removeProgramFromPlan.scss';
import { patchPlan } from '../../../../../actions/plans';

export const enhance = compose(withStyles(sRemoveProgramFromPlan));

function RemoveProgramFromPlan(props) {
  const dispatch = useDispatch();
  const [saving, setSaving] = useState(false);
  const saveStatus = useSelector(state => state.plans.plan);
  const customAliases = useSelector(state => state.profile.customAliases);

  useEffect(
    () => {
      if (saveStatus && !saveStatus.loading) {
        setSaving(false);
        props.onClose(false);
      }
    },
    [saveStatus],
  );

  useEffect(
    () => {
      if (saving) {
        dispatch(
          patchPlan(props.planId, {
            program_range_id: null,
          }),
        );
      }
    },
    [saving],
  );

  return (
    <>
      <ViaModal showing={props.show} onClose={() => props.onClose(false)}>
        <div className={sRemoveProgramFromPlan['remove-program-from-plan']}>
          <div className={sRemoveProgramFromPlan.subHeader}>
            <Headline centered tag="h1" as="h1">
              Remove {customAliases.alias_program} from this Plan?
            </Headline>
          </div>
          <div className={sRemoveProgramFromPlan.content}>
            <Headline centered tag="h2" as="h2">
              If removed:
            </Headline>
            <Paragraph centered size="large">
              Travelers with a committed application will still appear in the Who's Going Tab.
            </Paragraph>
          </div>

          <div className={sRemoveProgramFromPlan.footer}>
            <div className={sRemoveProgramFromPlan['footer-button-wrapper']}>
              <div className={sRemoveProgramFromPlan['button-content']}>
                <Button
                  display="secondary"
                  kind="solid"
                  size="medium"
                  onClick={() => {
                    props.onClose(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
              <div className={sRemoveProgramFromPlan['button-content']}>
                <Button
                  display="primary"
                  kind="solid"
                  size="medium"
                  type="submit"
                  onClick={() => {
                    setSaving(true);
                  }}
                >
                  Remove {customAliases.alias_program} Association
                </Button>
              </div>
            </div>
          </div>
        </div>
      </ViaModal>
    </>
  );
}

RemoveProgramFromPlan.propTypes = {
  show: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
  planId: propTypes.string.isRequired,
};

export default enhance(RemoveProgramFromPlan);
