import React from 'react';
import styled from 'styled-components';

import { breakpoints } from '../../utils/breakpoints';
import { buttonFocus } from '../../utils/shared/focusStyles';

export const Header = styled.header`
  position: sticky;
  top: 0;
  z-index: 50;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  // padding: 1.625rem 2.5rem 1.8125rem 2.6875rem;
  padding: 1.625rem 0.75rem 1.8125rem 0.75rem; 
  align-items: center;
  box-shadow: 0 0.25rem 0.9375rem 0 rgba(0, 0, 0, 0.1);
  gap: 1rem;

  .section {
    display: flex;
    align-items: center;
    &--left {
      gap: 1.5625rem;
    }
    &--right {
      gap: 1.3125rem;

      @media ${breakpoints.tablet} {
        gap: 1.875rem;
      }

      @media ${breakpoints.desktop} {
        gap: 6.875rem;
      }
    }
  }

  @media ${breakpoints.tablet} {
    padding: 1rem 4.375rem 1rem 3rem;
    gap: 3.5rem;
  }
`;

export const HeaderLabel = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 0.9rem;
  color: #373737;
  background-color:#ffffff;
  margin-bottom: 0;
  padding:5px;
  display: none;
  @media ${breakpoints.tablet} {
     display: inline;
  }
`;

export const HeaderActionDiv = styled.div`
  p {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 0.9rem;
    color: #373737;
    background-color:#ffffff;
    margin-bottom: 0;
    display: none;
  }

  @media ${breakpoints.tablet} {
    display: flex;
    align-items: center;

    p {
      display: inline;
    }
  }

  .share-btn {
    &--desktop {
      display: none;
      @media ${breakpoints.tablet} {
        display: inline-block;
      }
    }
    &--mobile {
      @media ${breakpoints.tablet} {
        display: none;
      }
    }
  }
`;

export const HeaderIconButton = styled.button`
  background-color: transparent;
  background-image: none;
  border: 2px solid transparent;
  border-radius: 2px;
  box-shadow: ${props => (props.open ? '0 0.3125rem 0.625rem 0 rgba(55, 55, 55, 0.25)' : 'none')};

  ${buttonFocus};
`;
