import PropTypes from 'prop-types';
import { orderBy, uniq } from 'lodash';
import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';

import ProfileSelectInput from '../../../base/inputs/ProfileSelectInput';

export default function ProgramNameFilter({ value, handleOnChange }) {
  const { programs, selectedFilters } = useSelector(state => state.programDashboard);

  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (selectedFilters.programName.length > 0) {
      handleOnChange(selectedFilters.programName);
    }
  }, []);

  useEffect(() => {
    if (programs?.length > 0) {
      const options = programs
        .filter(program => program?.attributes?.program_name)
        .filter(program => program?.attributes?.private?.toLowerCase() !== 'yes')
        .map(program => program?.attributes?.program_name);

      const orderedOptions = orderBy(uniq(options), option => option.toLowerCase(), ['asc']).map(option => ({
        label: option,
        value: option,
      }));

      setOptions(orderedOptions);
    }
  }, [programs]);

  return (
    <ProfileSelectInput
      id="program_name"
      value={value}
      label="Program Name"
      placeholder="Select Program Name(s)"
      options={options}
      isMulti
      handleOnChange={handleOnChange}
    />
  );
}

ProgramNameFilter.propTypes = {
  handleOnChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]).isRequired,
};
