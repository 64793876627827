import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import { useDispatch, useSelector } from 'react-redux';
import propTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import styles from './index.module.css';
import { Modal } from 'react-bootstrap';
import BaseModalHeader from '../../../../../components/baseModal/BaseModalHeader';
import BaseModalFooter from '../../../../../components/baseModal/BaseModalFooter';
import { Controller, useForm } from 'react-hook-form';
import SelectInput from '../../../../travelerForms/components/base/inputs/SelectInput';
// import toastMessageStyles from '../../../../../sites/viaGlobal/components/toastMessage/toastMessage.scss';
// import { toast } from 'react-toastify';
import WarningModalContent from '../../../../../components/WarningModalContent';
import { getSubmissionStatuses } from '../../../../../actions/formsActions';
import { updatePlanFormStatuses } from '../../../../../actions/reportsActions';
import ToastMessage from '../../toastMessage';

export const enhance = compose(withStyles(styles));

function BatchUpdatePlanFormStatus(props) {
  const dispatch = useDispatch();
  const submissionStatuses = useSelector(state => state.forms.submission_statuses);
  const planFormStatus = useSelector(state => state.reports.updatePlanFormStatus);
  const customAliases = useSelector(state => state.profile.customAliases);
  const [planFormStatusOptions, setPlanFormStatusOptions] = useState([]);
  const [formValues, setFormValues] = useState(null);
  const [showWarningContent, setShowWarningContent] = useState(false);
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);
  const [saving, setSaving] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [failureMessage, setFailureMessage] = useState('');
  const { control, handleSubmit, reset } = useForm({
    values: {
      plan_form_status_id: '',
    },
  });

  useEffect(() => {
    dispatch(getSubmissionStatuses('plan_form'));
  }, []);

  useEffect(() => {
    if (!submissionStatuses.data) {
      dispatch(getSubmissionStatuses('plan_form'));
    } else {
      setPlanFormStatusOptions(
        submissionStatuses.data.map(item => {
          return { label: item.attributes.name, value: item.id };
        }),
      );
    }
  }, [submissionStatuses.data]);

  useEffect(() => {
    if (planFormStatus) {
      if (planFormStatus.loading) {
        setSaving(true);
      }

      if (!planFormStatus.loading && !!planFormStatus.data) {
        if (planFormStatus.data) {
          setSaving(false);
          setSuccessMessage(planFormStatus.data);
          setSuccess(true);
          setShowWarningContent(false);
          reset();
        }
        if (planFormStatus.error) {
          setSaving(false);
          setFailureMessage(planFormStatus.error);
          setFailure(true);
          setShowWarningContent(false);
          reset();
        }
      }
    }
    // resetForm();
    props.onClose(false);
  }, [planFormStatus]);

  const onSubmit = data => {
    const submitValues = {
      submission_ids: props.submissionIds,
      plan_form_status_id: data.plan_form_status_id,
    };

    setFormValues(submitValues);
    setShowWarningContent(true);
  };

  const onSubmitForm = () => {
    let payload = {
      submission_ids: props.submissionIds,
      submission_status_id: formValues.plan_form_status_id.value,
      type: 'plan_form',
    };

    dispatch(updatePlanFormStatuses(payload, props.selectedTemplateId, null, false));
  };

  return (
    <>
      <Modal show={props.show} onHide={props.onClose} centered size={showWarningContent ? 'md' : 'lg'}>
        {showWarningContent ? (
          <WarningModalContent
            text={`You are about to update the status of ${props.submissionIds.length} plan form${
              props.submissionIds.length > 1 ? '(s)' : ''
            }. ${
              customAliases.alias_travelers
            } and default message recipients will receive email notifications of this change.`}
            textBold={`Are you sure you sure you want to continue?`}
            onCancel={() => setShowWarningContent(false)}
            onSubmit={onSubmitForm}
            loadingBtn={saving}
          />
        ) : (
          <>
            <BaseModalHeader heading={'Update Plan Form Status'} onClose={props.onClose} />
            <Modal.Body className={styles.modelBody}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className={styles.container}>
                  <div className={styles.text}>
                    Select a plan form status from the drop down to update the selected form
                    {props?.submissionIds?.length > 0 ? '(s)' : ''}.
                  </div>

                  <div>
                    <Controller
                      control={control}
                      name="plan_form_status_id"
                      rules={{
                        required: 'Plan form status is required',
                      }}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <SelectInput
                          label="Plan Status"
                          placeholder={'Select plan form status'}
                          required={true}
                          options={planFormStatusOptions}
                          value={value}
                          handleOnChange={onChange}
                          isMulti={false}
                          error={error?.message}
                        />
                      )}
                    />
                  </div>
                </div>

                <BaseModalFooter
                  mainBtnType="submit"
                  mainBtnText="Update"
                  isBtnGroupRight={true}
                  onCancel={props.onClose}
                />
              </form>
            </Modal.Body>
          </>
        )}
      </Modal>

      {success ? (
        <ToastMessage
          message={successMessage}
          show={success}
          isSuccess
          newestOnTop={false}
          onClose={() => {
            setSuccess(false);
          }}
        />
      ) : null}
      {failure ? (
        <ToastMessage
          message={failureMessage}
          isSuccess={false}
          show={failure}
          newestOnTop={false}
          onClose={() => {
            setFailure(false);
          }}
        />
      ) : null}
    </>
  );
}

BatchUpdatePlanFormStatus.propTypes = {
  show: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
};

export default enhance(BatchUpdatePlanFormStatus);
