import React, { useEffect, useState } from 'react';
import { Formik, Field, Form } from 'formik';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import propTypes from 'prop-types';
import moment from 'moment-timezone';
import _get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import HousingTypeSelect from '../../selects/housingTypeSelect';
import Button from '../../../../../sites/components/library/button';
import InputTimePicker from '../../../../../sites/components/library/inputTimePicker';
import InputTextArea from '../../../../../sites/components/library/inputTextArea';
import InputText from '../../../../../sites/components/library/inputText';
import InputDate from '../../../../../sites/components/library/inputDate';
import { addDetail, getPlan, patchDetail } from '../../../../../actions/plans';
import EditDetailSuccessModal from '../../modals/editDetailSuccessModal';
import GroupAddDetailSuccessModal from '../../modals/groupAddDetailSuccessModal';
import LocationSearch from '../../../../components/library/locationSearch';
import Paragraph from '../../../../components/library/paragraph';
import sHousingForm from './housingForm.scss';
import ScreenReaderText from '../../../../travelerProfile/components/base/ScreenReaderText.styled';

function HousingForm(props) {
  const dispatch = useDispatch();
  const planDetail = useSelector(state => state.plans.addDetail);
  const [showScreenReader, setShowScreenReader] = useState(false);
  const isEdit = props.isEdit;
  const defaultTimezone = '11'; // Mountain time (US & Canada)
  const [saving, setSaving] = useState(false);
  const [success, setSuccess] = useState(false);
  const [newSuccess, setNewSuccess] = useState(false);
  const [housingLocation, setHousingLocation] = useState();
  const [checked, setChecked] = useState(props.details ? props.details.force_add : false);
  const [defaultValues, setDefaultValues] = useState(() => {
    const lat = _get(props, 'details.location.lat') || '';
    const lng = _get(props, 'details.location.lng') || '';
    const google_place_id = _get(props, 'details.location.google_place_id') || '';
    const location = {
      id: _get(props, 'details.location.id') || '',
      formatted_address: _get(props, 'details.location.formatted_address') || '',
      google_place_id: google_place_id,
      country_alpha2_code: _get(props, 'details.location.country_alpha2_code') || '',
      country_common_name: _get(props, 'details.location.country_common_name') || '',
      county_or_region: _get(props, 'details.location.county_or_region') || '',
      locality: _get(props, 'details.location.locality') || '',
      postal_code: _get(props, 'details.location.postal_code') || '',
      postal_code_suffix: _get(props, 'details.location.postal_code_suffix') || '',
      state_or_province: _get(props, 'details.location.state_or_province') || '',
      state_or_province_code: _get(props, 'details.location.state_or_province_code') || '',
      street: _get(props, 'details.location.street') || '',
      street_number: _get(props, 'details.location.street_number') || '',
      lat: lat,
      lng: lng,
      time_zone: _get(props, 'details.location.time_zone') || '',
      time_zone_offset: _get(props, 'details.location.time_zone_offset') || '',
      mapUrl: `https://www.google.com/maps/search/?api=1&query=${lat},${lng}&query_place_id=${google_place_id}`,
    };

    setHousingLocation(null);
    setHousingLocation(location);

    return {
      nickname: _get(props, 'details.nickname') || '',
      tp_housing_type_id: _get(props, 'details.tp_housing_type_id') || '',
      tp_housing_type_name: _get(props, 'details.tp_housing_type_name') || 'Select detail type...',
      name: _get(props, 'details.name') || '',
      confirmation_number: _get(props, 'details.confirmation_number') || '',
      room_number: _get(props, 'details.room_number') || '',
      phone_number: _get(props, 'details.phone_number') || '',
      website: _get(props, 'details.website') || '',
      email_address: _get(props, 'details.email_address') || '',
      arrival_date: _get(props, 'details.arrival_date') || '',
      arrival_time: _get(props, 'details.arrival_time') || '',
      arrival_tp_time_zone_id: defaultTimezone,
      departure_date: _get(props, 'details.departure_date') || '',
      departure_time: _get(props, 'details.departure_time') || '',
      departure_tp_time_zone_id: defaultTimezone,
      line_1: _get(props, 'details.tp_address.line_1') || '',
      line_2: _get(props, 'details.tp_address.line_2') || '',
      city: _get(props, 'details.tp_address.city') || '',
      state_or_province: _get(props, 'details.tp_address.state_or_province') || '',
      zip_code: _get(props, 'details.tp_address.zip_code') || '',
      via_country_id: _get(props, 'details.tp_address.via_country_id') || '',
      via_country_name: _get(props, 'details.tp_address.via_country_name') || '',
      notes: _get(props, 'details.notes') || '',
      group: _get(props, 'details.group', props.group),
      removed: _get(props, 'details.removed', false),
      force_add: _get(props, 'details.force_add', checked),
      location_attributes: location,
    };
  });

  useEffect(() => {
    if (planDetail) {
      if (planDetail.loading) {
        setSaving(true);
      }

      if (saving && !!planDetail.detail) {
        setSaving(false);

        if (planDetail.detail.attributes.group && isEdit) {
          setSuccess(true);
        } else if (planDetail.detail.attributes.group && !isEdit) {
          setNewSuccess(true);
        } else {
          // dispatch(getPlan(props.planId));
          props.onClose(false);
        }
      }

      if (saving && !!planDetail.error) {
        setSaving(false);
        // alert(planDetail.error);
      }
    }
  }, [planDetail]);

  const customStyles = {
    placeholder: (provided, state) => ({
      ...provided,
      color: 'black',
    }),
  };

  return (
    <>
      {success ? (
        <EditDetailSuccessModal
          onClose={() => {
            props.onClose(false);
          }}
        />
      ) : newSuccess ? (
        <GroupAddDetailSuccessModal
          planId={props.planId}
          onClose={() => {
            props.onClose(false);
          }}
        />
      ) : (
        <div className={sHousingForm['housing-form']}>
          <Formik
            enableReinitialize
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={defaultValues}
            validate={values => {
              const errors = {};

              setShowScreenReader(false);

              values.notes = values.notes.trim();
              values.departure_date = moment(values.departure_date).isValid()
                ? moment(values.departure_date).format('YYYY-MM-DD')
                : '';
              values.arrival_date = moment(values.arrival_date).isValid()
                ? moment(values.arrival_date).format('YYYY-MM-DD')
                : '';

              if (!values.tp_housing_type_id) {
                errors.tp_housing_type_id = 'Housing Type Required';
              }
              if (!values.name) {
                errors.name = 'Housing Name Required';
              }
              if (!values.arrival_date) {
                errors.arrival_date = 'Arrival Date Required';
              }
              if (!values.arrival_time) {
                errors.arrival_time = 'Arrival Time Required';
              }
              if (!values.departure_date) {
                errors.departure_date = 'Departure Date Required';
              }
              if (values.departure_date && moment(values.departure_date).isBefore(moment(values.arrival_date), 'day')) {
                errors.departure_date = 'End Date must be after Start Date';
              }
              if (!values.departure_time) {
                errors.departure_time = 'Departure Time Required';
              }

              if (!values.location_attributes.lat || !values.location_attributes.lng) {
                errors.location_attributes = 'Select an address';
              }

              if (Object.keys(errors).length > 0) {
                setTimeout(() => {
                  setShowScreenReader(true);
                }, 1000);
              }

              return errors;
            }}
            onSubmit={values => {
              const submitValues = {
                plan_housing: {
                  nickname: values.nickname,
                  tp_housing_type_id: values.tp_housing_type_id,
                  name: values.name,
                  confirmation_number: values.confirmation_number,
                  room_number: values.room_number,
                  phone_number: values.phone_number,
                  website: values.website,
                  email_address: values.email_address,
                  arrival_date: values.arrival_date,
                  arrival_time: moment(values.arrival_time).isValid()
                    ? moment(values.arrival_time).format('h:mm A')
                    : values.arrival_time,
                  arrival_tp_time_zone_id: values.arrival_tp_time_zone_id,
                  departure_date: values.departure_date,
                  departure_time: moment(values.departure_time).isValid()
                    ? moment(values.departure_time).format('h:mm A')
                    : values.departure_time,
                  departure_tp_time_zone_id: values.departure_tp_time_zone_id,
                  location_attributes: {
                    id: values.location_attributes.id,
                    country_alpha2_code: values.location_attributes.country_alpha2_code,
                    country_common_name: values.location_attributes.country_common_name,
                    county_or_region: values.location_attributes.county_or_region,
                    formatted_address: values.location_attributes.formatted_address,
                    google_place_id: values.location_attributes.google_place_id,
                    lat: values.location_attributes.lat,
                    locality: values.location_attributes.locality,
                    lng: values.location_attributes.lng,
                    postal_code: values.location_attributes.postal_code,
                    postal_code_suffix: values.location_attributes.postal_code_suffix,
                    state_or_province: values.location_attributes.state_or_province,
                    state_or_province_code: values.location_attributes.state_or_province_code,
                    street: values.location_attributes.street,
                    street_number: values.location_attributes.street_number,
                    time_zone: values.location_attributes.time_zone,
                    time_zone_offset: values.location_attributes.time_zone_offset,
                  },
                  notes: values.notes,
                  group: values.group,
                  removed: values.removed,
                  force_add: checked,
                },
              };

              if (isEdit) {
                dispatch(patchDetail(props.planId, props.itemId, submitValues, 'plan_housings'));
              } else {
                dispatch(addDetail(props.planId, submitValues, 'plan_housings'));
              }
            }}
          >
            {({ values, handleSubmit, errors, handleChange }) => (
              <Form onSubmit={handleSubmit}>
                <div className={sHousingForm.subHeader}>
                  <Paragraph italic centered>
                    Please complete all required fields
                  </Paragraph>

                  {showScreenReader && (
                    <ScreenReaderText>
                      {/* Rendering error messages in a list */}
                      {Object.keys(errors).length > 0 && (
                        <div role="alert" aria-live="assertive">
                          <ul>
                            {Object.keys(errors).map((key, index) => (
                              <li key={index}>{errors[key]}</li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </ScreenReaderText>
                  )}
                </div>

                <div className={sHousingForm.divider}>
                  <Paragraph italic>Housing Details</Paragraph>
                </div>

                <div className={sHousingForm.newrow}>
                  <div className={sHousingForm.item}>
                    <Field name="nickname">
                      {({ field, form }) => (
                        <InputText
                          id="nickname"
                          name="nickname"
                          labelText="Detail Nickname"
                          value={values.nickname}
                          onChange={e => {
                            form.setFieldValue('nickname', e.target.value);
                          }}
                          placeholder="Add a custom name for this detail..."
                        />
                      )}
                    </Field>
                  </div>
                </div>
                <div className={sHousingForm.newrow}>
                  <div className={sHousingForm.item}>
                    <Field name="tp_housing_type_id">
                      {({ field, form }) => (
                        <HousingTypeSelect
                          labelText="Housing Detail Type"
                          isRequired
                          adjustedWidth="48%"
                          id="tp_housing_type_id"
                          name="tp_housing_type_id"
                          value={field.tp_housing_type_id}
                          styles={customStyles}
                          placeholder={values.tp_housing_type_name}
                          errorMessage={errors.tp_housing_type_id}
                          onChange={e => {
                            form.setFieldValue('tp_housing_type_id', e.value);
                          }}
                        />
                      )}
                    </Field>
                  </div>
                </div>
                <div className={sHousingForm.newrow}>
                  <div className={sHousingForm.item}>
                    <Field name="arrival_date">
                      {({ field, form }) => (
                        <InputDate
                          labelText="Arrival Date"
                          id="arrival_date"
                          name="arrival_date"
                          isRequired
                          errorMessage={errors.arrival_date}
                          value={field.arrival_date}
                          onChange={e => {
                            form.setFieldValue('arrival_date', e);
                          }}
                          placeholder={
                            values.arrival_date ? moment(values.arrival_date).format('MMM D, YYYY') : 'Select Date...'
                          }
                        />
                      )}
                    </Field>
                  </div>
                  <div className={sHousingForm.item}>
                    <Field name="arrival_time">
                      {({ field, form }) => (
                        <InputTimePicker
                          labelText="Arrival Time"
                          aria-label="Arrival Time"
                          id="arrival_time"
                          name="arrival_time"
                          isRequired
                          value={values.arrival_time ? moment(values.arrival_time, 'h:mm A') : null}
                          errorMessage={errors.arrival_time}
                          onChange={e => form.setFieldValue('arrival_time', e)}
                          helpText="Time zone added based on location information"
                        />
                      )}
                    </Field>
                  </div>
                </div>

                <div className={sHousingForm.newrow}>
                  <div className={sHousingForm.item}>
                    <Field name="departure_date">
                      {({ field, form }) => (
                        <InputDate
                          labelText="Departure Date"
                          errorMessage={errors.departure_date}
                          id="departure_date"
                          name="departure_date"
                          isRequired
                          value={field.departure_date}
                          onChange={e => {
                            form.setFieldValue('departure_date', e);
                          }}
                          placeholder={
                            values.departure_date
                              ? moment(values.departure_date).format('MMM D, YYYY')
                              : 'Select Date...'
                          }
                        />
                      )}
                    </Field>
                  </div>
                  <div className={sHousingForm.item}>
                    <Field name="departure_time">
                      {({ field, form }) => (
                        <InputTimePicker
                          labelText="Departure Time"
                          aria-label="Departure Time"
                          id="departure_time"
                          name="departure_time"
                          isRequired
                          value={values.departure_time ? moment(values.departure_time, 'h:mm A') : null}
                          errorMessage={errors.departure_time}
                          onChange={e => form.setFieldValue('departure_time', e)}
                          helpText="Time zone added based on location information"
                        />
                      )}
                    </Field>
                  </div>
                </div>
                <div className={sHousingForm.newrow}>
                  <div className={sHousingForm.item}>
                    <Field name="name">
                      {({ form }) => (
                        <InputText
                          isRequired
                          id="name"
                          name="name"
                          labelText="Housing Name"
                          value={values.name}
                          errorMessage={errors.name}
                          onChange={e => {
                            form.setFieldValue('name', e.target.value);
                          }}
                          placeholder="Add the name of the hotel, hostel, apartment complex, etc..."
                        />
                      )}
                    </Field>
                  </div>
                </div>

                <div className={sHousingForm.newrow}>
                  <div className={sHousingForm['item-address']}>
                    <Field name="location_attributes">
                      {({ form }) => (
                        <LocationSearch
                          id="location_attributes"
                          name="location_attributes"
                          isRequired
                          labelText="Address Search"
                          placeholder="Type to select a city and country, or address..."
                          searchType="address"
                          selectHandler={e => {
                            if (!e.loading) {
                              values.location_attributes.formatted_address = e.formatted_address;
                              values.location_attributes.google_place_id = e.google_place_id;
                              values.location_attributes.country_alpha2_code = e.country_alpha2_code;
                              values.location_attributes.country_common_name = e.country_common_name;
                              values.location_attributes.county_or_region = e.county_or_region;
                              values.location_attributes.locality = e.locality;
                              values.location_attributes.postal_code = e.postal_code;
                              values.location_attributes.postal_code_suffix = e.postal_code_suffix;
                              values.location_attributes.state_or_province = e.state_or_province;
                              values.location_attributes.state_or_province_code = e.state_or_province_code;
                              values.location_attributes.street = e.street;
                              values.location_attributes.street_number = e.street_number;
                              values.location_attributes.lat = e.lat.toString();
                              values.location_attributes.lng = e.lng.toString();
                              values.location_attributes.time_zone = e.time_zone;
                              values.location_attributes.time_zone_offset = e.time_zone_offset;
                              values.location_attributes.mapUrl = e.mapUrl;

                              setHousingLocation(null);
                              setHousingLocation(values.location_attributes);
                            }
                          }}
                          onChange={handleChange}
                          errorMessage={errors.location_attributes}
                          value={values.location_attributes}
                        />
                      )}
                    </Field>
                  </div>
                  <div className={sHousingForm.item}>
                    <Paragraph size="small" bold>
                      Selected Address
                    </Paragraph>
                    {!housingLocation ||
                    !housingLocation.formatted_address ||
                    housingLocation.formatted_address.length === 0 ? (
                      <Paragraph size="small" italic>
                        Please search for your housing address.
                      </Paragraph>
                    ) : (
                      <>
                        <Paragraph size="small">Address: {housingLocation.formatted_address}</Paragraph>
                        <Paragraph size="small">Timezone: {housingLocation.time_zone}</Paragraph>
                        <Paragraph size="small">
                          <a href={housingLocation.mapUrl} rel="noopener noreferrer" target="_blank" className={sHousingForm.mapLink}>
                            See it on a map
                          </a>
                        </Paragraph>
                      </>
                    )}
                  </div>
                </div>

                <div className={sHousingForm.divider}>
                  <Paragraph italic>Additional Housing Information</Paragraph>
                </div>

                <div className={sHousingForm.newrow}>
                  <div className={sHousingForm.item}>
                    <Field name="phone_number">
                      {({ field, form }) => (
                        <InputText
                          labelText="Phone #"
                          id="phone_number"
                          name="phone_number"
                          value={values.phone_number}
                          onChange={e => {
                            form.setFieldValue('phone_number', e.target.value);
                          }}
                        />
                      )}
                    </Field>
                  </div>
                </div>

                <div className={sHousingForm.newrow}>
                  <div className={sHousingForm.item}>
                    <Field name="email_address">
                      {({ form }) => (
                        <InputText
                          labelText="Email"
                          id="email_address"
                          name="email_address"
                          value={values.email_address}
                          onChange={e => {
                            form.setFieldValue('email_address', e.target.value);
                          }}
                        />
                      )}
                    </Field>
                  </div>
                </div>

                <div className={sHousingForm.newrow}>
                  <div className={sHousingForm.item}>
                    <Field name="website">
                      {({ form }) => (
                        <InputText
                          labelText="Website"
                          id="website"
                          name="website"
                          value={values.website}
                          onChange={e => {
                            form.setFieldValue('website', e.target.value);
                          }}
                        />
                      )}
                    </Field>
                  </div>
                </div>

                <div className={sHousingForm.newrow}>
                  <div className={sHousingForm.item}>
                    <Field name="confirmation_number">
                      {({ form }) => (
                        <InputText
                          labelText="Confirmation #"
                          id="confirmation_number"
                          name="confirmation_number"
                          value={values.confirmation_number}
                          onChange={e => {
                            form.setFieldValue('confirmation_number', e.target.value);
                          }}
                        />
                      )}
                    </Field>
                  </div>
                </div>

                <div className={sHousingForm.newrow}>
                  <div className={sHousingForm.item}>
                    <Field name="room_number">
                      {({ form }) => (
                        <InputText
                          labelText="Room #"
                          id="room_number"
                          name="room_number"
                          value={values.room_number}
                          onChange={e => {
                            form.setFieldValue('room_number', e.target.value);
                          }}
                        />
                      )}
                    </Field>
                  </div>
                </div>

                <div className={sHousingForm.newrow}>
                  <div className={sHousingForm.item}>
                    <Field name="notes">
                      {({ field, form }) => (
                        <InputTextArea
                          id="notes"
                          name="notes"
                          labelText="Notes"
                          value={values.notes}
                          onChange={e => {
                            form.setFieldValue('notes', e.target.value);
                          }}
                        />
                      )}
                    </Field>
                  </div>
                </div>

                {props.group ? (
                  <div className={sHousingForm.row}>
                    <div className={sHousingForm.item}>
                      <Field name="force_add">
                        {({ field, form }) => (
                          <div
                            className={
                              isEdit ? sHousingForm['disabled-force-add-checkbox'] : sHousingForm['force-add-checkbox']
                            }
                          >
                            <input
                              type="checkbox"
                              id="force_add"
                              name="force_add"
                              value={values.force_add}
                              readOnly={isEdit ? true : false}
                              onClick={!isEdit ? () => setChecked(!checked) : null}
                              checked={checked}
                            />
                            <label htmlFor="force_add">
                              Add this detail to all Travelers added to this Group Plan, now and in the future
                            </label>
                          </div>
                        )}
                      </Field>
                    </div>
                  </div>
                ) : null}

                <div className={sHousingForm['button-row']}>
                  <div className={sHousingForm['button-content']}>
                    <Button
                      display="secondary"
                      kind="solid"
                      size="medium"
                      onClick={() => {
                        props.onClose(false);
                      }}
                      ariaLabel="Cancel"
                    >
                      Cancel
                    </Button>
                  </div>
                  <div className={sHousingForm['button-content']}>
                    <Button display="primary" kind="solid" size="medium" type="submit" ariaLabel={isEdit ? "Save" : "Add"}>
                      {isEdit ? 'Save' : 'Add'}
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </>
  );
}

HousingForm.propTypes = {
  planId: propTypes.string.isRequired,
  onClose: propTypes.func,
  group: propTypes.bool,
};

HousingForm.defaultProps = {
  group: false,
};

export default withStyles(sHousingForm)(HousingForm);
