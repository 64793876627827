import React from 'react';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import QuestionHeaderAddEdit from './header/question.header.addEdit';
import QuestionHeaderDisplayForm from './header/question.header.display.form';
import QuestionMultiLineAddEdit from './multiLineText/question.multiLine.addEdit';
import QuestionMultiLineDisplayForm from './multiLineText/question.multiLine.display.form';
import QuestionParagraphAddEdit from './paragraph/question.paragraph.addEdit';
import QuestionParagraphDisplayForm from './paragraph/question.paragraph.display.form';
import QuestionRecommendationAddEdit from './recommendation/question.recommendation.addEdit';
import QuestionRecommendationDisplayForm from './recommendation/question.recommendation.display.form';
import QuestionShortTextAddEdit from './shortText/question.shortText.addEdit';
import QuestionShortTextDisplayForm from './shortText/question.shortText.display.form';
import QuestionSignatureAddEdit from './signature/question.signature.addEdit';
import QuestionSignatureDisplayForm from './signature/question.signature.display.form';
import QuestionHeaderDisplayPreview from './header/question.header.display.preview';
import QuestionParagraphDisplayPreview from './paragraph/question.paragraph.display.preview';
import QuestionShortTextDisplayPreview from './shortText/question.shortText.display.preview';
import QuestionSignatureDisplayPreview from './signature/question.signature.display.preview';
import QuestionMultiLineDisplayPreview from './multiLineText/question.multiLine.display.preview';
import QuestionRecommendationDisplayPreview from './recommendation/question.recommendation.display.preview';
import QuestionVideoPromptAddEdit from './videoPrompt/question.videoPrompt.addEdit';
import QuestionVideoPromptDisplayForm from './videoPrompt/question.videoPrompt.display.form';
import QuestionVideoPromptDisplayPreview from './videoPrompt/question.videoPrompt.display.preview';
import QuestionDropdownAddEdit from './dropdown/question.dropdown.addEdit';
import QuestionDropdownDisplayForm from './dropdown/question.dropdown.display.form';
import QuestionDropdownDisplayPreview from './dropdown/question.dropdown.display.preview';
import QuestionFileUploadAddEdit from './fileUpload/question.fileUpload.addEdit';
import QuestionFileUploadDisplayForm from './fileUpload/question.fileUpload.display.form';
import QuestionFileUploadDisplayPreview from './fileUpload/question.fileUpload.display.preview';
import QuestionFileDownloadAddEdit from './fileDownload/question.fileDownload.addEdit';
import QuestionFileDownloadDisplayForm from './fileDownload/question.fileDownload.display.form';
import QuestionFileDownloadDisplayPreview from './fileDownload/question.fileDownload.display.preview';
import QuestionMultipleChoiceAddEdit from './multipleChoice/question.multipleChoice.addEdit';
import QuestionMultipleChoiceDisplayForm from './multipleChoice/question.multipleChoice.display.form';
import QuestionMultipleChoiceDisplayPreview from './multipleChoice/question.multipleChoice.display.preview';
import QuestionDatePickerAddEdit from './datePicker/question.datePicker.addEdit';
import QuestionDatePickerDisplayForm from './datePicker/question.datePicker.display.form';
import QuestionDatePickerDisplayPreview from './datePicker/question.datePicker.display.preview';
import QuestionStarRatingAddEdit from './starRating/question.starRating.addEdit';
import QuestionStarRatingDisplayForm from './starRating/question.starRating.display.form';
import QuestionStarRatingDisplayPreview from './starRating/question.starRating.display.preview';
import QuestionSingleChoiceAddEdit from './singleChoice/question.singleChoice.addEdit';
import QuestionSingleChoiceDisplayForm from './singleChoice/question.singleChoice.display.form';
import QuestionSingleChoiceDisplayPreview from './singleChoice/question.singleChoice.display.preview';
import QuestionCashnetAddEdit from './cashnet/question.cashnet.addEdit';
import QuestionCashnetDisplayForm from './cashnet/question.cashnet.display.form';
import QuestionCashnetDisplayPreview from './cashnet/question.cashnet.display.preview';
import QuestionCashnetCodeAddEdit from './cashnetCode/question.cashnetCode.addEdit';
import QuestionCashnetCodeDisplayForm from './cashnetCode/question.cashnetCode.display.form';
import QuestionCashnetCodeDisplayPreview from './cashnetCode/question.cashnetCode.display.preview';
import QuestionFlywireAddEdit from './flywire/question.flywire.addEdit';
import QuestionFlywireDisplayForm from './flywire/question.flywire.display.form';
import QuestionFlywireDisplayPreview from './flywire/question.flywire.display.preview';
import QuestionPayflowAddEdit from './payflow/question.payflow.addEdit';
import QuestionPayflowDisplayForm from './payflow/question.payflow.display.form';
import QuestionPayflowDisplayPreview from './payflow/question.payflow.display.preview';

import QuestionCybersourceAddEdit from './cybersource/question.cybersource.addEdit';
import QuestionCybersourceDisplayForm from './cybersource/question.cybersource.display.form';
import QuestionCybersourceDisplayPreview from './cybersource/question.cybersource.display.preview';

import QuestionQuikpayAddEdit from './quikpay/question.quikpay.addEdit';
import QuestionQuikpayDisplayForm from './quikpay/question.quikpay.display.form';
import QuestionQuikpayDisplayPreview from './quikpay/question.quikpay.display.preview';


import QuestionConvergepayAddEdit from './convergePay/question.convergepay.addEdit';
import QuestionConvergepayDisplayForm from './convergePay/question.convergepay.display.form';
import QuestionConvergepayDisplayPreview from './convergePay/question.convergepay.display.preview';


import QuestionTouchnetAddEdit from './touchnet/question.touchnet.addEdit';
import QuestionTouchnetDisplayForm from './touchnet/question.touchnet.display.form';
import QuestionTouchnetDisplayPreview from './touchnet/question.touchnet.display.preview';
import QuestionFeeAddEdit from './fee/question.fee.addEdit';
import QuestionFeeDisplayForm from './fee/question.fee.display.form';
import QuestionFeeDisplayPreview from './fee/question.fee.display.preview';
import QuestionTravelerInfoAddEdit from './travelerInfo/question.travelerInfo.addEdit';
import QuestionDividerAddEdit from './divider/question.divider.addEdit';
import QuestionDividerDisplay from './divider/question.divider.display';


function QuestionRender({
  onClose,
  question,
  type,
  formState,
  errors,
  response,
  childResponse,
  setValue,
  defaultValue,
  formId,
  cashNetCodes,
  control,
  inFormBuilder = false,
  registerOptions = null,
  isReadonly,
  isAdmin = false,
  isVisitor = false,
  isSynced = false,
  ...rest
}) {
  const renderSwitch = () => {
    if (type.toLowerCase() === 'add') {
      switch (question.identifier.toLowerCase()) {
        case 'header':
          return <QuestionHeaderAddEdit onClose={onClose} />;
        case 'paragraph':
          return <QuestionParagraphAddEdit onClose={onClose} />;
        case 'short_text':
          return <QuestionShortTextAddEdit onClose={onClose} />;
        case 'signature':
          return <QuestionSignatureAddEdit onClose={onClose} />;
        case 'long_text':
          return <QuestionMultiLineAddEdit onClose={onClose} />;
        case 'recommendation':
          return <QuestionRecommendationAddEdit onClose={onClose} formId={formId} />;
        case 'video':
          return <QuestionVideoPromptAddEdit onClose={onClose} />;
        case 'dropdown':
          return <QuestionDropdownAddEdit onClose={onClose} />;
        case 'file_upload':
          return <QuestionFileUploadAddEdit onClose={onClose} />;
        case 'file_download':
          return <QuestionFileDownloadAddEdit onClose={onClose} />;
        case 'multiple_choice':
          return <QuestionMultipleChoiceAddEdit onClose={onClose} />;
        case 'date':
          return <QuestionDatePickerAddEdit onClose={onClose} />;
        case 'star_rating':
          return <QuestionStarRatingAddEdit onClose={onClose} />;
        case 'single_choice':
          return <QuestionSingleChoiceAddEdit onClose={onClose} />;
        case 'cash_net':
          return <QuestionCashnetAddEdit onClose={onClose} />;
        case 'cash_net_with_code':
          return <QuestionCashnetCodeAddEdit onClose={onClose} />;
        case 'flywire':
          return <QuestionFlywireAddEdit onClose={onClose} />;
        case 'payflow':
          return <QuestionPayflowAddEdit onClose={onClose} />;
        case 'cybersource':
          return <QuestionCybersourceAddEdit onClose={onClose} />;
        case 'converge_pay':
          return <QuestionConvergepayAddEdit onClose={onClose} />;
        case 'quik_pay':
            return <QuestionQuikpayAddEdit onClose={onClose} />;  
        case 'touch_net':
          return <QuestionTouchnetAddEdit onClose={onClose} />;
        case 'fee':
          return <QuestionFeeAddEdit onClose={onClose} />;
        case 'traveler_info':
          return <QuestionTravelerInfoAddEdit onClose={onClose} />;
        case 'divider':
          return <QuestionDividerAddEdit onClose={onClose} />;
        default:
          return 'coming soon';
      }
    } else if (type.toLowerCase() === 'builder') {
      switch (question.question_type_identifier.toLowerCase()) {
        case 'header':
          return <QuestionHeaderDisplayPreview question={question} key={question.id} />;
        case 'paragraph':
          return <QuestionParagraphDisplayPreview question={question} key={question.id} />;
        case 'short_text':
          return <QuestionShortTextDisplayPreview question={question} key={question.id} />;
        case 'signature':
          return <QuestionSignatureDisplayPreview question={question} key={question.id} />;
        case 'long_text':
          return <QuestionMultiLineDisplayPreview question={question} key={question.id} />;
        case 'recommendation':
          return <QuestionRecommendationDisplayPreview question={question} key={question.id} formId={formId} />;
        case 'video':
          return <QuestionVideoPromptDisplayPreview question={question} key={question.id} />;
        case 'dropdown':
          return <QuestionDropdownDisplayPreview question={question} key={question.id} />;
        case 'file_upload':
          return <QuestionFileUploadDisplayPreview question={question} key={question.id} />;
        case 'file_download':
          return <QuestionFileDownloadDisplayPreview question={question} key={question.id} />;
        case 'multiple_choice':
          return <QuestionMultipleChoiceDisplayPreview question={question} key={question.id} />;
        case 'date':
          return <QuestionDatePickerDisplayPreview question={question} key={question.id} />;
        case 'star_rating':
          return <QuestionStarRatingDisplayPreview question={question} key={question.id} />;
        case 'single_choice':
          return <QuestionSingleChoiceDisplayPreview question={question} key={question.id} />;
        case 'cash_net':
          return <QuestionCashnetDisplayPreview question={question} key={question.id} isAdmin={isAdmin} />;
        case 'cash_net_with_code':
          return <QuestionCashnetCodeDisplayPreview question={question} key={question.id} isAdmin={isAdmin} />;
        case 'flywire':
          return <QuestionFlywireDisplayPreview question={question} key={question.id} isAdmin={isAdmin} />;
        case 'payflow':
          return <QuestionPayflowDisplayPreview question={question} key={question.id} isAdmin={isAdmin} />;
        case 'cybersource':
          return <QuestionCybersourceDisplayPreview question={question} key={question.id} isAdmin={isAdmin} />;
        case 'quik_pay':
          return <QuestionQuikpayDisplayPreview question={question} key={question.id} isAdmin={isAdmin} />;  
        case 'converge_pay':
          return <QuestionConvergepayDisplayPreview question={question} key={question.id} isAdmin={isAdmin} />;
        case 'touch_net':
          return <QuestionTouchnetDisplayPreview question={question} key={question.id} isAdmin={isAdmin} />;
        case 'fee':
          return <QuestionFeeDisplayPreview question={question} key={question.id} isAdmin={isAdmin} />;
        case 'divider':
          return <QuestionDividerDisplay question={question} key={question.id} isAdmin={isAdmin} />;
        default:
          return null;
      }
    } else if (type.toLowerCase() === 'edit') {
      switch (question.question_type_identifier.toLowerCase()) {
        case 'header':
          return <QuestionHeaderAddEdit question={question} edit onClose={() => {}} />;
        case 'paragraph':
          return <QuestionParagraphAddEdit question={question} edit onClose={() => {}} />;
        case 'short_text':
          return <QuestionShortTextAddEdit question={question} edit onClose={() => {}} />;
        case 'signature':
          return <QuestionSignatureAddEdit question={question} edit onClose={() => {}} />;
        case 'long_text':
          return <QuestionMultiLineAddEdit question={question} edit onClose={() => {}} />;
        case 'recommendation':
          return <QuestionRecommendationAddEdit question={question} edit onClose={() => {}} formId={formId} />;
        case 'video':
          return <QuestionVideoPromptAddEdit question={question} edit onClose={() => {}} />;
        case 'dropdown':
          return <QuestionDropdownAddEdit question={question} edit onClose={() => {}} />;
        case 'file_upload':
          return <QuestionFileUploadAddEdit question={question} edit onClose={() => {}} />;
        case 'file_download':
          return <QuestionFileDownloadAddEdit question={question} edit onClose={() => {}} />;
        case 'multiple_choice':
          return <QuestionMultipleChoiceAddEdit question={question} edit onClose={() => {}} />;
        case 'date':
          return <QuestionDatePickerAddEdit question={question} edit onClose={() => {}} />;
        case 'star_rating':
          return <QuestionStarRatingAddEdit question={question} edit onClose={() => {}} />;
        case 'single_choice':
          return <QuestionSingleChoiceAddEdit question={question} edit onClose={() => {}} />;
        case 'cash_net':
          return <QuestionCashnetAddEdit question={question} edit onClose={() => {}} />;
        case 'cash_net_with_code':
          return <QuestionCashnetCodeAddEdit question={question} edit onClose={() => {}} />;
        case 'flywire':
          return <QuestionFlywireAddEdit question={question} edit onClose={() => {}} />;
        case 'payflow':
          return <QuestionPayflowAddEdit question={question} edit onClose={() => {}} />;
        case 'cybersource':
          return <QuestionCybersourceAddEdit question={question} edit onClose={() => {}} />;
        case 'quik_pay':
          return <QuestionQuikpayAddEdit question={question} edit onClose={() => {}} />;
        case 'converge_pay':
          return <QuestionConvergepayAddEdit question={question} edit onClose={() => {}} />;
        case 'touch_net':
          return <QuestionTouchnetAddEdit question={question} edit onClose={() => {}} />;
        case 'fee':
          return <QuestionFeeAddEdit question={question} edit onClose={() => {}} />;
        case 'divider':
          return <QuestionDividerAddEdit question={question} edit onClose={() => {}} />;
        default:
          return null;
      }
    } else if (type.toLowerCase() === 'form') {
      switch (question.question_type_identifier.toLowerCase()) {
        case 'header':
          return <QuestionHeaderDisplayForm question={question} key={question.id} />;
        case 'paragraph':
          return <QuestionParagraphDisplayForm question={question} key={question.id} />;
        case 'short_text':
          return (
            <QuestionShortTextDisplayForm
              question={question}
              key={question.id}
              formState={formState}
              errors={errors}
              isReadonly={isReadonly}
              setValue={setValue}
              isSynced={isSynced}
              disabled={isReadonly}
              {...rest}
            />
          );
        case 'signature':
          return (
            <QuestionSignatureDisplayForm
              question={question}
              key={question.id}
              formState={formState}
              errors={errors}
              response={response}
              readOnly={isReadonly}
              disabled={isReadonly}
              icon={isReadonly ? 'LockClosed' : ''}
              {...rest}
            />
          );
        case 'long_text':
          return (
            <QuestionMultiLineDisplayForm
              question={question}
              key={question.id}
              formState={formState}
              errors={errors}
              isReadonly={isReadonly}
              isSynced={isSynced}
              disabled={isReadonly}
              icon={isReadonly ? 'LockClosed' : ''}
              {...rest}
            />
          );
        case 'video':
          return (
            <QuestionVideoPromptDisplayForm
              question={question}
              key={question.id}
              formState={formState}
              errors={errors}
              {...rest}
            />
          );
        case 'multiple_choice':
          return (
            <QuestionMultipleChoiceDisplayForm
              question={question}
              key={question.id}
              formState={formState}
              errors={errors}
              setValue={setValue}
              defaultValue={defaultValue}
              isReadonly={isReadonly}
              isSynced={isSynced}
              {...rest}
            />
          );
        case 'single_choice':
          return (
            <QuestionSingleChoiceDisplayForm
              question={question}
              key={question.id}
              formState={formState}
              errors={errors}
              setValue={setValue}
              defaultValue={defaultValue}
              isReadonly={isReadonly}
              isSynced={isSynced}
              response={response}
              childResponse={childResponse}
              formId={formId}
              isVisitor={isVisitor}
              control={control}
              registerOptions={registerOptions}
              {...rest}
            />
          );
        case 'dropdown':
          return (
            <QuestionDropdownDisplayForm
              question={question}
              key={question.id}
              formState={formState}
              errors={errors}
              setValue={setValue}
              defaultValue={defaultValue}
              isReadonly={isReadonly}
              isSynced={isSynced}
              disabled={isReadonly}
              response={response}
              formId={formId}
              isVisitor={isVisitor}
              control={control}
              registerOptions={registerOptions}
              {...rest}
            />
          );

        case 'date':
          return (
            <QuestionDatePickerDisplayForm
              question={question}
              key={question.id}
              formState={formState}
              errors={errors}
              setValue={setValue}
              defaultValue={defaultValue}
              response={response}
              isReadonly={isReadonly}
              isSynced={isSynced}
              disabled={isReadonly}
              {...rest}
            />
          );

        case 'file_download':
          return (
            <QuestionFileDownloadDisplayForm
              question={question}
              key={question.id}
              formState={formState}
              errors={errors}
              {...rest}
            />
          );

        case 'star_rating':
          return (
            <QuestionStarRatingDisplayForm
              question={question}
              key={question.id}
              formState={formState}
              errors={errors}
              setValue={setValue}
              defaultValue={defaultValue}
              response={response}
              isReadonly={isReadonly}
              {...rest}
            />
          );

        case 'recommendation':
          return (
            <QuestionRecommendationDisplayForm
              question={question}
              key={question.id}
              formState={formState}
              errors={errors}
              setValue={setValue}
              defaultValue={defaultValue}
              response={response}
              formId={formId}
              isAdmin={isAdmin}
              isReadonly={isReadonly}
              {...rest}
            />
          );

        case 'file_upload':
          return (
            <QuestionFileUploadDisplayForm
              question={question}
              key={question.id}
              formState={formState}
              errors={errors}
              setValue={setValue}
              defaultValue={defaultValue}
              formId={formId}
              response={response}
              isReadonly={isReadonly}
              isVisitor={isVisitor}
              {...rest}
            />
          );

        case 'fee':
          return (
            <QuestionFeeDisplayForm
              question={question}
              key={question.id}
              formState={formState}
              errors={errors}
              setValue={setValue}
              defaultValue={defaultValue}
              formId={formId}
              response={response}
              isReadonly={isReadonly}
              isVisitor={isVisitor}
              isAdmin={isAdmin}
              inFormBuilder={inFormBuilder}
              {...rest}
            />
          );

        case 'cash_net':
          return (
            <QuestionCashnetDisplayForm
              question={question}
              key={question.id}
              formState={formState}
              errors={errors}
              setValue={setValue}
              defaultValue={defaultValue}
              formId={formId}
              response={response}
              isReadonly={isReadonly}
              isVisitor={isVisitor}
              isAdmin={isAdmin}
              inFormBuilder={inFormBuilder}
              {...rest}
            />
          );
        case 'cash_net_with_code':
          return (
            <QuestionCashnetCodeDisplayForm
              question={question}
              key={question.id}
              formState={formState}
              errors={errors}
              setValue={setValue}
              defaultValue={defaultValue}
              formId={formId}
              response={response}
              isReadonly={isReadonly}
              isVisitor={isVisitor}
              isAdmin={isAdmin}
              cashNetCodes={cashNetCodes}
              inFormBuilder={inFormBuilder}
              {...rest}
            />
          );
        case 'flywire':
          return (
            <QuestionFlywireDisplayForm
              question={question}
              key={`${question.id}-${response?.payment_response_status_change_logs?.length}`}
              formState={formState}
              errors={errors}
              setValue={setValue}
              defaultValue={defaultValue}
              formId={formId}
              response={response}
              isReadonly={isReadonly}
              isVisitor={isVisitor}
              isAdmin={isAdmin}
              inFormBuilder={inFormBuilder}
              {...rest}
            />
          );
        case 'payflow':
          return (
            <QuestionPayflowDisplayForm
              question={question}
              key={question.id}
              formState={formState}
              errors={errors}
              setValue={setValue}
              defaultValue={defaultValue}
              formId={formId}
              response={response}
              isReadonly={isReadonly}
              isVisitor={isVisitor}
              isAdmin={isAdmin}
              inFormBuilder={inFormBuilder}
              {...rest}
            />
          );
          case 'cybersource':
          return (
            <QuestionCybersourceDisplayForm
              question={question}
              key={question.id}
              formState={formState}
              errors={errors}
              setValue={setValue}
              defaultValue={defaultValue}
              formId={formId}
              response={response}
              isReadonly={isReadonly}
              isVisitor={isVisitor}
              isAdmin={isAdmin}
              inFormBuilder={inFormBuilder}
              {...rest}
            />
          );
          case 'quik_pay':
          return (
            <QuestionQuikpayDisplayForm
              question={question}
              key={question.id}
              formState={formState}
              errors={errors}
              setValue={setValue}
              defaultValue={defaultValue}
              formId={formId}
              response={response}
              isReadonly={isReadonly}
              isVisitor={isVisitor}
              isAdmin={isAdmin}
              inFormBuilder={inFormBuilder}
              {...rest}
            />
          );
          case 'converge_pay':
          return (
            <QuestionConvergepayDisplayForm
              question={question}
              key={question.id}
              formState={formState}
              errors={errors}
              setValue={setValue}
              defaultValue={defaultValue}
              formId={formId}
              response={response}
              isReadonly={isReadonly}
              isVisitor={isVisitor}
              isAdmin={isAdmin}
              inFormBuilder={inFormBuilder}
              {...rest}
            />
          );
        case 'touch_net':
          return (
            <QuestionTouchnetDisplayForm
              question={question}
              key={question.id}
              formState={formState}
              errors={errors}
              setValue={setValue}
              defaultValue={defaultValue}
              formId={formId}
              response={response}
              isReadonly={isReadonly}
              isVisitor={isVisitor}
              isAdmin={isAdmin}
              inFormBuilder={inFormBuilder}
              {...rest}
            />
          );
        case 'divider':
          return <QuestionDividerDisplay question={question} key={question.id} />;
        default:
          return null;
      }
    }

    return null;
  };

  return <>{renderSwitch()}</>;
}


export default DragDropContext(HTML5Backend)(QuestionRender);

// export default QuestionRender
