exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ":root{--dark:var(--theme_color_dark);--accent:var(--theme_color_accent);--ocean:var(--theme_color_light);--button-border:var(--theme_border_color)}._27fN5{width:100%;background-color:#f5f5f5;height:137px;margin-bottom:20px;-ms-flex-align:center;align-items:center}._27fN5,.UtNlW{display:-ms-flexbox;display:flex}.UtNlW{padding-left:30px}._1PR1Z{margin-top:-35px;margin-left:20px;width:30px}._1_odB{padding-left:10px}._37h7I{color:var(--theme_color_accent);color:var(--accent,#dd2160);font-size:14px}._2ywiP,._3akTd,._37h7I{font-family:AvenirNextDemiBold,sans-serif}._2ywiP,._3akTd{color:#9b9b9b;font-size:12px;padding-left:5px}._2ywiP{padding-left:0}._3mV5r,.P75JV{font-family:AvenirNextDemiBold,sans-serif;color:#686868;font-size:14px}._1d9rn,._1ENSA{font-family:IntroBoldItalic,sans-serif;font-size:24px;color:var(--theme_color_dark);color:var(--dark,#043544)}._3rbxs{color:#000}@media only screen and (max-width:768px){._3dRF-{width:40px;height:40px}._1d9rn{font-size:10px}._1ENSA{font-size:16px}}", ""]);

// exports
exports.locals = {
	"event": "_27fN5",
	"eventInfo": "UtNlW",
	"day": "_1PR1Z",
	"titleSection": "_1_odB",
	"date": "_37h7I",
	"time": "_3akTd",
	"timezone": "_2ywiP",
	"attendance": "P75JV",
	"registration": "_3mV5r",
	"name": "_1ENSA",
	"location": "_1d9rn",
	"dayNumber": "_3rbxs",
	"calendar": "_3dRF-"
};