import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import history from '../../../../history';
import { buttonFocus } from '../../utils/shared/focusStyles';

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 1.6875rem;
`;

const SaveButton = styled.button`
  padding: 0.5625rem 1.1875rem;
  background-color: #eeeeee;
  border: 1px solid #373737;
  border-radius: 5px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 1rem;
  color: #373737;
  letter-spacing: 0.06em;
  line-height: 120%;

  &:hover {
    opacity: 0.9;
  }

  ${buttonFocus};
`;

const NextButton = styled.button`
  padding: 0.5625rem 2.375rem;
  background-color: var(--theme_color_accent);
  border: 1px solid transparent;
  border-radius: 5px;
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
  font-size: 1rem;
  color: var(--theme_color_accent_font);
  letter-spacing: 0.06em;
  line-height: 120%;

  &:hover {
    opacity: 0.9;
  }

  ${buttonFocus};

  ${props => {
    if (!props.isIncomplete) {
      return `
        justify-content: flex-end;
        min-width: 25%;
      `;
    }
  }};
`;

export default function DocumentDetailsButtons({ onNext, onSave }) {
  const {
    formSubmission,
    applicationSubmission: { attributes: submission },
  } = useSelector(state => state.travelerApplicationSubmissions);

  const forForm = history.location.pathname.includes('form');
  const isPreviewPage = history.location.pathname.includes('preview');
  const isAlternatesPage = history.location.pathname.includes('alternates');

  const isIncomplete = forForm
    ? ['Not Started', 'Started'].includes(formSubmission?.attributes?.submission_status?.name)
    : submission.submission_status.name === 'Incomplete';

  const formName = isAlternatesPage ? 'addAlternates' : 'travelerSubmissionForm';
  const nextButtonText = forForm ? 'Submit' : isIncomplete ? 'Next' : 'Close';

  return (
    <Container>
      {isPreviewPage ? (
        <NextButton type="submit" form={formName} onClick={onNext}>
          Submit Application
        </NextButton>
      ) : (
        <>
          {isIncomplete && (
            <SaveButton type="button" form={formName} onClick={onSave}>
              Save
            </SaveButton>
          )}
          <NextButton type="submit" form={formName} isIncomplete={isIncomplete} onClick={onNext}>
            {nextButtonText}
          </NextButton>
        </>
      )}
    </Container>
  );
}

DocumentDetailsButtons.propTypes = {
  onNext: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};
