/* eslint-disable import/prefer-default-export */

import fetch from 'node-fetch';

import { keys } from '../../../config/keys';

import { GET_CURRENT_ORGANIZATION } from './types';

export function getCurrentOrganization() {
  return async function(dispatch) {
    const subdomain = window.location.host.split('.')[0];

    const response = await fetch(`${keys.baseUri}/api/client/current_org/${subdomain}`);

    if (response.status === 404) {
      const url = new URL(window.location.href);
      const { protocol } = url;

      let hostParts = url.host.split('.');

      hostParts = hostParts.filter(element => /\S/.test(element));
      hostParts = (hostParts.splice(0, 1, 'www'), hostParts);

      const host = hostParts.join('.');
      const homePage = protocol + '//' + host;

      window.location.assign(homePage);
    }

    const data = await response.json();

    dispatch({
      type: GET_CURRENT_ORGANIZATION,
      payload: data,
    });
  };
}
