import styled from 'styled-components';

const CheckboxGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 0;
  max-height: 200px;
  padding: 0.625rem 0 0 0;
  border-bottom: 1px solid #cccccc;
  overflow-y: scroll;
  scrollbar-gutter: stable;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    background: #eeeeee;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 50px;
    background-color: #c4c4c4;
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

export default CheckboxGrid;
