import styled from 'styled-components';

import { breakpoints } from '../../utils/breakpoints';

const EventGroup = styled.div`
  margin-bottom: 1.774375rem;
  padding: 0 0.75rem;

  &:last-of-type {
    margin-bottom: 0;
    padding-bottom: 3.75rem;
  }

  @media ${breakpoints.tablet} {
    padding: 0;

    &:last-of-type {
      padding-bottom: 0;
    }
  }
`;

export default EventGroup;
