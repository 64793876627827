import React from 'react';
import styled from 'styled-components';
import TextInput from '../../../../../styledComponents/styles/TextInput.styled';

const Container = styled.div`
  font-family: 'Nunito', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 0.015em;
  color: #373737;
`;

const InputContainer = styled.div`
  flex: 1;
  margin: 13px 0;
  text-align: left;
`;

export default function QuestionSignatureDisplayPreview({ question }) {
  return (
    <Container>
      <InputContainer>
        <TextInput
          aria-label={question.label}
          id={'signature'}
          instructions={question.instructions}
          label={question.label}
          openSans
          placeholder={`Enter ${question.label}`}
          required={question.required}
          tabIndex={-1}
          readOnly
          disabled={true}
          icon={'LockClosed'}
        />
      </InputContainer>
    </Container>
  );
}
