import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import SimpleClockIcon from '../icons/SimpleClockIcon';

const Deadline = styled.div`
  display: flex;
  align-items: center;
  gap: 0.543125rem;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  color: #373737;
  letter-spacing: 0.0025em;
  line-height: 120%;

  svg {
    width: 16px;
    height: 16px;
    color: var(--theme_color_accent);
  }
`;

export default function DocumentDeadline({ forForm }) {
  const {
    applicationSubmission: { attributes: submission },
  } = useSelector(state => state.travelerApplicationSubmissions);

  const {
    info: { org_timezone: timezone },
  } = useSelector(state => state.currentOrganization);

  const formattedDate = moment(submission.deadline).format('MMM D, YYYY');
  const formattedTime = moment(submission.deadline)
    .tz(timezone)
    .format('h:mma z');

  const deadlineText = forForm ? formattedDate : `${formattedDate} at ${formattedTime}`;

  return (
    <Deadline>
      <SimpleClockIcon />
      <span>Deadline: {deadlineText}</span>
    </Deadline>
  );
}

DocumentDeadline.propTypes = {
  forForm: PropTypes.bool.isRequired,
};
