exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2lwOp{margin-bottom:150px;overflow-wrap:break-word}", ""]);

// exports
exports.locals = {
	"container": "_2lwOp"
};