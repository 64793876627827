import React, { useState } from 'react';
import styled from 'styled-components';
import { Paragraph } from '../../../../../styledComponents/styles/Paragraph.styled';
import StyledIcon from '../../../../../styledComponents/styles/Icon.styled';
import { Col, Row } from '../../../../../styledComponents/styles/FlexGrid.styled';
import { Table, TableBody, TableRow, TableColumn } from '../../../../../styledComponents/styles/Table.styled';
import ImageViewer from '../../../../../components/ImageViewer';
import ReactPDF from '../../../../../components/ReactPDF';
import { downloadFile } from '../../../../../shared/helpers/General';

const Container = styled.div`
  font-family: 'Nunito', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 0.015em;
  color: #373737;
`;

const FileItem = styled.span`
  color: #043544;
  font-family: Open Sans, sans-serif;
  font-size: 16px;
  font-weight: 400;
  overflow-wrap: anywhere;
`;

const DownloadFile = styled.span`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 0.06em;
  text-decoration-line: underline;
  color: #446372;
  margin-left: 5px;
`;

const InputContainer = styled.div`
  flex: 1;
  margin: 13px 0;
  text-align: left;
`;

const Label = styled.div`
  color: ${({ theme }) => theme.textInput.label.fontColor};
  font-family: ${({ theme }) => theme.textInput.label.fontFamily};
  font-size: ${({ theme }) => theme.textInput.label.fontSize};
  font-weight: ${({ theme }) => theme.textInput.label.fontWeight};
`;

const PreviewIcon = styled.button`
  color: #446372;
  cursor: pointer;
  justify-self: center;
  // margin-left: 10px;
  border: none; 
  background: transparent; 
  padding: 0;
`;

const Required = styled.span`
  color: ${({ theme }) => theme.defaults.errorColor};
  padding-left: 5px;
`;

const FileContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const AttachmentTable = styled(Table)`
  box-sizing: border-box;
  width: 100%;
  border: 1px solid #cccccc;

  th:first-child,
  tr:first-child {
    // width: calc(100% - 200px);
    width: 70%;
  }

  td:first-child {
    // width: calc(100% - 200px);
    width: 70%;
  }

  tr:nth-of-type(even) {
    background-color: #ebebeb;
  }

  tr:nth-of-type(odd) {
    background-color: #ffffff;
  }
`;

const RowStyle = styled(Row)`
  padding-bottom: 8px;
`;

const ImageNameSpanUnlink = styled.span`
  padding-left: 0px;
`;

export const PreviewColumn = styled.td`
  width: 20px;
`;

export default function QuestionFileDownloadDisplayForm({ question }) {
  const [showPreview, setShowPreview] = useState(false);
  const [showPDFPreview, setShowPDFPreview] = useState(false);
  const [previewFile, setPreviewFile] = useState('');

  function handleShowPreview(file) {
    if (!!file.url && file.content_type) {
      if (file.content_type.toLowerCase().indexOf('pdf') > -1) {
        setPreviewFile(file.url);
        setShowPDFPreview(true);
      }
      if (file.content_type.toLowerCase().indexOf('image') > -1) {
        setPreviewFile(file.url);
        setShowPreview(true);
      }
    }
  }
  const updatedInstruction = !!question.instructions ? question.instructions.replace(/<p><\/p>/g, '<br />') : '';
  return (
    <Container>
      <InputContainer>
        <Label tabIndex={0}>
          {question.label}
          {question.required && <Required>*</Required>}
        </Label>
        <Paragraph
          tabIndex={1}
          id="file-download-instructions"
          openSans
          dangerouslySetInnerHTML={{
            __html: updatedInstruction,
          }}
        />
      </InputContainer>
      {question && !!question.uploaded_files && question.uploaded_files.length === 0 ? null : (
        <InputContainer>
          <Col size={4}>
            <RowStyle>
              <FileContainer>
                <AttachmentTable>
                  <TableBody>
                    {question?.uploaded_files?.map((file, index) => (
                      <TableRow key={`${file.id}-${file.filename}-${index}`}>
                        <TableColumn>
                          <FileItem>
                            <ImageNameSpanUnlink>{file.filename}</ImageNameSpanUnlink>
                          </FileItem>
                        </TableColumn>
                        <PreviewColumn>
                          {file.content_type &&
                            file.url &&
                            (file.content_type.toLowerCase().indexOf('pdf') > -1 ||
                              file.content_type.toLowerCase().indexOf('image') > -1) && (
                              <PreviewIcon
                                type="button"
                                aria-label='Preview File'
                                onClick={() => {
                                  handleShowPreview(file);
                                }}
                              >
                                <StyledIcon type="Eye" size="16px" />
                              </PreviewIcon>
                            )}
                        </PreviewColumn>
                        <TableColumn style={{width: "150px"}}>
                          <PreviewIcon type="button" onClick={() => downloadFile(file.url, file.filename)} aria-label='Download File'>
                            <StyledIcon type="File_download" size="16px" />
                            <DownloadFile>Download File</DownloadFile>
                          </PreviewIcon>
                        </TableColumn>
                      </TableRow>
                    ))}
                  </TableBody>
                </AttachmentTable>
              </FileContainer>
            </RowStyle>
          </Col>
        </InputContainer>
      )}
      {showPreview && <ImageViewer file={previewFile} handleClose={() => setShowPreview(false)} show={showPreview} />}
      {showPDFPreview && (
        <ReactPDF file={previewFile} width="300px" show={showPDFPreview} handleClose={() => setShowPDFPreview(false)} />
      )}
    </Container>
  );
}
