exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2UAQ2{font-family:Nunito;font-size:25px;font-weight:400;line-height:30px;letter-spacing:0;text-align:left;margin-bottom:0}.WPr4B{display:-ms-flexbox;display:flex;-ms-flex-pack:justify;justify-content:space-between;width:100%;-ms-flex-align:center;align-items:center;padding:5px}._1SpqA{gap:64px;padding:25px 26px}.Ratwh{font-family:Nunito,sans-serif;font-weight:400;font-size:25px;line-height:30px;margin-bottom:0}.JngqX{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row}._32ePW{font-weight:400}._32ePW,.PUNbZ{font-family:Open Sans,sans-serif;font-size:16px;line-height:22.4px;letter-spacing:.5%}.PUNbZ{font-weight:700;margin-top:20px}._3GYFQ{background-color:#ebebeb}._307cT{display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center}._2eaBP{width:90px;height:100px}.PUNbZ a,.PUNbZ a:hover{color:#255a94}._1cKxh{background:var(--theme_color_accent);border-color:var(--theme_color_accent);color:var(--theme_color_accent_font)}._1cKxh:active,._1cKxh:disabled,._1cKxh:focus,._1cKxh:hover,._1cKxh:not(:disabled):not(._2ebdS):active{background:var(--theme_color_accent);color:var(--theme_color_accent_font);border-color:var(--theme_color_accent);opacity:.7;-webkit-box-shadow:unset;box-shadow:unset}._2nUck{font-family:Nunito;font-weight:600;line-height:19.2px;letter-spacing:.015em}._1IsTg,._2nUck{font-size:16px;text-align:left}._1IsTg{font-family:Open Sans;font-weight:400;line-height:22.4px;letter-spacing:.005em}", ""]);

// exports
exports.locals = {
	"heading": "_2UAQ2",
	"title": "WPr4B",
	"headerTitle": "_1SpqA",
	"headingtext": "Ratwh",
	"modalBodycontainer": "JngqX",
	"colspancontent": "_32ePW",
	"colpcontent": "PUNbZ",
	"footercolor": "_3GYFQ",
	"pdfIconContainer": "_307cT",
	"pdfIcon": "_2eaBP",
	"downloadPdfButton": "_1cKxh",
	"disabled": "_2ebdS",
	"checkLabel": "_2nUck",
	"text": "_1IsTg"
};