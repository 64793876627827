import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { compose } from 'recompose';
import propTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import Headline from '../../../../../components/library/headline';
import Paragraph from '../../../../../components/library/paragraph';
import Button from '../../../../../components/library/button';
import InputText from '../../../../../components/library/inputText';
import sAddOneTravelerToPlan from './addOneTravelerToPlan.scss';
import { Formik } from 'formik';
import { addTravelerToPlan } from '../../../../../../actions/plans';
import BatchAddTravelerToPlan from '../batchAddTravelerToPlan';
export const enhance = compose(withStyles(sAddOneTravelerToPlan));

function AddOneTravelerToPlan(props) {
  const dispatch = useDispatch();
  const [saving, setSaving] = useState(false);
  const [showBatch, setShowBatch] = useState(false);

  return (
    <>
      {showBatch ? (
        <BatchAddTravelerToPlan
          planId={props.planId}
          onClose={() => {
            props.onClose(false);
          }}
        />
      ) : (
        <Formik
          initialValues={{
            first_name: '',
            last_name: '',
            email: '',
          }}
          validate={values => {
            const errors = {};
            if (!values.first_name) {
              errors.first_name = 'First Name Required';
            }
            if (!values.last_name) {
              errors.last_name = 'Last Name Required';
            }
            if (!values.email) {
              errors.email = 'Email Required';
            }
            return errors;
          }}
          onSubmit={values => {
            setSaving(true);
            dispatch(addTravelerToPlan(props.planId, values, 'soloTraveler'));
            props.onClose(false);
          }}
        >
          {({ values, errors, handleChange, handleBlur, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <div className={sAddOneTravelerToPlan['add-one-traveler-to-plan']}>
                <Headline tag="h2" as="h2" centered>
                  Add A Single Traveler
                </Headline>
                <div className={sAddOneTravelerToPlan.container}>
                  <div className={sAddOneTravelerToPlan.input}>
                    <InputText
                      labelText="First Name"
                      name="first_name"
                      id="first_name"
                      placeholder="First Name"
                      type="text"
                      isRequired
                      errorMessage={errors.first_name}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.first_name}
                    />
                  </div>
                  <div className={sAddOneTravelerToPlan.input}>
                    <InputText
                      labelText="Last Name"
                      name="last_name"
                      id="last_name"
                      placeholder="Last Name"
                      type="text"
                      isRequired
                      errorMessage={errors.last_name}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.last_name}
                    />
                  </div>
                  <div className={sAddOneTravelerToPlan.input}>
                    <InputText
                      labelText="Email"
                      name="email"
                      id="email"
                      placeholder="Email"
                      type="text"
                      isRequired
                      errorMessage={errors.email}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.email}
                    />
                  </div>
                  <div className={sAddOneTravelerToPlan.toggleContainer} onClick={() => setShowBatch(true)}>
                    <Paragraph>Add Multiple Travelers</Paragraph>
                  </div>
                  <div>
                    <div className={sAddOneTravelerToPlan['button-row']}>
                      <div className={sAddOneTravelerToPlan['button-content']}>
                        <Button
                          display="secondary"
                          kind="solid"
                          size="medium"
                          onClick={() => {
                            props.onClose(false);
                          }}
                        >
                          Cancel
                        </Button>
                      </div>
                      {values.first_name && values.last_name && values.email ? (
                        <div className={sAddOneTravelerToPlan['button-content']}>
                          <Button display="primary" kind="solid" size="medium" type="submit">
                            Add
                          </Button>
                        </div>
                      ) : (
                        <div className={sAddOneTravelerToPlan['button-content']}>
                          <Button display="secondary" kind="solid" size="medium" disabled>
                            Add
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          )}
        </Formik>
      )}
    </>
  );
}

AddOneTravelerToPlan.propTypes = {
  show: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
  planId: propTypes.string.isRequired,
};

export default enhance(AddOneTravelerToPlan);
