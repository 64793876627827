import React from 'react';
import PropTypes from 'prop-types';

export default function ChevronRightIcon({ fillColor }) {
  return (
    <svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.71967 0.23013C1.01256 -0.0767101 1.48744 -0.0767101 1.78033 0.23013L6.28033 4.94442C6.57322 5.25126 6.57322 5.74874 6.28033 6.05558L1.78033 10.7699C1.48744 11.0767 1.01256 11.0767 0.71967 10.7699C0.426777 10.463 0.426777 9.96554 0.71967 9.6587L4.68934 5.5L0.71967 1.3413C0.426777 1.03446 0.426777 0.536971 0.71967 0.23013Z"
        fill={fillColor}
      />
    </svg>
  );
}

ChevronRightIcon.propTypes = {
  fillColor: PropTypes.string,
};

ChevronRightIcon.defaultProps = {
  fillColor: 'currentColor',
};
