import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import GenericStyledModal from './GenericStyledModal.modal';
import history from '../../../../../history';
import { addApplicationToTravelerProfile } from '../../../../../actions/formsActions';
import { ButtonMediumNunito, AccentButtonMediumNunito } from '../../../../../styledComponents/styles/Buttons.styled';
import { WarningIcon } from '../../../../../styledComponents/styles/IconCustom.styled';

const Container = styled.div``;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 25px;
`;

const IconWrapper = styled.div`
  align-self: center;
  padding-left: 34px;
`;

const ParagraphWrapper = styled.div`
  margin-left: 45px;
  margin-right: 37px;
`;

const Paragraph = styled.p`
  color: #373737;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 400;
`;

const ParagraphWarning = styled(Paragraph)`
  font-weight: 600;
`;

const ButtonContainer = styled.div`
  background: #ebebeb;
  display: flex;
  height: 111px;
  justify-content: center;
  left: 0;
  margin-top: 43px;
  top: 273px;
  width: 520px;
  margin-bottom: -20px;
  border-radius: 15px;
`;

const ButtonDiv = styled.div`
  margin-top: 30px;
  margin-right: 20px;
`;


const ApplicationTimingWarningModal = props => {
  const dispatch = useDispatch();
  
  const customAliases = useSelector(state => state.profile.customAliases);
  const availablePrograms = useSelector(state => state.forms?.availableApplications);
  const [selectedProgram, setSelectedProgram] = useState([]);
  const [filteredPrograms, setFilteredPrograms] = useState([]);
  const [selectedTerm, setSelectedTerm] = useState([]);

  const handleClose = () => {
    props.onClose();
  };

  return (
    <GenericStyledModal
      header='Application Timing Warning'
      showing={props.show}
      width='520px'
      onClose={() => {
        handleClose();
      }}
    >
      <Container>
        <Content>
          <IconWrapper>
            <WarningIcon size='100px' />
          </IconWrapper>
          <ParagraphWrapper>
            <Paragraph>
              {`${props.travelerName} has already started an application for a program within this time frame.`}
            </Paragraph>
            <ParagraphWarning>
              {`If you would like to continue, please select "Add Application."`}
            </ParagraphWarning>
          </ParagraphWrapper>
        </Content>
        <ButtonContainer>
          <ButtonDiv>
            <ButtonMediumNunito
              onClick={() => {
                handleClose();
              }}
            >
              Cancel
            </ButtonMediumNunito>
          </ButtonDiv>
          <ButtonDiv>
          <AccentButtonMediumNunito
              disabled={false}
              onClick={() => {
                const values = {
                  override_application_timeframe_violation: true,
                  program_range_id: props.selectedTerm,
                  user_id: props.travelerId,
                };

                dispatch(addApplicationToTravelerProfile(values,1));
                handleClose();
              }}
            >
             Add Application
            </AccentButtonMediumNunito>
          </ButtonDiv>
        </ButtonContainer>
      </Container>
    </GenericStyledModal>
  );
};

ApplicationTimingWarningModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

export default ApplicationTimingWarningModal;
