import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import GenericStyledModal from './GenericStyledModal.modal';
import StyledIcon from '../../../../../styledComponents/styles/Icon.styled';
import { ButtonMediumNunito, AccentButtonMediumNunito } from '../../../../../styledComponents/styles/Buttons.styled';

import ToastMessage from '../../../../viaGlobal/components/toastMessage';
import { TrashCanIcon } from '../../../../../styledComponents/styles/IconCustom.styled';
import TextInput from '../../../../../styledComponents/styles/TextInput.styled';
import { deleteQuestion } from '../../../../../actions/formsActions';

const Container = styled.div``;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 25px;
`;

const IconWrapper = styled.div`
  align-self: center;
  padding-left: 34px;
`;

const ParagraphWrapper = styled.div`
  margin-left: 45px;
  margin-right: 37px;
`;

const Paragraph = styled.p`
  color: #373737;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 400;
`;

const ParagraphWarning = styled(Paragraph)`
  font-weight: 600;
`;

const FooterContainer = styled.div`
  background: #ebebeb;
  display: flex;
  flex-direction: column;
  height: 150px;
  justify-content: center;
  left: 0;
  top: 273px;
  width: 520px;
  margin-bottom: -20px;
  border-radius: 15px;
`;

const InputDiv = styled.div`
  align-self: center;
  margin-bottom: 15px;
  width: 300px;
`;

const ButtonWrapper = styled.div`
  align-self: center;
  display: flex;
`;

const ButtonDiv = styled.div`
  flex: 1;
  width: 100px;
`;

const DeleteFormLink = styled.div`
  color: #8b1313;
  cursor: pointer;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 14px;
  text-align: end;
  span {
    min-height: 15px;
    display: inline-flex;
    align-items: center;
  }
  svg {
    align-items: center;
    display: inline-flex;
    margin: -2px 5px 0 0;
    min-height: 15px;
  }
`;

const ClearLogicSpan = styled.span`
  color: #446372;
  cursor: pointer;
  font-family: 'Nunito', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  float: right;
  margin-top: -10px;
  text-decoration: underline;
`;

export default function DeleteQuestionModal({ clearLogic = false, question, formId }) {
  const dispatch = useDispatch();
  const [showing, setShowing] = useState(false);
  const [confirm, setConfirm] = useState('');
  const customAliases = useSelector(state => state.profile.customAliases);
  const fetchForm = useSelector(state => state.forms.fetchForm);
  const fetchDelete = useSelector(state => state.forms.deleteQuestion);
  
  const questionTypesInfo = useSelector(state => state.forms.questionTypes);

  const [showToastSuccess, setShowToastSuccess] = useState(false);
  const [showToastFailure, setShowToastFailure] = useState(false);
  const [toastMessage, setToastMessage] = useState('');

  const handleDelete = () => {
    if (questionTypesInfo?.data) {
      const qTypes = questionTypesInfo.data.flatMap(type => type.attributes.question_types);
      const matchQType = qTypes.find(q => q.identifier.toLowerCase() === question.question_type_identifier.toLowerCase());
      setShowToastSuccess(false)
      setShowToastFailure(false)
      if (formId && question?.id && matchQType?.endpoint) {
        setShowing(false);
        dispatch(deleteQuestion(formId, matchQType.endpoint, question.id));
        if(!fetchDelete.error){
          setToastMessage("Question deleted")
          setShowToastSuccess(true)
        }else{
          setToastMessage("Error deleting question")
          setShowToastFailure(true)
        }
      }
    }
  };

  const deleteQuestionClick = () => {
    const isUnprotectedType = ['header', 'paragraph', 'divider'].includes(question?.question_type_identifier);
    const isDraft = fetchForm?.data?.status === 'draft';
    if(isUnprotectedType || isDraft) {
      handleDelete();
    } else {
      setConfirm('');
      setShowing(true);
    }
  }  

  return (
    <>
      {!clearLogic ? (
        <DeleteFormLink
          onClick={deleteQuestionClick}
        >
          <StyledIcon type='Trash' size='16px' color='#8b1313' />
          <span>Delete</span>
        </DeleteFormLink>
      ) : (
        <ClearLogicSpan
          onClick={deleteQuestionClick}
        >
          Delete Logic
        </ClearLogicSpan>
      )}

      <GenericStyledModal
        header={clearLogic ? 'Confirm Deletion of Logic' : 'Confirm Deletion'}
        showing={showing}
        width='520px'
        onClose={() => {
          setShowing(false);
        }}
      >
        <Container>
          <Content>
            <IconWrapper>
              <TrashCanIcon size='100px' />
            </IconWrapper>
            <ParagraphWrapper>
              {!clearLogic && (
                <>
                  <Paragraph>
                    You are about to delete this question. Deleting this question will permanently delete all historic
                    responses. This action cannot be undone.
                  </Paragraph>
                  <ParagraphWarning>
                    Enter ‘DELETE’ to delete question and all {customAliases.alias_traveler} responses.
                  </ParagraphWarning>
                </>
              )}
              {clearLogic && (
                <>
                  <Paragraph>
                    You are about to delete a conditional logic question on a form that may have associated traveler
                    responses.
                  </Paragraph>
                  <ParagraphWarning>
                    Deleting this question will permanently delete all responses to the logic question. This action can
                    not be undone.
                  </ParagraphWarning>
                  <ParagraphWarning>Enter ‘DELETE’ to delete the logic question.</ParagraphWarning>
                </>
              )}
            </ParagraphWrapper>
          </Content>
          <FooterContainer>
            <InputDiv>
              <TextInput
                aria-label='Delete confirmation'
                id='confirm-prompt'
                label=''
                onChange={e => {
                  setConfirm(e.target.value.toUpperCase());
                }}
                value={confirm}
              />
            </InputDiv>
            <ButtonWrapper>
              <ButtonDiv>
                <ButtonMediumNunito
                  onClick={() => {
                    setShowing(false);
                  }}
                >
                  Cancel
                </ButtonMediumNunito>
              </ButtonDiv>
              <ButtonDiv>
                <AccentButtonMediumNunito disabled={confirm !== 'DELETE'} onClick={handleDelete}>
                  Delete
                </AccentButtonMediumNunito>
              </ButtonDiv>
            </ButtonWrapper>
          </FooterContainer>
        </Container>
      </GenericStyledModal>
      <ToastMessage
        message={toastMessage}
        show={showToastSuccess}
        isSuccess
        onClose={() => {
          setShowToastSuccess(false);
          setToastMessage('');
        }}
      />
       <ToastMessage
        message={toastMessage}
        show={showToastFailure}
        isSuccess={false}
        onClose={() => {
          setShowToastFailure(false);
          setToastMessage('');
        }}
      />
    </>
  );
}

DeleteQuestionModal.propTypes = {
  clearLogic: PropTypes.bool,
  formId: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  question: PropTypes.object.isRequired,
};

DeleteQuestionModal.defaultProps = {
  clearLogic: false,
};