import fetch from 'node-fetch';
import history from '../history';
import { keys } from '../config/keys';

import {
  FETCH_VISITOR_EVENT,
  FETCH_VISITOR_EVENT_FAILURE,
  FETCH_VISITOR_EVENT_REQUEST,
  VISITOR_SINGLE_TRAVELER,
} from './types';
import { Cookies } from 'react-cookie';

export function fetchVisitorEvent(id) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({
      type: FETCH_VISITOR_EVENT_REQUEST,
    });

    fetch(`${keys.baseUri}/api/visitor/events/${id}/get_event/`, {
      headers: token,
    })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: FETCH_VISITOR_EVENT,
          payload: data,
        }),
      )
      .catch(error => {
        dispatch({
          type: FETCH_VISITOR_EVENT_FAILURE,
          payload: error.message,
        });
      });
  };
}

export function inviteSingleTraveler(id, user) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(
      `${keys.baseUri}/api/visitor/events/${id}/create_event_traveler?user=${JSON.stringify({
        email: encodeURIComponent(`${user.email}`),
        first_name: encodeURIComponent(`${user.first_name}`),
        last_name: encodeURIComponent(`${user.last_name}`),
      })}`,
      {
        method: 'POST',
        headers: token,
      },
    )
      .then(res => res.json())
      .then(res => {
        dispatch({
          type: VISITOR_SINGLE_TRAVELER,
          code: res.status,
        });
      });
  };
}
