import { createTheme } from '@material-ui/core/styles';

const viaMuiTheme = (hideChip = false, pageSubNav = true, isAutomation = false, bannerSpacing = false) => {
  const colorBackground = '#ffffff';
  const colorDefault = '#446372';
  const darkTextColor = '#474747';
  const colorDark = document.documentElement.style.getPropertyValue(`--theme_color_dark`);
  let offset = 370;

  if (pageSubNav) {
    offset = offset + 70;
  }

  if (bannerSpacing) {
    offset = offset + 110;
  }

  if (isAutomation) {
    offset = 480;
  }

  const heightOffset = `calc(100vh - ${offset}px) !important`;

  return createTheme({
    overrides: {
      MUIDataTableToolbar: {
        root: {
          marginLeft: '-25px',
        },
      },
      MUIDataTable: {
        paper: {
          boxShadow: 'none',
          height: 'inherit',
        },
        responsiveScroll: {
          minHeight: heightOffset,
          maxHeight: heightOffset,
        },
      },
      MuiCheckbox: {
        colorPrimary: {
          color: colorDefault,
          '&$checked': {
            color: colorDefault,
          },
        },
      },
      MUIDataTableSelectCell: {
        headerCell: {
          backgroundColor: colorBackground,
        },
      },
      MuiToolbar: {
        root: {
          paddingLeft: '0',
          paddingRight: '0',
        },
        regular: {
          minHeight: '0',
        },
        gutters: {
          paddingLeft: '0',
          paddingRight: '0',
        },
      },
      MuiGrid: {
        container: {
          padding: '12px 8px',
        },
      },
      MuiFormControl: {
        root: {
          fontFamily: 'Open Sans',
          fontWeight: '400',
          color: '#373737',
          fontSize: '14px',
        },
      },
      MuiInputBase: {
        input: {
          fontFamily: 'Open Sans',
          fontWeight: '400',
          color: '#373737',
          fontSize: '14px',
        },
      },
      MUIDataTableFilterList: {
        chip: {
          marginBottom: '5px',
          display: 'none',
        },
      },
      MUIDataTableBodyRow: {
        root: {
          '&.Mui-selected, &.Mui-selected:hover': {
            backgroundColor: '#DEEBFF !important',
          },
          '&:nth-child(odd)': {
            backgroundColor: '#eeeeee',
          },
        },
      },
      MuiIconButton: {
        root: {
          color: colorDefault,
        },
      },
      MuiButton: {
        root: {
          fontSize: '16px',
          fontFamily: 'Nunito',
          color: '#474747',
          fontWeight: '700',
        },
        label: {
          fontSize: '16px',
          fontFamily: 'Nunito',
          color: '#474747',
          fontWeight: '700',
        },
      },
      MUIDataTableHeadCell: {
        root: {
          fontSize: '16px',
          fontFamily: 'Nunito',
          color: '#474747',
          fontWeight: '700',
          textAlign: 'left',
        },
        fixedHeader: {
          backgroundColor: colorBackground,
          color: darkTextColor,
        },
        sortAction: {
          textAlign: 'left',
          '& path': {
            color: darkTextColor,
          },
        },
        sortActive: {
          color: darkTextColor,
        },
      },
      MUIDataTableBodyCell: {
        root: {
          fontFamily: 'Open Sans',
          fontWeight: '400',
          color: '#373737',
          fontSize: '14px',
          padding: '5px 15px 3px 0',
        },
      },
      MuiTableCell: {
        root: {
          padding: '8px 8px 8px 0',
        },
        footer: {
          border: 'none',
        },
      },
      MuiTableHead: {
        root: {
          position: 'sticky',
          top: '0',
          borderBottom: 'transparent',
          boxShadow: `0 0 0 6px ${colorDark}`,
          backgroundColor: '#ffffff',
          zIndex: '300',
        },
      },
      MuiTableRow: {
        root: {
          '&.MuiTableRow-hover': {
            '&:hover': {
              backgroundColor: '#DEEBFF',
              cursor: 'default',
            },
          },
        },
      },
      MUIDataTablePagination: {
        tableCellContainer: {
          paddingRight: '60px',
        },
      },
      MuiTablePagination: {
        caption: {
          fontFamily: 'Open Sans',
          fontWeight: '400',
          color: '#373737',
          fontSize: '14px',
        },
        actions: {
          fontFamily: 'Open Sans',
          fontWeight: '400',
          color: '#373737',
          fontSize: '14px',
        },
      },
      MUIDataTableJumpToPage: {
        caption: {
          fontFamily: 'Open Sans',
          fontWeight: '400',
          color: '#373737',
          fontSize: '14px',
        },
      },
      MuiSelect: {
        selectMenu: {
          minHeight: 'none',
          color: '#043544',
        },
      },
      MuiTypography: {
        root: {
          fontFamily: 'Open Sans',
          fontWeight: '400',
          color: '#373737',
          fontSize: '14px',
        },
        body1: {
          fontFamily: 'Open Sans',
          fontWeight: '400',
          color: '#373737',
          fontSize: '14px',
        },
      },
      MUIDataTableViewCol: {
        label: {
          fontFamily: 'Open Sans',
          fontWeight: '400',
          color: '#373737',
          fontSize: '14px',
        },
        title: {
          fontFamily: 'Open Sans',
          fontWeight: '400',
          color: '#373737',
          fontSize: '14px',
        },
      },
    },
  });
};

export { viaMuiTheme };
