import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import styled from 'styled-components';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, useFieldArray } from 'react-hook-form';

import StyledIcon from '../../../../styledComponents/styles/Icon.styled';
import Checkbox from '../../../../styledComponents/styles/Checkbox.styled';
import { Paragraph } from '../../../../styledComponents/styles/Paragraph.styled';
import DropdownSelect from '../../../../styledComponents/styles/DropdownSelect.styled';
import { AccentButtonMediumNunito, ButtonMediumNunito } from '../../../../styledComponents/styles/Buttons.styled';

import { getProgram } from '../../../../actions/programBrochure';
import { updateApplicationSubmission } from '../../actions/travelerApplicationsActions';
import { fetchProgramTerms, fetchTravelerPrograms } from '../../../../actions/reportsActions';
import { isEmpty } from '../../../../helpers/replaceLodash';

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 45px;
`;

const CheckboxContainer = styled.div`
  max-width: 525px;
`;

const ProgramContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 13px;
  margin-bottom: 45px;
`;

const ButtonDiv = styled.div`
  margin-top: 30px;
  margin-right: 22px;
`;

const InputContainer = styled.div`
  flex: 1;
  margin: 20px 0;
  text-align: left;
`;

const MainContainer = styled.div`
  margin-top: 46px;
`;

const Number = styled.div`
  display: flex;
  align-items: center;
  margin-right: 13px;
  font-family: 'Nunito', sans-serif;
  font-weight: 700;
  font-size: 1rem;
  color: #373737;
  line-height: 20px;
`;

const OptionContainer = styled.span`
  display: flex;
  margin-bottom: 12px;
`;

const OtherOptionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 22px 0 34px 30px;
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  color: #446372;
  letter-spacing: 0.0015em;
  text-decoration-line: underline;
  line-height: 120%;
  cursor: pointer;
`;

const ParagraphBold = styled(Paragraph)`
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
  font-size: 1.125rem;
  color: #373737;
  text-align: left;
  letter-spacing: 0.001em;
  line-height: 120%;
`;

const PreferredProgram = styled(Paragraph)`
  margin-top: 45px;
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
  font-size: 1.125rem;
  color: #373737;
  text-align: left;
  letter-spacing: 0.0025em;
  line-height: 120%;
`;

const RemoveOption = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px;
  cursor: pointer;
`;

const SubText = styled(Paragraph)`
  margin-left: 10px;
`;

const ProgramDiv = styled.div`
  display: flex;
  width: 513px;
  padding: 30px 35px 30px 42px;
  background-color: #ffffff;
  border: 1px solid #d7d7d7;
  border-radius: 15px;
  box-shadow: 0px 10px 20px rgba(132, 131, 131, 0.3);
`;

const ImageDiv = styled.div`
  align-self: center;
  flex: 85px 0;

  img {
    width: 66px;
    height: 66px;
    border-radius: 50%;
  }
`;

const DetailsDiv = styled.div`
  flex: 1;
  flex-direction: row;
`;

const DetailProgramTitle = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 1rem;
  color: #373737;
  letter-spacing: 0.0025em;
  line-height: 120%;

  span {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: ${({ customBranding }) => (customBranding ? customBranding : '#516c4f')};
  }
`;

const DetailRangeDates = styled.div`
  display: inline-block;
  flex: 1;
  padding-top: 10px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  color: #373737;
  text-align: center;
  line-height: 16px;

  span {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: ${({ customBranding }) => (customBranding ? customBranding : '#516c4f')};
  }

  svg {
    position: relative;
    display: inline-flex;
    align-self: center;
    margin-right: 5px;
    margin-bottom: 3px;
  }
`;

const AlternateContainer = styled.div`
  display: grid;
  grid-template-columns: 325px 325px;
  margin-top: 32px;
`;

const DropdownContainer = styled.div`
  margin-right: 17px;
`;

export default function ApplicationAlternates({ forceSaveState, onNext, onSave }) {
  const dispatch = useDispatch();
  const userProfile = useSelector(state => state.profile);
  const program = useSelector(state => state.programBrochure);
  const alternates = useSelector(state => state.programBrochure.meta);
  const programs = useSelector(state => state.reports.fetchTravelerPrograms.data);
  const programTerms = useSelector(state => state.reports.fetchProgramTerms.data);
  const { 'is_traveler?': isTraveler, admin_sign_in: isAdmin } = useSelector(state => state.profile.currentUser);
  const {
    customAliases,
    info: { org_timezone: orgTimeZone },
    brandingTheme: {
      themes: { theme_color_dark: customBranding },
    },
  } = useSelector(state => state.currentOrganization);
  const {
    applicationSubmission: { id: submissionId, attributes: submission },
  } = useSelector(state => state.travelerApplicationSubmissions);

  const [dateRange, setDateRange] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [minAlternates, setMinAlternates] = useState(0);
  const [availableTerms, setAvailableTerms] = useState([]);
  const [filteredPrograms, setFilteredPrograms] = useState();
  const [selectedProgram, setSelectedProgram] = useState([]);
  const [declineAlternates, setDeclineAlternates] = useState(false);

  const { control, handleSubmit, formState, getValues, register, reset, setValue } = useForm({
    mode: 'onChange',
    shouldUnregister: false,
    defaultValues: {
      dropdown_question_options_attributes: [{ value: '', order: 0, id: '', termRangeId: null }],
    },
  });

  const { fields, append, prepend, remove } = useFieldArray({
    control,
    name: 'dropdown_question_options_attributes',
  });

  useEffect(() => {
    dispatch(fetchProgramTerms('internal'));
  }, []);

  // Get Program List for Dropdown
  useEffect(
    () => {
      let programList = [];

      if (!!programs && programs.length > 0) {
        programList = programs
          .filter(
            program =>
              program.attributes.program_term_start_dates.length > 0 && program.attributes.status === 'Published',
          )
          .map(program => {
            return { value: program.attributes.program_id, label: program.attributes.program_name };
          })
          .sort((a, b) => a.label.localeCompare(b.label));
      }

      setFilteredPrograms(programList);
    },
    [programs],
  );

  useEffect(
    () => {
      let terms = [];

      if (!!programTerms && programTerms.length > 0) {
        terms = programTerms
          .filter(dates => {
            const nowDate = moment()
              .tz(orgTimeZone)
              .format('MMMM D, YYYY');

            return moment(dates.term_start_date).isSameOrAfter(nowDate);
          })
          .filter(program => {
            return program.program_private !== 'Yes';
          })
          .map(program => {
            return {
              program_id: program.program_id,
              program_name: program.program_name,
              value: program.program_range_id,
              label: `${moment(new Date(program.term_start_date)).format('MMM D[,] YYYY')} -${moment(
                new Date(program.term_end_date),
              ).format('MMM D[,] YYYY')} `,
            };
          })
          .sort((a, b) => a.label.localeCompare(b.label));

        setAvailableTerms(terms);
      }
    },
    [programTerms],
  );

  // To Display Card
  useEffect(
    () => {
      if (submission) {
        let sDate = null;
        let eDate = null;

        if (submission.program_range.exact_dates) {
          if (submission.program_range.start_date) {
            sDate = moment(new Date(submission.program_range.start_date)).format('MMM D[,] YYYY');
          }

          if (submission.program_range.start_date) {
            eDate = moment(new Date(submission.program_range.end_date)).format('MMM D[,] YYYY');
          }
        } else {
          if (submission.program_range.start_date) {
            sDate = moment(new Date(submission.program_range.start_date)).format('MMM [] YYYY');
          }

          if (submission.program_range.start_date) {
            eDate = moment(new Date(submission.program_range.end_date)).format('MMM [] YYYY');
          }
        }

        setDateRange(`${sDate}${eDate ? ' - ' + eDate : ''}`);
      }
    },
    [submission],
  );

  useEffect(
    () => {
      if (!program.meta.id) {
        setIsLoading(true);

        dispatch(fetchTravelerPrograms());

        if (isTraveler && !isAdmin) {
          dispatch(getProgram(submission.program_range.program_id, 'traveler'));
        } else if (isAdmin) {
          dispatch(getProgram(submission.program_range.program_id, 'client'));
        } else {
          dispatch(getProgram(submission.program_range.program_id, 'visitor'));
        }
      }

      fetchProgramTerms('internal');
      fetchProgramTerms('authorized');

      if (!program.loading) {
        setMinAlternates(alternates.minAlternates);

        setTimeout(() => {
          setIsLoading(false);
        }, 500);
      }
    },
    [userProfile, program.loading],
  );

  useEffect(
    () => {
      const values = getValues();

      if (!isEmpty(values) && forceSaveState) {
        onSubmitHandler(values);
      }
    },
    [forceSaveState],
  );

  const onSubmitHandler = data => {
    if (data) {
      const submitValues = {
        alternate_program_ranges_attributes: !declineAlternates
          ? []
          : data.dropdown_question_options_attributes.map((alternate, index) => {
              return alternate._destroy
                ? { id: alternate.id, program_range_id: alternate.termId, ranking: index, _destroy: alternate._destroy }
                : { id: alternate.id, program_range_id: alternate.termId, ranking: index };
            }),
      };

      dispatch(
        updateApplicationSubmission({
          // TODO: Add submission status ID (see DeferApplicationModal component for an example)
          submissionId,
          body: submitValues,
          type: submission.submission_type.identifier,
        }),
      );
    }
  };

  return (
    <MainContainer>
      <form id="addAlternates" onSubmit={handleSubmit(onSubmitHandler)}>
        <ParagraphBold>Your application is almost complete! Now choose Alternates</ParagraphBold>
        <SubText>Last step - choose backup options in case this program term reaches capacity.</SubText>
        <PreferredProgram>Preferred {submission.program_range.program_title} Term</PreferredProgram>
        <ProgramContainer>
          <ProgramDiv>
            <ImageDiv>
              <img
                src={submission.program_range.program_image.program_square.url}
                alt={submission.program_range.program_title}
              />
            </ImageDiv>
            <DetailsDiv>
              <DetailProgramTitle customBranding={customBranding}>
                {submission.program_range.program_title}
              </DetailProgramTitle>
              <DetailRangeDates customBranding={customBranding}>
                <StyledIcon type="Form_date" color="#446372" size="20px" />
                {submission.program_range.term_name ? `${submission.program_range.term_name}: ` : null}
                {dateRange}
              </DetailRangeDates>
            </DetailsDiv>
          </ProgramDiv>
        </ProgramContainer>
        <CheckboxContainer>
          <PreferredProgram>Alternates {customAliases.alias_programs}</PreferredProgram>
          <Checkbox
            label={`I do not want to choose alternate ${customAliases.alias_programs.toLowerCase()} because I only want to go on my preferred ${customAliases.alias_program.toLowerCase()}.`}
            openSans
            defaultChecked={declineAlternates}
            onChange={() => setDeclineAlternates(!declineAlternates)}
          />
        </CheckboxContainer>

        {fields.filter(f => !f._destroy).map((item, index) => {
          return (
            <InputContainer key={`${item.id}-${item.id}`}>
              {!item._destroy && (
                <OptionContainer>
                  <Number>{index + 1}. </Number>
                  <AlternateContainer>
                    <DropdownContainer>
                      <DropdownSelect
                        aria-label="Select a program"
                        name={`dropdown_question_options_attributes[${index}]`}
                        id={`dropdown_question_options_attributes[${index}]`}
                        placeholder={`Select a ${customAliases.alias_program}`}
                        openSans
                        label={`${customAliases.alias_program} Name`}
                        fontColor="#373737"
                        options={filteredPrograms}
                        isDisabled={!declineAlternates}
                        defaultValue={
                          !!filteredPrograms &&
                          filteredPrograms.filter(
                            option =>
                              option.value.toString() ===
                              getValues(`dropdown_question_options_attributes[${index}]`).value,
                          )
                        }
                        onChange={e => {
                          setSelectedProgram([e]);
                          const foundItem = fields.find(f => f.id === item.id);
                          foundItem.value = e.value;
                          setValue(
                            `dropdown_question_options_attributes[${index}]`,
                            { value: e, order: index, id: e, termId: null },
                            {
                              shouldValidate: true,
                              shouldDirty: true,
                              shouldTouch: true,
                            },
                          );
                        }}
                        fontWeight={600}
                      />
                    </DropdownContainer>
                    <DropdownContainer>
                      <DropdownSelect
                        placeholder="Select a Term"
                        name={`dropdown_question_options_attributes[${index}]`}
                        id={`dropdown_question_options_attributes[${index}]`}
                        openSans
                        isDisabled={
                          !declineAlternates ||
                          (getValues(`dropdown_question_options_attributes[${index}]`).value?.length === 0 &&
                            !getValues(`dropdown_question_options_attributes[${index}]`)._destroy)
                        }
                        label="Term Name"
                        options={availableTerms.filter(program => {
                          return (
                            program.program_id ===
                            getValues(`dropdown_question_options_attributes[${index}]`).value?.toString()
                          );
                        })}
                        onChange={e => {
                          const foundItem = fields.find(f => f.id === item.id);
                          foundItem.value = e.value;
                          setValue(
                            `dropdown_question_options_attributes[${index}]`,
                            {
                              value: getValues(`dropdown_question_options_attributes[${index}]`).value,
                              order: index,
                              id: getValues(`dropdown_question_options_attributes[${index}]`).id,
                              termId: e,
                            },
                            {
                              shouldValidate: true,
                              shouldDirty: true,
                              shouldTouch: true,
                            },
                          );
                        }}
                        fontColor="#373737"
                        fontWeight={600}
                      />
                    </DropdownContainer>
                  </AlternateContainer>

                  {fields.filter(f => !f._destroy).length === 1 ? null : (
                    <RemoveOption
                      onClick={() => {
                        const foundItem = fields.find(f => f.id === item.id);
                        foundItem._destroy = true;
                        setValue(`dropdown_question_options_attributes`, fields, {
                          shouldValidate: true,
                          shouldDirty: true,
                          shouldTouch: true,
                        });
                      }}
                      tabIndex={0}
                      onKeyDown={e => {
                        if (e.keyCode === 13) {
                          const foundItem = fields.find(f => f.id === item.id);
                          foundItem._destroy = true;
                          setValue(`dropdown_question_options_attributes`, fields, {
                            shouldValidate: true,
                            shouldDirty: true,
                            shouldTouch: true,
                          });
                        }
                      }}
                    >
                      <StyledIcon type="Close" color="#446372" size="24px" />
                    </RemoveOption>
                  )}
                </OptionContainer>
              )}
            </InputContainer>
          );
        })}
        {fields.filter(f => !f._destroy).length < minAlternates ? (
          <OtherOptionContainer
            onClick={() => {
              const nextOrder = fields.length - 1;
              append({ id: '', value: '', order: nextOrder });
            }}
            onKeyDown={e => {
              if (e.keyCode === 13) {
                const nextOrder = fields.length - 1;
                append({ id: '', value: '', order: nextOrder });
              }
            }}
            tabIndex={0}
          >
            <StyledIcon type="Add" color="#446372" size="16px" />
            Add Another Alternate
          </OtherOptionContainer>
        ) : null}

        <ButtonContainer>
          <ButtonDiv>
            <ButtonMediumNunito type="button" form="addAlternates" onClick={onSave}>
              Save
            </ButtonMediumNunito>
          </ButtonDiv>
          <ButtonDiv>
            <AccentButtonMediumNunito type="submit" form="addAlternates" onClick={onNext}>
              Next
            </AccentButtonMediumNunito>
          </ButtonDiv>
        </ButtonContainer>
      </form>
    </MainContainer>
  );
}

ApplicationAlternates.propTypes = {
  onNext: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  forceSaveState: PropTypes.bool.isRequired,
};
