import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import ResumeIcon from '../../icons/ResumeIcon';
import ApplicationStatus from './ApplicationStatus';
import SimpleClockIcon from '../../icons/SimpleClockIcon';

import { breakpoints } from '../../../utils/breakpoints';

const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-width: 50%;
  background-color: #ffffff;
  border: 1px solid #d7d7d7;
  border-radius: 15px;
  box-shadow: 0px 10px 20px rgba(132, 131, 131, 0.3);

  @media ${breakpoints.desktop} {
    flex-direction: row;
  }
`;

const OuterInfoContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  padding: 2.3125rem 2.529375rem 2.3125rem 1.79875rem;
`;

const InnerInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const Heading = styled.h4`
  margin-bottom: 0;
  font-family: 'Nunito', sans-serif;
  font-weight: 500;
  font-size: 1.125rem;
  color: #373737;
  letter-spacing: 0.06em;
  line-height: 120%;

  &::after {
    content: '';
    display: block;
    width: 30%;
    padding-top: 0.4375rem;
    border-bottom: 1px solid var(--theme_color_accent);
  }

  @media ${breakpoints.desktop} {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 1rem;
    letter-spacing: 0.0025em;
  }
`;

const Deadline = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  color: #373737;
  letter-spacing: 0.0025em;
  line-height: 120%;

  svg {
    width: 12px;
    height: 12px;
    color: var(--theme_color_accent);
  }
`;

const LinkContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  padding: 0.875rem 0.5rem;
  background-color: ${props => (props.canEdit ? '#446372' : '#666666')};
  border-radius: 0px 0px 10px 10px;

  @media ${breakpoints.desktop} {
    padding: ${props => (props.canEdit ? '3.82125rem 2rem' : '3.82125rem 3.5rem')};
    border-radius: 0px 14px 14px 0px;
  }
`;

const ViewLink = styled(Link)`
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 1rem;
  color: #ffffff;
  text-decoration-line: underline;
  line-height: 20px;

  &:hover {
    color: #ffffff;
    text-decoration-line: none;
  }

  &:focus {
    outline: none;
  }

  &:focus-visible {
    outline: 2px solid #ffffff;
    outline-offset: 1px;
  }

  @media ${breakpoints.desktop} {
    font-size: 0.875rem;
  }
`;

const ApplicationCard = () => {
  const { admin_sign_in: isAdmin } = useSelector(state => state.profile.currentUser);
  const permissions = useSelector(state => state.profile.permissions);
  const travelerSubmission = useSelector(state => state.forms.travelerSubmission);
  const {
    info: { org_timezone: timezone },
    customAliases: { alias_program: programAlias },
  } = useSelector(state => state.currentOrganization);

  const canViewApplication =
    !isAdmin || permissions?.traveler_applications_and_forms_settings?.includes('view_traveler_applications');
  const canEditApplication =
    !isAdmin || permissions?.traveler_applications_and_forms_settings?.includes('edit_traveler_applications');

  const [submission, setSubmission] = useState(travelerSubmission?.data || {});
  const [submissionId, setSubmissionId] = useState();
  const [canEdit, setCanEdit] = useState();

  const formattedDate = moment(submission.deadline)
    .tz(timezone)
    .format('MMM D, YYYY');
  const formattedTime = moment(submission.deadline)
    .tz(timezone)
    .format('h:mma z');

  useEffect(() => {
    if (!travelerSubmission.loading && travelerSubmission.data) {
      setSubmission(travelerSubmission.data);
      setSubmissionId(travelerSubmission.data.id);
      setCanEdit(
        canEditApplication &&
          submission.submission_status.name === 'Incomplete' &&
          moment(submission.deadline).isAfter(moment()),
      );
    }
  }, [travelerSubmission]);

  const buildLink = submissionId => {
    if (isAdmin) {
      return `/client/form-review/${submissionId}`;
    }
    return `/traveler/form-submission/${submissionId}`;
  };

  return (
    submission && (
      <Card>
        <OuterInfoContainer>
          <ResumeIcon />
          <InnerInfoContainer>
            <ApplicationStatus />
            <Heading tabIndex={0} role='alert' aria-live='assertive'>
              {programAlias} Application
            </Heading>
            {submission.deadline && submission.submission_status.name !== 'Withdrawn' && (
              <Deadline>
                <SimpleClockIcon />
                <span tabIndex={0} role="">{`Deadline: ${formattedDate} at ${formattedTime}`}</span>
              </Deadline>
            )}
          </InnerInfoContainer>
        </OuterInfoContainer>
        {(canViewApplication || canEditApplication) && (
          <LinkContainer canEdit={canEdit}>
            <ViewLink to={buildLink(submissionId)}>{canEdit ? 'View / Edit' : 'View'}</ViewLink>
          </LinkContainer>
        )}
      </Card>
    )
  );
};

export default ApplicationCard;
