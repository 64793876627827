import React from 'react';
const PersonIcon = (props) => {
  return(
  
    <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Admin-View" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Admin-View-of-Traveler-Profile:-Messages" transform="translate(-920.000000, -1641.000000)" fill={props.color}>
              <g id="Meta" transform="translate(357.000000, 1609.000000)">
                  <g id="Group-2" transform="translate(75.000000, 0.000000)">
                      <g id="Group-4">
                          <g id="Recipients" transform="translate(488.000000, 30.000000)">
                              <path d="M3.43662416,9.73584906 C3.43662416,6.16389937 6.2829488,3.25786164 9.78150225,3.25786164 C13.2800557,3.25786164 16.1263803,6.16389937 16.1263803,9.73584906 C16.1263803,11.598239 15.3523463,13.2792453 14.1158137,14.4620545 C13.7707263,14.7921593 13.389664,15.0833962 12.9792797,15.3289308 C12.0397039,15.8910273 10.9469065,16.2138365 9.78150225,16.2138365 C8.61609804,16.2138365 7.52330059,15.8910273 6.58372476,15.3289308 C6.17334051,15.0833962 5.79227822,14.7921593 5.44719078,14.4620545 C4.21065822,13.2792453 3.43662416,11.598239 3.43662416,9.73584906 M2.78312225,16.4061635 C1.27698372,17.9438994 0.316545763,19.8901048 0,22 L1.25000258,22 C1.69389765,19.4485535 3.21243847,17.2716562 5.30936935,15.9763522 C6.56368398,16.915891 8.11010942,17.4716981 9.78150225,17.4716981 C11.4528951,17.4716981 12.9993205,16.915891 14.2536352,15.9763522 C16.350566,17.2716562 17.8691069,19.4485535 18.3130019,22 L19.5630045,22 C19.2464587,19.8901048 18.2860208,17.9438994 16.7798823,16.4061635 C16.3000534,15.9163103 15.7794449,15.4833543 15.2261879,15.1097275 C16.5453476,13.7170231 17.3583955,11.8216771 17.3583955,9.73584906 C17.3583955,5.47027254 13.9594299,2 9.78150225,2 C5.60357457,2 2.204609,5.47027254 2.204609,9.73584906 C2.204609,11.8216771 3.01765687,13.7170231 4.33681658,15.1097275 C3.78355963,15.4833543 3.26295109,15.9163103 2.78312225,16.4061635" id="recipient"></path>
                          </g>
                      </g>
                  </g>
              </g>
          </g>
      </g>
    </svg>
  
  )
}
export default (PersonIcon);