import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { TermInfoCardCTA } from './Term.styled';
import { programIdType } from '../../../../utils/types';
import { useSelector } from 'react-redux';

const DisabledLink = styled(Link)`
  cursor: not-allowed;
`;

const VisitorLink = styled.div`
  cursor: pointer;
  text-decoration-line: underline;
  color: #043544;
`;

export default function Cta({ status, programId, termId }) {
  const { 'is_traveler?': isTraveler, admin_sign_in: isAdmin } = useSelector(state => state.profile.currentUser);

  return (
    <>
      {status === 'active' && (
        <TermInfoCardCTA disabled={isAdmin}>
          {isAdmin ? (
            <DisabledLink to="/" className="disabled" onClick={event => event.preventDefault()}>
              Apply now
            </DisabledLink>
          ) : isTraveler ? (
            <Link to={`/program_brochure/${programId}/apply?termId=${termId}`} className="active">
              Apply now
            </Link>
          ) : (
            <VisitorLink onClick={() => window.location.assign(`/?jmp=${window.location.href}`)}>Apply now</VisitorLink>
          )}
        </TermInfoCardCTA>
      )}

      {status === 'coming-soon' && <TermInfoCardCTA>Coming Soon</TermInfoCardCTA>}

      {status === 'closed' && <TermInfoCardCTA>Application Closed</TermInfoCardCTA>}
    </>
  );
}

Cta.propTypes = {
  status: PropTypes.string.isRequired,
  programId: programIdType,
  termId: PropTypes.number.isRequired,
};
