import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

//import Modal from './Modal';
import Modal from 'react-responsive-modal';
import modalCss from 'react-responsive-modal/styles.css';
import { compose } from 'recompose';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import CloseIcon from '../../../../travelerProfile/components/icons/CloseIcon';

import { ModalButton } from './Modal.styled';
import hearticon from './../../../../../shared/images/heart-icon.png';

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-family: Open Sans, sans-serif;
  font-size: 16px;
  font-weight: 400;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 25px 25px;
  border-bottom: 1px solid #9FABAE;
  h1 {
    color: #373737;
    text-align: center;
    /* Headline 5 */
    font-family: Nunito;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 30px */
}
`;

const Button = styled.button`
   outline: none;
    border: none;
    padding: 0;
    background: none;
    &:focus-visible {
      outline: 2px solid #255a94;
      outline-offset: 1px;
    }
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  background-color: #ebebeb;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  button {
    margin: 15px;
  }
`;

const FooterButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 20px;
  button {
    margin: 15px;
  }
`;
// const Header = styled.h1`
//   color: #0b3544;
//   font-size: 25px;
//   padding: 20px 30px;
//   padding-bottom: 20px;
//   border-bottom: 3px solid #ebebeb;
//   font-weight: 300;
// `;

const Instructions = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #373737;
  width: 90%;
  margin: 15px auto;
`;

const ImageTag = styled.img`
  width: 135px;
`;
const LeftContainer = styled.div`
  display: flex;
  float: left;
  width: 30%;
  justify-content: center;
  align-items: center;
  height: 118px;
`;
const RightContainer = styled.div`
  display: block;
  float: right;
  width: 70%;
`;
const Clear = styled.div`
  clear: both;
`;
const BoldInstructions = styled.div`
  font-weight: 600;
  margin-bottom: 20px;
`;
const NormalInstructions = styled.div`
  margin-bottom: 20px;
`;

const SmallText = styled.div`
  font-size: 12px;
`;

const LinkTag = styled.a`
  text-decoration: none;
  color: #124a67;
  &:hover {
    text-decoration: none;
  }
`;

export const enhance = compose(withStyles(modalCss));

 function FavoriteProgramModal(props) {
  const customAliases = useSelector(state => state.profile.customAliases);

  const handleFavoriteSubmit = () => {
    props.onSubmit();
    props.onClose(false);
  };

  const handleClose = () => {
    props.onClose(false);
  };

  return (
    // <Modal
    //   show={props.show}
    //   title={`About ${customAliases.alias_favorite || 'Favorite'} ${customAliases.alias_programs}`}
    //   close={handleClose}
    //   styles={{ width: '519px' }}
    // >
    <Modal
      blockScroll={false}
      open={props.show}
      onClose={handleClose}
      center
      closeOnEsc
      closeOnOverlayClick
      showCloseIcon={false}
      styles={{
        modal: {
          padding: '0px',
          borderRadius: '5px',
          width: '520px',
        },
      }}
    >
      <FlexContainer>
      <Header>
        <h1 >{`About ${customAliases.alias_favorite || 'Favorite'} ${customAliases.alias_programs}`}</h1>
        <Button onClick={handleClose} aria-label='close'>
          <CloseIcon fillColor={`#9FABAE`} />
        </Button>
      </Header>
        <Instructions>
          <div>
            <LeftContainer>
              <ImageTag src={hearticon} alt='Add to favorite' />
            </LeftContainer>
            <RightContainer>
              <BoldInstructions>
                By selecting "{customAliases.alias_favorite || 'Favorite'}" you can save programs to filter, revisit or
                let {customAliases.alias_program} admins know you're interested.
              </BoldInstructions>
              <NormalInstructions>
                This tool will help you find {customAliases.alias_programs} you like, and plan your study abroad journey
                effectively.
              </NormalInstructions>
            </RightContainer>
            <Clear />
          </div>
          <SmallText>
            By using the favorite tool, you consent to share your contact information to your selected{' '}
            {customAliases.alias_program} administrators to receive additional {customAliases.alias_program}{' '}
            information. Learn more in our{' '}
            <LinkTag href="https://www.viatrm.com/privacy-policy/" target="_blank">
              privacy policy
            </LinkTag>
            .
          </SmallText>
        </Instructions>
        <FooterContainer>
          <FooterButtonContainer>
            <ModalButton type="button" className="secondary" onClick={handleClose}>
              Cancel
            </ModalButton>
            <ModalButton type="button" className="primary" onClick={handleFavoriteSubmit}>
              Okay
            </ModalButton>
          </FooterButtonContainer>
        </FooterContainer>
      </FlexContainer>
    </Modal>
  );
}

export default enhance(FavoriteProgramModal);