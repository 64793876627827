import React from 'react'
import PropTypes from 'prop-types'
import { HeaderIconButton } from '../Header.styled'

export default function WhatsApp({ url, title, clickHandler }) {
  const shareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(title + ' | ' + url)}`

  const handleClick = () => {
    clickHandler(shareUrl)
  }

  return (
    <HeaderIconButton
      type="button"
      aria-label="Share on WhatsApp"
      onClick={() => handleClick(shareUrl)}
    >
      <svg
        style={{ height: '1.5rem', width: '1.5rem' }}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        aria-hidden="true"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.44 4.552a10.413 10.413 0 0 0-7.396-3.051C6.281 1.5 1.59 6.169 1.588 11.907a10.341 10.341 0 0 0 1.396 5.203L1.5 22.5l5.543-1.446a10.483 10.483 0 0 0 4.997 1.266h.004c5.762 0 10.453-4.669 10.456-10.407a10.32 10.32 0 0 0-3.06-7.36Zm-7.396 16.011h-.004a8.707 8.707 0 0 1-4.423-1.205L7.3 19.17l-3.29.859.879-3.192-.207-.328a8.6 8.6 0 0 1-1.329-4.602c0-4.768 3.9-8.648 8.694-8.648a8.672 8.672 0 0 1 8.688 8.655c-.002 4.769-3.9 8.65-8.69 8.65Zm4.767-6.478c-.261-.13-1.547-.759-1.785-.846-.238-.086-.414-.13-.588.13-.174.261-.675.844-.827 1.02-.153.176-.305.195-.566.065-.261-.13-1.104-.405-2.102-1.29-.776-.69-1.3-1.541-1.453-1.801-.152-.26-.016-.402.115-.531.117-.117.26-.304.392-.456.13-.152.174-.26.26-.434.087-.173.044-.325-.02-.455-.066-.13-.589-1.41-.806-1.93-.213-.508-.428-.44-.588-.447a10.61 10.61 0 0 0-.501-.01.962.962 0 0 0-.697.326c-.24.26-.914.89-.914 2.17 0 1.278.937 2.515 1.067 2.689.129.173 1.842 2.8 4.463 3.926.486.209.984.391 1.49.548.625.197 1.195.17 1.645.103.502-.075 1.546-.63 1.764-1.237.217-.607.217-1.127.152-1.236-.065-.108-.24-.174-.501-.304Z"
          fill="#4CAF50"
        />
      </svg>
    </HeaderIconButton>
  )
}

WhatsApp.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  clickHandler: PropTypes.func.isRequired,
}
