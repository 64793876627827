import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import s from './ProgramAdditionalDocuments.css';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import ProgramFileUploader from './ProgramFileUploader';
import { parseURI } from '../../../../helpers/UriHelper';
import FileSaver from 'file-saver';
import { keys } from '../../../../config/keys';
import downloadIcon from '../../../../../public/img/download.svg';
import trashIcon from '../../../../../public/img/ico-trash.svg';
import { deleteProgramAttachment, updateProgramAttachment } from '../../../../actions/programBrochureActions';
import { errorFileNameBlank } from '../../../../shared/helpers/UploaderMessages';
import { Cookies } from 'react-cookie';

class ProgramAdditionalDocuments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: '',
      error: '',
      fileTitle: '',
    };
    this.updateProgramTitle = this.updateProgramTitle.bind(this);
  }

  download = (fileUrl, fileName) => {
    const cookies = new Cookies();
    const token = cookies.get('token');
    const uri = parseURI(fileUrl);

    fetch(`${keys.baseUri}/files/download?f=${uri.path}`, {
      headers: token,
    })
      .then(function(response) {
        return response.blob();
      })
      .then(function(blob) {
        FileSaver.saveAs(blob, fileName.replace(/ /g, '_'));
      });
  };

  updateProgramTitle = (e, file) => {
    this.setState({
      file,
      fileTitle: e.target.value,
    });
  };

  saveUpdatedProgramTitle = file => {
    if (this.state.fileTitle === '') {
      this.setState({
        error: errorFileNameBlank,
      });
    } else {
      this.props.updateProgramAttachment({
        id: file.id,
        program_id: this.props.id,
        title: this.state.fileTitle,
        file: file.file,
      });

      this.setState({
        file: '',
        fileTitle: '',
        error: '',
      });
    }
  };

  deleteDocument = file => {
    this.props.deleteProgramAttachment({
      id: file.id,
      program_id: this.props.id,
      title: this.state.fileTitle,
      file: file.file,
    });
  };

  render() {
    let { currentFiles } = this.props;
    if (this.props.status == 'view') {
      return (
        <div className={s.wrapper}>
          <div className={s.section}>
            {this.props.currentFiles.length > 0 && (
              <>
                <h2 className={s.header}>Downloadable Documents</h2>
                <div className={s.downloadDocsSection}>
                  <p className={s.sectionTitle}>List of Downloadable Documents:</p>
                  {currentFiles.map(file => {
                    return (
                      <div key={file.id} className={s.sectionAnswers}>
                        <p className={s.fileTitle}>{file.title}</p>
                        <img
                          src={downloadIcon}
                          className={s.actionButton}
                          onClick={() => this.download(file.file.url, file.title)}
                        />
                      </div>
                    );
                  })}
                </div>
              </>
            )}
          </div>
        </div>
      );
    } else if (this.props.status == 'edit') {
      return (
        <div className={s.wrapper}>
          <div className={s.section}>
            <h2 className={s.header}>Downloadable Documents</h2>
            <div className={s.downloadDocsSection}>
              <p className={s.sectionTitle}>Upload New Document </p>
              <p className={s.sectionTitleSubHeader}>Please Note: Save changes first before adding uploads</p>
              <div className={s.Row}>
                <ProgramFileUploader id={this.props.id} currentFiles={this.props.currentFiles} />
              </div>
            </div>
            <div className={s.downloadDocsSection}>
              <p className={s.sectionTitle}>Uploaded Documents</p>
              {this.state.error ? (
                <React.Fragment>
                  <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <g id="Traveler-View" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                      <g id="Program-Application-(Traveler-View)" transform="translate(-844.000000, -2271.000000)">
                        <g id="1.-Personal-Information" transform="translate(125.000000, 1119.000000)">
                          <g id="info" transform="translate(720.000000, 1146.000000)">
                            <g id="Group-3">
                              <circle
                                id="Oval-Copy"
                                stroke="#FFFFFF"
                                strokeWidth="2"
                                fill="#DD2121"
                                cx="9"
                                cy="16"
                                r="9"
                              />
                              <text
                                id="!"
                                fontFamily="Intro-Bold-Italic, Intro Bold Italic"
                                fontSize="12"
                                fontStyle="italic"
                                fontWeight="bold"
                                linespacing="33"
                                fill="#FFFFFF"
                              >
                                <tspan x="7" y="20">
                                  !
                                </tspan>
                              </text>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                  <span style={{ marginLeft: '4px', color: 'red' }}>{this.state.error}</span>
                </React.Fragment>
              ) : null}
              {this.props.currentFiles !== undefined && (
                <div>
                  {this.props.currentFiles.map(file => {
                    return (
                      <div key={file.id} className={s.Row}>
                        <input
                          id={file.id}
                          name={file.id}
                          type="text"
                          className={s.handleTitleChangeInput}
                          defaultValue={file.title}
                          onChange={(e, file) => this.updateProgramTitle(e, file)}
                        />
                        <button className={s.saveActionButton} onClick={() => this.saveUpdatedProgramTitle(file)}>
                          Save
                        </button>
                        <img
                          src={downloadIcon}
                          className={s.actionButton}
                          onClick={() => this.download(file.file.url, file.title)}
                        />
                        <img src={trashIcon} className={s.trashButton} onClick={() => this.deleteDocument(file)} />
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      );
    } else if (this.props.status == 'new') {
      return (
        <div className={s.wrapper}>
          <div className={s.section}>
            <h2 className={s.header}>Downloadable Documents </h2>
            <div className={s.downloadDocsSection}>
              <p className={s.sectionTitle}>Upload New Document</p>
              <div className={s.sectionAnswers}>
                <p>
                  After saving your initial {this.props.alias_program.toLowerCase()} brochure you will be able to add
                  additional documentation here
                </p>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default compose(
  withStyles(s),
  connect(null, { deleteProgramAttachment, updateProgramAttachment }),
)(ProgramAdditionalDocuments);
