import styled from 'styled-components';

export const CardDetailItem = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 0.4375rem;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  color: #585858;
  letter-spacing: 0.0015em;
  line-height: 120%;

  svg {
    flex-shrink: 0;
    width: 15px;
    height: 15px;
    fill: #446372;
  }
`;
