import React, { useEffect, useState } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { useDispatch, useSelector } from 'react-redux';
import ProfileSelectInput from '../../../../sites/travelerProfile/components/base/inputs/ProfileSelectInput';
import { fetchWithdrawDefferedReason, getSubmissionStatuses } from '../../../../actions/formsActions';
import styles from './index.css';
import ProfileTextInput from '../../../../sites/travelerProfile/components/base/inputs/ProfileTextInput';

function ChangeStatusAction({ automationType = 'Application', value = null, setValue, reason = null, setReason, otherReason = "", setOtherReason }) {
  const dispatch = useDispatch();
  const submissionStatuses = useSelector(state => state.forms.submission_statuses);
  const { data: reasons, loading } = useSelector(state => state.forms.withdrawReason);
  const [statusOptions, setStatusOptions] = useState([]);
  const [reasonOptions, setReasonOptions] = useState([]);
  const [showWithdrawReason, setShowWithdrawReason] = useState(false);
  const [showOtherReason, setShowOtherReason] = useState(false);
  

  useEffect(() => {
    dispatch(getSubmissionStatuses(automationType === 'Application' ? 'application' : 'form'));
    dispatch(fetchWithdrawDefferedReason());
  }, []);

  useEffect(() => {

    if (submissionStatuses?.data?.length > 0) {
      let options = submissionStatuses?.data?.map((item) => {
        return {
          label: item?.attributes?.name,
          value: item?.id,
          identifier: item?.attributes?.identifier
        }
      });

      setStatusOptions([...options]);

    }

  }, [submissionStatuses?.data]);

  useEffect(() => {

    if (reasons?.data?.length > 0) {
      let options = reasons?.data?.map((item) => {
        return {
          label: item?.attributes?.display_text,
          value: item?.id,
          identifier: item?.type
        }
      });

      setReasonOptions([...options]);

    }

  }, [reasons?.data])


  useEffect(() => {

    let selectedStatus = statusOptions?.find(i => Number(i?.value) === Number(value)); 

    if (selectedStatus?.identifier === "deferred" || selectedStatus?.identifier === "withdrawn") {
      setShowWithdrawReason(true);
    }
    else {
      setShowWithdrawReason(false);
      // setReason(null); 
    }

  }, [value, statusOptions]);


  useEffect(() => {

    let selectedReason = reasonOptions?.find(i => i?.label === "Other"); 

    if(Number(selectedReason?.value) === Number(reason)){
      setShowOtherReason(true);
    }
    else{
      setShowOtherReason(false);
      // setOtherReason(""); 
    }

  }, [reason, reasonOptions]);


  const onChangeStatus = (val) => {
    setValue(val?.value);

    if(val?.identifier !== "withdrawn" || val?.identifier !== "deferred"){
      setReason(null); 
      setOtherReason(""); 
    }

  }

  const onChangeReason = (val) => {
    setReason(val?.value);

    if(val?.label !== "Other"){
      setOtherReason(""); 
    }

  }

  return (
    <div>
      
      <ProfileSelectInput
        placeholder={`Type to select ${automationType === "Application" ? "application" : "form"} status`}
        options={statusOptions}
        handleOnChange={onChangeStatus}
        value={statusOptions.find(i => Number(i.value) === Number(value))}
      />

      {
        showWithdrawReason && <>

          <div style={{ marginBottom: "20px" }}></div>

          <ProfileSelectInput
            label="Reason"
            showAstrik={true}
            placeholder={`Type to select reason`}
            options={reasonOptions}
            handleOnChange={onChangeReason}
            value={reasonOptions.find(i => Number(i.value) === Number(reason))}
          />

          {showOtherReason && <>

            <div style={{ marginBottom: "20px" }}></div>


            <ProfileTextInput
              label="Other"
              showAstrik={true}
              placeholder={"Enter reason"}
              value={otherReason}
              handleOnChange={(e) => setOtherReason(e.target.value)}
            />

          </>}



        </>
      }




    </div>
  );
}

export default withStyles(styles)(ChangeStatusAction);
