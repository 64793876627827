import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { breakpoints } from '../../utils/breakpoints';
import { buttonFocus } from '../../utils/shared/focusStyles';

const Banner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 1.25rem 0;
  background-color: #deecff;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 1rem;
  color: #4d4d4d;
  letter-spacing: 0.005em;
  line-height: 120%;

  @media ${breakpoints.tablet} {
    flex-direction: row;
    gap: 3.125rem;
  }
`;

const EditLink = styled(Link)`
  color: #446372;
  letter-spacing: -0.02em;
  text-decoration-line: underline;
  line-height: 22px;

  &:hover {
    color: #446372;
    text-decoration-line: none;
  }

  ${buttonFocus};
`;

export default function DocumentPreviewBanner() {
  const {
    applicationSubmission: { id: submissionId },
  } = useSelector(state => state.travelerApplicationSubmissions);

  return (
    <Banner>
      {`You're viewing a preview of your application.`}
      <EditLink to={`/traveler/application/${submissionId}`}>Edit Application</EditLink>
    </Banner>
  );
}
