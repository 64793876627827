import fetch from 'node-fetch';
import _clone from 'lodash/clone';
import {
  CREATE_TEMPLATE,
  TEMPLATE_UPDATE,
  GET_TEMPLATE,
  UPDATE_TEMPLATE_WITH_OUT_SYNC,
  UPDATE_TEMPLATE_STATE_ONLY,
} from './types';
import { keys } from '../config/keys';
import { Cookies } from 'react-cookie';

export function createTemplate(data) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/trm_templates`, {
      method: 'POST',
      headers: token,
      body: JSON.stringify(data),
    }).then(response => {
      response.json().then(res => {
        dispatch({
          type: CREATE_TEMPLATE,
          payload: res,
        });
        window.location = '/client/application/' + res.id + '/edit';
      });
    });
  };
}

export function getTemplate(data) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/trm_templates/${data}`, {
      method: 'GET',
      headers: token,
    }).then(response => {
      response.json().then(res => {
        dispatch({
          type: GET_TEMPLATE,
          payload: res,
        });
      });
    });
  };
}

export function saveFullTemplateData(data) {
  let saveTemplate = data;
  const templateId = data.id;

  delete saveTemplate.elements_attributes;

  saveTemplate.sectionsCount = data.sections.length;
  saveTemplate.sections_attributes = data.sections;

  delete saveTemplate.sections;
  delete saveTemplate.template;

  let sectionOrder = 1;
  saveTemplate.sections_attributes.forEach(section => {
    section.order = sectionOrder;
    sectionOrder = sectionOrder + 1;

    if (section.id.toString().indexOf('new') === 0) {
      section.id = null;
    }

    section.elements_attributes = section.elements;

    let elementOrder = 1;
    section.elements_attributes.forEach(element => {
      element.order = elementOrder;
      elementOrder = elementOrder + 1;
      element.id = !!element.id ? element.id : null;

      if (element.id && element.id.toString().indexOf('new') === 0) {
        element.id = null;
      }
    });

    section.elements_attributes.forEach(element => {
      if (element.trm_type === 'ElementTravelerInfo') {
        const matchingIndexes = section.elements_attributes.filter(
          ele => ele.details.marker === element.details.marker && ele.trm_type !== 'ElementTravelerInfo',
        );
        matchingIndexes.forEach(ele => {
          ele.order = element.order;
        });
      }
    });

    // reorder all elements with base of 1
    let newOrder = 1;
    section.elements_attributes.forEach(element => {
      element.order = newOrder;
      newOrder++;
    });

    delete section.elements;
  });

  let saveData = { trm_template: saveTemplate };
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/trm_templates/${templateId}/update_trm_template`, {
      method: 'PATCH',
      headers: token,
      body: JSON.stringify(saveData),
    }).then(response => {
      response.json().then(res => {
        dispatch({
          type: TEMPLATE_UPDATE,
          payload: res,
        });
      });
    });
  };
}

export function updateTemplateStateOnly(data) {
  return function(dispatch) {
    dispatch({
      type: UPDATE_TEMPLATE_STATE_ONLY,
      payload: data,
    });
  };
}

export function updateTemplateDelaySync(data) {
  return function(dispatch) {
    dispatch({
      type: UPDATE_TEMPLATE_WITH_OUT_SYNC,
      payload: data,
    });
  };
}

export function updateTemplateElementDownload(data) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  const formPayLoad = new FormData();
  let tempTokens = _clone(token);

  formPayLoad.append('file', data.element.details.document_file);

  delete tempTokens['Accept'];
  delete tempTokens['Content-Type'];

  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/elements/upload_file`, {
      method: 'POST',
      headers: tempTokens,
      body: formPayLoad,
    }).then(response => {
      response.json().then(res => {
        data.template.sections.forEach(section => {
          section.elements.forEach(element => {
            if (data.element.id === element.id) {
              element.details.document_file = res.url;
              element.details.file_name = data.element.details.file_name;
              element.details.error = '';
            }
          });
        });

        dispatch(updateTemplateStateOnly(data.template));
      });
    });
  };
}
