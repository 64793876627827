import React from 'react';
import PropTypes from 'prop-types';

export default function TransferredIcon({ fillColor }) {
  return (
    <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
      <path
        d="M0.125 5.5C0.125 8.19227 2.30773 10.375 5 10.375C7.69227 10.375 9.875 8.19227 9.875 5.5C9.875 2.80773 7.69227 0.625 5 0.625C2.30773 0.625 0.125 2.80773 0.125 5.5ZM2.375 7.06258C2.50273 5.62234 3.17516 4.31828 5.25477 4.31828V3.44359C5.25513 3.40575 5.26649 3.36883 5.28747 3.33733C5.30846 3.30583 5.33816 3.28112 5.37294 3.26621C5.40773 3.25131 5.4461 3.24684 5.48338 3.25337C5.52066 3.2599 5.55524 3.27713 5.58289 3.30297L7.56453 5.17188C7.58361 5.18984 7.59882 5.21153 7.60921 5.23559C7.61961 5.25965 7.62497 5.28559 7.62497 5.3118C7.62497 5.33801 7.61961 5.36394 7.60921 5.38801C7.59882 5.41207 7.58361 5.43375 7.56453 5.45172L5.58594 7.32133C5.55828 7.34717 5.52371 7.3644 5.48643 7.37093C5.44915 7.37745 5.41078 7.37299 5.37599 7.35808C5.3412 7.34317 5.31151 7.31846 5.29052 7.28697C5.26954 7.25547 5.25817 7.21855 5.25781 7.1807V6.30672C3.92023 6.30672 3.27711 6.62242 2.71297 7.21328C2.5843 7.3457 2.35977 7.24422 2.375 7.06258Z"
        fill={fillColor}
      />
    </svg>
  );
}

TransferredIcon.propTypes = {
  fillColor: PropTypes.string,
};

TransferredIcon.defaultProps = {
  fillColor: '#373737',
};
