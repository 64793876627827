import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { TabCard, TabHeading, TabCardPanel, TabContentContainer } from '../TabContent.styled';

import TermCostsAccordion from './TermCostsAccordion';
import ContentBlock from '../../structure/ContentBlock';
import { TextBlockRichContentSection } from '../../structure/ContentBlock.styled';

import isEmpty from '../../../utils/isEmpty';
import { breakpoints } from '../../../utils/breakpoints';

const Subheading = styled.h3`
  margin-bottom: 1.25rem;
  font-family: 'Nunito', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.3125rem;
  line-height: 120%;
  letter-spacing: 0.0025em;
  color: #446372;
  padding-top: 1.625rem;

  ${props => {
    if (props.hasScholarshipInfo) {
      return `
        margin-top: 1.75rem;
      `;
    }

    return `
      margin-top: 0;
    `;
  }}

  @media ${breakpoints.tablet} {
    font-size: 1.5rem;
  }
`;

const Container = styled.div`
  border: 0.0625rem solid #c2c2c2;
  border-radius: 0.9375rem 0.9375rem 0 0;
  overflow: hidden;
`;

const Table = styled.div`
  background-color: #ffffff;
  min-width: 100%;

  .headings {
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    border-bottom: 0.0625rem solid #eeeeee;
    padding: 0.4375rem;

    span {
      font-family: 'Open Sans', sans-serif;
      font-weight: 500;
      line-height: 1.375rem;
      letter-spacing: -0.02em;
      color: #373737;
      border: none;
      font-size: 0.75rem;
      display: flex;
      align-items: center;

      @media ${breakpoints.tablet} {
        font-size: 1rem;
        padding: 0.9375rem;
      }
    }

    .term {
      grid-column: span 6 / span 6;

      @media ${breakpoints.tablet} {
        grid-column: span 8 / span 8;
      }
    }

    .cost {
      grid-column: span 4 / span 4;

      @media ${breakpoints.tablet} {
        grid-column: span 3 / span 3;
      }
    }
  }
`;

export default function CostAndFunding() {
  const { terms, scholarshipInfo } = useSelector(state => state.programBrochure);
  const customAliases = useSelector(state => state.profile.customAliases);

  const hasScholarshipInfo = !isEmpty(scholarshipInfo);
  const hasTerms = !isEmpty(terms);

  return (
    <TabContentContainer>
      <TabHeading level={2}>Cost &amp; Funding</TabHeading>
      <TabCard>
        <TabCardPanel className="full">
          {hasScholarshipInfo && (
            <ContentBlock title={`${customAliases.alias_program} Cost Information`}>
              <TextBlockRichContentSection sectionContent={scholarshipInfo} />
            </ContentBlock>
          )}
          {hasTerms ? (
            <div>
              <Subheading includeTopMargin={hasScholarshipInfo}>Term Costs</Subheading>
              <Container>
                <Table>
                  <div className="headings">
                    <span className="term">Term</span>
                    <span className="cost">Cost</span>
                  </div>
                  <TermCostsAccordion items={terms.filter(term => term.range.status !== 'cancelled')} />
                </Table>
              </Container>
            </div>
          ) : null}
        </TabCardPanel>
      </TabCard>
    </TabContentContainer>
  );
}
