import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import DocumentDetailsBreadcrumbs from './DocumentDetailsBreadcrumbs';

import history from '../../../../history';
import { breakpoints } from '../../utils/breakpoints';
import { buttonFocus } from '../../utils/shared/focusStyles';

const MobileContainer = styled.div`
  @media ${breakpoints.desktop} {
    display: none;
  }
`;

const DesktopContainer = styled.div`
  display: none;

  @media ${breakpoints.desktop} {
    display: block;
  }
`;

const Header = styled.header`
  display: block;
  padding: 1.1875rem 1.625rem 0.9375rem 1.5rem;
  border-radius: 15px 15px 0px 0px;

  @media ${breakpoints.desktop} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 3.375rem 1.625rem 3.375rem;
    border-bottom: 4px solid var(--theme_color_accent);
  }
`;

const OrganizationImageContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 33%;

  img {
    width: 56px;
    height: 56px;
    padding: 1px;
    border: 3px solid var(--theme_color_dark);
    border-radius: 50%;
  }
`;

const CloseButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 33%;
`;

const CloseButton = styled.button`
  height: fit-content;
  padding: 0.5625rem 2.71875rem;
  background-color: var(--theme_color_accent);
  border: 1px solid transparent;
  border-radius: 5px;
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
  font-size: 1rem;
  color: var(--theme_color_accent_font);
  letter-spacing: 0.06em;
  line-height: 120%;

  &:hover {
    opacity: 0.9;
  }

  ${buttonFocus};
`;

export default function DocumentSuccessHeader({ type }) {
  const {
    organizationName,
    brandingTheme: { logo },
  } = useSelector(state => state.currentOrganization);

  const {
    applicationSubmission: { id: submissionId },
  } = useSelector(state => state.travelerApplicationSubmissions);

  const handleClose = () => {
    history.push(`/traveler/my-programs/details/${submissionId}`);
  };

  return (
    <>
      <MobileContainer>
        <Header>
          <DocumentDetailsBreadcrumbs forForm={type === 'form'} />
        </Header>
      </MobileContainer>
      <DesktopContainer>
        <Header>
          <div style={{ width: '33%' }} />
          <OrganizationImageContainer>
            <img src={logo.theme_logo} alt={organizationName} />
          </OrganizationImageContainer>
          <CloseButtonContainer>
            <CloseButton type="button" onClick={handleClose}>
              Close
            </CloseButton>
          </CloseButtonContainer>
        </Header>
      </DesktopContainer>
    </>
  );
}

DocumentSuccessHeader.propTypes = {
  type: PropTypes.oneOf(['application', 'form']).isRequired,
};
