import { orderBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ProfileSelectInput from '../../../base/inputs/ProfileSelectInput';

import { fetchProgramHousingTypes } from '../../../../../../actions/lookupActions';

export default function ProgramHousingTypeFilter({ value, handleOnChange }) {
  const dispatch = useDispatch();
  const { selectedFilters } = useSelector(state => state.programDashboard);
  const { programHousingTypes: housingTypes } = useSelector(state => state.lookup.allProgramHousingTypes);

  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (!housingTypes) {
      dispatch(fetchProgramHousingTypes());
    }

    if (selectedFilters.housingType.length > 0) {
      handleOnChange(selectedFilters.housingType);
    }
  }, []);

  useEffect(
    () => {
      if (housingTypes) {
        const types = orderBy(housingTypes, type => type.attributes.name.toLowerCase(), ['asc'])
          .filter(type => type.attributes.name.toLowerCase() !== 'not sure')
          .map(housingType => ({
            value: housingType.attributes.name,
            label: housingType.attributes.name,
          }));

        setOptions(types);
      }
    },
    [housingTypes],
  );

  return (
    <ProfileSelectInput
      id="housing_type"
      value={value}
      label="Housing Type"
      placeholder="Select Housing Type(s)"
      options={options}
      isMulti
      handleOnChange={handleOnChange}
    />
  );
}

ProgramHousingTypeFilter.propTypes = {
  handleOnChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]).isRequired,
};
