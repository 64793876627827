import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

export default function useLocationsOptions() {
  const { selectedFilters } = useSelector(state => state.programDashboard);

  const [selectedCities, setSelectedCities] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);

  useEffect(() => {
    if (selectedFilters.cities.length > 0) {
      setSelectedCities(selectedFilters.cities);
    }

    if (selectedFilters.countries.length > 0) {
      setSelectedCountries(selectedFilters.countries);
    }
  }, []);

  return [selectedCities, setSelectedCities, selectedCountries, setSelectedCountries];
}
