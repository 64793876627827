import eventImageDefault from '../../../public/img/Events_Default_Image.png';
import moment from 'moment-timezone';

const defaultEventImage = () => eventImageDefault;

const calcEventTiming = (start, end) => {
  const options = ['Past', 'Upcoming', 'Today'];
  const momentStart = moment(start).format('YYYY-MM-DD');
  let momentEnd = moment(end).format('YYYY-MM-DD');

  if (!end) {
    momentEnd = moment(start).format('YYYY-MM-DD');
  }

  if (moment().isAfter(momentEnd, 'day')) {
    return options[0];
  } else if (moment().isBefore(momentStart, 'day')) {
    return options[1];
  } else {
    return options[2];
  }
};

export { defaultEventImage, calcEventTiming };
