import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import GoogleMapReact from 'google-map-react';
import { withStyles as muiStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Paragraph from '../../../components/library/paragraph';
import Headline from '../../../components/library/headline';
import sWorldMap from './worldMap.scss';
import _groupBy from 'lodash/groupBy';
import map from 'lodash/fp/map';
import flow from 'lodash/fp/flow';

function WorldMap(props) {
  const [plotMarkers, setPlotMarkers] = useState();


  const fetchLatLngFromPlaceId = async (googlePlaceId) => {
    if(window) {
      const service = new window.google.maps.places.PlacesService(document.createElement('div'));

      return new Promise((resolve, reject) => {
        service.getDetails({ placeId: googlePlaceId }, (place, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            resolve({
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng(),
            });
          } else {
            console.error(`Error fetching place details: ${status}`);
            reject(`Error fetching place details: ${status}`);
          }
        });
      });
    }
  };

  useEffect(() => {
    const processGroupLocations = async () => {
      if (props.data && props.data.length > 0) {
        const groupLocations = _groupBy(props.data, 'loc_key');
        function mergeObjects(data) {
          const merged = {};
      
          data.forEach(item => {
              if (merged[item.key]) {
                  merged[item.key].count += item.count;
              } else {
                  merged[item.key] = { ...item };
              }
          });
      
          return Object.values(merged);
        }

        const mapMarkersFlow = await Promise.all(
          flow(
            map(async (item) => {
              let lat = Number(item[0]?.lat);
              let lng = Number(item[0]?.lng);
              if (window && lat===lng && item[0]?.google_place_id) {
                try {
                  const coordinates = await fetchLatLngFromPlaceId(item[0].google_place_id);
                  lat = coordinates.lat;
                  lng = coordinates.lng;
                } catch (error) {
                  console.warn('Could not fetch coordinates:', error);
                }
              }

              if (props.safecheck) {
                const formatTraveler = map(item, (traveler) =>
                  traveler.traveler.length > 0 ? traveler.traveler : traveler.email
                ).join(', ');

                return {
                  key: `${lat}-${lng}`,
                  count: item.length,
                  name: item[0]?.location,
                  traveler: formatTraveler,
                  lat,
                  lng,
                };
              } else {
                return {
                  key: `${lat}-${lng}`,
                  count: item.length,
                  name: `${item[0]?.plan_city}, ${item[0]?.plan_country}`,
                  lat,
                  lng,
                  email: '',
                };
              }
            })
          )(groupLocations)
        );
        
        const updatedMapMarkersFlow = mergeObjects(mapMarkersFlow);
        setPlotMarkers(updatedMapMarkersFlow);
      } else {
        setPlotMarkers(null);
      }
    };

    processGroupLocations();
  }, [props.data, props.safecheck]);


  const WhiteTooltip = muiStyles({
    tooltip: {
      backgroundColor: 'white',
    },
  })(Tooltip);

  const MapMarker = ({ text, count, lat, lng, traveler }) => {
    return (
      <WhiteTooltip
        title={
          <div style={{ paddingTop: '5px' }}>
            <Headline tag="h5" as="h5">
              {text}
            </Headline>
            {props.safecheck ? (
              <>
                <Paragraph size="small">{traveler}</Paragraph>
              </>
            ) : (
              <Paragraph size="small">Travelers: {count}</Paragraph>
            )}
          </div>
        }
        placement="right"
      >
        <LocationOnIcon style={{ fill: '#df235d', transform: 'translate(-50%, -100%)' }} />
      </WhiteTooltip>
    );
  };

  const getMapOptions = (maps) => {
    return {
      streetViewControl: true,
      scaleControl: true,
      fullscreenControl: false,
      styles: [
        {
          featureType: 'poi.business',
          elementType: 'labels',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
      ],
      gestureHandling: 'greedy',
      disableDoubleClickZoom: true,
      mapTypeControl: true,
      mapTypeId: maps.MapTypeId.ROADMAP,
      mapTypeControlOptions: {
        style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
        position: maps.ControlPosition.BOTTOM_LEFT,
        mapTypeIds: [maps.MapTypeId.ROADMAP, maps.MapTypeId.SATELLITE, maps.MapTypeId.HYBRID],
      },
      zoomControl: true,
      clickableIcons: false,
      minZoom: 2,
      minZoomOverride: true,
    };
  };

  return (
    <section className={sWorldMap['world-map']}>
      <div className={sWorldMap['world-map-view']}>
        <GoogleMapReact
          options={getMapOptions}
          bootstrapURLKeys={{
            key: 'AIzaSyDyZnxFjwYKZv_QCVKkOAUpOxDOSMmFOJA',
          }}
          defaultCenter={{ lat: 20, lng: -5 }}
          defaultZoom={2}
        >
          {!!plotMarkers &&
            plotMarkers.length > 0 &&
            plotMarkers.map(marker => (
              <MapMarker
                key={marker.key}
                lat={marker.lat}
                lng={marker.lng}
                text={marker.name}
                count={marker.count}
                traveler={marker.traveler}
              />
            ))}
        </GoogleMapReact>
        <Paragraph italic>Map responds to filter criteria in the table below.</Paragraph>
      </div>
    </section>
  );
}

WorldMap.propTypes = {
  data: propTypes.array.isRequired,
  safecheck: propTypes.bool,
};

WorldMap.defaultProps = {
  safecheck: false,
};

export default withStyles(sWorldMap)(WorldMap);