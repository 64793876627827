import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import GoogleMapReact from 'google-map-react';

import { breakpoints } from '../../../../../utils/breakpoints';

import Marker from './components/Marker';

const Container = styled.section`
  max-width: 60.375rem;
  margin: 3rem auto 2.25rem;
  padding: 1.5rem 2.4375rem;

  @media ${breakpoints.desktop} {
    padding-left: 0;
    padding-right: 0;
  }
`;

export default function Map({ coordinates }) {
  const defaultLatLon = { lat: '40.7127281', lon: '-74.0060152' };
  const { lat, lon } = coordinates.length ? coordinates[0][0].data : defaultLatLon;

  return (
    <Container style={{ height: '30rem', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: 'AIzaSyDyZnxFjwYKZv_QCVKkOAUpOxDOSMmFOJA',
        }}
        center={{
          lat: Number(lat),
          lng: Number(lon),
        }}
        zoom={12}
      >
        {coordinates.map(locationSet =>
          locationSet.map((location, index) => (
            <Marker
              key={location.data.place_id}
              lat={location.data.lat}
              lng={location.data.lon}
              isPrimary={index === 0}
            />
          )),
        )}
      </GoogleMapReact>
    </Container>
  );
}

Map.propTypes = {
  coordinates: PropTypes.array.isRequired,
};
