import React from 'react';

export default function ColoredMagnifyingGlassIcon() {
  return (
    <svg width="92" height="92" viewBox="0 0 92 92" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
      <rect
        x="65.376"
        y="69.1794"
        width="8.27006"
        height="29.1411"
        transform="rotate(-45 65.376 69.1794)"
        fill="#1C748A"
      />
      <rect
        x="57.4551"
        y="62.281"
        width="8.27006"
        height="10.6149"
        transform="rotate(-45 57.4551 62.281)"
        fill="#85AC82"
      />
      <ellipse cx="36.2889" cy="35.2667" rx="34.2444" ry="34.2444" fill="#1C748A" />
      <path
        d="M68.0787 67.2837C67.386 67.994 66.6707 68.6826 65.933 69.3466C65.269 70.0843 64.5804 70.7995 63.8701 71.4923L81.0015 88.6237C82.0544 89.6765 83.4329 90.203 84.8128 90.203C85.3877 90.203 85.9626 90.1103 86.5136 89.9271C87.1354 88.053 86.7017 85.9059 85.2101 84.4151L68.0787 67.2837Z"
        fill="#6DA6C2"
      />
      <path
        d="M63.6486 71.2713C64.4818 70.6157 65.2882 69.9285 66.073 69.2175C66.784 68.4328 67.4712 67.6263 68.1267 66.7931L60.7238 59.3901C59.9973 60.1573 59.2364 60.8901 58.4433 61.5878C57.7463 62.381 57.0128 63.1418 56.2456 63.8683L63.6486 71.2713Z"
        fill="#ABCAA9"
      />
      <path
        d="M40.6094 2.11768C23.9862 4.40308 11.1406 18.6967 11.1406 35.9375C11.1406 53.1805 23.989 67.4755 40.6143 69.7581C46.2885 68.9769 51.5233 66.7968 55.9565 63.5771C58.8799 61.4553 61.4552 58.88 63.5771 55.9565C67.665 50.3287 70.0781 43.4086 70.0781 35.9375C70.0781 18.6967 57.2326 4.40308 40.6094 2.11768Z"
        fill="#6DA6C2"
      />
      <path
        d="M35.9375 8.98438C50.7996 8.98438 62.8906 21.0754 62.8906 35.9375C62.8906 50.7996 50.7996 62.8906 35.9375 62.8906C21.0754 62.8906 8.98438 50.7996 8.98438 35.9375C8.98438 21.0754 21.0754 8.98438 35.9375 8.98438Z"
        fill="#A9CADA"
      />
      <path
        d="M40.4297 9.36084C27.6985 11.5059 17.9688 22.6065 17.9688 35.9377C17.9688 49.269 27.6985 60.3696 40.4297 62.5146C53.1608 60.3696 62.8906 49.269 62.8906 35.9377C62.8906 22.6065 53.1608 11.5059 40.4297 9.36084Z"
        fill="#D6ECF8"
      />
      <path
        d="M89.8943 79.7307L65.9095 55.7459C69.817 49.8605 71.875 43.045 71.875 35.9375C71.875 16.1213 55.7537 0 35.9375 0C16.1213 0 0 16.1213 0 35.9375C0 55.7537 16.1213 71.875 35.9375 71.875C43.045 71.875 49.8605 69.817 55.7459 65.9095L62.3754 72.539C62.3768 72.5404 62.3775 72.5411 62.3782 72.5425L79.7307 89.8943C81.0889 91.2525 82.8935 92 84.8125 92C86.7315 92 88.5361 91.2525 89.8936 89.895C91.2518 88.5375 92 86.7329 92 84.8125C92 82.8921 91.2518 81.0875 89.8943 79.7307ZM58.6708 63.7519C60.5308 62.2301 62.2308 60.5301 63.7519 58.6708L68.8498 63.7687C67.2993 65.5986 65.5986 67.2993 63.7687 68.8498L58.6708 63.7519ZM3.59375 35.9375C3.59375 18.1028 18.1028 3.59375 35.9375 3.59375C53.7722 3.59375 68.2812 18.1028 68.2812 35.9375C68.2812 42.7972 66.1517 49.3544 62.1227 54.9008C60.1076 57.6776 57.6776 60.1076 54.9008 62.1227V62.1234C49.3544 66.1517 42.7972 68.2812 35.9375 68.2812C18.1028 68.2812 3.59375 53.7722 3.59375 35.9375ZM87.3534 87.3534C86.674 88.0321 85.772 88.4062 84.8125 88.4062C83.853 88.4062 82.951 88.0321 82.2716 87.3534L66.3166 71.3977C68.136 69.836 69.836 68.1367 71.3977 66.3166L87.3534 82.2723C88.0321 82.951 88.4062 83.8523 88.4062 84.8125C88.4062 85.7727 88.0321 86.674 87.3534 87.3534Z"
        fill="#373737"
      />
      <path
        d="M64.6875 35.9375C64.6875 20.0843 51.7907 7.1875 35.9375 7.1875C20.0843 7.1875 7.1875 20.0843 7.1875 35.9375C7.1875 51.7907 20.0843 64.6875 35.9375 64.6875C51.7907 64.6875 64.6875 51.7907 64.6875 35.9375ZM35.9375 61.0938C22.0665 61.0938 10.7812 49.8085 10.7812 35.9375C10.7812 22.0665 22.0665 10.7812 35.9375 10.7812C49.8085 10.7812 61.0938 22.0665 61.0938 35.9375C61.0938 49.8085 49.8085 61.0938 35.9375 61.0938Z"
        fill="#373737"
      />
      <path
        d="M55.0412 30.5462C55.9677 30.1903 56.4303 29.1508 56.0744 28.2243C54.5267 24.1967 51.8328 20.7539 48.2826 18.2671C44.6488 15.7206 40.3799 14.375 35.9375 14.375C34.945 14.375 34.1406 15.1794 34.1406 16.1719C34.1406 17.1644 34.945 17.9688 35.9375 17.9688C43.3222 17.9688 50.0668 22.6076 52.7193 29.513C52.9945 30.2275 53.6753 30.6662 54.3976 30.6662C54.6117 30.6662 54.8293 30.6276 55.0412 30.5462Z"
        fill="#373737"
      />
      <path
        d="M55.7031 34.1406C54.7113 34.1406 53.9062 34.9457 53.9062 35.9375C53.9062 36.9293 54.7113 37.7344 55.7031 37.7344C56.6949 37.7344 57.5 36.9293 57.5 35.9375C57.5 34.9457 56.6949 34.1406 55.7031 34.1406Z"
        fill="#373737"
      />
      <path
        d="M63.6486 71.2713C64.4818 70.6157 65.2882 69.9285 66.073 69.2175C66.784 68.4328 67.4712 67.6263 68.1267 66.7931L60.7238 59.3901C59.9973 60.1573 59.2364 60.8901 58.4433 61.5878C57.7463 62.381 57.0128 63.1418 56.2456 63.8683L63.6486 71.2713Z"
        fill="#ABCAA9"
      />
      <path
        d="M35.9375 8.98438C50.7996 8.98438 62.8906 21.0754 62.8906 35.9375C62.8906 50.7996 50.7996 62.8906 35.9375 62.8906C21.0754 62.8906 8.98438 50.7996 8.98438 35.9375C8.98438 21.0754 21.0754 8.98438 35.9375 8.98438Z"
        fill="#A9CADA"
      />
      <path
        d="M40.4297 9.36084C27.6985 11.5059 17.9688 22.6065 17.9688 35.9377C17.9688 49.269 27.6985 60.3696 40.4297 62.5146C53.1608 60.3696 62.8906 49.269 62.8906 35.9377C62.8906 22.6065 53.1608 11.5059 40.4297 9.36084Z"
        fill="#D6ECF8"
      />
      <path
        d="M89.8943 79.7307L65.9095 55.7459C69.817 49.8605 71.875 43.045 71.875 35.9375C71.875 16.1213 55.7537 0 35.9375 0C16.1213 0 0 16.1213 0 35.9375C0 55.7537 16.1213 71.875 35.9375 71.875C43.045 71.875 49.8605 69.817 55.7459 65.9095L62.3754 72.539C62.3768 72.5404 62.3775 72.5411 62.3782 72.5425L79.7307 89.8943C81.0889 91.2525 82.8935 92 84.8125 92C86.7315 92 88.5361 91.2525 89.8936 89.895C91.2518 88.5375 92 86.7329 92 84.8125C92 82.8921 91.2518 81.0875 89.8943 79.7307ZM58.6708 63.7519C60.5308 62.2301 62.2308 60.5301 63.7519 58.6708L68.8498 63.7687C67.2993 65.5986 65.5986 67.2993 63.7687 68.8498L58.6708 63.7519ZM3.59375 35.9375C3.59375 18.1028 18.1028 3.59375 35.9375 3.59375C53.7722 3.59375 68.2812 18.1028 68.2812 35.9375C68.2812 42.7972 66.1517 49.3544 62.1227 54.9008C60.1076 57.6776 57.6776 60.1076 54.9008 62.1227V62.1234C49.3544 66.1517 42.7972 68.2812 35.9375 68.2812C18.1028 68.2812 3.59375 53.7722 3.59375 35.9375ZM87.3534 87.3534C86.674 88.0321 85.772 88.4062 84.8125 88.4062C83.853 88.4062 82.951 88.0321 82.2716 87.3534L66.3166 71.3977C68.136 69.836 69.836 68.1367 71.3977 66.3166L87.3534 82.2723C88.0321 82.951 88.4062 83.8523 88.4062 84.8125C88.4062 85.7727 88.0321 86.674 87.3534 87.3534Z"
        fill="#373737"
      />
      <path
        d="M64.6875 35.9375C64.6875 20.0843 51.7907 7.1875 35.9375 7.1875C20.0843 7.1875 7.1875 20.0843 7.1875 35.9375C7.1875 51.7907 20.0843 64.6875 35.9375 64.6875C51.7907 64.6875 64.6875 51.7907 64.6875 35.9375ZM35.9375 61.0938C22.0665 61.0938 10.7812 49.8085 10.7812 35.9375C10.7812 22.0665 22.0665 10.7812 35.9375 10.7812C49.8085 10.7812 61.0938 22.0665 61.0938 35.9375C61.0938 49.8085 49.8085 61.0938 35.9375 61.0938Z"
        fill="#373737"
      />
      <path
        d="M55.0412 30.5462C55.9677 30.1903 56.4303 29.1508 56.0744 28.2243C54.5267 24.1967 51.8328 20.7539 48.2826 18.2671C44.6488 15.7206 40.3799 14.375 35.9375 14.375C34.945 14.375 34.1406 15.1794 34.1406 16.1719C34.1406 17.1644 34.945 17.9688 35.9375 17.9688C43.3222 17.9688 50.0668 22.6076 52.7193 29.513C52.9945 30.2275 53.6753 30.6662 54.3976 30.6662C54.6117 30.6662 54.8293 30.6276 55.0412 30.5462Z"
        fill="#373737"
      />
      <path
        d="M55.7031 34.1406C54.7113 34.1406 53.9062 34.9457 53.9062 35.9375C53.9062 36.9293 54.7113 37.7344 55.7031 37.7344C56.6949 37.7344 57.5 36.9293 57.5 35.9375C57.5 34.9457 56.6949 34.1406 55.7031 34.1406Z"
        fill="#373737"
      />
      <path
        d="M63.6486 71.2713C64.4818 70.6157 65.2882 69.9285 66.073 69.2175C66.784 68.4328 67.4712 67.6263 68.1267 66.7931L60.7238 59.3901C59.9973 60.1573 59.2364 60.8901 58.4433 61.5878C57.7463 62.381 57.0128 63.1418 56.2456 63.8683L63.6486 71.2713Z"
        fill="#ABCAA9"
      />
      <path
        d="M35.9375 8.98438C50.7996 8.98438 62.8906 21.0754 62.8906 35.9375C62.8906 50.7996 50.7996 62.8906 35.9375 62.8906C21.0754 62.8906 8.98438 50.7996 8.98438 35.9375C8.98438 21.0754 21.0754 8.98438 35.9375 8.98438Z"
        fill="#A9CADA"
        fillOpacity="0.9"
      />
      <path
        d="M40.4297 9.36084C27.6985 11.5059 17.9688 22.6065 17.9688 35.9377C17.9688 49.269 27.6985 60.3696 40.4297 62.5146C53.1608 60.3696 62.8906 49.269 62.8906 35.9377C62.8906 22.6065 53.1608 11.5059 40.4297 9.36084Z"
        fill="#D6ECF8"
        fillOpacity="0.5"
      />
      <path
        d="M89.8943 79.7307L65.9095 55.7459C69.817 49.8605 71.875 43.045 71.875 35.9375C71.875 16.1213 55.7537 0 35.9375 0C16.1213 0 0 16.1213 0 35.9375C0 55.7537 16.1213 71.875 35.9375 71.875C43.045 71.875 49.8605 69.817 55.7459 65.9095L62.3754 72.539C62.3768 72.5404 62.3775 72.5411 62.3782 72.5425L79.7307 89.8943C81.0889 91.2525 82.8935 92 84.8125 92C86.7315 92 88.5361 91.2525 89.8936 89.895C91.2518 88.5375 92 86.7329 92 84.8125C92 82.8921 91.2518 81.0875 89.8943 79.7307ZM58.6708 63.7519C60.5308 62.2301 62.2308 60.5301 63.7519 58.6708L68.8498 63.7687C67.2993 65.5986 65.5986 67.2993 63.7687 68.8498L58.6708 63.7519ZM3.59375 35.9375C3.59375 18.1028 18.1028 3.59375 35.9375 3.59375C53.7722 3.59375 68.2812 18.1028 68.2812 35.9375C68.2812 42.7972 66.1517 49.3544 62.1227 54.9008C60.1076 57.6776 57.6776 60.1076 54.9008 62.1227V62.1234C49.3544 66.1517 42.7972 68.2812 35.9375 68.2812C18.1028 68.2812 3.59375 53.7722 3.59375 35.9375ZM87.3534 87.3534C86.674 88.0321 85.772 88.4062 84.8125 88.4062C83.853 88.4062 82.951 88.0321 82.2716 87.3534L66.3166 71.3977C68.136 69.836 69.836 68.1367 71.3977 66.3166L87.3534 82.2723C88.0321 82.951 88.4062 83.8523 88.4062 84.8125C88.4062 85.7727 88.0321 86.674 87.3534 87.3534Z"
        fill="#373737"
      />
      <path
        d="M55.0412 30.5462C55.9677 30.1903 56.4303 29.1508 56.0744 28.2243C54.5267 24.1967 51.8328 20.7539 48.2826 18.2671C44.6488 15.7206 40.3799 14.375 35.9375 14.375C34.945 14.375 34.1406 15.1794 34.1406 16.1719C34.1406 17.1644 34.945 17.9688 35.9375 17.9688C43.3222 17.9688 50.0668 22.6076 52.7193 29.513C52.9945 30.2275 53.6753 30.6662 54.3976 30.6662C54.6117 30.6662 54.8293 30.6276 55.0412 30.5462Z"
        fill="#373737"
      />
      <path
        d="M55.7031 34.1406C54.7113 34.1406 53.9062 34.9457 53.9062 35.9375C53.9062 36.9293 54.7113 37.7344 55.7031 37.7344C56.6949 37.7344 57.5 36.9293 57.5 35.9375C57.5 34.9457 56.6949 34.1406 55.7031 34.1406Z"
        fill="#373737"
      />
    </svg>
  );
}
