import React from 'react';
import styled from 'styled-components';
import InfoIcon from '../../icons/InfoIcon';

const InfoMessageContainer = styled.div`
  border-radius: 5px;
  background: #ffefd4;

  display: flex;
  flex-direction: row;
  gap: 25px;
  height: 77px;
  justify-content: left;
  align-items: center;
  padding: 0px 25px;
  border-left: 20px solid #ddaf64;
`;

const InfoMessageIcon = styled.div``;

const InfoMessageTextContainer = styled.div`
  display: block;
  flex-direction: column;
  align-items: center;
  justify-content: left;
`;

const InfoMessageText = styled.div`
  color: #4d4d4d;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 19.2px */
  letter-spacing: 0.08px;
  margin-bottom: 4px;
`;

const InfoSubMessage = styled.div`
  color: #4d4d4d;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 19.2px */
  letter-spacing: 0.08px;
`;

function RequestToWithdrawPending() {
  return (
    <div>
      <InfoMessageContainer>
        <InfoMessageIcon>
          <InfoIcon fillColor={'#DDAF64'} height={'40px'} width={'40px'} />
        </InfoMessageIcon>
        <InfoMessageTextContainer>
          <InfoMessageText>Application is Pending Withdraw Request</InfoMessageText>
          <InfoSubMessage>Your withdraw request is awaiting administrator review, check back later.</InfoSubMessage>
        </InfoMessageTextContainer>
      </InfoMessageContainer>
    </div>
  );
}

export default RequestToWithdrawPending;
