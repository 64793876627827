exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@-webkit-keyframes _3GlEh{to{-webkit-transform:rotate(1turn)}}@keyframes _3GlEh{to{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}._1jqWH ._3d-ru{height:400px;background-color:#a1d8e6;border-top:24px solid #ebebeb;border-bottom:32px solid #ebebeb;border-left:24px solid #ebebeb;border-right:24px solid #ebebeb}._1jqWH ._3d-ru p{margin-bottom:-32px}", ""]);

// exports
exports.locals = {
	"world-map": "_1jqWH",
	"world-map-view": "_3d-ru",
	"spin": "_3GlEh"
};