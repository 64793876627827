import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import FormsAccordion from './FormsAccordion';
import ApplicationAccordion from './ApplicationAccordion';

import { breakpoints } from '../../../utils/breakpoints';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0.375rem 2.8125rem 0.375rem;
`;

const Heading = styled.h3`
  width: fit-content;
  margin-bottom: 0.375rem;
  padding-left: 0.875rem;
  font-family: 'Nunito', sans-serif;
  font-weight: 700;
  font-size: 1.125rem;
  color: #373737;
  line-height: 120%;

  &::after {
    content: '';
    display: block;
    width: 70%;
    padding-top: 0.4375rem;
    border-bottom: 2px solid #cccccc;
  }

  @media ${breakpoints.tablet} {
    padding-left: 0;
  }
`;

const Info = styled.p`
  margin-top: 0.75rem;
  margin-bottom: 1.4375rem;
  padding-left: 0.875rem;
  font-family: 'Nunito', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  color: #373737;
  line-height: 120%;

  @media ${breakpoints.tablet} {
    padding-left: 0;
  }
`;

const AccordionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3.125rem;
`;

export default function ProgramDetailsDocuments() {
  const travelerSubmission = useSelector(state => state.forms.travelerSubmission);
  const [submission, setSubmission] = useState(travelerSubmission?.data || {});
  useEffect(() => {
    if (!travelerSubmission.loading && travelerSubmission.data) {
      setSubmission(travelerSubmission.data);
    }
  }, [travelerSubmission]);

  const {
    customAliases: { alias_program: programAlias },
  } = useSelector(state => state.currentOrganization);

  return (
    <Container>
      <Heading tabIndex={0}>{programAlias} Documents</Heading>
      <Info>
        1 Application, {submission.corresponding_forms?.length || '0'}{' '}
        {submission.corresponding_forms?.length === 1 ? 'Form' : 'Forms'}
      </Info>
      <AccordionsContainer>
        <ApplicationAccordion />
        <FormsAccordion />
      </AccordionsContainer>
    </Container>
  );
}
