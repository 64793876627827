import styled from 'styled-components';
import { breakpoints } from '../settings/breakpoints';

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${props => props.justify || 'inherit'};
`;

export const ContentContainer = styled.main`
  max-width: 1080px;
  margin: 56px 15px 0 15px;

  @media ${breakpoints.tablet} {
    margin: 56px 77px 0 77px;
  }

  @media ${breakpoints.laptop} {
    margin: 56px 100px 0 100px;
  }
  
  @media ${breakpoints.laptopL} {
    margin: 56px auto 0 auto;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
`;

export const StyleGuideSection = styled.div`
  border: 2px solid #ebebeb;
  margin: 10px 0 50px 0;
  padding: 5px;

  @media ${breakpoints.tablet} {
    margin: 10px 0 50px 20px;
    padding: 20px;
  }
`;

export const ToggleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${props => props.justify || 'inherit'};
`;
