import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';

import IntakeSection from './IntakeSection';

import visitedRegionOptions from '../../utils/options/visitedRegionOptions';

export default function IntakeHistory() {
  const intake = useSelector(state => state.intakeForm);

  const [visitedRegions, setVisitedRegions] = useState('');
  const [previousExperience, setPreviousExperience] = useState('');

  useEffect(
    () => {
      if (intake.travel_experience) {
        const hasPreviousExperience = [true, 'yes'].includes(intake.travel_experience.has_left_home_country)
          ? 'Yes'
          : 'No';

        setPreviousExperience(hasPreviousExperience);

        if (hasPreviousExperience === 'Yes') {
          const selectedRegions = [];

          for (const [key, value] of Object.entries(intake.travel_experience)) {
            if (key === 'has_left_home_country') continue;

            if (value) {
              selectedRegions.push(key);
            }
          }

          const regionValues = selectedRegions.reduce((accumulator, region) => {
            const value = visitedRegionOptions.find(visitedRegion => visitedRegion.value === region);

            if (value) {
              accumulator.push(value.label);
            }

            return accumulator;
          }, []);

          if (regionValues.length > 0) {
            setVisitedRegions(regionValues.join(', '));
          }
        }
      }
    },
    [intake.travel_experience],
  );

  return (
    <IntakeSection heading="Travel History">
      {!previousExperience && !visitedRegions ? (
        <span>None selected</span>
      ) : (
        <p>
          {previousExperience && <span>{previousExperience}</span>}
          {previousExperience && visitedRegions && <span>; </span>}
          {visitedRegions && <span>{visitedRegions}</span>}
        </p>
      )}
    </IntakeSection>
  );
}
