export default [
  {
    id: 1,
    label: 'Freshman',
    value: 'Freshman',
  },
  {
    id: 2,
    label: 'Sophomore',
    value: 'Sophomore',
  },
  {
    id: 3,
    label: 'Junior',
    value: 'Junior',
  },
  {
    id: 4,
    label: 'Senior',
    value: 'Senior',
  },
  {
    id: 5,
    label: 'Graduate Student',
    value: 'Graduate Student',
  },
  {
    id: 6,
    label: 'Non-degree Seeking',
    value: 'Non-degree Seeking',
  },
];
