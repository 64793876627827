import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import ColoredMagnifyingGlassIcon from '../icons/ColoredMagnifyingGlassIcon';

import { breakpoints } from '../../utils/breakpoints';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  margin-top: 2.5rem;
  padding-bottom: 2.1875rem;
  padding-left: 1.375rem;
  padding-right: 1.8275rem;

  svg {
    width: 56px;
    height: 56px;
  }

  @media ${breakpoints.tablet} {
    margin-top: 3rem;
    padding: 0;

    svg {
      width: 92px;
      height: 92px;
    }
  }

  @media ${breakpoints.desktop} {
    gap: 4rem;
    margin-top: 5.25rem;
  }
`;

const Info = styled.p`
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
  font-size: 1rem;
  color: #373737;
  letter-spacing: 0.0015em;
  line-height: 120%;

  @media ${breakpoints.desktop} {
    font-size: 1.3125rem;
  }
`;

export default function NoPrograms() {
  const {
    customAliases: { alias_programs: programsAlias },
  } = useSelector(state => state.currentOrganization);

  return (
    <Container>
      <ColoredMagnifyingGlassIcon />
      <Info>There are no {programsAlias.toLowerCase()} available. Check back soon!</Info>
    </Container>
  );
}
