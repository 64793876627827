import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ImageCropModal from './crop.modal';
import { AccentButtonMedium } from '../Buttons.styled';
import { ButtonContainer } from '../Containers.styled';
import Tooltip from '../Tooltip.styled';
import StyledIcon from '../Icon.styled';
import {
  errorImageFileSize,
  maxFileSize,
} from '../../../shared/helpers/UploaderMessages';

const CropWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Image = styled.img`
  border-radius: ${props => (props.type === 'logo' ? '0' : '50%')};
  height: ${props => (props.type === 'logo' ? '300px' : '150px')};
  width: ${props => (props.type === 'logo' ? '300px' : '150px')};
`;

const ImageDiv = styled.div`
  flex: 1;
  padding-bottom: 30px;
  text-align:center;
`;

const ImageLabel = styled.label`
  cursor: pointer;
  margin-bottom: 0;
`;

const TooltipSpan = styled.span`
  align-self: center;
  padding-left: 10px;
`;
const ErrorDiv = styled.div`
  color: #a82425;
  font-family: AvenirNextRegular, sans-serif;
  font-size: 14px;
  margin-bottom: 0;
  padding-top: 10px;
  text-align:center;
`;
export default function ImageCropper(props) {
  const [showCrop, setShowCrop] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [finalImage, setFinalImage] = useState('');
  const [previewImage, setPreviewImage] = useState('');
  const [croppedImage, setCroppedImage] = useState('');

  useEffect(
    () => {
      if (!!props.defaultPreview) {
        setCroppedImage(props.defaultPreview);
      }
    },
    [props.defaultPreview],
  );

  const onCrop = image => {
    if (image && image.length > 0) {
      setCroppedImage(image);
      props.onChange(image);
    }
  };

  return (
    <>
    <CropWrapper>
      {croppedImage &&
        croppedImage.length > 0 && (
          <ImageDiv>
            <Image alt={`Personalized ${props.type}`} src={croppedImage} type={props.type} />
          </ImageDiv>
        )}
      <ButtonContainer justify={'center'}>
        <AccentButtonMedium id={props.id}>
          <ImageLabel hmtlfor={`${props.type}-image`}>
            {props.buttonText}
            <input
              accept="image/xpng, image/gif, image/jpeg, image/png"
              id={`${props.type}-image`}
              name={`${props.type}-image`}
              onChange={e => {
                const reader = new FileReader();
                const file = e.target.files[0];

                if (file) {
                  reader.onload = () => {
                    const fileSize = file.size;
                    const isGoodSize = fileSize <= maxFileSize;
                    if (!isGoodSize) {
                      setErrorMsg(errorImageFileSize)
                    }

                    if (isGoodSize) {
                      setErrorMsg('');
                      setPreviewImage(reader.result);
                      setShowCrop(true);
                    }
                  }
                }

                // reader.onloadend = () => {
                //   setPreviewImage(reader.result);
                //   setShowCrop(true);
                // };

                file ? reader.readAsDataURL(file) : null;
                setFinalImage(file);
              }}
              onClick={(e)=> { 
                e.target.value = null
              }}
              style={{ display: 'none', paddingTop: '10px' }}
              type="file"
            />
          </ImageLabel>
        </AccentButtonMedium>
        
        {props.tooltip &&
          props.tooltip.length > 0 && (
            <TooltipSpan>
              <Tooltip toolTipText={props.tooltip}>
                <StyledIcon type={'InformationCircle'} size={'14px'} />
              </Tooltip>
            </TooltipSpan>
          )}
      </ButtonContainer>
      
      {showCrop && (
        <ImageCropModal
          onClose={() => {
            setShowCrop(false);
          }}
          onCrop={onCrop}
          previewImage={previewImage}
          show={showCrop}
          type={props.type}
        />
      )}
      { errorMsg &&
        <ErrorDiv>      
        {errorMsg}
        </ErrorDiv>
      }
    </CropWrapper>
    
    </>
  );
}
