import No from '../../components/icons/No';
import Yes from '../../components/icons/Yes';
import DoNotKnow from '../../components/icons/DoNotKnow';

export default [
  {
    id: 1,
    label: `Yes, I'm interested`,
    value: 'yes',
    icon: Yes,
  },
  {
    id: 2,
    label: 'No, not right now',
    value: 'no',
    icon: No,
  },
  {
    id: 3,
    label: `Maybe? I haven't decided`,
    value: 'not_sure',
    icon: DoNotKnow,
  },
];
