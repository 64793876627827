import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';

// import ProgramSorter from './ProgramSorter';
import ProgramFiltersListItem from './ProgramFiltersListItem';
import { buttonFocus } from '../../utils/shared/focusStyles';
import { breakpoints } from '../../utils/breakpoints';
import getProgramFilters from '../../utils/program-dashboard/getProgramFilters';
import { clearAllProgramsFilters, setQueryStringFilters } from "../../actions/programDashboardActions";
import { parseProgramDashboardQueryStringToFilters } from "../../../../shared/helpers/General";

const Container = styled.div`
  display: none;

  @media ${breakpoints.desktop} {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    padding-bottom: 1.25rem;
    border-bottom: 3px solid var(--theme_color_accent);

    .program-sorter {
      width: 25%;
    }
  }
`;

const PillsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const ClearAllButton = styled.button`

  display: flex;
  background-color: transparent;
  align-items: center;
  padding: 0.25rem 0.4375rem;

  border: none;
  border-radius: 50px;
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  color: #373737;
  letter-spacing: 0.015em;
  line-height: 120%;
  text-decoration-line: underline;
  &:hover {
    color: #446372;
    text-decoration-line: none;
  }
  //padding: 0;
  //color: #446372;
  //letter-spacing: 0.0015em;
  //line-height: 120%;



  ${buttonFocus};
`;


export default function ProgramFiltersList() {
  const dispatch = useDispatch()
  const { role } = useSelector(state => state.travelerProfile);
  const { selectedFilters } = useSelector(state => state.programDashboard);
  const { customAliases } = useSelector(state => state.currentOrganization);
  const [hasFilters, setHasFilters] = useState(false);
  const [openMenu, setOpenMenu] = useState('');
  const [isClearFilter, setIsClearFilter] = useState(false);

  const isTraveler = role === 'traveler';


  useEffect(() => {
    const queryString = window.location.search;
    const filters = parseProgramDashboardQueryStringToFilters(queryString, selectedFilters);
    dispatch(setQueryStringFilters(filters));
  }, []);


  useEffect(
    () => {
      const arrayFilters = Object.values(selectedFilters).filter(selectedFilter => Array.isArray(selectedFilter));
      const filtersSelected =
        arrayFilters.some(filter => filter.length > 0) ||
        selectedFilters.startDate.date ||
        selectedFilters.endDate.date ||
        selectedFilters.languageImmersion === 'yes' ||
        selectedFilters.favorites;

      setHasFilters(filtersSelected);
    },
    [selectedFilters],
  );
  //clear all filters
  const clearAllFilters = () => {
    dispatch(clearAllProgramsFilters());
    setIsClearFilter(true);
  };
  const handleBlur = () => {
    if(isClearFilter) {
      setIsClearFilter(false);
    }
  }
  return (
    <Container>
      <PillsContainer>
        {getProgramFilters(customAliases, !isTraveler, selectedFilters).map(programFilter => (
          <ProgramFiltersListItem
            key={`program-filter-${programFilter.id}`}
            filter={programFilter}
            openMenu={openMenu}
            setOpenMenu={setOpenMenu}
          />
        ))}
        <ClearAllButton aria-label={isClearFilter ? "Filter is cleared" : "Clear All"} type="button"
          onClick={clearAllFilters}
          onBlur={handleBlur}
        >
          Clear All
        </ClearAllButton>
      </PillsContainer>
      {/* <ProgramSorter /> */}
    </Container>
  );
}
