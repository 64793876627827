import React, { useEffect, useState } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import styles from './index.module.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Form } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { Controller } from 'react-hook-form';
import LoadingIndicator from '../LoadingIndicator';
import ProfileSelectInput from '../../../../sites/travelerProfile/components/base/inputs/ProfileSelectInput';

const planAvailabilityOptions = [
  {
    label: 'Public',
    value: 'public',
  },
  {
    label: 'Private',
    value: 'private',
  },
];

function ConfigurePlanDetails({ control }) {
  const [planTypeOptions, setPlanTypeOptions] = useState([]);
  const [planStatusOptions, setPlanStatusOptions] = useState([]);
  const [registrationStatusOptions, setRegistrationStatusOptions] = useState([]);

  const { allPlanTypes, allPlanStatusTypes, allRegistrationStatusTypes } = useSelector(state => state.lookup);

  const { createPlanTerm } = useSelector(state => state.plans);

  useEffect(() => {
    if (allPlanTypes?.planTypes?.length > 0) {
      let options = allPlanTypes?.planTypes?.map(item => {
        return {
          label: item.attributes.name,
          value: item.id,
        };
      });

      setPlanTypeOptions([...options]);
    }
  }, [allPlanTypes?.planTypes]);

  useEffect(() => {
    if (allPlanStatusTypes?.planStatusTypes?.length > 0) {
      let options = allPlanStatusTypes?.planStatusTypes?.map(item => {
        return {
          label: item.attributes.name,
          value: item.id,
        };
      });

      setPlanStatusOptions([...options]);
    }
  }, [allPlanStatusTypes?.planStatusTypes]);

  useEffect(() => {
    if (allRegistrationStatusTypes?.registrationStatusTypes?.length > 0) {
      let options = allRegistrationStatusTypes?.registrationStatusTypes?.map(item => {
        return {
          label: item.attributes.name,
          value: item.id,
        };
      });

      setRegistrationStatusOptions([...options]);
    }
  }, [allRegistrationStatusTypes?.registrationStatusTypes]);

  if (createPlanTerm.loading) {
    return <LoadingIndicator />;
  }

  return (
    <div>
      <div>
        <p className={styles.plandetails}>Plan Details</p>
        <p className={styles.plandetailsp}>
          Select your plan type and make any necessary changes to other plan details.
        </p>
      </div>

      <div className={styles.formDiv}>
        <Container>
          <Row>
            <Col>
              <Controller
                name="planTypeId"
                control={control}
                rules={{
                  required: 'Plan type is required',
                }}
                render={({ fieldState: { error }, field: { onChange, onBlur, value } }) => (
                  <>
                    <ProfileSelectInput
                      label="Plan Type"
                      placeholder={'Select Plan Type'}
                      required={true}
                      options={planTypeOptions}
                      handleOnChange={onChange}
                      value={value}
                      error={error?.message}
                      showAstrik={error?.message ? false : true}
                    />
                  </>
                )}
              />
            </Col>
            <Col>
              <Controller
                name="planAvailability"
                control={control}
                rules={{
                  required: 'Plan Availability is required',
                }}
                render={({ fieldState: { error }, field: { onChange, onBlur, value } }) => (
                  <>
                    <ProfileSelectInput
                      label="Plan Availability"
                      placeholder={'Select Plan Availibility'}
                      required={true}
                      options={planAvailabilityOptions}
                      handleOnChange={onChange}
                      value={value}
                      error={error?.message}
                      showAstrik={error?.message ? false : true}
                    />
                  </>
                )}
              />
            </Col>
          </Row>

          <Row className={styles.Plan}>
            <Col>
              <Controller
                name="planStatusId"
                control={control}
                rules={{
                  required: 'Plan Status is required',
                }}
                render={({ fieldState: { error }, field: { onChange, onBlur, value } }) => (
                  <>
                    <ProfileSelectInput
                      label="Plan Status"
                      placeholder={'Select Plan Status'}
                      required={true}
                      options={planStatusOptions}
                      handleOnChange={onChange}
                      value={value}
                      error={error?.message}
                      showAstrik={error?.message ? false : true}
                    />
                  </>
                )}
              />
            </Col>
            <Col>
              <Controller
                name="registrationStatusId"
                control={control}
                rules={{
                  required: 'Registration Status is required',
                }}
                render={({ fieldState: { error }, field: { onChange, onBlur, value } }) => (
                  <>
                    <ProfileSelectInput
                      label="Registration Status"
                      placeholder={'Select Registration Status'}
                      required={true}
                      options={registrationStatusOptions}
                      handleOnChange={onChange}
                      value={value}
                      error={error?.message}
                      showAstrik={error?.message ? false : true}
                    />
                  </>
                )}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default withStyles(styles)(ConfigurePlanDetails);
