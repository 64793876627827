import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { buttonFocus } from '../../utils/shared/focusStyles';

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const ProgramLink = styled(Link)`
  font-family: 'Nunito', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 0.875rem;
  color: #446372;
  text-decoration-line: underline;
  line-height: 16px;

  &:hover {
    color: #446372;
    text-decoration-line: none;
  }

  ${buttonFocus};
`;

const CurrentDocumentItem = styled.span`
  font-family: 'Nunito', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  color: #446372;
  line-height: 16px;
`;

export default function DocumentDetailsBreadcrumbs({ forForm }) {
  const {
    applicationSubmission: { id: submissionId, attributes: submission },
  } = useSelector(state => state.travelerApplicationSubmissions);

  const documentText = forForm ? 'Form' : 'Application';

  return (
    <Container>
      <ProgramLink to={`/traveler/my-programs/details/${submissionId}`}>
        {submission.program_range.program_title}
      </ProgramLink>
      <span>/</span>
      <CurrentDocumentItem>{documentText}</CurrentDocumentItem>
    </Container>
  );
}

DocumentDetailsBreadcrumbs.propTypes = {
  forForm: PropTypes.bool.isRequired,
};
