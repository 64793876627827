exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".a4R-6{display:block;margin-top:60px;max-width:600px}._2SUmj{width:60px;height:60px;border-radius:30px}._2FCgE{display:inline-block;margin-left:30px}._1fU1D{font-size:24px;color:#043544}._2QcqO{float:right;cursor:pointer;margin-top:15px}", ""]);

// exports
exports.locals = {
	"admin": "a4R-6",
	"adminAvatar": "_2SUmj",
	"adminInfo": "_2FCgE",
	"adminName": "_1fU1D",
	"trash": "_2QcqO"
};