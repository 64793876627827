import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import DropdownSelect from '../../../../../styledComponents/styles/DropdownSelect.styled';

const Container = styled.div`
  font-family: 'Nunito', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 0.015em;
  color: #373737;
`;

const InputContainer = styled.div`
  flex: 1;
  margin: 13px 0;
  text-align: left;
`;

const InfoMsg = styled.div`
  background-color: #deecff;
  border-left: 20px solid #5180b1;
  border-radius: 5px;
  color: #4d4d4d;
  font-weight: 400;
  font-family: 'Open Sans', sans-serif;
  line-height: 17px;
  margin-top: 17px;
  padding: 20px;
  width: 100%;

  span {
    font-weight: 600;
  }
`;

export default function QuestionDropdownDisplayPreview({ question }) {
  const [isLogicQuestion, setIsLogicQuestion] = useState(false);
  const [trigger, setTrigger] = useState('');
  const [response, setResponse] = useState('');
  const [questionType, setQuestionType] = useState('');

  useEffect(
    () => {
      if (question?.dropdown_question_logic?.child_question?.id) {
        const logic = question?.dropdown_question_logic;

        setTrigger(logic?.trigger?.toLowerCase() === 'equals' ? logic?.trigger : 'does not equal');

        const findResponseOption = question?.dropdown_question_options?.find(opt => {
          return opt.id.toString() === logic?.dropdown_question_option_id.toString();
        });

        setResponse(findResponseOption?.name);

        switch (logic?.child_question?.question_type_identifier.toLowerCase()) {
          case 'short_text':
            setQuestionType('short text question');
            break;
          case 'long_text':
            setQuestionType('long text question');
            break;
          case 'file_upload':
            setQuestionType('file upload question');
            break;
        }
        setIsLogicQuestion(true);
      }
    },
    [question],
  );

  return (
    <Container>
      <InputContainer>
        <DropdownSelect
          aria-label={question.label}
          id='dropdown'
          instructions={question.instructions}
          label={question.label}
          options={question.dropdown_question_options.map(question => {
            return { label: question.name, value: question.order };
          })}
          openSans
          readOnly
          placeholder={`Select ${question.label}`}
          required={question.required}
          isMulti={question.multiselect}
        />
        {isLogicQuestion && (
          <InfoMsg>
            If response <span>{`${trigger} "${response}"`}</span>, then a <span>{questionType}</span> will display.
          </InfoMsg>
        )}
      </InputContainer>
    </Container>
  );
}
