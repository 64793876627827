import styled from 'styled-components';

export const PageHeader = styled.h1`
  color: ${({ theme }) => theme.pageHeader.fontColor};
  font-family: ${({ theme }) => theme.pageHeader.fontFamily};
  font-size: ${({ theme }) => theme.pageHeader.fontSize};
  font-weight: 600;
  line-height: ${({ theme }) => theme.pageHeader.lineHeight};
`;

export const PageHeaderApplication = styled.span`
  color: ${({ theme }) => theme.pageHeader.fontColor};
  font-family: ${({ theme }) => theme.pageHeader.fontFamily};
  font-size: ${({ theme }) => theme.pageHeader.fontSize};
  font-weight: 600;
  line-height: ${({ theme }) => theme.pageHeader.lineHeight};
`;