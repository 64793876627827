import React from "react";
const ScreenReaderLegend = ({children}) => {
  return <legend style={{
    position: "absolute",
    width: "0.0625rem",
    height: "0.0625rem",
    padding: "0",
    margin: "-0.0625rem",
    overflow: "hidden",
    clip: "rect(0, 0, 0, 0)",
    whiteSpace: "nowrap",
    borderEidth: "0"
  }}>{children}</legend>

} 
  


export default ScreenReaderLegend;
