import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { compose } from 'recompose';
import { useDispatch, useSelector } from 'react-redux';
import _get from 'lodash/get';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import sModal from 'react-responsive-modal/styles.css';
import Header from '../../../../components/Header';
import Footer from '../../../components/footer';
import { fetchCurrentTravelerUser, fetchCurrentUser } from '../../../../actions/profileActions';
import userId from '../../../../shared/helpers/userId';
import { Spinner } from '../../../components/library/spinner';
import sReportsPage from './reportsPage.scss';

export const enhance = compose(withStyles(sModal, sReportsPage));

function ReportsPage(props) {
  const { children } = props;
  const userProfile = useSelector(state => state.profile);
  const dispatch = useDispatch();
  const [profile, setProfile] = useState();
  const [loading, setLoading] = useState(true);
  const [traveler, setTraveler] = useState(true);

  if (!userId || typeof userId === 'undefined') {
    window.location.assign(`/`);
  }

  useEffect(() => {
    let unmounted = false;
    setLoading(userProfile.userLoading);
    if (!unmounted) {
      if (userProfile && userProfile.userLoading) {
        if (props.authAdmin) {
          dispatch(fetchCurrentUser(userId));
        } else {
          dispatch(fetchCurrentTravelerUser(userId));
        }
      }
    }
    return () => {
      unmounted = true;
    };
  }, []);

  useEffect(() => {
    if (userProfile && !userProfile.userLoading) {
      const isTraveler = _get(userProfile.currentUser, ['is_traveler?']) || false;
      setTraveler(isTraveler);

      // if (!userProfile.clientFeatureList || !userProfile.clientFeatureList.reports) {
      //   window.location.assign(`/`);
      // }

      if (isTraveler && props.authAdmin) {
        window.location.assign(`/`);
      }
      if (!isTraveler && props.authTraveler) {
        window.location.assign(`/`);
      }
    }

    setLoading(userProfile.userLoading);
    setProfile(userProfile);
  }, [userProfile]);

  return (
    <div className={sReportsPage.page}>
      {!loading ? (
        <>
          <Header role={traveler ? 'Traveler' : 'Admin'} />
          <div className={props.subHeader ? sReportsPage.sub : sReportsPage.full}>{children}</div>
          <Footer />
        </>
      ) : (
        <>
          <div>
            <Spinner />
          </div>
        </>
      )}
    </div>
  );
}

ReportsPage.propTypes = {
  children: propTypes.node,
  authAdmin: propTypes.bool,
  authTraveler: propTypes.bool,
  subHeader: propTypes.bool,
};

ReportsPage.defaultProps = {
  subHeader: false,
};

export default enhance(ReportsPage);
