export default function getSortValues(selectedSort) {
  let sortProperties = [];
  let sortDirections = [];

  switch (selectedSort) {
    case 'internal_to_affiliate':
      sortProperties = ['internal_authorized', 'program_name'];
      sortDirections = ['desc', 'asc'];

      break;
    case 'affiliate_to_internal':
      sortProperties = ['internal_authorized', 'program_name'];
      sortDirections = ['asc', 'asc'];

      break;
    case 'program_name_asc':
      sortProperties = ['program_name'];
      sortDirections = ['asc'];

      break;
    case 'program_name_desc':
      sortProperties = ['program_name'];
      sortDirections = ['desc'];

      break;
    case 'match_percentage':
      sortProperties = ['program_match_percentage'];
      sortDirections = ['desc'];

      break;
    default:
      sortProperties = ['internal_authorized', 'program_name'];
      sortDirections = ['desc', 'asc'];
  }

  return {
    sortDirections,
    sortProperties,
  };
}
