import React from 'react'; 
import { Modal } from 'react-bootstrap';
import closeIcon from '../../../shared/images/closeIcon.png'; 
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import styles from './index.module.css';


function BaseModalHeader({heading = "", onClose, showYellowBorderStripe = false}) {
  return (
    <Modal.Header className={showYellowBorderStripe && styles.yellowBorderStripe}>
      <div className={styles.title}>
        <h1 className={styles.headingtext}>{heading}</h1>
        <a
          href="#"
          role="button"
          onClick={e => {
            e.preventDefault();
            onClose(); 
          }}
        >
          <img src={closeIcon} alt="Close" />
        </a>
      </div>
    </Modal.Header>
  );
}

export default withStyles(styles)(BaseModalHeader);
