import React, { useState, useEffect } from 'react';
import { compose } from 'recompose';
import { useDispatch, useSelector } from 'react-redux';
import propTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import ViaModal from '../../../../components/library/viaModal';
import Headline from '../../../../components/library/headline';
import Button from '../../../../components/library/button';
import SafecheckMessageSelect from '../../selects/safecheckMessageSelect';
import Paragraph from '../../../../components/library/paragraph';
import { sendSafecheckMessage } from '../../../../../actions/safecheckActions';
import SendSafecheckSuccess from './sendSafecheckSuccess';
import sSendSafecheck from './sendSafeCheck.scss';

export const enhance = compose(
  withStyles(sSendSafecheck),
  withRouter,
);

function SendSafecheck(props) {
  const dispatch = useDispatch();
  const [userCount, setUserCount] = useState(0);
  const [selectedPlan, setSelectedPlan] = useState();
  const [locCheck, setLocCheck] = useState(false);
  const [msgId, setMsgId] = useState('');
  const msgSent = useSelector(state => state.safecheck.sendSafecheckMessage);
  const [showSendSafecheckSuccess, setShowSendSafecheckSuccess] = useState(false);
  const [saving, setSaving] = useState(false);
  const [emailRequested, setEmailRequested] = useState(false);

  useEffect(
    () => {
      if (props.planUsers && props.planUsers.length > 0) {
        setUserCount(props.planUsers.length);
      }
    },
    [props],
  );

  const sendMessage = () => {
    const submitValues = {
      plans_user_ids: props.planUsers,
      safe_check_message: {
        email_requested: emailRequested,
        safe_check_message_type_id: selectedPlan,
        geolocation_requested: locCheck,
        group_lead: props.as === 'admin' ? false : true,
      },
    };
    dispatch(sendSafecheckMessage(submitValues));
    setEmailRequested(false);
  };

  useEffect(
    () => {
      if (!!msgSent) {
        if (msgSent.loading) {
          setSaving(true);
        }
        if (!!msgSent.data && !msgSent.loading) {
          setMsgId(msgSent.data.id);
          setSaving(false);
          props.onClose(false);
          setShowSendSafecheckSuccess(true);
        }
        if (!!msgSent.error) {
          setSaving(false);
          // alert(itemsAdded.error);
        }
      }
    },
    [msgSent],
  );

  return (
    <>
      <ViaModal
        showing={props.show}
        onClose={() => {
          props.onClose(false);
        }}
        headerText="SELECT AND SEND SAFECHECK MESSAGE"
      >
        <div className={sSendSafecheck['send-safecheck-message']}>
          <div className={sSendSafecheck.subHeader}>
            <Headline tag="h2" as="h3">
              Select the SafeCheck message you would like to send to {userCount}{' '}
              {userCount === 0 || userCount > 1 ? 'Travelers' : 'Traveler'} with verified phone numbers:
            </Headline>
          </div>
          <div className={sSendSafecheck.content}>
            <SafecheckMessageSelect
              labelText=""
              isRequired
              id="message_type_id"
              name="message_type_id"
              placeholder="Select SafeCheck SMS Message"
              onChange={e => {
                setSelectedPlan(e.value);
              }}
            />
          </div>
          <div className={sSendSafecheck.checkbox}>
            <input type="checkbox" id="locationCheck" name="locationCheck" onClick={() => setLocCheck(!locCheck)} />
            <label htmlFor="locationCheck" id="locationCheckLabel">
              <Paragraph>
                Add a LocationCheck request to this SafeCheck. This will capture a location snapshot for the Traveler at
                the time of their response. LocationCheck is optional for Travelers.
              </Paragraph>
            </label>
          </div>
          <div className={sSendSafecheck.emailCheckbox}>
            <input
              type="checkbox"
              id="emailRequestedCheckBox"
              name="emailRequestedCheckBox"
              onClick={() => setEmailRequested(!emailRequested)}
            />
            <label htmlFor="emailRequestedCheckBox" id="emailRequestedCheckBoxLabel">
              <Paragraph>Also send SafeCheck content as email.</Paragraph>
            </label>
          </div>
          <div className={sSendSafecheck.footer}>
            <div className={sSendSafecheck['footer-button-wrapper']}>
              <div className={sSendSafecheck['button-content']}>
                <Button
                  display="secondary"
                  kind="solid"
                  size="medium"
                  onClick={() => {
                    props.onClose(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
              <div className={sSendSafecheck['button-content']}>
                <Button
                  display="primary"
                  kind="solid"
                  size="medium"
                  type="submit"
                  disabled={userCount < 1 || !selectedPlan}
                  loading={saving}
                  onClick={() => {
                    sendMessage();
                  }}
                >
                  Send SafeCheck Message
                </Button>
              </div>
            </div>
          </div>
        </div>
      </ViaModal>
      <SendSafecheckSuccess
        show={showSendSafecheckSuccess}
        onClose={() => setShowSendSafecheckSuccess(false)}
        locCheck={locCheck}
        userCount={userCount}
        msgId={msgId}
        planId={props.planId}
      />
    </>
  );
}

SendSafecheck.propTypes = {
  show: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
  planUsers: propTypes.array,
  as: propTypes.string.isRequired,
  planId: propTypes.string,
};

SendSafecheck.defaultProps = {
  planId: '',
};

export default enhance(SendSafecheck);
