import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { compose } from 'recompose';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import sInputTimePicker from './inputTimePicker.scss';
import classnames from 'classnames';
import moment from 'moment-timezone';
import Grid from '@material-ui/core/Grid';
import MomentUtils from '@date-io/moment';
import propTypes from 'prop-types';
import { MuiPickersUtilsProvider, KeyboardTimePicker } from '@material-ui/pickers';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { viaMuiTheme } from '../../../../shared/helpers/muiTableTheme';
import ViaIcon from '../../../plans/components/ViaIcon';

export const enhance = compose(withStyles(sInputTimePicker));

function InputTimePicker(props) {
  const isError = props.errorMessage && props.errorMessage.length > 0;
  const [selectedDate, setSelectedDate] = useState(new Date());

  const inputClass = classnames(
    sInputTimePicker['input-time-picker'],
    sInputTimePicker[props.size],
    isError ? sInputTimePicker['error'] : '',
  );

  const helpLabelClass = classnames(
    sInputTimePicker['label-select'],
    sInputTimePicker[props.size],
    isError ? sInputTimePicker['error'] : '',
    sInputTimePicker[props.labelFont],
  );

  const customBranding = useSelector(state => state.profile.customBranding.themes);
  // Unable to update for aria-labels at this time - https://github.com/mui-org/material-ui-pickers/issues/2111
  return (
    <>
      {!!props.labelText && (
        <label className={helpLabelClass} htmlFor={props.id}>
          {props.labelText} {props.isRequired ? <div className={sInputTimePicker.required}>*</div> : ''}
        </label>
      )}
      <MuiThemeProvider theme={viaMuiTheme(customBranding)}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Grid container justify="flex-start">
            <KeyboardTimePicker
              margin="normal"
              id={props?.id || "time-picker"}
              autoComplete='off'
              format="hh:mm A"
              value={props.value ? props.value : null}
              onChange={date => {
                moment(date).isValid()
                  ? (props.onChange(moment(date)), setSelectedDate(moment(date)))
                  : (props.onChange(null), setSelectedDate(null));
              }}
              KeyboardButtonProps={{
                'aria-label': 'change time',
              }}
              className={inputClass}
              keyboardIcon={<ViaIcon name="clockPicker" color="secondary-darkest" size="small" />}
            />
          </Grid>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
      {!!props.helpText && !isError && <span className={sInputTimePicker['help-select']}>{props.helpText}</span>}
      {isError && <span className={sInputTimePicker['error-select']} aria-live='assertive'>{props.errorMessage}</span>}
    </>
  );
}

InputTimePicker.propTypes = {
  errorMessage: propTypes.string,
  labelText: propTypes.string,
  helpText: propTypes.string,
  placeholder: propTypes.string,
  isRequired: propTypes.bool,
  size: propTypes.oneOf(['medium', 'normal']),
  labelFont: propTypes.oneOf(['nextMedium', 'regular']),
};

InputTimePicker.defaultProps = {
  errorMessage: '',
  labelText: '',
  helpText: '',
  placeholder: '',
  isRequired: false,
  size: 'medium',
  labelFont: 'nextMedium',
};

export default enhance(InputTimePicker);
