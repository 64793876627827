import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ProfileFormGrid from '../../base/ProfileFormGrid.styled';
import ProfileTextInput from '../../base/inputs/ProfileTextInput';
import ProfileSelectInput from '../../base/inputs/ProfileSelectInput';
import ProfileDatePickerInput from '../../base/inputs/ProfileDatePickerInput';

import userId from '../../../../../shared/helpers/userId';
import ethnicityOptions from '../../../utils/options/ethnicityOptions';
import residencyOptions from '../../../utils/options/residencyOptions';
import { getRaceOptions } from '../../../actions/travelerProfileActions';
import veteranStatusOptions from '../../../utils/options/veteranStatusOptions';

export default function AdditionalInfoForm({ form, setForm }) {
  const dispatch = useDispatch();
  const { info, readonly, raceOptions } = useSelector(state => state.travelerProfile);
  const [veteranStatusList, setVetaranStatusList] = useState([...veteranStatusOptions]);
  const [residencyOptionList, setResidencyStatusList] = useState([...residencyOptions]);

  const [races, setRaces] = useState([]);

  useEffect(() => {
    dispatch(getRaceOptions(userId));
  }, []);

  useEffect(() => {
    if (raceOptions.length > 0) {
      const mappedRaceOptions = raceOptions.map(raceOption => ({
        value: raceOption,
        label: raceOption,
      }));

      setRaces(mappedRaceOptions);

      // mapping veteran status

      let newVetaranStatusList = [...veteranStatusList];

      if (info.veteran_status) {
        let veteran = veteranStatusList.find(option => option.value === info.veteran_status);

        if (!veteran) {
          newVetaranStatusList = [
            ...newVetaranStatusList,
            {
              id: Date.now(),
              label: info.veteran_status,
              value: info.veteran_status,
            },
          ];

          setVetaranStatusList([...newVetaranStatusList]);
        }
      }
      // end

      let newResidencyOptionList = [...residencyOptionList];

      if (info.residency) {
        let residency = residencyOptionList.find(i => i.value === info.residency || i.label === info.residency);

        if (!residency) {
          newResidencyOptionList = [
            ...residencyOptionList,
            {
              id: Date.now(),
              label: info.residency,
              value: info.residency,
            },
          ];

          setResidencyStatusList([...newResidencyOptionList]);
        }
      }

      setForm({
        dob: info.dob || null,
        disability: info.disability,
        race: mappedRaceOptions.find(option => option.value === info.race) || '',
        ethnicity: ethnicityOptions.find(option => option.value === info.ethnicity) || '',
        residency: newResidencyOptionList.find(option => option.value === info.residency) || '',
        veteran_status: newVetaranStatusList.find(option => option.value === info.veteran_status) || '',
      });
    }
  }, [info, raceOptions]);

  return (
    <ProfileFormGrid>
      <ProfileDatePickerInput
        id="dob"
        label="Date of Birth"
        value={form.dob}
        synced={readonly.includes('dob')}
        minDate={new Date(new Date().getFullYear() - 100, 0, 1).toString()}
        disableFuture
        handleOnChange={event => {
          setForm({ ...form, dob: event });
        }}
      />
      <ProfileSelectInput
        id="ethnicity"
        label="Ethnicity"
        placeholder="Select ethnicity"
        value={form.ethnicity}
        options={ethnicityOptions}
        synced={readonly.includes('ethnicity')}
        handleOnChange={event => {
          setForm({ ...form, ethnicity: event });
        }}
      />
      <ProfileSelectInput
        id="race"
        label="Race"
        placeholder="Select race"
        value={form.race}
        options={races}
        synced={readonly.includes('race')}
        handleOnChange={event => {
          setForm({ ...form, race: event });
        }}
      />
      <ProfileTextInput
        id="disability"
        label="Disability"
        placeholder="Enter disability"
        value={form.disability}
        synced={readonly.includes('disability')}
        handleOnChange={event => {
          setForm({ ...form, disability: event.target.value });
        }}
      />
      <ProfileSelectInput
        id="residency_status"
        label="Residency Status"
        placeholder="Select residency status"
        value={form.residency}
        options={residencyOptionList}
        synced={readonly.includes('residency')}
        handleOnChange={event => {
          setForm({ ...form, residency: event });
        }}
      />
      <ProfileSelectInput
        id="veteran_status"
        label="Veteran"
        placeholder="Select veteran status"
        value={form.veteran_status}
        options={veteranStatusList}
        synced={readonly.includes('veteran_status')}
        handleOnChange={event => {
          setForm({ ...form, veteran_status: event });
        }}
      />
    </ProfileFormGrid>
  );
}

AdditionalInfoForm.propTypes = {
  form: PropTypes.shape({
    disability: PropTypes.string,
    dob: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    race: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    ethnicity: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    residency: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    veteran_status: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  }).isRequired,
  setForm: PropTypes.func.isRequired,
};
