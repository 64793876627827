import styled from 'styled-components';
import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';

import PageWrapper from './base/PageWrapper';
import ApplicationForm from './document-details/ApplicationForm';
import ToastMessage from '../../viaGlobal/components/toastMessage';
import ScreenReaderHeading from './base/ScreenReaderHeading.styled';
import ApplicationAlternates from './document-details/ApplicationAlternates';
import DocumentDetailsHeader from './document-details/DocumentDetailsHeader';
import DocumentPreviewBanner from './document-details/DocumentPreviewBanner';

import history from '../../../history';
import { breakpoints } from '../utils/breakpoints';

const ContentContainer = styled.div`
  position: relative;

  @media ${breakpoints.desktop} {
    padding: 0 15.625rem 4.0625rem 15.625rem;
  }
`;

export default function TravelerApplicationDetails() {
  const {
    applicationUpdated,
    applicationUpdateError,
    applicationSubmission: { id: submissionId, attributes: submission },
  } = useSelector(state => state.travelerApplicationSubmissions);

  const [showError, setShowError] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  const [forceSaveState, setForceSaveState] = useState(false);

  const isPreviewPage = history.location.pathname.includes('preview');
  const isIncomplete = submission.submission_status.name === 'Incomplete';
  const isAlternatesPage = history.location.pathname.includes('alternates');

  useEffect(
    () => {
      if (applicationUpdated && !applicationUpdateError) {
        setShowSuccess(true);

        if (forceSaveState) {
          setToastMessage(`Saved ${submission.submission_type.name}`);
        }
      }

      if (applicationUpdated && applicationUpdateError) {
        setShowError(true);

        setToastMessage(`Error saving ${submission.submission_type.name}`);
      }

      setTimeout(() => {
        setForceSaveState(false);
      }, 3000);
    },
    [applicationUpdated],
  );

  const forceSave = () => {
    setForceSaveState(true);
  };

  const handleNext = () => {
    setForceSaveState(false);

    setTimeout(() => {
      if (!isIncomplete) {
        history.push(`/traveler/my-programs/details/${submissionId}`);

        return;
      }

      if (isPreviewPage) {
        history.push(`/traveler/application/${submissionId}/success`);

        return;
      }

      if (submission.program_range.allow_alternates) {
        history.push(`/traveler/application/${submissionId}/alternates`);
      } else {
        history.push(`/traveler/application/${submissionId}/preview`);
      }
    }, 500);
  };

  return (
    <PageWrapper showSideNav={false} showMenuDropdown={false} noPadding>
      <ScreenReaderHeading>Application Details</ScreenReaderHeading>
      <DocumentDetailsHeader type="application" onNext={handleNext} onSave={forceSave} />
      <ContentContainer>
        {isPreviewPage && <DocumentPreviewBanner />}
        {isAlternatesPage ? (
          <ApplicationAlternates forceSaveState={forceSaveState} onNext={handleNext} onSave={forceSave} />
        ) : (
          <ApplicationForm forceSaveState={forceSaveState} onNext={handleNext} onSave={forceSave} />
        )}
        {toastMessage && (
          <>
            <ToastMessage
              show={showSuccess}
              message={toastMessage}
              toastId="traveler-form-save-success"
              isSuccess
              onClose={() => {
                setShowSuccess(false);
                setToastMessage('');
              }}
            />
            <ToastMessage
              show={showError}
              message={toastMessage}
              toastId="traveler-form-save-failure"
              isSuccess={false}
              onClose={() => {
                setShowError(false);
                setToastMessage('');
              }}
            />
          </>
        )}
      </ContentContainer>
    </PageWrapper>
  );
}
