exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@-webkit-keyframes _1ewPI{to{-webkit-transform:rotate(1turn)}}@keyframes _1ewPI{to{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}._2pfeA{width:320px}._2pfeA ._1QKRs{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;place-content:space-between}", ""]);

// exports
exports.locals = {
	"left-nav": "_2pfeA",
	"header": "_1QKRs",
	"spin": "_1ewPI"
};