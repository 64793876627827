import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as yup from 'yup';
import _cloneDeep from 'lodash/cloneDeep';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import StyledIcon from '../../../../../styledComponents/styles/Icon.styled';
import { AccentButtonMediumNunito } from '../../../../../styledComponents/styles/Buttons.styled';
import { AccentButtonSpinner } from '../../../../../styledComponents/styles/spinner.styled';
import { getTravelerInfoFields, updateForm } from '../../../../../actions/formsActions';
import TextSearch from '../../../../../styledComponents/styles/TextSearch.styled';
import Checkbox from '../../../../../styledComponents/styles/Checkbox.styled';

const FormContainer = styled.div`
  font-family: 'Nunito', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 0.015em;
  color: #373737;
`;

const SubmitContainer = styled.main`
  display: flex;
  justify-content: flex-end;
  margin-top: 35px;
`;

const SearchDiv = styled.div`
  margin-bottom: 30px;
`;

const QuestionsDiv = styled.div`
  height: 400px;
  width: 340px;
  margin-bottom: 40px;
  overflow-x: hidden;
  overflow-y: auto;
`;

const QuestionHeader = styled.div`
  font-family: 'Nunito', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #373737;
  display: flex;
  justify-content: space-between;
  padding: 5px;
  margin: auto;
  align-items: center;
  background-color: #ffffff;
`;

const QavailableRow = styled.div`
  align-items: center;
  background-color: ${props => (props.odd ? '#eeeeee' : '#ffffff')};
  border: 1px solid #ebebeb;
  color: #373737;
  display: flex;
  justify-content: space-between;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin: auto;
  padding: 5px 5px 5px 10px;
`;

const QavailableRowOuter = styled.div`
  flex: 0 0 20px;
  margin-bottom: ${props => (props.removeMargin ? '-0.5rem' : '')};
`;

const QavailableRowMiddle = styled.div`
  flex: 0 0 220px;
`;

const QavailableGroup = styled.div`
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #ebebeb;
  border-bottom: ${props => (!props.isEnd ? '1px solid #474747' : '1px solid #ebebeb')};
  border-top: ${props => (props.isEnd ? '1px solid #474747' : '1px solid #ebebeb')};
  color: #373737;
  font-family: 'Nunito', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  height: ${props => (props.isEnd ? '0' : '30px')};
  margin: auto;
  padding: ${props => (props.isEnd ? '0' : '10px 0 0 30px')};
`;

const QuestionTravelerInfoAddEdit = ({ onClose }) => {
  const dispatch = useDispatch();
  const fetchForm = useSelector(state => state.forms.fetchForm);
  const updateFormSubmit = useSelector(state => state.forms.updateForm);
  const updateQuestionSubmit = useSelector(state => state.forms.updateQuestion);
  const travelerInfoFields = useSelector(state => state.forms.travelerInfoFields);
  const customAliases = useSelector(state => state.profile.customAliases);
  const [formId, setFormId] = useState(null);
  const [searchTxt, setSearchTxt] = useState('');
  const [availQuestionGroups, setAvailQuestionGroups] = useState([]);
  const [addQuestions, setAddQuestions] = useState([]);
  const [isValid, setIsValid] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);

  const updateSearch = value => {
    setSearchTxt(value);
  };

  const schema = yup.object().shape({});
  const { handleSubmit } = useForm({
    shouldUnregister: false,
    resolver: yupResolver(schema),
    defaultValues: {},
    mode: 'onChange',
  });

  useEffect(() => {
    dispatch(getTravelerInfoFields());
  }, []);

  useEffect(
    () => {
      if (!travelerInfoFields?.error && travelerInfoFields?.data && !fetchForm?.loading && fetchForm?.data) {
        let group = [];

        const usedTravelerIds = fetchForm.data.questions
          .filter(q => q.sourceable_type === 'TravelerInfoField')
          .map(q => {
            return q.sourceable_id;
          });

        travelerInfoFields.data.forEach(q => {
          let groupQuestionsAvailable = [];

          //Traveler info fields
          q.attributes.traveler_info_fields.forEach(item => {
            let used = false;
            let required = true;
            let label = item.display_name;

            if (usedTravelerIds.includes(item.id)) {
              const findQuestion = fetchForm.data.questions.find(q => q.sourceable_id === item.id);
              used = true;
              required = findQuestion.required;
              label = findQuestion.label;
            }

            if (item.name !== 'gender_identity_additional_text') {
              groupQuestionsAvailable.push({
                question_type_id: item.question_type_id,
                question_type_identifier: item.question_type_identifier,
                required: required,
                source_id: item.id,
                source_type: 'TravelerInfoField',
                label: label,
                used: used,
                add: used ? true : false,
              });
            }
          });

          if (groupQuestionsAvailable?.length > 0) {
            group.push({
              group_label: q.attributes.traveler_info_fields.length > 1 ? q.attributes.display_name : '',
              questions: groupQuestionsAvailable,
            });
          }
        });

        // Custom fields
        if (travelerInfoFields?.custom_fields) {
          let groupQuestionsAvailable = [];

          const usedCustomFieldIds = fetchForm.data.questions
            .filter(q => q.sourceable_type === 'CustomField')
            .map(q => {
              return q.sourceable_id;
            });

          travelerInfoFields?.custom_fields?.forEach(item => {
            let used = false;
            let required = true;
            let label = item.title;

            if (usedCustomFieldIds.includes(item.id)) {
              const findQuestion = fetchForm.data.questions.find(q => q.sourceable_id === item.id);
              used = true;
              required = findQuestion.required;
              label = findQuestion.label;
            }
            groupQuestionsAvailable.push({
              question_type_id: item.question_type_id,
              question_type_identifier: item.question_type_identifier,
              required: required,
              source_id: item.id,
              source_type: 'CustomField',
              label: label,
              used: used,
              add: used ? true : false,
            });
          });

          if (groupQuestionsAvailable?.length > 0) {
            group.push({
              group_label: 'Custom Fields',
              questions: groupQuestionsAvailable,
            });
          }
        }

        setAvailQuestionGroups(group);
      }
    },
    [travelerInfoFields, fetchForm],
  );

  useEffect(() => {
    if (!fetchForm?.loading && fetchForm?.data) {
      setDisableSubmit(false);
      setFormId(fetchForm.data.id);
    }
  }, [fetchForm]);

  useEffect(() => {
    if (
      (!updateFormSubmit?.loading && updateFormSubmit?.data) ||
      (!updateQuestionSubmit?.loading && updateQuestionSubmit?.data)
    ) {
      setDisableSubmit(false);
      onClose();
    }
  }, [updateFormSubmit, updateQuestionSubmit]);

  useEffect(() => {
    //Enable/Disable submit button for dirty form
    setIsValid(addQuestions.length > 0);
  }, [addQuestions]);

  const onSubmitHandler = () => {
    let values = [];

    if (addQuestions) {
      addQuestions.forEach((q, index) => {
        values.push({
          question_type_id: parseInt(q.question_type_id),
          required: q.required,
          sourceable_id: q.source_id,
          sourceable_type: q.source_type,
          label: q.label,
          order: index + 1,
        });
      });

      if (formId && values) {
        //add new
        setDisableSubmit(true);
        dispatch(updateForm(formId, 'traveler_info_questions', null, null, values));
      }
    }
  };

  const handleAddCheck = (event, q) => {
    const adding = _cloneDeep(addQuestions);
    const question = _cloneDeep(q);

    const findIndex = adding.findIndex(
      item => item.source_id === question.source_id && item.source_type === question.source_type,
    );

    if (event && findIndex === -1) {
      question.add = event;
      question.required = q.required;
      adding.push(question);
      setAddQuestions(adding);
    }

    if (findIndex > -1) {
      if (!event) {
        adding.splice(findIndex, 1);
        setAddQuestions(adding);
      }
    }
  };

  const renderQuestions = (group, index) => {
    return (
      <Fragment key={index}>
        {group.questions.length > 1 &&
          group.group_label !== '' &&
          searchTxt.length === 0 && (
            <QavailableGroup>
              <span>{group.group_label}</span>
            </QavailableGroup>
          )}

        {!!group.questions &&
          group.questions
            .filter(q => {
              let check = true;
              if (searchTxt.length > 0) {
                check = q.label.toLowerCase().includes(searchTxt.toLowerCase());
              }
              return check;
            })
            .map((q, idx) => {
              return (
                <QavailableRow
                  key={`${q.source_id}-${q.source_type}`}
                  border
                  odd={(index + idx + 1) % 2 === 0}
                >
                  <QavailableRowOuter removeMargin>
                    <Checkbox
                      tabIndex={0}
                      defaultChecked={q.add}
                      disabled={q.used}
                      onChange={e => {
                        if (q.add !== e) {
                          q.add = e;
                          handleAddCheck(e, q);
                        }
                      }}
                    />
                  </QavailableRowOuter>
                  <QavailableRowMiddle>{q.label}</QavailableRowMiddle>
                  <QavailableRowOuter removeMargin>
                    <Checkbox
                      defaultChecked={q.required}
                      disabled={addQuestions.find(aq => aq.source_id === q.source_id) === undefined}
                      onChange={e => {
                        const adding = _cloneDeep(addQuestions);
                        const question = adding.find(aq => aq.source_id === q.source_id);
                        if (question && e !== question.required) {
                          question.required = e;
                          setAddQuestions(adding);
                        }
                        if (e !== q.required) {
                          q.required = e;
                        }
                      }}
                      tabIndex={0}
                    />
                  </QavailableRowOuter>
                </QavailableRow>
              );
            })}
        {group.questions.length > 1 &&
          group.group_label !== '' &&
          searchTxt.length === 0 && <QavailableGroup isEnd />}
      </Fragment>
    );
  };

  return (
    <FormContainer>
      <form id='travelerInfo'>
        <SearchDiv>
          <TextSearch
            id='textSearchId'
            debounceValue={value => updateSearch(value)}
            placeholder={`Search ${customAliases?.alias_traveler} info Fields`}
            tooltip=''
            openSans
          />
        </SearchDiv>
        <QuestionHeader>
          <QavailableRowOuter>Add</QavailableRowOuter>
          <QavailableRowMiddle>{customAliases?.alias_traveler} Info Field</QavailableRowMiddle>
          <QavailableRowOuter>Required?</QavailableRowOuter>
        </QuestionHeader>
        <QuestionsDiv>
          {availQuestionGroups?.map((group, index) => renderQuestions(group, index))}
        </QuestionsDiv>
        <SubmitContainer>
          <AccentButtonMediumNunito
            aria-label={`Add ${customAliases?.alias_traveler} info Fields to Form`}
            form="shortAdd"
            type='button'
            disabled={!isValid || disableSubmit}
            onClick={() => {
              onSubmitHandler();
            }}
          >
            {disableSubmit ? <AccentButtonSpinner displayText='Saving ...' /> : 'Add to Form'}
            {!disableSubmit && <StyledIcon type='ChevronForward' color='#fff' />}
          </AccentButtonMediumNunito>
        </SubmitContainer>
      </form>
    </FormContainer>
  );
}

QuestionTravelerInfoAddEdit.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default QuestionTravelerInfoAddEdit;
