import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import ProgramDetailsTerms from './ProgramDetailsTerms';
import ChevronRightIcon from '../../icons/ChevronRightIcon';

import { breakpoints } from '../../../utils/breakpoints';
import { buttonFocus } from '../../../utils/shared/focusStyles';
import {
  toggleDeferModal,
  toggleWithdrawModal,
  toggleRequestToWithdrawModal,
} from '../../../actions/travelerApplicationsActions';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 0.8125rem;
  margin-bottom: 2.0625rem;

  @media ${breakpoints.desktop} {
    margin-top: 1.1875rem;
  }
`;

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.625rem;

  .program-details-terms {
    display: none;
  }

  @media ${breakpoints.desktop} {
    .program-details-terms {
      display: flex;
    }
  }
`;

const Actions = styled.div`
  display: flex;
  gap: 0.25rem;
  padding: 0 1.25rem;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  color: #373737;
  letter-spacing: 0.0015em;
  line-height: 120%;

  button {
    display: inline;
    padding: 0;
    background-color: transparent;
    border: none;
    color: #446372;
    text-decoration-line: underline;

    &:hover {
      text-decoration-line: none;
    }

    ${buttonFocus};
  }

  @media ${breakpoints.tablet} {
    padding: 0;
  }
`;

const ViewProgramLink = styled.a`
  display: none;
  align-items: center;
  gap: 1.375rem;
  padding: 0.5625rem 0.75rem;
  background-color: #e7e7e7;
  border: 1px solid #373737;
  border-radius: 5px;
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
  font-size: 1rem;
  color: #373737;
  letter-spacing: 0.06em;
  line-height: 120%;

  &:hover {
    color: #373737;
    text-decoration-line: none;
    opacity: 0.9;
  }

  @media ${breakpoints.desktop} {
    display: flex;
  }

  ${buttonFocus};
`;

const RequestPendingMessage = styled.span`
  color: #446372;
  font-family: Open Sans;
  font-size: 14px;
  font-style: italic;
  font-weight: 600;
  line-height: 120%; /* 16.8px */
  letter-spacing: 0.021px;
`;

const ProgramDetailsActions = ({ isAdmin }) => {
  const dispatch = useDispatch();
  const permissions = useSelector(state => state.profile.permissions);
  const travelerPerms = useSelector(state => state.auth.organization);

  const [allowDefer, setAllowDefer] = useState(true);
  const [allowWithdraw, setAllowWithdraw] = useState(true);
  const [allowRequestToWithdraw, setAllowRequestToWithdraw] = useState(false);
  const [showRequestToWithdrawPending, setShowRequestToWithdrawPending] = useState(false);

  const {
    customAliases: { alias_program: programAlias },
  } = useSelector(state => state.currentOrganization);

  const travelerSubmission = useSelector(state => state.forms.travelerSubmission);
  const [status, setStatus] = useState('');
  const [submission, setSubmission] = useState({});
  useEffect(() => {
    if (!travelerSubmission.loading && travelerSubmission.data) {
      setStatus(travelerSubmission.data.submission_status.identifier);
      setSubmission(travelerSubmission.data);

      if (travelerSubmission?.requestToWithdrawData?.active) {
        if (travelerSubmission?.submissionWithdrawalRequests?.length > 0) {
          let withdrawalRequests = travelerSubmission?.submissionWithdrawalRequests;
          let requestStatus = withdrawalRequests[withdrawalRequests.length - 1]?.status;
          if (requestStatus === 'pending') {
            setShowRequestToWithdrawPending(true);
          } else if (
            travelerSubmission?.requestToWithdrawData?.application_statuses?.includes(
              travelerSubmission.data.submission_status.identifier,
            )
          ) {
            setAllowRequestToWithdraw(true);
          }
        } else if (
          travelerSubmission?.requestToWithdrawData?.application_statuses?.includes(
            travelerSubmission.data.submission_status.identifier,
          )
        ) {
          setAllowRequestToWithdraw(true);
        }
      }
    }
  }, [travelerSubmission]);

  useEffect(() => {
    if (isAdmin) {
      setAllowDefer(
        !['transferred', 'deferred', 'withdrawn'].includes(status) &&
          permissions.traveler_applications_and_forms_settings.includes('defer_on_behalf_of_traveler'),
      );
      setAllowWithdraw(
        !['transferred', 'deferred', 'withdrawn'].includes(status) &&
          permissions.traveler_applications_and_forms_settings.includes('withdraw_on_behalf_of_traveler'),
      );
    } else {
      //allow_traveler_deferral
      setAllowDefer(
        !['transferred', 'deferred', 'withdrawn'].includes(status) && travelerPerms.allow_traveler_deferral,
      );
      setAllowWithdraw(!['transferred', 'deferred', 'withdrawn'].includes(status));
    }
  }, [permissions]);

  const handleWithdrawClick = () => {
    dispatch(toggleWithdrawModal());
  };

  const handleDeferClick = () => {
    dispatch(toggleDeferModal());
  };

  const handleRequestToWithdrawClick = () => {
    dispatch(toggleRequestToWithdrawModal());
  };

  return (
    submission && (
      <Container>
        <ActionsContainer>
          <ProgramDetailsTerms />
          {!['transferred', 'deferred', 'withdrawn'].includes(status) && (
            <Actions>
              {(allowDefer || allowWithdraw) && <span tabIndex={0}>No longer going?</span>}
              {allowDefer && (
                <button type="button" onClick={handleDeferClick}>
                  Defer
                </button>
              )}
              {allowWithdraw && !allowRequestToWithdraw && !showRequestToWithdrawPending && (
                <>
                  {allowDefer && <span tabIndex={0}>or</span>}
                  <button type="button" onClick={handleWithdrawClick}>
                    Withdraw
                  </button>
                </>
              )}
              {!isAdmin && allowRequestToWithdraw && (
                <>
                  {allowDefer && <span>or</span>}
                  <button type="button" onClick={handleRequestToWithdrawClick}>
                    Request to Withdraw
                  </button>
                </>
              )}
            </Actions>
          )}

          {showRequestToWithdrawPending && <RequestPendingMessage>Withdraw request pending</RequestPendingMessage>}
        </ActionsContainer>
        <ViewProgramLink
          href={`${isAdmin ? '/client' : ''}/program_brochure/${submission?.program_range?.program_id}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <span>View {programAlias}</span>
          <ChevronRightIcon />
        </ViewProgramLink>
      </Container>
    )
  );
};

ProgramDetailsActions.propTypes = {
  isAdmin: PropTypes.bool,
};

ProgramDetailsActions.defaultProps = {
  isAdmin: false,
};

export default ProgramDetailsActions;
