import React from 'react';
import styled from 'styled-components';
import StyledIcon from '../styles/Icon.styled';

const Span = styled.span`
  color: ${({ theme }) => theme.defaults.errorColor};
`;

export default function NotIncludedIcon(props) {
  return (
    <Span {...props}>
      <StyledIcon type={'Prohibited'} size={'18px'} />
    </Span>
  );
}
