import React from 'react';
import { useSelector } from 'react-redux';
import ContentBlock from '../../structure/ContentBlock';
import { TextBlockRichContentSection } from '../../structure/ContentBlock.styled';

import {
  TabCard,
  TabHeading,
  TabCardPanel,
  TabContentContainer,
  IframeCard,
  CourseListIframe,
} from '../TabContent.styled';

import isEmpty from '../../../utils/isEmpty';
import EmbeddedVideoView from '../../../../../components/EmbeddedVideoView';

export default function Academics() {
  const {
    academic: {
      notes,
      subjects,
      embed_course_list_url,
      academic_video_url,
      course: { notes: courseNotes },
    },
  } = useSelector(state => state.programBrochure);

  return (
    <TabContentContainer>
      <TabHeading level={2}>Academics</TabHeading>
      <TabCard>
        <TabCardPanel className="full" tabindex={0}>
          <ContentBlock>
            {!isEmpty(subjects) && (
              <TextBlockRichContentSection
                headingText="Subject Areas"
                headingWeight="medium"
                plainParagraph
                sectionContent={subjects.map(subject => subject.name).join('; ')}
              />
            )}
            {!isEmpty(courseNotes) && (
              <TextBlockRichContentSection headingText="Courses" headingWeight="medium" sectionContent={courseNotes} />
            )}

            {embed_course_list_url && (
              <IframeCard>
                <CourseListIframe width={'100%'} height={'500'} src={embed_course_list_url}></CourseListIframe>
              </IframeCard>
            )}

            {!isEmpty(notes) && (
              <TextBlockRichContentSection
                headingText="Academics Information"
                headingWeight="medium"
                sectionContent={notes}
              />
            )}
         </ContentBlock>

         {academic_video_url && <div style={{marginTop: "1rem"}}><EmbeddedVideoView url={academic_video_url} /></div>}
        
        </TabCardPanel>
      </TabCard>
    </TabContentContainer>
  );
}
