exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._21CHC{font-family:Nunito;font-size:25px;font-weight:400;line-height:30px;letter-spacing:0;text-align:left;margin-bottom:0}.BJpB9{display:-ms-flexbox;display:flex;-ms-flex-pack:justify;justify-content:space-between;width:100%;-ms-flex-align:center;align-items:center;padding:5px}._1EQ4-{border-bottom:5px solid #c3665a}._3jRD1{-ms-flex-direction:row;flex-direction:row;padding:25px}._3jRD1,.cCsDu{display:-ms-flexbox;display:flex}.cCsDu{-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center;padding:0 30px}._2rdGf{max-width:100px}.cxwGJ{display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center;gap:30px;-ms-flex-direction:column;flex-direction:column;font-family:Open Sans;font-size:16px;font-weight:400;line-height:22px;letter-spacing:.005em;text-align:left}._1XM1F{padding:0}._2PEB4{-ms-flex-direction:column;flex-direction:column;-ms-flex-align:center;align-items:center;border-bottom-left-radius:5px;border-bottom-right-radius:5px}._2nJ_8,._2PEB4{display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;padding:25px 0;background:#ebebeb}._2nJ_8{-ms-flex-direction:row;flex-direction:row;gap:20px}._2pm9W{background:none;border:none;color:#373737}._2h___{background-color:#a82425!important;border-color:#a82425!important;color:#fff!important}._2h___:disabled{opacity:.7}.QFyGJ{min-width:75%}", ""]);

// exports
exports.locals = {
	"heading": "_21CHC",
	"title": "BJpB9",
	"modalHeader": "_1EQ4-",
	"container": "_3jRD1",
	"imageContainer": "cCsDu",
	"image": "_2rdGf",
	"textContainer": "cxwGJ",
	"modalBody": "_1XM1F",
	"footer": "_2PEB4",
	"btnContainer": "_2nJ_8",
	"closeButton": "_2pm9W",
	"deleteBtn": "_2h___",
	"inputContainer": "QFyGJ"
};