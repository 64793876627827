import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { compose } from 'recompose';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { EditorState, convertToRaw, ContentState, Modifier } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { stripHtmlString } from '../../shared/helpers/General';
import StyledIcon from '../styles/Icon.styled';
import Tooltip from './Tooltip.styled';
import es from 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import sStyles from './EditTextArea.scss';
import wsy from '../../../public/css/wysiwyg-editor.css';

export const enhance = compose(withStyles(es, wsy, sStyles));

const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const InputInnerGroup = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const InputLabel = styled.label`
  color: ${({ theme }) => theme.editTextArea.label.fontColor};
  font-family: ${({ theme }) => theme.editTextArea.label.fontFamily};
  font-size: ${({ theme }) => theme.editTextArea.label.fontSize};
  font-weight: 600;
`;

const Instructions = styled.div`
  color: ${({ theme }) => theme.textInput.instructions.fontColor};
  font-family: ${({ theme }) => theme.textInput.instructions.fontFamily};
  font-size: ${({ theme }) => theme.textInput.instructions.fontSize};
  font-weight: ${({ theme }) => theme.textInput.instructions.fontWeight};
  padding-left: 5px;
  overflow-wrap: anywhere;
  ${({ openSans }) => (openSans ? openSansFont : null)};
  line-height: normal;
  p{
    margin-bottom:0px;
  }
  ol, ul{
    // white-space: pre-wrap;
  }
`;

const Error = styled.span`
  color: ${({ theme }) => theme.editTextArea.error.fontColor};
  font-family: ${({ theme }) => theme.editTextArea.error.fontFamily};
  font-size: ${({ theme }) => theme.editTextArea.error.fontSize};

  svg {
    justify-content: center;
    margin-right: 5px;
  }
`;

const IconDiv = styled.div`
  color: ${({ theme }) => theme.editTextArea.disabled.fontColor};
  position: relative;
  right: 28px;
  top: 9px;
  width: 0;
`;

const openSansFont = css`
  font-family: ${({ theme }) => theme.textInput.fontFamilyOpenSans};
`;

const Required = styled.span`
  color: ${({ theme }) => theme.defaults.errorColor};
  padding-left: 5px;
`;

const TooltipContainer = styled.span`
  padding-left: 5px;
`;

const Wrapper = styled.div`
  width: 100%;
  background-color: ${props =>
    props.readOnly ? ({ theme }) => theme.editTextArea.disabled.backgroundColor : '#ffffff'};
  border: ${props =>
    props.hasFocus
      ? ({ theme }) => theme.editTextArea.focus.border
      : props.errorMsg && props.errorMsg.length > 0
      ? ({ theme }) => theme.editTextArea.error.border
      : props.readOnly
      ? ({ theme }) => theme.editTextArea.disabled.border
      : ({ theme }) => theme.editTextArea.border};
  color: ${props =>
    props.readOnly
      ? ({ theme }) => theme.editTextArea.disabled.fontColor
      : ({ theme }) => theme.editTextArea.fontColor};
  cursor: ${props => (props.readOnly ? 'not-allowed' : 'pointer')};
  font-family: ${({ theme }) => theme.editTextArea.fontFamily};
`;

const CharLimit = styled.div`
  color: #555555;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`;

function EditTextArea(props) {
  const prepareDraft = value => {
    const draft = htmlToDraft(value);
    const contentState = ContentState.createFromBlockArray(draft.contentBlocks);
    const editorState = EditorState.createWithContent(contentState);
    return editorState;
  };
  const [hasFocus, setHasFocus] = useState(false);
  const [editorState, setEditorState] = useState(props.value ? prepareDraft(props.value) : EditorState.createEmpty());
  const [openSansFont, setOpenSansFont] = useState(props.openSans);
  let cleanseInstructions = null;
  if (props.instructions && stripHtmlString(props.instructions).trim().length > 0) {
    const updatedIntro = !!props.instructions ? props.instructions.replace(/<p><\/p>/g, '<br />') : '';
    cleanseInstructions = updatedIntro;
  }


  useEffect(()=>{
    if(props.setUpdateToken){
      sendTextToEditor(props.setUpdateToken)
    }
  },[props.setUpdateToken])

  const sendTextToEditor = (text) => {
    let updatedEditor = insertText(text,editorState)
    setEditorState(updatedEditor)
  }
  const insertText = (text, editorState) => {
    const currentContent = editorState.getCurrentContent(),
          currentSelection = editorState.getSelection();

    const newContent = Modifier.replaceText(
      currentContent,
      currentSelection,
      text
    );

    const newEditorState = EditorState.push(editorState, newContent, 'insert-characters');
    return  EditorState.forceSelection(newEditorState, newContent.getSelectionAfter());
  }


  const onEditorStateChange = editorState => {
    const formattedValue = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    setEditorState(editorState);
    props.onChange(formattedValue);
  };

  const editorLabels = {
    // Link
    'components.controls.link.linkTitle': 'Link Title',
    'components.controls.link.linkTarget': 'Link URL',
    'components.controls.link.linkTargetOption': 'Open link in new window',
    'components.controls.link.link': 'Link',
    'components.controls.link.unlink': 'Unlink',
  };
  
  const handlePastedText = (pastedText, html, editorState) => {
    const currentContent = editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText('').length;
    
    if (currentContentLength + pastedText.length > props.charLimit) {
      return 'handled';
    }
  };

  return (
    <InputGroup>
      <InputLabel htmlFor={props.id}>
        {props.label}
        {props.required && <Required>*</Required>}
        {props.tooltip && (
          <TooltipContainer>
            <Tooltip toolTipText={props.tooltip}>
              <StyledIcon type={'InformationCircle'} size={'14px'} />
            </Tooltip>
          </TooltipContainer>
        )}
      </InputLabel>
      {cleanseInstructions && cleanseInstructions.length > 0 && (
        <Instructions
          openSans={openSansFont}
          dangerouslySetInnerHTML={{
            __html: cleanseInstructions,
          }}
        />
      )}
      <InputInnerGroup>
        <Wrapper errorMsg={props.errorMsg} hasFocus={hasFocus} readOnly={props.readOnly}>
          <Editor
            handlePastedText={handlePastedText}
            maxlength={15}
            defaultValue={props.value}
            editorClassName={sStyles.editor}
            editorState={editorState}
            id={props.id}
            localization={{ locale: 'en', translations: editorLabels }}
            onBlur={() => setHasFocus(false)}
            onEditorStateChange={onEditorStateChange}
            onFocus={() => setHasFocus(true)}
            placeholder={props.placeholder || ' '}
            readOnly={props.readOnly}
            stripPastedStyles={true}
            toolbar={{
              options: ['inline', 'link', 'list'],
              inline: {
                options: ['bold', 'italic', 'underline', 'strikethrough'],
              },
              list: {
                inDropdown: false,
                options: ['unordered', 'ordered'],
              },
              link: {
                inDropdown: false,
                showOpenOptionOnHover: true,
                defaultTargetOption: '_self',
                options: ['link', 'unlink'],
              },
            }}
            toolbarHidden={props?.toolbarHidden || props.readOnly}
            openSans={openSansFont}
            handleBeforeInput={value => {
              const inputLength = editorState.getCurrentContent().getPlainText().length;

              if (value && props.charLimit && props.charLimit > 0 && inputLength >= props.charLimit) {
                return 'handled';
              }
            }}
          />
        </Wrapper>
        {props.icon && props.icon.length > 0 && (
          <IconDiv>
            <StyledIcon type={props.icon} size={'16px'} />
          </IconDiv>
        )}
      </InputInnerGroup>
      {!!props.charLimit && parseInt(props.charLimit) > 0 && (
        <CharLimit>
          {props.charLimit - editorState.getCurrentContent().getPlainText().length} Characters Remaining
        </CharLimit>
      )}
      {props.errorMsg && props.errorMsg.length > 0 && (
        <Error>
          <StyledIcon type={'Warning'} size={'16px'} />
          {props.errorMsg}
        </Error>
      )}
    </InputGroup>
  );
}

export default enhance(EditTextArea);
