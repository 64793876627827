import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import styles from './index.module.css';
import { Table } from 'react-bootstrap';
import rightArrowIcon from './right-arrow.png';
import warningIcon from './warning-icon.png';
import { useSelector } from 'react-redux';

function CreatePlan({ locationWarning = '', planCount = '' }) {
  const customAliases = useSelector(state => state.profile.customAliases);

  return (
    <div className={styles.custom}>
      <div>
        <span className={styles.divtext1}>
          You are about to create and associate {planCount} travel plan(s) from the selected{' '}
          {customAliases?.alias_program} term(s).
        </span>{' '}
        <span className={styles.divtext2}>
          In the future, any new {customAliases?.alias_traveler}(s) who commit to the {customAliases?.alias_program}(s)
          will be added to the plan(s) automatically.
        </span>
      </div>
      <div className={styles.plansettingdiv}>
        <span className={styles.plansettingtext}>Program Data will translate to Plan Data</span>
        <p className={styles.fontSize}>
          The existing {customAliases?.alias_program} information will populate the new plan(s) as follows. You can
          configure additional required fields in the next step.
        </p>
      </div>
      <Table striped size="sm" bordered>
        <thead>
          <tr>
            <th className={styles.tableHeader}>{customAliases?.alias_program} Data</th>
            <th className={styles.tableHeader}>
              <img src={rightArrowIcon} />
            </th>
            <th className={styles.tableHeader}>Travel Plan Data</th>
          </tr>
        </thead>
        <tbody>
          <tr className={styles.tableRow}>
            <td className={styles.divtext2}>Name</td>
            <td className={styles.divtext2}>
              <img src={rightArrowIcon} />
            </td>
            <td className={styles.divtext2}>Plan Name</td>
          </tr>
          <tr className={styles.tableRow}>
            <td className={styles.divtext2}>Term Start Date</td>
            <td className={styles.divtext2}>
              <img src={rightArrowIcon} />
            </td>
            <td className={styles.divtext2}>1st Location Start Date</td>
          </tr>
          <tr className={styles.tableRow}>
            <td className={styles.divtext2}>Term End Date</td>
            <td className={styles.divtext2}>
              <img src={rightArrowIcon} />
            </td>
            <td className={styles.divtext2}>1st Location End Date</td>
          </tr>
          <tr className={styles.tableRow}>
            <td className={styles.divtext2}>Main Location</td>
            <td className={styles.divtext2}>
              <img src={rightArrowIcon} />
            </td>
            <td className={styles.divtext2}>1st Location</td>
          </tr>
          <tr className={styles.tableRow}>
            <td className={styles.divtext2}>Information</td>
            <td className={styles.divtext2}>
              <img src={rightArrowIcon} />
            </td>
            <td className={styles.divtext2}>Plan Description</td>
          </tr>
        </tbody>
      </Table>
      {/* <div className={styles.formDiv}> */}
      {locationWarning && (
        <div className={styles.warningText}>
          <img src={warningIcon} /> {locationWarning}
        </div>
      )}
    </div>
  );
}

export default withStyles(styles)(CreatePlan);
