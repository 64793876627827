import React from 'react';

export default function ResumeIcon() {
  return (
    <svg width="71" height="71" viewBox="0 0 71 71" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
      <g clipPath="url(#clip0_3039_9637)">
        <path
          d="M14.3792 69.96H61.0668C61.6807 69.96 62.1784 69.4623 62.1784 68.8484V6.59817C62.1784 5.98427 61.6807 5.48657 61.0668 5.48657H14.3792C13.7653 5.48657 13.2676 5.98427 13.2676 6.59817V68.8484C13.2674 69.4623 13.7651 69.96 14.3792 69.96Z"
          fill="white"
        />
        <path
          d="M56.7556 11.1799L47.5931 2.01684C46.9677 1.39143 46.1194 1.04004 45.235 1.04004H9.93288C9.31898 1.04004 8.82129 1.53773 8.82129 2.15163V64.4019C8.82129 65.0158 9.31898 65.5135 9.93288 65.5135H56.6205C57.2344 65.5135 57.7321 65.0158 57.7321 64.4019V13.5378C57.7322 12.6535 57.3809 11.8053 56.7556 11.1799Z"
          fill="white"
        />
        <path
          d="M12.1561 58.8439V1.04004H9.93288C9.31898 1.04004 8.82129 1.53773 8.82129 2.15163V64.4019C8.82129 65.0158 9.31898 65.5135 9.93288 65.5135H56.6205C57.2344 65.5135 57.7321 65.0158 57.7321 64.4019V62.1787H15.491C13.6492 62.1787 12.1561 60.6856 12.1561 58.8439Z"
          fill="white"
        />
        <path
          d="M56.7556 11.18L47.5932 2.01697C47.309 1.73283 46.9747 1.51387 46.6162 1.3501V11.0448C46.6162 11.6587 47.1139 12.1564 47.7278 12.1564H57.4221C57.2583 11.7979 57.0395 11.464 56.7556 11.18Z"
          fill="white"
        />
        <path
          d="M24.3838 27.7186C28.6813 27.7186 32.1651 24.2348 32.1651 19.9373C32.1651 15.6398 28.6813 12.156 24.3838 12.156C20.0863 12.156 16.6025 15.6398 16.6025 19.9373C16.6025 24.2348 20.0863 27.7186 24.3838 27.7186Z"
          fill="#A9CADA"
        />
        <path
          d="M24.384 24.384C26.8398 24.384 28.8305 22.3932 28.8305 19.9375C28.8305 17.4817 26.8398 15.491 24.384 15.491C21.9283 15.491 19.9375 17.4817 19.9375 19.9375C19.9375 22.3932 21.9283 24.384 24.384 24.384Z"
          fill="#A9CADA"
        />
        <path
          d="M24.3838 21.0489C25.6117 21.0489 26.607 20.0536 26.607 18.8257C26.607 17.5979 25.6117 16.6025 24.3838 16.6025C23.156 16.6025 22.1606 17.5979 22.1606 18.8257C22.1606 20.0536 23.156 21.0489 24.3838 21.0489Z"
          fill="#CD916E"
        />
        <path
          d="M24.3841 27.7186C25.999 27.7186 27.4989 27.2262 28.7422 26.384C27.7539 24.5013 26.174 23.2722 24.3841 23.2722C22.5941 23.2722 21.0142 24.5014 20.0259 26.384C21.2692 27.2263 22.7691 27.7186 24.3841 27.7186Z"
          fill="#6DA6C2"
        />
        <path
          d="M24.3838 22.0853C26.1832 22.0853 27.6471 20.6213 27.6471 18.8221C27.6471 17.0228 26.1831 15.5588 24.3838 15.5588C22.5846 15.5588 21.1206 17.0228 21.1206 18.8221C21.1206 20.6213 22.5844 22.0853 24.3838 22.0853ZM24.3838 17.6389C25.0363 17.6389 25.567 18.1698 25.567 18.8221C25.567 19.4744 25.0361 20.0052 24.3838 20.0052C23.7315 20.0052 23.2007 19.4744 23.2007 18.8221C23.2007 18.1698 23.7314 17.6389 24.3838 17.6389Z"
          fill="#373737"
        />
        <path
          d="M24.3838 28.755C29.2479 28.755 33.2052 24.7977 33.2052 19.9336C33.2052 15.0696 29.2479 11.1123 24.3838 11.1123C19.5196 11.1123 15.5625 15.0696 15.5625 19.9336C15.5625 24.7978 19.5196 28.755 24.3838 28.755ZM21.3479 25.9498C22.1719 25.1979 23.2403 24.7781 24.3838 24.7781C25.5272 24.7781 26.5958 25.198 27.4198 25.9498C26.5066 26.4125 25.4756 26.675 24.3838 26.675C23.2921 26.6749 22.261 26.4124 21.3479 25.9498ZM24.3838 13.1924C28.1009 13.1924 31.1251 16.2165 31.1251 19.9336C31.1251 21.8004 30.3621 23.492 29.132 24.7139C27.8935 23.4243 26.2032 22.6979 24.3838 22.6979C22.5642 22.6979 20.8742 23.4243 19.6357 24.7139C18.4056 23.492 17.6424 21.8004 17.6424 19.9336C17.6426 16.2165 20.6666 13.1924 24.3838 13.1924Z"
          fill="#373737"
        />
        <path
          d="M61.067 4.44637H57.7322C57.1579 4.44637 56.6922 4.91203 56.6922 5.48641C56.6922 6.06079 57.1579 6.52645 57.7322 6.52645H61.067C61.1065 6.52645 61.1386 6.55849 61.1386 6.59801V68.8482C61.1386 68.8877 61.1065 68.9198 61.067 68.9198H14.3793C14.3397 68.9198 14.3077 68.8877 14.3077 68.8482V66.5535H56.6205C57.807 66.5535 58.7721 65.5883 58.7721 64.4019V61.0669C58.7721 60.4926 58.3065 60.0269 57.7321 60.0269C57.1577 60.0269 56.6921 60.4926 56.6921 61.0669V64.4019C56.6921 64.4414 56.66 64.4734 56.6205 64.4734H9.93288C9.89336 64.4734 9.86133 64.4414 9.86133 64.4019V2.15163C9.86133 2.11211 9.89336 2.08008 9.93288 2.08008H45.235C45.3505 2.08008 45.4639 2.09131 45.576 2.10795V11.0445C45.576 12.231 46.5411 13.1962 47.7276 13.1962H56.6642C56.6808 13.3085 56.6921 13.4222 56.6921 13.5378V56.621C56.6921 57.1954 57.1577 57.661 57.7321 57.661C58.3065 57.661 58.7721 57.1954 58.7721 56.621V13.5378C58.7721 12.3694 58.3172 11.2708 57.4908 10.4444L48.3285 1.28147C47.5021 0.455121 46.4036 0 45.235 0H9.93288C8.74641 0 7.78125 0.965156 7.78125 2.15163V64.4019C7.78125 65.5883 8.74641 66.5535 9.93288 66.5535H12.2276V68.8482C12.2276 70.0347 13.1928 70.9999 14.3793 70.9999H61.067C62.2535 70.9999 63.2187 70.0347 63.2187 68.8482V6.59815C63.2187 5.41167 62.2535 4.44637 61.067 4.44637ZM55.221 11.1161H47.7278C47.6882 11.1161 47.6562 11.084 47.6562 11.0445V3.55069L55.221 11.1161Z"
          fill="#373737"
        />
        <path
          d="M49.9508 16.6741H37.7231C37.1488 16.6741 36.6831 17.1397 36.6831 17.7141C36.6831 18.2885 37.1488 18.7542 37.7231 18.7542H49.9508C50.5252 18.7542 50.9909 18.2885 50.9909 17.7141C50.9909 17.1397 50.5253 16.6741 49.9508 16.6741Z"
          fill="#373737"
        />
        <path
          d="M47.7276 24.3123C48.302 24.3123 48.7677 23.8466 48.7677 23.2722C48.7677 22.6978 48.302 22.2322 47.7276 22.2322H37.7231C37.1488 22.2322 36.6831 22.6978 36.6831 23.2722C36.6831 23.8466 37.1488 24.3123 37.7231 24.3123H47.7276Z"
          fill="#373737"
        />
        <path
          d="M50.9908 33.2769C50.9908 32.7025 50.5251 32.2368 49.9508 32.2368H16.6025C16.0282 32.2368 15.5625 32.7025 15.5625 33.2769C15.5625 33.8512 16.0282 34.3169 16.6025 34.3169H49.9509C50.5253 34.3169 50.9908 33.8512 50.9908 33.2769Z"
          fill="#373737"
        />
        <path
          d="M45.5044 37.7947H16.6025C16.0282 37.7947 15.5625 38.2603 15.5625 38.8347C15.5625 39.4091 16.0282 39.8748 16.6025 39.8748H45.5044C46.0788 39.8748 46.5444 39.4091 46.5444 38.8347C46.5444 38.2603 46.0788 37.7947 45.5044 37.7947Z"
          fill="#373737"
        />
        <path
          d="M16.6025 50.991H45.5044C46.0788 50.991 46.5444 50.5253 46.5444 49.9509C46.5444 49.3765 46.0788 48.9109 45.5044 48.9109H16.6025C16.0282 48.9109 15.5625 49.3765 15.5625 49.9509C15.5625 50.5253 16.028 50.991 16.6025 50.991Z"
          fill="#373737"
        />
        <path
          d="M16.6025 45.4329H37.7232C38.2976 45.4329 38.7633 44.9672 38.7633 44.3928C38.7633 43.8184 38.2976 43.3528 37.7232 43.3528H16.6025C16.0282 43.3528 15.5625 43.8184 15.5625 44.3928C15.5625 44.9672 16.028 45.4329 16.6025 45.4329Z"
          fill="#373737"
        />
      </g>
      <defs>
        <clipPath id="clip0_3039_9637">
          <rect width="71" height="71" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
