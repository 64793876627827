import moment from 'moment-timezone';

export default function getFormattedEventDate(event, timeOnly = false) {
  if (event.start) {
    const startDate = moment(new Date(event.start));
    const timezone = startDate.tz(event.timezone);

    return timeOnly
      ? `${timezone.format('h:mma')} ${timezone.format('z')}`
      : `${startDate.format('MMM D, YYYY')} at ${timezone.format('h:mma')} ${timezone.format('z')}`;
  }

  return '';
}
