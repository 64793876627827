import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import DatesFilterInputs from './DatesFilterInputs';
import MobileFilterContainer from '../MobileFilterContainer.styled';

const Subheading = styled.span`
  margin-bottom: 1rem;
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  color: #373737;
  line-height: 120%;
`;

export default function MobileDatesFilter({
  endDate,
  endRange,
  startDate,
  startRange,
  endDateInclusive,
  setFocusTrapPaused,
  startDateInclusive,
  handleEndDateChange,
  handleEndRangeChange,
  handleStartDateChange,
  handleStartRangeChange,
  handleEndDateInclusiveChange,
  handleStartDateInclusiveChange,
}) {
  return (
    <MobileFilterContainer bottomBorder>
      <Subheading>Date Ranges</Subheading>
      <DatesFilterInputs
        setFocusTrapPaused={setFocusTrapPaused}
        endRange={endRange}
        startRange={startRange}
        startDate={startDate}
        endDate={endDate}
        startDateInclusive={startDateInclusive}
        endDateInclusive={endDateInclusive}
        handleEndRangeChange={handleEndRangeChange}
        handleStartRangeChange={handleStartRangeChange}
        handleStartDateChange={handleStartDateChange}
        handleEndDateChange={handleEndDateChange}
        handleStartDateInclusiveChange={handleStartDateInclusiveChange}
        handleEndDateInclusiveChange={handleEndDateInclusiveChange}
      />
    </MobileFilterContainer>
  );
}

MobileDatesFilter.propTypes = {
  setFocusTrapPaused: PropTypes.func.isRequired,
  handleEndDateChange: PropTypes.func.isRequired,
  handleEndRangeChange: PropTypes.func.isRequired,
  handleStartDateChange: PropTypes.func.isRequired,
  handleStartRangeChange: PropTypes.func.isRequired,
  handleEndDateInclusiveChange: PropTypes.func.isRequired,
  handleStartDateInclusiveChange: PropTypes.func.isRequired,
  endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  endDateInclusive: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  startDateInclusive: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  endRange: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  startRange: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
};

MobileDatesFilter.defaultProps = {
  endDate: null,
  startDate: null,
  endDateInclusive: null,
  startDateInclusive: null,
};
