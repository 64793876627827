import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import Truncate from 'react-truncate';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import ViaDate from '../../../components/library/viaDate';
import ViaIcon from '../../components/ViaIcon';
import { defaultPlanLocationImage } from '../../../../shared/helpers/plans';
import sPlanCardSmall from './planCardSmall.scss';
import Paragraph from '../../../components/library/paragraph';
import admin from '../../../../shared/images/person.png';

import grouplocation from '../../../../shared/images/group-location.png';
import groupdate from '../../../../shared/images/group-date.png';
import groupinfo from '../../../../shared/images/group-info.png';
import planFormIcon from '../../../../shared/images/svgs/FileTransferIcon.svg';
import WarningIcon from '../../../travelerProfile/components/icons/WarningIcon';

export const enhance = compose(
  withStyles(sPlanCardSmall),
  withRouter,
);

function PlanCardSmall(props) {
  //const planAttr = props.plan.attributes;
  const planAttr = props.plan;
  const isAdmin = props.isAdmin ? true : false;
  const [allLocs, setAllLocs] = useState('');
  const [img, setImg] = useState({ src: defaultPlanLocationImage(), alt: 'travel location' });

  useEffect(() => {
    let locsString = '';
    if (planAttr.locations && planAttr.locations.length > 0) {
      planAttr.locations.map(loc => {
        let locationStr = ""; 

        if(loc.locality && loc.country_common_name){
          locationStr = loc.locality + ', ' + loc.country_common_name; 
        }
        else{
          locationStr = loc.formatted_address; 
        }
        locsString = locsString + locationStr + '; ';
        //locsString = locsString + loc.locality + ', ' + loc.country_common_name + '; ';
      });
      locsString = locsString.slice(0, -2);

      setImg({
        src: planAttr.locations[0].image_url,
        alt: `Location image for ${locsString}`,
      });
    }
    setAllLocs(locsString);
  }, []);

  return (
    <>
    {!isAdmin && planAttr.total_plan_form > 0 && 
      <div className={sPlanCardSmall.planFormBannerContainer}>
        <WarningIcon color={"#6D4603"} />
        <div className={sPlanCardSmall.planFormBannerTitle}>You have plan forms due. <a href={`/plans/plan-overview/${props.plan.id}?tab=plans`} target="_blank">View Forms</a></div>
      </div>
    }
    <div
      className={sPlanCardSmall['plan-card']}
      tabIndex={0}
      onClick={() => {
        if(isAdmin) {
          props.onClick();
          props.history.push(`/client/travelers/${props.userID}/?tab=plans&viewPlanDetail=true&plan_id=${props.plan.id}`);
          //window.location.assign(`/client/travelers/${props.userID}/?tab=plans&viewPlanDetail=true&plan_id=${props.plan.id}`);
        }
        else {
          props.history.push(`/plans/plan-overview/${props.plan.id}`, {
            backTo: props.groupSearch ? 'group-travel-search' : 'my-travel',
          });
        }
      }}
      onKeyDown={e => {
        if(isAdmin) {
          props.history.push(`/client/travelers/${props.userID}/?tab=plans&plan_id=${props.plan.id}`);
        } else {
          if (e.keyCode === 32 || e.keyCode === 13) {
            props.history.push(`/plans/plan-overview/${props.plan.id}`, {
              backTo: props.groupSearch ? 'group-travel-search' : 'my-travel',
            });
          }
        }
      }}
    >
      <div>
        
        <div className={sPlanCardSmall['row-item-img']}>
          <img alt={`${img.alt}`} src={img.src} />
        </div>
        <div className={sPlanCardSmall.content}>
          <div className={sPlanCardSmall['row-item-wrapper']}>
            <div className={sPlanCardSmall['row-item-title']}>
              <div className={sPlanCardSmall['title']}>
                {planAttr.name.substring(0, 60)}
              </div>
              <div className={sPlanCardSmall.right}>
                {planAttr.group ? (
                  <img src={admin} alt="Group plan" name="group" size="smallPlusW10" />
                ) : (
                  <ViaIcon name="solo" color="darkText" size="small" ariaLabel={sPlanCardSmall['row-item-title']} />
                )}
              </div>
            </div>

            <div className={sPlanCardSmall['card-content-details']}>
              {/*<div*/}
              {/*  className={sPlanCardSmall['row-item-content']}*/}
              {/*  dangerouslySetInnerHTML={{ __html: planAttr.description }}*/}
              {/*></div>*/}
              <div className={sPlanCardSmall['row-item-content']}>
                <div className={sPlanCardSmall['row-item-content-img']}>
                  <img src={groupdate} alt="Plan Date" />
                </div>
                <ViaDate date={planAttr.start_date} /> - <ViaDate date={planAttr.end_date} />
              </div>
              <div className={sPlanCardSmall['row-item-content']}>
                <div className={sPlanCardSmall['row-item-content-img']}>
                  <img src={groupinfo} alt="Information" />
                </div>
                <div>{planAttr.plan_type_name}</div>
              </div>
              <div className={sPlanCardSmall['row-item-content']}>
                <div className={sPlanCardSmall['row-item-content-img']}>
                  <img src={grouplocation} alt="Location" />
                </div>
                <Truncate lines={2} ellipsis={<span>...</span>}>
                  <Paragraph>{allLocs}</Paragraph>
                </Truncate>
              </div>
              {planAttr.total_plan_form > 0 &&
                <div className={sPlanCardSmall['row-item-content']}>
                  <div className={sPlanCardSmall['row-item-content-img']}>
                    <img src={planFormIcon} alt="Plan Form" />
                  </div>
                  
                  <div className={sPlanCardSmall['planFormContainer']}>
                    <div>{planAttr.total_plan_form} Plan Forms</div>
                    {
                      planAttr.accepted_plan_form>0 ? (
                        <div className={sPlanCardSmall.planFormAccepted}>{planAttr.accepted_plan_form} of {planAttr.total_plan_form} Accepted</div>
                        )
                      : ( planAttr.submitted_plan_form>0 ? (
                        <div className={sPlanCardSmall.planFormSubmitted}>{planAttr.submitted_plan_form} of {planAttr.total_plan_form} Submitted</div>
                      )
                       : (
                          <div className={sPlanCardSmall.planFormNotSubmitted}>0 of {planAttr.total_plan_form} Submitted</div>
                       )
                      )
                    }
                  </div>
                  
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

PlanCardSmall.propTypes = {
  plan: propTypes.object.isRequired,
  groupSearch: propTypes.bool,
};

PlanCardSmall.defaultProps = {
  groupSearch: false,
};

export default enhance(PlanCardSmall);

/* <ViaIcon name="group" color="darkText" size="smallPlusW10" /> */
