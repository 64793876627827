import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { FORMS } from '../../../../../helpers/constants';

const Container = styled.div`
  font-family: 'Nunito', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 0.015em;
  color: #373737;
`;

const InputContainer = styled.div`
  flex: 1;
  margin: 13px 0;
  text-align: left;
`;

const QuestionDividerDisplay = ({ question }) => {
  const { DIVIDER_COLOR_VALUE } = FORMS;
  const customBranding = useSelector(state => state.profile.customBranding.themes);
  let dividerColor = customBranding.theme_color_dark;
  if (question.border_color === DIVIDER_COLOR_VALUE.ACCENT) {
    dividerColor = customBranding.theme_color_accent;
  } else if (question.border_color === DIVIDER_COLOR_VALUE.GREY) {
    dividerColor = '#666';
  }
  const dividerHeight = `${question.weight}px`

  return (
    <Container>
      <InputContainer>
        <hr style={{backgroundColor: dividerColor, height: dividerHeight}} />
      </InputContainer>
    </Container>
  );
}

QuestionDividerDisplay.displayName = 'QuestionDividerDisplay';

QuestionDividerDisplay.propTypes = {
  question: PropTypes.shape({
    border_color: PropTypes.number,
    weight: PropTypes.number,
  }).isRequired, 
};

export default QuestionDividerDisplay;
