import React, { useState, useEffect, useRef } from 'react';
import { upperFirst } from 'lodash';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';

import FormStatus from './FormStatus';
import FormDeadline from './FormDeadline';
import ScreenReaderText from '../../base/ScreenReaderText.styled';
import { clearSubmission } from '../../../../../actions/formsActions';
import { breakpoints } from '../../../utils/breakpoints';
import { buttonFocus } from '../../../utils/shared/focusStyles';
import { Link } from 'react-router-dom';

const Table = styled.table`
  min-width: 90%;
  background-color: #ffffff;
  border: 1px solid #c2c2c2;
  border-radius: 2px;

  th.center,
  td.center {
    text-align: center;
  }

  th.actions,
  td.actions {
    display: none;

    @media ${breakpoints.desktop} {
      display: table-cell;
    }
  }
`;

const TableHeading = styled.th`
  padding: 0.875rem 0 0.5625rem 1.510625rem;
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
  font-size: 1rem;
  color: #373737;
  letter-spacing: -0.02em;
  line-height: 22px;

  @media ${breakpoints.tablet} {
    padding: 1.125rem 0 0.3125rem 1.66125rem;
  }
`;

const TableRow = styled.tr`
  border-top: 1px solid #bbbbbb;
`;

const TableCell = styled.td`
  padding: 0.8125rem 0.679375rem 0.6875rem 1.4775rem;

  @media ${breakpoints.tablet} {
    padding: 0.75rem 0 0.75rem 1.6875rem;
  }
`;

const FormNameContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.625rem;
  margin-bottom: 0.3125rem;
`;

const FormName = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 1rem;
  color: #446372;
  text-decoration-line: underline;
  line-height: 20px;
  cursor: pointer;

  &:hover {
    color: #446372;
    text-decoration-line: none;
  }

  ${buttonFocus};
`;

const FormStatusContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FormViewLink = styled(Link)`
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 1rem;
  color: #446372;
  text-decoration-line: underline;
  line-height: 20px;

  &:hover {
    color: #446372;
    text-decoration-line: none;
  }

  ${buttonFocus};
`;

export default function FormsTable() {
  const dispatch = useDispatch();
  const { admin_sign_in: isAdmin } = useSelector(state => state.profile.currentUser);
  const permissions = useSelector(state => state.profile.permissions);
  const travelerSubmission = useSelector(state => state.forms.travelerSubmission);
  const myRef = useRef(null);

  useEffect(() => {
    if (myRef.current) {
      const queryParams = new URLSearchParams(window.location.search);
      if (queryParams.get('scrollToForm') === 'yes') {
        myRef.current.scrollIntoView({ behavior: 'smooth' });
        // Wait for the smooth scroll to finish before scrolling additional 400px
        setTimeout(() => {
          // Scroll additional 400px
          window.scrollBy(0, 400);
        }, 500); // Adjust the timeout duration as needed
      }
    }
  }, []);

  const statusOrder = {
    not_started: 1,
    started: 2,
    submitted: 3,
    accepted: 4,
  };

  const [forms, setForms] = useState([]);

  useEffect(() => {
    if (!travelerSubmission.loading && travelerSubmission.data) {
      const currentDate = new Date();
      const overDueObject = [];
      const dueDateObject = [];
      const otherDateObject = [];

      let data = travelerSubmission.data.corresponding_forms;

      data.forEach(obj => {
        const deadlineDate = new Date(obj.deadline);
        if (obj.deadline && deadlineDate < currentDate && obj.status !== 'submitted' && obj.status !== 'accepted') {
          overDueObject.push(obj);
        } else if (obj.deadline && obj.status !== 'submitted' && obj.status !== 'accepted') {
          dueDateObject.push(obj);
        } else {
          otherDateObject.push(obj);
        }
      });

      overDueObject.sort((a, b) => {
        // Sort by status (Highest to lowest: Not Started>Started>Submitted>Accepted)
        const statusA = statusOrder[a.status];
        const statusB = statusOrder[b.status];
        if (statusA !== statusB) {
          return statusA - statusB;
        }

        // Sort by deadline (soonest date to latest date)
        const deadlineA = new Date(a.deadline);
        const deadlineB = new Date(b.deadline);
        if (deadlineA.getTime() !== deadlineB.getTime()) {
          return deadlineA.getTime() - deadlineB.getTime();
        }

        // Sort alphabetically by template
        return a.template_name.localeCompare(b.template_name);
      });

      dueDateObject.sort((a, b) => {
        // Sort by status (Highest to lowest: Not Started>Started>Submitted>Accepted)
        const statusA = statusOrder[a.status];
        const statusB = statusOrder[b.status];
        if (statusA !== statusB) {
          return statusA - statusB;
        }

        // Sort by deadline (soonest date to latest date)
        const deadlineA = new Date(a.deadline);
        const deadlineB = new Date(b.deadline);
        if (deadlineA.getTime() !== deadlineB.getTime()) {
          return deadlineA.getTime() - deadlineB.getTime();
        }

        // Sort alphabetically by template
        return a.template_name.localeCompare(b.template_name);
      });

      otherDateObject.sort((a, b) => {
        // Sort by status (Highest to lowest: Not Started>Started>Submitted>Accepted)
        const statusA = statusOrder[a.status];
        const statusB = statusOrder[b.status];
        if (statusA !== statusB) {
          return statusA - statusB;
        }

        // Sort alphabetically by template
        return a.template_name.localeCompare(b.template_name);
      });

      let allForms = [...overDueObject, ...dueDateObject, ...otherDateObject];

      setForms(allForms);
    }
  }, [travelerSubmission]);

  const canViewForm =
    !isAdmin || permissions?.traveler_applications_and_forms_settings?.includes('view_traveler_forms');
  const canEditForm =
    !isAdmin || permissions?.traveler_applications_and_forms_settings?.includes('edit_traveler_forms');

  const getFormStatus = status =>
    status
      .split('_')
      .map(upperFirst)
      .join(' ');

  const loadForm = formId => {
    let link = `/traveler/form-submission/${formId}`;
    if (isAdmin) {
      link = `/client/form-review/${formId}`;
    }
    dispatch(clearSubmission());
    window.location.replace(link);
  };

  const buildLink = submissionId => {
    if (isAdmin) {
      return `/client/form-review/${submissionId}`;
    }
    return `/traveler/form-submission/${submissionId}`;
  };

  return (
    <Table ref={myRef}>
      <thead>
        <TableRow>
          <TableHeading>Name</TableHeading>
          <TableHeading className="actions center">Actions</TableHeading>
        </TableRow>
      </thead>
      <tbody>
        {forms.map(form => (
          <TableRow key={form.id}>
            <TableCell>
              <FormNameContainer>
                {canViewForm || canEditForm ? (
                  <FormViewLink to={buildLink(form.id)}>
                    <FormName>{form.template_name}</FormName>
                  </FormViewLink>
                ) : (
                  <FormName>{form.template_name}</FormName>
                )}

                {form.deadline && ['not_started', 'started'].includes(form.status) && (
                  <FormDeadline deadline={form.deadline} hideOnMobile />
                )}
              </FormNameContainer>
              <FormStatusContainer>
                <FormStatus status={getFormStatus(form.status)} />
                {form.deadline && ['not_started', 'started'].includes(form.status) && (
                  <FormDeadline deadline={form.deadline} />
                )}
              </FormStatusContainer>
            </TableCell>
            <TableCell className="actions center">
              {(canViewForm || canEditForm) && (
                <FormViewLink to={buildLink(form.id)}>
                  {['not_started', 'started'].includes(form.status) && canEditForm ? 'View / Edit' : 'View'}
                  <ScreenReaderText> {form.name} form</ScreenReaderText>
                </FormViewLink>
              )}
            </TableCell>
          </TableRow>
        ))}
      </tbody>
    </Table>
  );
}
