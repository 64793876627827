import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withCookies } from 'react-cookie';
import _flatten from 'lodash/flatten';
import _groupBy from 'lodash/groupBy';
import _isEqual from 'lodash/isEqual';
import _map from 'lodash/map';
import s from './Header.css';
import {
  fetchCurrentUser,
  fetchNewMessages,
  fetchNewMessagesTraveler,
  fetchCurrentTravelerUser,
  markAsRead,
  markAsReadTraveler,
} from '../../actions/profileActions';
import { baseUrl } from '../../shared/helpers/baseUrl';
import { defaultUserProfileAvatar } from '../../shared/helpers/plans';

import defaultLogo from '../../routes/admin-sign-in/img/viatrm-newlogo.png';
import customBranding from '../../hoc/customBranding/customBranding';
import AccountSuspended from '../../routes/errorpages/AccountSuspended/AccountSuspended';
import AccountPaused from '../../routes/errorpages/AccountPaused/AccountPaused';
import TravelerHeader from './TravelerHeader';
import AdminHeader from './AdminHeader';
import StyledIcon from '../../styledComponents/styles/Icon.styled';

@customBranding
class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      messageRead: false,
      isOpen: true,
    };
    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggle = this.toggle.bind(this);
    this.toggleMessage = this.toggleMessage.bind(this);
    this.truncate = this.truncate.bind(this);
    this.viewMessage = this.viewMessage.bind(this);
    this.state = {
      appDropdownOpen: false,
      formDropdownOpen: false,
      dropdownOpen: false,
      messageDropdownOpen: false,
      programDropdownOpen: false,
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleClickTraveler = this.handleClickTraveler.bind(this);
    this.travelPlansURL = this.travelPlansURL.bind(this);
  }

  toggleMenu() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  toggle() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  }

  toggleApplication = () => {
    this.setState(prevState => ({
      appDropdownOpen: !prevState.appDropdownOpen,
    }));
  };

  togglePrograms = () => {
    this.setState(prevState => ({
      programDropdownOpen: !prevState.programDropdownOpen,
    }));
  };

  toggleForm = () => {
    this.setState(prevState => ({
      formDropdownOpen: !prevState.formDropdownOpen,
    }));
  };

  toggleMessage = () => {
    const { cookies } = this.props;
    this.setState(
      prevState => ({
        messageDropdownOpen: !prevState.messageDropdownOpen,
      }),
      () => {
        if (this.state.messageDropdownOpen) {
          this.props.role.toLowerCase() === 'admin'
            ? this.props.fetchNewMessages(cookies.get('user_id'))
            : this.props.fetchNewMessagesTraveler(cookies.get('user_id'));
        }
      },
    );
  };

  signOut = role => {
    localStorage.clear();
    if (this.props.logout_url.length > 0) {
      window.location.assign(`/clear_cookies?redirect=${this.props.logout_url}`);
    } else {
      window.location.assign(`/clear_cookies`);
    }
  };

  truncate = (text, num) => text.substring(0, num);

  viewMessage = id => {
    window.location = `${baseUrl}/client/travelers/${id}`;
  };

  travelerMessage = () => {
    let length = window.location.href.split('/').length;
    window.location.href.split('/')[length - 1] == 'traveler'
      ? (window.location = '/traveler')
      : window.location.assign('/traveler');
  };

  componentDidMount() {
    const { cookies } = this.props;
    if (this.props.role == 'Admin') {
      const userId = cookies.get('user_id');
      if(!this.props?.user?.client_account_id){
        this.props.fetchCurrentUser(userId);
      }

    } else {
      const userId = cookies.get('user_id');
      if(!this.props?.user?.client_account_id){
        this.props.fetchCurrentTravelerUser(userId);
      }
    }
  }

  componentDidUpdate = prevProps => {
    const { cookies } = this.props;
    const userId = cookies.get('user_id');
    const arePropsEqual = _isEqual(prevProps.customBranding.themes, this.props.customBranding.themes);

    if (!arePropsEqual && this.props.role.toLowerCase() == 'admin') {
      this.props.fetchCurrentUser(userId);
    } else if (!arePropsEqual && this.props.role.toLowerCase() !== 'admin') {
      this.props.fetchCurrentTravelerUser(userId);
    } else {
      null;
    }
  };

  handleClick = (msgId, senderId) => {
    const { cookies } = this.props;
    const userId = cookies.get('user_id');

    this.props.markAsRead(msgId, userId);
    window.location.assign(`/client/travelers/${senderId}`);
  };

  handleClickTraveler = msgId => {
    const { cookies } = this.props;
    const userId = cookies.get('user_id');

    this.props.markAsReadTraveler(msgId, userId);
    window.location.assign(`/traveler?tab=messages`);
  };

  addDefaultSrc = ev => {
    ev.target.src = defaultUserProfileAvatar();
  };

  travelPlansURL() {
    let protocol = window.location.href.match(/^(https?:\/\/)/)[1];
    let subdomain = window.location.href.match(/^https?:\/\/(([A-Za-z0-9_]*)\.)?/)[2];
    subdomain = subdomain ? `${subdomain}.` : '';
    let stripped_backend_url = BACK_END_URL.match(/^https?:\/\/(.*)$/)[1];
    const userId = this.props.cookies.get('user_id');
    const token = this.props.cookies.get('token');
    const userToken = token ? token['X-User-Token'] : '';
    const travel_plans_url = `${protocol}${subdomain}${stripped_backend_url}/client/bypass_login_to_travel_plans/${userId}/${userToken}`;
    return travel_plans_url;
  }

  render() {
    const alias_travelers = this.props.customAliases ? this.props.customAliases.alias_travelers : 'Travelers';
    const alias_traveler = this.props.customAliases ? this.props.customAliases.alias_traveler : 'Traveler';

    const alias_programs = this.props.customAliases ? this.props.customAliases.alias_programs : 'Programs';

    const travel_plans_url = this.travelPlansURL();

    let theme_logo = this.props.customBranding.logo ? this.props.customBranding.logo.theme_logo : defaultLogo;

    const currentViewAdmin = window.location.pathname.split('/').filter(p => p)[0] === 'client';

    let clientAccountState = this.props.clientAccountState ? this.props.clientAccountState.toLowerCase() : null;

    if (this.props.loading) {
      return <section />;
    }

    const grouped_messages = _groupBy(_flatten(this.props.newMessages), message => {
      return message.created_at_date;
    });

    const sorted_messages = Object.keys(grouped_messages);

    const messages = _map(sorted_messages, (created_at, index) => {
      return grouped_messages[created_at].map((mess, inner_i) => (
        <article className={s.message}>
          {inner_i == 0 ? (
            <div className={s.messageCreatedAt}>
              <section className={s.messageCreatedAtDate}>
                {created_at == moment().format('YYYY-MM-DD')
                  ? 'Today'
                  : created_at ==
                    moment()
                      .add(-1, 'days')
                      .format('YYYY-MM-DD')
                    ? 'Yesterday'
                    : moment(created_at).format('ddd, MMM Do YYYY')}
              </section>
              <span className={s.messageCreatedAtSpan} />
            </div>
          ) : null}
          <img className={s.messageAvatar} src={mess.sender_avatar_url} alt="Message avatar" />
          <section
            className={s.messageInfo}
            onClick={
              this.props.role == 'Admin'
                ? this.handleClick.bind(this, mess.message_id, mess.sender.id)
                : this.handleClickTraveler.bind(this, mess.message_id)
            }
          >
            <p>{mess.author}</p>
            <span>
              <b>{`${this.truncate(mess.subject, 15)}...`}</b>
              {`${this.truncate(mess.body, 30)}...`}
            </span>
          </section>
          <section className={s.viewSection}>
            <a
              className={s.viewButton}
              href={this.props.role == 'Admin' ? `/client/travelers/${mess.sender.id}` : `/traveler?tab=messages`}
              onClick={
                this.props.role == 'Admin'
                  ? this.handleClick.bind(this, mess.message_id, mess.sender.id)
                  : this.handleClickTraveler.bind(this, mess.message_id)
              }
            >
              View
            </a>
          </section>
        </article>
      ));
    });
    if (clientAccountState === 'closed') {
      return <AccountSuspended />;
    } else if (clientAccountState === 'suspended') {
      return <AccountPaused />;
    } else if (clientAccountState === 'active') {
      if (this.props.role === 'Admin') {
        return (
          <AdminHeader
            addDefaultSrc={this.addDefaultSrc}
            alias_programs={alias_programs}
            alias_travelers={alias_travelers}
            alias_traveler={alias_traveler}
            appDropdownOpen={this.state.appDropdownOpen}
            avatarUrl={this.props.avatar}
            currentViewAdmin={currentViewAdmin}
            customBranding={this.props.customBranding}
            dropdownOpen={this.state.dropdownOpen}
            formDropdownOpen={this.state.formDropdownOpen}
            programDropdownOpen={this.state.programDropdownOpen}
            freemium={this.props.freemium}
            isOpen={this.state.isOpen}
            lead={this.props.lead}
            logo={defaultLogo}
            messages={messages}
            messageDropdownOpen={this.state.messageDropdownOpen}
            newMessagesLoading={this.props.newMessagesLoading}
            onError={this.addDefaultSrc}
            profileInfo={this.props.profileInfo}
            signOut={this.signOut.bind(this, 'Admin')}
            travelerMessage={this.travelerMessage.bind()}
            travel_plans_url={travel_plans_url}
            theme_logo={theme_logo !== '/default.png' ? theme_logo : defaultLogo}
            toggle={this.toggle}
            toggleApplication={this.toggleApplication}
            togglePrograms={this.togglePrograms}
            toggleForm={this.toggleForm}
            toggleMenu={this.toggleMenu}
            toggleMessage={this.toggleMessage}
            clientFeatureList={this.props.clientFeatureList}
            clientPermissions={this.props.clientPermissions}
            admin_access={this.props.admin_access}
            admin_sign_in={this.props.admin_sign_in}
            client_account_id={this.props.user.client_account_id}
          />
        );
      } else if (this.props.role == 'Traveler') {
        return (
          <TravelerHeader
            addDefaultSrc={this.addDefaultSrc}
            alias_programs={alias_programs}
            avatarUrl={this.props.avatar}
            clientFeatureList={this.props.clientFeatureList}
            customBranding={this.props.customBranding}
            dropdownOpen={this.state.dropdownOpen}
            isOpen={this.state.isOpen}
            messageDropdownOpen={this.state.messageDropdownOpen}
            messages={messages}
            newMessagesLoading={this.props.newMessagesLoading}
            onError={this.addDefaultSrc}
            profileInfo={this.props.profileInfo}
            signOut={this.signOut.bind(this, 'Traveler')}
            theme_logo={theme_logo !== '/default.png' ? theme_logo : defaultLogo}
            toggle={this.toggle}
            toggleMenu={this.toggleMenu}
            toggleMessage={this.toggleMessage}
            travelerMessage={this.travelerMessage.bind()}
            freemium={this.props.freemium}
            admin_access={this.props.admin_access}
            admin_sign_in={this.props.admin_sign_in}
            client_account_id={this.props.user.client_account_id}
          />
        );
      } 
    } else if (clientAccountState === 'under_maintenance') {
      return (
        <div className={s.blockPage}>
          <div className={s.errorState}>
            <StyledIcon type="Warning" size="16px" />
            Your account is under maintenance, please check back soon.
          </div>
        </div>
      )
    }
  }
}

const mapStateToProps = state => ({
  signedIn: state.auth.signedIn,
  user: state.profile.currentUser,
  avatar: state.profile.avatar,
  loading: state.profile.userLoading,
  newMessages: state.profile.newMessages,
  newMessagesLoading: state.profile.newMessagesLoading,
  customBranding: state.profile.customBranding || {},
  profileInfo: state.profile.profileInfo,
  customAliases: state.profile.customAliases || {},
  freemium: state.profile.freemium,
  lead: state.profile.lead,
  logout_url: state.profile.logout_url,
  clientAccountState: state.profile.clientAccountState,
  clientFeatureList: state.profile.clientFeatureList,
  clientPermissions: state.profile.permissions,
  admin_access: state.profile.admin_access,
  admin_sign_in: state.profile.admin_sign_in,
});

export default compose(
  withCookies,
  withStyles(s),
  connect(
    mapStateToProps,
    {
      fetchCurrentUser,
      fetchNewMessages,
      fetchNewMessagesTraveler,
      fetchCurrentTravelerUser,
      markAsRead,
      markAsReadTraveler,
    },
  ),
)(Header);
