import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { compose } from 'redux';

import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap';


import s from './dropdown.css';

function EditorDropdown({menuLeftSpace = true, dropdownStyles = null, ...props}) {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggle = () => setDropdownOpen((prevState) => !prevState);
  
    const setThisToken = (val) => {
        props.onChange(val)
    }
    return(
        <div>
            <Dropdown data-value="menu" isOpen={dropdownOpen} toggle={toggle} direction={'start'} className={`${s.dropdownButton}`} style={dropdownStyles || {}}>
                <DropdownToggle className={`${s.dropDownToggle}`}>
                    {props.title}
                </DropdownToggle>
                <DropdownMenu className={`${s.dropdownMenu} ${menuLeftSpace ? s.dropdownMenuLeftSpace : "" } `}>
                    {
                        props.value?.map((item, i)=>(
                            <DropdownItem>
                                <div className={s.dropdownText} key={'dropDownEditor'+i} onClick={()=>setThisToken(item.value)}>
                                    {item.label}
                                </div>
                            </DropdownItem>
                        ))
                    }
                </DropdownMenu>
            </Dropdown>
        </div>
    )
}


EditorDropdown.propTypes = {
    title: PropTypes.string,
    value: PropTypes.object,
    onChange: PropTypes.func,
    type: PropTypes.string
};
  
export default compose(withStyles(s))(EditorDropdown);
  
