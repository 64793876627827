import React from 'react';
import moment from 'moment-timezone';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import ViaLogo from '../svgs/viaLogo';
import Paragraph from '../library/paragraph';
import sFooter from './footer.scss';

function Footer() {
  return (
    <>
      <footer className={sFooter['page-footer']}>
        <section className={sFooter['footer-content']}>
          <article className={sFooter['column-left']}>
            <ViaLogo size="small" alt="Via Logo" />
          </article>
          <article className={sFooter['column-right']}>
            <Paragraph size="small" color="white">
              Copyright &copy; {moment().format('YYYY')} Project Travel. All
              rights reserved.
            </Paragraph>
          </article>
        </section>
      </footer>
    </>
  );
}

export default withStyles(sFooter)(Footer);
