import styled from 'styled-components';
import React, { useState } from 'react';

import CloseIcon from '../icons/CloseIcon';
import MobileNavigation from './MobileNavigation';
import HamburgerMenuIcon from '../icons/HamburgerMenuIcon';
import ScreenReaderText from '../base/ScreenReaderText.styled';

import { breakpoints } from '../../utils/breakpoints';
import { buttonFocus } from '../../utils/shared/focusStyles';

const Trigger = styled.button`
  display: none;
  min-width: 106px;
  padding: 0.9375rem 1.5rem;
  background-color: #eeeeee;
  border: 1px solid #373737;
  border-radius: 5px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 1rem;
  color: #373737;
  letter-spacing: 0.06em;
  line-height: 120%;

  @media ${breakpoints.desktop} {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  svg {
    width: 20px;
    height: 20px;
  }

  ${buttonFocus};
`;

export default function MenuDropdown() {
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  return (
    <>
      <Trigger
        type="button"
        onClick={() => {
          setMenuIsOpen(!menuIsOpen);
        }}
      >
        {menuIsOpen ? <CloseIcon /> : <HamburgerMenuIcon />}
        <ScreenReaderText>{menuIsOpen ? 'Close Main Navigation ' : 'Open Main Navigation '}</ScreenReaderText>
        Menu
      </Trigger>
      {menuIsOpen && <MobileNavigation isOpen={menuIsOpen} />}
    </>
  );
}
