import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import Select, { components } from 'react-select';
import StyledIcon from './Icon.styled';
const ActionList = styled.div`
  display: inline-block;
  margin-top: 10px;
  width: ${props => (props.width ? props.width : '200px')};
`;
const SubmissionButton = styled.button`
  color: var(--gray-medium3, #666);
  leading-trim: both;
  text-edge: cap;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: 0.056px;
  text-decoration-line: underline;
  background-color: transparent;
  border: none;
  &:hover {
    color: #446372;
    text-decoration-line: none;
  }
`;

const SubmissionButtonEditTag = styled.button`
  color: var(--secondary-darkest, #446372);
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 14.4px */
  letter-spacing: 0.048px;
  text-decoration-line: underline;
  leading-trim: both;
  text-edge: cap;
  text-decoration-line: underline;
  background-color: transparent;
  border: none;
  &:hover {
    text-decoration-line: none;
  }
`;

const TagFooter = styled.div`
  padding: 8px 0px 8px 0px;
  background-color: #f0f0f0;
  text-align: left;
  bottom: 0;
  left: 0;
  width: 100%;
  border-top: 1px solid #ccc;
`;

const SubmissionTagDropDown = ({
  defaultValue,
  id,
  options,
  customStyles,
  value,
  isMulti = true,
  maxMenuHeight = '150px',
  width,
  handleInputChange,
  handleSelectChange,
  applicationTag,
  tagSearchQuery,
  handleShowTagModal,
  showCreateTagOption = true,
  showCreateEditBtn = true,
  ...props
}) => {
  const permissions = useSelector(state => state.profile.permissions);

  const selectComponent = (
    <Select
      aria-label={props.label || 'Please select an option'}
      aria-invalid={props?.errorMsg?.length > 0 ? 'true' : 'false'}
      id={id}
      isMulti
      valueKey={id}
      value={applicationTag}
      styles={customStyles}
      isSearchable={true}
      onInputChange={handleInputChange}
      onChange={handleSelectChange}
      maxMenuHeight={maxMenuHeight}
      options={options}
      tabIndex="0"
      components={{
        NoOptionsMessage: () => (
          <CustomNoOptionsMessage tagSearchQuery={tagSearchQuery} handleShowTagModal={handleShowTagModal} />
        ),
        Menu: ({ children, ...props }) => (
          <components.Menu {...props}>
            {children}

            {showCreateEditBtn && (
              <>
                {permissions.organization_settings.includes('create_edit_delete_tags') &&
                  options.length > 0 &&
                  showCreateTagOption && <CustomFooter handleShowTagModal={handleShowTagModal} />}
              </>
            )}
          </components.Menu>
        ),
      }}
      {...props}
    />
  );

  const CustomNoOptionsMessage = ({ tagSearchQuery, handleShowTagModal }) => {
    return (
      <div
        className="custom-no-options-message"
        style={{
          display: 'flex',
          height: '36px',
          padding: '14px 12px 12px 8px',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '12px',
          alignSelf: 'stretch',
        }}
      >
        {showCreateEditBtn ? (
          <>
            {permissions.organization_settings.includes('create_edit_delete_tags') && showCreateTagOption ? (
              <SubmissionButton type="button" onClick={e => handleShowTagModal(true, 'create')}>
                <StyledIcon type={'AddCircle'} color={'#446372'} size={'16px'} />
                <span style={{ marginLeft: '5px' }}>
                  {tagSearchQuery.trim() ? `Create New Tag "${tagSearchQuery}"` : 'Create New Tag'}
                </span>
              </SubmissionButton>
            ) : (
              'No tags found'
            )}
          </>
        ) : (
          <>No tags found</>
        )}
      </div>
    );
  };

  const CustomFooter = ({ handleShowTagModal }) => {
    return (
      <TagFooter>
        <SubmissionButtonEditTag type="button" onClick={e => handleShowTagModal(true, 'edit')}>
          <StyledIcon type={'PencilSolid'} color={'#446372'} size={'18px'} />
          <span style={{ marginLeft: '5px' }}>Edit Tags</span>
        </SubmissionButtonEditTag>
      </TagFooter>
    );
  };

  return <ActionList width={width}>{selectComponent}</ActionList>;
};
SubmissionTagDropDown.displayName = 'SubmissionTagDropDown';

export default SubmissionTagDropDown;
