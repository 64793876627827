import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ProfileSelectInput from './inputs/ProfileSelectInput';

import history from '../../../../history';
import { breakpoints } from '../../utils/breakpoints';

const NavSelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 65%;
  margin-bottom: 1.4375rem;

  @media ${breakpoints.tablet} {
    width: 50%;
  }

  @media ${breakpoints.desktop} {
    display: none;
  }
`;

export default function MobileProfileMenu({ tabs, isAdmin, currentTab, label, urlPathSection }) {
  const handleSelectChange = event => {
    const index = tabs.findIndex(tabToFind => tabToFind.component === event.value);

    localStorage.setItem('scrollY', window.scrollY);

    if (isAdmin) {
      const pathname = window.location.pathname.split('/');
      const travelerId = pathname[3];

      history.push(`/client/travelers/${travelerId}/${urlPathSection}/${tabs[index].path}`);
    } else {
      history.push(`/traveler/${urlPathSection}/${tabs[index].path}`);
    }
  };

  return (
    <NavSelectContainer>
      <ProfileSelectInput
        id="selected_tab"
        // label={`${label}:`}
        ariaLabel={"Select Options"}
        label={''}
        value={{ label: currentTab.title, value: currentTab.component }}
        options={tabs.map(tab => ({ label: tab.title, value: tab.component }))}
        handleOnChange={handleSelectChange}
      />
    </NavSelectContainer>
  );
}

MobileProfileMenu.propTypes = {
  urlPathSection: PropTypes.string,
  label: PropTypes.string.isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string,
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      component: PropTypes.string.isRequired,
    }),
  ),
  currentTab: PropTypes.shape({
    path: PropTypes.string,
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    component: PropTypes.string.isRequired,
  }).isRequired,
};

MobileProfileMenu.defaultProps = {
  tabs: [],
  urlPathSection: 'profile',
};
