import React, { useState, useEffect } from 'react';
import { compose } from 'recompose';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import propTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import Headline from '../../../sites/components/library/headline';
import Paragraph from '../../../sites/components/library/paragraph';
import ToggleButton from '../../../sites/components/library/toggleButton';
import sWhatAreYouHereToDo from './whatAreYouHereToDo.scss';

export const enhance = compose(
  withStyles(sWhatAreYouHereToDo),
  withRouter,
);

function WhatAreYouHereToDo(props) {
  const showIntake = useSelector(state => state.profile.show_intake);
  const showProgramMatch = useSelector(state => state.profile.show_program_match);
  const showWelcomeScreen = useSelector(state => state.profile.profile[0].info.show_traveler_welcome_screen);
  const [showInstructions, setShowInstructions] = useState(showWelcomeScreen);

  useEffect(
    () => {
      props.setShowInstructions(showInstructions);
    },
    [showInstructions],
  );

  return (
    <>
      <div className={sWhatAreYouHereToDo['what-are-you-here-to-do']}>
        <div className={sWhatAreYouHereToDo.subHeader}>
          <Headline centered tag="h1" as="h2">
            What are you here to do?
          </Headline>
        </div>
        <div className={sWhatAreYouHereToDo.content}>
          <div className={sWhatAreYouHereToDo.toggle}>
            <ToggleButton
              title="Explore Programs"
              icon="mapMagnify"
              display="primary"
              onClick={() => {
                props.updateProfileDataInstructions(showInstructions, 'explore', showProgramMatch, showIntake);
              }}
            />
          </div>
          <div className={sWhatAreYouHereToDo.toggle}>
            <ToggleButton
              title="Register Travel"
              icon="suitcase"
              display="primary"
              onClick={() => {
                props.updateProfileDataInstructions(showInstructions, 'register', showProgramMatch, showIntake);
              }}
            />
          </div>
        </div>
        <div className={sWhatAreYouHereToDo.emailCheckbox}>
          <input
            type="checkbox"
            id="hideInstructions"
            name="hideInstructions"
            onClick={() => setShowInstructions(!showInstructions)}
          />
          <Paragraph> Don't show this welcome screen again</Paragraph>
        </div>
      </div>
    </>
  );
}

WhatAreYouHereToDo.propTypes = {
  updateProfileDataInstructions: propTypes.func,
};

export default enhance(WhatAreYouHereToDo);
