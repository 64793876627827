import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  font-family: 'Nunito', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 0.015em;
  color: #373737;
`;
Container.displayName = 'QuestionHeaderContainer';

const Header = styled.h1`
  color: #373737;
  font-family: 'Nunito', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 120%;
  letter-spacing: 0.015em;
  margin: unset;
`;
Container.displayName = 'QuestionHeaderText';

const QuestionHeaderDisplayForm = ({ question }) => {
  return (
    <Container>
      <Header>
        {question.text}
      </Header>
    </Container>
  );
}

export default QuestionHeaderDisplayForm;
