import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';

import { breakpoints } from '../../../utils/breakpoints';
import { formInputFocus } from '../../../utils/shared/focusStyles';
import characterLimits from '../../../utils/messageCharacterLimits';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const FormRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.875rem;
  align-items: center;

  @media ${breakpoints.tablet} {
    flex-direction: row;
  }
`;

const FormLabel = styled.label`
  margin-bottom: 0;
  align-self: flex-start;
  font-family: 'Nunito', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 140%;
  text-align: right;
  letter-spacing: 0.005em;
  color: #373737;
  flex: 1 0 15%;
`;


const FormSendingLabel = styled.div`
  margin-bottom: 0;
  align-self: flex-start;
  font-family: 'Nunito', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 140%;
  text-align: right;
  letter-spacing: 0.005em;
  color: #373737;
  flex: 1 0 15%;
`;
const FormRecipient = styled.div`
  margin-bottom: 0;
  flex-basis: 85%;

  p {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 140%;
    letter-spacing: 0.005em;
    color: #373737;
    margin-bottom: 0;
  }
`;

const FormFieldRequired = styled.span`
  color: #770711;
  align-self: flex-start;
`;

const FormInputGroup = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 85%;
  width: 100%;

  @media ${breakpoints.tablet} {
    width: auto;
  }
`;

const FormInput = styled.input`
  border: 0.0625rem solid #bbbbbb;
  background-color: #ffffff;
  border-radius: 2px;
  padding: 0.75rem 0.875rem 0.625rem 0.875rem;
  font-size: 0.875rem;

  &::placeholder {
    font-family: 'Open Sans', sans-serif;
    font-style: italic;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 120%;
    letter-spacing: 0.015em;
    color: #555555;
  }

  ${formInputFocus};
`;

const FormTextArea = styled.textarea`
  border: 0.0625rem solid #bbbbbb;
  background-color: #ffffff;
  border-radius: 2px;
  padding: 0.75rem 0.875rem 0.625rem 0.875rem;
  font-size: 0.875rem;
  resize: none;

  &::placeholder {
    font-family: 'Open Sans', sans-serif;
    font-style: italic;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 120%;
    letter-spacing: 0.015em;
    color: #555555;
  }

  ${formInputFocus};
`;

const FormInputFieldCount = styled.span`
  align-self: flex-end;
  font-style: italic;
  font-weight: 300;
  font-size: 0.75rem;
  line-height: 1.25rem;
  font-family: 'Open Sans', sans-serif;
  color: #555555;
  text-align: right;
  margin-top: 0.25rem;
`;

const FormStartDisclaimer = styled.div`
  display: flex;
  gap: 0.75rem;
  align-items: flex-start;
  text-align: center;
  margin: 5px 50px;
`;

const DisclaimerTextDiv = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: #373737;
  margin-bottom: 0;

  @media ${breakpoints.tablet} {
    font-size: 0.8125rem;
  }
`;

export default function MessageForm({ handleChange, hasBeenSubmitted }) {
  const { contacts } = useSelector(state => state.programBrochure);
  const programAlias = useSelector(state => state.auth.organization.info.alias_program);

  let contactText = `${programAlias} Administrators`;

  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');

  const handleSubjectChange = event => {
    setSubject(event.target.value);

    handleChange({
      subject: event.target.value,
      message,
    });
  };

  const handleMessageChange = event => {
    setMessage(event.target.value);

    handleChange({
      subject,
      message: event.target.value,
    });
  };

  useEffect(
    () => {
      if (hasBeenSubmitted) {
        setSubject('');
        setMessage('');
      }
    },
    [hasBeenSubmitted],
  );

  return (
    <Form method="POST">
      {contactText && (
        <FormRow>
          <FormSendingLabel>Sending to:</FormSendingLabel>
          <FormRecipient>
            <p>{contactText}</p>
          </FormRecipient>
        </FormRow>
      )}
      <FormRow>
        <FormLabel htmlFor="subject">
          Subject: <FormFieldRequired>&#42;</FormFieldRequired>
        </FormLabel>
        <FormInputGroup>
          <FormInput
            id="subject"
            type="text"
            name="subject"
            placeholder="Enter message subject"
            required
            value={subject}
            // aria-describedby={(!!characterLimits && parseInt(characterLimits.subject) > 0) ? `characterCount-subject` : ""}
            onChange={handleSubjectChange}
          />
          <FormInputFieldCount aria-live="polite" id={`characterCount-subject`}>
            {characterLimits.subject - subject.length > 0 ? characterLimits.subject - subject.length : 0} characters
            remaining
          </FormInputFieldCount>
        </FormInputGroup>
      </FormRow>
      <FormRow>
        <FormLabel htmlFor="message">
          Message: <FormFieldRequired>&#42;</FormFieldRequired>
        </FormLabel>
        <FormInputGroup>
          <FormTextArea
            id="message"
            name="message"
            rows="10"
            placeholder="Enter message body"
            required
            value={message}
            // aria-describedby={(!!characterLimits && parseInt(characterLimits.message) > 0) ? `characterCount-message` : ""}
            onChange={handleMessageChange}
          />
          <FormInputFieldCount aria-live="polite" id={`characterCount-message`}>
            {characterLimits.message - message.length > 0 ? characterLimits.message - message.length : 0} characters
            remaining
          </FormInputFieldCount>
        </FormInputGroup>
      </FormRow>
      <FormRow>
        <FormStartDisclaimer>
          <DisclaimerTextDiv>
            By clicking NEXT, you agree to be contacted about this {programAlias.toLowerCase()} by advisors and
            administrators of this {programAlias.toLowerCase()}.
          </DisclaimerTextDiv>
        </FormStartDisclaimer>
      </FormRow>
    </Form>
  );
}

MessageForm.propTypes = {
  handleChange: PropTypes.func.isRequired,
  hasBeenSubmitted: PropTypes.bool.isRequired,
};
