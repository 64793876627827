import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import styled from 'styled-components';
import StyledIcon from '../../../../styledComponents/styles/Icon.styled';
import LocationIcon from '../../../travelerProfile/components/icons/LocationIcon';
import CardApplicationStatus from '../../../travelerProfile/components/my-programs/cards/CardApplicationStatus';
import { breakpoints } from '../../../travelerProfile/utils/breakpoints';
import ScreenReaderHeadingComponent from '../../../travelerProfile/components/heading/ScreenReaderHeadingComponent';
const ProgramDiv = styled.div`
  border-bottom: 1px solid #bbbbbb;
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
  padding-bottom: 30px;

  @media ${breakpoints.mobile} {
    padding-left: 10px;
    padding-right: 10px;
  }
`;

const ImageDiv = styled.div`
  align-self: center;
  flex: 85px 0;

  img {
    border-radius: 50%;
    height: 66px;
    width: 66px;
  }
`;

const Deadline = styled.div`
  display: flex;
  align-items: center;
  gap : 0.243125rem;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  color: #373737;
  letter-spacing: 0.0025em;
  line-height: 120%;
  margin-top: 10px; 
  svg {
    align-self: center;
    display: inline-flex;
    position: relative;
    margin-left: -3px;
  }
`;

const DetailsDiv = styled.div`
  flex: 1;
  flex-direction: row;
`;

const DetailType = styled.div`
  color: #373737;
  flex: 1;
  font-family: 'Nunito', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;

  span {
    ${({ customBranding }) =>
      customBranding ? `border-bottom: 1px ${customBranding} solid;` : ` border-bottom: 1px #516c4f solid;`};
  }
`;

const DetailProgramTitle = styled.div`
  color: #373737;
  flex: 1;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  padding-top: 10px;

  span {
    ${({ customBranding }) =>
      customBranding ? `border-bottom: 1px ${customBranding} solid;` : ` border-bottom: 1px #516c4f solid;`};
  }
`;

const DetailRangeDates = styled.div`
  color: #373737;
  display: inline-block;
  flex: 1;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  padding-top: 10px;
  text-align: left;

  svg {
    align-self: center;
    display: inline-flex;
    margin-bottom: 3px;
    margin-right: 5px;
    position: relative;
  }
`;

const StatusDiv = styled.div`
  flex: 155px 0;
  text-align: end;

  p {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    margin-bottom: 5px;
  }
`;

export default function SubmissionPlanInfo({ planInfo }) {
  const [dateRange, setDateRange] = useState('');
  const [location, setLocation] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const orgTimeZone = useSelector(state => state.profile.orgTimezone);
  const customBranding = useSelector(state => state.profile.customBranding.themes.theme_color_dark);
  const customBrandingAccent = useSelector(state => state.profile.customBranding.themes.theme_color_accent);

  useEffect(() => {
    if (planInfo) {
      let sDate = null;
      let eDate = null;

      if (planInfo?.startDate) {
        sDate = moment(new Date(planInfo?.startDate)).format('MMM D[,] YYYY');
      }
      if (planInfo?.endDate) {
        eDate = moment(new Date(planInfo?.endDate)).format('MMM D[,] YYYY');
      }
      setDateRange(`${sDate}${eDate ? ' - ' + eDate : ''}`);
    }

    let locsString = '';
    let locsImage = '';
   
    if (planInfo?.location && planInfo?.location?.length > 0) {
      planInfo?.location.map(loc => {
        let locationStr = ""; 
        if(loc.locality && loc.country_common_name){
          locationStr = loc.locality + ', ' + loc.country_common_name; 
        }
        else{
          locationStr = loc?.formatted_address; 
        }
        locsString = locsString + locationStr + '; ';
        if(!locsImage) {
          locsImage = loc.image_url;
        }
      });
      locsString = locsString.slice(0, -2);
    }
    setLocation(locsString);
    setImageUrl(locsImage);

  }, [planInfo, orgTimeZone]);

  return planInfo ? (
    <ProgramDiv>
      <ImageDiv>
        <img
          src={imageUrl}
          alt={planInfo?.name}
        />
      </ImageDiv>
      <DetailsDiv>
        <ScreenReaderHeadingComponent text={`${planInfo?.name}`} type="div"/>
        <DetailType customBranding={customBranding}>
          <span tabIndex={0}>
            {planInfo?.name}
          </span>
        </DetailType>
        <DetailProgramTitle customBranding={customBranding} tabIndex={0}>
          <span tabIndex={0}>
            {planInfo?.planTitle}
          </span>
        </DetailProgramTitle>
        <DetailRangeDates customBranding={customBranding}>
          <StyledIcon type="Form_date" color={customBrandingAccent} size="20px" />
          <span tabIndex={0}>
          {/* {`${planInfo?.startDate}- ${planInfo?.endDate}`} */}
          {dateRange}
          </span>
        </DetailRangeDates>
        <Deadline>
          <LocationIcon color={customBrandingAccent} width='26' height='24' />
          <span tabIndex={0}>
            {location}
          </span>
        </Deadline>
        
      </DetailsDiv>
      <StatusDiv>
        <p>Current Status:</p>
        <CardApplicationStatus status={planInfo?.submission_status?.name} />
      </StatusDiv>
    </ProgramDiv>
  ) : null;
}