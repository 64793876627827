exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ":root{--dark:var(--theme_color_dark);--accent:var(--theme_color_accent);--ocean:var(--theme_color_light)}._2KtA9{height:14px;background:#fff;width:100%;display:-ms-flexbox;display:flex}._120mj{background-color:var(--theme_color_accent);background-color:var(--accent,#dd2160);height:100%;width:0;-webkit-transition:width 2s;-o-transition:width 2s;transition:width 2s}._3bL78{color:var(--theme_color_accent);color:var(--accent,#dd2160);margin-left:auto;margin-right:10px}._3bL78,.YwQwX{font-family:AvenirNextBold,sans-serif;font-size:11px;font-weight:700}.YwQwX{color:#fff;margin-left:10px}", ""]);

// exports
exports.locals = {
	"fullBar": "_2KtA9",
	"percentBar": "_120mj",
	"percentTextRight": "_3bL78",
	"percentTextLeft": "YwQwX"
};