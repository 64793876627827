exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@-webkit-keyframes wIXG6{to{-webkit-transform:rotate(1turn)}}@keyframes wIXG6{to{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}._1Vk1E ._33weL{-ms-flex:1 1;flex:1 1;-ms-flex-direction:row;flex-direction:row;display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;padding:16px}@media only screen and (max-width:599px){._1Vk1E ._33weL{-ms-flex-direction:column;flex-direction:column}}._1Vk1E ._213tA{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;padding:40px 100px;-ms-flex-pack:center;justify-content:center}._1Vk1E ._213tA ._33hcz{padding:12px}._1Vk1E ._3LawU{-ms-flex:1 1;flex:1 1;-ms-flex-direction:column;flex-direction:column;display:-ms-flexbox;display:flex;border-top:2px solid #ebebeb;padding:32px 20px 0 0;-ms-flex-pack:center;justify-content:center}@media only screen and (max-width:599px){._1Vk1E ._3LawU{-ms-flex-direction:column;flex-direction:column}}._1Vk1E ._3LawU .bq-qG{padding:20px 0;display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-pack:center;justify-content:center}._1Vk1E ._3LawU .bq-qG .qGSqt{padding-left:12px}@media only screen and (max-width:599px){._1Vk1E ._3LawU .bq-qG .qGSqt{padding-top:12px}}._1Vk1E ._3LawU ._38hMZ{-ms-flex:1 1;flex:1 1}", ""]);

// exports
exports.locals = {
	"send-safecheck-message-success": "_1Vk1E",
	"subHeader": "_33weL",
	"content": "_213tA",
	"toggle": "_33hcz",
	"footer": "_3LawU",
	"footer-button-wrapper": "bq-qG",
	"button-content": "qGSqt",
	"footer-confirm": "_38hMZ",
	"spin": "wIXG6"
};