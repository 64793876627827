// export const reasonOptions = [
//   { value: 'Academics', label: 'Academics' },
// { value: 'Cancellation of Program', label: 'Cancellation of Program' },
// { value: 'Coronavirus disease (COVID-19)', label: 'Coronavirus disease (COVID-19)' },
// { value: 'Deferred Finances', label: 'Deferred Finances' },
// { value: 'Health or Safety Concern', label: 'Health or Safety Concern' },
// { value: 'Housing Challenge', label: 'Housing Challenge' },
// { value: 'Insufficient Advising or Support', label: 'Insufficient Advising or Support' },
// { value: 'Lack of Family or Community Support', label: 'Lack of Family or Community Support' },
// { value: 'Lack of Finances', label: 'Lack of Finances' },
// { value: 'Lack of Information', label: 'Lack of Information' },
// { value: 'Personal Obligation', label: 'Personal Obligation' },
// { value: 'Prefer Not to Say', label: 'Prefer Not to Say' },
// { value: 'Sport, Music, or Artistic Obligation', label: 'Sport, Music, or Artistic Obligation' },
// { value: 'Time Away from a Job', label: 'Time Away from a Job' },
// { value: 'Timing of the Program', label: 'Timing of the Program' },
// { value: 'Other', label: 'Other' },
// ];
import React, { useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchWithdrawDefferedReason } from '../actions/formsActions';
export const reasonOptions = (programAlias) => { 
  const dispatch = useDispatch();
  const reasonList = useSelector(state => state.forms.withdrawReason);
  const [optionList, setOptionList] = useState([]);
  useEffect(()=>{
    dispatch(fetchWithdrawDefferedReason())
  },[])

  useEffect(()=>{
    const options = reasonList?.data?.data?.map((option)=>{
      return {
          id:option.id, 
          value: option.id, 
          //label: option.attributes.display_text 
          label: option.attributes.display_text.replace(/Program/g, programAlias) 
        };
    });
    setOptionList(options)
  },[reasonList]);

  return optionList;


//   return [
//   {id:1, value: 'Academics', label: 'Academics' },
//   {id:2, value: `Cancellation of Program`, label: `Cancellation of ${programAlias.toLowerCase()}` },
//   {id:3, value: `Chose Different Program`, label: `Chose Different ${programAlias.toLowerCase()}` },
//   {id:4, value: `Declined`, label: `Declined` },
//   {id:5, value: 'Deferred Finances', label: 'Deferred Finances' },
//   {id:6, value: 'Global health risk (i.e. COVID-19)', label: 'Global health risk (i.e. COVID-19)' },
//   {id:7, value: 'Health or Safety Concern', label: 'Health or Safety Concern' },  
//   {id:8, value: 'Housing Challenge', label: 'Housing Challenge' },
//   {id:9, value: 'Insufficient Advising or Support', label: 'Insufficient Advising or Support' },
//   {id:10, value: 'Lack of Family or Community Support', label: 'Lack of Family or Community Support' },
//   {id:11, value: 'Lack of Finances', label: 'Lack of Finances'},
//   {id:12, value: 'Lack of Information', label: 'Lack of Information'},
//   {id:13, value: 'Personal Obligation', label: 'Personal Obligation' },
//   {id:14, value: 'Prefer Not to Say', label: 'Prefer Not to Say' },
//   {id:15, value: 'Returned Home Early', label: 'Returned Home Early' },
//   {id:16, value: 'Sport, Music, or Artistic Obligation', label: 'Sport, Music, or Artistic Obligation' },
//   {id:17, value: 'Switching to a Future Term', label: 'Switching to a Future Term' },
//   {id:18, value: 'Time Away from a Job', label: 'Time Away from a Job' },
//   {id:19, value: `Timing of Program`, label: `Timing of ${programAlias.toLowerCase()}` },
//   {id:20, value: 'Other', label: 'Other' },
//   //{ value: 'Coronavirus disease (COVID-19)', label: 'Coronavirus disease (COVID-19)' },
// ];

}