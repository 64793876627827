exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3DJ4w{padding-top:64px;padding-top:4rem;padding-left:128px;padding-left:8rem;padding-right:128px;padding-right:8rem;width:100%;background-color:#fff}._2zFco{margin:0;max-width:72%;min-width:72%}._2F1hQ{font-family:IntroBold,sans-serif;font-size:28px;font-size:1.75rem;border-bottom:3px solid #f5f5f5;padding-bottom:24px;padding-bottom:1.5rem;margin-bottom:14px;margin-bottom:.875rem;color:#043544}._2zFco p{color:#043544;font-family:Open Sans;font-size:16px;font-size:1rem;line-height:24px}._2TXH-{color:#767676!important;font-size:16px;padding-bottom:16px;padding-bottom:1rem}._2TXH-,._3JPHa{font-family:AvenirNextRegular,sans-serif}._3JPHa{margin:26px 0;margin:1.625rem 0;color:#767676;font-size:28px;font-size:1.75rem;font-weight:200;line-height:32px}._3JPHa,._3JPHa a{background-color:#fff}._3JPHa a{color:#dd2160;color:var(--accent,#dd2160)}._3JPHa>p:after{content:\"\\A\";white-space:pre}", ""]);

// exports
exports.locals = {
	"wrapper": "_3DJ4w",
	"section": "_2zFco",
	"header": "_2F1hQ",
	"contactHeader": "_2TXH-",
	"description": "_3JPHa"
};