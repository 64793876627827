import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { breakpoints } from '../../utils/breakpoints';

const Container = styled.div`
  background: #f7f7f7;
  border-radius: 0px 0px 20px 20px;

  @media ${breakpoints.tablet} {
    box-shadow: 0px 10px 20px rgba(132, 131, 131, 0.3);
  }
`;

const Heading = styled.div`
  padding-top: 0.78125rem;
  padding-bottom: 0.59375rem;
  padding-left: 1.07625rem;
  background-color: #f7f7f7;
  border-bottom: 5px solid var(--theme_color_accent);
  border-radius: 2px 2px 0px 0px;
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
  font-size: 1.125rem;
  color: #373737;
  letter-spacing: 0.015em;
  line-height: 120%;

  @media ${breakpoints.tablet} {
    background-color: #eeeeee;
  }
`;

const ChildrenContainer = styled.div`
  padding: 1.68rem 1.9225rem 3.09375rem 1.9225rem;
`;

export default function ProfileFormContainer({ heading, children }) {
  return (
    <Container>
      <Heading tabIndex={0}>{heading}</Heading>
      <ChildrenContainer>{children}</ChildrenContainer>
    </Container>
  );
}

ProfileFormContainer.propTypes = {
  children: PropTypes.node.isRequired,
  heading: PropTypes.string.isRequired,
};
