import React from 'react';
import { compose } from 'recompose';
import propTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import V2InputText from '../../../components/v2/inputs/text';
import Button from '../../../components/library/button';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import sStyles from './registerEventForm.scss';
import _get from 'lodash/get';
import InputText from '../../../components/library/inputText';
import TextInput from '../../../../styledComponents/styles/TextInput.styled';
import { ThemeProvider } from 'styled-components';
import { themeViaForms } from '../../../../styledComponents/settings/theme';

export const enhance = compose(withStyles(sStyles));

const schema = yup.object().shape({
  firstname: yup.string().required('First Name is Required'),
  lastname: yup.string().required('Last Name is Required'),
  email: yup
    .string()
    .required('Email is Required')
    .email('Valid Email Required'),
});

function RegisterEventFormMobile(props) {
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ resolver: yupResolver(schema) });

  const onSubmitHandler = data => {
    props.onSubmit(data);
    reset({
      firstname: '',
      lastname: '',
      email: '',
    });
  };


  return (
    <ThemeProvider theme={themeViaForms}>
      <div className={sStyles.wrapper}>
        {/* <div className={sStyles.header}>Enter your information below to confirm attendance</div> */}
        <div className={sStyles.form}>
          <form onSubmit={handleSubmit(onSubmitHandler)} id='eventFormMobile'>
            <div className={sStyles.input}>
              {/* <label htmlFor="firstname">First Name</label> */}
              <Controller
                name="firstname"
                control={control}
                render={({ field: { ref, value, onChange, onBlur } }) => (
                  <>
                    <TextInput
                      errorMsg={_get(errors.firstname, 'message') || ''}
                      id={'firstname'}
                      aria-label="Enter first name"
                      name={'firstname'}
                      openSans
                      label={`First Name`}
                      placeholder={'Enter first name'}
                      inputBorderRadius="6px"
                      inputFontSize="14px"
                      customLabelStyle={{ fontWeight: '400' }}
                      required
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                    />
                  </>
                )}
              />
            </div>
            <div className={sStyles.input}>
              {/* <label htmlFor="lastname">Last Name</label> */}
              <Controller
                name="lastname"
                control={control}
                render={({ field: { ref, value, onChange, onBlur } }) => (
                  <>
                    <TextInput
                      errorMsg={_get(errors.lastname, 'message') || ''}
                      id={'lastname'}
                      aria-label="Enter last name"
                      name={'lastname'}
                      openSans
                      label={`Last Name`}
                      placeholder={'Enter last name'}
                      inputBorderRadius="6px"
                      inputFontSize="14px"
                      customLabelStyle={{ fontWeight: '400' }}
                      required
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                    />
                  </>
                )}
              />
            </div>
            <div className={sStyles.input}>
              {/* <label htmlFor="email">Email</label> */}
              <Controller
                name="email"
                control={control}
                render={({ field: { ref, value, onChange, onBlur } }) => (
                  <>
                    <TextInput
                      errorMsg={_get(errors.email, 'message') || ''}
                      id={'email'}
                      aria-label="Enter email"
                      name={'email'}
                      openSans
                      label={`Email`}
                      placeholder={'Enter email'}
                      inputBorderRadius="6px"
                      inputFontSize="14px"
                      customLabelStyle={{ fontWeight: '400' }}
                      required
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                    />
                  </>
                )}
              />
            </div>
            {/* <div className={sStyles.button}>
              <Button
                className={sStyles.confirmButton}
                display="clientAccent"
                ariaLabel="Confirm Registration"
                kind="solid"
                size="normal"
                type="submit"
              >
                Confirm Registration
              </Button>
            </div> */}
          </form>
        </div>
      </div>
    </ThemeProvider>
  );
}

RegisterEventFormMobile.propTypes = {
  eventId: propTypes.string.isRequired,
  onSubmit: propTypes.func.isRequired,
};

export default enhance(RegisterEventFormMobile);
