import { css } from 'styled-components';

export const buttonFocus = css`
  &:focus {
    outline: none;
  }

  &:focus-visible {
    border-color: #255a94;
  }
`;

export const formInputFocus = css`
  &:focus {
    outline: none;
    border-color: #255a94;
  }
`;
