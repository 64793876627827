import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import InputSelect from '../../../../components/library/inputSelect';
import { fetchActivityTypes } from '../../../../../actions/lookupActions';

function ActivityTypeSelect(props) {
  const dispatch = useDispatch();
  const activityTypes = useSelector(state => state.lookup.allActivityTypes);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (!activityTypes.activityTypes) {
      dispatch(fetchActivityTypes());
    } else {
      setOptions(
        activityTypes.activityTypes.map(item => {
          return { label: item.attributes.name, value: item.id };
        }),
      );
    }
  }, [activityTypes]);

  return (
    <>
      {activityTypes && activityTypes.activityTypes && (
        <InputSelect {...props} options={options} />
      )}
    </>
  );
}

ActivityTypeSelect.propTypes = {
  errorMessage: propTypes.string,
  labelText: propTypes.string,
  helpText: propTypes.string,
  placeholder: propTypes.string,
  isRequired: propTypes.bool,
};


ActivityTypeSelect.defaultProps = {
  errorMessage: '',
  labelText: 'ACTIVITY TYPE',
  helpText: '',
  placeholder: '',
  isRequired: false,
};

export default (ActivityTypeSelect);
