import React, { lazy, Suspense, useEffect, useState } from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import StyledIcon from '../../../../../styledComponents/styles/Icon.styled';
import TextInput from '../../../../../styledComponents/styles/TextInput.styled';
import Toggle from '../../../../../styledComponents/styles/Toggle.styled';
import { AccentButtonMediumNunito } from '../../../../../styledComponents/styles/Buttons.styled';
import { AccentButtonSpinner } from '../../../../../styledComponents/styles/spinner.styled';
import { updateForm, updateQuestion } from '../../../../../actions/formsActions';
import _get from 'lodash/get';

const EditTextArea = lazy(() => import('../../../../../styledComponents/styles/EditTextArea.styled'));

const FormContainer = styled.div`
  font-family: 'Nunito', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 0.015em;
  color: #373737;
`;

const InputContainer = styled.div`
  flex: 1;
  margin: 20px 0;
  text-align: left;
`;

const SubmitContainer = styled.main`
  display: flex;
  justify-content: flex-end;
  margin-top: 35px;
`;

const ToggleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: inherit;
`;

const ToggleLabel = styled.span`
  align-self: center;
  flex: 1;
  justify-content: center;
  padding-left: 10px;
  padding-bottom: 7px;
`;

const InfoMsg = styled.div`
  background-color: #deecff;
  border-left: 20px solid #5180b1;
  border-radius: 5px;
  color: #4d4d4d;
  line-height: 17px;
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 20px;
  width: 100%;
`;

export default function QuestionShortTextAddEdit({ question, edit = false, onClose }) {
  const MAX_LENGTH = 90;
  const qIdentifier = 'short_text';
  const dispatch = useDispatch();
  const fetchForm = useSelector(state => state.forms.fetchForm);
  const questionTypesInfo = useSelector(state => state.forms.questionTypes);
  const updateFormSubmit = useSelector(state => state.forms.updateForm);
  const updateQuestionSubmit = useSelector(state => state.forms.updateQuestion);
  const [formId, setFormId] = useState(null);
  const customAliases = useSelector(state => state.profile.customAliases);
  const isTravelerInfo = ['travelerinfofield', 'customfield'].includes(question?.sourceable_type?.toLowerCase());
  const [disableSubmit, setDisableSubmit] = useState(false);

  const schema = yup.object().shape({
    short_label: yup
      .string()
      .required('Question must have label')
      .max(MAX_LENGTH, `Label can not be more than ${MAX_LENGTH} characters`),
    short_instructions: yup.string(),
    short_char_limit: yup
      .number()
      .integer('Limit must be positive numerical (i.e. 5000)')
      .typeError('Limit must be positive numerical (i.e. 5000)')
      .min(0, 'Limit must be positive numerical (i.e. 5000)')
      .nullable()
      .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value)),
    short_admin_only: yup.boolean(),
    short_required: yup.boolean(),
  });

  const { control, handleSubmit, formState, setValue } = useForm({
    shouldUnregister: false,
    resolver: yupResolver(schema),
    defaultValues: {
      short_label: edit ? question.label : '',
      short_instructions: edit ? question.instructions || '' : '',
      short_char_limit: edit ? question.character_limit : 90,
      short_admin_only: false,
      short_required: edit ? question.required : true,
    },
    mode: 'onChange',
  });

  useEffect(() => {
    if (!fetchForm?.loading && fetchForm?.data) {
      setDisableSubmit(false);
      setFormId(fetchForm.data.id);
    }
  }, [fetchForm]);

  useEffect(() => {
    if (
      (!updateFormSubmit?.loading && updateFormSubmit?.data) ||
      (!updateQuestionSubmit?.loading && updateQuestionSubmit?.data)
    ) {
      setDisableSubmit(false);
      onClose();
    }
  }, [updateFormSubmit, updateQuestionSubmit]);

  const onSubmitHandler = data => {
    if (questionTypesInfo && questionTypesInfo.data) {
      const questionType = questionTypesInfo.data.reduce((prev, curr) => {
        return prev || curr.attributes.question_types.find(question => question.identifier === qIdentifier);
      }, undefined);

      const values = {
        admin_only: false,
        character_limit: data.short_char_limit,
        instructions: data.short_instructions,
        label: data.short_label,
        required: data.short_required,
      };

      if (formId && data && (questionType?.id || question?.id)) {
        //update or save new
        const actionToCall = edit ? updateQuestion : updateForm;
        const idToUpdate = edit ? question.id : questionType.id;
        setDisableSubmit(true);
        dispatch(actionToCall(formId, questionType.endpoint, idToUpdate, values));
      }
    }
  };

  return (
    <FormContainer>
      <form id={'shortAdd'} onSubmit={handleSubmit(onSubmitHandler)}>
        {isTravelerInfo && (
          <TextInput
            aria-label={'Traveler Info Field'}
            errorMsg=""
            id={'readonly_label'}
            label={'Traveler Info Field'}
            openSans
            placeholder={question?.sourceable_attributes?.display_name || question?.sourceable_attributes?.title}
            readOnly
            disabled={true}
            icon={'Sync'}
          />
        )}
        <Controller
          control={control}
          name={'short_label'}
          render={({ field: { ref, ...rest } }) => (
            <InputContainer>
              <TextInput
                aria-label={'Short Text Label'}
                errorMsg={_get(formState.errors.short_label, 'message') || ''}
                id={'short_label'}
                label={isTravelerInfo ? 'Label' : 'Short Text Label'}
                openSans
                placeholder={'Enter Label'}
                required
                {...rest}
              />
            </InputContainer>
          )}
        />
        {!isTravelerInfo && (
          <>
            <Controller
              control={control}
              name={'short_instructions'}
              render={({ field: { ref, ...rest } }) => (
                <InputContainer>
                  <Suspense fallback={<div />}>
                    <EditTextArea
                      aria-label="Instructions"
                      errorMsg={_get(formState.errors.short_instructions, 'message') || ''}
                      placeholder={`Enter Instructions`}
                      label={`Instructions`}
                      id={'short_instructions'}
                      {...rest}
                    />
                  </Suspense>
                </InputContainer>
              )}
            />
            <Controller
              control={control}
              name={'short_char_limit'}
              render={({ field: { ref, ...rest } }) => (
                <InputContainer>
                  <TextInput
                    aria-label={'Short Text Label'}
                    errorMsg={_get(formState.errors.short_char_limit, 'message') || ''}
                    id={'short_char_limit'}
                    label={'Set Character Limit'}
                    openSans
                    placeholder={'Enter character limit'}
                    type={'number'}
                    {...rest}
                  />
                </InputContainer>
              )}
            />
          </>
        )}
        {isTravelerInfo && (
          <InfoMsg>
            {`${customAliases.alias_traveler} info fields will be auto-populated 
            from ${customAliases.alias_traveler.toLowerCase()}’s profile 
            if the ${customAliases.alias_traveler.toLowerCase()} has filled 
            out their profile.`}
          </InfoMsg>
        )}
        <Controller
          control={control}
          name={'short_required'}
          render={({ field: { onChange, register, ref, ...rest } }) => (
            <ToggleContainer>
              <Toggle
                id={'short_required'}
                label={'Required'}
                onChange={value => {
                  setValue('short_required', value);
                }}
                ref={register}
                {...rest}
              />
              <ToggleLabel>Required Field</ToggleLabel>
            </ToggleContainer>
          )}
        />
        <SubmitContainer>
          <AccentButtonMediumNunito
            aria-label={edit ? 'Save Short Text' : 'Add Short Text to Form'}
            form="shortAdd"
            type={'submit'}
            disabled={disableSubmit}
          >
            {disableSubmit ? <AccentButtonSpinner displayText='Saving ...' /> : edit ? 'Save' : 'Add to Form'}
            {!disableSubmit && <StyledIcon type='ChevronForward' color='#fff' />}
          </AccentButtonMediumNunito>
        </SubmitContainer>
      </form>
    </FormContainer>
  );
}
