import React from 'react';
import styled from 'styled-components';
import StyledIcon from './Icon.styled';

const CardContainer = styled.div`
  background: #eeeeee;
  border: 1px solid #d7d7d7;
  box-sizing: border-box;
  box-shadow: 0 5px 10px rgba(196, 196, 196, 0.5);
  border-radius: 2px;
  width: 108px;
  height: 96px;
  cursor: pointer;
`;
const Container = styled.div`
  margin-top: 5px;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;

const LineContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const Line = styled.div`
  width: 14.46px;
  height: 1.98px;
  background: #848383;
  margin-top: 4px;
  border-radius: 2px;
`;

const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  font-family: 'Nunito', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #373737;
  margin-top: 10px;
`;

const QuestionTypeCard = ({ text = '', identifier }) => {
  return (
    <CardContainer>
      <Container>
        <LineContainer>
          <Line />
        </LineContainer>
        <LineContainer>
          <Line />
        </LineContainer>
      </Container>
      <IconContainer>
        <StyledIcon type={`Form_${identifier}`} size={'20px'} questionType={true} />
      </IconContainer>
      <TextContainer>{text}</TextContainer>
    </CardContainer>
  );
};

export default QuestionTypeCard;
