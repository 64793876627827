import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  color: #373737;
  line-height: 20px;
`;

const Indicator = styled.span`
  width: 10px;
  height: 10px;
  border-radius: 50px;

  ${({ status }) => {
    switch (status) {
      case 'Not Started':
        return `
          background-color: #CCCCCC;
          border: 1px solid #666666;
        `;
      case 'Started':
        return `
          background-color: #DDAF64;
        `;
      case 'Submitted':
        return `
          background-color: #DAFDD7;
          border: 1px solid #0E5B2F;
        `;
      case 'Accepted':
        return `
          background-color: #0E5B2F;
        `;
      default:
        return ``;
    }
  }};
`;

export default function FormStatus({ status }) {
  return (
    <Container>
      <Indicator status={status} />
      <span>{status}</span>
    </Container>
  );
}

FormStatus.propTypes = {
  status: PropTypes.oneOf(['Not Started', 'Started', 'Submitted', 'Accepted']).isRequired,
};
