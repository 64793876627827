import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { ThemeProvider } from 'styled-components';
import { AccentButtonMediumOpenSans, ButtonMediumOpenSans } from '../../../styledComponents/styles/Buttons.styled';
import { deleteTraveler } from '../../../actions/profileActions';
import TextInput from '../../../styledComponents/styles/TextInput.styled';
import { themeVia } from '../../../styledComponents/settings/theme';
import ToastMessage from '../../../sites/viaGlobal/components/toastMessage';
import ViaModal from '../../../sites/components/library/viaModal';

const FlexContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  font-family: Open Sans, sans-serif;
  font-size: 16px;
  font-weight: 400;
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;

  button {
    margin: 15px;
  }
`;

const FooterButtonContainer = styled.div`
  display: flex;
  flex-direction: row;

  button {
    margin: 15px;
  }
`;
const Header = styled.h1`
  color: #0b3544;
  font-size: 28px;
  font-weight: 600;
  line-height: 74px;
`;

const Instructions = styled.div`
  margin-bottom: 30px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  color: #373737;
  width: 75%;
`;

const InstructionBold = styled.div`
  margin-bottom: 30px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  color: #373737;
  width: 75%;
`;

const TextInputContainer = styled.div`
  width: 75%;
`;

function DeleteTravelerModal(props) {
  const dispatch = useDispatch();
  const customAliases = useSelector(state => state.profile.customAliases);
  const [showToastSuccess, setShowToastSuccess] = useState(false);
  const [showToastFailure, setShowToastFailure] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const travelerDeleted = useSelector(state => state.profile.deleteTraveler);
  const travelerId = useSelector(state => state.profile.profile[0].profile.user_id);
  const [value, setValue] = useState('');

  useEffect(
    () => {
      if (!!travelerDeleted && !!travelerDeleted.data) {
        if (travelerDeleted.data.message) {
          setToastMessage(`${customAliases.alias_traveler} deleted`);
          setShowToastSuccess(true);
        } else if (travelerDeleted.data.error) {
          setToastMessage(`Unable to delete ${customAliases.alias_traveler.toLowerCase()}`);
          setShowToastFailure(true);
        }
      }
    },
    [travelerDeleted],
  );
  const handleClose = () => {
    setValue('');
    props.onClose(false);
  };

  const handleDelete = () => {
    dispatch(deleteTraveler(travelerId));
    setValue('');
    props.onClose(false);
  };

  return (
    <ThemeProvider theme={themeVia}>
      <ViaModal showing={props.show} onClose={() => handleClose()} width={'519px'}>
        <FlexContainer>
          <Header>Delete {customAliases.alias_traveler} </Header>
          <Instructions>
            Deleting {props.travelerName} will permanently delete all
            {` ${customAliases.alias_traveler.toLowerCase()} `}
            data, including forms and applications.
          </Instructions>
          <InstructionBold>This action CANNOT be undone.</InstructionBold>
          <TextInputContainer>
            <TextInput
              aria-label="Type Delete in input to confirm"
              placeholder={`Type "DELETE" to confirm`}
              id="delete_traveler"
              name="delete_traveler"
              errorMsg={value.length > 5 && value !== 'DELETE' ? 'The word "DELETE" must be entered' : ''}
              label={`To confirm, type "DELETE".`}
              value={value}
              onChange={e => {
                setValue(e.target.value);
              }}
            />
          </TextInputContainer>

          <FooterContainer>
            <FooterButtonContainer>
              <ButtonMediumOpenSans aria-label="Cancel" onClick={() => handleClose()}>
                Cancel
              </ButtonMediumOpenSans>
              <AccentButtonMediumOpenSans
                aria-label="Delete Traveler"
                disabled={value !== 'DELETE'}
                onClick={() => {
                  handleDelete();
                }}
              >
                Delete {customAliases.alias_traveler}
              </AccentButtonMediumOpenSans>
            </FooterButtonContainer>
          </FooterContainer>
        </FlexContainer>
      </ViaModal>
      <ToastMessage
        message={toastMessage}
        show={showToastSuccess}
        isSuccess
        onClose={() => {
          setShowToastSuccess(false);
          setToastMessage('');
          setTimeout(() => {
            window.location.href = '/client/traveler-dashboard';
          }, 2000);
        }}
      />
      <ToastMessage
        message={toastMessage}
        show={showToastFailure}
        isSuccess={false}
        onClose={() => {
          setShowToastFailure(false);
          setToastMessage('');
        }}
      />
    </ThemeProvider>
  );
}

export default DeleteTravelerModal;
