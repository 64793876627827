import React from 'react';

class Hubspot extends React.Component {
  componentDidMount () {
    const script = document.createElement("script");
    script.async = true;
    script.defer = true;
    script.type = 'text/javascript';
    script.id = 'hs-script-loader';
    script.src = '//js.hs-scripts.com/20904955.js'; // production site-id

    // <!-- Start of HubSpot Embed Code -->
    // <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/20904955.js"></script>
    // <!-- End of HubSpot Embed Code -->

    document.head.appendChild(script);
    // this.instance.appendChild(script);
  }

  render() {
    return (<></>);
  }
}

export default Hubspot;
