import React from 'react';

export default function NoPassport() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.7484 5.55176H8.2492C7.58676 5.55176 7.04932 6.0892 7.04932 6.75164V17.2506C7.04932 17.913 7.58676 18.4505 8.2492 18.4505H15.7484C16.4109 18.4505 16.9483 17.913 16.9483 17.2506V6.75164C16.9483 6.0892 16.4109 5.55176 15.7484 5.55176Z"
        fill="#C79A83"
      />
      <path
        d="M15.7534 7.05161H10.249C9.58631 7.05161 9.04912 7.5888 9.04912 8.25149V17.6505C9.04912 18.0924 8.691 18.4505 8.2492 18.4505C7.58836 18.4505 7.04932 17.9151 7.04932 17.2506V6.75164C7.04932 6.08895 7.58651 5.55176 8.2492 5.55176H15.7484C16.7826 5.55176 16.729 7.05161 15.7534 7.05161Z"
        fill="#E0B7A3"
      />
      <path
        d="M11.9992 13.9008C13.6559 13.9008 14.9989 12.5578 14.9989 10.9011C14.9989 9.24438 13.6559 7.90137 11.9992 7.90137C10.3425 7.90137 8.99951 9.24438 8.99951 10.9011C8.99951 12.5578 10.3425 13.9008 11.9992 13.9008Z"
        fill="#CBE3FA"
      />
      <path
        d="M10.2065 12.1364C10.2993 12.6054 9.66975 12.8507 9.42407 12.4405C9.14075 11.9676 8.98407 11.4102 9.00072 10.815C9.04551 9.21334 10.3688 7.90932 11.9841 7.90137C12.4662 7.89897 12.5799 8.56451 12.1271 8.72994C10.755 9.23134 9.91298 10.653 10.2065 12.1364Z"
        fill="#EEF7FF"
      />
      <path
        d="M11.9992 0.402344C18.4051 0.402344 23.5981 5.59532 23.5981 12.0012C23.5981 18.407 18.4051 23.6 11.9992 23.6C5.59337 23.6 0.400391 18.4071 0.400391 12.0012C0.400391 5.59537 5.59337 0.402344 11.9992 0.402344ZM21.5483 12.0012C21.5483 9.67997 20.7201 7.55213 19.3429 5.89719C19.1923 5.71611 18.9185 5.70336 18.7519 5.86995L5.86799 18.7539C5.70141 18.9205 5.71416 19.1943 5.89524 19.3449C7.55018 20.7221 9.67801 21.5503 11.9992 21.5503C17.2558 21.5502 21.5483 17.2578 21.5483 12.0012ZM4.51228 17.1169L17.1148 4.51433C17.2964 4.33275 17.2631 4.02828 17.0451 3.89245C15.581 2.97979 13.8517 2.45214 11.9993 2.45214C6.73451 2.45209 2.45014 6.73646 2.45019 12.0013C2.45019 13.8537 2.97783 15.583 3.89049 17.0471C4.02633 17.265 4.3307 17.2985 4.51228 17.1169Z"
        fill="#EE9999"
      />
      <path
        d="M1.55028 12.4511C1.55028 15.5253 2.74621 18.32 4.69826 20.3956C4.90399 20.6144 4.59397 20.9337 4.36789 20.736C1.93624 18.6099 0.400441 15.4849 0.400391 12.0012C0.400341 5.58834 5.73226 0.275175 12.2347 0.404662C12.5366 0.410661 12.5476 0.855217 12.2465 0.879064C6.47613 1.33537 1.55028 6.13839 1.55028 12.4511Z"
        fill="#FFDEDB"
      />
      <path
        d="M22.698 12.4917C22.6763 17.7469 18.344 22.0727 13.0153 21.9994C12.7042 21.9952 12.6823 21.5384 12.9917 21.5055C17.6592 21.0089 21.5482 17.0466 21.5482 12.0013C21.5482 10.0269 20.9486 8.19205 19.9225 6.6692C19.836 6.54086 19.8523 6.36943 19.9618 6.25999C20.0958 6.12596 20.3157 6.1349 20.4377 6.27999C21.0571 7.01642 22.7118 9.16665 22.698 12.4917Z"
        fill="#FFDEDB"
      />
      <path
        d="M18.1305 4.31687C18.4194 4.42991 18.4541 4.77463 18.2646 4.96411L5.80609 17.4226C5.28109 17.9476 4.48127 17.1476 5.00822 16.6206L17.1148 4.51405C17.397 4.23188 17.7537 4.16949 18.0596 4.28918L18.1305 4.31687Z"
        fill="#FFDEDB"
      />
      <path
        d="M20.4832 3.51679C16.6995 -0.266985 11.0506 -0.986563 6.55584 1.30521C6.35906 1.40555 6.28087 1.64642 6.38121 1.8432C6.48155 2.04003 6.72243 2.11813 6.91921 2.01784C8.5011 1.21127 10.2101 0.802308 11.9988 0.802308C18.1739 0.802308 23.1977 5.82611 23.1977 12.0012C23.1977 18.1763 18.1739 23.2001 11.9988 23.2001C5.82377 23.2001 0.79997 18.1763 0.79997 12.0012C0.79997 8.81181 2.16628 5.76426 4.54849 3.63997C4.71338 3.49299 4.72783 3.24011 4.58079 3.07528C4.43381 2.9104 4.18098 2.8959 4.0161 3.04298C1.4638 5.31886 0 8.58403 0 12.0012C0 18.6326 5.36651 24 11.9988 24C18.6301 24 23.9976 18.6335 23.9976 12.0012C23.9976 8.79621 22.7495 5.78306 20.4832 3.51679Z"
        fill="#373737"
      />
      <path
        d="M17.6902 3.83943C16.0162 2.66995 14.0481 2.05176 11.9988 2.05176C6.51291 2.05176 2.0498 6.51486 2.0498 12.0008C2.0498 14.0501 2.66799 16.0181 3.83748 17.6921C3.91527 17.8034 4.03946 17.863 4.16569 17.863C4.48426 17.863 4.67994 17.5013 4.49321 17.234C3.41802 15.695 2.84972 13.8854 2.84972 12.0008C2.84972 6.95592 6.95396 2.85168 11.9988 2.85168C13.7225 2.85168 15.3834 3.32703 16.8319 4.23084L15.9032 5.15965C15.7733 5.1471 16.262 5.1515 8.24923 5.1515C7.36707 5.1515 6.64939 5.86918 6.64939 6.75134V14.4135L5.65354 15.4093C5.49731 15.5655 5.49731 15.8187 5.65354 15.9749C5.80973 16.1311 6.06295 16.1311 6.21914 15.9749L17.744 4.45007C17.92 4.27409 17.8938 3.98171 17.6902 3.83943ZM7.44927 6.75129C7.44927 6.31023 7.80813 5.95137 8.24919 5.95137H15.1114L9.48781 11.575C9.1158 10.1863 9.94667 8.78843 11.273 8.40367C11.4852 8.34213 11.6072 8.1203 11.5457 7.90812C11.4842 7.69599 11.2624 7.57386 11.0502 7.63545C9.1381 8.19009 8.07535 10.3133 8.85847 12.2043L7.44927 13.6135V6.75129Z"
        fill="#373737"
      />
      <path
        d="M9.35235 18.4507C9.35235 18.6715 9.53143 18.8506 9.75231 18.8506H15.7485C16.6307 18.8506 17.3484 18.1329 17.3484 17.2508V7.83882L19.3375 5.84972C19.4936 5.69354 19.4936 5.44031 19.3375 5.28413C19.1813 5.128 18.9281 5.1279 18.7718 5.28413L5.28166 18.7743C5.12042 18.9355 5.12647 19.1989 5.29491 19.3526C7.13082 21.0278 9.51168 21.9504 11.9989 21.9504C17.4848 21.9504 21.9479 17.4873 21.9479 12.0014C21.9479 10.5541 21.6439 9.15839 21.0444 7.85312C20.9522 7.65239 20.7145 7.5644 20.514 7.65659C20.3133 7.74878 20.2253 7.98626 20.3175 8.18699C20.8685 9.38677 21.148 10.6701 21.148 12.0013C21.148 17.0462 17.0437 21.1504 11.9989 21.1504C9.8518 21.1504 7.79111 20.4029 6.15007 19.0371L14.5814 10.6057C14.6952 11.6146 14.2289 12.588 13.3483 13.124C13.1596 13.2389 13.0997 13.485 13.2145 13.6736C13.3294 13.8624 13.5755 13.9222 13.7641 13.8074C15.1216 12.9812 15.6895 11.3817 15.2569 9.93022L16.5484 8.63874V17.2508C16.5484 17.6918 16.1895 18.0507 15.7485 18.0507H9.75231C9.53138 18.0507 9.35235 18.2298 9.35235 18.4507Z"
        fill="#373737"
      />
      <path
        d="M13.5487 16.5011C13.7696 16.5011 13.9487 16.322 13.9487 16.1011C13.9487 15.8803 13.7696 15.7012 13.5487 15.7012H10.449C10.2282 15.7012 10.0491 15.8803 10.0491 16.1011C10.0491 16.322 10.2282 16.5011 10.449 16.5011H13.5487Z"
        fill="#373737"
      />
    </svg>
  );
}
