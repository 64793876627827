import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import styles from './index.module.css';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import closeIcon from './closeIcon.png';
import { useDispatch, useSelector } from 'react-redux';
import { manualDataSync } from '../../actions/formsActions';
import toastMessageStyles from '../../sites/viaGlobal/components/toastMessage/toastMessage.scss';
import { toast } from 'react-toastify';
import warningIcon from '../../shared/images/warningIcon.png';
import ProfileTextInput from '../../sites/travelerProfile/components/base/inputs/ProfileTextInput';

function BatchManualDataSyncModal({ show, handleClose, submissionIds = [], type = '' }) {
  const dispatch = useDispatch();
  const { batchManualDataSync } = useSelector(state => state.forms);
  const customAliases = useSelector(state => state.profile.customAliases);
  const [checkedAppFormResponses, setCheckedAppFormResponses] = useState(true);
  const [checkedUploadedFiles, setCheckedUploadedFiles] = useState(false);
  const [inputValue, setInputValue] = useState(""); 


  const syncBatchData = () => {
    let payload = {
      submission_ids: submissionIds,
      response_downloadable: checkedAppFormResponses,
      upload_files: checkedUploadedFiles,
      type: type,
    };
    dispatch(
      manualDataSync(payload, (status, message) => {
        if (status) {
          toast(message, {
            type: 'success',
            className: toastMessageStyles['successContainer'],
            toastClassName: toastMessageStyles['wrapper'],
            bodyClassName: toastMessageStyles['successBody'],
            progressClassName: toastMessageStyles['progress-bar'],
          });
          handleClose();
        } else {
          toast('Something went wrong, please try again later', {
            type: 'error',
            className: toastMessageStyles['failureContainer'],
            toastClassName: toastMessageStyles['wrapper'],
            bodyClassName: toastMessageStyles['failureBody'],
            progressClassName: toastMessageStyles['progress-bar'],
          });
        }
      }),
    );
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header className={styles.modalHeader}>
          <div className={styles.title}>
            <h1 className={styles.headingtext}>Manual {type} Data Sync</h1>
            <a
              href="#"
              role="button"
              onClick={e => {
                e.preventDefault();
                handleClose();
              }}
            >
              <img src={closeIcon} alt="Close" />
            </a>
          </div>
        </Modal.Header>

        <Modal.Body>
          <Container>
            <Row>
              <Col xs={3} className={styles.imageContainer}>
                <img src={warningIcon} className={styles.warningIcon} />
              </Col>
              <Col>
                <p className={styles.text}>
                  Manual data sync allows you to update {customAliases?.alias_traveler} information in {submissionIds.length} {type.toLowerCase()}{submissionIds.length > 1 ? "(s)" : ""} with
                  the most current profile data.
                </p>
                <p>
                  This action will replace any existing responses in {customAliases?.alias_traveler} information questions with the
                  existing profile data. If no data exists in their profile, data will be cleared.
                </p>
                <p className={styles.colpcontent}>This action can not be undone.</p>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer className={styles.footercolor}>
          <div className={styles.inputContainer}>
          <ProfileTextInput
            label={`Type SYNC to manually sync ${submissionIds.length} ${type.toLowerCase()}${submissionIds.length > 1 ? "(s)" : ""}.`}
            placeholder={'Type SYNC to continue'}
            value={inputValue}
            handleOnChange={(e) => setInputValue(e.target.value)}
          />
          </div>

          <div className={styles.btnContainer}>
            <button className={styles.cancelBtn} type="button" onClick={handleClose}>Cancel</button>

            <Button
              onClick={syncBatchData}
              className={styles.submitBtn}
              type="button"
              disabled={(inputValue !== "SYNC" || batchManualDataSync?.loading) ? true : false}
            >
              {batchManualDataSync?.loading ? (
                <>
                  <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> Loading...
                </>
              ) : (
                <>Manual Sync</>
              )}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default withStyles(styles)(BatchManualDataSyncModal);
