import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import _uniqBy from 'lodash/uniqBy';
import moment from 'moment-timezone';
import { ButtonMediumNunito, AccentButtonMediumNunito } from '../../../../../styledComponents/styles/Buttons.styled';
import { addApplicationToTravelerProfile, fetchAvailableApplications } from '../../../../../actions/formsActions';
import Dropdown from '../../../../../styledComponents/styles/DropdownSelect.styled';
import GenericStyledModal from './GenericStyledModal.modal';

const Container = styled.div``;

const Content = styled.div`
  padding-top: 25px;
`;

const DropdownContainer = styled.div`
  margin: 1rem 4rem;
`;

const ParagraphWrapper = styled.div`
  margin-left: 45px;
  margin-right: 37px;
`;

const Paragraph = styled.p`
  color: #373737;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 400;
`;

const ButtonContainer = styled.div`
  background: #ebebeb;
  display: flex;
  height: 111px;
  justify-content: flex-end;
  left: 0;
  margin-top: 43px;
  top: 273px;
  width: 520px;
  margin-bottom: -20px;
  border-radius: 15px;
`;

const ButtonDiv = styled.div`
  margin-top: 30px;
  margin-right: 20px;
`;

const AddApplicationTravelerProfileModal = props => {
  const dispatch = useDispatch();
  const customAliases = useSelector(state => state.profile.customAliases);
  const availablePrograms = useSelector(state => state.forms?.availableApplications);
  const [selectedProgram, setSelectedProgram] = useState([]);
  const [filteredPrograms, setFilteredPrograms] = useState([]);
  const [selectedTerm, setSelectedTerm] = useState([]);

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      dispatch(fetchAvailableApplications(props.travelerId));
    }
    return () => {
      unmounted = true;
    };
  }, []);

  useEffect(
    () => {
      let programList = [];

      if (!!availablePrograms.data && availablePrograms?.data.length > 0) {
        programList = availablePrograms.data.filter(program => program.program_ranges.length > 0).map(range =>
          range.program_ranges.map(item => {
            return {
              value: item.program_id,
              label: range.title.trim(),
              term: `${item.name ? `${item.name}: ` : ''}${
                item.use_exact_dates
                  ? `${moment(new Date(item.start_date)).format('MMM D[,] YYYY')}
                    - ${moment(new Date(item.end_date)).format('MMM D[,] YYYY')}`
                  : `${moment(new Date(item.start_date)).format('MMM [] YYYY')}
                    - ${moment(new Date(item.end_date)).format('MMM [] YYYY')}`
              }`,
              program_range_id: item.id,
              start_date: item.start_date,
            };
          }),
        );

        let flattened = [].concat.apply([], programList).sort((a, b) => a.label.localeCompare(b.label));

        setFilteredPrograms(flattened);
      }
    },
    [availablePrograms],
  );

  const handleClose = () => {
    props.onClose(selectedTerm);
    setSelectedProgram([]);
    setSelectedTerm([]);
  };

  return (
    <GenericStyledModal
      header="Add Application"
      showing={props.show}
      width="520px"
      onClose={() => {
        handleClose();
      }}
    >
      <Container>
        <Content>
          <ParagraphWrapper>
            <Paragraph>
              {`Select the ${customAliases.alias_program.toLowerCase()} and ${customAliases.alias_program.toLowerCase()} term to add an application to this ${customAliases.alias_traveler.toLowerCase()}`}
            </Paragraph>
          </ParagraphWrapper>
          <DropdownContainer>
            <Dropdown
              aria-label={`Select ${customAliases.alias_program.toLowerCase()}`}
              placeholder={`Select ${customAliases.alias_program.toLowerCase()}`}
              openSans
              label={`${customAliases.alias_program}`}
              fontColor="#373737"
              isRequired
              errorMessage={`${customAliases.alias_program} must be selected`}
              options={_uniqBy(filteredPrograms, 'label')
                .filter(program => (selectedProgram !== program.value ? program : null))
                .sort((a, b) => a.label.localeCompare(b.label))}
              onChange={e => {
                setSelectedTerm([]);
                setSelectedProgram(e);
              }}
              fontWeight={600}
            />
          </DropdownContainer>
          <DropdownContainer>
            <Dropdown
              aria-label={`Select ${customAliases.alias_program.toLowerCase()} term`}
              placeholder={`Select ${customAliases.alias_program.toLowerCase()} term`}
              label={`${customAliases.alias_program} Term`}
              openSans
              isRequired
              isDisabled={selectedProgram.length === 0}
              value={
                selectedTerm
                  ? filteredPrograms.filter(program => program.program_range_id === selectedTerm).map(item => {
                      return { value: item.program_range_id, label: item.term };
                    })
                  : null
              }
              errorMessage={`${customAliases.alias_program} term must be selected`}
              fontColor={`#373737`}
              options={filteredPrograms
                ?.filter(program => selectedProgram === program.value)
                .map(item => {
                  return { value: item.program_range_id, label: item.term, start_date: item.start_date };
                })
                .sort((a, b) => b.start_date.localeCompare(a.start_date))}
              onChange={e => {
                setSelectedTerm(e);
              }}
              fontWeight={600}
            />
          </DropdownContainer>
        </Content>
        <ButtonContainer>
          <ButtonDiv>
            <ButtonMediumNunito
              onClick={() => {
                handleClose();
              }}
            >
              Cancel
            </ButtonMediumNunito>
          </ButtonDiv>
          <ButtonDiv>
            <AccentButtonMediumNunito
              disabled={selectedTerm.length === 0}
              onClick={() => {
                const values = {
                  program_range_id: selectedTerm,
                  user_id: props.travelerId,
                };
                dispatch(addApplicationToTravelerProfile(values,0));
                handleClose();
              }}
            >
              Add Application
            </AccentButtonMediumNunito>
          </ButtonDiv>
        </ButtonContainer>
      </Container>
    </GenericStyledModal>
  );
};

AddApplicationTravelerProfileModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  travelerId: PropTypes.number.isRequired,
};

export default AddApplicationTravelerProfileModal;
