import React from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import DragAndDropItem from './DragAndDropItem';

export default function DragAndDropList({ listItems, setListItems }) {
  const reorder = (list, startIndex, endIndex) => {
    const result = [...list];
    const [removed] = result.splice(startIndex, 1);

    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = result => {
    if (!result.destination) {
      return;
    }

    const reorderedItems = reorder(listItems, result.source.index, result.destination.index);

    setListItems(reorderedItems);
  };

  const getListStyle = () => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '0.9375rem',
    maxWidth: '32rem',
    margin: '3.125rem auto 0 auto',
  });

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {provided => (
          <>
            <div {...provided.droppableProps} ref={provided.innerRef} style={getListStyle()}>
              {listItems.map((item, index) => (
                <DragAndDropItem key={item.id} item={item} index={index} />
              ))}
            </div>
            {provided.placeholder}
          </>
        )}
      </Droppable>
    </DragDropContext>
  );
}

DragAndDropList.propTypes = {
  listItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      icon: PropTypes.func,
      content: PropTypes.string,
    }),
  ).isRequired,
  setListItems: PropTypes.func.isRequired,
};
