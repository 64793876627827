import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ThemeProvider } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import GetLogo from '../../sites/authentication/components/GetLogo';
import StyledIcon from '../../styledComponents/styles/Icon.styled';
import ViaModal from '../../sites/components/library/viaModal';
import { AccentButtonMediumOpenSans, ButtonMediumOpenSans } from '../../styledComponents/styles/Buttons.styled';
import { FacultyAccessButton, TravelerAccessButton } from '../../styledComponents/styles/Action.buttons.styled';
import { getLoginPath } from '../../shared/helpers/sessionManagement';
import { selectAccessAdmin } from '../../actions/authenticationActions';
import { themeVia } from '../../styledComponents/settings/theme';
import { useThemeColors } from '../../shared/hooks/useThemeColors';

const ButtonContainer = styled.div`
  margin-top: 15px;
`;

const Error = styled.div`
  color: #a82425;
  display: inline-block;
  font-family: Open Sans, sans-serif;
  font-size: 14px;
  font-weight: 400;
  padding-top: 3px;
  vertical-align: middle;

  svg {
    justify-content: center;
    margin-right: 5px;
    vertical-align: middle;
    display: inline-block;
  }
  span {
    vertical-align: middle;
    display: inline-block;
  }
`;

const FlexContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  font-family: Open Sans, sans-serif;
  font-size: 16px;
  font-weight: 400;
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;

  button {
    margin: 15px;
  }
`;

const FooterButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  button {
    margin: 15px;
  }
`;
const Header = styled.h1`
  color: #0b3544;
  font-size: 28px;
  font-weight: 600;
  line-height: 74px;
`;

const Instructions = styled.div`
  color: #373737;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 29px;
  text-align: center;
`;

function SelectAccessModal(props) {
  const dispatch = useDispatch();
  const changeUserRole = useSelector(state => state.authentication.changeUserRole);
  const [newAccess, setNewAccess] = useState(null);
  const [errorText, setErrorText] = useState('');
  const isBrandingSet = useThemeColors();

  useEffect(
    () => {
      if (
        newAccess !== '' &&
        changeUserRole &&
        !changeUserRole.loading &&
        !changeUserRole.error &&
        !!changeUserRole.data
      ) {
        const accountPath = getLoginPath({
          admin_sign_in: newAccess === 'faculty' ? true : false,
          feature_legacy: props.clientFeatureList.legacy,
          feature_vt: props.clientFeatureList.travel_plans,
          freemium: props.freemium,
          lead: props.lead,
          redirect_jmp: '',
          organization: props.organization,
        });

        setNewAccess('');
        window.location = accountPath;
      }
    },
    [changeUserRole],
  );

  const handleRoleSelect = role => {
    setNewAccess(role);
    setErrorText('');
  };

  const handleClose = () => {
    setNewAccess(null);
    setErrorText('');
    props.onClose(false);
  };
  const handleSwitchRole = () => {
    if (!newAccess || newAccess.length === 0) {
      setErrorText('You must select an access role to switch');
    } else {
      dispatch(selectAccessAdmin(newAccess === 'faculty' ? true : false));
    }
  };

  return (
    <ThemeProvider theme={themeVia}>
      {isBrandingSet && (
        <ViaModal showing={props.show} onClose={() => handleClose()} width={'500px'}>
          <FlexContainer>
            <GetLogo />
            <Header>Select your access</Header>
            <Instructions>Note: You will be logged out of your current role.</Instructions>
            <ButtonContainer>
              <FacultyAccessButton
                disabled={props.admin_sign_in}
                onClick={() => handleRoleSelect('faculty')}
                selected={newAccess === 'faculty'}
              />
            </ButtonContainer>
            <ButtonContainer>
              <TravelerAccessButton
                disabled={!props.admin_sign_in}
                onClick={() => handleRoleSelect('traveler')}
                selected={newAccess === 'traveler'}
              />
            </ButtonContainer>
            <FooterContainer>
              {errorText &&
                errorText.length > 0 && (
                  <Error>
                    <StyledIcon type={'Warning'} size={'16px'} />
                    <span>{errorText}</span>
                  </Error>
                )}
              <FooterButtonContainer>
                <ButtonMediumOpenSans aria-label="Cancel" onClick={() => handleClose()}>
                  Cancel
                </ButtonMediumOpenSans>
                <AccentButtonMediumOpenSans aria-label="Switch now" onClick={() => handleSwitchRole()}>
                  Switch Now
                </AccentButtonMediumOpenSans>
              </FooterButtonContainer>
            </FooterContainer>
          </FlexContainer>
        </ViaModal>
      )}
    </ThemeProvider>
  );
}

export default SelectAccessModal;
