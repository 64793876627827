import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import Testimonial from './components/Testimonial';

import { breakpoints } from '../../../../../utils/breakpoints';
import useMediaQuery from '../../../../../../../hooks/useMediaQuery';

const TestimonialSection = styled.section`
  max-width: 60.375rem;
  margin: 1.5rem auto;
  padding: 1.5rem 2.4375rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;

  @media ${breakpoints.tablet} {
    gap: 2.0625rem;
  }

  @media ${breakpoints.desktop} {
    padding-left: 0;
    padding-right: 0;
  }
`;

const TestimonialHeading = styled.h2`
  font-family: 'Nunito', sans-serif;
  font-style: normal;
  font-weight: 300;
  line-height: 120%;
  letter-spacing: 0.0025em;
  color: #373737;
  font-size: 1.3125rem;

  @media ${breakpoints.tablet} {
    font-size: 1.75rem;
  }
`;

const TestimonialList = styled.ul`
  display: flex;
  column-gap: 1.125rem;
  row-gap: 1.875rem;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  padding-left: 0;
  width:100%;

  @media (min-width: 1024px) {
    /* Desktop */
    flex-direction: row;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    /* Tablet */
    flex-direction: column;
  }

  @media (max-width: 767px) {
    /* Mobile */
    flex-direction: column;
  }

  li {
    border-top: solid 0.875rem var(--theme_color_accent);
    padding: 0.875rem 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 0;
    width:33%;
    background: #fff;
    box-shadow: 0 0.625rem 1.25rem #dbdada;
    // TODO: There's a note in Figma that says, "admins can add ONE quote, it should stretch" -> thus, this needs a conditional:
    // one testimonial => span 12 / span 12;
    // two testimonials => span 6 / span 6;
    // three testimonials => span 4 / span 4;
    grid-column: span 12 / span 12;
    figure {
      margin-bottom: 0;
    }
    blockquote {
      text-align: center;
      font-family: 'Open Sans', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.5rem;
      color: #373737;
    }
    figcaption {
      font-family: 'Open Sans', sans-serif;
      text-align: center;
      color: #373737;
      font-style: normal;
      font-size: 0.875rem;
      line-height: 1.1875rem;
      margin-bottom: 0;
      .heading {
        font-weight: 600;
        margin-bottom: 0;
      }
    }
  }
`;

export default function Testimonials() {
  const { isMobile, isTablet, isDesktop } = useMediaQuery();
  const testimonials = useSelector(state => state.programBrochure.testimonials);
  const studentsAlias = useSelector(state => state.auth.organization.info.alias_travelers);
  if (testimonials.length === 0) return null;
  if (testimonials.length > 0 && testimonials.filter(testimonial => testimonial?.review_text).length === 0) return null;

  const filteredTestimonials = testimonials.filter(
    (item) => item.review_text !== "" || item.reviewer_details !== "" || item.reviewer_name !== ""
  );
  const sortedTestimonials = filteredTestimonials.sort((a, b) => a.id - b.id);

  return (
    <TestimonialSection tabIndex={0}>
      <TestimonialHeading>What {studentsAlias} Are Saying</TestimonialHeading>
      <TestimonialList>
        {sortedTestimonials.length > 0 &&
          sortedTestimonials.map((testimonial, index) => (
            <li 
              key={`testimonial-${testimonial.id}`} 
              style={{
                width: isMobile || isTablet  ? "100%" : sortedTestimonials.length===1 ? "100%" : sortedTestimonials.length===2 ? "50%" : "33%" }}
            >
              <Testimonial testimonial={testimonial} />
            </li>
          ))}
      </TestimonialList>
    </TestimonialSection>
  );
}
