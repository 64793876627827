import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import GenericStyledModal from './GenericStyledModal.modal';
import PropTypes from 'prop-types';
import StyledIcon from '../../../../../styledComponents/styles/Icon.styled';
import { ButtonMediumNunito, AccentButtonMediumNunito } from '../../../../../styledComponents/styles/Buttons.styled';
import { TrashCanIcon } from '../../../../../styledComponents/styles/IconCustom.styled';
import TextInput from '../../../../../styledComponents/styles/TextInput.styled';
import { uploadFileDelete } from '../../../../../actions/formsActions';

const Container = styled.div``;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 25px;
`;

const IconWrapper = styled.div`
  align-self: center;
  padding-left: 34px;
`;

const ParagraphWrapper = styled.div`
  margin-left: 45px;
  margin-right: 37px;
`;

const Paragraph = styled.p`
  color: #373737;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 400;
`;

const ParagraphWarning = styled(Paragraph)`
  font-weight: 600;
`;

const FooterContainer = styled.div`
  background: #ebebeb;
  display: flex;
  flex-direction: column;
  height: 150px;
  justify-content: center;
  left: 0;
  top: 273px;
  width: 100%;
  margin-bottom: -20px;
  border-radius: 15px;
`;

const InputDiv = styled.div`
  align-self: center;
  margin-bottom: 15px;
  width: 300px;
`;

const ButtonWrapper = styled.div`
  align-self: center;
  display: flex;
`;

const ButtonDiv = styled.div`
  flex: 1;
  width: 100px;
`;

const DeleteFormLink = styled.button`
  
  border: none; 
  background: transparent; 
  padding: 0; 

  color: #8b1313;
  cursor: pointer;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 14px;
  text-align: end;

  span {
    min-height: 15px;
    display: inline-flex;
    align-items: center;
  }

  svg {
    align-items: center;
    display: inline-flex;
    margin: -2px 5px 0 0;
    min-height: 15px;
  }
`;

export default function DeleteUploadedFile({ question_id, submission_id, id, file_name, is_visitor }) {
  const dispatch = useDispatch();
  const [showing, setShowing] = useState(false);
  const [confirm, setConfirm] = useState('');
  const uploadDelete = useSelector(state => state.forms.uploadFileDelete);

  useEffect(
    () => {
      if (uploadDelete && !uploadDelete.error && uploadDelete.data) {
        setShowing(false);
      }
    },
    [uploadDelete],
  );

  const handleDelete = () => {
    const deleteFile = {
      question_id: question_id,
      submission_id: submission_id,
      uploaded_files_attributes: [
        {
          id: id,
          _destroy: true,
        },
      ],
    };
    dispatch(uploadFileDelete(deleteFile, is_visitor));
  };

  return (
    <>
      <DeleteFormLink
        type="button"
        aria-label='Remove file'
        onClick={() => {
          setConfirm('');
          setShowing(true);
        }}
      >
        <StyledIcon type={'Trash'} size={'18px'} color={'#8b1313'} />
      </DeleteFormLink>
      <GenericStyledModal
        header={'Confirm Deletion'}
        showing={showing}
        width={'520px'}
        onClose={() => {
          setShowing(false);
        }}
      >
        <Container>
          <Content>
            <IconWrapper>
              <TrashCanIcon size={'100px'} />
            </IconWrapper>
            <ParagraphWrapper>
              <Paragraph>
                You are about to delete this uploaded file. Deleting this file will permanently delete it. This action
                cannot be undone.
              </Paragraph>
              <Paragraph>{file_name}</Paragraph>
              <ParagraphWarning>Enter ‘DELETE’ to delete the uploaded file.</ParagraphWarning>
            </ParagraphWrapper>
          </Content>
          <FooterContainer>
            <InputDiv>
              <TextInput
                aria-label={'Delete confirmation'}
                id={'confirm-prompt'}
                label={''}
                onChange={e => {
                  setConfirm(e.target.value.toUpperCase());
                }}
                value={confirm}
              />
            </InputDiv>
            <ButtonWrapper>
              <ButtonDiv>
                <ButtonMediumNunito
                  onClick={() => {
                    setShowing(false);
                  }}
                >
                  Cancel
                </ButtonMediumNunito>
              </ButtonDiv>
              <ButtonDiv>
                <AccentButtonMediumNunito disabled={confirm !== 'DELETE'} onClick={() => handleDelete()}>
                  Delete
                </AccentButtonMediumNunito>
              </ButtonDiv>
            </ButtonWrapper>
          </FooterContainer>
        </Container>
      </GenericStyledModal>
    </>
  );
}

DeleteUploadedFile.propTypes = {
  question_id: PropTypes.number.isRequired,
  submission_id: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  file_name: PropTypes.string.isRequired,
  is_visitor: PropTypes.bool.isRequired,
};
