import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { compose } from 'recompose';
import propTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Headline from '../../../../../components/library/headline';
import Button from '../../../../../components/library/button';
import Paragraph from '../../../../../../sites/components/library/paragraph';
import sPlanSuccess from './planSuccess.scss';

export const enhance = compose(
  withStyles(sPlanSuccess),
  withRouter,
);

function PlanSuccess(props) {
  const secondaryLink = props.plan.attributes.group
    ? `/plans/admin-plan-overview/${props.plan.id}`
    : `/plans/plan-overview/${props.plan.id}`;
  const primaryLink = props.plan.attributes.group
    ? `/plans/group-itinerary/${props.plan.id}`
    : `/plans/my-itinerary/${props.plan.id}`;

  return (
    <>
      <div className={sPlanSuccess['plan-success']}>
        <Headline tag="h1" as="h1" centered italic>
          Success!
        </Headline>
        <Headline tag="h2" as="h3" centered>
          YOU CREATED A TRAVEL PLAN.
        </Headline>
        <Paragraph size="large" centered>
          Check your email to view your confirmation.
        </Paragraph>
        <Paragraph size="large" centered>
          Take the next step and add your flight, housing, transportation, and
          activity details to your itinerary!
        </Paragraph>
        <div className={sPlanSuccess['button-row']}>
          <div className={sPlanSuccess['button-content']}>
            <Button
              display="secondary"
              kind="solid"
              size="medium"
              onClick={() => {
                props.onClose(false);
                props.history.push(`${secondaryLink}`);
              }}
            >
              Take Me To My Plan
            </Button>
          </div>
          <div className={sPlanSuccess['button-content']}>
            <Button
              display="primary"
              kind="solid"
              size="medium"
              onClick={() => {
                props.onClose(false);
                props.history.push(`${primaryLink}`);
              }}
            >
              Keep Going! Build My Itinerary
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

PlanSuccess.propTypes = {
  onClose: propTypes.func,
  plan: propTypes.object.isRequired,
};

export default enhance(PlanSuccess);
