import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import ProfileFormContainer from '../base/ProfileFormContainer';
import ProfileFormSubheading from '../base/ProfileFormSubheading';
import ToastMessage from '../../../viaGlobal/components/toastMessage';
import PrimaryEmergencyContactForm from '../forms/emergency/PrimaryEmergencyContactForm';
import SecondaryEmergencyContactForm from '../forms/emergency/SecondaryEmergencyContactForm';
import { ProfileFormButton, ProfileFormButtonContainer } from '../base/ProfileFormButton.styled';

import userId from '../../../../shared/helpers/userId';
import { updateTravelerProfile } from '../../actions/travelerProfileActions';

export default function EmergencyTab() {
  const dispatch = useDispatch();

  const [showToast, setShowToast] = useState(false);

  const [primaryContactForm, setPrimaryContactForm] = useState({
    emergency_contact_email: '',
    emergency_contact_last_name: '',
    emergency_contact_first_name: '',
    emergency_contact_relationship: '',
    emergency_contact_phone_number: '',
    emergency_contact_address_city: '',
    emergency_contact_address_state: '',
    emergency_contact_address_line_1: '',
    emergency_contact_address_line_2: '',
    emergency_contact_address_country: '',
    emergency_contact_address_zip_code: '',
  });

  const [secondaryContactForm, setSecondaryContactForm] = useState({
    secondary_emergency_contact_email: '',
    secondary_emergency_contact_last_name: '',
    secondary_emergency_contact_first_name: '',
    secondary_emergency_contact_relationship: '',
    secondary_emergency_contact_phone_number: '',
    secondary_emergency_contact_address_city: '',
    secondary_emergency_contact_address_state: '',
    secondary_emergency_contact_address_line_1: '',
    secondary_emergency_contact_address_line_2: '',
    secondary_emergency_contact_address_country: '',
    secondary_emergency_contact_address_zip_code: '',
  });

  const handleSubmit = event => {
    event.preventDefault();

    const primaryContactPayload = {
      ...primaryContactForm,
      emergency_contact_country_code: null,
      emergency_contact_relationship: primaryContactForm.emergency_contact_relationship.value,
      emergency_contact_address_country: primaryContactForm.emergency_contact_address_country.value,
    };

    const secondaryContactPayload = {
      ...secondaryContactForm,
      secondary_emergency_contact_country_code: null,
      secondary_emergency_contact_relationship: secondaryContactForm.secondary_emergency_contact_relationship.value,
      secondary_emergency_contact_address_country:
        secondaryContactForm.secondary_emergency_contact_address_country.value,
    };

    dispatch(
      updateTravelerProfile(userId, {
        travelerProfile: { ...primaryContactPayload, ...secondaryContactPayload },
      }),
    );

    setShowToast(true);
  };

  return (
    <form method="POST" onSubmit={handleSubmit}>
      <ProfileFormContainer heading="Emergency Contact Information">
        <div>
          <div>
            <ProfileFormSubheading text="Primary Emergency Contact" />
            <PrimaryEmergencyContactForm form={primaryContactForm} setForm={setPrimaryContactForm} />
          </div>
          <div>
            <ProfileFormSubheading text="Secondary Emergency Contact" />
            <SecondaryEmergencyContactForm form={secondaryContactForm} setForm={setSecondaryContactForm} />
          </div>
        </div>
        <ProfileFormButtonContainer>
          <ProfileFormButton type="submit">Save</ProfileFormButton>
        </ProfileFormButtonContainer>
        {showToast && (
          <ToastMessage
            message="All changes saved"
            isSuccess
            show={showToast}
            onClose={() => {
              setShowToast(false);
            }}
          />
        )}
      </ProfileFormContainer>
    </form>
  );
}
