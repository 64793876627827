import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import ProfileFormGrid from '../../base/ProfileFormGrid.styled';
import ProfileTextInput from '../../base/inputs/ProfileTextInput';
import ProfileSelectInput from '../../base/inputs/ProfileSelectInput';
import ProfileDatePickerInput from '../../base/inputs/ProfileDatePickerInput';

import countries from '../../../../../../public/data/countries.json';
import passportStatusOptions from '../../../utils/options/passportStatusOptions';

export default function PassportAndVisaForm({ passportStatusForm, setPassportStatusForm, infoForm, setInfoForm }) {
  const { info, passportStatus, readonly } = useSelector(state => state.travelerProfile);

  const omittedCountryValues = ['Countries Vary', 'Stateless/Refuge/Other', 'Unspecified Location'];
  const countryOptions = countries.filter(country => !omittedCountryValues.includes(country.label));
  const [countryOptionList, setCountryOptionList] = useState([...countryOptions]);
  const [passportStatusOptionList, setPassportStatusOptionList] = useState([...passportStatusOptions]);

  useEffect(() => {
    let newPassportStatusOptionList = [...passportStatusOptionList];

    if (passportStatus && passportStatus.status) {
      let passportStatusOption = passportStatusOptionList.find(
        option => option.value === passportStatus.status || option.label === passportStatus.status,
      );

      if (!passportStatusOption) {
        newPassportStatusOptionList = [
          ...newPassportStatusOptionList,
          {
            id: Date.now(),
            value: passportStatus.status,
            label: passportStatus.status,
          },
        ];

        setPassportStatusOptionList([...newPassportStatusOptionList]);
      }
    }

    setPassportStatusForm({
      status: newPassportStatusOptionList.find(option => option.value === passportStatus.status) || '',
    });
  }, [passportStatus]);

  useEffect(() => {
    let newCountryOptionList = [...countryOptionList];
    //country mapping
    if (info.countries_of_citizenship) {
      if (Array.isArray(info.countries_of_citizenship)) {
        for (let i = 0; i < info.countries_of_citizenship.length; i++) {
          let country = countryOptionList.find(
            option =>
              option.value === info.countries_of_citizenship[i] || option.label === info.countries_of_citizenship[i],
          );

          if (!country) {
            newCountryOptionList = [
              ...newCountryOptionList,
              {
                id: `${Date.now}-${i}`,
                value: info.countries_of_citizenship[i],
                label: info.countries_of_citizenship[i],
              },
            ];
          }
        }

        setCountryOptionList([...newCountryOptionList]);
      }
    }

    setInfoForm({
      place_of_birth: info.place_of_birth,
      passport_number: info.passport_number,
      visa_description: info.visa_description,
      passport_issuing_agency: info.passport_issuing_agency,
      passport_expiration_date: info.passport_expiration_date,
      countries_of_citizenship: newCountryOptionList.filter(country =>
        info.countries_of_citizenship.includes(country.value),
      ),
    });
  }, [info]);

  const handleCountryChange = event => {
    const countriesValue = event || [];

    setInfoForm({ ...infoForm, countries_of_citizenship: countriesValue });
  };

  return (
    <ProfileFormGrid>
      <ProfileTextInput
        id="place_of_birth"
        label="Place of Birth"
        placeholder="Enter place of birth"
        value={infoForm.place_of_birth}
        synced={readonly.includes('place_of_birth')}
        handleOnChange={event => {
          setInfoForm({ ...infoForm, place_of_birth: event.target.value });
        }}
      />
      <ProfileSelectInput
        id="countries_of_citizenship"
        label="Country/Countries of Citizenship"
        placeholder="Select country/countries of citizenship"
        value={infoForm.countries_of_citizenship}
        options={countryOptionList}
        synced={readonly.includes('countries_of_citizenship')}
        isMulti
        handleOnChange={handleCountryChange}
      />
      <ProfileSelectInput
        id="passport_status"
        label="Passport Status"
        placeholder="Select passport status"
        value={passportStatusForm.status}
        options={passportStatusOptionList}
        handleOnChange={event => {
          setPassportStatusForm({ ...passportStatusForm, status: event });
        }}
      />
      <ProfileTextInput
        id="passport_number"
        type="password"
        label="Passport Number"
        placeholder="Enter passport number"
        value={infoForm.passport_number}
        handleOnChange={event => {
          setInfoForm({ ...infoForm, passport_number: event.target.value });
        }}
      />
      <ProfileDatePickerInput
        id="passport_expiration_date"
        label="Passport Expiration Date"
        value={infoForm.passport_expiration_date}
        minDate={new Date(new Date().getFullYear() - 20, 0, 1).toString()}
        maxDate={new Date(new Date().getFullYear() + 20, 0, 1).toString()}
        handleOnChange={event => {
          setInfoForm({ ...infoForm, passport_expiration_date: event });
        }}
      />
      <ProfileTextInput
        id="passport_issuing_agency"
        label="Passport Issuing Agency"
        placeholder="Enter passport issuing agency"
        value={infoForm.passport_issuing_agency}
        handleOnChange={event => {
          setInfoForm({ ...infoForm, passport_issuing_agency: event.target.value });
        }}
      />
      <ProfileTextInput
        id="visa_status"
        label="Visa Status"
        placeholder="Enter visa status"
        value={infoForm.visa_description}
        synced={readonly.includes('visa_description')}
        handleOnChange={event => {
          setInfoForm({ ...infoForm, visa_description: event.target.value });
        }}
      />
    </ProfileFormGrid>
  );
}

PassportAndVisaForm.propTypes = {
  passportStatusForm: PropTypes.shape({
    status: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  }).isRequired,
  setPassportStatusForm: PropTypes.func.isRequired,
  infoForm: PropTypes.shape({
    place_of_birth: PropTypes.string,
    passport_number: PropTypes.string,
    visa_description: PropTypes.string,
    passport_issuing_agency: PropTypes.string,
    passport_expiration_date: PropTypes.string,
    countries_of_citizenship: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  }).isRequired,
  setInfoForm: PropTypes.func.isRequired,
};
