export default [
  {
    id: 1,
    label: 'High School/Secondary',
    value: 'high_school',
  },
  {
    id: 2,
    label: 'Undergraduate',
    value: 'undergraduate',
  },
  {
    id: 3,
    label: 'Graduate',
    value: 'graduate',
  },
  {
    id: 4,
    label: 'Postgraduate',
    value: 'post_graduate',
  },
  {
    id: 5,
    label: 'Faculty',
    value: 'faculty',
  },
  {
    id: 6,
    label: 'Staff',
    value: 'staff',
  },
  {
    id: 7,
    label: 'Guest',
    value: 'guest',
  },
];
