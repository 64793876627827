import React from 'react';
import DropDownOptionFieldCard from './question.dropdown.optionFieldCard'; 

function DropDownOptionFields({ fields, formState, control, setValue, moveCard, logicOption }) {
  return (
    <>
      {fields.map((item, index) => {
        let errorMessage;
        if (formState.errors.dropdown_question_options_attributes) {
          for (const element of formState.errors.dropdown_question_options_attributes) {
            errorMessage =
              element !== undefined && item.value === ''
                ? { message: element.value.message, index }
                : { message: '', index };
          }
        }
        return (
          <>
            <DropDownOptionFieldCard
              key={`${item.id}-${item.id}`}
              control={control}
              index={index}
              item={item}
              errorMessage={errorMessage}
              setValue={setValue}
              fields={fields}
              logicOption={logicOption}
              moveCard={moveCard}
            />
          </>
        );
      })}
    </>
  );
}

export default DropDownOptionFields;
