export default function removeReadOnlyFields(payload, readonly) {
  for (const [key, value] of Object.entries(payload)) {
    Object.keys(value).forEach(key => {
      if (readonly.includes(key)) {
        delete value[key];
      }
    });
  }

  return payload;
}
