import React from 'react';
import PropTypes from 'prop-types';
import { TermInfoCardInfo } from './Term.styled';

export default function Credits({ minCredits, maxCredits }) {
  let credits = '';

  if (minCredits == null && maxCredits == null) {
    credits = 'Please inquire';
  }

  if (minCredits != null) {
    credits = minCredits;
  }

  if (maxCredits != null) {
    if (minCredits != null) {
      credits += ' - ';
    }

    credits += `${maxCredits}`;
  }

  return (
    <TermInfoCardInfo>
      <p className="label">Credits:</p>
      <p className="text">{credits}</p>
    </TermInfoCardInfo>
  );
}

Credits.propTypes = {
  minCredits: PropTypes.number,
  maxCredits: PropTypes.number,
};
