import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import { useDispatch, useSelector } from 'react-redux';
import propTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import toastMessageStyles from '../../../../../sites/viaGlobal/components/toastMessage/toastMessage.scss';
import { toast } from 'react-toastify';
import styles from './index.module.css';
import { Modal } from 'react-bootstrap';
import WarningModalContent from '../../../../../components/WarningModalContent';
import BaseModalHeader from '../../../../../components/baseModal/BaseModalHeader';
import BaseModalFooter from '../../../../../components/baseModal/BaseModalFooter';
import { Controller, useForm } from 'react-hook-form';
import SelectInput from '../../../../travelerForms/components/base/inputs/SelectInput';
import { fetchRegistrationStatusTypes } from '../../../../../actions/lookupActions';
import { batchUpdatePlanRegistrationStatus } from '../../../../../actions/plans';


export const enhance = compose(withStyles(styles));

function BatchUpdatePlanRegistrationStatus(props) {
  const dispatch = useDispatch();
  const registrationStatusTypes = useSelector(state => state.lookup.allRegistrationStatusTypes);
  const batchUpdatePlanRegistrationStatusData = useSelector(state => state.plans.batchUpdatePlanRegistrationStatus);
  const [registrationStatusOptions, setRegistrationStatusOptions] = useState([]);
  const [formValues, setFormValues] = useState(null);
  const [showWarningContent, setShowWarningContent] = useState(false);
  const { control, handleSubmit } = useForm({
    values: {
      plan_registration_status_id: '',
    },
  });

  // useEffect(() => {
  //   dispatch(fetchRegistrationStatusTypes());
  // }, []);


  useEffect(() => {
    if (!registrationStatusTypes.registrationStatusTypes) {
      dispatch(fetchRegistrationStatusTypes());
    } else {
      setRegistrationStatusOptions(
        registrationStatusTypes.registrationStatusTypes.map(item => {
          return { label: item.attributes.name, value: item.id };
        }),
      );
    }
  }, [registrationStatusTypes]);


  const onSubmit = data => {
    const submitValues = {
      plan_ids: props.selectedPlans,
      new_status_id: data.plan_registration_status_id.value,
    };
    setFormValues(submitValues);
    setShowWarningContent(true);

  };

  const onSubmitForm = () => {
    dispatch(
      batchUpdatePlanRegistrationStatus(formValues, (status, message) => {
        if (status) {
          toast(message, {
            type: 'success',
            className: toastMessageStyles['successContainer'],
            toastClassName: toastMessageStyles['wrapper'],
            bodyClassName: toastMessageStyles['successBody'],
            progressClassName: toastMessageStyles['progress-bar'],
          });

          props.onClose();
        } else {
          toast(message, {
            type: 'error',
            className: toastMessageStyles['failureContainer'],
            toastClassName: toastMessageStyles['wrapper'],
            bodyClassName: toastMessageStyles['failureBody'],
            progressClassName: toastMessageStyles['progress-bar'],
          });
        }
      }),
    );
  };

  return (
    <Modal show={props.show} onHide={props.onClose} centered size={showWarningContent ? "md" : "lg"}>
      {showWarningContent ? (
        <WarningModalContent
          text={`You are about to update the registration status of ${props.selectedPlans.length} plan${props.selectedPlans.length > 0 ? "(s)" : ""}. Students are not able to join cancelled or closed plans.`}
          textBold={`Are you sure you sure you want to continue?`}
          onCancel={() => setShowWarningContent(false)}
          onSubmit={onSubmitForm}
          loadingBtn={batchUpdatePlanRegistrationStatusData?.loading}
        />
      ) : (
        <>
          <BaseModalHeader heading={'Update Registration Status'} onClose={props.onClose} />
          <Modal.Body className={styles.modelBody}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className={styles.container}>
                <div className={styles.text}>Select a registration status from the drop down to update the selected plans.</div>

                <div>
                  <Controller
                    control={control}
                    name="plan_registration_status_id"
                    rules={{
                      required: 'Registration status is required',
                    }}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <SelectInput
                        label="Registration Status"
                        placeholder={'Select registration status'}
                        required={true}
                        options={registrationStatusOptions}
                        value={value}
                        handleOnChange={onChange}
                        isMulti={false}
                        error={error?.message}
                      />
                    )}
                  />
                </div>
              </div>

              <BaseModalFooter
                mainBtnType="submit"
                mainBtnText="Update"
                isBtnGroupRight={true}
                onCancel={props.onClose}
              />
            </form>
          </Modal.Body>
        </>
      )}
    </Modal>
  );

}

BatchUpdatePlanRegistrationStatus.propTypes = {
  show: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
};

export default enhance(BatchUpdatePlanRegistrationStatus);
