import React from 'react';

export default function ChevronUpIcon() {
  return (
    <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.2879 8.06819C12.9218 8.4343 12.3282 8.4343 11.9621 8.06819L7 3.1061L2.03791 8.06819C1.6718 8.4343 1.0782 8.4343 0.712088 8.06819C0.345971 7.70207 0.345971 7.10848 0.712088 6.74236L6.33709 1.11736C6.7032 0.751245 7.2968 0.751245 7.66291 1.11736L13.2879 6.74236C13.654 7.10848 13.654 7.70207 13.2879 8.06819Z"
        fill="#373737"
      />
    </svg>
  );
}
