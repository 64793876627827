import { uniq } from 'lodash';
import moment from 'moment-timezone';
import _sortBy from 'lodash/sortBy';
import _uniq from 'lodash/uniq';
import { calcTiming } from '../../../../shared/helpers/plans';
import { useSelector } from 'react-redux';

function reducePrograms(filteredPrograms, selectedFilters, filterProperty, programProperty, filterAttribute = null) {
  return filteredPrograms.reduce((accumulator, program) => {
    selectedFilters[filterProperty].forEach(filter => {
      const attribute = filterAttribute ? filter[filterAttribute] : filter;

      if (program[programProperty].includes(attribute)) {
        accumulator.push(program);
      }
    });

    return accumulator;
  }, []);
}

function getActiveTerms(program) {
  var today = new Date();
  var mergedTerms = '';
  if (program.program_terms) {
    var programTerm = program.program_terms.split('; ');
    //var startDates = program.program_term_start_dates.split('; ');
    mergedTerms = programTerm.map((terms, index) => {
      var splitDateTerms = terms.split(': ');
      if (splitDateTerms[1]) {
        var startDate = splitDateTerms[1].split('-')[0];

        let date1 = startDate.split(',');
        if (date1.length == 1) {
          let date2 = startDate.split(' ');
          let newDate = date2[0] + ' ' + 1 + ',' + date2[1];
          startDate = new Date(newDate);
        } else {
          startDate = new Date(startDate);
        }

        if (moment(startDate).format('YYYY-MM-DD') > moment(today).format('YYYY-MM-DD') && splitDateTerms[0]) {
          return splitDateTerms[0];
        }
      }
    });
    if (mergedTerms.length > 0) {
      mergedTerms = mergedTerms.join('; ');
    }
  }
  return mergedTerms;
}

function reduceProgramsForTermNames(
  filteredPrograms,
  selectedFilters,
  filterProperty,
  programProperty,
  filterAttribute = null,
) {
  return filteredPrograms.reduce((accumulator, program) => {
    program['program_active_terms'] = getActiveTerms(program);
    selectedFilters[filterProperty].forEach(filter => {
      const attribute = filterAttribute ? filter[filterAttribute] : filter;
      if (program[programProperty].includes(attribute)) {
        accumulator.push(program);
      }
    });

    return accumulator;
  }, []);
}

function getUniquePrograms(filteredPrograms) {
  const seenIds = new Set();
  const uniquePrograms = [];
  for (const program of filteredPrograms) {
    const key = JSON.stringify(program);
    if (!seenIds.has(key)) {
      uniquePrograms.push(program);
      seenIds.add(key);
    }
  }
  return uniquePrograms;
}

const buildTermTimings = dates => {
  let timings = [];
  let timingsDisplay = '';
  if (dates) {
    let arrDateRanges = dates.split(';');
    arrDateRanges.forEach(range => {
      let arrRange = range.split(' - ');
      const programTiming = calcTiming(arrRange[0], arrRange[1]);
      timings.push(programTiming);
      timings = _uniq(timings);
      timings = _sortBy(timings);
    });
  }

  timingsDisplay = timings.join('; ');
  return timingsDisplay;
};

export default function getFilteredPrograms(
  programs,
  searchTerm = '',
  selectedFilters = {},
  enable_active_term_name_filtering = false,
) {
  let filteredPrograms = [];

  // Text search filter
  if (searchTerm) {
    filteredPrograms = programs.filter(program => {
      const fields = [
        program.locations.toLowerCase(),
        program.program_name.toLowerCase(),
        program.program_terms.toLowerCase(),
        program.organization_name.toLowerCase(),
        program.program_housing_types.toLowerCase(),
        program.program_subject_areas.toLowerCase(),
      ];

      return fields.some(field => field.includes(searchTerm.toLowerCase()));
    });
  } else {
    filteredPrograms = [...programs];
  }

  // Favorites only filter
  if (selectedFilters.favorites) {
    filteredPrograms = filteredPrograms.filter(program => program.program_favorite);
  }

  // Term name filter
  if (selectedFilters.termName.length > 0) {
    filteredPrograms = reduceProgramsForTermNames(filteredPrograms, selectedFilters, 'termName', 'program_term_names');
  }

  // Program type filter
  if (selectedFilters.programType.length > 0) {
    filteredPrograms = reducePrograms(filteredPrograms, selectedFilters, 'programType', 'types');
  }

  // Locations filter -- city
  if (selectedFilters.cities.length > 0) {
    filteredPrograms = reducePrograms(filteredPrograms, selectedFilters, 'cities', 'locations', 'label');
  }

  // Locations filter -- country
  if (selectedFilters.countries.length > 0) {
    filteredPrograms = reducePrograms(filteredPrograms, selectedFilters, 'countries', 'locations', 'label');
  }

  // Subject areas filter
  if (selectedFilters.subjectAreas.length > 0) {
    filteredPrograms = reducePrograms(filteredPrograms, selectedFilters, 'subjectAreas', 'program_subject_areas');
  }

  // Dates filter -- start date
  if (selectedFilters.startDate.date) {
    if (selectedFilters.startDate.range.value === 'is') {
      const startDate = moment(new Date(selectedFilters.startDate.date)).format('YYYY-MM-DD');

      filteredPrograms = filteredPrograms.filter(program => program.program_term_start_dates.includes(startDate));
    }

    if (selectedFilters.startDate.range.value === 'between_inclusive') {
      filteredPrograms = filteredPrograms.reduce((accumulator, program) => {
        const startDates = program.program_term_start_dates.split('; ');

        startDates.forEach(startDate => {
          if (
            moment(startDate).isBetween(
              moment(selectedFilters.startDate.date),
              moment(selectedFilters.startDate.dateInclusive),
              null,
              '[]',
            )
          ) {
            accumulator.push(program);
          }
        });

        return uniq(accumulator);
      }, []);
    }

    if (selectedFilters.startDate.range.value === 'greater_or_equal') {
      filteredPrograms = filteredPrograms.reduce((accumulator, program) => {
        const startDates = program.program_term_start_dates.split('; ');

        startDates.forEach(startDate => {
          if (moment(startDate).isSameOrAfter(moment(selectedFilters.startDate.date))) {
            accumulator.push(program);
          }
        });

        return uniq(accumulator);
      }, []);
    }

    if (selectedFilters.startDate.range.value === 'less_or_equal') {
      filteredPrograms = filteredPrograms.reduce((accumulator, program) => {
        const startDates = program.program_term_start_dates.split('; ');

        startDates.forEach(startDate => {
          if (moment(startDate).isSameOrBefore(moment(selectedFilters.startDate.date))) {
            accumulator.push(program);
          }
        });

        return uniq(accumulator);
      }, []);
    }
  }

  // Dates filter -- end date
  if (selectedFilters.endDate.date) {
    if (selectedFilters.endDate.range.value === 'is') {
      const endDate = moment(new Date(selectedFilters.endDate.date)).format('YYYY-MM-DD');

      filteredPrograms = filteredPrograms.filter(program => program.program_term_end_dates.includes(endDate));
    }

    if (selectedFilters.endDate.range.value === 'between_inclusive') {
      filteredPrograms = filteredPrograms.reduce((accumulator, program) => {
        const endDates = program.program_term_end_dates.split('; ');

        endDates.forEach(endDate => {
          if (
            moment(endDate).isBetween(
              moment(selectedFilters.endDate.date),
              moment(selectedFilters.endDate.dateInclusive),
              null,
              '[]',
            )
          ) {
            accumulator.push(program);
          }
        });

        return uniq(accumulator);
      }, []);
    }

    if (selectedFilters.endDate.range.value === 'greater_or_equal') {
      filteredPrograms = filteredPrograms.reduce((accumulator, program) => {
        const endDates = program.program_term_end_dates.split('; ');

        endDates.forEach(endDate => {
          if (moment(endDate).isSameOrAfter(moment(selectedFilters.endDate.date))) {
            accumulator.push(program);
          }
        });

        return uniq(accumulator);
      }, []);
    }

    if (selectedFilters.endDate.range.value === 'less_or_equal') {
      filteredPrograms = filteredPrograms.reduce((accumulator, program) => {
        const endDates = program.program_term_start_dates.split('; ');

        endDates.forEach(endDate => {
          if (moment(endDate).isSameOrBefore(moment(selectedFilters.endDate.date))) {
            accumulator.push(program);
          }
        });

        return uniq(accumulator);
      }, []);
    }
  }

  // Program name filter
  if (selectedFilters.programName.length > 0) {
    filteredPrograms = reducePrograms(filteredPrograms, selectedFilters, 'programName', 'program_name', 'value');
  }

  // Program housing type filter
  if (selectedFilters.housingType.length > 0) {
    filteredPrograms = reducePrograms(
      filteredPrograms,
      selectedFilters,
      'housingType',
      'program_housing_types',
      'value',
    );
  }

  // Program organization name filter
  if (selectedFilters.organizationName.length > 0) {
    filteredPrograms = reducePrograms(
      filteredPrograms,
      selectedFilters,
      'organizationName',
      'organization_name',
      'value',
    );
  }

  // Program language of instruction filter
  if (selectedFilters.languageOfInstruction.length > 0) {
    filteredPrograms = reducePrograms(
      filteredPrograms,
      selectedFilters,
      'languageOfInstruction',
      'instructional_languages',
      'value',
    );
  }

  // Program language immersion filter
  if (selectedFilters.languageImmersion === 'yes') {
    filteredPrograms = filteredPrograms.filter(program => program.language_immersion.toLowerCase() === 'yes');
  }
  if (enable_active_term_name_filtering === false) {
    filteredPrograms = filteredPrograms.filter(program => {
      const termTimings = buildTermTimings(program.program_term_dates);
      let arrTimings = termTimings.split(';');
      const filters = ['Upcoming', 'On-site'];
      let isFound = false;
      arrTimings = arrTimings.map(string => string.trim());
      const filteredTimings = arrTimings.filter(item => item !== '');

      if (filteredTimings.length == 0) {
        return false;
      }
      filters.forEach(filter => {
        isFound = isFound ? isFound : arrTimings.includes(filter);
      });
      if (isFound) {
        return true;
      } else {
        return false;
      }
    });
  }
  filteredPrograms = getUniquePrograms(filteredPrograms);

  return filteredPrograms;
}
