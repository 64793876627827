import styled from 'styled-components';

const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 1.25rem 1.9375rem 1.25rem;

  ${props => {
    if (props.bottomBorder) {
      return `
        border-bottom: 1px solid #CCCCCC;
      `;
    }
  }};
`;

export default FilterContainer;
