import React from 'react';

export default function MyProfileIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
      <path d="M16.3753 3.69125C15.4631 2.70641 14.189 2.16406 12.7828 2.16406C11.369 2.16406 10.0907 2.70312 9.18276 3.68187C8.26495 4.67141 7.81776 6.01625 7.92276 7.46844C8.13089 10.3334 10.311 12.6641 12.7828 12.6641C15.2545 12.6641 17.4309 10.3339 17.6423 7.46937C17.7487 6.03031 17.2987 4.68828 16.3753 3.69125Z" />
      <path d="M21.0325 23.1641H4.53249C4.31652 23.1669 4.10264 23.1215 3.90642 23.0313C3.71019 22.941 3.53654 22.8081 3.39812 22.6423C3.09343 22.2781 2.97062 21.7808 3.06156 21.2778C3.45718 19.0831 4.69187 17.2395 6.63249 15.9453C8.35656 14.7964 10.5405 14.1641 12.7825 14.1641C15.0245 14.1641 17.2084 14.7969 18.9325 15.9453C20.8731 17.2391 22.1078 19.0827 22.5034 21.2773C22.5944 21.7803 22.4716 22.2777 22.1669 22.6419C22.0285 22.8078 21.8549 22.9407 21.6586 23.031C21.4624 23.1214 21.2485 23.1668 21.0325 23.1641Z" />
    </svg>
  );
}
