export default [
  {
    id: 1,
    label: 'Current',
    value: 'current',
  },
  {
    id: 2,
    label: 'Expired',
    value: 'expired',
  },
  {
    id: 3,
    label: `I don't have a passport`,
    value: 'no_passport',
  },
  {
    id: 4,
    label: `I don't know`,
    value: 'unknown',
  },
];
