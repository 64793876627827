import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import { useDispatch, useSelector } from 'react-redux';
import propTypes from 'prop-types';
import moment from 'moment-timezone';
import _map from 'lodash/map';
import _orderBy from 'lodash/orderBy';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import ViaModal from '../../../../components/library/viaModal';
import Headline from '../../../../components/library/headline';
import Button from '../../../../components/library/button';
import Paragraph from '../../../../components/library/paragraph';
import Select from 'react-select';
import sAssociateProgramToPlan from './associateProgramToPlan.scss';
import { fetchProgramLinks, patchPlan } from '../../../../../actions/plans';
export const enhance = compose(withStyles(sAssociateProgramToPlan));

function AssociateProgramToPlan(props) {
  const dispatch = useDispatch();
  const [programData, setProgramData] = useState();
  const [selectedProgram, setSelectedProgram] = useState();
  const [selectedProgramRange, setSelectedProgramRange] = useState();
  const [programRanges, setProgramRanges] = useState();
  const [saving, setSaving] = useState(false);
  const saveStatus = useSelector(state => state.plans.plan);
  const programs = useSelector(state => state.plans.availablePrograms);
  const customAliases = useSelector(state => state.profile.customAliases);

  useEffect(
    () => {
      if (saveStatus && !saveStatus.loading) {
        setSaving(false);
        props.onClose(false);
      }
    },
    [saveStatus],
  );

  useEffect(
    () => {
      if (saving) {
        dispatch(
          patchPlan(props.planId, {
            program_range_id: selectedProgramRange.value,
          }),
        );
      }
    },
    [saving],
  );

  useEffect(
    () => {
      if (programData && selectedProgram) {
        programData.filter(function(d) {
          if (d.programId === selectedProgram.value) {
            setProgramRanges(d.programRangesList);
            setSelectedProgramRange(d.programRangesList[0].id);
            return;
          }
        });
      }
    },
    [selectedProgram],
  );

  useEffect(
    () => {
      if (programs) {
        let programData = _map(programs.programs, item => {
          return {
            name: item.attributes.title,
            programId: item.id,
            programRangesList: item.attributes.program_ranges,
          };
        });

        programData = _orderBy(programData, ['name'], ['asc']);
        setProgramData(programData);
      }
    },
    [programs],
  );

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      dispatch(fetchProgramLinks());
    }
  }, []);

  const customStyles = {
    container: (provided, state) => ({
      ...provided,
      paddingLeft: '20px',
    }),
  };

  return (
    <>
      <ViaModal showing={props.show} onClose={() => props.onClose(false)}>
        <div className={sAssociateProgramToPlan['associate-program-to-plan']}>
          <div className={sAssociateProgramToPlan.subHeader}>
            <Headline centered tag="h1" as="h1">
              Associate {customAliases.alias_program} with this Plan?
            </Headline>
          </div>
          <div className={sAssociateProgramToPlan.content}>
            <Headline centered tag="h2" as="h2">
              If associated:
            </Headline>
            <Paragraph centered size="large">
              Travelers with a committed application to the associated {customAliases.alias_program} will appear in the
              Who's Going Tab on this Travel Plan.
            </Paragraph>
          </div>
          <div className={sAssociateProgramToPlan.programContainer}>
            <Headline as="h3" tag="h3">
              {customAliases.alias_program}
            </Headline>
            <div>
              <Paragraph size="medium">Select Your {customAliases.alias_program}</Paragraph>
              {programData && programData.length > 0 ? (
                <Select
                  value={selectedProgram}
                  styles={customStyles}
                  placeholder={selectedProgram ? selectedProgram.label : 'Search...'}
                  onChange={e => {
                    setSelectedProgram(e);
                  }}
                  options={
                    programData &&
                    programData.map((program, index) => ({
                      value: program.programId,
                      label: program.name,
                    }))
                  }
                />
              ) : null}
            </div>
            {selectedProgram && (
              <div>
                <Paragraph size="medium">Select Your {customAliases.alias_program} Range</Paragraph>
                <Select
                  value={selectedProgramRange}
                  styles={customStyles}
                  placeholder={selectedProgramRange ? selectedProgramRange.label : 'Search...'}
                  onChange={e => {
                    setSelectedProgramRange(e);
                  }}
                  options={
                    programRanges &&
                    programRanges.map((range, index) => ({
                      value: range.id,
                      label: `${range.name ? `${range.name}: ` : ''}${
                        range.use_exact_dates
                          ? `${moment(new Date(range.start_date)).format('MMM D[,] YYYY')}
                            - ${moment(new Date(range.end_date)).format('MMM D[,] YYYY')}`
                          : `${moment(new Date(range.start_date)).format('MMM [] YYYY')}
                            - ${moment(new Date(range.end_date)).format('MMM [] YYYY')}`
                      }`,
                    }))
                  }
                />
              </div>
            )}
          </div>

          <div className={sAssociateProgramToPlan.footer}>
            <div className={sAssociateProgramToPlan['footer-button-wrapper']}>
              <div className={sAssociateProgramToPlan['button-content']}>
                <Button
                  display="secondary"
                  kind="solid"
                  size="medium"
                  onClick={() => {
                    props.onClose(false);
                  }}
                >
                  No, Do Not Associate
                </Button>
              </div>
              <div className={sAssociateProgramToPlan['button-content']}>
                {selectedProgramRange && selectedProgramRange.label ? (
                  <Button
                    display="primary"
                    kind="solid"
                    size="medium"
                    type="submit"
                    onClick={() => {
                      setSaving(true);
                    }}
                  >
                    Yes, Associate To Plan
                  </Button>
                ) : (
                  <Button display="secondary" kind="solid" size="medium" disabled>
                    Yes, Associate To Plan
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </ViaModal>
    </>
  );
}

AssociateProgramToPlan.propTypes = {
  show: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
  planId: propTypes.string.isRequired,
};

export default enhance(AssociateProgramToPlan);
