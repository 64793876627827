import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import Dropdown from '../Dropdown/Dropdown';
import moment from 'moment-timezone';
import s from './EligibilityModal.css';
import ReactHtmlParser from 'react-html-parser';

class EligibilityModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: this.props.selectedOption || null,
      isError: false,
      alternate: false,
      gdprChecked: false,
    };
  }

  handleChange = (event, data) => {
    this.setState({ selectedOption: data });
    this.setState({ isError: false });

    var selectedVal = this.mapToFormat(this.props.ranges)
      .map(val => val.value)
      .indexOf(data);
    let selected_range = this.props.ranges[selectedVal];

    if (selected_range.range.alternate) {
      this.setState({ alternate: true });
    } else {
      this.setState({ alternate: false });
    }
  };

  isSameOrAfterCurrentDate = date => {
    const nowDate = moment().format('MMMM D, YYYY h:mm a');
    const dateToCheck = moment(new Date(date)).format('MMMM D, YYYY h:mm a');
    return moment(dateToCheck).isSameOrAfter(nowDate);
  };

  isBeforeCurrentDate = date => {
    const nowDate = moment().format('MMMM D, YYYY h:mm a');
    const dateToCheck = moment(new Date(date)).format('MMMM D, YYYY h:mm a');
    return moment(dateToCheck).isSameOrBefore(nowDate);
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.selectedOption) {
      this.setState({ selectedOption: nextProps.selectedOption });
    }
    if (nextProps.selectedOption) {
      this.setState({ alternate: nextProps.alternate });
    }
  }

  handleAuthorizedProgramLogic(program_range) {
    let range = program_range.range;
    let deadline = range.deadline;
    let application = program_range.application;

    return this.displayRange(range, deadline, application);
  }

  handleInternalProgramLogic(program_range) {
    if (program_range !== undefined && program_range.range !== undefined) {
      let range = program_range.range;
      let deadline = range.deadline;
      let application = program_range.application;

      return this.displayRange(range, deadline, application);
    }
  }

  displayTermName(rangeName) {
    if (rangeName === null) {
      return '';
    } else {
      return rangeName + ':';
    }
  }

  displayRange(range, deadline, application) {
    if (application && this.isSameOrAfterCurrentDate(range.start_date) && this.isSameOrAfterCurrentDate(deadline)) {
      return true;
    } else if (
      application &&
      deadline == null &&
      this.isSameOrAfterCurrentDate(range.start_date) &&
      this.isSameOrAfterCurrentDate(range.end_date)
    ) {
      return true;
    }

    return false;
  }

  handleClick(e) {
    if (!this.state.selectedOption && !this.state.gdprChecked) {
      this.setState({ isError: true, gdprError: true });
      e.preventDefault();
    } else if (!this.state.selectedOption) {
      this.setState({ isError: true });
      e.preventDefault();
    } else if (!this.state.gdprChecked) {
      this.setState({ gdprError: true });
      e.preventDefault();
    } else {
      this.setState({ isError: false, gdprError: false });
    }
  }

  mapToFormat = ranges => {
    return ranges.map(program_range => {
      let range = program_range.range;
      return {
        value: range.id,
        label: range.use_exact_dates
          ? `${moment(new Date(range.start_date)).format('MMMM D, YYYY')}
         - ${moment(new Date(range.end_date)).format('MMMM D, YYYY')}`
          : `${moment(new Date(range.start_date)).format('MMMM [] YYYY')}
         - ${moment(new Date(range.end_date)).format('MMMM [] YYYY')}`,
      };
    });
  };

  gdprChange = e => {
    this.setState({ gdprChecked: !this.state.gdprChecked });
  };

  render() {
    const showHideClassName = this.props.show ? s.modalDisplay : s.modalDisplayNone;
    const background = this.props.show ? s.backgroundShow : s.backgroundNone;
    const oBody = document.getElementsByTagName('body')[0];
    oBody.style.overflow = this.props.show ? 'hidden' : 'auto';
    let nonCancelledRanges = this.props.ranges.filter(range => {
      return range.range.status !== 'cancelled';
    });
    const ranges = nonCancelledRanges
      .filter(current => {
        let range = current.range || current;
        if (this.isBeforeCurrentDate(range.start_date) && this.isBeforeCurrentDate(range.end_date)) {
          return false;
        } else {
          if (this.props.authorized) {
            return this.handleAuthorizedProgramLogic(current);
          } else {
            return this.handleInternalProgramLogic(current);
          }
        }
      })
      .map(program_range => {
        let range = program_range.range;
        return {
          value: range.id,
          label: range.use_exact_dates
            ? `${this.displayTermName(range.name)} ${moment(new Date(range.start_date)).format('MMMM D, YYYY')}
           - ${moment(new Date(range.end_date)).format('MMMM D, YYYY')}`
            : `${this.displayTermName(range.name)} ${moment(new Date(range.start_date)).format('MMMM [] YYYY')}
           - ${moment(new Date(range.end_date)).format('MMMM [] YYYY')}`,
        };
      });
    const aliasProgram = this.props.aliasProgram ? this.props.aliasProgram.toLowerCase() : 'program'

    return (
      <div id={background}>
        <div id={showHideClassName} className={s.wrapper}>
          <div className={s.formSection}>
            <p className={s.formLabel}>Start my application</p>
            <div className={`${s.greyBackground}`}>
              <form>
                {this.props.instructions &&
                  this.props.authorized && (
                    <div>
                      <h3 className={s.header}>Instructions</h3>
                      <p className={s.subText}>{this.props.instructions}</p>
                    </div>
                  )}
                <h3 className={s.header}>Are you eligible to apply?</h3>
                <p className={s.subText}>
                  {this.props.eligibility
                    ? ReactHtmlParser(this.props.eligibility)
                    : `Contact your ${this.props.aliasProgram} Administrator for more information.`}
                </p>
                <h3 className={s.header}>When would you like to go?</h3>
                <Dropdown
                  name="Program Ranges"
                  placeholder={`Select a ${this.props.aliasProgram} Term`}
                  value={this.state.selectedOption}
                  onChange={this.handleChange.bind(this)}
                  options={ranges}
                  styleId={s.eligibilityDropdown}
                />
                {this.state.isError ? (
                  <small className={s.error}>* Please select a {aliasProgram} term</small>
                ) : (
                  ''
                )}
                {this.state.alternate && (
                  <div>
                    <br />
                    <div className={s.brochureInstruction}>{this.props.alternateSetting.brochure_instruction}</div>
                  </div>
                )}
                <div>
                  <label className={s.checkboxContainer}>
                    <span className={s.checkboxLabel}>
                      {`By clicking NEXT, I agree to be contacted about this ${aliasProgram} by advisors and administrators of this ${aliasProgram}.`}
                    </span>
                    <input
                      type="checkbox"
                      name={'gdpr'}
                      onChange={e => this.gdprChange(e)}
                      checked={this.gdprChecked ? 'checked' : null}
                    />
                    <span className={s.checkmark} />
                  </label>
                </div>
                <div style={{ marginTop: '1.5%' }}>
                  {this.state.gdprError ? <small className={s.error}>* Please select</small> : ''}
                </div>
                <div style={{ marginTop: '50px' }}>
                  <span className={s.cancelButton} onClick={this.props.handleClose}>
                    Cancel
                  </span>
                  <a
                    href={`/traveler/programs/${this.state.selectedOption}/apply`}
                    onClick={this.handleClick.bind(this)}
                    className={s.getStarted}
                    style={{ marginLeft: '3rem' }}
                  >
                    Next
                  </a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(s)(EligibilityModal);
