import styled from 'styled-components';
import { AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';

import { breakpoints } from '../../../utils/breakpoints';

export const ItemHeadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem 0 1rem;
  background-color: #ebebeb;
  border-radius: 2px 2px 0px 0px;
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
  font-size: 1rem;
  color: #373737;
  letter-spacing: -0.04em;
  line-height: 48px;
`;

export const ItemButton = styled(AccordionItemButton)`
  &:focus {
    outline: none;
  }

  &:focus-visible {
    svg {
      outline: 2px solid #255a94;
      outline-offset: 1px;
    }
  }
`;

export const ItemPanel = styled(AccordionItemPanel)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 0.625rem 1.5625rem 0.625rem;
  background-color: #fffcfc;
  border-width: 0px 1px 1px 1px;
  border-style: solid;
  border-color: #ebebeb;
  border-radius: 0px 0px 2px 2px;

  @media ${breakpoints.desktop} {
    padding: 2.5rem 0;
  }
`;
