import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import { useDispatch } from 'react-redux';
import propTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import ViaModal from '../../../../components/library/viaModal';
import Button from '../../../../components/library/button';
import Paragraph from '../../../../components/library/paragraph';
import sOneClickModal from './oneClickModal.scss';
import {
  batchAddApplication,
  batchAddForm,
  authorizeExternalPrograms,
  unauthorizeExternalPrograms,
  batchAddTerm,
  batchUpdateCustomFields,
  batchRemoveMessageRecipient,
  batchAddMessageRecipient,
  batchAddFormsToTravelers,
} from '../../../../../actions/reportsActions';
import { batchUpdatePlanRegistrationStatus, batchUpdatePlanStatus } from '../../../../../actions/plans';
import { batchAddTermNameAuth } from '../../../../../actions/termNamesActions';
import { sendTravelerBatchMessage } from '../../../../../actions/clientActions';
import ToastMessage from '../../toastMessage';
import { CustomTimeIcon } from '../../../../../styledComponents/styles/IconCustom.styled';

export const enhance = compose(withStyles(sOneClickModal));

function OneClickModal(props) {
  const dispatch = useDispatch();
  const [saving, setSaving] = useState(false);
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const handleBatchAction = action => {
    const submitValues = {
      program_ids: props.selectedPrograms,
      source: 'external',
    };

    setSuccessMessage('');

    switch (action.toLowerCase()) {
      case '1':
        break;
      case 'add_application':
        setSuccessMessage(props.addApplicationSuccessMessage);
        dispatch(batchAddApplication(props.submitValues, props.termType));
        break;

      case 'add_form':
        setSuccessMessage(props.addFormSuccessMessage);
        dispatch(batchAddForm(props.submitValues, props.termType));

        break;

      case 'add_term':
        setSuccessMessage(props.addTermSuccessMessage);
        dispatch(batchAddTerm(props.submitValues));

        break;

      case 'authorize':
        setSuccessMessage(props.authSuccessMessage);
        dispatch(authorizeExternalPrograms(submitValues, props.currentOrg));

        break;

      case 'unauthorize':
        setSuccessMessage(props.unauthSuccessMessage);
        dispatch(unauthorizeExternalPrograms(submitValues));

        break;

      case 'send_message_to_travelers':
        setSuccessMessage(props.sendMessageSuccess);
        dispatch(
          sendTravelerBatchMessage({
            subject: props.submitValues.subject,
            body: props.submitValues.body,
            traveler_ids: props.submitValues.traveler_ids,
            attachments: props.submitValues.attachments,
            attachmentCount: props.submitValues.attachments ? props.submitValues.attachments.length : null,
          }),
        );
        break;

      case 'batch_add_term_name_auth':
        setSuccessMessage(props.addTermNameAuthSuccessMessage);
        dispatch(batchAddTermNameAuth(props.submitValues));

        break;

      case 'update_custom_field':
        setSuccessMessage(props.updateCustomFieldSuccess);
        dispatch(batchUpdateCustomFields(props.submitValues, props.fetchApps));

        break;

      case 'remove_message_recipient':
        setSuccessMessage(props.batchUpdateMessageRecipientSuccess);
        dispatch(batchRemoveMessageRecipient(props.submitValues, props.fetchApps));

        break;

      case 'add_message_recipient':
        setSuccessMessage(props.batchUpdateMessageRecipientSuccess);
        dispatch(batchAddMessageRecipient(props.submitValues, props.fetchApps));

        break;

      case 'batch_add_form_to_travelers':
        setSuccessMessage(props.addFormSuccessMessage);
        dispatch(batchAddFormsToTravelers(props.submitValues, props.dayOffset, props.fetchApps));

        break;

      case 'batch_update_registration_status':
        setSuccessMessage(props.batchUpdatePlanRegistrationStatusSuccess);
        dispatch(batchUpdatePlanRegistrationStatus(props.submitValues));

        break;

      case 'batch_update_plan_status':
        setSuccessMessage(props.batchUpdatePlanStatusSuccess);
        dispatch(batchUpdatePlanStatus(props.submitValues));

        break;
    }

    props.onClose(false);
  };

  useEffect(() => {
    if ((props.actionState && props.actionState !== null) || (props.actionState && props.actionState !== undefined)) {
      if (props.actionState.action && props.actionState.action === 'updateAuthTerms') {
        if (props.actionState.data) {
          setSuccess(true);
          setFailure(false);
          props.onClose(false);
        }
        if (!!props.actionState.data && !!props.actionState.data.message) {
          setSuccess(false);
          setFailure(true);
          props.onClose(false);
        }
      } else if (props.actionState.action && props.actionState.action === 'batchAddAuthTerm') {
        if (props.actionState.data) {
          setSuccess(true);
          setFailure(false);
          props.onClose(false);
        }
        if (!!props.actionState.data && !!props.actionState.data.message) {
          setSuccess(false);
          setFailure(true);
          props.onClose(false);
        }
      } else if (props.actionState.action && props.actionState.action === 'batchUpdateCustomField') {
        if (props.actionState.data) {
          setSuccess(true);
          setFailure(false);
          props.onClose(false);
        }
        if (!!props.actionState.data && !!props.actionState.error) {
          setSuccess(false);
          setFailure(true);
          props.onClose(false);
        }
      } else if (props.actionState.action && props.actionState.action === 'updateMessageRecipient') {
        if (props.actionState.data) {
          setSuccess(true);
          setFailure(false);
          props.onClose(false);
        }
        if (!!props.actionState.data && !!props.actionState.error) {
          setSuccess(false);
          setFailure(true);
          props.onClose(false);
        }
      } else {
        if (typeof props.actionState === 'string' && props.actionState.includes('Message sent to')) {
          setSuccess(true);
          setFailure(false);
          props.onClose(false);
        }
        if (!!props.actionState.message && props.actionState.message.includes('Forms added')) {
          const addFormSuccessMessage = `${
            !!props.actionState.success_count && props.actionState.success_count === 1 ? 'Form' : 'Forms'
          } added to ${props.actionState.success_count} ${
            props.actionState.success_count === 1
              ? `${props.customAliases.alias_traveler.toLowerCase()}`
              : `${props.customAliases.alias_travelers.toLowerCase()}`
          }`;

          setSuccess(true);
          setSuccessMessage(addFormSuccessMessage);
          setFailure(false);
          props.onClose(false);
        }

        if (props.actionState.loading) {
          setSaving(true);
        }

        if (!!props.actionState && !!props.actionState.failures && props.actionState.failures.length > 0) {
          setSuccess(false);
          setFailure(true);
          props.onClose(false);
        }

        if (saving && !!props.actionState.data) {
          setSaving(false);

          if (!!props.actionState.data.failure && !!props.actionState.data.failure.length > 0) {
            setSuccess(false);
            setFailure(true);
            props.onClose(false);
          } else if (props.actionState.data.message) {
            setSuccess(false);
            setFailure(true);
            props.onClose(false);
          } else if (
            (!!props.actionState.data.data &&
              !!props.actionState.data.data.failures &&
              props.actionState.data.data.failures.length > 0) ||
            (!!props.actionState.data &&
              !!props.actionState.data.failures &&
              props.actionState.data.failures.length > 0)
          ) {
            setSuccess(false);
            setFailure(true);
            props.onClose(false);
          } else if (props.actionState.data.data && props.actionState.data.data !== {}) {
            setSuccess(true);
            setFailure(false);
            props.onClose(false);
          }
        }
      }
    }
  }, [props.actionState]);

  return (
    <>
      <ViaModal
        showing={props.show}
        onClose={() => props.onClose(false)}
        headerText={props.headline}
        width={props.width ? props.width : '100%'}
      >
        <div className={sOneClickModal['one-click-modal']}>
          {props.showAddTermIcon ? (
            <div className={sOneClickModal.centeredContent}>
              <CustomTimeIcon size="150px" />
            </div>
          ) : null}
          <div className={props.centered ? sOneClickModal.centeredContent : sOneClickModal.content}>
            <Paragraph size="normal">{props.paragraphOne}</Paragraph>
          </div>

          {props.bulletedContent ? (
            <div className={sOneClickModal.bulletedContent}>{props.bulletedMessage ? props.bulletedMessage : null}</div>
          ) : null}

          {props.paragraphTwo ? (
            <div className={props.centered ? sOneClickModal.centeredContentTwo : sOneClickModal.content}>
              <Paragraph size="normal" demiBold={props.paragraphTwoDemiBold ? props.paragraphTwoDemiBold : false}>
                {props.paragraphTwo}
              </Paragraph>
            </div>
          ) : null}

          <div className={sOneClickModal.footer}>
            <div className={sOneClickModal['button-content']}>
              <Button
                display="secondary"
                kind="solid"
                size="medium"
                onClick={() => {
                  props.onClose(false);
                }}
              >
                {props.cancelMessage}
              </Button>
            </div>
            <div className={sOneClickModal['button-content']}>
              <Button
                display="primary"
                kind="solid"
                size="medium"
                type="submit"
                onClick={() => {
                  handleBatchAction(props.source);
                }}
              >
                {props.confirmActionMessage}
              </Button>
            </div>
          </div>
        </div>
      </ViaModal>
      {success ? (
        <ToastMessage
          message={success && successMessage !== '' ? successMessage : null}
          show={success && successMessage !== '' ? success : false}
          isSuccess
          onClose={() => {
            setSuccess(false);
            setSuccessMessage('');
            props.onClose(true);
            props.setSelectedPrograms ? props.setSelectedPrograms() : null;
          }}
        />
      ) : null}
      {failure ? (
        <ToastMessage
          message={props.failureMessage}
          isSuccess={false}
          show={failure && !!props.failureMessage}
          source={props.source}
          onClose={() => {
            setFailure(false);
            props.onClose(true);
            props.setSelectedPrograms ? props.setSelectedPrograms() : null;
          }}
        />
      ) : null}
    </>
  );
}

OneClickModal.propTypes = {
  show: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
  centered: propTypes.bool,
  showAddTermIcon: propTypes.bool,
};

OneClickModal.defaultProps = {
  centered: false,
  showAddTermIcon: false,
  fetchApps: true,
};

export default enhance(OneClickModal);
