exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@-webkit-keyframes _38jkt{to{-webkit-transform:rotate(1turn)}}@keyframes _38jkt{to{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}._3LXGT{border:1px solid #df235d}._3LXGT::-webkit-input-placeholder{color:#df235d;opacity:1}._3LXGT::-moz-placeholder{color:#df235d;opacity:1}._3LXGT::-ms-input-placeholder{color:#df235d;opacity:1}._3LXGT::placeholder{color:#df235d;opacity:1}", ""]);

// exports
exports.locals = {
	"traveler-actions-select": "_3LXGT",
	"spin": "_38jkt"
};