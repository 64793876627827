import React from 'react';
const ArchiveIcon = (props) => {
  return(
  <div>
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_2134_17649)">
      <rect width="16" height="16" transform="translate(0 0.5)" fill={props.color} fill-opacity="0.01"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M2 1.99902H14C14.5523 1.99902 15 2.44674 15 2.99902V3.49902C15 4.05131 14.5523 4.49902 14 4.49902H2C1.44772 4.49902 1 4.05131 1 3.49902V2.99902C1 2.44674 1.44772 1.99902 2 1.99902ZM2 13.249V5.62402C2 5.59087 2.01317 5.55908 2.03661 5.53563C2.06005 5.51219 2.09185 5.49902 2.125 5.49902H13.875C13.9082 5.49902 13.9399 5.51219 13.9634 5.53563C13.9868 5.55908 14 5.59087 14 5.62402V13.249C14 13.7132 13.8156 14.1583 13.4874 14.4865C13.1592 14.8146 12.7141 14.999 12.25 14.999H3.75C3.28587 14.999 2.84075 14.8146 2.51256 14.4865C2.18437 14.1583 2 13.7132 2 13.249ZM8.35344 12.3525L10.3438 10.3625C10.5341 10.1721 10.5516 9.86215 10.3703 9.66309C10.3248 9.61297 10.2696 9.57261 10.2081 9.54445C10.1465 9.5163 10.0799 9.50094 10.0122 9.4993C9.94451 9.49766 9.87721 9.50979 9.81437 9.53493C9.75152 9.56008 9.69443 9.59772 9.64656 9.64559L8.5 10.7918V7.49902C8.49999 7.43134 8.48625 7.36436 8.45959 7.30215C8.43293 7.23994 8.39392 7.18378 8.34492 7.13709C8.29593 7.0904 8.23796 7.05414 8.17453 7.03052C8.1111 7.00689 8.04354 6.99639 7.97594 6.99965C7.70687 7.01309 7.5 7.24402 7.5 7.51309V10.7918L6.35344 9.64559C6.30557 9.59772 6.24848 9.56008 6.18563 9.53493C6.12279 9.50979 6.05549 9.49766 5.98781 9.4993C5.92014 9.50094 5.85351 9.5163 5.79195 9.54445C5.73039 9.57261 5.67519 9.61297 5.62969 9.66309C5.44844 9.86246 5.46594 10.1721 5.65625 10.3625L7.64656 12.3525C7.74032 12.4462 7.86745 12.4988 8 12.4988C8.13255 12.4988 8.25968 12.4462 8.35344 12.3525Z" fill={props.color}/>
      </g>
      <defs>
      <clipPath id="clip0_2134_17649">
      <rect width="16" height="16" fill={props.innerColor} transform="translate(0 0.5)"/>
      </clipPath>
      </defs>
    </svg>

  </div>
  )
}
export default (ArchiveIcon);