import React from 'react';
import { useSelector } from 'react-redux';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import styles from './index.module.css';
import notesImg from './notes.png';
import useMediaQuery from '../../../../hooks/useMediaQuery';

function BlockPage() {
  const { isMobile } = useMediaQuery();
  const currentUser = useSelector(state => state.profile.currentUser);

  return (
    <>
      {isMobile ? (
        <>
          <div className={styles.center}>
            <div className={styles.contentMobile}>
              <img src={notesImg} className={styles.imgMobile} />
              <h1 className={`${styles.heading} ${styles.headingMobile}`}>
                Looks like this program is now off the radar.
              </h1>

              <div className={`${styles.message} ${styles.mesageMobile}`}>
                We encourage you to explore other available programs that might spark your interest.
              </div>

              <a
                href={currentUser?.id ? '/traveler/program-dashboard-v2' : '/visitor-programs-v2'}
                className={`btn btn-primary ${styles.btnMobile} ${styles.exploreBtn}`}
              >
                Explore Available Programs
              </a>
            </div>
          </div>
        </>
      ) : (
        <div className={styles.center}>
          <div className={styles.content}>
            <div>
              <h1 className={styles.heading}>Looks like this program is now off the radar.</h1>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div>
                <div className={styles.message}>
                  We encourage you to explore other available programs that might spark your interest.
                </div>
                <div className={styles.btnContainer}>
                  <a href={currentUser?.id ? '/traveler/program-dashboard-v2' : '/visitor-programs-v2'} className={`btn btn-primary ${styles.exploreBtn}`}>
                    Explore Available Programs
                  </a>
                </div>
              </div>
              <div>
                <img src={notesImg} className={styles.img} />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default withStyles(styles)(BlockPage);
