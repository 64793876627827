import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import s from './Group.css';

class Group extends React.Component {
  render() {
    return (
      <div className={s.group}>
        <div className={s.header}>
          <span className={s.headerTitle}>{this.props.header}</span>
          <hr />
        </div>
        {this.props.children}
      </div>
    );
  }
}

export default withStyles(s)(Group);
