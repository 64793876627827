import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import InputSelect from '../../../../components/library/inputSelect';
import { fetchTransportationTypes } from '../../../../../actions/lookupActions';

function TransportationTypeSelect(props) {
  const dispatch = useDispatch();
  const transportationTypes = useSelector(state => state.lookup.allTransportationTypes);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (!transportationTypes.transportationTypes) {
      dispatch(fetchTransportationTypes());
    } else {
      setOptions(
        transportationTypes.transportationTypes.map(item => {
          return { label: item.attributes.name, value: item.id };
        }),
      );
    }
  }, [transportationTypes]);

  return (
    <>
      {transportationTypes && transportationTypes.transportationTypes && (
        <InputSelect {...props} options={options} />
      )}
    </>
  );
}

TransportationTypeSelect.propTypes = {
  errorMessage: propTypes.string,
  labelText: propTypes.string,
  helpText: propTypes.string,
  placeholder: propTypes.string,
  isRequired: propTypes.bool,
};


TransportationTypeSelect.defaultProps = {
  errorMessage: '',
  labelText: 'TRANSPORTATION TYPE',
  helpText: '',
  placeholder: '',
  isRequired: false,
};

export default (TransportationTypeSelect);
