exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@-webkit-keyframes _1rsbt{to{-webkit-transform:rotate(1turn)}}@keyframes _1rsbt{to{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}._21CIH{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;width:100%}._21CIH ._2oNqa,._21CIH .DLeRs{-ms-flex:1 1;flex:1 1}._21CIH ._2oNqa{padding-top:12px}._21CIH ._2oNqa p{font-family:AvenirNext,sans-serif}._21CIH ._3clEm{-ms-flex:1 1;flex:1 1;padding-top:12px;background-color:#ebebeb;border-radius:12px;padding-left:12px;margin-bottom:20px}._21CIH ._3clEm h2{padding:20px 0}", ""]);

// exports
exports.locals = {
	"ov-details": "_21CIH",
	"ov-details-headline": "DLeRs",
	"ov-details-description": "_2oNqa",
	"ov-details-program": "_3clEm",
	"spin": "_1rsbt"
};