import React, { Component } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import Editor from 'draft-js-plugins-editor';
import createLinkPlugin from 'draft-js-anchor-plugin';
import createInlineToolbarPlugin from 'draft-js-inline-toolbar-plugin';
import draftDefaultStyles from './DraftDefault.css';
import editorStyles from './DraftEditor.css';
import buttonStyles from './buttonStyles.css';
import toolbarStyles from './toolbarStyles.css';
import linkStyles from './linkStyles.css';
import draftEditorPlaceholder from './DraftEditorPlaceholder.css';

import ss from 'draft-js-inline-toolbar-plugin/lib/plugin.css';
import { convertToRaw, EditorState, ContentState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';
import { UnorderedListButton, OrderedListButton } from 'draft-js-buttons';
import customBranding from '../../../../hoc/customBranding/customBranding';

import { connect } from 'react-redux';
import { compose } from 'redux';
import { withCookies } from 'react-cookie';
import createSoftNewLinePlugin from '@jimmycode/draft-js-soft-newline-plugin';
import _isEmpty from 'lodash/isEmpty';

@customBranding
class DraftEditorSingle extends Component {
  constructor(props) {
    super(props);
    this.inlineTool = createInlineToolbarPlugin({
      theme: { buttonStyles, toolbarStyles },
    });

    this.softNewLinePLugin = createSoftNewLinePlugin();
    this.linkPlugin = createLinkPlugin({
      theme: linkStyles,
      placeholder: 'Enter a valid URL and press enter.',
      linkTarget: '_blank',
    });
    const contentState = stateFromHTML(props.description);
    if (!_isEmpty(props.description)) {
      this.state = {
        editorState: EditorState.createWithContent(contentState),
        placeholder: null,
      };
    } else {
      this.state = {
        editorState: EditorState.createEmpty(),
        placeholder: null,
      };
    }
    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }

  badData = data => {
    const filthyData = ['<p>null</p>', '<p><br></p>', '<p> </p>', '<p></p>'];
    return filthyData.includes(data);
  };

  onChange(editorState) {
    this.setState({
      editorState,
    });
    const content = editorState.getCurrentContent();
    const dataToSaveBackend = convertToRaw(content);
    let options = {
      entityStyleFn: entity => {
        const entityType = entity.get('type').toLowerCase();
        if (entityType === 'link') {
          const data = entity.getData();
          return {
            element: 'a',
            attributes: {
              href: data.url,
              target: '_blank',
            },
            style: {
              // Put styles here...
            },
          };
        }
      },
    };

    if (!this.badData(stateToHTML(content, options))) {
      this.props.descriptionEditChild({
        target: { value: stateToHTML(content, options) },
      });
    } else {
      this.props.descriptionEditChild({
        target: { value: '' },
      });
    }
  }

  onBlur(editorState) {
    if (this.props && this.props.myBlured != undefined) {
      this.setPlaceholder();
      this.setState({
        editorState,
      });
      const content = this.state.editorState.getCurrentContent();
      const dataToSaveBackend = convertToRaw(content);
      let options = {
        entityStyleFn: entity => {
          const entityType = entity.get('type').toLowerCase();
          if (entityType === 'link') {
            const data = entity.getData();
            return {
              element: 'a',
              attributes: {
                href: data.url,
                target: '_blank',
              },
              style: {
                // Put styles here...
              },
            };
          }
        },
      };
      if (!this.badData(stateToHTML(content, options))) {
        this.props.myBlured({
          target: { value: stateToHTML(content, options) },
        });
      }
    }
  }

  onBlur(editorState) {
    if (this.props && this.props.myBlured != undefined) {
      this.setPlaceholder();
      this.setState({
        editorState,
      });
      const content = this.state.editorState.getCurrentContent();
      const dataToSaveBackend = convertToRaw(content);
      let options = {
        entityStyleFn: entity => {
          const entityType = entity.get('type').toLowerCase();
          if (entityType === 'link') {
            const data = entity.getData();
            return {
              element: 'a',
              attributes: {
                href: data.url,
                target: '_blank',
              },
              style: {
                // Put styles here...
              },
            };
          }
        },
      };
      this.props.myBlured({
        target: { value: stateToHTML(content, options) },
      });
    }
  }

  focus = () => {
    this.editor.focus();
    this.setState({ placeholder: null });
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.reset !== undefined && nextProps.reset === true) {
      const editorState = EditorState.push(
        this.state.editorState,
        ContentState.createFromText(''),
      );
      this.setState({ editorState: editorState }, () => {});
      if (this.props.setReset !== undefined) {
        this.props.setReset();
      }
    }
  }
  componentDidMount() {
    this.setPlaceholder();
  }

  setPlaceholder() {
    const { placeholder } = this.state;
    const { status } = this.props;
    const { alias_program } = this.props.alias_program ? this.props : 'Program';
    this.setState({
      placeholder: this.placeholderSwitch(status, alias_program),
    });
  }

  placeholderSwitch = (param, alias_program) => {
    switch (param) {
      case 'description':
        return `${alias_program} Intro...`;
      case 'eligibility':
        return `Enter any eligibility requirements for the ${alias_program.toLowerCase()}...`;
      case 'scholarship':
        return `Enter any scholarship information for the ${alias_program.toLowerCase()}...`;
      case 'subDescription':
        return `${alias_program} Description...`;
      case 'activities':
        return null;
      case 'templateIntro':
        return this.props.default;
      case 'templateSectionInfo':
        return '';
      case 'templateLngInfo':
        return this.props.placeholder;
      case 'templateMultiChoiceInfo':
        return this.props.placeholder;
      case 'templateShrtInfo':
        return this.props.placeholder;
      case 'templateSectionInfo':
        return '';
      case 'templateLngInfo':
        return this.props.placeholder;
      case 'templateMultiChoiceInfo':
        return this.props.placeholder;
      case 'templateShrtInfo':
        return this.props.placeholder;
      case 'courses':
        return this.props.placeholder;
      case 'newMessage':
        return 'New Message...';
      default:
        return this.props.placeholder || null;
    }
  };

  cssSwitch = param => {
    switch (param) {
      case 'description':
        return editorStyles.newDescriptionInput;
      case 'eligibility':
        return editorStyles.newEligibility;
      case 'scholarship':
        return editorStyles.newScholarship;
      case 'subDescription':
        return editorStyles.newSubDescriptionInput;
      case 'activities':
        return editorStyles.newActivitiesAndAdmin;
      case 'excursions':
        return editorStyles.newActivitiesAndAdmin;
      case 'addOns':
        return editorStyles.newActivitiesAndAdmin;
      case 'templateIntro':
        return editorStyles.paraContent;
      case 'templateSectionInfo':
        return editorStyles.helpText;
      case 'templateLngInfo':
        return editorStyles.helpText;
      case 'templateMultiChoiceInfo':
        return editorStyles.helpText;
      case 'templateShrtInfo':
        return editorStyles.helpText;
      case 'courses':
        return editorStyles.newActivitiesAndAdmin;
      case 'newMessage':
        return editorStyles.bodyInput;
      case 'eventDesc':
        return editorStyles.eventDescription;
      case 'message':
        return editorStyles.message;
      default:
        return editorStyles.newDescriptionInput;
    }
  };

  render() {
    const { status } = this.props;
    const plugins = [this.inlineTool, this.linkPlugin, this.softNewLinePLugin];
    const { InlineToolbar } = this.inlineTool;
    const { LinkButton } = this.linkPlugin;
    return (
      <div className={this.cssSwitch(status)} onClick={this.focus}>
        <div>
        {this.props.is_required && <span>*</span>}
          <Editor
            editorState={this.state.editorState}
            onChange={this.onChange}
            onBlur={this.onBlur}
            plugins={plugins}
            ref={element => {
              this.editor = element;
            }}
            placeholder={this.state.placeholder}
          />
          <InlineToolbar>
            {externalProps => (
              <div>
                <UnorderedListButton {...externalProps} />
                <OrderedListButton {...externalProps} />
                <LinkButton {...externalProps} />
              </div>
            )}
          </InlineToolbar>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  customBranding: state.profile.customBranding || {},
});

export default compose(
  withCookies,
  withStyles(
    draftDefaultStyles,
    ss,
    editorStyles,
    buttonStyles,
    toolbarStyles,
    linkStyles,
  ),
  connect(
    mapStateToProps,
    null,
  ),
)(DraftEditorSingle);
