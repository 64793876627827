var escape = require("../../../node_modules/css-loader/lib/url/escape.js");
exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@-webkit-keyframes Vq7qm{0%{-webkit-transform:translateZ(0);transform:translateZ(0)}to{-webkit-transform:translate3d(375px,0,0);transform:translate3d(375px,0,0)}}@keyframes Vq7qm{0%{-webkit-transform:translateZ(0);transform:translateZ(0)}to{-webkit-transform:translate3d(375px,0,0);transform:translate3d(375px,0,0)}}@-webkit-keyframes _2Q2A0{0%{-webkit-transform:translateY(-45%) rotate(-2deg);transform:translateY(-45%) rotate(-2deg)}to{-webkit-transform:translateY(-90%);transform:translateY(-90%)}}@keyframes _2Q2A0{0%{-webkit-transform:translateY(-45%) rotate(-2deg);transform:translateY(-45%) rotate(-2deg)}to{-webkit-transform:translateY(-90%);transform:translateY(-90%)}}._374jx{position:absolute;display:inline-block;z-index:5;left:45%;top:20%}._33Bjr{position:relative;display:block;margin:0 auto;width:100%;height:100%;border-radius:50%;overflow:hidden;width:128px;height:128px;-webkit-mask-image:-webkit-radial-gradient(#fff,#000)}._33Bjr:after{content:\"\";left:0;border-radius:50%;background:#a1dbdc;z-index:-1}._21mTs,._33Bjr:after{position:absolute;right:0;top:0;bottom:0}._21mTs{background:url(" + escape(require("./img/map.svg")) + ") repeat-x 50%;background-size:375px;width:1500px;-webkit-animation:Vq7qm 10s linear infinite;animation:Vq7qm 10s linear infinite}._2P--Z{position:absolute;top:50%;-webkit-transform:translateY(-50%);-ms-transform:translateY(-50%);transform:translateY(-50%);right:-20px;-webkit-animation:_2Q2A0 1.75s ease-in-out infinite;animation:_2Q2A0 1.75s ease-in-out infinite;-webkit-animation-direction:alternate;animation-direction:alternate}", ""]);

// exports
exports.locals = {
	"globe": "_374jx",
	"circle": "_33Bjr",
	"map": "_21mTs",
	"mapScroll": "Vq7qm",
	"plane": "_2P--Z",
	"planeMove": "_2Q2A0"
};