import React, { useEffect } from 'react';
import { compose } from 'recompose';
import propTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import sPagePublic from './pagePublic.scss';

export const enhance = compose(
  withStyles(sPagePublic),
);

function PagePublic(props) {
  const { children } = props;

  return (
    <div className={sPagePublic.page}>
      {children}
    </div>
  );
}

PagePublic.propTypes = {
  children: propTypes.node,
};

export default enhance(PagePublic);
