import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import moment from 'moment-timezone';

import s from './TravelerEvent.css';
import fill from './img/fill.png';

const TravelerEvent = props => {
  return (
    <div className={s.event}>
      <div className={s.eventInfo}>
        <div>
          {/* <img src={fill} />
          <h3 className={s.day}>{props.day}</h3> */}
          <svg
            className={s.calendar}
            viewBox="0 0 44 36"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g
                transform="translate(-408.000000, -334.000000)"
                fill={props.customBranding.theme_color_accent}
              >
                <g transform="translate(193.000000, 242.000000)">
                  <g transform="translate(215.000000, 92.000000)">
                    <path
                      d="M1.94594593,34.0540541 L41.8378375,34.0540541 L41.8378375,13.6216218 L1.94594593,13.6216218 L1.94594593,34.0540541 Z M32.5945943,5.35135162 C33.4121619,5.35135162 34.0540538,5.99324351 34.0540538,6.81081107 C34.0540538,7.62837863 33.4121619,8.27027051 32.5945943,8.27027051 C31.7770267,8.27027051 31.1351349,7.62837863 31.1351349,6.81081107 C31.1351349,5.99324351 31.7770267,5.35135162 32.5945943,5.35135162 L32.5945943,5.35135162 Z M11.1891891,5.35135162 C12.0067567,5.35135162 12.6486485,5.99324351 12.6486485,6.81081107 C12.6486485,7.62837863 12.0067567,8.27027051 11.1891891,8.27027051 C10.3716215,8.27027051 9.72972964,7.62837863 9.72972964,6.81081107 C9.72972964,5.99324351 10.3716215,5.35135162 11.1891891,5.35135162 L11.1891891,5.35135162 Z M32.5945943,3.40540569 C30.7253636,3.40540569 29.1891889,4.94158032 29.1891889,6.81081107 C29.1891889,8.68004182 30.7253636,10.2162164 32.5945943,10.2162164 C34.4638251,10.2162164 35.9999997,8.68004182 35.9999997,6.81081107 C35.9999997,4.94158032 34.4638251,3.40540569 32.5945943,3.40540569 L32.5945943,3.40540569 Z M11.1891891,3.40540569 C9.31995834,3.40540569 7.78378372,4.94158032 7.78378372,6.81081107 C7.78378372,8.68004182 9.31995834,10.2162164 11.1891891,10.2162164 C13.0584198,10.2162164 14.5945945,8.68004182 14.5945945,6.81081107 C14.5945945,4.94158032 13.0584198,3.40540569 11.1891891,3.40540569 L11.1891891,3.40540569 Z M1.94594593,11.6756759 L41.8378375,11.6756759 L41.8378375,1.94594625 L1.94594593,1.94594625 L1.94594593,11.6756759 Z M0.972972964,3.17707784e-07 C0.379989602,-0.000467457756 0.000571725567,0.515696779 0,0.972973282 L0,35.027027 C5.19750515e-05,35.5364865 0.463513509,35.999948 0.972972964,36 L42.8108104,36 C43.3202699,35.999948 43.7837314,35.5364865 43.7837834,35.027027 L43.7837834,0.972973282 C43.7837314,0.463513827 43.3202699,5.22927593e-05 42.8108104,3.17707784e-07 L0.972972964,3.17707784e-07 Z"
                      id="Fill-1"
                    />
                    <text
                      id="12"
                      fontSize="16"
                      x="12"
                      y="32"
                      line-spacing="18"
                      letterSpacing="-1.23076928"
                      fill="#000"
                    >
                      {props.day}
                    </text>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </div>
        <div className={s.titleSection}>
          <a href={'https://' + props.publicUrl} target="_blank">
            <span className={s.name}>{props.name}</span>
            <span className={s.location}>{' located in '}</span>
            <span className={s.location}>{props.location}</span>
          </a>
          <div>
            <span className={s.date}>{props.date}</span>
            <span className={s.time}>at{' '}{props.time}</span>
            <span className={s.timezone}>{' '}{props.timezone}</span>
          </div>
          <div>
            <span className={s.attendance}>{props.attended}</span>
            <span className={s.attendance}>{' | '}</span>
            <span className={s.registration}>{props.registered}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withStyles(s)(TravelerEvent);
