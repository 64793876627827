import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Paragraph } from '../../../../../styledComponents/styles/Paragraph.styled';
import StarRating from '../../../../../styledComponents/styles/StarRating.styled';
import _get from 'lodash/get';

const Container = styled.div`
  font-family: 'Nunito', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 0.015em;
  color: #373737;
`;

const InputContainer = styled.div`
  flex: 1;
  margin: 13px 0;
  text-align: left;
`;

const Label = styled.label`
  color: ${({ theme }) => theme.textInput.label.fontColor};
  font-family: ${({ theme }) => theme.textInput.label.fontFamily};
  font-size: ${({ theme }) => theme.textInput.label.fontSize};
  font-weight: ${({ theme }) => theme.textInput.label.fontWeight};
`;

const Required = styled.span`
  color: ${({ theme }) => theme.defaults.errorColor};
  padding-left: 5px;
`;

export default function QuestionStarRatingDisplayForm({
  question,
  formState,
  errors = {},
  setValue,
  value,
  control,
  defaultValue,
  isReadonly = false,
  ...rest
}) {
  const id = question.question_id.toString();
  const [initialLoading, setInitialLoading] = useState(true);

  question.star_rating_question_options.sort((a, b) => {
    if (a.order < b.order) return -1;
    else if (a.order > b.order) return 1;
    return 0;
  });

  useEffect(
    () => {
      if (defaultValue?.star_rating_question_option_id) {
        setValue(id, defaultValue?.star_rating_question_option_id, {
          shouldValidate: true,
          shouldTouch: false,
          shouldDirty: false,
        });
      }
      setTimeout(() => {
        setInitialLoading(false);
      }, 200);
    },
    [defaultValue],
  );
  const updatedInstructions = !!question.instructions ? question.instructions.replace(/<p><\/p>/g, '<br />') : ''; 
  return (
    <Container>
      <InputContainer>
        <Label htmlFor={id} tabIndex={0}>
          {question.label}
          {question.required && <Required>*</Required>}
        </Label>
        <Paragraph
          tabIndex={1}
          // aria-label={`${question.label} ${question.required && "Required"}`}
          id={'star-rating-instructions'}
          openSans
          dangerouslySetInnerHTML={{
            __html: updatedInstructions,
          }}
        />

        <StarRating
          maxStars={question.star_rating_question_options.length}
          options={question.star_rating_question_options.reduce(
            (a, key, index) => Object.assign(a, { [index + 1]: key.name }),
            {},
          )}
          errorMsg={_get(errors[id], 'message') || ''}
          id={id}
          disabled={isReadonly}
          onChange={e => {
            const selected = question.star_rating_question_options.find(opt => opt.order === e);
            const valueChanged = !initialLoading && defaultValue?.star_rating_question_option_order !== selected?.order;

            if (selected) {
              setValue(id, selected.id, {
                shouldValidate: true,
                shouldTouch: valueChanged,
                shouldDirty: valueChanged,
              });
            } else {
              setValue(id, null, {
                shouldValidate: true,
                shouldTouch: valueChanged,
                shouldDirty: valueChanged,
              });
            }
          }}
          defaultValue={defaultValue?.star_rating_question_option_order}
        />
      </InputContainer>
    </Container>
  );
}
