exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ":root{--dark:var(--theme_color_dark);--accent:var(--theme_color_accent);--ocean:var(--theme_color_light)}._2UuUo{margin:8px;margin:.5rem}._3ZdvP{font-family:IntroBoldItalic,sans-serif;font-size:30px;color:var(--theme_color_dark);color:var(--dark,#043544);text-align:center;line-height:33px;margin:32px 20%;margin:2rem 20%}", ""]);

// exports
exports.locals = {
	"events": "_2UuUo",
	"noMatchHeader": "_3ZdvP"
};