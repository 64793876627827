import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import FeesIcon from './icons/FeesIcon';
import AcademicsIcon from './icons/AcademicsIcon';
import MiscellaneousIcon from './icons/MiscellaneousIcon';
import AccommodationsIcon from './icons/AccommodationsIcon';
import TransportationIcon from './icons/TransportationIcon';

import { breakpoints } from '../../../utils/breakpoints';
import { ScreenReaderText } from '../../../utils/shared/ScreenReader.styled';

const Card = styled.div`
  box-shadow: 0 0.625rem 1.25rem rgba(132, 131, 131, 0.3);
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  background-color: #ffffff;
  color: #373737;
  font-style: normal;
  font-size: 0.75rem;
  line-height: 120%;
  font-family: 'Open Sans', sans-serif;
  width: 100%;
  justify-content: center;
  padding: 1.75rem;
  max-width: 22rem;

  @media ${breakpoints.phone} {
    min-width: 22rem;
  }

  @media ${breakpoints.tablet} {
    padding: 2.8125rem 0.75rem;
    height: 100%;
  }

  svg {
    width: 3rem;
    flex-shrink: 0;
  }
`;

const CardInner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

const CardTitle = styled.h3`
  font-weight: 700;
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  margin-bottom: 0;
`;

const CardSubtitle = styled.h4`
  font-weight: 700;
  margin-bottom: 0;
  margin-top: 0.5rem;
  font-size: 0.75rem;
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 120%;
  color: #373737;
`;

const CardList = styled.ul`
  list-style-type: disc;
  padding-left: 1.25rem;
`;

export default function IncludedCard({ title, icon, included, excluded }) {
  const iconMap = {
    fees: FeesIcon,
    academics: AcademicsIcon,
    accommodations: AccommodationsIcon,
    miscellaneous: MiscellaneousIcon,
    transportation: TransportationIcon,
  };

  const Component = iconMap[icon];

  return (
    <Card tabIndex={0}>
      <CardInner>
        <Component />
        <CardTitle>{title}</CardTitle>
        {included.length > 0 && (
          <CardContent>
            <CardList>
              {included.sort((a, b) => a.localeCompare(b)).map(includedItem => (
                <li key={includedItem.split(' ').join('-')}>{includedItem}</li>
              ))}
            </CardList>
          </CardContent>
        )}
        {excluded.length > 0 && (
          <CardContent>
            <CardSubtitle>
              Not Included:
              <ScreenReaderText>for {title}</ScreenReaderText>
            </CardSubtitle>
            <CardList tabIndex={0}>
              {excluded.sort((a, b) => a.localeCompare(b)).map(notIncludedItem => (
                <li key={notIncludedItem.split(' ').join('-')}>{notIncludedItem}</li>
              ))}
            </CardList>
          </CardContent>
        )}
      </CardInner>
    </Card>
  );
}

IncludedCard.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  included: PropTypes.arrayOf(PropTypes.string),
  excluded: PropTypes.arrayOf(PropTypes.string),
};

IncludedCard.defaultProps = {
  included: [],
  excluded: [],
};
