import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const InputContainer = styled.div`
  position: relative;
  width: 100%;
  padding: 0 0.625rem;
  background-color: #ffffff;
  border: 1px solid transparent;
  color: #373737;
  cursor: pointer;

  ${props => {
    if (props.checked) {
      return `
        background-color: #D2E4ED;
      `;
    }
  }};
`;

const InputLabel = styled.label`
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  margin-bottom: 0;
  padding: 0.5625rem 0;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 120%;
  cursor: pointer;
  user-select: none;

  span {
    margin-bottom: 1.5px;
  }
`;

const Input = styled.input`
  width: 18px;
  height: 18px;
  border: 2px solid #446372;
  border-radius: 1px;
  accent-color: #446372;
  transform: translateY(-1px);

  &:focus {
    outline: none;
  }

  &:focus-visible {
    outline: 2px solid #255a94;
    outline-offset: 1px;
  }
`;

export default function ProfileCheckboxInput({ id, label, value, checked, handleOnChange }) {
  return (
    <InputContainer checked={checked}>
      <InputLabel htmlFor={id}>
        <Input id={id} value={value} checked={checked} type="checkbox" name={id} onChange={handleOnChange} />
        <span>{label}</span>
      </InputLabel>
    </InputContainer>
  );
}

ProfileCheckboxInput.propTypes = {
  id: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]).isRequired,
};
