import React from 'react';
import Grow from '@material-ui/core/Grow';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import { withStyles } from '@material-ui/core/styles';
import styled, { ThemeProvider } from 'styled-components';
import { themeVia } from '../../styledComponents/settings/theme';
import TextInput from '../../styledComponents/styles/TextInput.styled';

function debounce(func, wait, immediate) {
  let timeout;
  return function() {
    let context = this,
      args = arguments;
    let later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    let callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

const ClearDiv = styled.div`

  &:hover {
    color: #373737;
  },
`;

const InputDiv = styled.div`
  width: 400px;
`;

const MainDiv = styled.div`
  display: flex;
  flex: 1 0 auto;
  align-items: center;
`;

const defaultStyles = theme => ({});

class _DebounceTableSearch extends React.Component {
  componentDidMount() {
    document.addEventListener('keydown', this.onKeyDown, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown, false);
  }

  onKeyDown = event => {
    if (event.keyCode === 27) {
      this.props.onHide();
    }
  };

  handleTextChangeWrapper = debouncedSearch => event => {
    debouncedSearch(event.target.value);
  };

  render() {
    const { classes, options, onHide, searchText, debounceWait } = this.props;

    const debouncedSearch = debounce(value => {
      this.props.onSearch(value);
    }, debounceWait);

    return (
      <ThemeProvider theme={themeVia}>
        <Grow appear in timeout={300}>
          <MainDiv>
            <InputDiv>
              <TextInput
                id={'textSearch'}
                icon={'Search'}
                className={classes.searchText}
                autoFocus={true}
                defaultValue={searchText}
                onChange={this.handleTextChangeWrapper(debouncedSearch)}
                placeholder={options.searchPlaceholder}
                {...(options.searchProps || {})}
              />
            </InputDiv>
            <IconButton onClick={onHide}>
              <ClearDiv>
                <ClearIcon />
              </ClearDiv>
            </IconButton>
          </MainDiv>
        </Grow>
      </ThemeProvider>
    );
  }
}

let DebounceTableSearch = withStyles(defaultStyles, { name: 'MUIDataTableSearch' })(_DebounceTableSearch);
export { DebounceTableSearch };

export function debounceSearchRender(debounceWait = 200) {
  return (searchText, handleSearch, hideSearch, options) => {
    return (
      <DebounceTableSearch
        searchText={searchText}
        onSearch={handleSearch}
        onHide={hideSearch}
        options={options}
        debounceWait={debounceWait}
      />
    );
  };
}

export const DEBOUNCE_VALUE = 500;
