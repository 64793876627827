exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ":root{--dark:var(--theme_color_dark);--accent:var(--theme_color_accent);--ocean:var(--theme_color_light)}._3YFVD{left:50%;-webkit-transform:translate(-50%) scale(0);-ms-transform:translate(-50%) scale(0);transform:translate(-50%) scale(0);position:absolute;border:5px solid var(--theme_color_accent);border:5px solid var(--accent,#dd2160);background:#fff;border-radius:4px;-webkit-box-shadow:0 1px 3px 0 #dcdcdc;box-shadow:0 1px 3px 0 #dcdcdc;z-index:2;-webkit-box-sizing:border-box;box-sizing:border-box}._3YFVD:after,._3YFVD:before{top:100%;left:50%;border:solid transparent;content:\" \";height:0;width:0;position:absolute;pointer-events:none}._3YFVD:after{border-color:hsla(0,0%,100%,0);border-top-color:var(--theme_color_accent);border-top-color:var(--accent,#dd2160);border-width:8px;margin-left:-4px}._3YFVD:before{border-color:hsla(0,0%,87%,0);border-top-color:var(--theme_color_accent);border-top-color:var(--accent,#dd2160);border-width:10px;margin-left:-6px}", ""]);

// exports
exports.locals = {
	"toolbar": "_3YFVD"
};