import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import WarningIcon from '../../icons/WarningIcon';
import SimpleClockIcon from '../../icons/SimpleClockIcon';

const Container = styled.div`
  display: ${({ shouldShow }) => (shouldShow ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  gap: 0.546875rem;
  padding: 0.5rem;
  border-radius: 20px 20px 0px 0px;
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  letter-spacing: 0.0015em;
  line-height: 120%;

  ${props => {
    switch (true) {
      case props.isPast && props.programType !== 'accepted' && !props.successStatus:
        return `
          background-color: #D7D7D7;
          color: #373737;
        `;
      case props.programType === 'accepted':
        return `
          background-color: #DAFDD7;
          color: #0E5B2F;
        `;
      case !props.isPast && !props.successStatus && props.withinOneWeek:
        return `
          background-color: #FFDEDB;
          color: #8B1313;
        `;
      case !props.isPast && !props.successStatus && props.withinTwoWeeks:
        return `
          background-color: #DEECFF;
          color: #255A94;
        `;
      default:
        return ``;
    }
  }};
`;

const CardBanner = ({ programType, date, status, isAdmin }) => {
  const {
    customAliases: { 
      alias_traveler: travelerAlias,
    },
  } = useSelector(state => state.currentOrganization);
  const formattedDate = date ? moment(date).format('MMM D, YYYY') : '';
  const isPast = date ? moment(date).isBefore(moment()) : false;
  const withinOneWeek = date ? moment().add(7, 'days').isAfter(date) : false;
  const withinTwoWeeks = date ? moment(date).isBetween(moment().add(8, 'days'), moment().add(14, 'days')) : false;
  const greaterThanTwoWeeks = date ? moment(date).isAfter(moment().add(14, 'days')) : false;
  const shouldShow = status === 'Accepted' || (status === 'Incomplete' && date && !greaterThanTwoWeeks);
  const successStatus = ['Committed'].includes(status);
  
  let lateString = '';
  let commitString = '';
  if (!['Committed', 'Accepted'].includes(status) && date) {
    if (isPast) {
      lateString = 'Your application deadline has passed';
    } else {
      lateString = `${programType} Deadline: ${formattedDate}`;
    }
  }

  if ('Accepted' === status) {
    if (isAdmin) {
      commitString = `View to Commit on the ${travelerAlias}'s behalf`;
    } else {
      commitString = `You've been accepted! View & Commit`;
    }
  }

  return (
    <Container
      shouldShow={shouldShow}
      withinOneWeek={!successStatus && withinOneWeek}
      withinTwoWeeks={!successStatus && withinTwoWeeks}
      isPast={!successStatus && isPast}
      programType={programType}
      successStatus={successStatus}
    >
      {!successStatus && !isPast && withinOneWeek && <WarningIcon />}
      {!successStatus && !isPast && withinTwoWeeks && <SimpleClockIcon />}
      <span>
        {status === 'Accepted'
          ? commitString
          : status !== 'Committed' && lateString}
      </span>
    </Container>
  );
}

CardBanner.propTypes = {
  date: PropTypes.string,
  status: PropTypes.string,
  programType: PropTypes.oneOf(['Application', 'Form', 'accepted', '']),
  isAdmin: PropTypes.bool,
};

CardBanner.defaultProps = {
  date: '',
  status: '',
  programType: '',
  isAdmin: false,
};

export default CardBanner;
