import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { fetchAuthorizedProgramSettings } from '../../../../actions/orgSettingsActions';
import propTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import sFormReportsSubHeader from './formReportsSubHeader.scss';

function FormReportsSubHeader(props) {
  const dispatch = useDispatch();
  const customAliases = useSelector(state => state.profile.customAliases);
  const freemium = useSelector(state => state.profile.freemium);
  const userId = useSelector(state => state.profile.currentUser.id);

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      dispatch(fetchAuthorizedProgramSettings(userId));
    }
    return () => {
      unmounted = true;
    };
  }, []);

  return (
    <>
      <div className={sFormReportsSubHeader.subheader}>
        {!props.traveler ? (
          <div className={sFormReportsSubHeader['subheader-content']}>
            <div className={sFormReportsSubHeader.item}>
              <NavLink
                className={sFormReportsSubHeader.selection}
                // to={!props.betaLinks ? '/client/traveler-forms-dashboard' : '/client/traveler-forms-dashboard-beta'}
                to={'/client/traveler-forms-dashboard'}
                activeClassName={sFormReportsSubHeader.selected}
              >
                {customAliases.alias_traveler} Forms
              </NavLink>
            </div>
            {!freemium ? (
              <div className={sFormReportsSubHeader.item}>
                <NavLink
                  className={sFormReportsSubHeader.selection}
                  // to={!props.betaLinks ? '/client/forms-status-dashboard' : '/client/forms-status-dashboard-beta'}
                  to={'/client/forms-status-dashboard'}
                  activeClassName={sFormReportsSubHeader.selected}
                >
                  Form Completion
                </NavLink>
              </div>
            ) : null}
            <div className={sFormReportsSubHeader.item}>
              <NavLink
                className={sFormReportsSubHeader.selection}
                // to={!props.betaLinks ? '/client/form-content-dashboard' : '/client/form-content-dashboard-beta'}
                to={'/client/form-content-dashboard'}
                activeClassName={sFormReportsSubHeader.selected}
              >
                Form Content
              </NavLink>
            </div>
          </div>
        ) : (
          <div className={sFormReportsSubHeader['subheader-content']} />
        )}
      </div>
    </>
  );
}

FormReportsSubHeader.defaultProps = {
  betaLinks: false,
};

FormReportsSubHeader.propTypes = {
  traveler: propTypes.bool.isRequired,
  betaLinks: propTypes.bool,
};

export default withStyles(sFormReportsSubHeader)(FormReportsSubHeader);
