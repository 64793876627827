exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".zG-jc{margin:30px;min-height:1200px}._2kgY4{border-bottom:1px solid #f0f0f0;border-top:none;border-left:none;border-right:none;max-width:600px;margin-top:60px}", ""]);

// exports
exports.locals = {
	"assignedAdmins": "zG-jc",
	"select": "_2kgY4"
};