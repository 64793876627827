import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import _omit from 'lodash/omit';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import propTypes from 'prop-types';
import classnames from 'classnames';
import ViaIcon from '../../../plans/components/ViaIcon';
import sInputText from './inputText.scss';

export const enhance = compose(withStyles(sInputText));

function InputText(props) {
  const [ariaLabel, setAriaLabel] = useState('');
  const isError = props.errorMessage.length > 0;
  const inputClass = classnames(
    sInputText['input-text'],
    sInputText[props.size],
    isError ? sInputText['error'] : '',
    props.greenCheck ? sInputText['greenBorder'] : '',
  );
  const helpLabelClass = classnames(
    sInputText['label-text'],
    //isError ? sInputText['error'] : '',
    sInputText[props.labelSize],
  );
  const maxlength = props.maxlength;

  useEffect(() => {
    const defineAria = props['aria-label'] || props.labelText || 'Text input';
    setAriaLabel(defineAria);
  }, [props]);

  return (
    <>
      {!!props.labelText && (
        <label className={helpLabelClass} htmlFor={props.id}>
          {props.labelText} {props.isRequired ? <div style={{ color: '#f44831', display: 'inline' }}>*</div> : ''}
        </label>
      )}
      {!!props.subLabelText && <div className={sInputText['sub-label-text']}>{props.subLabelText}</div>}
      {!props.searchBar && !props.checkInput ? (
        <input
          aria-label={ariaLabel}
          {..._omit(props, [
            'errorMessage',
            'labelText',
            'helpText',
            'isRequired',
            'labelSize',
            'searchBar',
            'searchClear',
            'hourGlassCustom',
            'subLabelText',
            'greenCheck',
            'checkInput',
          ])}
          autoComplete={props?.autocomplete ? props?.autocomplete : "off"}
          className={inputClass}
          maxLength={maxlength ? maxlength : null}
        />
      ) : props.checkInput ? (
        <div className={sInputText['checkWrapper']}>
          <div className={sInputText['checkInputWrapper']}>
            <input
              aria-label={ariaLabel}
              {..._omit(props, [
                'errorMessage',
                'labelText',
                'helpText',
                'isRequired',
                'labelSize',
                'searchBar',
                'searchClear',
                'hourGlassCustom',
                'subLabelText',
                'greenCheck',
                'checkInput',
              ])}
              className={inputClass}
              type="text"
              maxLength={maxlength ? maxlength : null}
            />
          </div>
          <div className={sInputText['checkMarkWrapper']}>
            <div className={sInputText['checkMark']}>
              {props.greenCheck && <ViaIcon name="checkMarkSolid" color="green" size="small" />}
            </div>
          </div>
        </div>
      ) : (
        <div className={sInputText['searchBarWrapper']}>
          <div className={sInputText['searchWrapper']}>
            <input
              className={sInputText['searchInput']}
              aria-label={ariaLabel}
              {..._omit(props, [
                'errorMessage',
                'labelText',
                'helpText',
                'isRequired',
                'labelSize',
                'searchBar',
                'searchClear',
                'hourGlassCustom',
                'subLabelText',
                'greenCheck',
                'checkInput',
              ])}
              type="text"
              value={props.value}
            />
            <span className={sInputText['searchClear']} onClick={() => props.searchClear()}>
              x
            </span>
          </div>
          <div className={sInputText['hourglassWrapper']}>
            <div
              className={props.hourGlassCustom ? sInputText['searchHourglassCustom'] : sInputText['searchHourglass']}
            >
              <ViaIcon name="search" color="white" size="small" />
            </div>
          </div>
        </div>
      )}

      {!!props.helpText && !isError && <span className={sInputText['help-text']}>{props.helpText}</span>}
      {isError && <span className={sInputText['error-text']} aria-live='assertive'>{props.errorMessage}</span>}
    </>
  );
}

InputText.propTypes = {
  type: propTypes.string,
  errorMessage: propTypes.string,
  labelText: propTypes.string,
  helpText: propTypes.string,
  placeholder: propTypes.string,
  isRequired: propTypes.bool,
  size: propTypes.oneOf(['medium', 'normal', 'addTermModal', 'dashboardSaveName']),
  labelSize: propTypes.oneOf(['medium', 'normal']),
  searchBar: propTypes.bool,
  searchClear: propTypes.func,
  hourGlassCustom: propTypes.bool,
  subLabelText: propTypes.string,
  greenCheck: propTypes.bool,
  checkInput: propTypes.bool,
};

InputText.defaultProps = {
  type: 'text',
  errorMessage: '',
  labelText: '',
  helpText: '',
  placeholder: '',
  isRequired: false,
  size: 'normal',
  labelSize: 'medium',
  searchBar: false,
  hourGlassCustom: false,
  subLabelText: '',
  greenCheck: false,
  checkInput: false,
};

export default enhance(InputText);
