import React, { useState, useEffect } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { useDispatch, useSelector } from 'react-redux';
import propTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import _find from 'lodash/find';
import _get from 'lodash/get';
import _groupBy from 'lodash/groupBy';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import Arrow from '../../../../../../public/img/arrow.svg';
import Headline from '../../../../components/library/headline';
import sAccordion from '../../../../../../public/css/accordion.scss';
import Paragraph from '../../../../components/library/paragraph';
import { getPlan } from '../../../../../actions/plans';
import TransportationItemView from '../../itineraryItems/transportationItemView';
import HousingItemView from '../../itineraryItems/housingItemView';
import ActivityItemView from '../../itineraryItems/activityItemView';
import ViaDate from '../../../../components/library/viaDate';
import sTravelerItinerary from './travelerItinerary.scss';

export const enhance = compose(
  withStyles(sTravelerItinerary, sAccordion),
  withRouter,
);

function TravelerItinerary(props) {
  const dispatch = useDispatch();
  const selectedPlan = useSelector(state => state.plans.plan);
  const backTo = _get(props.history.location.state, 'backTo') || `admin-plan-overview/${props.planId}`;
  const [transportationItems, setTransportationItems] = useState();
  const [housingItems, setHousingItems] = useState();
  const [activityItems, setActivityItems] = useState();
  const [selectTraveler, setSelectTraveler] = useState();
  const [selectTravelerName, setSelectTravelerName] = useState('');
  const [allLocs, setAllLocs] = useState('');

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      dispatch(getPlan(props.planId));
    }

    return () => {
      unmounted = true;
    };
  }, []);

  useEffect(
    () => {
      if (!!selectedPlan && !!selectedPlan.travelers) {
        setSelectTraveler(
          _find(selectedPlan.travelers, traveler => {
            return traveler.plans_user_id.toString() === props.planUserId.toString();
          }),
        );
      }

      if (!!selectedPlan && !!selectedPlan.plan) {
        let locsString = '';
        if (selectedPlan.plan.attributes.locations && selectedPlan.plan.attributes.locations.length > 0) {
          selectedPlan.plan.attributes.locations.map(loc => {
            locsString = locsString + loc.locality + ', ' + loc.country_common_name + '; ';
          });
          locsString = locsString.slice(0, -2);
        }
        setAllLocs(locsString);
      }
    },
    [selectedPlan],
  );

  useEffect(
    () => {
      if (!!selectTraveler) {
        const traveler_itinerary_byType = _groupBy(selectTraveler.itinerary, item => item.type);
        setTransportationItems(_get(traveler_itinerary_byType, 'plan_transportation') || null);
        setHousingItems(_get(traveler_itinerary_byType, 'plan_housing') || null);
        setActivityItems(_get(traveler_itinerary_byType, 'plan_activity') || null);

        let name = `${selectTraveler.first_name ? selectTraveler.first_name : ''} ${
          selectTraveler.last_name ? selectTraveler.last_name : ''
        }`;
        name = name.trim().length === 0 ? selectTraveler.email : name;
        setSelectTravelerName(name);
      }
    },
    [selectTraveler],
  );

  return (
    <div className={sTravelerItinerary['traveler-itinerary']}>
      {!!selectedPlan &&
        !!selectedPlan.plan &&
        !!selectTraveler && (
          <>
            <div className={sTravelerItinerary['header-info-wrapper']}>
              <div
                className={sTravelerItinerary['header-info-back']}
                onClick={() => {
                  props.history.push(`/plans/${backTo}`, { tab: 'planTravelers' });
                }}
              >
                <img alt="back arrow" src={Arrow} className={sTravelerItinerary.backButton} />
              </div>

              <div className={sTravelerItinerary['header-info-content']}>
                <div className={sTravelerItinerary['header-info-title']}>
                  <Headline tag="h1" as="h1">{`${selectTravelerName} Itinerary`}</Headline>
                </div>
                <div className={sTravelerItinerary['header-info-details-wrapper']}>
                  <div className={sTravelerItinerary['header-info-details-block']}>
                    <Paragraph size="large">{selectedPlan.plan.attributes.name}</Paragraph>
                    <Paragraph size="large">
                      <ViaDate date={selectedPlan.plan.attributes.start_date} />
                      &nbsp;-&nbsp;
                      <ViaDate date={selectedPlan.plan.attributes.end_date} />
                    </Paragraph>
                    <Paragraph size="large">{allLocs}</Paragraph>
                    <Paragraph size="large">{selectedPlan.plan.attributes.plan_type_name}</Paragraph>
                  </div>
                  <div className={sTravelerItinerary['header-info-details-block']} />
                </div>
              </div>
            </div>

            <div className={sTravelerItinerary['accordion-wrapper']}>
              <Accordion
                allowMultipleExpanded
                allowZeroExpanded
                preExpanded={['transportation', 'activity', 'housing']}
              >
                <AccordionItem uuid="transportation">
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <div className="wrapper-line-center">
                        <Headline tag="h2">
                          <span className="line-center">
                            <Headline tag="h2">Transportation&nbsp;&nbsp;</Headline>
                          </span>
                        </Headline>
                      </div>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    {!transportationItems && (
                      <Paragraph italic size="large">
                        Traveler has no transporation details.
                      </Paragraph>
                    )}
                    {!!transportationItems &&
                      transportationItems.map(detail => (
                        <TransportationItemView
                          key={detail.id}
                          detail={detail}
                          planId={props.planId}
                          hideEdit
                          hideRemove
                        />
                      ))}
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem uuid="housing">
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <div className="wrapper-line-center">
                        <Headline tag="h2">
                          <span className="line-center">
                            <Headline tag="h2">Housing&nbsp;&nbsp;</Headline>
                          </span>
                        </Headline>
                      </div>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    {!housingItems && (
                      <Paragraph italic size="large">
                        Traveler has no housing details.
                      </Paragraph>
                    )}
                    {!!housingItems &&
                      housingItems.map(detail => (
                        <HousingItemView key={detail.id} detail={detail} planId={props.planId} hideEdit hideRemove />
                      ))}
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem uuid="activity">
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <div className="wrapper-line-center">
                        <Headline tag="h2">
                          <span className="line-center">
                            <Headline tag="h2">Activities&nbsp;&nbsp;</Headline>
                          </span>
                        </Headline>
                      </div>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    {!activityItems && (
                      <Paragraph italic size="large">
                        Traveler has no activity details.
                      </Paragraph>
                    )}
                    {!!activityItems &&
                      activityItems.map(detail => (
                        <ActivityItemView key={detail.id} detail={detail} planId={props.planId} hideEdit hideRemove />
                      ))}
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
            </div>
          </>
        )}
    </div>
  );
}

TravelerItinerary.propTypes = {
  planId: propTypes.string.isRequired,
  planUserId: propTypes.string.isRequired,
};

export default enhance(TravelerItinerary);
