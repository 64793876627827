exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1-LY6{padding:30px 45px}._1--B0{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row}.bVdZ8{font-weight:400}._35HBi,.bVdZ8{font-family:Open Sans,sans-serif;font-size:16px;line-height:22.4px;letter-spacing:.5%}._35HBi{font-weight:700;margin-top:20px}._35HBi a,._35HBi a:hover{color:#255a94}._3GTow{font-family:Open Sans;font-size:16px;font-weight:400;line-height:22.4px;letter-spacing:.005em;text-align:left}._1dWCe{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-pack:right;justify-content:right;gap:20px;background:#ebebeb;padding:30px;border-bottom-left-radius:4.8px;border-bottom-left-radius:.3rem;border-bottom-right-radius:4.8px;border-bottom-right-radius:.3rem}._2pnpe{padding:0}", ""]);

// exports
exports.locals = {
	"container": "_1-LY6",
	"modalBodycontainer": "_1--B0",
	"colspancontent": "bVdZ8",
	"colpcontent": "_35HBi",
	"text": "_3GTow",
	"footer": "_1dWCe",
	"modalBody": "_2pnpe"
};