import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import styles from './index.module.css';
import TextButton from '../../TextButton';
import PrimaryButton from '../../PrimaryButton';

function BaseModalFooter({ mainBtnType = "button", 
    mainBtnText = "", 
    cancelBtnText = "Cancel", 
    loadingBtn = false, 
    isBtnGroupRight = false, 
    onCancel, 
    onSubmit, 
    isDeleteButton = false,
    handleDeleteModal
  }) {
  return (
    <div className={`${styles.footer} ${isDeleteButton ?  styles.buttonsSpaceBetween : isBtnGroupRight ? styles.buttonsRight : styles.buttonsCenter}`}>
      {isDeleteButton && 
        <div className={styles.deleteButtonContainer}>
          <a href="#" onClick={(e)=>{
            e.preventDefault();
            handleDeleteModal()
            }
          } className={styles.deleteBtton}>Delete Form</a>
        </div>
      }
      <div className={styles.buttonContainer}>
        <TextButton text={cancelBtnText} type="button" onClick={onCancel} />
        <PrimaryButton text={mainBtnText} loading={loadingBtn} type={mainBtnType} onClick={onSubmit} />
      </div>
    </div>
  );
}

export default withStyles(styles)(BaseModalFooter);
