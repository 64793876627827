exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ":root{--dark:var(--theme_color_dark);--accent:var(--theme_color_accent);--ocean:var(--theme_color_light);--button-border:var(--theme_border_color)}.XBWpw{width:210px;background-color:var(--theme_color_light);background-color:var(--ocean,#76ccce);border:6px solid hsla(0,0%,100%,.25);text-transform:uppercase;color:#fff;margin-top:20px;cursor:pointer;font-family:Intro,sans-serif;font-size:16px;font-weight:700;line-height:2.17;letter-spacing:1.7px;text-align:center;display:block}", ""]);

// exports
exports.locals = {
	"cyanButton": "XBWpw"
};