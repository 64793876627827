import React, { Component } from 'react'
import { connect } from 'react-redux'
import _isEmpty from 'lodash/isEmpty'
import Loader from '../../components/Loader/Loader'

class Ability extends Component {
  state = {
    permissions: { ...this.props.permissions },
    currentUser: { ...this.props.currentUser }
  };

  shouldHide = () => (this.props.hideUntilResolved && this.props.hideUntilResolved !== 'false');

  ableToAccess = (module, action) => {
    const { permissions, currentUser } = this.state
    return (
      _isEmpty(currentUser) ? this.props.currentUser['client_user_role?'] : currentUser['client_user_role?']
    ) && (
      _isEmpty(permissions) ? this.props.permissions[module].includes(action) : permissions[module].includes(action))
    // return true
  };

  render () {
    const { module, action, currentUser } = this.props

    const greyedOut = React.Children.map(this.props.children, child => (
      child && child.props && child.props.disable ? React.cloneElement(child) : null))
    const activeChildren = React.Children.map(this.props.children, child => (
      child && child.props && child.props.disable === undefined ? React.cloneElement(child) : null))
    return (
      <>
        {Object.keys(currentUser).length > 0
          ? (this.ableToAccess(module, action) ? activeChildren : greyedOut)
          : (this.shouldHide() ? <Loader /> : greyedOut)}
      </>
    )
  }
}

const mapStateToProps = state => ({
  permissions: state.profile.permissions,
  currentUser: state.profile.currentUser
})

export default connect(
  mapStateToProps,
  {}
)(Ability)
