import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import { connect } from 'react-redux';
import { fetchTravelerNotes } from '../../../actions/profileActions';
import { compose } from 'redux';
import { withCookies, Cookies } from 'react-cookie';

import TravelerNotes from './TravelerNotes/TravelerNotes';

class Notes extends React.Component {
  render() {
    return (
      <div>
        <TravelerNotes
          id={this.props.id}
          role="Admin"
          currentUser={this.props.currentUser}
          notes={this.props.notes}
          newNote={this.props.newNote}
          notesLoading={this.props.notesLoading}
          currentUserAvatar={this.props.currentUserAvatar}
        />
      </div>
    );
  }
}
const mapStateToProps = state => ({
  notes: state.profile.notes,
});

export default compose(
  withCookies,
  connect(
    mapStateToProps,
    {
      fetchTravelerNotes,
    },
  ),
)(Notes);
