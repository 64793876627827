import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import Select, { components } from 'react-select';
import StyledIcon from './Icon.styled';
import Tooltip from './Tooltip.styled';
import { stripHtmlString } from '../../shared/helpers/General';
import ViaIcon from '../../sites/plans/components/ViaIcon';

//If you need to hide the selected value in the action dropdown pass the below in your component
// hideSelectedOptions={true}
// controlShouldRenderValue={false}

const openSansFont = css`
  font-family: ${({ theme }) => theme.textInput.fontFamilyOpenSans};
`;

const Error = styled.div`
  color: ${({ theme }) => theme.selectOption.error.fontColor};
  display: inline-block;
  font-family: ${({ theme }) => theme.selectOption.error.fontFamily};
  font-size: ${({ theme }) => theme.selectOption.error.fontSize};
  font-weight: ${({ theme }) => theme.selectOption.error.fontWeight};
  padding-top: 3px;
  vertical-align: middle;

  ${({ openSans }) => (openSans ? openSansFont : null)} svg {
    justify-content: center;
    margin-right: 5px;

    vertical-align: middle;
    display: inline-block;
  }

  span {
    vertical-align: middle;
    display: inline-block;
  }
`;
Error.displayName = 'Error';

const Instructions = styled.div`
  color: ${({ theme }) => theme.textInput.instructions.fontColor};
  font-family: ${({ theme }) => theme.textInput.instructions.fontFamily};
  font-size: ${({ theme }) => theme.textInput.instructions.fontSize};
  font-weight: ${({ theme }) => theme.textInput.instructions.fontWeight};
  padding-left: 5px;
  p {
    margin-bottom:0px;
  }
  overflow-wrap: anywhere;
  ${({ openSans }) => (openSans ? openSansFont : null)};
`;
Instructions.displayName = 'Instructions';

const Required = styled.span`
  color: ${({ theme }) => theme.defaults.error.fontColor};
  padding-left: 5px;
`;
Required.displayName = 'Required';

const TooltipContainer = styled.span`
  padding-left: 5px;
`;
TooltipContainer.displayName = 'TooltipContainer';

const SelectGroup = styled.div`
  display: ${props => (props.isActionList ? 'inline-block' : 'flex')};
  flex-direction: ${props => (props.isActionList ? 'inherit' : 'column')};
  position: relative;
  width:${props => (props.customWidth ? '125px' : '')};
`;
SelectGroup.displayName = 'SelectGroup';

const SelectLabel = styled.label`
  font-family: ${({ theme }) => theme.selectOption.label.fontFamily};
  font-size: ${({ theme }) => theme.selectOption.label.fontSize};
  font-weight: ${({ theme }) => theme.selectOption.label.fontWeight};
  ${({ openSans }) => (openSans ? openSansFont : null)};
  ${({ fontColor }) => fontColor ? `color: ${fontColor}` : `color: ${({ theme }) => theme.selectOption.label.fontColor};`};
  ${({ fontWeight }) => (fontWeight ? `font-weight: ${fontWeight}` : 500)};
`;
SelectLabel.displayName = 'SelectLabel';

const ActionList = styled.div`
  display: inline-block;
  width: ${props => (props.width ? props.width : '200px')};
`;
ActionList.displayName = 'ActionList';

const customStyles = {
  container: provided => ({
    ...provided,
    flexGrow: '1',
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isDisabled ? '#6d6d6d' : '#373737',
    cursor: state.isDisabled ? 'not-allowed' : 'pointer',
    fontStyle: state.isDisabled ? 'italic' : 'normal',
    backgroundColor: !state.isDisabled && (state.isFocused || state.isSelected) ? '#ebebeb' : '#ffffff',

    '&:hover': {
      backgroundColor: '#ebebeb',
    },
  }),
  indicatorSeparator: provided => ({
    ...provided,
    display: 'none',
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: !state.isDisabled ? '#6d6d6d' : '#666',
  }),
  menu: provided => ({
    ...provided,
    zIndex: 9999,
    marginTop: '0',
    fontSize: '14px',
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  singleValue: provided => ({
    ...provided,
    color: '#373737',

    disabled: {
      color: '#373737',
      backgroundColor: '#F6F7F9',
      cursor: 'not-allowed',
    },
  }),
  multiValue: styles => ({
    ...styles,
    backgroundColor: '#D2E4ED',
    borderRadius: '50px',
    fontFamily: "'Open Sans', sans-serif",
    fontWeight: '600',
    fontSize: '0.875rem',
    color: '#373737',
    lineHeight: '120%',
  }),
};

const DropdownSelect = ({
  defaultValue,
  id,
  options,
  value,
  instructions,
  onChange,
  isActionList = false,
  isDisabled = false,
  readOnly,
  isSynced = false,
  showOriginalIndicator=false,
  isMulti = false,
  maxMenuHeight = '400px',
  width,
  minHeight = "44px",
  required,
  ...props
}) => {
  if (props.openSans) {
    customStyles.container = (provided, { isDisabled }) => ({
      ...provided,
      color: isDisabled ? '#555' : '#373737',
      backgroundColor: isDisabled ? '#E7E7E7' : '#F6F7F9',
      fontSize: '14px',
      fontWeight: 400,
      fontFamily: "'Open Sans', sans-serif",
      lineHeight: '140%',
    });

    customStyles.placeholder = (provided, { isDisabled }) => ({
      ...provided,
      color: isDisabled ? '#6d6d6d' : '#555',
      fontSize: '14px',
      fontWeight: 400,
      fontFamily: "'Open Sans', sans-serif",
      lineHeight: '140%',
      fontStyle: 'italic',
    });
  }

  if (isActionList) {
    if (!props.openSans) {
      customStyles.placeholder = (provided, { isDisabled }) => ({
        ...provided,
        color: isDisabled ? '#6d6d6d' : '#373737',
        fontWeight: 600,
      });
    }

    customStyles.control = (provided, { isDisabled }) => ({
      ...provided,
      backgroundColor: isDisabled ? '#E7E7E7' : '#F6F7F9',
      borderColor: isDisabled ? '#BBB' : '#656565',
      color: isDisabled ? '#666' : '#6d6d6d',
      cursor: isDisabled ? 'not-allowed' : 'default',
      fill: isDisabled ? '#666' : '#6d6d6d',
      minHeight: minHeight,
    });
  } else {
    if (!props.openSans) {
      customStyles.placeholder = (provided, { isDisabled }) => ({
        ...provided,
        color: isDisabled ? '#6d6d6d' : '#373737',
        fontSize: '14px',
        fontStyle: isDisabled ? 'normal' : 'italic',
      });
    }
    customStyles.control = (provided, { isDisabled }) => ({
      ...provided,
      backgroundColor: isDisabled ? '#E7E7E7' : '#fff',
      borderColor: '#bbb',
      color: isDisabled ? '#555' : '#373737',
      cursor: isDisabled ? 'not-allowed' : 'default',
      minHeight: minHeight,
    });
    customStyles.indicatorContainer = provided => ({
      ...provided,
      display: 'none',
    });
    customStyles.noOptionsMessage = provided => ({
      ...provided,
    });
  }

  const [fontColor, setFontColor] = useState(props.fontColor);
  const [fontWeight, setFontWeight] = useState(props.fontWeight);

  let cleanseInstructions = null;
  if (instructions && stripHtmlString(instructions).trim().length > 0) {
    const updatedIntro = !!instructions ? instructions.replace(/<p><\/p>/g, '<br />') : '';
    cleanseInstructions = updatedIntro;
  }

  const DisableIcon = () => {
    return <StyledIcon type="LockClosed" size="16px" />;
  };

  const SyncIcon = () => {
    return <StyledIcon type="Sync" size="16px" />;
  };

  const DropdownIndicator = props => {
    if (isSynced) {
      return (
        <components.DropdownIndicator {...props}>
          <SyncIcon />
        </components.DropdownIndicator>
      );
    } 
    else if (showOriginalIndicator) {
      return (
        <components.DropdownIndicator {...props}><ViaIcon name="chevronDown" size="xsmall" /></components.DropdownIndicator>
      )
    }
    else {
      return (
        <components.DropdownIndicator {...props}>{props.isDisabled && <DisableIcon />}</components.DropdownIndicator>
      );
    }
  };

  const CustomNoOptionsMessage = () => {
    return (
      <div className="custom-no-options-message" style={{
        display: "flex",
        height: "36px",
        padding: "14px 12px 12px 8px",
        justifyContent: "center",
        alignItems: "center",
        gap: "12px",
        alignSelf: "stretch",
      }}>
        {props.noOptionLabel ?  props.noOptionLabel : "No Options" }
      </div>
    );
  };

  const selectComponent = (
    <Select
      aria-label={props.label || 'Please select an option'}
      aria-invalid={props?.errorMsg?.length > 0 ? 'true' : 'false'}
      components={{ 
        NoOptionsMessage: () => <CustomNoOptionsMessage  />,
        DropdownIndicator 
      }}
      id={`dropdown-${id}`}
      inputId={`dropdown-input-${id}`}
      aria-live="assertive"
      valueKey={id}
      styles={customStyles}
      maxMenuHeight={maxMenuHeight}
      aria-labelledby={`dropdown-select-label-${id}`}
      value={value}
      onChange={e => {
        if (!isMulti) {
          onChange(e.value);
        } else {
          let valueIds = [];
          if (e) {
            valueIds = e.map(item => {
              return item.value;
            });
          }
          onChange(valueIds);
        }
      }}
      isDisabled={isDisabled || readOnly}
      isSearchable={!isActionList}
      defaultValue={defaultValue}
      options={options}
      tabIndex="0"
      isMulti={isMulti}
      menuPortalTarget={document.body}
      {...props}
    />
  );

  return (
    <SelectGroup customWidth={props.customWidth} isActionList={isActionList}>
      {props.label && (
        <SelectLabel
          htmlFor={`dropdown-input-${id}`}
          id={`dropdown-select-label-${id}`}
          openSans
          fontColor={fontColor}
          fontWeight={fontWeight}
        >
          {props.label}
          {required && <Required>*</Required>}
          {props.tooltip && (
            <TooltipContainer>
              <Tooltip toolTipText={props.tooltip}>
                <StyledIcon type="InformationCircle" size="14px" />
              </Tooltip>
            </TooltipContainer>
          )}
        </SelectLabel>
      )}
      {cleanseInstructions?.length > 0 && (
        <Instructions
          openSans
          dangerouslySetInnerHTML={{
            __html: cleanseInstructions,
          }}
        />
      )}
      {isActionList ? <ActionList width={width}>{selectComponent}</ActionList> : <>{selectComponent}</>}
      {props?.errorMsg?.length > 0 && (
        <Error openSans>
          <StyledIcon type="Warning" size="16px" />
          <span>{props.errorMsg}</span>
        </Error>
      )}
    </SelectGroup>
  );
};
DropdownSelect.displayName = 'DropdownSelect';

export default DropdownSelect;
