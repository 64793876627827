import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { TermInfoCardInfo } from './Term.styled';

export default function Deadline({ deadline, timezone, prefix }) {
  return (
    <>
      {deadline &&
        timezone && (
          <TermInfoCardInfo>
            <p className="label">
              {prefix ? `${prefix} ` : ''}
              Application Deadline:
            </p>
            <p className="text">
              {moment(deadline)
                .tz(timezone)
                .format('MMMM D, YYYY h:mma')}{' '}
              ({timezone})
            </p>
          </TermInfoCardInfo>
        )}
    </>
  );
}

Deadline.propTypes = {
  prefix: PropTypes.string,
  deadline: PropTypes.string,
  timezone: PropTypes.string,
};
