const scriptInject = (src, callback, id, defer = false) => {
  if (!src) {
    console.error('src is required when injecting a script file');
    return false;
  }
  const existingScript = document.querySelector(`[src='${src}']`);
  if (!existingScript) {
    const script = document.createElement('script');
    script.setAttribute('type', 'text/javascript');

    if (id) {
      script.setAttribute('id', id);
    }
    if (defer) {
      script.setAttribute('defer', defer);
    }
    script.setAttribute('src', src);

    document.getElementsByTagName('body')[0].appendChild(script);

    script.onload = () => {
      if (callback) {
        callback();
      }
    };
  } else if (callback) {
    callback();
  }
};
export default scriptInject;
