export const themeVia = {
  defaults: {
    body: {
      backgroundColor: '#fff',
      fontColor: '#043544',
      fontFamily: 'AvenirNextRegular, sans-serif',
      fontSize: '16px',
    },
    clientAccent: '#dd2160',
    clientAccentFont: '#fff',
    clientDark: '#043544',
    clientDarkFont: '#fff',
    clientLight: '#dd2160',
    clientLightFont: '#000',
    fontColor: '#373737',
    errorColor: '#a82425',
    error: {
      border: '1px solid #a82425',
      fontColor: '#a82425',
      fontFamily: 'AvenirNextRegular, sans-serif',
      fontFamilyOpenSans: 'Open Sans, sans-serif',
      fontSize: '14px',
      fontWeight: '400',
    },
    successColor: '#43B648',
  },
  accordionRow: {
    backgroundColor: '#fffcfc',
    backgroundColorEven: '#ebebeb',
    backgroundColorOdd: '#F7F7F7',
    border: '1px solid #ebebeb',
    fontFamily: 'AvenirNextRegular, sans-serif',
    fontSize: '16px',
    informationFontSize: '14px',
    minHeight: '200px',
    content: {
      padding: '24px',
    },
    header: {
      backgroundColor: '#ebebeb',
      fontColor: '#043544',
      fontFamily: 'AvenirNextMedium, sans-serif',
      fontSize: '14px',
      height: '44px',
      padding: '0 0 0 16px',
    },
  },
  actionButton: {
    backgroundColor: '#ffffff',
    borderRadius: '3px',
    boxShadow: '0 5px 10px 0 rgba(55, 55, 55, 0.25)',
    fontColor: '#373737',
    fontFamily: 'Open Sans, sans-serif',
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '140%',
    padding: '15px 0 15px 30px',
    height: '50px',
    width: '259px',
    active: {
      backgroundColor: '#d1dff1',
      border: '1px solid #d1dff1',
    },
    disabled: {
      backgroundColor: '#e6e6e6',
      boxShadow: 'none',
    },
    focus: {
      border: '1px solid #007bff',
      outlineColor: '#007bff',
    },
    hover: {
      backgroundColor: '#deecff',
      border: '1px solid #deecff',
    },
  },
  button: {
    backgroundColorAccent: '#D74B73',
    backgroundColor: '#ebebeb',
    backgroundColorDarkGrey: '#CCCCCC',
    backgroundColorGrey: '#EEEEEE',
    backgroundColorSecondaryDarkest: '#446372',
    borderRadius: '2px',
    fontColor: '#373737',
    fontColorWhite: '#FFFFFF',
    fontFamily: 'AvenirNextDemiBold, sans-serif',
    fontFamilyOpenSans: 'Open Sans, sans-serif',
    fontFamilyNunito: 'Nunito, sans-serif',
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '24px',
    padding: '8px 16px',
    focus: {
      outlineColor: '#007bff',
      focusBorder: '1px solid #007bff',
    },
    large: {
      fontSize: '16px',
      padding: '16px 20px',
    },
    medium: {
      padding: '8px 16px',
    },
    small: {
      padding: '4px 16px',
    },
  },
  checkbox: {
    checkedColor: '#56a3ba',
    borderColor: '#56a3ba',
    fontColor: '#373737',
    fontFamily: 'AvenirNextRegular, sans-serif',
    fontSize: '14px',
    lineHeight: '140%;',
    focus: {
      outlineColor: '#007bff',
      focusBorder: '1px solid #007bff',
    },
  },
  checkboxOpenSans: {
    checkedColor: '#56a3ba',
    borderColor: '#56a3ba',
    fontColor: '#373737',
    fontFamily: 'Open-sans',
    fontSize: '14px',
    lineHeight: '140%;',
    focus: {
      outlineColor: '#007bff',
      focusBorder: '1px solid #007bff',
    },
  },
  darkCheckbox: {
    checkedColor: '#446372',
    borderColor: '#446372',
    fontColor: '#373737',
    fontFamily: 'Open Sans',
    fontSize: '14px',
    lineHeight: '140%;',
    fontWeight: '400',

    focus: {
      outlineColor: '#007bff',
      focusBorder: '1px solid #007bff',
    },
  },
  chip: {
    backgroundColor: '#ebebeb',
    backgroundColorToggled: '#a1d8e5',
    backgroundColorPrimary: '#56a3ba',
    borderRadius: '50px',
    fontColor: '#373737',
    fontFamily: 'AvenirNextRegular, sans-serif',
    fontSize: '14px',
    lineHeight: '24px',
    padding: '4px 16px',
    focus: {
      outlineColor: '#007bff',
      focusBorder: '1px solid #007bff',
    },
  },
  copyUrl: {
    border: '1px solid #ebebeb',
    borderRadius: '2px',
    fontColor: '#373737',
    fontFamily: 'AvenirNextregular, sans-serif',
    fontSize: '14px',
    height: '44px',
    lineHeight: '140%',
    padding: '12px',
    disabled: {
      backgroundColor: '#f6f7f9',
      border: '1px solid #ebebeb',
      fontColor: '#646464',
    },
    label: {
      fontColor: '#043544',
      fontFamily: 'AvenirNextMedium, sans-serif',
      fontSize: '16px',
    },
    width: '240px',
    button: {
      backgroundColor: '#ebebeb',
      borderRadius: '2px',
      fontColor: '#373737',
      fontFamily: 'AvenirNextDemiBold, sans-serif',
      fontSize: '14px',
      lineHeight: '26px',
      padding: '8px 16px',
      focus: {
        outlineColor: '#007bff',
        focusBorder: '1px solid #007bff',
      },
      medium: {
        padding: '8px 16px',
      },
      small: {
        padding: '4px 16px',
      },
    },
    error: {
      border: '1px solid #a82425',
      fontColor: '#a82425',
      fontFamily: 'AvenirNextRegular, sans-serif',
      fontSize: '14px',
    },
    focus: {
      outlineColor: '#007bff',
    },
    placeholder: {
      fontColor: '#373737',
      fontFamily: 'AvenirNextRegular, sans-serif',
      fontSize: '14px',
      fontStyle: 'italic',
    },
  },
  editTextArea: {
    border: '1px solid #c2c2c2',
    fontColor: '#373737',
    fontFamily: 'AvenirNextRegular, sans-serif',
    disabled: {
      backgroundColor: '#f6f7f9',
      border: '1px solid #c2c2c2',
      fontColor: '#646464',
    },
    error: {
      border: '1px solid #a82425',
      fontColor: '#a82425',
      fontFamily: 'AvenirNextRegular, sans-serif',
      fontSize: '14px',
    },
    focus: {
      outlineColor: '#007bff',
      border: '2px solid #007bff',
    },
    label: {
      fontColor: '#043544',
      fontFamily: 'AvenirNextMedium, sans-serif',
      fontSize: '16px',
    },
  },
  emailCheck: {
    border: '1px solid #c2c2c2',
    borderRadius: '2px',
    fontColor: '#373737',
    fontFamily: 'Open Sans, sans-serif',
    fontSize: '14px',
    height: '44px',
    lineHeight: '140%',
    padding: '12px',
    disabled: {
      backgroundColor: '#f6f7f9',
      border: '1px solid #c2c2c2',
      fontColor: '#646464',
    },
    width: '100%',
    button: {
      backgroundColor: '#ebebeb',
      borderRadius: '2px',
      fontColor: '#373737',
      fontFamily: 'Open Sans, sans-serif',
      fontSize: '14px',
      fontWeight: '600',
      lineHeight: '26px',
      padding: '8px 16px',
      focus: {
        outlineColor: '#007bff',
        focusBorder: '1px solid #007bff',
      },
      medium: {
        padding: '8px 16px',
      },
      small: {
        padding: '4px 16px',
      },
    },
    error: {
      border: '1px solid #a82425',
      fontColor: '#a82425',
      fontFamily: 'Open Sans, sans-serif',
      fontSize: '14px',
    },
    focus: {
      outlineColor: '#007bff',
    },
    label: {
      fontColor: '#043544',
      fontFamily: 'Open Sans, sans-serif',
      fontSize: '16px',
    },
    placeholder: {
      fontColor: '#373737',
      fontFamily: 'Open Sans, sans-serif',
      fontSize: '14px',
      fontStyle: 'italic',
    },
  },
  formCard: {
    backgroundColor: '#fffcfc',
    border: '1px solid #ebebeb',
    fontFamily: 'AvenirNextRegular, sans-serif',
    fontSize: '16px',
    minHeight: '200px',
    content: {
      padding: '28px 24px',
    },
    header: {
      backgroundColor: '#ebebeb',
      fontColor: '#043544',
      fontFamily: 'AvenirNextMedium, sans-serif',
      fontSize: '14px',
      height: '44px',
      padding: '0 0 0 16px',
    },
  },
  questionType: {
    fontColor: '#043544',
    fontFamily: 'AvenirNextMedium, sans-serif',
    fontSize: '14px',
    lineHeight: '140%',
    backgroundColor: '#848383',
    width: '14.46px',
    height: '1.98px',
  },
  legend: {
    fontColor: '#043544',
    fontFamily: 'AvenirNextMedium, sans-serif',
    fontSize: '14px',
    lineHeight: '140%',
  },
  locationSearch: {
    border: '1px solid #c2c2c2',
    borderRadius: '2px',
    fontColor: '#373737',
    fontFamily: 'AvenirNextregular, sans-serif',
    fontSize: '14px',
    height: '44px',
    lineHeight: '140%',
    padding: '12px 12px 12px 30px',
    disabled: {
      backgroundColor: '#f6f7f9',
      border: '1px solid #c2c2c2',
      fontColor: '#646464',
    },
    error: {
      border: '1px solid #a82425',
      fontColor: '#a82425',
      fontFamily: 'AvenirNextRegular, sans-serif',
      fontSize: '14px',
    },
    focus: {
      outlineColor: '#007bff',
    },
    label: {
      fontColor: '#043544',
      fontFamily: 'AvenirNextMedium, sans-serif',
      fontSize: '16px',
    },
    listContainer: {
      border: '1px solid #ebebeb',
      padding: '8px 0',
    },
    listItem: {
      fontColor: '#043544',
      fontFamily: 'AvenirNextRegular, sans-serif',
      fontSize: '14px',
      padding: '8px 25px',
    },
    placeholder: {
      fontColor: '#373737',
      fontFamily: 'AvenirNextRegular, sans-serif',
      fontSize: '14px',
      fontStyle: 'italic',
    },
  },
  pageHeader: {
    backgroundColor: '#fff',
    fontColor: '#043544',
    fontFamily: 'AvenirNextMedium, sans-serif',
    fontSize: '34px',
    lineHeight: '48px',
  },
  paragraph: {
    fontColor: '#373737',
    fontFamily: 'AvenirNextRegular, sans-serif',
    fontSize: '14px',
    lineHeight: '140%',
  },
  radioButton: {
    checkedColor: '#56a3ba',
    borderColor: '#56a3ba',
    focusBorder: '1px solid #007bff',
    fontColor: '#373737',
    fontFamily: 'AvenirNextRegular, sans-serif',
    fontSize: '14px',
    lineHeight: '140%;',
  },
  sectionHeader: {
    backgroundColor: '#fff',
    fontColor: '#043544',
    fontFamily: 'AvenirNextMedium, sans-serif',
    fontSize: '24px',
    lineHeight: '34px%',
    line: '1px solid #043544',
    textPadding: '0 16px 0 0',
  },
  selectOptionsOutside: {
    label: {
      fontColor: '#043544',
      fontFamily: 'AvenirNextMedium, sans-serif',
      fontSize: '16px',
    },
  },
  selectOption: {
    label: {
      fontColor: '#043544',
      fontFamily: 'AvenirNextMedium, sans-serif',
      fontSize: '16px',
    },
    nunitoLabel: {
      fontFamily: 'Nunito',
      fontSize: '14px',
      lineHeight: '120%',
      letterSpacing: '0.015em',
      color: '#373737',
    },
    error: {
      border: '1px solid #a82425',
      fontColor: '#a82425',
      fontFamily: 'AvenirNextRegular, sans-serif',
      fontFamilyOpenSans: 'Open Sans, sans-serif',
      fontSize: '14px',
      fontWeight: '400',
    },
  },
  textAreaExpanding: {
    border: '1px solid #c2c2c2',
    borderRadius: '2px',
    fontColor: '#373737',
    fontFamily: 'AvenirNextregular, sans-serif',
    fontSize: '14px',
    height: '44px',
    lineHeight: '140%',
    padding: '12px',
    maxHeight: '100px',
    disabled: {
      backgroundColor: '#f6f7f9',
      border: '1px solid #c2c2c2',
      fontColor: '#646464',
    },
    error: {
      border: '1px solid #a82425',
      fontColor: '#a82425',
      fontFamily: 'AvenirNextRegular, sans-serif',
      fontSize: '14px',
    },
    focus: {
      outlineColor: '#007bff',
    },
    label: {
      fontColor: '#043544',
      fontFamily: 'AvenirNextMedium, sans-serif',
      fontSize: '16px',
    },
    placeholder: {
      fontColor: '#373737',
      fontFamily: 'AvenirNextRegular, sans-serif',
      fontSize: '14px',
      fontStyle: 'italic',
    },
  },
  textInput: {
    border: '1px solid #c2c2c2',
    borderRadius: '2px',
    fontColor: '#373737',
    fontFamily: 'AvenirNextregular, sans-serif',
    fontFamilyOpenSans: 'Open Sans, sans-serif',
    fontSize: '14px',
    fontWeight: '400',
    height: '44px',
    lineHeight: '140%',
    padding: '12px',
    disabled: {
      backgroundColor: '#f6f7f9',
      border: '1px solid #c2c2c2',
      fontColor: '#646464',
    },
    error: {
      border: '1px solid #a82425',
      fontColor: '#a82425',
      fontFamily: 'AvenirNextRegular, sans-serif',
      fontFamilyOpenSans: 'Open Sans, sans-serif',
      fontSize: '14px',
      fontWeight: '400',
    },
    focus: {
      outlineColor: '#007bff',
    },
    label: {
      fontColor: '#043544',
      fontFamily: 'AvenirNextMedium, sans-serif',
      fontFamilyOpenSans: 'Open Sans, sans-serif',
      fontSize: '16px',
      fontWeight: '600',
    },
    instructions: {
      fontColor: '#373737',
      fontFamily: 'AvenirNextMedium, sans-serif',
      fontFamilyOpenSans: 'Open Sans, sans-serif',
      fontSize: '12px',
      fontWeight: '400',
    },
    placeholder: {
      fontColor: '#373737',
      fontFamily: 'AvenirNextRegular, sans-serif',
      fontFamilyOpenSans: 'Open Sans, sans-serif',
      fontSize: '14px',
      fontWeight: '400',
      fontStyle: 'italic',
    },
  },
  textSearch: {
    backgroundColor: 'transparent',
    border: 'none',
    borderBottom: '1px solid #c2c2c2',
    fontColor: '#373737',
    fontFamily: 'AvenirNextregular, sans-serif',
    fontFamilyOpenSans: 'Open Sans, sans-serif',
    fontSize: '16px',
    height: '44px',
    lineHeight: '140%',
    padding: '12px 30px 12px 4px',
    disabled: {
      backgroundColor: '#f6f7f9',
      border: '1px solid #c2c2c2',
      fontColor: '#646464',
    },
    error: {
      border: 'none',
      borderBottom: '1px solid #a82425',
      fontColor: '#a82425',
      fontFamily: 'AvenirNextRegular, sans-serif',
      fontSize: '14px',
    },
    focus: {
      outlineColor: '#007bff',
    },
    label: {
      fontColor: '#043544',
      fontFamily: 'AvenirNextMedium, sans-serif',
      fontSize: '16px',
    },
    placeholder: {
      fontColor: '#373737',
      fontFamily: 'AvenirNextRegular, sans-serif',
      fontSize: '16px',
      fontStyle: 'italic',
    },
  },
  toggle: {
    border: '1px solid #c2c2c2',
    fontColor: '#043544',
    fontFamily: 'AvenirNextRegular, sans-serif',
    fontSize: '14px',
    onBackgroundColor: '#dd2160',
    offBackgroundColor: '#373737',
    focus: {
      outlineColor: '#007bff',
      focusBorder: '1px solid #007bff',
    },
  },
  textToggle: {
    textDecoration: 'underline',
    fontFamily: 'AvenirNextRegular, sans-serif',
    fontSize: '14px',
    color: '#1c748a',
    dividerColor: '#373737',
    focus: {
      outlineColor: '#007bff',
      focusBorder: '1px solid #007bff',
    },
  },
  tooltip: {
    backgroundColor: '#fff',
    border: '1px solid #d2d5d9',
    fontColor: '#373737',
    fontFamily: 'AvenirNextRegular, sans-serif',
    fontSize: '10px',
    lineHeight: '14px',
  },
};


export const themeViaForms = Object.assign({}, themeVia, {
  defaults: {
    body: {
      backgroundColor: '#fff',
      fontColor: '#043544',
      fontFamily: 'Open Sans, sans-serif',
      fontSize: '16px',
    },
    clientAccent: '#dd2160',
    clientAccentFont: '#fff',
    clientDark: '#043544',
    clientDarkFont: '#fff',
    clientLight: '#dd2160',
    clientLightFont: '#000',
    fontColor: '#373737',
    errorColor: '#a82425',
    error: {
      border: '1px solid #a82425',
      fontColor: '#a82425',
      fontFamily: 'Open Sans, sans-serif',
      fontFamilyOpenSans: 'Open Sans, sans-serif',
      fontSize: '14px',
      fontWeight: '400',
    },
    successColor: '#43B648',
  },
  accordionRow: {
    backgroundColor: '#fffcfc',
    backgroundColorEven: '#ebebeb',
    backgroundColorOdd: '#F7F7F7',
    border: '1px solid #ebebeb',
    fontFamily: 'Open Sans, sans-serif',
    fontSize: '16px',
    informationFontSize: '14px',
    minHeight: '200px',
    content: {
      padding: '24px',
    },
    header: {
      backgroundColor: '#ebebeb',
      fontColor: '#043544',
      fontFamily: 'Open Sans, sans-serif',
      fontSize: '14px',
      height: '44px',
      padding: '0 0 0 16px',
    },
  },
  actionButton: {
    backgroundColor: '#ffffff',
    borderRadius: '3px',
    boxShadow: '0 5px 10px 0 rgba(55, 55, 55, 0.25)',
    fontColor: '#373737',
    fontFamily: 'Open Sans, sans-serif',
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '140%',
    padding: '15px 0 15px 30px',
    height: '50px',
    width: '259px',
    active: {
      backgroundColor: '#d1dff1',
      border: '1px solid #d1dff1',
    },
    disabled: {
      backgroundColor: '#E7E7E7',
      boxShadow: 'none',
      fontColor: '#555',
    },
    focus: {
      border: '1px solid #255A94',
      outlineColor: '#255A94',
    },
    hover: {
      backgroundColor: '#deecff',
      border: '1px solid #deecff',
    },
  },
  button: {
    backgroundColorAccent: '#D74B73',
    backgroundColor: '#ebebeb',
    backgroundColorDarkGrey: '#CCCCCC',
    backgroundColorGrey: '#D7D7D7',
    backgroundColorSecondaryDarkest: '#446372',
    borderRadius: '5px',
    fontColor: '#373737',
    fontColorWhite: '#FFFFFF',
    fontFamily: 'Open Sans, sans-serif',
    fontFamilyOpenSans: 'Open Sans, sans-serif',
    fontFamilyNunito: 'Nunito, sans-serif',
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '24px',
    padding: '8px 16px',
    focus: {
      outlineColor: '#255A94',
      focusBorder: '1px solid #255A94',
    },
    large: {
      fontSize: '16px',
      padding: '16px 20px',
    },
    medium: {
      padding: '8px 16px',
    },
    small: {
      padding: '4px 16px',
    },
  },
  checkbox: {
    checkedColor: '#56a3ba',
    borderColor: '#56a3ba',
    fontColor: '#373737',
    fontFamily: 'Open Sans, sans-serif',
    fontSize: '14px',
    lineHeight: '140%;',
    focus: {
      outlineColor: '#255A94',
      focusBorder: '1px solid #255A94',
    },
  },
  checkboxOpenSans: {
    checkedColor: '#56a3ba',
    borderColor: '#56a3ba',
    fontColor: '#373737',
    fontFamily: 'Open Sans, sans-serif',
    fontSize: '14px',
    lineHeight: '140%;',
    focus: {
      outlineColor: '#255A94',
      focusBorder: '1px solid #255A94',
    },
  },
  darkCheckbox: {
    checkedColor: '#373737',
    borderColor: '#373737',
    fontColor: '#373737',
    fontFamily: 'Open Sans, sans-serif',
    fontSize: '14px',
    lineHeight: '140%;',
    fontWeight: '400',

    focus: {
      outlineColor: '#255A94',
      focusBorder: '1px solid #255A94',
    },
  },
  chip: {
    backgroundColor: '#ebebeb',
    backgroundColorToggled: '#a1d8e5',
    backgroundColorPrimary: '#56a3ba',
    borderRadius: '50px',
    fontColor: '#373737',
    fontFamily: 'Open Sans, sans-serif',
    fontSize: '14px',
    lineHeight: '24px',
    padding: '4px 16px',
    focus: {
      outlineColor: '#255A94',
      focusBorder: '1px solid #255A94',
    },
  },
  copyUrl: {
    border: '1px solid #ebebeb',
    borderRadius: '2px',
    fontColor: '#373737',
    fontFamily: 'Open Sans, sans-serif',
    fontSize: '14px',
    height: '44px',
    lineHeight: '140%',
    padding: '12px',
    disabled: {
      backgroundColor: '#E7E7E7',
      border: '1px solid #bbb',
      fontColor: '#555',
    },
    label: {
      fontColor: '#043544',
      fontFamily: 'Open Sans, sans-serif',
      fontSize: '16px',
    },
    width: '240px',
    button: {
      backgroundColor: '#ebebeb',
      borderRadius: '2px',
      fontColor: '#373737',
      fontFamily: 'Open Sans, sans-serif',
      fontSize: '14px',
      lineHeight: '26px',
      padding: '8px 16px',
      focus: {
        outlineColor: '#255A94',
        focusBorder: '1px solid #255A94',
      },
      medium: {
        padding: '8px 16px',
      },
      small: {
        padding: '4px 16px',
      },
    },
    error: {
      border: '1px solid #a82425',
      fontColor: '#a82425',
      fontFamily: 'Open Sans, sans-serif',
      fontSize: '14px',
    },
    focus: {
      outlineColor: '#255A94',
    },
    placeholder: {
      fontColor: '#373737',
      fontFamily: 'Open Sans, sans-serif',
      fontSize: '14px',
      fontStyle: 'italic',
    },
  },
  editTextArea: {
    border: '1px solid #bbb',
    fontColor: '#373737',
    fontFamily: 'Open Sans, sans-serif',
    disabled: {
      backgroundColor: '#E7E7E7',
      border: '1px solid #BBB',
      fontColor: '#555',
    },
    error: {
      border: '1px solid #8B1313',
      fontColor: '#8B1313',
      fontFamily: 'Open Sans, sans-serif',
      fontSize: '14px',
    },
    focus: {
      outlineColor: '#255A94',
      border: '2px solid #255A94',
    },
    label: {
      fontColor: '#373737',
      fontFamily: 'Open Sans, sans-serif',
      fontSize: '16px',
    },
  },
  emailCheck: {
    border: '1px solid #BBB',
    borderRadius: '2px',
    fontColor: '#373737',
    fontFamily: 'Open Sans, sans-serif',
    fontSize: '14px',
    height: '44px',
    lineHeight: '140%',
    padding: '12px',
    disabled: {
      backgroundColor: '#E7E7E7',
      border: '1px solid #BBB',
      fontColor: '#555',
    },
    width: '100%',
    button: {
      backgroundColor: '#ebebeb',
      borderRadius: '2px',
      fontColor: '#373737',
      fontFamily: 'Open Sans, sans-serif',
      fontSize: '14px',
      fontWeight: '600',
      lineHeight: '26px',
      padding: '8px 16px',
      focus: {
        outlineColor: '#255A94',
        focusBorder: '1px solid #255A94',
      },
      medium: {
        padding: '8px 16px',
      },
      small: {
        padding: '4px 16px',
      },
    },
    error: {
      border: '1px solid #a82425',
      fontColor: '#a82425',
      fontFamily: 'Open Sans, sans-serif',
      fontSize: '14px',
    },
    focus: {
      outlineColor: '#255A94',
    },
    label: {
      fontColor: '#373737',
      fontFamily: 'Open Sans, sans-serif',
      fontSize: '16px',
    },
    placeholder: {
      fontColor: '#373737',
      fontFamily: 'Open Sans, sans-serif',
      fontFamilyOpenSans: 'Open Sans, sans-serif',
      fontSize: '14px',
      fontWeight: '400',
      fontStyle: 'italic',
    },
  },
  formCard: {
    backgroundColor: '#fffcfc',
    border: '1px solid #ebebeb',
    fontFamily: 'Open Sans, sans-serif',
    fontSize: '16px',
    minHeight: '200px',
    content: {
      padding: '28px 24px',
    },
    header: {
      backgroundColor: '#ebebeb',
      fontColor: '#043544',
      fontFamily: 'Open Sans, sans-serif',
      fontSize: '14px',
      height: '44px',
      padding: '0 0 0 16px',
    },
  },
  legend: {
    fontColor: '#043544',
    fontFamily: 'Open Sans, sans-serif',
    fontSize: '14px',
    lineHeight: '140%',
  },
  locationSearch: {
    border: '1px solid #c2c2c2',
    borderRadius: '2px',
    fontColor: '#373737',
    fontFamily: 'Open Sans, sans-serif',
    fontSize: '14px',
    height: '44px',
    lineHeight: '140%',
    padding: '12px 12px 12px 30px',
    disabled: {
      backgroundColor: '#E7E7E7',
      border: '1px solid #bbb',
      fontColor: '#555',
    },
    error: {
      border: '1px solid #a82425',
      fontColor: '#a82425',
      fontFamily: 'Open Sans, sans-serif',
      fontSize: '14px',
    },
    focus: {
      outlineColor: '#255A94',
    },
    label: {
      fontColor: '#043544',
      fontFamily: 'Open Sans, sans-serif',
      fontSize: '16px',
    },
    listContainer: {
      border: '1px solid #ebebeb',
      padding: '8px 0',
    },
    listItem: {
      fontColor: '#043544',
      fontFamily: 'Open Sans, sans-serif',
      fontSize: '14px',
      padding: '8px 25px',
    },
    placeholder: {
      fontColor: '#373737',
      fontFamily: 'Open Sans, sans-serif',
      fontSize: '14px',
      fontStyle: 'italic',
    },
  },
  pageHeader: {
    backgroundColor: '#fff',
    fontColor: '#043544',
    fontFamily: 'Open Sans, sans-serif',
    fontSize: '34px',
    lineHeight: '48px',
  },
  paragraph: {
    fontColor: '#373737',
    fontFamily: 'Open Sans, sans-serif',
    fontSize: '14px',
    lineHeight: '140%',
  },
  radioButton: {
    checkedColor: '#56a3ba',
    borderColor: '#56a3ba',
    focusBorder: '1px solid #007bff',
    fontColor: '#373737',
    fontFamily: 'Open Sans, sans-serif',
    fontSize: '14px',
    lineHeight: '140%;',
  },
  sectionHeader: {
    backgroundColor: '#fff',
    fontColor: '#043544',
    fontFamily: 'Open Sans, sans-serif',
    fontSize: '24px',
    lineHeight: '34px%',
    line: '1px solid #043544',
    textPadding: '0 16px 0 0',
  },
  selectOptionsOutside: {
    label: {
      fontColor: '#043544',
      fontFamily: 'Open Sans, sans-serif',
      fontSize: '16px',
    },
  },
  selectOption: {
    label: {
      fontColor: '#043544',
      fontFamily: 'Open Sans, sans-serif',
      fontSize: '16px',
      fontWeight: 600,
    },
    nunitoLabel: {
      fontFamily: 'Nunito',
      fontSize: '14px',
      lineHeight: '120%',
      letterSpacing: '0.015em',
      color: '#373737',
    },
    error: {
      border: '1px solid #a82425',
      fontColor: '#a82425',
      fontFamily: 'Open Sans, sans-serif',
      fontFamilyOpenSans: 'Open Sans, sans-serif',
      fontSize: '14px',
      fontWeight: '400',
    },
  },
  textAreaExpanding: {
    border: '1px solid #bbb',
    borderRadius: '2px',
    fontColor: '#373737',
    fontFamily: 'Open Sans, sans-serif',
    fontSize: '14px',
    height: '44px',
    lineHeight: '140%',
    padding: '12px',
    maxHeight: '100px',
    disabled: {
      backgroundColor: '#E7E7E7',
      border: '1px solid #bbb',
      fontColor: '#555',
    },
    error: {
      border: '1px solid #8B1313',
      fontColor: '#8B1313',
      fontFamily: 'Open Sans, sans-serif',
      fontSize: '14px',
    },
    focus: {
      outlineColor: '#255A94',
    },
    label: {
      fontColor: '#043544',
      fontFamily: 'Open Sans, sans-serif',
      fontSize: '16px',
    },
    placeholder: {
      fontColor: '#373737',
      fontFamily: 'Open Sans, sans-serif',
      fontSize: '14px',
      fontStyle: 'italic',
    },
  },
  textInput: {
    border: '1px solid #BBB',
    borderRadius: '2px',
    fontColor: '#373737',
    fontFamily: 'Open Sans, sans-serif',
    fontFamilyOpenSans: 'Open Sans, sans-serif',
    fontSize: '14px',
    fontWeight: '400',
    height: '44px',
    lineHeight: '140%',
    padding: '12px',
    disabled: {
      backgroundColor: '#E7E7E7',
      border: '1px solid #bbb',
      fontColor: '#555',
    },
    error: {
      border: '1px solid #8B1313',
      fontColor: '#8B1313',
      fontFamily: 'Open Sans, sans-serif',
      fontFamilyOpenSans: 'Open Sans, sans-serif',
      fontSize: '14px',
      fontWeight: '400',
    },
    focus: {
      outlineColor: '#255A94',
    },
    label: {
      fontColor: '#373737',
      fontFamily: 'Open Sans, sans-serif',
      fontFamilyOpenSans: 'Open Sans, sans-serif',
      fontSize: '16px',
      fontWeight: '600',
    },
    instructions: {
      fontColor: '#373737',
      fontFamily: 'Open Sans, sans-serif',
      fontFamilyOpenSans: 'Open Sans, sans-serif',
      fontSize: '14px',
      fontWeight: '400',
    },
    placeholder: {
      fontColor: '#373737',
      fontFamily: 'Open Sans, sans-serif',
      fontFamilyOpenSans: 'Open Sans, sans-serif',
      fontSize: '14px',
      fontWeight: '400',
      fontStyle: 'italic',
    },
  },
  textSearch: {
    backgroundColor: 'transparent',
    border: 'none',
    borderBottom: '1px solid #bbb',
    fontColor: '#373737',
    fontFamily: 'Open Sans, sans-serif',
    fontFamilyOpenSans: 'Open Sans, sans-serif',
    fontSize: '16px',
    height: '44px',
    lineHeight: '140%',
    padding: '12px 30px 12px 4px',
    disabled: {
      backgroundColor: '#E7E7E7',
      border: '1px solid #bbb',
      fontColor: '#555',
    },
    error: {
      border: 'none',
      borderBottom: '1px solid #8B1313',
      fontColor: '#8B1313',
      fontFamily: 'Open Sans, sans-serif',
      fontSize: '14px',
    },
    focus: {
      outlineColor: '#255A94',
    },
    label: {
      fontColor: '#373737',
      fontFamily: 'Open Sans, sans-serif',
      fontSize: '16px',
    },
    placeholder: {
      fontColor: '#555',
      fontFamily: 'Open Sans, sans-serif',
      fontSize: '16px',
      fontStyle: 'italic',
    },
  },
  toggle: {
    border: '1px solid #c2c2c2',
    fontColor: '#373737',
    fontFamily: 'Open Sans, sans-serif',
    fontSize: '14px',
    onBackgroundColor: '#dd2160',
    offBackgroundColor: '#373737',
    focus: {
      outlineColor: '#255A94',
      focusBorder: '1px solid #255A94',
    },
  },
  textToggle: {
    textDecoration: 'underline',
    fontFamily: 'Open Sans, sans-serif',
    fontSize: '14px',
    color: '#1c748a',
    dividerColor: '#373737',
    focus: {
      outlineColor: '#255A94',
      focusBorder: '1px solid #255A94',
    },
  },
  tooltip: {
    backgroundColor: '#fff',
    border: '1px solid #d2d5d9',
    fontColor: '#373737',
    fontFamily: 'Open Sans, sans-serif',
    fontSize: '10px',
    lineHeight: '14px',
  },
});