import React from 'react';

export default function ClockReturnIcon() {
  return (
    <svg width="70" height="71" viewBox="0 0 70 71" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
      <g clipPath="url(#clip0_1244_28687)">
        <path
          d="M35 2.20947C30.5956 2.20947 26.3889 3.05649 22.5335 4.59618L21.1727 2.25327L15.7659 11.6772L26.6302 11.6473L25.3181 9.38881C28.3376 8.28331 31.5975 7.67822 35 7.67822C50.5544 7.67822 63.1641 20.2879 63.1641 35.8423C63.1641 51.3967 50.5544 64.0063 35 64.0063C19.4456 64.0063 6.83594 51.3967 6.83594 35.8423H1.36719C1.36719 54.4173 16.4249 69.4751 35 69.4751C53.5751 69.4751 68.6328 54.4173 68.6328 35.8423C68.6328 17.2672 53.5751 2.20947 35 2.20947Z"
          fill="#DDAF64"
        />
        <path
          d="M57.4219 35.8423C57.4219 48.2255 47.3832 58.2642 35 58.2642C22.6168 58.2642 12.5781 48.2255 12.5781 35.8423C12.5781 23.4591 22.6168 13.4204 35 13.4204C47.3832 13.4204 57.4219 23.4591 57.4219 35.8423Z"
          fill="#A9CADA"
        />
        <path
          d="M59.7488 11.0935C53.1382 4.48296 44.3487 0.842285 35 0.842285C30.9177 0.842285 26.9315 1.53603 23.1327 2.90642L22.3546 1.56647C22.11 1.14563 21.6598 0.886078 21.1727 0.886078C21.1716 0.886078 21.17 0.886078 21.169 0.886078C20.6808 0.88768 20.2301 1.14937 19.9871 1.57288L14.5803 10.9963C14.3373 11.4204 14.3389 11.9416 14.584 12.3635C14.8286 12.7849 15.2794 13.0439 15.7659 13.0439H15.7697L26.634 13.0145C27.1227 13.0129 27.5734 12.7512 27.8164 12.3277C28.0594 11.9042 28.0578 11.383 27.8127 10.9605L27.3454 10.1562C29.8207 9.41872 32.389 9.04541 35 9.04541C49.7758 9.04541 61.7969 21.0665 61.7969 35.8423C61.7969 50.6181 49.7758 62.6392 35 62.6392C20.2242 62.6392 8.20312 50.6181 8.20312 35.8423C8.20312 35.0871 7.59109 34.4751 6.83594 34.4751H1.36719C0.61203 34.4751 0 35.0871 0 35.8423C0 45.191 3.64067 53.9805 10.2512 60.591C16.8618 67.2016 25.6513 70.8423 35 70.8423C44.3487 70.8423 53.1382 67.2016 59.7488 60.591C66.3593 53.9805 70 45.191 70 35.8423C70 26.4936 66.3593 17.7041 59.7488 11.0935ZM35 68.1079C17.6672 68.1079 3.48206 54.3687 2.76321 37.2095H5.49973C5.83351 44.5843 8.86375 51.4699 14.1183 56.7239C19.696 62.3016 27.112 65.3735 35 65.3735C42.888 65.3735 50.304 62.3016 55.8817 56.7239C61.4594 51.1462 64.5312 43.7303 64.5312 35.8423C64.5312 27.9543 61.4594 20.5383 55.8817 14.9606C50.304 9.38293 42.888 6.31104 35 6.31104C31.5153 6.31104 28.0994 6.91452 24.8481 8.10493C24.4684 8.24379 24.1698 8.54393 24.0326 8.92418C23.8948 9.30443 23.9327 9.7258 24.1362 10.0756L24.2585 10.2866L18.1302 10.3031L21.1797 4.98764L21.3516 5.28297C21.6918 5.86937 22.4112 6.11771 23.0403 5.86563C26.8444 4.34677 30.868 3.57666 35 3.57666C52.7916 3.57666 67.2656 18.0507 67.2656 35.8423C67.2656 53.6339 52.7916 68.1079 35 68.1079Z"
          fill="black"
        />
        <path
          d="M51.8213 19.0211C51.2883 18.4881 50.4274 18.4897 49.8933 19.0221C49.3587 19.5541 49.3593 20.4214 49.8901 20.9565C49.8917 20.9581 49.8992 20.9656 49.9003 20.9667C50.1668 21.2326 50.5144 21.3656 50.8626 21.3656C51.2119 21.3656 51.5612 21.2321 51.8288 20.9656C52.3633 20.4337 52.3628 19.5664 51.832 19.0307C51.8304 19.0296 51.8229 19.0221 51.8213 19.0211Z"
          fill="black"
        />
        <path
          d="M47.1071 16.9147C47.4745 16.2551 47.2379 15.4225 46.5784 15.0545C42.1484 12.584 36.9247 11.5917 31.8694 12.2588C26.6468 12.9488 21.9124 15.2869 18.1788 19.021C13.6852 23.514 11.2109 29.488 11.2109 35.8422C11.2109 42.1964 13.6852 48.1704 18.1788 52.6634C22.6718 57.157 28.6458 59.6313 35 59.6313C41.3542 59.6313 47.3282 57.157 51.8212 52.6634C59.5517 44.9335 61.0316 32.993 55.4197 23.6272C55.032 22.9794 54.1919 22.7695 53.5441 23.1573C52.8963 23.5455 52.6859 24.3851 53.0741 25.0329C58.0403 33.3209 56.7297 43.8883 49.8879 50.7301C45.9113 54.7067 40.6242 56.8969 35 56.8969C29.3758 56.8969 24.0887 54.7067 20.1121 50.7301C16.1355 46.7535 13.9453 41.4664 13.9453 35.8422C13.9453 30.218 16.1355 24.9309 20.1121 20.9543C26.7376 14.3288 37.0732 12.8852 45.247 17.4429C45.9065 17.8103 46.7391 17.5737 47.1071 16.9147Z"
          fill="black"
        />
        <path
          d="M36.3848 19.5044V18.479C36.3848 17.7238 35.7722 17.1118 35.0176 17.1118C34.2624 17.1118 33.6504 17.7238 33.6504 18.479V19.5044C33.6504 20.2596 34.2624 20.8716 35.0176 20.8716C35.7722 20.8716 36.3848 20.2596 36.3848 19.5044Z"
          fill="black"
        />
        <path
          d="M33.6152 52.1802V53.2056C33.6152 53.9607 34.2278 54.5728 34.9824 54.5728C35.7376 54.5728 36.3496 53.9607 36.3496 53.2056V52.1802C36.3496 51.425 35.7376 50.813 34.9824 50.813C34.2278 50.813 33.6152 51.425 33.6152 52.1802Z"
          fill="black"
        />
        <path
          d="M49.9707 35.8599C49.9707 36.6145 50.5827 37.2271 51.3379 37.2271H52.3633C53.1184 37.2271 53.7305 36.6145 53.7305 35.8599C53.7305 35.1047 53.1184 34.4927 52.3633 34.4927H51.3379C50.5827 34.4927 49.9707 35.1047 49.9707 35.8599Z"
          fill="black"
        />
        <path
          d="M17.6367 34.4575C16.8816 34.4575 16.2695 35.0701 16.2695 35.8247C16.2695 36.5799 16.8816 37.1919 17.6367 37.1919H18.6621C19.4173 37.1919 20.0293 36.5799 20.0293 35.8247C20.0293 35.0701 19.4173 34.4575 18.6621 34.4575H17.6367Z"
          fill="black"
        />
        <path
          d="M43.203 35.8424C43.203 35.0872 42.5909 34.4752 41.8358 34.4752H35.5659L25.3158 24.225C24.7822 23.6909 23.9165 23.6909 23.3825 24.225C22.8484 24.7585 22.8484 25.6242 23.3825 26.1583L34.0332 36.809C34.2895 37.0654 34.6372 37.2095 34.9998 37.2095H41.8358C42.5909 37.2095 43.203 36.5975 43.203 35.8424Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_1244_28687">
          <rect width="70" height="70" fill="white" transform="translate(0 0.842285)" />
        </clipPath>
      </defs>
    </svg>
  );
}
