import fetch from 'node-fetch';
import { keys } from '../../../config/keys';

import {
  GET_PROGRAMS,
  SET_PROGRAMS_SORT,
  TOGGLE_PROGRAMS_FILTER,
  SET_PROGRAMS_SEARCH_TERM,
  CLEAR_ALL_PROGRAMS_FILTERS,
  GET_PROGRAMS_REQUEST,
} from './types';
import { Cookies } from 'react-cookie';
import { SET_QUERY_STRING_FILTERS } from "../../../actions/types";

export function getVisitorPrograms(clientId) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return async function(dispatch) {
    try {
      const response = await fetch(`${keys.baseUri}/api/visitor/client_programs/${clientId}`, {
        method: 'GET',
        headers: token,
      });

      const { data } = await response.json();

      dispatch({
        type: GET_PROGRAMS,
        payload: {
          data,
          error: false,
        },
      });
    } catch (error) {
      dispatch({
        type: GET_PROGRAMS,
        payload: {
          data: [],
          error: true,
        },
      });
    }
  };
}

export function getTravelerPrograms() {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return async function(dispatch) {
    try {
      dispatch({
        type: GET_PROGRAMS_REQUEST,
      });

      const response = await fetch(`${keys.baseUri}/api/programs`, {
        method: 'GET',
        headers: token,
      });

      const { data } = await response.json();

      dispatch({
        type: GET_PROGRAMS,
        payload: {
          data,
          error: false,
        },
      });
    } catch (error) {
      dispatch({
        type: GET_PROGRAMS,
        payload: {
          data: [],
          error: true,
        },
      });
    }
  };
}

export function toggleProgramFavorite(userId, programId, shouldFavorite) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return async function(dispatch) {
    const method = shouldFavorite ? 'POST' : 'DELETE';
    const endpointPath = shouldFavorite ? 'create_favorite' : 'delete_favorite';

    await fetch(`${keys.baseUri}/api/traveler/programs/${userId}/favorites/${programId}/${endpointPath}`, {
      method,
      headers: token,
    });

    dispatch(getTravelerPrograms());
  };
}

export function setProgramsSort(sortProperties, sortDirections) {
  return function(dispatch) {
    dispatch({
      type: SET_PROGRAMS_SORT,
      payload: {
        sortDirections,
        sortProperties,
      },
    });
  };
}

export function setProgramsSearchTerm(searchTerm) {
  return function(dispatch) {
    dispatch({
      type: SET_PROGRAMS_SEARCH_TERM,
      payload: searchTerm,
    });
  };
}

export function toggleProgramsFilter(filter) {
  return function(dispatch) {
    dispatch({
      type: TOGGLE_PROGRAMS_FILTER,
      payload: filter,
    });
  };
}

export function setQueryStringFilters(filter) {
  return function(dispatch) {
    dispatch({
      type: SET_QUERY_STRING_FILTERS,
      payload: filter,
    });
  };
}


export function clearAllProgramsFilters() {
  return function(dispatch) {
    dispatch({
      type: CLEAR_ALL_PROGRAMS_FILTERS,
    });
  };
}
