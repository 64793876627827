import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ScreenReaderText from '../base/ScreenReaderText.styled';
import { ToggleSlider, ToggleSwitch } from '../base/Toggle.styled';

import userId from '../../../../shared/helpers/userId';
import sendGridMailerType from '../../types/sendGridMailerType';
import { updateNotificationOptOut } from '../../actions/travelerProfileActions';
import { replaceCustomAliasesHandlebars } from '../../../../shared/helpers/General';

export default function NotificationSettingsToggleSwitch({ mailerType }) {
  const dispatch = useDispatch();

  const { customAliases } = useSelector(state => state.currentOrganization);
  const { archivedNotifications } = useSelector(state => state.travelerProfile);

  const [archivedNotificationIds, setArchivedNotificationIds] = useState([]);

  const displayName = replaceCustomAliasesHandlebars(mailerType.display_name, customAliases);

  useEffect(
    () => {
      const archivedIds = archivedNotifications.map(
        archivedNotification => archivedNotification.attributes.send_grid_mailer_type_id,
      );

      setArchivedNotificationIds(archivedIds);
    },
    [archivedNotifications],
  );

  const handleToggle = event => {
    dispatch(
      updateNotificationOptOut({
        mailer_opt_out: {
          user_id: userId,
          archived: event.target.checked,
          send_grid_mailer_type_id: mailerType.id,
        },
      }),
    );
  };

  return (
    <ToggleSwitch htmlFor={`mailer-type-${mailerType.id}-toggle`}>
      <ScreenReaderText>{displayName} notification status</ScreenReaderText>
      <input
        id={`mailer-type-${mailerType.id}-toggle`}
        type="checkbox"
        checked={archivedNotifications.length > 0 && archivedNotificationIds.includes(mailerType.id) ? false : true}
        onChange={handleToggle}
      />
      <ToggleSlider className="toggle-slider" />
    </ToggleSwitch>
  );
}

NotificationSettingsToggleSwitch.propTypes = {
  mailerType: sendGridMailerType.isRequired,
};
