import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import { ViaLogoCircleIcon } from '../../../styledComponents/styles/IconCustom.styled';
import { useSelector } from 'react-redux';

const ImageLogo = styled.img`
  height: 75px;
  width: 75px;
`;

export default function GetLogo() {
  const organization = useSelector(state => state.auth.organization);
  const [logo, setLogo] = useState(<ViaLogoCircleIcon size={'75'} />);

  useEffect(() => {
    if (organization && organization.branding_theme) {
      if (organization.branding_theme.logo.theme_logo.toLowerCase() !== '/default.png') {
        const clientLogo = <ImageLogo
          alt={'client logo'}
          src={organization.branding_theme.logo.theme_logo}
        />;
        setLogo(clientLogo);
      }
    }
  }, [organization]);

  return (
    <>
      {logo}
    </>
  );

}