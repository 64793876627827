import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { breakpoints } from '../../../utils/breakpoints';

const Container = styled.div`
  grid-column: span 2 / span 2;

  @media ${breakpoints.tablet} {
    grid-column: span 1 / span 1;
  }
`;

const Status = styled.span`
  padding: 0.25rem 0.625rem;
  border-radius: 50px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  letter-spacing: 0.0015em;
  line-height: 120%;

  ${({ status, count, type }) => {
    if (status.toLowerCase().includes('of')) {
      return status.toLowerCase().includes('submitted') ||
        (parseInt(status[0]) !== count && type.toLowerCase() === 'forms')
        ? `
          background-color: #FFEFD4;
          color: #6D4603;
        `
        : `
          background-color: #DAFDD7;
          color: #0E5B2F;
        `;
    }

    switch (status) {
      case 'In Review':
      case 'Submitted':
      case 'Started':
        return `
          background-color: #FFEFD4;
          color: #6D4603;
        `;
      case 'Waitlisted':
      case 'Nominated':
      case 'Conditionally Accepted':
      case 'Conditionally Approved':
        return `
          background-color: #DEECFF;
          color: #255A94;
        `;
      case 'Accepted':
      case 'Approved':
      case 'Committed':
        return `
          background-color: #DAFDD7;
          color: #0E5B2F;
        `;
      case 'Not Started':
      case 'Not Accepted':
      case 'Deferred':
      case 'Withdrawn':
      case 'Transferred':
      case 'Incomplete':
        return `
          background-color: #D7D7D7;
          color: #373737;
        `;
      default:
        return ``;
    }
  }};
`;

export default function CardApplicationStatus({ status, count, type }) {
  return (
    <Container>
      <Status status={status} count={count} type={type}>
        {status}
      </Status>
    </Container>
  );
}

CardApplicationStatus.propTypes = {
  status: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
};
