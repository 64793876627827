import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import GlobeSearchIcon from '../icons/GlobeSearchIcon';

import { breakpoints } from '../../utils/breakpoints';
import { buttonFocus } from '../../utils/shared/focusStyles';
import { clearAllProgramsFilters } from '../../actions/programDashboardActions';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  margin-top: 2.5rem;
  padding-bottom: 2.1875rem;
  padding-left: 1.375rem;
  padding-right: 1.8275rem;

  svg {
    width: 56px;
    height: 56px;
  }

  @media ${breakpoints.tablet} {
    margin-top: 3rem;
    padding: 0;

    svg {
      width: 92px;
      height: 92px;
    }
  }

  @media ${breakpoints.desktop} {
    gap: 4rem;
    margin-top: 5.25rem;
  }
`;

const Info = styled.p`
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
  font-size: 1rem;
  color: #373737;
  letter-spacing: 0.0015em;
  line-height: 120%;

  @media ${breakpoints.desktop} {
    font-size: 1.3125rem;
  }
`;

const ClearAllButton = styled.button`
  padding: 0;
  background-color: transparent;
  border: none;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  font-size: 1.3125rem;
  color: #446372;
  letter-spacing: 0.0015em;
  text-decoration-line: underline;
  line-height: 120%;

  &:hover {
    color: #446372;
    text-decoration-line: none;
  }

  ${buttonFocus};
`;

export default function NoResults() {
  const dispatch = useDispatch();

  const { selectedFilters } = useSelector(state => state.programDashboard);
  const {
    customAliases: { alias_programs: programsAlias },
  } = useSelector(state => state.currentOrganization);

  const [hasFilters, setHasFilters] = useState(false);

  useEffect(
    () => {
      const arrayFilters = Object.values(selectedFilters).filter(selectedFilter => Array.isArray(selectedFilter));
      const filtersSelected =
        arrayFilters.some(filter => filter.length > 0) ||
        selectedFilters.startDate.date ||
        selectedFilters.endDate.date ||
        selectedFilters.languageImmersion === 'yes' ||
        selectedFilters.favorites;

      setHasFilters(filtersSelected);
    },
    [selectedFilters],
  );

  const clearAllFilters = () => {
    dispatch(clearAllProgramsFilters());
  };

  return (
    <Container>
      <GlobeSearchIcon />
      <Info>
        No {programsAlias.toLowerCase()} match your {hasFilters ? 'filters' : 'search'}.
      </Info>
      {hasFilters && (
        <ClearAllButton type="button" onClick={clearAllFilters}>
          Clear Filters
        </ClearAllButton>
      )}
    </Container>
  );
}
