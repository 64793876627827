exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3l0Ih{position:absolute;width:1px;width:.0625rem;height:1px;height:.0625rem;padding:0;margin:-1px;margin:-.0625rem;overflow:hidden;clip:rect(0,0,0,0);white-space:nowrap;border-width:0}", ""]);

// exports
exports.locals = {
	"heading": "_3l0Ih"
};