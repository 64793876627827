import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import DropdownSelect from '../../../../../styledComponents/styles/DropdownSelect.styled';
import _get from 'lodash/get';
import QuestionMultiLineDisplayForm from '../multiLineText/question.multiLine.display.form';
import QuestionShortTextDisplayForm from '../shortText/question.shortText.display.form';
import QuestionFileUploadDisplayForm from '../fileUpload/question.fileUpload.display.form';
import { Controller } from 'react-hook-form';

const Container = styled.div`
  font-family: 'Nunito', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 0.015em;
  color: #373737;
`;

const InputContainer = styled.div`
  flex: 1;
  margin: 13px 0;
  text-align: left;
`;

const ChildInputContainer = styled.div`
  flex: 1;
  margin: 25px 0 25px 0;
  text-align: left;
  display: flex;
`;

const VerticalBar = styled.div`
  background-color: #d9d9d9;
  border-radius: 3px;
  flex: 0 1 10px;
  margin-right: 20px;
`;

const ChildInput = styled.div`
  flex: 0 1 100%;
`;

export default function QuestionDropdownDisplayForm({
  question,
  formState,
  errors = {},
  setValue,
  value,
  control,
  defaultValue,
  response,
  formId,
  registerOptions = false,
  isVisitor = false,
  isReadonly,
  disabled,
  isSynced = false,
  ...rest
}) {
  const [dropdownOptions, setDropdownOptions] = useState(null);
  const [savedValue, setSavedValue] = useState(null);
  const [showChildQuestion, setShowChildQuestion] = useState(false);
  const id = question.question_id.toString();
  const errorMsg = _get(errors[id], 'message') || '';
  const multi = question?.multiselect || false;
  const logicQuestion = question?.dropdown_question_logic;
  const [initialLoading, setInitialLoading] = useState(true);
  const [rnd, setRnd] = useState(0);

  const handleShowQuestion = (logic, value) => {
    setShowChildQuestion(false);
    if (
      (logic?.trigger === 'equals' && logic?.dropdown_question_option_id === value) ||
      (logic?.trigger === 'does_not_equal' && logic?.dropdown_question_option_id !== value)
    ) {
      setShowChildQuestion(true);
    }
  };

  useEffect(() => {
    let opts = [];
    if (question?.dropdown_question_options) {
      question.dropdown_question_options.sort((a, b) => {
        if (a.order < b.order) return -1;
        else if (a.order > b.order) return 1;
        return 0;
      });

      question.dropdown_question_options.forEach(q => {
        opts.push({ value: q.id, label: q.name });
      });

      if (defaultValue?.dropdown_responses) {
        let selected = [];
        defaultValue.dropdown_responses.forEach(item => {
          let findOption = opts.find(o => o.value.toString() === item.dropdown_question_option_id.toString());
          selected.push(findOption);
        });

        setSavedValue(selected);
        setRnd(Math.random());
        handleShowQuestion(logicQuestion, selected[0]?.value);
      }

      setDropdownOptions(opts);
    }
  }, [question]);

  useEffect(() => {
    let value = [];
    if (dropdownOptions) {
      if (defaultValue?.dropdown_responses) {
        value.push({
          id: defaultValue.dropdown_responses.id,
          dropdown_question_option_id: defaultValue.dropdown_responses.dropdown_question_option_id,
        });
      }

      setValue(question.question_id.toString(), value, {
        shouldValidate: false,
        shouldTouch: !initialLoading,
        shouldDirty: !initialLoading,
      });
      setInitialLoading(false);
    }
  }, [dropdownOptions]);

  return (
    <Container>
      {dropdownOptions?.length > 0 && (
        <InputContainer>
          <DropdownSelect
            key={rnd}
            aria-label={question.label}
            id={`dropdown-${question.id}`}
            instructions={question.instructions}
            label={question.label}
            options={dropdownOptions}
            openSans
            placeholder={`Select ${question.label}`}
            required={question.required}
            errorMsg={errorMsg}
            readOnly={isReadonly}
            disabled={disabled || isReadonly}
            isSynced={isSynced}
            onChange={e => {
              let values = [];
              const childMatchId = e;
              e = typeof e === 'number' ? [e] : e;

              if (defaultValue?.dropdown_responses) {
                defaultValue.dropdown_responses.forEach(dv => {
                  let findIndex = e.findIndex(item => item.toString() === dv.dropdown_question_option_id.toString());
                  if (findIndex > -1) {
                    values.push({ id: dv.id, dropdown_question_option_id: dv.dropdown_question_option_id });
                    e.splice(findIndex, 1);
                  } else {
                    values.push({ id: dv.id, _destroy: true });
                  }
                });
              }

              if (e?.length > 0) {
                e.forEach(value => {
                  values.push({ id: null, dropdown_question_option_id: value });
                });
              }

              setValue(question.question_id.toString(), values, {
                shouldValidate: true,
                shouldTouch: true,
              });

              handleShowQuestion(logicQuestion, childMatchId);
            }}
            defaultValue={savedValue}
            isMulti={multi}
          />
        </InputContainer>
      )}
      {showChildQuestion && logicQuestion?.child_question && (
        <ChildInputContainer>
          <VerticalBar />
          {logicQuestion?.child_question?.question_type_identifier === 'long_text' && (
            <ChildInput>
              <Controller
                key={logicQuestion?.child_question?.question_id}
                control={control}
                name={logicQuestion?.child_question?.question_id.toString()}
                defaultValue={
                  !!logicQuestion?.child_question?.response && logicQuestion?.child_question?.response.value
                    ? logicQuestion?.child_question?.response.value
                    : ''
                }
                rules={registerOptions[logicQuestion?.child_question?.question_id.toString()]}
                render={({ field: { ref, ...rest } }) => (
                  <QuestionMultiLineDisplayForm
                    question={logicQuestion?.child_question}
                    key={logicQuestion?.child_question?.question_id}
                    formState={formState}
                    errors={errors}
                    readOnly={isReadonly}
                    isSynced={isSynced}
                    disabled={isReadonly}
                    icon={isReadonly ? 'LockClosed' : ''}
                    {...rest}
                  />
                )}
              />
            </ChildInput>
          )}
          {logicQuestion?.child_question?.question_type_identifier === 'short_text' && (
            <ChildInput>
              <Controller
                key={logicQuestion?.child_question?.question_id}
                control={control}
                name={logicQuestion?.child_question?.question_id.toString()}
                defaultValue={
                  !!logicQuestion?.child_question?.response && logicQuestion?.child_question?.response.value
                    ? logicQuestion?.child_question?.response.value
                    : ''
                }
                rules={registerOptions[logicQuestion?.child_question?.question_id.toString()]}
                render={({ field: { ref, ...rest } }) => (
                  <QuestionShortTextDisplayForm
                    question={logicQuestion?.child_question}
                    key={logicQuestion?.child_question?.question_id}
                    formState={formState}
                    errors={errors}
                    isReadonly={isReadonly}
                    setValue={setValue}
                    isSynced={isSynced}
                    disabled={isReadonly}
                    {...rest}
                  />
                )}
              />
            </ChildInput>
          )}
          {logicQuestion?.child_question?.question_type_identifier === 'file_upload' && (
            <ChildInput>
              <Controller
                key={logicQuestion?.child_question?.question_id}
                control={control}
                name={logicQuestion?.child_question?.question_id.toString()}
                defaultValue={
                  !!logicQuestion?.child_question?.response && logicQuestion?.child_question?.response.value
                    ? logicQuestion?.child_question?.response.value
                    : ''
                }
                rules={registerOptions[logicQuestion?.child_question?.question_id.toString()]}
                render={({ field: { ref, ...rest } }) => (
                  <QuestionFileUploadDisplayForm
                    question={logicQuestion?.child_question}
                    key={logicQuestion?.child_question?.question_id}
                    formState={formState}
                    errors={errors}
                    setValue={setValue}
                    defaultValue={defaultValue}
                    formId={formId}
                    response={logicQuestion?.child_question?.response}
                    isReadonly={isReadonly}
                    isVisitor={isVisitor}
                    {...rest}
                  />
                )}
              />
            </ChildInput>
          )}
        </ChildInputContainer>
      )}
    </Container>
  );
}
