exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3zrDE{-webkit-box-sizing:content-box;box-sizing:content-box;display:block;margin:0 auto}", ""]);

// exports
exports.locals = {
	"spinner": "_3zrDE"
};