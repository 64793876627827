import React from 'react';
import { compose } from 'recompose';
import propTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import ViaModal from '../../../../components/library/viaModal';
import Headline from '../../../../components/library/headline';
import Button from '../../../../components/library/button';
import Paragraph from '../../../../components/library/paragraph';
import { getPlan } from '../../../../../actions/plans';
import sSuccessAdminRemovedGroupDetail from './successAdminRemovedGroupDetail.scss';

export const enhance = compose(withStyles(sSuccessAdminRemovedGroupDetail));

function SuccessAdminRemovedGroupDetail(props) {
  const dispatch = useDispatch();

  return (
    <>
      <ViaModal
        showing={props.show}
        onClose={() => {
          dispatch(getPlan(props.planId));
          props.onClose(false)
        }}
      >
        <div className={sSuccessAdminRemovedGroupDetail['success-admin-removed-group-detail']}>
          <div className={sSuccessAdminRemovedGroupDetail.subHeader}>
            <Headline centered tag="h1" as="h1">
              Success!
            </Headline>
          </div>
          <div className={sSuccessAdminRemovedGroupDetail.subHeader}>
            <Headline centered tag="h1" as="h2">
              THE DETAILS WERE REMOVED FROM {props.planUsers ? props.planUsers.length : ''} TRAVELERS
            </Headline>
          </div>
          <div className={sSuccessAdminRemovedGroupDetail.content}>
            <Paragraph centered size="large">
              These Travelers have been notified that this group detail has been removed from their itinerary.
            </Paragraph>
          </div>
          <div className={sSuccessAdminRemovedGroupDetail.footer}>
            <div className={sSuccessAdminRemovedGroupDetail['footer-button-wrapper']}>
              <div className={sSuccessAdminRemovedGroupDetail['button-content']}>
                <Button
                  display="primary"
                  kind="solid"
                  size="medium"
                  onClick={() => {
                    dispatch(getPlan(props.planId));
                    props.onClose(false);
                  }}
                >
                  Return to Who's Going
                </Button>
              </div>
            </div>
          </div>
        </div>
      </ViaModal>
    </>
  )
}

SuccessAdminRemovedGroupDetail.propTypes = {
  show: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
  planId: propTypes.string.isRequired,
  planUsers: propTypes.array,
};

export default enhance(SuccessAdminRemovedGroupDetail);
