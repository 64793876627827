import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import styles from './index.module.css';
import { Spinner } from 'react-bootstrap';

function TextButton({ text = '', loading = false, ...props }) {
  return (
    <button
      {...props}
      className={styles.btn}
      disabled={loading}
    >
      {loading ? (
        <>
          {' '}
          <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> Loading...{' '}
        </>
      ) : (
        <>{text}</>
      )}
    </button>
  );
}

export default withStyles(styles)(TextButton);
