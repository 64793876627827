import React, { lazy, Suspense, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import styled from 'styled-components';
import _get from 'lodash/get';

const Container = styled.div`
  font-family: 'Nunito', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 0.015em;
  color: #373737;
`;

const InputContainer = styled.div`
  flex: 1;
  font-weight: 400;
  margin: 13px 0;
  text-align: left;
`;

// const EditTextArea = lazy(() => import('../../../../../styledComponents/styles/EditTextArea.styled'));
const EditTextAreaQuill = lazy(() => import('../../../../../styledComponents/styles/EditTextAreaQuill.styled'));

export default function QuestionMultiLineDisplayForm({ question, formState, errors, isReadonly, ...rest }) {
  const id = question.question_id.toString();
  // const [isTravelerProfile, setIsTravelerProfile] = useState(false);
  // const userProfile = useSelector(state => state.profile);

  // useEffect(()=>{
  //   if (userProfile && !userProfile.userLoading) {
  //     const isTraveler = !userProfile.admin_sign_in || false;
  //     setIsTravelerProfile(isTraveler);
  //   }
  // },[userProfile])

  return (
    <Container>
      <InputContainer>
        <Suspense fallback={<span />}>
          {/* {!isTravelerProfile ?
          <EditTextArea
            aria-label={question.label}
            id={id}
            instructions={question.instructions}
            label={question.label}
            openSans
            placeholder={`Enter ${question.label}`}
            required={question.required}
            errorMsg={_get(errors[id], 'message') || ''}
            charLimit={question.character_limit}
            readOnly={isReadonly}
            disabled={isReadonly}
            toolbarHidden={false}
            {...rest}
          /> : */}
          <EditTextAreaQuill
            aria-label={question.label}
            id={id}
            instructions={question.instructions}
            label={question.label}
            openSans
            placeholder={`Enter ${question.label}`}
            required={question.required}
            errorMsg={_get(errors[id], 'message') || ''}
            charLimit={question.character_limit}
            readOnly={isReadonly}
            disabled={isReadonly}
            {...rest}
          />
        {/* } */}
        </Suspense>
      </InputContainer>
    </Container>
  );
}
