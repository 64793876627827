export default [
  {
    label: 'Not provided',
    numberValue: 5,
  },
  {
    label: 'Going no matter what',
    numberValue: 4,
  },
  {
    label: 'Likely to go',
    numberValue: 3,
  },
  {
    label: 'Maybe, if I get answers',
    numberValue: 2,
  },
  {
    label: `I'm not sure`,
    numberValue: 1,
  },
  {
    label: 'Just curious',
    numberValue: 0,
  },
];
