import Guest from '../../components/icons/Guest';
import Other from '../../components/icons/Other';
import Staff from '../../components/icons/Staff';
import Faculty from '../../components/icons/Faculty';
import Graduate from '../../components/icons/Graduate';
import Postgraduate from '../../components/icons/Postgraduate';
import Undergraduate from '../../components/icons/Undergraduate';
import SecondarySchool from '../../components/icons/SecondarySchool';

export default [
  {
    id: 1,
    label: 'High school / secondary',
    value: 'high_school',
    icon: SecondarySchool,
  },
  {
    id: 2,
    label: 'Undergraduate',
    value: 'undergraduate',
    icon: Undergraduate,
  },
  {
    id: 3,
    label: 'Graduate',
    value: 'graduate',
    icon: Graduate,
  },
  {
    id: 4,
    label: 'Postgraduate',
    value: 'post_graduate',
    icon: Postgraduate,
  },
  {
    id: 5,
    label: 'Faculty',
    value: 'faculty',
    icon: Faculty,
  },
  {
    id: 6,
    label: 'Staff',
    value: 'staff',
    icon: Staff,
  },
  {
    id: 7,
    label: 'Guest',
    value: 'guest',
    icon: Guest,
  },
  {
    id: 8,
    label: 'Other',
    value: 'other',
    icon: Other,
  },
];
