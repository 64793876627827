import React from 'react';
import PropTypes from 'prop-types';

export default function FilterMenuIcon({ fillColor }) {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
      <path
        d="M20.2812 7.21875H1.71875C1.44525 7.21875 1.18294 7.1101 0.989546 6.9167C0.796149 6.72331 0.6875 6.461 0.6875 6.1875C0.6875 5.914 0.796149 5.65169 0.989546 5.4583C1.18294 5.2649 1.44525 5.15625 1.71875 5.15625H20.2812C20.5548 5.15625 20.8171 5.2649 21.0105 5.4583C21.2038 5.65169 21.3125 5.914 21.3125 6.1875C21.3125 6.461 21.2038 6.72331 21.0105 6.9167C20.8171 7.1101 20.5548 7.21875 20.2812 7.21875Z"
        fill={fillColor}
      />
      <path
        d="M16.8438 12.0312H5.15625C4.88275 12.0312 4.62044 11.9226 4.42705 11.7292C4.23365 11.5358 4.125 11.2735 4.125 11C4.125 10.7265 4.23365 10.4642 4.42705 10.2708C4.62044 10.0774 4.88275 9.96875 5.15625 9.96875H16.8438C17.1173 9.96875 17.3796 10.0774 17.573 10.2708C17.7663 10.4642 17.875 10.7265 17.875 11C17.875 11.2735 17.7663 11.5358 17.573 11.7292C17.3796 11.9226 17.1173 12.0312 16.8438 12.0312Z"
        fill={fillColor}
      />
      <path
        d="M12.7188 16.8438H9.28125C9.00775 16.8438 8.74544 16.7351 8.55205 16.5417C8.35865 16.3483 8.25 16.086 8.25 15.8125C8.25 15.539 8.35865 15.2767 8.55205 15.0833C8.74544 14.8899 9.00775 14.7812 9.28125 14.7812H12.7188C12.9923 14.7812 13.2546 14.8899 13.448 15.0833C13.6414 15.2767 13.75 15.539 13.75 15.8125C13.75 16.086 13.6414 16.3483 13.448 16.5417C13.2546 16.7351 12.9923 16.8438 12.7188 16.8438Z"
        fill={fillColor}
      />
    </svg>
  );
}

FilterMenuIcon.propTypes = {
  fillColor: PropTypes.string,
};

FilterMenuIcon.defaultProps = {
  fillColor: 'currentColor',
};
