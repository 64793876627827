import React from 'react';
import { compose } from 'recompose';
import _omit from 'lodash/omit';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import propTypes from 'prop-types';
import classnames from 'classnames';
import sInputTextArea from './inputTextArea.scss';

export const enhance = compose(withStyles(sInputTextArea));

function InputTextArea(props) {
  const isError = props.errorMessage.length > 0;
  const inputClass = classnames(
    sInputTextArea['input-textarea'],
    sInputTextArea[props.size],
    isError ? sInputTextArea['error'] : '',
  );
  const helpLabelClass = classnames(
    sInputTextArea['label-textarea'],
    sInputTextArea[props.labelSize],
    isError ? sInputTextArea['error'] : '',
  );

  return (
    <>
      {!!props.labelText && (
        <label className={helpLabelClass} htmlFor={props.id}>
          {props.labelText} {props.isRequired ? <div style={{ color: '#f44831', display: 'inline' }}>*</div> : ''}
        </label>
      )}
      <textarea
        {..._omit(props, ['errorMessage', 'labelText', 'helpText', 'isRequired', 'labelSize'])}
        className={inputClass}
      />
      {props.helpText && !isError && <span className={sInputTextArea['help-textarea']}>{props.helpText}</span>}
      {isError && <span className={sInputTextArea['error-textarea']}>{props.errorMessage}</span>}
    </>
  );
}

InputTextArea.propTypes = {
  errorMessage: propTypes.string,
  labelText: propTypes.string,
  helpText: propTypes.string,
  placeholder: propTypes.string,
  isRequired: propTypes.bool,
  size: propTypes.oneOf(['medium', 'normal']),
  labelSize: propTypes.oneOf(['medium', 'normal']),
};

InputTextArea.defaultProps = {
  errorMessage: '',
  labelText: '',
  helpText: '',
  placeholder: '',
  isRequired: false,
  size: 'normal',
  labelSize: 'medium',
};

export default enhance(InputTextArea);
