import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import FocusTrap from 'focus-trap-react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import LocationIcon from '../icons/LocationIcon';
import SignPostIcon from '../icons/SignPostIcon';

import DashboardIcon from '../icons/DashboardIcon';
import MessageIcon from '../icons/MessageIcon';
import MyProgramIcon from '../icons/MyProgramIcon';
import SafecheckIcon from '../icons/SafecheckIcon';
import MyTravelIcon from '../icons/MytTravelIcon';
import MyEventIcon from '../icons/MyEventIcon';
import MyProfileIcon from '../icons/MyProfileIcon';

import { breakpoints } from '../../utils/breakpoints';
import getSidebarLinks from '../../utils/getSidebarLinks';
import { buttonFocus, buttonFocusInset } from '../../utils/shared/focusStyles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { IconButton } from '@material-ui/core';
import { ViaInternationalIcon, ViaGlobalIcon, ViaInternationalInactiveIcon } from '../../../../styledComponents/styles/IconCustom.styled';
import GearIcon from '../icons/GearIcon';
import { updateLastVisitedProduct } from '../../../../actions/authActions';
import { Cookies } from 'react-cookie';



const Container = styled.nav`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 100;
  padding-top: 0.7675rem;
  padding-bottom: 1.10375rem;
  background: #FFF;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.15);
  
  //border-radius: 0px 0px 25px 25px;

  @media ${breakpoints.tablet} {
    top: 80%;
    left: 5%;
    right: auto;
    padding: 2.103125rem 2.625rem 2.26875rem 2.625rem;
    border-radius: 0px 25px 25px 25px;
  }

  @media ${breakpoints.desktop} {
    top: 9%;
  }
`;

const Subheading = styled.span`
  display: block;
  margin-left: 1.125rem;
  font-family: 'Nunito', sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  color: #474747;
  letter-spacing: 0.01em;
  line-height: 19px;

  @media ${breakpoints.tablet} {
    margin-left: 0.890625rem;
  }
`;

const ExploreContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.154375rem;
  margin-bottom: 0.845625rem;
`;

const ExploreOptions = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 0.175rem;
`;

const ExploreOption = styled(NavLink)`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  gap: 0.5525rem;
  width: 11.125rem;
  padding-top: 0.8225rem;
  padding-bottom: 0.625rem;
  background-color: #ffffff;
  border-radius: 3px;

  svg {
    fill: var(--theme_color_accent);
  }

  span {
    font-family: 'Nunito', sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    color: #373737;
    letter-spacing: 0.01em;
    line-height: 19px;
  }

  ${buttonFocus};
`;

const MenuGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  padding: 3px;
`;

const MenuGridItem = styled(NavLink)`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  gap: 0.5525rem;
  // width: 11.125rem;
  padding-top: 0.8225rem;
  padding-bottom: 0.625rem;
  background-color: #ffffff;
  border-radius: 3px;

  svg {
    fill: var(--theme_color_accent);
  }

  span {
    font-family: 'Nunito', sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    color: #373737;
    letter-spacing: 0.01em;
    line-height: 19px;
  }

  ${buttonFocus};
`;

const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.154375rem;
`;

const ProfileOptions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.125rem;
`;

const ProfileOption = styled(NavLink)`
  padding: 0.65625rem 0 0.65625rem 1rem;
  background-color: #ffffff;
  border-radius: 3px;
  font-family: 'Nunito', sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  color: #373737;
  letter-spacing: 0.01em;
  line-height: 19px;

  &.active {
    border-left: 5px solid #255a94;
  }

  ${buttonFocusInset};
`;

const SubMenu = styled.div`
  padding-left: 10px;
  background: #ffffff;
`;

const SubOption = styled(NavLink)`
  padding: 0.65625rem 0 0.65625rem 1rem;
  background-color: #ffffff;
  border-radius: 3px;
  font-family: 'Nunito', sans-serif;
  border-bottom: 1px solid #eeeeee;
  font-weight: 500;
  font-size: 0.875rem;
  color: #373737;
  letter-spacing: 0.01em;
  line-height: 19px;
  display: flex;

  &.active {
    border-left: 5px solid #255a94;
  }

  ${buttonFocusInset};
`;

const ProfileOption2 = styled(NavLink)`
  font-family: 'Nunito', sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  color: #373737;
  letter-spacing: 0.01em;
  // border-bottom: 1px solid #eeeeee;
  line-height: 19px;
  padding: 0.65625rem 0 0.65625rem 1rem;
  background-color: #ffffff;
`;

const ParentMenu = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ParentMenuContainer = styled.div`
  background-color: #ffffff;
  width: 100%;
`;

const ProductContainer = styled.div``;

const Content = styled.div`
  display: flex;
  flex-direction:column;
  align-items: center;
  // margin:auto;
  gap:5px;
  // padding-left:30px;
  margin-top:10px;
`;

const IconContainer = styled.div`
    display:flex;
    flex-direction:column;
    align-items:start;

    width: 100%;
    height: 58px;
    padding-left:20px;
    
    justify-content: center;

    gap: 5.283px;
    flex-shrink: 0;
    // border-radius: 8.805px;
    background: var(--white, #FFF);
    box-shadow: 0px 3.522px 8.805px 0px rgba(0, 0, 0, 0.05);

`;

const IconLabel = styled.div`
  color: var(--gray-dark, #474747);
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 138%; /* 22.08px */
  letter-spacing: 0.016px;
  text-align: center;
  display:flex;
  justify-content: center;
  align-items: center;
`;

const LinkContainer = styled.a`
  text-decoration : none;
  text-align:center;
  display:flex;
  gap:5px;
  align-item:center;
`;

const LinkContainerInactive = styled.div`
  text-decoration : none;
  text-align:center;
  display:flex;
  gap:5px;
  align-item:center;
`;

const IconDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: self-start;
`;

const Description = styled.div`
  color: var(--gray-medium3, #666);
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 138%;
  letter-spacing: 0.014px;
`;


const SettingButton = styled(NavLink)`
  display: flex;
  width: 80%;
  height: 44px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 15px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid var(--secondary-darkest, #446372);
  background: var(--white, #FFF);
`;
const SettingIcon = styled.div`
  
`;
const SettingLabel = styled.div`
  color: var(--black, #373737);
  /* Button Primary */
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 19.2px */
  letter-spacing: 0.96px;
`;

function getIcon(name) {
  if (name === 'dashboard') {
    return <DashboardIcon />;
  }

  if (name === 'message') {
    return <MessageIcon />;
  }

  if (name === 'my-programs') {
    return <MyProgramIcon />;
  }

  if (name === 'my-travel') {
    return <MyTravelIcon />;
  }

  if (name === 'safecheck') {
    return <SafecheckIcon />;
  }

  if (name === 'my-events') {
    return <MyEventIcon />;
  }

  if (name === 'my-profile') {
    return <MyProfileIcon />;
  }
}

export default function MobileNavigation({ isOpen }) {
  const dispatch = useDispatch(); 
  const cookies = new Cookies(); 
  const { clientFeatureList, customAliases } = useSelector(state => state.currentOrganization);
  const { inboundOrgData } = useSelector(state => state.travelerProfile);
  const { currentUser } = useSelector(state => state.profile);
  const [openSubMenu, setOpenSubMenu] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [showTravelerMenu, setShowTravelerMenu] = useState(false);
  const internationalProduct = inboundOrgData?.organizationProducts?.find(item=>item.name=="INBOUND");

  useEffect(()=>{
    if((clientFeatureList?.legacy && clientFeatureList?.travel_plans) || (!clientFeatureList?.legacy && clientFeatureList?.travel_plans)){
      setShowTravelerMenu(true)
    }
  },[clientFeatureList])

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 767px)');
    const handleMediaQueryChange = mq => {
      setIsSmallScreen(mq.matches);
    };

    mediaQuery.addListener(handleMediaQueryChange);
    handleMediaQueryChange(mediaQuery);

    return () => {
      mediaQuery.removeListener(handleMediaQueryChange);
    };
  }, []);

  const sidebarLinks = getSidebarLinks(
    clientFeatureList.legacy,
    clientFeatureList.travel_plans,
    customAliases.alias_programs,
  );

  const currentUrl = window.location.href;
  // Regular expression to extract subdomain and domain
  const subdomainRegex = /\/\/([^/]+)\//;
  // Extract the subdomain and domain
  const match = currentUrl.match(subdomainRegex);
  const subdomain = match ? match[1] : null;
  // Split the domain into second-level domain and top-level domain
  const domainParts = subdomain ? subdomain.split('.') : [];
  const secondLevelDomain = domainParts.length >= 2 ? domainParts[0] : null;
  const topLevelDomain = domainParts.length >= 2 ? domainParts.slice(1).join('.') : null;

  return (
    <FocusTrap active={isOpen} focusTrapOptions={{ clickOutsideDeactivates: true }}>
      <Container>
        <div style={{background: "#eeeeee"}}>
        <ExploreContainer>
          {!isSmallScreen && <Subheading>Explore</Subheading>}

          {!isSmallScreen && (
            <ExploreOptions>
              <ExploreOption to="/traveler/program-dashboard">
                <SignPostIcon />
                <span>{customAliases.alias_programs}</span>
              </ExploreOption>
              {showTravelerMenu && (
              <ExploreOption to="/plans/group-travel-search">
                <LocationIcon />
                <span>Travel Plans</span>
              </ExploreOption>
              )}
            </ExploreOptions>
          )}

          {isSmallScreen && (
            <MenuGrid style={{
                gridTemplateColumns : showTravelerMenu ? "repeat(2,1fr)" : "auto", 
                width: showTravelerMenu ? "" : "50%", 
                margin: showTravelerMenu ? "" : "auto"
              }}
            >
              <MenuGridItem  to="/traveler/program-dashboard">
                <SignPostIcon />
                <span>{customAliases.alias_programs}</span>
              </MenuGridItem>
              {showTravelerMenu && (
                <MenuGridItem to="/plans/group-travel-search">
                  <LocationIcon />
                  <span>Travel Plans</span>
                </MenuGridItem>
              )}
            </MenuGrid>
          )}
        </ExploreContainer>
        <ProfileContainer>
          {isSmallScreen && (
            <>
              <ExploreContainer>
                <MenuGrid>
                  {sidebarLinks.map(sidebarLink => {
                    return (
                      <>
                        {!sidebarLink.submenu && (
                          <MenuGridItem to={sidebarLink.path} key={`sidebar-link-${sidebarLink.label}`}>
                            {getIcon(sidebarLink.name)}
                            <span>{sidebarLink.label}</span>
                          </MenuGridItem>
                        )}

                        {sidebarLink?.menuList?.map(submenu => (
                          <MenuGridItem to={submenu.path} key={`sidebar-link-submenu-${submenu.label}`}>
                            {getIcon(submenu.name)}
                            <span>{submenu.label}</span>
                          </MenuGridItem>
                        ))}
                      </>
                    );
                  })}
                </MenuGrid>
              </ExploreContainer>
            </>
          )}

          {!isSmallScreen && <Subheading>My Profile</Subheading>}

          {!isSmallScreen && (
            <ProfileOptions>
              {sidebarLinks.map(sidebarLink => {
                if (!sidebarLink.submenu) {
                  return (
                    <ProfileOption
                      key={`sidebar-link-${sidebarLink.id}`}
                      to={sidebarLink.path}
                      isActive={match => match && match.url.includes('profile')}
                    >
                      {sidebarLink.label}
                    </ProfileOption>
                  );
                } else {
                  return (
                    <ParentMenuContainer>
                      <ParentMenu>
                        <ProfileOption2
                          to={sidebarLink.path}
                          key={`sidebar-link-${sidebarLink.id}`}
                          isActive={match => match && match.url.includes('profile')}
                        >
                          {sidebarLink.label}
                        </ProfileOption2>

                        <IconButton aria-label={openSubMenu ? `${sidebarLink.label} Toggle expanded` : `${sidebarLink.label} Toggle collapsed`} onClick={() => setOpenSubMenu(!openSubMenu)}>
                          {openSubMenu ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                      </ParentMenu>

                      {openSubMenu && (
                        <SubMenu>
                          {sidebarLink.menuList.map(submenuLink => (
                            <SubOption
                              key={`sidebar-link-${submenuLink.id}`}
                              to={submenuLink.path}
                              isActive={match => match && match.url.includes('profile')}
                            >
                              {submenuLink.label}
                            </SubOption>
                          ))}
                        </SubMenu>
                      )}
                    </ParentMenuContainer>
                  );
                }
              })}
            </ProfileOptions>
          )}
        </ProfileContainer>
          {clientFeatureList?.inbound  && (  
              <ProductContainer style={{borderTop: isSmallScreen ? "1px solid var(--gray-medium1, #BBB)" : ""}}>
                <Content> 
                  <IconContainer>
                    <LinkContainer  style={{textDecoration:"none" ,cursor:"default"}}>
                      <ViaGlobalIcon width="30" height="30" />
                      <IconLabel>Via Global</IconLabel>
                    </LinkContainer>
                  </IconContainer>
                  { currentUser?.via_international ?
                  <IconContainer style={{marginBottom:"10px"}}>
                    <LinkContainer href={`https://${secondLevelDomain}.inbound.${topLevelDomain}`} target="_blank" style={{textDecoration:"none"}} onClick={() => dispatch(updateLastVisitedProduct(cookies.get("user_id"), "via_international", "traveler"))}>
                      <IconDiv>
                        <ViaInternationalIcon width="30" height="30" />
                      </IconDiv>
                      <LabelContainer>
                      <IconLabel>{internationalProduct?.label ? internationalProduct?.label : "Via International" }</IconLabel>
                      <Description>{internationalProduct?.description ? internationalProduct?.description : "" }</Description>
                      </LabelContainer>
                    </LinkContainer>
                  </IconContainer>
                  :
                  <IconContainer style={{marginBottom:"10px"}}>
                    <LinkContainerInactive>
                      <IconDiv>
                        <ViaInternationalInactiveIcon width="30" height="30" />
                      </IconDiv>
                      <LabelContainer>
                      <IconLabel style={{color: "var(--gray-medium2, #999)", textDecoration:"none"}}>{internationalProduct?.label ? internationalProduct?.label : "Via International" }</IconLabel>
                      <Description style={{color: "var(--gray-medium2, #999)", textDecoration:"none"}}>{internationalProduct?.description ? internationalProduct?.description : "" }</Description>
                      </LabelContainer>
                    </LinkContainerInactive>
                  </IconContainer>
                  }
                </Content>
              </ProductContainer>
          )}
        </div>  
          <ProductContainer style={{borderTop: isSmallScreen ? "1px solid var(--gray-medium1, #BBB)" : ""}}>
          <Content style={{paddingTop:"10px"}}> 
            <SettingButton to="/traveler/traveler-settings">
              <SettingIcon><GearIcon /></SettingIcon>
              <SettingLabel>Account Settings</SettingLabel>
            </SettingButton>
          </Content>
        </ProductContainer>
        
      </Container>
    </FocusTrap>
  );
}

MobileNavigation.propTypes = {
  isOpen: PropTypes.bool,
};

MobileNavigation.defaultProps = {
  isOpen: false,
};
