import No from '../../components/icons/No';
import Yes from '../../components/icons/Yes';
import DoNotKnow from '../../components/icons/DoNotKnow';

export default [
  {
    id: 1,
    label: 'Yes',
    value: 'yes',
    icon: Yes,
  },
  {
    id: 2,
    label: 'No',
    value: 'no',
    icon: No,
  },
  {
    id: 3,
    label: `I'm not sure`,
    value: 'not_sure',
    icon: DoNotKnow,
  },
];
