import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import StarRibbonIcon from '../icons/StarRibbonIcon';
import ProgramMatchSection from './ProgramMatchSection';

const List = styled.ol`
  padding-inline-start: 25px;
`;

export default function ProgramPrioritiesPreferences() {
  const programMatch = useSelector(state => state.programMatchForm);

  return (
    <ProgramMatchSection heading="Participation Priorities" icon={StarRibbonIcon} justifyCenter>
      {programMatch.priorities.length === 0 ? (
        <p>None selected</p>
      ) : (
        <List>
          {programMatch.priorities.map(priority => (
            <li key={`participation-priority-${priority.value}`}>{priority.text}</li>
          ))}
        </List>
      )}
    </ProgramMatchSection>
  );
}
