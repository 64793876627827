import moment from 'moment-timezone';

export const getRelativeTime = (datetime, timezone = '') => {
  let referenceDate = moment(datetime);
  let currentDate = moment();

  if (timezone) {
    referenceDate = moment(datetime).tz(timezone);
    currentDate = moment().tz(timezone);
  }

  const differenceInMinutes = moment.duration(currentDate.diff(referenceDate)).asMinutes();
  const differenceInHours = moment.duration(currentDate.diff(referenceDate)).asHours();
  const differenceInDays = moment.duration(currentDate.diff(referenceDate)).asDays();

  let result = '';

  if (differenceInMinutes < 1) {
    result = 'Just now';
  } else if (differenceInMinutes < 60) {
    const minutesAgo = Math.floor(differenceInMinutes);
    if (Number(minutesAgo) === 1) {
      result = `${minutesAgo} minute ago`;
    } else {
      result = `${minutesAgo} minutes ago`;
    }
  } else if (differenceInHours < 24) {
    const hoursAgo = Math.floor(differenceInHours);

    if (Number(hoursAgo) === 1) {
      result = `${hoursAgo} hour ago`;
    } else {
      result = `${hoursAgo} hours ago`;
    }
  } else if (differenceInDays < 30) {
    const daysAgo = Math.floor(differenceInDays);

    if (Number(daysAgo) === 1) {
      result = `${daysAgo} day ago`;
    } else {
      result = `${daysAgo} days ago`;
    }
  } else {
    const formattedDate = referenceDate.format('YYYY-MM-DD');
    result = formattedDate;
  }

  return result;
};

export const getLevelColor = level => {
  level = level.toUpperCase();
  let bgColor = '';
  let frontColor = '';

  switch (level) {
    case 'EXTREME':
      bgColor = '#8B1313';
      frontColor = '#FFFFFF';
      break;

    case 'HIGH':
      bgColor = '#DE653F';
      frontColor = '#FFFFFF';
      break;

    case 'MEDIUM':
      bgColor = '#FAD599';
      frontColor = '#373737';
      break;

    case 'MODERATE':
      bgColor = '#B4E9B0';
      frontColor = '#373737';
      break;

    case 'LOW':
      bgColor = '#2A8A53';
      frontColor = '#FFFFFF';

    case 'LEVEL':
      bgColor = '#999999';
      frontColor = '#FFFFFF';

    default:
      break;
  }

  return {
    bgColor,
    frontColor,
  };
};

export const getLocationName = impacted_areas_json => {
  let locationArr = [];
  if (Array.isArray(impacted_areas_json) && impacted_areas_json.length > 0) {
    let city = impacted_areas_json.filter(i => i.type === 'city');
    if (city.length > 0) {
      locationArr.push(city[0]?.name);
    }

    let region = impacted_areas_json.filter(i => i.type === 'region');
    if (region.length > 0) {
      locationArr.push(region[0]?.name);
    }

    let country = impacted_areas_json.filter(i => i.type === 'country');
    if (country.length > 0) {
      locationArr.push(country[0]?.name);
    }
  }

  return locationArr.join(', ');
};

export const getCountryName = impacted_areas_json => {
  let countryName = '';
  if (Array.isArray(impacted_areas_json) && impacted_areas_json.length > 0) {
    let country = impacted_areas_json.filter(i => i.type === 'country');
    if (country.length > 0) {
      countryName = country[0]?.name;
    }
  }

  return countryName;
};

export const getCountryAndCity = (impacted_areas_json, format = ', ') => {
  let locationArr = [];
  if (Array.isArray(impacted_areas_json) && impacted_areas_json.length > 0) {
    let country = impacted_areas_json.filter(i => i.type === 'country');
    if (country.length > 0) {
      locationArr.push(String(country[0]?.name).toUpperCase());
    }

    let city = impacted_areas_json.filter(i => i.type === 'city');
    if (city.length > 0) {
      locationArr.push(String(city[0]?.name).toUpperCase());
    }
  }

  return locationArr.join(format);
};

export const getRiskFormattedDateTime = (time, timezone = '') => {
  if (timezone) {
    return moment(time)
      .tz(timezone)
      .format('MMM D, YYYY [at] h:mm a z');
  }

  return moment(time).format('MMM D, YYYY [at] h:mm a z');
};

export const getCurrentLocation = () => {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          let latitude = position.coords.latitude;
          let longitude = position.coords.longitude;
          resolve({
            latitude,
            longitude,
          });
        },
        error => {
          if (error.code === 1) {
            reject('Location access disabled');
          } else {
            reject(error.message);
          }
        },
      );
    } else {
      reject('Cannot access location');
    }
  });
};

export const isUnreadAlert = alerts => {
  let is_new_message = false;

  for (let i = 0; i < alerts.length; i++) {
    if (alerts[i]?.attributes?.read === false) {
      is_new_message = true;
    }
  }

  return is_new_message;
};

export const generateQueryString = obj => {
  let url = '';
  let strArr = [];
  for (let key in obj) {
    strArr.push(`${key}=${obj[key]}`);
  }

  url = strArr.join('&');
  return url;
};

export const getCoordinatesFromAlert = areas => {
  const geoJson = areas.find(i => i.type === 'geojson');

  if (geoJson) {
    let feature = geoJson?.content?.features?.find(i => i.type === 'Feature');
    if (feature) {
      let coordinates = feature?.geometry?.coordinates;
      if (coordinates?.length > 0) {
        return {
          latitude: coordinates[1],
          longitude: coordinates[0],
        };
      }
    }
  }

  return null;
};

export const extractEmergencyNumbers = emergency_numbers => {
  let numbers = [];

  let police = emergency_numbers?.find(i => i.type === 'Police');

  if (police) {
    numbers.push({
      type: 'POLICE',
      number: police.number,
      additional: police?.additional,
    });
  }

  let medical = emergency_numbers?.find(i => i.type === 'Medical');

  if (medical) {
    numbers.push({
      type: 'MEDICAL',
      number: medical.number,
      additional: medical?.additional,
    });
  }

  let fire = emergency_numbers?.find(i => i.type === 'Fire');

  if (fire) {
    numbers.push({
      type: 'FIRE',
      number: fire.number,
      additional: fire?.additional,
    });
  }

  if (numbers.length === 3) {
    return numbers;
  }

  let hazard = emergency_numbers?.find(i => i.type === 'Hazards');

  if (hazard) {
    let hazardType = 'HAZARDS';

    if (hazard?.additional !== '-') {
      hazardType = `${hazardType} (${hazard.additional})`;
    }

    numbers.push({
      type: hazardType,
      number: hazard.number,
      additional: hazard.additional,
    });
  } else {
    let other = emergency_numbers.find(i => i.type === 'Other');

    if (other) {
      let otherType = 'OTHER';

      if (other?.additional !== '-') {
        otherType = `${otherType} (${other.additional})`;
      }

      numbers.push({
        type: otherType,
        number: other.number,
        additional: other.additional,
      });
    }
  }

  return numbers;
};

export const getTravelerPreferredName = traveler => {
  let name = '';

  let fName = traveler?.preferred_first_name ? traveler?.preferred_first_name : "";
  if(!traveler?.preferred_first_name){
    fName = traveler?.user_first_name ? traveler?.user_first_name : "";
  }

  let lName = traveler?.preferred_last_name ? traveler?.preferred_last_name : "";
  if(!traveler?.preferred_last_name){
    lName = traveler?.user_last_name ? traveler?.user_last_name : "";
  }

  if(fName || lName){
    name = fName;
    if(lName){
    	name+= " "+lName;
    }
  }
  else if (traveler?.email){
    name = traveler?.email;
  }

  // if (traveler?.preferred_first_name || traveler?.preferred_last_name) {
  //   name = `${traveler?.preferred_first_name} ${traveler?.preferred_last_name}`;
  // } 
  // else if (traveler?.email) {
  //   name = traveler?.email;
  // } else {
  //   name = `${traveler?.user_first_name} ${traveler?.user_last_name}`;
  // }

  return name;
};

export const isAllAlertsRead = riskAlerts => {
  let allRead = true;

  for (let i = 0; i < riskAlerts?.length; i++) {
    if (!riskAlerts[i]?.attributes?.read) {
      allRead = false;
      break;
    }
  }

  return allRead;
};


export const capitalizeFirstLetter = (str) => {
  // Check if the input is not an empty string
  if (str?.length === 0) {
      return str;
  }

  // Convert the first letter to uppercase and concatenate the rest of the string
  return str?.charAt(0)?.toUpperCase() + str?.slice(1);
}
