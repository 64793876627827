import React from 'react';
import styled from 'styled-components';

const Asterisk = styled.span`
  margin-left: 2px;
  color: #8b1313;
`;

export default function ErrorRequired() {
  return <Asterisk aria-label="Required" role="status">*</Asterisk>;
}
