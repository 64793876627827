exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1gTP4{padding:30px 45px}._1-i7B{font-family:Nunito;font-size:25px;font-weight:400;line-height:30px;letter-spacing:0;text-align:left;margin-bottom:0}._2rM9O{gap:64px;padding:25px 26px}._1UAtl{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row}._3Muqm{font-weight:400}._2la1M,._3Muqm{font-family:Open Sans,sans-serif;font-size:16px;line-height:22.4px;letter-spacing:.5%}._2la1M{font-weight:700;margin-top:20px}._2la1M a,._2la1M a:hover{color:#255a94}._2lduv{font-family:Open Sans;font-size:16px;font-weight:400;line-height:22.4px;letter-spacing:.005em;text-align:left}._1bV1n{padding:0}", ""]);

// exports
exports.locals = {
	"container": "_1gTP4",
	"heading": "_1-i7B",
	"headerTitle": "_2rM9O",
	"modalBodycontainer": "_1UAtl",
	"colspancontent": "_3Muqm",
	"colpcontent": "_2la1M",
	"text": "_2lduv",
	"modalBody": "_1bV1n"
};