export const generateUniqueId = () => {
  const timestamp = Date.now().toString(36); // Convert timestamp to base36
  const randomString = Math.random()
    .toString(36)
    .substring(2, 8); // Generate random string

  return timestamp + randomString;
};

export const getMissingNumbers = (array1, array2) => {
  const numericArray1 = array1.map(element => Number(element));
  const numericArray2 = array2.map(element => Number(element));

  const missingElements = [];

  numericArray1.forEach(element => {
    if (!numericArray2.includes(element)) {
      missingElements.push(element);
    }
  });

  return missingElements;
};

export const getDaysArrayByMonth = month => {
  let daysArray = [];
  let maxDay = 31;
  if (month == '02') {
    maxDay = 29;
  } else if (['04', '06', '09', '11'].includes(month)) {
    maxDay = 30;
  }

  for (let i = 1; i <= maxDay; i++) {
    let day = i <= 9 ? `0${i}` : i;
    let dayObj = { label: day, value: day };
    daysArray.push(dayObj);
  }

  return daysArray;
};

export const monthsArrayOptions = [
  { label: 'Jan', value: '01', ariaLabel: 'January' },
  { label: 'Feb', value: '02', ariaLabel: 'February' },
  { label: 'Mar', value: '03', ariaLabel: 'March' },
  { label: 'Apr', value: '04', ariaLabel: 'April' },
  { label: 'May', value: '05', ariaLabel: 'May' },
  { label: 'Jun', value: '06', ariaLabel: 'June' },
  { label: 'Jul', value: '07', ariaLabel: 'July' },
  { label: 'Aug', value: '08', ariaLabel: 'August' },
  { label: 'Sep', value: '09', ariaLabel: 'September' },
  { label: 'Oct', value: '10', ariaLabel: 'October' },
  { label: 'Nov', value: '11', ariaLabel: 'November' },
  { label: 'Dec', value: '12', ariaLabel: 'December' },
];

export const getYearArrayOptions = (minLimit=20, maxLimit=20) => {
  const date = new Date();
  let year = date.getFullYear();
  const yearArray = [];

  let pastYear = year - minLimit;
  let futureYear = parseInt(year) + maxLimit;

  for (let i = pastYear; i <= futureYear; i++) {
    let yearObj = { label: i, value: i };
    yearArray.push(yearObj);
  }

  return yearArray;
};

export const getTimeHour = () => {
  let timeArray = [];
  for (let i = 1; i <= 12; i++) {
    let time = i <= 9 ? `0${i}` : i;
    let timeObj = { label: time, value: time };
    timeArray.push(timeObj);
  }

  return timeArray;
};

export const getTimeMinute = () => {
  let timeArray = [];
  for (let i = 0; i <= 59; i++) {
    let time = i <= 9 ? `0${i}` : i;
    let timeObj = { label: time, value: time };
    timeArray.push(timeObj);
  }

  return timeArray;
};

export const timeMeridiem = [
  { label: 'AM', value: 'AM', ariaLabel: 'AM' },
  { label: 'PM', value: 'PM', ariaLabel: 'PM' },
]


export const stripHtml = (html) => {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.body.textContent || "";
}