import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import ChevronLeftIcon from '../icons/ChevronLeftIcon';

import { breakpoints } from '../../utils/breakpoints';
import { buttonFocus } from '../../utils/shared/focusStyles';

const Back = styled(Link)`
  display: none;
  padding: 0.84375rem 1.78125rem;
  background-color: #eeeeee;
  border: 1px solid #373737;
  border-radius: 5px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 1rem;
  color: #373737;
  letter-spacing: 0.06em;
  line-height: 120%;

  &:hover {
    color: #373737;
    text-decoration-line: underline;
  }

  @media ${breakpoints.desktop} {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.765625rem;
  }

  ${buttonFocus};
`;

export default function BackLink({ url, text }) {
  return (
    <Back to={url}>
      <ChevronLeftIcon />
      <span>{text}</span>
    </Back>
  );
}

BackLink.propTypes = {
  url: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};
