import React, { useCallback, useState } from 'react';
import _debounce from 'lodash/debounce';
import styled, { css } from 'styled-components';
import StyledIcon from '../styles/Icon.styled';
import Tooltip from './Tooltip.styled';

const openSansFont = css`
  font-family: ${({ theme }) => theme.textInput.fontFamilyOpenSans};
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const InputInnerGroup = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const InputLabel = styled.label`
  color: ${({ theme }) => theme.textSearch.label.fontColor};
  font-family: ${({ theme }) => theme.textSearch.label.fontFamily};
  font-size: ${({ theme }) => theme.textSearch.label.fontSize};

  ${({ openSans }) => (openSans ? openSansFont : null)};
`;

const InputField = styled.input`
  background-color: ${({ theme }) => theme.textSearch.backgroundColor};
  border: ${props =>
    props.errorMsg && props.errorMsg.length > 0
      ? ({ theme }) => theme.textSearch.error.border
      : ({ theme }) => theme.textSearch.border};
  border-bottom: ${({ theme }) => theme.textSearch.borderBottom};
  color: ${({ theme }) => theme.textSearch.fontColor};
  font-family: ${({ theme }) => theme.textSearch.fontFamily};
  font-size: ${({ theme }) => theme.textSearch.fontSize};
  height: ${({ theme }) => theme.textSearch.height};
  margin-bottom: 0;
  line-height: ${({ theme }) => theme.textSearch.lineHeight};
  padding: ${({ theme }) => theme.textSearch.padding};
  width: 100%;

  ${({ openSans }) => (openSans ? openSansFont : null)} &:disabled {
    border: ${({ theme }) => theme.textSearch.disabled.border};
    background-color: ${({ theme }) => theme.textSearch.disabled.backgroundColor};
    color: ${({ theme }) => theme.textSearch.disabled.fontColor};
    cursor: not-allowed;
  }

  &:focus {
    outline-color: ${({ theme }) => theme.textSearch.focus.outlineColor};
  }

  &::placeholder {
    color: ${({ theme }) => theme.textSearch.placeholder.fontColor};
    font-family: ${({ theme }) => theme.textSearch.placeholder.fontFamily};
    font-size: ${({ theme }) => theme.textSearch.placeholder.fontSize};
    font-style: ${({ theme }) => theme.textSearch.placeholder.fontStyle};

    ${({ openSans }) => (openSans ? openSansFont : null)};
  }
`;

const Error = styled.span`
  color: ${({ theme }) => theme.textSearch.error.fontColor};
  font-family: ${({ theme }) => theme.textSearch.error.fontFamily};
  font-size: ${({ theme }) => theme.textSearch.error.fontSize};
  position: relative;
  top: 5px;

  ${({ openSans }) => (openSans ? openSansFont : null)} svg {
    justify-content: center;
    margin-right: 5px;
    position: relative;
    top: -2px;
  }
`;

const IconDiv = styled.div`
  color: ${({ theme }) => theme.textSearch.disabled.fontColor};
  position: relative;
  right: 28px;
  top: 9px;
  width: 0;
`;

export default function TextSearch({
  disabled,
  errorMsg,
  id,
  label,
  placeholder,
  tooltip,
  debounceValue,
  value,
  openSans,
  ...props
}) {
  const [openSansFont, setOpenSansFont] = useState(openSans);
  const DEBOUNCE_DELAY = 300;

  const debouncedSave = useCallback(_debounce(newValue => debounceValue(newValue), DEBOUNCE_DELAY), []);

  const updateDebounceValue = newValue => {
    debouncedSave(newValue);
  };

  return (
    <InputGroup>
      {label && <InputLabel htmlFor={id}>{label}</InputLabel>}
      <InputInnerGroup>
        <InputField
          aria-invalid={errorMsg && errorMsg.length > 0 ? 'true' : 'false'}
          aria-label={label || 'Text search input'}
          disabled={disabled}
          errorMsg={errorMsg}
          id={id}
          onChange={input => updateDebounceValue(input.target.value)}
          placeholder={placeholder}
          type='text'
          value={value}
          openSans={openSansFont}
          {...props}
        />
        <Tooltip toolTipText={tooltip || 'Search'}>
          <IconDiv>
            <StyledIcon type='Search' size='20px' />
          </IconDiv>
        </Tooltip>
      </InputInnerGroup>

      {errorMsg &&
        errorMsg.length > 0 && (
          <Error>
            <StyledIcon type='Warning' size='16px' />
            {errorMsg}
          </Error>
        )}
    </InputGroup>
  );
}
