import React from 'react';
import styled from 'styled-components';

const RadioWrapper = styled.div`
  display: inline-block;
`;

const Mark = styled.span`
  border: 1px solid ${({ theme }) => theme.radioButton.borderColor};
  ${props => props.type !== 'accent'} {
    border: 1px solid var(--theme_color_accent, ${({ theme }) => theme.radioButton.borderColor});
  }
  border-radius: 50%;
  display: inline-block;
  height: 14px;
  left: 0;
  margin-right: 5px;
  position: relative;
  vertical-align: middle;
  width: 14px;

  &::after {
    background-color: ${({ theme }) => theme.radioButton.checkedColor};
    ${props => props.type !== 'accent'} {
      background-color: var(--theme_color_accent, ${({ theme }) => theme.radioButton.checkedColor});
    }
    border-radius: 50%;
    content: '';
    display: block;
    height: 0;
    left: 50%;
    opacity: 0;
    position: absolute;
    top: 50%;
    transition: all 110ms;
    width: 0;
  }
`;

const Input = styled.input`
  display: none;
  position: absolute;
  visibility: hidden;
  &:checked + ${Mark} {
    &::after {
      height: 10px;
      left: 1px;
      opacity: 1;
      top: 1px;
      width: 10px;
    }
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

const Label = styled.label`
  align-items: center;
  color: ${({ theme }) => theme.radioButton.fontColor};
  cursor: pointer;
  display: flex;
  font-family: ${({ theme }) => theme.radioButton.fontFamily};
  font-size: ${({ theme }) => theme.radioButton.fontSize};
  line-height: ${({ theme }) => theme.radioButton.lineHeight};
  padding: 5px 10px 5px 0;
  position: relative;
  ${props =>
    props.disabled &&
    `
    cursor: not-allowed;
    opacity: 0.4;
  `};
`;

const RadioButton = ({ children, defaultChecked, disabled, name, onChange, type, value,id, ...props }) => (
  <RadioWrapper>
    <Label
      disabled={disabled}
      htmlFor={`${name}-${value}`}
      onKeyDown={e => {
        if (e.keyCode === 13) {
          onChange(value);
        }
      }}
      tabIndex={!disabled ? '0' : null}
    >
      <Input
        checked={defaultChecked}
        disabled={disabled}
        name={name}
        type="radio"
        id={`${name}-${value}`}
        value={value}
        onChange={() => onChange(value)}
        onClick={() => onChange(value)}
        {...props}
      />
      <Mark type={type} />
      {children}
    </Label>
  </RadioWrapper>
);

export default RadioButton;
