exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@-webkit-keyframes _25qoS{to{-webkit-transform:rotate(1turn)}}@keyframes _25qoS{to{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}._1xWA6{background:rgba(0,0,0,.65)}._2-wCN{border-radius:15px;max-width:1000px;width:100%}._2DhJH{margin-top:5.8px;margin-right:20.8px}._2DhJH svg{height:36px;margin-top:3.5px;width:36px;fill:#373737}._3RNYQ{margin-top:20px;margin-left:-19px;margin-right:-19px}._1TDEu{padding-left:20.8px;padding-right:10.8px}._14Sfv{text-align:center;margin-top:100px}._14Sfv ._1yH40{position:absolute;height:210px;top:calc(50% - 355px);width:calc(100% - 40px)}._14Sfv ._1yH40 img{width:210px;height:210px}@media only screen and (max-width:599px){._14Sfv ._1yH40{left:230px;bottom:475px}}._14Sfv ._22bI6{position:absolute;height:210px;top:calc(50% - 320px);width:calc(100% - 40px)}._14Sfv ._22bI6 img{width:210px;height:210px}@media only screen and (max-width:599px){._14Sfv ._22bI6{top:calc(50% - 370px);left:10px;bottom:475px}}.kXpbQ{float:right;border:none;padding:0;background:none}._1Fuqh{display:-ms-flexbox;display:flex;-ms-flex-pack:justify;justify-content:space-between;-ms-flex-direction:row;flex-direction:row}", ""]);

// exports
exports.locals = {
	"customOverlay": "_1xWA6",
	"customModal": "_2-wCN",
	"customCloseButton": "_2DhJH",
	"via-modal": "_3RNYQ",
	"via-modal-title": "_1TDEu",
	"via-modal-title-image": "_14Sfv",
	"headerImage": "_1yH40",
	"templateSaveWarningHeaderImage": "_22bI6",
	"closeButton": "kXpbQ",
	"modalHeader": "_1Fuqh",
	"spin": "_25qoS"
};