import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import FocusTrap from 'focus-trap-react';

import { Container, ModalBackdrop, ModalCloseButton, ModalContainer, ModalHeader, ModalPanel, ModalTitle } from './Modal.styled';

export default function Modal({ show, children, close, title, hideTitleOnDesktop, maxWidth, showOverflow }) {
  useEffect(
    () => {
      if (show) {
        document.body.style.setProperty('overflow', 'hidden');
      } else {
        document.body.style.setProperty('overflow', 'scroll');
      }
    },
    [show],
  );

  const handleKeyDown = event => {
    if (event.key === 'Escape') {
      close();
    }
  };

  return (
    <Container show={show} role="dialog" aria-modal="true" aria-labelledby="modal_title" onKeyDown={handleKeyDown}>
      <ModalBackdrop aria-hidden="true" />
      <FocusTrap active={show}>
        <ModalContainer>
          <ModalPanel maxWidth={maxWidth} showOverflow={showOverflow}>
            <ModalHeader showBorder={!!title} hideTitleOnDesktop={hideTitleOnDesktop}>
              {title && <ModalTitle id="modal_title" hideTitleOnDesktop={hideTitleOnDesktop}>{title}</ModalTitle>}
              <ModalCloseButton type="button" aria-label="Close Modal" onClick={close}>
                <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24" aria-hidden="true">
                  <path
                    d="M24.5 2.417 22.083 0 12.5 9.583 2.917 0 .5 2.417 10.083 12 .5 21.583 2.917 24l9.583-9.583L22.083 24l2.417-2.417L14.917 12 24.5 2.417Z"
                    fill="#9FABAE"
                  />
                </svg>
              </ModalCloseButton>
            </ModalHeader>
            {children}
          </ModalPanel>
        </ModalContainer>
      </FocusTrap>
    </Container>
  );
}

Modal.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  maxWidth: PropTypes.string,
  showOverflow: PropTypes.bool,
  show: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  hideTitleOnDesktop: PropTypes.bool,
};

Modal.defaultProps = {
  title: '',
  children: [],
  maxWidth: '',
  showOverflow: false,
  hideTitleOnDesktop: false,
};
