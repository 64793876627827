import React, { useState, useEffect } from 'react';
import { componentFromProp, compose, defaultProps } from 'recompose';
import classnames from 'classnames';
import propTypes from 'prop-types';
import PlacesAutocomplete, { geocodeByAddress, getLatLng, geocodeByPlaceId } from 'react-places-autocomplete';
import _each from 'lodash/each';
import _get from 'lodash/get';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import InputText from '../inputText';
import Paragraph from '../paragraph';
import sLocationSearch from './locationSearch.scss';

export const enhance = compose(withStyles(sLocationSearch));

function LocationSearch(props) {
  const [address, setAddress] = useState('');
  const [searchOptions, setSearchOptions] = useState({});
  const handleChange = address => {
    setAddress(address);
  };

  const getPlace = async placeId => {
    return geocodeByPlaceId(placeId)
      .then(results => {
        let components = {};
        let formatAddress = {};

        components.formatted_address = results[0].formatted_address;
        _each(results[0].address_components, function(k, v1) {
          _each(results[0].address_components[v1].types, function(k2, v2) {
            components[`${results[0].address_components[v1].types[v2]}_long`] =
              results[0].address_components[v1].long_name;
            components[`${results[0].address_components[v1].types[v2]}_short`] =
              results[0].address_components[v1].short_name;
          });
        });

        formatAddress.google_place_id = placeId;
        formatAddress.country_alpha2_code = _get(components, 'country_short') || '';
        formatAddress.country_common_name = _get(components, 'country_long') || '';
        formatAddress.county_or_region = _get(components, 'administrative_area_level_2_long') || '';
        formatAddress.formatted_address = _get(components, 'formatted_address') || '';
        formatAddress.locality = _get(components, 'locality_long') || '';
        formatAddress.postal_code = _get(components, 'postal_code_long') || '';
        formatAddress.postal_code_suffix = _get(components, 'postal_code_suffix_long') || '';
        formatAddress.state_or_province = _get(components, 'administrative_area_level_1_long') || '';
        formatAddress.state_or_province_code = _get(components, 'administrative_area_level_1_short') || '';
        formatAddress.street = _get(components, 'route_long') || '';
        formatAddress.street_number = _get(components, 'street_number_long') || '';

        return formatAddress;
      })
      .catch(error => console.error('geocodeByPlaceId error', error));
  };

  const getGeocode = async address => {
    return geocodeByAddress(address)
      .then(results => {
        return getLatLng(results[0]);
      })
      .catch(error => console.error('Error', error));
  };

  const getTimeZone = async (lat, lng) => {
    const secondsSinceEpoch = new Date().getTime() / 1000;
    const path = `https://maps.googleapis.com/maps/api/timezone/json?location=${lat},${lng}&timestamp=${secondsSinceEpoch}&key=AIzaSyB25i20ioPnZAD_9JR4p_B0b4ONm6kZpGM`;
    return fetch(path, {
      method: 'GET',
    })
      .then(response => response.json())
      .then(response => {
        return response;
      })
      .catch(err => console.log(err));
  };

  const handleSelect = async (value, placeId) => {
    if (value && placeId) {
      props.selectHandler({ loading: true });
      setAddress(value);
      const place_components = await getPlace(placeId);
      const place_geocode = await getGeocode(value);
      const place_timezone = await getTimeZone(place_geocode.lat, place_geocode.lng);
      const geoLat = _get(place_geocode, 'lat') || '';
      const geoLng = _get(place_geocode, 'lng') || '';
      const location = {
        ...place_components,
        loading: false,
        lat: geoLat,
        lng: geoLng,
        time_zone: _get(place_timezone, 'timeZoneId') || '',
        time_zone_offset: _get(place_timezone, 'rawOffset') || '',
        mapUrl: `https://www.google.com/maps/search/?api=1&query=${geoLat},${geoLng}&query_place_id=${placeId}`,
      };

      props.selectHandler(location);
    }
  };

  useEffect(() => {
    if (props.searchType === 'city') {
      setSearchOptions({ types: ['(cities)'] });
    }
  }, []);

  // To force PlacesAutocomplete to not hide dropdown on loss of focus uncomment this attr:
  // ref={(ref) => { if (!ref) return; ref.handleInputOnBlur = () => { }; }}
  // Useful when styling/debugging dropdown options.
  const optionClass = classnames(
    sLocationSearch['autocomplete-dropdown-container'],
    props.helpText.length > 0 || props.errorMessage.length > 0 ? sLocationSearch.helptext : '',
  );

  return (
    <div className={sLocationSearch['location-search']}>
      <PlacesAutocomplete
        debounce={700}
        value={address}
        onChange={handleChange}
        onSelect={handleSelect}
        searchOptions={searchOptions}
        // shouldFetchSuggestions={address.length > 2}
        ref={ref => {
          if (!ref) return;
          ref.handleInputOnBlur = () => {};
        }}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <>
            <InputText
              {...getInputProps({})}
              placeholder={props.placeholder}
              errorMessage={props.errorMessage}
              isRequired={props.isRequired}
              labelText={props.labelText}
              helpText={props.helpText}
              id={props?.id}
            />

            {!loading && suggestions.length > 0 && (
              <div className={optionClass}>
                {suggestions.map(suggestion => {
                  const className = classnames(sLocationSearch['suggested-item'], {
                    'Demo__suggestion-item--active': suggestion.active,
                  });
                  const key = `${suggestion.placeId}-${Math.random()}`;
                  if (suggestion.placeId.length > 0) {
                    return (
                      <div {...getSuggestionItemProps(suggestion, { className })} key={key}>
                        <a href="#" onClick={e => e.preventDefault()} aria-label={suggestion.description} role="option">
                          <Paragraph size="small">{suggestion.description}</Paragraph>
                        </a>
                      </div>
                    );
                  }
                })}
              </div>
            )}
          </>
        )}
      </PlacesAutocomplete>
    </div>
  );
}

LocationSearch.propTypes = {
  labelText: propTypes.string,
  isRequired: propTypes.bool,
  helpText: propTypes.string,
  placeholder: propTypes.string,
  errorMessage: propTypes.string,
  searchType: propTypes.oneOf(['city', 'address']),
  selectHandler: propTypes.func.isRequired,
};

LocationSearch.defaultProps = {
  labelText: '',
  isRequired: false,
  helpText: '',
  placeholder: '',
  errorMessage: '',
  searchType: 'address',
};

export default enhance(LocationSearch);
