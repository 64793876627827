import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './Loader.css';

import { connect } from 'react-redux';
import { compose } from 'redux';
import { withCookies } from 'react-cookie';

class Loader extends React.Component {

  render() {
    const accentColor =
      this.props.customBranding && this.props.customBranding.themes
        ? this.props.customBranding.themes.theme_color_accent
        : '#DD2161';
    return (
      <div className={s.globe}>
        <div className={s.circle}>
          <div className={s.map} />
        </div>
        <div className={s.plane}>
          <svg
            width="93px"
            height="32px"
            viewBox="0 0 93 32"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <defs />
            <g
              id="Page-1"
              stroke="none"
              strokeWidth="1"
              fill="none"
              fillRule="evenodd"
            >
              <g
                id="VIA-Loader-Small"
                transform="translate(-61.000000, -66.000000)"
                fill={accentColor}
                fillRule="nonzero"
              >
                <path
                  d="M147.6,82.4 L153.2,67 C153.2,67 150.1,66.9 148.8,66.8 C147.5,66.8 147,67.6 147,67.6 L134.7,80.1 C133.2,82.1 131.3,82.4 131.3,82.4 L101.3,81.2 L88.9,79 C88.9,79 75,78.3 72.3,78.4 C69.6,78.5 68.8,79.4 68.8,79.4 L67.5,80.6 L67.2,80.9 C67.2,80.9 66.1,81.4 65,82.1 C64.3,82.5 63.7,82.9 63.3,83.2 C62.3,84.1 61.9,84.9 61.9,84.9 L61.9,85.4 C62.1,85.9 62.4,86.3 62.8,86.7 C66.1,90 76.6,91 76.6,91 L85.5,91.7 L90.8,92.3 L92.9,92.7 L92.5,96.3 C93.2,97.3 98,97 98,97 L98.1,96.5 L101.8,96 L102.1,93.4 L100.7,92.8 L105,92.8 L104.6,96.3 C105.3,97.3 110.1,97 110.1,97 L110.2,96.5 L113.9,96 L114.2,93.4 L112,92.5 L113.1,92.5 C113.1,92.5 113.2,92.4 113.2,92.4 C114.6,92.5 117.4,92.6 118.4,92.6 C119.7,92.6 123.7,92.2 123.7,92.2 C123.7,92.2 148,86.4 149.2,86.3 C150.4,86.2 150.6,84.3 150.6,84.3 L152.7,83.3 L149.8,82.9 C149.1,82.3 147.6,82.4 147.6,82.4 Z"
                  id="Shape"
                />
              </g>
            </g>
          </svg>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  customBranding:
    localStorage.getItem('customBranding') !== undefined ||
    localStorage.getItem('customBranding') !== null
      ? JSON.parse(localStorage.getItem('customBranding'))
      : state.profile.customBranding || {},
});

export default compose(
  withCookies,
  withStyles(s),
  connect(
    mapStateToProps,
    null,
  ),
)(Loader);
