import React, { Component } from 'react';

const Branding = WrappedComponent => {
  class HOC extends Component {
    constructor(props) {
      super(props);
    }

    colorLuminance = (hex, lum) => {
      // validate hex string
      hex = String(hex).replace(/[^0-9a-f]/gi, '');
      if (hex.length < 6) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
      }
      lum = lum || 0;

      // convert to decimal and change luminosity
      var rgb = '#',
        c,
        i;
      for (i = 0; i < 3; i++) {
        c = parseInt(hex.substr(i * 2, 2), 16);
        c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
        rgb += ('00' + c).substr(c.length);
      }
      return rgb;
    };

    render() {
      if (
        this.props.customBranding &&
        Object.keys(this.props.customBranding).length > 0
      ) {
        let borderColor = this.colorLuminance(
          this.props.customBranding.themes.theme_color_accent,
          0.5,
        );
        let oceanShadowColor = this.colorLuminance(
          this.props.customBranding.themes.theme_color_light,
          0.05,
        );
        document.documentElement.style.setProperty(
          `--theme_border_color`,
          borderColor,
        );
        document.documentElement.style.setProperty(
          `--theme_ocean_shadow_color`,
          oceanShadowColor,
        );
        // let { theme_color_dark, theme_color_light, theme_color_accent } = this.props.customBranding;
        for (let [key, value] of Object.entries(
          this.props.customBranding.themes,
        )) {
          document.documentElement.style.setProperty(`--${key}`, value);
        }
      }

      return <WrappedComponent {...this.props} />;
    }
  }

  return HOC;
};

export default Branding;
