import React from 'react';
import styled from 'styled-components';
import StyledIcon from '../styles/Icon.styled';

const Span = styled.span`
  color: ${({ theme }) => theme.defaults.successColor};
`;

export default function IncludedIcon(props) {
  return (
    <Span {...props}>
      <StyledIcon type={'Checkmark'} size={'18px'} />
    </Span>
  );
}
