import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _orderBy from 'lodash/orderBy';
import _sortBy from 'lodash/sortBy';
import mapValues from 'lodash/fp/map';
import flow from 'lodash/fp/flow';
import DocumentContent from './DocumentContent.styled';
import StyledIcon from '../../../../styledComponents/styles/Icon.styled';
import FormGridLayout from '../../../forms/components/formGridLayout/FormGridLayout';
import { QuestionBuildSubmit } from '../../../forms/components/questions/QuestionBuildSubmit';
import { QuestionValidations } from '../../../forms/components/questions/QuestionValidations';
import { AccentButtonSmallNunito, ButtonSmallNunitoDarkGray } from '../../../../styledComponents/styles/Buttons.styled';

import history from '../../../../history';
import { isEmpty } from '../../../../helpers/replaceLodash';
import { updateApplicationSubmission } from '../../actions/travelerApplicationsActions';

const SubmitContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 35px;

  button {
    margin-right: 15px;
  }
`;

export default function ApplicationForm({ forceSaveState, onNext, onSave }) {
  const dispatch = useDispatch();
  const {
    formSubmission,
    applicationSubmission: { id: applicationId, attributes: applicationSubmission },
  } = useSelector(state => state.travelerApplicationSubmissions);

  const [uiLayout, setUiLayout] = useState([]);
  const [formQuestions, setFormQuestions] = useState(null);
  const [registerOptions, setRegisterOptions] = useState({});

  const forForm = history.location.pathname.includes('form');
  const submissionId = forForm ? formSubmission?.id : applicationId;
  const submission = forForm ? formSubmission?.attributes : applicationSubmission;

  const {
    control,
    setValue,
    getValues,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm({
    mode: 'onChange',
    shouldUnregister: false,
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    const layoutUi = [];
    let renderLayout = [];
    const finalValidations = QuestionValidations(submission.renderedLayout);

    const sortLayout = _orderBy(
      submission.layout.layout,
      [l => parseInt(l.position[0]), l => parseInt(l.position[1])],
      ['asc', 'asc'],
    );

    let newRow = 1;

    sortLayout.forEach(l => {
      const getQ = submission.renderedLayout.find(q => q.layout_id?.toString() === l.id?.toString());

      if (getQ) {
        renderLayout.push({ id: l.id, q: getQ, row: newRow.toString(), col: '3' });
        newRow++;
      }
    });

    renderLayout = _sortBy(renderLayout, e => parseInt(e.row));
    flow(
      mapValues(function(v) {
        layoutUi.push(v);
      }),
    )(renderLayout);

    setUiLayout(layoutUi);
    setRegisterOptions(finalValidations);
    setFormQuestions(submission.renderedLayout);
  }, []);

  useEffect(() => {
    const values = getValues();

    if (!isEmpty(values) && forceSaveState) {
      onSubmitHandler(values);
    }
  }, [forceSaveState]);

  const handleError = () => {
    // TODO: Add error handling
  };

  const onSubmitHandler = data => {
    if (data) {
      const submitValues = QuestionBuildSubmit(formQuestions, data);

      dispatch(
        updateApplicationSubmission({
          submissionId,
          body: submitValues,
          type: submission.submission_type.identifier,
        }),
      );
    }
  };

  return (
    <DocumentContent>
      <form id="travelerSubmissionForm" onSubmit={handleSubmit(onSubmitHandler, handleError)}>
        <FormGridLayout
          control={control}
          registerOptions={!forceSaveState && registerOptions}
          errors={errors}
          uiLayout={uiLayout}
          setValue={setValue}
          formId={submissionId}
        />
        <SubmitContainer>
          <ButtonSmallNunitoDarkGray aria-label="Save" form="travelerSubmissionForm" type="button" onClick={onSave}>
            Save
          </ButtonSmallNunitoDarkGray>
          <AccentButtonSmallNunito type="submit" form="travelerSubmissionForm" disabled={!isValid} onClick={onNext}>
            Next <StyledIcon type="ChevronForward" size="16px" aria-hidden="true" />
          </AccentButtonSmallNunito>
        </SubmitContainer>
      </form>
    </DocumentContent>
  );
}

ApplicationForm.propTypes = {
  onNext: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  forceSaveState: PropTypes.bool.isRequired,
};
