import React, { useState, useEffect } from 'react';
import { compose } from 'recompose';
import propTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import ViaModal from '../../../../components/library/viaModal';
import Headline from '../../../../components/library/headline';
import Button from '../../../../components/library/button';
import Paragraph from '../../../../components/library/paragraph';
import {
  patchDetail,
  getPlan,
  patchDetailFromTraveler,
} from '../../../../../actions/plans';
import sRemoveDetail from './reinstateDetail.scss';

export const enhance = compose(withStyles(sRemoveDetail));

function ReinstateDetail(props) {
  const dispatch = useDispatch();
  const planDetail = useSelector(state => state.plans.addDetail);
  const planDetailBatch = useSelector(
    state => state.plans.patchDetailFromTraveler,
  );
  const [saving, setSaving] = useState(false);
  const [reinstateUpdate, setReinstateUpdate] = useState(false);

  useEffect(
    () => {
      if (planDetail && reinstateUpdate) {
        if (planDetail.loading) {
          setSaving(true);
        }
        if (
          saving &&
          !!planDetail.detail &&
          planDetail.detail.id.toString() === props.detailId.toString()
        ) {
          dispatch(getPlan(props.planId));
          setSaving(false);
          props.onClose(false);
          setReinstateUpdate(false);
        }

        if (saving && !!planDetail.error) {
          setSaving(false);
          setReinstateUpdate(false);
          // alert(planDetail.error);
        }
      }
    },
    [planDetail],
  );

  useEffect(
    () => {
      if (planDetailBatch && reinstateUpdate) {
        if (planDetailBatch.loading) {
          setSaving(true);
        }
        if (saving && !!planDetailBatch.data) {
          dispatch(getPlan(props.planId));
          setSaving(false);
          props.onClose(false);
          setReinstateUpdate(false);
        }

        if (saving && !!planDetailBatch.error) {
          setSaving(false);
          setReinstateUpdate(false);
          // alert(planDetail.error);
        }
      }
    },
    [planDetailBatch],
  );

  const updateDetail = () => {
    setReinstateUpdate(true);
    if (!!props.plans_user_id) {
      const submitValues = {
        plans_user_ids: [props.plans_user_id],
        plan_activity_ids:
          props.type === 'plan_activities' ? [Number(props.detailId)] : [],
        plan_housing_ids:
          props.type === 'plan_housings' ? [Number(props.detailId)] : [],
        plan_transportation_ids:
          props.type === 'plan_transportations' ? [Number(props.detailId)] : [],
        removed: false,
      };

      dispatch(patchDetailFromTraveler(submitValues));
    } else {
      const submitValues = {
        removed: false,
      };

      dispatch(
        patchDetail(props.planId, props.detailId, submitValues, props.type),
      );
    }

    // props.onClose(false);
  };

  return (
    <>
      <ViaModal showing={props.show} onClose={() => props.onClose(false)}>
        <div className={sRemoveDetail['reinstate-detail']}>
          <div className={sRemoveDetail.subHeader}>
            <Headline centered tag="h1" as="h1">
              Reinstate Detail
            </Headline>
          </div>
          <div className={sRemoveDetail.content}>
            <Paragraph centered size="large">
              {props.groupReinstate
                ? `You are about to reinstate a detail to the Group Itinerary.
                Reinstated details will NOT automatically be reinstated on Travelers’ Itineraries.
                Administrators or Group Leaders can reinstate group details to Travelers in the
                Who’s Going grid view of a Travel Plan.`
                : `You are about to reinstate a detail to your itinerary. You can remove details at any time.`}
            </Paragraph>
          </div>
          <div className={sRemoveDetail.footer}>
            <div className={sRemoveDetail['footer-confirm']}>
              <Headline tag="h2" as="h3" centered>
                WOULD YOU STILL LIKE TO REINSTATE?
              </Headline>
            </div>
            <div className={sRemoveDetail['footer-button-wrapper']}>
              <div className={sRemoveDetail['button-content']}>
                <Button
                  display="primary"
                  kind="solid"
                  size="medium"
                  type="submit"
                  onClick={() => {
                    updateDetail();
                  }}
                >
                  Yes, Reinstate
                </Button>
              </div>
              <div className={sRemoveDetail['button-content']}>
                <Button
                  display="secondary"
                  kind="solid"
                  size="medium"
                  onClick={() => {
                    props.onClose(false);
                  }}
                >
                  No, Do Not Reinstate
                </Button>
              </div>
            </div>
          </div>
        </div>
      </ViaModal>
    </>
  );
}

ReinstateDetail.propTypes = {
  show: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
  detailId: propTypes.string.isRequired,
  type: propTypes.oneOf([
    'plan_transportations',
    'plan_housings',
    'plan_activities',
  ]).isRequired,
  planId: propTypes.string.isRequired,
  groupReinstate: propTypes.bool,
};

ReinstateDetail.defaultProps = {
  groupReinstate: false,
};

export default enhance(ReinstateDetail);
