import React from 'react';
import styled from 'styled-components';
import StyledIcon from './Icon.styled';

const StyledSpinner = styled.svg`
  animation: rotate 1s linear infinite;
  margin: 40px;
  width: 50px;
  height: 50px;

  & .path {
    stroke: #043544;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`;

const StyledSpinnerSmall = styled(StyledSpinner)`
  animation: rotate 1s linear infinite;
  margin: 0;
  width: 25px;
  height: 25px;
`;

const AccentButtonStyledSpinner = styled.svg`
  animation: rotate 1s linear infinite;
  width: 12px;

  & .path {
    stroke: var(--theme_color_accent_font, ${({ theme }) => theme.button.fontColor});
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`;

const SpinnerContainer = styled.div`
  display: inline-block;
  margin: 0;
  padding: 0;
`;

const TextSpan = styled.span`
  display: inline-block;
  margin: 0;
  padding: 0 0 0 5px;
  vertical-align: middle;
`;

export const Spinner = ({ displayText }) => (
  <SpinnerContainer>
    <StyledSpinner viewBox="0 0 50 50">
      <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="3" />
    </StyledSpinner>
    <TextSpan>{displayText}</TextSpan>
  </SpinnerContainer>
);

export const AccentButtonSpinner = ({ displayText }) => (
  <SpinnerContainer>
    <AccentButtonStyledSpinner viewBox="0 0 32 32">
      <circle className="path" cx="16" cy="16" r="14" fill="none" strokeWidth="3" />
    </AccentButtonStyledSpinner>
    <TextSpan>{displayText}</TextSpan>
  </SpinnerContainer>
);

export const GraySpinner = ({ displayText }) => (
  <SpinnerContainer>
    <StyledSpinner viewBox="0 0 50 50">
      <StyledIcon type={'Spinner'} />
    </StyledSpinner>
    <TextSpan>{displayText}</TextSpan>
  </SpinnerContainer>
);

export const GraySpinnerSmall = ({ displayText }) => (
  <SpinnerContainer>
    <StyledSpinnerSmall viewBox="0 0 25 25">
      <StyledIcon type={'Spinner'} />
    </StyledSpinnerSmall>
    <TextSpan>{displayText}</TextSpan>
  </SpinnerContainer>
);
