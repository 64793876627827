import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import { useDispatch, useSelector } from 'react-redux';
import propTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import V2ShareBar from '../../../../sites/components/v2/shareBar';
import ViaIcon from '../../../../sites/plans/components/ViaIcon';
import QuestionModal from '../../../QuestionModal/QuestionModal';
import { fetchOrganization } from '../../../../actions/authActions';
import { toggleFavorite } from '../../../../actions/programBrochureActions';
import { askQuestion } from '../../../../actions/programBrochureActions';
import sStyle from './programViewInfoBar.scss';

export const enhance = compose(withStyles(sStyle));

function ProgramViewInfoBar(props) {
  const dispatch = useDispatch();
  const organization = useSelector(state => state.auth.organization);
  const colorAccent = document.documentElement.style.getPropertyValue(`--theme_color_accent`);
  const [contactInfo, setContactInfo] = useState({ name: '', email: '', avatar: '' });
  const [quote, setQuote] = useState('');
  const [title, setTitle] = useState('');
  const [isFavorite, setIsFavorite] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(
    () => {
      setIsFavorite(props.favorite);
      const quote = `Check out this program ${window.location.host}/visitor/programs/${props.programId}`;
      const title = `I thought you might want to check out ${props.programTitle}`;
      setQuote(quote);
      setTitle(title);

      if (!!props.programContacts) {
        const firstContact = props.programContacts[0];
        let name = '';
        let email = '';
        let avatar = '';

        if (firstContact && firstContact.info) {
          name = firstContact.info[0].email;
          email = firstContact.info[0].email;
          avatar = firstContact.info[0].avatar;

          if (!!firstContact.info[1][0].first_name && !!firstContact.info[1][0].last_name) {
            name = firstContact.info[1][0].first_name + ' ' + firstContact.info[1][0].last_name;
          }
        }
        setContactInfo({ name, email, avatar });
      }
    },
    [props],
  );

  const toggleFavoriteOption = () => {
    setIsFavorite(!isFavorite);
    dispatch(toggleFavorite(props.programId));
  };

  const showContactModal = () => {
    if (props.userRole.toLowerCase() === 'traveler') {
      setShowModal(true);
    }
  };

  const handleAskQuestion = (id, data) => {
    dispatch(askQuestion(id, data));
  };

  const alias_program =
    organization.info && organization.info.alias_program ? organization.info.alias_program : 'Program';

  return (
    <div className={sStyle.infoBar}>
      <div className={sStyle.leftBlock}>
        {props.userRole.toLowerCase() === 'traveler' && (
          <div className={sStyle.favLabel}>
            <span>Favorite {alias_program}:</span>
            <span
              className={sStyle.icon}
              onClick={() => {
                toggleFavoriteOption();
              }}
            >
              <ViaIcon
                name="heart"
                color="noFill"
                size="small"
                ariaLabel="favorite"
                fillColor={isFavorite ? colorAccent : ''}
              />
            </span>
          </div>
        )}
        <div className={sStyle.shareLinks}>
          <span>Share {alias_program}:</span>
          <V2ShareBar
            facebook
            whatsapp
            twitter
            copyLink
            linkUrl={`${window.location.host}/visitor/programs/${props.programId}`}
            quote={quote}
            title={title}
            height="20px"
          />
        </div>
      </div>
      <div className={sStyle.rightBlock}>
        <div className={sStyle.columnLeft}>
          <ViaIcon name="conversation" color="darkText" size="small" />
        </div>
        <div className={sStyle.columnRight}>
          <div className={sStyle.providerName}>
            <span>{props.programProvider}</span>
          </div>
          {!!props.programContacts &&
            !!props.programContacts[0] && (
              <>
                <div
                  className={props.userRole.toLowerCase() !== 'traveler' ? sStyle.question : sStyle.questionTraveler}
                  onClick={showContactModal}
                >
                  Ask a question?
                </div>
                <div className={sStyle.innerBlock}>
                  <div className={sStyle.avatarWrapper}>
                    <img src={contactInfo.avatar} alt="Contact avatar" className={sStyle.avatar} />
                  </div>
                  <div className={sStyle.contactWrapper}>
                    <div className={sStyle.contact}>
                      Contact: <span>{contactInfo.name}</span>
                    </div>
                    <div className={sStyle.email}>
                      <span>{contactInfo.email}</span>
                    </div>
                  </div>
                </div>
              </>
            )}
        </div>
      </div>
      <QuestionModal
        show={showModal}
        id={props.programId}
        handleClose={() => setShowModal(false)}
        askQuestion={(id, state) => handleAskQuestion(id, state)}
        provider={props.programProvider}
        alias_program={alias_program}
      />
    </div>
  );
}

ProgramViewInfoBar.propTypes = {
  programContacts: propTypes.array,
  programId: propTypes.string.isRequired,
  programProvider: propTypes.string,
  programTitle: propTypes.string.isRequired,
  userRole: propTypes.oneOf(['Admin', 'Traveler', 'Visitor']).isRequired,
  favorite: propTypes.bool,
};

ProgramViewInfoBar.defaultProps = {
  favorite: false,
  programContacts: [],
  programProvider: '',
};

export default enhance(ProgramViewInfoBar);
