import fetch from 'node-fetch';
import {
  CHECK_EMAIL_CLEAR,
  CHECK_EMAIL_REQUEST,
  CHECK_EMAIL_SUCCESS,
  CHECK_EMAIL_FAILURE,
  RESET_PASSWORD_REQUEST_CLEAR,
  RESET_PASSWORD_REQUEST_REQUEST,
  RESET_PASSWORD_REQUEST_SUCCESS,
  RESET_PASSWORD_REQUEST_FAILURE,
  GET_USER_FROM_RESET_TOKEN_CLEAR,
  GET_USER_FROM_RESET_TOKEN_REQUEST,
  GET_USER_FROM_RESET_TOKEN_SUCCESS,
  GET_USER_FROM_RESET_TOKEN_FAILURE,
  TOKEN_CHANGE_PASSWORD_CLEAR,
  TOKEN_CHANGE_PASSWORD_REQUEST,
  TOKEN_CHANGE_PASSWORD_SUCCESS,
  TOKEN_CHANGE_PASSWORD_FAILURE,
  CREATE_USER_ACCOUNT_CLEAR,
  CREATE_USER_ACCOUNT_REQUEST,
  CREATE_USER_ACCOUNT_SUCCESS,
  CREATE_USER_ACCOUNT_FAILURE,
  SELECT_USER_ROLE_CLEAR,
  SELECT_USER_ROLE_REQUEST,
  SELECT_USER_ROLE_SUCCESS,
  SELECT_USER_ROLE_FAILURE,
} from './types';
import { keys } from '../config/keys';
import { Cookies } from 'react-cookie';

export function checkEmail(email) {
  return function(dispatch) {
    const value = encodeURIComponent(`${email.trim().toLowerCase()}`);
    const params = value.length > 0 ? `?email=${value}` : '';

    dispatch({ type: CHECK_EMAIL_REQUEST });
    fetch(`${keys.baseUri}/api/visitor/pre_sessions${params}`, {
      method: 'GET',
      headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    })
      .then(res => {
        if (res.status === 200) {
          res.json().then(data => {
            dispatch({
              type: CHECK_EMAIL_SUCCESS,
              payload: data,
            });
          });
        } else {
          res.json().then(message => {
            dispatch({
              type: CHECK_EMAIL_FAILURE,
              payload: message,
            });
          });
        }

        dispatch({
          type: CHECK_EMAIL_CLEAR,
        });
      })
      .catch(error => {
        dispatch({
          type: CHECK_EMAIL_FAILURE,
          payload: error.toString(),
        });
        dispatch({
          type: CHECK_EMAIL_CLEAR,
        });
      });
  };
}

export function resetPasswordRequest(email) {
  return function(dispatch) {
    const value = encodeURIComponent(`${email.trim()}`);
    const params = value.length > 0 ? `?email=${value}` : '';

    dispatch({ type: RESET_PASSWORD_REQUEST_REQUEST });
    fetch(`${keys.baseUri}/api/v2/passwords/forgot${params}`, {
      method: 'POST',
      headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    })
      .then(res => {
        if (res.status === 200) {
          res.json().then(data => {
            dispatch({
              type: RESET_PASSWORD_REQUEST_SUCCESS,
              payload: data,
            });
          });
        } else {
          res.json().then(message => {
            dispatch({
              type: RESET_PASSWORD_REQUEST_FAILURE,
              payload: message,
            });
          });
        }

        dispatch({
          type: RESET_PASSWORD_REQUEST_CLEAR,
        });
      })
      .catch(error => {
        dispatch({
          type: RESET_PASSWORD_REQUEST_FAILURE,
          payload: error.toString(),
        });
        dispatch({
          type: RESET_PASSWORD_REQUEST_CLEAR,
        });
      });
  };
}

export function getUserFromResetToken(token) {
  return function(dispatch) {
    dispatch({ type: GET_USER_FROM_RESET_TOKEN_REQUEST });
    fetch(`${keys.baseUri}/api/v2/passwords/${token}`, {
      method: 'GET',
      headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    })
      .then(res => {
        if (res.status === 200) {
          res.json().then(data => {
            dispatch({
              type: GET_USER_FROM_RESET_TOKEN_SUCCESS,
              payload: data,
            });
          });
        } else {
          res.json().then(message => {
            dispatch({
              type: GET_USER_FROM_RESET_TOKEN_FAILURE,
              payload: message,
            });
          });
        }

        dispatch({
          type: GET_USER_FROM_RESET_TOKEN_CLEAR,
        });
      })
      .catch(error => {
        dispatch({
          type: GET_USER_FROM_RESET_TOKEN_FAILURE,
          payload: error.toString(),
        });
        dispatch({
          type: GET_USER_FROM_RESET_TOKEN_CLEAR,
        });
      });
  };
}

export function changePasswordWithToken(data) {
  return function(dispatch) {
    dispatch({ type: TOKEN_CHANGE_PASSWORD_REQUEST });
    fetch(`${keys.baseUri}/api/v2/passwords/reset`, {
      method: 'POST',
      headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    })
      .then(res => {
        if (res.status === 200) {
          res.json().then(data => {
            dispatch({
              type: TOKEN_CHANGE_PASSWORD_SUCCESS,
              payload: data,
            });
          });
        } else {
          res.json().then(message => {
            dispatch({
              type: TOKEN_CHANGE_PASSWORD_FAILURE,
              payload: message,
            });
          });
        }

        dispatch({
          type: TOKEN_CHANGE_PASSWORD_CLEAR,
        });
      })
      .catch(error => {
        dispatch({
          type: TOKEN_CHANGE_PASSWORD_FAILURE,
          payload: error.toString(),
        });
        dispatch({
          type: TOKEN_CHANGE_PASSWORD_CLEAR,
        });
      });
  };
}

export function createUserAccount(data) {
  const submitValues = {
    client_account_id: data.client_account_id,
    email: data.email,
    password: data.password,
    password_confirmation: data.password_confirmation,
  };
  return function(dispatch) {
    dispatch({ type: CREATE_USER_ACCOUNT_REQUEST });
    fetch(`${keys.baseUri}/api/sign_up`, {
      method: 'POST',
      headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
      body: JSON.stringify(submitValues),
    })
      .then(res => {
        if (res.status === 200 || res.status === 201) {
          res.json().then(data => {
            dispatch({
              type: CREATE_USER_ACCOUNT_SUCCESS,
              payload: data,
            });
          });
        } else {
          res.json().then(message => {
            dispatch({
              type: CREATE_USER_ACCOUNT_FAILURE,
              payload: message,
            });
          });
        }

        dispatch({
          type: CREATE_USER_ACCOUNT_CLEAR,
        });
      })
      .catch(error => {
        dispatch({
          type: CREATE_USER_ACCOUNT_FAILURE,
          payload: error.toString(),
        });
        dispatch({
          type: CREATE_USER_ACCOUNT_CLEAR,
        });
      });
  };
}

export function selectAccessAdmin(data) {
  const submitData = {
    user: {
      admin_sign_in: data,
    },
  };
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({ type: SELECT_USER_ROLE_REQUEST });
    fetch(`${keys.baseUri}/api/users/change_role`, {
      method: 'PATCH',
      headers: token,
      body: JSON.stringify(submitData),
    })
      .then(res => {
        if (res.status === 200 || res.status === 422) {
          res.json().then(data => {
            dispatch({
              type: SELECT_USER_ROLE_SUCCESS,
              payload: data,
            });
          });
        } else {
          res.json().then(message => {
            dispatch({
              type: SELECT_USER_ROLE_FAILURE,
              payload: message,
            });
          });
        }

        dispatch({
          type: SELECT_USER_ROLE_CLEAR,
        });
      })
      .catch(error => {
        dispatch({
          type: SELECT_USER_ROLE_FAILURE,
          payload: error.toString(),
        });
        dispatch({
          type: SELECT_USER_ROLE_CLEAR,
        });
      });
  };
}
