import React, { useState } from 'react';
import { compose } from 'recompose';
import propTypes from 'prop-types';
import moment from 'moment-timezone';
import _get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import Headline from '../../../../components/library/headline';
import Paragraph from '../../../../components/library/paragraph';
import ViaModal from '../../../../components/library/viaModal';
import TransportationForm from '../../forms/transportationForm';
import RemoveDetail from '../../modals/removeDetail';
import ReinstateDetail from '../../modals/reinstateDetail';
import { getPlan } from '../../../../../actions/plans';
import ViaIcon from '../../ViaIcon';
import sTransportationItemView from './transportationItemView.scss';

export const enhance = compose(withStyles(sTransportationItemView));

function TransportationItemView(props) {
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.profile.currentUser);
  const permissions = useSelector(state => state.profile.permissions);
  const [showTransportation, setShowTransportation] = useState(false);
  const [removeDetail, setRemoveDetail] = useState(false);
  const [showReinstateDetail, setShowReinstateDetail] = useState(false);

  const transportation = {
    nickname: props.detail.nickname,
    tp_transportation_type_id: props.detail.tp_transportation_type_id,
    tp_transportation_type_name: props.detail.tp_transportation_type_name,
    confirmation_number: props.detail.confirmation_number,
    ticket_number: props.detail.ticket_number,
    carrier_name: props.detail.carrier_name,
    route_number: props.detail.route_number,
    seat_number: props.detail.seat_number,
    departure_city: '',
    departure_country_id: '',
    departure_country_name: '',
    departure_date: moment(props.detail.departure_date).format('MMM D, YYYY'),
    departure_time: props.detail.departure_time,
    departure_tp_time_zone_id: '',
    arrival_city: '',
    arrival_country_id: '',
    arrival_country_name: '',
    arrival_date: moment(props.detail.arrival_date).format('MMM D, YYYY'),
    arrival_time: props.detail.arrival_time,
    arrival_tp_time_zone_id: '',
    notes: props.detail.notes,
    group: props.detail.group,
    force_add: props.detail.force_add,
    removed: props.detail.removed,
    departure_formatted_address: _get(props, 'detail.departure_location.formatted_address') || '',
    departure_timezone: _get(props, 'detail.departure_location.time_zone') || '',
    arrival_formatted_address: _get(props, 'detail.arrival_location.formatted_address') || '',
    arrival_timezone: _get(props, 'detail.arrival_location.time_zone') || '',
  };

  return (
    <>
      <div className={sTransportationItemView['transportation-item']}>
        <div className={sTransportationItemView.soloGroupContainer}>
          <Paragraph>
            {transportation.removed ? 'Removed ' : ''}
            {transportation.group ? 'With the Group' : 'My Detail'}
          </Paragraph>
          <ViaIcon
            name={transportation.group ? 'group' : 'solo'}
            color="darkText"
            size={transportation.group ? 'smallPlusW10' : 'small'}
          />
        </div>
        <div
          className={
            transportation.removed ? sTransportationItemView.headerRemoved : sTransportationItemView.headerActive
          }
        >
          <ViaIcon ariaLabel={transportation.nickname ? transportation.nickname : transportation.carrier_name} transportationId={Number(transportation.tp_transportation_type_id)} />
          <div style={{
                          fontSize:"16px",
                          lineHeight : "24px",
                          fontFamily : "IntroBoldItalic,sans-serif",
                          color:"#fff",
                          flex : "1 1"
                      }}>
            {transportation.nickname ? transportation.nickname : transportation.carrier_name}
          </div>
          <div className={sTransportationItemView.action}>
            {!props.hideEdit && (
              <a
                href='#'
                role='button'
                aria-label='Edit'
                className={sTransportationItemView['action-item']}
                onClick={(e) => {
                  e.preventDefault(); 
                  setShowTransportation(true);
                }}
              >
                <FontAwesomeIcon icon="pencil-alt" color="white" size="sm" />
                <Paragraph>Edit</Paragraph>
              </a>
            )}

            {(currentUser['client_user_role?'] &&
              permissions.travel_plans_settings.includes('delete_travel_plan_and_itinerary')) ||
            currentUser['is_traveler?'] ? (
              !props.hideRemove ? (
                !transportation.removed ? (
                  <a
                    href='#'
                    role='button'
                    aria-label='Remove'
                    className={sTransportationItemView['action-item']}
                    onClick={(e) => {
                      e.preventDefault(); 
                      setRemoveDetail(true);
                    }}
                  >
                    <Paragraph> x Remove</Paragraph>
                  </a>
                ) : (
                  <a
                    href='#'
                    role='button'
                    aria-label='Reinstate'
                    className={sTransportationItemView['action-item']}
                    onClick={(e) => {
                      e.preventDefault(); 
                      setShowReinstateDetail(true);
                    }}
                  >
                    <FontAwesomeIcon icon="plus" color="white" size="sm" />
                    <Paragraph>Reinstate</Paragraph>
                  </a>
                )
              ) : null
            ) : null}
          </div>
        </div>
        <div className={sTransportationItemView.itineraryContainer}>
          <div className={sTransportationItemView.column}>
            <div className={sTransportationItemView.row}>
              <div className={sTransportationItemView.item}>
                <Paragraph size="medium">Departure Date</Paragraph>
                <div className={sTransportationItemView.innerRow}>
                  <Paragraph size="small">{transportation.departure_date}</Paragraph>
                </div>
              </div>
            </div>
            <div className={sTransportationItemView.row}>
              <div className={sTransportationItemView.item}>
                <Paragraph size="medium">Departure Time</Paragraph>
                <div className={sTransportationItemView.innerRow}>
                  <Paragraph size="small">
                    {`${transportation.departure_time} ${transportation.departure_timezone}`}
                  </Paragraph>
                </div>
              </div>
            </div>
            <div className={sTransportationItemView.row}>
              <div className={sTransportationItemView.item}>
                <Paragraph size="medium">Departure Location</Paragraph>
                <div className={sTransportationItemView.innerRow}>
                  <Paragraph size="small">{transportation.departure_formatted_address}</Paragraph>
                </div>
              </div>
            </div>
          </div>

          <div className={sTransportationItemView.borderedColumnLeft}>
            <div className={sTransportationItemView.item}>
              <Paragraph size="medium">Carrier Name</Paragraph>
              <div className={sTransportationItemView.innerRow}>
                <Paragraph size="small">{transportation.carrier_name}</Paragraph>
              </div>
            </div>
            <div className={sTransportationItemView.item}>
              <Paragraph size="medium">Route #</Paragraph>
              <div className={sTransportationItemView.innerRow}>
                <Paragraph size="small">{transportation.route_number}</Paragraph>
              </div>
            </div>
          </div>

          <div className={sTransportationItemView.borderedColumnRight}>
            <div className={sTransportationItemView.item}>
              <Paragraph size="medium">Confirmation #</Paragraph>
              <div className={sTransportationItemView.innerRow}>
                <Paragraph size="small" customStyle={{width:"230px", wordWrap:"break-word"}}>{transportation.confirmation_number}</Paragraph>
              </div>
            </div>
            <div className={sTransportationItemView.item}>
              <Paragraph size="medium">Seat #</Paragraph>
              <div className={sTransportationItemView.innerRow}>
                <Paragraph size="small" customStyle={{width:"230px", wordWrap:"break-word"}}>{transportation.seat_number}</Paragraph>
              </div>
            </div>
            <div className={sTransportationItemView.item}>
              <Paragraph size="medium">Ticket #</Paragraph>
              <div className={sTransportationItemView.innerRow}>
                <Paragraph size="small" customStyle={{width:"230px", wordWrap:"break-word"}}>{transportation.ticket_number}</Paragraph>
              </div>
            </div>
          </div>

          <div className={sTransportationItemView.column}>
            <div className={sTransportationItemView.row}>
              <div className={sTransportationItemView.item}>
                <Paragraph size="medium">Arrival Date</Paragraph>
                <div className={sTransportationItemView.innerRow}>
                  <Paragraph size="small">{transportation.arrival_date}</Paragraph>
                </div>
              </div>
            </div>
            <div className={sTransportationItemView.row}>
              <div className={sTransportationItemView.item}>
                <Paragraph size="medium">Arrival Time</Paragraph>
                <div className={sTransportationItemView.innerRow}>
                  <Paragraph size="small">
                    {`${transportation.arrival_time} ${transportation.arrival_timezone}`}
                  </Paragraph>
                </div>
              </div>
            </div>
            <div className={sTransportationItemView.row}>
              <div className={sTransportationItemView.item}>
                <Paragraph size="medium">Arrival Location</Paragraph>
                <div className={sTransportationItemView.innerRow}>
                  <Paragraph size="small">{transportation.arrival_formatted_address}</Paragraph>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={sTransportationItemView.notesContainer}>
          <Paragraph size="medium">Notes</Paragraph>
          <Paragraph size="small">{transportation.notes}</Paragraph>
        </div>
      </div>
      <ViaModal
        showing={showTransportation}
        onClose={() => {
          dispatch(getPlan(props.planId));
          setShowTransportation(false);
        }}
        headerText="Edit Transportation"
      >
        <TransportationForm
          onClose={() => {
            dispatch(getPlan(props.planId));
            setShowTransportation(false);
          }}
          isEdit
          details={props.detail}
          itemId={props.detail.id}
          planId={props.planId}
          group={props.detail.group}
        />
      </ViaModal>
      <RemoveDetail
        show={removeDetail}
        onClose={() => setRemoveDetail(false)}
        detailId={props.detail.id.toString()}
        type="plan_transportations"
        planId={props.planId}
        plans_user_id={props.plans_user_id}
        groupRemove={props.groupRemove}
      />
      <ReinstateDetail
        show={showReinstateDetail}
        onClose={() => setShowReinstateDetail(false)}
        detailId={props.detail.id.toString()}
        type="plan_transportations"
        planId={props.planId}
        plans_user_id={props.plans_user_id}
        groupReinstate={props.groupReinstate}
      />
    </>
  );
}

TransportationItemView.propTypes = {
  detail: propTypes.object.isRequired,
  planId: propTypes.string.isRequired,
  hideEdit: propTypes.bool,
  hideRemove: propTypes.bool,
  plans_user_id: propTypes.number,
  groupRemove: propTypes.bool,
  groupReinstate: propTypes.bool,
};

TransportationItemView.defaultProps = {
  hideEdit: false,
  hideRemove: false,
  plans_user_id: null,
  groupRemove: false,
  groupReinstate: false,
};

export default enhance(TransportationItemView);
