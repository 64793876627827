import React, { useState, useEffect } from 'react';
import { compose } from 'recompose';
import { useDispatch, useSelector } from 'react-redux';
import _get from 'lodash/get';
import _includes from 'lodash/includes';
import _map from 'lodash/map';
import _reject from 'lodash/reject';
import propTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import ViaModal from '../../../../components/library/viaModal';
import Headline from '../../../../components/library/headline';
import Button from '../../../../components/library/button';
import ItineraryToggle from '../../itineraryToggle';
import { patchDetailFromTraveler } from '../../../../../actions/plans';
import SuccessAdminRemovedGroupDetail from '../successAdminRemovedGroupDetail';
import Paragraph from '../../../../components/library/paragraph';
import sAdminDetailFromTraveler from './adminDetailFromTraveler.scss';

export const enhance = compose(
  withStyles(sAdminDetailFromTraveler),
  withRouter,
);

function AdminDetailFromTraveler(props) {
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.profile.currentUser);
  const selectedPlan = useSelector(state => state.plans.plan);
  const itemsRemoved = useSelector(state => state.plans.patchDetailFromTraveler);
  const [transportationItems, setTransportationItems] = useState(null);
  const [housingItems, setHousingItems] = useState(null);
  const [activityItems, setActivityItems] = useState(null);
  const [selectedTransportation, setSelectedTransportation] = useState([]);
  const [selectedHousing, setSelectedHousing] = useState([]);
  const [selectedActivity, setSelectedActivity] = useState([]);
  const [saving, setSaving] = useState(false);
  const [showAdminRemoveSuccess, setShowAdminRemoveSuccess] = useState(false);
  const [availableOptions, setAvailableOptions] = useState(false);
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    if (!!selectedPlan && !!selectedPlan.plan) {
      let rawTransportation = _get(selectedPlan.group_itinerary_byType, 'plan_transportation') || null;
      let rawHousing = _get(selectedPlan.group_itinerary_byType, 'plan_housing') || null;
      let rawActivity = _get(selectedPlan.group_itinerary_byType, 'plan_activity') || null;

      if ((!!rawTransportation &&  rawTransportation.length > 0) || (!!rawHousing && rawHousing.length > 0) || (!!rawActivity && rawActivity.length > 0)) {
        setAvailableOptions(true);
      }

      setTransportationItems(rawTransportation || null);
      setHousingItems(rawHousing || null);
      setActivityItems(rawActivity || null);
    }
  }, [selectedPlan]);

  const toggleItem = (type, id) => {
    if (type === 'plan_transportation') {
      if (_includes(selectedTransportation, id)) {
        const tempRemove = _reject(selectedTransportation, function(item) {return item === id});
        setSelectedTransportation(tempRemove);
      } else {
        setSelectedTransportation([...selectedTransportation, id]);
      }
    }
    if (type === 'plan_housing') {
      if (_includes(selectedHousing, id)) {
        const tempRemove = _reject(selectedHousing, function(item) {return item === id});
        setSelectedHousing(tempRemove);
      } else {
        setSelectedHousing([...selectedHousing, id]);
      }
    }
    if (type === 'plan_activity') {
      if (_includes(selectedActivity, id)) {
        const tempRemove = _reject(selectedActivity, function(item) {return item === id});
        setSelectedActivity(tempRemove);
      } else {
        setSelectedActivity([...selectedActivity, id]);
      }
    }
  };

  const addItems = () => {
    if (selectedTransportation.length > 0 || selectedHousing.length > 0 || selectedActivity.length > 0) {
      const submitValues = {
        removed: true,
        plans_user_ids: props.planUsers,
        plan_activity_ids: selectedActivity,
        plan_housing_ids: selectedHousing,
        plan_transportation_ids: selectedTransportation
      };

      dispatch(patchDetailFromTraveler(submitValues));
    }
  };

  useEffect(() => {
    if (!!itemsRemoved) {
      if (itemsRemoved.loading) {
        setSaving(true);
      }
      if(!!itemsRemoved.data && !itemsRemoved.loading) {
        setSaving(false);
        props.onClose(false);
        setSelectedActivity([]);
        setSelectedHousing([]);
        setSelectedTransportation([]);
        setShowAdminRemoveSuccess(true);
      }
      if (!!itemsRemoved.error) {
        setSaving(false);
        // alert(itemsRemoved.error);
      }
    }
  }, [itemsRemoved]);

  useEffect(() => {
    if (selectAll) {
      setSelectedTransportation(_map(transportationItems, 'id'));
      setSelectedHousing(_map(housingItems, 'id'));
      setSelectedActivity(_map(activityItems, 'id'));
    } else {
      setSelectedTransportation([]);
      setSelectedHousing([]);
      setSelectedActivity([]);
    }
  }, [selectAll]);

  const clearState = () => {
    setSelectedTransportation([]);
    setSelectedHousing([]);
    setSelectedActivity([]);
    setSelectAll(false);
  };

  return (
    <>
      <ViaModal
        showing={props.show}
        onClose={() => {
          clearState();
          props.onClose(false);
        }}
        headerText="REMOVE DETAILS FROM TRAVELER ITINERARIES"
      >
        <div className={sAdminDetailFromTraveler['admin-detail-from-traveler']}>
          <div className={sAdminDetailFromTraveler.subHeader}>
            {availableOptions ? (
              <Headline centered tag="h2" as="h3">
                {currentUser.first_name && currentUser.first_name.length > 0 ? `${currentUser.first_name}, w` : 'W'}hich Group Details would you like to remove from {props.planUsers ? props.planUsers.length : ''} Traveler itineraries?
              </Headline>
            ) : (
              <Headline centered tag="h2" as="h3">
                {currentUser.first_name && currentUser.first_name.length > 0 ? `${currentUser.first_name}, t` : 'T'}here are no group travel details to remove from the group itinerary.
              </Headline>
            )}
          </div>
          <div className={sAdminDetailFromTraveler.content}>
            <div
              className={sAdminDetailFromTraveler['toggle-link']}
              onClick={() => {setSelectAll(!selectAll)}}
            >
              <Paragraph size="small" color="primary" bold>{selectAll ? `CLEAR ALL GROUP DETAILS` : `SELECT ALL GROUP DETAILS`}</Paragraph>
            </div>
            <div className={sAdminDetailFromTraveler['content-header']}>
              <Headline tag="h3" as="h3" italic>
                Group Transportation
              </Headline>
            </div>
            {!!transportationItems && transportationItems.length > 0 ? (
              transportationItems.map(item => (
                <div key={item.id} className={sAdminDetailFromTraveler.itoggle}>
                  <ItineraryToggle
                    iName={item.nickname ? item.nickname : item.carrier_name}
                    iType={item.tp_transportation_type_name}
                    iStart={item.departure_date}
                    iEnd={item.arrival_date}
                    icon={Number(item.tp_transportation_type_id)}
                    iLocations={(!!item.departure_location && !!item.arrival_location) ?
                      `${item.departure_location.locality}, ${item.departure_location.country_common_name} to
                     ${item.arrival_location.locality}, ${item.arrival_location.country_common_name}`
                      : ''}
                    isActive={_includes(selectedTransportation, item.id)}
                    onClick={() => toggleItem('plan_transportation', item.id)}
                    type="remove"
                    detailType="transportation"
                  />
                </div>
              ))

            ) : (
              <Paragraph>There are no group transportation details added yet.</Paragraph>
            )}
          </div>
          <div className={sAdminDetailFromTraveler.content}>
            <div className={sAdminDetailFromTraveler['content-header']}>
              <Headline tag="h3" as="h3" italic>
                Group Housing
              </Headline>
            </div>
            {!!housingItems && housingItems.length > 0 ? (
              housingItems.map(item => (
                <div key={item.id} className={sAdminDetailFromTraveler.itoggle}>
                  <ItineraryToggle
                    iName={item.nickname ? item.nickname : item.name}
                    iType={item.tp_housing_type_name}
                    iStart={item.arrival_date}
                    iEnd={item.departure_date}
                    icon={Number(item.tp_housing_type_id)}
                    iLocations={(!!item.location) ?
                      `${item.location.locality}, ${item.location.country_common_name}`
                      : ''}
                    isActive={_includes(selectedHousing, item.id)}
                    onClick={() => toggleItem('plan_housing', item.id)}
                    type="remove"
                    detailType="housing"
                  />
                </div>
              ))
            ) : (
              <Paragraph>There are no group housing details added yet.</Paragraph>
            )}

          </div>
          <div className={sAdminDetailFromTraveler.content}>
            <div className={sAdminDetailFromTraveler['content-header']}>
              <Headline tag="h3" as="h3" italic>
                Group Activities
              </Headline>
            </div>
            {!!activityItems && activityItems.length > 0 ? (
              activityItems.map(item => (
                <div key={item.id} className={sAdminDetailFromTraveler.itoggle}>
                  <ItineraryToggle
                    iName={item.nickname ? item.nickname : item.name}
                    iType={item.tp_activity_type_name}
                    iStart={item.start_date}
                    iEnd={item.end_date}
                    icon={Number(item.tp_activity_type_id)}
                    iLocations={(!!item.location) ?
                      `${item.location.locality}, ${item.location.country_common_name}`
                      : ''}
                    isActive={_includes(selectedActivity, item.id)}
                    onClick={() => toggleItem('plan_activity', item.id)}
                    type="remove"
                    detailType="activity"
                  />
                </div>
              ))
            ) : (
              <Paragraph>There are no group activity details added yet.</Paragraph>
            )}
          </div>
          <div className={sAdminDetailFromTraveler.footer}>
            <div className={sAdminDetailFromTraveler['footer-confirm']}>
              {!availableOptions &&
              <Headline tag="h2" as="h3" centered>
                Would you like to build your itinerary?
              </Headline>
              }
            </div>
            <div className={sAdminDetailFromTraveler['footer-button-wrapper']}>
              <div className={sAdminDetailFromTraveler['button-content']}>
                <Button
                  display="secondary"
                  kind="solid"
                  size="medium"
                  onClick={() => {
                    clearState();
                    props.onClose(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
              {availableOptions ? (
                <div className={sAdminDetailFromTraveler['button-content']}>
                  <Button
                    display="primary"
                    kind="solid"
                    size="medium"
                    type="submit"
                    disabled={
                      selectedTransportation.length === 0 &&
                      selectedHousing.length === 0 &&
                      selectedActivity.length === 0
                    }
                    loading={saving}
                    onClick={() => {
                      addItems();
                    }}
                  >
                    Remove Details From Selected Travelers
                  </Button>
                </div>
              ) : (
                <div className={sAdminDetailFromTraveler['button-content']}>
                  <Button
                    display="primary"
                    kind="solid"
                    size="medium"
                    type="submit"
                    onClick={() => {
                      props.onClose(false);
                      props.history.push(`/plans/group-itinerary/${props.planId}`);
                    }}
                  >
                    Yes, Build Itinerary
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </ViaModal>
      <SuccessAdminRemovedGroupDetail
        show={showAdminRemoveSuccess}
        onClose={() => setShowAdminRemoveSuccess(false)}
        planUsers={props.planUsers}
        planId={props.planId}
      />
    </>
  );
}

AdminDetailFromTraveler.propTypes = {
  show: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
  planId: propTypes.string.isRequired,
  planUsers: propTypes.array,
};

export default enhance(AdminDetailFromTraveler);
