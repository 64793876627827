import React, { useState } from 'react';
import { compose } from 'recompose';
import { useSelector } from 'react-redux';
import propTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import ViaModal from '../../../../components/library/viaModal';
import Headline from '../../../../components/library/headline';
import Button from '../../../../components/library/button';
import ToggleButton from '../../../../components/library/toggleButton';
import TransportationForm from '../../forms/transportationForm';
import ActivityForm from '../../forms/activityForm';
import HousingForm from '../../forms/housingForm';
import { getPlan } from '../../../../../actions/plans';
import sDetailChoiceModal from './detailChoiceModal.scss';

export const enhance = compose(withStyles(sDetailChoiceModal));

function DetailChoiceModal(props) {
  const dispatch = useDispatch();
  const [toggleValue, setToggleValue] = useState(0);
  const [showTransportation, setShowTransportation] = useState(false);
  const [showHousing, setShowHousing] = useState(false);
  const [showActivity, setShowActivity] = useState(false);

  const currentUser = useSelector(state => state.profile.currentUser);

  const showAddDetails = () => {
    switch (toggleValue) {
      case 1:
        setShowTransportation(true);
        break;
      case 2:
        setShowHousing(true);
        break;
      case 3:
        setShowActivity(true);
        break;
    }

    props.onClose(false);
    setToggleValue(0);
  };

  return (
    <>
      <ViaModal
        showing={props.show}
        onClose={() => props.onClose(false)}
        headerText={
          currentUser.first_name && currentUser.last_name
            ? `HELLO ${currentUser.first_name} ${
                currentUser.last_name
              }! ADD DETAIL`
            : 'ADD DETAIL'
        }
      >
        <div className={sDetailChoiceModal['detail-choice-modal']}>
          <div className={sDetailChoiceModal.subHeader}>
            <Headline centered tag="h2" as="h3">
              What type of {props.group ? 'group' : 'personal'} detail would you
              like to add to your plan?
            </Headline>
          </div>
          <div className={sDetailChoiceModal.content}>
            <div className={sDetailChoiceModal.toggle}>
              <ToggleButton
                title="TRANSPORTATION"
                icon="plane"
                display="primary"
                active={toggleValue === 1}
                onClick={() => setToggleValue(1)}
              />
            </div>
            <div className={sDetailChoiceModal.toggle}>
              <ToggleButton
                title="HOUSING"
                icon="housing"
                display="primary"
                active={toggleValue === 2}
                onClick={() => setToggleValue(2)}
              />
            </div>
            <div className={sDetailChoiceModal.toggle}>
              <ToggleButton
                title="ACTIVITY"
                icon="hiking"
                display="primary"
                active={toggleValue === 3}
                onClick={() => setToggleValue(3)}
              />
            </div>
          </div>
          <div className={sDetailChoiceModal.footer}>
            <div className={sDetailChoiceModal['button-content']}>
              <Button
                display="secondary"
                kind="solid"
                size="medium"
                ariaLabel="Cancel"
                onClick={() => {
                  props.onClose(false);
                }}
              >
                Cancel
              </Button>
            </div>
            <div className={sDetailChoiceModal['button-content']}>
              <Button
                display="primary"
                kind="solid"
                size="medium"
                type="submit"
                ariaLabel="Continue"
                disabled={toggleValue === 0}
                onClick={() => {
                  showAddDetails();
                }}
              >
                Continue
              </Button>
            </div>
          </div>
        </div>
      </ViaModal>
      <ViaModal
        showing={showTransportation}
        onClose={() => {
          dispatch(getPlan(props.planId));
          setShowTransportation(false);
        }}
        headerText="ADD TRANSPORTATION"
      >
        <TransportationForm
          onClose={() => {
            dispatch(getPlan(props.planId));
            setShowTransportation(false);
          }}
          planId={props.planId}
          group={props.group}
        />
      </ViaModal>
      <ViaModal
        showing={showActivity}
        onClose={() => {
          dispatch(getPlan(props.planId));
          setShowActivity(false);
        }}
        headerText="ADD ACTIVITY"
      >
        <ActivityForm
          onClose={() => {
            dispatch(getPlan(props.planId));
            setShowActivity(false);
          }}
          planId={props.planId}
          group={props.group}
        />
      </ViaModal>
      <ViaModal
        showing={showHousing}
        onClose={() => {
          dispatch(getPlan(props.planId));
          setShowHousing(false);
        }}
        headerText="ADD HOUSING"
      >
        <HousingForm
          onClose={() => {
            dispatch(getPlan(props.planId));
            setShowHousing(false);
          }}
          planId={props.planId}
          group={props.group}
        />
      </ViaModal>
    </>
  );
}

DetailChoiceModal.propTypes = {
  show: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
  planId: propTypes.string.isRequired,
  group: propTypes.bool,
};

DetailChoiceModal.defaultProps = {
  group: false,
};

export default enhance(DetailChoiceModal);
