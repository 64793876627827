export default [
  {
    id: 1,
    label: 'Yes',
    value: 'yes',
  },
  {
    id: 2,
    label: 'No',
    value: 'no',
  },
];
