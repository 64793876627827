exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1YEZg{padding-top:64px;padding-top:4rem;padding-left:128px;padding-left:8rem;padding-right:128px;padding-right:8rem;width:100%;background-color:#fff}.Rffu4{margin:0;max-width:72%;min-width:72%}.Rffu4 div,.Rffu4 p{color:#043544;font-family:Open Sans;font-size:16px;font-size:1rem;line-height:24px}._2jGI9{font-family:AvenirNextBold,sans-serif;color:#dd2160}.VngHb{font-family:IntroBold,sans-serif;font-size:28px;font-size:1.75rem;border-bottom:3px solid #f5f5f5;padding-bottom:24px;padding-bottom:1.5rem;margin-bottom:14px;margin-bottom:.875rem;color:#043544}._6lgsY{float:right;margin-right:85px;margin-top:3%}._6lgsY img{width:25px}._1bUlY{width:100%;min-height:100px;height:auto;font-size:16px;font-family:Avenir,sans-serif;padding-left:5px;padding-top:5px;outline:none;border:none}._3FkkZ{opacity:.1}._1hPpf{cursor:pointer}._3i-b8 a{color:#dd2160;font-weight:700}._3i-b8>p:after{content:\"\\A\";white-space:pre}", ""]);

// exports
exports.locals = {
	"wrapper": "_1YEZg",
	"section": "Rffu4",
	"bold": "_2jGI9",
	"header": "VngHb",
	"trashButton": "_6lgsY",
	"newScholarship": "_1bUlY",
	"blur": "_3FkkZ",
	"scholarshipOption": "_1hPpf",
	"scholarshipInfo": "_3i-b8"
};