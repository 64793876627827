import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const TermInfoCardLabel = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;

  .icon {
    height: 0.625rem;
    width: 0.625rem;
    flex-shrink: 0;

    &.open {
      color: #0e5b2f;
    }

    &.coming-soon {
      color: #bc8c3f;
    }

    &.cancelled,
    &.closed {
      color: #999999;
    }
  }

  span {
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 0.875rem;
    line-height: 120%;
    letter-spacing: 0.0015em;
    margin-bottom: 0;
    color: #585858;
  }
`;

export default function Status({ status }) {
  const customAliases = useSelector(state => state.profile.customAliases);

  const statusMap = {
    active: !!customAliases.alias_enrollment ? `Open ${customAliases.alias_enrollment}` : 'Open Enrollment',
    cancelled: 'Cancelled',
    'coming-soon': 'Coming Soon',
    closed: 'Application Closed',
  };

  let statusClass = 'icon';

  switch (status) {
    case 'active':
      statusClass += ' open';
      break;
    case 'cancelled':
      statusClass += ' cancelled';
      break;
    case 'coming-soon':
      statusClass += ' coming-soon';
      break;
    case 'closed':
      statusClass += ' closed';
      break;
  }

  return (
    <TermInfoCardLabel>
      <svg
        className={statusClass}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 10 11"
        aria-hidden="true"
      >
        <circle cx="5" cy="5.674" r="5" fill="currentColor" />
      </svg>
      <span>{statusMap[status]}</span>
    </TermInfoCardLabel>
  );
}

Status.propTypes = {
  status: PropTypes.string.isRequired,
};
