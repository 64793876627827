import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { breakpoints } from '../../../utils/breakpoints';
import { buttonFocus } from '../../../utils/shared/focusStyles';
import { ScreenReaderText } from '../../../utils/shared/ScreenReader.styled';
import { TabCard, TabHeading, TabCardPanel, TabContentContainer } from '../TabContent.styled';

const FilesContainer = styled.table`
  grid-column: span 12 / span 12;
  display: flex;
  flex-direction: column;
`;

const FilesHeader = styled.tr`
  display: flex;
  align-items: center;
  padding: 0.8125rem 0.5625rem;
  border: 0.0625rem solid #eeeeee;
  border-bottom: 0.3125rem solid var(--theme_color_dark);
  background-color: #ffffff;
  justify-content: space-between;

  @media ${breakpoints.tablet} {
    padding-right: 4.75rem;
  }

  .files-header-text {
    font-family: 'Nunito', sans-serif;
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 1rem;
    color: #373737;
    margin-bottom: 0;
    display: inline-flex;
    align-items: end;
    gap: 0.375rem;

    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
`;

const FilesListItem = styled.tr`
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  padding: 0.5625rem 0.4375rem;
  align-items: center;

  @media ${breakpoints.tablet} {
    padding-right: 2.125rem;
  }

  &:nth-of-type(even) {
    background-color: #eeeeee;
  }

  .file-info {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 120%;
    letter-spacing: 0.0025em;
    color: #373737;
    margin-bottom: 0;
    word-break:break-word;
  }

  .no-results {
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 120%;
    letter-spacing: 0.0025em;
    color: #373737;
    margin-bottom: 0;
    margin-top: .5rem;
    flex: 1;
  }

  a {
    display: inline-flex;
    gap: 0.40625rem;
    align-items: center;
    align-self: flex-start;
    font-family: 'Open Sans', sans-serif;
    font-size: 0.75rem;
    line-height: 120%;
    letter-spacing: 0.06em;
    padding: 0.1875rem 0.3125rem;
    color: var(--theme_color_accent_font) !important;
    font-weight: 600;
    background: var(--theme_color_accent);
    border-radius: 5px;
    border: 2px solid transparent;

    ${buttonFocus}

    @media ${breakpoints.tablet} {
      padding: 0.5625rem 2.0625rem;
    }
  }
`;

export default function Resources() {
  const attachments = useSelector(state => state.programBrochure.attachments);

  return (
    <TabContentContainer>
      <TabHeading level={2}>Resources</TabHeading>
      <TabCard>
        <TabCardPanel className="full">
          <FilesContainer>
            <thead>
              <FilesHeader>
                <th className="files-header-text">File Name</th>
                <th className="files-header-text">
                  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" aria-hidden="true">
                    <path
                      d="M17.625 7.5H12.75v7.19l2.47-2.47a.75.75 0 0 1 1.06 1.06l-3.75 3.75a.75.75 0 0 1-1.06 0l-3.75-3.75a.75.75 0 0 1 1.06-1.06l2.47 2.47V7.5H6.375a2.628 2.628 0 0 0-2.625 2.625v9.75A2.628 2.628 0 0 0 6.375 22.5h11.25a2.627 2.627 0 0 0 2.625-2.625v-9.75A2.627 2.627 0 0 0 17.625 7.5ZM12.75 2.25a.75.75 0 1 0-1.5 0V7.5h1.5V2.25Z"
                      fill="#255A94"
                    />
                  </svg>
                  <span>Download</span>
                </th>
              </FilesHeader>
            </thead>
            <tbody>
              {attachments.length > 0 ? (
                attachments.map((file, index) => (
                  <FilesListItem key={`file-item-${index}`}>
                    <td className="file-info">
                      {file.title} {file.extension && `(${file.extension})`}
                    </td>
                    <td>
                      <a href={file.file.url} target="_blank" rel="noopener noreferrer">
                        Download
                        <ScreenReaderText>{file.title}</ScreenReaderText>
                      </a>
                    </td>
                  </FilesListItem>
                ))
              ) : (
                <FilesListItem>
                  <td className="no-results" colSpan={2}>
                    There are no available resources
                  </td>
                </FilesListItem>
              )}
            </tbody>
          </FilesContainer>
        </TabCardPanel>
      </TabCard>
    </TabContentContainer>
  );
}
