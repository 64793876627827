import React, { useState, useEffect } from 'react';
import { compose } from 'recompose';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import propTypes from 'prop-types';
import classnames from 'classnames';
import Select, { components } from 'react-select';
import ViaIcon from '../../../plans/components/ViaIcon';
import sInputSelect from './inputSelect.scss';

export const enhance = compose(withStyles(sInputSelect));

function InputSelect(props) {
  const [ariaLabel, setAriaLabel] = useState('');
  const isError = props.errorMessage.length > 0;
  const inputClass = classnames(
    sInputSelect['input-select'],
    sInputSelect[props.size],
    isError ? sInputSelect['error'] : '',
  );

  const helpLabelClass = classnames(
    sInputSelect['label-select'],
    isError ? sInputSelect['error'] : '',
    sInputSelect[props.labelSize],
    sInputSelect[props.labelFont],
  );

  const DropdownIndicator = props => {
    return props.isDisabled ? (
      <components.DropdownIndicator {...props}>
        <ViaIcon name="lock" color="gray" size="xsmall" />
      </components.DropdownIndicator>
    ) : (
      <components.DropdownIndicator {...props}>
        <ViaIcon name="chevronDown" size="xsmall" />
      </components.DropdownIndicator>
    );
  };

  const CustomDropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <ViaIcon name="chevronDown" size="xsmall" />
      </components.DropdownIndicator>
    );
  };

  const customStyles = !!props.adjustedWidth
    ? {
        container: provided => ({
          ...provided,
          width: props.adjustedWidth,
        }),
        placeholder: provided => ({
          ...provided,
          color: props.placeholderColor ? props.placeholderColor : '#373737',
          fontStyle: props.placeholderFontStyle ? props.placeholderFontStyle : 'Normal',
          fontFamily: props.placeholderFontfamily ? props.placeholderFontfamily : '',
        }),
        menu: (provided, state) => ({
          ...provided,
          zIndex: '12',
        }),
        singleValue: provided => ({
          ...provided,
          color: '#373737',

          disabled: {
            color: '#373737',
            backgroundColor: '#F6F7F9',
          },
        }),
        multiValueLabel: provided => ({
          color: '#373737',
          fontSize: '14px',
        }),
        control: provided => ({
          ...provided,
          borderColor: props.borderColor ? props.borderColor : '#ebebeb',
          minHeight: props.minHeight ? props.minHeight : '44px',
        }),
      }
    : {
        container: provided => ({
          ...provided,
        }),
        menu: (provided, state) => ({
          ...provided,
          zIndex: '12',
        }),
        placeholder: provided => ({
          ...provided,
          color: props.placeholderColor ? props.placeholderColor : '#373737',
          fontStyle: props.placeholderFontStyle ? props.placeholderFontStyle : 'Normal',
          fontFamily: props.placeholderFontfamily ? props.placeholderFontfamily : '',
        }),
        singleValue: provided => ({
          ...provided,
          color: '#373737',
          disabled: {
            color: '#373737',
            backgroundColor: '#F6F7F9',
          },
        }),
        multiValueLabel: provided => ({
          color: '#373737',
          fontSize: '14px',
        }),
        control: provided => ({
          ...provided,
          borderColor: props.borderColor ? props.borderColor : '#ebebeb',
          minHeight: props.minHeight ? props.minHeight : '44px',
        }),
      };

  const cleanStyle = {
    container: provided => ({
      ...provided,
    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: '12',
    }),
    placeholder: provided => ({
      ...provided,
      color: props.placeholderColor ? props.placeholderColor : '#373737',
      fontStyle: props.placeholderFontStyle ? props.placeholderFontStyle : 'Normal',
      fontFamily: props.placeholderFontfamily ? props.placeholderFontfamily : '',
    }),
    singleValue: provided => ({
      ...provided,
      color: '#373737',
      disabled: {
        color: '#373737',
        backgroundColor: '#F6F7F9',
      },
    }),
    control: provided => ({
      ...provided,
      borderColor: '#ebebeb',
      minHeight: '44px',
    }),
    indicatorSeparator: styles => ({
      display: 'none',
    }),
    dropdownIndicator: provided => ({
      width: '16px',
    }),
  };

  useEffect(() => {
    const defineAria = props['aria-label'] || props.labelText || 'Select input';
    setAriaLabel(defineAria);
  }, [props]);

  return (
    <>
      {!!props.labelText && !props.cleanStyle && (
        <label className={helpLabelClass} htmlFor={props.id}>
          {props.labelText} {props.isRequired ? <div className={sInputSelect.required}>*</div> : ''}
        </label>
      )}
      {props.cleanStyle && <div className={sInputSelect['clean-label']}>{props.labelText}</div>}
      <Select
        options={props.options}
        className={inputClass}
        components={{ DropdownIndicator: props?.showOriginalIndicator ? CustomDropdownIndicator : DropdownIndicator }}
        inputId={props?.id ? props?.id : ''}
        styles={!props.cleanStyle ? customStyles : cleanStyle}
        aria-label={ariaLabel}
        {...props}
      />
      {!!props.helpText && !isError && <span className={sInputSelect['help-select']}>{props.helpText}</span>}
      {!!isError && (
        <span className={sInputSelect['error-select']} aria-live="assertive">
          {props.errorMessage}
        </span>
      )}
    </>
  );
}

InputSelect.propTypes = {
  errorMessage: propTypes.string,
  labelText: propTypes.string,
  helpText: propTypes.string,
  placeholder: propTypes.string,
  isRequired: propTypes.bool,
  options: propTypes.array.isRequired,
  size: propTypes.oneOf(['medium', 'normal']),
  labelSize: propTypes.oneOf(['medium', 'normal']),
  labelFont: propTypes.oneOf(['nextMedium', 'regular']),
  cleanStyle: propTypes.bool,
};

InputSelect.defaultProps = {
  errorMessage: '',
  labelText: '',
  helpText: '',
  placeholder: '',
  isRequired: false,
  size: 'normal',
  labelSize: 'medium',
  labelFont: 'nextMedium',
  cleanStyle: false,
};

export default enhance(InputSelect);
