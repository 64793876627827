import React, { useEffect, useState } from 'react';
import { Formik, Field, Form } from 'formik';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import propTypes from 'prop-types';
import moment from 'moment-timezone';
import _get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import ActivityTypeSelect from '../../selects/activityTypeSelect';
import Button from '../../../../../sites/components/library/button';
import InputTimePicker from '../../../../../sites/components/library/inputTimePicker';
import InputTextArea from '../../../../../sites/components/library/inputTextArea';
import InputText from '../../../../../sites/components/library/inputText';
import InputDate from '../../../../../sites/components/library/inputDate';
import { addDetail, getPlan, patchDetail } from '../../../../../actions/plans';
import EditDetailSuccessModal from '../../modals/editDetailSuccessModal';
import GroupAddDetailSuccessModal from '../../modals/groupAddDetailSuccessModal';
import LocationSearch from '../../../../components/library/locationSearch';
import Paragraph from '../../../../components/library/paragraph';
import sActivityForm from './activityForm.scss';
import ScreenReaderText from '../../../../travelerProfile/components/base/ScreenReaderText.styled';

function ActivityForm(props) {
  const dispatch = useDispatch();
  const planDetail = useSelector(state => state.plans.addDetail);
  const [showScreenReader, setShowScreenReader] = useState(false);
  const isEdit = props.isEdit;
  const defaultTimezone = '11'; // Mountain time (US & Canada)
  const [saving, setSaving] = useState(false);
  const [success, setSuccess] = useState(false);
  const [newSuccess, setNewSuccess] = useState(false);
  const [activityLocation, setActivityLocation] = useState();
  const [checked, setChecked] = useState(props.details ? props.details.force_add : false);

  const [defaultValues, setDefaultValues] = useState(() => {
    const lat = _get(props, 'details.location.lat') || '';
    const lng = _get(props, 'details.location.lng') || '';
    const google_place_id = _get(props, 'details.location.google_place_id') || '';
    const location = {
      id: _get(props, 'details.location.id') || '',
      formatted_address: _get(props, 'details.location.formatted_address') || '',
      google_place_id: google_place_id,
      country_alpha2_code: _get(props, 'details.location.country_alpha2_code') || '',
      country_common_name: _get(props, 'details.location.country_common_name') || '',
      county_or_region: _get(props, 'details.location.county_or_region') || '',
      locality: _get(props, 'details.location.locality') || '',
      postal_code: _get(props, 'details.location.postal_code') || '',
      postal_code_suffix: _get(props, 'details.location.postal_code_suffix') || '',
      state_or_province: _get(props, 'details.location.state_or_province') || '',
      state_or_province_code: _get(props, 'details.location.state_or_province_code') || '',
      street: _get(props, 'details.location.street') || '',
      street_number: _get(props, 'details.location.street_number') || '',
      lat: lat,
      lng: lng,
      time_zone: _get(props, 'details.location.time_zone') || '',
      time_zone_offset: _get(props, 'details.location.time_zone_offset') || '',
      mapUrl: `https://www.google.com/maps/search/?api=1&query=${lat},${lng}&query_place_id=${google_place_id}`,
    };

    setActivityLocation(null);
    setActivityLocation(location);

    return {
      name: _get(props, 'details.name') || '',
      nickname: _get(props, 'details.nickname') || '',
      tp_activity_type_id: _get(props, 'details.tp_activity_type_id') || '',
      tp_activity_type_name: _get(props, 'details.tp_activity_type_name') || 'Select detail type...',
      provider_name: _get(props, 'details.provider_name') || '',
      confirmation_number: _get(props, 'details.confirmation_number') || '',
      provider_contact_name: _get(props, 'details.provider_contact_name') || '',
      line_1: _get(props, 'details.provider_address.line_1') || '',
      line_2: _get(props, 'details.provider_address.line_2') || '',
      city: _get(props, 'details.provider_address.city') || '',
      state_or_province: _get(props, 'details.provider_address.state_or_province') || '',
      zip_code: _get(props, 'details.provider_address.zip_code') || '',
      via_country_id: _get(props, 'details.provider_address.via_country_id') || '',
      via_country_name: _get(props, 'details.provider_address.via_country_name') || '',
      start_date: _get(props, 'details.start_date') || '',
      start_time: _get(props, 'details.start_time') || '',
      start_tp_time_zone_id: defaultTimezone,
      end_date: _get(props, 'details.end_date') || '',
      end_time: _get(props, 'details.end_time') || '',
      end_tp_time_zone_id: defaultTimezone,
      notes: _get(props, 'details.notes') || '',
      group: _get(props, 'details.group', props.group),
      removed: _get(props, 'details.removed', false),
      force_add: _get(props, 'details.removed', checked),
      location_attributes: location,
    };
  });

  useEffect(() => {
    if (planDetail) {
      if (planDetail.loading) {
        setSaving(true);
      }

      if (saving && !!planDetail.detail) {
        setSaving(false);

        if (planDetail.detail.attributes.group && isEdit) {
          setSuccess(true);
        } else if (planDetail.detail.attributes.group && !isEdit) {
          setNewSuccess(true);
        } else {
          // dispatch(getPlan(props.planId));
          props.onClose(false);
        }
      }

      if (saving && !!planDetail.error) {
        setSaving(false);
        // alert(planDetail.error);
      }
    }
  }, [planDetail]);

  const customStyles = {
    placeholder: (provided, state) => ({
      ...provided,
      color: 'black',
    }),
  };

  return (
    <>
      {success ? (
        <EditDetailSuccessModal
          onClose={() => {
            props.onClose(false);
          }}
        />
      ) : newSuccess ? (
        <GroupAddDetailSuccessModal
          planId={props.planId}
          onClose={() => {
            props.onClose(false);
          }}
        />
      ) : (
        <div className={sActivityForm['activity-form']}>
          <Formik
            enableReinitialize
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={defaultValues}
            validate={values => {
              const errors = {};

              setShowScreenReader(false);

              values.notes = values.notes.trim();
              values.start_date = moment(values.start_date).isValid()
                ? moment(values.start_date).format('YYYY-MM-DD')
                : '';
              values.end_date = moment(values.end_date).isValid() ? moment(values.end_date).format('YYYY-MM-DD') : '';

              if (!values.provider_name) {
                errors.provider_name = 'Provider Name Required';
              }
              if (!values.tp_activity_type_id) {
                errors.tp_activity_type_id = 'Activity Type Required';
              }
              if (!values.start_date) {
                errors.start_date = 'Start Date Required';
              }
              if (!values.start_time) {
                errors.start_time = 'Start Time Required';
              }
              if (!values.end_date) {
                errors.end_date = 'End Date Required';
              }
              if (values.end_date && moment(values.end_date).isBefore(moment(values.start_date), 'day')) {
                errors.end_date = 'End Date must be after Start Date';
              }
              if (!values.end_time) {
                errors.end_time = 'End Time Required';
              }
              if (!values.location_attributes.lat || !values.location_attributes.lng) {
                errors.location_attributes = 'Select an address';
              }

              if (Object.keys(errors).length > 0) {
                setTimeout(() => {
                  setShowScreenReader(true);
                }, 1000);
              }

              return errors;
            }}
            onSubmit={values => {
              const submitValues = {
                plan_activity: {
                  nickname: values.nickname,
                  tp_activity_type_id: values.tp_activity_type_id,
                  name: values.name,
                  provider_contact_name: values.provider_contact_name,
                  provider_name: values.provider_name,
                  confirmation_number: values.confirmation_number,
                  start_date: values.start_date,
                  start_time: moment(values.start_time).isValid()
                    ? moment(values.start_time).format('h:mm A')
                    : values.start_time,
                  start_tp_time_zone_id: '11',
                  end_date: values.end_date,
                  end_time: moment(values.end_time).isValid()
                    ? moment(values.end_time).format('h:mm A')
                    : values.end_time,
                  end_tp_time_zone_id: '11',
                  location_attributes: {
                    id: values.location_attributes.id,
                    country_alpha2_code: values.location_attributes.country_alpha2_code,
                    country_common_name: values.location_attributes.country_common_name,
                    county_or_region: values.location_attributes.county_or_region,
                    formatted_address: values.location_attributes.formatted_address,
                    google_place_id: values.location_attributes.google_place_id,
                    lat: values.location_attributes.lat,
                    locality: values.location_attributes.locality,
                    lng: values.location_attributes.lng,
                    postal_code: values.location_attributes.postal_code,
                    postal_code_suffix: values.location_attributes.postal_code_suffix,
                    state_or_province: values.location_attributes.state_or_province,
                    state_or_province_code: values.location_attributes.state_or_province_code,
                    street: values.location_attributes.street,
                    street_number: values.location_attributes.street_number,
                    time_zone: values.location_attributes.time_zone,
                    time_zone_offset: values.location_attributes.time_zone_offset,
                  },
                  notes: values.notes,
                  group: values.group,
                  removed: values.removed,
                  force_add: checked,
                },
              };

              if (isEdit) {
                dispatch(patchDetail(props.planId, props.itemId, submitValues, 'plan_activities'));
              } else {
                dispatch(addDetail(props.planId, submitValues, 'plan_activities'));
              }
            }}
          >
            {({ values, handleSubmit, errors, handleChange }) => (
              <Form onSubmit={handleSubmit}>
                <div className={sActivityForm.subHeader}>
                  <Paragraph italic centered>
                    Please complete all required fields
                    {showScreenReader && (
                      <ScreenReaderText>
                        {/* Rendering error messages in a list */}
                        {Object.keys(errors).length > 0 && (
                          <div role="alert" aria-live="assertive">
                            <ul>
                              {Object.keys(errors).map((key, index) => (
                                <li key={index}>{errors[key]}</li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </ScreenReaderText>
                    )}
                  </Paragraph>
                </div>

                <div className={sActivityForm.divider}>
                  <Paragraph italic>Activity Details</Paragraph>
                </div>

                <div className={sActivityForm.newrow}>
                  <div className={sActivityForm.item}>
                    <Field name="nickname">
                      {({ form }) => (
                        <InputText
                          id="nickname"
                          name="nickname"
                          autocomplete="off"
                          labelText="Detail Nickname"
                          value={values.nickname}
                          onChange={e => {
                            form.setFieldValue('nickname', e.target.value);
                          }}
                          placeholder="Add a custom name for this detail..."
                        />
                      )}
                    </Field>
                  </div>
                </div>
                <div className={sActivityForm.newrow}>
                  <div className={sActivityForm.item}>
                    <Field name="tp_activity_type_id">
                      {({ field, form }) => (
                        <ActivityTypeSelect
                          labelText="Activity Detail Type"
                          isRequired
                          adjustedWidth="48%"
                          id="tp_activity_type_id"
                          name="tp_activity_type_id"
                          value={field.tp_activity_type_id}
                          styles={customStyles}
                          placeholder={values.tp_activity_type_name}
                          errorMessage={errors.tp_activity_type_id}
                          onChange={e => {
                            form.setFieldValue('tp_activity_type_id', e.value);
                          }}
                        />
                      )}
                    </Field>
                  </div>
                </div>
                <div className={sActivityForm.newrow}>
                  <div className={sActivityForm.item}>
                    <Field name="provider_name">
                      {({ form }) => (
                        <InputText
                          id="provider_name"
                          name="provider_name"
                          labelText="Activity Provider Name"
                          autocomplete="off"
                          value={values.provider_name}
                          onChange={e => form.setFieldValue('provider_name', e.target.value)}
                          isRequired
                          errorMessage={errors.provider_name}
                          placeholder="Add the business or tour operator associated with this activity..."
                        />
                      )}
                    </Field>
                  </div>
                </div>
                <div className={sActivityForm.newrow}>
                  <div className={sActivityForm['item-address']}>
                    <Field name="location_attributes">
                      {({ form }) => (
                        <LocationSearch
                          id="location_attributes"
                          name="location_attributes"
                          isRequired
                          labelText="Address Search"
                          placeholder="Type to select a city and country, or address..."
                          searchType="address"
                          selectHandler={e => {
                            if (!e.loading) {
                              values.location_attributes.formatted_address = e.formatted_address;
                              values.location_attributes.google_place_id = e.google_place_id;
                              values.location_attributes.country_alpha2_code = e.country_alpha2_code;
                              values.location_attributes.country_common_name = e.country_common_name;
                              values.location_attributes.county_or_region = e.county_or_region;
                              values.location_attributes.locality = e.locality;
                              values.location_attributes.postal_code = e.postal_code;
                              values.location_attributes.postal_code_suffix = e.postal_code_suffix;
                              values.location_attributes.state_or_province = e.state_or_province;
                              values.location_attributes.state_or_province_code = e.state_or_province_code;
                              values.location_attributes.street = e.street;
                              values.location_attributes.street_number = e.street_number;
                              values.location_attributes.lat = e.lat.toString();
                              values.location_attributes.lng = e.lng.toString();
                              values.location_attributes.time_zone = e.time_zone;
                              values.location_attributes.time_zone_offset = e.time_zone_offset;
                              values.location_attributes.mapUrl = e.mapUrl;

                              setActivityLocation(null);
                              setActivityLocation(values.location_attributes);
                            }
                          }}
                          onChange={handleChange}
                          errorMessage={errors.location_attributes}
                          value={values.location_attributes}
                        />
                      )}
                    </Field>
                  </div>
                  <div className={sActivityForm.item}>
                    <Paragraph size="small" bold>
                      Selected Address
                    </Paragraph>
                    {!activityLocation ||
                    !activityLocation.formatted_address ||
                    activityLocation.formatted_address.length === 0 ? (
                      <Paragraph size="small" italic>
                        Please search for your activity address.
                      </Paragraph>
                    ) : (
                      <>
                        <Paragraph size="small">Address: {activityLocation.formatted_address}</Paragraph>
                        <Paragraph size="small">Timezone: {activityLocation.time_zone}</Paragraph>
                        <Paragraph size="small">
                          <a href={activityLocation.mapUrl} rel="noopener noreferrer" target="_blank" className={sActivityForm.mapLink}>
                            See it on a map
                          </a>
                        </Paragraph>
                      </>
                    )}
                  </div>
                </div>
                <div className={sActivityForm.newrow}>
                  <div className={sActivityForm.item}>
                    <Field name="start_date">
                      {({ field, form }) => (
                        <InputDate
                          labelText="Start Date"
                          errorMessage={errors.start_date}
                          id="start_date"
                          name="start_date"
                          isRequired
                          value={field.start_date}
                          onChange={e => {
                            form.setFieldValue('start_date', e);
                          }}
                          placeholder={
                            values.start_date ? moment(values.start_date).format('MMM D, YYYY') : 'Select Date...'
                          }
                        />
                      )}
                    </Field>
                  </div>
                  <div className={sActivityForm.item}>
                    <Field name="start_time">
                      {({ field, form }) => (
                        <InputTimePicker
                          labelText="Start Time"
                          aria-label="Start Time"
                          id="start_time"
                          name="start_time"
                          isRequired
                          value={values.start_time ? moment(values.start_time, 'h:mm A') : null}
                          errorMessage={errors.start_time}
                          onChange={e => form.setFieldValue('start_time', e)}
                          helpText="Time zone added based on location information"
                        />
                      )}
                    </Field>
                  </div>
                </div>
                <div className={sActivityForm.newrow}>
                  <div className={sActivityForm.item}>
                    <Field name="end_date">
                      {({ field, form }) => (
                        <InputDate
                          labelText="End Date"
                          errorMessage={errors.end_date}
                          id="end_date"
                          name="end_date"
                          isRequired
                          value={field.end_date}
                          onChange={e => {
                            form.setFieldValue('end_date', e);
                          }}
                          placeholder={
                            values.end_date ? moment(values.end_date).format('MMM D, YYYY') : 'Select Date..'
                          }
                        />
                      )}
                    </Field>
                  </div>
                  <div className={sActivityForm.item}>
                    <Field name="end_time">
                      {({ field, form }) => (
                        <InputTimePicker
                          labelText="End Time"
                          id="end_time"
                          name="end_time"
                          isRequired
                          value={values.end_time ? moment(values.end_time, 'h:mm A') : null}
                          errorMessage={errors.end_time}
                          onChange={e => form.setFieldValue('end_time', e)}
                          helpText="Time zone added based on location information"
                        />
                      )}
                    </Field>
                  </div>
                </div>

                <div className={sActivityForm.divider}>
                  <Paragraph italic>Additional Activity Information</Paragraph>
                </div>
                <div className={sActivityForm.newrow}>
                  <div className={sActivityForm.item}>
                    <Field name="provider_contact_name">
                      {({ form }) => (
                        <InputText
                          id="provider_contact_name"
                          name="provider_contact_name"
                          autocomplete="off"
                          labelText="Activity Provider Contact Name"
                          value={values.provider_contact_name}
                          onChange={e => form.setFieldValue('provider_contact_name', e.target.value)}
                        />
                      )}
                    </Field>
                  </div>
                </div>
                <div className={sActivityForm.newrow}>
                  <div className={sActivityForm.item}>
                    <Field name="confirmation_number">
                      {({ form }) => (
                        <InputText
                          id="confirmation_number"
                          name="confirmation_number"
                          autocomplete="off"
                          labelText="Confirmation #"
                          value={values.confirmation_number}
                          onChange={e => form.setFieldValue('confirmation_number', e.target.value)}
                        />
                      )}
                    </Field>
                  </div>
                </div>
                <div className={sActivityForm.newrow}>
                  <div className={sActivityForm.item}>
                    <Field name="notes">
                      {({ field, form }) => (
                        <InputTextArea
                          id="notes"
                          name="notes"
                          labelText="Notes"
                          value={values.notes}
                          onChange={e => {
                            form.setFieldValue('notes', e.target.value);
                          }}
                        />
                      )}
                    </Field>
                  </div>
                </div>
                {props.group ? (
                  <div className={sActivityForm.row}>
                    <div className={sActivityForm.item}>
                      <Field name="force_add">
                        {({ field, form }) => (
                          <div
                            className={
                              isEdit
                                ? sActivityForm['disabled-force-add-checkbox']
                                : sActivityForm['force-add-checkbox']
                            }
                          >
                            <input
                              type="checkbox"
                              id="force_add"
                              name="force_add"
                              value={values.force_add}
                              readOnly={isEdit ? true : false}
                              onClick={!isEdit ? () => setChecked(!checked) : null}
                              checked={checked}
                            />
                            <label htmlFor="force_add">
                              Add this detail to all Travelers added to this Group Plan, now and in the future
                            </label>
                          </div>
                        )}
                      </Field>
                    </div>
                  </div>
                ) : null}

                <div className={sActivityForm['button-row']}>
                  <div className={sActivityForm['button-content']}>
                    <Button
                      display="secondary"
                      kind="solid"
                      size="medium"
                      ariaLabel="Cancel"
                      onClick={() => {
                        props.onClose(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                  <div className={sActivityForm['button-content']}>
                    <Button display="primary" kind="solid" size="medium" type="submit" ariaLabel={isEdit ? "Save" : "Add"}>
                      {isEdit ? 'Save' : 'Add'}
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </>
  );
}

ActivityForm.propTypes = {
  planId: propTypes.string.isRequired,
  onClose: propTypes.func,
  group: propTypes.bool,
};

ActivityForm.defaultProps = {
  group: false,
};

export default withStyles(sActivityForm)(ActivityForm);
