import React from 'react';
import styles from './TextInputStyles.scss';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import classnames from 'classnames/bind';
import Cleave from 'cleave.js/react';

const MaskedInput = ({ name, label, options, value, onChange, ...rest }) => {
    
  return (
    <div className={styles.root}>
      <div className={styles.inputWrapper}>
        <Cleave
          id={name}
          name={name}
          value={value}
          type="text"
          options={options}
          autoComplete="nope"
          className={value ? `${styles.fieldAdded + ' ' + styles.textInput}` : styles.textInput}
          onChange={onChange}
          {...rest}
        />
        <label>{label}</label>
      </div>
    </div>
  )
};


export default withStyles(styles)(MaskedInput)