import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import styles from "./travelerPlans.scss"
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { compose } from 'recompose';
import PlanCardSmall from '../../../sites/plans/components/planCardSmall';
import { getAllPlans } from '../../../actions/plans';
import LoadingScreen from '../../../styledComponents/styles/LoadingScreen';
import magnifier from '../../../../public/img/magnifier.png';

export const enhance = compose(withStyles(styles));

const TravelerPlans = (props) => {
  const dispatch = useDispatch();
  const plansData = useSelector(state => state.plans.allPlans);
  const [plans, setPlans] = useState([])
  const [activeTab, setActiveTab] = useState('upcoming');
  const [noPlanMessage, setNoPlanMessage] = useState("You have no upcoming travel plans");  

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      dispatch(getAllPlans(props.id));
    }
    return () => {
      unmounted = true;
    };
  }, []);

  useEffect(()=>{
    
    if(plansData) {
      plansData.upcoming_plans = [...(plansData.upcoming_plans || []),
      ...(plansData.onsite_plans || [])];
      setPlans(plansData);
    }
  },[plansData])

  return (
    <div>
        <div className={styles['my-travel-header']}>
            <div className={styles['title']}>Traveler Plans</div>
            <div>
            <ul className={styles['my-travel-header-list']}>
                <li>
                    <button
                    className={activeTab === 'upcoming' && styles['button-active']}
                    onClick={() => {
                        setActiveTab('upcoming');
                        setNoPlanMessage("");
                        setTimeout(()=>{
                            setNoPlanMessage("You have no upcoming travel plans");
                        },0);
                        }
                    }
                    >
                    Upcoming & On-site ({plans?.upcoming_plans?.length || 0})
                    </button>
                </li>
                {/* <li>
                    <button
                    className={activeTab === 'onsite' && styles['button-active']}
                    onClick={() => setActiveTab('onsite')}
                    >
                    On-site ({plans?.onsite_plans?.length || 0})
                    </button>
                </li> */}
                <li>
                    <button className={activeTab === 'past' && styles['button-active']} onClick={() => setActiveTab('past')}>
                    Past ({plans?.past_plans?.length || 0})
                    </button>
                </li>         
            </ul>
            </div>
        </div>
        <div>
        <div className={styles['my-travel-content']}>
        {activeTab === 'upcoming' && (
          <div className={styles.content}>
            {plans && plans.loading ? (
              <LoadingScreen displayText="Loading upcoming plans..." width="100%" height="90vh" />
            ) : !!plans && !!plans.upcoming_plans && plans.upcoming_plans.length > 0 ? (
              plans.upcoming_plans.map(plan => (
                <div key={plan.id} className={styles.item}>
                  <PlanCardSmall onClick={props.onClick} key={plan.id} userID={props.id} plan={plan} isAdmin={true} />
                </div>
              ))
            ) : (
              <div className={styles.nodataContainer}>
                <img src={magnifier} alt="Search Group Travel Plans" className={styles.nodataimg} />
                <div className={styles.nodataText} aria-live="polite" role="alert" tabIndex={0}>{noPlanMessage}</div>
              </div>
            )}
          </div>
        )}
        {/* {activeTab === 'onsite' && (
          <div className={styles.content}>
            {plans && plans.loading ? (
              <LoadingScreen displayText="Loading on-site plans..." width="100%" height="80vh" />
            ) : !!plans && !!plans.onsite_plans && plans.onsite_plans.length > 0 ? (
              plans.onsite_plans.map(plan => (
                <div key={plan.id} className={styles.item}>
                  <PlanCardSmall key={plan.id} userID={props.id} plan={plan} isAdmin={true} />
                </div>
              ))
            ) : (
              <div className={styles.nodataContainer}>
                <img src={magnifier} alt="Search Group Travel Plans" className={styles.nodataimg} />
                <div className={styles.nodataText} aria-live="polite" role="alert" tabIndex={0}>You have no on-site plans</div>
              </div>
            )}
          </div>
        )} */}
        {activeTab === 'past' && (
          <div className={styles.content}>
            {plans && plans.loading ? (
              <LoadingScreen displayText="Loading past plans..." width="100%" height="80vh" />
            ) : !!plans && !!plans.past_plans && plans.past_plans.length > 0 ? (
              plans.past_plans.map(plan => (
                <div key={plan.id} className={styles.item}>
                  <PlanCardSmall onClick={props.onClick} key={plan.id} userID={props.id} plan={plan} isAdmin={true} />
                </div>
              ))
            ) : (
              <div className={styles.nodataContainer}>
                <img src={magnifier} alt="Search Group Travel Plans" className={styles.nodataimg} />
                <div className={styles.nodataText} aria-live="polite" role="alert" tabIndex={0}>You have no past plans</div>
              </div>
            )}
          </div>
        )}
       
      </div>
        </div>
    </div>
  )
}

export default enhance(TravelerPlans)
