import React from 'react';
import classnames from 'classnames';
import propTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import MoonLoader from 'react-spinners/MoonLoader';
import BarLoader from 'react-spinners/BarLoader';
import CircleLoader from 'react-spinners/CircleLoader';
import PuffLoader from 'react-spinners/PuffLoader';

import s from './spinner.scss';

export function Spinner(props = {}) {
  let setSize = 18;
  if (props.size === 'small') {
    setSize = 14;
  } else if (props.size === 'large') {
    setSize = 22;
  } else if (props.size === 'dataLoading') {
    setSize = 40;
  } else if (props.size === 'xlarge') {
    setSize = 70;
  }

  const spinnerTypes = {
    moon: <MoonLoader size={setSize} color={props.color} />,
    circle: <CircleLoader size={setSize} color={props.color} />,
    puff: <PuffLoader size={setSize} color={props.color} />,
    bar: <BarLoader size={setSize} color={props.color} speedMultiplier={0.4} width={'100%'} />,
  };

  return <div className={classnames('spinner', s[props.size])}>{spinnerTypes[props.kind]}</div>;
}

Spinner.propTypes = {
  size: propTypes.oneOf(['small', 'regular', 'large', 'dataLoading', 'xlarge']),
  kind: propTypes.oneOf(['moon', 'bar', 'circle', 'puff']),
  color: propTypes.string,
};

Spinner.defaultProps = {
  size: 'regular',
  kind: 'moon',
  color: '#e32064',
};

export default withStyles(s)(Spinner);
