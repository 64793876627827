import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { compose } from 'recompose';
import { useDispatch, useSelector } from 'react-redux';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import sModal from 'react-responsive-modal/styles.css';
import Header from '../../../../components/Header';
import Footer from '../../../components/footer';
import Hubspot from '../../../../components/Hubspot';
import { fetchCurrentTravelerUser, fetchCurrentUser } from '../../../../actions/profileActions';
import userId from '../../../../shared/helpers/userId';
import { Spinner } from '../../../components/library/spinner';
import sPage from './page.scss';

export const enhance = compose(withStyles(sModal, sPage));

function Page(props) {
  const { children } = props;
  const userProfile = useSelector(state => state.profile);
  const dispatch = useDispatch();
  const [profile, setProfile] = useState();
  const [loading, setLoading] = useState(true);
  const [traveler, setTraveler] = useState(true);

  if (!userId || typeof userId === 'undefined') {
    window.location.assign(`/`);
  }

  useEffect(() => {
    let unmounted = false;
    setLoading(userProfile.userLoading);
    if (!unmounted) {
      if (userProfile && userProfile.userLoading) {
        if (props.authAdmin) {
          dispatch(fetchCurrentUser(userId));
        } else {
          dispatch(fetchCurrentTravelerUser(userId));
        }
      }
    }
    return () => {
      unmounted = true;
    };
  }, []);

  useEffect(() => {
    if (userProfile && !userProfile.userLoading) {
      const isTraveler = !userProfile.admin_sign_in || false;
      setTraveler(isTraveler);

      if (!userProfile.clientFeatureList || !userProfile.clientFeatureList.travel_plans) {
        window.location.assign(`/`);
      }

      if (isTraveler && props.authAdmin) {
        window.location.assign(`/plans/my-travel`);
      }
      if (!isTraveler && props.authTraveler) {
        window.location.assign(`/plans/dashboard`);
      }
    }

    setLoading(userProfile.userLoading);
    setProfile(userProfile);
  }, [userProfile]);

  return (
    <nav>
      {props.authAdmin ? <Hubspot /> : ''}
      <div className={sPage.page}>
        {!loading ? (
          <>
            <Header role={traveler ? 'Traveler' : 'Admin'} />
            <div className={props.subHeader ? sPage.sub : sPage.full}>{children}</div>
          </>
        ) : (
          <>
            <div>
              <Spinner />
            </div>
          </>
        )}
        <Footer />
      </div>
    </nav>
  );
}

Page.propTypes = {
  children: propTypes.node,
  authAdmin: propTypes.bool,
  authTraveler: propTypes.bool,
  subHeader: propTypes.bool,
};

Page.defaultProps = {
  subHeader: false,
};

export default enhance(Page);
