import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { PropTypes } from 'prop-types';

import s from './TableCheckbox.css';

const TableCheckbox = ({ name, value, onChange, disabled, checked, alternateColor }) => (
  <span className={s.checkboxWrapper}>
    <input
      type="checkbox"
      name={name}
      value={value}
      disabled={disabled}
      checked={checked}
      onChange={onChange}
      className={s.checkboxInput}
    />
    <span className={`${s.checkmark} ${ alternateColor && s.checkmarkAlternative }`} />
  </span>
);
TableCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  alternateColor: PropTypes.bool,
};

export default withStyles(s)(TableCheckbox);
