import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import styles from './TextInputStyles.scss';

class TextInput extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      name,
      value,
      type,
      label,
      pre_filled,
      rest,
      disabled,
      onBlur,
      onChange,
    } = this.props;

    return (
      <div className={styles.root}>
        <div className={styles.inputWrapper}>
          <form autocomplete="off">
            <input
              id={name}
              name={name}
              type={type || 'text'}
              value={value}
              onChange={onChange}
              disabled={disabled}
              className={
                value
                  ? `${styles.fieldAdded + ' ' + styles.textInput}`
                  : styles.textInput
              }
              {...rest}
              onBlur={onBlur}
              onFocus={false}
            />
            <label>{label}</label>
          </form>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(TextInput);
