import React, { useEffect, useState } from 'react';
import AccessDenied from '../../../../../sites/viaGlobal/components/pages/accessDenied';
import { ContentContainer } from '../../../../../styledComponents/styles/Containers.styled';
import NotificationsForm from './notifications.form';
import { PageHeader } from '../../../../../styledComponents/styles/PageHeader.styled';
import PremiumFeature from '../../../../../sites/viaGlobal/components/pages/premiumFeature';
import propTypes from 'prop-types';
import { ReadOnlyBanner } from '../../../../../styledComponents/styles/Banners.styled';
import { Spinner } from '../../../../../styledComponents/styles/spinner.styled';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

const LoadingContainer = styled.div`
  padding-top: 100px;
  text-align-last: center;
`;

const Container = styled(ContentContainer)`
  margin: 56px 77px;
`;

export default function Notifications() {
  const freemium = useSelector(state => state.profile.freemium);
  const [loading, setLoading] = useState(true);
  const [errorMsg, setErrorMsg] = useState('');
  const organization = useSelector(state => state.orgSettings.organization);
  const clientFeatureList = useSelector(state => state.profile.clientFeatureList);

  useEffect(
    () => {
      if (!!organization) {
        setLoading(false);
      }
    },
    [organization],
  );

  return (
    <Container>
      {loading || organization.loading ? (
        <>
          <PageHeader>
            {!clientFeatureList.legacy && clientFeatureList.legacy ? 'My Via Global Notifications' : 'My Notifications'}
          </PageHeader>
          <LoadingContainer>
            <Spinner displayText={`Loading Notifications`} />
          </LoadingContainer>
        </>
      ) : freemium ? (
        <PremiumFeature header={`My Notifications`} />
      ) : organization.error ? (
        <ErrorPage
          errorMsg={errorMsg}
          header={
            !clientFeatureList.legacy && clientFeatureList.legacy ? 'My Via Global Notifications' : 'My Notifications'
          }
        />
      ) : (
        <>{!!organization && <NotificationsForm organization={organization} />}</>
      )}
    </Container>
  );
}
