import React from 'react';

export default function PencilIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2015 3.35295C11.3839 3.35283 11.5589 3.42528 11.6878 3.55431L12.4407 4.30744C12.5696 4.43647 12.642 4.61151 12.6419 4.79399C12.6418 4.97648 12.5692 5.15143 12.4401 5.2803L3.91858 13.7844C3.85473 13.8481 3.77897 13.8986 3.69561 13.9331L2.44863 14.4484C2.19196 14.5544 1.8967 14.4955 1.70032 14.2991C1.50395 14.1026 1.44507 13.8072 1.5511 13.5505L2.0662 12.303C2.10063 12.2196 2.15113 12.1438 2.21483 12.0799L10.7154 3.55491C10.8442 3.42572 11.0191 3.35306 11.2015 3.35295Z"
        fill="#446372"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.8075 1.59158C12.9534 1.53109 13.1099 1.49996 13.2679 1.49996C13.4258 1.49996 13.5823 1.53109 13.7282 1.59158C13.8742 1.65206 14.0068 1.74071 14.1185 1.85246C14.1185 1.85247 14.1185 1.85248 14.1185 1.85249L14.1421 1.87608C14.3675 2.10174 14.4941 2.4077 14.4941 2.72672C14.4941 3.04574 14.3675 3.3517 14.1421 3.57736L13.7736 3.94598C13.5052 4.21447 13.0701 4.21447 12.8018 3.94598L12.0489 3.19286C11.92 3.06389 11.8476 2.88896 11.8477 2.70657C11.8477 2.52419 11.9202 2.34929 12.0491 2.22038L12.4172 1.85249C12.4172 1.85244 12.4173 1.85239 12.4173 1.85234C12.4174 1.85231 12.4174 1.85228 12.4174 1.85225C12.5291 1.7406 12.6616 1.65202 12.8075 1.59158ZM13.656 3.09141L13.7736 2.97371L13.4091 2.60902L13.656 2.36203L13.409 2.60893L13.3857 2.58558L13.0208 2.22058"
        fill="#373737"
      />
    </svg>
  );
}
