import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import ProfileFormGrid from '../../base/ProfileFormGrid.styled';
import ProfileTextInput from '../../base/inputs/ProfileTextInput';
import ProfileSelectInput from '../../base/inputs/ProfileSelectInput';
import GridColumnPlaceholder from '../../base/GridColumnPlaceholder.styled';

import countries from '../../../../../../public/data/countries.json';

export default function CurrentAddressForm({ form, setForm }) {
  const { info, readonly } = useSelector(state => state.travelerProfile);

  const omittedCountryValues = ['Countries Vary', 'Stateless/Refuge/Other', 'Unspecified Location'];
  let countryOptions = countries.filter(country => !omittedCountryValues.includes(country.label));
  const [countryOptionList, setCountryOptionList] = useState([...countryOptions]);

  useEffect(() => {
    let newCountryOptionList = [...countryOptionList];

    if (info.current_address_country) {
      let country = countryOptionList.find(
        option => option.value === info.current_address_country || option.label === info.current_address_country,
      );
      if (!country) {
        newCountryOptionList = [
          ...newCountryOptionList,
          {
            id: Date.now(),
            label: info.current_address_country,
            value: info.current_address_country,
          },
        ];

        setCountryOptionList([...newCountryOptionList]);
      }
    }

    setForm({
      current_address_city: info.current_address_city,
      current_address_state: info.current_address_state,
      current_address_line_1: info.current_address_line_1,
      current_address_line_2: info.current_address_line_2,
      current_address_zip_code: info.current_address_zip_code,
      current_address_country:
        newCountryOptionList.find(country => country.value === info.current_address_country) || '',
    });
  }, [info]);

  return (
    <ProfileFormGrid>
      <ProfileTextInput
        id="current_address_line_1"
        className="col-span-2"
        label="Current Address"
        labelHelperText="(Street Address or P.O. Box)"
        placeholder="Enter current address"
        value={form.current_address_line_1}
        synced={readonly.includes('current_address_line_1')}
        handleOnChange={event => {
          setForm({ ...form, current_address_line_1: event.target.value });
        }}
      />
      <ProfileTextInput
        id="current_address_line_2"
        className="col-span-2"
        label="Address Line 2"
        placeholder="Enter address line 2"
        value={form.current_address_line_2}
        synced={readonly.includes('current_address_line_2')}
        handleOnChange={event => {
          setForm({ ...form, current_address_line_2: event.target.value });
        }}
      />
      <GridColumnPlaceholder />
      <ProfileTextInput
        id="current_address_city"
        label="City"
        placeholder="Enter city"
        value={form.current_address_city}
        synced={readonly.includes('current_address_city')}
        handleOnChange={event => {
          setForm({ ...form, current_address_city: event.target.value });
        }}
      />
      <ProfileTextInput
        id="current_address_state"
        label="State / Province / Territory"
        placeholder="Enter state / province / territory"
        value={form.current_address_state}
        synced={readonly.includes('current_address_state')}
        handleOnChange={event => {
          setForm({ ...form, current_address_state: event.target.value });
        }}
      />
      <GridColumnPlaceholder />
      <ProfileTextInput
        id="current_address_zip_code"
        label="Zip / Postal Code"
        placeholder="Enter zip / postal code"
        value={form.current_address_zip_code}
        synced={readonly.includes('current_address_zip_code')}
        handleOnChange={event => {
          setForm({ ...form, current_address_zip_code: event.target.value });
        }}
      />
      <ProfileSelectInput
        id="current_address_country"
        label="Country"
        placeholder="Select country"
        value={form.current_address_country}
        options={countryOptionList}
        synced={readonly.includes('current_address_country')}
        handleOnChange={event => {
          setForm({ ...form, current_address_country: event });
        }}
      />
    </ProfileFormGrid>
  );
}

CurrentAddressForm.propTypes = {
  form: PropTypes.shape({
    current_address_city: PropTypes.string,
    current_address_state: PropTypes.string,
    current_address_line_1: PropTypes.string,
    current_address_line_2: PropTypes.string,
    current_address_zip_code: PropTypes.string,
    current_address_country: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  }).isRequired,
  setForm: PropTypes.func.isRequired,
};
