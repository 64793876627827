import { useCallback, useState } from "react";
import { useSelector } from "react-redux";

function useCheckRiskAlertFeature(redirect = false) {
    const { clientFeatureList } = useSelector(state => state.profile);
    const [is_feature_enabled, set_is_feature_enabled] = useState(false);

    const checkFeature = useCallback(() => {
        if (Object.keys(clientFeatureList).includes("risk_alerts")) {
            if (clientFeatureList.risk_alerts === true) {
                set_is_feature_enabled(true);
            }
            else {
                if (redirect) {
                    window.location.href = "/not_found";
                }
            }
        }
    }, [clientFeatureList]);

    return { checkFeature, is_feature_enabled }
}

export default useCheckRiskAlertFeature