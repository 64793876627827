import styled from 'styled-components';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import VisitorLink from './VisitorLink';
import CloseIcon from '../icons/CloseIcon';
import MobileNavigation from './MobileNavigation';
import AccountNavigation from './AccountNavigation';
import HamburgerMenuIcon from '../icons/HamburgerMenuIcon';
import OrganizationInfo from '../organization/OrganizationInfo';
import { withRouter } from 'react-router-dom';

import { breakpoints } from '../../utils/breakpoints';

const Container = styled.header`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.0625rem 1.3425rem 1.0625rem 1.375rem;
  background-color: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);

  margin-bottom: 20px;

  @media ${breakpoints.tablet} {
    padding: 0.70125rem 2.23125rem 0.70125rem 1.18625rem;
  }

  @media ${breakpoints.desktop} {
    display: none;
  }
`;

const MenuButton = styled.button`
  min-width: 26px;
  padding: 0;
  background-color: transparent;
  border: none;

  &:focus {
    outline: none;
  }

  &:focus-visible {
    svg {
      outline: 2px solid #255a94;
      outline-offset: 1px;
    }
  }
`;

const InfoContainer = styled.div`
  display: flex;
  gap: 1.875rem;
`;

function MobileHeader({ history }) {
  const { adminAccess, role } = useSelector(state => state.travelerProfile);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const isTraveler = role === 'traveler';

  useEffect(() => {
    const unlisten = history.listen(() => {
      setMenuIsOpen(false);
    });

    return () => {
      unlisten();
    };
  }, [history]);

  return (
    <Container>
      <InfoContainer>
        {isTraveler || adminAccess ? (
          <MenuButton
            type="button"
            aria-label={menuIsOpen ? 'Close Main Navigation' : 'Open Main Navigation'}
            onClick={() => {
              setMenuIsOpen(!menuIsOpen);
            }}
          >
            {menuIsOpen ? <CloseIcon /> : <HamburgerMenuIcon />}
          </MenuButton>
        ) : null}
        <OrganizationInfo />
      </InfoContainer>
      {isTraveler || adminAccess ? <AccountNavigation /> : <VisitorLink />}
      {menuIsOpen && <MobileNavigation isOpen={menuIsOpen} />}
    </Container>
  );
}

export default withRouter(MobileHeader);
