import React from 'react';
import { useSelector } from 'react-redux';
import noMessageSvg from './noMessage.svg';

export default function NoMessageAdminTravelerCard() {
  const customAliases = useSelector(state => state?.profile?.customAliases);

  return (
    <div
      style={{
        margin: 'auto',
        padding: '85px',
        width: 'auto',
        height: '435px',
        textAlign: 'center',
        fontFamily: 'Open Sans',
      }}
    >
      <img src={noMessageSvg} alt="No Messages" />
      <div style={{ fontSize: '16px', fontWeight: '600', paddingBottom: '30px', paddingTop: '30px' }}>
        Messaging is disabled from {customAliases.alias_travelers.toLowerCase()} with administrator access.
      </div>
      <div>
        To send a message to a {customAliases.alias_traveler.toLowerCase()}, please switch to your administrator view.
        To send a message to another administrator, please follow your organization’s protocol.
      </div>
    </div>
  );
}
