exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@-webkit-keyframes _1yLad{to{-webkit-transform:rotate(1turn)}}@keyframes _1yLad{to{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}:root{--dark:var(--theme_color_dark);--accent:var(--theme_color_accent);--ocean:var(--theme_color_light)}._2beCo{cursor:pointer;-ms-flex-line-pack:center;align-content:center;-ms-flex-align:center;align-items:center;-ms-flex-pack:justify;justify-content:space-between;margin:40px 0 40px 10px;position:relative}._1UjCq,._2beCo{display:-ms-flexbox;display:flex}._1UjCq{background:#fff;color:#043544;font-size:32px;font-family:IntroBoldItalic,sans-serif;margin:0;padding-right:20px}._1UjCq p{margin-left:10px;margin-top:10px;font-size:16px;font-weight:700;background-color:var(--theme_color_light);background-color:var(--ocean,#76ccce);width:23px;height:23px;text-align:center;color:#fff;padding-top:2px;border-radius:13px;padding-right:0}._1UjCq:after{content:\"\";position:absolute;left:0;right:0;top:50%;-webkit-transform:translateY(-50%);-ms-transform:translateY(-50%);transform:translateY(-50%);height:4px;background:#f0f0f0;z-index:-1}._3PvNy{padding-left:16px;padding-left:1rem;background-color:#f0f0f0;display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;-ms-flex-line-pack:center;align-content:center;-ms-flex-align:center;align-items:center}._3si5I{position:relative;display:inline-block;background:#043544;width:14px;height:3px}._3si5I:before{content:\"\";display:block;width:100%;height:100%;background:#043544;-webkit-transform:rotate(90deg);-ms-transform:rotate(90deg);transform:rotate(90deg)}._22Dcy{position:relative;display:inline-block;background:#043544;width:14px;height:3px}._22Dcy:before{opacity:0;-webkit-transform:rotate(0deg);-ms-transform:rotate(0deg);transform:rotate(0deg)}._2ANhm,._35oKo{display:none}@media only screen and (max-width:768px){._2beCo{margin-bottom:0}}", ""]);

// exports
exports.locals = {
	"accordion": "_2beCo",
	"sectionHeader": "_1UjCq",
	"iconWrapper": "_3PvNy",
	"iconPlus": "_3si5I",
	"iconMinus": "_22Dcy",
	"hidden": "_2ANhm",
	"undefined": "_35oKo",
	"spin": "_1yLad"
};