import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import userId from '../../../../../shared/helpers/userId';
import { useDispatch, useSelector } from 'react-redux';
import { Paragraph } from '../../../../../styledComponents/styles/Paragraph.styled';
import { getPaymentStatusOptions } from '../../../../../actions/formsActions';
import PaymentBox from '../../paymentBox/paymentBox';

const Container = styled.div`
  font-family: 'Nunito', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 0.015em;
  color: #373737;
`;

const InputContainer = styled.div`
  flex: 1;
  margin: 13px 0;
  text-align: left;
`;

const Label = styled.label`
  color: ${({ theme }) => theme.textInput.label.fontColor};
  font-family: ${({ theme }) => theme.textInput.label.fontFamily};
  font-size: ${({ theme }) => theme.textInput.label.fontSize};
  font-weight: ${({ theme }) => theme.textInput.label.fontWeight};
`;

const Required = styled.span`
  color: ${({ theme }) => theme.defaults.errorColor};
  padding-left: 5px;
`;

export default function QuestionCashnetCodeDisplayForm({
  question,
  setValue,
  defaultValue,
  errors = {},
  inFormBuilder = false,
  isReadonly = false,
  isAdmin = false,
  formId,
  cashNetCodes,
}) {
  const dispatch = useDispatch();
  const paymentOptions = useSelector(state => state.forms.paymentStatusOptions);
  const id = question.question_id.toString();
  const [savedDefaults, setSavedDefaults] = useState(defaultValue);
  const [rnd, setRnd] = useState(1);
  const url = `${BACK_END_URL}/api/cash_net/${formId}/${question.id}/${userId}`;
  const { message: errorMsg } = errors[id] || {};

  useEffect(
    () => {
      if (defaultValue) {
        setSavedDefaults(defaultValue);
        setRnd(Math.random());
        setValue(question.question_id.toString(), defaultValue.payment_status_id, {
          shouldValidate: false,
          shouldTouch: false,
          shouldDirty: false,
        });
      }
    },
    [defaultValue],
  );

  useEffect(
    () => {
      if (isAdmin && !paymentOptions?.data && !paymentOptions?.loading) {
        dispatch(getPaymentStatusOptions());
      }
    },
    [paymentOptions.data],
  );

  if (!formId) {
    isReadonly = true;
  }

  useEffect(
    () => {
      let options = [];

      if (paymentOptions && !paymentOptions.loading && !paymentOptions.error && paymentOptions.data) {
        paymentOptions.data.forEach(item => {
          options.push({ label: item.attributes.name, value: item.id });
        });

        let value = '';
        if (savedDefaults && savedDefaults.payment_status_id) {
          value = savedDefaults.payment_status_id;
        } else {
          const notPaid = options.find(i => i.label.toLowerCase() === 'not paid');
          value = parseInt(notPaid.value);
        }
        setValue(question.question_id.toString(), value, {
          shouldValidate: false,
          shouldTouch: false,
          shouldDirty: false,
        });
      }
    },
    [paymentOptions],
  );

  const handleStatusChange = status => {
    setValue(id, parseInt(status), {
      shouldValidate: true,
      shouldTouch: true,
      shouldDirty: true,
    });
  };
  const updatedInstruction = !!question.instructions ? question.instructions.replace(/<p><\/p>/g, '<br />') : '';
  return (
    <Container>
      <InputContainer>
        <Label htmlFor={question.id}>
          {question.label}
          {question.required && <Required>*</Required>}
        </Label>
        <Paragraph
          aria-label={question.label}
          id="cashnet-code--instructions"
          openSans
          dangerouslySetInnerHTML={{
            __html: updatedInstruction,
          }}
        />
        {(question || inFormBuilder) && (
          <PaymentBox
            key={rnd}
            cashNetCodes={cashNetCodes}
            cost={question.cost}
            currency={question.currency}
            responseValues={savedDefaults}
            paymentOptions={paymentOptions}
            isAdmin={isAdmin}
            questionId={id}
            isReadonly={isReadonly}
            errorMsg={errorMsg}
            onStatusChange={status => handleStatusChange(status)}
            url={url}
          />
        )}
      </InputContainer>
    </Container>
  );
}
