exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".SReei{padding:24px;padding:1.5rem}", ""]);

// exports
exports.locals = {
	"container": "SReei"
};