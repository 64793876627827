import React from 'react';

const RemoveIcon = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      role="img"
      tabIndex="0"
      viewBox="0 0 512 512"
      height="20px"
      aria-labelledby="removeFile"
      htmlFor="removeFile"
    >
      <title id="removeFile">Remove file</title>
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="32"
        d="M368 368L144 144M368 144L144 368"
      />
    </svg>
  );
};
export default RemoveIcon;
