exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@-webkit-keyframes V_OUw{to{-webkit-transform:rotate(1turn)}}@keyframes V_OUw{to{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}._3wZas ._1yKhu{-ms-flex:1 1;flex:1 1;-ms-flex-direction:row;flex-direction:row;display:-ms-flexbox;display:flex;border-bottom:2px solid #ebebeb;-ms-flex-pack:center;justify-content:center;padding:16px;margin-bottom:16px}@media only screen and (max-width:599px){._3wZas ._1yKhu{-ms-flex-direction:column;flex-direction:column}}._3wZas ._1aopb{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;padding:12px 52px;-ms-flex-pack:center;justify-content:center}._3wZas ._1aopb ._6ByN6{padding-bottom:16px;cursor:pointer}._3wZas ._1aopb ._1aP2o{padding:4px 0}._3wZas ._3VhQS{-ms-flex:1 1;flex:1 1;-ms-flex-direction:column;flex-direction:column;display:-ms-flexbox;display:flex;border-top:2px solid #ebebeb;padding:32px 20px 0 0;-ms-flex-pack:center;justify-content:center}@media only screen and (max-width:599px){._3wZas ._3VhQS{-ms-flex-direction:column;flex-direction:column}}._3wZas ._3VhQS ._3LDTo{padding-left:12px}@media only screen and (max-width:599px){._3wZas ._3VhQS ._3LDTo{padding-top:12px}}._3wZas ._3VhQS ._2gaTr{padding:20px 0;display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-pack:center;justify-content:center}._3wZas ._3VhQS ._2gaTr ._3LDTo{padding-left:12px}@media only screen and (max-width:599px){._3wZas ._3VhQS ._2gaTr ._3LDTo{padding-top:12px}}._3wZas ._3VhQS ._24Fi5{-ms-flex:1 1;flex:1 1}", ""]);

// exports
exports.locals = {
	"admin-detail-from-traveler": "_3wZas",
	"subHeader": "_1yKhu",
	"content": "_1aopb",
	"toggle-link": "_6ByN6",
	"itoggle": "_1aP2o",
	"footer": "_3VhQS",
	"button-content": "_3LDTo",
	"footer-button-wrapper": "_2gaTr",
	"footer-confirm": "_24Fi5",
	"spin": "V_OUw"
};