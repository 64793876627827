import { useSelector } from 'react-redux';
import momentTimezone from 'moment-timezone';
import React, { useEffect, useState } from 'react';

import ClockIcon from '../icons/ClockIcon';
import ProgramMatchSection from './ProgramMatchSection';

export default function ProgramTimingPreferences() {
  const { programTerms, programLength } = useSelector(state => state.programMatchForm);
  const {
    customAliases: { alias_program: programAlias },
  } = useSelector(state => state.currentOrganization);

  const [minWeeks, setMinWeeks] = useState('');
  const [maxWeeks, setMaxWeeks] = useState('');

  const [endDate, setEndDate] = useState('');
  const [startDate, setStartDate] = useState('');

  const formatDate = date => momentTimezone(new Date(date)).format('MMM [] YYYY');

  useEffect(
    () => {
      if (programLength && programLength.minWeeks && programLength.maxWeeks) {
        setMinWeeks(programLength.minWeeks);
        setMaxWeeks(programLength.maxWeeks);
      }
    },
    [programLength],
  );

  useEffect(
    () => {
      if (programTerms.length > 0) {
        setEndDate(programTerms[0].end_date);
        setStartDate(programTerms[0].start_date);
      }
    },
    [programTerms],
  );

  return (
    <ProgramMatchSection heading={`${programAlias} Timing`} icon={ClockIcon}>
      <p style={{ marginBottom: '0.25rem' }}>
        <strong>Timing:</strong>{' '}
        {programTerms.length > 0 ? `${formatDate(startDate)} to ${formatDate(endDate)}` : 'None selected'}
      </p>
      <p>
        <strong>Length:</strong>{' '}
        {minWeeks && maxWeeks
          ? `${minWeeks} ${minWeeks === 1 ? 'week' : 'weeks'} min. - ${maxWeeks} weeks max.`
          : 'None selected'}
      </p>
    </ProgramMatchSection>
  );
}
