import React from 'react';

export default function LockIcon() {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
      <path
        d="M11.8893 6.40845H11.3895V3.90845C11.3895 3.1128 11.0735 2.34974 10.5111 1.78713C9.94877 1.22452 9.18602 0.908447 8.3907 0.908447C7.59538 0.908447 6.83264 1.22452 6.27026 1.78713C5.70788 2.34974 5.39194 3.1128 5.39194 3.90845V6.40845H4.89215C4.36211 6.40903 3.85395 6.61993 3.47916 6.99487C3.10437 7.36982 2.89355 7.87819 2.89297 8.40845V13.9084C2.89355 14.4387 3.10437 14.9471 3.47916 15.322C3.85395 15.697 4.36211 15.9079 4.89215 15.9084H11.8893C12.4193 15.9079 12.9275 15.697 13.3022 15.322C13.677 14.9471 13.8879 14.4387 13.8884 13.9084V8.40845C13.8879 7.87819 13.677 7.36982 13.3022 6.99487C12.9275 6.61993 12.4193 6.40903 11.8893 6.40845ZM10.3899 6.40845H6.39153V3.90845C6.39153 3.37801 6.60216 2.86931 6.97707 2.49423C7.35199 2.11916 7.86049 1.90845 8.3907 1.90845C8.92092 1.90845 9.42941 2.11916 9.80433 2.49423C10.1793 2.86931 10.3899 3.37801 10.3899 3.90845V6.40845Z"
        fill="#373737"
      />
    </svg>
  );
}
