import React from 'react'
import PropTypes from 'prop-types'
import { HeaderIconButton } from '../Header.styled'

export default function Facebook({ url, hashtag, clickHandler }) {

  const shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}&hashtag=${hashtag}`

  const handleClick = () => {
    clickHandler(shareUrl)
  }

  return (
    <HeaderIconButton
      type="button"
      aria-label="Share on Facebook"
      onClick={() => handleClick(shareUrl)}
    >
      <svg
        style={{ height: '1.5rem', width: '1.5rem' }}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        aria-hidden="true"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.5 12.064c0-5.799-4.702-10.5-10.5-10.5S1.5 6.265 1.5 12.064c0 5.24 3.84 9.584 8.86 10.373V15.1H7.692v-3.036h2.666V9.75c0-2.63 1.568-4.085 3.966-4.085 1.15 0 2.351.205 2.351.205v2.584h-1.324c-1.304 0-1.712.81-1.712 1.64v1.97h2.912l-.465 3.036h-2.446v7.338c5.02-.788 8.859-5.132 8.859-10.374Z"
          fill="#3C5A99"
        />
      </svg>
    </HeaderIconButton>
  )
}

Facebook.propTypes = {
  url: PropTypes.string.isRequired,
  hashtag: PropTypes.string.isRequired,
  clickHandler: PropTypes.func.isRequired,
}
