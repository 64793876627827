import styled from 'styled-components';
import { breakpoints } from '../../../styledComponents/settings/breakpoints';

export const AuthFlexContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  font-family: Open Sans, sans-serif;
  font-size: 16px;
  font-weight: 400;
`;

export const ContentFlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 325px;
  
  @media ${breakpoints.tablet} {
    background-image: url('/img/backgrounds/bkgd-image.png');
    background-size: cover;
    border-radius: 15px;
    filter: drop-shadow(0px 10px 20px rgba(105, 105, 105, 0.25));
    height: 680px;
    width: 750px;
  }
`;

export const ActionCardFlexContainer = styled.div`
  width: 100%;
  
  @media ${breakpoints.tablet} {
    margin-top: 25px;
    width: auto;
  }
  
  @media ${breakpoints.laptop} {
    margin-left: 250px;
  }
`;

export const ActionCard = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;

  @media ${breakpoints.tablet} {
    background: rgba(255, 255, 255, 0.95);
    border: 4px solid #FFFFFF;
    border-radius: 15px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    height: 630px;
    padding-left: 17px;
    padding-right: 17px;
    padding-top: 30px;
    width: 394px;
  }
`;