import React from 'react';
import _isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { breakpoints } from '../../../utils/breakpoints';
import { formRadioFocus } from '../../../utils/shared/focusStyles';

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media ${breakpoints.tablet} {
    ${props => {
      if (!props.fullWidth) {
        return `
          width: 60%;
        `;
      }
    }};
  }

  @media ${breakpoints.desktop} {
    ${props => {
      if (!props.fullWidth) {
        return `
          width: 40%;
        `;
      }
    }};
  }
`;

const Input = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0.875rem;
  border: 1px solid #d7d7d7;
  border-radius: 5px;
  font-family: 'Open Sans', sans-serif;
  font-size: 0.875rem;
  font-weight: 600;

  svg {
    width: 24px;
    height: 24px;
    margin-right: 1.508125rem;
  }

  &:hover {
    cursor: pointer;

    ${props => {
      if (props.disabled) {
        return `
          cursor: not-allowed;
        `;
      }
    }};
  }

  ${formRadioFocus};

  ${props => {
    if (props.checked) {
      return `
        background-color: #DEECFF;
        border-color: #255A94;
        color: #373737;
        filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.15));
      `;
    }

    if (props.disabled && !props.checked) {
      return `
        background-color: #eeeeee;
        border-color: #d7d7d7 !important;
        filter: none !important;
        box-shadow: none !important;
      `;
    }
  }};
`;

export default function RadioInput({ value, selected, label, icon, handleOnChange, disabled, fullWidth }) {
  const IconComponent = icon;

  const handleClick = () => {
    if (disabled) return;

    handleOnChange();
  };

  const handleKeyDown = event => {
    if (disabled) return;

    if ([13, 32].includes(event.keyCode)) {
      handleClick();
    }
  };

  return (
    <InputContainer fullWidth={fullWidth}>
      <Input
        role="radio"
        value={value}
        aria-label={label}
        aria-checked={_isEqual(value, selected)}
        checked={_isEqual(value, selected)}
        tabIndex={disabled ? -1 : 0}
        disabled={disabled}
        aria-disabled={disabled}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
      >
        <IconComponent />
        {label}
      </Input>
    </InputContainer>
  );
}

RadioInput.propTypes = {
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  icon: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.object]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.object]).isRequired,
};

RadioInput.defaultProps = {
  selected: '',
  disabled: false,
  fullWidth: false,
};
