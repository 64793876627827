exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@-webkit-keyframes _8dAOy{to{-webkit-transform:rotate(1turn)}}@keyframes _8dAOy{to{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}.WNMkE{background-color:#ebebeb;color:#043544;width:100%;padding:16px 0;font-size:16px;line-height:1;font-weight:500}@media only screen and (max-width:599px){.WNMkE{padding:16px 4px;font-size:12px}}.WNMkE ._8KQu6{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-wrap:wrap;flex-wrap:wrap;width:100%;-ms-flex-align:center;align-items:center}.WNMkE ._8KQu6 ._3y3o4{padding:0 16px}.WNMkE ._8KQu6 ._3UHjy{padding:0 16px;margin-left:auto;text-align:right;display:-ms-flexbox;display:flex}.WNMkE ._8KQu6 ._3UHjy ._3QXqB{font-size:12px;font-family:AvenirNextRegular,sans-serif;font-style:italic;padding-right:8px;-ms-flex-item-align:center;align-self:center}.WNMkE ._8KQu6 ._1OhvC{color:#043544;text-decoration:none}.WNMkE ._8KQu6 .QAUqd{color:#1c748a;text-decoration:underline}", ""]);

// exports
exports.locals = {
	"subheader": "WNMkE",
	"subheader-content": "_8KQu6",
	"item": "_3y3o4",
	"itemLast": "_3UHjy",
	"textInfo": "_3QXqB",
	"selection": "_1OhvC",
	"selected": "QAUqd",
	"spin": "_8dAOy"
};