import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';

import IntakeSection from './IntakeSection';

import financialAidOptions from '../../utils/options/financialAidOptions';

export default function IntakeFinancialAid() {
  const intake = useSelector(state => state.intakeForm);

  const [receivesAid, setReceivesAid] = useState('');

  useEffect(
    () => {
      if (intake.receives_federal_financial_aid) {
        const value = financialAidOptions.find(option => option.value === intake.receives_federal_financial_aid);

        if (value) {
          setReceivesAid(value.label);
        }
      }
    },
    [intake.receives_federal_financial_aid],
  );

  return (
    <IntakeSection heading="Financial Aid">
      <p>{receivesAid || 'None selected'}</p>
    </IntakeSection>
  );
}
