import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import StyledIcon from './Icon.styled';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
} from 'react-accessible-accordion';
import sAccordion from '../../../public/css/form-card-accordion.scss';

const FormCardDiv = styled.div`
  background-color: ${({ theme }) => theme.formCard.backgroundColor};
  border: ${({ theme }) => theme.formCard.border};
  font-family: ${({ theme }) => theme.formCard.fontFamily};
  font-size: ${({ theme }) => theme.formCard.fontSize};
`;

const HeaderDiv = styled.div`
  color: ${({ theme }) => theme.formCard.header.fontColor};
  background-color: ${({ theme }) => theme.formCard.header.backgroundColor};
  display: flex;
  font-family: ${({ theme }) => theme.formCard.header.fontFamily};
  font-size: ${({ theme }) => theme.formCard.header.fontSize};
  font-weight: 500;
  height: ${({ theme }) => theme.formCard.header.height};
  justify-content: ${props => (props.addTitle ? 'space-between' : null)};
  padding: ${({ theme }) => theme.formCard.header.padding};
  align-self: center;
  span {
    align-self: center;
  }
  svg {
    align-self: center;
    display: inline-flex;
    margin-right: 10px;
    position: relative;
  }
`;

const ContentDiv = styled.div`
  padding: ${({ theme }) => theme.formCard.content.padding};
`;

const ComponentDiv = styled.div`
  align-self: center;
`;

const TitleSpan = styled.span`
  width: 50%;
  text-transform: uppercase;
`;

const AccordionFormCard = ({
  children,
  title,
  icon = null,
  iconSize = '20px',
  headerComponent,
  defaultOpen = true,
  id,
}) => {
  const [isVisible, setIsVisible] = useState([]);

  useEffect(
    () => {
      if (defaultOpen) {
        setIsVisible([id]);
      }
    },
    [isVisible.length],
  );

  return (
    <FormCardDiv>
      <Accordion
        allowMultipleExpanded
        allowZeroExpanded
        onChange={e => setIsVisible(e)}
        preExpanded={defaultOpen ? [id] : []}
      >
        <AccordionItem uuid={id}>
          <HeaderDiv>
            {icon && <StyledIcon type={icon} size={iconSize} />}
            <TitleSpan>{title}</TitleSpan>
            {headerComponent && isVisible.length > 0 && <ComponentDiv>{headerComponent} </ComponentDiv>}
          </HeaderDiv>
          <div className="accordionButton">
            <AccordionItemHeading>
              <AccordionItemButton />
            </AccordionItemHeading>
          </div>

          <AccordionItemPanel>
            <ContentDiv>{children}</ContentDiv>
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    </FormCardDiv>
  );
};

export default withStyles(sAccordion)(AccordionFormCard);
