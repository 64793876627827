var escape = require("../../../../../node_modules/css-loader/lib/url/escape.js");
exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Caveat&family=Homemade+Apple&family=Hurricane&family=Yellowtail&display=swap);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=KoHo:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap);", ""]);

// module
exports.push([module.id, "@-webkit-keyframes _2oF2A{to{-webkit-transform:rotate(1turn)}}@keyframes _2oF2A{to{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}@font-face{font-family:AvenirNextMedium;src:url(" + escape(require("./fonts/AvenirNext-Medium-06.ttf")) + ") format(\"truetype\");font-weight:400;font-style:normal}@font-face{font-family:AvenirNextRegular;src:url(" + escape(require("./fonts/avenirnext-regular-webfont.woff")) + ") format(\"woff\"),url(" + escape(require("./fonts/avenirnext-regular-webfont.ttf")) + ") format(\"truetype\"),url(" + escape(require("./fonts/avenirnext-regular-webfont.svg")) + "#avenirnext-regular-webfont) format(\"svg\");font-weight:400;font-style:normal}@font-face{font-family:IntroBoldItalic;src:url(" + escape(require("./fonts/intro-bold-italic-webfont.woff")) + ") format(\"truetype\"),url(" + escape(require("./fonts/intro-bold-italic-webfont.ttf")) + ") format(\"truetype\"),url(" + escape(require("./fonts/intro-bold-italic-webfont.svg")) + ") format(\"svg\");font-weight:400;font-style:normal}._3U-fP{font-family:AvenirNextRegular,sans-serif;color:#373737}", ""]);

// exports
exports.locals = {
	"wrapper": "_3U-fP",
	"spin": "_2oF2A"
};