import React from 'react';
import styled from 'styled-components';
import StyledIcon from '../../../../../styledComponents/styles/Icon.styled';
import OptionField from './question.optionFieldsCard.addEdit';


const OtherOptionContainer = styled.div`
  font-family: 'Nunito', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  display: flex;
  align-items: center;
  letter-spacing: 0.0015em;
  text-decoration-line: underline;
  color: #446372;
  margin: 22px 0 34px 30px;
  cursor: pointer;
`;

function OptionFields({ fields, formState, control, setValue, moveCard, createOption }) {
  return (
    <>
      {fields.map((item, index) => {
        let errorMessage;
        if (formState.errors.multiple_choice_question_options_attributes) {
          for (const element of formState.errors.multiple_choice_question_options_attributes) {
            errorMessage =
              element !== undefined && item.value === ''
                ? { message: element.value.message, index }
                : { message: '', index };
          }
        }

        return (
          <OptionField
            control={control}
            index={index}
            item={item}
            errorMessage={errorMessage}
            setValue={setValue}
            fields={fields}
            moveCard={moveCard}
          />
        );
      })}

      <OtherOptionContainer
        onClick={() => {
          createOption(fields.length + 1);
        }}
        onKeyDown={e => {
          if (e.keyCode === 13) {
            createOption(fields.length + 1);
          }
        }}
        tabIndex={0}
      >
        <StyledIcon type="Add" color="#446372" size="16px" />
        Add Option
      </OtherOptionContainer>
    </>
  );
}

export default OptionFields; 
// export default DragDropContext(HTML5Backend)(OptionFields);
