import React from 'react';
import PropTypes from 'prop-types';

import programType from '../../types/program';
import ProgramsGrid from '../my-programs/ProgramsGrid';
import programApplication from '../../types/programApplication';

export default function UpcomingProgramsTab({ programs, programsType, isAdmin }) {
  return <ProgramsGrid programs={programs} programsType={programsType} isAdmin={isAdmin} />;
}

UpcomingProgramsTab.propTypes = {
  programsType: PropTypes.string.isRequired,
  programs: PropTypes.arrayOf(PropTypes.oneOfType([programType, programApplication])).isRequired,
  isAdmin: PropTypes.bool,
};

UpcomingProgramsTab.defaultProps = {
  isAdmin: false
}