import React from 'react';

export default function Graduate() {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
      <path d="M1.75781 9.58594L0.351562 8.17969V6.77344H1.75781V9.58594Z" fill="#D0CDCD" />
      <path d="M8.78906 8.17969H5.97656L4.57031 12.3984H8.78906V8.17969Z" fill="#464342" />
      <path d="M0.351562 8.17969V10.9922L5.97656 12.3984V8.17969H0.351562Z" fill="#625F5E" />
      <path d="M4.57031 19.4297L5.97656 23.6484H7.38281L8.78906 19.4297H4.57031Z" fill="#D0CDCD" />
      <path d="M0.351562 20.8359V23.6484H5.97656V19.4297L0.351562 20.8359Z" fill="#E7E6E6" />
      <path d="M8.78906 10.9922H5.97656L4.57031 15.9141L5.97656 20.8359H8.78906V10.9922Z" fill="#FF835D" />
      <path
        d="M5.97656 10.9922H0.351562V20.8359H5.97656V19.4297L5.27344 18.0234L5.97656 16.6172V15.2109L5.27344 13.8047L5.97656 12.3984V10.9922Z"
        fill="#FC9F63"
      />
      <path d="M22.2422 9.58594L23.6484 8.17969V6.77344H22.2422V9.58594Z" fill="#B8B4B3" />
      <path d="M23.6484 8.17969H22.2422L20.8359 12.3984L23.6484 10.9922V8.17969Z" fill="#464342" />
      <path d="M15.2109 8.17969H22.2422V12.3984H15.2109V8.17969Z" fill="#625F5E" />
      <path d="M23.6484 20.8359L20.8359 19.4297L22.2422 23.6484H23.6484V20.8359Z" fill="#B8B4B3" />
      <path d="M15.2109 19.4297L16.6172 23.6484H22.2422V19.4297H15.2109Z" fill="#D0CDCD" />
      <path d="M22.2422 10.9922L20.8359 15.9141L22.2422 20.8359H23.6484V10.9922H22.2422Z" fill="#DDAF64" />
      <path
        d="M21.5391 18.0234L22.2422 16.6172V15.2109L21.5391 13.8047L22.2422 12.3984V10.9922H15.2109V20.8359H22.2422V19.4297L21.5391 18.0234Z"
        fill="#FF835D"
      />
      <path d="M12 3.86719L15.2109 23.6484H16.6172V6.77344L12 3.86719Z" fill="#FC9F63" />
      <path
        d="M15.2109 6.77344L12 3.86719L7.38281 6.77344V23.6484H8.78906L15.2109 13.8047V12.3984L14.8125 10.916L15.2109 9.43359V6.77344Z"
        fill="#F9BB69"
      />
      <path d="M13.1016 21.5391V22.9453L13.8047 23.6484H15.2109V22.2422L13.1016 21.5391Z" fill="#9C9897" />
      <path d="M8.78906 22.2422V23.6484H13.8047V21.5391L8.78906 22.2422Z" fill="#B8B4B3" />
      <path d="M12.3984 19.4297L13.1016 21.5391L13.8047 22.2422H15.2109V20.8359L12.3984 19.4297Z" fill="#B8B4B3" />
      <path d="M8.78906 20.8359V22.2422H13.8047V19.4297L8.78906 20.8359Z" fill="#D0CDCD" />
      <path d="M13.8047 13.8047L11.6953 17.3203L13.8047 20.8359H15.2109V13.8047H13.8047Z" fill="#A9CADA" />
      <path d="M12 13.8047L10.5938 17.3203L12 20.8359H13.8047V13.8047H12Z" fill="#D2E4ED" />
      <path d="M8.78906 13.8047H12V20.8359H8.78906V13.8047Z" fill="white" />
      <path d="M1.75781 16.6172H3.16406V19.4297H1.75781V16.6172Z" fill="white" />
      <path d="M4.57031 16.6172H5.97656V19.4297H4.57031V16.6172Z" fill="white" />
      <path d="M1.75781 12.3984H3.16406V15.2109H1.75781V12.3984Z" fill="white" />
      <path d="M4.57031 12.3984H5.97656V15.2109H4.57031V12.3984Z" fill="white" />
      <path d="M18.0234 16.6172H19.4297V19.4297H18.0234V16.6172Z" fill="white" />
      <path d="M20.8359 16.6172H22.2422V19.4297H20.8359V16.6172Z" fill="white" />
      <path d="M18.0234 12.3984H19.4297V15.2109H18.0234V12.3984Z" fill="white" />
      <path d="M20.8359 12.3984H22.2422V15.2109H20.8359V12.3984Z" fill="white" />
      <path
        d="M12.4688 1.05469L13.4062 1.99219C13.4062 2.3805 13.7211 2.69531 14.1094 2.69531H14.8125V1.05469H12.4688Z"
        fill="#C74368"
      />
      <path
        d="M12.7031 0.351562H12V1.99219H13.4062V1.05469C13.4062 0.666375 13.0914 0.351562 12.7031 0.351562Z"
        fill="#D74B73"
      />
      <path d="M12.7031 12.3984H15.2109V10.9922L13.957 10.2891L12.7031 10.9922V12.3984Z" fill="#FC9F63" />
      <path
        d="M13.957 8.17969C13.2645 8.17969 12.7031 8.74106 12.7031 9.43359V10.9922H15.2109V9.43359C15.2109 8.74106 14.6496 8.17969 13.957 8.17969Z"
        fill="white"
      />
      <path d="M8.78906 12.3984H11.2969V10.9922L10.043 10.2891L8.78906 10.9922V12.3984Z" fill="#FC9F63" />
      <path
        d="M10.043 8.17969C9.35044 8.17969 8.78906 8.74106 8.78906 9.43359V10.9922H11.2969V9.43359C11.2969 8.74106 10.7355 8.17969 10.043 8.17969Z"
        fill="white"
      />
      <path
        d="M23.6484 6.42188H22.2422C22.048 6.42188 21.8906 6.57923 21.8906 6.77344V7.82812H16.9688V6.77344C16.9688 6.65264 16.9067 6.54023 16.8045 6.47587L12.3516 3.67303V2.34375H13.1152C13.2603 2.75292 13.6511 3.04688 14.1094 3.04688H14.8125C15.0067 3.04688 15.1641 2.88952 15.1641 2.69531V1.05469C15.1641 0.860484 15.0067 0.703125 14.8125 0.703125H13.6976C13.5525 0.293906 13.1615 0 12.7031 0H12C11.8058 0 11.6484 0.157359 11.6484 0.351562V3.67303L7.19555 6.47587C7.09331 6.54023 7.03125 6.65264 7.03125 6.77344V7.82812H2.10938V6.77344C2.10938 6.57923 1.95197 6.42188 1.75781 6.42188H0.351562C0.157406 6.42188 0 6.57923 0 6.77344V23.6484C0 23.8426 0.157406 24 0.351562 24H23.6484C23.8426 24 24 23.8426 24 23.6484V6.77344C24 6.57923 23.8426 6.42188 23.6484 6.42188ZM13.7578 1.40625H14.4609V2.34375H14.1094C13.9155 2.34375 13.7578 2.18606 13.7578 1.99219V1.40625ZM22.5938 7.125H23.2969V7.82812H22.5938V7.125ZM23.2969 8.53125V10.6406H16.9688V8.53125H23.2969ZM23.2969 11.3438V20.4844H16.9688V11.3438H23.2969ZM12.7031 0.703125C12.897 0.703125 13.0547 0.860812 13.0547 1.05469V1.64062H12.3516V0.703125H12.7031ZM12 4.28259L16.2656 6.96755V23.2969H15.5625V13.8047C15.5625 13.6105 15.4051 13.4531 15.2109 13.4531H8.78906C8.59491 13.4531 8.4375 13.6105 8.4375 13.8047V23.2969H7.73438V6.96759L12 4.28259ZM14.8594 20.4844H12.3516V14.1562H14.8594V20.4844ZM12 21.1875H14.8594V21.8906H9.14062V21.1875H10.5938C10.7879 21.1875 10.9453 21.0301 10.9453 20.8359C10.9453 20.6417 10.7879 20.4844 10.5938 20.4844H9.14062V14.1562H11.6484V20.8359C11.6484 21.0301 11.8058 21.1875 12 21.1875ZM9.14062 22.5938H14.8594V23.2969H9.14062V22.5938ZM7.03125 20.4844H0.703125V11.3438H7.03125V20.4844ZM7.03125 10.6406H0.703125V8.53125H7.03125V10.6406ZM1.40625 7.125V7.82812H0.703125V7.125H1.40625ZM0.703125 21.1875H7.03125V23.2969H0.703125V21.1875ZM16.9688 23.2969V21.1875H23.2969V23.2969H16.9688Z"
        fill="#373737"
      />
      <path
        d="M1.75781 19.7812H3.16406C3.35822 19.7812 3.51562 19.6239 3.51562 19.4297V16.6172C3.51562 16.423 3.35822 16.2656 3.16406 16.2656H1.75781C1.56366 16.2656 1.40625 16.423 1.40625 16.6172V19.4297C1.40625 19.6239 1.56366 19.7812 1.75781 19.7812ZM2.10938 16.9688H2.8125V19.0781H2.10938V16.9688Z"
        fill="#373737"
      />
      <path
        d="M4.57031 19.7812H5.97656C6.17072 19.7812 6.32812 19.6239 6.32812 19.4297V16.6172C6.32812 16.423 6.17072 16.2656 5.97656 16.2656H4.57031C4.37616 16.2656 4.21875 16.423 4.21875 16.6172V19.4297C4.21875 19.6239 4.37616 19.7812 4.57031 19.7812ZM4.92188 16.9688H5.625V19.0781H4.92188V16.9688Z"
        fill="#373737"
      />
      <path
        d="M1.75781 15.5625H3.16406C3.35822 15.5625 3.51562 15.4051 3.51562 15.2109V12.3984C3.51562 12.2042 3.35822 12.0469 3.16406 12.0469H1.75781C1.56366 12.0469 1.40625 12.2042 1.40625 12.3984V15.2109C1.40625 15.4051 1.56366 15.5625 1.75781 15.5625ZM2.10938 12.75H2.8125V14.8594H2.10938V12.75Z"
        fill="#373737"
      />
      <path
        d="M4.57031 15.5625H5.97656C6.17072 15.5625 6.32812 15.4051 6.32812 15.2109V12.3984C6.32812 12.2042 6.17072 12.0469 5.97656 12.0469H4.57031C4.37616 12.0469 4.21875 12.2042 4.21875 12.3984V15.2109C4.21875 15.4051 4.37616 15.5625 4.57031 15.5625ZM4.92188 12.75H5.625V14.8594H4.92188V12.75Z"
        fill="#373737"
      />
      <path
        d="M18.0234 19.7812H19.4297C19.6239 19.7812 19.7812 19.6239 19.7812 19.4297V16.6172C19.7812 16.423 19.6239 16.2656 19.4297 16.2656H18.0234C17.8292 16.2656 17.6719 16.423 17.6719 16.6172V19.4297C17.6719 19.6239 17.8292 19.7812 18.0234 19.7812ZM18.375 16.9688H19.0781V19.0781H18.375V16.9688Z"
        fill="#373737"
      />
      <path
        d="M20.8359 19.7812H22.2422C22.4364 19.7812 22.5938 19.6239 22.5938 19.4297V16.6172C22.5938 16.423 22.4364 16.2656 22.2422 16.2656H20.8359C20.6417 16.2656 20.4844 16.423 20.4844 16.6172V19.4297C20.4844 19.6239 20.6417 19.7812 20.8359 19.7812ZM21.1875 16.9688H21.8906V19.0781H21.1875V16.9688Z"
        fill="#373737"
      />
      <path
        d="M18.0234 15.5625H19.4297C19.6239 15.5625 19.7812 15.4051 19.7812 15.2109V12.3984C19.7812 12.2042 19.6239 12.0469 19.4297 12.0469H18.0234C17.8292 12.0469 17.6719 12.2042 17.6719 12.3984V15.2109C17.6719 15.4051 17.8292 15.5625 18.0234 15.5625ZM18.375 12.75H19.0781V14.8594H18.375V12.75Z"
        fill="#373737"
      />
      <path
        d="M20.8359 15.5625H22.2422C22.4364 15.5625 22.5938 15.4051 22.5938 15.2109V12.3984C22.5938 12.2042 22.4364 12.0469 22.2422 12.0469H20.8359C20.6417 12.0469 20.4844 12.2042 20.4844 12.3984V15.2109C20.4844 15.4051 20.6417 15.5625 20.8359 15.5625ZM21.1875 12.75H21.8906V14.8594H21.1875V12.75Z"
        fill="#373737"
      />
      <path
        d="M12.7031 12.75H15.2109C15.4051 12.75 15.5625 12.5926 15.5625 12.3984V9.43359C15.5625 8.54831 14.8423 7.82812 13.957 7.82812C13.0718 7.82812 12.3516 8.54831 12.3516 9.43359V12.3984C12.3516 12.5926 12.5089 12.75 12.7031 12.75ZM13.0547 12.0469V11.3438H14.8594V12.0469H13.0547ZM13.957 8.53125C14.4546 8.53125 14.8594 8.93606 14.8594 9.43359V10.6406H13.0547V9.43359C13.0547 8.93606 13.4595 8.53125 13.957 8.53125Z"
        fill="#373737"
      />
      <path
        d="M8.78906 12.75H11.2969C11.491 12.75 11.6484 12.5926 11.6484 12.3984V9.43359C11.6484 8.54831 10.9283 7.82812 10.043 7.82812C9.15769 7.82812 8.4375 8.54831 8.4375 9.43359V12.3984C8.4375 12.5926 8.59491 12.75 8.78906 12.75ZM9.14062 12.0469V11.3438H10.9453V12.0469H9.14062ZM10.043 8.53125C10.5405 8.53125 10.9453 8.93606 10.9453 9.43359V10.6406H9.14062V9.43359C9.14062 8.93606 9.54544 8.53125 10.043 8.53125Z"
        fill="#373737"
      />
    </svg>
  );
}
