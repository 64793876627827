import React from 'react';

export default function SignPostIcon() {
  return (
    <svg width="28" height="25" viewBox="0 0 28 25" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
      <g clipPath="url(#clip0_1055_23081)">
        <path d="M25.9333 15.9984L22.8056 13.2178C22.6493 13.0781 22.4633 12.9673 22.2584 12.8919C22.0536 12.8166 21.834 12.778 21.6123 12.7786H14.3681V11.2786H21.9619C22.4094 11.2786 22.8387 11.1205 23.1551 10.8392C23.4716 10.5579 23.6494 10.1764 23.6494 9.77856V5.27856C23.6494 4.88074 23.4716 4.49921 23.1551 4.2179C22.8387 3.9366 22.4094 3.77856 21.9619 3.77856H14.3681V3.02856C14.3681 2.82965 14.2792 2.63889 14.121 2.49823C13.9628 2.35758 13.7482 2.27856 13.5244 2.27856C13.3006 2.27856 13.086 2.35758 12.9278 2.49823C12.7695 2.63889 12.6806 2.82965 12.6806 3.02856V3.77856H5.43652C5.21481 3.77802 4.99519 3.81656 4.79035 3.89195C4.58551 3.96734 4.39952 4.07809 4.24314 4.21778L1.11546 6.99841C0.957349 7.13905 0.86853 7.32974 0.86853 7.52856C0.86853 7.72739 0.957349 7.91808 1.11546 8.05872L4.24314 10.8393C4.39952 10.979 4.58551 11.0898 4.79035 11.1652C4.99519 11.2406 5.21481 11.2791 5.43652 11.2786H12.6806V12.7786H5.08689C4.63934 12.7786 4.21011 12.9366 3.89365 13.2179C3.57718 13.4992 3.39939 13.8807 3.39939 14.2786V18.7786C3.39939 19.1764 3.57718 19.5579 3.89365 19.8392C4.21011 20.1205 4.63934 20.2786 5.08689 20.2786H12.6806V22.5286C12.6806 22.7275 12.7695 22.9182 12.9278 23.0589C13.086 23.1995 13.3006 23.2786 13.5244 23.2786C13.7482 23.2786 13.9628 23.1995 14.121 23.0589C14.2792 22.9182 14.3681 22.7275 14.3681 22.5286V20.2786H21.6123C21.834 20.2791 22.0536 20.2406 22.2584 20.1652C22.4633 20.0898 22.6493 19.979 22.8056 19.8393L25.9333 17.0587C26.0914 16.9181 26.1802 16.7274 26.1802 16.5286C26.1802 16.3297 26.0914 16.139 25.9333 15.9984Z" />
      </g>
      <defs>
        <clipPath>
          <rect width="27" height="24" fill="white" transform="translate(0.0244141 0.778564)" />
        </clipPath>
      </defs>
    </svg>
  );
}
