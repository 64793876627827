import fetch from 'node-fetch';
import _clone from 'lodash/clone';
import { keys } from '../config/keys';

import {
  FETCH_AUTOMATION_DETAIL_SUCCESS,
  FETCH_AUTOMATION_DETAIL_FAILURE,
  UPDATE_AUTOMATION_DETAIL_SUCCESS,
  UPDATE_AUTOMATION_DETAIL_FAILURE,
  UPDATE_AUTOMATION_DETAIL_CLEAR,
  FETCH_AUTOMATION_ACTIONS,
  FETCH_AUTOMATION_CONDITIONS,
  FETCH_AUTOMATION_TOKENS,
  FETCH_AUTOMATION_BUTTON_TARGETS,
  FETCH_AUTOMATION_TYPES,
  FETCH_AUTOMATION_TYPES_REQUEST,
  FETCH_AUTOMATION_TYPES_FAILURE,
  FETCH_AUTOMATION_REQUEST,
  FETCH_AUTOMATION_SUCCESS,
  FETCH_AUTOMATION_FAILURE,
  FETCH_AUTOMATION_CLEAR,
  FETCH_AUTOMATION_QUALIFIER_REQUEST,
  FETCH_AUTOMATION_QUALIFIER_SUCCESS,
  FETCH_AUTOMATION_QUALIFIER_FAILURE,
  FETCH_AUTOMATION_PRECONDITION_REQUEST,
  FETCH_AUTOMATION_PRECONDITION_SUCCESS,
  FETCH_AUTOMATION_PRECONDITION_FAILURE,
  FETCH_AUTOMATION_CONDITION_REQUEST,
  FETCH_AUTOMATION_CONDITION_SUCCESS,
  FETCH_AUTOMATION_CONDITION_FAILURE,
  CREATE_AUTOMATION_REQUEST,
  CREATE_AUTOMATION_SUCCESS,
  CREATE_AUTOMATION_FAILURE,
  GET_AUTOMATION_MAILER_REQUEST,
  GET_AUTOMATION_MAILER_SUCCESS,
  GET_AUTOMATION_MAILER_FAILURE,
  GET_PROGRAMS_REQUEST,
  GET_PROGRAMS_SUCCESS,
  GET_PROGRAMS_FAILURE,
  FETCH_AUTOMATION_MAILER_HISTORY_REQUEST,
  FETCH_AUTOMATION_MAILER_HISTORY_SUCCESS,
  FETCH_AUTOMATION_MAILER_HISTORY_FAILURE,
  FETCH_AUTOMATION_FORM_HISTORY_REQUEST,
  FETCH_AUTOMATION_FORM_HISTORY_SUCCESS,
  FETCH_AUTOMATION_FORM_HISTORY_FAILURE,
  FETCH_AUTOMATION_TAG_HISTORY_REQUEST,
  FETCH_AUTOMATION_TAG_HISTORY_SUCCESS,
  FETCH_AUTOMATION_TAG_HISTORY_FAILURE,
  FETCH_AUTOMATION_CHANGE_STATUS_HISTORY_REQUEST,
  FETCH_AUTOMATION_CHANGE_STATUS_HISTORY_SUCCESS,
  FETCH_AUTOMATION_CHANGE_STATUS_HISTORY_FAILURE,
  DELETE_AUTOMATION_REQUEST,
  DELETE_AUTOMATION_SUCCESS,
  DELETE_AUTOMATION_FAILURE,
} from './types';
import { Cookies } from 'react-cookie';

export function fetchAutomationData() {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({ type: FETCH_AUTOMATION_REQUEST });
    fetch(`${keys.baseUri}/api/automations`, {
      method: 'GET',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: FETCH_AUTOMATION_SUCCESS,
          payload: data,
        });
        dispatch({
          type: FETCH_AUTOMATION_CLEAR,
        });
      })
      .catch(error => {
        dispatch({
          type: FETCH_AUTOMATION_FAILURE,
          payload: error.toString(),
        });
      });
  };
}

export function fetchAutomationDetail(id) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/automations/${id}`, {
      method: 'GET',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: FETCH_AUTOMATION_DETAIL_SUCCESS,
          payload: data.data,
        });
      })
      .catch(error => {
        dispatch({
          type: FETCH_AUTOMATION_DETAIL_FAILURE,
          payload: [],
        });
      });
  };
}

export function deleteAutomation(id, callback = null) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({
      type: DELETE_AUTOMATION_REQUEST,
    });

    fetch(`${keys.baseUri}/api/automations/${id}`, {
      method: 'DELETE',
      headers: token,
    })
      .then(res => {
        if (res.status === 204) {
          dispatch({
            type: DELETE_AUTOMATION_SUCCESS,
          });

          if (callback && typeof callback === 'function') {
            callback(true, 'Automation deleted.');
          }
        } else {
          if (callback && typeof callback === 'function') {
            dispatch({
              type: DELETE_AUTOMATION_FAILURE,
              payload: 'Something went wrong, please try again later.',
            });
            callback(false, 'Something went wrong, please try again later.');
          }
        }
      })
      .catch(error => {
        dispatch({
          type: DELETE_AUTOMATION_FAILURE,
          payload: error.message,
        });

        if (callback && typeof callback === 'function') {
          callback(false, error.message);
        }
      });
  };
}

export function updateAutomationDetail(id, values, callback = null) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return async function(dispatch) {
    try {
      let response = await fetch(`${keys.baseUri}/api/automations/${id}`, {
        method: 'PATCH',
        headers: token,
        body: JSON.stringify(values),
      });

      if (response.status !== 200) {
        throw new Error('Something went wrong');
      }

      let data = await response.json();
      dispatch({
        type: UPDATE_AUTOMATION_DETAIL_SUCCESS,
        payload: { status: 'success', message: 'Automation updated' },
      });
      dispatch({
        type: UPDATE_AUTOMATION_DETAIL_CLEAR,
        payload: null,
      });

      if (callback && typeof callback === 'function') {
        callback(true, 'Info updated', data.data);
      }

      dispatch(fetchAutomationData());
    } catch (e) {
      dispatch({
        type: UPDATE_AUTOMATION_DETAIL_FAILURE,
        payload: { status: 'fail', message: 'Error updating automation' },
      });

      if (callback && typeof callback === 'function') {
        callback(false, 'Error updating automation');
      }
    }
  };
}

export function fetchAutomationActions(type) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/automation_actions?type=${type}`, {
      method: 'GET',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: FETCH_AUTOMATION_ACTIONS,
          payload: data,
        });
      })
      .catch(error => {
        dispatch({
          type: FETCH_AUTOMATION_ACTIONS,
          payload: [],
        });
      });
  };
}

export function fetchAutomationConditions(precondition, callback) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({
      type: FETCH_AUTOMATION_CONDITION_REQUEST,
    });

    fetch(`${keys.baseUri}/api/automation_conditions?precondition=${precondition}`, {
      method: 'GET',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: FETCH_AUTOMATION_CONDITIONS,
          payload: data,
        });

        dispatch({
          type: FETCH_AUTOMATION_CONDITION_SUCCESS,
          payload: data,
        });

        if (callback && typeof callback === 'function') {
          callback(true, data.data);
        }
      })
      .catch(error => {
        dispatch({
          type: FETCH_AUTOMATION_CONDITIONS,
          payload: [],
        });

        dispatch({
          type: FETCH_AUTOMATION_CONDITION_FAILURE,
          payload: error.message,
        });

        if (callback && typeof callback === 'function') {
          callback(false, error.message);
        }
      });
  };
}

export function fetchAutomationTokens(type) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/automation_tokens?type=${type}`, {
      method: 'GET',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: FETCH_AUTOMATION_TOKENS,
          payload: data,
        });
      })
      .catch(error => {
        dispatch({
          type: FETCH_AUTOMATION_TOKENS,
          payload: [],
        });
      });
  };
}

export function fetchAutomationButtonTargets(type) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/automation_button_targets?type=${type}`, {
      method: 'GET',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: FETCH_AUTOMATION_BUTTON_TARGETS,
          payload: data,
        });
      })
      .catch(error => {
        dispatch({
          type: FETCH_AUTOMATION_BUTTON_TARGETS,
          payload: [],
        });
      });
  };
}

export function fetchAutomationTypes() {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({
      type: FETCH_AUTOMATION_TYPES_REQUEST,
    });

    fetch(`${keys.baseUri}/api/automation_types`, {
      method: 'GET',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: FETCH_AUTOMATION_TYPES,
          payload: data,
        });
      })
      .catch(error => {
        dispatch({
          type: FETCH_AUTOMATION_TYPES_FAILURE,
          payload: error.message,
        });
      });
  };
}

export function fetchAutomationQualifiers(type, callback) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({
      type: FETCH_AUTOMATION_QUALIFIER_REQUEST,
    });

    fetch(`${keys.baseUri}/api/automation_qualifiers?type=${type}`, {
      method: 'GET',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: FETCH_AUTOMATION_QUALIFIER_SUCCESS,
          payload: data,
        });

        if (callback && typeof callback === 'function') {
          callback(true, data.data);
        }
      })
      .catch(error => {
        dispatch({
          type: FETCH_AUTOMATION_QUALIFIER_FAILURE,
          payload: error.message,
        });

        if (callback && typeof callback === 'function') {
          callback(false, error.message);
        }
      });
  };
}

export function fetchAutomationPreconditions(qualifier, callback) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({
      type: FETCH_AUTOMATION_PRECONDITION_REQUEST,
    });

    fetch(`${keys.baseUri}/api/automation_preconditions?qualifier=${qualifier}`, {
      method: 'GET',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: FETCH_AUTOMATION_PRECONDITION_SUCCESS,
          payload: data,
        });

        if (callback && typeof callback === 'function') {
          callback(true, data.data);
        }
      })
      .catch(error => {
        dispatch({
          type: FETCH_AUTOMATION_PRECONDITION_FAILURE,
          payload: error.message,
        });

        if (callback && typeof callback === 'function') {
          callback(false, error.message);
        }
      });
  };
}

export function createNewAutomation(postData, callback) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({
      type: CREATE_AUTOMATION_REQUEST,
    });

    fetch(`${keys.baseUri}/api/automations`, {
      method: 'POST',
      headers: token,
      body: JSON.stringify(postData),
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: CREATE_AUTOMATION_SUCCESS,
          payload: data,
        });

        if (callback && typeof callback === 'function') {
          callback(true, data.data);
        }
      })
      .catch(error => {
        dispatch({
          type: CREATE_AUTOMATION_FAILURE,
          payload: error.message,
        });

        if (callback && typeof callback === 'function') {
          callback(false, error.message);
        }
      });
  };
}

export function getAutomationMailers(qualifier, callback) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({
      type: GET_AUTOMATION_MAILER_REQUEST,
    });

    fetch(`${keys.baseUri}/api/automation_form_mailers?qualifier=${qualifier}`, {
      method: 'GET',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: GET_AUTOMATION_MAILER_SUCCESS,
          payload: data.data,
        });

        if (callback && typeof callback === 'function') {
          callback(true, data.data);
        }
      })
      .catch(error => {
        dispatch({
          type: GET_AUTOMATION_MAILER_FAILURE,
          payload: error.message,
        });

        if (callback && typeof callback === 'function') {
          callback(false, error.message);
        }
      });
  };
}

export function getPrograms(
  automation_id = '',
  callback = null,
  unauthorize_programs = false,
  source = '',
  org_id = '',
) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({
      type: GET_PROGRAMS_REQUEST,
    });
    let url = `${keys.baseUri}/api/client/programs/thin?automation_id=${automation_id}`;
    if (unauthorize_programs) {
      url += `&unauthorize_programs=true`;
    }
    if (source) {
      url += `&source=${source}`;
    }
    if (org_id) {
      url += `&org_id=${org_id}`;
    }
    fetch(url, {
      method: 'GET',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: GET_PROGRAMS_SUCCESS,
          payload: data.programs,
        });

        if (callback && typeof callback === 'function') {
          callback(true, data.programs);
        }
      })
      .catch(error => {
        dispatch({
          type: GET_PROGRAMS_FAILURE,
          payload: error.message,
        });

        if (callback && typeof callback === 'function') {
          callback(false, error.message);
        }
      });
  };
}

export function fetchAutomationMailerHistory(automation_id) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({ type: FETCH_AUTOMATION_MAILER_HISTORY_REQUEST });
    fetch(`${keys.baseUri}/api/send_grid_mailers/automation_mailers_history?automation_id=${automation_id}`, {
      method: 'GET',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: FETCH_AUTOMATION_MAILER_HISTORY_SUCCESS,
          payload: data,
        });
      })
      .catch(error => {
        dispatch({
          type: FETCH_AUTOMATION_MAILER_HISTORY_FAILURE,
          payload: error.toString(),
        });
      });
  };
}

export function fetchAutomationFormHistory(automation_id) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({ type: FETCH_AUTOMATION_FORM_HISTORY_REQUEST });
    fetch(`${keys.baseUri}/api/send_grid_mailers/form_mailers_history?automation_id=${automation_id}`, {
      method: 'GET',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: FETCH_AUTOMATION_FORM_HISTORY_SUCCESS,
          payload: data,
        });
      })
      .catch(error => {
        dispatch({
          type: FETCH_AUTOMATION_FORM_HISTORY_FAILURE,
          payload: error.toString(),
        });
      });
  };
}

export function fetchAutomationTagHistory(automation_id) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({ type: FETCH_AUTOMATION_TAG_HISTORY_REQUEST });
    fetch(`${keys.baseUri}/api/automation_history?automation_action=add_tags&automation_id=${automation_id}`, {
      method: 'GET',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: FETCH_AUTOMATION_TAG_HISTORY_SUCCESS,
          payload: data,
        });
      })
      .catch(error => {
        dispatch({
          type: FETCH_AUTOMATION_TAG_HISTORY_FAILURE,
          payload: error.toString(),
        });
      });
  };
}

export function fetchAutomationChangeStatusHistory(automation_id) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({ type: FETCH_AUTOMATION_CHANGE_STATUS_HISTORY_REQUEST });
    fetch(`${keys.baseUri}/api/automations/status_history?automation_id=${automation_id}`, {
      method: 'GET',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: FETCH_AUTOMATION_CHANGE_STATUS_HISTORY_SUCCESS,
          payload: data?.automation_history,
        });
      })
      .catch(error => {
        dispatch({
          type: FETCH_AUTOMATION_CHANGE_STATUS_HISTORY_FAILURE,
          payload: error.toString(),
        });
      });
  };
}
