exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@-webkit-keyframes _2zRA9{to{-webkit-transform:rotate(1turn)}}@keyframes _2zRA9{to{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}:root{--dark:var(--theme_color_dark);--accent:var(--theme_color_accent);--light:var(--theme_color_light)}._4nXTL{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column}._4nXTL .ox0_x{font-family:AvenirNextRegular,sans-serif;font-size:14px;color:#373737;text-align:center;padding-bottom:21px}@media (min-width:768px){._4nXTL ._1KE9S{padding:0 80px}}._4nXTL ._1KE9S ._Ij8L{padding-bottom:16px}._4nXTL ._1KE9S .SVxWR{width:214px;margin:0 0 0 auto}._4nXTL ._1KE9S .SVxWR button{color:#fff;border:none;padding:10px 20px;font-size:1em;cursor:pointer;border-radius:4px;-webkit-transition:background-color .3s;-o-transition:background-color .3s;transition:background-color .3s;font-family:Open Sans;font-size:15px;font-weight:500}._4nXTL ._1KE9S .SVxWR button,._4nXTL ._1KE9S .SVxWR button:active,._4nXTL ._1KE9S .SVxWR button:hover{background-color:var(--theme_color_accent)}._4nXTL ._1KE9S .SVxWR button:disabled{background-color:#9e9e9e;cursor:not-allowed}", ""]);

// exports
exports.locals = {
	"wrapper": "_4nXTL",
	"header": "ox0_x",
	"form": "_1KE9S",
	"input": "_Ij8L",
	"button": "SVxWR",
	"spin": "_2zRA9"
};