import React from 'react';
import classnames from 'classnames';
import propTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import {
  iconFlight,
  iconBus,
  iconShip,
  iconCar,
  iconTrain,
  iconMetro,
  iconBike,
  iconWalk,
  iconTaxi,
  iconTransportationOther,
} from './svgs/transportationSvgs';
import {
  iconApartmentSingle,
  iconApartmentDouble,
  iconHomestay,
  iconHotel,
  iconHostel,
  iconDorm,
  iconVacationRental,
  iconShipCabin,
  iconTrainCabin,
  iconCabin,
  iconIndependent,
  iconHousingOther,
} from './svgs/housingSvgs';
import {
  iconArtisticPerformance,
  iconConference,
  iconExcursion,
  iconMeeting,
  iconSportingEvent,
  iconMedicalVisit,
  iconVolunteerService,
  iconWork,
  iconResearch,
  iconIndependentTravel,
  iconMeal,
  iconClass,
  iconTour,
  iconMuseum,
  iconOutdoorActivity,
  iconActivityOther,
} from './svgs/activitySvgs';
import {
  iconSolo,
  iconGroup,
  iconAddHousing,
  iconAddTransportation,
  iconAddActivity,
  iconTableView,
  iconRosterView,
  iconMap,
  iconTrash,
  iconCheckMark,
  iconWarning,
  iconItinerary,
  iconCalendar,
  iconMapMagnifying,
  iconSuitcase,
  iconGear,
  iconEye,
  iconClock,
  iconClockPicker,
  iconPencil,
  iconRestore,
  iconHeart,
  iconCardCalendar,
  iconLocationPin,
  iconProgramType,
  iconBack,
  iconSearch,
  iconOpenLinkInNewWindow,
  iconLightbulb,
  iconPlus,
  iconLock,
  iconChevronDown,
  iconSuccessMountain,
  iconCircleInfo,
  iconChevronLeft,
  iconConversation,
  iconCheckMarkSolid,
} from './svgs/otherSvgs';
import sViaIcon from './viaIcon.scss';

function ViaIcon(props = {}) {
  const className = classnames(sViaIcon['via-icon'], sViaIcon[props.color], sViaIcon[props.size]);

  if (props.name === 'flight' || props.transportationId === 1) {
    return iconFlight(className, props.ariaLabel ? props.ariaLabel : 'Flight Icon');
  }

  if (props.name === 'ship' || props.transportationId === 2) {
    return iconShip(className, props.ariaLabel ? props.ariaLabel : `Ship Icon`);
  }

  if (props.name === 'car' || props.transportationId === 3) {
    return iconCar(className, props.ariaLabel ? props.ariaLabel : `Car Icon`);
  }

  if (props.name === 'train' || props.transportationId === 4) {
    return iconTrain(className, props.ariaLabel ? props.ariaLabel : `Train Icon`);
  }

  if (props.name === 'metro' || props.transportationId === 5) {
    return iconMetro(className, props.ariaLabel ? props.ariaLabel : `Metro Icon`);
  }

  if (props.name === 'bike' || props.transportationId === 6) {
    return iconBike(className, props.ariaLabel ? props.ariaLabel : `Bike Icon`);
  }

  if (props.name === 'walk' || props.transportationId === 7) {
    return iconWalk(className, props.ariaLabel ? props.ariaLabel : `Walk Icon`);
  }

  if (props.name === 'other' || props.transportationId === 8) {
    return iconTransportationOther(className, props.ariaLabel ? props.ariaLabel : `Other Icon`);
  }

  if (props.name === 'taxi' || props.transportationId === 9) {
    return iconTaxi(className, props.ariaLabel ? props.ariaLabel : `Taxi Car Icon`);
  }

  if (props.name === 'bus' || props.transportationId === 10) {
    return iconBus(className, props.ariaLabel ? props.ariaLabel : `Bus Icon`);
  }

  if (props.name === 'apartmentSingle' || props.housingId === 1) {
    return iconApartmentSingle(className, props.ariaLabel ? props.ariaLabel : `Single Apartment Icon`);
  }

  if (props.name === 'apartmentDouble' || props.housingId === 2) {
    return iconApartmentDouble(className, props.ariaLabel ? props.ariaLabel : `Double Apartment Icon`);
  }
  if (props.name === 'homestay' || props.housingId === 3) {
    return iconHomestay(className, props.ariaLabel ? props.ariaLabel : `Homestay Icon`);
  }
  if (props.name === 'hotel' || props.housingId === 4) {
    return iconHotel(className, props.ariaLabel ? props.ariaLabel : `Hotel Icon`);
  }
  if (props.name === 'hostel' || props.housingId === 5) {
    return iconHostel(className, props.ariaLabel ? props.ariaLabel : `Hostel Icon`);
  }
  if (props.name === 'dorm' || props.housingId === 6) {
    return iconDorm(className, props.ariaLabel ? props.ariaLabel : `Dorm Icon`);
  }
  if (props.name === 'vacationRental' || props.housingId === 7) {
    return iconVacationRental(className, props.ariaLabel ? props.ariaLabel : `Vacation Rental Icon`);
  }
  if (props.name === 'shipCabin' || props.housingId === 8) {
    return iconShipCabin(className, props.ariaLabel ? props.ariaLabel : `Ship Cabin Icon`);
  }
  if (props.name === 'trainCabin' || props.housingId === 9) {
    return iconTrainCabin(className, props.ariaLabel ? props.ariaLabel : `Train Cabin Icon`);
  }
  if (props.name === 'cabin' || props.housingId === 10) {
    return iconCabin(className, props.ariaLabel ? props.ariaLabel : `Cabin Icon`);
  }
  if (props.name === 'independent' || props.housingId === 11) {
    return iconIndependent(className, props.ariaLabel ? props.ariaLabel : `Independent Icon`);
  }
  if (props.name === 'otherHousing' || props.housingId === 12) {
    return iconHousingOther(className, props.ariaLabel ? props.ariaLabel : `Other Housing Icon`);
  }

  if (props.name === 'artisticPerformance' || props.activityId === 1) {
    return iconArtisticPerformance(className, props.ariaLabel ? props.ariaLabel : `Artistic Performace Icon`);
  }

  if (props.name === 'conference' || props.activityId === 2) {
    return iconConference(className, props.ariaLabel ? props.ariaLabel : `Conference Icon`);
  }

  if (props.name === 'excursion' || props.activityId === 3) {
    return iconExcursion(className, props.ariaLabel ? props.ariaLabel : `Excursion Icon`);
  }

  if (props.name === 'meeting' || props.activityId === 4) {
    return iconMeeting(className, props.ariaLabel ? props.ariaLabel : `Meeting Icon`);
  }

  if (props.name === 'sportingEvent' || props.activityId === 5) {
    return iconSportingEvent(className, props.ariaLabel ? props.ariaLabel : `Sporting Event Icon`);
  }

  if (props.name === 'medicalVisit' || props.activityId === 6) {
    return iconMedicalVisit(className, props.ariaLabel ? props.ariaLabel : `Medical Visit Icon`);
  }

  if (props.name === 'volunteer' || props.activityId === 7) {
    return iconVolunteerService(className, props.ariaLabel ? props.ariaLabel : `Volunteer Icon`);
  }

  if (props.name === 'work' || props.activityId === 8) {
    return iconWork(className, props.ariaLabel ? props.ariaLabel : `Work Icon`);
  }

  if (props.name === 'research' || props.activityId === 9) {
    return iconResearch(className, props.ariaLabel ? props.ariaLabel : `Research Icon`);
  }

  if (props.name === 'independentTravel' || props.activityId === 10) {
    return iconIndependentTravel(className, props.ariaLabel ? props.ariaLabel : `Independent Travel Icon`);
  }

  if (props.name === 'otherActivity' || props.activityId === 11) {
    return iconActivityOther(className, props.ariaLabel ? props.ariaLabel : `Other Activity Icon`);
  }

  if (props.name === 'meal' || props.activityId === 12) {
    return iconMeal(className, props.ariaLabel ? props.ariaLabel : `Meal Icon`);
  }

  if (props.name === 'class' || props.activityId === 13) {
    return iconClass(className, props.ariaLabel ? props.ariaLabel : `Class Icon`);
  }

  if (props.name === 'tour' || props.activityId === 14) {
    return iconTour(className, props.ariaLabel ? props.ariaLabel : `Tour Icon`);
  }

  if (props.name === 'museum' || props.activityId === 15) {
    return iconMuseum(className, props.ariaLabel ? props.ariaLabel : `Museum Icon`);
  }

  if (props.name === 'outdoorActivity' || props.activityId === 16) {
    return iconOutdoorActivity(className, props.ariaLabel ? props.ariaLabel : `Outdoor Activity Icon`);
  }

  if (props.name === 'solo') {
    return iconSolo(className, props.ariaLabel ? props.ariaLabel : `Solo Icon`);
  }

  if (props.name === 'group') {
    return iconGroup(className, props.ariaLabel ? props.ariaLabel : `Group Icon`);
  }

  if (props.name === 'hiking') {
    return iconAddActivity(className, props.ariaLabel ? props.ariaLabel : `Hiking Icon`);
  }

  if (props.name === 'housing') {
    return iconAddHousing(className, props.ariaLabel ? props.ariaLabel : `Housing con`);
  }

  if (props.name === 'plane') {
    return iconAddTransportation(className, props.ariaLabel ? props.ariaLabel : `Plane Icon`);
  }

  if (props.name === 'table') {
    return iconTableView(className, props.ariaLabel ? props.ariaLabel : `Table Icon`);
  }

  if (props.name === 'roster') {
    return iconRosterView(className, props.ariaLabel ? props.ariaLabel : `Roster Icon`);
  }

  if (props.name === 'map') {
    return iconMap(className, props.ariaLabel ? props.ariaLabel : `Map Icon`);
  }

  if (props.name === 'trash') {
    return iconTrash(className, props.ariaLabel ? props.ariaLabel : `Trash Icon`);
  }

  if (props.name === 'checkMark') {
    return iconCheckMark(className, props.ariaLabel ? props.ariaLabel : `Checkmark Icon`);
  }

  if (props.name === 'checkMarkSolid') {
    return iconCheckMarkSolid(className, props.ariaLabel ? props.ariaLabel : `Checkmark Solid Icon`);
  }

  if (props.name === 'warning') {
    return iconWarning(className, props.ariaLabel ? props.ariaLabel : `Warning Icon`);
  }

  if (props.name === 'itinerary') {
    return iconItinerary(className, props.ariaLabel ? props.ariaLabel : `Itinerary Icon`);
  }

  if (props.name === 'calendar') {
    return iconCalendar(className, props.ariaLabel ? props.ariaLabel : `Calendar Icon`);
  }

  if (props.name === 'mapMagnify') {
    return iconMapMagnifying(className, props.ariaLabel ? props.ariaLabel : `Magnify Map Icon`);
  }

  if (props.name === 'suitcase') {
    return iconSuitcase(className, props.ariaLabel ? props.ariaLabel : `Suitcase Icon`);
  }
  if (props.name === 'gear') {
    return iconGear(className, props.ariaLabel ? props.ariaLabel : `Gear Icon`);
  }

  if (props.name === 'eye') {
    return iconEye(className, props.ariaLabel ? props.ariaLabel : `Eye Icon`);
  }

  if (props.name === 'clock') {
    return iconClock(className, props.ariaLabel ? props.ariaLabel : `Clock Icon`);
  }
  if (props.name === 'clockPicker') {
    return iconClockPicker(className, props.ariaLabel ? props.ariaLabel : `Clock Icon`);
  }

  if (props.name === 'pencil') {
    return iconPencil(className, props.ariaLabel ? props.ariaLabel : `Pencil Icon`);
  }

  if (props.name === 'restore') {
    return iconRestore(className, props.ariaLabel ? props.ariaLabel : `Restore Icon`);
  }

  if (props.name === 'heart') {
    return iconHeart(className, props.ariaLabel ? props.ariaLabel : `Heart Icon`, props.fillColor);
  }

  if (props.name === 'cardCalendar') {
    return iconCardCalendar(className, props.ariaLabel ? props.ariaLabel : `Calendar Icon`);
  }

  if (props.name === 'locationPin') {
    return iconLocationPin(className, props.ariaLabel ? props.ariaLabel : `Location Pin Icon`);
  }

  if (props.name === 'programType') {
    return iconProgramType(className, props.ariaLabel ? props.ariaLabel : `Program Type Icon`);
  }

  if (props.name === 'back') {
    return iconBack(className, props.ariaLabel ? props.ariaLabel : `Back Icon`);
  }

  if (props.name === 'search') {
    return iconSearch(className, props.ariaLabel ? props.ariaLabel : `Search Icon`);
  }

  if (props.name === 'openInNewWindow') {
    return iconOpenLinkInNewWindow(className, props.ariaLabel ? props.ariaLabel : `Open in new window`);
  }

  if (props.name === 'lightbulb') {
    return iconLightbulb(className, props.ariaLabel ? props.ariaLabel : `Lightbulb`);
  }

  if (props.name === 'plus') {
    return iconPlus(className, props.ariaLabel ? props.ariaLabel : `Plus/Add Icon`);
  }

  if (props.name === 'lock') {
    return iconLock(className, props.ariaLabel ? props.ariaLabel : `Locked Field Icon`);
  }

  if (props.name === 'chevronDown') {
    return iconChevronDown(className, props.ariaLabel ? props.ariaLabel : 'down chevron');
  }

  if (props.name === 'chevronLeft') {
    return iconChevronLeft(className, props.ariaLabel ? props.ariaLabel : 'left chevron');
  }

  if (props.name === 'successMountain') {
    return iconSuccessMountain(className, props.ariaLabel ? props.ariaLabel : 'via icon - mountain');
  }

  if (props.name === 'circleInfo') {
    return iconCircleInfo(className, props.ariaLabel ? props.ariaLabel : 'via icon - info');
  }

  if (props.name === 'conversation') {
    return iconConversation(className, props.ariaLabel ? props.ariaLabel : 'via icon - conversation');
  }

  return <span />;
}

ViaIcon.propTypes = {
  size: propTypes.oneOf([
    'xsmall',
    'xsmallPlusW10',
    'small',
    'smallPlusW10',
    'medium',
    'mediumPlusW10',
    'mini',
    'miniPlusW10',
    'large',
    'largePlusW10',
    'xlarge',
    'xlargePlusW10',
    'icon24',
    'nano',
  ]),
  color: propTypes.oneOf([
    'white',
    'black',
    'primary',
    'darkText',
    'secondary',
    'navigation',
    'gray',
    'darkGray',
    'secondary-darkest',
    'noFill',
    'green',
    'error',
  ]),
  transportationId: propTypes.number,
  activityId: propTypes.number,
  housingId: propTypes.number,
  fillColor: propTypes.string,
  name: propTypes.oneOf([
    '',
    'flight',
    'ship',
    'car',
    'train',
    'metro',
    'bike',
    'walk',
    'other',
    'taxi',
    'bus',
    'apartmentSingle',
    'apartmentDouble',
    'homestay',
    'hotel',
    'hostel',
    'dorm',
    'vacationRental',
    'shipCabin',
    'trainCabin',
    'cabin',
    'independent',
    'otherHousing',
    'artisticPerformance',
    'conference',
    'excursion',
    'meeting',
    'sportingEvent',
    'medicalVisit',
    'volunteer',
    'work',
    'research',
    'independentTravel',
    'otherActivity',
    'meal',
    'class',
    'tour',
    'museum',
    'outdoorActivity',
    'solo',
    'group',
    'hiking',
    'housing',
    'plane',
    'table',
    'roster',
    'map',
    'trash',
    'checkMark',
    'warning',
    'itinerary',
    'calendar',
    'mapMagnify',
    'suitcase',
    'gear',
    'eye',
    'clock',
    'pencil',
    'restore',
    'heart',
    'cardCalendar',
    'locationPin',
    'programType',
    'back',
    'search',
    'openInNewWindow',
    'lightbulb',
    'plus',
    'lock',
    'chevronDown',
    'successMountain',
    'circleInfo',
    'chevronLeft',
    'conversation',
  ]),
};

ViaIcon.defaultProps = {
  size: 'medium',
  color: 'white',
  name: '',
  transportationId: -1,
  activityId: -1,
  housingId: -1,
  ariaLabel: '',
  fillColor: '',
};

export default withStyles(sViaIcon)(ViaIcon);
