import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { breakpoints } from '../../utils/breakpoints';

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 0.875rem;


  @media ${breakpoints.desktop} {
    display: block;
  }

  img {
    width: 36px;
    height: 36px;
    padding: 1px;
    border: 3px solid var(--theme_color_dark);
    border-radius: 50%;

    @media ${breakpoints.desktop} {
      width: 52px;
      height: 52px;
    }
  }

  span {
    display: none;
    font-family: 'Nunito', sans-serif;
    font-weight: 700;
    font-size: 1rem;
    color: #000000;
    letter-spacing: 0.04em;
    line-height: 120%;

    @media ${breakpoints.desktop} {
      margin-top: 15px;
    }
    @media ${breakpoints.tablet} {
      display: block;
    }
  }
`;

export default function OrganizationInfo() {
  const {
    organizationName,
    brandingTheme: { logo },
  } = useSelector(state => state.currentOrganization);

  return (
    <Container>
      <img src={logo.theme_logo} alt="Organization Company Logo" />
      <span>{organizationName}</span>
    </Container>
  );
}
