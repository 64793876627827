exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@-webkit-keyframes _1KNyc{to{-webkit-transform:rotate(1turn)}}@keyframes _1KNyc{to{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}._25gWi{display:-ms-flexbox;display:flex;width:90%;min-height:62px;-ms-flex-align:center;align-items:center;border-radius:5px;background:#deecff;background:var(--info-bkgd,#deecff);margin:20px auto 10px;-webkit-box-shadow:0 4px 15px 0 rgba(81,128,177,.25);box-shadow:0 4px 15px 0 rgba(81,128,177,.25);color:#4d4d4d;font-family:Open Sans;font-size:16px;font-style:normal;font-weight:400}._25gWi,._25gWi ._3TUzg{-ms-flex-negative:0;flex-shrink:0}._25gWi ._3TUzg{width:20px;-ms-flex-item-align:stretch;align-self:stretch;border-radius:5px 0 0 5px;background:#255a94;background:var(--info-text,#255a94);margin-right:20px}._25gWi ._2GFLr{color:#255a94;font-weight:700;text-decoration:underline;-webkit-text-decoration-color:#255a94;text-decoration-color:#255a94;padding-left:5px}", ""]);

// exports
exports.locals = {
	"switch-banner": "_25gWi",
	"switch-left-div": "_3TUzg",
	"switch-link": "_2GFLr",
	"spin": "_1KNyc"
};