import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import CardApplicationStatus from '../my-programs/cards/CardApplicationStatus';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.3125rem;
`;

const Text = styled.span`
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 0.8125rem;
  color: #373737;
  letter-spacing: 0.004em;
  line-height: 120%;
`;

export default function DocumentStatus({ forForm }) {
  const {
    formSubmission,
    applicationSubmission: { attributes: submission },
  } = useSelector(state => state.travelerApplicationSubmissions);

  const status = forForm ? formSubmission?.attributes?.submission_status?.name : submission.submission_status.name;

  return (
    <Container>
      <Text>Current Status:</Text>
      <CardApplicationStatus status={status} forForm={forForm} />
    </Container>
  );
}

DocumentStatus.propTypes = {
  forForm: PropTypes.bool.isRequired,
};
