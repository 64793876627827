import Clock from '../../components/icons/Clock';
import House from '../../components/icons/House';
import Location from '../../components/icons/Location';
import Education from '../../components/icons/Education';
import Languages from '../../components/icons/Languages';
import ProgramType from '../../components/icons/ProgramType';

// The "id" property is the "priority_id" sent to the API.
// The "value" property is the ranking.
export default [
  {
    id: 3,
    content: 'Timing',
    value: 1,
    icon: Clock,
  },
  {
    id: 2,
    content: 'Housing',
    value: 2,
    icon: House,
  },
  {
    id: 6,
    content: 'Language',
    value: 3,
    icon: Languages,
  },
  {
    id: 5,
    content: 'Location',
    value: 4,
    icon: Location,
  },
  {
    id: 1,
    content: 'Subject Areas',
    value: 5,
    icon: Education,
  },
  {
    id: 7,
    content: 'Program Type',
    value: 6,
    icon: ProgramType,
  },
];
