import fetch from 'node-fetch';
import { keys } from '../config/keys';
import _clone from 'lodash/clone';
import {
  FETCH_TRAVELER_APPLICATION,
  SEND_RECOMMENDATION,
  CANCEL_RECOMMENDATION,
  RESEND_RECOMMENDATION,
  GET_TRAVELER_APPLICATION,
  APPLICATION_LOADED,
  SET_ALTERNATE_PROGRAM_SUCCESS,
} from './types';
import { Cookies } from 'react-cookie';

export function getTravelerApplication(application_id) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/traveler/applications/${application_id}/get_traveler_application`, {
      headers: token,
    }).then(response => {
      response.json().then(res => {
        dispatch({
          type: GET_TRAVELER_APPLICATION,
          payload: res,
        });
      });
    });
  };
}

export function fetchTravelerApplication(programRangeId) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/traveler/applications/${programRangeId}`, {
      headers: token,
    })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: FETCH_TRAVELER_APPLICATION,
          payload: data,
        }),
      )
      .catch(error => console.error(error));
  };
}

export const updateAlternateRankApplication = (params, application_id) => dispatch => {
  const cookies = new Cookies();
  const token = cookies.get('token');
  fetch(`${keys.baseUri}/api/traveler/applications/${application_id}`, {
    method: 'PUT',
    headers: token,
    body: JSON.stringify(params),
  })
    .then(response => response.json())
    .then(data => {
      let modal = '';
      if (params.trm_submission_application.status == 'submitted') {
        modal = '&modal=appSubmit';
      }

      dispatch({
        type: APPLICATION_LOADED,
      });
      window.scrollTo(0, 0);
      dispatch({
        type: SET_ALTERNATE_PROGRAM_SUCCESS,
        payload: data.notice,
      });
      setTimeout(() => {
        window.location.assign(`/traveler?tab=applications${modal}`);
        getTravelerApplication(application_id);
      }, 2000);
    });
};

export function updateTravelerApplication(programRangeId, data, submit, source = null, is_alternate_on, deadline) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  let formData = new FormData();
  const objKeys = Object.keys(data);

  objKeys.map(obj => {
    let answerValue = data[obj];

    formData.append(`answers[${obj}]`, Array.isArray(answerValue) ? JSON.stringify(answerValue) : answerValue);
  });

  formData.append('submit', submit);

  let cloneToken = _clone(token);
  delete cloneToken['Accept'];
  delete cloneToken['Content-Type'];

  return function(dispatch) {
    fetch(`${keys.baseUri}/api/traveler/applications/${programRangeId}`, {
      method: 'PUT',
      headers: cloneToken,
      body: formData,
    }).then(response => {
      response.json().then(res => {
        if (!is_alternate_on) {
          if (response.status === 200 && source && submit == 'submitted') {
            window.location.assign('/traveler?tab=applications&modal=appSubmit');
          } else if (response.status === 200 && source && !deadline) {
            window.location.assign('/traveler?tab=applications');
          }
        } else if (is_alternate_on) {
          if (response.status === 200) {
            window.location.assign(`/traveler/application/${res.submission.id}/programs_rank`);
          }
        }
      });
    });
  };
}

export function sendRecommendation(elementId, program_range, email) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/recommendations/${elementId}/create_recommendation`, {
      method: 'POST',
      headers: token,
      body: JSON.stringify({ program_range: program_range, email: email }),
    })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: SEND_RECOMMENDATION,
          payload: data,
        }),
      );
  };
}

export function cancelRecommendation(recommendationId) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/recommendations/${recommendationId}/cancel_recommendation_request`, {
      method: 'PUT',
      headers: token,
    })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: CANCEL_RECOMMENDATION,
          payload: data,
        }),
      );
  };
}

export function resendRecommendation(recommendationId) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/recommendations/${recommendationId}/resend_recommendation_request`, {
      method: 'PUT',
      headers: token,
    })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: RESEND_RECOMMENDATION,
          payload: data,
        }),
      );
  };
}

export function commitApplication(id) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/traveler/applications/${id}/commit`, {
      method: 'PUT',
      headers: token,
    }).then(response => {
      response.json().then(res => {
        if (response.status === 200) {
          window.location.assign('/traveler?tab=forms&modal=commitSubmit');
        }
      });
    });
  };
}

export function withdrawApplication(id, data, role) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/traveler/applications/${id}/withdraw`, {
      method: 'PUT',
      headers: token,
      body: JSON.stringify({ reasons: data }),
    }).then(response => {
      response.json().then(res => {
        if (response.status === 200 && role === 'Admin') {
          window.location.reload();
        } else if (response.status === 200 && role != 'Admin') {
          window.location.assign('/traveler?tab=applications');
        }
      });
    });
  };
}

export function deferApplication(id, data, role) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/traveler/applications/${id}/defer`, {
      method: 'PUT',
      headers: token,
      body: JSON.stringify({ reasons: data }),
    }).then(response => {
      response.json().then(res => {
        if (response.status === 200 && role === 'Admin') {
          window.location.reload();
        } else if (response.status === 200 && role != 'Admin') {
          window.location.assign('/traveler?tab=applications');
        }
      });
    });
  };
}
