import React from 'react';
import Auxiliary from '../../hoc/Auxiliary';
import moment from 'moment-timezone';

export const MonthYearFormat = props => {
  const termTimezone = props.timezone || null;
  let newStart = props.startDate;
  let newEnd = props.endDate;
  let displayStart = moment(new Date(newStart)).format('MMM [] YYYY');
  let displayEnd = moment(new Date(newEnd)).format('MMM [] YYYY');

  if (termTimezone && termTimezone?.length > 0) {
    newStart = moment(props.startDate)
      .tz(termTimezone)
      .format();

    // displayStart = moment(newStart)
    //   .tz(termTimezone)
    //   .format('MMM[.] DD[,] YYYY');

    displayStart = moment(newStart)
    .tz(termTimezone)
    .format('MMM[.] YYYY');

    newEnd = moment(props.endDate)
      .tz(termTimezone)
      .format();

    // displayEnd = moment(newEnd)
    //   .tz(termTimezone)
    //   .format('MMM[.] DD[,] YYYY');

    displayEnd = moment(newEnd)
    .tz(termTimezone)
    .format('MMM[.] YYYY');
  }

  return (
    <Auxiliary>
      {displayStart} - {props.addBr ? <br /> : null}
      {displayEnd}
    </Auxiliary>
  );
};
