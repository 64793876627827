import React, { lazy, Suspense, useEffect, useState } from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import _get from 'lodash/get';
import { Controller, useForm, useFieldArray } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import StyledIcon from '../../../../../styledComponents/styles/Icon.styled';
import TextInput from '../../../../../styledComponents/styles/TextInput.styled';
import Toggle from '../../../../../styledComponents/styles/Toggle.styled';
import { AccentButtonMediumNunito } from '../../../../../styledComponents/styles/Buttons.styled';
import { AccentButtonSpinner } from '../../../../../styledComponents/styles/spinner.styled';
import { updateForm, updateQuestion } from '../../../../../actions/formsActions';
import LogicTab from '../logic/logic.tab';
import LogicForm from '../logic/logicForm';
import DropDownOptionFields from './question.dropdown.optionFields.addEdit';

const EditTextArea = lazy(() => import('../../../../../styledComponents/styles/EditTextArea.styled'));

const FormContainer = styled.div`
  font-family: 'Nunito', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 0.015em;
  color: #373737;
`;

const InputContainer = styled.div`
  flex: 1;
  margin: 20px 0;
  text-align: left;
`;



const OtherOptionContainer = styled.div`
  font-family: 'Nunito', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  display: flex;
  align-items: center;
  letter-spacing: 0.0015em;
  text-decoration-line: underline;
  color: #446372;
  margin: 22px 0 34px 30px;
  cursor: pointer;
`;

const SubmitContainer = styled.main`
  display: flex;
  justify-content: flex-end;
  margin-top: 35px;
`;

const ToggleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: inherit;
`;

const ToggleLabel = styled.span`
  align-self: center;
  flex: 1;
  justify-content: center;
  padding-left: 10px;
  padding-bottom: 7px;
`;

const InfoMsg = styled.div`
  background-color: #deecff;
  border-left: 20px solid #5180b1;
  border-radius: 5px;
  color: #4d4d4d;
  line-height: 17px;
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 20px;
  width: 100%;
`;

export default function QuestionDropdownAddEdit({ question, edit, onClose }) {
  const MAX_LENGTH = 90;
  const qIdentifier = 'dropdown';
  const dispatch = useDispatch();
  const fetchForm = useSelector(state => state.forms.fetchForm);
  const questionTypesInfo = useSelector(state => state.forms.questionTypes);
  const updateFormSubmit = useSelector(state => state.forms.updateForm);
  const updateQuestionSubmit = useSelector(state => state.forms.updateQuestion);
  const [formId, setFormId] = useState(null);
  const customAliases = useSelector(state => state.profile.customAliases);
  const isTravelerInfo = ['travelerinfofield', 'customfield'].includes(question?.sourceable_type?.toLowerCase());
  const [focusId, setFocusId] = useState(null);
  const [showQuestion, setShowQuestion] = useState(true);
  const [selectOptions, setSelectOptions] = useState(null);
  const [logicOption, setLogicOption] = useState(question?.dropdown_question_logic?.dropdown_question_option_id);
  const [questionLogicForm, setQuestionLogicForm] = useState(question);
  const [disableSubmit, setDisableSubmit] = useState(false);

  const schema = yup.object().shape({
    dropdown_label: yup
      .string()
      .required('Question must have label')
      .max(MAX_LENGTH, `Label can not be more than ${MAX_LENGTH} characters`),
    dropdown_instructions: yup.string(),
    dropdown_question_options_attributes: yup.array().of(
      yup.object().shape({
        value: yup.string().test('len', 'Option value is required', val => val.length > 0),
      }),
    ),
    dropdown_admin_only: yup.boolean(),
    dropdown_required: yup.boolean(),
  });

  if (question && question?.dropdown_question_options) {
    question?.dropdown_question_options.sort((a, b) => {
      if (a.order < b.order) return -1;
      else if (a.order > b.order) return 1;
      return 0;
    });
  }

  const { control, handleSubmit, formState, reset, setValue } = useForm({
    shouldUnregister: false,
    resolver: yupResolver(schema),
    defaultValues: {
      dropdown_label: edit ? question?.label : '',
      dropdown_instructions: edit ? question?.instructions || '' : '',
      dropdown_question_options_attributes: edit
        ? question?.dropdown_question_options
            .map(opt => {
              return { value: opt?.name, order: opt?.order, optId: opt?.id };
            })
            .sort((a, b) => {
              if (a.order < b.order) return -1;
              else if (a.order > b.order) return 1;
              return 0;
            })
        : [{ value: 'Option 1', order: 0, optId: '' }],
      dropdown_admin_only: false,
      dropdown_required: edit ? question?.required : true,
    },
    mode: 'onChange',
  });

  const { fields, append, prepend, remove, swap } = useFieldArray({
    control,
    name: 'dropdown_question_options_attributes',
  });

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset({}, { keepValues: true });
    }
  }, [formState]);

  useEffect(() => {
    if (fetchForm && !fetchForm?.loading && fetchForm?.data) {
      setDisableSubmit(false);
      const findQuestion = fetchForm?.data?.questions?.find(q => q?.question_id === question?.question_id);
      setFormId(fetchForm.data.id);
      setQuestionLogicForm(findQuestion);
    }
  }, [fetchForm]);

  useEffect(() => {
    if (updateFormSubmit && updateFormSubmit.data && !updateFormSubmit.loading) {
      setDisableSubmit(false);
      onClose();
    }
  }, [updateFormSubmit]);

  useEffect(() => {
    if (
      updateQuestionSubmit &&
      updateQuestionSubmit?.data &&
      !updateQuestionSubmit.loading &&
      updateQuestionSubmit?.data?.attributes?.question_options
    ) {
      let options = [];
      updateQuestionSubmit?.data?.attributes?.question_options?.forEach(opt => {
        options.push({ value: opt.name, order: opt.order, optId: opt.id });
      });
      options.sort((a, b) => {
        if (a.order < b.order) return -1;
        else if (a.order > b.order) return 1;
        return 0;
      });

      setLogicOption(updateQuestionSubmit?.data?.attributes?.question_logic?.dropdown_question_option_id);

      setValue(`dropdown_question_options_attributes`, options, {
        shouldValidate: false,
        shouldDirty: false,
        shouldTouch: false,
      });
    }
  }, [updateQuestionSubmit]);

  useEffect(() => {
    // Focus on the newly added dropdown option item
    if (focusId) {
      document.getElementById(focusId)?.focus();
      //clear it out of state
      setFocusId(null);
    }
  }, [focusId]);

  useEffect(() => {
    //Focus on the first element in the editor
    setFocusId('dropdown_label');
  }, []);

  useEffect(() => {
    const values = [];
    fields.forEach(item => {
      values.push({ label: item.value, value: item.optId });
    });
    setSelectOptions(values);
  }, [fields]);

  const onSubmitHandler = data => {
    if (questionTypesInfo && questionTypesInfo.data) {
      const questionType = questionTypesInfo.data.reduce((prev, curr) => {
        return prev || curr.attributes.question_types.find(question => question.identifier === qIdentifier);
      }, undefined);

      const deletedOptions = data?.dropdown_question_options_attributes
        .filter(item => item._destroy && item.optId > 0)
        .map(item => {
          return {
            id: item.optId < 1 ? '' : item.optId,
            order: 0,
            _destroy: true,
          };
        });

      let updateOptions = data?.dropdown_question_options_attributes
        .filter(item => !item._destroy)
        .map((item, index) => {
          return {
            name: item.value,
            order: item.order,
            id: item.optId < 1 ? '' : item.optId,
          };
        });

      const optsToSave = updateOptions.concat(deletedOptions);

      let orderCount = 1;
      optsToSave.forEach(item => {
        item.order = orderCount;
        orderCount++;
      });

      const values = {
        admin_only: false,
        dropdown_question_options_attributes: optsToSave,
        instructions: data.dropdown_instructions,
        label: data.dropdown_label,
        required: data.dropdown_required,
      };

      if (formId && data && (questionType?.id || question?.id)) {
        //update or save new
        const actionToCall = edit ? updateQuestion : updateForm;
        const idToUpdate = edit ? question.id : questionType.id;
        setDisableSubmit(true);
        dispatch(actionToCall(formId, questionType.endpoint, idToUpdate, values));
      }
    }
  };

  const createOption = nextOrder => {
    append({ optId: -nextOrder, value: '', order: nextOrder });
    setFocusId(`dropdown_question_options_attributes[${nextOrder - 1}]`);
  };

  return (
    <FormContainer>
      {!isTravelerInfo && edit && (
        <LogicTab
          isDirty={formState.isDirty}
          onTabChange={value => {
            setShowQuestion(value === 'question');
          }}
        />
      )}

      {showQuestion && (
        <form id="dropdownAdd" onSubmit={handleSubmit(onSubmitHandler)}>
          {isTravelerInfo && (
            <TextInput
              aria-label="Traveler Info Field"
              errorMsg=""
              id="readonly_label"
              label="Traveler Info Field"
              openSans
              placeholder={question?.sourceable_attributes?.display_name}
              readOnly
              disabled
              icon="Sync"
            />
          )}
          <Controller
            control={control}
            name="dropdown_label"
            render={({ field: { ref, ...rest } }) => (
              <InputContainer>
                <TextInput
                  aria-label="Dropdown Label"
                  errorMsg={_get(formState.errors.dropdown_label, 'message') || ''}
                  id="dropdown_label"
                  label={isTravelerInfo ? 'Label' : 'Dropdown Label'}
                  openSans
                  placeholder="Enter Label"
                  required
                  {...rest}
                />
              </InputContainer>
            )}
          />
          {!isTravelerInfo && (
            <>
              <Controller
                control={control}
                name="dropdown_instructions"
                render={({ field: { ref, ...rest } }) => (
                  <InputContainer>
                    <Suspense fallback={<div />}>
                      <EditTextArea
                        aria-label="Instructions"
                        errorMsg={_get(formState.errors.dropdown_instructions, 'message') || ''}
                        placeholder="Enter Instructions"
                        label="Instructions"
                        id="dropdown_instructions"
                        {...rest}
                      />
                    </Suspense>
                  </InputContainer>
                )}
              />

              <DropDownOptionFields
                fields={fields}
                formState={formState}
                control={control}
                setValue={setValue}
                createOption={createOption}
                moveCard={(dragIndex, hoverIndex) => {
                  swap(dragIndex, hoverIndex);
                }}
                logicOption={logicOption}
              />
              
              <OtherOptionContainer
                onClick={() => {
                  createOption(fields.length + 1);
                }}
                onKeyDown={e => {
                  if (e.keyCode === 13) {
                    createOption(fields.length + 1);
                  }
                }}
                tabIndex={0}
              >
                <StyledIcon type="Add" color="#446372" size="16px" />
                Add Option
              </OtherOptionContainer>
            </>
          )}
          {isTravelerInfo && (
            <InfoMsg>
              {`${customAliases.alias_traveler} info fields will be auto-populated 
            from ${customAliases.alias_traveler.toLowerCase()}’s profile 
            if the ${customAliases.alias_traveler.toLowerCase()} has filled 
            out their profile.`}
            </InfoMsg>
          )}
          <Controller
            control={control}
            name="dropdown_required"
            render={({ field: { onChange, register, ref, ...rest } }) => (
              <ToggleContainer>
                <Toggle
                  id="dropdown_required"
                  label="Required"
                  onChange={value => {
                    setValue('dropdown_required', value, {
                      shouldValidate: true,
                      shouldDirty: true,
                      shouldTouch: true,
                    });
                  }}
                  ref={register}
                  {...rest}
                />
                <ToggleLabel>Required Field</ToggleLabel>
              </ToggleContainer>
            )}
          />
          <SubmitContainer>
            <AccentButtonMediumNunito
              aria-label={edit ? 'Save Dropdown Text' : 'Add Dropdown to Form'}
              form="dropdownAdd"
              type="submit"
              disabled={disableSubmit}
            >
              {disableSubmit ? <AccentButtonSpinner displayText="Saving ..." /> : edit ? 'Save' : 'Add to Form'}
              {!disableSubmit && <StyledIcon type="ChevronForward" color="#fff" />}
            </AccentButtonMediumNunito>
          </SubmitContainer>
        </form>
      )}
      {!showQuestion && <LogicForm questionOptions={selectOptions} question={questionLogicForm} />}
    </FormContainer>
  );
}
