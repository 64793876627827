import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import { connect } from 'react-redux';
import { selectMessageTab, fetchTravelerMessages } from '../../../../actions/profileActions';
import { compose } from 'redux';
import { withCookies, Cookies } from 'react-cookie';
import noMessageIcon from './noMessage.svg';
import AssignedAdmins from './AssignedAdmins/AssignedAdmins';
import MessageOptions from './MessageOptions/MessageOptions';
import MessageComponent from './MessageComponent/MessageComponent';

import Loader from '../../../Loader/Loader';

class Messages extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      success: '',
    };

    this.setActiveMessageTab = this.setActiveMessageTab.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const { cookies } = this.props;
    const token = cookies.get('token');

    if (nextProps.messageCreate == 200) {
      this.setState({ success: 'Your message has been sent' });
      this.props.fetchTravelerMessages(this.props.id, this.props.role == 'traveler' ? 'traveler' : 'client');
    }
  }

  setActiveMessageTab(e, tab) {
    this.props.selectMessageTab(tab);
  }

  render() {
    let accent =
      this.props.customBranding && this.props.customBranding.themes
        ? this.props.customBranding.themes.theme_color_accent
        : '#dd2160';
    if (this.props.messagesLoading == true) {
      return <Loader />;
    } else if (this.props.messages == null || this.props.assignedAdmins == null) {
      return (
        <div>
          <div>error</div>
        </div>
      );
    }

    return (
      <div>
        {this.state.success ? (
          <p
            style={{
              fontFamily: 'AvenirNextRegular, sans-serif',
              textAlign: 'center',
              margin: '0',
              marginTop: '1.5rem',
              color: this.props.customBranding.themes.theme_color_light,
            }}
          >
            {this.state.success}
          </p>
        ) : null}
        {!this.props.admin_access || this.props.admin_sign_in ? (
          <>
            {this.props.activeMessageTab === 'messages' && (
              <MessageComponent
                id={this.props.id}
                createMessage={this.props.createMessage}
                messages={this.props.messages}
                newMessage={this.props.newMessage}
                role={this.props.role}
                avatar={this.props.avatar}
                currentUserAvatar={this.props.currentUserAvatar}
                accentColor={accent}
                orgTimezone={this.props.orgTimezone}
              />
            )}

            {this.props.activeMessageTab === 'recipients' && (
              <AssignedAdmins
                id={this.props.id}
                assignedAdmins={this.props.assignedAdmins}
                admins={this.props.admins}
                role={this.props.role}
              />
            )}
            <MessageOptions
              messageCount={this.props.messages.length}
              newMessagesCount={
                this.props.messages.filter(count => count.is_read == false && count.allow_mark_read == true).length
              }
              assignedAdmins={this.props.assignedAdmins.length}
              activeMessageTab={this.props.activeMessageTab}
              accentColor={accent}
              markAllRead={this.props.markAllRead}
            />
          </>
        ) : (
          <div
            style={{
              margin: 'auto',
              padding: '85px',
              width: 'auto',
              height: '435px',
              textAlign: 'center',
              fontFamily: 'Open Sans',
            }}
          >
            <img src={noMessageIcon} alt="No Messages" />
            <div style={{ fontSize: '16px', fontWeight: '600', paddingBottom: '30px', paddingTop: '30px' }}>
              Messaging is disabled from {this.props.customAliases.alias_travelers.toLowerCase()} with administrator
              access.
            </div>
            <div>
              To send a message to a {this.props.customAliases.alias_traveler.toLowerCase()}, please switch to your
              administrator view. To send a message to another administrator, please follow your organization’s
              protocol.
            </div>
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = state => ({
  customBranding: state.profile.customBranding || {},
  orgTimezone: state.profile.orgTimezone,
  admin_access: state.profile.admin_access,
  admin_sign_in: state.profile.admin_sign_in,
  customAliases: state.profile.customAliases,
});

export default compose(
  withCookies,
  connect(mapStateToProps, {
    selectMessageTab,
    fetchTravelerMessages,
  }),
)(Messages);
