export default [
  {
    id: 1,
    label: 'See new places',
    value: 'new_places',
  },
  {
    id: 2,
    label: 'Learn or improve language skills',
    value: 'learn_language',
  },
  {
    id: 3,
    label: 'Become more independent',
    value: 'become_independent',
  },
  {
    id: 4,
    label: 'Have an adventure',
    value: 'have_adventure',
  },
  {
    id: 5,
    label: 'Improve job eligibility',
    value: 'improve_job_prospects',
  },
  {
    id: 6,
    label: 'Study or research',
    value: 'study_or_research',
  },
  {
    id: 7,
    label: 'Learn new skills',
    value: 'learn_new_skills',
  },
  {
    id: 8,
    label: 'Meet new people',
    value: 'meet_people',
  },
  {
    id: 9,
    label: 'Get away from home',
    value: 'leave_home',
  },
  {
    id: 10,
    label: 'Explore my heritage',
    value: 'family_heritage',
  },
  {
    id: 11,
    label: 'See friends',
    value: 'see_friends',
  },
  {
    id: 12,
    label: `I'm not sure`,
    value: 'not_sure',
  },
  {
    id: 13,
    label: 'Other',
    value: 'other',
  },
];
