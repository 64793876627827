import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { AccentButtonMediumNunito } from '../../../styledComponents/styles/Buttons.styled';
import { Paragraph } from '../../../styledComponents/styles/Paragraph.styled';
import { ErrorRoadIcon } from '../../../styledComponents/styles/IconCustom.styled';
import userId from '../../../shared/helpers/userId';
import { getUserDetails } from '../../../actions/profileActions';

const ButtonDiv = styled.div`
  margin-top: 30px;
  margin-right: 22px;
  width: 513px;
`;

const Container = styled.div`
  display: flex;
  width: 550px; 
  gap: 30px; 
`;

const CenterDiv = styled.div``;

const ImageDiv = styled.div`
  margin-left: 30px;
`;

const MainContentContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const ParagraphHeader = styled.h1`
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  font-size: 50px;
  line-height: 120%;
  color: #000000;
`;
const ParagraphSubHeader = styled.h2`
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  font-size: 50px;
  line-height: 120%;
  color: #000000;
`;

const Path = styled(Paragraph)`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  color: #000000;
`;

const Permission = styled(Paragraph)`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: #000000;
`;

const Warning = styled.div`
  max-width: 30%;
`;

export default function EventErrorScreen() {
  const { 'is_traveler?': isTraveler, admin_sign_in: isAdmin } = useSelector(state => state.profile.currentUser);
  const dispatch = useDispatch(); 

  const goBack = e => {
    isTraveler
      ? (window.location.href = '/traveler/dashboard')
      : isAdmin
      ? (window.location.href = '/client/dashboard')
      : (window.location.href = '/');
  };

  useEffect(() => {
    if(userId){
      dispatch(getUserDetails(userId)); 
    }
  }, []); 

  return (
    <>
      <MainContentContainer>
        <CenterDiv>
          <div>
            <ParagraphHeader>Oops...</ParagraphHeader>
            <ParagraphSubHeader>404: Event Not Found</ParagraphSubHeader>
          </div>

          <Container>
            <Warning>
              <Permission>
                It looks like this event no longer exists. Go back to your dashboard to continue on your journey.
              </Permission>
              {/* <Path>Let's get you on the right path.</Path> */}
              <ButtonDiv>
                <AccentButtonMediumNunito
                  style={{
                    color: "#fff"
                  }}
                  onClick={() => {
                    goBack();
                  }}
                  
                >
                  Go to Dashboard
                </AccentButtonMediumNunito>
              </ButtonDiv>
            </Warning>
            <ImageDiv>
              <ErrorRoadIcon size="200px" />
            </ImageDiv>
          </Container>
        </CenterDiv>
      </MainContentContainer>
    </>
  );
}
