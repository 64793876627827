import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { breakpoints } from '../../utils/breakpoints';

export const TextBlock = styled.section`
  background-color: #eeeeee;
  border-radius: 10px;
  padding: 0.875rem 1.375rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;

  h3 {
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 120%;
    letter-spacing: 0.015em;
    margin-bottom: 0;
    color: #666666;

    @media ${breakpoints.tablet} {
      font-size: 0.875rem;
      color: #446372;
    }
  }
`;

export const TextBlockTag = styled.div`
  color: #fff;
  letter-spacing: 0.005em;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 120%;
  padding: 0.15625rem 0.75rem;
  background-color: #446372;
  border-radius: 50px;
  display: inline-block;
  align-self: flex-start;
`;

export const TextBlockRichContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  font-family: 'Open Sans', sans-serif;

  @media ${breakpoints.tablet} {
    gap: 1.25rem;
  }

  p,
  span {
    font-size: 0.875rem;
    line-height: 120%;
    color: #373737;

    @media ${breakpoints.tablet} {
      font-size: 1rem;
    }
  }

  ul,
  ol {
    padding-left: 0.75rem;
    display: flex;
    flex-direction: column;

    @media ${breakpoints.tablet} {
      padding-left: 2rem;
    }
  }

  ul,
  ol,
  li {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-size: 1rem;
    line-height: 120%;
    letter-spacing: 0.005em;
    font-weight: 300;

    font-size: 0.875rem;
    line-height: 120%;
    color: #373737;

    @media ${breakpoints.tablet} {
      font-size: 1rem;
    }
  }
  a {
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 120%;
    letter-spacing: 0.015em;
    text-decoration-line: underline;
    color: #446372;
    border: 0.125rem solid transparent;

    @media ${breakpoints.tablet} {
      font-size: 1rem;
    }

    &:focus {
      outline: none;
    }
    &:focus-visible {
      border: 0.125rem solid #255a94;
      border-radius: 2px;
    }
  }
`;

export const TextBlockContent = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-size: 1rem;
  line-height: 120%;
  letter-spacing: 0.005em;
  margin-bottom: 0;
  font-weight: 300;
`;

export const TextBlockContentDescription = styled.span`
  margin-right: 0.25rem;

  ${props => {
    switch (props.weight) {
      case 'medium':
        return `
          font-weight: 600;
        `;
      default:
        return `
          color: #446372;
          font-weight: 400;
        `;
    }
  }};
`;

export const TextBlockRichContentSectionContainer = styled.div`
  font-family: 'Open Sans', sans-serif;
  p {
    margin-bottom: 0px;
  }
  p,
  span {
    font-size: 0.875rem;
    line-height: 120%;
    color: #373737;
    word-wrap: break-word;

    @media ${breakpoints.tablet} {
      font-size: 1rem;
    }
  }

  ul {
    list-style-type: disc;
  }

  ul,
  ol {
    padding-left: 0.75rem;
    display: flex;
    flex-direction: column;

    @media ${breakpoints.tablet} {
      padding-left: 2rem;
    }
  }

  ul,
  ol,
  li {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-size: 1rem;
    line-height: 120%;
    letter-spacing: 0.005em;
    font-weight: 300;

    font-size: 0.875rem;
    line-height: 120%;
    color: #373737;

    @media ${breakpoints.tablet} {
      font-size: 1rem;
    }
  }
`;

export const TextBlockRichContentSection = ({
  headingText,
  headingWeight,
  headingColor,
  sectionContent,
  plainParagraph,
}) => {
  const updateEditorContent = data => {
    const lines = data ? data.split('\n') : [];
    // Initialize a variable to store the modified data
    let updatedData = '';
    // Iterate through the lines
    for (let i = 0; i < lines.length; i++) {
      const line = lines[i];
      // Check if the line contains a closing </p> tag and no <br /> tag
      if (line.includes('</p>') && !line.includes('<br />')) {
        // Add <br /> tag after the closing </p> tag
        updatedData += line.replace('</p>', '</p><br />') + '\n';
      } else {
        // Otherwise, keep the line as it is
        updatedData += line + '\n';
      }
    }
    return updatedData;
  };

  let updatedSectionContent = !!sectionContent && sectionContent.replace(/<p><\/p>/g, '<br />');
  updatedSectionContent = updateEditorContent(updatedSectionContent);
  return (
    <div tabIndex={0}>
      {headingText && (
        <TextBlockContentDescription weight={headingWeight} style={headingColor ? { color: headingColor } : {}}>
          {headingText}:
        </TextBlockContentDescription>
      )}
      {plainParagraph && <p>{sectionContent}</p>}
      {!plainParagraph && (
        <TextBlockRichContentSectionContainer tabIndex={0} dangerouslySetInnerHTML={{ __html: updatedSectionContent }} />
      )}
    </div>
  );
};

TextBlockRichContentSection.propTypes = {
  headingText: PropTypes.string,
  headingColor: PropTypes.string,
  headingWeight: PropTypes.string,
  plainParagraph: PropTypes.bool,
  sectionContent: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

TextBlockRichContentSection.defaultProps = {
  headingText: '',
  headingColor: null,
  headingWeight: '',
  sectionContent: '',
  plainParagraph: false,
};
