import React from 'react';
import { NavLink } from 'react-router-dom';
import propTypes from 'prop-types';
import { useSelector } from 'react-redux';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import sReportSubHeader from './subHeader.scss';

function ReportSubHeader(props) {
  const customAliases = useSelector(state => state.profile.customAliases);
  const featureList = useSelector(state => state.profile.clientFeatureList);
  const permissions = useSelector(state => state.profile.permissions);

  return (
    <>
      <div className={sReportSubHeader.subheader}>
        {!props.traveler ? (
          <div className={sReportSubHeader['subheader-content']}>
            <div className={sReportSubHeader.item}>
              <NavLink
                className={sReportSubHeader.selection}
                // to={!props.betaLinks ? '/reports/applications' : '/reports/applications-beta'}
                to={'/reports/applications'}
                activeClassName={sReportSubHeader.selected}
              >
                Applications
              </NavLink>
            </div>
            <div className={sReportSubHeader.item}>
              <NavLink
                className={sReportSubHeader.selection}
                // to={!props.betaLinks ? '/reports/forms' : '/reports/forms-beta'}
                to={'/reports/forms'}
                activeClassName={sReportSubHeader.selected}
              >
                General Forms
              </NavLink>
            </div>
            <div className={sReportSubHeader.item}>
              <NavLink
                className={sReportSubHeader.selection}
                // to={!props.betaLinks ? '/reports/programs' : '/reports/programs-beta'}
                to={'/reports/programs'}
                activeClassName={sReportSubHeader.selected}
              >
                {customAliases.alias_programs}
              </NavLink>
            </div>
            <div className={sReportSubHeader.item}>
              <NavLink
                className={sReportSubHeader.selection}
                // to={!props.betaLinks ? '/reports/travelers' : '/reports/travelers-beta'}
                to={'/reports/travelers'}
                activeClassName={sReportSubHeader.selected}
              >
                {customAliases.alias_travelers}
              </NavLink>
            </div>

            {!!featureList.risk_alerts && permissions.risk_alerts_settings.includes('view') && (
              <div className={sReportSubHeader.item}>
                <NavLink
                  className={sReportSubHeader.selection}
                  to={'/reports/risk-alert-history'}
                  activeClassName={sReportSubHeader.selected}
                >
                  {/* {customAliases.alias_travelers.toUpperCase()} */}
                  Risk Alerts
                </NavLink>
              </div>
            )}

            {!!featureList.horizons && (
              <div className={sReportSubHeader.item}>
                <NavLink
                  className={sReportSubHeader.selection}
                  to="/reports/historic-data"
                  activeClassName={sReportSubHeader.selected}
                >
                  Historic Data
                </NavLink>
              </div>
            )}
            {!!featureList.sfsu_historic_data && (
              <div className={sReportSubHeader.item}>
                <NavLink
                  className={sReportSubHeader.selection}
                  to="/reports/sfsu-historic-data"
                  activeClassName={sReportSubHeader.selected}
                >
                  Historic Data
                </NavLink>
              </div>
            )}

            <div className={sReportSubHeader.item}>
              <NavLink
                className={sReportSubHeader.selection}
                to="/reports/downloads"
                activeClassName={sReportSubHeader.selected}
              >
                Downloads
              </NavLink>
            </div>
          </div>
        ) : (
          <div className={sReportSubHeader['subheader-content']} />
        )}
      </div>
    </>
  );
}

ReportSubHeader.defaultProps = {
  betaLinks: false,
};

ReportSubHeader.propTypes = {
  traveler: propTypes.bool.isRequired,
  betaLinks: propTypes.bool,
};

export default withStyles(sReportSubHeader)(ReportSubHeader);
