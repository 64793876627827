import styled from 'styled-components';

const Button = styled.button.attrs(props => ({
  type: props.type || 'button',
}))`
  background-color: ${({ theme }) => theme.button.backgroundColor};
  display: inline-block;
  border: 1px solid ${({ theme }) => theme.button.backgroundColor};
  border-radius: ${({ theme }) => theme.button.borderRadius};
  color: ${({ theme }) => theme.button.fontColor};
  font-family: ${({ theme }) => theme.button.fontFamily};
  font-size: ${({ theme }) => theme.button.fontSize};
  line-height: ${({ theme }) => theme.button.lineHeight};
  padding: ${({ theme }) => theme.button.padding};
  text-align: center;

  svg {
    align-self: center;
    display: inline-flex;
    margin-bottom: 3px;
    margin-right: 5px;
    position: relative;
  }

  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }

  &:active {
    opacity: 0.9;
  }

  &:disabled,
  &:disabled:active {
    opacity: ${props => (props.saving ? '.7' : '0.3')};
    cursor: not-allowed;
  }

  &:focus {
    border: ${({ theme }) => theme.button.focus.focusBorder};
    outline-color: ${({ theme }) => theme.button.focus.outlineColor};
  }
`;

/* Medium Button definitions */
export const ButtonMedium = styled(Button)`
  padding: ${({ theme }) => theme.button.medium.padding};
`;

/* Medium Button Open Sans definitions */
export const ButtonMediumOpenSans = styled(ButtonMedium)`
  font-family: ${({ theme }) => theme.button.fontFamilyOpenSans};
  font-weight: ${({ theme }) => theme.button.fontWeight};
`;

export const InvertedButtonMedium = styled(ButtonMedium)`
  background-color: ${({ theme }) => theme.button.fontColor};
  border: 1px solid ${({ theme }) => theme.button.backgroundColor};
  color: ${({ theme }) => theme.button.backgroundColor};
`;

export const AccentButtonMedium = styled(ButtonMedium)`
  background-color: var(--theme_color_accent, ${({ theme }) => theme.button.backgroundColor});
  border: 1px solid var(--theme_color_accent, ${({ theme }) => theme.button.backgroundColor});
  color: var(--theme_color_accent_font, ${({ theme }) => theme.button.fontColor});
`;

export const MakeAPaymentButton = styled(Button)`
  font-family: ${({ theme }) => theme.button.fontFamilyOpenSans};
  font-weight: ${({ theme }) => theme.button.fontWeight};
  background-color: ${({ theme }) => theme.button.backgroundColorSecondaryDarkest};
  color: ${({ theme }) => theme.button.fontColorWhite};
  font-size: ${({ theme }) => theme.button.large.fontSize};
  border-radius: 5px;
`;

export const ButtonLargeOpenSans = styled(Button)`
  padding: ${({ theme }) => theme.button.large.padding};
  font-family: ${({ theme }) => theme.button.fontFamilyOpenSans};
  font-weight: ${({ theme }) => theme.button.fontWeight};
  background-color: ${({ theme }) => theme.button.backgroundColorSecondaryDarkest};
  color: ${({ theme }) => theme.button.fontColorWhite};
  font-size: ${({ theme }) => theme.button.large.fontSize};
  border-radius: 10px;
`;

/* Accent Medium Button Open Sans definitions */
export const AccentButtonMediumOpenSans = styled(AccentButtonMedium)`
  font-family: ${({ theme }) => theme.button.fontFamilyOpenSans};
  font-weight: ${({ theme }) => theme.button.fontWeight};
`;

export const InvertedAccentButtonMedium = styled(ButtonMedium)`
  background-color: var(--theme_color_accent_font, ${({ theme }) => theme.button.fontColor});
  border: 1px solid var(--theme_color_accent, ${({ theme }) => theme.button.backgroundColor});
  color: var(--theme_color_accent, ${({ theme }) => theme.button.backgroundColor});
`;

/* Small Button definitions */
export const ButtonSmall = styled(Button)`
  padding: ${({ theme }) => theme.button.small.padding};
`;

/* Small Button Open Sans definitions */
export const ButtonSmallOpenSans = styled(ButtonSmall)`
  font-family: ${({ theme }) => theme.button.fontFamilyOpenSans};
  font-weight: ${({ theme }) => theme.button.fontWeight};
`;

export const InvertedButtonSmall = styled(ButtonSmall)`
  background-color: ${({ theme }) => theme.button.fontColor};
  border: 1px solid ${({ theme }) => theme.button.backgroundColor};
  color: ${({ theme }) => theme.button.backgroundColor};
`;

export const AccentButtonSmall = styled(ButtonSmall)`
  background-color: var(--theme_color_accent, ${({ theme }) => theme.button.backgroundColor});
  border: 1px solid var(--theme_color_accent, ${({ theme }) => theme.button.backgroundColor});
  color: var(--theme_color_accent_font, ${({ theme }) => theme.button.fontColor});
`;

export const InvertedAccentButtonSmall = styled(ButtonSmall)`
  background-color: var(--theme_color_accent_font, ${({ theme }) => theme.button.fontColor});
  border: 1px solid var(--theme_color_accent, ${({ theme }) => theme.button.backgroundColor});
  color: var(--theme_color_accent, ${({ theme }) => theme.button.backgroundColor});
`;

//Nunito Button definitions

export const ButtonSmallNunitoDarkGray = styled(ButtonSmall)`
  background-color: ${({ theme }) => theme.button.backgroundColorDarkGrey};
  border: 1px solid ${({ theme }) => theme.button.backgroundColorDarkGrey};
  font-family: ${({ theme }) => theme.button.fontFamilyNunito};
  font-weight: ${({ theme }) => theme.button.fontWeight};
`;

export const ButtonMediumNunito = styled(ButtonMedium)`
  font-family: ${({ theme }) => theme.button.fontFamilyNunito};
  font-weight: ${({ theme }) => theme.button.fontWeight};
  background-color: ${({ theme }) => theme.button.backgroundColorGrey};
`;

export const AccentButtonMediumNunito = styled(AccentButtonMedium)`
  font-family: ${({ theme }) => theme.button.fontFamilyNunito};
  font-weight: ${({ theme }) => theme.button.fontWeight};
  background-color: var(--theme_color_accent, ${({ theme }) => theme.button.backgroundColorAccent});
`;

export const AccentButtonSmallNunito = styled(AccentButtonSmall)`
  font-family: ${({ theme }) => theme.button.fontFamilyNunito};
  font-weight: ${({ theme }) => theme.button.fontWeight};
  background-color: var(--theme_color_accent, ${({ theme }) => theme.button.backgroundColorAccent});
`;
