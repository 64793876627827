import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import styles from './index.css';
import closeIcon from '../../shared/images/close.png';
import CountDownTimer from './CountDownTimer';
import { useDispatch } from 'react-redux';
import { refreshToken } from '../../actions/authActions';

function SessionExpireModal({ countDownSeconds = 60, show, handleClose }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const onClickKeepWorking = () => {
    setLoading(true);
    dispatch(
      refreshToken((status, message) => {
        if (status) {
          setLoading(false);
          handleClose();
          // window.location.reload();
        }
      }),
    );
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header>
        <Modal.Title className={styles.header}>
          <h1 className={styles.heading}>Session will expire soon</h1>
          <button className={styles.closeBtn} type="button" onClick={handleClose}>
            <img src={closeIcon} className={styles.closeIcon} />
          </button>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={styles.container}>
          <p className={styles.text}>You will be automatically logged out for security reasons in:</p>
          <CountDownTimer countDownSeconds={countDownSeconds} handleClose={handleClose} />
          <p className={styles.text}>Would you like to keep working?</p>
        </div>
      </Modal.Body>
      <Modal.Footer className={styles.footer}>
        <button
          type="button"
          className={styles.logoutBtn}
          onClick={() => {
            localStorage.clear();
            window.location.assign(`/clear_cookies`);
          }}
        >
          Log Out
        </button>
        <button type="button" className={styles.primaryBtn} onClick={onClickKeepWorking} disabled={loading}>
          {loading ? 'Loading...' : 'Keep Working'}
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default withStyles(styles)(SessionExpireModal);
