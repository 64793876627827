import React, { useState } from 'react';
import { compose } from 'recompose';
import propTypes from 'prop-types';
import { withStyles as uiStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import TooltipInfo from './Tooltip.styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import StyledIcon from '../styles/Icon.styled';
import { CopyIcon } from './IconCustom.styled';

const InputLabel = styled.label`
  color: ${({ theme }) => theme.copyUrl.label.fontColor};
  font-family: ${({ theme }) => theme.copyUrl.label.fontFamily};
  font-size: ${({ theme }) => theme.copyUrl.label.fontSize};
  font-weight: ${({ labelFontWeight }) => labelFontWeight ? labelFontWeight : "500"};
  align-items: center;
  cursor: pointer;
  display: flex;
  position: relative;
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const InputInnerGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;
`;

const InputField = styled.input`
  border:1px solid var(--Gray-400, #CED4DA);
  border-bottom-left-radius: ${({ theme }) => theme.copyUrl.borderRadius};
  border-top-left-radius: ${({ theme }) => theme.copyUrl.borderRadius};
  color: ${({ theme }) => theme.copyUrl.fontColor};
  font-family: ${({ theme }) => theme.copyUrl.fontFamily};
  font-size: ${({ theme }) => theme.copyUrl.fontSize};
  height: ${({ theme }) => theme.copyUrl.height};
  margin-bottom: 0;
  line-height: ${({ theme }) => theme.copyUrl.lineHeight};
  padding: ${({ theme }) => theme.copyUrl.padding};
  width: ${({ theme }) => theme.copyUrl.width};
  background: var(--Gray-200, #E9ECEF);
  

  &:disabled {
    border: ${({ theme }) => theme.copyUrl.disabled.border};
    background-color: ${({ theme }) => theme.copyUrl.disabled.backgroundColor};
    color: ${({ theme }) => theme.copyUrl.disabled.fontColor};
    cursor: not-allowed;
  }

  &:focus {
    outline-color: ${({ theme }) => theme.copyUrl.focus.outlineColor};
  }

  &::placeholder {
    color: ${({ theme }) => theme.copyUrl.placeholder.fontColor};
    font-family: ${({ theme }) => theme.copyUrl.placeholder.fontFamily};
    font-size: ${({ theme }) => theme.copyUrl.placeholder.fontSize};
    font-style: ${({ theme }) => theme.copyUrl.placeholder.fontStyle};
  }
`;

const ButtonDiv = styled.div`
  width: 50px;
`;

const LinkButton = styled.button.attrs(props => ({
  type: props.type || 'button',
  showIcon: props.showIcon  || false,
}))`
  //background-color: var(--theme_color_dark, ${({ theme,showIcon }) => showIcon ? "#E9ECEF" : theme.copyUrl.button.backgroundColor});
  background-color: ${({ showIcon, theme }) => showIcon ? "var(--Gray-200, #E9ECEF)" : `var(--theme_color_dark, ${theme.copyUrl.button.backgroundColor})`};
  display: inline-block;
  //border: 1px solid var(--theme_color_dark, ${({ theme }) => theme.copyUrl.button.backgroundColor});
  border: ${({ showIcon, theme }) => showIcon ? "1px solid var(--Gray-400, #CED4DA)" : `1px solid var(--theme_color_dark, ${theme.copyUrl.button.backgroundColor})`};
  border-bottom-right-radius: ${({ theme }) => theme.copyUrl.button.borderRadius};
  border-top-right-radius: ${({ theme }) => theme.copyUrl.button.borderRadius};
  color: var(--theme_color_accent_font, ${({ theme }) => theme.copyUrl.button.fontColor});
  font-family: ${({ theme }) => theme.copyUrl.button.fontFamily};
  font-weight: 400;
  font-size: ${({ theme }) => theme.copyUrl.button.fontSize};
  line-height: ${({ theme }) => theme.copyUrl.button.lineHeight};
  padding: ${({ theme }) => theme.copyUrl.button.padding};
  text-align: center;

  svg {
    align-self: center;
    display: inline-flex;
    margin-right: 5px;
    position: relative;
  }

  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }

  &:active {
    opacity: 0.9;
  }

  &:focus {
    border: ${({ theme }) => theme.copyUrl.button.focus.focusBorder};
    outline-color: ${({ theme }) => theme.copyUrl.button.focus.outlineColor};
  }
`;

const Required = styled.span`
  color: ${({ theme }) => theme.defaults.errorColor};
  padding-left: 5px;
`;

const TooltipContainer = styled.span`
  padding-left: 5px;
`;

export const enhance = compose();

function CopyOrOpenUrlTextComponent({
  buttonText = 'Open',
  linkUrl = '',
  label,
  id,
  copyOnly = false,
  tooltip,
  required,
  labelFontWeight,
  showIcon= false
}) {
  const [open, setOpen] = useState(false);
  let copyInput = null;

  const OpenTooltip = uiStyles(theme => ({
    tooltip: {
      backgroundColor: '#ffffff',
      color: '#00485d',
      boxShadow: theme.shadows[1],
      fontSize: 12,
      fontFamily: 'AvenirNextRegular, sans-serif',
      marginTop: '5px',
    },
  }))(Tooltip);

  const CopyTooltip = uiStyles(theme => ({
    tooltip: {
      backgroundColor: '#ffffff',
      color: '#00485d',
      boxShadow: theme.shadows[1],
      fontSize: 12,
      fontFamily: 'AvenirNextRegular, sans-serif',
      marginBottom: '5px',
    },
  }))(Tooltip);

  const openLink = () => {
    window.open(linkUrl, '_blank');
  };

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    copyInput.select();
    document.execCommand('copy');
    setOpen(true);
  };

  return (
    <InputGroup>
      <InputInnerGroup>
        <div>
          <InputLabel labelFontWeight={labelFontWeight} htmlFor={id}>
            {label}
            {required && <Required>*</Required>}
            {tooltip && (
              <TooltipContainer>
                <TooltipInfo toolTipText={tooltip}>
                  <StyledIcon type={'InformationCircle'} size={'14px'} />
                </TooltipInfo>
              </TooltipContainer>
            )}
          </InputLabel>
        </div>
        <ButtonContainer>
          <CopyTooltip
            onClose={handleTooltipClose}
            open={open}
            title={
              <div>
                <FontAwesomeIcon icon="check" color="#219653" />
                &nbsp;&nbsp; Copied to Clipboard
              </div>
            }
            placement="top-end"
          >
            <InputField
              id={id}
              name={id}
              aria-label="Copy to clipboard"
              value={linkUrl}
              type="text"
              // disabled={true}
              readOnly
              onClick={() => handleTooltipOpen()}
              ref={ref => (copyInput = ref)}
            />
          </CopyTooltip>

          <OpenTooltip title={copyOnly ? 'Click to Copy' : 'Click to Open in New Window'} placement="bottom-start">
            <ButtonDiv>
              <LinkButton showIcon={showIcon} onClick={() => (copyOnly ? handleTooltipOpen() : openLink())}>
                {showIcon ? 
                    <CopyIcon size="16px" />
                  : `${buttonText}`
                }
                
              </LinkButton>
            </ButtonDiv>
          </OpenTooltip>
        </ButtonContainer>
      </InputInnerGroup>
    </InputGroup>
  );
}

CopyOrOpenUrlTextComponent.propTypes = {
  linkUrl: propTypes.string.isRequired,
};

CopyOrOpenUrlTextComponent.defaultProps = {};

export default enhance(CopyOrOpenUrlTextComponent);
