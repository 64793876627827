exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3Q2gk{color:#a94442;background-color:#f2dede;border-color:#ebccd1}._1dlMl{display:block;padding:15px;margin-bottom:20px;margin-top:20px;border:1px solid transparent;border-radius:4px}._1AFOj{position:absolute;color:inherit;float:right;cursor:pointer;top:100px;right:23px}._3VgDo{color:#3c763d;background-color:#dff0d8;border-color:#d6e9c6}._3aPmb{color:#856404;background-color:#fff3cd;border-color:#ffeeba}", ""]);

// exports
exports.locals = {
	"danger": "_3Q2gk",
	"alert": "_1dlMl",
	"settingsAction": "_1AFOj",
	"success": "_3VgDo",
	"warning": "_3aPmb"
};