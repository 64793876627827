import React from 'react';

export default function EllipseIcon({color, colorOuterCircle, width, height}) {
  return (
    <svg width={width ? width : "11"} height={height ? height : "11"} viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="5.01172" cy="5.0191" r="4.5" fill={color? color : "#DDAF64"} stroke={colorOuterCircle ? colorOuterCircle : "#DDAF64"}/>
    </svg>

  );
}
