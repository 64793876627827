import fetch from 'node-fetch';
import { keys } from '../config/keys';

import {
  FETCH_PROGRAMS,
  FETCH_UNAUTHORIZED_PROGRAMS,
  FETCH_PROVIDERS,
  AUTHORIZE_PROGRAMS,
  FILTER_PROGRAM_LOCATION,
  FILTER_PROGRAM_CITIES,
  FILTER_PROGRAM_LANGUAGES,
  FILTER_PROGRAM_SUBJECTS,
  FILTER_LANGUAGE_IMMERSION,
  FILTER_PROGRAM_LENGTH,
  FILTER_PROGRAM_CONTACTS,
  FILTER_PROGRAM_PROVIDERS,
  FILTER_PROGRAM_SUPPORT_LEVEL,
  FILTER_PROGRAM_TYPES,
  FILTER_HOUSING_TYPES,
  FILTER_FAVORITES,
  FILTER_PUBLISH_STATUS,
  FILTER_AUTHORIZATION_STATUS,
  FILTER_ELIGIBILITY,
  FILTER_START_DATE,
  FILTER_END_DATE,
  FILTER_TERM_NAME,
  BATCH_PUBLISH_PROGRAM,
  BATCH_UNPUBLISH_PROGRAM,
  CLEAR_PROGRAM_FILTERS,
  TOGGLE_FAVORITE,
  SEARCH_PROGRAMS,
  SELECT_PROGRAM,
  UNSELECT_PROGRAM,
  ADD_PROGRAM_RANGES,
  ADD_PROGRAM_ADMINS,
  DEAUTHORIZE_PROGRAMS,
  BATCH_ADD_TAGS_SUCCESS,
  BATCH_ADD_TAGS_FAILURE,
  PROGRAM_DASHBOARD_LOADING,
  CLEAR_PROGRAM_DASHBOARD_FLASHES,
  FILTER_SUPPORT_LEVEL_PROGRAMS,
  CLEAR_DRAFT_STATUSES,
  CLEAR_SELECTED_PROGRAMS,
  PROGRAM_LOADING,
  CLEAR_PUBLISHED_ID,
  FAVORITE_DISCLAIMER_ACCEPTED,
  CHECK_USER_FAVORITE_DISCLAIMER,
  GET_PROGRAMS,
} from './types';
import { getUserDetails } from './profileActions';
import { getMissingNumbers } from '../helpers/CommonHelper';
import { Cookies } from 'react-cookie';

export function fetchTravelerPrograms(id, block) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  if (!id) {
    window.location.assign(`/`);
  }
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/traveler/programs/${id}/programs?block=${block}`, {
      headers: token,
    })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: FETCH_PROGRAMS,
          payload: data,
        }),
      );
  };
}

export function fetchClientPrograms(id) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/programs/with_associations`, {
      headers: token,
    })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: FETCH_PROGRAMS,
          payload: data,
        }),
      );
  };
}

export function fetchIncrementalClientPrograms(block) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/programs/with_associations?block=${block}`, {
      headers: token,
    })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: FETCH_PROGRAMS,
          payload: data,
        }),
      );
  };
}

export function fetchUnauthorizedPrograms(id, provider) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/programs/${id}/unauthorized_programs?org_name=${provider}`, { headers: token })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: FETCH_UNAUTHORIZED_PROGRAMS,
          payload: data,
        }),
      );
  };
}

export function fetchProviders(id) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/programs/${id}/providers`, {
      headers: token,
    })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: FETCH_PROVIDERS,
          payload: data,
        }),
      );
  };
}

export function authorizePrograms(id, programs) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    return fetch(`${keys.baseUri}/api/client/programs/${id}/authorize_programs`, {
      method: 'POST',
      body: JSON.stringify({ program_ids: programs }),
      headers: token,
    })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: AUTHORIZE_PROGRAMS,
          payload: data,
        }),
      );
  };
}

export function toggleProgramType(active, programType) {
  if (active) {
    return {
      type: TOGGLE_ON_PROGRAM_TYPE,
      programType,
    };
  }
  return {
    type: TOGGLE_OFF_PROGRAM_TYPE,
    programType,
  };
}

export function toggleHousingType(active, housingType) {
  if (active) {
    return {
      type: TOGGLE_ON_HOUSING_TYPE,
      housingType,
    };
  }
  return {
    type: TOGGLE_OFF_HOUSING_TYPE,
    housingType,
  };
}

export function filterProgramLocation(programLocations) {
  return {
    type: FILTER_PROGRAM_LOCATION,
    programLocations,
  };
}

export function filterProgramCities(programCities) {
  return {
    type: FILTER_PROGRAM_CITIES,
    programCities,
  };
}

export function filterProgramLanguages(programLanguages) {
  return {
    type: FILTER_PROGRAM_LANGUAGES,
    programLanguages,
  };
}

export function filterProgramSubjects(programSubjects) {
  return {
    type: FILTER_PROGRAM_SUBJECTS,
    programSubjects,
  };
}

export function filterLanguageImmersion(value) {
  return {
    type: FILTER_LANGUAGE_IMMERSION,
    value,
  };
}

export function filterProgramLength(min, max) {
  return {
    type: FILTER_PROGRAM_LENGTH,
    programLength: [min, max],
  };
}

export function filterProgramContacts(programContacts) {
  return {
    type: FILTER_PROGRAM_CONTACTS,
    programContacts,
  };
}

export function filterProgramProviders(programProviders) {
  return {
    type: FILTER_PROGRAM_PROVIDERS,
    programProviders,
  };
}

export function filterProgramTypes(programTypes) {
  return {
    type: FILTER_PROGRAM_TYPES,
    programTypes,
  };
}

export function filterHousingTypes(housingTypes) {
  return {
    type: FILTER_HOUSING_TYPES,
    housingTypes,
  };
}

export function filterTermName(programTermNames) {
  return {
    type: FILTER_TERM_NAME,
    programTermNames,
  };
}

export function clearProgramFilters() {
  return {
    type: CLEAR_PROGRAM_FILTERS,
  };
}

export function toggleFavorite(userId, id, status, isFavDisAccepted) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  if (status) {
    return function(dispatch) {
      fetch(`${keys.baseUri}/api/traveler/programs/${userId}/favorites/${id}/create_favorite`, {
        method: 'POST',
        headers: token,
      })
        .then(res => res.json())
        .then(data => {
          dispatch({
            type: TOGGLE_FAVORITE,
            payload: data,
          });
          if (isFavDisAccepted) {
            dispatch(addIntakeTravelerInfoUpdateFavourite(userId));
          }
          setTimeout(() => {
            dispatch(fetchUpdatedTravelerPrograms());
          }, 800);
        });
    };
  }
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/traveler/programs/${userId}/favorites/${id}/delete_favorite`, {
      method: 'DELETE',
      headers: token,
    })
      .then(res => res.json())
      .then(
        data =>
          dispatch({
            type: TOGGLE_FAVORITE,
            payload: data,
          }),
        setTimeout(() => {
          dispatch(fetchUpdatedTravelerPrograms());
        }, 800),
      );
  };
}

export function addIntakeTravelerInfoUpdateFavourite(id) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/traveler/intake_wizard/${id}/update_traveler_info`, {
      method: 'PUT',
      body: JSON.stringify({ traveler_info: { program_favorite_disclaimer_accepted: true } }),
      headers: token,
    })
      .then(res => res.json())
      .then(res => {
        if (res[0].status == 200) {
          dispatch({
            type: FAVORITE_DISCLAIMER_ACCEPTED,
            payload: true,
          });

          dispatch(getUserDetails(id));
        } else {
          dispatch({
            type: FAVORITE_DISCLAIMER_ACCEPTED,
            payload: false,
          });
        }
      });
  };
}

export function fetchUpdatedTravelerPrograms() {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return async function(dispatch) {
    try {
      const response = await fetch(`${keys.baseUri}/api/programs`, {
        method: 'GET',
        headers: token,
      });

      const { data } = await response.json();

      dispatch({
        type: GET_PROGRAMS,
        payload: {
          data,
          error: false,
        },
      });
    } catch (error) {
      dispatch({
        type: GET_PROGRAMS,
        payload: {
          data: [],
          error: true,
        },
      });
    }
  };
}

export function searchPrograms(query) {
  return {
    type: SEARCH_PROGRAMS,
    query,
  };
}

export function filterFavorites(value) {
  return {
    type: FILTER_FAVORITES,
    value,
  };
}

export function filterPublishStatus(value) {
  return {
    type: FILTER_PUBLISH_STATUS,
    value,
  };
}

export function filterEligibility(value) {
  return {
    type: FILTER_ELIGIBILITY,
    value,
  };
}

export function filterAuthorizationStatus(value) {
  return {
    type: FILTER_AUTHORIZATION_STATUS,
    value,
  };
}

export function filterStartDate(startDate, endDate) {
  return {
    type: FILTER_START_DATE,
    value: [startDate, endDate],
  };
}

export function filterEndDate(startDate, endDate) {
  return {
    type: FILTER_END_DATE,
    value: [startDate, endDate],
  };
}

export function publishPrograms(userId, programIds) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/programs/${userId}/publish_programs`, {
      method: 'POST',
      body: JSON.stringify({ programs: programIds }),
      headers: token,
    })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: BATCH_PUBLISH_PROGRAM,
          code: data.status,
        }),
      );
  };
}

export function publishProgramsLoudly(programIds) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({ type: PROGRAM_LOADING });
    fetch(`${keys.baseUri}/api/client/programs/publish_programs_loudly`, {
      method: 'POST',
      body: JSON.stringify({ programs: programIds }),
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({ type: CLEAR_SELECTED_PROGRAMS });
        dispatch({
          type: BATCH_PUBLISH_PROGRAM,
          code: data.code,
          draftErrors: data.errors,
          published_id: data.published_id,
          message: data.message,
        });
      });
  };
}

export function archiveProgramsLoudly(programIds) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/programs/archive_programs`, {
      method: 'POST',
      body: JSON.stringify({ programs: programIds }),
      headers: token,
    }).then(data =>
      dispatch({
        type: BATCH_PUBLISH_PROGRAM,
        code: 200,
      }),
    );
  };
}

export function destroyDraftPrograms(programIds) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({ type: PROGRAM_LOADING });
    fetch(`${keys.baseUri}/api/client/programs/destroy_programs`, {
      method: 'DELETE',
      body: JSON.stringify({ programs: programIds }),
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: BATCH_PUBLISH_PROGRAM,
          code: data.code,
          message: data.message,
        });
        window.location.assign(`/client/programs`);
      });
  };
}

export function unpublishPrograms(userId, programIds) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/programs/${userId}/unpublish_programs`, {
      method: 'POST',
      body: JSON.stringify({ programs: programIds }),
      headers: token,
    })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: BATCH_UNPUBLISH_PROGRAM,
          code: data.status,
        }),
      );
  };
}

export function toggleProgram(programId, status) {
  if (status) {
    return {
      type: SELECT_PROGRAM,
      programId,
    };
  }
  return {
    type: UNSELECT_PROGRAM,
    programId,
  };
}

export function addProgramRanges(userId, programIds, range) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/programs/${userId}/add_program_ranges`, {
      method: 'POST',
      body: JSON.stringify({
        programs: programIds,
        start_date: range.startDate,
        end_date: range.endDate,
        weeks: range.weeks,
        use_exact_dates: range.terms,
      }),
      headers: token,
    })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: ADD_PROGRAM_RANGES,
          code: data.status,
        }),
      );
  };
}

export function addProgramAdmins(userId, programIds, type, admin) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/programs/${userId}/add_program_admins`, {
      method: 'POST',
      body: JSON.stringify({
        programs: programIds,
        user_id: admin,
        type,
      }),
      headers: token,
    })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: ADD_PROGRAM_ADMINS,
          code: data.status,
        }),
      );
  };
}

export function deauthorizePrograms(id, programs, callback = null) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return async function(dispatch) {
    try {
      let response = await fetch(`${keys.baseUri}/api/client/programs/${id}/deauthorize_programs`, {
        method: 'PUT',
        body: JSON.stringify({ program_ids: programs }),
        headers: token,
      });

      if (response.status === 200) {
        let data = await response.json();

        let missingNumbers = getMissingNumbers(programs, data.program_ids);

        if (missingNumbers.length > 0) {
          // throw new Error(`Program ids ${missingNumbers.join(', ')} are internal programs`);
          throw new Error('Internal programs cannot be deauthorize.');
        } else {
          if (callback && typeof callback === 'function') {
            callback(true, 'Program deauthorized.');
          }

          dispatch({
            type: DEAUTHORIZE_PROGRAMS,
            payload: data,
          });
        }
      } else {
        throw new Error('Something went wrong, please try again later.');
      }
    } catch (e) {
      if (callback && typeof callback === 'function') {
        callback(false, e.message);
      }
    }
  };
}

export const batchAddTags = data => dispatch => {
  const cookies = new Cookies();
  const token = cookies.get('token');
  dispatch(programDashboardLoading());
  fetch(`${keys.baseUri}/api/client/tags/batch_add_tags`, {
    method: 'PUT',
    headers: token,
    body: JSON.stringify(data),
  })
    .then(response => response.json())
    .then(data => {
      if (data.status === 200) {
        dispatch({ type: CLEAR_SELECTED_PROGRAMS });
        dispatch({
          type: BATCH_ADD_TAGS_SUCCESS,
          success: data.success,
        });
      } else {
        dispatch({
          type: BATCH_ADD_TAGS_FAILURE,
          error: data.error,
        });
      }
    });
};

export const programDashboardLoading = () => dispatch => {
  dispatch({ type: PROGRAM_DASHBOARD_LOADING });
};

export const clearProgramDashboardFlashes = () => dispatch => {
  dispatch({ type: CLEAR_PROGRAM_DASHBOARD_FLASHES });
};
export const filterSupportLevelPrograms = programSupportLevel => dispatch => {
  dispatch({
    type: FILTER_SUPPORT_LEVEL_PROGRAMS,
    payload: programSupportLevel,
  });
};

export const clearDraftStauses = () => dispatch => {
  dispatch({
    type: CLEAR_DRAFT_STATUSES,
  });
};

export const clearSelectedPrograms = () => dispatch => {
  dispatch({
    type: CLEAR_SELECTED_PROGRAMS,
  });
};

export function clearPublishedId() {
  return function(dispatch) {
    dispatch({
      type: CLEAR_PUBLISHED_ID,
    });
  };
}
