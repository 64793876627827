import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import DocumentStatus from './DocumentStatus';
import DocumentDeadline from './DocumentDeadline';
import FormStatus from '../my-programs/details/FormStatus';
import DocumentDetailsButtons from './DocumentDetailsButtons';
import DocumentDetailsBreadcrumbs from './DocumentDetailsBreadcrumbs';
import ApplicationStatus from '../my-programs/details/ApplicationStatus';
import ProgramDetailsTerms from '../my-programs/details/ProgramDetailsTerms';

import { breakpoints } from '../../utils/breakpoints';

const MobileContainer = styled.div`
  @media ${breakpoints.desktop} {
    display: none;
  }
`;

const DesktopContainer = styled.div`
  display: none;

  @media ${breakpoints.desktop} {
    display: block;
  }
`;

const Header = styled.header`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  padding: 1.1875rem 1.625rem 0.9375rem 1.5rem;
  border-bottom: 4px solid var(--theme_color_accent);
  border-radius: 15px 15px 0px 0px;

  @media ${breakpoints.desktop} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 0;
    padding: 1rem 4.3125rem 0.875rem 3.375rem;
  }
`;

const ProgramInfoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.875rem;
  width: 100%;

  @media ${breakpoints.desktop} {
    width: auto;
  }
`;

const ProgramImage = styled.img`
  width: 82px;
  height: 82px;
  border-radius: 50px;
  object-fit: cover;
  object-position: center;
`;

const ProgramInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.375rem;
  flex-grow: 1;

  .program-details-terms {
    align-items: flex-start;
    gap: 0.543125rem;
    font-size: 0.875rem;

    svg {
      flex-shrink: 0;
      width: 16px;
      height: 16px;
    }
  }

  @media ${breakpoints.desktop} {
    gap: 0.25rem;
    flex-grow: 0;

    .program-details-terms {
      align-items: center;
    }
  }
`;

const Heading = styled.h2`
  width: fit-content;
  margin-bottom: 0;
  font-family: 'Nunito', sans-serif;
  font-weight: 400;
  font-size: 1.5625rem;
  color: #373737;
  line-height: 120%;

  @media ${breakpoints.desktop} {
    font-size: 1.125rem;
    font-weight: 600;
    letter-spacing: 0.001em;

    &::after {
      content: '';
      display: block;
      width: 80%;
      padding-top: 0.1875rem;
      border-bottom: 1px solid var(--theme_color_accent);
    }
  }
`;

const ProgramName = styled.h3`
  margin-bottom: 0;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  color: #373737;
  letter-spacing: 0.0025em;
  line-height: 120%;
`;

export default function DocumentDetailsHeader({ type, onNext, onSave }) {
  const {
    formSubmission,
    applicationSubmission: { attributes: submission },
  } = useSelector(state => state.travelerApplicationSubmissions);

  const StatusComponent = type === 'application' ? ApplicationStatus : FormStatus;
  const headingText = type === 'application' ? 'Application' : formSubmission?.attributes?.template?.name;
  const statusComponentProps =
    type === 'application' ? {} : { status: formSubmission?.attributes?.submission_status?.name };

  return (
    <>
      <MobileContainer>
        <Header>
          <DocumentDetailsBreadcrumbs forForm={type === 'form'} />
          <ProgramInfoContainer>
            <ProgramInfo>
              <Heading>{headingText}</Heading>
              <ProgramDetailsTerms />
              <StatusComponent {...statusComponentProps} />
            </ProgramInfo>
            <ProgramImage src={submission.program_range.program_image.url} alt="Program Image" aria-hidden="true" />
          </ProgramInfoContainer>
        </Header>
      </MobileContainer>
      <DesktopContainer>
        <Header>
          <ProgramInfoContainer>
            <ProgramImage src={submission.program_range.program_image.url} alt="Program Image" aria-hidden="true" />
            <ProgramInfo>
              <Heading>{headingText}</Heading>
              <ProgramName>{submission.program_range.program_title}</ProgramName>
              <ProgramDetailsTerms />
              <DocumentDeadline forForm={type === 'form'} />
            </ProgramInfo>
          </ProgramInfoContainer>
          <DocumentStatus forForm={type === 'form'} />
          <DocumentDetailsButtons onNext={onNext} onSave={onSave} />
        </Header>
      </DesktopContainer>
    </>
  );
}

DocumentDetailsHeader.propTypes = {
  onNext: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['application', 'form']).isRequired,
};
