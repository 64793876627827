/* eslint-disable */
import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import moment from 'moment-timezone';
import s from './TravelerHero.css';

class TravelerHero extends React.Component {
  constructor(props) {
    super(props);

    this.travelerName = this.travelerName.bind(this);
  }

  renderHeaderByPreferences(preferred_name, name) {
    return preferred_name ? preferred_name : name;
  }

  travelerName(profile, header) {
    let firstName = profile.info.preferred_first_name;
    firstName = firstName === '' ? header.FirstName : firstName;

    let lastName = profile.info.preferred_last_name;
    lastName = lastName === '' ? header.LastName : lastName;

    let fullName = `${firstName} ${lastName}`;
    fullName = fullName.trim() === '' ? header.Email : fullName;

    return fullName;
  }

  render() {
    const header = this.props.header[0];
    const profile = this.props.profile[0];
    const name = this.travelerName(profile, header);
    const statuses = header.ChevronStatuses[0];
    const avatarURL = header.Avatar;

    return (
      <div className={s.firstBorder}>
        <div className={s.secondBorder}>
          <h2 className={s.hiddenName}>{name}</h2>

          <section className={s.profile}>
            <div className={s.avatar}>
              {header.Avatar && <img src={avatarURL} className={s.profileImg} alt="User avatar" />}
            </div>
            <p className={s.lastSignIn}>
              Last Login:{' '}
              {header.LastLogin
                ? this.props.orgTimezone
                  ? moment(new Date(header.LastLogin))
                      .tz(this.props.orgTimezone)
                      .format('MMMM D, YYYY')
                  : moment(new Date(header.LastLogin)).format('MMMM D, YYYY')
                : '--'}
            </p>
          </section>
          <section className={s.personalInfo}>
            <h2 className={s.travelerName}>{name}</h2>
            {this.props.clientFeatureList.legacy ? (
              <div className={s.travelerInfo}>
                <div className={s.personalColumn}>
                  <h3 className={s.infoHeader}>
                    <span className={s.dividerLine}>Events</span>
                  </h3>

                  <ul className={s.personalInfoList}>
                    <li>
                      <p className={s.infoItemHeader}>{`${header.Events.attended_total} - Attended`}</p>
                    </li>
                    <li>
                      <p className={s.infoItemHeader}>{`${header.Events.registered_total} - Registered`}</p>
                    </li>
                  </ul>
                </div>

                <div className={s.personalColumn}>
                  <h3 className={s.infoHeader}>
                    <span className={s.dividerLine}>COUNTDOWN</span>
                  </h3>
                </div>
              </div>
            ) : null}
          </section>
        </div>
      </div>
    );
  }
}

export default withStyles(s)(TravelerHero);
