exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1iEG9{padding:0}._3dPV4{padding-bottom:300px}._1hrvO{padding-top:20px;margin:0;color:#76ccce}._1hrvO,.ajHH0{font-family:AvenirNextRegular,sans-serif;text-align:center}.ajHH0{font-size:24px;font-style:normal;font-stretch:normal;line-height:1.4;letter-spacing:normal;color:#fff;margin:30px}", ""]);

// exports
exports.locals = {
	"noPadding": "_1iEG9",
	"content": "_3dPV4",
	"successMessage": "_1hrvO",
	"inactiveConfirmationText": "ajHH0"
};