import { useState, useEffect } from 'react';

const useMediaQuery = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);
  const [isTablet, setIsTablet] = useState(false);

  useEffect(() => {
    const mobileQuery = window.matchMedia('(max-width: 767px)');
    const desktopQuery = window.matchMedia('(min-width: 1025px)');
    const tabletQuery = window.matchMedia('(min-width: 768px) and (max-width: 1024px)');

    const updateMediaQuery = () => {
      setIsMobile(mobileQuery.matches);
      setIsDesktop(desktopQuery.matches);
      setIsTablet(tabletQuery.matches);
    };

    updateMediaQuery();

    const handleResize = () => {
      updateMediaQuery();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return { isMobile, isDesktop, isTablet };
};

export default useMediaQuery;
