import styled from 'styled-components';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ProfileFormContainer from '../base/ProfileFormContainer';
import NameAndEmailForm from '../forms/account/NameAndEmailForm';
import ProfileFormSubheading from '../base/ProfileFormSubheading';
import ProfilePictureForm from '../forms/account/ProfilePictureForm';
import ToastMessage from '../../../viaGlobal/components/toastMessage';
import { ProfileFormButton, ProfileFormButtonContainer } from '../base/ProfileFormButton.styled';

import userId from '../../../../shared/helpers/userId';
import { updateTravelerInfo } from '../../actions/travelerProfileActions';

const ScreenReaderOnlyLegend = styled.legend`
  position: absolute;
  width: 0.0625rem;
  height: 0.0625rem;
  padding: 0;
  margin: -0.0625rem;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
`;

const FormContainer = styled.div`
  margin-bottom: 2.28875rem;
`;

export default function AccountTab() {
  const dispatch = useDispatch();
  const { readonly } = useSelector(state => state.travelerProfile);

  const [showToast, setShowToast] = useState(false);

  const [profileForm, setProfileForm] = useState({
    last_name: '',
    first_name: '',
  });

  const [avatarForm, setAvatarForm] = useState({
    url: '',
  });

  const handleSubmit = event => {
    event.preventDefault();

    const data = new FormData();

    if (!readonly.includes('first_name')) {
      data.append('profile[first_name]', profileForm.first_name);
    }

    if (!readonly.includes('last_name')) {
      data.append('profile[last_name]', profileForm.last_name);
    }

    data.append('profile[avatar]', avatarForm.url);

    dispatch(updateTravelerInfo(userId, data));

    setShowToast(true);
  };

  return (
    <form method="POST" encType="multipart/form-data" onSubmit={handleSubmit}>
      <ProfileFormContainer heading="My Account">
        <FormContainer>
          <fieldset>
            <ScreenReaderOnlyLegend>Name and Email</ScreenReaderOnlyLegend>
            <NameAndEmailForm profileForm={profileForm} setProfileForm={setProfileForm} />
          </fieldset>
          <fieldset>
            <ScreenReaderOnlyLegend>Upload Profile Picture</ScreenReaderOnlyLegend>
            <ProfileFormSubheading text="Profile Picture" />
            <ProfilePictureForm avatarForm={avatarForm} setAvatarForm={setAvatarForm} />
          </fieldset>
        </FormContainer>
        <ProfileFormButtonContainer>
          <ProfileFormButton type="submit">Save</ProfileFormButton>
        </ProfileFormButtonContainer>
        {showToast && (
          <ToastMessage
            message="All changes saved"
            isSuccess
            show={showToast}
            onClose={() => {
              setShowToast(false);
            }}
          />
        )}
      </ProfileFormContainer>
    </form>
  );
}
