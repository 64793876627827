import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import { connect } from 'react-redux';
import { createTravelerNote } from '../../../../../actions/profileActions';
import { compose } from 'redux';
import { withCookies, Cookies } from 'react-cookie';

import s from './CreateNote.css';

class CreateNote extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subject: null,
      body: null,
      attachments: [],
      errors: {},
      submitReady: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.extendInput = this.extendInput.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.noteCreate == 201) {
      this.setState({ subject: '', body: '<p> </p>', attachments: [], reset: true });
    }
  }

  handleInputChange(event) {
    const value = event.target.value;
    const name = event.target.name;

    if (name == 'subject') {
      if (value.length > 0) {
        this.setState({ submitReady: true }, () => {
          this.setState({ errors: {} });
        });
      } else {
        this.setState({ submitReady: false });
      }
    } else if (value.length > 0) {
      this.setState({ submitReady: true }, () => {
        this.setState({ errors: {} });
      });
    } else {
      this.setState({ submitReady: false });
    }

    this.setState({
      [name]: value,
    });
  }

  extendInput(e) {
    const height = document.querySelector('textarea').scrollHeight;
    e.target.style.height = `${height}px`;
  }

  handleSubmit(event) {
    event.preventDefault();

    const subject = this.state.subject;
    const body = this.state.body;
    if (subject == null || subject == '') {
      this.setState({ errors: { subject: 'Please enter a subject' } });
    } else if (body == null || body == '') {
      this.setState({ errors: { body: 'Please enter a body' } });
    } else {
      this.props.createTravelerNote(this.props.id, {
        subject: this.state.subject,
        body: this.state.body,
        attachments: this.state.attachments,
        attachmentCount: this.state.attachments.length,
      });
      this.setState({ subject: '', body: '', attachments: [] });
    }
  }

  render() {
    const avatar = this.props.avatar;
    return (
      <div className={s.submitContainer}>
        <div className={s.container}>
          <img
            src={avatar}
            className={s.avatar}
            alt="User avatar"
          />
          <form className={s.inputContainer} onSubmit={this.handleSubmit}>
            <input
              className={s.subjectInput}
              placeholder="Subject Line"
              name="subject"
              onChange={this.handleInputChange}
              value={this.state.subject}
            />
            <div className={s.bodyInputContainer}>
              <textarea
                className={s.bodyInput}
                placeholder="New Note..."
                name="body"
                onChange={this.handleInputChange}
                value={this.state.body}
                onInput={this.extendInput}
              />
              <div className={s.buttonWrapper}>
                <button type="submit" className={s.submit}>
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className={s.errors}>
          <span className={s.errorMessage}>{this.state.errors.subject}</span>
          <span className={s.errorMessage}>{this.state.errors.body}</span>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  noteCreate: state.profile.noteCreate,
});

export default compose(
  withCookies,
  withStyles(s),
  connect(
    mapStateToProps,
    { createTravelerNote },
  ),
)(CreateNote);
