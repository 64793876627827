exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@-webkit-keyframes _1WD_n{to{-webkit-transform:rotate(1turn)}}@keyframes _1WD_n{to{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}:root{--dark:var(--theme_color_dark);--accent:var(--theme_color_accent);--ocean:var(--theme_color_light)}._1ERrl{display:-ms-flexbox;display:flex;width:235px;height:35px}._1ERrl .mMrXW{-ms-flex:1 0;flex:1 0}._1ERrl .mMrXW input{width:187px;color:#043544;background-color:#f6f7f9;border-top-left-radius:3px;border-bottom-left-radius:3px;border:1px solid #ebebeb;padding-left:10px;padding-right:12px;height:35px}._1ERrl ._1fNSd,._1ERrl .mMrXW input{font-family:AvenirNextRegular,sans-serif;font-style:normal;font-weight:400;font-size:12px;cursor:pointer}._1ERrl ._1fNSd{-ms-flex:1 1;flex:1 1;width:48px;background-color:var(--theme_color_dark);background-color:var(--dark,#043544);border-top-right-radius:3px;border-bottom-right-radius:3px;height:100%;border:1px solid var(--theme_color_dark);border:1px solid var(--dark,#043544)}", ""]);

// exports
exports.locals = {
	"wrapper": "_1ERrl",
	"linkInput": "mMrXW",
	"linkButton": "_1fNSd",
	"spin": "_1WD_n"
};