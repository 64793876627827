import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { breakpoints } from '../../utils/breakpoints';

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 0.625rem;
  margin-left: 0.5rem;
  margin-bottom: 0.90625rem;

  @media ${breakpoints.tablet} {
    margin-left: 0;
  }
`;

const HeadingText = styled.h2`
  margin-bottom: 0;
  font-family: 'Nunito', sans-serif;
  font-weight: 700;
  font-size: 0.875rem;
  color: #666666;
  line-height: 120%;
`;

const LineDecoration = styled.span`
  flex-grow: 1;
  border: 1px solid #cccccc;

  &:first-of-type {
    display: none;

    @media ${breakpoints.tablet} {
      display: block;
    }
  }
`;

export default function EventsGroupHeading({ text }) {
  return (
    <Container>
      <LineDecoration />
      <HeadingText>{text}</HeadingText>
      <LineDecoration />
    </Container>
  );
}

EventsGroupHeading.propTypes = {
  text: PropTypes.string.isRequired,
};
