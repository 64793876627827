import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
//import userId from '../../../../../shared/helpers/userId';
import { Paragraph } from '../../../../../styledComponents/styles/Paragraph.styled';
import { getPaymentStatusOptions, processFlywirePayment } from '../../../../../actions/formsActions';
import PaymentBox from '../../paymentBox/paymentBox';
import scriptInject from '../../../../../helpers/scriptInject';

const Container = styled.div`
  font-family: 'Nunito', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 0.015em;
  color: #373737;
`;

const InputContainer = styled.div`
  flex: 1;
  margin: 13px 0;
  text-align: left;
`;

const Label = styled.label`
  color: ${({ theme }) => theme.textInput.label.fontColor};
  font-family: ${({ theme }) => theme.textInput.label.fontFamily};
  font-size: ${({ theme }) => theme.textInput.label.fontSize};
  font-weight: ${({ theme }) => theme.textInput.label.fontWeight};
`;

const Required = styled.span`
  color: ${({ theme }) => theme.defaults.errorColor};
  padding-left: 5px;
`;

const QuestionFlywireDisplayForm = ({
  question,
  setValue,
  defaultValue,
  errors = {},
  inFormBuilder = false,
  isReadonly = false,
  isAdmin = false,
  formId,
}) => {
  const dispatch = useDispatch();
  const paymentOptions = useSelector(state => state.forms.paymentStatusOptions);
  const id = question.question_id.toString();
  const [savedDefaults, setSavedDefaults] = useState(defaultValue);
  const [rnd, setRnd] = useState(1);
  // eslint-disable-next-line no-undef
  const url = `${BACK_END_URL}/api/flywire/postback`;
  const { message: errorMsg } = errors[id] || {};

  useEffect(() => {
    loadFlywireScript();
  }, []);

  useEffect(() => {
    if (defaultValue) {
      setSavedDefaults(defaultValue);
      setRnd(Math.random());
      setValue(question.question_id.toString(), defaultValue.payment_status_id, {
        shouldValidate: false,
        shouldTouch: false,
        shouldDirty: false,
      });
    }
  }, [defaultValue]);

  useEffect(() => {
    if (isAdmin && !paymentOptions?.data && !paymentOptions?.loading) {
      dispatch(getPaymentStatusOptions());
    }
    // setTimeout(initFlywire, 100);
  }, [paymentOptions.data]);

  useEffect(() => {
    let options = [];

    if (paymentOptions && !paymentOptions.loading && !paymentOptions.error && paymentOptions.data) {
      paymentOptions.data.forEach(item => {
        options.push({ label: item.attributes.name, value: item.id });
      });

      let value = '';
      if (defaultValue && defaultValue.payment_status_id) {
        value = defaultValue.payment_status_id;
      } else {
        const notPaid = options.find(i => i.label.toLowerCase() === 'not paid');
        value = parseInt(notPaid.value);
      }
      setValue(question.question_id.toString(), value, {
        shouldValidate: false,
        shouldTouch: false,
        shouldDirty: false,
      });
    }
  }, [paymentOptions, defaultValue]);

  if (!formId) {
    isReadonly = true;
  }

  const loadFlywireScript = () => {
    //TODO: We should use something more like ${params.institution}, but that might not always match the subdomain
    const subdomain =
      process.env.NODE_ENV === 'development' ||
      window.location.host.indexOf('via-trm-staging') > 0 ||
      window.location.host.indexOf('via-trm-development') > 0
        ? 'test-tamu'
        : 'tamu';

    const scriptCheck = document.getElementById('opu-guest-payment');
    if (scriptCheck) {
      scriptCheck.remove();
    }

    scriptInject(`https://${subdomain}.myonplanu.com/js/opu-guest-payment.js`, initFlywireConnect, 'opu-guest-payment');
  };

  const handleStatusChange = status => {
    setValue(String(id), parseInt(status), {
      shouldValidate: true,
      shouldTouch: true,
      shouldDirty: true,
    });
  };

  const initFlywire = () => {
    loadFlywireScript();
  };

  const initFlywireConnect = () => {
    if (
      document.getElementById('opu-guest-payment') &&
      document.getElementById('opu-guest-payment-link') &&
      question.flywire_params
    ) {
      let flywireParams = { ...question.flywire_params };
      flywireParams.amount = question.flywire_params.amount;

      setTimeout(() => {
        if (window && window.OnPlanU?.Payment?.init) {
          window.OnPlanU.Payment.init(
            flywireParams,
            data => {
              //onSuccess (of payment)
              dispatch(
                processFlywirePayment(
                  {
                    amount: data.amount,
                    nonce: data.nonce,
                    success: data.success,
                    txId: String(data.txId),
                  },
                  isAdmin,
                ),
              );
            },
            // eslint-disable-next-line no-unused-vars
            data => {
              //onError
            },
            // eslint-disable-next-line no-unused-vars
            data => {
              //onCancel (closed the window)
            },
          );
        } else {
          setTimeout(initFlywire, 200);
        }
      }, 100);
    } else if (question.flywire_params) {
      setTimeout(initFlywire, 200);
    } else {
      console.error('No flywire params recieved from server', question);
    }
  };

  const updatedInstruction = question.instructions ? question.instructions.replace(/<p><\/p>/g, '<br />') : '';

  return (
    <Container>
      <InputContainer>
        <Label htmlFor={question.id}>
          {question.label}
          {question.required && <Required>*</Required>}
        </Label>
        <Paragraph
          aria-label={question.label}
          id="fee-instructions"
          openSans
          dangerouslySetInnerHTML={{
            __html: updatedInstruction,
          }}
        />
        {(question || inFormBuilder) && (
          <PaymentBox
            key={rnd}
            cost={question.cost}
            currency={question.currency}
            responseValues={savedDefaults}
            paymentOptions={paymentOptions}
            isAdmin={isAdmin}
            questionId={id}
            isReadonly={isReadonly}
            isFlywire
            onInit={initFlywire}
            errorMsg={errorMsg}
            onStatusChange={status => handleStatusChange(status)}
            url={url}
          />
        )}
      </InputContainer>
    </Container>
  );
};
QuestionFlywireDisplayForm.displayName = 'QuestionFlywireDisplayForm';

QuestionFlywireDisplayForm.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.number,
    label: PropTypes.string,
    required: PropTypes.bool,
    instructions: PropTypes.any,
  }).isRequired,
  setValue: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types, react/require-default-props
  errors: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types, react/require-default-props
  defaultValue: PropTypes.any,
  inFormBuilder: PropTypes.bool,
  isReadonly: PropTypes.bool,
  isAdmin: PropTypes.bool,
  formId: PropTypes.string,
};

export default QuestionFlywireDisplayForm;
