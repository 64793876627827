import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { breakpoints } from '../../utils/breakpoints';

const Subheading = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5625rem;
  margin-top: 3rem;
  margin-bottom: 2.055rem;
  font-family: 'Nunito', sans-serif;
  font-weight: 700;
  font-size: 1rem;
  color: #474747;
  letter-spacing: 0.015em;
  line-height: 120%;

  &:first-of-type {
    margin-top: 0;
  }

  @media ${breakpoints.tablet} {
    flex-direction: row;
    gap: 0;
    margin-top: 2.625rem;
  }
`;

const HeadingContainer = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  width: 100%;

  @media ${breakpoints.tablet} {
    width: auto;
  }
`;

const LineDecoration = styled.span`
  flex-grow: 1;
  margin-left: 0.3125rem;
  border-top: 1px solid #cccccc;

  @media ${breakpoints.tablet} {
    ${props => {
      if (props.hasChildren) {
        return `
          margin-right: 0.8125rem;
        `;
      }
    }};
  }
`;

export default function ProfileFormSubheading({ text, children }) {
  return (
    <Subheading>
      <HeadingContainer>
        <span>{text}</span>
        <LineDecoration hasChildren={!!children} />
      </HeadingContainer>
      {children}
    </Subheading>
  );
}

ProfileFormSubheading.propTypes = {
  children: PropTypes.node,
  text: PropTypes.string.isRequired,
};

ProfileFormSubheading.defaultProps = {
  children: null,
};
