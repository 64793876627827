import React from 'react';

import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './ProgramViewDescription.css';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withCookies, Cookies } from 'react-cookie';
import DraftEditorSingle from '../../../../components/Admin/ProgramView/DraftEditor/DraftEditorSingle';
import Textarea from 'react-textarea-autosize';
import ReactHtmlParser from 'react-html-parser';
import customBranding from '../../../../hoc/customBranding/customBranding';
import ViaIcon from '../../../../sites/plans/components/ViaIcon';

@customBranding
class ProgramViewDescription extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSticky: false,
      programTitle: this.props.title || '',
      description: this.props.description || '',
      subdescription: this.props.subdescription || '',
      favorite: this.props.favorite,
      new: {
        programTitle: '',
        description: '',
        subDescription: '',
      },
    };

    this.onChange = this.onChange.bind(this);
    this.extendInput = this.extendInput.bind(this);
    this.getLinesAndMoreText = this.getLinesAndMoreText.bind(this);
  }

  getLinesAndMoreText() {
    let lines;
    let more;
    if (this.props.subjects.length >= 3) {
      lines = this.props.subjects.slice(0, 3).length;
      more = `+${this.props.subjects.slice(3, this.props.subjects.length).length} more`;
    } else {
      lines = 2;
      more = 'Show All';
    }

    return { lines: lines, more: more };
  }

  componentDidMount() {
    document.addEventListener('scroll', () => {
      const isSticky = window.scrollY > 582 && window.scrollY < 1270;
      if (isSticky !== this.state.isSticky) {
        this.setState({ isSticky });
      }
    });
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  extendInput(e) {
    const height = document.querySelector('textarea').scrollHeight;
    e.target.style.height = `${height}px`;
  }

  render() {
    if (this.props.role == 'Admin') {
      if (this.props.status == 'view') {
        return (
          <div className={s.wrapper}>
            <h1 className={s.title}>{this.props.title}</h1>
            <div className={s.description}>
              {this.props.description ? ReactHtmlParser(this.props.description.replace(/(\r\n|\n|\r)/gm, '')) : ''}
            </div>
            <div className={s.subDescription}>
              {this.props.subdescription
                ? ReactHtmlParser(this.props.subdescription.replace(/(\r\n|\n|\r)/gm, ''))
                : ''}
            </div>
          </div>
        );
      } else if (this.props.status == 'edit') {
        return (
          <div className={s.wrapper}>
            <Textarea
              className={s.newTitleInput}
              value={this.props.title}
              name="programTitle"
              placeholder={this.props.alias_program + ' Title'}
              onChange={this.props.titleEdit}
            />
            <DraftEditorSingle
              cssClass={'newDescriptionInput'}
              description={this.props.description}
              descriptionEditChild={this.props.descriptionEdit}
              status={'description'}
              alias_program={this.props.alias_program}
            />
            <DraftEditorSingle
              cssClass={'newSubDescriptionInput'}
              description={this.props.subdescription}
              descriptionEditChild={this.props.subDescriptionEdit}
              status={'subDescription'}
              alias_program={this.props.alias_program}
            />
          </div>
        );
      } else if (this.props.status == 'new') {
        // New Section
        return (
          <div className={s.wrapper}>
            <Textarea
              className={s.newTitleInput}
              value={this.props.title}
              name="programTitle"
              placeholder={this.props.alias_program + ' Title...'}
              onChange={this.props.titleEdit}
            />
            <DraftEditorSingle
              cssClass={'newDescriptionInput'}
              description={''}
              descriptionEditChild={this.props.descriptionEdit}
              status={'description'}
              alias_program={this.props.alias_program}
            />

            <DraftEditorSingle
              cssClass={'newSubDescriptionInput'}
              description={''}
              descriptionEditChild={this.props.subDescriptionEdit}
              status={'subDescription'}
              alias_program={this.props.alias_program}
            />
          </div>
        );
      }
    } else {
      return (
        <div className={s.wrapper}>
          {this.props.role === 'Visitor' && (
            <div className={s.backLink}>
              <a href={`/visitor-programs`}>
                <ViaIcon name="back" color="noFill" size="icon24" />
              </a>
              <span className={s.backText}>
                <a href={`/visitor-programs`} style={{ color: '#043544' }}>
                  Return to {this.props.aliasProgram.toLowerCase()} results
                </a>
              </span>
            </div>
          )}
          <h1 className={s.title}>{this.props.title}</h1>
          <div className={s.description}>
            {this.props.description ? ReactHtmlParser(this.props.description.replace(/(\r\n|\n|\r)/gm, '')) : ''}
          </div>
          <div className={s.subDescription}>
            {this.props.subdescription ? ReactHtmlParser(this.props.subdescription.replace(/(\r\n|\n|\r)/gm, '')) : ''}
          </div>
        </div>
      );
    }
  }
}
const mapStateToProps = state => ({
  customBranding: state.profile.customBranding || state.program.programHash.branding_theme || {},
});
export default compose(
  withCookies,
  withStyles(s),
  connect(mapStateToProps),
)(ProgramViewDescription);
