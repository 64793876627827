import devUri from '../../config/keys_dev';

export function baseUrl() {
  let root;
  if (process.env.NODE_ENV === 'production' && process.env.AUCKLAND !== 'true') {
    root = 'https://s3-us-west-2.amazonaws.com/via-trm-stage-migrated';
  } else {
    root = devUri.baseUri;
  }
  return root;
}
