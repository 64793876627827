import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import CardApplicationStatus from './CardApplicationStatus';

import { breakpoints } from '../../../utils/breakpoints';

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  gap: 0.5rem;

  @media ${breakpoints.tablet} {
    gap: 1.59375rem;
  }
`;

const ItemInfo = styled.div`
  grid-column: span 2 / span 2;
  display: flex;
  align-items: baseline;
  gap: 0.6875rem;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  color: #373737;
  letter-spacing: 0.0015em;
  line-height: 120%;

  .item-info-count {
    font-family: 'Nunito', sans-serif;
    font-weight: 600;
  }

  @media ${breakpoints.tablet} {
    grid-column: span 1 / span 1;
  }
`;

export default function CardApplicationInfoItem({ count, type, status }) {
  return (
    <Container >
      <ItemInfo>
        <span className="item-info-count">{count}</span>
        <span>{type}</span>
      </ItemInfo>
      <CardApplicationStatus status={status} count={count} type={type} />
    </Container>
  );
}

CardApplicationInfoItem.propTypes = {
  count: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['Application', 'Forms', 'Form']).isRequired,
};
