import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import history from '../../../../history';
import { programIdType } from '../../utils/types';
import { breakpoints, size } from '../../utils/breakpoints';
import { formInputFocus } from '../../utils/shared/focusStyles';

const NavSelectContainer = styled.div`
  display: flex;
  flex-direction: column;

  label {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.5rem;
    color: #373737;

    @media only screen and ${breakpoints.desktop} {
      font-size: 1rem;
    }
  }

  select {
    background-color: #ffffff;
    border-radius: 2px;
    border: 0.0625rem solid #c2c2c2;
    padding: 0.75rem;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 140%;
    color: #666666;
    max-width: 12.8125rem;
    appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='18' height='17' viewBox='0 0 18 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M4.31934 6.17136L9.06754 10.6714L13.8158 6.17136' stroke='%23373737' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e ");
    background-position: right 1.0625rem center;
    background-repeat: no-repeat;
    background-size: 1.25rem;
    padding-right: 2rem;

    ${formInputFocus};
  }

  ${props => {
    return props.forTablet === true
      ? `
        @media only screen and (max-width: ${size.tablet}) {
          display: none;
        }

        @media only screen and ${breakpoints.tablet} {
          display: flex;
          flex-direction: row;
          justify-content: end;
          align-items: center;

          label {
            margin-right: 0.5rem;
          }

          select {
            width: 100%;
          }
        }

        @media only screen and ${breakpoints.desktop} {
          display: none;
        }
      `
      : `
        @media only screen and ${breakpoints.tablet} {
          display: none;
        }
      `;
  }};
`;

export default function MobileTabs({ programId, currentTab, activeTabs, forTablet, isAdmin }) {
  const programAlias = useSelector(state => state.auth.organization.info.alias_program);

  const handleSelectChange = event => {
    const tab = event.target.value;

    const index = activeTabs.findIndex(tabToFind => tabToFind.component === tab);

    localStorage.setItem('scrollY', window.scrollY);
    isAdmin
      ? history.push(`/client/program_brochure/${programId}/${activeTabs[index].path}`)
      : history.push(`/program_brochure/${programId}/${activeTabs[index].path}`);
  };

  return (
    <NavSelectContainer forTablet={forTablet}>
      <label htmlFor={forTablet ? "selected_tab" : "selected_menu"}>{programAlias} Menu:</label>
      <select id={forTablet ? "selected_tab" : "selected_menu"} name="selected_tab" value={currentTab.component} onChange={handleSelectChange}>
        {activeTabs.map(tab => (
          <option key={tab.component} value={tab.component}>
            {tab.title}
          </option>
        ))}
      </select>
    </NavSelectContainer>
  );
}

MobileTabs.propTypes = {
  programId: programIdType,
  currentTab: PropTypes.shape({
    path: PropTypes.string,
    title: PropTypes.string.isRequired,
    component: PropTypes.string.isRequired,
  }).isRequired,
  activeTabs: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string,
      title: PropTypes.string.isRequired,
      component: PropTypes.string.isRequired,
    }),
  ),
  forTablet: PropTypes.bool,
};

MobileTabs.defaultProps = {
  programId: '',
  activeTabs: [],
  forTablet: false,
};
