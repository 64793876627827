import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import CreateMessage from './CreateMessage/CreateMessage';
import MessageList from './MessageList/MessageList';

class MessageComponent extends React.Component {
  render() {
    return (
      <div>
        <CreateMessage
          id={this.props.id}
          createMessage={this.props.createMessage}
          role={this.props.role}
          avatar={this.props.avatar}
          currentUserAvatar={this.props.currentUserAvatar}
        />
        <MessageList
          id={this.props.id}
          role={this.props.role}
          newMessage={this.props.newMessage}
          messages={this.props.messages}
          accentColor={this.props.accentColor}
          orgTimezone={this.props.orgTimezone}
        />
      </div>
    );
  }
}

export default MessageComponent;
