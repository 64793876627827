import React from 'react';

import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './ProgramViewAbout.css';
import countries from '../../../../../public/data/countries.json';
import languages from '../../../../../public/data/languages.json';
import Select from 'react-select';
import reactSelectStyles from '../../../../../public/css/program-select.css';
import DraftEditorSingle from '../../../../components/Admin/ProgramView/DraftEditor/DraftEditorSingle';
import ReactHtmlParser from 'react-html-parser';
import _compact from 'lodash/compact';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';
import _map from 'lodash/map';
import _uniqWith from 'lodash/uniqWith';

class ProgramViewAbout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subjectField: '',
      subjects: [],
      city: '',
    };

    this.subjectEdit = this.subjectEdit.bind(this);
    this.deleteSubject = this.deleteSubject.bind(this);
  }

  subjectEdit = e => {
    this.setState({ subjectField: '' });
    const subjects = this.state.subjects;
    subjects.push(e.label);
    this.setState({ subjects });
  };

  deleteSubject = i => {
    const array = [...this.state.subjects];
    array.splice(i, 1);
    this.setState({ subjects: array });
  };

  renderHousingTypes = () => {
    const other = this.props.allHousingTypes.find(other => other.name === 'Other')
    const housingTypes = this.props.allHousingTypes
      .filter(x => x.name != 'Not Sure')
      .filter(x => x.name != 'Other')
      .map(type => type.name)
      .sort((a, b) => a.localeCompare(b))
      .concat(other.name)
    return _map(housingTypes, (housingType, index) => {
      return (
        <div className={`col-md-1 ${s.viewCheckboxWrapper}`} key={index}>
          <label className={s.checkboxContainer}>
             <input
              type='checkbox'
              onChange={this.props.housingEdit.bind(this, housingType)}
              checked={this.props.housing.includes(housingType) == true}
              >
              </input>
            <p>{housingType}</p>
            <span className={s.checkmark} />
          </label>
        </div>
      );
    });
  };

  renderProgramTypes = () => {
    const other = this.props.allProgramTypes.find(other => other.name === 'Other')
    const ProgramTypes = this.props.allProgramTypes
      .filter(x => x.name != 'Other')
      .map(type => type.name)
      .sort((a, b) => a.localeCompare(b))
      .concat(other.name)
    return _map(ProgramTypes, (programType, index) => {
      return (
        <div className={`col-md-1 ${s.viewCheckboxWrapper}`} key={index}>
          <label className={s.checkboxContainer}>
            <input
            type='checkbox'
            onChange={this.props.programTypeEdit.bind(this, programType)}
            checked={this.props.programTypes.includes(programType) == true}
            >
            </input>
            <p>{programType}</p>
            <span className={s.checkmark} />
          </label>
        </div>
      );
    });
  };

  render() {
    const alias_program = this.props.alias_program
      ? this.props.alias_program
      : 'Program';
    const languageOptions = [];
    languages.map(language =>
      languageOptions.push({ value: language.code, label: language.name }),
    );
    let subjectOptions = [];
    const excursions = ReactHtmlParser(this.props.pExcursions);
    const additionals = ReactHtmlParser(this.props.pAddons);
    const courses = ReactHtmlParser(this.props.pCourses);
    const activities = ReactHtmlParser(this.props.pActivities);
    const activityNotes = ReactHtmlParser(this.props.activityNotes)
    const hostName = ReactHtmlParser(this.props.hostOrg);
    const hostNotes = ReactHtmlParser(this.props.hostOrgNotes);
    const locationNotes = ReactHtmlParser(this.props.locationNotes);
    const housingNotes = ReactHtmlParser(this.props.housingNotes);
    const academicNotes = ReactHtmlParser(this.props.academicNotes);

    if (['new', 'edit'].includes(this.props.status)) {
      subjectOptions = this.props.subjectAreas.map((subject, index) => ({
        value: subject.id,
        label: subject.name,
      }));
    }

    let housings = '';
    if (this.props.housing) {
      housings = this.props.housing
        .filter(housing => housing.included)
        .map(includedHousing => includedHousing.housing_type.name)
        .join(', ');
    }

    let types = '';
    if (this.props.programTypes) {
      types = this.props.programTypes.map(type => type).join(', ');
    }

    if (this.props.role == 'Admin') {
      if (this.props.status == 'view') {
        const languages = this.props.languages;
        const languagesCount = (languages.match(/,/g) || []).length + 1;
        const loc = this.props.locations;
        const locations =
          loc.length > 0 && loc[0].alpha2 !== null
            ? loc
                .map((location, index) => (
                  <span key={index}>
                    {location.city === '' || location.city === null
                      ? location.country
                      : location.city + ', ' + location.country}
                  </span>
                ))
                .reduce((prev, curr) => [prev, '; ', curr])
            : '';

        const subjects = _compact(this.props.subjects)
          .map(subject => subject.name)
          .join('; ');

        return (
          <div className={`row no-gutters ${s.wrapper}`}>
            <div className={`col-sm-12 ${s.section}`}>
              <h2 className={s.aboutHeader}>About this {alias_program}</h2>
              <div className={s.aboutSection}>
                <p className={s.sectionTitle}>
                  Type
                </p>
                <div className={s.sectionType}>
                  {types}
                </div>
              </div>
              <div className={s.aboutSection}>
                <p className={s.sectionTitle}>Language and Location</p>
                <div className={s.sectionAnswers}>
                  <div className={`col-md-1 ${s.viewCheckboxWrapper}`}>
                    <label className={s.viewCheckboxContainer}>
                      <input
                        type='checkbox'
                        checked={this.props.immersion == true}
                        disabled={true}
                      >
                      </input>
                    <p>Immersion {alias_program}</p>
                    <span className={s.viewCheckmark} />
                    </label>
                  </div>
                  <div className={s.activitiesAndAdminView}>
                    <p>
                      <span className={s.bolder}>
                        {languagesCount <= 1
                          ? `Instructional Language: `
                          : `Instructional Languages: `
                        }
                      </span>
                      <span className={s.informationContent}>
                        {languages !== null ? languages : `English`}
                      </span>
                    </p>
                  </div>
                  {locations ? (
                    <div className={s.activitiesAndAdminView}>
                      <p>
                        <span className={s.bolder}>
                          {this.props.locations.length <= 1
                            ? `Location: `
                            : `Locations: `
                          }
                        </span>
                        <span className={s.informationContent}>{locations}</span>
                      </p>
                    </div>
                  ) : (
                    ''
                  )}
                  {!_isEmpty(locationNotes) ? (
                    <div className={s.activitiesAndAdminView}>
                      <p>
                        <span className={s.bolder}>Language and Location Information: </span>
                        <span className={s.informationContent}>{locationNotes}</span>
                      </p>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <div className={s.aboutSection}>
                <p className={s.sectionTitle}>Housing</p>
                <div className={s.sectionHousing}>
                  <div className={s.capitalize}>{housings}</div>
                  {!_isEmpty(housingNotes) ? (
                    <div className={s.activitiesAndAdminView}>
                      <p>
                        <span className={s.bolder}>Housing Information: </span>
                        <span className={s.informationContent}>{housingNotes}</span>
                      </p>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
                {!_isEmpty(courses) ||
                !_isEmpty(academicNotes) ||
                subjects ? (
                  <div className={s.aboutSection}>
                    <p className={s.sectionTitle}>Academics</p>
                    <div className={`${s.sectionAnswers} ${s.negativeMargin}`}>
                      {subjects ? (
                        <div className={s.activitiesAndAdminView}>
                          <p>
                            <span className={s.bolder}> Subject Areas: </span>
                            <span className={s.informationContent}>{subjects}</span>
                          </p>
                        </div>
                      ) : (
                        ``
                      )}
                      {!_isEmpty(courses) ? (
                        <div className={s.activitiesAndAdminView}>
                          <p>
                            <span className={s.bolder}>Courses: </span>
                            <span className={s.informationContent}>{courses}</span>
                          </p>
                        </div>
                      ) : (
                        ``
                      )}
                      {!_isEmpty(academicNotes) ? (
                        <div className={s.activitiesAndAdminView}>
                          <p>
                            <span className={s.bolder}> Academics Information: </span>
                            <span className={s.informationContent}>{academicNotes}</span>
                          </p>
                        </div>
                      ) : (
                        ``
                      )}
                    </div>
                  </div>
                ) : (
                ''
              )}
              {!_isEmpty(hostName) ||
               !_isEmpty(hostNotes) ? (
                <div className={s.aboutSection}>
                  <p className={s.sectionTitle}>Host Organization</p>
                  <div className={`${s.sectionAnswers} ${s.negativeMargin}`}>
                    {!_isEmpty(hostName) ? (
                      <div className={s.activitiesAndAdminView}>
                        <p>
                          <span className={s.bolder}>Host Organization: </span>
                          <span className={s.informationContent}>{hostName}</span>
                        </p>
                      </div>
                    ) : (
                      ``
                    )}
                    {!_isEmpty(hostNotes) ? (
                      <div className={s.activitiesAndAdminView}>
                        <p>
                          <span className={s.bolder}>Host Organization Information: </span>
                          <span className={s.informationContent}>{hostNotes}</span>
                        </p>
                      </div>
                    ) : (
                      ``
                    )}
                  </div>
                </div>
              ) : (
                ''
              )}
              {!_isEmpty(activities) ||
              !_isEmpty(excursions) ||
              !_isEmpty(additionals) ||
              !_isEmpty(activityNotes) ? (
                <div className={s.aboutSection}>
                  <p className={s.sectionTitle}>Activities</p>
                  <div className={`${s.sectionAnswers} ${s.negativeMargin}`}>
                    {!_isEmpty(activities) ? (
                      <div className={s.activitiesAndAdminView}>
                        <p>
                          <span className={s.bolder}>Activities: </span>
                          <span className={s.informationContent}>{activities}</span>
                        </p>
                      </div>
                    ) : (
                      ``
                    )}
                    {!_isEmpty(excursions) ? (
                      <div className={s.activitiesAndAdminView}>
                        <p>
                          <span className={s.bolder}>Excursions: </span>
                          <span className={s.informationContent}>{excursions}</span>
                        </p>
                      </div>
                    ) : (
                      ``
                    )}
                    {!_isEmpty(additionals) ? (
                      <div className={s.activitiesAndAdminView}>
                        <p>
                          <span className={s.bolder}>Add Ons: </span>
                          <span className={s.informationContent}>{additionals}</span>
                        </p>
                      </div>
                    ) : (
                      ``
                    )}
                    {!_isEmpty(activityNotes) ? (
                      <div className={s.activitiesAndAdminView}>
                        <p>
                          <span className={s.bolder}>Activities Information: </span>
                          <span className={s.informationContent}>{activityNotes}</span>
                        </p>
                      </div>
                    ) : (
                      ``
                    )}
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        );
      } else if (this.props.status == 'edit') {
        return (
          <div className={`row no-gutters ${s.wrapper}`}>
            <div className={`col-sm-12 ${s.aboutProgramSection}`}>
              <h2 className={s.aboutHeader}>About this {alias_program}</h2>
              <div className={s.aboutSection}>
                <p className={s.sectionTitle}>
                  What type of {alias_program} is this? &nbsp; &nbsp; Select
                  all that apply.
                </p>
                <div className={s.sectionAnswers}>
                  {this.renderProgramTypes()}
                </div>
              </div>
              <div className={s.aboutSection}>
                <p className={s.sectionTitle}>Language and Location</p>
                <div className={s.sectionAnswers}>
                  <div className={`col-md-1 ${s.viewCheckboxWrapper}`}>
                    <label className={s.checkboxContainer}>
                      <input
                        type='checkbox'
                        onChange={this.props.immersionEdit}
                        checked={this.props.immersion == true}
                      >
                      </input>
                      <p> Immersion {alias_program}</p>
                      <span className={s.immersionCheckmark} />
                    </label>
                  </div>
                  <div className={s.activitiesAndAdmin}>
                    <p>
                      {
                        this.props.languages.length > 1
                        ? `Instructional Languages: `
                        : `Instructional Language: `
                      }
                    </p>
                    {<Select
                      isSearchable
                      isClearable
                      className={s.dropdown}
                      onChange={this.props.languageEdit}
                      options={languageOptions}
                      placeholder="List your Instructional Languages here..."
                      value={this.props.languageField}
                      id={s.languageSelect}
                    />}
                    <ul className={s.demibold}>
                      {this.props.languages.map((language, index) => (
                        <li key={index} className={s.aboutListItems}>
                          {language}{' '}
                          <span
                            className={s.deleteLanguage}
                            onClick={this.props.deleteLanguage.bind(
                              this,
                              index,
                            )}
                          >
                            X
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className={s.activitiesAndAdmin}>
                    <p>
                      {
                        this.props.addedLocations.length > 1
                        ? `Locations:`
                        : `Location:`
                      }
                    </p>
                    {<Select
                      isSearchable
                      isClearable
                      className={s.dropdown}
                      onChange={this.props.locationEdit}
                      options={countries}
                      placeholder="List your Locations here..."
                      value={this.props.locationField}
                      id={s.languageSelect}
                    />}
                    <ul className={s.demibold}>
                    {this.props.addedLocations.map((location, index) => (
                      <li key={index}>
                        {location.country}{' '}
                        <input
                          type="text"
                          className={s.newTravelerInput}
                          value={location.city}
                          name="city"
                          placeholder="City"
                          onChange={e =>
                            this.props.updateCity(e, location.id)
                          }
                        />
                        <span
                          className={s.deleteLanguage}
                          onClick={this.props.locationRemove.bind(
                            this,
                            location,
                          )}
                        >
                          X
                        </span>
                      </li>
                    ))}
                    </ul>
                  </div>
                  <div className={s.activitiesAndAdmin}>
                    <p>Language and Location Information:{' '}</p>
                    <DraftEditorSingle
                      cssClass={'newActivitiesAndAdmin'}
                      description={this.props.languageLocationNotesField}
                      descriptionEditChild={this.props.languageLocationNotesChange}
                      status={'activities'}
                      alias_program={this.props.alias_program}
                    />
                  </div>
                </div>
              </div>
              <div className={s.aboutSection}>
                <p className={s.sectionTitle}>Housing</p>
                <div className={s.sectionAnswers}>
                  {this.renderHousingTypes()}
                  <div className={s.activitiesAndAdmin}>
                    <p>Housing Information:{' '}</p>
                    <DraftEditorSingle
                      cssClass={'newActivitiesAndAdmin'}
                      description={this.props.housingNotesField}
                      descriptionEditChild={this.props.housingNotesChange}
                      status={'activities'}
                      alias_program={this.props.alias_program}
                    />
                  </div>
                </div>
              </div>
              <div className={s.aboutSection}>
                <p className={s.sectionTitle}>Academics</p>
                <div className={`${s.sectionAnswers} ${s.negativeMargin}`}>
                  <div className={s.activitiesAndAdmin}>
                    <p>Subject Areas:{' '}</p>
                    {<Select
                      isSearchable
                      isClearable
                      className={s.dropdown}
                      onChange={this.props.subjectEdit}
                      options={subjectOptions}
                      placeholder="List Subject Areas here..."
                      value={this.props.subjectField}
                      id={s.languageSelect}
                    />}
                    <div className={`${s.answerWrapper} ${s.demibold}`}>
                      {this.props.subjects.sort().map((subject, index) => (
                        <span key={index}>
                          {subject.name}{' '}
                          <span
                            className={s.deleteLanguage}
                            onClick={this.props.deleteSubject.bind(
                              this,
                              index,
                              subject,
                            )}
                          >
                            X
                          </span>{' '}
                        </span>
                      ))}
                    </div>
                  </div>
                  <div className={s.activitiesAndAdmin}>
                    <p>Courses:{' '}</p>
                    <DraftEditorSingle
                      cssClass={'newActivitiesAndAdmin'}
                      description={this.props.courseField}
                      descriptionEditChild={this.props.courseChange}
                      status={'courses'}
                      alias_program={this.props.alias_program}
                      placeholder={'Type in course information'}
                    />
                  </div>
                  <div className={s.activitiesAndAdmin}>
                    <p>Academics Information:{' '}</p>
                    <DraftEditorSingle
                      cssClass={'newActivitiesAndAdmin'}
                      description={this.props.academicsNotesField}
                      descriptionEditChild={this.props.academicsNotesChange}
                      status={'activities'}
                      alias_program={this.props.alias_program}
                      placeholder={'Type in academics information'}
                    />
                  </div>
                </div>
              </div>
              <div className={s.aboutSection}>
                <p className={s.sectionTitle}>Host Organization</p>
                <div className={`${s.sectionAnswers} ${s.negativeMargin}`}>
                  <div className={s.activitiesAndAdmin}>
                    <p>Host Organization Name:{' '}</p>
                    <DraftEditorSingle
                      cssClass={'newActivitiesAndAdmin'}
                      description={this.props.hostOrgNameField}
                      descriptionEditChild={this.props.hostOrgNameChange}
                      status={'activities'}
                      alias_program={this.props.alias_program}
                    />
                  </div>
                  <div className={s.activitiesAndAdmin}>
                    <p>Host Organization Information:{' '}</p>
                    <DraftEditorSingle
                      cssClass={'newActivitiesAndAdmin'}
                      description={this.props.hostOrgNotesField}
                      descriptionEditChild={this.props.hostOrgNotesChange}
                      status={'activities'}
                      alias_program={this.props.alias_program}
                    />
                  </div>
                </div>
              </div>
              <div className={s.aboutSection}>
                <p className={s.sectionTitle}>Activities</p>
                <div className={`${s.sectionAnswers} ${s.negativeMargin}`}>
                  <div className={s.activitiesAndAdmin}>
                    <p>Activities:{' '}</p>
                    <DraftEditorSingle
                      cssClass={'newActivitiesAndAdmin'}
                      description={this.props.activityField}
                      descriptionEditChild={this.props.activityChange}
                      status={'activities'}
                      alias_program={this.props.alias_program}
                    />
                  </div>
                  <div className={s.activitiesAndAdmin}>
                    <p>Excursions:{' '}</p>
                    <DraftEditorSingle
                      cssClass={'newActivitiesAndAdmin'}
                      description={this.props.excursionField}
                      descriptionEditChild={this.props.excursionChange}
                      status={'excursions'}
                      alias_program={this.props.alias_program}
                    />
                  </div>
                  <div className={s.activitiesAndAdmin}>
                    <p>Add Ons:{' '}</p>
                    <DraftEditorSingle
                      cssClass={'newActivitiesAndAdmin'}
                      description={this.props.additionalField}
                      descriptionEditChild={this.props.additionalChange}
                      status={'addOns'}
                      alias_program={this.props.alias_program}
                    />
                  </div>
                  <div className={s.activitiesAndAdmin}>
                    <p>Activities Information:{' '}</p>
                    <DraftEditorSingle
                      cssClass={'newActivitiesAndAdmin'}
                      description={this.props.activityNotesField}
                      descriptionEditChild={this.props.activityNotesChange}
                      status={'activities'}
                      alias_program={this.props.alias_program}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      } else if (this.props.status == 'new') {
        return (
          <div className={`row no-gutters ${s.wrapper}`}>
            <div className={`col-sm-12 ${s.aboutProgramSection}`}>
              <h2 className={s.aboutHeader}>
                About this {alias_program.toLowerCase()}
              </h2>
              <div className={s.aboutSection}>
                <p className={s.sectionTitle}>
                  What type of {alias_program.toLowerCase()} is this? &nbsp;
                  &nbsp; Select all that apply.
                </p>
                <div className={s.sectionAnswers}>
                  {this.renderProgramTypes()}
                </div>
              </div>
              <div className={s.aboutSection}>
                <p className={s.sectionTitle}>Language and Location</p>
                <div className={s.sectionAnswers}>
                  <div className={`col-md-1 ${s.viewCheckboxWrapper}`}>
                    <label className={s.checkboxContainer}>
                      <input
                        type='checkbox'
                        onChange={this.props.immersionEdit}
                        checked={this.props.immersion_program == true}
                      >
                      </input>
                      <p> Immersion {alias_program}?{' '}</p>
                      <span className={s.immersionCheckmark} />
                    </label>
                  </div>
                  <div className={s.activitiesAndAdmin}>
                    <p>Instructional Language(s):{' '}</p>
                    {<Select
                      isSearchable
                      isClearable
                      className={s.dropdown}
                      onChange={this.props.languageEdit}
                      options={languageOptions}
                      placeholder="List your Instructional Languages here..."
                      value={this.props.languageField}
                      id={s.languageSelect}
                    />}
                    <ul className={s.demibold}>
                      {this.props.languages.map((language, index) => (
                        <li key={index}>
                          {language}{' '}
                          <span
                            className={s.deleteLanguage}
                            onClick={this.props.deleteLanguage.bind(
                              this,
                              index,
                            )}
                          >
                            X
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className={s.activitiesAndAdmin}>
                    <p>Location(s):</p>
                    {<Select
                      isSearchable
                      isClearable
                      className={s.dropdown}
                      onChange={this.props.locationEdit}
                      options={countries}
                      placeholder="List your Locations here..."
                      value={this.props.locationField}
                      id={s.languageSelect}
                    />}
                    <ul className={s.demibold}>
                      {this.props.locations.map((location, index) => (
                        <li key={index}>
                          {location.country}{' '}
                          <input
                            type="text"
                            className={s.newTravelerInput}
                            value={location.city}
                            name="city"
                            placeholder="City"
                            onChange={e =>
                              this.props.updateCity(e, location.id)
                            }
                          />
                          <span
                            className={s.deleteLanguage}
                            onClick={this.props.locationRemove.bind(
                              this,
                              location,
                            )}
                          >
                            X
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className={s.activitiesAndAdmin}>
                    <p>Language and Location Information:{' '}</p>
                    <DraftEditorSingle
                      cssClass={'newActivitiesAndAdmin'}
                      description={this.props.languageLocationNotesField}
                      descriptionEditChild={this.props.languageLocationNotesChange}
                      status={'activities'}
                      alias_program={this.props.alias_program}
                    />
                  </div>
                </div>
              </div>
              <div className={s.aboutSection}>
                <p className={s.sectionTitle}>Housing</p>
                <div className={s.sectionAnswers}>
                  {this.renderHousingTypes()}
                  <div className={s.activitiesAndAdmin}>
                    <p>Housing Information:{' '}</p>
                    <DraftEditorSingle
                      cssClass={'newActivitiesAndAdmin'}
                      description={this.props.housingNotesField}
                      descriptionEditChild={this.props.housingNotesChange}
                      status={'activities'}
                      alias_program={this.props.alias_program}
                    />
                  </div>
                </div>
              </div>
              <div className={s.aboutSection}>
                <p className={s.sectionTitle}>Academics</p>
                <div className={`${s.sectionAnswers} ${s.negativeMargin}`}>
                  <div className={s.activitiesAndAdmin}>
                    <p>Subject Areas:{' '}</p>
                    {<Select
                      isSearchable
                      isClearable
                      className={s.dropdown}
                      onChange={this.props.subjectEdit}
                      options={subjectOptions}
                      placeholder="List Subject Areas here..."
                      value={this.props.subjectField}
                      id={s.languageSelect}
                    />}
                    <div className={`${s.answerWrapper} ${s.demibold}`}>
                      {this.props.subjects.sort().map((subject, index) => (
                        <span key={index}>
                          {subject.name}{' '}
                          <span
                            className={s.deleteLanguage}
                            onClick={this.props.deleteSubject.bind(
                              this,
                              index,
                              subject,
                            )}
                          >
                            X
                          </span>{' '}
                        </span>
                      ))}
                    </div>
                  </div>
                  <div className={s.activitiesAndAdmin}>
                    <p>Courses:</p>
                      <DraftEditorSingle
                        cssClass={'newActivitiesAndAdmin'}
                        description={this.props.courseField}
                        descriptionEditChild={this.props.courseChange}
                        status={'courses'}
                        alias_program={this.props.alias_program}
                        placeholder={'Type in course information'}
                      />
                  </div>
                  <div className={s.activitiesAndAdmin}>
                    <p>Academics Information:{' '}</p>
                    <DraftEditorSingle
                      cssClass={'newActivitiesAndAdmin'}
                      description={this.props.academicsNotesField}
                      descriptionEditChild={this.props.academicsNotesChange}
                      status={'activities'}
                      alias_program={this.props.alias_program}
                      placeholder={'Type in academics information'}
                    />
                  </div>
                </div>
              </div>
              <div className={s.aboutSection}>
                <p className={s.sectionTitle}>Host Organization</p>
                <div className={`${s.sectionAnswers} ${s.negativeMargin}`}>
                  <div className={s.activitiesAndAdmin}>
                    <p>Host Organization Name:{' '}</p>
                    <DraftEditorSingle
                      cssClass={'newActivitiesAndAdmin'}
                      description={this.props.hostOrgNameField}
                      descriptionEditChild={this.props.hostOrgNameChange}
                      status={'activities'}
                      alias_program={this.props.alias_program}
                    />
                  </div>
                  <div className={s.activitiesAndAdmin}>
                    <p>Host Organization Information:{' '}</p>
                    <DraftEditorSingle
                      cssClass={'newActivitiesAndAdmin'}
                      description={this.props.hostOrgNotesField}
                      descriptionEditChild={this.props.hostOrgNotesChange}
                      status={'activities'}
                      alias_program={this.props.alias_program}
                    />
                  </div>
                </div>
              </div>
              <div className={s.aboutSection}>
                <p className={s.sectionTitle}>Activities & Admin</p>
                <div className={`${s.sectionAnswers} ${s.negativeMargin}`}>
                  <div className={s.activitiesAndAdmin}>
                    <p>Activities:{' '}</p>
                    <DraftEditorSingle
                      cssClass={'newActivitiesAndAdmin'}
                      description={this.props.activityField}
                      descriptionEditChild={this.props.activityChange}
                      status={'activities'}
                      alias_program={this.props.alias_program}
                    />
                  </div>
                  <div className={s.activitiesAndAdmin}>
                    <p>Excursions:{' '}</p>
                    <DraftEditorSingle
                      cssClass={'newActivitiesAndAdmin'}
                      description={this.props.excursionField}
                      descriptionEditChild={this.props.excursionChange}
                      status={'excursions'}
                      alias_program={this.props.alias_program}
                    />
                  </div>
                  <div className={s.activitiesAndAdmin}>
                    <p>Add Ons:{' '}</p>
                    <DraftEditorSingle
                      cssClass={'newActivitiesAndAdmin'}
                      description={this.props.additionalField}
                      descriptionEditChild={this.props.additionalChange}
                      status={'addOns'}
                      alias_program={this.props.alias_program}
                    />
                  </div>
                  <div className={s.activitiesAndAdmin}>
                    <p>Activities Information:{' '}</p>
                    <DraftEditorSingle
                      cssClass={'newActivitiesAndAdmin'}
                      description={this.props.activityNotesField}
                      descriptionEditChild={this.props.activityNotesChange}
                      status={'activities'}
                      alias_program={this.props.alias_program}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
    } else {
      // Traveler View & Visitor View
      const languages = this.props.languages;
      const languagesCount = (languages.match(/,/g) || []).length + 1;
      const loc = this.props.locations;
      const locations =
        loc.length > 0 && loc[0].alpha2 !== null
          ? loc
              .map((location, index) => (
                <span key={index}>
                  {location.city === '' || location.city === null
                    ? location.country
                    : location.city + ', ' + location.country}
                </span>
              ))
              .reduce((prev, curr) => [prev, '; ', curr])
          : '';
      const subjects = this.props.subjects
        .sort()
        .map(subject => subject.name)
        .join('; ');
      const activities = ReactHtmlParser(this.props.pActivities);
      const excursions = ReactHtmlParser(this.props.pExcursions);
      const additionals = ReactHtmlParser(this.props.pAddons);
      const courses = ReactHtmlParser(this.props.pCourses);
      const activityNotes = ReactHtmlParser(this.props.activityNotes);
      const locationNotes = ReactHtmlParser(this.props.locationNotes);
      const housingNotes = ReactHtmlParser(this.props.housingNotes);
      const hostName = ReactHtmlParser(this.props.hostOrg);
      const hostNotes = ReactHtmlParser(this.props.hostOrgNotes);
      const academicNotes = ReactHtmlParser(this.props.academicNotes);
      const other = this.props.housing.find(other => other.housing_type.name === 'Other')
      const housing = _uniqWith(this.props.housing, _isEqual)
        .filter(type => type.housing_type.name != 'Not Sure')
        .filter(x => x.housing_type.name != 'Other')
        .sort((a, b) => a.housing_type.name.localeCompare(b.housing_type.name))
        .concat(other)
        .map((house, index) => (
          <div className={`col-md-1 ${s.viewCheckboxWrapper}`} key={index}>
            <label className={s.viewCheckboxContainer}>
              <input
                type='checkbox'
                checked={house.included == true}
                disabled={true}
              >
              </input>
              <p key={index} >
                {`${house.housing_type.name}`}
              </p>
              <span className={s.viewCheckmark} />
            </label>
          </div>
        ));
      return (
        <div className={`row no-gutters ${s.wrapper}`}>
          <div className={`col-sm-12 ${s.section}`}>
            <h2 className={s.aboutHeader}>
              About this {alias_program.toLowerCase()}
            </h2>
            <div className={s.aboutSection}>
              <p className={s.sectionTitle}>
                Type
              </p>
              <div className={s.sectionType}>
                {types}
              </div>
            </div>
            <div className={s.aboutSection}>
              <p className={s.sectionTitle}>Language and Location</p>
              <div className={s.sectionAnswers}>
                <div className={`col-md-1 ${s.viewCheckboxWrapper}`}>
                  <label className={s.viewCheckboxContainer}>
                    <input
                      type='checkbox'
                      checked={this.props.immersion == true}
                      disabled={true}
                    >
                    </input>
                    <p>
                      Immersion {alias_program}
                    </p>
                    <span className={s.viewCheckmark} />
                  </label>
                </div>
                <div className={s.activitiesAndAdminView}>
                  <p>
                    <span className={s.bolder}>
                      {languagesCount <= 1
                        ? `Instructional Language: `
                        : `Instructional Languages: `
                      }
                    </span>
                    <span className={s.informationContent}>
                      {languages !== null ? languages : `English`}
                    </span>
                  </p>
                </div>
                {locations ? (
                  <div className={s.activitiesAndAdminView}>
                    <p>
                      <span className={s.bolder}>
                        {this.props.locations.length <= 1
                          ? `Location: `
                          : `Locations: `
                        }
                      </span>
                      <span className={s.informationContent}>{locations}</span>
                    </p>
                  </div>
                ) : (
                  ``
                )}
                  {!_isEmpty(locationNotes) ? (
                    <div className={s.activitiesAndAdminView}>
                      <p>
                        <span className={s.bolder}>Language and Location Information: </span>
                        <span className={s.informationContent}>{locationNotes}</span>
                      </p>
                    </div>
                ) : (
                  ''
                )}
              </div>
            </div>
            <div className={s.aboutSection}>
              <p className={s.sectionTitle}>Housing</p>
              <div className={s.sectionHousing}>
                {housings}
                {!_isEmpty(housingNotes) ? (
                  <div className={s.activitiesAndAdminView}>
                    <p>
                      <span className={s.bolder}> Housing Information: </span>
                      <span className={s.informationContent}>{housingNotes}</span>
                    </p>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
            {!_isEmpty(courses) ||
            !_isEmpty(academicNotes) ||
            subjects ? (
              <div className={s.aboutSection}>
                <p className={s.sectionTitle}>Academics</p>
                <div className={`${s.sectionAnswers} ${s.negativeMargin}`}>
                  {subjects ? (
                    <div className={s.activitiesAndAdminView}>
                      <p>
                        <span className={s.bolder}>Subject Areas: </span>
                        <span className={s.informationContent}>{subjects}</span>
                      </p>
                    </div>
                  ) : (
                    ``
                  )}
                  {!_isEmpty(courses) ? (
                    <div className={s.activitiesAndAdmin}>
                      <p>
                        <span className={s.bolder}>Courses: </span>
                        <span className={s.informationContent}>{courses}</span>
                      </p>
                    </div>
                  ) : (
                    ``
                  )}
                  {!_isEmpty(academicNotes) ? (
                    <div className={s.activitiesAndAdminView}>
                      <p>
                        <span className={s.bolder}>Academics Information: </span>
                        <span className={s.informationContent}>{academicNotes}</span>
                      </p>
                    </div>
                  ) : (
                    ``
                  )}
                </div>
              </div>
            ) : (
              ``
            )}
            {!_isEmpty(hostName) ||
             !_isEmpty(hostNotes) ? (
              <div className={s.aboutSection}>
                <p className={s.sectionTitle}>Host Organization</p>
                  <div className={`${s.sectionAnswers} ${s.negativeMargin}`}>
                    {!_isEmpty(hostName) ? (
                      <div className={s.activitiesAndAdminView}>
                        <p>
                          <span className={s.bolder}>Host Organization: </span>
                          <span className={s.informationContent}>{hostName}</span>
                        </p>
                      </div>
                    ) : (
                      ``
                    )}
                    {!_isEmpty(hostNotes) ? (
                      <div className={s.activitiesAndAdminView}>
                        <p>
                          <span className={s.bolder}>Host Organization Information: </span>
                          <span className={s.informationContent}>{hostNotes}</span>
                        </p>
                      </div>
                    ) : (
                      ``
                    )}
                  </div>
              </div>
            ) : (
              ''
            )}
            {!_isEmpty(activities) ||
            !_isEmpty(excursions) ||
            !_isEmpty(additionals) ||
            !_isEmpty(activityNotes) ? (
              <div className={s.aboutSection}>
                <p className={s.sectionTitle}>Activities</p>
                <div className={`${s.sectionAnswers} ${s.negativeMargin}`}>
                  {!_isEmpty(activities) ? (
                    <div className={s.activitiesAndAdminView}>
                      <p>
                        <span className={s.bolder}>Activities: </span>
                        <span className={s.informationContent}>{activities}</span>
                      </p>
                    </div>
                  ) : (
                    ``
                  )}
                  {!_isEmpty(excursions) ? (
                    <div className={s.activitiesAndAdminView}>
                      <p>
                        <span className={s.bolder}>Excursions: </span>
                        <span className={s.informationContent}>{excursions}</span>
                      </p>
                    </div>
                  ) : (
                    ``
                  )}
                  {!_isEmpty(additionals) ? (
                    <div className={s.activitiesAndAdminView}>
                      <p>
                        <span className={s.bolder}>Add Ons: </span>
                        <span className={s.informationContent}>{additionals}</span>
                      </p>
                    </div>
                  ) : (
                    ``
                  )}
                  {!_isEmpty(activityNotes) ? (
                    <div className={s.activitiesAndAdminView}>
                      <p>
                        <span className={s.bolder}>Activities Information: </span>
                        <span className={s.informationContent}>{activityNotes}</span>
                      </p>
                    </div>
                  ) : (
                    ``
                  )}
                </div>
              </div>
            ) : (
              ``
            )}
          </div>
        </div>
      );
    }
  }
}

export default withStyles(reactSelectStyles, s)(ProgramViewAbout);
