import React, { useEffect, useState, memo } from 'react';
import { compose } from 'recompose';
import propTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import FilterDataRange from '../../filterTypes/filterDateRange';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import sFilterAccordion from '../../../../../../public/css/filterAccordion.scss';

export const enhance = compose(withStyles(sFilterAccordion));

function GenericDateFilter(props) {
  const [expanded, setExpanded] = useState(false);
  const [manualExpanded, setManualExpanded] = useState(false);

  useEffect(() => {
    if (props.selectedValues) {
      const isDate = props.selectedValues[1] || props.selectedValues[2] || props.selectedValues[3] ? true : false;
      const calcExpanded = manualExpanded || isDate ? true : false;
      setExpanded(calcExpanded);
    }
  }, [props]);

  const selectedValues = values => {
    props.change(values);
  };

  return (
    <Accordion
      allowMultipleExpanded
      allowZeroExpanded
      onChange={() => {
        setExpanded(!expanded);
        setManualExpanded(!manualExpanded);
      }}
    >
      <AccordionItem uuid={props.id} dangerouslySetExpanded={expanded}>
        <AccordionItemHeading>
          <AccordionItemButton>{props.displayText}</AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          {!!props.selectedValues && (
            <FilterDataRange
              id={props.id}
              yearsIncrease={props.yearsIncrease}
              yearsDecrease={props.yearsDecrease}
              selectedValues={props.selectedValues}
              change={e => selectedValues(e)}
            />
          )}
        </AccordionItemPanel>
      </AccordionItem>
    </Accordion>
  );
}

GenericDateFilter.propTypes = {
  change: propTypes.func.isRequired,
  displayText: propTypes.string,
  id: propTypes.string.isRequired,
  selectedValues: propTypes.array,
};

GenericDateFilter.defaultProps = {
  displayText: 'Date Filter',
};

export default enhance(GenericDateFilter);

export const LastSignInDateMemo = memo(
  compose(withStyles(sFilterAccordion))(function LastSignInDateMemo({ selectedValues = null, onFilterChange }) {
    return (
      <GenericDateFilter
        id={'last_sign_in_date'}
        selectedValues={selectedValues}
        change={values => {
          onFilterChange('last_sign_in_date', values);
        }}
        yearsIncrease={0}
        yearsDecrease={30}
        displayText={'Last Sign In Date'}
      />
    );
  }),
);

export const SignUpDateMemo = memo(
  compose(withStyles(sFilterAccordion))(function SignUpDateMemo({ selectedValues = null, onFilterChange }) {
    return (
      <GenericDateFilter
        id={'sign_up_date'}
        selectedValues={selectedValues}
        change={values => {
          onFilterChange('sign_up_date', values);
        }}
        yearsIncrease={0}
        yearsDecrease={30}
        displayText={'Sign Up Date'}
      />
    );
  }),
);
