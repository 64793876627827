import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const TestimonialQuoteMark = styled.p`
  font-family: 'Nunito', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1.75rem;
  line-height: 1.5rem;
  text-align: center;
  color: #373737;
  margin-bottom: 0;
`;

export default function Testimonial({ testimonial: { review_text, reviewer_details, reviewer_name }, isAdminPreview=false }) {
  return (
    <figure>
      <TestimonialQuoteMark>
        <svg width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
          <path
            d="M2.31697 0.791999C2.55964 0.474666 2.81164 0.297333 3.07297 0.259999C3.35297 0.222666 3.5863 0.278666 3.77297 0.427998C3.9783 0.558666 4.0903 0.763999 4.10897 1.044C4.1463 1.30533 4.0343 1.59467 3.77297 1.912C3.39964 2.37867 3.1383 2.84533 2.98897 3.312C2.8583 3.77867 2.79297 4.19867 2.79297 4.572L2.68097 3.648C3.3343 3.648 3.85697 3.83467 4.24897 4.208C4.65964 4.58133 4.86497 5.08533 4.86497 5.72C4.86497 6.31733 4.6783 6.812 4.30497 7.204C3.93164 7.57733 3.42764 7.764 2.79297 7.764C2.13964 7.764 1.6263 7.54 1.25297 7.092C0.879635 6.644 0.692969 6.01867 0.692969 5.216C0.692969 4.768 0.730302 4.31067 0.804969 3.844C0.898302 3.35867 1.0663 2.864 1.30897 2.36C1.55164 1.856 1.88764 1.33333 2.31697 0.791999ZM7.77697 0.791999C8.01964 0.474666 8.27164 0.297333 8.53297 0.259999C8.81297 0.222666 9.0463 0.278666 9.23297 0.427998C9.4383 0.558666 9.5503 0.763999 9.56897 1.044C9.6063 1.30533 9.4943 1.59467 9.23297 1.912C8.85964 2.37867 8.5983 2.84533 8.44897 3.312C8.3183 3.77867 8.25297 4.19867 8.25297 4.572L8.14097 3.648C8.7943 3.648 9.31697 3.83467 9.70897 4.208C10.1196 4.58133 10.325 5.08533 10.325 5.72C10.325 6.31733 10.1383 6.812 9.76497 7.204C9.39164 7.57733 8.88764 7.764 8.25297 7.764C7.59964 7.764 7.0863 7.54 6.71297 7.092C6.33964 6.644 6.15297 6.01867 6.15297 5.216C6.15297 4.768 6.1903 4.31067 6.26497 3.844C6.3583 3.35867 6.5263 2.864 6.76897 2.36C7.01164 1.856 7.34764 1.33333 7.77697 0.791999Z"
            fill="#373737"
          />
        </svg>
      </TestimonialQuoteMark>
      <blockquote>{review_text}</blockquote>
      <figcaption>
        <p className="heading" style={{fontWeight : isAdminPreview ? "700" : "400" }}>{reviewer_name}</p>
        <p className="subheading">{reviewer_details}</p>
      </figcaption>
    </figure>
  );
}

Testimonial.propTypes = {
  testimonial: PropTypes.shape({
    review_text: PropTypes.string.isRequired,
    reviewer_name: PropTypes.string.isRequired,
    reviewer_details: PropTypes.string.isRequired,
  }).isRequired,
};
