exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@-webkit-keyframes _3ssih{to{-webkit-transform:rotate(1turn)}}@keyframes _3ssih{to{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}._3gWWP ._3D64x{-ms-flex:1 1;flex:1 1;-ms-flex-direction:row;flex-direction:row;display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;padding:16px}@media only screen and (max-width:599px){._3gWWP ._3D64x{-ms-flex-direction:column;flex-direction:column}}._3gWWP ._2hru6{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;padding:40px 100px;-ms-flex-pack:center;justify-content:center}._3gWWP ._2hru6 ._3vSMr{padding:12px}._3gWWP ._1gNJl{-ms-flex:1 1;flex:1 1;-ms-flex-direction:column;flex-direction:column;display:-ms-flexbox;display:flex;border-top:2px solid #ebebeb;padding:30px 20px 0 0;-ms-flex-pack:center;justify-content:center}@media only screen and (max-width:599px){._3gWWP ._1gNJl{-ms-flex-direction:column;flex-direction:column}}._3gWWP ._1gNJl ._2xFbb{padding:20px 0;display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-pack:center;justify-content:center}._3gWWP ._1gNJl ._2xFbb ._2oMbS{padding-left:12px}@media only screen and (max-width:599px){._3gWWP ._1gNJl ._2xFbb ._2oMbS{padding-top:12px}}._3gWWP ._1gNJl .nILuJ{-ms-flex:1 1;flex:1 1}", ""]);

// exports
exports.locals = {
	"success-admin-removed-group-detail": "_3gWWP",
	"subHeader": "_3D64x",
	"content": "_2hru6",
	"toggle": "_3vSMr",
	"footer": "_1gNJl",
	"footer-button-wrapper": "_2xFbb",
	"button-content": "_2oMbS",
	"footer-confirm": "nILuJ",
	"spin": "_3ssih"
};