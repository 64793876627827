exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@-webkit-keyframes _3sUQQ{to{-webkit-transform:rotate(1turn)}}@keyframes _3sUQQ{to{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}._21vxr ._2AN20{-ms-flex:1 1;flex:1 1;-ms-flex-direction:row;flex-direction:row;display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;padding:16px}@media only screen and (max-width:599px){._21vxr ._2AN20{-ms-flex-direction:column;flex-direction:column}}._21vxr .PS9eL{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;padding:40px 100px;-ms-flex-pack:center;justify-content:center}._21vxr .PS9eL ._1s3ym{padding:12px}._21vxr .my5o-{-ms-flex:1 1;flex:1 1;-ms-flex-direction:column;flex-direction:column;display:-ms-flexbox;display:flex;border-top:2px solid #ebebeb;padding:32px 20px 0 0;-ms-flex-pack:center;justify-content:center}@media only screen and (max-width:599px){._21vxr .my5o-{-ms-flex-direction:column;flex-direction:column}}._21vxr .my5o- .hlN9F{padding:20px 0;display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-pack:center;justify-content:center}._21vxr .my5o- .hlN9F ._3FtoF{padding-left:12px}@media only screen and (max-width:599px){._21vxr .my5o- .hlN9F ._3FtoF{padding-top:12px}}._21vxr .my5o- ._3pcT7{-ms-flex:1 1;flex:1 1}", ""]);

// exports
exports.locals = {
	"rejoin-group": "_21vxr",
	"subHeader": "_2AN20",
	"content": "PS9eL",
	"toggle": "_1s3ym",
	"footer": "my5o-",
	"footer-button-wrapper": "hlN9F",
	"button-content": "_3FtoF",
	"footer-confirm": "_3pcT7",
	"spin": "_3sUQQ"
};