import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { withCookies, Cookies } from 'react-cookie';
import { connect } from 'react-redux';
import { compose } from 'redux';

import s from './QuestionModal.scss';

class QuestionModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subject: 'Sample',
      question: '',
    };
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleKeyUp(e) {
    this.setState({ question: e.target.value });
    let textEntered, countRemaining, counter;
    textEntered = document.querySelector('#question').value;
    counter = 2000 - textEntered.length;
    countRemaining = document.getElementById('charactersRemaining');
    countRemaining.textContent = `${counter} characters left`;
  }

  handleSubmit(event) {
    event.preventDefault();
    const { cookies } = this.props;
    const token = cookies.get('token');
    this.props.askQuestion(this.props.id, this.state);
    this.setState({ subject: '', question: '' });
    this.props.handleClose();
  }

  render() {
    const alias_program = this.props.alias_program ? this.props.alias_program : 'Program';
    const showHideClassName = this.props.show ? s.modalDisplay : s.modalDisplayNone;
    const background = this.props.show ? s.backgroundShow : s.backgroundNone;
    return (
      <div id={background}>
        <div id={showHideClassName} className={s.modal}>
          <div className={s.header}>
            <p className={s.questionHeader}>Ask a Question</p>
            <p className={s.closeButton} onClick={this.props.handleClose}>
              X
            </p>
          </div>
          <p className={s.providerName}>{this.props.provider}</p>
          <form onSubmit={this.handleSubmit}>
            <div className={s.textArea}>
              <textarea
                ref="first"
                id="question"
                placeholder={`Ask us a question about this ${alias_program}...`}
                onKeyUp={this.handleKeyUp}
              />
              <div id="charactersRemaining" className={s.charactersRemaining}>
                2000 characters left
              </div>
            </div>
            <div className={s.buttonContainer}>
              <button className={s.cancelButton} type="button" onClick={this.props.handleClose}>
                Cancel
              </button>
              <button className={s.sendButton} type="submit">
                Send
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
export default compose(
  withCookies,
  withStyles(s),
  connect(
    null,
    {},
  ),
)(QuestionModal);
