import React, { Fragment, useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { MuiThemeProvider } from '@material-ui/core/styles';
import styled, { css } from 'styled-components';
import StyledIcon from '../styles/Icon.styled';
import Tooltip from './Tooltip.styled';
import { viaMuiTheme } from '../../shared/helpers/muiTableTheme';
import { stripHtmlString } from '../../shared/helpers/General';

const openSansFont = css`
  font-family: ${({ theme }) => theme.textInput.fontFamilyOpenSans};
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const InputLabel = styled.label`
  color: ${({ theme }) => theme.textInput.label.fontColor};
  font-family: ${({ theme }) => theme.textInput.label.fontFamily};
  font-size: ${({ theme }) => theme.textInput.label.fontSize};
  font-weight: ${({ theme }) => theme.textInput.label.fontWeight};

  ${({ openSans }) => (openSans ? openSansFont : null)};
`;

const Instructions = styled.div`
  color: ${({ theme }) => theme.textInput.instructions.fontColor};
  font-family: ${({ theme }) => theme.textInput.instructions.fontFamily};
  font-size: ${({ theme }) => theme.textInput.instructions.fontSize};
  font-weight: ${({ theme }) => theme.textInput.instructions.fontWeight};
  padding-left: 5px;
  ${({ openSans }) => (openSans ? openSansFont : null)};
`;

const Error = styled.div`
  color: ${({ theme }) => theme.textInput.error.fontColor};
  display: inline-block;
  font-family: ${({ theme }) => theme.textInput.error.fontFamily};
  font-size: ${({ theme }) => theme.textInput.error.fontSize};
  font-weight: ${({ theme }) => theme.textInput.error.fontWeight};
  padding-top: 3px;
  vertical-align: middle;
  ${({ openSans }) => (openSans ? openSansFont : null)} svg {
    justify-content: center;
    margin-right: 5px;

    vertical-align: middle;
    display: inline-block;
  }

  span {
    vertical-align: middle;
    display: inline-block;
  }
`;

const DatePicker = styled(KeyboardDatePicker)`

  .MuiInputBase-formControl.Mui-disabled {
    border: ${({ theme }) => theme.textInput.disabled.border};
    background-color: ${({ theme }) => theme.textInput.disabled.backgroundColor};
    color: ${({ theme }) => theme.textInput.disabled.fontColor};
    cursor: not-allowed;
  }
`;

const Required = styled.span`
  color: ${({ theme }) => theme.defaults.errorColor};
  padding-left: 5px;
`;

const TooltipContainer = styled.span`
  padding-left: 5px;
`;

export default function InlineDatePicker({
  disabled,
  errorMsg,
  icon,
  id,
  label,
  placeholder,
  required,
  tooltip,
  value,
  instructions,
  defaultValue,
  disablePast,
  setValue,
  testing,
  minDate,
  maxDate,
  disableFuture,
  isSynced = false,
  isReadonly = false,
  ...props
}) {
  const [selectedDate, setSelectedDate] = useState(null);
  const customBranding = useSelector(state => state.profile.customBranding.themes);
  let cleanseInstructions = null;
  if (instructions && stripHtmlString(instructions).trim().length > 0) {
    cleanseInstructions = instructions;
  }

  useEffect(() => {
    if (defaultValue) {
      setSelectedDate(defaultValue);
    }
  }, []);

  useEffect(
    () => {
      const dateToSave = moment(selectedDate).isValid() ? moment(selectedDate).format('YYYY-MM-DD') : null;
      if (setValue) {
        setValue(dateToSave);
      }
    },
    [selectedDate],
  );

  return (
    <InputGroup>
      <MuiThemeProvider theme={viaMuiTheme(customBranding)}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Fragment>
            {label &&
              label.length > 0 && (
                <InputLabel htmlFor={id} openSans={openSansFont}>
                  {label}
                  {required && <Required>*</Required>}
                  {tooltip && (
                    <TooltipContainer>
                      <Tooltip toolTipText={tooltip}>
                        <StyledIcon type={'InformationCircle'} size={'14px'} />
                      </Tooltip>
                    </TooltipContainer>
                  )}
                </InputLabel>
              )}
            {cleanseInstructions &&
              cleanseInstructions.length > 0 && (
                <Instructions
                  openSans={openSansFont}
                  dangerouslySetInnerHTML={{
                    __html: cleanseInstructions,
                  }}
                />
              )}

            <DatePicker
              autoOk
              variant="inline"
              inputVariant="outlined"
              openTo="year"
              id={id}
              placeholder={placeholder ? placeholder : 'MM/DD/YYYY'}
              format="MM/DD/yyyy"
              value={selectedDate}
              InputAdornmentProps={{ position: 'end' }}
              onChange={date => setSelectedDate(date)}
              disablePast={disablePast}
              disableFuture={disableFuture}
              disabled={disabled}
              minDateMessage={''}
              keyboardIcon={
                isSynced ? (
                  <StyledIcon type={'Sync'} size={'16px'} color={'#446372'} />
                ) : isReadonly ? (
                  <StyledIcon type={'LockClosed'} size={'16px'} color={'#446372'} />
                ) : (
                  <StyledIcon type={'Form_date'} size={'16px'} color={'#446372'} />
                )
              }
              KeyboardButtonProps={{ 'aria-label': 'Open date picker' }}
              minDate={minDate}
              maxDate={maxDate}
            />
            {errorMsg &&
              errorMsg.length > 0 && (
                <Error openSans={openSansFont}>
                  <StyledIcon type={'Warning'} size={'16px'} />
                  <span>{errorMsg}</span>
                </Error>
              )}
          </Fragment>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    </InputGroup>
  );
}
