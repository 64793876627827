import React from 'react';
import styled from 'styled-components';
import DatePicker from '../../../../../styledComponents/styles/DatePicker.styled';
import { Paragraph } from '../../../../../styledComponents/styles/Paragraph.styled';

const Container = styled.div`
  font-family: 'Nunito', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 0.015em;
  color: #373737;
`;

const InputContainer = styled.div`
  flex: 1;
  margin: 13px 0;
  text-align: left;
`;

const Label = styled.label`
  color: ${({ theme }) => theme.textInput.label.fontColor};
  font-family: ${({ theme }) => theme.textInput.label.fontFamily};
  font-size: ${({ theme }) => theme.textInput.label.fontSize};
  font-weight: ${({ theme }) => theme.textInput.label.fontWeight};
`;

const Required = styled.span`
  color: ${({ theme }) => theme.defaults.errorColor};
  padding-left: 5px;
`;
export default function QuestionDatePickerDisplayPreview({ question }) {
  const updatedIntro = !!question.instructions ? question.instructions.replace(/<p><\/p>/g, '<br />') : '';
  return (
    <Container>
      <InputContainer>
        <Label htmlFor={question.id}>
          {question.label}
          {question.required && <Required>*</Required>}
        </Label>
        <Paragraph
          aria-label={question.label}
          id={'date-picker-instructions'}
          openSans
          dangerouslySetInnerHTML={{
            __html: updatedIntro,
          }}
        />
        <DatePicker disablePast={question.future_only} disabled />
      </InputContainer>
    </Container>
  );
}
