import React, { Component } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

// import { connect } from 'react-redux';
import s from './AccountSuspended.css';
import ServerErrorImage from '../images/serverError.svg';
import PrimaryHeader from '../../../components/PrimaryHeader';

class AccountSuspended extends Component {
  render() {
    return (
      <div>
        <PrimaryHeader />
        <div className={s.backgroundImg}>
          <div className={s.contentDiv}>
            <div className={s.titleOfError}>Account Closed</div>
            <div className={s.messageOfError}>
              This account has been closed. Please contact
              support@via-trm.com.
            </div>
            <img className={s.contentErrorImage} src={ServerErrorImage} />
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(s)(AccountSuspended);
