exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".NkZtx/DraftEditorPlaceholder/root{color:var(fbui-desktop-text-placeholder);position:absolute;z-index:0}.NkZtx/DraftEditorPlaceholder/hasFocus{color:var(fbui-desktop-text-placeholder-focused)}._3eLQy/hidden{display:none}", ""]);

// exports
exports.locals = {
	"public": "NkZtx",
	"DraftEditorPlaceholder": "_3eLQy"
};