import React from 'react';

export default function ClosedEyeIcon() {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
      <path
        d="M14.4769 14.9165C14.4113 14.9166 14.3463 14.9037 14.2856 14.8786C14.225 14.8534 14.1699 14.8165 14.1236 14.7699L3.12818 3.76994C3.0384 3.67541 2.9891 3.54954 2.99076 3.41916C2.99243 3.28877 3.04495 3.1642 3.13711 3.072C3.22927 2.9798 3.35379 2.92727 3.48412 2.9256C3.61444 2.92393 3.74027 2.97326 3.83476 3.06307L14.8302 14.0631C14.9001 14.133 14.9476 14.2221 14.9669 14.319C14.9862 14.416 14.9763 14.5165 14.9385 14.6078C14.9006 14.6991 14.8366 14.7772 14.7545 14.8322C14.6723 14.8871 14.5757 14.9165 14.4769 14.9165Z"
        fill="#373737"
      />
      <path
        d="M8.72917 10.7868L7.11141 9.16839C7.10214 9.15919 7.09022 9.15313 7.07733 9.15106C7.06444 9.14899 7.05123 9.15101 7.03954 9.15683C7.02786 9.16266 7.0183 9.172 7.01219 9.18355C7.00609 9.19509 7.00375 9.20826 7.00552 9.2212C7.07083 9.64109 7.2679 10.0293 7.56825 10.3298C7.8686 10.6303 8.25667 10.8274 8.67638 10.8928C8.68932 10.8945 8.70248 10.8922 8.71402 10.8861C8.72556 10.88 8.7349 10.8704 8.74072 10.8587C8.74655 10.847 8.74857 10.8338 8.7465 10.8209C8.74442 10.808 8.73837 10.7961 8.72917 10.7868Z"
        fill="#373737"
      />
      <path
        d="M9.22903 7.04661L10.8493 8.66692C10.8585 8.67625 10.8705 8.68242 10.8834 8.68457C10.8964 8.68671 10.9097 8.68472 10.9215 8.67887C10.9332 8.67303 10.9428 8.66362 10.9489 8.652C10.9551 8.64037 10.9574 8.62711 10.9555 8.61411C10.8904 8.19366 10.6931 7.80485 10.3924 7.504C10.0917 7.20314 9.70304 7.00584 9.28275 6.94067C9.26974 6.93866 9.25642 6.94082 9.24471 6.94683C9.23299 6.95285 9.22347 6.96241 9.21751 6.97416C9.21156 6.98591 9.20946 6.99924 9.21153 7.01225C9.2136 7.02526 9.21972 7.03729 9.22903 7.04661Z"
        fill="#373737"
      />
      <path
        d="M16.3199 9.45924C16.4233 9.29686 16.478 9.1082 16.4774 8.91566C16.4769 8.72312 16.4211 8.53478 16.3167 8.37299C15.4902 7.09424 14.4178 6.008 13.2158 5.23143C11.8842 4.37144 10.4161 3.91675 8.96916 3.91675C8.20638 3.9178 7.44879 4.04221 6.72571 4.28519C6.70547 4.29192 6.6873 4.30372 6.67291 4.31947C6.65852 4.33522 6.6484 4.3544 6.64352 4.37517C6.63863 4.39594 6.63914 4.41761 6.645 4.43813C6.65086 4.45864 6.66187 4.47732 6.67698 4.49237L8.15262 5.96862C8.16795 5.98398 8.18702 5.99509 8.20794 6.00084C8.22886 6.0066 8.25092 6.0068 8.27195 6.00143C8.77213 5.87949 9.29528 5.88843 9.79101 6.02738C10.2867 6.16633 10.7384 6.43063 11.1024 6.79481C11.4664 7.159 11.7306 7.61081 11.8695 8.10674C12.0084 8.60268 12.0173 9.12604 11.8955 9.62643C11.8901 9.64742 11.8904 9.66942 11.8961 9.69029C11.9019 9.71117 11.9129 9.73019 11.9282 9.74549L14.0508 11.8705C14.0729 11.8926 14.1025 11.9057 14.1337 11.9071C14.1649 11.9084 14.1955 11.8981 14.2195 11.878C15.0365 11.1813 15.7445 10.366 16.3199 9.45924Z"
        fill="#373737"
      />
      <path
        d="M8.97914 11.9169C8.52518 11.9169 8.07715 11.8138 7.66881 11.6154C7.26048 11.417 6.9025 11.1284 6.62187 10.7714C6.34123 10.4145 6.14528 9.99843 6.04876 9.55467C5.95225 9.11091 5.95771 8.65102 6.06472 8.20968C6.07003 8.18869 6.06979 8.16668 6.06404 8.14581C6.05829 8.12494 6.04722 8.10592 6.03192 8.09061L3.94403 6.00093C3.92189 5.97875 3.89224 5.96571 3.86094 5.96438C3.82964 5.96305 3.79898 5.97353 3.77504 5.99374C3.01317 6.64405 2.3069 7.4353 1.66123 8.35999C1.54821 8.52227 1.48596 8.71451 1.4824 8.91226C1.47884 9.11002 1.53413 9.30437 1.64123 9.47061C2.46621 10.7622 3.52764 11.85 4.71122 12.6159C6.04473 13.4794 7.47726 13.9169 8.96914 13.9169C9.73961 13.9148 10.5051 13.793 11.2382 13.5559C11.2586 13.5494 11.277 13.5377 11.2916 13.522C11.3062 13.5063 11.3166 13.4872 11.3216 13.4663C11.3267 13.4455 11.3263 13.4237 11.3205 13.403C11.3147 13.3824 11.3037 13.3636 11.2885 13.3484L9.80567 11.8653C9.79037 11.85 9.77136 11.8389 9.7505 11.8332C9.72964 11.8274 9.70764 11.8272 9.68666 11.8325C9.45501 11.8886 9.21749 11.917 8.97914 11.9169Z"
        fill="#373737"
      />
    </svg>
  );
}
