import React from 'react';
import { compose } from 'recompose';
import classnames from 'classnames';
import propTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import sParagraph from './paragraph.scss';

export const enhance = compose(withStyles(sParagraph));

function Paragraph(props) {
  return (
    <div className={sParagraph.paragraph}>
      <p
        style={props?.customStyle}
        role={props.role ? props.role : null}
        aria-live={props.ariaLive ? props.ariaLive : null}
        className={classnames(
          sParagraph[props.size],
          props.italic ? sParagraph['italic'] : '',
          props.bold ? sParagraph['bold'] : '',
          props.demiBold ? sParagraph['demiBold'] : '',
          props.avenirNextMedium ? sParagraph['avenirNextMedium'] : '',
          sParagraph[props.color],
          props.centered ? sParagraph['centered'] : '',
        )}
      >
        {props.children}
      </p>
    </div>
  );
}

Paragraph.propTypes = {
  size: propTypes.oneOf(['small', 'normal', 'medium', 'large', 'x-large']),
  italic: propTypes.bool,
  bold: propTypes.bool,
  demiBold: propTypes.bool,
  avenirMedium: propTypes.bool,
  color: propTypes.oneOf([
    'light',
    'dark',
    'gray',
    'secondary',
    'error',
    'primary',
    'white',
    'secondaryLight',
    'warning',
  ]),
  centered: propTypes.bool,
  warning: propTypes.bool,
};

Paragraph.defaultProps = {
  size: 'medium',
  italic: false,
  bold: false,
  color: 'dark',
  centered: false,
  demiBold: false,
  avenirMedium: false,
};

export default enhance(Paragraph);
