exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@-webkit-keyframes _3eVhR{to{-webkit-transform:rotate(1turn)}}@keyframes _3eVhR{to{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}._2DWLg{display:-ms-flexbox;display:flex}._2DWLg img{padding-left:20px;cursor:pointer}", ""]);

// exports
exports.locals = {
	"wrapper": "_2DWLg",
	"spin": "_3eVhR"
};