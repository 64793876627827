import PropTypes from 'prop-types';
import { orderBy, uniq } from 'lodash';
import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';

import ProfileSelectInput from '../../../base/inputs/ProfileSelectInput';

export default function ProgramOrganizationNameFilter({ value, handleOnChange }) {
  const { programs, selectedFilters } = useSelector(state => state.programDashboard);

  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (selectedFilters.organizationName.length > 0) {
      handleOnChange(selectedFilters.organizationName);
    }
  }, []);

  useEffect(
    () => {
      if (programs.length > 0) {
        const options = programs
          .filter(program => program.attributes.organization_name)
          .map(program => program.attributes.organization_name);

        const orderedOptions = orderBy(uniq(options), option => option.toLowerCase(), ['asc']).map(option => ({
          label: option,
          value: option,
        }));

        setOptions(orderedOptions);
      }
    },
    [programs],
  );

  return (
    <ProfileSelectInput
      id="organization_name"
      value={value}
      label="Organization Name"
      placeholder="Select Organization(s)"
      options={options}
      isMulti
      handleOnChange={handleOnChange}
    />
  );
}

ProgramOrganizationNameFilter.propTypes = {
  handleOnChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]).isRequired,
};
