import React, { lazy, Suspense, useEffect, useState } from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import _get from 'lodash/get';
import { Controller, useForm, useFieldArray } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import StyledIcon from '../../../../../styledComponents/styles/Icon.styled';
import TextInput from '../../../../../styledComponents/styles/TextInput.styled';
import Toggle from '../../../../../styledComponents/styles/Toggle.styled';
import { AccentButtonMediumNunito } from '../../../../../styledComponents/styles/Buttons.styled';
import { AccentButtonSpinner } from '../../../../../styledComponents/styles/spinner.styled';
import { updateForm, updateQuestion } from '../../../../../actions/formsActions';
import OptionFields from './question.optionFIelds.addEdit'; 


const EditTextArea = lazy(() => import('../../../../../styledComponents/styles/EditTextArea.styled'));

const FormContainer = styled.div`
  font-family: 'Nunito', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 0.015em;
  color: #373737;
`;

const InputContainer = styled.div`
  flex: 1;
  margin: 20px 0;
  text-align: left;
`;


const SubmitContainer = styled.main`
  display: flex;
  justify-content: flex-end;
  margin-top: 35px;
`;

const ToggleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: inherit;
`;

const ToggleLabel = styled.span`
  align-self: center;
  flex: 1;
  justify-content: center;
  padding-left: 10px;
  padding-bottom: 7px;
`;

const InfoMsg = styled.div`
  background-color: #deecff;
  border-left: 20px solid #5180b1;
  border-radius: 5px;
  color: #4d4d4d;
  line-height: 17px;
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 20px;
  width: 100%;
`;


export default function QuestionMultipleChoiceAddEdit({ question, edit, onClose }) {
  const MAX_LENGTH = 90;
  const qIdentifier = 'multiple_choice';
  const dispatch = useDispatch();
  const fetchForm = useSelector(state => state.forms.fetchForm);
  const questionTypesInfo = useSelector(state => state.forms.questionTypes);
  const updateFormSubmit = useSelector(state => state.forms.updateForm);
  const updateQuestionSubmit = useSelector(state => state.forms.updateQuestion);
  const [formId, setFormId] = useState(null);
  const customAliases = useSelector(state => state.profile.customAliases);
  const isTravelerInfo = ['travelerinfofield', 'customfield'].includes(question?.sourceable_type?.toLowerCase());
  const [focusId, setFocusId] = useState(null);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [multipleChoiceOptions, setMultipleChoiceOptions] = useState([]);
  const schema = yup.object().shape({
    multiple_choice_label: yup
      .string()
      .required('Question must have label')
      .max(MAX_LENGTH, `Label can not be more than ${MAX_LENGTH} characters`),
    multiple_choice_instructions: yup.string(),
    multiple_choice_question_options_attributes: yup.array().of(
      yup.object().shape({
        //value: yup.string().test('len', 'Option value is required', val => val.length > 0),
        value: yup.string().required('Option value is required'),
      }),
    ),
    multiple_choice_admin_only: yup.boolean(),
    multiple_choice_required: yup.boolean(),
  });

  // make sure options are sorted
  if (question && question.multiple_choice_question_options) {
    question.multiple_choice_question_options.sort((a, b) => {
      if (a.order < b.order) return -1;
      else if (a.order > b.order) return 1;
      return 0;
    });
  }

  const { control, handleSubmit, formState, setValue, getValues } = useForm({
    shouldUnregister: false,
    resolver: yupResolver(schema),
    defaultValues: {
      multiple_choice_label: edit ? question.label : '',
      multiple_choice_instructions: edit ? question.instructions || '' : '',
      multiple_choice_question_options_attributes: edit
        ? question.multiple_choice_question_options
            .map(opt => {
              return { value: opt.name, order: opt.order, optId: opt.id };
            })
            .sort((a, b) => {
              if (a.order < b.order) return -1;
              else if (a.order > b.order) return 1;
              return 0;
            })
        : [{ value: 'Option 1', order: 0, optId: '' }],
      multiple_choice_admin_only: false,
      multiple_choice_required: edit ? question.required : true,
    },
    mode: 'onChange',
  });

  const { fields, append, swap, prepend, remove } = useFieldArray({
    control,
    name: 'multiple_choice_question_options_attributes',
  });

  useEffect(() => {
    if (!fetchForm?.loading && fetchForm?.data) {
      setDisableSubmit(false);
      setFormId(fetchForm.data.id);
    }
  }, [fetchForm]);

  useEffect(() => {
    if (
      (!updateFormSubmit?.loading && updateFormSubmit?.data) ||
      (!updateQuestionSubmit?.loading && updateQuestionSubmit?.data)
    ) {
      setDisableSubmit(false);
      onClose();
    }
  }, [updateFormSubmit, updateQuestionSubmit]);

  useEffect(() => {
    if (updateQuestionSubmit && updateQuestionSubmit.data && !updateQuestionSubmit.loading) {
      let options = [];
      updateQuestionSubmit.data.attributes.question_options.forEach(opt => {
        options.push({ value: opt.name, order: opt.order, optId: opt.id });
      });
      options.sort((a, b) => {
        if (a.order < b.order) return -1;
        else if (a.order > b.order) return 1;
        return 0;
      });

      setValue(`multiple_choice_question_options_attributes`, options);
    }
  }, [updateQuestionSubmit]);

  useEffect(() => {
    // Focus on the newly added multiple choice option item
    if (focusId) {
      document.getElementById(focusId)?.focus();
      //clear it out of state
      setFocusId(null);
    }
  }, [focusId]);

  useEffect(() => {
    //Focus on the first element in the editor
    setFocusId('multiple_choice_label');
  }, []);

  const onSubmitHandler = data => {
    //data.multiple_choice_question_options_attributes = multipleChoiceOptions;
    if (questionTypesInfo && questionTypesInfo.data) {
      const questionType = questionTypesInfo.data.reduce((prev, curr) => {
        return prev || curr.attributes.question_types.find(question => question.identifier === qIdentifier);
      }, undefined);

      const deletedOptions = data.multiple_choice_question_options_attributes
        .filter(item => item._destroy && item.optId > 0)
        .map(item => {
          return {
            id: item.optId < 1 ? '' : item.optId,
            order: 0,
            _destroy: true,
          };
        });

      let updateOptions = data.multiple_choice_question_options_attributes
        .filter(item => !item._destroy)
        .map((item, index) => {
          return {
            name: item.value,
            order: item.order,
            id: item.optId < 1 ? '' : item.optId,
          };
        });

      const optsToSave = updateOptions.concat(deletedOptions);

      let orderCount = 1;
      optsToSave.forEach(item => {
        item.order = orderCount;
        orderCount++;
      });

      const values = {
        admin_only: false,
        multiple_choice_question_options_attributes: optsToSave,
        instructions: data.multiple_choice_instructions,
        label: data.multiple_choice_label,
        required: data.multiple_choice_required,
      };

      if (formId && data && (questionType?.id || question?.id)) {
        //update or save new
        const actionToCall = edit ? updateQuestion : updateForm;
        const idToUpdate = edit ? question.id : questionType.id;
        setDisableSubmit(true);
        dispatch(actionToCall(formId, questionType.endpoint, idToUpdate, values));
      }
    }
  };

  const createOption = nextOrder => {
    append({ optId: -nextOrder, value: '', order: nextOrder });
    setFocusId(`multiple_choice_question_options_attributes[${nextOrder - 1}]`);
  };



  return (
    <FormContainer>
      <form id="multipleChoiceAdd" onSubmit={handleSubmit(onSubmitHandler)}>
        {isTravelerInfo && (
          <TextInput
            aria-label="Traveler Info Field"
            errorMsg=""
            id="readonly_label"
            label="Traveler Info Field"
            openSans
            placeholder={question?.sourceable_attributes?.display_name}
            readOnly
            disabled
            icon="Sync"
          />
        )}
        <Controller
          control={control}
          name="multiple_choice_label"
          render={({ field: { ref, ...rest } }) => (
            <InputContainer>
              <TextInput
                aria-label="Multiple Choice Label"
                errorMsg={_get(formState.errors.multiple_choice_label, 'message') || ''}
                id="multiple_choice_label"
                label={isTravelerInfo ? 'Label' : 'Multiple Choice Label'}
                openSans
                placeholder="Enter Label"
                required
                {...rest}
              />
            </InputContainer>
          )}
        />
        {!isTravelerInfo && (
          <>
            <Controller
              control={control}
              name="multiple_choice_instructions"
              render={({ field: { ref, ...rest } }) => (
                <InputContainer>
                  <Suspense fallback={<div />}>
                    <EditTextArea
                      aria-label="Instructions"
                      errorMsg={_get(formState.errors.multiple_choice_instructions, 'message') || ''}
                      placeholder="Enter Instructions"
                      label="Instructions"
                      id="multiple_choice_instructions"
                      {...rest}
                    />
                  </Suspense>
                </InputContainer>
              )}
            />

          <OptionFields
            formState={formState}
            control={control}
            fields={fields}
            setValue={setValue}
            createOption={createOption}
            moveCard={(dragIndex, hoverIndex) => {
              swap(dragIndex, hoverIndex)
            }}
          />
          </>
        )}
        {isTravelerInfo && (
          <InfoMsg>
            {`${customAliases.alias_traveler} info fields will be auto-populated 
            from ${customAliases.alias_traveler.toLowerCase()}’s profile 
            if the ${customAliases.alias_traveler.toLowerCase()} has filled 
            out their profile.`}
          </InfoMsg>
        )}
        <Controller
          control={control}
          name="multiple_choice_required"
          render={({ field: { onChange, register, ref, ...rest } }) => (
            <ToggleContainer>
              <Toggle
                id="multiple_choice_required"
                label="Required"
                onChange={value => {
                  setValue('multiple_choice_required', value, {
                    shouldValidate: true,
                    shouldDirty: true,
                    shouldTouch: true,
                  });
                }}
                ref={register}
                {...rest}
              />
              <ToggleLabel>Required Field</ToggleLabel>
            </ToggleContainer>
          )}
        />
        <SubmitContainer>
          <AccentButtonMediumNunito
            aria-label={edit ? 'Save Multiple Choice Text' : 'Add Multiple Choice to Form'}
            form="multipleChoiceAdd"
            type="submit"
            disabled={disableSubmit}
          >
            {disableSubmit ? <AccentButtonSpinner displayText="Saving ..." /> : edit ? 'Save' : 'Add to Form'}
            {!disableSubmit && <StyledIcon type="ChevronForward" color="#fff" />}
          </AccentButtonMediumNunito>
        </SubmitContainer>
      </form>
    </FormContainer>
  );
}
