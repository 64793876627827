exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@-webkit-keyframes _2ck5P{to{-webkit-transform:rotate(1turn)}}@keyframes _2ck5P{to{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}._390Ka{display:-ms-inline-flexbox;display:inline-flex}._3ytAh{fill:none}._1fl5x{fill:#fff}.IDb0-{fill:#000}._9CJg0{fill:#df235d}._2NCBz{fill:#1c748a}._36jxL{fill:#043544}._3yHmR{fill:#219653}.qruUQ{fill:#8b1313}._3ddzP{fill:#1c748a}._2ltRQ{fill:#043544}._2o1bJ{fill:#767676}._3KHBL{fill:#373737}._3Guuj{width:11px;height:11px}._3Qtpm{width:16px;height:16px}._3MtKt{width:28px;height:16px}._3bbKi{width:20px;height:20px}._3euwF{width:32px;height:20px}.yZFw3{width:32px;height:32px}._KYwR{width:24px;height:24px}._1goNP{width:44px;height:32px}._1fyDE{width:14px;height:14px}._34OqW{width:26px;height:14px}._3o7PS{width:48px;height:48px}._2tJMQ{width:60px;height:48px}._1SiIQ{width:112px;height:100px}.q8fnJ{width:100px;height:100px}", ""]);

// exports
exports.locals = {
	"via-icon": "_390Ka",
	"noFill": "_3ytAh",
	"white": "_1fl5x",
	"black": "IDb0-",
	"primary": "_9CJg0",
	"secondary": "_2NCBz",
	"secondary-darkest": "_36jxL",
	"green": "_3yHmR",
	"error": "qruUQ",
	"navigation": "_3ddzP",
	"text": "_2ltRQ",
	"gray": "_2o1bJ",
	"darkGray": "_3KHBL",
	"nano": "_3Guuj",
	"xsmall": "_3Qtpm",
	"xsmallPlusW10": "_3MtKt",
	"small": "_3bbKi",
	"smallPlusW10": "_3euwF",
	"medium": "yZFw3",
	"icon24": "_KYwR",
	"mediumPlusW10": "_1goNP",
	"mini": "_1fyDE",
	"miniPlusW10": "_34OqW",
	"large": "_3o7PS",
	"largePlusW10": "_2tJMQ",
	"xlarge": "_1SiIQ",
	"xlargePlusW10": "q8fnJ",
	"spin": "_2ck5P"
};