import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './ProgramAdditionalDocuments.css';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { saveNewDocument, getProgram } from '../../../../actions/programBrochureActions';
import Button from '../../../../sites/components/library/button';
import { saveNewAdminDocument } from '../../../../actions/programSettingsActions';
import {
  fileType,
  errorFileExtension,
  errorFileSize,
  errorFileNameBlank,
} from '../../../../shared/helpers/UploaderMessages';

class ProgramFileUploader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: '',
      error: '',
      fileTitle: '',
      saved: '',
      disabled: true,
    };
    this.handleTitleChange = this.handleTitleChange.bind(this);
  }

  handleChange(e) {
    e.persist();
    this.setState({
      disabled: false,
    });

    const reader = new FileReader();
    const file = e.target.files[0];

    if (file) {
      const extension = file.name
        .split('.')
        .pop()
        .toLowerCase();

      const isSuccess = fileType.indexOf(extension) > -1;
      if (!isSuccess) {
        this.setState({
          error: errorFileExtension,
          fileTitle: '',
        });
      } else if (file.size > 10485760) {
        this.setState({
          error: errorFileSize,
          fileTitle: '',
        });
      } else {
        reader.onloadend = () => {
          this.setState({
            file,
            fileTitle: file.name,
            error: '',
          });
        };
        file ? reader.readAsDataURL(file) : null;
      }
    }
  }

  handleTitleChange(e) {
    this.setState({
      fileTitle: e.target.value,
    });
  }

  saveNewUpload = () => {
    if (this.state.fileTitle === '') {
      this.setState({
        error: errorFileNameBlank,
      });
    } else {
      this.showSavedAlert();
      this.props.source !== 'admin_document'
        ? this.props.saveNewDocument({
            program_id: this.props.id,
            title: this.state.fileTitle,
            file: this.state.file,
          })
        : this.props.saveNewAdminDocument({
            program_id: this.props.id,
            title: this.state.fileTitle,
            file: this.state.file,
          });

      this.setState({
        file: null,
        fileTitle: '',
        error: '',
      });

      this.props.source !== 'admin_document' ? this.props.getProgram(this.props.id, 'client') : null;
    }
  };

  showSavedAlert = () => {
    this.setState({
      saved: 'File is saved! It will appear below in just a moment.',
    });

    setTimeout(
      () =>
        this.setState({
          saved: '',
        }),
      2500,
    );
  };

  cancelUpload = () => {
    this.setState({
      file: null,
      fileTitle: '',
    });
  };

  render() {
    return (
      <div className={s.avatarUpload}>
        {!this.state.file && (
          <div>
            <label className={s.uploadLogo}>
              <span>BROWSE FOR FILE</span>
              <svg width="20px" height="20px" viewBox="0 0 20 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <g id="Admin-View-of-Settings" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <g
                    id="Admin-View-of-Settings:-Organization-DD-Avatar"
                    transform="translate(-922.000000, -2140.000000)"
                    fill="#043544"
                    stroke="#043544"
                  >
                    <g id="btn" transform="translate(727.000000, 2120.000000)">
                      <g id="SMALL-TEXT" transform="translate(27.000000, 21.000000)">
                        <path
                          d="M178.840563,19.3679667 L185.751745,11.992427 L186.946081,13.119079 L178.011284,22.6541321 L169.294722,13.1037877 L170.503821,11.9930242 L177.199786,19.3293687 L177.199786,0.5 L178.840563,0.5 L178.840563,19.3679667 Z"
                          id="Path"
                          transform="translate(178.120243, 11.458865) scale(1, -1) translate(-178.120243, -11.458865) "
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
              <input type="file" name={name} style={{ display: 'none' }} onChange={e => this.handleChange(e)} />
            </label>
          </div>
        )}
        {this.state.file && (
          <div>
            <label className={s.documentName}>New File Display Name</label>
            <input
              className={s.handleTitleChangeInput}
              type="text"
              value={this.state.fileTitle}
              onChange={this.handleTitleChange}
            />
            <button className={s.saveActionButton} onClick={this.saveNewUpload} disabled={this.state.disabled}>
              Save
            </button>
            <button className={s.cancelActionButton} onClick={this.cancelUpload}>
              Cancel
            </button>
          </div>
        )}
        <p className={s.uploadErrorMessage}>
          {this.state.saved ? (
            <React.Fragment>
              <span style={{ marginLeft: '4px', color: 'green' }}>{this.state.saved}</span>
            </React.Fragment>
          ) : null}
        </p>
        <p className={s.uploadErrorMessage}>
          {this.state.error ? (
            <React.Fragment>
              <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <g id="Traveler-View" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <g id="Program-Application-(Traveler-View)" transform="translate(-844.000000, -2271.000000)">
                    <g id="1.-Personal-Information" transform="translate(125.000000, 1119.000000)">
                      <g id="info" transform="translate(720.000000, 1146.000000)">
                        <g id="Group-3">
                          <circle id="Oval-Copy" stroke="#FFFFFF" strokeWidth="2" fill="#DD2121" cx="9" cy="16" r="9" />
                          <text
                            id="!"
                            fontFamily="Intro-Bold-Italic, Intro Bold Italic"
                            fontSize="12"
                            fontStyle="italic"
                            fontWeight="bold"
                            linespacing="33"
                            fill="#FFFFFF"
                          >
                            <tspan x="7" y="20">
                              !
                            </tspan>
                          </text>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
              <span style={{ marginLeft: '4px', color: 'red' }}>{this.state.error}</span>
            </React.Fragment>
          ) : null}
        </p>
      </div>
    );
  }
}

export default compose(
  withStyles(s),
  connect(
    null,
    { saveNewDocument, getProgram, saveNewAdminDocument },
  ),
)(ProgramFileUploader);
