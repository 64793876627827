import React,{useEffect} from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import ProgramSearch from './ProgramSearch';
import ProgramSorter from './ProgramSorter';

import { breakpoints } from '../../utils/breakpoints';

const ScreenReaderHeading = styled.div`
  position: absolute;
  width: 0.0625rem;
  height: 0.0625rem;
  padding: 0;
  margin: -0.0625rem;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.875rem;

  @media ${breakpoints.mobile} {
    margin-bottom: 1.5rem;
    display: block;
  }

  @media ${breakpoints.tablet} {
    margin-bottom: 1.5rem;
    display: flex;
  }

  @media ${breakpoints.desktop} {
    margin-bottom: 1.5rem;
    display: flex;
  }
`;

const Seperator = styled.div`
  width: 30%;
  display: block;

  @media ${breakpoints.mobile} {
   display: none;
  }

  @media ${breakpoints.tablet} {
    width: 100%;
    display: block;
  }


  @media screen and (min-width: 749px) and (max-width: 1100px) {
    margin-top: 42px;
  }
  

  @media ${breakpoints.desktop} {
    width: 30%;
    display: block;
  }
`;

const Organization = styled.div`
  display: none;

  @media ${breakpoints.desktop} {
    display: flex;
    align-items: center;
    gap: 0.875rem;
  }

  img {
    width: 52px;
    height: 52px;
    padding: 1px;
    border: 3px solid var(--theme_color_dark);
    border-radius: 50%;
  }

  span {
    font-family: 'Nunito', sans-serif;
    font-weight: 700;
    font-size: 1rem;
    color: #000000;
    letter-spacing: 0.04em;
    line-height: 120%;
  }
`;

export default function ProgramDashboardHeader() {
  const {
    organizationName,
    brandingTheme: { logo },
  } = useSelector(state => state.currentOrganization);

  useEffect(()=>{
    document.getElementById("program-dashboard-heading").focus();
  },[])

  return (
    <div>
      <ScreenReaderHeading id="program-dashboard-heading" tabIndex="0">Program Dashboard</ScreenReaderHeading>
      <Container>
        <ProgramSearch />
        <Seperator>
          <ProgramSorter />
        </Seperator>
        {/* <Organization>
          <span>{organizationName}</span>
          <img src={logo.theme_logo} alt="" />
        </Organization> */}
      </Container>
    </div>
  );
}
