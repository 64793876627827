import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Accordion, AccordionItem, AccordionItemState, AccordionItemHeading } from 'react-accessible-accordion';

import FormsTable from './FormsTable';
import ChevronUpIcon from '../../icons/ChevronUpIcon';
import ChevronDownIcon from '../../icons/ChevronDownIcon';
import { ItemButton, ItemHeadingContainer, ItemPanel } from './ProgramDetailsAccordion.styled';

const NoFormsInfo = styled.p`
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  color: #373737;
  text-align: center;
  letter-spacing: 0.0015em;
  line-height: 120%;
`;

export default function FormsAccordion() {
  const travelerSubmission = useSelector(state => state.forms.travelerSubmission);
  const [submission, setSubmission] = useState(travelerSubmission?.data || {});

  useEffect(() => {
    if (!travelerSubmission.loading && travelerSubmission.data) {
      setSubmission(travelerSubmission.data);
    }
  }, [travelerSubmission]);

  return (
    <Accordion allowZeroExpanded preExpanded={['form-details']}>
      <AccordionItem uuid="form-details">
        <ItemHeadingContainer>
          <span>My Forms</span>
          <AccordionItemHeading aria-level={3}>
            <ItemButton aria-label="Toggle Form Details">
              <AccordionItemState>
                {({ expanded }) => (expanded ? <ChevronUpIcon /> : <ChevronDownIcon />)}
              </AccordionItemState>
            </ItemButton>
          </AccordionItemHeading>
        </ItemHeadingContainer>
        <ItemPanel>
          {submission.corresponding_forms?.length > 0 ? (
            <FormsTable />
          ) : (
            <NoFormsInfo tabIndex={0}>You have no forms available at this time, check back soon.</NoFormsInfo>
          )}
        </ItemPanel>
      </AccordionItem>
    </Accordion>
  );
}
