import React, { lazy, Suspense, useEffect, useState } from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import StyledIcon from '../../../../../styledComponents/styles/Icon.styled';
import { AccentButtonMediumNunito } from '../../../../../styledComponents/styles/Buttons.styled';
import { AccentButtonSpinner } from '../../../../../styledComponents/styles/spinner.styled';
import { updateForm, updateQuestion } from '../../../../../actions/formsActions';
import _get from 'lodash/get';

const EditTextArea = lazy(() => import('../../../../../styledComponents/styles/EditTextArea.styled'));

const FormContainer = styled.div`
  font-family: 'Nunito', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 0.015em;
  color: #373737;
`;

const InputContainer = styled.div`
  flex: 1;
  margin: 13px 0;
  text-align: left;
`;

const SubmitContainer = styled.main`
  display: flex;
  justify-content: flex-end;
  margin-top: 35px;
`;

export default function QuestionParagraphAddEdit({ question, edit, onClose }) {
  const qIdentifier = 'paragraph';
  const dispatch = useDispatch();
  const fetchForm = useSelector(state => state.forms.fetchForm);
  const questionTypesInfo = useSelector(state => state.forms.questionTypes);
  const updateFormSubmit = useSelector(state => state.forms.updateForm);
  const updateQuestionSubmit = useSelector(state => state.forms.updateQuestion);
  const [formId, setFormId] = useState(null);
  const [disableSubmit, setDisableSubmit] = useState(false);

  const schema = yup.object().shape({
    paragraph_text: yup.string().required('Paragraph must be entered'),
  });

  const { control, handleSubmit, formState } = useForm({
    shouldUnregister: false,
    resolver: yupResolver(schema),
    defaultValues: {
      paragraph_text: edit ? question.text : '',
    },
    mode: 'onChange',
  });

  useEffect(() => {
    if (!fetchForm?.loading && fetchForm?.data) {
      setDisableSubmit(false);
      setFormId(fetchForm.data.id);
    }
  }, [fetchForm]);

  useEffect(() => {
    if (
      (!updateFormSubmit?.loading && updateFormSubmit?.data) ||
      (!updateQuestionSubmit?.loading && updateQuestionSubmit?.data)
    ) {
      setDisableSubmit(false);
      onClose();
    }
  }, [updateFormSubmit, updateQuestionSubmit]);

  const onSubmitHandler = data => {
    if (questionTypesInfo && questionTypesInfo.data) {
      const questionType = questionTypesInfo.data.reduce((prev, curr) => {
        return prev || curr.attributes.question_types.find(question => question.identifier === qIdentifier);
      }, undefined);

      const values = {
        text: data.paragraph_text,
      };

      if (formId && data && (questionType?.id || question?.id)) {
        //update or save new
        const actionToCall = edit ? updateQuestion : updateForm;
        const idToUpdate = edit ? question.id : questionType.id;
        setDisableSubmit(true);
        dispatch(actionToCall(formId, questionType.endpoint, idToUpdate, values));
      }
    }
  };

  return (
    <FormContainer>
      <form id={'paragraphAdd'} onSubmit={handleSubmit(onSubmitHandler)}>
        <Controller
          control={control}
          name={'paragraph_text'}
          render={({ field: { ref, ...rest } }) => (
            <InputContainer>
              <Suspense fallback={<div />}>
                <EditTextArea
                  aria-label="Paragraph"
                  errorMsg={_get(formState.errors.paragraph_text, 'message') || ''}
                  placeholder={`Enter Instructions`}
                  label={`Paragraph Text`}
                  id={'paragraph_text'}
                  required
                  {...rest}
                />
              </Suspense>
            </InputContainer>
          )}
        />
        <SubmitContainer>
          <AccentButtonMediumNunito
            aria-label={edit ? 'Save Header' : 'Add Paragraph to Form'}
            form="paragraphAdd"
            type={'submit'}
            disabled={disableSubmit}
          >
            {disableSubmit ? <AccentButtonSpinner displayText='Saving ...' /> : edit ? 'Save' : 'Add to Form'}
            {!disableSubmit && <StyledIcon type='ChevronForward' color='#fff' />}
          </AccentButtonMediumNunito>
        </SubmitContainer>
      </form>
    </FormContainer>
  );
}
