import React from 'react';
import PropTypes from 'prop-types';

export default function RedoArrowIcon({ fillColor }) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
      <path
        d="M8 0C3.58885 0 0 3.58923 0 8C0 12.4177 3.58231 16 8 16C12.4112 16 16 12.4112 16 8C16 3.58192 12.4181 0 8 0ZM8 12.0385C7.04114 12.0385 6.12155 11.6576 5.44354 10.9795C4.76552 10.3015 4.38462 9.38194 4.38462 8.42308C4.38462 7.46422 4.76552 6.54463 5.44354 5.86661C6.12155 5.1886 7.04114 4.80769 8 4.80769H8.16192L7.61923 4.26538C7.51825 4.1644 7.46151 4.02743 7.46151 3.88462C7.46151 3.7418 7.51825 3.60483 7.61923 3.50385C7.72022 3.40286 7.85718 3.34613 8 3.34613C8.14282 3.34613 8.27978 3.40286 8.38077 3.50385L9.91923 5.04231C9.96924 5.09231 10.0089 5.15167 10.036 5.217C10.063 5.28234 10.077 5.35236 10.077 5.42308C10.077 5.49379 10.063 5.56382 10.036 5.62915C10.0089 5.69448 9.96924 5.75384 9.91923 5.80385L8.38077 7.34231C8.27978 7.44329 8.14282 7.50003 8 7.50003C7.85718 7.50003 7.72022 7.44329 7.61923 7.34231C7.51825 7.24132 7.46151 7.10435 7.46151 6.96154C7.46151 6.81872 7.51825 6.68176 7.61923 6.58077L8.31154 5.88846C8.22 5.88462 8.11539 5.88462 8 5.88462C7.49794 5.88462 7.00715 6.03349 6.58971 6.31242C6.17226 6.59135 5.8469 6.98781 5.65477 7.45165C5.46264 7.91549 5.41237 8.42589 5.51031 8.91831C5.60826 9.41072 5.85003 9.86303 6.20504 10.218C6.56005 10.5731 7.01236 10.8148 7.50477 10.9128C7.99718 11.0107 8.50758 10.9604 8.97143 10.7683C9.43527 10.5762 9.83172 10.2508 10.1107 9.83337C10.3896 9.41592 10.5385 8.92514 10.5385 8.42308C10.5385 8.28027 10.5952 8.14331 10.6962 8.04233C10.7972 7.94135 10.9341 7.88462 11.0769 7.88462C11.2197 7.88462 11.3567 7.94135 11.4577 8.04233C11.5587 8.14331 11.6154 8.28027 11.6154 8.42308C11.6143 9.38159 11.233 10.3005 10.5552 10.9783C9.87745 11.6561 8.95852 12.0373 8 12.0385Z"
        fill={fillColor}
      />
    </svg>
  );
}

RedoArrowIcon.propTypes = {
  fillColor: PropTypes.string,
};

RedoArrowIcon.defaultProps = {
  fillColor: 'currentColor',
};
