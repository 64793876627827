exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@-webkit-keyframes _2y5Oh{to{-webkit-transform:rotate(1turn)}}@keyframes _2y5Oh{to{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}._2fO-g{-ms-flex:1 1;flex:1 1;width:100%;line-height:140%;font-family:AvenirNextRegular,sans-serif;font-weight:500}._2fO-g._1XYLO{border:1px solid #8b1313}._2fO-g._2V6ww{font-size:16px}._2fO-g._3wM8g{font-size:14px}._2fO-g::-webkit-input-placeholder{color:#767676!important}._2fO-g::-moz-placeholder{line-height:140%;color:#767676!important;font-style:italic;font-family:AvenirNextRegular,sans-serif;font-size:14px;opacity:1;padding-left:4px;padding-top:4px}._2fO-g::placeholder{line-height:140%;color:#767676!important;font-style:italic;font-family:AvenirNextRegular,sans-serif;font-size:14px;opacity:1;padding-left:4px;padding-top:4px}._2fO-g::-ms-input-placeholder{line-height:140%;color:#767676!important;font-style:italic;font-family:AvenirNextRegular,sans-serif;font-size:14px;opacity:1;padding-left:4px;padding-top:4px}._2fO-g::-webkit-input-placeholder{line-height:140%;color:#373737;font-style:italic;font-family:AvenirNextRegular,sans-serif;font-size:14px;opacity:1;padding-left:4px;padding-top:4px}._2PPEv{font-weight:500;color:#043544;-ms-flex:1 1;flex:1 1}._2PPEv._1XYLO{color:#8b1313}._2PPEv._1eN5K{font-family:AvenirNextRegular,sans-serif}._2PPEv._2jftM{font-family:AvenirNextMedium,sans-serif}._2PPEv._3wM8g{font-size:14px}._2PPEv._2V6ww{font-size:16px}._2PPEv .iNoAu{color:#8b1313;display:inline}._3FoMY{color:#043544}._1y7-f,._3FoMY{font-size:12px;font-family:AvenirNextRegular,sans-serif;-ms-flex:1 1;flex:1 1;font-style:italic}._1y7-f{color:#8b1313;height:48px}.cEmR2{font-size:14px;font-family:AvenirNextRegular,sans-serif;line-height:38px;color:#043544}", ""]);

// exports
exports.locals = {
	"input-select": "_2fO-g",
	"error": "_1XYLO",
	"medium": "_2V6ww",
	"normal": "_3wM8g",
	"label-select": "_2PPEv",
	"regular": "_1eN5K",
	"nextMedium": "_2jftM",
	"required": "iNoAu",
	"help-select": "_3FoMY",
	"error-select": "_1y7-f",
	"clean-label": "cEmR2",
	"spin": "_2y5Oh"
};