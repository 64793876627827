import React from 'react';
import ProgramCardsGrid from './program-dashboard/ProgramCardsGrid';
import ProgramFiltersList from './program-dashboard/ProgramFiltersList';
import ProgramDashboardHeader from './program-dashboard/ProgramDashboardHeader';
import TakeProgramMatchBanner from './program-dashboard/TakeProgramMatchBanner';
import styled from 'styled-components';
import useSession from '../../../hooks/useSession';
import SessionExpireModal from '../../../components/SessionExpireModal';

const Container = styled.div`
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
`;

export default function TravelerProgramDashboard() {
  const { showSessionExpire, handleCloseSessionModal, countDownSeconds } = useSession();

  return (
    <Container>
      <ProgramDashboardHeader />
      <ProgramFiltersList />
      <ProgramCardsGrid />
      <TakeProgramMatchBanner />
      {showSessionExpire && (
        <SessionExpireModal
          show={showSessionExpire}
          handleClose={handleCloseSessionModal}
          countDownSeconds={countDownSeconds}
       />
      )}
    </Container>
  );
}
