import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { Accordion, AccordionItem, AccordionItemState, AccordionItemHeading } from 'react-accessible-accordion';

import ApplicationCard from './ApplicationCard';
import ChevronUpIcon from '../../icons/ChevronUpIcon';
import ChevronDownIcon from '../../icons/ChevronDownIcon';
import { ItemButton, ItemHeadingContainer, ItemPanel } from './ProgramDetailsAccordion.styled';

export default function ApplicationAccordion() {
  const travelerSubmission = useSelector(state => state.forms.travelerSubmission);
  const [submission, setSubmission] = useState(travelerSubmission?.data || {});
  const [submissionId, setSubmissionId] = useState();
  useEffect(() => {
    if (!travelerSubmission.loading && travelerSubmission.data) {
      setSubmission(travelerSubmission.data);
      setSubmissionId(travelerSubmission.data.id);
    }
  }, [travelerSubmission]);

  const [expandedItems, setExpandedItems] = useState([]);

  useEffect(
    () => {
      const uuids = submission.submission_status.name === 'Committed' ? [] : ['application-details'];

      setExpandedItems(uuids);
    },
    [submissionId],
  );

  return (
    // A key is used here to force the accordion to re-render when the submission status changes.
    // This is necessary because the "preExpanded" prop cannot be modified after the accordion
    // has mounted, and it mounts before the submission is updated in state. See this issue:
    // https://github.com/springload/react-accessible-accordion/issues/323#issuecomment-882940342
    <Accordion key={expandedItems.join('')} allowZeroExpanded preExpanded={expandedItems}>
      <AccordionItem uuid="application-details">
        <ItemHeadingContainer>
          <span>My Application</span>
          <AccordionItemHeading aria-level={3}>
            <ItemButton aria-label="Toggle Application Details">
              <AccordionItemState>
                {({ expanded }) => (expanded ? <ChevronUpIcon /> : <ChevronDownIcon />)}
              </AccordionItemState>
            </ItemButton>
          </AccordionItemHeading>
        </ItemHeadingContainer>
        <ItemPanel>
          <ApplicationCard />
        </ItemPanel>
      </AccordionItem>
    </Accordion>
  );
}
