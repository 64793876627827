exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@-webkit-keyframes _2X9O7{to{-webkit-transform:rotate(1turn)}}@keyframes _2X9O7{to{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}.C3mqd{background-color:#ebebeb;color:#043544;width:100%;padding:16px 0;font-size:16px;line-height:1;font-weight:500}@media only screen and (max-width:599px){.C3mqd{padding:16px 4px;font-size:12px}}.C3mqd ._3fPoh{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-wrap:wrap;flex-wrap:wrap;width:100%;-ms-flex-align:center;align-items:center}.C3mqd ._3fPoh ._1CLZ3{padding:0 16px}.C3mqd ._3fPoh ._1o4b6{color:#043544;text-decoration:none}.C3mqd ._3fPoh .VIkHW{color:#1c748a;text-decoration:underline}", ""]);

// exports
exports.locals = {
	"subheader": "C3mqd",
	"subheader-content": "_3fPoh",
	"item": "_1CLZ3",
	"selection": "_1o4b6",
	"selected": "VIkHW",
	"spin": "_2X9O7"
};