import React, { useEffect, useLayoutEffect, useState } from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import _throttle from 'lodash/throttle';
import QuestionErrorText from '../questions/QuestionErrorText';
import DropdownSelect from '../../../../styledComponents/styles/DropdownSelect.styled';
import StyledIcon from '../../../../styledComponents/styles/Icon.styled';
import Tooltip from '../../../../styledComponents/styles/Tooltip.styled';
import { fetchTravelerPaymentStatus } from '../../../../actions/formsActions';
import { MakeAPaymentButton } from '../../../../styledComponents/styles/Buttons.styled';
import { PAYMENT } from '../../../../helpers/constants';
import { useWindowFocus } from '../../../../hooks/useWindowFocus';

const CashnetCode = styled.div`
  background-color: #deecff;
  border-radius: 5px 0 0 5px;
  line-height: 17px;
  margin-top: 30px;
  padding: 10px;
  width: 320px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.015em;
  color: #373737;

  span {
    font-weight: 600;
  }
`;

const InputContainer = styled.div`
  flex: 1;
  margin: 13px 0;
  text-align: left;
`;

const InfoMsg = styled.div`
  margin-top: 17px;
  padding: 20px;
  background-color: #deecff;
  border-radius: 5px;
  color: #373737;
  font-size: 0.875 rem;
  font-weight: 600;
  width: 60%;
`;

const StatusContainer = styled.span`
  display: flex;
  font-size: 12px;
  float: right;

  svg {
    margin-bottom: 2px;
  }
`;

const Status = styled.span`
  font-weight: 400;
  padding-left: 5px;
`;

const StatusHeader = styled.span`
  font-weight: 600;
`;

const CostDiv = styled.div`
  align-self: center;
  background-color: #e7e7e7;
  display: flex;
  border: 1px solid #bbbbbb;
  border-radius: 2px;
  flex-direction: column;
  font-size: 14px;
  line-height: 16px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  margin-left: 40px;
  width: 300px;
  padding: 15px 15px 5px 15px;
`;

const CostDivRow = styled.div`
  display: flex;
`;

const CostDivLeft = styled.div`
  justify-content: left;
  flex: 1;
`;

const CostDivRight = styled.div`
  justify-content: right;
  flex: 1;
`;

const ButtonContainer = styled.div`
  margin: 0 auto;
  padding-top: 30px;
  padding-bottom: 10px;
`;

const ParagraphCost = styled.div`
  color: #373737;
  font-weight: 400;
  font-size: 20px;
  padding-top: 20px;
`;

const ParagraphHeader = styled.div`
  font-weight: 600;
  color: #373737;
`;

const Logs = styled.div`
  align-self: center;
  margin-left: 40px;
  width: 300px;
  padding-top: 10px;
`;

const LogItem = styled.div`
  padding-bottom: 10px;
`;

const LogDate = styled.div`
  color: #373737;
  line-height: 12px;
  font-size: 10px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 3px;
`;

const LogDetails = styled.div`
  color: #373737;
  line-height: 14px;
  font-size: 12px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 3px;
`;

const ShowMore = styled.span`
  color: #446372;
  cursor: pointer;
  line-height: 12px;
  font-size: 10px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  text-decoration: underline;
`;

const PaymentBox = ({
  cashNetCodes,
  cost,
  currency,
  responseValues,
  isAdmin,
  isReadonly,
  isFee,
  isFlywire,
  questionId,
  onStatusChange,
  paymentOptions,
  onInit,
  url,
  errorMsg,
  validUrl = true,
}) => {
  const dispatch = useDispatch();

  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [logLimit, setLogLimit] = useState(3);
  const [isPaymentClicked, setIsPaymentClicked] = useState();
  const [selectedOption, setSelectedOption] = useState({});
  const [logs, setLogs] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [cashnetCode, setCashnetCode] = useState(null);
  const [paymentStatusHandler, setPaymentStatusHandler] = useState(null);
  // This is to trigger the "check payment status" for payment types handled on other pages
  // We need to make this call as the other window cannot pass back payment status
  const focused = useWindowFocus();
  const travelerSubmission = useSelector(state => state?.forms?.travelerSubmission?.data);

  const checkPayments = () => {
    if (responseValues?.submission_id) {
      dispatch(fetchTravelerPaymentStatus(responseValues?.submission_id));
    }
  };

  const handleFormType = () => {
    if (travelerSubmission?.submission_type?.identifier) {
      return `After submitting a payment, please save your ${
        travelerSubmission?.submission_type?.identifier.toLowerCase() === 'general form'
          ? 'form'
          : travelerSubmission?.submission_type?.identifier.toLowerCase()
      } to see your updated payment status.`;
    }
  };

  const updatePaymentStatus = _throttle(checkPayments, 300);

  useLayoutEffect(
    () => {
      if (!isReadonly && !disabled && focused && (paymentStatusHandler && isPaymentClicked)) {
        //Window just returned into focus
        paymentStatusHandler();
      }
      if (!focused) {
        setIsPaymentClicked(true);
      }
    },
    [focused],
  );

  useEffect(() => {
    if (onInit) {
      //Initialize any payment scripts (like Flywire)
      onInit();
    }
  }, []);

  useEffect(
    () => {
      if (cashNetCodes) {
        cashNetCodes.forEach(code => {
          if (code.question_id.toString() === questionId.toString()) {
            setCashnetCode(code.code);
          }
        });
      }
    },
    [cashNetCodes],
  );

  useEffect(
    () => {
      let options = [];

      if (paymentOptions && !paymentOptions.loading && !paymentOptions.error && paymentOptions.data) {
        paymentOptions.data.forEach(item => {
          options.push({ label: item.attributes.name, value: item.id, status: item.attributes.identifier });
        });

        if (responseValues?.payment_status_id) {
          const selected = options.find(o => o.status === responseValues.payment_status_identifier);
          setSelectedOption(selected);
        } else {
          const notPaid = options.find(i => i.status === PAYMENT.STATUS.NOT_PAID);
          setSelectedOption(notPaid);
        }

        if (responseValues?.payment_status_identifier) {
          const checkDisabled = [
            PAYMENT.STATUS.PAID,
            PAYMENT.STATUS.PAID_ADMIN_OVERRIDE,
            PAYMENT.STATUS.WAIVED,
          ].includes(responseValues.payment_status_identifier);
          setDisabled(checkDisabled);
        }

        if (responseValues?.payment_response_status_change_logs) {
          const buildLogList = [];
          responseValues.payment_response_status_change_logs
            .sort((a, b) => moment(b.created_at).diff(moment(a.created_at)))
            .forEach(log => {
              const statusOption = paymentOptions.data.find(p => {
                return log.payment_status_id && p.id.toString() === log.payment_status_id.toString();
              });

              buildLogList.push({
                id: log.id,
                date: moment(new Date(log.created_at)).format('MMMM D, YYYY h:mm a'),
                status: statusOption ? statusOption.attributes.name : '',
                by:
                  log.user_first_name && log.user_first_name.length > 0
                    ? `${log.user_first_name} ${log.user_last_name}`
                    : log.user_email,
                notes: log.notes,
                transaction_id: log.transaction_id,
                transaction_response: log.transaction_response,
                transaction_success: log.transaction_success,
              });
            });

          setLogs(buildLogList);
        }
      }

      setDropdownOptions(options);
    },
    [responseValues],
  );

  const openUrlInNewTab = url => {
    setPaymentStatusHandler(() => updatePaymentStatus);
    setIsPaymentClicked(true);
    //legacy payment redirect process, keeping logic as it is
    let newUrl = '';
    try {
      var regex = new RegExp(
        /(?:([^\:]*)\:\/\/)?(?:([^\:\@]*)(?:\:([^\@]*))?\@)?(?:([^\/\:]*)\.(?=[^\.\/\:]*\.[^\.\/\:]*))?([^\.\/\:]*)(?:\.([^\/\.\:]*))?(?:\:([0-9]*))?(\/[^\?#]*(?=.*?\/)\/)?([^\?#]*)?(?:\?([^#]*))?(?:#(.*))?/,
      );
      let a = url.match(regex);
      let b = a?.filter(a => a);

      if (!b.includes('www') && !b.includes('http') && !b.includes('https')) {
        newUrl = `//${url}`;
      } else if (b.includes('www') && !b.includes('http') && !b.includes('https')) {
        newUrl = `//${url}`;
      } else {
        newUrl = url;
      }

      window.open(newUrl, '_blank');
    } catch (error) {
      window.open(url, '_blank');
    }
  };

  return (
    <>
      <CostDiv>
        <CostDivRow>
          <CostDivLeft>
            <ParagraphHeader>Total Due:</ParagraphHeader>
          </CostDivLeft>
          <CostDivRight>
            <StatusContainer>
              <StatusHeader>Status:</StatusHeader>
              <Status>{selectedOption?.label}</Status>
              {isFee && (
                <Tooltip
                  toolTipText={`Payment statuses are updated by your administrators. If you have recently made a payment, this status may not be up-to-date.`}
                >
                  <StyledIcon type={'InformationCircle'} size={'14px'} color={'#255A94'} />
                </Tooltip>
              )}
            </StatusContainer>
          </CostDivRight>
        </CostDivRow>
        <CostDivRow>
          <ParagraphCost>{`$ ${Number(cost)?.toFixed(2)} ${currency}`}</ParagraphCost>
        </CostDivRow>
        {cashnetCode &&
          cashnetCode.length > 0 && (
            <CostDivRow>
              <CashnetCode>
                Via TRM Confirmation Code: <span>{cashnetCode}</span>
              </CashnetCode>
            </CostDivRow>
          )}
        <CostDivRow>
          <ButtonContainer>
            {isFlywire ? (
              <MakeAPaymentButton disabled={isReadonly || disabled} id="opu-guest-payment-link">
                Make a Payment
              </MakeAPaymentButton>
            ) : (
              validUrl && (
                <MakeAPaymentButton
                  disabled={isReadonly || disabled}
                  onClick={() => {
                    if (url && url.length > 0) {
                      openUrlInNewTab(url);
                    }
                  }}
                >
                  Make a Payment
                </MakeAPaymentButton>
              )
            )}
          </ButtonContainer>
        </CostDivRow>
        {isAdmin && (
          <CostDivRow>
            {dropdownOptions?.length > 0 && (
              <InputContainer>
                <DropdownSelect
                  aria-label="Status"
                  id={`dropdown-${questionId}`}
                  options={dropdownOptions}
                  openSans
                  label="Status"
                  readOnly={isReadonly}
                  onChange={onStatusChange}
                  defaultValue={selectedOption}
                />
              </InputContainer>
            )}
          </CostDivRow>
        )}
      </CostDiv>
      <QuestionErrorText errorMsg={errorMsg} />
      {!isFee && <InfoMsg>{handleFormType()}</InfoMsg>}

      {logs?.length > 0 && (
        <Logs>
          {logs.slice(0, logLimit).map(log => (
            <LogItem key={log.id}>
              <LogDate>{log.date}</LogDate>
              {!log?.transaction_id && (
                <LogDetails>
                  Status updated to {log.status} by {log.by}
                </LogDetails>
              )}

              {!!log?.transaction_id && (
                <>
                  <LogDetails>
                    Transaction #{log.transaction_id}{' '}
                    {!!log.transaction_success ? `Successful; Status changed to ${log.status}` : 'Failed'}
                  </LogDetails>
                  {log?.notes && !log?.transaction_success && <LogDetails>{log.notes}</LogDetails>}
                </>
              )}
            </LogItem>
          ))}
          {logs.length > logLimit && (
            <ShowMore onClick={() => setLogLimit(logs.length)}>View {logs.length - logLimit} more</ShowMore>
          )}
        </Logs>
      )}
    </>
  );
};

PaymentBox.propTypes = {
  cashnetCode: PropTypes.string,
  cost: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  paymentOptions: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  responseValues: PropTypes.object,
  isAdmin: PropTypes.bool,
  isReadonly: PropTypes.bool,
  isFee: PropTypes.bool,
  isFlywire: PropTypes.bool,
  onStatusChange: PropTypes.func.isRequired,
  onInit: PropTypes.func,
  questionId: PropTypes.string.isRequired,
  url: PropTypes.string,
  errorMsg: PropTypes.string,
};

PaymentBox.defaultProps = {
  cashnetCode: '',
  paymentOptions: null,
  responseValues: null,
  isAdmin: false,
  isReadonly: false,
  isFee: false,
  isFlywire: false,
  onInit: () => {},
  url: '',
  errorMsg: '',
};

export default PaymentBox;
