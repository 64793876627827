exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@-webkit-keyframes I6XLj{to{-webkit-transform:rotate(1turn)}}@keyframes I6XLj{to{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}._3v3Yc{padding-top:16px;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column}._3v3Yc ._1B4Xi{padding:12px 52px}._3v3Yc ._2omXv{-ms-flex:1 1;flex:1 1;-ms-flex-direction:row;flex-direction:row;display:-ms-flexbox;display:flex}@media only screen and (max-width:599px){._3v3Yc ._2omXv{-ms-flex-direction:column;flex-direction:column}}._3v3Yc ._2omXv .VwTOf{-ms-flex:1 1;flex:1 1;padding:12px 20px}._3v3Yc ._3kGEa{-ms-flex:1 1;flex:1 1;-ms-flex-direction:row;flex-direction:row;display:-ms-flexbox;display:flex;border-top:2px solid #ebebeb;padding:32px 20px 0 0;-ms-flex-pack:center;justify-content:center;margin-top:52px}@media only screen and (max-width:599px){._3v3Yc ._3kGEa{-ms-flex-direction:column;flex-direction:column}}._3v3Yc ._3kGEa ._1_ohk{padding-left:12px}@media only screen and (max-width:599px){._3v3Yc ._3kGEa ._1_ohk{padding-top:12px}}", ""]);

// exports
exports.locals = {
	"group-add-detail-success-modal": "_3v3Yc",
	"edit-detail-success-modal-content": "_1B4Xi",
	"row": "_2omXv",
	"item": "VwTOf",
	"button-row": "_3kGEa",
	"button-content": "_1_ohk",
	"spin": "I6XLj"
};