exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2GnSu{font-size:14px;height:120px;overflow:auto;resize:vertical;width:100%}._2GnSu ._3ar5e{height:100%;width:100%}", ""]);

// exports
exports.locals = {
	"editor": "_2GnSu",
	"editorClassName": "_3ar5e"
};