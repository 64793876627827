exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@-webkit-keyframes _37f7a{to{-webkit-transform:rotate(1turn)}}@keyframes _37f7a{to{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}._1cZ7o{color:#043544;-ms-flex:1 1;flex:1 1;width:100%;line-height:32px;line-height:2rem;border:2px solid #ebebeb;height:124px;padding:8px;font-family:AvenirNextRegular,sans-serif}._1cZ7o.qM6cf{border:2px solid #8b1313}._1cZ7o._2q9h5{font-size:16px}._1cZ7o._10KWD{font-size:14px}._1cZ7o::-webkit-input-placeholder{font-size:12px}._1cZ7o::-moz-placeholder{color:#767676;font-style:italic;font-family:AvenirNextRegular,sans-serif;font-size:12px;opacity:1;padding-left:4px}._1cZ7o::-ms-input-placeholder{font-size:12px}._1cZ7o::placeholder{color:#767676;font-style:italic;font-family:AvenirNextRegular,sans-serif;font-size:12px;opacity:1;padding-left:4px}._1cZ7o::-ms-input-placeholder{color:#767676;font-style:italic;font-size:16px;font-family:AvenirNextRegular,sans-serif;opacity:1;padding-left:4px}._1cZ7o::-webkit-input-placeholder{color:#767676;font-style:italic;font-family:AvenirNextRegular,sans-serif;font-size:16px;opacity:1;padding-left:4px}._1iU_D{font-family:AvenirNextMedium,sans-serif;font-weight:500;color:#043544;-ms-flex:1 1;flex:1 1}._1iU_D.qM6cf{color:#8b1313}._1iU_D._2q9h5{font-size:16px}._1iU_D._10KWD{font-size:14px}._29QYx{font-size:16px;color:#043544}._29QYx,.fW-qO{font-family:AvenirNextRegular,sans-serif;-ms-flex:1 1;flex:1 1;font-style:italic}.fW-qO{font-size:12px;color:#8b1313}", ""]);

// exports
exports.locals = {
	"input-textarea": "_1cZ7o",
	"error": "qM6cf",
	"medium": "_2q9h5",
	"normal": "_10KWD",
	"label-textarea": "_1iU_D",
	"help-textarea": "_29QYx",
	"error-textarea": "fW-qO",
	"spin": "_37f7a"
};