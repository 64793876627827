exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@-webkit-keyframes _1KWXG{to{-webkit-transform:rotate(1turn)}}@keyframes _1KWXG{to{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}._1-BGx ._26PPa{-ms-flex:1 1;flex:1 1;-ms-flex-direction:row;flex-direction:row;display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;padding:16px}@media only screen and (max-width:599px){._1-BGx ._26PPa{-ms-flex-direction:column;flex-direction:column}}._1-BGx .vu1P6{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;padding:40px 100px;-ms-flex-pack:center;justify-content:center}._1-BGx .vu1P6 ._1uxN_{padding:12px}._1-BGx ._1jW_5{-ms-flex:1 1;flex:1 1;-ms-flex-direction:column;flex-direction:column;display:-ms-flexbox;display:flex;border-top:2px solid #ebebeb;padding:32px 20px 0 0;-ms-flex-pack:center;justify-content:center}@media only screen and (max-width:599px){._1-BGx ._1jW_5{-ms-flex-direction:column;flex-direction:column}}._1-BGx ._1jW_5 ._3FCJf{padding:20px 0;display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-pack:center;justify-content:center}._1-BGx ._1jW_5 ._3FCJf ._15YJk{padding-left:12px}@media only screen and (max-width:599px){._1-BGx ._1jW_5 ._3FCJf ._15YJk{padding-top:12px}}._1-BGx ._1jW_5 ._2j67m{-ms-flex:1 1;flex:1 1}", ""]);

// exports
exports.locals = {
	"remove-detail": "_1-BGx",
	"subHeader": "_26PPa",
	"content": "vu1P6",
	"toggle": "_1uxN_",
	"footer": "_1jW_5",
	"footer-button-wrapper": "_3FCJf",
	"button-content": "_15YJk",
	"footer-confirm": "_2j67m",
	"spin": "_1KWXG"
};