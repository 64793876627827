import React from 'react';

export default function ChatBubbles() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99023 11.6045H1.81329C1.07373 11.6045 0.46875 12.2095 0.46875 12.949V17.2687C0.46875 18.0082 1.07373 18.6132 1.81329 18.6132H3.4411L5.90186 21.074V18.6132H9.99023C10.7298 18.6132 11.3348 18.0082 11.3348 17.2687V12.949C11.3348 12.2095 10.7298 11.6045 9.99023 11.6045Z"
        fill="#EE7A9B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.90118 2.90625H21.3239C22.5379 2.90625 23.5312 3.89941 23.5312 5.1134V12.2043C23.5312 13.4183 22.5379 14.4115 21.3239 14.4115H18.652L14.6125 18.451V14.4115H7.90118C6.68719 14.4115 5.69385 13.4183 5.69385 12.2043V5.1134C5.69385 3.8996 6.68701 2.90625 7.90118 2.90625Z"
        fill="#A9CADA"
      />
      <path
        d="M23.5312 10.0651C23.2723 10.0651 23.0625 10.2751 23.0625 10.5339V12.2043C23.0625 13.1629 22.2825 13.9429 21.3239 13.9429H18.652C18.5277 13.9429 18.4085 13.9922 18.3204 14.0801L15.0813 17.3194V14.4117C15.0813 14.1528 14.8715 13.9429 14.6125 13.9429H7.90118C6.94263 13.9429 6.1626 13.1631 6.1626 12.2043V5.1134C6.1626 4.15485 6.94263 3.375 7.90118 3.375H21.3239C22.2825 3.375 23.0625 4.15485 23.0625 5.1134V6.78387C23.0625 7.04279 23.2723 7.25262 23.5312 7.25262C23.79 7.25262 24 7.04279 24 6.78387V5.1134C24 3.63794 22.7996 2.4375 21.3239 2.4375H7.90118C6.42554 2.4375 5.2251 3.63794 5.2251 5.1134V11.1356H1.81329C0.813538 11.1356 0 11.9489 0 12.9489V17.2685C0 18.2684 0.813538 19.0818 1.81329 19.0818H3.24701L5.57025 21.4052C5.65997 21.4949 5.77991 21.5425 5.90186 21.5425C5.96228 21.5425 6.02325 21.5308 6.08112 21.5068C6.25635 21.4343 6.37061 21.2635 6.37061 21.0738V19.0818H9.99023C10.9902 19.0818 11.8035 18.2684 11.8035 17.2685V14.8804H14.1438V18.451C14.1438 18.6407 14.2581 18.8115 14.4333 18.884C14.4911 18.908 14.5521 18.9197 14.6125 18.9197C14.7345 18.9197 14.8544 18.8721 14.944 18.7824L18.8461 14.8804H21.3239C22.7996 14.8804 24 13.68 24 12.2043V10.5339C24 10.2751 23.79 10.0651 23.5312 10.0651ZM10.8662 17.2685C10.8662 17.7515 10.4733 18.1443 9.99023 18.1443H5.90186C5.64294 18.1443 5.43311 18.3543 5.43311 18.613V19.9422L3.77252 18.2816C3.68463 18.1937 3.56543 18.1443 3.4411 18.1443H1.81329C1.33044 18.1443 0.9375 17.7515 0.9375 17.2685V12.9489C0.9375 12.466 1.33044 12.0731 1.81329 12.0731H5.2251V12.2043C5.2251 13.68 6.42554 14.8804 7.90118 14.8804H10.8662V17.2685Z"
        fill="#373737"
      />
      <path
        d="M19.1826 5.50977H10.042C9.78308 5.50977 9.57324 5.7196 9.57324 5.97852C9.57324 6.23743 9.78308 6.44727 10.042 6.44727H19.1826C19.4415 6.44727 19.6514 6.23743 19.6514 5.97852C19.6514 5.7196 19.4415 5.50977 19.1826 5.50977Z"
        fill="#373737"
      />
      <path
        d="M19.6514 8.50977C19.6514 8.25085 19.4415 8.04102 19.1826 8.04102H10.042C9.78308 8.04102 9.57324 8.25085 9.57324 8.50977C9.57324 8.76868 9.78308 8.97852 10.042 8.97852H19.1826C19.4415 8.97852 19.6514 8.76868 19.6514 8.50977Z"
        fill="#373737"
      />
      <path
        d="M10.042 10.5723C9.78308 10.5723 9.57324 10.7821 9.57324 11.041C9.57324 11.2999 9.78308 11.5098 10.042 11.5098H15.3154C15.5743 11.5098 15.7842 11.2999 15.7842 11.041C15.7842 10.7821 15.5743 10.5723 15.3154 10.5723H10.042Z"
        fill="#373737"
      />
      <path
        d="M23.5312 8.19043C23.2731 8.19043 23.0625 8.401 23.0625 8.65918C23.0625 8.91736 23.2731 9.12793 23.5312 9.12793C23.7894 9.12793 24 8.91736 24 8.65918C24 8.401 23.7894 8.19043 23.5312 8.19043Z"
        fill="#373737"
      />
    </svg>
  );
}
