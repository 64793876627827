exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2JBAr{border-radius:5px;border-left:20px solid #ddaf64;background-color:#ffefd4;display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;padding:10px 20px;gap:20px}.E_IrJ{width:37px;height:37px}._3ihmY{display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center}._1N8Av{font-size:16px;font-weight:600;line-height:19.2px}._1DvEq,._1N8Av{font-family:Open Sans;letter-spacing:.005em;text-align:left}._1DvEq{font-size:14px;font-weight:400;line-height:16.8px}", ""]);

// exports
exports.locals = {
	"container": "_2JBAr",
	"warningIconImg": "E_IrJ",
	"imgContainer": "_3ihmY",
	"heading": "_1N8Av",
	"message": "_1DvEq"
};