import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import Cta from './CTA';
import Date from './Date';
import Status from './Status';
import Credits from './Credits';
import Deadline from './Deadline';
import { programIdType } from '../../../../utils/types';
import { TermInfoCard, TermInfoCardImage, TermInfoCardHeading, TermInfoCardProviderHeading } from './Term.styled';

export default function Term({ term, authorized, timezone, provider, organization, programId, isAdmin }) {
  const image = useSelector(state => state.programBrochure.images.list);

  return (
    <li key={`term-${term.range.id}`}>
      <TermInfoCard tabIndex={0}>
        <div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
            }}
          >
            <TermInfoCardImage src={image} alt="" />
            <Status status={term.range.parsed_status} />
          </div>
          <TermInfoCardHeading>{term?.range?.name}</TermInfoCardHeading>
          {authorized && term?.range?.provider_term_name && (
            <TermInfoCardProviderHeading>
              {provider} Term: {term?.range?.provider_term_name}
            </TermInfoCardProviderHeading>
          )}
          <Date
            startDate={term?.range?.start_date}
            endDate={term?.range?.end_date}
            useExactDates={term?.range?.use_exact_dates}
            weeks={term?.range?.weeks}
          />
          <Deadline deadline={term?.range?.deadline} timezone={timezone} prefix={authorized ? organization : ''} />
          <Credits minCredits={term?.range?.min_course_credits} maxCredits={term?.range?.max_course_credits} />
        </div>
        <Cta status={term?.range?.parsed_status} programId={programId} termId={term?.range?.id} isAdmin={isAdmin} />
      </TermInfoCard>
    </li>
  );
}

Term.propTypes = {
  programId: programIdType,
  term: PropTypes.object.isRequired,
  timezone: PropTypes.string,
  organization: PropTypes.string.isRequired,
  provider: PropTypes.string.isRequired,
  authorized: PropTypes.bool.isRequired,
};
