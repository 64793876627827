import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import propTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import CopyLinkIcon from '../../../../../public/img/CopyLink.svg';
import FacebookIcon from '../../../../../public/img/Facebook.svg';
import TwitterIcon from '../../../../../public/img/Twitter.svg';
import Whatsapp from '../../../../../public/img/Whatsapp.svg';
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share';
import { withStyles as uiStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import sStyles from './shareBar.scss';

export const enhance = compose(withStyles(sStyles));

function V2ShareBar(props) {
  const [open, setOpen] = useState(false);
  const [customStyle, setCustomStyle] = useState({});
  let copyInput = null;
  const CopyTooltip = uiStyles((theme) => ({
    tooltip: {
      backgroundColor: '#ffffff',
      color: '#00485d',
      boxShadow: theme.shadows[1],
      fontSize: 12,
      fontFamily: 'AvenirNextRegular, sans-serif',
      marginBottom: '1px',
    },
  }))(Tooltip);

  const handleTooltipOpen = () => {
    if (window.clipboardData && window.clipboardData.setData) {
      setOpen(true);
      return window.clipboardData.setData('Text', props.linkUrl);
    }
    else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
      let textarea = document.createElement("textarea");
      textarea.textContent = props.linkUrl;
      textarea.style.position = "fixed";
      document.body.appendChild(textarea);
      textarea.select();
      try {
        return document.execCommand("copy");
      }
      catch (ex) {
        return false;
      }
      finally {
        setOpen(true);
        document.body.removeChild(textarea);
      }
    }
  };

  const handleTooltipClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (props && props.height) {
      setCustomStyle({height: props.height});
    }
  }, [props]);


  return (
    <div className={sStyles.wrapper}>
      {props.facebook && (
        <FacebookShareButton
          url={props.linkUrl}
          quote={props.quote}
          hashtag={`#ViaTRM`}
        >
          <img alt="Facebook share" src={FacebookIcon} style={customStyle} />
        </FacebookShareButton>
      )}
      {props.twitter && (
        <TwitterShareButton
          url={` `}
          title={props.quote}
          via={'ViaTRM'}
          hashtags={['ViaTRM']}
        >
          <img alt="Twitter share" src={TwitterIcon} style={customStyle} />
        </TwitterShareButton>
      )}
      {props.whatsapp && (
        <WhatsappShareButton
          title={props.title}
          url={props.linkUrl}
        >
          <img alt="Whatsapp share" src={Whatsapp} style={customStyle} />
        </WhatsappShareButton>

      )}
      {props.copyLink && (
        <CopyTooltip
          onClose={handleTooltipClose}
          open={open}
          title={
            <div>
              <FontAwesomeIcon icon="check" color="#219653" />&nbsp;&nbsp;
              Copied to Clipboard
            </div>
          }
          placement="top-end"
        >
          <div>
            <img
              alt="Copy Link"
              src={CopyLinkIcon}
              onClick={() => handleTooltipOpen()}
              ref={(ref) => copyInput = ref}
              style={customStyle}
            />
          </div>
        </CopyTooltip>
      )}

    </div>
  )
}

V2ShareBar.propTypes = {
  facebook: propTypes.bool,
  whatsapp: propTypes.bool,
  twitter: propTypes.bool,
  copyLink: propTypes.bool,
  linkUrl: propTypes.string.isRequired,
  quote: propTypes.string.isRequired,
  title: propTypes.string,
  height: propTypes.string,
}

V2ShareBar.defaultProps = {
  facebook: true,
  whatsapp: true,
  twitter: true,
  copy: true,
  height: '24px',
}

export default enhance(V2ShareBar);
