import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import TransferredIcon from '../../icons/TransferredIcon';

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-family: 'Nunito', sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  color: #585858;
  letter-spacing: 0.0015em;
  line-height: 120%;
`;

const Indicator = styled.span`
  width: 10px;
  height: 10px;
  border-radius: 50px;

  ${({ status }) => {
    switch (status) {
      case 'Incomplete':
        return `
          background-color: #CCCCCC;
          border: 1px solid #666666;
        `;
      case 'Submitted':
      case 'In Review':
        return `
          background-color: #DDAF64;
        `;
      case 'Conditionally Accepted':
      case 'Conditionally Approved':
      case 'Nominated':
      case 'Waitlisted':
        return `
          background-color: #5180B1;
        `;
      case 'Withdrawn':
      case 'Deferred':
      case 'Not Accepted':
        return `
          background-color: #848383;
        `;
      case 'Accepted':
      case 'Approved':
        return `
          background-color: #DAFDD7;
          border: 1px solid #0E5B2F;
        `;
      case 'Committed':
        return `
          background-color: #0E5B2F;
        `;
      default:
        return ``;
    }
  }};
`;

export default function ApplicationStatus() {
  const travelerSubmission = useSelector(state => state.forms.travelerSubmission);
  const [status, setStatus] = useState('');
  useEffect(() => {
    if (!travelerSubmission.loading && travelerSubmission.data) {
      setStatus(travelerSubmission.data.submission_status.name);
    }
  }, [travelerSubmission]);  

  return (status && (
    <Container>
      {status === 'Transferred' ? <TransferredIcon /> : <Indicator status={status} />}
      <span tabIndex={0}>{status}</span>
    </Container>
  ));
}
