import PropTypes from 'prop-types';

// There are other properties returned from the API.
// This type includes the properties used by the UI.
const programApplication = PropTypes.shape({
  deadline: PropTypes.string.isRequired,
  program_id: PropTypes.string.isRequired,
  corresponding_forms: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      status: PropTypes.string.isRequired,
      deadline: PropTypes.string.isRequired,
      template_name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  program_range: PropTypes.shape({
    term_name: PropTypes.string,
    end_date: PropTypes.string.isRequired,
    exact_dates: PropTypes.bool.isRequired,
    start_date: PropTypes.string.isRequired,
    program_title: PropTypes.string.isRequired,
    program_image: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  submission_status: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  submission_tracking_step: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
});

export default programApplication;
