import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Draggable } from 'react-beautiful-dnd';

import DragIcon from '../../icons/DragIcon';

const Container = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  gap: 1rem;
  min-width: 19rem;
  padding: 7px 9px;
  padding-right: 2.5rem;
  background: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  cursor: grab;

  svg:first-of-type {
    width: 24px;
    height: 24px;
  }

  svg:last-of-type {
    position: absolute;
    right: 1rem;
    color: #999999;

    ${props => {
      if (props.isDragging) {
        return `
          color: #373737;
        `;
      }
    }};
  }

  ${props => {
    if (props.isDragging) {
      return `
          border-color: transparent;
          box-shadow: 2px 10px 20px 2px rgba(0, 0, 0, 0.15);
        `;
    }
  }};

  &:focus {
    outline: none;
  }

  &:focus-visible {
    border-color: #255a94;
  }
`;

const ItemContent = styled.p`
  margin-bottom: 0;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 120%;
  letter-spacing: 0.0025rem;
`;

export default function DragAndDropItem({ item, index }) {
  const IconComponent = item.icon;

  return (
    <Draggable draggableId={`item-${item.id}`} index={index}>
      {(provided, snapshot) => (
        <Container
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          isDragging={snapshot.isDragging}
        >
          <IconComponent />
          <ItemContent>{item.content}</ItemContent>
          <DragIcon />
        </Container>
      )}
    </Draggable>
  );
}

DragAndDropItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    icon: PropTypes.func,
    content: PropTypes.string,
  }).isRequired,
  index: PropTypes.number.isRequired,
};
