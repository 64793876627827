exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@-webkit-keyframes _1Zdb2{to{-webkit-transform:rotate(1turn)}}@keyframes _1Zdb2{to{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}._2eXn5 ._1jn6v{-ms-flex:1 1;flex:1 1;-ms-flex-direction:row;flex-direction:row;display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;padding:16px}@media only screen and (max-width:599px){._2eXn5 ._1jn6v{-ms-flex-direction:column;flex-direction:column}}._2eXn5 ._3oSpu{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;padding:40px 100px;-ms-flex-pack:center;justify-content:center}._2eXn5 ._3oSpu ._2lZl6{padding:12px}._2eXn5 ._2lhfV{-ms-flex:1 1;flex:1 1;-ms-flex-direction:column;flex-direction:column;display:-ms-flexbox;display:flex;border-top:2px solid #ebebeb;padding:32px 20px 0 0;-ms-flex-pack:center;justify-content:center}@media only screen and (max-width:599px){._2eXn5 ._2lhfV{-ms-flex-direction:column;flex-direction:column}}._2eXn5 ._2lhfV ._3XBbR{padding:20px 0;display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-pack:center;justify-content:center}._2eXn5 ._2lhfV ._3XBbR ._1NV18{padding-left:12px}@media only screen and (max-width:599px){._2eXn5 ._2lhfV ._3XBbR ._1NV18{padding-top:12px}}._2eXn5 ._2lhfV ._2di0C{-ms-flex:1 1;flex:1 1}", ""]);

// exports
exports.locals = {
	"reinstate-plan": "_2eXn5",
	"subHeader": "_1jn6v",
	"content": "_3oSpu",
	"toggle": "_2lZl6",
	"footer": "_2lhfV",
	"footer-button-wrapper": "_3XBbR",
	"button-content": "_1NV18",
	"footer-confirm": "_2di0C",
	"spin": "_1Zdb2"
};