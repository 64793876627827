import React from 'react';
import withStyles from '../../../node_modules/isomorphic-style-loader/lib/withStyles';
import s from './ProgramRequestModal.css';
import BlueModal from '../../components/BlueModal/BlueModal';
import { requestToApply } from '../../actions/programBrochureActions';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withCookies } from 'react-cookie';

class ProgramRequestModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { requestShow: true };
    this.sendRequest = this.sendRequest.bind(this);
    this.hideRequestModal = this.hideRequestModal.bind(this);
    this.showRequestModal = this.showRequestModal.bind(this);
  }

  showRequestModal = () => {
    this.setState({ requestShow: true });
  };

  hideRequestModal = () => {
    this.setState({ requestShow: false });
    this.props.requestHideParent();
  };

  sendRequest() {
    this.props.requestToApply(this.props.programId);
    this.hideRequestModal();
  }

  render() {
    return (
      <BlueModal
        show={this.state.requestShow}
        handleClose={this.hideRequestModal}
        header="Request to Apply"
        description="There are no open applications for this program. Don't worry! It's possible that future terms are available. Request to apply to let this program provider know you are interested in taking the next step. When you request to apply, a message will be sent letting the contacts for this program know you are ready to get started."
        buttonOne="Yes, Request to Apply"
        buttonTwo="No, Maybe Later"
        buttonOneHandle={this.sendRequest}
        buttonTwoHandle={this.hideRequestModal}
      />
    );
  }
}

export default compose(
  withCookies,
  withStyles(s),
  connect(null, {
    requestToApply,
  }),
)(ProgramRequestModal);
