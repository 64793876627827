import PropTypes from 'prop-types';

const programFilterItem = PropTypes.shape({
  icon: PropTypes.func,
  id: PropTypes.number,
  label: PropTypes.string,
  value: PropTypes.string,
});

export default programFilterItem;
