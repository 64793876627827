import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import styles from './index.module.css';
import checkIcon from './checkIcon.png';

const Stepper = ({ currentStep }) => {
  return (
    <div className={styles.container}>
      <div className={styles.item}>
        {currentStep > 0 ? (
          <div>
            <img src={checkIcon} className={styles.checkIcon} />
          </div>
        ) : (
          <div className={`${styles.stepNumber} ${currentStep === 0 ? styles.stepNumberActive : ''}`}>1</div>
        )}

        <div className={`${styles.stepText} ${styles.stepTextActive}`}>Create Plan</div>
      </div>

      <div className={styles.straightLine}></div>

      <div className={styles.item}>
        {currentStep > 1 ? (
          <div>
            <img src={checkIcon} className={styles.checkIcon} />
          </div>
        ) : (
          <div className={`${styles.stepNumber} ${currentStep === 1 ? styles.stepNumberActive : ''}`}>2</div>
        )}

        <div className={styles.stepText}>Configure Plan Details</div>
      </div>

      <div className={styles.straightLine}></div>

      <div className={styles.item}>
        {currentStep === 2 ? (
          <div>
            <img src={checkIcon} className={styles.checkIcon} />
          </div>
        ) : (
          <div className={`${styles.stepNumber} ${currentStep === 2 ? styles.stepNumberActive : ''}`}>3</div>
        )}

        <div className={styles.stepText}>Confirm</div>
      </div>
    </div>
  );
};

export default withStyles(styles)(Stepper);
