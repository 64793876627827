export default [
  {
    id: 1,
    label: 'Mr.',
    value: 'Mr.',
  },
  {
    id: 2,
    label: 'Ms.',
    value: 'Ms.',
  },
  {
    id: 3,
    label: 'Mrs.',
    value: 'Mrs.',
  },
  {
    id: 4,
    label: 'Mx.',
    value: 'Mx.',
  },
  {
    id: 5,
    label: 'Dr.',
    value: 'Dr.',
  },
];
