import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';

import CloseIcon from '../icons/CloseIcon';
import GreyPlane from '../../../travelerForms/components/icons/GreyPlane';

import { breakpoints } from '../../utils/breakpoints';
import { buttonFocus } from '../../utils/shared/focusStyles';

const Container = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 1.2025rem 3.25rem 2.1875rem 3.25rem;
  background-color: rgba(255, 255, 255, 0.9);
  border: 2px solid #ffffff;
  border-radius: 5px;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25);

  @media ${breakpoints.tablet} {
    max-width: 354px;
  }
`;

const SvgContainer = styled.div`
  svg {
    display: none;
    width: 56px;
    height: 56px;
  }

  @media ${breakpoints.tablet} {
    svg {
      display: block;
    }
  }
`;

const Info = styled.p`
  margin-bottom: 0;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 0.9375rem;
  color: #4d4d4d;
  text-align: center;
  letter-spacing: 0.005em;
  line-height: 120%;

  span {
    display: block;
  }

  span:first-of-type {
    font-weight: 600;
  }
`;

const MatchLink = styled(Link)`
  padding: 0.75rem 1.125rem;
  background-color: var(--theme_color_accent);
  border-radius: 5px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 1rem;
  color: var(--theme_color_accent_font);
  letter-spacing: 0.06em;
  line-height: 120%;

  &:hover {
    color: var(--theme_color_accent_font);
    text-decoration: none;
    opacity: 0.8;
  }

  ${buttonFocus};
`;

const CloseButton = styled.button`
  position: absolute;
  top: 5px;
  right: 10px;
  padding: 0;
  background-color: transparent;
  border: none;

  svg {
    width: 13px;
    height: 13px;
  }

  &:focus {
    outline: none;
  }

  &:focus-visible {
    svg {
      outline: 2px solid #255a94;
      outline-offset: 1px;
    }
  }
`;

export default function TakeProgramMatchBanner() {
  const {
    customAliases: { alias_program: programAlias },
  } = useSelector(state => state.currentOrganization);
  const { showMatch, matchComplete } = useSelector(state => state.travelerProfile);

  const [show, setShow] = useState(true);

  useEffect(
    () => {
      setShow(showMatch && !matchComplete);
    },
    [showMatch, matchComplete],
  );

  const handleClose = () => {
    setShow(false);
  };

  return (
    <>
      {show && (
        <Container role="dialog" aria-describedby="program_match_info">
          <CloseButton type="button" aria-label={`Close ${programAlias.toLowerCase()} banner`} onClick={handleClose}>
            <CloseIcon fillColor="#373737" />
          </CloseButton>
          <SvgContainer>
            <GreyPlane />
          </SvgContainer>
          <Info id="program_match_info">
            <span>Want help finding a good match?</span>
            <span>Take the {programAlias} Matching quiz to get recommendations!</span>
          </Info>
          <MatchLink to="/traveler/program-match">{programAlias} Match Quiz</MatchLink>
        </Container>
      )}
    </>
  );
}
