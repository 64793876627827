import React from 'react';

export default function OpenEyeIcon() {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
      <path
        d="M8.97912 10.9167C10.0832 10.9167 10.9783 10.0213 10.9783 8.91675C10.9783 7.81218 10.0832 6.91675 8.97912 6.91675C7.87501 6.91675 6.97995 7.81218 6.97995 8.91675C6.97995 10.0213 7.87501 10.9167 8.97912 10.9167Z"
        fill="#373737"
      />
      <path
        d="M16.3149 8.373C15.4884 7.09425 14.416 6.008 13.214 5.23144C11.8842 4.37144 10.4161 3.91675 8.96855 3.91675C7.64035 3.91675 6.33432 4.29644 5.08671 5.04519C3.81443 5.80862 2.66178 6.92394 1.66063 8.35987C1.54761 8.52216 1.48537 8.71439 1.48181 8.91214C1.47825 9.1099 1.53353 9.30425 1.64064 9.4705C2.46561 10.7621 3.52736 11.8499 4.71062 12.6158C6.04288 13.4792 7.47666 13.9167 8.96855 13.9167C10.4276 13.9167 11.8989 13.4658 13.223 12.613C14.4244 11.8389 15.4946 10.7486 16.318 9.45925C16.4214 9.29686 16.4761 9.10821 16.4755 8.91567C16.475 8.72312 16.4192 8.53478 16.3149 8.373ZM8.97917 11.9167C8.38607 11.9167 7.80629 11.7408 7.31315 11.4112C6.82 11.0815 6.43564 10.613 6.20868 10.0648C5.98171 9.51662 5.92232 8.91342 6.03803 8.33148C6.15374 7.74953 6.43934 7.21499 6.85872 6.79543C7.27811 6.37587 7.81244 6.09015 8.39414 5.97439C8.97584 5.85864 9.57879 5.91805 10.1267 6.14511C10.6747 6.37217 11.143 6.75669 11.4725 7.25004C11.8021 7.74338 11.9779 8.3234 11.9779 8.91675C11.977 9.71212 11.6608 10.4747 11.0986 11.0371C10.5364 11.5995 9.77421 11.9158 8.97917 11.9167Z"
        fill="#373737"
      />
    </svg>
  );
}
