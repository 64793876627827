import React, { useState, useEffect } from 'react';
import { compose } from 'recompose';
import propTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import ViaModal from '../../../../components/library/viaModal';
import Headline from '../../../../components/library/headline';
import Button from '../../../../components/library/button';
import Paragraph from '../../../../components/library/paragraph';
import { patchDetail, getPlan, patchDetailFromTraveler } from '../../../../../actions/plans';
import sRemoveDetail from './removeDetail.scss';

export const enhance = compose(withStyles(sRemoveDetail));

function RemoveDetail(props) {
  const dispatch = useDispatch();
  const planDetail = useSelector(state => state.plans.addDetail);
  const planDetailBatch = useSelector(state => state.plans.patchDetailFromTraveler);
  const [saving, setSaving] = useState(false);
  const [removeUpdate, setRemoveUpdate] = useState(false);

  useEffect(
    () => {
      if (planDetail && removeUpdate) {
        if (planDetail.loading) {
          setSaving(true);
        }
        if (saving && !!planDetail.detail && (planDetail.detail.id.toString() === props.detailId.toString())) {
          dispatch(getPlan(props.planId));
          setSaving(false);
          props.onClose(false);
          setRemoveUpdate(false);
        }

        if (saving && !!planDetail.error) {
          setSaving(false);
          setRemoveUpdate(false);
          // alert(planDetail.error);
        }
      }
    },
    [planDetail],
  );

  useEffect(
    () => {
      if (planDetailBatch && removeUpdate) {
        if (planDetailBatch.loading) {
          setSaving(true);
        }
        if (saving && !!planDetailBatch.data) {
          dispatch(getPlan(props.planId));
          setSaving(false);
          props.onClose(false);
          setRemoveUpdate(false);
        }

        if (saving && !!planDetailBatch.error) {
          setSaving(false);
          setRemoveUpdate(false);
          // alert(planDetail.error);
        }
      }
    },
    [planDetailBatch],
  );

  const updateDetail = () =>{
    setRemoveUpdate(true);
    if (!!props.plans_user_id) {
      const submitValues = {
        plans_user_ids: [props.plans_user_id],
        plan_activity_ids: props.type === 'plan_activities' ? [Number(props.detailId)] : [],
        plan_housing_ids: props.type === 'plan_housings' ? [Number(props.detailId)] : [],
        plan_transportation_ids: props.type === 'plan_transportations' ? [Number(props.detailId)] : [],
        removed: true,
      };

      dispatch(patchDetailFromTraveler(submitValues));

    } else {
      const submitValues = {
        removed: true,
      };

      dispatch(patchDetail(props.planId, props.detailId, submitValues, props.type));
    }

    // props.onClose(false);
  };

  return (
    <>
      <ViaModal
        showing={props.show}
        onClose={() => props.onClose(false)}
      >
        <div className={sRemoveDetail['remove-detail']}>
          <div className={sRemoveDetail.subHeader}>
            <Headline centered tag="h1" as="h1">
              Remove Details
            </Headline>
          </div>
          <div className={sRemoveDetail.content}>
            <Paragraph centered size="large">
              {props.groupRemove ? (
                `You are about to remove a detail from the Group Itinerary.
                Removed details will automatically be removed from Travelers’ Itineraries.
                Only Administrators or Group Leaders can reinstate group details to
                the Group Itinerary and/or to Travelers.`
              ) : (
                `You are about to remove a detail from your itinerary.
                You can reinstate details you have removed at any time`
              )}
            </Paragraph>
          </div>
          <div className={sRemoveDetail.footer}>
            <div className={sRemoveDetail['footer-confirm']}>
              <Headline tag="h2" as="h3" centered>
                WOULD YOU STILL LIKE TO REMOVE?
              </Headline>
            </div>
            <div className={sRemoveDetail['footer-button-wrapper']}>
              <div className={sRemoveDetail['button-content']}>
                <Button
                  display="primary"
                  kind="solid"
                  size="medium"
                  type="submit"
                  onClick={() => {
                    updateDetail();
                  }}
                >
                  Yes, Remove
                </Button>
              </div>
              <div className={sRemoveDetail['button-content']}>
                <Button
                  display="secondary"
                  kind="solid"
                  size="medium"
                  onClick={() => {
                    props.onClose(false);
                  }}
                >
                  No, Do Not Remove
                </Button>
              </div>
            </div>
          </div>
        </div>
      </ViaModal>
    </>
  )
}

RemoveDetail.propTypes = {
  show: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
  detailId: propTypes.string.isRequired,
  type: propTypes.oneOf(['plan_transportations', 'plan_housings', 'plan_activities']).isRequired,
  planId: propTypes.string.isRequired,
  plans_user_id: propTypes.number,
  groupRemove: propTypes.bool,
};

RemoveDetail.defaultProps = {
  plans_user_id: null,
  groupRemove: false,
};

export default enhance(RemoveDetail);
