exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@-webkit-keyframes _3yQ9y{to{-webkit-transform:rotate(1turn)}}@keyframes _3yQ9y{to{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}._3mywg ._31lfu{-ms-flex:1 1;flex:1 1;-ms-flex-direction:row;flex-direction:row;display:-ms-flexbox;display:flex;border-bottom:2px solid #ebebeb;-ms-flex-pack:center;justify-content:center;padding:16px}@media only screen and (max-width:599px){._3mywg ._31lfu{-ms-flex-direction:column;flex-direction:column}}._3mywg .RPX4f{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;padding:52px;-ms-flex-pack:center;justify-content:center}._3mywg .RPX4f ._37al1{padding:12px}._3mywg ._1LGIT{-ms-flex:1 1;flex:1 1;-ms-flex-direction:row;flex-direction:row;display:-ms-flexbox;display:flex;border-top:2px solid #ebebeb;padding:32px 20px 0 0;-ms-flex-pack:center;justify-content:center}@media only screen and (max-width:599px){._3mywg ._1LGIT{-ms-flex-direction:column;flex-direction:column}}._3mywg ._1LGIT .Hn8zA{padding-left:12px}@media only screen and (max-width:599px){._3mywg ._1LGIT .Hn8zA{padding-top:12px}}@media only screen and (min-device-width:320px) and (max-device-width:700px){._3mywg .RPX4f{-ms-flex-direction:column;flex-direction:column;-ms-flex-align:center;align-items:center}._3mywg .RPX4f div button{width:150px}}", ""]);

// exports
exports.locals = {
	"detail-choice-modal": "_3mywg",
	"subHeader": "_31lfu",
	"content": "RPX4f",
	"toggle": "_37al1",
	"footer": "_1LGIT",
	"button-content": "Hn8zA",
	"spin": "_3yQ9y"
};