import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import EmptyHeartIcon from '../../../icons/EmptyHeartIcon';
import FilledHeartIcon from '../../../icons/FilledHeartIcon';
import MobileFilterContainer from '../MobileFilterContainer.styled';

import { buttonFocus } from '../../../../utils/shared/focusStyles';

const FilterButton = styled.button`
  display: flex;
  align-items: center;
  margin: 0 auto;
  padding: 0.25rem 0.4375rem;
  background-color: ${({ isSelected }) => (isSelected ? '#D2E4ED' : '#d7d7d7')};
  border: none;
  border-radius: 50px;
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  color: #373737;
  letter-spacing: 0.015em;
  line-height: 120%;

  svg {
    max-width: 13px;
    max-height: 13px;
    margin-right: 0.9375rem;
  }

  ${buttonFocus};
`;

export default function MobileFavoritesOnlyFilter({ isSelected, handleClick }) {
  const {
    customAliases: { 
      alias_favorites: favoritesAlias = 'Favorites',
    },
  } = useSelector(state => state.profile);

  return (
    <MobileFilterContainer>
      <FilterButton type="button" onClick={handleClick} isSelected={isSelected}>
        {isSelected ? <FilledHeartIcon fillColor="#373737" /> : <EmptyHeartIcon />}
        <span>{`${favoritesAlias} Only`}</span>
      </FilterButton>
    </MobileFilterContainer>
  );
}

MobileFavoritesOnlyFilter.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
};
