exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2h8xa{padding-top:64px;padding-top:4rem;padding-left:128px;padding-left:8rem;padding-right:128px;padding-right:8rem;width:100%}._3Xlh1{max-width:72%;min-width:72%}._2zCUr{border-bottom:3px solid #f5f5f5;color:#043544;font-family:IntroBold,sans-serif;font-size:28px;font-size:1.75rem;padding-bottom:24px;padding-bottom:1.5rem;margin:0 0 14px;margin:0 0 .875rem}.uyZSx{color:#043544;width:100%}._2sPeZ{font-size:18px;font-size:1.125rem;font-family:Open Sans;font-weight:bolder;margin:0 0 14px;margin:0 0 .875rem}._1XlN8,._2sPeZ{color:#043544;min-width:304px;min-width:19rem}._1XlN8{font-family:AvenirNextRegular,sans-serif;margin-bottom:16px!important}._1XlN8,._251iz{font-size:16px;font-size:1rem}._251iz{color:#043544;display:-ms-flexbox;display:flex;font-family:Open Sans;margin-top:16px;margin-top:1rem}._251iz p,._308F7 p{margin:0}._308F7{-ms-flex-align:center;align-items:center;display:-ms-flexbox;display:flex;margin:0 0 14px;margin:0 0 .875rem;width:100%}._3K-wg,.CPTrX{margin:0 .4em;cursor:pointer;-ms-flex-item-align:center;align-self:center}.CPTrX{position:relative;left:40px;left:2.5rem}._3UTwb{margin:.4em;cursor:pointer;background-color:#dd2160!important;color:#fff;height:100%;-ms-flex-item-align:center;align-self:center}.GvvNu{position:relative;min-width:384px;min-width:24rem;max-width:384px;max-width:24rem;background-color:#fff;border:1px solid #ccc;border-radius:4px;padding:2px 8px;cursor:text;cursor:pointer;display:block;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;outline:none!important;font-family:AvenirNextRegular,sans-serif}._1l1qp{margin:.4em;-ms-flex-item-align:end;align-self:flex-end;background-color:grey;color:#fff}._1l1qp,._1mdCY:hover{cursor:pointer}._1mdCY:focus,._1mdCY:hover{outline:2px solid #0075ff;background:#fff}", ""]);

// exports
exports.locals = {
	"wrapper": "_2h8xa",
	"section": "_3Xlh1",
	"header": "_2zCUr",
	"downloadDocsSection": "uyZSx",
	"sectionTitle": "_2sPeZ",
	"sectionTitleSubHeader": "_1XlN8",
	"sectionAnswers": "_251iz",
	"Row": "_308F7",
	"actionButton": "_3K-wg",
	"trashButton": "CPTrX",
	"saveActionButton": "_3UTwb",
	"handleTitleChangeInput": "GvvNu",
	"cancelActionButton": "_1l1qp",
	"uploadLogo": "_1mdCY"
};