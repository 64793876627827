import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  color: #373737;
  overflow-wrap: break-word;
  width: 100%;
`;
Container.displayName = 'QuestionParagraphContainer';

const Paragraph = styled.div`
  color: #373737;
  margin-left: 15px;

  p {
    margin-bottom: unset;
    margin-top: unset;
  }
`;
Paragraph.displayName = 'QuestionParagraph';

export default function QuestionParagraphDisplayForm({ question }) {
  const updatedText = !!question.text ? question.text.replace(/<p><\/p>/g, '<br />') : '';
  return (
    <Container>
      <Paragraph
        dangerouslySetInnerHTML={{
          __html: updatedText,
        }}
      />
    </Container>
  );
}
