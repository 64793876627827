import React from 'react';
import styled from 'styled-components';
import * as supportedIcons from '../settings/supportedIcons';

const StyledIcon = ({ type, color, size }) => {
  const StyleIcon = styled(supportedIcons[type])`
    color: ${color || 'inherit'};
    height: ${size || '16px'};
    width: ${size || '16px'};
  `;

  return <StyleIcon />;
};

export default StyledIcon;
