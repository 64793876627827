import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { breakpoints } from '../../utils/breakpoints';

const Container = styled.div`
  grid-column: span 6 / span 6;
  display: flex;
  flex-direction: column;

  @media ${breakpoints.tablet} {
    grid-column: span 3 / span 3;
  }

  @media ${breakpoints.desktop} {
    grid-column: span 2 / span 2;
  }
`;

const Heading = styled.span`
  margin-bottom: 0.4375rem;
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  color: #373737;
  letter-spacing: 0.015em;
  line-height: 120%;
`;

const ChildrenContainer = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  color: #555555;
  letter-spacing: 0.015em;
  line-height: 120%;
`;

export default function IntakeSection({ heading, children }) {
  return (
    <Container>
      <Heading>{heading}</Heading>
      <ChildrenContainer>{children}</ChildrenContainer>
    </Container>
  );
}

IntakeSection.propTypes = {
  children: PropTypes.node.isRequired,
  heading: PropTypes.string.isRequired,
};
