exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ":root{--dark:var(--theme_color_dark);--accent:var(--theme_color_accent);--ocean:var(--theme_color_light);--button-border:var(--theme_border_color)}._3Z4vc{border-bottom:2px solid #f5f5f5;padding:32px 48px 32px 24px;padding:2rem 3rem 2rem 1.5rem;display:-ms-flexbox;display:flex;max-width:950px;font-family:Avenir,Arial,Helvetica,sans-serif;line-height:1.3;color:#043544;-webkit-font-smoothing:antialiased;font-smoothing:antialiased}._3YKQV{font-weight:600;margin-top:10px}._3YKQV,._27RkY{font-size:24px}._3FhDt{padding:16px 20px;border-radius:9px;background-color:#fffaee;width:100%}._3GB_3{color:#dd2160;font-weight:900;font-size:14px;line-height:1.3}._2S6vE{border-radius:50%;height:64px;width:64px;vertical-align:top!important}._1jnLY,._2S6vE{display:inline-block}._1jnLY{color:#363636;margin-left:10px;font-weight:500;font-size:14px}", ""]);

// exports
exports.locals = {
	"container": "_3Z4vc",
	"subject": "_3YKQV",
	"body": "_27RkY",
	"noteContent": "_3FhDt",
	"author": "_3GB_3",
	"avatar": "_2S6vE",
	"dateTime": "_1jnLY"
};