import React from 'react';
import { compose } from 'redux';
import { withCookies } from 'react-cookie';
import sModal from 'react-responsive-modal/styles.css';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { connect } from 'react-redux';
import 'url-search-params-polyfill';
import moment from 'moment-timezone';
import {
  fetchProfileData,
  fetchProfileHeader,
  selectTravelerProfileTab,
  fetchTravelerMessages,
  selectMessageTab,
  resetMessageCreateCode,
  fetchTravelerPreferences,
  fetchTravelerDetails,
  fetchCurrentUser,
  updateProfileData,
  fetchGenderIdentities,
} from '../../actions/profileActions';

import TravelerHero from './TravelerHero';
import Sidebar from '../../components/pages/TravelerProfile/Sidebar';
import Profile from '../../components/pages/TravelerProfile/Profile';
import Layout from '../../sites/travelerProfile/layouts/TravelerPrograms';
import ProgramDetailsLayout from '../../sites/travelerProfile/layouts/TravelerProgramDetails';
import TravelerPrograms from '../../sites/travelerProfile/components/TravelerPrograms';
import TravelerProgramDetails from '../../sites/travelerProfile/components/TravelerProgramDetails';
import Preferences from '../../components/pages/TravelerProfile/Preferences';
import Events from '../../components/pages/TravelerProfile/Events';
import Messages from '../../components/pages/TravelerProfile/Messages';
import LoadingScreen from '../../styledComponents/styles/LoadingScreen';
import SubmitModal from './SubmitModal/SubmitModal';

import s from './traveler-view.css';
import customBranding from '../../hoc/customBranding/customBranding';

import ViaModal from '../../sites/components/library/viaModal';
import ToastMessage from '../../sites/viaGlobal/components/toastMessage';
import WhatAreYouHereToDo from './whatAreYouHereToDo';

@customBranding
class TravelerView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      success: false,
      submitModal: false,
      commitModal: false,
      onClose: true,
      showModal: true,
      showWorkflow: true,
      showComponent: false,
    };

    this.setActiveTab = this.setActiveTab.bind(this);
    this.updateProfileDataInstructions = this.updateProfileDataInstructions.bind(this);
  }

  componentDidMount() {
    const { cookies } = this.props;
    const userId = cookies.get('user_id');

    if (!userId || typeof userId === 'undefined') {
      window.location.assign(`/`);
    } else {
      let search = window.location.search;
      let params = new URLSearchParams(search);
      let modal = params.get('modal');

      if (modal == 'appSubmit') {
        this.setState({ submitModal: true });
      } else if (modal == 'commitSubmit') {
        this.setState({ commitModal: true });
      }

      this.props.fetchProfileHeader(userId, 'traveler');
      this.props.fetchTravelerDetails(userId, 'traveler');
      this.props.fetchProfileData(userId, 'traveler');
      this.props.fetchTravelerMessages(userId, 'traveler');
      this.props.fetchTravelerPreferences(userId, 'traveler');
      this.props.fetchGenderIdentities(userId);
    }
  }

  closeModal = () => {
    this.setState({ submitModal: false, commitModal: false });
  };

  confirmModal = () => {
    this.setState({ submitModal: false });
    this.setActiveTab(null, 'applications');
  };

  confirmCommitModal = () => {
    this.setState({ commitModal: false });
    this.setActiveTab(null, 'forms');
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.updateProfileStatus) {
      this.setState({ success: true });
    }

    if (nextProps.program_match_complete) {
    }

    const splitWebsite = window.location.href.split('/').pop();

    if (splitWebsite === 'traveler' && !!nextProps && !!nextProps.profile[0] && !!nextProps.profile[0].info) {
      if (
        (nextProps.show_intake && !nextProps.intake_complete) ||
        (nextProps.show_intake && nextProps.show_program_match && !nextProps.intake_complete)
      ) {
        window.location.assign('/intake');
      } else if (
        nextProps.show_program_match &&
        nextProps.show_intake &&
        nextProps.intake_complete &&
        !nextProps.program_match_complete
      ) {
        window.location.assign('/program-match');
      } else if (nextProps.show_program_match && !nextProps.show_intake && !nextProps.program_match_complete) {
        window.location.assign('/program-match');
      }

      this.setState({ showComponent: true });
    }
  }
  setActiveTab(e, tab) {
    this.props.selectTravelerProfileTab(tab);
  }

  updateProfileDataInstructions = (showInstructions, source, showProgramMatch, showIntake) => {
    this.setShowInstructions(showInstructions);
    const profileInformation = this.props.profile && this.props.profile[0] && this.props.profile[0];
    let pedDay = moment(profileInformation.info.passport_expiration_date, 'YYYY/MM/DD').date();
    let pedMonth = 1 + moment(profileInformation.info.passport_expiration_date, 'YYYY/MM/DD').month();
    let pedYear = moment(profileInformation.info.passport_expiration_date, 'YYYY/MM/DD').year();

    let travelerInfo = {
      academicInfoExpanded: false,
      allowSubmission: true,
      contactInfoExpanded: false,
      countries_of_citizenship: profileInformation.info.countries_of_citizenship,
      country_code: profileInformation.info.country_code,
      cumulative_gpa: profileInformation.info.cumulative_gpa,
      current_address_city: profileInformation.info.current_address_city,
      current_address_country: profileInformation.info.current_address_country,
      current_address_line_1: profileInformation.info.current_address_line_1,
      current_address_line_2: profileInformation.info.current_address_line_2,
      current_address_state: profileInformation.info.current_address_state,
      current_address_zip_code: profileInformation.info.current_address_zip_code,
      customFieldsExpanded: false,
      date: null,
      disability: profileInformation.info.disability,
      dob: profileInformation.info.dob,
      dobType: true,
      emergencyContactInfoExpanded: false,
      emergency_contact_address_city: profileInformation.info.emergency_contact_address_city,
      emergency_contact_address_country: profileInformation.info.emergency_contact_address_country,
      emergency_contact_address_line_1: profileInformation.info.emergency_contact_address_line_1,
      emergency_contact_address_line_2: profileInformation.info.emergency_contact_address_line_2,
      emergency_contact_address_state: profileInformation.info.emergency_contact_address_state,
      emergency_contact_address_zip_code: profileInformation.info.emergency_contact_address_zip_code,
      emergency_contact_country_code: 1,
      emergency_contact_email: profileInformation.info.emergency_contact_email,
      emergency_contact_first_name: profileInformation.info.emergency_contact_first_name,
      emergency_contact_last_name: profileInformation.info.emergency_contact_last_name,
      emergency_contact_phone_number: profileInformation.info.emergency_contact_phone_number,
      emergency_contact_relationship: profileInformation.info.emergency_contact_relationship,
      ethnicity: profileInformation.info.ethnicity,
      focused: null,
      footerVisible: true,
      gender: profileInformation.info.gender,
      grad_year: profileInformation.info.grad_year,
      honors_status: profileInformation.info.honors_status,
      hours_earned: profileInformation.info.hours_earned,
      input: 'password',
      inputType: 'text',
      judicial_status: profileInformation.info.judicial_status,
      major_gpa: profileInformation.info.major_gpa,
      middle_name: profileInformation.profile.middle_name,
      on_site_phone_number: profileInformation.info.on_site_phone_number,
      passportMasked: true,
      passportStatus: profileInformation.profile.passport_status
        ? profileInformation.profile.passport_status.status
        : 'not_provided',
      passportVisaExpanded: false,
      passport_expiration_date: pedYear + '-' + pedMonth + '-' + pedDay,
      passport_issuing_agency: profileInformation.info.passport_issuing_agency,
      passport_number: profileInformation.info.passport_number,
      pedDay: pedDay,
      pedMonth: pedMonth,
      pedYear: pedYear,
      permanent_address_city: profileInformation.info.permanent_address_city,
      permanent_address_country: profileInformation.info.permanent_address_country,
      permanent_address_line_1: profileInformation.info.permanent_address_line_1,
      permanent_address_line_2: profileInformation.info.permanent_address_line_2,
      permanent_address_state: profileInformation.info.permanent_address_state,
      permanent_address_zip_code: profileInformation.info.permanent_address_zip_code,
      personalInfoExpanded: true,
      personal_email: profileInformation.info.personal_email,
      phone_number: profileInformation.info.phone_number,
      place_of_birth: profileInformation.info.place_of_birth,
      places_of_study: profileInformation.info.places_of_study,
      preferred_first_name: profileInformation.info.preferred_first_name,
      preferred_last_name: profileInformation.info.preferred_last_name,
      primary_major: profileInformation.info.primary_major,
      primary_minor: profileInformation.info.primary_minor,
      pronoun: profileInformation.info.pronoun,
      race: profileInformation.info.race,
      residency: profileInformation.info.residency,
      safe_check_phone_number: profileInformation.info.safe_check_phone_number,
      school_name: profileInformation.info.school_name,
      secondary_emergency_contact_address_city: profileInformation.info.secondary_emergency_contact_address_city,
      secondary_emergency_contact_address_country: profileInformation.info.secondary_emergency_contact_address_country,
      secondary_emergency_contact_address_line_1: profileInformation.info.secondary_emergency_contact_address_line_1,
      secondary_emergency_contact_address_line_2: profileInformation.info.secondary_emergency_contact_address_line_2,
      secondary_emergency_contact_address_state: profileInformation.info.secondary_emergency_contact_address_state,
      secondary_emergency_contact_address_zip_code:
        profileInformation.info.secondary_emergency_contact_address_zip_code,
      secondary_emergency_contact_country_code: profileInformation.info.secondary_emergency_contact_country_code,
      secondary_emergency_contact_email: profileInformation.info.secondary_emergency_contact_email,
      secondary_emergency_contact_first_name: profileInformation.info.secondary_emergency_contact_first_name,
      secondary_emergency_contact_last_name: profileInformation.info.secondary_emergency_contact_last_name,
      secondary_emergency_contact_phone_number: profileInformation.info.secondary_emergency_contact_phone_number,
      secondary_emergency_contact_relationship: profileInformation.info.secondary_emergency_contact_relationship,
      secondary_major: profileInformation.info.secondary_major,
      secondary_minor: profileInformation.info.secondary_minor,
      show_traveler_welcome_screen: showInstructions,
      status: profileInformation.info.status,
      student_id: profileInformation.info.student_id,
      third_minor: profileInformation.info.third_minor,
      title: profileInformation.info.title,
      traveler_email: profileInformation.traveler_email,
      traveler_type: profileInformation.info.traveler_type,
      veteran_status: profileInformation.info.veteran_status,
      visa_description: profileInformation.info.visa_description,
      year_in_school: profileInformation.info.year_in_school,
    };
    let passport = {};
    let profile = {};
    let custom_fields = {};
    const custom_field_keys = Object.keys(profileInformation).filter(key => key.includes('custom_field_'));
    custom_field_keys.map(key => (custom_fields[key.replace('custom_field_', '')] = profileInformation[key]));
    custom_field_keys.map(key => delete profileInformation[key]);
    passport.status = travelerInfo.passportStatus;
    profile.first_name = profileInformation.profile.first_name;
    profile.middle_name = profileInformation.profile.middle_name;
    profile.last_name = profileInformation.profile.last_name;
    this.props.updateProfileData(
      profileInformation.profile.user_id,
      travelerInfo,
      passport,
      profile,
      custom_fields,
      'traveler',
      source === 'explore' ? 'travelerView' : source,
      showProgramMatch,
      showIntake,
    );
  };

  setShowInstructions = showInstructions => {
    this.setState({ showWorkflow: showInstructions });
  };

  render() {
    const { cookies } = this.props;
    const userId = cookies.get('user_id');

    const { forceTab, activeTab } = this.props;

    const selectedTab = activeTab || forceTab;

    if (this.props.loading == true || this.props.headerLoading == true) {
      return <LoadingScreen displayText='Loading...' />;
    } else if (this.props.profile == null || this.props.header == null) {
      return (
        <div>
          <div>error</div>
          <div>Header Info: {this.props.header}</div>
          <div>ProfileInfo: {this.props.profile}</div>
        </div>
      );
    }
    return (
      <div>
        <div className={`${s.noPadding} container-fluid`}>
          {this.state.submitModal ? (
            <SubmitModal
              showModal={this.state.submitModal}
              closeModal={this.closeModal}
              title={'You did it!'}
              type="submit"
              confirmHandler={this.confirmModal}
            >
              <p className={s.inactiveConfirmationText}>You successfully submitted your application for review.</p>
              <p className={s.inactiveConfirmationText}>Check your application status anytime in your profile.</p>
            </SubmitModal>
          ) : null}
          {this.state.commitModal ? (
            <SubmitModal
              showModal={this.state.commitModal}
              closeModal={this.closeModal}
              title={"You're in!"}
              type="commit"
              confirmHandler={this.confirmCommitModal}
            >
              <p className={s.inactiveConfirmationText}>
                You successfully committed to the {this.props.customAliases.alias_program.toLowerCase()}.
              </p>
              <p className={s.inactiveConfirmationText}>Take the next step by completing predeparture requirements.</p>
            </SubmitModal>
          ) : null}
          <div>
            <TravelerHero
              id={userId}
              header={this.props.header}
              profile={this.props.profile}
              headerLoading={this.props.headerLoading}
              loading={this.props.loading}
              setActiveTab={this.setActiveTab}
              currentUser={this.props.currentUser}
              orgTimezone={this.props.orgTimezone}
              clientFeatureList={this.props.clientFeatureList}
            />
          </div>
          <div className="row no-gutters">
            <div className="col-md-3">
              <Sidebar
                role="traveler"
                activeTab={selectedTab}
                customAliases={this.props.customAliases}
                clientFeatureList={this.props.clientFeatureList}
              />
            </div>
            <div className="col-md-9">
              <div className="content">
                {selectedTab === 'profile' && (
                  <Profile id={userId} profile={this.props.profile} role="traveler" />
                )}
                {selectedTab === 'messages' &&
                  this.props.clientAccountState === 'active' && (
                    <Messages
                      id={userId}
                      role="traveler"
                      messagesLoading={this.props.messagesLoading}
                      adminsLoading={this.props.adminsLoading}
                      messages={this.props.messages}
                      messageCreate={this.props.messageCreate}
                      assignedAdmins={this.props.assignedAdmins}
                      admins={this.props.admins}
                      activeMessageTab={this.props.activeMessageTab}
                      newMessage={this.props.newMessage}
                      avatar={this.props.avatar}
                      currentUserAvatar={this.props.currentUserAvatar}
                    />
                  )}
                {selectedTab === 'preferences' &&
                  this.props.clientFeatureList.legacy && (
                    <Preferences
                      id={userId}
                      role="traveler"
                      preferences={this.props.preferences}
                      preferencesLoading={this.props.preferencesLoading}
                      percent={this.props.percent}
                      customAliases={this.props.customAliases}
                      show_program_match={this.props.show_program_match}
                      show_intake={this.props.show_intake}
                    />
                  )}
                {selectedTab === 'events' && this.props.clientFeatureList.legacy && (
                  <Events
                    id={userId}
                    role="traveler"
                    events={this.props.events}
                    eventsLoading={this.props.eventsLoading}
                  />
                )}
                {selectedTab === 'programs' &&
                  this.props.clientFeatureList.legacy && (
                    <>
                      {this.props.viewDetails ? (
                        <ProgramDetailsLayout submissionId={this.props.submissionId}>
                          <TravelerProgramDetails legacyWrapper />
                        </ProgramDetailsLayout>
                      ) : (
                        <Layout>
                          <TravelerPrograms layout='partial' tab={this.props.tab} />
                        </Layout>
                      )}
                    </>
                  )}
              </div>
            </div>
          </div>
        </div>
        {this.state.success && (
          <ToastMessage
            message="All changes saved"
            isSuccess={true}
            show={this.state.success}
            onClose={() => {
              this.setState({ success: false });
            }}
          />
        )}
        {this.state.failure && (
          <ToastMessage
            message={this.state.toastMessage}
            isSuccess={false}
            id={'failure-update'}
            show={this.state.failure}
            onClose={
              () => this.closeToast
            }
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  signedIn: state.auth.signedIn,
  profile: state.profile.profile,
  loading: state.profile.profileLoading,
  header: state.profile.header,
  headerLoading: state.profile.headerLoading,
  activeTab: state.profile.activeTab,
  updateProfileStatus: state.profile.updateProfileStatus,
  applications: state.profile.applications,
  applicationsLoading: state.profile.detailsLoading,
  events: state.profile.events,
  forms: state.profile.forms,
  activeMessageTab: state.profile.activeMessageTab,
  messages: state.profile.messages,
  admins: state.profile.admins,
  assignedAdmins: state.profile.assignedAdmins,
  adminsLoading: state.profile.adminsLoading,
  messagesLoading: state.profile.messagesLoading,
  messageCreate: state.profile.messageCreate,
  newMessage: state.profile.message,
  preferencesLoading: state.profile.preferencesLoading,
  preferences: state.profile.preferences,
  percent: state.profile.preferences.percent,
  currentUser: state.profile.currentUser,
  avatar: state.profile.currentUserAvatar,
  userLoading: state.profile.userLoading,
  favorites: state.profile.favorites,
  detailsLoading: state.profile.detailsLoading,
  customBranding: state.profile.customBranding || {},
  customAliases: state.profile.customAliases || {},
  clientAccountState: state.profile.clientAccountState,
  formsLoading: state.templates && state.templates.loading ? state.templates.loading : false,
  orgTimezone: state.profile.orgTimezone,
  clientFeatureList: state.profile.clientFeatureList,
  show_program_match: state.profile.show_program_match,
  show_intake: state.profile.show_intake,
  organization: state.auth.organization,
  intake_complete: state.profile.intake_complete,
  program_match_complete: state.profile.program_match_complete,
});

export default compose(
  withCookies,
  withStyles(s, sModal),
  connect(
    mapStateToProps,
    {
      fetchProfileData,
      fetchProfileHeader,
      selectTravelerProfileTab,
      fetchTravelerMessages,
      selectMessageTab,
      resetMessageCreateCode,
      fetchTravelerPreferences,
      fetchTravelerDetails,
      fetchCurrentUser,
      updateProfileData,
      fetchGenderIdentities,
    },
  ),
)(TravelerView);
