import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'recompose';
import { Formik, Form, Field } from 'formik';
import _orderBy from 'lodash/orderBy';
import propTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import GenericStyledModal from '../../../../forms/pages/admin/modals/GenericStyledModal.modal';
import Button from '../../../../components/library/button';
import { AccentButtonMediumNunito } from '../../../../../styledComponents/styles/Buttons.styled';
import { AccentButtonSpinner } from '../../../../../styledComponents/styles/spinner.styled';
import sBatchAddTags from './batchAddTags.scss';
import ToastMessage from '../../toastMessage';
import SubmissionTagDropDown from '../../../../../styledComponents/styles/SubmissionTagDropDown.styled';
import { getAvailableTags } from '../../../../../actions/formsActions';
import { addApplicationTags } from '../../../../../actions/reportsActions';
export const enhance = compose(withStyles(sBatchAddTags));

let fetchedTags = false;

function BatchAddTags(props) {
  const dispatch = useDispatch();
  const availableTags = useSelector(state => state.forms.availableTags);
  const addApplicationTagsData = useSelector(state => state.reports.addApplicationTags);
  const customAliasTraveler = useSelector(state => state.profile.customAliases.alias_traveler.toLowerCase());
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);
  const [saving, setSaving] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [failureMessage, setFailureMessage] = useState('');
  const [alltagOptions, setAlltagOptions] = useState([]);
  const [tagSearchQuery, setTagSearchQuery] = useState('');
  const [applicationTag, setApplicationTag] = useState([]);

  useEffect(() => {
    dispatch(getAvailableTags(props.type));
  }, []);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      width: 'max-content',
      marginTop: '7px',
      marginLeft: '7px',
      padding: '4px 8px',
      gap: '4px',
      fontFamily: 'Open Sans',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '700',
      borderRadius: '4px',
      display: 'flex',
      color: state.data.textColor,
      backgroundColor: state.data.bgcolor,
    }),
    multiValue: (styles, state) => ({
      ...styles,
      backgroundColor: state.data.bgcolor,
    }),
    multiValueLabel: (styles, state) => ({
      ...styles,
      color: state.data.textColor,
      fontWeight: '700',
      fontSize: '14px',
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
    }),
    placeholder: styles => {
      return {
        ...styles,
        color: '#555',
        fontFamily: 'Open Sans',
        fontSize: '14px',
        fontStyle: 'italic',
        fontWeight: '400',
        lineHeight: '120%',
        letterSpacing: '0.21px',
      };
    },
  };
  const handleFilterOption = (option, rawInput) => {
    // Implement your custom filtering logic here.
    return option.label.toLowerCase().includes(rawInput.toLowerCase());
  };
  const handleTagInputChange = inputValue => {
    setTagSearchQuery(inputValue);
  };
  const handleSelectChange = newValue => {
    setApplicationTag(newValue?.length > 0 ? newValue : []);
  };

  useEffect(() => {
    const tagList = availableTags?.data?.data;
    const tags = tagList?.map(tag => {
      let tagLabel = tag.attributes.name;
      if (tag.attributes.emoji !== null && tag.attributes.emoji !== undefined) {
        tagLabel =
          tag.attributes.emoji
            .split(',')
            .join('')
            .trim() +
          ' ' +
          tag.attributes.name;
      }
      return {
        label: tagLabel,
        value: tag.id,
        name: tag.attributes.name.toLowerCase(),
        description: tag.attributes.description,
        bgcolor: tag.attributes.tag_color.bg_hex_code,
        textColor: tag.attributes.tag_color.font_hex_code,
      };
    });
    const sortedTags = _orderBy(tags, 'name', 'asc');

    const tagOptions = sortedTags?.filter(tag => {
      return handleFilterOption(tag, tagSearchQuery);
    });
    setAlltagOptions(tagOptions);
  }, [availableTags]);

  const resetForm = () => {
    setDefaultValues(() => {
      let values = {
        applicationSelected: [],
      };
      return values;
    });
  };

  useEffect(() => {
    if (addApplicationTagsData) {
      if (addApplicationTagsData.loading) {
        setSaving(true);
      }
      if (!addApplicationTagsData.loading && !!addApplicationTagsData.data) {
        if (addApplicationTagsData.data) {
          setSaving(false);
          setSuccessMessage(addApplicationTagsData.data);
          setSuccess(true);
          resetForm();
          props.onClose(false);
        }
        if (addApplicationTagsData.error) {
          setSaving(false);
          setFailureMessage(addApplicationTagsData.error);
          setFailure(true);
          resetForm();
          props.onClose(false);
        }
      }
    }
  }, [addApplicationTagsData]);

  const [defaultValues, setDefaultValues] = useState(() => {
    let values = {
      applicationSelected: [],
    };
    return values;
  });

  return (
    <>
      <GenericStyledModal
        showing={props.show}
        onClose={() => {
          props.onClose(false);
        }}
        width="700px"
        header={`Add ${props.type} Tags`}
      >
        <div className={sBatchAddTags['modal-body']}>
          <Formik
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={defaultValues}
            validate={(values, index) => {
              const errors = {};
              if (applicationTag.length === 0) {
                errors.status = 'Please select tags';
              }
              return errors;
            }}
            onSubmit={values => {
              const submissionTagIds = applicationTag?.map(tag => {
                return tag.value;
              });
              const submitValues = {
                tag_ids: submissionTagIds,
                submission_ids: props.applicationIds,
                type: props.type,
              };
              setSaving(true);
              dispatch(addApplicationTags(submitValues, props.type));
              props.setSelectedPrograms();
            }}
          >
            {({ values, handleSubmit, errors }) => (
              <Form>
                <div className={sBatchAddTags.heading}>
                  <p>
                    You are about to add tags to {props.applicationIds.length} {customAliasTraveler}{' '}
                    {props.type == 'Application'
                      ? props.applicationIds.length === 1
                        ? 'application'
                        : 'applications'
                      : props.applicationIds.length === 1
                      ? 'form'
                      : 'forms'}
                    .{' '}
                  </p>
                  <p style={{ fontWeight: '700' }}>
                    Want to create a new tag or edit an existing one? Click here to{' '}
                    <a
                      href="
https://help.viatrm.com/how-do-i-create-tags-to-add-to-traveler-applications-and-forms"
                      target="_blank"
                    >
                      Learn More
                    </a>
                    .
                  </p>
                </div>
                <div className={sBatchAddTags.row}>
                  <div className={sBatchAddTags.item}>
                    <Field name="tags">
                      {({ form }) => (
                        <div style={{ width: '450px', margin: 'auto' }}>
                          <SubmissionTagDropDown
                            key={`action_submission`}
                            aria-label="Submission Tag"
                            placeholder={'Type to select tag'}
                            id="application_tag"
                            options={alltagOptions}
                            handleInputChange={handleTagInputChange}
                            handleSelectChange={handleSelectChange}
                            tagSearchQuery={tagSearchQuery}
                            customStyles={customStyles}
                            width="100%"
                            showCreateTagOption={false}
                          />
                        </div>
                      )}
                    </Field>
                  </div>
                </div>

                <div className={sBatchAddTags.footer}>
                  <div className={sBatchAddTags.buttonContainer}>
                    <div className={sBatchAddTags['button-content']}>
                      <Button
                        display="secondary"
                        kind="solid"
                        size="medium"
                        aria-label="Cancel Button"
                        onClick={() => {
                          props.onClose(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                    <div className={sBatchAddTags['button-content']}>
                      <AccentButtonMediumNunito
                        disabled={saving || applicationTag.length == 0}
                        aria-label="Add Tags"
                        type="submit"
                      >
                        {saving ? <AccentButtonSpinner displayText="Loading ..." /> : 'Add Tags'}
                      </AccentButtonMediumNunito>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </GenericStyledModal>

      {success ? (
        <ToastMessage
          message={successMessage}
          show={success}
          isSuccess
          newestOnTop={false}
          onClose={() => {
            setSuccess(false);
          }}
        />
      ) : null}
      {failure ? (
        <ToastMessage
          message={failureMessage}
          isSuccess={false}
          show={failure}
          newestOnTop={false}
          onClose={() => {
            setFailure(false);
          }}
        />
      ) : null}
    </>
  );
}

BatchAddTags.propTypes = {
  show: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
};

BatchAddTags.propTypes = {
  show: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
};

export default enhance(BatchAddTags);
