import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import trash from '../ProgramViewTestimonial/trash.svg';
import Eye from '../../../../routes/admin-sign-in/img/eye.png';

import s from './ProgramEligibility.css';
import DraftEditorSingle from '../../../../components/Admin/ProgramView/DraftEditor/DraftEditorSingle';
import ReactHtmlParser from 'react-html-parser';
import _trim from 'lodash/trim';

class ProgramEligibility extends React.Component {
  render() {
    if (this.props.role == 'Admin') {
      if (this.props.status == 'view') {
        return (
          <div className={s.wrapper}>
            <div className={s.section}>
              <h2 className={s.header}>Eligibility Requirements</h2>
              <p className={s.eligibility}>
                {ReactHtmlParser(_trim(this.props.eligibility))}
              </p>
            </div>
          </div>
        );
      } else if (this.props.status == 'new') {
        return (
          <div className={s.wrapper}>
            <div className={s.section}>
              <div
                className={this.props.showEligibility == true ? '' : s.blur
                }
              >
                <h2 className={s.header}>Eligibility Requirements</h2>
                <DraftEditorSingle
                  description={this.props.eligibility}
                  descriptionEditChild={this.props.eligibilityEdit}
                  status={'eligibility'}
                  disabled={this.props.showEligibility == true ? false : true}
                  alias_program={this.props.alias_program}
                />
              </div>
            </div>
          </div>
        );
      }
    } else {
      return (
        <div className={s.wrapper}>
          <div className={s.section}>
            <h2 className={s.header}>Eligibility Requirements</h2>
            <p className={s.eligibility}>{ReactHtmlParser(_trim(this.props.eligibility))}</p>
          </div>
        </div>
      );
    }
  }
}

export default withStyles(s)(ProgramEligibility);
