import React from 'react';
const DuplicateIcon = (props) => {
  return(
  <div>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.75 3.5H5.75C5.15326 3.5 4.58097 3.73705 4.15901 4.15901C3.73705 4.58097 3.5 5.15326 3.5 5.75V12.75C3.5 13.3467 3.73705 13.919 4.15901 14.341C4.58097 14.7629 5.15326 15 5.75 15H12.75C13.3467 15 13.919 14.7629 14.341 14.341C14.7629 13.919 15 13.3467 15 12.75V5.75C15 5.15326 14.7629 4.58097 14.341 4.15901C13.919 3.73705 13.3467 3.5 12.75 3.5ZM11.7359 9.75H9.75V11.7359C9.75 12.005 9.54312 12.2359 9.27406 12.2494C9.20646 12.2526 9.1389 12.2421 9.07547 12.2185C9.01204 12.1949 8.95407 12.1586 8.90508 12.1119C8.85608 12.0652 8.81707 12.0091 8.79041 11.9469C8.76375 11.8847 8.75001 11.8177 8.75 11.75V9.75H6.76406C6.495 9.75 6.26406 9.54312 6.25063 9.27406C6.24737 9.20646 6.25787 9.1389 6.2815 9.07547C6.30512 9.01204 6.34138 8.95407 6.38807 8.90508C6.43476 8.85608 6.49091 8.81707 6.55313 8.79041C6.61534 8.76375 6.68232 8.75001 6.75 8.75H8.75V6.76406C8.75 6.495 8.95688 6.26406 9.22594 6.25063C9.29354 6.24737 9.3611 6.25787 9.42453 6.2815C9.48796 6.30512 9.54593 6.34138 9.59492 6.38807C9.64392 6.43476 9.68293 6.49091 9.70959 6.55313C9.73625 6.61534 9.74999 6.68232 9.75 6.75V8.75H11.75C11.8177 8.74992 11.8848 8.76361 11.9471 8.79023C12.0094 8.81684 12.0656 8.85584 12.1123 8.90485C12.1591 8.95386 12.1954 9.01186 12.2191 9.07532C12.2427 9.13879 12.2533 9.2064 12.25 9.27406C12.2369 9.54312 12.005 9.75 11.7359 9.75Z" fill={props.color}/>
      <path d="M12.3713 2.5C12.2157 2.06164 11.9283 1.68215 11.5485 1.41359C11.1687 1.14503 10.7151 1.00056 10.25 1H3.25C2.65326 1 2.08097 1.23705 1.65901 1.65901C1.23705 2.08097 1 2.65326 1 3.25V10.25C1.00056 10.7151 1.14503 11.1687 1.41359 11.5485C1.68215 11.9283 2.06164 12.2157 2.5 12.3713V5C2.5 4.33696 2.76339 3.70107 3.23223 3.23223C3.70107 2.76339 4.33696 2.5 5 2.5H12.3713Z" fill={props.color}/>
    </svg>
  </div>
  )
}
export default (DuplicateIcon);