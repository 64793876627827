exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1GGWz{background:var(--theme_color_accent);border-color:var(--theme_color_accent);color:var(--theme_color_accent_font);font-family:Open Sans;font-size:16px;font-weight:700;line-height:120%}._1GGWz:active,._1GGWz:disabled,._1GGWz:focus,._1GGWz:hover,._1GGWz:not(:disabled):not(.QgSU_):active{background:var(--theme_color_accent);color:var(--theme_color_accent_font);border-color:var(--theme_color_accent);opacity:.7;-webkit-box-shadow:unset;box-shadow:unset}", ""]);

// exports
exports.locals = {
	"btn": "_1GGWz",
	"disabled": "QgSU_"
};