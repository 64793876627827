import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { fetchAuthorizedProgramSettings } from '../../../../actions/orgSettingsActions';
import propTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import sApplicationReportsSubHeader from './applicationReportsSubheader.scss';

function ApplicationReportsSubHeader(props) {
  const dispatch = useDispatch();
  const customAliases = useSelector(state => state.profile.customAliases);
  const freemium = useSelector(state => state.profile.freemium);
  const userId = useSelector(state => state.profile.currentUser.id);

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      dispatch(fetchAuthorizedProgramSettings(userId));
    }
    return () => {
      unmounted = true;
    };
  }, []);

  return (
    <>
      <div className={sApplicationReportsSubHeader.subheader}>
        {!props.traveler ? (
          <div className={sApplicationReportsSubHeader['subheader-content']}>
            <div className={sApplicationReportsSubHeader.item}>
              <NavLink
                className={sApplicationReportsSubHeader.selection}
                // to={!props.betaLinks ? '/client/application-dashboard' : '/client/application-dashboard-beta'}
                to={'/client/application-dashboard'}
                activeClassName={sApplicationReportsSubHeader.selected}
              >
                {customAliases.alias_traveler} Applications
              </NavLink>
            </div>
            <div className={sApplicationReportsSubHeader.item}>
              <NavLink
                className={sApplicationReportsSubHeader.selection}
                // to={
                //   !props.betaLinks
                //     ? '/client/application-content-dashboard'
                //     : '/client/application-content-dashboard-beta'
                // }
                to={'/client/application-content-dashboard'}
                activeClassName={sApplicationReportsSubHeader.selected}
              >
                Application Content
              </NavLink>
            </div>
          </div>
        ) : (
          <div className={sApplicationReportsSubHeader['subheader-content']} />
        )}
      </div>
    </>
  );
}

ApplicationReportsSubHeader.defaultProps = {
  betaLinks: false,
};

ApplicationReportsSubHeader.propTypes = {
  traveler: propTypes.bool.isRequired,
  betaLinks: propTypes.bool,
};

export default withStyles(sApplicationReportsSubHeader)(ApplicationReportsSubHeader);
