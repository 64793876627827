import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import LocationsFilterInputs from './LocationsFilterInputs';
import MobileFilterContainer from '../MobileFilterContainer.styled';

const Subheading = styled.span`
  margin-bottom: 1rem;
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  color: #373737;
  line-height: 120%;
`;

export default function MobileLocationsFilter({
  selectedCities,
  selectedCountries,
  handleCitiesChange,
  handleCountriesChange,
}) {
  return (
    <MobileFilterContainer bottomBorder>
      <Subheading>Locations</Subheading>
      <LocationsFilterInputs
        selectedCities={selectedCities}
        selectedCountries={selectedCountries}
        handleCitiesChange={handleCitiesChange}
        handleCountriesChange={handleCountriesChange}
      />
    </MobileFilterContainer>
  );
}

MobileLocationsFilter.propTypes = {
  handleCitiesChange: PropTypes.func.isRequired,
  handleCountriesChange: PropTypes.func.isRequired,
  selectedCities: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  selectedCountries: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
};
