import fetch from 'node-fetch';

import { keys } from '../../../config/keys';

import {
  FETCH_INTAKE_FORM,
  ADD_INTAKE_FORM_GOAL,
  ADD_INTAKE_FORM_PROFILE,
  ADD_INTAKE_FORM_ADVISING,
  ADD_INTAKE_FORM_COMPLETE,
  ADD_INTAKE_FORM_PASSPORT,
  ADD_INTAKE_FORM_FINANCIAL_AID,
  ADD_INTAKE_FORM_TRAVELER_TYPE,
  ADD_INTAKE_FORM_FINANCIAL_INFO,
  ADD_INTAKE_FORM_HOME_INSTITUTION,
  ADD_INTAKE_FORM_TRAVEL_EXPERIENCE,
  ADD_INTAKE_FORM_PARTICIPATION_CERTAINTY,
  ADD_INTAKE_FORM_COUNTRIES_OF_CITIZENSHIP,
  ADD_INTAKE_FORM_PRIMARY_MAJOR,
  ADD_INTAKE_FORM_PRIMARY_MINOR,
  ADD_INTAKE_FORM_HONORS_STATUS,
  ADD_INTAKE_FORM_PRONOUN,
  ADD_INTAKE_FORM_YEAR_IN_SCHOOL,
  ADD_INTAKE_FORM_GRAD_YEAR,
  ADD_INTAKE_FORM_CUMULATIVE_GPA,
  ADD_INTAKE_FORM_VETERAN_STATUS,
  ADD_INTAKE_FORM_FIRST_GENERATION_STATUS,
  ADMIN_INTAKE_FORM_VALUES,
  CLEAR_ADMIN_INTAKE_FORM_VALUES,
  SHOW_INTAKE_PREVIEW,
} from './types';
import { Cookies } from 'react-cookie';

export function fetchIntake(userId) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/traveler/intake_wizard/${userId}/get_intake`, { headers: token })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: FETCH_INTAKE_FORM,
          payload: data,
        }),
      );
  };
}

export function addIntakeProfile(userId, data) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/traveler/intake_wizard/${userId}/update_traveler_profile/`, {
      method: 'PUT',
      body: JSON.stringify({ profile: data }),
      headers: token,
    })
      .then(res => res.json())
      .then(res => {
        if (res[0].status == 200) {
          dispatch({
            type: ADD_INTAKE_FORM_PROFILE,
            payload: data,
          });
        }
      });
  };
}

export function addIntakeCountriesOfCitizenship(userId, data) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/traveler/intake_wizard/${userId}/update_traveler_info/`, {
      method: 'PUT',
      body: JSON.stringify({ traveler_info: data }),
      headers: token,
    })
      .then(res => res.json())
      .then(res => {
        if (res[0].status == 200) {
          dispatch({
            type: ADD_INTAKE_FORM_COUNTRIES_OF_CITIZENSHIP,
            payload: data,
          });
        }
      });
  };
}

export function addIntakeTravelerType(userId, data, travelerType) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/traveler/intake_wizard/${userId}/update_traveler_info/`, {
      method: 'PUT',
      body: JSON.stringify({ traveler_info: data }),
      headers: token,
    })
      .then(res => res.json())
      .then(res => {
        if (res[0].status == 200) {
          dispatch({
            type: ADD_INTAKE_FORM_TRAVELER_TYPE,
            payload: travelerType,
          });
        }
      });
  };
}

export function addIntakeHomeInstitution(userId, data) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/traveler/intake_wizard/${userId}/update_traveler_info/`, {
      method: 'PUT',
      body: JSON.stringify({ traveler_info: data }),
      headers: token,
    })
      .then(res => res.json())
      .then(res => {
        if (res[0].status == 200) {
          dispatch({
            type: ADD_INTAKE_FORM_HOME_INSTITUTION,
            payload: data,
          });
        }
      });
  };
}

export function addIntakeAdvising(userId, data) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/traveler/intake_wizard/${userId}/update_advising/`, {
      method: 'PUT',
      body: JSON.stringify({ advising: data }),
      headers: token,
    })
      .then(res => res.json())
      .then(res => {
        if (res[0].status == 200) {
          dispatch({
            type: ADD_INTAKE_FORM_ADVISING,
            payload: data,
          });
        }
      });
  };
}

export function addIntakeFinancialInfo(userId, data) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/traveler/intake_wizard/${userId}/update_financial_info/`, {
      method: 'PUT',
      body: JSON.stringify({ financial_info: data }),
      headers: token,
    })
      .then(res => res.json())
      .then(res => {
        if (res[0].status == 200) {
          dispatch({
            type: ADD_INTAKE_FORM_FINANCIAL_INFO,
            payload: data,
          });
        }
      });
  };
}

export function addIntakeFinancialAid(userId, selected) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/traveler/intake_wizard/${userId}/update_financial_info/`, {
      method: 'PUT',
      body: JSON.stringify({
        financial_info: { receives_federal_financial_aid: selected },
      }),
      headers: token,
    })
      .then(res => res.json())
      .then(res => {
        if (res[0].status == 200) {
          dispatch({
            type: ADD_INTAKE_FORM_FINANCIAL_AID,
            payload: selected,
          });
        }
      });
  };
}

export function addIntakeGoal(userId, data) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/traveler/intake_wizard/${userId}/update_travel_goal/`, {
      method: 'PUT',
      body: JSON.stringify({ travel_goal: data }),
      headers: token,
    })
      .then(res => res.json())
      .then(res => {
        if (res[0].status == 200) {
          dispatch({
            type: ADD_INTAKE_FORM_GOAL,
            payload: data,
          });
        }
      });
  };
}

export function addIntakeTravelExperience(userId, data) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/traveler/intake_wizard/${userId}/update_travel_experience/`, {
      method: 'PUT',
      body: JSON.stringify({ travel_experience: data }),
      headers: token,
    })
      .then(res => res.json())
      .then(res => {
        if (res[0].status == 200) {
          dispatch({
            type: ADD_INTAKE_FORM_TRAVEL_EXPERIENCE,
            payload: data,
          });
        }
      });
  };
}

export function addIntakePassport(userId, data) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/traveler/intake_wizard/${userId}/update_passport/`, {
      method: 'PUT',
      body: JSON.stringify({ passport: data }),
      headers: token,
    })
      .then(res => res.json())
      .then(res => {
        if (res[0].status == 200) {
          dispatch({
            type: ADD_INTAKE_FORM_PASSPORT,
            payload: data,
          });
        }
      });
  };
}

export function addIntakeParticipationCertainty(userId, data, intakeComplete) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/traveler/intake_wizard/${userId}/update_traveler_info/`, {
      method: 'PUT',
      body: JSON.stringify({ traveler_info: { ...data, intake_complete: intakeComplete } }),
      headers: token,
    })
      .then(res => res.json())
      .then(res => {
        if (res[0].status == 200) {
          dispatch({
            type: ADD_INTAKE_FORM_PARTICIPATION_CERTAINTY,
            payload: {
              ...data,
              intake_complete: intakeComplete,
            },
          });
        }
      });
  };
}

export function addIntakeComplete(userId, data) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/traveler/intake_wizard/${userId}/update_traveler_info/`, {
      method: 'PUT',
      body: JSON.stringify({ traveler_info: { intake_complete: data } }),
      headers: token,
    })
      .then(res => res.json())
      .then(res => {
        if (res[0].status == 200) {
          dispatch({
            type: ADD_INTAKE_FORM_COMPLETE,
            payload: data,
          });
        }
      });
  };
}

const getQuestionType = type => {
  const questionType = {
    primary_major: ADD_INTAKE_FORM_PRIMARY_MAJOR,
    primary_minor: ADD_INTAKE_FORM_PRIMARY_MINOR,
    honors_status: ADD_INTAKE_FORM_HONORS_STATUS,
    pronoun: ADD_INTAKE_FORM_PRONOUN,
    year_in_school: ADD_INTAKE_FORM_YEAR_IN_SCHOOL,
    grad_year: ADD_INTAKE_FORM_GRAD_YEAR,
    cumulative_gpa: ADD_INTAKE_FORM_CUMULATIVE_GPA,
    veteran_status: ADD_INTAKE_FORM_VETERAN_STATUS,
    first_generation_status: ADD_INTAKE_FORM_FIRST_GENERATION_STATUS,
  };
  return questionType[type];
};

export function addIntakeQuestions(userId, data, type) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/traveler/intake_wizard/${userId}/update_traveler_info/`, {
      method: 'PUT',
      body: JSON.stringify({ traveler_info: data }),
      headers: token,
    })
      .then(res => res.json())
      .then(res => {
        if (res[0].status == 200) {
          dispatch({
            type: getQuestionType(type),
            payload: data,
          });
        }
      });
  };
}

export function addAdminIntakeFormData(step, data) {
  return function(dispatch) {
    dispatch({
      type: ADMIN_INTAKE_FORM_VALUES,
      payload: { step, data },
    });
  };
}

export function clearAdminIntakeFormData(step, data) {
  return function(dispatch) {
    dispatch({
      type: CLEAR_ADMIN_INTAKE_FORM_VALUES,
      payload: {},
    });
  };
}
export function showIntakePreview(value) {
  return function(dispatch) {
    dispatch({
      type: SHOW_INTAKE_PREVIEW,
      payload: value,
    });
  };
}
