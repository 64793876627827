exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@-webkit-keyframes _2JDCK{to{-webkit-transform:rotate(1turn)}}@keyframes _2JDCK{to{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}._2Xzt3 .I_VmC{-ms-flex:1 1;flex:1 1;-ms-flex-direction:row;flex-direction:row;display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;padding:15px}@media only screen and (max-width:599px){._2Xzt3 .I_VmC{-ms-flex-direction:column;flex-direction:column}}._2Xzt3 ._1LGEP{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;padding:40px 100px;-ms-flex-pack:center;justify-content:center}._2Xzt3 ._1LGEP ._3JiIZ{padding:12px}._2Xzt3 ._1VKbN{-ms-flex:1 1;flex:1 1;-ms-flex-direction:column;flex-direction:column;display:-ms-flexbox;display:flex;border-top:2px solid #ebebeb;padding:32px 20px 0 0;-ms-flex-pack:center;justify-content:center}@media only screen and (max-width:599px){._2Xzt3 ._1VKbN{-ms-flex-direction:column;flex-direction:column}}._2Xzt3 ._1VKbN ._3ZAXn{padding:20px 0;display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-pack:center;justify-content:center}._2Xzt3 ._1VKbN ._3ZAXn ._5nhk9{padding-left:12px}@media only screen and (max-width:599px){._2Xzt3 ._1VKbN ._3ZAXn ._5nhk9{padding-top:12px}}._2Xzt3 ._1VKbN ._2GV1C{-ms-flex:1 1;flex:1 1}", ""]);

// exports
exports.locals = {
	"success-admin-added-group-detail": "_2Xzt3",
	"subHeader": "I_VmC",
	"content": "_1LGEP",
	"toggle": "_3JiIZ",
	"footer": "_1VKbN",
	"footer-button-wrapper": "_3ZAXn",
	"button-content": "_5nhk9",
	"footer-confirm": "_2GV1C",
	"spin": "_2JDCK"
};