export default [
  {
    id: 1,
    label: 'Citizen',
    value: 'citizen',
  },
  {
    id: 2,
    label: 'In state',
    value: 'in_state',
  },
  {
    id: 3,
    label: 'International student/visitor',
    value: 'international',
  },
  {
    id: 4,
    label: 'National',
    value: 'national',
  },
  {
    id: 5,
    label: 'Out of state',
    value: 'out_of_state',
  },
  {
    id: 6,
    label: 'Permanent resident',
    value: 'permanent_resident',
  },
  {
    id: 7,
    label: 'Refugee/stateless',
    value: 'refugee',
  },
  {
    id: 8,
    label: 'Other residency',
    value: 'other_residency',
  },
];
