import MidTermLength from '../../components/icons/MidTermLength';
import LongTermLength from '../../components/icons/LongTermLength';
import ShortTermLength from '../../components/icons/ShortTermLength';

export const programLengthRadioOptions = [
  {
    id: 1,
    label: 'Short-term (less than 1 month)',
    value: {
      minWeeks: 1,
      maxWeeks: 4,
    },
    icon: ShortTermLength,
  },
  {
    id: 2,
    label: 'Mid-length (1 - 6 months)',
    value: {
      minWeeks: 4,
      maxWeeks: 26,
    },
    icon: MidTermLength,
  },
  {
    id: 3,
    label: 'Long-term (6 months - 1 year)',
    value: {
      minWeeks: 26,
      maxWeeks: 52,
    },
    icon: LongTermLength,
  },
];

export const customLengthSelectOptions = [
  {
    label: '1 week',
    value: 1,
  },
  {
    label: '2 weeks',
    value: 2,
  },
  {
    label: '3 weeks',
    value: 3,
  },
  {
    label: '4 weeks',
    value: 4,
  },
  {
    label: '1 month',
    value: 5,
  },
  {
    label: '2 months',
    value: 9,
  },
  {
    label: '3 months',
    value: 13,
  },
  {
    label: '4 months',
    value: 17,
  },
  {
    label: '5 months',
    value: 22,
  },
  {
    label: '6 months',
    value: 26,
  },
  {
    label: '7 months',
    value: 30,
  },
  {
    label: '8 months',
    value: 35,
  },
  {
    label: '9 months',
    value: 39,
  },
  {
    label: '10 months',
    value: 43,
  },
  {
    label: '11 months',
    value: 48,
  },
  {
    label: '12 months',
    value: 52,
  },
  {
    label: '13 months',
    value: 56,
  },
  {
    label: '14 months',
    value: 61,
  },
  {
    label: '15 months',
    value: 65,
  },
  {
    label: '16 months',
    value: 70,
  },
  {
    label: '17 months',
    value: 74,
  },
  {
    label: '18 months',
    value: 78,
  },
  {
    label: '19 months',
    value: 83,
  },
  {
    label: '20 months',
    value: 87,
  },
  {
    label: '21 months',
    value: 91,
  },
  {
    label: '22 months',
    value: 96,
  },
  {
    label: '23 months',
    value: 100,
  },
  {
    label: '24 months',
    value: 104,
  },
];
