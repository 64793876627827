import React from 'react';
import { useSelector } from 'react-redux';

import ContentBlock from '../../structure/ContentBlock';
import { TextBlockRichContentSection } from '../../structure/ContentBlock.styled';

import { TabCard, TabHeading, TabCardPanel, TabContentContainer } from '../TabContent.styled';
import EmbeddedVideoView from '../../../../../components/EmbeddedVideoView';

export default function Eligibility() {
  const eligibility = useSelector(state => state.programBrochure.eligibility);
  const eligibility_video_url = useSelector(state => state.programBrochure.eligibility_video_url);

  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = eligibility;
  const updatedContent = tempDiv.textContent || tempDiv.innerText;    
  
  return (
    <TabContentContainer>
      <TabHeading level={2}>Eligibility</TabHeading>
      <TabCard>
        <TabCardPanel className="full">
          {updatedContent?.trim()?.length>0 &&
            <ContentBlock>
              <TextBlockRichContentSection sectionContent={eligibility} />
            </ContentBlock>
          }
          {eligibility_video_url && <div style={{ marginTop: "1.5rem" }}><EmbeddedVideoView url={eligibility_video_url} /></div>}

        </TabCardPanel>
      </TabCard>
    </TabContentContainer>
  );
}
