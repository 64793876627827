import React from 'react';
import { connect } from 'react-redux';
import { removeAlert } from '../../actions/alertActions';
import { compose } from 'redux';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import ReactHtmlParser from 'react-html-parser';
import s from './Alert.css';
class Alert extends React.Component {
  remove = () => {
    var { alert } = this.props;
    this.props.removeAlert(alert.id);
  };
  render() {
    var { alert, style } = this.props;
    return (
      <div className={s[alert.style]} key={alert.id}>
        <div className={s.alert}>
          {ReactHtmlParser(alert.text)}
          <span className={s.settingsAction} onClick={this.remove}>
            x
          </span>
        </div>
      </div>
    );
  }
}
export default compose(
  withStyles(s),
  connect(
    null,
    {
      removeAlert,
    },
  ),
)(Alert);
