import React from 'react';
import PropTypes from 'prop-types';

export default function HamburgerIcon({ fillColor }) {
  return (
    <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M6.09375 6.1875C6.09375 5.61796 6.55546 5.15625 7.125 5.15625H19.5C20.0695 5.15625 20.5312 5.61796 20.5312 6.1875C20.5312 6.75704 20.0695 7.21875 19.5 7.21875H7.125C6.55546 7.21875 6.09375 6.75704 6.09375 6.1875Z" fill={fillColor}/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M6.09375 11C6.09375 10.4305 6.55546 9.96875 7.125 9.96875H19.5C20.0695 9.96875 20.5312 10.4305 20.5312 11C20.5312 11.5695 20.0695 12.0312 19.5 12.0312H7.125C6.55546 12.0312 6.09375 11.5695 6.09375 11Z" fill={fillColor}/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M6.09375 15.8125C6.09375 15.243 6.55546 14.7812 7.125 14.7812H19.5C20.0695 14.7812 20.5312 15.243 20.5312 15.8125C20.5312 16.382 20.0695 16.8438 19.5 16.8438H7.125C6.55546 16.8438 6.09375 16.382 6.09375 15.8125Z" fill={fillColor} />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M2.3125 6.1875C2.3125 5.42811 2.92811 4.8125 3.6875 4.8125C4.44689 4.8125 5.0625 5.42811 5.0625 6.1875C5.0625 6.94689 4.44689 7.5625 3.6875 7.5625C2.92811 7.5625 2.3125 6.94689 2.3125 6.1875Z" fill={fillColor} />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M2.3125 11C2.3125 10.2406 2.92811 9.625 3.6875 9.625C4.44689 9.625 5.0625 10.2406 5.0625 11C5.0625 11.7594 4.44689 12.375 3.6875 12.375C2.92811 12.375 2.3125 11.7594 2.3125 11Z" fill={fillColor} />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M2.3125 15.8125C2.3125 15.0531 2.92811 14.4375 3.6875 14.4375C4.44689 14.4375 5.0625 15.0531 5.0625 15.8125C5.0625 16.5719 4.44689 17.1875 3.6875 17.1875C2.92811 17.1875 2.3125 16.5719 2.3125 15.8125Z" fill={fillColor} />
    </svg>
  );
}

HamburgerIcon.propTypes = {
  fillColor: PropTypes.string,
};

HamburgerIcon.defaultProps = {
  fillColor: 'white',
};
