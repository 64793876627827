import React, { useEffect, useState } from 'react';
import _get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import { ACCENT_DEFAULT, DARK_DEFAULT, LIGHT_DEFAULT } from '../helpers/ViaDefaults';
import { fetchOrganization } from '../../actions/authActions';
import { getContrastColor } from '../helpers/General';

export function useThemeColors(){
  const dispatch = useDispatch();
  const organization = useSelector(state => state.auth.organization);
  const urlDomain = window.location.hostname.split('.')[0];
  const [isLoaded, setIsLoaded] = useState(false);

  const setThemeProperties = (accent = ACCENT_DEFAULT, dark = DARK_DEFAULT, light = LIGHT_DEFAULT) => {
    document.documentElement.style.setProperty('--theme_color_accent', accent);
    document.documentElement.style.setProperty('--theme_color_accent_font', getContrastColor(accent));
    document.documentElement.style.setProperty('--theme_color_dark', dark);
    document.documentElement.style.setProperty('--theme_color_dark_font', getContrastColor(dark));
    document.documentElement.style.setProperty('--theme_color_light', light);
    document.documentElement.style.setProperty('--theme_color_light_font', getContrastColor(light));

    setTimeout(() => {
      setIsLoaded(true);
    }, 100);
  }

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      if (urlDomain.toLowerCase() !== 'localhost' && !urlDomain.toLowerCase().includes('www')) {
        dispatch(fetchOrganization(urlDomain));
      } else {
        setThemeProperties();
      }
    }

    return () => {
      unmounted = true;
    };
  }, []);

  useEffect(() => {
    if (organization && organization.branding_theme) {
      const accentColor = _get(organization, 'branding_theme.themes.theme_color_accent') || ACCENT_DEFAULT;
      const darkColor = _get(organization, 'branding_theme.themes.theme_color_dark') || DARK_DEFAULT;
      const lightColor = _get(organization, 'branding_theme.themes.theme_color_light') || LIGHT_DEFAULT;

      setThemeProperties(accentColor, darkColor, lightColor);
    }
  }, [organization]);

  return isLoaded;
}
