import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import InputSelect from '../../../../components/library/inputSelect';
import { fetchPlanTypes } from '../../../../../actions/lookupActions';

function PlanTypeSelect(props) {
  const dispatch = useDispatch();
  const planTypes = useSelector(state => state.lookup.allPlanTypes);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (!planTypes.planTypes) {
      dispatch(fetchPlanTypes());
    } else {
      setOptions(
        planTypes.planTypes.map(item => {
          return { label: item.attributes.name, value: item.id };
        }),
      );
    }
  }, [planTypes]);

  return (
    <>
      {planTypes && planTypes.planTypes && (
        <InputSelect {...props} options={options} />
      )}
    </>
  );
}

PlanTypeSelect.propTypes = {
  errorMessage: propTypes.string,
  labelText: propTypes.string,
  helpText: propTypes.string,
  placeholder: propTypes.string,
  isRequired: propTypes.bool,
};


PlanTypeSelect.defaultProps = {
  errorMessage: '',
  labelText: 'PLAN TYPE',
  helpText: '',
  placeholder: '',
  isRequired: false,
};

export default (PlanTypeSelect);
