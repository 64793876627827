import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { compose } from 'recompose';
import propTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Headline from '../../../../components/library/headline';
import Button from '../../../../components/library/button';
import Paragraph from '../../../../components/library/paragraph';
import sEditDetailSuccessModal from './editDetailSuccessModal.scss';

export const enhance = compose(
  withStyles(sEditDetailSuccessModal),
  withRouter,
);

function EditDetailSuccessModal(props) {
  return (
    <>
      <div className={sEditDetailSuccessModal['edit-detail-success-modal']}>
        <div
          className={
            sEditDetailSuccessModal['edit-detail-success-modal-content']
          }
        >
          <Headline tag="h1" as="h1" centered italic>
            Success!
          </Headline>
        </div>
        <div
          className={
            sEditDetailSuccessModal['edit-detail-success-modal-content']
          }
        >
          <Headline tag="h2" as="h3" centered>
            YOUR EDITS SAVED TO THE ITINERARY.
          </Headline>
        </div>
        <div
          className={
            sEditDetailSuccessModal['edit-detail-success-modal-content']
          }
        >
          <Paragraph size="large">
            The Travelers associated with this detail have been notified by
            email that this detail has been updated.
          </Paragraph>
        </div>

        <div className={sEditDetailSuccessModal['button-row']}>
          <div className={sEditDetailSuccessModal['button-content']}>
            <Button
              display="primary"
              kind="solid"
              size="medium"
              onClick={() => {
                props.onClose(false);
              }}
            >
              Return To Itinerary
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

EditDetailSuccessModal.propTypes = {
  onClose: propTypes.func,
};

export default enhance(EditDetailSuccessModal);
