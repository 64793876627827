import React from 'react';

export default function DashboardIcon() {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="icons" clip-path="url(#clip0_5531_8935)">
        <path
          id="Vector"
          d="M13.0436 5.41164C12.9738 5.34488 12.881 5.30762 12.7844 5.30762C12.6878 5.30762 12.595 5.34488 12.5252 5.41164L3.89551 13.6555C3.85886 13.6906 3.8297 13.7327 3.8098 13.7794C3.7899 13.826 3.77967 13.8762 3.77973 13.9269L3.77832 21.6641C3.77832 22.062 3.93636 22.4435 4.21766 22.7248C4.49896 23.0061 4.8805 23.1641 5.27832 23.1641H9.78301C9.98192 23.1641 10.1727 23.0851 10.3133 22.9445C10.454 22.8038 10.533 22.613 10.533 22.4141V16.0391C10.533 15.9397 10.5725 15.8443 10.6428 15.774C10.7132 15.7036 10.8086 15.6641 10.908 15.6641H14.658C14.7575 15.6641 14.8528 15.7036 14.9232 15.774C14.9935 15.8443 15.033 15.9397 15.033 16.0391V22.4141C15.033 22.613 15.112 22.8038 15.2527 22.9445C15.3933 23.0851 15.5841 23.1641 15.783 23.1641H20.2858C20.6836 23.1641 21.0652 23.0061 21.3465 22.7248C21.6278 22.4435 21.7858 22.062 21.7858 21.6641V13.9269C21.7859 13.8762 21.7756 13.826 21.7557 13.7794C21.7358 13.7327 21.7067 13.6906 21.67 13.6555L13.0436 5.41164Z"
        />
        <path
          id="Vector_2"
          d="M23.7941 12.1086L20.2879 8.75423V3.66406C20.2879 3.46515 20.2089 3.27439 20.0682 3.13373C19.9276 2.99308 19.7368 2.91406 19.5379 2.91406H17.2879C17.089 2.91406 16.8982 2.99308 16.7576 3.13373C16.6169 3.27439 16.5379 3.46515 16.5379 3.66406V5.16407L13.8229 2.56813C13.5688 2.31125 13.191 2.16406 12.7827 2.16406C12.3759 2.16406 11.999 2.31125 11.7449 2.56859L1.77461 12.1077C1.48305 12.3889 1.44648 12.8516 1.7118 13.1563C1.77842 13.2332 1.86 13.2957 1.95156 13.3401C2.04312 13.3845 2.14276 13.4098 2.24441 13.4144C2.34605 13.4191 2.44758 13.403 2.54282 13.3671C2.63805 13.3313 2.72499 13.2765 2.79836 13.206L12.5249 3.91156C12.5947 3.84481 12.6876 3.80755 12.7841 3.80755C12.8807 3.80755 12.9736 3.84481 13.0434 3.91156L22.7709 13.206C22.9141 13.3434 23.1061 13.4184 23.3046 13.4145C23.5031 13.4106 23.6919 13.3282 23.8298 13.1853C24.1176 12.8872 24.0937 12.395 23.7941 12.1086Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_5531_8935">
          <rect width="24" height="24" fill="white" transform="translate(0.782715 0.664062)" />
        </clipPath>
      </defs>
    </svg>
  );
}
