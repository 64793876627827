import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import trash from '../ProgramViewTestimonial/trash.svg';
import Eye from '../../../../routes/admin-sign-in/img/eye.png';
import DraftEditorSingle from '../../../../components/Admin/ProgramView/DraftEditor/DraftEditorSingle';
import s from './ProgramScholarship.css';
import ReactHtmlParser from 'react-html-parser';

class ProgramScholarship extends React.Component {
  render() {
    if (this.props.role == 'Admin') {
      if (this.props.status == 'view') {
        return (
          <div className={s.wrapper}>
            <div className={s.section}>
              <h2 className={s.header}>
                Scholarship or Financial Aid Opportunities
              </h2>
              <p className={s.scholarshipInfo}>
                {ReactHtmlParser(this.props.financialAid.scholarship_info)}
              </p>
              {/* <p>Early application discount</p>
                  <p>Fund for Education Abroad</p>
                  <p>Departmental Scholarship/Grants</p>
                  <p className={s.bold}>Pell Grant Matching Awards</p>
                  <p>Institutional & Federal Awards</p> */}
            </div>
          </div>
        );
      } else if (this.props.status == 'new') {
        return (
          <div className={s.wrapper}>
            <div className={s.section}>
              <div
                className={
                  this.props.showScholarship == true ? '' : s.blur
                }
              >
                <h2 className={s.header}>
                  Scholarship or Financial Aid Opportunities
                </h2>
                <DraftEditorSingle
                  description={this.props.scholarship}
                  descriptionEditChild={this.props.scholarshipEdit}
                  status={'scholarship'}
                  disabled={this.props.showScholarship == true ? false : true}
                  alias_program={this.props.alias_program}
                />
              </div>
            </div>
          </div>
        );
      }
    } else {
      return (
        <div className={s.wrapper}>
          <div className={s.section}>
            <h2 className={s.header}>
              Scholarship or Financial Aid Opportunities
            </h2>
            <p className={s.scholarshipInfo}>
              {ReactHtmlParser(this.props.financialAid.scholarship_info)}
            </p>
            {/* <p>Early application discount</p>
            <p>Fund for Education Abroad</p>
            <p>Departmental Scholarship/Grants</p>
            <p className={s.bold}>Pell Grant Matching Awards</p>
            <p>Institutional & Federal Awards</p> */}
          </div>
        </div>
      );
    }
  }
}

export default withStyles(s)(ProgramScholarship);
