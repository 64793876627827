import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import OrganizationInfo from '../organization/OrganizationInfo';

import { breakpoints } from '../../utils/breakpoints';
import getSidebarLinks from '../../utils/getSidebarLinks';
import { buttonFocus } from '../../utils/shared/focusStyles';
import getTravelerDisplayName from '../../utils/getTravelerDisplayName';
import ViaIcon from '../../../../sites/plans/components/ViaIcon';

import sidemenuexpore from '../../../../shared/images/sidemenuexpprog.png';
import sidemenuregtra from '../../../../shared/images/sidemenuregtravel.png';

const Container = styled.nav`
  display: none;
  width: 330px;
  min-width: 330px;
  min-height: 95vh;
  padding: 2rem 1.676875rem 2rem 2.375rem;
  background-color: #ffffff;
  border-radius: 15px;

  @media ${breakpoints.desktop} {
    display: flex;
    flex-direction: column;
  }
`;

const ExploreContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin-top: 2.6875rem;
  margin-bottom: 1.2188rem;
  margin-left: -0.4375rem;
`;

const MenuIcon = styled.img`
  height: 30px;
  width: 30px;
  display: inline-block;
  margin-right: 6px;
`;
const Span = styled.span`
  display: inline-block;
`;

const ExploreLink = styled(NavLink)`
  padding: 0.4375rem 0.7753rem;
  font-family: 'Nunito', sans-serif;
  font-weight: 700;
  font-size: 1rem;
  color: #446372;
  letter-spacing: 0.04em;
  border-radius: 5px;
  text-decoration: none;
  &:hover {
    color: #446372;
    text-decoration: none;
  }

  ${buttonFocus};
`;

const TravelerInfoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1.175625rem;
  margin: 2.4375rem 0;
  padding-top: 2.125rem;
  border-top: 1px solid #bbbbbb;

  img {
    width: 55px;
    height: 55px;
    border-radius: 50%;
  }
`;

const TravelerInfo = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: scroll;
  scrollbar-gutter: stable;

  span {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    letter-spacing: 0.04em;
    line-height: 130%;
  }

  span:first-of-type {
    font-size: 0.875rem;
    color: #2a2a2a;
  }

  span:last-of-type {
    font-size: 0.75rem;
    color: #373737;
  }
`;

const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.9375rem;
  margin-left: -0.4375rem;
  padding-top: 1.2188rem;
  border-top: 1px solid gray;
`;

const Link = styled(NavLink)`
  padding: 0.4375rem 0.7753rem;
  padding-top: 0.6123rem;
  font-family: 'Nunito', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  color: #446372;
  letter-spacing: 0.04em;
  border-radius: 5px;

  &.active {
    border-bottom: 2px solid #255a94;
    color: #eeeeee;
    background-color: #255a94;
  }

  &:hover {
    background-color: #255a94;
    color: #eeeeee;
    text-decoration: none;
  }

  ${buttonFocus};
`;

const CollapsibleDiv = styled.div``;

const GroupMenu = styled.div`
  ${props =>
    props.isActive &&
    css`
      background-color: #255a94 !important;

      a,
      span {
        background-color: #255a94 !important;
        color: #eeeeee !important;
        text-decoration: none !important;
      }

      svg {
        background-color: #eeeeee !important;
        color: #255a94 !important;
        border-radius: 2rem !important;
      }
    `} display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  cursor: pointer;
  padding: 0.4375rem 0.7753rem;
  padding-top: 0.6123rem;
  font-family: 'Nunito', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  color: #446372;
  letter-spacing: 0.04em;
  border-radius: 5px;
  border: none;
  background: transparent;
  display: flex;
  justify-content: space-between;
  width: 100%;
  text-align: left;

  a,
  span {
    text-decoration: none;
    color: #446372;
  }

  &.active {
    border-bottom: 2px solid #255a94;
    color: #eeeeee;
    background-color: #255a94;
  }

  &:hover {
    background-color: #255a94;

    a,
    span {
      background-color: #255a94;
      color: #eeeeee;
      text-decoration: none;
    }

    svg {
      background-color: #eeeeee;
      color: #255a94;
      border-radius: 2rem;
    }
  }

  &:focus-visible {
    outline: 2px solid #255a94;
    outline-offset: 1px;
  }
`;

const ArrowIconUp = styled(KeyboardArrowUpIcon)`
  display: inline-block;
`;

const ArrowIconDown = styled(KeyboardArrowDownIcon)`
  display: inline-block;
`;

const InnerLink = styled(NavLink)`
  padding: 0.4375rem 0.7753rem;
  padding-top: 0.6123rem;
  font-family: 'Nunito', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  color: #446372;
  letter-spacing: 0.04em;
  border-radius: 5px;
  display: block;

  &.active {
    border-bottom: 2px solid #255a94;
    color: #eeeeee;
    background-color: #255a94;
  }

  &:hover {
    background-color: #255a94;
    color: #eeeeee;
    text-decoration: none;
  }

  ${buttonFocus};
`;

const CollpasibleMenu = styled.div`
  margin-left: 0.4375rem;
  padding-left: 0.4375rem;
  border-left: 1px solid #eeeeee;
`;

export default function SidebarNavigation({ routeSegment }) {
  const {
    clientFeatureList,
    customAliases: { alias_programs: programsAlias },
  } = useSelector(state => state.currentOrganization);

  const [openSubMenu, setOpenSubMenu] = useState(false);

  const { info, profile } = useSelector(state => state.travelerProfile);

  const sidebarLinks = getSidebarLinks(clientFeatureList.legacy, clientFeatureList.travel_plans, programsAlias);

  const travelerDisplayName = getTravelerDisplayName(info, profile);

  useEffect(() => {
    if (window.location.pathname.includes('my-travel') || window.location.pathname.includes('safecheck')) {
      setOpenSubMenu(true);
    } else {
      setOpenSubMenu(false);
    }
  }, [window.location.pathname]);

  return (
    <Container aria-label="Main Navigation">
      <OrganizationInfo />
      {(clientFeatureList.legacy || clientFeatureList.travel_plans) && (
        <ExploreContainer>
          {clientFeatureList.legacy && (
            <ExploreLink to="/traveler/program-dashboard-v2">
              <MenuIcon src={sidemenuexpore} alt={`Explore Program Icon`} longdesc={"Explore Program Icon"}  />
              <Span>Explore {programsAlias}</Span>
            </ExploreLink>
          )}
          {clientFeatureList.travel_plans && (
            <ExploreLink to="/plans/register-travel-plans">
              <MenuIcon src={sidemenuregtra} alt="Register Travel Icon" longdesc={`Register Travel`}  />
              <Span>Register Travel</Span>
            </ExploreLink>
          )}
        </ExploreContainer>
      )}
      <LinksContainer>
        {sidebarLinks.map(sidebarLink => {
          if (!sidebarLink.submenu) {
            return (
              <Link
                key={`sidebar-link-${sidebarLink.id}`}
                to={sidebarLink.path}
                isActive={match => (routeSegment ? match && match.url.includes(routeSegment) : match)}
              >
                {sidebarLink.label}
              </Link>
            );
          } else {
            return (
              <CollapsibleDiv key={`sidebar-link-${sidebarLink.id}`}>
                <GroupMenu
                  onClick={e => {
                    e.preventDefault();
                    setOpenSubMenu(!openSubMenu);
                  }}
                  aria-label={openSubMenu ? `${sidebarLink.label} toggle button expanded` : `${sidebarLink.label} toggle button collapsed`}
                  role="link"
                  isActive={
                    window.location.pathname.includes('my-travel') || window.location.pathname.includes('safecheck')
                  }
                  tabIndex={0}
                  onKeyDown={e => {
                    if (e.keyCode === 13) {
                      e.preventDefault();
                      setOpenSubMenu(!openSubMenu);
                      
                    }
                  }}
                >
                  <span>{sidebarLink.label}</span>
                  {!openSubMenu ? <ArrowIconDown /> : <ArrowIconUp />}
                </GroupMenu>

                {openSubMenu && (
                  <CollpasibleMenu>
                    {sidebarLink.menuList.map(submenuLink => (
                      <InnerLink
                        key={`sidebar-link-${sidebarLink.id}`}
                        to={submenuLink.path}
                        isActive={match => (routeSegment ? match && match.url.includes(routeSegment) : match)}
                      >
                        {submenuLink.label}
                      </InnerLink>
                    ))}
                  </CollpasibleMenu>
                )}
              </CollapsibleDiv>
            );
          }
        })}
      </LinksContainer>
    </Container>
  );
}

SidebarNavigation.propTypes = {
  routeSegment: PropTypes.string,
};

SidebarNavigation.defaultProps = {
  routeSegment: '',
};
