exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ":root{--dark:var(--theme_color_dark);--accent:var(--theme_color_accent);--ocean:var(--theme_color_light)}._3TR_G{display:inline-block}._1nUhC{background:#fff;color:var(--theme_color_accent);color:var(--accent,#dd2160);font-size:8px;border:0;padding-top:5px;vertical-align:bottom;height:34px;width:36px;border-radius:4px}._1nUhC svg{fill:var(--theme_color_accent);fill:var(--accent,#dd2160)}._1nUhC:focus,._1nUhC:hover{cursor:pointer;outline:0}._3ge24{color:var(--theme_color_accent);color:var(--accent,#dd2160)}", ""]);

// exports
exports.locals = {
	"buttonWrapper": "_3TR_G",
	"button": "_1nUhC",
	"active": "_3ge24"
};