export default function travelerProgramsMenuTabs(counts, favoritesAlias) {
  return [
    {
      id: 1,
      path: 'favorites',
      title: `${favoritesAlias} (${counts.favoritesCount})`,
      component: 'favorites',
    },
    {
      id: 2,
      path: 'upcoming',
      title: `Upcoming (${counts.upcomingCount})`,
      component: 'upcoming',
    },
    {
      id: 3,
      path: 'past',
      title: `Past (${counts.pastCount})`,
      component: 'past',
    },
  ];
}
