import React from 'react';
import { useDispatch, useSelector } from 'react-redux'; 
import styled from 'styled-components';
import fileIcon from './fileIcon.png';
import { capitalizeFirstLetter } from '../../../helpers/RiskAlertHelper';
import { markReadNotification } from '../../../actions/reportsActions';


const Container = styled.button`
  text-decoration: none !important;
  padding: 12px 6px;
  display: flex; 
  flex-direction: row; 
  gap: 15px; 
  border-top: 1px solid #D7D7D7;
  border-bottom: 1px solid #D7D7D7;
  background: none; 
  border: none; 
`;

const IconContainer = styled.div`
    padding: 12px; 
    background-color: #D2E4ED; 
    border-radius: 5px; 
`;

const IconImage = styled.img`
    width: unset !important;
    height: unset !important;
    position: unset !important; 
    right: unset !important; 
    bottom: unset !important; 
    max-width: unset !important; 
`;

const InfoContainer = styled.div`
    display: flex; 
    flex-direction: column; 
    min-width: 200px;
    align-items: flex-start; 
    text-align: left; 
`;

const NotificationTitle = styled.div`
  color: #999;
  font-family: Nunito;
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 13.2px */
  letter-spacing: 0.011px;
`;

const FileNameDisabled = styled.div`
  color: #446372;
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 14.4px */
  letter-spacing: 0.012px;
  margin-top:4px;
`;

const FileNameActive = styled.div`
  color: #446372;
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 14.4px */
  letter-spacing: 0.012px;
  text-decoration-line: underline;
  margin-top:4px;
`;

const DownloadStatus = styled.div`
  color: var(--black, #373737);
  font-family: Open Sans;
  font-size: 10px;
  font-style: italic;
  font-weight: 400;
  line-height: 120%; /* 12px */
  letter-spacing: 0.05px;
  margin-top:4px;
`;

export default function NotificationItem({ item }) {

  const dispatch = useDispatch(); 
  
  return (
    <Container type='button' onClick={(e) => {
      e.preventDefault(); 
      dispatch(markReadNotification(item.id, (status) => {
        if(status){
          window.location.href = "/reports/downloads"; 
        }
      })); 
    }}>
      <IconContainer>
        <IconImage src={fileIcon} />
      </IconContainer>

      <InfoContainer>
        <NotificationTitle>Report Downloads</NotificationTitle>
        {
          item?.attributes?.status === "completed" ? <FileNameActive>{item?.attributes?.file?.file_name}</FileNameActive> : <FileNameDisabled>{item?.attributes?.file?.file_name}</FileNameDisabled>
        }
        
        <DownloadStatus>{capitalizeFirstLetter(item?.attributes?.status)}</DownloadStatus>
      </InfoContainer>
    </Container>
  );
}
