import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ProfileFormContainer from '../base/ProfileFormContainer';
import ProfileFormSubheading from '../base/ProfileFormSubheading';
import ToastMessage from '../../../viaGlobal/components/toastMessage';
import { ProfileFormButton, ProfileFormButtonContainer } from '../base/ProfileFormButton.styled';
import userId from '../../../../shared/helpers/userId';
import { updateTravelerProfile } from '../../actions/travelerProfileActions';
import ProfileFormGrid from '../base/ProfileFormGrid.styled';
import ProfileTextInput from '../base/inputs/ProfileTextInput';

export default function AdditionalTab() {
  const dispatch = useDispatch();
  const { customFields } = useSelector(state => state.travelerProfile);
  const { organizationName } = useSelector(state => state.currentOrganization);
  const [availableCustomFields, setAvailableCustomFields] = useState([]);
  const [form, setForm] = useState({});
  const [formError, setFormError] = useState({});
  const [showToast, setShowToast] = useState(false);
  useEffect(() => {
    const formData = customFields?.reduce((acc, item) => {
      acc[item.title] = item.answer;
      return acc;
    }, {});

    setForm(formData);
    setAvailableCustomFields(customFields);
  }, [customFields]);

  const handleSubmit = event => {
    event.preventDefault();

    if (Object.keys(formError).length > 0) {
      return false;
    }

    const customFieldValues = { ...form };
    const formFields = availableCustomFields.map(item => [item.id, customFieldValues[item.title]]);
    const requestParams = { customFields: formFields };
    dispatch(
      updateTravelerProfile(userId, {
        travelerProfile: { ...requestParams },
      }),
    );

    setShowToast(true);
  };

  const customFieldInputs = availableCustomFields?.map(fieldData => {
    return (
      <ProfileTextInput
        id={fieldData.id}
        label={fieldData.title}
        synced={fieldData?.integration_field ? true : false}
        placeholder={`Enter ${fieldData?.title?.toLowerCase()}`}
        value={form[fieldData.title] || ''}
        handleOnChange={event => {
          setForm({ ...form, [fieldData.title]: event.target.value });
        }}
        onBlur={event => {
          let errorObj = { ...formError };
          if (errorObj.hasOwnProperty(fieldData.title)) {
            delete errorObj[fieldData.title];
            setFormError({ ...errorObj });
          }
        }}
        error={formError.hasOwnProperty(fieldData.title) ? formError[fieldData.title] : ''}
      />
    );
  });

  return (
    <form method="POST" onSubmit={handleSubmit}>
      <ProfileFormContainer heading="Additional Information">
        <div>
          <div>
            <ProfileFormSubheading text={`${organizationName} Information`} />
            <ProfileFormGrid>{customFieldInputs}</ProfileFormGrid>
          </div>
        </div>
        <ProfileFormButtonContainer>
          <ProfileFormButton type="submit">Save</ProfileFormButton>
        </ProfileFormButtonContainer>
        {showToast && (
          <ToastMessage
            message="All changes saved"
            isSuccess
            show={showToast}
            onClose={() => {
              setShowToast(false);
            }}
          />
        )}
      </ProfileFormContainer>
    </form>
  );
}
