exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@-webkit-keyframes ciJ5k{to{-webkit-transform:rotate(1turn)}}@keyframes ciJ5k{to{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}._3uypo{width:200px;height:200px;border:1px solid #ebebeb;border-radius:10px;-webkit-box-shadow:5px 5px 5px #ccc;box-shadow:5px 5px 5px #ccc;padding:12px;margin:0;background:none;font-size:16px;font-weight:400;display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;text-decoration:none;-ms-flex-pack:center;justify-content:center;letter-spacing:1px}@media only screen and (max-width:599px){._3uypo{width:100px;height:120px}}._3uypo:focus{outline:1px solid #ebebeb}._3uypo:hover{cursor:pointer}._3uypo.zxUhk{background-color:#ebebeb;-webkit-box-shadow:none;box-shadow:none}._3uypo ._3RXXT{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column}._3uypo ._3qau_{-ms-flex:1 1;flex:1 1;padding:12px 0}@media only screen and (max-width:599px){._3uypo ._3qau_{font-size:6px}}._3uypo ._3qau_ ._3kb_W{display:none}@media only screen and (max-width:599px){._3uypo ._3qau_ ._3kb_W{display:block}}._3uypo ._3qau_ ._1blNM{display:block}@media only screen and (max-width:599px){._3uypo ._3qau_ ._1blNM{display:none}}._3uypo ._1o8lu{-ms-flex:1 1;flex:1 1;padding:4px 0}@media only screen and (max-width:599px){._3uypo ._1o8lu{padding:4px 0}._3uypo ._1o8lu p{line-height:1.3;font-size:12px}}", ""]);

// exports
exports.locals = {
	"toggle-button": "_3uypo",
	"active": "zxUhk",
	"content": "_3RXXT",
	"row-icon": "_3qau_",
	"mobile": "_3kb_W",
	"notMobile": "_1blNM",
	"row-title": "_1o8lu",
	"spin": "ciJ5k"
};