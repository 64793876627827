import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import ReportStart from './ReportStart';
import s from './ReportsModal.css';
import { connect } from 'react-redux';
import { compose } from 'redux';

class ReportsModal extends React.Component {
  render() {
    const traveler_ids =
      this.props.selectedTravelers.length > 0
        ? this.props.selectedTravelers
        : this.props.travelers.map(travelerObject => travelerObject.traveler.id);

    const applications =
      this.props.selected_applications.length > 0
        ? this.props.applications.filter(application => {
            return this.props.selected_applications.includes(application.id);
          })
        : this.props.applications;

    return (
      <>
        {this.props.show && (
          <ReportStart
            traveler_ids={this.props.source === 'application_dashboard' ? this.props.userIds : traveler_ids}
            applications={this.props.source === 'application_dashboard' ? this.props.applicationIds : applications}
            customAliases={this.props.customAliases}
            orgTimezone={this.props.orgTimezone}
            origin={this.props.origin}
            source={this.props.source}
            show={this.props.show}
            handleClose={this.props.handleClose}
            reportType={this.props.reportType}
            reportBy={this.props?.reportBy}
            callbackAction={this.props.callbackAction ? this.props.callbackAction : () => {}}
          />
        )}
      </>
    );
  }
}
const mapStateToProps = state => ({
  customBranding: state.profile.customBranding || {},
  orgTimezone: state.profile.orgTimezone,
  selectedRows: state.adminApplications.selectedRows,
  selected_applications: state.adminApplications.selected_applications,
  selectedTravelers: state.client.selectedTravelers,
});

export default compose(withStyles(s), connect(mapStateToProps))(ReportsModal);
