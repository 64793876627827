import React, { lazy, Suspense, useEffect, useState } from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import _get from 'lodash/get';
import { Controller, useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import CurrencyInput from '../../../../../styledComponents/styles/CurrencyInput.styled';
import { Paragraph } from '../../../../../styledComponents/styles/Paragraph.styled';
import StyledIcon from '../../../../../styledComponents/styles/Icon.styled';
import TextInput from '../../../../../styledComponents/styles/TextInput.styled';
import { AccentButtonMediumNunito } from '../../../../../styledComponents/styles/Buttons.styled';
import { AccentButtonSpinner } from '../../../../../styledComponents/styles/spinner.styled';
import { updateForm, updateQuestion } from '../../../../../actions/formsActions';
import Toggle from '../../../../../styledComponents/styles/Toggle.styled';

const EditTextArea = lazy(() => import('../../../../../styledComponents/styles/EditTextArea.styled'));

const FormContainer = styled.div`
  font-family: 'Nunito', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 0.015em;
  color: #373737;
`;

const InputContainer = styled.div`
  flex: 1;
  margin: 20px 0;
  text-align: left;
`;

const CurrencyContainer = styled.div`
  flex: 1;
  margin: 20px 0 20px 20px;
  text-align: left;
`;

const CurrencyDiv = styled.div`
  line-height: 44px;
  font-size: 14px;
  font-weight: 400;
  font-family: Open Sans, sans-serif;

  span {
    display: inline-block;
    line-height: normal;
    vertical-align: middle;
  }
`;

const Label = styled(Paragraph)`
  color: #043544;
  font-size: 16px;
  font-weight: 600;
  font-family: Open Sans, sans-serif;
`;

const CostContainer = styled.div`
  display: flex;
  margin: 20px 0;
  text-align: left;
`;

const SubmitContainer = styled.main`
  display: flex;
  justify-content: flex-end;
  margin-top: 35px;
`;

const ToggleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: inherit;
`;

const ToggleLabel = styled.span`
  align-self: center;
  flex: 1;
  justify-content: center;
  padding-left: 10px;
  padding-bottom: 7px;
`;

export default function QuestionPayflowAddEdit({ question, edit, onClose }) {
  const MAX_LENGTH = 90;
  const qIdentifier = 'payflow';
  const dispatch = useDispatch();
  const fetchForm = useSelector(state => state.forms.fetchForm);
  const questionTypesInfo = useSelector(state => state.forms.questionTypes);
  const updateFormSubmit = useSelector(state => state.forms.updateForm);
  const updateQuestionSubmit = useSelector(state => state.forms.updateQuestion);
  const [formId, setFormId] = useState(null);
  const [disableSubmit, setDisableSubmit] = useState(false);

  const schema = yup.object().shape({
    payflow_label: yup
      .string()
      .required('Question must have label')
      .max(MAX_LENGTH, `Label can not be more than ${MAX_LENGTH} characters`),
    payflow_instructions: yup.string(),
    payflow_admin_only: yup.boolean(),
    payflow_required: yup.boolean(),
    payflow_cost: yup.string(),
    payflow_currency: yup.string(),
  });

  const { control, handleSubmit, formState, setValue } = useForm({
    shouldUnregister: false,
    resolver: yupResolver(schema),
    defaultValues: {
      payflow_label: edit ? question.label : '',
      payflow_instructions: edit ? question.instructions || '' : '',
      payflow_required: edit ? question.required : false,
      payflow_cost: edit ? Number(question.cost)?.toFixed(2) : '0.00',
      payflow_currency: 'USD',
    },
    mode: 'onChange',
  });

  useEffect(() => {
    if (!fetchForm?.loading && fetchForm?.data) {
      setDisableSubmit(false);
      setFormId(fetchForm.data.id);
    }
  }, [fetchForm]);

  useEffect(() => {
    if (
      (!updateFormSubmit?.loading && updateFormSubmit?.data) ||
      (!updateQuestionSubmit?.loading && updateQuestionSubmit?.data)
    ) {
      setDisableSubmit(false);
      onClose();
    }
  }, [updateFormSubmit, updateQuestionSubmit]);


  const onSubmitHandler = data => {
    if (questionTypesInfo && questionTypesInfo.data) {
      const questionType = questionTypesInfo.data.reduce((prev, curr) => {
        return prev || curr.attributes.question_types.find(question => question.identifier === qIdentifier);
      }, undefined);

      const values = {
        instructions: data.payflow_instructions,
        label: data.payflow_label,
        required: data.payflow_required,
        currency: data.payflow_currency,
        cost: data.payflow_cost,
      };

      if (formId && data && (questionType?.id || question?.id)) {
        //update or save new
        const actionToCall = edit ? updateQuestion : updateForm;
        const idToUpdate = edit ? question.id : questionType.id;
        setDisableSubmit(true);
        dispatch(actionToCall(formId, questionType.endpoint, idToUpdate, values));
      }
    }
  };

  return (
    <FormContainer>
      <form id={'payflowAdd'} onSubmit={handleSubmit(onSubmitHandler)}>
        <Controller
          control={control}
          name={'payflow_label'}
          render={({ field: { ref, ...rest } }) => (
            <InputContainer>
              <TextInput
                aria-label={'Payflow Label'}
                errorMsg={_get(formState.errors.payflow_label, 'message') || ''}
                id={'payflow_label'}
                label={'Payflow Payment Label'}
                openSans
                placeholder={'Enter Label'}
                required
                {...rest}
              />
            </InputContainer>
          )}
        />
        <Controller
          control={control}
          name={'payflow_instructions'}
          render={({ field: { ref, ...rest } }) => (
            <InputContainer>
              <Suspense fallback={<div />}>
                <EditTextArea
                  aria-label="Instructions"
                  errorMsg={_get(formState.errors.payflow_instructions, 'message') || ''}
                  placeholder={`Enter Instructions`}
                  label={`Instructions`}
                  id={'payflow_instructions'}
                  {...rest}
                />
              </Suspense>
            </InputContainer>
          )}
        />

        <CostContainer>
          <Controller
            control={control}
            name={'payflow_cost'}
            render={({ field: { onChange, register, ref, ...rest } }) => (
              <InputContainer>
                <CurrencyInput
                  aria-label={'Payflow Cost'}
                  errorMsg={_get(formState.errors.payflow_cost, 'message') || ''}
                  id={'payflow_cost'}
                  label={'Cost'}
                  openSans
                  required
                  onChange={value => {
                    setValue('payflow_cost', value);
                  }}
                  {...rest}
                />
              </InputContainer>
            )}
          />
          <CurrencyContainer>
            <Label>Currency</Label>
            <CurrencyDiv>
              <span>USD ($)</span>
            </CurrencyDiv>
          </CurrencyContainer>
        </CostContainer>
        <Controller
          control={control}
          name={'payflow_required'}
          render={({ field: { onChange, register, ref, ...rest } }) => (
            <ToggleContainer>
              <Toggle
                id={'payflow_required'}
                label={'Required'}
                onChange={value => {
                  setValue('payflow_required', value);
                }}
                ref={register}
                {...rest}
              />
              <ToggleLabel>Required Field</ToggleLabel>
            </ToggleContainer>
          )}
        />
        <SubmitContainer>
          <AccentButtonMediumNunito
            aria-label={edit ? 'Save Payflow' : 'Add Payflow to Form'}
            form="payflowAdd"
            type={'submit'}
            disabled={disableSubmit}
          >
            {disableSubmit ? <AccentButtonSpinner displayText='Saving ...' /> : edit ? 'Save' : 'Add to Form'}
            {!disableSubmit && <StyledIcon type='ChevronForward' color='#fff' />}
          </AccentButtonMediumNunito>
        </SubmitContainer>
      </form>
    </FormContainer>
  );
}
