import React from 'react';
import { compose } from 'recompose';
import propTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import ViaModal from '../../../../components/library/viaModal';
import Headline from '../../../../components/library/headline';
import Button from '../../../../components/library/button';
import Paragraph from '../../../../components/library/paragraph';
import { getPlan } from '../../../../../actions/plans';
import sJoinedGroup from './joinedGroup.scss';

export const enhance = compose(withStyles(sJoinedGroup));

function JoinedGroup(props) {
  const dispatch = useDispatch();

  return (
    <>
      <ViaModal
        showing={props.show}
        onClose={() => {
          dispatch(getPlan(props.planId));
          props.onClose(false)
        }}
      >
        <div className={sJoinedGroup['joined-group']}>
          <div className={sJoinedGroup.subHeader}>
            <Headline centered tag="h1" as="h1">
              Success!
            </Headline>
          </div>
          <div className={sJoinedGroup.subHeader}>
            <Headline centered tag="h1" as="h2">
              YOU JOINED THE GROUP.
            </Headline>
          </div>
          <div className={sJoinedGroup.content}>
            <Paragraph centered size="large">
              Check your email to view your confirmation. The Group Leaders and Administrators for this Group Plan
              have been notified.
            </Paragraph>
          </div>
          <div className={sJoinedGroup.content}>
            <Paragraph centered size="large">
              Take the next step to build your itinerary by adding the Group flight, housing, transportation, and
              activity details to your trip, or adding your own!
            </Paragraph>
          </div>
          <div className={sJoinedGroup.footer}>
            <div className={sJoinedGroup['footer-button-wrapper']}>
              <div className={sJoinedGroup['button-content']}>
                <Button
                  display="primary"
                  kind="solid"
                  size="medium"
                  type="submit"
                  onClick={() => {
                    dispatch(getPlan(props.planId));
                    props.onClose(false);
                  }}
                >
                  Take Me To My Plan
                </Button>
              </div>
              <div className={sJoinedGroup['button-content']}>
                <Button
                  display="secondary"
                  kind="solid"
                  size="medium"
                  isLink
                  isNavLink
                  href={`/plans/my-itinerary/${props.planId}`}
                >
                  Keep Going! Build My Itinerary
                </Button>
              </div>
            </div>
          </div>
        </div>
      </ViaModal>
    </>
  )
}

JoinedGroup.propTypes = {
  show: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
  planId: propTypes.string.isRequired,
};

export default enhance(JoinedGroup);
