import React from 'react';

function EmbeddedVideoView({ url }) {
  const getEmbedUrl = url => {
    if (url.includes('youtube.com') || url.includes('youtu.be')) {
      // Extract YouTube video ID
      const videoId = url.split('v=')[1]?.split('&')[0] || url.split('/').pop();
      return `https://www.youtube.com/embed/${videoId}`;
    } else if (url.includes('vimeo.com')) {
      // Extract Vimeo video ID
      const videoId = url.split('/').pop();
      return `https://player.vimeo.com/video/${videoId}`;
    } else {
      return null; // Handle invalid URLs
    }
  };

  const embedUrl = getEmbedUrl(url);

  if (!embedUrl) {
    return <p>Invalid video URL</p>;
  }

  return (
    <div>
      <iframe
        style={{
          width: '100%',
          height: '400px',
          border: 'none',
        }}
        src={embedUrl}
        title="Video Player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  );
}


export default EmbeddedVideoView; 