import moment from 'moment-timezone';
import isEmpty from '../../../programs/utils/isEmpty';
import { stripHtmlString, stripHtmlStringValidation } from '../../../../shared/helpers/General';

const QuestionValidations = questions => {
  let addValidations = Object.assign({}, {});

  if (questions) {
    questions.forEach(item => {
      if (item && item.question) {
        switch (item.question.question_type_identifier.toLowerCase()) {
          case 'short_text':
            addValidations[item.questionId.toString()] = {};
            if (item.question.required) {
              addValidations[item.questionId.toString()].required = `${item.question.label} is required`;
            }
            if (item.question.character_limit > 0) {
              addValidations[item.questionId.toString()].maxLength = {
                value: item.question.character_limit,
                message: `Maximum ${item.question.character_limit} characters`,
              };
            }
            break;
          case 'signature':
            addValidations[item.questionId.toString()] = {};
            addValidations[item.questionId.toString()].validate = {};
            if (item.question.required) {
              addValidations[item.questionId.toString()].validate['isEmpty'] = v => {
                return !isEmpty(v) || `${item.question.label} is required`;
              };
            }
            break;
          case 'long_text':
            addValidations[item.questionId.toString()] = {};
            addValidations[item.questionId.toString()].validate = {};

            if (item.question.required) {
              addValidations[item.questionId.toString()].validate['isEmpty'] = v => {
                return !isEmpty(v) || `${item.question.label} is required`;
              };
            }

            if (item.question.character_limit) {
              addValidations[item.questionId.toString()].validate['maxChars'] = v => {
                return (
                  stripHtmlStringValidation(v).trim().length <= item.question.character_limit ||
                  `Maximum ${item.question.character_limit} characters`
                );
              };
            }
            break;
          case 'multiple_choice':
            addValidations[item.questionId.toString()] = {};
            addValidations[item.questionId.toString()].validate = {};

            if (item.question.required) {
              addValidations[item.questionId.toString()].validate['noOption'] = v => {
                if (v && v.length > 0) {
                  v = v.filter(v => !v._destroy);
                }

                return (v && v.length > 0) || `${item.question.label} is required`;
              };
            }
            break;
          case 'single_choice':
            addValidations[item.questionId.toString()] = {};
            addValidations[item.questionId.toString()].validate = {};
            if (item.question.required) {
              addValidations[item.questionId.toString()].required = `${item.question.label} is required`;
            }
            addValidations[item.questionId.toString()].validate['correctSelection'] = v => {
              if (
                item.question.correct_option.toString().toLowerCase() === '2' ||
                item.question.correct_option.toString().toLowerCase() === 'either'
              ) {
                return true;
              }

              if (
                (v?.toString().toLowerCase() === '0' || v?.toString().toLowerCase() === 'yes') &&
                (item.question.correct_option.toString().toLowerCase() === '1' ||
                  item.question.correct_option.toString().toLowerCase() === 'no')
              ) {
                return `You must answer "No" to this question.`;
              }

              if (
                (v?.toString().toLowerCase() === '1' || v?.toString().toLowerCase() === 'no') &&
                (item.question.correct_option.toString().toLowerCase() === '0' ||
                  item.question.correct_option.toString().toLowerCase() === 'yes')
              ) {
                return `You must answer "Yes" to this question.`;
              }

              return true;
            };
            break;
          case 'dropdown':
            addValidations[item.questionId.toString()] = {};
            addValidations[item.questionId.toString()].validate = {};
            if (item.question.required) {
              if (item?.response?.dropdown_responses?.length === 0) {
                addValidations[item.questionId.toString()].validate['noOption'] = v => {
                  if (v && v.length > 0) {
                    v = v.filter(v => !v._destroy)?.filter(option => option.dropdown_question_option_id);
                    return (v && v.length > 0) || `${item.question.label} is required`;
                  }
                };
              } else if (!item?.response) {
                addValidations[item.questionId.toString()].validate['noOption'] = v => {
                  return (v && v.length > 0) || `${item.question.label} is required`;
                };
              } else {
                addValidations[item.questionId.toString()].validate['noOption'] = v => {
                  if (v && v.length > 0) {
                    v = v.filter(v => !v._destroy);
                    return (v && v.length > 0) || `${item.question.label} is required`;
                  }
                };
              }
            }
            break;
          case 'date':
            addValidations[item.questionId.toString()] = {};
            addValidations[item.questionId.toString()].validate = {};

            if (item.question.required) {
              addValidations[item.questionId.toString()].validate['noOption'] = v => {
                return (v && v.toString().length > 0) || `${item.question.label} is required`;
              };
            }

            if (item.question.future_only) {
              addValidations[item.questionId.toString()].validate['isFuture'] = v => {
                return !v || moment(v).isSameOrAfter(moment(), 'day') || `Please enter a future date`;
              };
            }
            break;
          case 'star_rating':
            addValidations[item.questionId.toString()] = {};
            addValidations[item.questionId.toString()].validate = {};
            if (item.question.required) {
              addValidations[item.questionId.toString()].validate['noOption'] = v => {
                return (v && v.toString().length > 0) || `${item.question.label} is required`;
              };
            }
            break;
          case 'recommendation':
            addValidations[item.questionId.toString()] = {};
            addValidations[item.questionId.toString()].validate = {};
            if (item.question.required) {
              addValidations[item.questionId.toString()].validate['noOption'] = v => {
                let filterV = [];
                if (v?.length > 0) filterV = v?.filter(item => !item.cancelled_at);

                return filterV?.length > 0 || `${item.question.label} is required`;
              };
            }
            break;

          case 'file_upload':
            addValidations[item.questionId.toString()] = {};
            addValidations[item.questionId.toString()].validate = {};

            if (item.question.required) {
              addValidations[item.questionId.toString()].validate['noOption'] = v => {
                return v.length > 0 || `${item.question.label} is required`;
              };
            }

            break;
          case 'cash_net':
          case 'cash_net_with_code':
          case 'fee':
          case 'flywire':
          case 'payflow':
          case 'cybersource':
          case 'quik_pay':
          case 'converge_pay':
          case 'touch_net':
            addValidations[item.questionId.toString()] = {};
            addValidations[item.questionId.toString()].validate = {};
            if (item.question.required) {
              if (item?.response?.payment_status_identifier === 'not_paid') {
                addValidations[item.questionId.toString()].validate['noOption'] = v => {
                  return `${item.question.label} is required`;
                };
              } else if (!item?.response) {
                addValidations[item.questionId.toString()].validate['noOption'] = v => {
                  return `${item.question.label} is required`;
                };
              } else {
                addValidations[item.questionId.toString()].validate['noOption'] = v => {
                  return Number(v) !== 1 || `${item.question.label} is required`;
                };
              }
            }
            break;
          default:
            break;
        }
      }
    });
  }

  return addValidations;
};

export { QuestionValidations };
