exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ":root{--dark:var(--theme_color_dark);--ocean:var(--theme_color_light);--accent:var(--theme_color_accent)}._1JIoJ{padding:10px}._3m2e-{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row}hr{width:100%;margin-top:9px}.dtocU{width:130px;font-size:14px;color:var(--theme_color_light);color:var(--ocean,#76ccce)}", ""]);

// exports
exports.locals = {
	"group": "_1JIoJ",
	"header": "_3m2e-",
	"headerTitle": "dtocU"
};