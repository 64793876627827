import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import { connect } from 'react-redux';
import { compose } from 'redux';

import userId from '../../../../../../shared/helpers/userId';
import { updateAssignedAdmins } from '../../../../../../actions/profileActions';
import { withCookies, Cookies } from 'react-cookie';

import s from './Admin.css';
import trash from './img/trash.png';

class Admin extends React.Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(index) {
    const { cookies } = this.props;
    const token = cookies.get('token');

    const admins = this.props.assigned_admins;
    const availAdmins = this.props.admins;
    const adminIndex = admins
      .map(admin => `${admin.admin_id}`)
      .indexOf(`${index}`);
    if (adminIndex > -1) {
      admins.splice(adminIndex, 1);
    }
    this.props.updateAssignedAdmins(
      this.props.traveler_id,
      admins.map(admin => `${admin.admin_id}`),
    );
    this.props.fetchAdmins(this.props.id, token);
  }

  render() {
    return (
      <div className={s.admin}>
        <img
          src={this.props.avatar}
          className={s.adminAvatar}
          alt={
            userId.toString() === this.props.admin_id.toString()
              ? 'User avatar'
              : 'Admin avatar'
          }
        />
        <div className={s.adminInfo}>
          <span className={s.adminName}>{this.props.name}</span>
          <span />
        </div>
        {this.props.role == 'traveler' ? (
          ''
        ) : (
          <img
            src={trash}
            className={s.trash}
            onClick={() => this.props.removeAdmin(this.props.admin_id)}
          />
        )}
      </div>
    );
  }
}

// export default withStyles(s)(Admin);
export default compose(
  withCookies,
  withStyles(s),
  connect(
    null,
    { updateAssignedAdmins },
  ),
)(Admin);
