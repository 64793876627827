import PropTypes from 'prop-types';

import datetime from './datetime';

const event = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  deleted: PropTypes.bool.isRequired,
  location: PropTypes.string.isRequired,
  timezone: PropTypes.string.isRequired,
  public_url: PropTypes.string.isRequired,
  attended: PropTypes.oneOf(['Attended', 'Not Attended']).isRequired,
  registered: PropTypes.oneOf(['Registered', 'Not Registered']).isRequired,
  start: (propValue, key, componentName) => {
    datetime(propValue, key, componentName);
  },
  ending: (propValue, key, componentName) => {
    datetime(propValue, key, componentName);
  },
});

export default event;
