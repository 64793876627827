import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';

import IntakeSection from './IntakeSection';

import travelGoalOptions from '../../utils/options/travelGoalOptions';

export default function IntakeGoals() {
  const intake = useSelector(state => state.intakeForm);

  const [goals, setGoals] = useState('');
  const [otherOption, setOtherOption] = useState('');

  useEffect(
    () => {
      if (intake.travel_goals) {
        const selectedGoals = [];

        for (const [key, value] of Object.entries(intake.travel_goals)) {
          if (key === 'other_goal_text') {
            setOtherOption(value || '');
          }

          if (value === true || value === 't') {
            selectedGoals.push(key);
          }
        }

        const goalValues = selectedGoals.reduce((accumulator, goal) => {
          const value = travelGoalOptions.find(travelGoal => travelGoal.value === goal);

          if (value) {
            accumulator.push(value.label);
          }

          return accumulator;
        }, []);

        if (goalValues.length > 0) {
          setGoals(goalValues.join('; '));
        }
      }
    },
    [intake.travel_goals],
  );

  return (
    <IntakeSection heading="Participation Goals">
      <p>{goals || 'None selected'}</p>
      {otherOption && (
        <p>
          <strong>Other:</strong> {otherOption}
        </p>
      )}
    </IntakeSection>
  );
}
