import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import Select from 'react-select';

import Admin from './Admin/Admin';
import Loader from '../../../../Loader/Loader';

import s from './AssignedAdmins.css';
import reactSelectStyles from '../../../../../../public/css/react-select.css';
import reactTelSelectStyles from '../../../../../../public/css/react-tel-select.css';
import { connect } from 'react-redux';
import {
  updateAssignedAdmins,
  fetchAssignedAdmins,
} from '../../../../../actions/profileActions';
import { fetchAdmins } from '../../../../../actions/clientActions';
import { compose } from 'redux';
import { withCookies } from 'react-cookie';

class AssignedAdmins extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: '',
      assignedAdmins: props.assignedAdmins,
      admins: props.admins,
    };
    this.removeAdmin = this.removeAdmin.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.assignedAdmins !== nextProps.assignedAdmins) {
      this.setState({ assignedAdmins: nextProps.assignedAdmins });
    }
  }

  handleChange = selectedOption => {
    this.setState({ selectedOption });
    const assignedAdmins = this.props.assignedAdmins.map(
      admin => admin.admin_id,
    );
    this.props.updateAssignedAdmins(
      this.props.id,
      assignedAdmins.concat(selectedOption.id),
    );

    this.setState({ selectedOption: '' });
    this.getAssignedAdmins();
  };

  getAssignedAdmins = () => {
    const { cookies } = this.props;
    const token = cookies.get('token');

    this.props.fetchAssignedAdmins(this.props.id);
    this.props.fetchAdmins(this.props.id, token);
  };

  removeAdmin(index) {
    const { cookies } = this.props;
    const token = cookies.get('token');

    const adminIndex = this.state.assignedAdmins
      .map(admin => `${admin.admin_id}`)
      .indexOf(`${index}`);
    if (adminIndex > -1) {
      this.state.assignedAdmins.splice(adminIndex, 1);
    }
    this.props.updateAssignedAdmins(
      this.props.id,
      this.state.assignedAdmins.map(admin => `${admin.admin_id}`),
      (status, data) => {
        if (status) {
          this.getAssignedAdmins();
        }
      }
    );
    // this.props.fetchAdmins(this.props.id, token);
  }

  filterAdminOptions(adminOptions) {
    let filteredOptions = adminOptions.filter(admin => {
      let isAssignedAdminExists = this.state.assignedAdmins.find(i => Number(i.admin_id) === Number(admin.id));
      if (isAssignedAdminExists) {
        return false;
      }
      return true;
    });

    return filteredOptions;
  }

  render() {
    const { adminsLoading } = this.props;
    const { selectedOption } = this.state;
    if (adminsLoading == true) {
      return <Loader />;
    } else if (this.props.assignedAdmins == null || this.props.admins == null) {
      return <div>error</div>;
    }

    const assignedAdmins = this.state.assignedAdmins
      .sort((a, b) => {
        if (a.name < b.name) return -1;
        else if (a.name > b.name) return 1;
        return 0;
      })
      .map((admin, index) => (
        <Admin
          key={index}
          id={this.props.id}
          traveler_id={this.props.id}
          avatar={admin.avatar}
          name={admin.name}
          admin_id={admin.admin_id}
          removeAdmin={this.removeAdmin}
          admins={this.props.admins}
          assigned_admins={this.props.assignedAdmins}
          role={this.props.role}
        />
      ));

    const adminOptions = this.state.admins.map(admin => ({
      value: admin.name,
      label: admin.name,
      id: admin.admin_id,
    }));

    const options = this.filterAdminOptions(adminOptions);

    return (
      <div className={s.assignedAdmins}>
        {assignedAdmins}
        {this.props.role == 'traveler' ? (
          ''
        ) : (
          <Select
            className={s.select}
            placeholder="Add Administrator..."
            onChange={this.handleChange}
            value={selectedOption}
            options={options}
          />
        )}
      </div>
    );
  }
}
export default compose(
  withCookies,
  withStyles(reactSelectStyles, reactTelSelectStyles, s),
  connect(
    null,
    { updateAssignedAdmins, fetchAssignedAdmins, fetchAdmins },
  ),
)(AssignedAdmins);
