import React, { useEffect, useState } from 'react';
import SubmissionTagDropDown from '../../../../styledComponents/styles/SubmissionTagDropDown.styled';
import { useDispatch, useSelector } from 'react-redux';
import _orderBy from 'lodash/orderBy';
import _sortBy from 'lodash/sortBy';
import { getAvailableTags } from '../../../../actions/formsActions';

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    width: 'max-content',
    marginTop: '7px',
    marginLeft: '7px',
    padding: '4px 8px',
    gap: '4px',
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '700',
    borderRadius: '4px',
    display: 'flex',
    backgroundColor: 'none',
    color: state.data.textColor,
    backgroundColor: state.data.bgcolor,
    // padding: "0px",
    // backgroundColor: "white"
  }),
  multiValue: (styles, state) => ({
    ...styles,
    backgroundColor: state.data.bgcolor,
  }),
  multiValueLabel: (styles, state) => ({
    ...styles,
    color: state.data.textColor,
    fontWeight: '700',
    fontSize: '14px',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
  }),
  placeholder: styles => {
    return {
      ...styles,
      color: '#555',
      fontFamily: 'Open Sans',
      fontSize: '14px',
      fontStyle: 'italic',
      fontWeight: '400',
      lineHeight: '120%',
      letterSpacing: '0.21px',
    };
  },
};

function SubmissionTagControl({ tagType = 'Application', tagIds = [], setTagIds}) {
  const dispatch = useDispatch();
  const [availableTags, setAvailableTags] = useState([]); 
  const [tagSearchQuery, setTagSearchQuery] = useState('');
  const [tagOptions, setTagOptions] = useState([]); 

  useEffect(() => {
    getTags(); 
  }, [tagType]);

  const getTags = () => {
    dispatch(getAvailableTags(tagType, (status, tagData) => {
    const existingTags = tagData?.data?.map(tag => {
        let tagLabel = tag.attributes.name;
        if (tag.attributes.emoji !== null && tag.attributes.emoji !== undefined) {
          tagLabel =
            tag.attributes.emoji
              .split(',')
              .join('')
              .trim() +
            ' ' +
            tag.attributes.name;
        }   
        return {
          label: tagLabel,
          value: tag.id,
          name: tag.attributes.name.toLowerCase(),
          description: tag.attributes.description,
          bgcolor: tag.attributes.tag_color.bg_hex_code,
          textColor: tag.attributes.tag_color.font_hex_code,
        };
      });
      
      const existingSortedTags = _orderBy(existingTags, 'name', 'asc');

      const tagOptionList = existingSortedTags?.filter(tag => {
        return handleFilterOption(tag, tagSearchQuery);
      });

      setTagOptions([...tagOptionList])

    }));
  }

  const handleFilterOption = (option, rawInput) => {
    // Implement your custom filtering logic here.
    return option.label.toLowerCase().includes(rawInput.toLowerCase());
  };

  const handleTagInputChange = inputValue => {
    setTagSearchQuery(inputValue);
  };

  const handleSelectChange = newValue => {
    setTagIds(newValue.map(i => i.value)); 
  };

  return (
    <div>

      <SubmissionTagDropDown
        key={`action_submission`}
        aria-label="Submission Tag"
        applicationTag={tagOptions.filter(tag => tagIds.includes(tag.value))}
        placeholder={'Type to select tag'}
        id={`${tagType?.toLowerCase()}_tag`}
        options={tagOptions}
        handleInputChange={handleTagInputChange}
        handleSelectChange={handleSelectChange}
        tagSearchQuery={tagSearchQuery}
        customStyles={customStyles}
        width="100%"
        showCreateEditBtn={false}
      />
    </div>
  );
}

export default SubmissionTagControl;
