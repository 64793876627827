import moment from 'moment-timezone';

export default function formatDate(startDate, endDate, exact) {
  const format = exact ? 'MMM DD[,] YYYY' : 'MMM [] YYYY';

  return `${moment(new Date(startDate)).format(format)} - ${moment(new Date(endDate)).format(format)}`;
}

export function formatDateTimezone(startDate, endDate, exact,timeZone) {
  const format = exact ? 'MMM DD[,] YYYY' : 'MMM [] YYYY';
  let newStart = startDate;
  let newEnd = endDate;
  let displayStart = moment(newStart).format(format);
  let displayEnd = moment(newEnd).format(format);

  if (timeZone && timeZone?.length > 0) {
   newStart = moment(startDate)
      .tz(timeZone)
      .format();

   displayStart = moment(newStart)
      .tz(timeZone)
      .format(format);

   newEnd = moment(endDate)
      .tz(timeZone)
      .format();

   displayEnd = moment(newEnd)
      .tz(timeZone)
      .format(format);
  }
  return `${displayStart} - ${displayEnd}`;
}
