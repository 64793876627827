import React from 'react';
import PropTypes from 'prop-types';

export default function InfoIcon({ width, height, fillColor }) {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      style={{
        width,
        height,
      }}
    >
      <path
        d="M8.5 0.424316C4.19219 0.424316 0.6875 3.929 0.6875 8.23682C0.6875 12.5446 4.19219 16.0493 8.5 16.0493C12.8078 16.0493 16.3125 12.5446 16.3125 8.23682C16.3125 3.929 12.8078 0.424316 8.5 0.424316ZM8.5 3.62744C8.70087 3.62744 8.89723 3.68701 9.06425 3.7986C9.23127 3.9102 9.36145 4.06882 9.43832 4.2544C9.51519 4.43998 9.5353 4.64419 9.49611 4.8412C9.45692 5.03822 9.36019 5.21918 9.21815 5.36122C9.07612 5.50326 8.89515 5.59999 8.69814 5.63918C8.50113 5.67836 8.29692 5.65825 8.11134 5.58138C7.92576 5.50451 7.76714 5.37434 7.65554 5.20732C7.54394 5.0403 7.48438 4.84394 7.48438 4.64307C7.48438 4.37371 7.59138 4.11538 7.78184 3.92491C7.97231 3.73444 8.23064 3.62744 8.5 3.62744ZM10.375 12.4556H6.9375C6.77174 12.4556 6.61277 12.3897 6.49556 12.2725C6.37835 12.1553 6.3125 11.9963 6.3125 11.8306C6.3125 11.6648 6.37835 11.5058 6.49556 11.3886C6.61277 11.2714 6.77174 11.2056 6.9375 11.2056H8.03125V7.76807H7.40625C7.24049 7.76807 7.08152 7.70222 6.96431 7.58501C6.8471 7.4678 6.78125 7.30883 6.78125 7.14307C6.78125 6.97731 6.8471 6.81834 6.96431 6.70112C7.08152 6.58391 7.24049 6.51807 7.40625 6.51807H8.65625C8.82201 6.51807 8.98098 6.58391 9.09819 6.70112C9.2154 6.81834 9.28125 6.97731 9.28125 7.14307V11.2056H10.375C10.5408 11.2056 10.6997 11.2714 10.8169 11.3886C10.9342 11.5058 11 11.6648 11 11.8306C11 11.9963 10.9342 12.1553 10.8169 12.2725C10.6997 12.3897 10.5408 12.4556 10.375 12.4556Z"
        fill={fillColor}
      />
    </svg>
  );
}

InfoIcon.propTypes = {
  fillColor: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

InfoIcon.defaultProps = {
  fillColor: '#255A94',
  width: 'unset',
  height: 'unset',
};
