import React, { useState} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {withStyles as uiStyles} from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'

import { HeaderIconButton } from '../Header.styled'

export default function CopyLink({ url, position }) {
  const [open, setOpen] = useState(false)

  let copyInput = null

  const CopyTooltip = uiStyles((theme) => ({
    tooltip: {
      backgroundColor: '#ffffff',
      color: '#00485d',
      boxShadow: theme.shadows[1],
      fontSize: 12,
      fontFamily: 'AvenirNextRegular, sans-serif',
      marginBottom: '0.0625rem',
    },
  }))(Tooltip)

  const handleTooltipOpen = () => {
    if (window.clipboardData && window.clipboardData.setData) {
      setOpen(true)
      return window.clipboardData.setData('Text', url)
    }
    else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
      let textarea = document.createElement("textarea")
      textarea.textContent = url
      textarea.style.position = "fixed"
      document.body.appendChild(textarea)
      textarea.select()
      try {
        return document.execCommand("copy")
      }
      catch (ex) {
        return false
      }
      finally {
        setOpen(true)
        document.body.removeChild(textarea)
      }
    }
  }

  const handleTooltipClose = () => {
    setOpen(false)
  }

  return (
    <>
      <CopyTooltip
        onClose={handleTooltipClose}
        open={open}
        title={
          <div>
            <FontAwesomeIcon
              icon="check"
              color="#219653"
              style={{ marginRight: '.5rem'}}
            />
            Copied to Clipboard
          </div>
        }
        placement={position}
      >
        <HeaderIconButton
          type="button"
          aria-label="Share Program Link"
          onClick={() => handleTooltipOpen()}
          ref={(ref) => copyInput = ref}
        >
          <svg
            style={{ height: '1.5rem', width: '1.5rem' }}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6.75 8.625a3.375 3.375 0 0 0 0 6.75h2.656a1.125 1.125 0 1 1 0 2.25H6.75a5.625 5.625 0 1 1 0-11.25h2.597a1.125 1.125 0 0 1 0 2.25H6.75ZM13.528 7.5c0-.621.503-1.125 1.125-1.125h2.597a5.625 5.625 0 1 1 0 11.25h-2.656a1.125 1.125 0 0 1 0-2.25h2.656a3.375 3.375 0 0 0 0-6.75h-2.597A1.125 1.125 0 0 1 13.528 7.5Z"
              fill="#373737"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6.8 12c0-.621.504-1.125 1.125-1.125h8.244a1.125 1.125 0 1 1 0 2.25H7.925A1.125 1.125 0 0 1 6.8 12Z"
              fill="#373737"
            />
          </svg>
        </HeaderIconButton>
      </CopyTooltip>
    </>
  )
}
