exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@-webkit-keyframes BeEjY{to{-webkit-transform:rotate(1turn)}}@keyframes BeEjY{to{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}._3Jd5m{width:100%}._3Jd5m a{color:#043544;text-decoration:underline}", ""]);

// exports
exports.locals = {
	"ov-itinerary-by-day": "_3Jd5m",
	"spin": "BeEjY"
};