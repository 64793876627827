import React from 'react';

export default function LongTermLength() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
      <g clipPath="url(#clip0_822_14787)">
        <path
          d="M5.8125 24.9844H2.34375V19.8762C2.34375 19.5649 2.55529 19.3125 2.81625 19.3125H5.34C5.60096 19.3125 5.8125 19.5649 5.8125 19.8762V24.9844Z"
          fill="#EE7A9B"
        />
        <path
          d="M11.1094 24.9844H7.64062V17.2659C7.64062 16.9724 7.85217 16.7344 8.11313 16.7344H10.6369C10.8978 16.7344 11.1094 16.9724 11.1094 17.2659V24.9844Z"
          fill="#DDAF64"
        />
        <path
          d="M16.4062 24.9844H12.9375V14.8063C12.9375 14.525 13.149 14.2969 13.41 14.2969H15.9337C16.1947 14.2969 16.4062 14.525 16.4062 14.8063V24.9844Z"
          fill="#98D893"
        />
        <path
          d="M21.7031 24.9844H18.2344V12.1754C18.2344 11.8973 18.4459 11.6719 18.7069 11.6719H21.2306C21.4916 11.6719 21.7031 11.8973 21.7031 12.1754V24.9844Z"
          fill="#6BA2DC"
        />
        <path
          d="M18.3834 7.86618L19.8504 8.8862L19.3584 3.98438L14.5875 5.22693L16.0545 6.24695C16.0545 6.24695 13.367 11.4882 3.65645 14.4848C3.1564 14.6391 3.29373 15.3798 3.81581 15.3435C7.70644 15.0732 14.1311 13.6844 18.3834 7.86618Z"
          fill="#A82425"
        />
        <path
          d="M17.9627 7.56928C17.8582 7.49545 17.7145 7.51936 17.6381 7.62198C13.27 13.4916 7.66618 14.2308 3.81576 14.4983C3.75902 14.5022 3.70712 14.4965 3.65983 14.4839L3.6564 14.4849C3.15977 14.6416 3.29889 15.3796 3.81839 15.3435C7.7093 15.0728 14.132 13.6833 18.3834 7.86635L17.9627 7.56928Z"
          fill="#A82425"
        />
        <path
          d="M23.6477 23.4322H22.0288V16.8403C22.0288 16.6458 21.8711 16.488 21.6766 16.488C21.482 16.488 21.3243 16.6458 21.3243 16.8403V23.4322H18.5809V11.8375C18.5809 11.7728 18.6335 11.7201 18.6983 11.7201H21.2069C21.2716 11.7201 21.3243 11.7728 21.3243 11.8375V15.4313C21.3243 15.6258 21.482 15.7835 21.6766 15.7835C21.8711 15.7835 22.0288 15.6258 22.0288 15.4313V11.8375C22.0288 11.3844 21.6601 11.0156 21.2069 11.0156H18.6983C18.2451 11.0156 17.8764 11.3844 17.8764 11.8375V23.4323H16.7271V14.4011C16.7271 13.9479 16.3583 13.5792 15.9051 13.5792H13.3966C12.9433 13.5792 12.5746 13.9479 12.5746 14.4011V23.4323H11.4253V16.9646C11.4253 16.5114 11.0566 16.1427 10.6034 16.1427H8.09481C7.64163 16.1427 7.27289 16.5114 7.27289 16.9646V20.0448C7.27289 20.2393 7.43061 20.397 7.62514 20.397C7.81968 20.397 7.97739 20.2393 7.97739 20.0448V16.9646C7.97739 16.8999 8.03009 16.8472 8.09481 16.8472H10.6034C10.6682 16.8472 10.7208 16.8999 10.7208 16.9646V23.4322H7.97739V21.4538C7.97739 21.2593 7.81968 21.1015 7.62514 21.1015C7.43061 21.1015 7.27289 21.2593 7.27289 21.4538V23.4322H6.12357V19.5282C6.12357 19.075 5.75488 18.7062 5.30165 18.7062H2.79311C2.33993 18.7062 1.97119 19.075 1.97119 19.5282V23.4322H0.35225C0.157714 23.4322 0 23.5899 0 23.7845C0 23.979 0.157714 24.1367 0.35225 24.1367H23.6477C23.8423 24.1367 24 23.979 24 23.7845C24 23.5899 23.8423 23.4322 23.6477 23.4322ZM13.2791 14.4011C13.2791 14.3364 13.3318 14.2837 13.3966 14.2837H15.9051C15.9699 14.2837 16.0226 14.3364 16.0226 14.4011V23.4323H13.2791V14.4011ZM2.67569 19.5282C2.67569 19.4634 2.72839 19.4107 2.79311 19.4107H5.3017C5.36647 19.4107 5.41912 19.4634 5.41912 19.5282V23.4322H2.67569V19.5282Z"
          fill="#373737"
        />
        <path
          d="M3.74243 15.3469C3.76173 15.3469 3.78122 15.3462 3.80076 15.3449C6.39858 15.1644 8.84146 14.5975 11.0616 13.6598C14.0512 12.3972 16.5261 10.4939 18.4227 7.99989L19.6099 8.82538C19.8438 8.99976 20.2002 8.79015 20.1615 8.50098L19.6694 3.59915C19.6534 3.38982 19.432 3.23582 19.2301 3.29345L14.4593 4.536C14.1745 4.60025 14.102 5.00745 14.347 5.1661L15.5451 5.99911C15.3067 6.37362 14.7815 7.11621 13.8658 8.02455C13.7277 8.1616 13.7268 8.38459 13.8638 8.52268C14.0008 8.66081 14.2239 8.6617 14.3619 8.5247C15.7736 7.12438 16.3066 6.10051 16.3286 6.05763C16.409 5.90066 16.361 5.70838 16.2162 5.60769L15.3779 5.02478L19.0096 4.07892L19.3842 7.81029L18.5451 7.22691C18.388 7.11767 18.1725 7.15383 18.0596 7.30826C16.2139 9.83366 13.7672 11.7523 10.7875 13.0108C8.11151 14.141 5.48452 14.5216 3.75201 14.642C3.65079 14.6679 3.62012 14.4821 3.72091 14.4713C7.58041 13.2803 10.8036 11.6059 13.301 9.49475C13.4496 9.36916 13.4682 9.14691 13.3426 8.99836C13.217 8.8498 12.9947 8.83111 12.8462 8.95674C10.4217 11.0063 7.2816 12.6352 3.51323 13.7981C3.1219 13.9189 2.89228 14.2984 2.96724 14.7005C3.03849 15.0829 3.3596 15.3469 3.74243 15.3469Z"
          fill="#373737"
        />
      </g>
      <defs>
        <clipPath id="clip0_822_14787">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
