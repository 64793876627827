import React from 'react';
import { NavLink } from 'react-router-dom';
import propTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import sSubHeader from './subHeader.scss';

function SubHeader(props) {
  return (
    <>
      <div className={sSubHeader.subheader}>
        {!props.traveler ? (
          <div className={sSubHeader['subheader-content']}>
            <div className={sSubHeader.item}>
              <NavLink
                className={sSubHeader.selection}
                to="/plans/plan-list"
                activeClassName={sSubHeader.selected}
              >
                PLANS
              </NavLink>
            </div>
            <div className={sSubHeader.item}>
              <NavLink
                className={sSubHeader.selection}
                to="/plans/traveler-list"
                activeClassName={sSubHeader.selected}
              >
                TRAVELERS
              </NavLink>
            </div>
            <div className={sSubHeader.item}>
              <NavLink
                className={sSubHeader.selection}
                to="/safecheck/messages"
                activeClassName={sSubHeader.selected}
              >
                SAFECHECK
              </NavLink>
            </div>
          </div>
        ) : (
          <div className={sSubHeader['subheader-content']}>
            <div className={sSubHeader.item}>
              <NavLink
                className={sSubHeader.selection}
                to="/plans/my-travel"
                activeClassName={sSubHeader.selected}
              >
                MY TRAVEL
              </NavLink>
            </div>
            <div className={sSubHeader.item}>
              <NavLink
                className={sSubHeader.selection}
                to="/plans/group-travel-search"
                activeClassName={sSubHeader.selected}
              >
                GROUP TRAVEL SEARCH
              </NavLink>
            </div>
            <div className={sSubHeader.item}>
              <NavLink
                className={sSubHeader.selection}
                to="/safecheck/phone"
                activeClassName={sSubHeader.selected}
              >
                SAFECHECK
              </NavLink>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

SubHeader.propTypes = {
  traveler: propTypes.bool.isRequired,
};

export default withStyles(sSubHeader)(SubHeader);
