exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1-YNE{height:24px}._2ytz4,._3lQjL{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;gap:20px}._2ytz4{margin-top:20px}._2pw9-{width:288px}._1Oln4{width:98px;height:51px;background-color:#d74b73}._1lViP{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;gap:55px;padding:30px;background-color:#ebebeb}._-8g0G{color:#373737;Width:57px;Height:19px;border:none;background-color:transparent}._3y-8S{gap:64px;padding:25px 26px}._1e6bJ{font-family:Open Sans,sans-serif;font-weight:400;font-size:14px;letter-spacing:.25%;line-height:16.8px;margin-top:6px}._3Ylxn{font-size:25px;width:693px;height:30px}._3UsXe{font-family:Nunito,sans-serif;font-weight:400;font-size:25px;line-height:30px}.flr1J{font-weight:700;line-height:16.8%}.flr1J,.U6h2h{font-family:Open Sans,sans-serif;font-size:14px;letter-spacing:.25%}.U6h2h{font-weight:400}._2a9ii{font-family:Nunito,sans-serif;font-weight:600;font-size:16px;line-height:19.2px;letter-spacing:.1%}._1O8ut{margin-top:10px}._23uOc{font-family:Open Sans,sans-serif;font-weight:600;font-size:14px;letter-spacing:.25%}._1Gr1n{height:18px}._2ip4I{font-family:Open Sans;font-size:14px;font-weight:400;line-height:19.6px;text-align:left;color:#6d4603;display:-ms-flexbox;display:flex;-ms-flex-pack:left;justify-content:left;-ms-flex-align:center;align-items:center;gap:5px}._2ip4I img{width:25px}", ""]);

// exports
exports.locals = {
	"tableRow": "_1-YNE",
	"formDiv": "_3lQjL",
	"formDiv2": "_2ytz4",
	"formSelect": "_2pw9-",
	"nextBtn": "_1Oln4",
	"btnDiv": "_1lViP",
	"cancelBtn": "_-8g0G",
	"headerTitle": "_3y-8S",
	"fontSize": "_1e6bJ",
	"modaltextsize": "_3Ylxn",
	"createGrouptext": "_3UsXe",
	"divtext1": "flr1J",
	"divtext2": "U6h2h",
	"plansettingtext": "_2a9ii",
	"plansettingdiv": "_1O8ut",
	"tableHeader": "_23uOc",
	"tableheight": "_1Gr1n",
	"warningText": "_2ip4I"
};