import { createTheme } from '@material-ui/core/styles';

export default function datePickerTheme() {
  return createTheme({
    overrides: {
      MuiTypography: {
        colorPrimary: {
          color: '#446372',
        },
        h4: {
          fontFamily: "'Open Sans', sans-serif !important",
          fontSize: '2.25rem !important',
          color: '#BBBBBB !important',
        },
      },
      MuiFormControl: {
        root: {
          '&.has-error > div.MuiInputBase-root': {
            borderColor: '#8b1313',
          },
        },
      },
      MuiInputBase: {
        root: {
          boxSizing: 'border-box',
          padding: '0.875rem',
          backgroundColor: '#ffffff',
          border: '1px solid #bbbbbb',
          borderRadius: '2px',
          '&:focus-within': {
            outine: 'none',
            border: '1px solid #5a869b',
            boxShadow: '0px 5px 10px rgba(94, 140, 190, 0.25)',
          },
          '&.Mui-disabled': {
            backgroundColor: '#eeeeee',
            cursor: 'not-allowed',
          },
        },
        input: {
          fontFamily: "'Open Sans', sans-serif",
          fontSize: '0.875rem',
          color: '#373737',
          lineHeight: '120%',
          letterSpacing: '0.015em',
          '&.Mui-disabled': {
            cursor: 'not-allowed',
            color: '#555555',
          },
        },
      },
      MuiInput: {
        input: {
          padding: 0,
          '&::placeholder': {
            fontStyle: 'italic',
            color: '#555555',
            opacity: 1,
          },
        },
        underline: {
          '&:before': {
            borderBottom: 'none',
          },
          '&:after': {
            borderBottom: 'none',
          },
          '&:hover:not(.Mui-disabled):before': {
            borderBottom: 'none',
          },
          '&.Mui-disabled:before': {
            borderBottom: 'none',
          },
        },
      },
      MuiPickersToolbar: {
        toolbar: {
          backgroundColor: '#446372',
        },
      },
      MuiPickersToolbarText: {
        toolbarTxt: {
          fontFamily: "'Nunito', sans-serif",
          fontSize: '0.875rem',
          color: '#FFFFFF',
        },
      },
      MuiPickersYear: {
        root: {
          fontFamily: "'Nunito', sans-serif",
          fontSize: '0.875rem',
          color: '#373737',
          '&:focus': {
            color: '#8b1313',
          },
        },
        yearSelected: {
          fontSize: '1.125rem',
          fontWeight: '600',
          color: '#446372',
        },
      },
      MuiPickersMonth: {
        root: {
          fontFamily: "'Nunito', sans-serif",
          fontSize: '0.875rem',
          color: '#373737',
          '&:focus': {
            color: '#8b1313',
          },
        },
        monthSelected: {
          fontSize: '1.125rem',
          fontWeight: '600',
          color: '#446372',
        },
      },
      MuiIconButton: {
        root: {
          padding: '0',
          border: '2px solid transparent',
          borderRadius: '2px',
          '&:focus-visible': {
            borderColor: '#446372',
          },
        },
      },
    },
  });
}
