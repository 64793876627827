const clearFlashMessages = (action, delay = 5000) => {
  setTimeout(() => {
    action();
  }, delay);
};

const scrollTo = (y = 0, x = 0) => {
  window.scrollTo(x, y);
};

export { clearFlashMessages, scrollTo };
