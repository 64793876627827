import React from 'react';

export default function Location() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
      <g clipPath="url(#clip0_991_18260)">
        <path
          d="M5.8953 23.6484C7.99835 23.6484 9.7032 22.9537 9.7032 22.0967C9.7032 21.2397 7.99835 20.5449 5.8953 20.5449C3.79226 20.5449 2.0874 21.2397 2.0874 22.0967C2.0874 22.9537 3.79226 23.6484 5.8953 23.6484Z"
          fill="#C6795C"
        />
        <path
          d="M9.37114 21.4629C8.77638 22.004 7.44429 22.3812 5.89521 22.3812C4.34613 22.3812 3.01403 22.004 2.41928 21.4629C2.20661 21.6564 2.0874 21.8706 2.0874 22.0964C2.0874 22.9534 3.7922 23.6482 5.89525 23.6482C7.99826 23.6482 9.7031 22.9534 9.7031 22.0964C9.70301 21.8706 9.58371 21.6563 9.37114 21.4629Z"
          fill="#C6795C"
        />
        <path
          d="M5.89532 7.56641C2.83981 7.56641 0.362793 10.0434 0.362793 13.0989C0.362793 17.7519 5.89532 22.0331 5.89532 22.0331C5.89532 22.0331 11.4278 17.7519 11.4278 13.0989C11.4278 10.0434 8.95087 7.56641 5.89532 7.56641ZM5.89532 15.3055C4.67666 15.3055 3.68872 14.3176 3.68872 13.0989C3.68872 11.8803 4.67666 10.8923 5.89532 10.8923C7.11398 10.8923 8.10196 11.8802 8.10196 13.0989C8.10196 14.3176 7.11403 15.3055 5.89532 15.3055Z"
          fill="#D74B73"
        />
        <path
          d="M5.89519 7.56641C5.68092 7.56641 5.46966 7.57897 5.26172 7.60273C8.01882 7.91708 10.1608 10.2577 10.1608 13.0989C10.1608 16.7719 6.71391 20.2126 5.26172 21.4993C5.64919 21.8426 5.89519 22.0332 5.89519 22.0332C5.89519 22.0332 11.4277 17.752 11.4277 13.099C11.4277 10.0434 8.95074 7.56641 5.89519 7.56641Z"
          fill="#B25771"
        />
        <path
          d="M19.3847 12.7124C21.001 12.7124 22.3113 12.1785 22.3113 11.5198C22.3113 10.8611 21.001 10.3271 19.3847 10.3271C17.7683 10.3271 16.458 10.8611 16.458 11.5198C16.458 12.1785 17.7683 12.7124 19.3847 12.7124Z"
          fill="#C6795C"
        />
        <path
          d="M21.8644 10.8857C21.3466 11.2216 20.4301 11.4449 19.3851 11.4449C18.3401 11.4449 17.4236 11.2216 16.9058 10.8857C16.6228 11.0693 16.4585 11.2864 16.4585 11.5192C16.4585 12.1779 17.7687 12.7119 19.3851 12.7119C21.0014 12.7119 22.3117 12.1779 22.3117 11.5192C22.3117 11.2864 22.1474 11.0694 21.8644 10.8857Z"
          fill="#C6795C"
        />
        <path
          d="M19.3849 0.351562C17.0365 0.351562 15.1328 2.2553 15.1328 4.6037C15.1328 8.17984 19.3849 11.4702 19.3849 11.4702C19.3849 11.4702 23.637 8.17984 23.637 4.6037C23.637 2.2553 21.7333 0.351562 19.3849 0.351562ZM19.3849 6.29964C18.4482 6.29964 17.689 5.54035 17.689 4.6037C17.689 3.66704 18.4482 2.90776 19.3849 2.90776C20.3216 2.90776 21.0809 3.66704 21.0809 4.6037C21.0809 5.54035 20.3216 6.29964 19.3849 6.29964Z"
          fill="#D74B73"
        />
        <path
          d="M19.3849 0.351562C19.1696 0.351562 18.9582 0.367688 18.7515 0.398578C20.7993 0.704485 22.3701 2.4706 22.3701 4.60365C22.3701 7.30937 19.9363 9.85113 18.7515 10.9283C19.1326 11.2748 19.3849 11.4702 19.3849 11.4702C19.3849 11.4702 23.637 8.1798 23.637 4.60365C23.637 2.2553 21.7333 0.351562 19.3849 0.351562Z"
          fill="#B25771"
        />
        <path
          d="M5.89521 7.21387C2.65051 7.21387 0.0107422 9.85364 0.0107422 13.0983C0.0107422 15.1129 0.969057 17.2789 2.85911 19.5362C3.13994 19.8716 3.41978 20.1824 3.68833 20.4659C2.46798 20.799 1.73532 21.3975 1.73532 22.096C1.73532 23.3325 3.87855 23.9997 5.89507 23.9997C7.91159 23.9997 10.0548 23.3324 10.0548 22.096C10.0548 21.398 9.32234 20.7994 8.10279 20.4662C8.59001 19.9518 9.11426 19.3467 9.6117 18.6717C9.72706 18.5152 9.69364 18.2949 9.53717 18.1796C9.38079 18.0644 9.16039 18.0977 9.04507 18.2542C8.44451 19.0692 7.79445 19.7854 7.22721 20.3544C7.16219 20.3875 7.10782 20.4402 7.07374 20.5067C6.56561 21.006 6.13802 21.3765 5.89544 21.5789C5.65549 21.3784 5.23441 21.013 4.7328 20.5202C4.6968 20.4413 4.63356 20.3794 4.55641 20.3449C2.96233 18.7415 0.714665 15.9714 0.714665 13.0984C0.714665 10.2419 3.03869 7.91784 5.89525 7.91784C8.75182 7.91784 11.0758 10.2419 11.0758 13.0984C11.0758 14.3459 10.6508 15.6931 9.81247 17.1026C9.71314 17.2697 9.76798 17.4857 9.93504 17.585C10.1021 17.6843 10.3181 17.6295 10.4174 17.4624C11.3214 15.9425 11.7797 14.4743 11.7797 13.0984C11.7797 9.85364 9.1399 7.21387 5.89521 7.21387ZM5.89521 22.3844C5.97119 22.3844 6.04718 22.3599 6.1106 22.3108C6.15382 22.2774 6.74187 21.8188 7.52576 21.0523C8.71273 21.2944 9.35098 21.7605 9.35098 22.096C9.35098 22.3465 9.00757 22.6421 8.45477 22.8673C7.77677 23.1437 6.86772 23.2959 5.89507 23.2959C3.78545 23.2959 2.43915 22.5851 2.43915 22.096C2.43915 21.76 3.07759 21.2942 4.26522 21.0521C5.05352 21.8231 5.64246 22.2819 5.67982 22.3108C5.74324 22.36 5.81927 22.3844 5.89521 22.3844Z"
          fill="#373737"
        />
        <path
          d="M3.87129 12.1505C3.68454 12.097 3.48959 12.2049 3.43591 12.3917C3.37024 12.6205 3.33691 12.8583 3.33691 13.0986C3.33691 14.5094 4.48465 15.6572 5.89545 15.6572C7.30625 15.6572 8.45399 14.5094 8.45399 13.0986C8.45399 11.6878 7.30625 10.54 5.89545 10.54C5.33881 10.54 4.8095 10.7158 4.36474 11.0483C4.20912 11.1647 4.17724 11.3852 4.29359 11.5409C4.41003 11.6965 4.63053 11.7283 4.7862 11.612C5.10828 11.3712 5.49186 11.2439 5.8954 11.2439C6.91812 11.2439 7.75006 12.0759 7.75006 13.0986C7.75006 14.1213 6.91808 14.9533 5.8954 14.9533C4.87273 14.9533 4.04074 14.1213 4.04074 13.0986C4.04074 12.9241 4.06488 12.7516 4.11246 12.5859C4.16609 12.399 4.05813 12.2041 3.87129 12.1505Z"
          fill="#373737"
        />
        <path
          d="M22.6633 11.5194C22.6633 10.9618 22.1391 10.4973 21.2323 10.2244C21.4113 10.0323 21.5957 9.8253 21.7808 9.60433C23.246 7.85434 23.9889 6.17204 23.9889 4.60402C23.9889 2.06541 21.9235 0 19.3848 0C17.5303 0 15.8652 1.10372 15.1428 2.81185C15.067 2.99087 15.1508 3.1974 15.3298 3.27305C15.5087 3.34871 15.7153 3.26504 15.791 3.08607C16.4031 1.63894 17.8137 0.70383 19.3848 0.70383C21.5354 0.70383 23.285 2.45349 23.285 4.60407C23.285 7.51239 20.2317 10.2986 19.3847 11.0154C19.198 10.8576 18.9042 10.5995 18.5597 10.2618C18.5254 10.1936 18.4698 10.1397 18.4032 10.1063C17.1881 8.88278 15.4846 6.77964 15.4846 4.60407C15.4846 4.40968 15.3271 4.25213 15.1327 4.25213C14.9383 4.25213 14.7808 4.40963 14.7808 4.60407C14.7808 6.17204 15.5237 7.85439 16.989 9.60438C17.1739 9.82521 17.3582 10.0322 17.5372 10.2242C16.6299 10.4968 16.1062 10.9608 16.1062 11.5195C16.1062 11.9906 16.4884 12.4059 17.1825 12.6887C17.7765 12.9307 18.5586 13.064 19.3848 13.064C20.211 13.064 20.9931 12.9307 21.587 12.6887C22.281 12.4059 22.6633 11.9906 22.6633 11.5194ZM19.3847 12.3601C17.7188 12.3601 16.8101 11.8047 16.8101 11.5194C16.8101 11.3335 17.2235 10.9932 18.1062 10.8056C18.7002 11.3835 19.141 11.7269 19.1695 11.749C19.2329 11.798 19.3089 11.8226 19.3849 11.8226C19.4609 11.8226 19.5368 11.798 19.6003 11.749C19.6287 11.727 20.0695 11.3835 20.6634 10.8056C21.0567 10.8895 21.3974 11.0124 21.6358 11.1575C21.8385 11.281 21.9594 11.4163 21.9594 11.5194C21.9594 11.8047 21.0506 12.3601 19.3847 12.3601Z"
          fill="#373737"
        />
        <path
          d="M21.4327 4.60452C21.4327 3.47535 20.5141 2.55664 19.3848 2.55664C18.2557 2.55664 17.3369 3.4753 17.3369 4.60452C17.3369 5.73369 18.2556 6.65235 19.3848 6.65235C20.5141 6.65235 21.4327 5.73374 21.4327 4.60452ZM18.0408 4.60452C18.0408 3.86342 18.6437 3.26047 19.3849 3.26047C20.126 3.26047 20.7289 3.86338 20.7289 4.60452C20.7289 5.34557 20.126 5.94852 19.3849 5.94852C18.6437 5.94852 18.0408 5.34561 18.0408 4.60452Z"
          fill="#373737"
        />
        <path
          d="M14.8197 11.8709C15.0141 11.8709 15.1717 11.7134 15.1717 11.5189C15.1717 11.3245 15.0141 11.167 14.8197 11.167H14.659C14.542 11.167 14.4245 11.1747 14.3094 11.1901C14.1168 11.2157 13.9814 11.3926 14.007 11.5854C14.0306 11.7623 14.1817 11.8909 14.3554 11.8909C14.3709 11.8909 14.3866 11.8899 14.4023 11.8878C14.4866 11.8766 14.573 11.871 14.6591 11.871H14.8197V11.8709Z"
          fill="#373737"
        />
        <path
          d="M13.6566 21.7266C13.6561 21.7266 13.6556 21.7266 13.655 21.7266L13.1902 21.7287C12.9958 21.7296 12.839 21.8879 12.8398 22.0822C12.8407 22.276 12.9981 22.4325 13.1917 22.4325C13.1923 22.4325 13.1929 22.4325 13.1934 22.4325L13.6582 22.4304C13.8525 22.4295 14.0094 22.2713 14.0085 22.0769C14.0077 21.883 13.8502 21.7266 13.6566 21.7266Z"
          fill="#373737"
        />
        <path
          d="M15.2376 16.4304C15.432 16.4304 15.5896 16.2729 15.5896 16.0785C15.5896 15.8841 15.432 15.7266 15.2376 15.7266H14.7728C14.5784 15.7266 14.4209 15.8841 14.4209 16.0785C14.4209 16.2729 14.5784 16.4304 14.7728 16.4304H15.2376Z"
          fill="#373737"
        />
        <path
          d="M12.6012 12.7568C12.6634 12.8014 12.7351 12.8229 12.8063 12.8229C12.9157 12.8229 13.0236 12.7719 13.0924 12.6762C13.1688 12.5698 13.2564 12.4708 13.3526 12.382C13.4955 12.2503 13.5044 12.0276 13.3727 11.8847C13.2409 11.742 13.0183 11.733 12.8754 11.8647C12.7442 11.9857 12.6248 12.1205 12.5207 12.2656C12.4073 12.4235 12.4434 12.6434 12.6012 12.7568Z"
          fill="#373737"
        />
        <path
          d="M15.5155 21.7178C15.5149 21.7178 15.5144 21.7178 15.5138 21.7178L15.0491 21.7199C14.8547 21.7208 14.6979 21.8791 14.6987 22.0734C14.6996 22.2672 14.857 22.4238 15.0506 22.4238C15.0512 22.4238 15.0517 22.4238 15.0523 22.4238L15.517 22.4216C15.7114 22.4208 15.8682 22.2625 15.8674 22.0681C15.8665 21.8743 15.7091 21.7178 15.5155 21.7178Z"
          fill="#373737"
        />
        <path
          d="M11.7973 21.7344C11.7967 21.7344 11.7962 21.7344 11.7956 21.7344L11.3308 21.7365C11.1365 21.7373 10.9796 21.8957 10.9805 22.09C10.9814 22.2838 11.1387 22.4404 11.3324 22.4404C11.3329 22.4404 11.3334 22.4404 11.334 22.4404L11.7988 22.4383C11.9932 22.4374 12.15 22.2791 12.1492 22.0847C12.1483 21.8908 11.991 21.7344 11.7973 21.7344Z"
          fill="#373737"
        />
        <path
          d="M13.4444 16.0798C13.5612 16.0798 13.6754 16.0217 13.7424 15.9156C13.8461 15.7512 13.7969 15.5339 13.6325 15.4301C13.5219 15.3604 13.4179 15.2789 13.3234 15.1881C13.1833 15.0533 12.9605 15.0578 12.8258 15.1978C12.6911 15.3378 12.6954 15.5606 12.8355 15.6953C12.9643 15.8192 13.1061 15.9303 13.257 16.0254C13.3152 16.0623 13.3801 16.0798 13.4444 16.0798Z"
          fill="#373737"
        />
        <path
          d="M12.7673 14.1694C12.7437 14.0481 12.7317 13.9233 12.7317 13.7984L12.7318 13.7789C12.7341 13.5845 12.5784 13.4251 12.384 13.4229C12.3826 13.4229 12.3812 13.4229 12.3798 13.4229C12.1873 13.4229 12.0302 13.5777 12.028 13.7707L12.0278 13.7985C12.0278 13.9684 12.0442 14.1385 12.0764 14.3041C12.1092 14.4721 12.2564 14.5887 12.4215 14.5887C12.4438 14.5887 12.4665 14.5866 12.4892 14.5821C12.68 14.5449 12.8045 14.3602 12.7673 14.1694Z"
          fill="#373737"
        />
        <path
          d="M17.097 16.4304C17.2914 16.4304 17.449 16.2729 17.449 16.0785C17.449 15.8841 17.2914 15.7266 17.097 15.7266H16.6322C16.4378 15.7266 16.2803 15.8841 16.2803 16.0785C16.2803 16.2729 16.4378 16.4304 16.6322 16.4304H17.097Z"
          fill="#373737"
        />
        <path
          d="M22.5287 17.0708C22.6327 17.0708 22.7357 17.0249 22.8052 16.9369C22.9256 16.7844 22.8996 16.5631 22.7471 16.4426C22.6115 16.3356 22.4665 16.2384 22.3162 16.1538C22.147 16.0584 21.9323 16.1183 21.8369 16.2877C21.7415 16.457 21.8014 16.6717 21.9708 16.767C22.0894 16.8339 22.2039 16.9106 22.3109 16.9951C22.3755 17.0461 22.4523 17.0708 22.5287 17.0708Z"
          fill="#373737"
        />
        <path
          d="M21.044 21.6694C20.9287 21.6854 20.8107 21.6937 20.693 21.6942L20.6271 21.6945C20.4327 21.6955 20.2759 21.8539 20.2769 22.0482C20.2778 22.2419 20.4352 22.3984 20.6287 22.3984C20.6294 22.3984 20.6299 22.3984 20.6305 22.3984L20.6962 22.3981C20.8448 22.3974 20.9943 22.3869 21.1403 22.3667C21.3328 22.3401 21.4673 22.1624 21.4408 21.9699C21.414 21.7774 21.2361 21.6426 21.044 21.6694Z"
          fill="#373737"
        />
        <path
          d="M22.4965 20.9682C22.3978 21.0623 22.2909 21.1494 22.1788 21.2271C22.0191 21.3379 21.9794 21.5571 22.09 21.7169C22.1584 21.8155 22.2681 21.8684 22.3796 21.8684C22.4488 21.8684 22.5186 21.848 22.5798 21.8057C22.7216 21.7073 22.857 21.597 22.982 21.4779C23.1227 21.3439 23.128 21.1211 22.994 20.9804C22.8599 20.8395 22.6371 20.8341 22.4965 20.9682Z"
          fill="#373737"
        />
        <path
          d="M23.679 19.2708C23.4883 19.2348 23.304 19.3605 23.268 19.5515C23.2428 19.6854 23.2069 19.8183 23.1612 19.9465C23.0959 20.1296 23.1913 20.3309 23.3745 20.3962C23.4135 20.4101 23.4535 20.4167 23.4927 20.4167C23.6372 20.4167 23.7728 20.3269 23.8242 20.1829C23.8821 20.0203 23.9277 19.8517 23.9598 19.6817C23.9957 19.4908 23.87 19.3067 23.679 19.2708Z"
          fill="#373737"
        />
        <path
          d="M23.2384 17.4833C23.0622 17.5655 22.9861 17.7749 23.0682 17.951C23.1259 18.0745 23.1742 18.2035 23.2117 18.3343C23.256 18.4886 23.3968 18.5892 23.5498 18.5892C23.582 18.5892 23.6146 18.5848 23.6471 18.5755C23.8339 18.5219 23.9419 18.3269 23.8882 18.1401C23.8405 17.974 23.7793 17.8103 23.7061 17.6534C23.6239 17.4773 23.4147 17.4011 23.2384 17.4833Z"
          fill="#373737"
        />
        <path
          d="M18.9564 16.4304C19.1508 16.4304 19.3083 16.2729 19.3083 16.0785C19.3083 15.8841 19.1508 15.7266 18.9564 15.7266H18.4916C18.2972 15.7266 18.1396 15.8841 18.1396 16.0785C18.1396 16.2729 18.2972 16.4304 18.4916 16.4304H18.9564Z"
          fill="#373737"
        />
        <path
          d="M17.3749 21.709C17.3743 21.709 17.3738 21.709 17.3733 21.709L16.9085 21.7111C16.7141 21.712 16.5573 21.8703 16.5581 22.0646C16.559 22.2585 16.7164 22.415 16.91 22.415C16.9106 22.415 16.9111 22.415 16.9116 22.415L17.3765 22.4129C17.5708 22.412 17.7276 22.2537 17.7268 22.0593C17.726 21.8655 17.5685 21.709 17.3749 21.709Z"
          fill="#373737"
        />
        <path
          d="M20.6814 16.4303C20.7212 16.4303 20.7608 16.4312 20.8001 16.433C20.8054 16.4333 20.8107 16.4333 20.816 16.4333C21.0031 16.4333 21.1589 16.2858 21.1672 16.097C21.1759 15.9028 21.0254 15.7384 20.8313 15.7299C20.7815 15.7276 20.7311 15.7266 20.6815 15.7266H20.351C20.1566 15.7266 19.999 15.8841 19.999 16.0785C19.999 16.2729 20.1566 16.4304 20.351 16.4304L20.6814 16.4303Z"
          fill="#373737"
        />
        <path
          d="M19.2343 21.7012C19.2338 21.7012 19.2333 21.7012 19.2327 21.7012L18.7678 21.7033C18.5735 21.7041 18.4166 21.8625 18.4175 22.0568C18.4183 22.2506 18.5757 22.4072 18.7694 22.4072C18.7699 22.4072 18.7705 22.4072 18.771 22.4072L19.2358 22.405C19.4302 22.4042 19.587 22.2459 19.5862 22.0515C19.5853 21.8576 19.4279 21.7012 19.2343 21.7012Z"
          fill="#373737"
        />
      </g>
      <defs>
        <clipPath id="clip0_991_18260">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
