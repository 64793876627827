exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@-webkit-keyframes _2UI7d{to{-webkit-transform:rotate(1turn)}}@keyframes _2UI7d{to{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}._247bm{padding-top:16px;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column}._247bm h1,._247bm h2,._247bm p{padding-top:32px}._247bm ._1V2T8{-ms-flex:1 1;flex:1 1;-ms-flex-direction:row;flex-direction:row;display:-ms-flexbox;display:flex}@media only screen and (max-width:599px){._247bm ._1V2T8{-ms-flex-direction:column;flex-direction:column}}._247bm ._1V2T8 ._1z3Z5{-ms-flex:1 1;flex:1 1;padding:12px 20px}._247bm .q_wWl{-ms-flex:1 1;flex:1 1;-ms-flex-direction:row;flex-direction:row;display:-ms-flexbox;display:flex;border-top:2px solid #ebebeb;padding:32px 20px 0 0;-ms-flex-pack:center;justify-content:center;margin-top:52px}@media only screen and (max-width:599px){._247bm .q_wWl{-ms-flex-direction:column;flex-direction:column}}._247bm .q_wWl .FfQHu{padding-left:12px}@media only screen and (max-width:599px){._247bm .q_wWl .FfQHu{padding-top:12px}}", ""]);

// exports
exports.locals = {
	"plan-success": "_247bm",
	"row": "_1V2T8",
	"item": "_1z3Z5",
	"button-row": "q_wWl",
	"button-content": "FfQHu",
	"spin": "_2UI7d"
};