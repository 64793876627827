import PropTypes from 'prop-types';

const sendGridMailerType = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  recipient: PropTypes.string,
  description: PropTypes.string,
  display_name: PropTypes.string,
});

export default sendGridMailerType;
