import EmptyHeartIcon from '../../components/icons/EmptyHeartIcon';
import FilterMenuIcon from '../../components/icons/FilterMenuIcon';
import FilledHeartIcon from '../../components/icons/FilledHeartIcon';

export default function getProgramFilters(customAliases, isVisitor = false, selectedFilters = {}) {
  const filters = [
    {
      id: 1,
      label: 'Term Name',
      value: 'termName',
      icon: null,
    },
    {
      id: 2,
      label: 'Dates',
      value: 'dates',
      icon: null,
    },
    {
      id: 3,
      label: 'Subject Areas',
      value: 'subjectAreas',
      icon: null,
    },
    {
      id: 4,
      label: 'Locations',
      value: 'locations',
      icon: null,
    },
    {
      id: 5,
      label: `${customAliases.alias_program} Type`,
      value: 'programType',
      icon: null,
    },
    {
      id: 6,
      label: `${customAliases.alias_favorites || 'Favorites'} Only`,
      value: 'favorites',
      icon: selectedFilters.favorites ? FilledHeartIcon : EmptyHeartIcon,
    },
    {
      id: 7,
      label: 'More Filters',
      value: 'more',
      icon: FilterMenuIcon,
    },
  ];

  return isVisitor ? filters.filter(filterOption => filterOption.label !== 'Favorites Only') : filters;
}
