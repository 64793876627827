import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { breakpoints } from '../../utils/breakpoints';

const Container = styled.div`
  grid-column: span 1 / span 1;
  display: flex;
  align-items: flex-start;
  gap: 1.25rem;

  @media ${breakpoints.tablet} {
    grid-column: span 3 / span 3;

    ${props => {
      if (props.justifyCenter) {
        return `
          justify-self: center;
        `;
      }
    }};
  }
`;

const IconContainer = styled.div`
  padding: 0.5rem;
  background-color: #f7f7f7;
  border-radius: 10px;

  @media ${breakpoints.tablet} {
    background-color: #eeeeee;
  }
`;

const Heading = styled.span`
  margin-bottom: 0.4375rem;
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  color: #373737;
  letter-spacing: 0.015em;
  line-height: 120%;
`;

const ChildrenContainer = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  color: #555555;
  letter-spacing: 0.015em;
  line-height: 120%;
`;

export default function ProgramMatchSection({ heading, icon, children, justifyCenter }) {
  const IconComponent = icon;

  return (
    <Container justifyCenter={justifyCenter}>
      <IconContainer>
        <IconComponent />
      </IconContainer>
      <div>
        <Heading>{heading}</Heading>
        <ChildrenContainer>{children}</ChildrenContainer>
      </div>
    </Container>
  );
}

ProgramMatchSection.propTypes = {
  justifyCenter: PropTypes.bool,
  icon: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  heading: PropTypes.string.isRequired,
};

ProgramMatchSection.defaultProps = {
  justifyCenter: false,
};
