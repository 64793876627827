import React from 'react';
import FileSaver from 'file-saver';
import styled from 'styled-components';
import StyledIcon from './styles/Icon.styled';
import Tooltip from './styles/Tooltip.styled';
const { Parser } = require('json2csv');

const DivWrapper = styled.div`
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  color: ${props => (props.disabled ? '#696969' : '#043544')};
`;

export default function DownloadCSV(props) {
  const handleDownload = () => {
    if (!props.disabled) {
      const json2csvParser = new Parser({ fields: props.headers });
      const csv = json2csvParser.parse(props.data);
      const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

      FileSaver.saveAs(csvData, props.filename);
    }
  }

  return (
    <DivWrapper
      onClick={ handleDownload }
      onKeyDown={(e) => {
        if (e.keyCode === 32 || e.keyCode === 13) {
          handleDownload();
        }
      }}
      disabled={props.disabled}
      tabIndex={0}
    >
      <Tooltip toolTipText={'Download as CSV'} disabled={props.disabled}>
        <StyledIcon
          size={'24px'}
          type={'Download'}
        />
      </Tooltip>
    </DivWrapper>
  );
}