module.exports = [
  {value: "Alberta", label: "Alberta"},
  {value: "British Columbia", label: "British Columbia"},
  {value: "Manitoba", label: "Manitoba"},
  {value: "New Brunswick", label: "New Brunswick"},
  {value: "Newfoundland and Labrador", label: "Newfoundland and Labrador"},
  {value: "Nova Scotia", label: "Nova Scotia"},
  {value: "Northwest Territories", label: "Northwest Territories"},
  {value: "Nunavut", label: "Nunavut"},
  {value: "Ontario", label: "Ontario"},
  {value: "Prince Edward Island", label: "Prince Edward Island"},
  {value: "Québec", label: "Québec"},
  {value: "Saskatchewan", label: "Saskatchewan"},
  {value: "Yukon", label: "Yukon"}
];
