import React, { lazy, Suspense, useEffect, useState } from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import currencyToSymbolMap from 'currency-symbol-map/map';
import { Controller, useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import CurrencyInput from '../../../../../styledComponents/styles/CurrencyInput.styled';
import { Paragraph } from '../../../../../styledComponents/styles/Paragraph.styled';
import StyledIcon from '../../../../../styledComponents/styles/Icon.styled';
import TextInput from '../../../../../styledComponents/styles/TextInput.styled';
import { AccentButtonMediumNunito } from '../../../../../styledComponents/styles/Buttons.styled';
import { AccentButtonSpinner } from '../../../../../styledComponents/styles/spinner.styled';
import { updateForm, updateQuestion } from '../../../../../actions/formsActions';
import _get from 'lodash/get';
import DropdownSelect from '../../../../../styledComponents/styles/DropdownSelect.styled';
const EditTextArea = lazy(() => import('../../../../../styledComponents/styles/EditTextArea.styled'));

const FormContainer = styled.div`
  font-family: 'Nunito', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 0.015em;
  color: #373737;
`;

const InputContainer = styled.div`
  flex: 1;
  margin: 20px 0;
  text-align: left;
`;

const CurrencyContainer = styled.div`
  flex: 1;
  margin: 20px 0 20px 20px;
  text-align: left;
`;

const Label = styled(Paragraph)`
  color: #043544;
  font-size: 16px;
  font-weight: 600;
  font-family: Open Sans, sans-serif;
`;

const CostContainer = styled.div`
  display: flex;
  margin: 20px 0;
  text-align: left;
`;

const SubmitContainer = styled.main`
  display: flex;
  justify-content: flex-end;
  margin-top: 35px;
`;

export default function QuestionFeeAddEdit({ question, edit, onClose }) {
  const MAX_LENGTH = 90;
  const qIdentifier = 'fee';
  const dispatch = useDispatch();
  const fetchForm = useSelector(state => state.forms.fetchForm);
  const questionTypesInfo = useSelector(state => state.forms.questionTypes);
  const updateFormSubmit = useSelector(state => state.forms.updateForm);
  const updateQuestionSubmit = useSelector(state => state.forms.updateQuestion);
  const [formId, setFormId] = useState(null);
  const [disableSubmit, setDisableSubmit] = useState(false);

  const schema = yup.object().shape({
    fee_label: yup
      .string()
      .required('Question must have label')
      .max(MAX_LENGTH, `Label can not be more than ${MAX_LENGTH} characters`),
    fee_instructions: yup.string(),
    fee_admin_only: yup.boolean(),
    fee_required: yup.boolean(),
    fee_cost: yup.string(),
    fee_currency: yup.string(),
  });

  const { control, handleSubmit, formState, setValue } = useForm({
    shouldUnregister: false,
    resolver: yupResolver(schema),
    defaultValues: {
      fee_label: edit ? question.label : '',
      fee_instructions: edit ? question.instructions || '' : '',
      fee_required: false,
      fee_cost: edit ? Number(question.cost)?.toFixed(2) : '0.00',
      fee_currency: edit ? question.currency : 'USD',
      fee_url: edit ? question.url : '',
    },
    mode: 'onChange',
  });

  useEffect(
    () => {
      if (fetchForm && !fetchForm.loading && fetchForm.data) {
        setDisableSubmit(false);
        setFormId(fetchForm.data.id);
      }
    },
    [fetchForm],
  );

  useEffect(
    () => {
      if (
        (updateFormSubmit && updateFormSubmit.data && !updateFormSubmit.loading) ||
        (updateQuestionSubmit && updateQuestionSubmit.data && !updateQuestionSubmit.loading)
      ) {
        setDisableSubmit(false);
        onClose();
      }
    },
    [updateFormSubmit, updateQuestionSubmit],
  );

  const onSubmitHandler = data => {
    if (questionTypesInfo && questionTypesInfo.data) {
      const questionType = questionTypesInfo.data.reduce((prev, curr) => {
        return prev || curr.attributes.question_types.find(question => question.identifier === qIdentifier);
      }, undefined);

      const values = {
        instructions: data.fee_instructions,
        label: data.fee_label,
        required: false,
        currency: data.fee_currency,
        cost: data.fee_cost,
        url: data.fee_url,
      };

      values.url === '' ? delete values.url : null;

      if (formId && data && (questionType?.id || question?.id)) {
        //update or save new
        const actionToCall = edit ? updateQuestion : updateForm;
        const idToUpdate = edit ? question.id : questionType.id;
        setDisableSubmit(true);
        dispatch(actionToCall(formId, questionType.endpoint, idToUpdate, values));
      }
    }
  };

  return (
    <FormContainer>
      <form id={'feeAdd'} onSubmit={handleSubmit(onSubmitHandler)}>
        <Controller
          control={control}
          name={'fee_label'}
          render={({ field: { ref, ...rest } }) => (
            <InputContainer>
              <TextInput
                aria-label={'Fee Label'}
                errorMsg={_get(formState.errors.fee_label, 'message') || ''}
                id={'fee_label'}
                label={'Fee or Payment Label'}
                openSans
                placeholder={'Enter Label'}
                required
                {...rest}
              />
            </InputContainer>
          )}
        />
        <Controller
          control={control}
          name={'fee_instructions'}
          render={({ field: { ref, ...rest } }) => (
            <InputContainer>
              <Suspense fallback={<div />}>
                <EditTextArea
                  aria-label="Instructions"
                  errorMsg={_get(formState.errors.fee_instructions, 'message') || ''}
                  placeholder={`Enter Instructions`}
                  label={`Instructions`}
                  id={'fee_instructions'}
                  {...rest}
                />
              </Suspense>
            </InputContainer>
          )}
        />

        <CostContainer>
          <Controller
            control={control}
            name={'fee_cost'}
            render={({ field: { onChange, register, ref, ...rest } }) => (
              <InputContainer>
                <CurrencyInput
                  aria-label={'Fee Cost'}
                  errorMsg={_get(formState.errors.fee_cost, 'message') || ''}
                  id={'fee_cost'}
                  label={'Cost'}
                  openSans
                  required
                  onChange={value => {
                    setValue('fee_cost', value);
                  }}
                  {...rest}
                />
              </InputContainer>
            )}
          />
          <Controller
            control={control}
            name={'fee_currency'}
            render={({ field: { onChange, register, ref, ...rest } }) => (
              <CurrencyContainer>
                <Label>Currency</Label>
                <DropdownSelect
                  aria-label={'Currency'}
                  id={'fee_currency'}
                  options={Object.keys(currencyToSymbolMap).map(currencyCode => {
                    return { label: currencyCode, value: currencyCode };
                  })}
                  openSans
                  onChange={value => {
                    setValue('fee_currency', value);
                  }}
                  defaultValue={
                    edit ? { label: question.currency, value: question.currency } : { label: 'USD', value: 'USD' }
                  }
                />
              </CurrencyContainer>
            )}
          />
        </CostContainer>

        <Controller
          control={control}
          name={'fee_url'}
          render={({ field: { ref, ...rest } }) => (
            <InputContainer>
              <TextInput
                aria-label={'Online fee payment URL link '}
                errorMsg={_get(formState.errors.fee_url, 'message') || ''}
                id={'fee_url'}
                label={'Online payment URL Link'}
                openSans
                placeholder={'Enter your Fee/Payment URL'}
                {...rest}
              />
            </InputContainer>
          )}
        />

        <SubmitContainer>
          <AccentButtonMediumNunito
            aria-label={edit ? 'Save Fee' : 'Add Fee to Form'}
            form="feeAdd"
            type={'submit'}
            disabled={disableSubmit}
          >
            {disableSubmit ? <AccentButtonSpinner displayText="Saving ..." /> : edit ? 'Save' : 'Add to Form'}
            {!disableSubmit && <StyledIcon type="ChevronForward" color="#fff" />}
          </AccentButtonMediumNunito>
        </SubmitContainer>
      </form>
    </FormContainer>
  );
}
