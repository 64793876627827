import React, { useEffect } from 'react';
import { compose } from 'recompose';
import propTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { Modal } from 'react-responsive-modal';
import Headline from '../headline';
import sViaModal from './viaModal.scss';
import successPng from '../../../../../public/img/Success-whitebkgd.png';
import warningPng from '../../../../../public/img/Warning.png';
import infoPng from '../../../../../public/img/Info.png';
import CloseIcon from '../../../travelerProfile/components/icons/CloseIcon';
import { fontFamily } from '@atlaskit/theme';

export const enhance = compose(withStyles(sViaModal));

function ViaModal(props) {
  const { children } = props;

  return (
    <Modal
      blockScroll={false}
      open={props.showing}
      onClose={() => props.onClose(false)}
      center
      classNames={{
        overlay: sViaModal.customOverlay,
        modal: sViaModal.customModal,
        closeButton: sViaModal.customCloseButton,
      }}
      styles={
        !!props.width || (!!props.height && !!props.width)
          ? { modal: { height: `${props.height}`, width: `${props.width}` } }
          : null
      }
      closeOnEsc={props.closeable}
      closeOnOverlayClick={props.closeable}
      // showCloseIcon={props.closeable}
      showCloseIcon={false}
    >
      <div className={props.showCustomImage ? sViaModal['via-modal-title-image'] : sViaModal['via-modal-title']}>
        {props.customImageType === 'templateSaveWarning' && (
          <div className={sViaModal.templateSaveWarningHeaderImage}>
            {props.showCustomImage ? <img src={warningPng} /> : null}
          </div>
        )}
        {props.customImageType === 'warning' && (
          <div className={sViaModal.headerImage}>{props.showCustomImage ? <img src={warningPng} /> : null}</div>
        )}
        {props.customImageType === 'success' && (
          <div className={sViaModal.headerImage}>{props.showCustomImage ? <img src={successPng} /> : null}</div>
        )}
        {props.customImageType === 'info' && (
          <div className={sViaModal.headerImage}>{props.showCustomImage ? <img src={infoPng} /> : null}</div>
        )}

        <div className={sViaModal.modalHeader}>
          <Headline style={{
            fontFamily:"IntroBoldItalic, sans-serif",
            textTransform:"capitalize",
            fontSize:"24px",
            lineHeight:"32px"
          }} tag="div" as="div" upper={props.upper}>
            {props.headerText}
          </Headline>

          {props.closeable && (
            <button onClick={() => props.onClose()} type="button" className={sViaModal.closeButton} aria-label="Close">
              <CloseIcon fillColor={`#9FABAE`} />
            </button>
          )}
        </div>
      </div>
      <div className={sViaModal['via-modal']}>{children}</div>
    </Modal>
  );
}

ViaModal.propTypes = {
  showing: propTypes.bool.isRequired,
  closeable: propTypes.bool,
  onClose: propTypes.func,
  headerText: propTypes.string,
  showCustomImage: propTypes.bool,
  customImageType: propTypes.oneOf(['success', 'warning', 'templateSaveWarning', 'info']),
  upper: propTypes.bool,
};

ViaModal.defaultProps = {
  closeable: true,
  headerText: '',
  showCustomImage: false,
  upper: true,
};

export default enhance(ViaModal);
