export default [
  {
    id: 1,
    label: 'Mother',
    value: 'Mother',
  },
  {
    id: 2,
    label: 'Father',
    value: 'Father',
  },
  {
    id: 3,
    label: 'Parent',
    value: 'Parent',
  },
  {
    id: 4,
    label: 'Grandparent',
    value: 'Grandparent',
  },
  {
    id: 5,
    label: 'Mentor',
    value: 'Mentor',
  },
  {
    id: 6,
    label: 'Sister',
    value: 'Sister',
  },
  {
    id: 7,
    label: 'Brother',
    value: 'Brother',
  },
  {
    id: 8,
    label: 'Teacher',
    value: 'Teacher',
  },
  {
    id: 9,
    label: 'Other Family Member',
    value: 'Other Family Member',
  },
  {
    id: 10,
    label: 'Other',
    value: 'Other',
  },
];
