import React, { lazy, Suspense, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import _get from 'lodash/get';
import DropdownSelect from '../../../../../styledComponents/styles/DropdownSelect.styled';
import TextInput from '../../../../../styledComponents/styles/TextInput.styled';
import StyledIcon from '../../../../../styledComponents/styles/Icon.styled';
import Toggle from '../../../../../styledComponents/styles/Toggle.styled';
import { AccentButtonMediumNunito } from '../../../../../styledComponents/styles/Buttons.styled';
import { AccentButtonSpinner } from '../../../../../styledComponents/styles/spinner.styled';
import { updateForm, updateQuestion } from '../../../../../actions/formsActions';
import LogicTab from '../logic/logic.tab';
import LogicForm from '../logic/logicForm';

const EditTextArea = lazy(() => import('../../../../../styledComponents/styles/EditTextArea.styled'));

const FormContainer = styled.div`
  font-family: 'Nunito', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 0.015em;
  color: #373737;
`;

const InputContainer = styled.div`
  flex: 1;
  margin: 20px 0;
  text-align: left;
`;

const SubmitContainer = styled.main`
  display: flex;
  justify-content: flex-end;
  margin-top: 35px;
`;

const ToggleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: inherit;
`;

const ToggleLabel = styled.span`
  align-self: center;
  flex: 1;
  justify-content: center;
  padding-left: 10px;
  padding-bottom: 7px;
`;

export default function QuestionSingleChoiceAddEdit({ question, edit, onClose }) {
  const MAX_LENGTH = 90;
  const qIdentifier = 'single_choice';
  const dispatch = useDispatch();
  const fetchForm = useSelector(state => state.forms.fetchForm);
  const questionTypesInfo = useSelector(state => state.forms.questionTypes);
  const updateFormSubmit = useSelector(state => state.forms.updateForm);
  const updateQuestionSubmit = useSelector(state => state.forms.updateQuestion);
  const [formId, setFormId] = useState(null);
  const [showQuestion, setShowQuestion] = useState(true);
  const [questionLogicForm, setQuestionLogicForm] = useState(question);
  const [disableSubmit, setDisableSubmit] = useState(false);

  const schema = yup.object().shape({
    single_choice_label: yup
      .string()
      .required('Question must have label')
      .max(MAX_LENGTH, `Label can not be more than ${MAX_LENGTH} characters`),
    single_choice_instructions: yup.string(),
    single_choice_admin_only: yup.boolean(),
    single_choice_required: yup.boolean(),
    single_choice_correct_option: yup.string(),
  });

  const { control, handleSubmit, formState, reset, setValue } = useForm({
    shouldUnregister: false,
    resolver: yupResolver(schema),
    defaultValues: {
      single_choice_label: edit ? question.label : '',
      single_choice_instructions: edit ? question.instructions || '' : '',
      single_choice_correct_option: edit ? question.correct_option : '2',
      single_choice_admin_only: false,
      single_choice_required: edit ? question.required : true,
    },
    mode: 'onChange',
  });

  const dropdownOptions = [
    { value: 0, label: 'Yes' },
    { value: 1, label: 'No' },
    { value: 2, label: 'Either' },
  ];
  const dropdownChildOptions = [
    { value: 'yes', label: 'Yes' },
    { value: 'no', label: 'No' },
    { value: 'either', label: 'Either' },
  ];

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset({}, { keepValues: true });
    }
  }, [formState]);

  useEffect(() => {
    if (!fetchForm?.loading && fetchForm?.data) {
      setDisableSubmit(false);
      const findQuestion = fetchForm?.data?.questions?.find(q => q?.question_id === question?.question_id);
      setFormId(fetchForm.data.id);
      setQuestionLogicForm(findQuestion);
    }
  }, [fetchForm]);

  useEffect(() => {
    if (
      (!updateFormSubmit?.loading && updateFormSubmit?.data) ||
      (!updateQuestionSubmit?.loading && updateQuestionSubmit?.data)
    ) {
      setDisableSubmit(false);
      onClose();
    }
  }, [updateFormSubmit, updateQuestionSubmit]);

  const onSubmitHandler = data => {
    if (questionTypesInfo && questionTypesInfo.data) {
      const questionType = questionTypesInfo.data.reduce((prev, curr) => {
        return prev || curr.attributes.question_types.find(question => question.identifier === qIdentifier);
      }, undefined);
      
      let correctOption = dropdownOptions
        .filter(option => {
          return option.value.toString() === data.single_choice_correct_option;
        })[0]?.label.toLowerCase();

      const values = {
        admin_only: false,
        correct_option: correctOption,
        instructions: data.single_choice_instructions,
        label: data.single_choice_label,
        required: data.single_choice_required,
      };

      if (formId && data && (questionType?.id || question?.id)) {
        //update or save new
        const actionToCall = edit ? updateQuestion : updateForm;
        const idToUpdate = edit ? question.id : questionType.id;
        setDisableSubmit(true);
        dispatch(actionToCall(formId, questionType.endpoint, idToUpdate, values));
      }
    }
  };

  return (
    <FormContainer>
      {edit && (
        <LogicTab
          isDirty={formState.isDirty}
          onTabChange={value => {
            setShowQuestion(value === 'question');
          }}
        />
      )}
      {showQuestion && (
        <form id="singleChoiceAdd" onSubmit={handleSubmit(onSubmitHandler)}>
          <Controller
            control={control}
            name="single_choice_label"
            render={({ field: { ref, ...rest } }) => (
              <InputContainer>
                <TextInput
                  aria-label="Single Choice Label"
                  errorMsg={_get(formState.errors.single_choice_label, 'message') || ''}
                  id="single_choice_label"
                  label="Single Choice Label"
                  openSans
                  placeholder="Enter Label"
                  required
                  {...rest}
                />
              </InputContainer>
            )}
          />
          <Controller
            control={control}
            name="single_choice_instructions"
            render={({ field: { ref, ...rest } }) => (
              <InputContainer>
                <Suspense fallback={<div />}>
                  <EditTextArea
                    aria-label="Instructions"
                    errorMsg={_get(formState.errors.single_choice_instructions, 'message') || ''}
                    placeholder="Enter Instructions"
                    label="Instructions"
                    id="single_choice_instructions"
                    {...rest}
                  />
                </Suspense>
              </InputContainer>
            )}
          />

          <Controller
            control={control}
            name="single_choice_correct_option"
            render={({ field: { onChange, value, name, ref, ...rest } }) => (
              <InputContainer>
                <DropdownSelect
                  aria-label="Correct Option Dropdown"
                  errorMsg={_get(formState.errors.single_choice_correct_option, 'message') || ''}
                  placeholder="Select Correct Answer"
                  label="Select Correct Answer"
                  id="single_choice_correct_option"
                  options={dropdownOptions}
                  onChange={value => {
                    setValue('single_choice_correct_option', value, {
                      shouldValidate: true,
                      shouldDirty: true,
                      shouldTouch: true,
                    });
                    onChange(value);
                  }}
                  defaultValue={
                    edit
                      ? dropdownOptions.filter(
                          option =>
                            option.value.toString().toLowerCase() ===
                              question.correct_option.toString().toLowerCase() ||
                            option.label.toString().toLowerCase() === question.correct_option.toString().toLowerCase(),
                        )
                      : dropdownOptions[2]
                  }
                  {...rest}
                />
              </InputContainer>
            )}
          />

          <Controller
            control={control}
            name="single_choice_required"
            render={({ field: { onChange, register, ref, ...rest } }) => (
              <ToggleContainer>
                <Toggle
                  id="single_choice_required"
                  label="Required"
                  onChange={value => {
                    setValue('single_choice_required', value);
                  }}
                  ref={register}
                  {...rest}
                />
                <ToggleLabel>Required Field</ToggleLabel>
              </ToggleContainer>
            )}
          />
          <SubmitContainer>
            <AccentButtonMediumNunito
              aria-label={edit ? 'Save Single Choice Text' : 'Add Single Choice to Form'}
              form="singleChoiceAdd"
              type="submit"
              disabled={disableSubmit}
            >
              {disableSubmit ? <AccentButtonSpinner displayText="Saving ..." /> : edit ? 'Save' : 'Add to Form'}
              {!disableSubmit && <StyledIcon type="ChevronForward" color="#fff" />}
            </AccentButtonMediumNunito>
          </SubmitContainer>
        </form>
      )}
      {!showQuestion && <LogicForm questionOptions={dropdownChildOptions} question={questionLogicForm} />}
    </FormContainer>
  );
}
