import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';

import ProgramMatchSection from './ProgramMatchSection';
import LocationPointsIcon from '../icons/LocationPointsIcon';

import countries from '../../../../../public/data/countries.json';

export default function ProgramLocationsPreferences() {
  const programMatch = useSelector(state => state.programMatchForm);
  const {
    customAliases: { alias_program: programAlias },
  } = useSelector(state => state.currentOrganization);

  const [selectedCountries, setSelectedCountries] = useState('');

  useEffect(
    () => {
      const initialCountries = programMatch.programCountries.reduce((accumulator, programCountry) => {
        const value = countries.find(country => country.value === programCountry);

        if (value) {
          accumulator.push(value.label);
        }

        return accumulator;
      }, []);

      if (initialCountries.length > 0) {
        setSelectedCountries(initialCountries.join('; '));
      }
    },
    [programMatch.programCountries],
  );

  return (
    <ProgramMatchSection heading={`${programAlias} Locations`} icon={LocationPointsIcon}>
      <p>{selectedCountries || 'None selected'}</p>
    </ProgramMatchSection>
  );
}
