import React from 'react';
const MessageIcon = (props) => {
  return(
  <div>
    <svg width="25px" height="16px" viewBox="0 0 25 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Traveler-View-of-Dashboard" stroke="none" strokeWidth="1"  fillRule="evenodd">
          <g id="Traveler-View-of-Dashboard:-Messages" transform="translate(-613.000000, -1223.000000)" fill={props.color} >
              <g id="Meta" transform="translate(357.000000, 1189.000000)">
                  <g id="Group-2" transform="translate(75.000000, 0.000000)">
                      <g id="Group-4">
                          <g id="Total-Messages" transform="translate(181.000000, 30.000000)">
                              <path d="M0.8,5.25 L12.1375214,15.1 C12.2880342,15.2308547 12.5119658,15.2308547 12.6624786,15.1 L24,5.25 L24,18.8 C24,19.0285043 23.8285043,19.2 23.6,19.2 L1.2,19.2 C0.971495726,19.2 0.8,19.0285043 0.8,18.8 L0.8,5.25 Z M1.5,4.8 L23.3,4.8 L12.4,14.2624786 L1.5,4.8 Z M1.2,4 C0.542136752,4 0,4.54209402 0,5.2 L0,18.8 C0,19.457906 0.542136752,20 1.2,20 L23.6,20 C24.2578632,20 24.8,19.457906 24.8,18.8 L24.8,5.2 C24.8,4.54209402 24.2578632,4 23.6,4 L1.2,4 Z" id="All-Messages"></path>
                          </g>
                      </g>
                  </g>
              </g>
          </g>
      </g>
    </svg>
  </div>
  )
}
export default (MessageIcon);