import React, { useEffect, useRef, useState } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './TravelFeedAlertSidebar.css';
import closeIcon from './close.png';
import TravelFeedAlertFilterForm from './TravelFeedAlertFilterForm/TravelFeedAlertFilterForm';
import btnFilterImage from './btn-filter.svg';
import iconReloadImage from './icon-reload.svg';
import { useDispatch, useSelector } from 'react-redux';
import {
  getFilterConfig,
  getRiskAlerts,
  getRiskLevels,
  getRiskTypes,
  markAlertRead,
  saveRiskFilter,
  getCountries
} from '../../actions/riskAlertActions';
import { Spinner } from '../../styledComponents/styles/spinner.styled';
import { getRelativeTime, getLevelColor } from '../../helpers/RiskAlertHelper';
import SearchIcon from './Search.png';
import CrossIcon from './CrossIcon.png';
import RightArrowIconSvg from './right-arrow-icon.svg';

function TravelFeedAlertSidebar(props) {
  const sidePanelRef = useRef(null);
  const [showFilter, setShowFilter] = useState(false);
  const { riskAlerts, loading, appliedFilters, filterInfo, saveLoading, countriesOptions } = useSelector(state => state.riskAlert);
  const profile = useSelector(state => state.profile);
  const dispatch = useDispatch();

  useEffect(() => {
    if (props?.show) {
      handleOpenSidePanel();
    } else {
      handleCloseSidePanel();
    }
  }, [props.show]);

  useEffect(() => {
    if(countriesOptions?.length === 0){
      dispatch(getCountries())
    }
  }, []); 

  const handleOpenSidePanel = () => {
    sidePanelRef.current.classList.add(s.opensidebar);
    sidePanelRef.current.focus();
    dispatch(getRiskAlerts());
    dispatch(getRiskLevels());
    dispatch(getRiskTypes());
    dispatch(getFilterConfig());
  };

  const handleCloseSidePanel = () => {
    sidePanelRef.current.classList.remove(s.opensidebar);
  };

  const getRiskAlertIds = () => {
    let alert_ids = riskAlerts.map(alert => alert?.attributes?.guid);
    return alert_ids;
  };

  const handleMarkAllRead = () => {
    let alert_ids = getRiskAlertIds();
    dispatch(markAlertRead(alert_ids));
  };

  const removeFilter = (id, type) => {
    let filter_info = { ...filterInfo };

    if (type === 'risk_level') {
      filter_info.risk_level_ids = filter_info?.risk_level_ids?.filter(i => Number(i) !== Number(id));
    }

    if (type === 'risk_category') {
      filter_info.risk_category_ids = filter_info?.risk_category_ids?.filter(i => Number(i) !== Number(id));
    }

    let postData = {
      alert_config: filter_info,
    };

    dispatch(
      saveRiskFilter(postData, result => {
        if (result) {
          // hideFilter();
        }
      }),
    );
  };

  return (
    <>
      <div id="mySidepanel" className={s.alertsidebar} ref={sidePanelRef} tabIndex={0}>
        <a
          href="#"
          tabIndex={0}
          onClick={e => {
            e.preventDefault();
            props.handleClose();
          }}
          className={s.closeBtn}
        >
          <img src={closeIcon} role="button" alt="Close Icon" />
        </a>
        <div className={s.travelAlertWrapper}>
          <div className={s.headerContainer}>
            <div className={s.header}>
              <h2>Travel Alert Feed</h2>
              <div className={s.itemCounts}>
                Displaying the last 20 alerts.{' '}
                <a href="/reports/risk-alert-history" className={s.viewAllBtn}>
                  View All
                  <img src={RightArrowIconSvg}  alt="Right Arrow Icon" />
                </a>
              </div>
            </div>

            <div className={s.filterContainer}>
              <div>
                {showFilter === false && riskAlerts.length > 0 && (
                  <a
                    href="#"
                    className={s.markAllReadBtn}
                    onClick={e => {
                      e.preventDefault();
                      handleMarkAllRead();
                    }}
                  >
                    Mark all as read
                  </a>
                )}
              </div>
              <span className={s.spacer} />
              <div className={s.actionControls}>
                {showFilter === false && (
                  <a
                    href="#"
                    className={s.reloadBtn}
                    onClick={e => {
                      e.preventDefault();
                      dispatch(getRiskAlerts());
                    }}
                  >
                    <img width="25" src={iconReloadImage} alt="Reload Icon" />
                  </a>
                )}{' '}
                {showFilter === false && (
                  <a
                    href="#"
                    role="button"
                    onClick={e => {
                      e.preventDefault();
                      setShowFilter(true);
                    }}
                    className={s.filterBtn}
                  >
                    <img width="90" src={btnFilterImage} alt="Filter Image" />
                  </a>
                )}
              </div>
            </div>

            {showFilter === false && (
              <div className={s.chipContainer}>
                {appliedFilters.map((filter, index) => (
                  <div className={s.chipSidebar} key={`filter-${index}`}>
                    {filter.name}
                    <a
                      href="#"
                      role="button"
                      onClick={e => {
                        e.preventDefault();
                        removeFilter(filter.id, filter.type);
                      }}
                      className={s.chipSidebarCrossIcon}
                    >
                      <img src={CrossIcon} alt="Cross Icon" />
                    </a>
                  </div>
                ))}
              </div>
            )}
          </div>

          {showFilter ? (
            <TravelFeedAlertFilterForm hideFilter={() => setShowFilter(false)} />
          ) : (
            <div className={s.alertContainer}>
              {loading || saveLoading ? (
                <div className="text-center" style={{ padding: '100px', minHeight: '400px' }}>
                  <Spinner displayText={`Loading....`} />
                </div>
              ) : (
                <div className={s.itemContainer}>
                  {riskAlerts.length === 0 && (
                    <div className={s.noItemsDiv}>
                      <div>
                        <div>
                          <img src={SearchIcon} alt="Search Icon" />
                        </div>
                        <div className={s.noResultText}>No results</div>
                        <div
                          className={s.resetFilterBtn}
                          onClick={() => {
                            let postData = {
                              alert_config: {
                                send_alerts_filter_criteria: false,
                                display_if_travelers_impacted: false,
                                risk_level_ids: [],
                                risk_category_ids: [],
                              },
                            };

                            dispatch(
                              saveRiskFilter(postData, result => {
                                // console.log('result ', result);
                              }),
                            );
                          }}
                        >
                          Reset Filters
                        </div>
                      </div>
                    </div>
                  )}

                  {riskAlerts &&
                    riskAlerts.length > 0 &&
                    riskAlerts.map((riskAlert, index) => (
                      <a
                        className={s.itemList}
                        key={`risk-alert-${index}`}
                        href={`/client/alert-detail/${riskAlert?.attributes?.guid}`}
                        style={{
                          border: riskAlert?.attributes?.read === false ? '2px solid #6BA2DC' : 'none',
                        }}
                      >
                        <div
                          className={s.itemFlag}
                          style={{
                            borderColor: getLevelColor(String(riskAlert?.attributes?.risk_level).toUpperCase()).bgColor,
                          }}
                        >
                          <img
                            width="90"
                            alt="Flag"
                            src={riskAlert?.attributes?.country_flag_url}
                            style={{ height: '90px', width: 'auto' }}
                          />
                        </div>
                        <div className={s.itemDetails}>
                          {riskAlert?.attributes?.read === false && <span className={s.itemUnread}></span>}

                          {(function() {
                            let { bgColor, frontColor } = getLevelColor(
                              String(riskAlert?.attributes?.risk_level).toUpperCase(),
                            );

                            return (
                              <span
                                className={s.levelNormal}
                                style={{
                                  backgroundColor: bgColor,
                                  color: frontColor,
                                  fontFamily: 'KoHo',
                                  fontWeight: 600,
                                  lineHeight: '140%',
                                  fontSize: '14px',
                                }}
                              >
                                {String(riskAlert?.attributes?.risk_level).toUpperCase()}
                              </span>
                            );
                          })()}

                          <div className={s.itemTitle}>{riskAlert?.attributes?.title}</div>
                          <div>
                            <span className={s.textNormal}>
                              {getRelativeTime(riskAlert?.attributes?.created_at, profile?.orgTimezone)}
                            </span>{' '}
                            | <span className={s.textBold}>{riskAlert?.attributes?.risk_category}</span>
                          </div>
                        </div>
                        <div className={s.iconUser}>
                          <span
                            className={s.count}
                            style={{
                              color: Number(riskAlert?.attributes?.travler_admin_count) > 0 ? '#8B1313' : '#446372',
                            }}
                          >
                            {riskAlert?.attributes?.travler_admin_count
                              ? riskAlert?.attributes?.travler_admin_count
                              : '0'}
                          </span>
                          <span className={s.userImg}>
                            {/* <img width="20" src={iconUserImage} /> */}

                            <svg
                              width="20"
                              height="25"
                              viewBox="0 0 25 25"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M15.7527 3.69711C14.8405 2.71227 13.5665 2.16992 12.1602 2.16992C10.7465 2.16992 9.46817 2.70898 8.5602 3.68773C7.64239 4.67727 7.1952 6.02211 7.3002 7.4743C7.50833 10.3393 9.68849 12.6699 12.1602 12.6699C14.6319 12.6699 16.8083 10.3398 17.0197 7.47523C17.1262 6.03617 16.6762 4.69414 15.7527 3.69711Z"
                                fill={Number(riskAlert?.attributes?.travler_admin_count) > 0 ? '#8B1313' : '#446372'}
                              />
                              <path
                                d="M20.4104 23.1699H3.91042C3.69445 23.1727 3.48057 23.1274 3.28434 23.0371C3.08812 22.9469 2.91447 22.814 2.77605 22.6482C2.47136 22.284 2.34855 21.7866 2.43949 21.2837C2.83511 19.089 4.0698 17.2454 6.01042 15.9512C7.73449 14.8023 9.91839 14.1699 12.1604 14.1699C14.4025 14.1699 16.5864 14.8027 18.3104 15.9512C20.251 17.2449 21.4857 19.0885 21.8814 21.2832C21.9723 21.7862 21.8495 22.2835 21.5448 22.6477C21.4064 22.8136 21.2328 22.9466 21.0366 23.0369C20.8403 23.1272 20.6264 23.1727 20.4104 23.1699Z"
                                fill={Number(riskAlert?.attributes?.travler_admin_count) > 0 ? '#8B1313' : '#446372'}
                              />
                            </svg>
                          </span>
                        </div>
                      </a>
                    ))}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default withStyles(s)(TravelFeedAlertSidebar);
