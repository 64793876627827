import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import propTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import ViaModal from '../../../../components/library/viaModal';
import Headline from '../../../../components/library/headline';
import Button from '../../../../components/library/button';
import Paragraph from '../../../../components/library/paragraph';
import { patchPlan, getPlan } from '../../../../../actions/plans';
import sReinstatePlan from './reinstatePlan.scss';

export const enhance = compose(withStyles(sReinstatePlan));

function ReinstatePlan(props) {
  const dispatch = useDispatch();
  const planSubmit = useSelector(state => state.plans.addPatchPlan);
  const [saving, setSaving] = useState(false);

  useEffect(
    () => {
      if (planSubmit) {
        if (planSubmit.loading) {
          setSaving(true);
        }
        if (saving && !!planSubmit.plan) {
          dispatch(getPlan(planSubmit.plan.id));
          setSaving(false);
          props.onClose(false);
        }

        if (saving && !!planSubmit.error) {
          setSaving(false);
          // alert(planSubmit.error);
        }
      }
    },
    [planSubmit],
  );

  const updatePlan = () => {
    const submitValues = {
      removed: false,
    };

    dispatch(patchPlan(props.planId, submitValues));
  };

  return (
    <>
      <ViaModal showing={props.show} onClose={() => props.onClose(false)}>
        <div className={sReinstatePlan['reinstate-plan']}>
          <div className={sReinstatePlan.subHeader}>
            {!props.group ? (
              <Headline italic centered tag="h1" as="h1">
                Reinstate My Travel Plan
              </Headline>
            ) : (
              <Headline italic centered tag="h1" as="h1">
                Reinstate Group Plan
              </Headline>
            )}
          </div>
          <div className={sReinstatePlan.content}>
            {!props.group ? (
              <Paragraph centered size="large">
                You are about to reinstate your Travel Plan.
                <br />
                You will be able to reinstate any transportation, housing, and
                activity details added to your plan.
              </Paragraph>
            ) : (
              <Paragraph centered size="large">
                You are about to reinstate this Group Travel Plan. This action
                <br />
                will notify any Travelers associated with this plan. You will be
                <br />
                able to reinstate transportation, housing, and activity details
                <br />
                previously added to the group itinerary.
              </Paragraph>
            )}
          </div>
          <div className={sReinstatePlan.footer}>
            <div className={sReinstatePlan['footer-confirm']}>
              {!props.group ? (
                <Headline tag="h2" as="h3" centered>
                  Would You Still Like To Reinstate Your Travel Plan?
                </Headline>
              ) : (
                <Headline tag="h2" as="h3" centered>
                  Would You Still Like to Reinstate The Group Plan?
                </Headline>
              )}
            </div>
            <div className={sReinstatePlan['footer-button-wrapper']}>
              <div className={sReinstatePlan['button-content']}>
                <Button
                  display="secondary"
                  kind="solid"
                  size="medium"
                  onClick={() => {
                    props.onClose(false);
                  }}
                >
                  No, Do Not Reinstate
                </Button>
              </div>
              <div className={sReinstatePlan['button-content']}>
                <Button
                  display="primary"
                  kind="solid"
                  size="medium"
                  onClick={() => {
                    updatePlan();
                  }}
                >
                  Yes, Reinstate Plan
                </Button>
              </div>
            </div>
          </div>
        </div>
      </ViaModal>
    </>
  );
}

ReinstatePlan.propTypes = {
  show: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
  planId: propTypes.string.isRequired,
  group: propTypes.bool.isRequired,
};

export default enhance(ReinstatePlan);
