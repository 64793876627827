export default [
  {
    id: 1,
    label: 'Yes',
    value: 'Yes',
  },
  {
    id: 2,
    label: 'No',
    value: 'No',
  },
];
