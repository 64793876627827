/* eslint-disable */
import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import countries from '../../../../../public/data/countries.json';
import Loader from '../../../Loader/Loader';
import moment from 'moment-timezone';
import s from './Preferences.scss';
import lock from './images/lock.png';
import _capitalize from 'lodash/capitalize';
import _isEmpty from 'lodash/isEmpty';

class TravelerPreferences extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      programPreferencesExpanded: true,
      travelerIntakeExpanded: true,
    };
  }

  componentDidMount() {
    let height = null;
    const textAreas = document.getElementsByTagName('textarea');
    for (let textArea of textAreas) {
      if (!height) {
        height = textArea.clientHeight ? textArea.clientHeight : 70;
      }
      textArea.style.height = height;
    }
  }

  openBlock(event) {
    const block = event.target.getAttribute('data-name');

    this.setState({
      [block]: !this.state[block],
    });
  }

  handleInputChange(event) {
    const value = event.target.value;
    const name = event.target.name;

    this.setState({
      [name]: value,
    });
  }

  sentenceCase(str) {
    return str
      .replace(/[a-z]/i, function(letter) {
        return letter.toUpperCase();
      })
      .trim();
  }

  transformPreferredTerm() {
    let preferredTerm = this.props.preferences.program_terms.map(
      term => `${term.length > 0 ? `${moment(term[0]).format('LL')} to ${moment(term[1]).format('LL')};` : ''}`,
    );
    return preferredTerm.join(' ');
  }

  render() {
    if (this.props.preferencesLoading == true) {
      return <Loader />;
    } else if (this.props.preferences == null) {
      return <div>error</div>;
    } else {
      let prioritiesCounter = 1;
      this.props.preferences.priorities.forEach(priority => {
        priority.ranking = prioritiesCounter;
        prioritiesCounter += 1;
      });
      return (
        <form className={s.wrapper}>
          <div className={s.accordion}>
            <h1 data-name="programPreferencesExpanded" className={s.sectionHeader}>
              {this.props.customAliases.alias_program} Preferences&nbsp;
              {this.props.role.toLowerCase() === 'traveler' && this.props.show_program_match ? (
                <a href="/program-match" className={`${s.editLink}`}>
                  Edit Preferences
                </a>
              ) : null}
            </h1>
            <span
              data-name="programPreferencesExpanded"
              className={
                this.state.programPreferencesExpanded
                  ? 'icon icon--plus icon--large icon--pink icon--minus'
                  : 'icon icon--plus icon--large icon--pink icon--plus'
              }
            />
          </div>
          <div className={this.state.programPreferencesExpanded ? 'form form--default' : `${s.hidden}`}>
            <div className={s.inputWrapper}>
              <textarea
                name="desiredProgramType"
                type="text"
                value={
                  this.props.preferences.program_types === null
                    ? null
                    : !!this.props.preferences.program_types &&
                      this.props.preferences.program_types !== null &&
                      this.props.preferences.program_types
                        .map(
                          type =>
                            type === 'Teaching English'
                              ? type
                              : type.charAt(0).toUpperCase() + type.substring(1).toLowerCase(),
                        )
                        .join(', ')
                }
                className={this.props.preferences.program_types ? `${s.fieldAdded} ${s.textareaHeight}` : null}
                disabled
              />
              <img className={s.lockIcon} src={lock} />
              <label>Preferred {this.props.customAliases.alias_program} Type</label>
            </div>
            <div className={s.inputWrapper}>
              <input
                name="desiredProgramLengthMin"
                type="text"
                value={`${
                  this.props.preferences.minimum_duration_weeks
                    ? `${this.props.preferences.minimum_duration_weeks} weeks`
                    : ''
                }`}
                className={this.props.preferences.minimum_duration_weeks ? `${s.fieldAdded}` : null}
                readOnly
              />
              <img className={s.lockIcon} src={lock} />
              <label>Preferred Minimum {this.props.customAliases.alias_program} Length</label>
            </div>
            <div className={s.inputWrapper}>
              <input
                name="desiredProgramLengthMax"
                type="text"
                value={`${
                  this.props.preferences.maximum_duration_weeks
                    ? `${this.props.preferences.maximum_duration_weeks} weeks`
                    : ''
                } `}
                className={this.props.preferences.maximum_duration_weeks ? `${s.fieldAdded}` : null}
                readOnly
              />
              <img className={s.lockIcon} src={lock} />
              <label>Preferred Maximum {this.props.customAliases.alias_program} Length</label>
            </div>
            <div className={s.inputWrapper}>
              <input
                name="desiredProgramTerm"
                type="text"
                value={this.transformPreferredTerm()}
                className={this.props.preferences.program_terms ? `${s.fieldAdded}` : null}
                readOnly
              />
              <img className={s.lockIcon} src={lock} />
              <label>Preferred Term</label>
            </div>
            <div className={s.inputWrapper}>
              <input
                name="desiredLanguageImmersion"
                type="text"
                value={
                  this.props.preferences.language_immersion === null
                    ? null
                    : !!this.props.preferences.language_immersion &&
                      this.props.preferences.language_immersion === 'not_sure'
                      ? 'Not sure'
                      : this.props.preferences.language_immersion.charAt(0).toUpperCase() +
                        this.props.preferences.language_immersion.substring(1).toLowerCase()
                }
                className={this.props.preferences.language_immersion ? `${s.fieldAdded}` : null}
                readOnly
              />
              <img className={s.lockIcon} src={lock} />
              <label>Preferred Language Immersion</label>
            </div>
            <div className={s.inputWrapper}>
              <textarea
                name="preferredProgramLanguage"
                type="text"
                value={this.props.preferences.user_languages.join(', ')}
                className={this.props.preferences.user_languages ? `${s.fieldAdded} ${s.textareaHeight}` : null}
                disabled
              />
              <img className={s.lockIcon} src={lock} />
              <label>Preferred {this.props.customAliases.alias_program} Language</label>
            </div>
            <div className={s.inputWrapper}>
              <textarea
                name="desiredProgramLocation"
                type="text"
                value={this.props.preferences.program_countries
                  .map(country => countries.filter(val => val.value == country)[0].label)
                  .join(', ')}
                className={this.props.preferences.program_countries ? `${s.fieldAdded} ${s.textareaHeight}` : null}
                disabled
              />
              <img className={s.lockIcon} src={lock} />
              <label>Preferred {this.props.customAliases.alias_program} Location</label>
            </div>
            <div className={s.inputWrapper}>
              <input
                name="desiredHousing"
                type="text"
                value={this.props.preferences.program_housing
                  .map(housing => housing[0].toUpperCase() + housing.slice(1).toLowerCase())
                  .join(', ')}
                className={this.props.preferences.program_housing ? `${s.fieldAdded} ` : null}
                readOnly
              />

              <img className={s.lockIcon} src={lock} />
              <label>Preferred Housing</label>
            </div>
            <div className={s.inputWrapper}>
              <input
                name="otherProgramHousing"
                type="text"
                value={
                  this.props.preferences.other_program_housing
                    ? this.props.preferences.other_program_housing.map(name => name.replace(/_/g, ' '))
                    : ''
                }
                className={this.props.preferences.other_program_housing ? `${s.fieldAdded}` : null}
                readOnly
              />

              <img className={s.lockIcon} src={lock} />
              <label>Preferred Housing (Other)</label>
            </div>
            <div className={s.inputWrapper}>
              <textarea
                name="desiredSubjectAreas"
                type="text"
                value={this.props.preferences.subject_areas.join('; ')}
                className={this.props.preferences.subject_areas ? `${s.fieldAdded} ${s.textareaHeight}` : null}
                disabled
              />
              <img className={s.lockIcon} src={lock} />
              <label>Preferred Subject Areas</label>
            </div>
            <div className={s.inputWrapper}>
              <textarea
                name="travelPriorities"
                type="text"
                value={
                  _isEmpty(this.props.preferences.priorities)
                    ? 'Not prioritized yet'
                    : this.props.preferences.priorities
                        .map(
                          priority =>
                            priority.priority_id === 7
                              ? `${priority.ranking}. ${this.props.customAliases.alias_program} type`
                              : `${priority.ranking}. ${priority.text[0].toUpperCase() +
                                  priority.text.slice(1).toLowerCase()}`,
                        )
                        .join(', ')
                }
                className={this.props.preferences.priorities ? `${s.fieldAdded} ${s.textareaHeight}` : null}
                disabled
              />
              <img className={s.lockIcon} src={lock} />
              <label>Participation Priorities</label>
            </div>
          </div>
          <div className={s.accordion}>
            <h1 data-name="travelerIntakeExpanded" className={s.sectionHeader}>
              {this.props.customAliases.alias_traveler} Intake&nbsp; 
              {this.props.role.toLowerCase() == 'traveler' && this.props.show_intake ? (
                <a href="/intake" className={s.editLink}>
                  Edit Intake
                </a>
              ) : null}
            </h1>
            <span
              data-name="travelerIntakeExpanded"
              className={
                this.state.travelerIntakeExpanded
                  ? 'icon icon--plus icon--large icon--pink icon--minus'
                  : 'icon icon--plus icon--large icon--pink icon--plus'
              }
            />
          </div>
          <div className={this.state.travelerIntakeExpanded ? 'form form--default' : `${s.hidden}`}>
            <div className={s.inputWrapper}>
              <input
                name="SignupDate"
                type="text"
                value={moment(this.props.preferences.signup_date).format('L')}
                className={this.props.preferences.signup_date ? `${s.fieldAdded} ${s.capitalize}` : null}
                readOnly
              />
              <img className={s.lockIcon} src={lock} />
              <label>Signup Date</label>
            </div>
            <div className={s.inputWrapper}>
              <input
                name="SignupSource"
                type="text"
                value={
                  this.props.preferences.signup_source === ''
                    ? ''
                    : this.props.preferences.signup_source[0].toUpperCase() +
                      this.props.preferences.signup_source.slice(1).toLowerCase()
                }
                className={this.props.preferences.signup_source ? `${s.fieldAdded}` : null}
                readOnly
              />
              <img className={s.lockIcon} src={lock} />
              <label>Signup Source</label>
            </div>
            <div className={s.inputWrapper}>
              <input
                name="participationCertainty"
                type="text"
                value={
                  this.props.preferences.travel_certainty === "I want to but I don't know if I can"
                    ? "I want to but I don't know if I can"
                    : this.props.preferences.travel_certainty !== 'Not provided'
                      ? this.sentenceCase(this.props.preferences.travel_certainty.toLowerCase())
                      : ''
                }
                className={this.props.preferences.travel_certainty ? `${s.fieldAdded}` : null}
                readOnly
              />
              <img className={s.lockIcon} src={lock} />
              <label>Participation Certainty</label>
            </div>

            <div className={s.inputWrapper}>
              <textarea
                name="travelGoals"
                type="text"
                value={
                  this.props.preferences.travel_goal
                    ? Object.keys(this.props.preferences.travel_goal)
                        .filter(key => this.props.preferences.travel_goal[key] === true)
                        .map(name => _capitalize(name.replace(/_/g, ' ')))
                        .join(', ')
                    : ''
                }
                className={
                  this.props.preferences.travel_goal &&
                  Object.keys(this.props.preferences.travel_goal)
                    .filter(key => this.props.preferences.travel_goal[key] === true)
                    .map(name => name.replace(/_/g, ' '))
                    ? `${s.fieldAdded} ${s.textareaHeight}`
                    : null
                }
                readOnly
              />
              <img className={s.lockIcon} src={lock} />
              <label>Participation Goals</label>
            </div>
            <div className={s.inputWrapper}>
              <input
                name="travelHistory"
                type="text"
                value={
                  this.props.preferences.travel_experience
                    ? Object.keys(this.props.preferences.travel_experience)
                        .filter(key => this.props.preferences.travel_experience[key] === true)
                        .map(name => name.replace(/_/g, ' '))
                        .join(', ')
                    : null
                }
                className={
                  this.props.preferences.travel_experience &&
                  Object.keys(this.props.preferences.travel_experience).filter(
                    key => this.props.preferences.travel_experience[key] === true,
                  )
                    ? `${s.fieldAdded} ${s.capitalize}`
                    : `${s.fieldAdded} ${s.capitalize}`
                }
                readOnly
              />
              <img className={s.lockIcon} src={lock} />
              <label>Travel History</label>
            </div>
            <div className={s.inputWrapper}>
              <input
                name="financialAid"
                type="text"
                value={
                  this.props.preferences.financial_aid === 'Not provided' || this.props.preferences.financial_aid === ''
                    ? ''
                    : this.props.preferences.financial_aid[0].toUpperCase() +
                      this.props.preferences.financial_aid.slice(1).toLowerCase()
                }
                className={this.props.preferences.financial_aid ? `${s.fieldAdded}` : null}
                readOnly
              />
              <img className={s.lockIcon} src={lock} />
              <label>Receives Financial Aid</label>
            </div>
            <div className={s.inputWrapper}>
              <textarea
                name="fundingCertainty"
                type="text"
                value={
                  this.props.preferences.financial_info
                    ? Object.keys(this.props.preferences.financial_info)
                        .filter(key => this.props.preferences.financial_info[key] === true)
                        .map(name => {
                          let financial_source = name.replace(/_/g, ' ');
                          return financial_source[0].toUpperCase() + financial_source.slice(1).toLowerCase();
                        })
                        .join(', ')
                    : ''
                }
                className={
                  this.props.preferences.financial_info &&
                  Object.keys(this.props.preferences.financial_info)
                    .filter(key => this.props.preferences.financial_info[key] === true)
                    .map(name => name.replace(/_/g, ' '))
                    .join(', ')
                    ? `${s.fieldAdded} `
                    : null
                }
                readOnly
              />
              <img className={s.lockIcon} src={lock} />
              <label>Funding Sources</label>
            </div>
            <div className={s.inputWrapper}>
              <textarea
                name="fundingCertainty"
                type="text"
                value={
                  this.props.preferences.advising
                    ? Object.keys(this.props.preferences.advising)
                        .filter(key => this.props.preferences.advising[key] === true)
                        .map(name => {
                          let financial_source = name.replace(/_/g, ' ');
                          return financial_source[0].toUpperCase() + financial_source.slice(1).toLowerCase();
                        })
                        .join(', ')
                    : ''
                }
                className={
                  this.props.preferences.advising &&
                  Object.keys(this.props.preferences.advising).filter(
                    key => this.props.preferences.advising[key] === true,
                  )
                    ? `${s.fieldAdded}`
                    : null
                }
                readOnly
              />
              <img className={s.lockIcon} src={lock} />
              <label>Questions and Concerns</label>
            </div>
          </div>
        </form>
      );
    }
  }
}

export default withStyles(s)(TravelerPreferences);
