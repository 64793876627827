import React from 'react';
import moment from 'moment-timezone';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { fetchCurrentUser } from '../../../actions/profileActions';
import {
  getAvailableReports,
  getReport,
  getParticipationReport,
  getApplicationCountReport,
  getApplicationAlternatesReport,
  getTravelerInformationReport,
  getFormStatusByProgramAndTermReport,
  getApplicationContentByApplicationTemplate,
  getApplicationContentByProgramNameAndTerm,
  getFormContentByFormTemplate,
  getFormContentByProgramNameAndTerm,
} from '../../../actions/reportsActions';
import { withCookies } from 'react-cookie';
import customBranding from '../../../hoc/customBranding/customBranding';
import _map from 'lodash/map';
import ProfileSelectInput from '../../../sites/travelerProfile/components/base/inputs/ProfileSelectInput';
import ProfileTextInput from '../../../sites/travelerProfile/components/base/inputs/ProfileTextInput';
import { CircularProgress } from '@material-ui/core';
import Modal from 'react-responsive-modal';
import modalCss from 'react-responsive-modal/styles.css';
import CloseIcon from '../../../sites/travelerProfile/components/icons/CloseIcon';
import toastMessageStyles from '../../../sites/viaGlobal/components/toastMessage/toastMessage.scss';
import { toast } from 'react-toastify';
import infoIcon from './infoIcon.png';

const Header = styled.div`
  display: flex;
  justify-content: right;
  flex-direction: row;
  padding: 25px 25px;
  // border-bottom: 1px solid #f0f1f1;
`;

const Container = styled.div`
  padding: 30px 70px;
`;

const Heading = styled.h2`
  color: #373737;
  text-align: center;

  /* Headline 5 */
  font-family: Nunito;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 30px */
`;

const Footer = styled.div`
  border-radius: 0px 0px 10px 10px;
  background: #ebebeb;
  display: flex;
  flex-direction: row;
  justify-content: right;
  padding: 30px;
  gap: 55px;
`;

const CancelBtn = styled.button`
  color: #373737;
  /* Button Primary */
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 19.2px */
  letter-spacing: 0.96px;
  border: none;
`;

const PrimaryBtn = styled.button`
  border-radius: 5px;
  background: var(--theme_color_accent);
  color: var(--theme_color_accent_font);
  text-align: center;
  /* Button Primary */
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 19.2px */
  letter-spacing: 0.96px;
  border: 1px #d74b73;
  padding: 16px 28px;

  &:hover {
    opacity: 0.7;
  }

  &:disabled {
    opacity: 0.7;
  }
`;

const CloseButton = styled.button`
  // outline: none;
  border: none;
  padding: 0;
  background: none;
`;

const LabelDescription = styled.div`
  color: #373737;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 16.8px */
  letter-spacing: 0.035px;
  text-align: center;
`;

const FieldSection = styled.div`
  padding: 0px 10px;
  margin-bottom: 46px;
`;

const DownloadText = styled.div`
  color: #373737;
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: 0.035px;
  margin-bottom: 40px;
  text-align: center;
`;

const DownloadLink = styled.a`
  color: #255a94;
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: 0.035px;
  text-decoration-line: underline;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 34px 0px 0px;
  gap: 22px;
  min-height: 74px;
  width: 100%;
  background: #deecff;
  border-radius: 5px;
  margin-bottom: 48px;
  margin-top: 30px;
  border-left: 20px solid #6ba2dc;
  padding-left: 20px;
`;

const InfoContainerChildSecond = styled.div`
  display: flex;
  background: #deecff;
  flex-direction: column;
  justify-content: center;
`;

const InfoContainerMessage = styled.div`
  color: #000;
  font-family: 'Open Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 19.2px */
  letter-spacing: 0.08px;
`;

function CustomToastMessage() {
  return (
    <div
      style={{
        color: '#FFF',
        fontFamily: 'Open Sans',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: 'normal',
        letterSpacing: '-0.64px',
      }}
    >
      Standard report is generating.{' '}
      <a
        href="/reports/downloads"
        style={{
          color: '#fff',
          fontFamily: 'Open Sans',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 700,
          lineHeight: 'normal',
          letterSpacing: '-0.64px',
          textDecorationLine: 'underline',
        }}
      >
        View Downloads
      </a>
    </div>
  );
}

@customBranding
class ReportStart extends React.Component {
  constructor() {
    super();
    this.state = {
      reportType: '',
      reportExt: '',
      reportName: '',
      reportDownloaded: false,
      dataDownloading: false,
      standardReport: null,
      errors: {},
    };

    this.handleSelectChange = this.handleSelectChange.bind(this);
  }

  componentDidMount() {
    const { cookies } = this.props;
    const userId = cookies.get('user_id');
    this.props.fetchCurrentUser(userId);
    this.props.getAvailableReports(this.props.reportType);
  }

  handleSelectChange(value) {
    this.setState({ reportType: value.value });
    this.setState({ reportExt: value.type });
    this.setState({
      reportName: this.defaultFilename(value.prefix, value.type),
    });
    this.setState({ reportDownloaded: false });
    this.setState({ dataDownloading: false });
    this.setState({ standardReport: value });
  }

  downloadReportCallback = (status, message, data) => {
    if (status) {
      this.setState({
        dataDownloading: false,
      });
      toast(<CustomToastMessage />, {
        type: 'success',
        className: toastMessageStyles['successContainer'],
        toastClassName: toastMessageStyles['wrapper'],
        bodyClassName: toastMessageStyles['successBody'],
        progressClassName: toastMessageStyles['progress-bar'],
      });
      this.props.handleClose();
    } else {
      toast('Something went wrong, please try again later', {
        type: 'error',
        className: toastMessageStyles['failureContainer'],
        toastClassName: toastMessageStyles['wrapper'],
        bodyClassName: toastMessageStyles['failureBody'],
        progressClassName: toastMessageStyles['progress-bar'],
      });

      this.setState({
        dataDownloading: false,
      });
    }
  };

  gstateenerateReport = () => {
    this.setState({
      errors: {},
    });

    let errors = {};

    if (!this.state.standardReport) {
      errors = {
        ...errors,
        ['standardReport']: 'A standard report must be selected',
      };
    }

    if (!this.state.reportName) {
      errors = {
        ...errors,
        ['reportName']: 'A report name must be entered',
      };
    }

    if (Object.keys(errors).length > 0) {
      this.setState({
        errors: errors,
      });

      return false;
    }

    this.setState({ reportDownloaded: false });
    this.setState({ dataDownloading: true });

    const submissionIds =
      this.props.source === 'application_dashboard'
        ? this.props.applications
        : _map(this.props.applications, application => {
            return application.id;
          });
    const userIds = this.props.traveler_ids;

    switch (this.state.reportType.toLowerCase()) {
      case 'traveler_information':
        this.props.getTravelerInformationReport(userIds, this.state.reportName, this.downloadReportCallback);
        break;
      case 'form_status_by_program_name_and_term':
        this.props.getFormStatusByProgramAndTermReport(
          submissionIds,
          this.state.reportName,
          this.props?.reportBy || '',
          this.downloadReportCallback,
        );
        break;
      case 'application_counting':
        this.props.getApplicationCountReport(submissionIds, this.state.reportName, this.downloadReportCallback);
        break;
      case 'participation_report':
        this.props.getParticipationReport(submissionIds, this.state.reportName, this.downloadReportCallback);
        break;
      case 'application_alternates_report':
        this.props.getApplicationAlternatesReport(submissionIds, this.state.reportName, this.downloadReportCallback);
        break;
      case 'application_content_by_program_name_and_term':
        this.props.getApplicationContentByProgramNameAndTerm(
          submissionIds,
          this.state.reportName,
          this.downloadReportCallback,
        );
        break;
      case 'application_content_by_application_template':
        this.props.getApplicationContentByApplicationTemplate(
          submissionIds,
          this.state.reportName,
          this.downloadReportCallback,
        );
        break;
      case 'form_content_by_program_name_and_term':
        this.props.getFormContentByProgramNameAndTerm(
          submissionIds,
          this.state.reportName,
          this.props?.reportBy || '',
          this.downloadReportCallback,
        );
        break;
      case 'form_content_by_form_template':
        this.props.getFormContentByFormTemplate(
          submissionIds,
          this.state.reportName,
          this.props?.reportBy || '',
          this.downloadReportCallback,
        );
        break;

      default:
        this.props.getReport(
          {
            reportType: this.state.reportType,
            user_ids: this.props.traveler_ids,
            application_ids:
              this.props.source === 'application_dashboard'
                ? this.props.applications
                : _map(this.props.applications, application => {
                    return application.id;
                  }),
            origin: this.props.origin,
          },
          this,
        );
    }
  };

  defaultFilename = (prefix, suffix) => {
    return this.timestampedFilename(prefix || 'report', suffix);
  };

  timestampedFilename(filename, suffix) {
    suffix = suffix || 'csv';
    return filename + '_' + moment().format('YYYYMMDDHHmm') + '.' + suffix;
  }

  downloadReport(report) {
    if (!report.csv && !report.zip) {
      return;
    }

    const base64 = require('js-base64').Base64;
    var suffix = 'csv';
    var rpt = report.csv;
    var content_type = 'text/csv';

    if (!rpt) {
      suffix = 'zip';
      var str = base64.atob(report.zip);
      var buf = new ArrayBuffer(str.length);
      var bufView = new Uint8Array(buf);
      for (var i = 0, strLen = str.length; i < strLen; i++) {
        bufView[i] = str.charCodeAt(i);
        content_type = 'application/zip';
      }
      rpt = buf;
    }
    const fileDownload = require('js-file-download');
    fileDownload(rpt, this.state.reportName ? this.state.reportName : 'report.' + suffix, content_type);

    this.setState({ reportDownloaded: true });

    toast('Standard report downloaded', {
      type: 'success',
      className: toastMessageStyles['successContainer'],
      toastClassName: toastMessageStyles['wrapper'],
      bodyClassName: toastMessageStyles['successBody'],
      progressClassName: toastMessageStyles['progress-bar'],
    });
    this.setState({ dataDownloading: false });
  }

  render() {
    const count = this.props.origin == 'travelers' ? this.props.traveler_ids.length : this.props.applications.length;

    return (
      <Modal
        blockScroll={false}
        open={this.props.show}
        onClose={() => this.props.handleClose()}
        center
        closeOnEsc
        closeOnOverlayClick
        showCloseIcon={false}
        styles={{
          modal: {
            padding: '0px',
            borderRadius: '5px',
            width: '650px',
          },
        }}
      >
        <form
          onSubmit={e => {
            e.preventDefault();
            this.props.callbackAction();
            this.gstateenerateReport();
          }}
        >
          <Header>
            <CloseButton onClick={() => this.props.handleClose()}>
              <CloseIcon fillColor={`#9FABAE`} />
            </CloseButton>
          </Header>

          <Heading>Create Standard Report</Heading>
          <Container>
            <LabelDescription>
              {' '}
              You are about to create and export a standard report for <strong>{count}</strong> {this.props.origin}. To
              refine your results, filter on the Dashboard.
            </LabelDescription>
            <DownloadText>
              Once created, your report can be found in{' '}
              <DownloadLink href="/reports/downloads">downloads.</DownloadLink>
            </DownloadText>

            <FieldSection>
              <ProfileSelectInput
                value={this.state.standardReport}
                options={this.props.reports}
                label="Standard Report"
                placeholder={'Select Standard Report'}
                handleOnChange={this.handleSelectChange}
                error={this.state?.errors?.standardReport}
                showAstrik={true}
              />

              {this.state?.standardReport?.value === 'participation_report' && (
                <InfoContainer>
                  <div>
                    <img src={infoIcon} />
                  </div>
                  <InfoContainerChildSecond>
                    <InfoContainerMessage>
                      Export student and application data of ‘Committed’ applications for record keeping or OpenDoors®
                      reporting.
                    </InfoContainerMessage>
                  </InfoContainerChildSecond>
                </InfoContainer>
              )}
            </FieldSection>

            <FieldSection>
              <ProfileTextInput
                type="text"
                name="reportName"
                message="sdfasdf"
                label="Name of Report"
                placeholder={'Enter Report Name'}
                error={this.state.errors?.reportName}
                showAstrik={true}
                handleOnChange={e => {
                  this.setState({
                    reportName: e.target.value,
                  });
                }}
                value={this.state.reportName}
              />
            </FieldSection>
          </Container>

          <Footer>
            <CancelBtn onClick={() => this.props.handleClose()} type="button">
              Cancel
            </CancelBtn>
            <PrimaryBtn disabled={this.state.dataDownloading}>
              Create Standard Report
              {this.state.dataDownloading && (
                <>
                  {' '}
                  <CircularProgress color="inherit" size={20} />
                </>
              )}
            </PrimaryBtn>
          </Footer>
        </form>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  reports: state.reports.reports,
  report: state.reports.report,
  customBranding: state.profile.customBranding || {},
});

export default compose(
  withCookies,
  withStyles(modalCss, toastMessageStyles),
  connect(mapStateToProps, {
    fetchCurrentUser,
    getAvailableReports,
    getReport,
    getParticipationReport,
    getApplicationCountReport,
    getApplicationAlternatesReport,
    getTravelerInformationReport,
    getFormStatusByProgramAndTermReport,
    getApplicationContentByApplicationTemplate,
    getApplicationContentByProgramNameAndTerm,
    getFormContentByFormTemplate,
    getFormContentByProgramNameAndTerm,
  }),
)(ReportStart);
