import React from 'react';
import PropTypes from 'prop-types';

export default function GraduationCapIcon({ fillColor }) {
  return (
    <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
      <path
        d="M11.7545 2.51108L6.11129 0.52002C6.03568 0.493327 5.95318 0.493327 5.87757 0.52002L0.234391 2.51108C0.0940846 2.56058 0.000187606 2.69311 2.80685e-07 2.8419C-0.000187045 2.99068 0.0934055 3.12342 0.233595 3.17325L5.87678 5.17944C5.91483 5.19297 5.95464 5.19974 5.99442 5.19974C6.03423 5.19974 6.07401 5.19297 6.11206 5.17944L11.7552 3.17325C11.8954 3.12342 11.989 2.99068 11.9888 2.8419C11.9887 2.69311 11.8948 2.56058 11.7545 2.51108Z"
        fill={fillColor}
      />
      <path
        d="M11.145 7.19945V4.13574L10.4425 4.38547V7.19945C10.231 7.32124 10.0884 7.54942 10.0884 7.81109C10.0884 8.07274 10.231 8.30095 10.4425 8.42274V10.1488C10.4425 10.3428 10.5998 10.5001 10.7938 10.5001C10.9878 10.5001 11.145 10.3428 11.145 10.1488V8.42276C11.3566 8.301 11.4992 8.07276 11.4992 7.81112C11.4992 7.54945 11.3566 7.32124 11.145 7.19945Z"
        fill={fillColor}
      />
      <path
        d="M5.99461 5.90208C5.87404 5.90208 5.7553 5.88159 5.64167 5.8412L2.46924 4.71338V6.259C2.46924 6.63714 2.86372 6.95693 3.64173 7.20945C4.31987 7.42955 5.15549 7.55078 5.99461 7.55078C6.83374 7.55078 7.66933 7.42955 8.34749 7.20945C9.1255 6.95693 9.51999 6.63714 9.51999 6.259V4.71338L6.34758 5.8412C6.23392 5.88159 6.11518 5.90208 5.99461 5.90208Z"
        fill={fillColor}
      />
    </svg>
  );
}

GraduationCapIcon.propTypes = {
  fillColor: PropTypes.string,
};

GraduationCapIcon.defaultProps = {
  fillColor: '#D74B73',
};
