import React from 'react';

export default function LocationPointsIcon() {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
      <g clipPath="url(#clip0_1047_23253)">
        <path
          d="M8.84368 35.4724C11.9983 35.4724 14.5555 34.4303 14.5555 33.1447C14.5555 31.8592 11.9983 30.8171 8.84368 30.8171C5.68912 30.8171 3.13184 31.8592 3.13184 33.1447C3.13184 34.4303 5.68912 35.4724 8.84368 35.4724Z"
          fill="#C6795C"
        />
        <path
          d="M14.0565 32.1943C13.1643 33.0059 11.1662 33.5718 8.84257 33.5718C6.51894 33.5718 4.5208 33.0059 3.62867 32.1943C3.30966 32.4845 3.13086 32.8058 3.13086 33.1445C3.13086 34.4301 5.68806 35.4722 8.84264 35.4722C11.9971 35.4722 14.5544 34.4301 14.5544 33.1445C14.5543 32.8058 14.3753 32.4844 14.0565 32.1943Z"
          fill="#C6795C"
        />
        <path
          d="M8.84274 11.3492C4.25948 11.3492 0.543945 15.0646 0.543945 19.648C0.543945 26.6274 8.84274 33.0492 8.84274 33.0492C8.84274 33.0492 17.1415 26.6274 17.1415 19.648C17.1415 15.0646 13.4261 11.3492 8.84274 11.3492ZM8.84274 22.9579C7.01475 22.9579 5.53284 21.4759 5.53284 19.648C5.53284 17.82 7.01475 16.338 8.84274 16.338C10.6707 16.338 12.1527 17.8199 12.1527 19.648C12.1527 21.4759 10.6708 22.9579 8.84274 22.9579Z"
          fill="#D74B73"
        />
        <path
          d="M8.84278 11.3492C8.52138 11.3492 8.20448 11.368 7.89258 11.4037C12.0282 11.8752 15.2412 15.3861 15.2412 19.648C15.2412 25.1574 10.0709 30.3185 7.89258 32.2485C8.47378 32.7635 8.84278 33.0493 8.84278 33.0493C8.84278 33.0493 17.1416 26.6275 17.1416 19.6481C17.1416 15.0646 13.4261 11.3492 8.84278 11.3492Z"
          fill="#B25771"
        />
        <path
          d="M29.0775 19.068C31.502 19.068 33.4674 18.2671 33.4674 17.279C33.4674 16.291 31.502 15.4901 29.0775 15.4901C26.653 15.4901 24.6875 16.291 24.6875 17.279C24.6875 18.2671 26.653 19.068 29.0775 19.068Z"
          fill="#C6795C"
        />
        <path
          d="M32.7963 16.3289C32.0196 16.8326 30.645 17.1677 29.0774 17.1677C27.5099 17.1677 26.1351 16.8326 25.3585 16.3289C24.9339 16.6043 24.6875 16.9299 24.6875 17.2791C24.6875 18.2672 26.6529 19.0681 29.0774 19.0681C31.5019 19.0681 33.4673 18.2671 33.4673 17.2791C33.4673 16.9299 33.2209 16.6043 32.7963 16.3289Z"
          fill="#C6795C"
        />
        <path
          d="M29.0773 0.527908C25.5548 0.527908 22.6992 3.38352 22.6992 6.90611C22.6992 12.2703 29.0773 17.2059 29.0773 17.2059C29.0773 17.2059 35.4555 12.2703 35.4555 6.90611C35.4555 3.38352 32.5999 0.527908 29.0773 0.527908ZM29.0773 9.45002C27.6724 9.45002 26.5334 8.31109 26.5334 6.90611C26.5334 5.50112 27.6724 4.3622 29.0773 4.3622C30.4823 4.3622 31.6213 5.50112 31.6213 6.90611C31.6213 8.31109 30.4823 9.45002 29.0773 9.45002Z"
          fill="#D74B73"
        />
        <path
          d="M29.0772 0.527908C28.7542 0.527908 28.437 0.552096 28.127 0.598432C31.1988 1.05729 33.5549 3.70646 33.5549 6.90604C33.5549 10.9646 29.9042 14.7773 28.127 16.393C28.6987 16.9128 29.0772 17.2059 29.0772 17.2059C29.0772 17.2059 35.4553 12.2703 35.4553 6.90604C35.4553 3.38352 32.5998 0.527908 29.0772 0.527908Z"
          fill="#B25771"
        />
        <path
          d="M8.8433 10.8213C3.97626 10.8213 0.0166016 14.7809 0.0166016 19.648C0.0166016 22.6698 1.45407 25.9188 4.28915 29.3048C4.71039 29.8079 5.13016 30.274 5.53298 30.6993C3.70246 31.199 2.60347 32.0967 2.60347 33.1444C2.60347 34.9992 5.81831 36 8.84309 36C11.8679 36 15.0827 34.9991 15.0827 33.1444C15.0827 32.0975 13.984 31.1996 12.1547 30.6998C12.8855 29.9282 13.6719 29.0205 14.418 28.008C14.5911 27.7733 14.5409 27.4428 14.3062 27.2698C14.0717 27.0971 13.7411 27.147 13.5681 27.3817C12.6673 28.6042 11.6922 29.6786 10.8413 30.532C10.7438 30.5818 10.6622 30.6608 10.6111 30.7604C9.84891 31.5095 9.20752 32.0652 8.84365 32.3688C8.48372 32.0681 7.8521 31.52 7.09969 30.7807C7.04569 30.6624 6.95083 30.5695 6.8351 30.5178C4.44398 28.1128 1.07249 23.9576 1.07249 19.6481C1.07249 15.3632 4.55852 11.8772 8.84337 11.8772C13.1282 11.8772 16.6143 15.3632 16.6143 19.6481C16.6143 21.5193 15.9767 23.5401 14.7192 25.6543C14.5702 25.905 14.6525 26.2289 14.9031 26.3779C15.1536 26.5269 15.4776 26.4447 15.6266 26.1941C16.9825 23.9142 17.6701 21.7119 17.6701 19.6481C17.67 14.7809 13.7103 10.8213 8.8433 10.8213ZM8.8433 33.5771C8.95727 33.5771 9.07125 33.5403 9.16638 33.4667C9.23121 33.4166 10.1133 32.7286 11.2891 31.579C13.0696 31.9421 14.027 32.6412 14.027 33.1444C14.027 33.5202 13.5118 33.9636 12.6826 34.3015C11.6656 34.716 10.3021 34.9443 8.84309 34.9443C5.67867 34.9443 3.65922 33.8782 3.65922 33.1444C3.65922 32.6405 4.61688 31.9417 6.39832 31.5786C7.58076 32.7351 8.46417 33.4233 8.52021 33.4667C8.61534 33.5404 8.72939 33.5771 8.8433 33.5771Z"
          fill="#373737"
        />
        <path
          d="M5.80645 18.2258C5.52632 18.1457 5.23389 18.3074 5.15338 18.5876C5.05488 18.9309 5.00488 19.2876 5.00488 19.648C5.00488 21.7642 6.72649 23.4858 8.84269 23.4858C10.9589 23.4858 12.6805 21.7642 12.6805 19.648C12.6805 17.5318 10.9589 15.8102 8.84269 15.8102C8.00772 15.8102 7.21375 16.0738 6.54663 16.5725C6.31319 16.7471 6.26538 17.0779 6.43989 17.3114C6.61455 17.5448 6.9453 17.5926 7.17881 17.4181C7.66193 17.0569 8.2373 16.866 8.84262 16.866C10.3767 16.866 11.6246 18.1139 11.6246 19.648C11.6246 21.182 10.3766 22.43 8.84262 22.43C7.30861 22.43 6.06063 21.182 6.06063 19.648C6.06063 19.3863 6.09684 19.1274 6.16821 18.8789C6.24864 18.5986 6.08671 18.3062 5.80645 18.2258Z"
          fill="#373737"
        />
        <path
          d="M33.9956 17.2791C33.9956 16.4427 33.2094 15.746 31.8491 15.3367C32.1177 15.0485 32.3943 14.738 32.6719 14.4065C34.8697 11.7815 35.9841 9.25806 35.9841 6.90604C35.984 3.09812 32.886 0 29.078 0C26.2962 0 23.7985 1.65558 22.7149 4.21777C22.6013 4.4863 22.7269 4.79609 22.9954 4.90958C23.2638 5.02306 23.5737 4.89756 23.6872 4.6291C24.6053 2.45841 26.7213 1.05574 29.078 1.05574C32.3039 1.05574 34.9283 3.68023 34.9283 6.90611C34.9283 11.2686 30.3482 15.448 29.0778 16.5231C28.7978 16.2864 28.3571 15.8992 27.8403 15.3927C27.7888 15.2905 27.7055 15.2095 27.6056 15.1594C25.7829 13.3242 23.2277 10.1695 23.2277 6.90611C23.2277 6.61452 22.9914 6.3782 22.6998 6.3782C22.4082 6.3782 22.1719 6.61445 22.1719 6.90611C22.1719 9.25806 23.2863 11.7816 25.4842 14.4066C25.7615 14.7378 26.038 15.0482 26.3065 15.3363C24.9456 15.7452 24.16 16.4411 24.16 17.2792C24.16 17.9859 24.7334 18.6089 25.7746 19.0331C26.6654 19.3961 27.8386 19.5961 29.0779 19.5961C30.3172 19.5961 31.4903 19.3961 32.3813 19.0331C33.4222 18.6088 33.9956 17.9858 33.9956 17.2791ZM29.0778 18.5402C26.579 18.5402 25.2159 17.7071 25.2159 17.279C25.2159 17.0002 25.8359 16.4899 27.1601 16.2083C28.051 17.0752 28.7123 17.5903 28.755 17.6234C28.8501 17.6971 28.9641 17.7338 29.0781 17.7338C29.192 17.7338 29.3059 17.6971 29.4011 17.6234C29.4438 17.5905 30.105 17.0753 30.9959 16.2085C31.5858 16.3343 32.0969 16.5185 32.4545 16.7363C32.7584 16.9215 32.9399 17.1244 32.9399 17.2791C32.9398 17.7071 31.5767 18.5402 29.0778 18.5402Z"
          fill="#373737"
        />
        <path
          d="M32.1496 6.9061C32.1496 5.21234 30.7716 3.83428 29.0777 3.83428C27.384 3.83428 26.0059 5.21227 26.0059 6.9061C26.0059 8.59986 27.3838 9.97785 29.0777 9.97785C30.7717 9.97785 32.1496 8.59993 32.1496 6.9061ZM27.0617 6.9061C27.0617 5.79446 27.9661 4.89003 29.0778 4.89003C30.1895 4.89003 31.0939 5.79439 31.0939 6.9061C31.0939 8.01767 30.1895 8.9221 29.0778 8.9221C27.9661 8.9221 27.0617 8.01774 27.0617 6.9061Z"
          fill="#373737"
        />
        <path
          d="M22.2296 17.807C22.5211 17.807 22.7575 17.5708 22.7575 17.2791C22.7575 16.9875 22.5211 16.7512 22.2296 16.7512H21.9886C21.8131 16.7512 21.6367 16.7628 21.4642 16.7858C21.1753 16.8243 20.9721 17.0896 21.0105 17.3788C21.0459 17.6441 21.2726 17.8371 21.5332 17.8371C21.5564 17.8371 21.5798 17.8356 21.6035 17.8325C21.73 17.8157 21.8595 17.8072 21.9886 17.8072H22.2296V17.807Z"
          fill="#373737"
        />
        <path
          d="M20.485 32.5896C20.4841 32.5896 20.4833 32.5896 20.4825 32.5896L19.7853 32.5928C19.4938 32.5941 19.2585 32.8316 19.2598 33.1231C19.2611 33.4138 19.4972 33.6486 19.7876 33.6486C19.7884 33.6486 19.7893 33.6486 19.7901 33.6486L20.4873 33.6454C20.7788 33.6441 21.0141 33.4067 21.0128 33.1151C21.0115 32.8243 20.7754 32.5896 20.485 32.5896Z"
          fill="#373737"
        />
        <path
          d="M22.856 24.6449C23.1476 24.6449 23.3839 24.4086 23.3839 24.117C23.3839 23.8254 23.1476 23.5891 22.856 23.5891H22.1588C21.8672 23.5891 21.6309 23.8254 21.6309 24.117C21.6309 24.4086 21.8672 24.6449 22.1588 24.6449H22.856Z"
          fill="#373737"
        />
        <path
          d="M18.9016 19.1347C18.9948 19.2017 19.1024 19.2339 19.2092 19.2339C19.3733 19.2339 19.5352 19.1575 19.6384 19.0139C19.753 18.8543 19.8843 18.7058 20.0287 18.5727C20.243 18.375 20.2564 18.041 20.0588 17.8267C19.8611 17.6125 19.5273 17.599 19.3128 17.7966C19.116 17.9781 18.937 18.1804 18.7808 18.3979C18.6107 18.6349 18.6649 18.9647 18.9016 19.1347Z"
          fill="#373737"
        />
        <path
          d="M23.274 32.5768C23.2731 32.5768 23.2723 32.5768 23.2715 32.5768L22.5743 32.58C22.2828 32.5813 22.0476 32.8188 22.0488 33.1103C22.0502 33.401 22.2862 33.6358 22.5767 33.6358C22.5775 33.6358 22.5783 33.6358 22.5791 33.6358L23.2763 33.6326C23.5678 33.6314 23.8031 33.3939 23.8018 33.1023C23.8005 32.8116 23.5644 32.5768 23.274 32.5768Z"
          fill="#373737"
        />
        <path
          d="M17.6959 32.6022C17.6951 32.6022 17.6943 32.6022 17.6935 32.6022L16.9962 32.6054C16.7047 32.6066 16.4694 32.8442 16.4707 33.1357C16.472 33.4264 16.7081 33.6612 16.9985 33.6612C16.9994 33.6612 17.0002 33.6612 17.001 33.6612L17.6982 33.658C17.9898 33.6567 18.225 33.4192 18.2238 33.1277C18.2225 32.8369 17.9864 32.6022 17.6959 32.6022Z"
          fill="#373737"
        />
        <path
          d="M20.167 24.1198C20.3422 24.1198 20.5136 24.0327 20.6141 23.8735C20.7696 23.6269 20.6958 23.3009 20.4492 23.1453C20.2834 23.0407 20.1273 22.9185 19.9856 22.7823C19.7754 22.5801 19.4412 22.5868 19.2392 22.7968C19.0371 23.0069 19.0436 23.341 19.2537 23.5431C19.4469 23.729 19.6596 23.8955 19.886 24.0383C19.9733 24.0935 20.0707 24.1198 20.167 24.1198Z"
          fill="#373737"
        />
        <path
          d="M19.1513 21.2546C19.1158 21.0726 19.0978 20.8855 19.0978 20.6981L19.098 20.6688C19.1014 20.3773 18.8678 20.1382 18.5762 20.1349C18.5742 20.1348 18.5721 20.1348 18.57 20.1348C18.2813 20.1348 18.0456 20.3671 18.0422 20.6566L18.042 20.6982C18.042 20.9532 18.0665 21.2083 18.1149 21.4566C18.164 21.7087 18.3849 21.8835 18.6326 21.8835C18.666 21.8835 18.7 21.8804 18.7341 21.8737C19.0203 21.8179 19.207 21.5408 19.1513 21.2546Z"
          fill="#373737"
        />
        <path
          d="M25.645 24.6449C25.9366 24.6449 26.173 24.4086 26.173 24.117C26.173 23.8254 25.9366 23.5891 25.645 23.5891H24.9478C24.6562 23.5891 24.4199 23.8254 24.4199 24.117C24.4199 24.4086 24.6562 24.6449 24.9478 24.6449H25.645Z"
          fill="#373737"
        />
        <path
          d="M33.7934 25.6062C33.9493 25.6062 34.1039 25.5373 34.2081 25.4054C34.3887 25.1766 34.3497 24.8445 34.1209 24.6639C33.9175 24.5034 33.7001 24.3575 33.4746 24.2306C33.2208 24.0875 32.8986 24.1774 32.7556 24.4315C32.6125 24.6854 32.7023 25.0075 32.9564 25.1505C33.1343 25.2507 33.306 25.3658 33.4666 25.4926C33.5635 25.5692 33.6788 25.6062 33.7934 25.6062Z"
          fill="#373737"
        />
        <path
          d="M31.5657 32.5044C31.3928 32.5283 31.2158 32.5409 31.0392 32.5416L30.9404 32.5421C30.6488 32.5436 30.4136 32.7811 30.415 33.0725C30.4165 33.3632 30.6526 33.5979 30.9429 33.5979C30.9438 33.5979 30.9446 33.5979 30.9455 33.5979L31.0441 33.5974C31.267 33.5964 31.4912 33.5806 31.7102 33.5503C31.999 33.5104 32.2008 33.2438 32.1609 32.9551C32.1208 32.6663 31.8539 32.4642 31.5657 32.5044Z"
          fill="#373737"
        />
        <path
          d="M33.7447 31.4525C33.5967 31.5936 33.4363 31.7242 33.2682 31.8408C33.0286 32.0069 32.969 32.3358 33.1351 32.5755C33.2376 32.7234 33.4021 32.8027 33.5694 32.8027C33.6732 32.8027 33.7779 32.7722 33.8697 32.7086C34.0824 32.561 34.2854 32.3956 34.473 32.217C34.684 32.0159 34.6921 31.6817 34.491 31.4707C34.2899 31.2594 33.9557 31.2513 33.7447 31.4525Z"
          fill="#373737"
        />
        <path
          d="M35.5185 28.9067C35.2324 28.8528 34.956 29.0412 34.902 29.3277C34.8641 29.5286 34.8103 29.728 34.7417 29.9203C34.6439 30.1949 34.787 30.4969 35.0617 30.5948C35.1203 30.6157 35.1802 30.6256 35.239 30.6256C35.4558 30.6256 35.6592 30.4909 35.7362 30.2749C35.8232 30.0309 35.8916 29.778 35.9396 29.5231C35.9935 29.2367 35.805 28.9606 35.5185 28.9067Z"
          fill="#373737"
        />
        <path
          d="M34.8575 26.2245C34.5934 26.3478 34.4791 26.6619 34.6024 26.9262C34.6888 27.1114 34.7613 27.3049 34.8176 27.501C34.884 27.7326 35.0953 27.8835 35.3247 27.8835C35.373 27.8835 35.4219 27.8768 35.4706 27.8629C35.7508 27.7824 35.9128 27.49 35.8323 27.2098C35.7608 26.9605 35.6689 26.715 35.5591 26.4797C35.4358 26.2155 35.122 26.1013 34.8575 26.2245Z"
          fill="#373737"
        />
        <path
          d="M28.4341 24.6449C28.7257 24.6449 28.962 24.4086 28.962 24.117C28.962 23.8254 28.7257 23.5891 28.4341 23.5891H27.7369C27.4453 23.5891 27.209 23.8254 27.209 24.117C27.209 24.4086 27.4453 24.6449 27.7369 24.6449H28.4341Z"
          fill="#373737"
        />
        <path
          d="M26.0621 32.5642C26.0613 32.5642 26.0605 32.5642 26.0597 32.5642L25.3624 32.5673C25.0709 32.5687 24.8357 32.8061 24.8369 33.0976C24.8383 33.3884 25.0743 33.6232 25.3648 33.6232C25.3656 33.6232 25.3664 33.6232 25.3672 33.6232L26.0644 33.62C26.3559 33.6187 26.5912 33.3812 26.5899 33.0897C26.5887 32.799 26.3526 32.5642 26.0621 32.5642Z"
          fill="#373737"
        />
        <path
          d="M31.0217 24.6449C31.0814 24.6449 31.1407 24.6463 31.1997 24.6489C31.2076 24.6493 31.2156 24.6494 31.2235 24.6494C31.5042 24.6494 31.7379 24.4282 31.7504 24.1449C31.7633 23.8536 31.5377 23.607 31.2464 23.5942C31.1717 23.5909 31.0961 23.5893 31.0217 23.5893H30.526C30.2344 23.5893 29.998 23.8255 29.998 24.1172C29.998 24.4087 30.2344 24.6451 30.526 24.6451L31.0217 24.6449Z"
          fill="#373737"
        />
        <path
          d="M28.8512 32.5516C28.8504 32.5516 28.8496 32.5516 28.8488 32.5516L28.1515 32.5548C27.86 32.556 27.6247 32.7935 27.626 33.0851C27.6272 33.3758 27.8634 33.6106 28.1538 33.6106C28.1547 33.6106 28.1554 33.6106 28.1563 33.6106L28.8535 33.6074C29.145 33.6061 29.3803 33.3686 29.379 33.0771C29.3777 32.7863 29.1416 32.5516 28.8512 32.5516Z"
          fill="#373737"
        />
      </g>
      <defs>
        <clipPath id="clip0_1047_23253">
          <rect width="36" height="36" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
