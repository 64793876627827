import PropTypes from 'prop-types';
import styled from 'styled-components';
import { orderBy, uniq } from 'lodash';
import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';

import ProfileSelectInput from '../../../base/inputs/ProfileSelectInput';

import { breakpoints } from '../../../../utils/breakpoints';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.375rem;
  padding-left: 1.5625rem;

  @media ${breakpoints.desktop} {
    gap: 3.5rem;
    padding: 2rem 1.5rem 0 1.5rem;
  }
`;

export default function LocationsFilterInputs({
  selectedCities,
  selectedCountries,
  handleCitiesChange,
  handleCountriesChange,
}) {
  const { programs } = useSelector(state => state.programDashboard);
  const {
    customAliases: { alias_program: programAlias },
  } = useSelector(state => state.currentOrganization);

  const [cityOptions, setCityOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);

  useEffect(
    () => {
      if (programs.length > 0) {
        const cities = [];
        const countries = [];

        programs.filter(program => program.attributes.locations).forEach(program => {
          const locationsArray = program.attributes.locations.split(';');

          locationsArray.forEach(location => {
            const locationArray = location.split(',');

            if (locationArray.length > 1) {
              cities.push(locationArray[0].trim());
              countries.push(locationArray[1].trim());
            } else {
              countries.push(locationArray[0].trim());
            }
          });
        });

        const orderedCities = orderBy(uniq(cities), city => city.toLowerCase(), ['asc']).map(city => ({
          label: city,
          value: city,
        }));
        const orderedCountries = orderBy(uniq(countries), country => country.toLowerCase(), ['asc']).map(country => ({
          label: country,
          value: country,
        }));

        setCityOptions(orderedCities);
        setCountryOptions(orderedCountries);
      }
    },
    [programs],
  );

  return (
    <Container>
      <ProfileSelectInput
        id="program_country"
        value={selectedCountries}
        label={`${programAlias} Country`}
        placeholder="Select Countries"
        options={countryOptions}
        isMulti
        handleOnChange={handleCountriesChange}
      />
      <ProfileSelectInput
        id="program_city"
        value={selectedCities}
        label={`${programAlias} City`}
        placeholder="Select Cities"
        options={cityOptions}
        isMulti
        handleOnChange={handleCitiesChange}
      />
    </Container>
  );
}

LocationsFilterInputs.propTypes = {
  handleCitiesChange: PropTypes.func.isRequired,
  handleCountriesChange: PropTypes.func.isRequired,
  selectedCities: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  selectedCountries: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
};
