import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ButtonMediumNunito, AccentButtonMediumNunito } from '../../../../../styledComponents/styles/Buttons.styled';
import GenericStyledModal from './GenericStyledModal.modal';
import { Paragraph } from '../../../../../styledComponents/styles/Paragraph.styled';
import styled from 'styled-components';
import { WarningIcon } from '../../../../../styledComponents/styles/IconCustom.styled';
import { cancelRecommendationEmail } from '../../../../../actions/formsActions';

const ButtonContainer = styled.div`
  background: #ebebeb;
  display: flex;
  height: 111px;
  justify-content: flex-end;
  left: 0;
  top: 273px;
  width: 100%;
  margin-bottom: -20px;
`;

const ButtonDiv = styled.div`
  margin-top: 30px;
  margin-right: 20px;
`;

const FlexContainer = styled.div`
  display: grid;
  grid-template-areas: 'image main';
  grid-template-columns: 1fr;
  transition: all 0.25s ease-in-out;
  padding: 30px 0;

  @media (max-width: 550px) {
    grid-template-areas:
      'image'
      'main';
  }
`;

const ImageContainer = styled.div`
  grid-area: image;
  margin-right: 30px;
  padding-left: 40px;
`;

const Main = styled.main`
  grid-area: main;
  margin-right: 37px;
  @media (max-width: 480px) {
    padding:20px;
  }
`;

export default function CancelRecommendationRequestModal(props) {
  const dispatch = useDispatch();
  const [isSaving, setIsSaving] = useState(false);

  const submitEmail = () => {
    dispatch(cancelRecommendationEmail(props.cancelEmail.id));
    props.setShowCancelRequest(!props.showing);
  };
  return (
    <GenericStyledModal
      header={'Recommendation Request'}
      showing={props.showing}
      maxWidth={'520px'}
      onClose={() => {
        props.setShowCancelRequest(!props.showing);
      }}
    >
      <FlexContainer>
        <Main>
          <Paragraph>You are about to cancel a recommendation.</Paragraph>
          <Paragraph>
            Cancelling will mean that {props.cancelEmail.user_email} will no longer be able to submit a recommendation.
            Are you sure you want to continue?
          </Paragraph>
        </Main>
        <ImageContainer>
          <WarningIcon size={'125px'} />
        </ImageContainer>
      </FlexContainer>

      <ButtonContainer>
        <ButtonDiv>
          <ButtonMediumNunito
            disabled={isSaving}
            onClick={() => {
              props.setShowCancelRequest(!props.showing);
            }}
          >
            Cancel
          </ButtonMediumNunito>
        </ButtonDiv>
        <ButtonDiv>
          <AccentButtonMediumNunito
            disabled={isSaving}
            onClick={() => {
              submitEmail();
            }}
          >
            Cancel Recommendation
          </AccentButtonMediumNunito>
        </ButtonDiv>
      </ButtonContainer>
    </GenericStyledModal>
  );
}
