import styled from 'styled-components';
import { breakpoints } from '../settings/breakpoints';

export const Col = styled.div`
  flex: ${( {size} ) => size};
  margin-bottom: 11px;

  @media ${breakpoints.laptop} {
    margin-bottom: 0;
  }
`;

export const Grid = styled.div``;

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 36px;
  justify-content: ${props => props.justify || 'inherit'};

  @media ${breakpoints.laptop} {
    flex-direction: row;
    padding-bottom: 42px;
  }
`;

export const Row18 = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 18px;
  justify-content: ${props => props.justify || 'inherit'};
`;


