/* eslint-disable */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { findDOMNode } from 'react-dom';
import { DragSource, DropTarget } from 'react-dnd';
import styled from 'styled-components';
import TextInput from '../../../../../styledComponents/styles/TextInput.styled';
import StyledIcon from '../../../../../styledComponents/styles/Icon.styled';
import HamburgerMenuIcon from '../../../../travelerProfile/components/icons/HamburgerMenuIcon';


const InputContainer = styled.div`
  flex: 1;
  margin: 20px 0;
  text-align: left;
`;


const OptionContainer = styled.span`
  display: flex;
  margin-bottom: 12px;
  align-items: baseline;
`;


const RemoveOption = styled.main`
  cursor: pointer;
  margin-left: 20px;
  display: flex;
  align-items: center;
`;


const IconContainer = styled.span`
  margin-right: 10px; 
  cursor: pointer; 
`

const cardSource = {
  beginDrag(props) {
    return {
      id: props.id,
      index: props.index,
    };
  },
};



const cardTarget = {
  hover(props, monitor, component) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return;
    }

    // Determine rectangle on screen
    const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();

    // Get vertical middle
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

    // Determine mouse position
    const clientOffset = monitor.getClientOffset();

    // Get pixels to the top
    const hoverClientY = clientOffset.y - hoverBoundingRect.top;

    // Only perform the move when the mouse has crossed half of the items height
    // When dragging downwards, only move when the cursor is below 50%
    // When dragging upwards, only move when the cursor is above 50%

    // Dragging downwards
    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      return;
    }

    // Dragging upwards
    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      return;
    }

    // Time to actually perform the action
    props.moveCard(dragIndex, hoverIndex);

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    monitor.getItem().index = hoverIndex;
  },
};

@DropTarget('OPTION', cardTarget, connect => ({
  connectDropTarget: connect.dropTarget(),
}))
@DragSource('OPTION', cardSource, (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  isDragging: monitor.isDragging(),
}))
export default class StarRatingOptionFieldCard extends Component {
  static propTypes = {
    connectDragSource: PropTypes.func.isRequired,
    connectDropTarget: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    isDragging: PropTypes.bool.isRequired,
    id: PropTypes.any.isRequired,
    text: PropTypes.string.isRequired,
    moveCard: PropTypes.func.isRequired,
  };

  render() {
    const {
      isDragging,
      connectDragSource,
      connectDropTarget,
    } = this.props;

    const { control, index, item, errorMessage, setValue, fields, setFocusId } = this.props; 

    return connectDragSource(
      connectDropTarget(
        <div>
            <InputContainer>
                {!item._destroy && (
                  <OptionContainer>
                    <IconContainer>
                      <HamburgerMenuIcon width={18} height={12} />
                    </IconContainer>
                    <TextInput
                      name={`star_rating_question_options_attributes[${index}]`}
                      id={`star_rating_question_options_attributes[${index}]`}
                      aria-label="Star rating Options"
                      errorMsg={!!errorMessage && errorMessage.index === index ? errorMessage.message : ''}
                      label=""
                      openSans
                      required
                      defaultValue={item.value}
                      placeholder="Add option"
                      onChange={e => {
                        const foundItem = fields.find(f => f.optId === item.optId);
                        foundItem.value = e.target.value;
                        setValue(`star_rating_question_options_attributes`, fields, {
                          shouldValidate: true,
                          shouldDirty: true,
                          shouldTouch: true,
                        });
                        setFocusId(`star_rating_question_options_attributes[${index}]`);
                      }}
                    />

                    {fields.filter(f => !f._destroy).length < 4 ? null : (
                      <RemoveOption
                        onClick={() => {
                          const foundItem = fields.find(f => f.optId === item.optId);
                          foundItem._destroy = true;
                          setValue(`star_rating_question_options_attributes`, fields, {
                            shouldValidate: true,
                            shouldDirty: true,
                            shouldTouch: true,
                          });
                        }}
                        tabIndex={0}
                        onKeyDown={e => {
                          if (e.keyCode === 13) {
                            const foundItem = fields.find(f => f.optId === item.optId);
                            foundItem._destroy = true;
                            setValue(`star_rating_question_options_attributes`, fields, {
                              shouldValidate: true,
                              shouldDirty: true,
                              shouldTouch: true,
                            });
                          }
                        }}
                      >
                        <StyledIcon type="Close" color="#446372" size="24px" />
                      </RemoveOption>
                    )}
                  </OptionContainer>
                )}
              </InputContainer>
        </div>
      ),
    );
  }
}
