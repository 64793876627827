import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import 'moment-timezone';
import propTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import Button from '../../../../components/library/button';
import InputSelect from '../../../../components/library/inputSelect';
import sBatchAddFormsToTravelers from './batchAddFormsToTravelers.scss';
import OneClickModal from '../oneClickModal';
import { getForms } from '../../../../../actions/formsActions';
import GenericStyledModal from '../../../../forms/pages/admin/modals/GenericStyledModal.modal';
import moment from 'moment-timezone';

export const enhance = compose(withStyles(sBatchAddFormsToTravelers));

function BatchAddFormsToTravelers(props) {
  const dispatch = useDispatch();
  const batchAddFormsToTravelers = useSelector(state => state.reports.batchAddFormsToTravelers.data);
  const timezone = useSelector(state => state.profile.orgTimezone);
  const [selectedForms, setSelectedForms] = useState([]);
  const [submitValues, setSubmitValues] = useState();
  const [showAddForms, setShowAddForms] = useState(false);
  const [submitting, setSubmitting] = useState();
  const publishedForms = useSelector(state => state.forms.forms);
  const [formTemplates, setFormTemplates] = useState();
  const [years, setYear] = useState([]);
  const [days, setDays] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState([]);
  const [selectedDate, setSelectedDate] = useState([]);
  const [selectedYear, setSelectedYear] = useState([]);
  //To set year dropdown
  useEffect(() => {
    const date = new Date();
    let year = date.getFullYear();
    const yearArray = [];

    let pastYear = year - 5;
    let futureYear = parseInt(year) + 10;

    for (let i = pastYear; i <= futureYear; i++) {
      let yearObj = { label: i, value: i };
      yearArray.push(yearObj);
    }
    setYear(yearArray);
  }, []);

  useEffect(() => {
    let daysArray = [];
    let maxDay = 31;
    if (selectedMonth?.value == '02') {
      maxDay = 29;
    } else if (['04', '06', '09', '11'].includes(selectedMonth?.value)) {
      maxDay = 30;
    }

    for (let i = 1; i <= maxDay; i++) {
      let day = i <= 9 ? `0${i}` : i;
      let dayObj = { label: day, value: day };
      daysArray.push(dayObj);
    }
    setDays(daysArray);
  }, [selectedMonth]);

  const monthsArray = [
    { label: 'Jan', value: '01', ariaLabel: 'January' },
    { label: 'Feb', value: '02', ariaLabel: 'February' },
    { label: 'Mar', value: '03', ariaLabel: 'March' },
    { label: 'Apr', value: '04', ariaLabel: 'April' },
    { label: 'May', value: '05', ariaLabel: 'May' },
    { label: 'Jun', value: '06', ariaLabel: 'June' },
    { label: 'Jul', value: '07', ariaLabel: 'July' },
    { label: 'Aug', value: '08', ariaLabel: 'August' },
    { label: 'Sep', value: '09', ariaLabel: 'September' },
    { label: 'Oct', value: '10', ariaLabel: 'October' },
    { label: 'Nov', value: '11', ariaLabel: 'November' },
    { label: 'Dec', value: '12', ariaLabel: 'December' },
  ];

  const resetForm = () => {
    setDefaultValues(() => {
      let values = {
        template_ids: [],
        submission_ids: [],
      };
      return values;
    });
  };

  const [defaultValues, setDefaultValues] = useState(() => {
    let values = {
      template_ids: [],
      submission_ids: [],
    };
    return values;
  });

  useEffect(() => {
    dispatch(getForms('General Form', 1));
  }, []);

  useEffect(() => {
    const forms = publishedForms?.data?.map(template => ({
      value: template.id,
      label: template.name,
    }));

    setFormTemplates(forms);
  }, [publishedForms]);

  return (
    <>
      {/* <ViaModal
        showing={props.show}
        onClose={() => {
          props.onClose(false);
        }}
        headerText="Add General Forms"
      > */}
      <GenericStyledModal
        header="Add General Forms"
        showing={props.show}
        width="720px"
        paddingBottom="0px"
        onClose={() => {
          props.onClose(false);
        }}
      >
        <div className={sBatchAddFormsToTravelers['batch-add-forms-modal']}>
          <div className={sBatchAddFormsToTravelers['paragraph']}>
            You have selected {`${props.selections.length}`} {`${props.customAliases.alias_traveler.toLowerCase()}`}(s)
            to add general form(s) to. If a {`${props.customAliases.alias_traveler.toLowerCase()}`} already has a form,
            a duplicate form will not be sent.
          </div>
          <Formik
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={defaultValues}
            validate={(values, index) => {
              const errors = {};
              if (values.template_ids.length < 1) {
                errors.template_ids = 'Error: General form is required';
              }
              if (!values?.deadline_month?.value || !values?.deadline_date?.value || !values?.deadline_year?.value) {
                if (values?.deadline_month?.value || values?.deadline_date?.value || values?.deadline_year?.value) {
                  errors.deadline_month = 'Deadline must have a month, day, and year';
                }
              }
              return errors;
            }}
            onSubmit={values => {
              let deadlineDate = '';
              if (values?.deadline_month?.value && values?.deadline_date?.value && values?.deadline_year?.value) {
                deadlineDate =
                  values?.deadline_year.value + '-' + values?.deadline_month.value + '-' + values?.deadline_date.value;

                deadlineDate = moment
                  .tz(deadlineDate, timezone)
                  .tz('UTC')
                  .format();
              }

              const submitValues = {
                template_ids: values.template_ids.map(form => form.value),
                submission_ids: props.applicationIds,
                deadline: deadlineDate,
              };

              setSubmitValues(submitValues);
              props.onClose(false);
              setShowAddForms(true);
            }}
          >
            {({ values, handleSubmit, errors }) => (
              <Form>
                <div className={sBatchAddFormsToTravelers.row}>
                  <div className={sBatchAddFormsToTravelers.item}>
                    <Field name="template_ids">
                      {({ form }) => (
                        <InputSelect
                          id="template_ids"
                          name="template_ids"
                          labelText="General Form Name"
                          aria-label="General Form Name"
                          //errorMessage={errors.template_ids}
                          isRequired
                          helpText=""
                          isMulti
                          value={values.template_ids}
                          options={formTemplates}
                          // placeholder={values.template_ids ? values.template_ids.label : 'Please select form or forms'}
                          placeholder={'Select forms'}
                          placeholderFontStyle={'italic'}
                          placeholderColor={'#666'}
                          placeholderFontfamily={'Open Sans'}
                          onChange={e => {
                            form.setFieldValue('template_ids', e);
                            setSelectedForms(e);
                          }}
                        />
                      )}
                    </Field>
                  </div>
                </div>
                {errors.template_ids && (
                  <div className={sBatchAddFormsToTravelers.errors}>
                    <span className={sBatchAddFormsToTravelers['error-select']} aria-live="assertive">
                      {errors.template_ids}
                    </span>
                  </div>
                )}
                <div className={sBatchAddFormsToTravelers.row} style={{ paddingBottom: '0px' }}>
                  <div className={sBatchAddFormsToTravelers.item}>
                    <Field name="deadline_month">
                      {({ form }) => (
                        <InputSelect
                          id="deadline_month"
                          name="deadline_month"
                          labelText="Month"
                          aria-label="Month"
                          //errorMessage={errors.deadline_month}
                          //helpText=""
                          options={monthsArray}
                          placeholder={'Please select month'}
                          placeholderFontStyle={'italic'}
                          placeholderColor={'#666'}
                          placeholderFontfamily={'Open Sans'}
                          onChange={e => {
                            form.setFieldValue('deadline_month', e);
                            setSelectedMonth(e);
                          }}
                        />
                      )}
                    </Field>
                  </div>
                  <div className={sBatchAddFormsToTravelers.item}>
                    <Field name="deadline_date">
                      {({ form }) => (
                        <InputSelect
                          id="deadline_date"
                          name="deadline_date"
                          labelText="Day"
                          aria-label="Day"
                          errorMessage={errors.deadline_date}
                          //isRequired
                          helpText=""
                          options={days}
                          placeholder={'Please select day'}
                          placeholderFontStyle={'italic'}
                          placeholderColor={'#666'}
                          placeholderFontfamily={'Open Sans'}
                          onChange={e => {
                            form.setFieldValue('deadline_date', e);
                            setSelectedDate(e);
                          }}
                        />
                      )}
                    </Field>
                  </div>
                  <div className={sBatchAddFormsToTravelers.item}>
                    <Field name="deadline_year">
                      {({ form }) => (
                        <InputSelect
                          id="deadline_year"
                          name="deadline_year"
                          labelText="Year"
                          aria-label="Year"
                          errorMessage={errors.deadline_year}
                          //isRequired
                          helpText=""
                          options={years}
                          placeholder={'Please select Year'}
                          placeholderFontStyle={'italic'}
                          placeholderColor={'#666'}
                          placeholderFontfamily={'Open Sans'}
                          onChange={e => {
                            form.setFieldValue('deadline_year', e);
                            setSelectedYear(e);
                          }}
                        />
                      )}
                    </Field>
                  </div>
                </div>
                {errors.deadline_month && (
                  <div className={sBatchAddFormsToTravelers.errors}>
                    <span className={sBatchAddFormsToTravelers['error-select']} aria-live="assertive">
                      {errors.deadline_month}
                    </span>
                  </div>
                )}

                <div className={sBatchAddFormsToTravelers.footer}>
                  <div className={sBatchAddFormsToTravelers['button-content']}>
                    <Button
                      display="secondary"
                      kind="solid"
                      size="medium"
                      aria-label="Cancel Button"
                      onClick={() => {
                        props.onClose(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                  <div className={sBatchAddFormsToTravelers['button-content']} onClick={setSubmitting(true)}>
                    <Button
                      display="primary"
                      kind="solid"
                      size="medium"
                      aria-label="Add General Forms Button"
                      type="submit"
                      onClick={values => {
                        handleSubmit(values);
                      }}
                    >
                      Add
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </GenericStyledModal>
      {/* </ViaModal> */}
      <OneClickModal
        headline="Add General Forms"
        show={showAddForms}
        onClose={() => {
          resetForm();
          setShowAddForms(false);
          props.resetSelections();
        }}
        paragraphOne={`You have selected ${props.selections.length} ${
          props.selections.length === 1
            ? `${props.customAliases.alias_traveler.toLowerCase()}`
            : `${props.customAliases.alias_travelers.toLowerCase()}`
        } to send ${selectedForms.length} ${
          selectedForms.length === 1 ? 'form' : 'forms'
        } to. If a ${props.customAliases.alias_traveler.toLowerCase()} already has a form, a duplicate form will not be sent.`}
        paragraphTwo="Are you sure you want to continue?"
        cancelMessage="Cancel"
        confirmActionMessage="Add General Forms"
        source="batch_add_form_to_travelers"
        // resetForm={reset => setDefaultValues(reset)}
        actionState={batchAddFormsToTravelers}
        customAliases={props.customAliases}
        resetForm={() => resetForm()}
        submitValues={submitValues}
        dayOffset={props.dayOffset}
        fetchApps={props.fetchApps}
      />
    </>
  );
}

BatchAddFormsToTravelers.propTypes = {
  show: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
};

BatchAddFormsToTravelers.defaultProps = {
  fetchApps: true,
};

export default enhance(BatchAddFormsToTravelers);
