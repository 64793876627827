exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2YIzr{padding:20px;display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center}.gUkTM{height:100px;max-width:unset}._1E97M{-ms-flex-pack:center;justify-content:center;padding:20px}._1E97M,.C2xLb{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center}.C2xLb{-ms-flex-pack:justify;justify-content:space-between;width:100%;padding:5px}._3tAPS{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row}._3KiIb{font-weight:400}._2TlmE,._3KiIb{color:#373737;font-family:Open Sans;font-size:16px;font-style:normal;line-height:140%;letter-spacing:.08px}._2TlmE{font-weight:700;margin-top:15px}._2W1qk{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-pack:center;justify-content:center;gap:40px;background:#ebebeb;padding:30px;border-bottom-left-radius:4.8px;border-bottom-left-radius:.3rem;border-bottom-right-radius:4.8px;border-bottom-right-radius:.3rem}._2_fTI{padding:0}", ""]);

// exports
exports.locals = {
	"container": "_2YIzr",
	"img": "gUkTM",
	"imageContainer": "_1E97M",
	"title": "C2xLb",
	"modalBodycontainer": "_3tAPS",
	"text": "_3KiIb",
	"textBold": "_2TlmE",
	"footer": "_2W1qk",
	"modalBody": "_2_fTI"
};