import React from 'react';
import styled from 'styled-components';

import ProgramTypePreferences from './ProgramTypePreferences';
import ProgramTimingPreferences from './ProgramTimingPreferences';
import ProgramHousingPreferences from './ProgramHousingPreferences';
import ProgramLanguagePreferences from './ProgramLanguagePreferences';
import ProgramLocationsPreferences from './ProgramLocationsPreferences';
import ProgramSubjectAreasPreferences from './ProgramSubjectAreasPreferences';
import ProgramParticipationPreferences from './ProgramParticipationPreferences';

import { breakpoints } from '../../utils/breakpoints';

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  row-gap: 1rem;

  @media ${breakpoints.tablet} {
    grid-template-columns: repeat(6, minmax(0, 1fr));
    column-gap: 5rem;

    div:last-child:nth-child(3n - 2) {
      grid-column-end: 5;
    }
  }

  strong {
    font-weight: 600;
  }
`;

export default function ProgramMatchPreferences() {
  return (
    <Container tabIndex={0}>
      <ProgramTypePreferences />
      <ProgramLocationsPreferences />
      <ProgramSubjectAreasPreferences />
      <ProgramTimingPreferences />
      <ProgramLanguagePreferences />
      <ProgramHousingPreferences />
      <ProgramParticipationPreferences />
    </Container>
  );
}
