exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2_vY4{margin-top:18px}._3dpgR{background-image:url(\"/img/sample_bg.jpg\");display:-ms-flexbox;min-height:100vh;height:100%;background-size:contain;display:flex;-ms-flex-pack:center;justify-content:center}._3fLNO{text-align:center;margin-top:40px;width:40%}._3TknE{font-size:77px;font-style:italic;font-weight:700;margin-bottom:30px}._2R2fk{font-size:18px;margin-bottom:45px}._3LTgR{margin-top:20px}._2R2fk a{cursor:pointer}@media only screen and (max-width:767px){._3dpgR{display:block;background-position-y:12rem;background-repeat:no-repeat}}", ""]);

// exports
exports.locals = {
	"headerPrimary": "_2_vY4",
	"backgroundImg": "_3dpgR",
	"contentDiv": "_3fLNO",
	"titleOfError": "_3TknE",
	"messageOfError": "_2R2fk",
	"mT20": "_3LTgR"
};