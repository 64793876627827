import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import propTypes from 'prop-types';
import InputSelect from '../../../library/inputSelect';
import { buildSelectOptions } from '../../../../../shared/helpers/General';

export const enhance = compose();

function FilterMultiSelect(props) {
  const [filterOptions, setFilterOptions] = useState(props.sourceData);
  const [defaultValues, setDefaultValues] = useState(props.selectedValues);

  const selectedValues = (values) => {
    let filterValues = [];
    if (values && values.length > 0) {
      values.forEach((item) => {
        filterValues.push(item.value);
      });
    }

    props.change(filterValues);
  }

  useEffect(() => {
    if (props.sourceData) {
      setFilterOptions(props.sourceData);
    }

    if (props.selectedValues) {
      const buildDefaultValues = buildSelectOptions(props.selectedValues, true, true);
      setDefaultValues(buildDefaultValues);
    }

  }, [props]);


  const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: 14,
    borderBottom: '1px solid #ebebeb',
  };
  const groupBadgeStyles = {
    backgroundColor: '#ebebeb',
    borderRadius: '2em',
    color: '#043544',
    display: !props.showGroupCounts ? 'none' : 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.2em 0.5em',
    textAlign: 'center',
  };

  const formatGroupLabel = data => (
    <div style={groupStyles}>
      <span>{data.label}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
  );

  return (
    <InputSelect
      isMulti
      id={props.id}
      labelText={props.labelText}
      styles={{
        menu: provided => ({...provided, zIndex: 99999})
      }}
      options={filterOptions}
      onChange={(e) => selectedValues(e)}
      formatGroupLabel={formatGroupLabel}
      defaultValue={defaultValues}
      value={defaultValues}
    />
  );
}

FilterMultiSelect.propTypes = {
  id: propTypes.string.isRequired,
  change: propTypes.func.isRequired,
  selectedValues: propTypes.array,
  sourceData: propTypes.array.isRequired,
  showGroupCounts: propTypes.bool,
};

FilterMultiSelect.defaultProps = {
  labelText: 'Filter Options',
  selectedValues: [],
  showGroupCounts: false,
};

export default enhance(FilterMultiSelect);
