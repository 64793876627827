import React, { lazy, Suspense, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import * as yup from 'yup';
import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import { Controller, useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { Paragraph } from '../../../../../styledComponents/styles/Paragraph.styled';
import ImageViewer from '../../../../../components/ImageViewer';
import ReactPDF from '../../../../../components/ReactPDF';
import StyledIcon from '../../../../../styledComponents/styles/Icon.styled';
import TextInput from '../../../../../styledComponents/styles/TextInput.styled';
import Tooltip from '../../../../../styledComponents/styles/Tooltip.styled';
import { fileType, maxFileSize } from '../../../../../shared/helpers/UploaderMessages';
import { AccentButtonMediumNunito, ButtonLargeOpenSans } from '../../../../../styledComponents/styles/Buttons.styled';
import { AccentButtonSpinner } from '../../../../../styledComponents/styles/spinner.styled';
import { updateForm, updateQuestion } from '../../../../../actions/formsActions';
import { Col, Row } from '../../../../../styledComponents/styles/FlexGrid.styled';
import { Table, TableBody, TableRow, TableColumn } from '../../../../../styledComponents/styles/Table.styled';
import { ErrorXIcon } from '../../../../../styledComponents/styles/IconCustom.styled';

const EditTextArea = lazy(() => import('../../../../../styledComponents/styles/EditTextArea.styled'));

const openSansFont = css`
  font-family: ${({ theme }) => theme.textInput.fontFamilyOpenSans};
`;

const ErrorMsg = styled.div`
  background-color: #ffcfcb;
  border-left: 20px solid #ee9999;
  border-radius: 5px;
  color: #4d4d4d;
  display: flex;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.005em;
  line-height: 120%;
  margin-top: 17px;
  padding: 20px;
`;

const ErrorTextContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 22px;
`;

const ErrorTile = styled.div`
  font-weight: 600;
`;

const ErrorText = styled.div`
  font-weight: 400;
`;

const FormContainer = styled.div`
  font-family: 'Nunito', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 0.015em;
  color: #373737;
`;

const InfoContainer = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  letter-spacing: 0.06em;
  text-decoration: underline;
  color: #255a94;
  flex: 1;
  margin: 20px 0;
  text-align: left;
`;

const InputContainer = styled.div`
  flex: 1;
  margin: 20px 0;
  text-align: left;
`;

const SubmitContainer = styled.main`
  display: flex;
  justify-content: flex-end;
  margin-top: 35px;
`;

const Underline = styled.span`
  text-decoration: underline;
`;

const FileContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const FileItem = styled.div`
  color: #043544;
  font-family: Open Sans, sans-serif;
  font-size: 16px;
  font-weight: 400;
  overflow-wrap: anywhere;
`;

const ImageNameSpanUnlink = styled.span`
  padding-left: 0px;
`;

const UploadLabel = styled.label`
  cursor: pointer;
  display: inline-block;
  margin-bottom: 0;
`;

const AttachmentTable = styled(Table)`
  box-sizing: border-box;
  width: 100%;
  border: 1px solid grey;

  th:first-child,
  tr:first-child {
    width: 70%;
  }

  td:first-child {
    width: 70%;
  }

  tr:nth-of-type(even) {
    background-color: #ebebeb;
  }

  tr:nth-of-type(odd) {
    background-color: #ffffff;
  }
`;

const RowStyle = styled(Row)`
  padding-bottom: 8px;
`;

const AttachHeaderText = styled(Paragraph)`
  color: #043544;
  font-family: Open Sans, sans-serif;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0;
`;

const DeleteDiv = styled.div`
  cursor: pointer;
  color: #a82425;

  ${props => {
    if (props.disabled) {
      return `
        cursor: not-allowed;
      `;
    }
  }};
`;

const DeleteButton = styled.button`
  cursor: pointer;
  color: #a82425;
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  outline: inherit;

  ${props => {
    if (props.disabled) {
      return `
        cursor: not-allowed;
      `;
    }
  }};
`;

const Error = styled.div`
  color: ${({ theme }) => theme.textInput.error.fontColor};
  display: inline-block;
  font-family: ${({ theme }) => theme.textInput.error.fontFamily};
  font-size: ${({ theme }) => theme.textInput.error.fontSize};
  font-weight: ${({ theme }) => theme.textInput.error.fontWeight};
  padding-top: 3px;
  vertical-align: middle;
  ${({ openSans }) => (openSans ? openSansFont : null)} svg {
    justify-content: center;
    margin-right: 5px;

    vertical-align: middle;
    display: inline-block;
  }

  span {
    vertical-align: middle;
    display: inline-block;
  }
`;

export default function QuestionFileDownloadAddEdit({ question, edit, onClose }) {
  const MAX_LENGTH = 90;
  const qIdentifier = 'file_download';
  const dispatch = useDispatch();
  const fetchForm = useSelector(state => state.forms.fetchForm);
  const questionTypesInfo = useSelector(state => state.forms.questionTypes);
  const updateFormSubmit = useSelector(state => state.forms.updateForm);
  const updateQuestionSubmit = useSelector(state => state.forms.updateQuestion);
  const [formId, setFormId] = useState(null);
  const [attachments, setAttachments] = useState([]);
  const [errorMsg, setErrorMsg] = useState('');
  const [showPreview, setShowPreview] = useState(false);
  const [showPDFPreview, setShowPDFPreview] = useState(false);
  const [previewFile, setPreviewFile] = useState('');
  const [timeout, setButtonTimeout] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);

  function isValidFiles(message) {
    return this.test('isValidFiles', message, () => {
      const { path, createError } = this;
      const realFiles = attachments.filter(file => !file._destroy);

      if (realFiles && realFiles.length > 0) {
        return true;
      } else {
        return createError({ path, message: '* At least one uploaded file is required' });
      }
    });
  }

  yup.addMethod(yup.array, 'isValidFiles', isValidFiles);

  const schema = yup.object().shape({
    file_download_label: yup
      .string()
      .required('Question must have label')
      .max(MAX_LENGTH, `Label can not be more than ${MAX_LENGTH} characters`),
    file_download_instructions: yup.string(),
    file_download_admin_only: yup.boolean(),
    file_download_uploaded_files_attributes: yup.array().isValidFiles(),
  });

  const { control, handleSubmit, formState, setValue } = useForm({
    shouldUnregister: false,
    resolver: yupResolver(schema),
    defaultValues: {
      file_download_label: edit ? question.label : '',
      file_download_instructions: edit ? question.instructions || '' : '',
      file_download_admin_only: false,
      file_download_uploaded_files_attributes: edit ? question.uploaded_files_attributes : [],
    },
    mode: 'onChange',
  });

  useEffect(() => {
    let savedFiles = [];

    if (question && question.uploaded_files) {
      question.uploaded_files.forEach(file => {
        savedFiles.push({
          id: file.id,
          file: file.url,
          title: file.filename,
          content_type: file.content_type,
        });
      });
    }

    setAttachments(savedFiles);
  }, []);

  useEffect(
    () => {
      if (!fetchForm?.loading && fetchForm?.data) {
        setDisableSubmit(false);
        setFormId(fetchForm.data.id);
        setButtonTimeout(false);
      }
    },
    [fetchForm],
  );

  useEffect(
    () => {
      if (
        (!updateFormSubmit?.loading && updateFormSubmit?.data) ||
        (!updateQuestionSubmit?.loading && updateQuestionSubmit?.data)
      ) {
        if (updateQuestionSubmit?.data?.attributes.uploaded_files) {
          let newAttachments = updateQuestionSubmit.data.attributes.uploaded_files.map((file, index) => {
            return {
              title: file.filename,
              //content_type:,
              id: file.id,
              file: file.s3_store.url,
            };
          });
          setAttachments(newAttachments);
          setValue('file_download_uploaded_files_attributes', newAttachments, {
            shouldValidate: true,
            shouldDirty: true,
            shouldTouch: true,
          });
        }
        setDisableSubmit(false);
        onClose();
      }
    },
    [updateFormSubmit, updateQuestionSubmit],
  );

  const onSubmitHandler = data => {
    setButtonTimeout(true);

    if (questionTypesInfo?.data) {
      const questionType = questionTypesInfo?.data?.reduce((prev, curr) => {
        return prev || curr.attributes.question_types.find(question => question.identifier === qIdentifier);
      }, undefined);

      let saveFiles = [];
      attachments.forEach(file => {
        if (!file.id && !file._destroy) {
          //New file, not deleted, so save it
          saveFiles.push({
            id: file.id,
            filename: file.title,
            s3_store: file.file,
          });
        } else if (file.id && file._destroy) {
          saveFiles.push({
            id: file.id,
            _destroy: file._destroy,
          });
        } else {
          //This might be readding
          saveFiles.push(file);
        }
      });

      const values = {
        admin_only: false,
        instructions: data.file_download_instructions,
        label: data.file_download_label,
        uploaded_files_attributes: saveFiles,
      };

      if (saveFiles.length === 0) {
        setErrorMsg('You must speficy at least one file to download');
      } else if (formId && data && (questionType?.id || question?.id)) {
        //update or save new
        const actionToCall = edit ? updateQuestion : updateForm;
        const idToUpdate = edit ? question.id : questionType.id;
        setDisableSubmit(true);
        dispatch(actionToCall(formId, questionType.endpoint, idToUpdate, values));
      }
    }
  };

  return (
    <FormContainer>
      <form id="fileDownloadAdd" onSubmit={handleSubmit(onSubmitHandler)}>
        <Controller
          control={control}
          name="file_download_label"
          render={({ field: { ref, ...rest } }) => (
            <InputContainer>
              <TextInput
                aria-label="File Download Label"
                errorMsg={formState?.errors?.file_download_label?.message}
                id="file_download_label"
                label="File Download Label"
                openSans
                placeholder="Enter Label"
                required
                {...rest}
              />
            </InputContainer>
          )}
        />
        <Controller
          control={control}
          name="file_download_instructions"
          render={({ field: { ref, ...rest } }) => (
            <InputContainer>
              <Suspense fallback={<div />}>
                <EditTextArea
                  aria-label="Instructions"
                  errorMsg={formState?.errors?.file_download_instructions?.message}
                  placeholder="Enter Instructions"
                  label="Instructions"
                  id="file_download_instructions"
                  {...rest}
                />
              </Suspense>
            </InputContainer>
          )}
        />
        <InfoContainer>
          <Tooltip toolTipText="Compatible File Types: .csv, .doc, .docx, .gif, .jpeg, .jpg, .mov, .mp3, .mp4, .mpeg, .mpg, .msg, .pdf, .png, .ppt, .pptx, .rtf, .svg, .txt, .xls, .xlsx, .wmv, .zip">
            <StyledIcon type="InformationCircle" size="14px" />
            <Underline>Compatible File Types</Underline>
          </Tooltip>
        </InfoContainer>
        {errorMsg &&
          errorMsg.length > 0 && (
            <ErrorMsg>
              <ErrorXIcon size="40px" />
              <ErrorTextContent>
                <ErrorTile>Error Uploading</ErrorTile>
                <ErrorText>{errorMsg}</ErrorText>
              </ErrorTextContent>
            </ErrorMsg>
          )}
        <InputContainer>
          <Controller
            control={control}
            name="file_download_uploaded_files_attributes"
            render={({ field: { ref, ...rest } }) => (
              <>
                <ButtonLargeOpenSans
                  onClick={() => {
                    document.getElementById(`file_download_uploaded_files_attributes`).click();
                  }}
                >
                  <UploadLabel hmtlfor="file_download_uploaded_files_attributes">
                    <StyledIcon type="Add" size="18px" />
                    {'  Upload File'}
                  </UploadLabel>
                </ButtonLargeOpenSans>
                <Controller
                  control={control}
                  name="file_download_uploaded_files_attributes"
                  render={({ field: { onChange, register, ref, ...rest } }) => (
                    <>
                      <input
                        type="file"
                        id="file_download_uploaded_files_attributes"
                        style={{ display: 'none' }}
                        onChange={file => {
                          const reader = new FileReader();
                          const uploadedFile = file.target.files[0];
                          if (uploadedFile) {
                            reader.onload = () => {
                              const fileAsBinaryString = reader.result;
                              const buildValue = {
                                id: '',
                                file: fileAsBinaryString,
                                title: uploadedFile.name,
                              };

                              const extension = uploadedFile.name
                                .split('.')
                                .pop()
                                .toLowerCase();
                              const fileSize = uploadedFile.size;
                              const isGoodExtension = fileType.indexOf(extension) > -1;
                              const isGoodSize = fileSize <= maxFileSize;

                              if (!isGoodExtension) {
                                setErrorMsg('Incompatible file type');
                              }
                              if (!isGoodSize) {
                                setErrorMsg('File is too large, max is 10MB');
                              }

                              if (isGoodExtension && isGoodSize) {
                                setErrorMsg('');
                                attachments.push(buildValue);
                                setAttachments([...attachments]);
                                setValue('file_download_uploaded_files_attributes', attachments, {
                                  shouldValidate: true,
                                  shouldDirty: true,
                                  shouldTouch: true,
                                });
                              }
                            };
                            reader.onabort = () => setErrorMsg('File uploading was aborted');
                            reader.onerror = () => setErrorMsg('File is corrupted, try again');
                            reader.readAsDataURL(uploadedFile);
                          }
                        }}
                      />
                    </>
                  )}
                />
              </>
            )}
          />
          <div>
            <Error>{formState?.errors?.file_download_uploaded_files_attributes?.message}</Error>
          </div>
          <InputContainer>
            <Col size={4}>
              <RowStyle>
                <AttachHeaderText>Uploaded Files</AttachHeaderText>
              </RowStyle>
              <RowStyle>
                <FileContainer>
                  <AttachmentTable>
                    <TableBody>
                      {attachments.length > 0 &&
                        attachments.filter(item => !item._destroy).map((file, index) => (
                          <TableRow key={`${file.id}-${file.title}-${index}`}>
                            <TableColumn>
                              <FileItem>
                                <ImageNameSpanUnlink>{file.title}</ImageNameSpanUnlink>
                              </FileItem>
                            </TableColumn>
                            <TableColumn />

                            <TableColumn>
                              <DeleteDiv>
                                <DeleteButton
                                  onClick={() => {
                                    const values = _cloneDeep(attachments);
                                    const updateValue = values.find(v => v.id === file.id);
                                    updateValue._destroy = true;
                                    setAttachments(values);
                                  }}
                                  disabled={
                                    attachments.length === 1 || attachments.filter(item => !item._destroy).length === 1
                                  }
                                >
                                  <StyledIcon
                                    type="Trash"
                                    size="18px"
                                    color={
                                      attachments.length === 1 ||
                                      attachments.filter(item => !item._destroy).length === 1
                                        ? '#696969'
                                        : 'inherit'
                                    }
                                  />
                                </DeleteButton>
                              </DeleteDiv>
                            </TableColumn>
                          </TableRow>
                        ))}
                    </TableBody>
                  </AttachmentTable>
                </FileContainer>
              </RowStyle>
            </Col>
          </InputContainer>
        </InputContainer>
        <SubmitContainer>
          <AccentButtonMediumNunito
            aria-label={edit ? 'Save File Download' : 'Add File Download to Form'}
            form="fileDownloadAdd"
            type="submit"
            disabled={timeout || disableSubmit}
          >
            {disableSubmit ? <AccentButtonSpinner displayText="Saving ..." /> : edit ? 'Save' : 'Add to Form'}
            {!disableSubmit && <StyledIcon type="ChevronForward" color="#fff" />}
          </AccentButtonMediumNunito>
        </SubmitContainer>
      </form>
      {showPreview && <ImageViewer file={previewFile} handleClose={() => setShowPreview(false)} show={showPreview} />}
      {showPDFPreview && (
        <ReactPDF file={previewFile} width="300px" show={showPDFPreview} handleClose={() => setShowPDFPreview(false)} />
      )}
    </FormContainer>
  );
}
