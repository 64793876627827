import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { PropTypes } from 'prop-types';

import s from './Modal.css';
import CloseButton from '../CloseButton';

const Modal = props => {
  const backdrops = [...document.getElementsByClassName('modal-backdrop')];

  // if (!props.showModal) {
  //   backdrops.map(backdrop => backdrop.remove());
  //   document.body.classList.remove('modal-open');
  //   document.body.style = '';
  //   if (document.getElementById(props.id))
  //     document.getElementById(props.id).style = '';
  // } else {
  //   if (backdrops.length === 0) {
  //     let field = document.createElement('div');
  //     field.classList.add('modal-backdrop', 'fade', 'show');
  //     document.body.appendChild(field);
  //     setTimeout(() => {
  //       document.body.classList.add('modal-open');
  //     }, 500);
  //   }
  // }

  return (
    <div
      className={`${s.modal} ${s.fade} ${props.showModal ? s.show : null}`}
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      style={props.showModal ? { display: 'block' } : null}
    >
      <div className={s.modalDialog} role="document">
        <div className={s.modalContent}>
          <div className={s.modalBody}>
            <div className={s.modalHeader}>
              <p className={s.modalHeaderTitle}>{props.title}</p>
              <CloseButton clickHandler={props.closeModal} />
            </div>
            {props.infoText && (
              <p className={s.modalInfoText}>{props.infoText}</p>
            )}
            {props.children}
          </div>
        </div>
      </div>
    </div>
  );
};

Modal.propTypes = {
  title: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
};

Modal.defaultProps = {
  showModal: false,
};

export default withStyles(s)(Modal);
