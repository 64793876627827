import React, { useState, useEffect } from 'react';
import styles from './index.css';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { Cookies } from 'react-cookie';
import moment from 'moment-timezone';

const CountDownTimer = ({ countDownSeconds = 60, handleClose }) => {
  // Initialize state to store the remaining time in seconds
  const [timeLeft, setTimeLeft] = useState(60);

  useEffect(() => {
    setTimeLeft(countDownSeconds);
  }, [countDownSeconds]);

  useEffect(() => {
    // Exit early if the countdown has reached zero
    const differenceInSeconds = getSecondsDifference();
    if (timeLeft === 0) {
      // first check if any token renews or expiration time increased
      if (differenceInSeconds > 60) {
        // window.location.reload();
        handleClose();
      } else {
        localStorage.clear();
        localStorage.setItem('is_session_expired', '1');
        window.location.assign(`/clear_cookies`);
      }
      return;
    } else {
      if (differenceInSeconds > 60) {
        // window.location.reload();
        handleClose();
        return;
      }
    }

    // Set up a timer that runs every second
    const timerId = setInterval(() => {
      setTimeLeft(prevTime => prevTime - 1);
    }, 1000);

    // Cleanup function to clear the interval when the component unmounts or timeLeft changes
    return () => clearInterval(timerId);
  }, [timeLeft]);

  // Convert the remaining time into minutes and seconds format (MM:SS)
  const formatTime = () => {
    const minutes = Math.floor(timeLeft / 60);
    const seconds = timeLeft % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  const getSecondsDifference = () => {
    const cookies = new Cookies();
    const expirationTime = moment(cookies.get('expires_at'));
    const currentTime = moment();

    // Calculate the difference in seconds
    const differenceInSeconds = expirationTime.diff(currentTime, 'seconds');
    return differenceInSeconds;
  };

  return <div className={styles.clock}>{formatTime()}</div>;
};

export default withStyles(styles)(CountDownTimer);
