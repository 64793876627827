import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './ProgramAmmenities.css';
import Auxiliary from '../../../../hoc/Auxiliary';
import _uniq from 'lodash/uniq';
import _some from 'lodash/some';

class ProgramAmmenities extends React.Component {
  render() {
    if (this.props.role == 'Admin') {
      if (this.props.status == 'view') {
        const ammenities = _uniq(this.props.idAmmenities).map((am, index) => (
          <div className={s.amenityBlock} key={index}>
            <img className={s.amenityIcon} src={`${require(`./img/${am.amenity_id}.png`)}`} />
            <p key={index} className={this.props.included.includes(am.name) ? s.ammenity : s.notAmmenity}>
              {am.name}
            </p>
          </div>
        ));
        return this.props.ammenities.length > 0 ? (
          <div className={s.wrapper}>
            <div className={s.section}>
              <h2 className={s.header}>What&apos;s Included?</h2>
              <span className={s.contactHeader}>
                What is included or not included may vary by term. Contact your {this.props.aliasProgram.toLowerCase()}{' '}
                provider to confirm.
              </span>
              <div className={s.amenitiesBlock}>{ammenities}</div>
            </div>
          </div>
        ) : (
          ''
        );
      } else if (this.props.status == 'new') {
        const amenitiesDisplayed = _uniq(this.props.amenitiesDisplayed).map((sam, index) => (
          <div className={s.amenityBlock} key={index}>
            <img className={s.amenityIcon} src={`${require(`./img/${sam.amenity_id}.png`)}`} />
            <p
              className={_some(this.props.amenitiesExcluded, sam) ? s.notAmmenityNew : s.ammenityNew}
              onClick={e => this.props.toggleAmenity(e, sam)}
            >
              {sam.name}
            </p>
            <div className={s.trashContainer}>
              <svg
                onClick={() => this.props.markAsNotDisplayed(sam)}
                className={s.trash}
                width="16px"
                height="20px"
                viewBox="0 0 16 20"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="Admin-View-of-Settings" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <g
                    id="Admin-View-of-Settings:-Users"
                    transform="translate(-1390.000000, -763.000000)"
                    fill="#9B9B9B"
                    fillRule="nonzero"
                  >
                    <g id="Today" transform="translate(388.000000, 526.000000)">
                      <g id="JH" transform="translate(0.000000, 217.000000)">
                        <path
                          d="M1008.25,20 C1007.57708,20 1007,20.5358647 1007,21.2499992 L1007,21.9999988 L1003,21.9999988 C1002.45465,21.9999988 1002,22.4546435 1002,22.9999981 L1002,25.4999966 C1002,26.0453512 1002.45465,26.4999959 1003,26.4999959 L1003.5,26.4999959 L1003.5,39.0000006 C1003.5,39.5453253 1003.95465,40 1004.5,40 L1015.5,40 C1016.04535,40 1016.5,39.5453503 1016.5,39.0000006 L1016.5,26.4999959 L1017,26.4999959 C1017.54535,26.4999959 1018,26.0453512 1018,25.4999966 L1018,22.9999981 C1018,22.4546435 1017.54535,21.9999988 1017,21.9999988 L1013,21.9999988 L1013,21.2499992 C1013,20.5358447 1012.4229,20 1011.75,20 L1008.25,20 Z M1008.25,20.9999994 L1011.75,20.9999994 C1011.83255,20.9999994 1012,21.1331543 1012,21.2499992 L1012,21.9999988 L1008,21.9999988 L1008,21.2499992 C1008,21.1331343 1008.16742,20.9999994 1008.25,20.9999994 Z M1003,22.9999981 L1017,22.9999981 L1017,25.4999966 L1003,25.4999966 L1003,22.9999981 Z M1004.5,26.4999959 L1015.5,26.4999959 L1015.5,39.0000006 L1004.5,39.0000006 L1004.5,26.4999959 Z M1007,28.4999947 C1006.72385,28.4999947 1006.5,28.7238445 1006.5,28.9999944 L1006.5,36.5000022 C1006.5,36.776152 1006.72385,37.0000019 1007,37.0000019 C1007.27615,37.0000019 1007.5,36.776152 1007.5,36.5000022 L1007.5,28.9999944 C1007.5,28.7238445 1007.27615,28.4999947 1007,28.4999947 Z M1010,28.4999947 C1009.72385,28.4999947 1009.5,28.7238445 1009.5,28.9999944 L1009.5,36.5000022 C1009.5,36.776152 1009.72385,37.0000019 1010,37.0000019 C1010.27615,37.0000019 1010.5,36.776152 1010.5,36.5000022 L1010.5,28.9999944 C1010.5,28.7238445 1010.27615,28.4999947 1010,28.4999947 Z M1013,28.4999947 C1012.72385,28.4999947 1012.5,28.7238445 1012.5,28.9999944 L1012.5,36.5000022 C1012.5,36.776152 1012.72385,37.0000019 1013,37.0000019 C1013.27615,37.0000019 1013.5,36.776152 1013.5,36.5000022 L1013.5,28.9999944 C1013.5,28.7238445 1013.27615,28.4999947 1013,28.4999947 Z"
                          id="trash"
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </div>
          </div>
        ));

        const amenitiesNotDisplayed = _uniq(this.props.amenitiesNotDisplayed).map((amenity, index) => (
          <div className={s.amenityBlock} key={index}>
            <img className={s.amenityIcon} src={`${require(`./img/${amenity.amenity_id}.png`)}`} />
            <p className={s.ammenityNew}>{amenity.name}</p>
            <div className={s.trashContainer}>
              <svg
                className={s.eyeIcon}
                onClick={() => this.props.markAsDisplayable(amenity)}
                enableBackground="new -0.709 -32.081 141.732 141.732"
                height="30px"
                id="Livello_1"
                version="1.1"
                viewBox="-0.709 -32.081 141.732 141.732"
                width="30px"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="Livello_80" fill="#9B9B9B">
                  <path d="M89.668,38.786c0-10.773-8.731-19.512-19.51-19.512S50.646,28.01,50.646,38.786c0,10.774,8.732,19.511,19.512,19.511   C80.934,58.297,89.668,49.561,89.668,38.786 M128.352,38.727c-13.315,17.599-34.426,28.972-58.193,28.972   c-23.77,0-44.879-11.373-58.194-28.972C25.279,21.129,46.389,9.756,70.158,9.756C93.927,9.756,115.036,21.129,128.352,38.727    M140.314,38.76C125.666,15.478,99.725,0,70.158,0S14.648,15.478,0,38.76c14.648,23.312,40.591,38.81,70.158,38.81   S125.666,62.072,140.314,38.76" />
                </g>
                <g id="Livello_1_1_" />
              </svg>
            </div>
          </div>
        ));

        return (
          <div className={s.wrapper}>
            <div className={s.section}>
              <h2 className={s.header}>What&apos;s Included?</h2>
              <div className={s.editAmmenities}>{amenitiesDisplayed}</div>
              <Auxiliary>
                <br />
                <h3 className={s.subHeader}>Not Displayed on Brochure</h3>
                <div className={s.editAmmenities}>{amenitiesNotDisplayed}</div>
              </Auxiliary>
            </div>
          </div>
        );
      }
    } else {
      // Traveler View
      const ammenities = _uniq(this.props.idAmmenities).map((am, index) => (
        <div className={s.amenityBlock} key={index}>
          <img className={s.amenityIcon} src={`${require(`./img/${am.amenity_id}.png`)}`} />
          <p key={index} className={this.props.included.includes(am.name) ? s.ammenity : s.notAmmenity}>
            {am.name}
          </p>
        </div>
      ));
      return (
        <div className={s.wrapper}>
          <div className={s.section}>
            <h2 className={s.header}>What&apos;s Included?</h2>
            <div className={s.ammenities}>
              <span className={s.contactHeader}>
                What is included or not included may vary by term. Contact your {this.props.aliasProgram.toLowerCase()}{' '}
                provider to confirm.
              </span>
              <div className={s.amenitiesBlock}>{ammenities}</div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default withStyles(s)(ProgramAmmenities);
