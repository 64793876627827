import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import propTypes from 'prop-types';
import moment from 'moment-timezone';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import InputSelect from '../../../library/inputSelect';
import InputDate from '../../../library/inputDate';
import sFilterDateRange from './filterDateRange.scss';
import _isEqual from 'lodash/isEqual';

export const enhance = compose(withStyles(sFilterDateRange));

function FilterDataRange(props) {
  const [loading, setLoading] = useState(true);
  const [rangeType, setRangeType] = useState(props.selectedValues[0] || 'is');
  const [singleDateValue, setSingleDateValue] = useState(props.selectedValues[1]);
  const [fromDateValue, setFromDateValue] = useState(props.selectedValues[2]);
  const [toDateValue, setToDateValue] = useState(props.selectedValues[3]);
  const [defaultIndex, setDefaultIndex] = useState();
  const [clearDate, setClearDate] = useState(false);
  const typeOptions = [
    { value: 'is', label: 'Is' },
    { value: 'is_between', label: 'Is between (inclusive)' },
    { value: 'is_greater', label: 'Is greater than or equal to' },
    { value: 'is_less', label: 'Is less than or equal to' },
  ];

  useEffect(() => {
    setLoading(false);
  }, []);

  useEffect(
    () => {
      if (props.selectedValues[0]) {
        setClearDate(false);
        let defaultIndexValue = typeOptions.find(item => item.value === props.selectedValues[0]);
        if (!defaultIndexValue || defaultIndexValue === undefined) {
          defaultIndexValue = typeOptions[0];
        }
        setRangeType(props.selectedValues[0]);
        setSingleDateValue(props.selectedValues[1]);
        setFromDateValue(props.selectedValues[2]);
        setToDateValue(props.selectedValues[3]);
        setDefaultIndex(defaultIndexValue);
      } else {
        let defaultIndexValue = typeOptions.find(item => item.value === rangeType);
        setDefaultIndex(defaultIndexValue);
        setClearDate(true);
      }
    },
    [props.selectedValues],
  );

  useEffect(
    () => {
      let filterValues = [];
      let saveType = rangeType;

      if (typeof saveType === 'object') {
        saveType = typeOptions[0].value;
      }

      if (saveType === 'is_between') {
        filterValues.push(saveType);
        filterValues.push('');
        filterValues.push(moment(fromDateValue || null).isValid() ? moment(fromDateValue).format('YYYY-MM-DD') : '');
        filterValues.push(moment(toDateValue || null).isValid() ? moment(toDateValue).format('YYYY-MM-DD') : '');
      } else {
        filterValues.push(saveType);
        filterValues.push(
          moment(singleDateValue || null).isValid() ? moment(singleDateValue).format('YYYY-MM-DD') : '',
        );
        filterValues.push('');
        filterValues.push('');
      }

      if (!loading) {
        if (!_isEqual(filterValues, props.selectedValues)) {
          props.change(filterValues);
        }
      }
    },
    [rangeType, singleDateValue, fromDateValue, toDateValue],
  );

  return (
    <div className={sFilterDateRange.dateRangeFilter}>
      <InputSelect
        id="rangeType"
        labelText=""
        styles={{
          menu: provided => ({ ...provided, zIndex: 99999 }),
        }}
        yearsIncrease={props.yearsIncrease}
        yearsDecrease={props.yearsDecrease}
        options={typeOptions}
        onChange={e => {
          setRangeType(e.value);
        }}
        defaultValue={defaultIndex}
        value={defaultIndex}
      />
      {rangeType &&
        rangeType !== 'is_between' && (
          <div className={sFilterDateRange.singleDate}>
            <InputDate
              labelText="Date"
              id="singleDate"
              errorMessage=""
              name="singleDate"
              value={singleDateValue}
              onChange={e => {
                setSingleDateValue(e);
              }}
              showClearDate
              // withPortal={true}
              yearsIncrease={props.yearsIncrease}
              yearsDecrease={props.yearsDecrease}
              appendToBody
              initialDate={
                props.selectedValues[1] && moment(props.selectedValues[1]).isValid()
                  ? moment(props.selectedValues[1])
                  : null
              }
              placeholder={
                props.selectedValues[1] && moment(props.selectedValues[1]).isValid()
                  ? moment(props.selectedValues[1]).format('MMM D, YYYY')
                  : null
              }
              labelSize="normal"
              forceClear={clearDate}
            />
          </div>
        )}
      {rangeType &&
        rangeType === 'is_between' && (
          <div className={sFilterDateRange.rangeDates}>
            <div className={sFilterDateRange.rangeDate}>
              <InputDate
                labelText="Date"
                id="dateFromRange"
                errorMessage=""
                name="dateFromRange"
                value={fromDateValue}
                onChange={e => {
                  setFromDateValue(moment(e).format('YYYY-MM-DD'));
                }}
                showClearDate
                // withPortal={true}
                yearsIncrease={props.yearsIncrease}
                yearsDecrease={props.yearsDecrease}
                appendToBody
                initialDate={
                  props.selectedValues[2] && moment(props.selectedValues[2]).isValid()
                    ? moment(props.selectedValues[2])
                    : null
                }
                placeholder={
                  props.selectedValues[2] && moment(props.selectedValues[2]).isValid()
                    ? moment(props.selectedValues[2]).format('MMM D, YYYY')
                    : null
                }
                labelSize="normal"
                forceClear={clearDate}
              />
            </div>
            <span className={sFilterDateRange.separator}> and </span>
            <div className={sFilterDateRange.rangeDate}>
              <InputDate
                labelText=""
                id="dateToRange"
                errorMessage=""
                name="dateToRange"
                value={toDateValue}
                onChange={e => {
                  setToDateValue(moment(e).format('YYYY-MM-DD'));
                }}
                showClearDate
                // withPortal={true}
                yearsIncrease={props.yearsIncrease}
                yearsDecrease={props.yearsDecrease}
                appendToBody
                initialDate={
                  props.selectedValues[3] && moment(props.selectedValues[3]).isValid()
                    ? moment(props.selectedValues[3])
                    : null
                }
                placeholder={
                  props.selectedValues[3] && moment(props.selectedValues[3]).isValid()
                    ? moment(props.selectedValues[3]).format('MMM D, YYYY')
                    : null
                }
                labelSize="normal"
                forceClear={clearDate}
              />
            </div>
          </div>
        )}
    </div>
  );
}

FilterDataRange.propTypes = {
  change: propTypes.func.isRequired,
  displayText: propTypes.string,
  id: propTypes.string.isRequired,
  selectedValues: propTypes.array,
};

FilterDataRange.defaultProps = {
  displayText: 'Filter Options',
  selectedValues: [],
};

export default enhance(FilterDataRange);
