import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Userpilot } from 'userpilot';
import _get from 'lodash/get';

function UserPilotScript() {
  const userProfile = useSelector(state => state.profile);
  const orgInfoName = useSelector(state => state.auth.organization.org_name);
  const orgStatus = useSelector(state => state.auth.organization.status);

  useEffect(() => {
    if (userProfile && !userProfile?.userLoading) {
      const isTraveler = _get(userProfile?.currentUser, ['is_traveler?']) || false;
      if (!isTraveler) {
        Userpilot.initialize('NX-8e5c5323');

        const cDateUser = new Date(userProfile?.currentUser?.created_at).toISOString().split('T')[0];
        setTimeout(() => {
          Userpilot.identify(userProfile.currentUser.id, {
            name: `${userProfile.currentUser.first_name} ${userProfile.currentUser.last_name}`,
            email: userProfile.currentUser.email,
            created_at: cDateUser,
            user_role: userProfile.role,
            company: {
              id: userProfile.currentUser.client_account_id, // Company Unique ID
              name: orgInfoName,
              type: orgStatus,
            },
          });
        }, 100);
      }
    }
  }, [userProfile]);

  return <></>;
}

export default UserPilotScript;
