import DesktopDatesFilter from '../../components/program-dashboard/filters/dates/DesktopDatesFilter';
import DesktopTermNameFilter from '../../components/program-dashboard/filters/term-name/DesktopTermNameFilter';
import AdvancedFiltersDropdown from '../../components/program-dashboard/filters/AdvancedFiltersDropdown';
import DesktopLocationsFilter from '../../components/program-dashboard/filters/locations/DesktopLocationsFilter';
import DesktopProgramTypeFilter from '../../components/program-dashboard/filters/program-type/DesktopProgramTypeFilter';
import DesktopSubjectAreasFilter from '../../components/program-dashboard/filters/subject-areas/DesktopSubjectAreasFilter';

export default {
  favorites: null,
  dates: DesktopDatesFilter,
  more: AdvancedFiltersDropdown,
  termName: DesktopTermNameFilter,
  locations: DesktopLocationsFilter,
  programType: DesktopProgramTypeFilter,
  subjectAreas: DesktopSubjectAreasFilter,
};
