import { orderBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ProfileSelectInput from '../../../base/inputs/ProfileSelectInput';

import { fetchLanguages } from '../../../../../../actions/lookupActions';

export default function ProgramLanguageOfInstructionFilter({ value, handleOnChange }) {
  const dispatch = useDispatch();
  const { languages } = useSelector(state => state.lookup.allLanguages);
  const { selectedFilters } = useSelector(state => state.programDashboard);

  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (!languages) {
      dispatch(fetchLanguages());
    }

    if (selectedFilters.languageOfInstruction.length > 0) {
      handleOnChange(selectedFilters.languageOfInstruction);
    }
  }, []);

  useEffect(
    () => {
      if (languages) {
        const types = orderBy(languages, type => type.attributes.name.toLowerCase(), ['asc']).map(language => ({
          label: language.attributes.name,
          value: language.attributes.name,
        }));

        setOptions(types);
      }
    },
    [languages],
  );

  return (
    <ProfileSelectInput
      id="language_of_instruction"
      value={value}
      label="Language of Instruction"
      placeholder="Select Language(s)"
      options={options}
      isMulti
      handleOnChange={handleOnChange}
    />
  );
}

ProgramLanguageOfInstructionFilter.propTypes = {
  handleOnChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]).isRequired,
};
