import React from 'react';
import { useSelector } from 'react-redux';
import { FreemiumIcon } from '../../../../../styledComponents/styles/IconCustom.styled';
import { PageHeader } from '../../../../../styledComponents/styles/PageHeader.styled';
import { Paragraph } from '../../../../../styledComponents/styles/Paragraph.styled';
import styled from 'styled-components';

const PremiumFeatureContainer = styled.div`
  margin-top: 60px;
  margin-left: 60px;
`;

const ContentContainer = styled.div`
  text-align: center;
`;

const ParagraphMedium = styled(Paragraph)`
  font-family: AvenirNextMedium, sans-serif;
  margin: 10px 0;
`;

const ImageContainer = styled.div`
  margin-top: 40px;
  margin-bottom: 60px;
`;

const Container = styled.div`
  margin-bottom: 30px;
`;

export default function PremiumFeature(props) {
  return (
    <>
      <PremiumFeatureContainer>
        <PageHeader> {props.header}</PageHeader>
        <ContentContainer>
          <ImageContainer>
            <FreemiumIcon size={180} />
          </ImageContainer>

          <Container>
            <ParagraphMedium>{props.header} is a premium feature in Via TRM.</ParagraphMedium>
            <ParagraphMedium>Interested in unlocking? Contact your account manager!</ParagraphMedium>
          </Container>
        </ContentContainer>
      </PremiumFeatureContainer>
    </>
  );
}
