import history from '../../history';

export function authHeader(form) {
  // return authorization header
  const user = localStorage.getItem('user');
  const token = localStorage.getItem('token');
  form = form || null;

  if (user && token) {
    if (form == null || form == undefined) {
      return {
        'X-User-Email': user,
        'X-User-Token': token,
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      };
    }
    return {
      'X-User-Email': user,
      'X-User-Token': token,
      // 'Content-Type': 'application/json',
      // 'Content-Type': 'multipart/form-data',
      // 'Accept': 'application/json'
    };
  }

  return {};
  history.push('/');
}
