import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { useDispatch } from 'react-redux';

import CalendarIcon from '../icons/CalendarIcon';
import CheckmarkCircleIcon from '../icons/CheckmarkCircleIcon';
import ScreenReaderText from '../base/ScreenReaderText.styled';
import LineThroughHeartIcon from '../icons/LineThroughHeartIcon';

import {
  EventDay,
  FullDate,
  ViewLink,
  Container,
  EventName,
  EventHeading,
  RegisterButton,
  RegisteredPill,
  TodayContainer,
  TodayIndicator,
  ActionsContainer,
  FullDateContainer,
  EventDateContainer,
  EventInfoContainer,
} from './EventsListItem.styled';

import eventType from '../../types/event';
import getFormattedEventDate from '../../utils/events/getFormattedEventDate';
import { setActiveEvent, toggleEventRegistrationModal } from '../../actions/travelerEventsActions';

export default function EventsListItem({ event, category }) {
  const dispatch = useDispatch();
  const isPast = category === 'past';
  const isUpcoming = category === 'upcoming';
  const isCancelled = category === 'cancelled';
  const hasAttended = event.attended === 'Attended';
  const isRegistered = event.registered === 'Registered';
  const isToday = moment(new Date(event.start)).format('MMM D, YYYY') === moment().format('MMM D, YYYY');

  const publicUrl =
    event.public_url.includes('http') || event.public_url.includes('https')
      ? event.public_url
      : `https://${event.public_url}`;

  const handleRegisterClick = () => {
    dispatch(setActiveEvent(event));
    dispatch(toggleEventRegistrationModal());
  };

  return (
    <Container isRegistered={isRegistered} tabIndex={0}>
      <EventInfoContainer>
        <EventDay>
          <div>{moment(new Date(event.start)).format('MMM')}</div>
          <div>{moment(new Date(event.start)).format('DD')}</div>
        </EventDay>
        <EventDateContainer>
          <EventHeading>
            <EventName>{event.name}</EventName>
            {isRegistered && <RegisteredPill forDesktop>Registered</RegisteredPill>}
            {isUpcoming && isToday && (
              <TodayContainer>
                <TodayIndicator />
                <span>Today</span>
              </TodayContainer>
            )}
          </EventHeading>
          <FullDateContainer>
            {isUpcoming ||
              (isCancelled && (
                <CalendarIcon fillColor={document.documentElement.style.getPropertyValue('--theme_color_accent')} />
              ))}
            {isPast && hasAttended && <CheckmarkCircleIcon />}
            {isPast && !hasAttended && <LineThroughHeartIcon />}
            <FullDate category={category}>
              {isUpcoming && <span>{getFormattedEventDate(event)}</span>}
              {isCancelled && <span>Cancelled</span>}
              {isPast && hasAttended && <span>Attended</span>}
              {isPast && !hasAttended && <span>Not Attended</span>}
            </FullDate>
          </FullDateContainer>
          {isRegistered && <RegisteredPill>Registered</RegisteredPill>}
        </EventDateContainer>
      </EventInfoContainer>
      <ActionsContainer>
        {isUpcoming && !isRegistered && (
          <RegisterButton aria-label={`Sign-up to ${event.name}`} type="button" onClick={handleRegisterClick}>
            Sign-Up
          </RegisterButton>
        )}
        {/* <ViewLink href={publicUrl} target="_blank" rel="noopener noreferrer" isRegistered={isRegistered}> */}
        <ViewLink href={'/traveler/eventdetail/' + event.id} rel="noopener noreferrer" isRegistered={isRegistered}>
          View
          <ScreenReaderText style={{marginLeft:"10px",marginRight:"10px"}}>{` ${event.name}`} event</ScreenReaderText>
        </ViewLink>
      </ActionsContainer>
    </Container>
  );
}

EventsListItem.propTypes = {
  event: eventType.isRequired,
  category: PropTypes.oneOf(['upcoming', 'past', 'cancelled']).isRequired,
};
