import React from 'react';

export default function ClockIcon() {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
      <path
        d="M18 35.4727C27.6499 35.4727 35.4727 27.6499 35.4727 18C35.4727 8.35013 27.6499 0.527344 18 0.527344C8.35013 0.527344 0.527344 8.35013 0.527344 18C0.527344 27.6499 8.35013 35.4727 18 35.4727Z"
        fill="#DDAF64"
      />
      <path
        d="M18.0004 33.7816C26.7162 33.7816 33.7821 26.7157 33.7821 17.9999C33.7821 9.28394 26.7162 2.21826 18.0004 2.21826C9.28443 2.21826 2.21875 9.28394 2.21875 17.9999C2.21875 26.7157 9.28443 33.7816 18.0004 33.7816Z"
        fill="#DDAF64"
      />
      <path
        d="M23.0727 30.3998C13.4228 30.3998 5.60004 22.577 5.60004 12.9271C5.60004 9.39998 6.64867 6.11942 8.44608 3.37329C3.6791 6.49341 0.527344 11.8771 0.527344 17.9998C0.527344 27.6496 8.3501 35.4724 18 35.4724C24.1226 35.4724 29.5063 32.3207 32.6266 27.5536C29.8805 29.3512 26.5999 30.3998 23.0727 30.3998Z"
        fill="#DDAF64"
      />
      <path
        d="M2.21777 18C2.21777 26.7159 9.28347 33.7817 17.9996 33.7817C22.2415 33.7817 26.0894 32.1049 28.9255 29.3821C27.0946 30.0346 25.1275 30.3999 23.0722 30.3999C13.4223 30.3999 5.59959 22.5771 5.59959 12.9273C5.59959 10.872 5.9648 8.90484 6.61758 7.07397C3.89466 9.90989 2.21777 13.758 2.21777 18Z"
        fill="#BC8C3F"
      />
      <path
        d="M17.9998 32.0907C25.7818 32.0907 32.0904 25.782 32.0904 18C32.0904 10.218 25.7818 3.90942 17.9998 3.90942C10.2177 3.90942 3.90918 10.218 3.90918 18C3.90918 25.782 10.2177 32.0907 17.9998 32.0907Z"
        fill="#FFEFD4"
      />
      <path
        d="M18.0001 29.2726C24.2257 29.2726 29.2726 24.2257 29.2726 18.0001C29.2726 11.7744 24.2257 6.72754 18.0001 6.72754C11.7744 6.72754 6.72754 11.7744 6.72754 18.0001C6.72754 24.2257 11.7744 29.2726 18.0001 29.2726Z"
        fill="white"
      />
      <path
        d="M17.9998 20.8181C19.5562 20.8181 20.8179 19.5564 20.8179 18C20.8179 16.4436 19.5562 15.1819 17.9998 15.1819C16.4434 15.1819 15.1816 16.4436 15.1816 18C15.1816 19.5564 16.4434 20.8181 17.9998 20.8181Z"
        fill="#FFEFD4"
      />
      <path
        d="M18.0002 19.127C18.6226 19.127 19.1273 18.6224 19.1273 17.9999C19.1273 17.3774 18.6226 16.8728 18.0002 16.8728C17.3777 16.8728 16.873 17.3774 16.873 17.9999C16.873 18.6224 17.3777 19.127 18.0002 19.127Z"
        fill="#DDAF64"
      />
      <path
        d="M30.7279 5.27203C27.3281 1.87235 22.808 0 18 0C13.192 0 8.67185 1.87235 5.27203 5.27203C1.87235 8.67185 0 13.192 0 18C0 22.808 1.87235 27.3281 5.27203 30.7279C8.67185 34.1278 13.192 36 18 36C22.808 36 27.3281 34.1278 30.7279 30.7279C34.1278 27.3281 36 22.808 36 18C36 13.192 34.1278 8.67185 30.7279 5.27203ZM29.9822 29.9822C26.7816 33.1826 22.5263 34.9452 18 34.9452C13.4737 34.9452 9.2184 33.1826 6.01783 29.9822C2.81729 26.7816 1.05469 22.5263 1.05469 18C1.05469 13.4737 2.81729 9.2184 6.01783 6.01783C9.2184 2.81729 13.4737 1.05469 18 1.05469C22.5263 1.05469 26.7816 2.81729 29.9822 6.01783C33.1826 9.2184 34.9452 13.4737 34.9452 18C34.9452 22.5263 33.1826 26.7816 29.9822 29.9822Z"
        fill="#373737"
      />
      <path
        d="M18.5274 8.41814V6.16356C18.5274 5.87234 18.2912 5.63623 18 5.63623C17.7088 5.63623 17.4727 5.87234 17.4727 6.16356V8.41807C17.4727 8.70931 17.7088 8.94542 18 8.94542C18.2912 8.94542 18.5274 8.70938 18.5274 8.41814Z"
        fill="#373737"
      />
      <path
        d="M30.3638 18C30.3638 17.7088 30.1279 17.4727 29.8365 17.4727H27.582C27.2908 17.4727 27.0547 17.7088 27.0547 18C27.0547 18.2912 27.2908 18.5274 27.582 18.5274H29.8365C30.1279 18.5274 30.3638 18.2912 30.3638 18Z"
        fill="#373737"
      />
      <path
        d="M6.16405 17.4727C5.87283 17.4727 5.63672 17.7088 5.63672 18C5.63672 18.2912 5.87283 18.5274 6.16405 18.5274H8.41856C8.7098 18.5274 8.94589 18.2912 8.94589 18C8.94589 17.7088 8.7098 17.4727 8.41856 17.4727H6.16405Z"
        fill="#373737"
      />
      <path
        d="M9.182 12.9092C9.32761 12.657 9.24121 12.3345 8.98899 12.1888L8.01277 11.6252C7.76048 11.4795 7.43804 11.566 7.29241 11.8182C7.1468 12.0704 7.23322 12.393 7.48542 12.5386L8.46164 13.1022C8.54468 13.1501 8.63538 13.1729 8.72482 13.1729C8.90708 13.1729 9.08432 13.0783 9.182 12.9092Z"
        fill="#373737"
      />
      <path
        d="M27.5383 22.8977C27.2861 22.752 26.9635 22.8385 26.8179 23.0907C26.6722 23.3429 26.7586 23.6654 27.0108 23.811L27.9871 24.3746C28.0702 24.4226 28.1609 24.4454 28.2502 24.4454C28.4326 24.4454 28.6097 24.3508 28.7074 24.1816C28.8531 23.9294 28.7667 23.6069 28.5144 23.4613L27.5383 22.8977Z"
        fill="#373737"
      />
      <path
        d="M23.3541 9.253C23.5363 9.253 23.7136 9.15836 23.8112 8.98926L24.375 8.01304C24.5204 7.76082 24.434 7.43831 24.1818 7.2927C23.9297 7.147 23.6071 7.23349 23.4615 7.48571L22.8979 8.46191C22.7523 8.71412 22.8387 9.03666 23.0909 9.18227C23.1739 9.23022 23.2646 9.253 23.3541 9.253Z"
        fill="#373737"
      />
      <path
        d="M27.9869 11.6252L27.0108 12.1888C26.7586 12.3344 26.6722 12.6569 26.8179 12.9092C26.9153 13.0783 27.0927 13.1729 27.2748 13.1729C27.3643 13.1729 27.4551 13.1501 27.5381 13.1022L28.5144 12.5385C28.7664 12.3929 28.8531 12.0704 28.7074 11.8182C28.5617 11.566 28.2391 11.4796 27.9869 11.6252Z"
        fill="#373737"
      />
      <path
        d="M9.182 23.0907C9.03637 22.8384 8.71386 22.752 8.46164 22.8977L7.48542 23.4613C7.23322 23.6069 7.1468 23.9295 7.29241 24.1816C7.39009 24.3508 7.56733 24.4454 7.74958 24.4454C7.83903 24.4454 7.92973 24.4226 8.01277 24.3746L8.98899 23.811C9.24121 23.6655 9.32761 23.343 9.182 23.0907Z"
        fill="#373737"
      />
      <path
        d="M12.189 8.98928C12.2867 9.15846 12.464 9.25302 12.6462 9.25302C12.7357 9.25302 12.8264 9.23024 12.9094 9.18229C13.1616 9.03666 13.248 8.71415 13.1024 8.46193L12.5388 7.48571C12.3932 7.23344 12.0706 7.14702 11.8184 7.2927C11.5662 7.43833 11.4798 7.76084 11.6254 8.01306L12.189 8.98928Z"
        fill="#373737"
      />
      <path
        d="M25.8483 16.7924C25.8039 16.5046 25.5353 16.3071 25.2468 16.3514L19.4691 17.2403C19.1934 16.7091 18.6385 16.3453 17.9999 16.3453C17.7357 16.3453 17.486 16.4079 17.2642 16.5185L11.6092 10.8634C11.4032 10.6575 11.0693 10.6575 10.8634 10.8634C10.6575 11.0694 10.6575 11.4033 10.8634 11.6092L16.5185 17.2642C16.4078 17.486 16.3453 17.7357 16.3453 17.9999C16.3453 18.9123 17.0876 19.6545 17.9999 19.6545C18.8158 19.6545 19.4952 19.0607 19.6298 18.2827L25.4074 17.3938C25.6952 17.3495 25.8924 17.0803 25.8483 16.7924ZM17.9998 18.5998C17.669 18.5998 17.3999 18.3306 17.3999 17.9999C17.3999 17.6691 17.6691 17.4 17.9998 17.4C18.3305 17.4 18.5997 17.6691 18.5997 17.9999C18.5997 18.3306 18.3306 18.5998 17.9998 18.5998Z"
        fill="#373737"
      />
      <path
        d="M4.44783 17.4904C4.57462 14.0618 5.98143 10.8368 8.40903 8.40908C10.8367 5.98141 14.0618 4.57467 17.4903 4.44788C20.9005 4.32181 24.2027 5.46783 26.7896 7.67487C27.0112 7.86387 27.344 7.83752 27.5332 7.61595C27.722 7.39441 27.6956 7.06155 27.4741 6.87255C24.6856 4.49346 21.1262 3.2577 17.4514 3.39397C13.7559 3.5306 10.2798 5.04687 7.6633 7.66335C5.04682 10.2798 3.53055 13.756 3.39392 17.4514C3.25801 21.1262 4.49341 24.6858 6.8725 27.4741C6.97676 27.5965 7.12484 27.6594 7.2739 27.6594C7.39498 27.6594 7.51664 27.6179 7.6159 27.5332C7.83747 27.3443 7.86382 27.0114 7.67482 26.7896C5.46778 24.2029 4.32169 20.9003 4.44783 17.4904Z"
        fill="#373737"
      />
      <path
        d="M29.1275 8.52568C28.9386 8.30413 28.6057 8.27769 28.3842 8.46676C28.1627 8.65576 28.1363 8.98861 28.3252 9.21018C30.5322 11.7971 31.6784 15.0997 31.5522 18.5096C31.4255 21.9381 30.0186 25.1632 27.591 27.5908C25.1634 30.0186 21.9383 31.4252 18.5098 31.552C15.1002 31.6782 11.7973 30.5322 9.21038 28.3252C8.98891 28.136 8.65598 28.1624 8.46698 28.384C8.27798 28.6055 8.30433 28.9384 8.5259 29.1275C11.1758 31.388 14.5217 32.6161 18.0012 32.6161C18.1833 32.6161 18.366 32.6128 18.5488 32.606C22.2442 32.4692 25.7204 30.9532 28.3369 28.3367C30.9534 25.7202 32.4697 22.2439 32.6063 18.5485C32.7421 14.8738 31.5066 11.3143 29.1275 8.52568Z"
        fill="#373737"
      />
    </svg>
  );
}
