export function addAlert(text, style) {
  return {
    type: 'ADD_ALERT',
    text,
    style,
  };
}

export function removeAlert(id) {
  return {
    type: 'REMOVE_ALERT',
    id,
  };
}

export function clearAlerts() {
  return {
    type: 'CLEAR_ALERTS',
  };
}
