import PropTypes from 'prop-types';
import React, { useState } from 'react';

import Modal from '../base/Modal';
import { ModalBody, ModalButton, ModalFooter } from '../base/Modal.styled';
import ProfilePictureCropper from '../forms/account/ProfilePictureCropper';

export default function ProfilePictureCropperModal({ show, setShow, previewImage, setCroppedImage }) {
  const [shouldCrop, setShouldCrop] = useState(false);

  const close = () => {
    setShow(false);
  };

  const handleCropImage = croppedImage => {
    setCroppedImage(croppedImage);

    setShouldCrop(false);

    close();
  };

  const handleSave = () => {
    setShouldCrop(true);
  };

  return (
    <Modal show={show} title="Crop Profile Picture" close={close}>
      <ModalBody>
        <ProfilePictureCropper previewImage={previewImage} shouldCrop={shouldCrop} handleCropImage={handleCropImage} />
      </ModalBody>
      <ModalFooter>
        <ModalButton type="button" className="secondary" onClick={close}>
          Cancel
        </ModalButton>
        <ModalButton type="button" className="primary" onClick={handleSave}>
          Save Image
        </ModalButton>
      </ModalFooter>
    </Modal>
  );
}

ProfilePictureCropperModal.propTypes = {
  show: PropTypes.bool,
  setShow: PropTypes.func.isRequired,
  previewImage: PropTypes.string.isRequired,
  setCroppedImage: PropTypes.func.isRequired,
};

ProfilePictureCropperModal.defaultProps = {
  show: false,
};
