import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import TeacherIcon from '../icons/TeacherIcon';
import ProgramMatchSection from './ProgramMatchSection';

import { fetchProgramTypes } from '../../../../actions/lookupActions';

export default function ProgramTypePreferences() {
  const dispatch = useDispatch();
  const programMatch = useSelector(state => state.programMatchForm);
  const programTypes = useSelector(state => state.lookup.allProgramTypes.programTypes);
  const {
    customAliases: { alias_program: programAlias },
  } = useSelector(state => state.currentOrganization);

  const [types, setTypes] = useState('');

  useEffect(() => {
    dispatch(fetchProgramTypes());
  }, []);

  useEffect(
    () => {
      if (programTypes) {
        const typeValues = programMatch.programTypes.reduce((accumulator, type) => {
          const value = programTypes.find(programType => Number(programType.id) === type);

          if (value) {
            accumulator.push(value.attributes.name);
          }

          return accumulator;
        }, []);

        if (typeValues.length > 0) {
          setTypes(typeValues.join('; '));
        }
      }
    },
    [programTypes, programMatch.programTypes],
  );

  return (
    <ProgramMatchSection heading={`${programAlias} Types`} icon={TeacherIcon}>
      <p>{types || 'None selected'}</p>
      {programMatch.otherProgramTypeText && (
        <p>
          <strong>Other:</strong> {programMatch.otherProgramTypeText}
        </p>
      )}
    </ProgramMatchSection>
  );
}
