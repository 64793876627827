import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { PropTypes } from 'prop-types';

import s from './CyanButton.css';

const CyanButton = ({ title, clickHandler, type }) => (
  <button type={type} className={s.cyanButton} onClick={clickHandler}>
    {title}
  </button>
);

CyanButton.propTypes = {
  title: PropTypes.string.isRequired,
  clickHandler: PropTypes.func.isRequired,
  type: PropTypes.string,
};

CyanButton.defaultProps = {
  type: 'button',
};

export default withStyles(s)(CyanButton);
