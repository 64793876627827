import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import { connect } from 'react-redux';
import { compose } from 'redux';
import { selectOrgSettingsTab } from '../../../actions/orgSettingsActions';
import { selectTravelerSettingsTab } from '../../../actions/travelerSettingsActions';
import s from './OrgSidebar.scss';

class OrgSidebar extends React.Component {
  handleClick(e, tab) {
    this.props.selectOrgSettingsTab(tab);
    this.props.selectTravelerSettingsTab(tab);
    this.props.setActiveTab(tab);
  }

  render() {
    const { role } = this.props;
    const aliasPrograms = this.props.customAliases ? this.props.customAliases.alias_programs : 'Programs';
    const aliasProgram = this.props.customAliases ? this.props.customAliases.alias_program : 'Program';

    if (role == 'admin') {
      return (
        <div className={s.sidebar}>
          <li
            className={this.props.tab === 'organization' ? s.activeFilter : s.filter}
            onClick={e => this.handleClick(e, 'organization')}
          >
            Organization
          </li>
          
          <li
            className={this.props.tab === 'accountSettings' ? s.activeFilter : s.filter}
            onClick={e => this.handleClick(e, 'accountSettings')}
          >
            Branding
          </li>


          <li
            className={this.props.tab === 'intakeSettings' ? s.activeFilter : s.filter}
            onClick={e => this.handleClick(e, 'intakeSettings')}
          >
            Intake Settings
          </li>





          {this.props.clientFeatureList.legacy ? (
            <div>
              {!this.props.freemium ? (
                <div>
                  <li
                    className={this.props.tab === 'authorizedPrograms' ? s.activeFilter : s.filter}
                    onClick={e => this.handleClick(e, 'authorizedPrograms')}
                  >
                    Authorized {aliasPrograms}
                  </li>
                  <li
                    className={this.props.tab === 'programAlternatives' ? s.activeFilter : s.filter}
                    onClick={e => this.handleClick(e, 'programAlternatives')}
                  >
                    {aliasProgram} Alternates
                  </li>
                  {this.props.clientFeatureList.travel_plans ? (
                    <div>
                      <li
                        className={this.props.tab === 'safeCheck' ? s.activeFilter : s.filter}
                        onClick={e => this.handleClick(e, 'safeCheck')}
                      >
                        Via Travel
                      </li>
                    </div>
                  ) : null}
                </div>
              ) : null}
            </div>
          ) : null}
          {!this.props.freemium ? (
            <div>
              <li
                className={this.props.tab === 'customFields' ? s.activeFilter : s.filter}
                onClick={e => this.handleClick(e, 'customFields')}
              >
                Custom Fields
              </li>
              {this.props.clientFeatureList.legacy ? (
                <div>
                  <li
                    className={this.props.tab === 'tags' ? s.activeFilter : s.filter}
                    onClick={e => this.handleClick(e, 'tags')}
                  >
                    Term Tags
                  </li>
                </div>
              ) : null}
            </div>
          ) : null}
          <li
            className={this.props.tab === 'term_names' ? s.activeFilter : s.filter}
            onClick={e => this.handleClick(e, 'term_names')}
          >
            Term Names
          </li>
          <li
            className={this.props.tab === 'users' ? s.activeFilter : s.filter}
            onClick={e => this.handleClick(e, 'users')}
          >
            Users
          </li>

          <li
            className={this.props.tab === 'roles&Permissions' ? s.activeFilter : s.filter}
            onClick={e => this.handleClick(e, 'roles&Permissions')}
          >
            Roles & Permissions
          </li>

          <li
            className={this.props.tab === 'myAccount' ? s.activeFilter : s.filter}
            onClick={e => this.handleClick(e, 'myAccount')}
          >
            My Account
          </li>
          {this.props.clientFeatureList.travel_plans && !this.props.freemium && !this.props.clientFeatureList.legacy ? (
            <div>
              <li
                className={this.props.tab === 'safeCheck' ? s.activeFilter : s.filter}
                onClick={e => this.handleClick(e, 'safeCheck')}
              >
                SafeCheck
              </li>
            </div>
          ) : null}
          {/* {!this.props.freemium && (
            <li
              className={this.props.tab === 'myNotifications' ? s.activeFilter : s.filter}
              onClick={e => this.handleClick(e, 'myNotifications')}
            >
              My Notifications
            </li>
          )} */}
          <li
            className={this.props.tab === 'notifications' ? s.activeFilter : s.filter}
            onClick={e => this.handleClick(e, 'notifications')}
          >
            My Notifications
          </li>
        </div>
      );
    } else if (role == 'traveler') {
      return (
        <div className={s.sidebar}>
          <li
            className={this.props.tab === 'myAccount' ? s.activeFilter : s.filter}
            onClick={e => this.handleClick(e, 'myAccount')}
          >
            My Account
          </li>
          {/* {this.props.clientFeatureList.legacy ? (
            <li
              className={this.props.tab === 'myNotifications' ? s.activeFilter : s.filter}
              onClick={e => this.handleClick(e, 'myNotifications')}
            >
              My Notifications
            </li>
          ) : null} */}
          <li
            className={this.props.tab === 'notifications' ? s.activeFilter : s.filter}
            onClick={e => this.handleClick(e, 'notifications')}
          >
            My Notifications
          </li>
        </div>
      );
    }
  }
}

export default compose(
  withStyles(s),
  connect(
    null,
    { selectOrgSettingsTab, selectTravelerSettingsTab },
  ),
)(OrgSidebar);
