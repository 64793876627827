exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3CIBE ._2_BUm{margin-left:0!important;display:-ms-flexbox!important;display:flex!important}", ""]);

// exports
exports.locals = {
	"sliderCoverDiv": "_3CIBE",
	"slick-track": "_2_BUm"
};