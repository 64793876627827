import React from 'react';
import withStyle from 'isomorphic-style-loader/lib/withStyles';
import s from './SubmitModal.css';

const closeIcon = () => (
  <svg
    width="24px"
    height="25px"
    viewBox="0 0 24 25"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="Events"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="square"
    >
      <g
        id="Register-Modal-Traveler-Logged-In"
        transform="translate(-1337.000000, -69.000000)"
        stroke="#FFFFFF"
        strokeWidth="2"
      >
        <g id="xcancel" transform="translate(1327.000000, 59.000000)">
          <g
            id="x-cancel"
            transform="translate(22.000000, 22.500000) rotate(45.000000) translate(-22.000000, -22.500000) translate(7.000000, 7.000000)"
          >
            <path d="M15,0.5 L15,30.5" id="Line" />
            <path d="M30,15.5 L6.39488462e-13,15.5" id="Line" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

const SubmitModal = ({
  showModal = false,
  closeModal,
  title,
  children,
  type,
  confirmHandler,
}) => {
  showModal
    ? document.body.classList.add(s.modalOpen)
    : document.body.classList.remove(s.modalOpen);
  return (
    <div
      className={s.wrapper}
      style={showModal ? { display: 'block' } : null}
    >
      <div className={s.modalWrapper}>
        <div className={s.closeWrapper} onClick={closeModal}>
          <p>{closeIcon()}</p>
          <p className={s.closeText}>close</p>
        </div>
        <div className={s.verticalCenter}>
          <h1 className={s.header}>{title}</h1>
          {children}
          <button className={s.button} onClick={confirmHandler}>
            {type == "commit" ? "ONWARD!" : "OK! GOT IT"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default withStyle(s)(SubmitModal);

