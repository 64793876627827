import styled from 'styled-components';

import { buttonFocus, buttonFocusOutline } from '../../utils/shared/focusStyles';

export const ButtonPrimary = styled.button`
  padding: 1rem 1.75rem;
  background: var(--theme_color_accent);
  border: 1px solid transparent;
  border-radius: 5px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  color: var(--theme_color_accent_font);
  line-height: 120%;
  letter-spacing: 0.06em;

  &:hover {
    opacity: 0.9;
  }

  ${buttonFocusOutline};
`;

export const ButtonSecondary = styled.button`
  padding: 1rem;
  background: #eeeeee;
  border: 1px solid #373737;
  border-radius: 5px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  color: #373737;
  line-height: 120%;
  letter-spacing: 0.06em;

  &:hover {
    background: #e7e7e7;
  }

  ${buttonFocusOutline};
`;

export const ButtonLink = styled.button`
  display: inline;
  align-self: center;
  background: transparent;
  border: 2px solid transparent;
  font-family: 'Nunito', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 120%;
  letter-spacing: 0.015em;
  text-decoration-line: underline;
  color: #446372;

  &:hover {
    text-decoration-line: none;
  }

  ${buttonFocus};
`;
