import ContactTab from '../components/tabs/ContactTab';
import AcademicTab from '../components/tabs/AcademicTab';
import PersonalTab from '../components/tabs/PersonalTab';
import EmergencyTab from '../components/tabs/EmergencyTab';
import PassportAndVisaTab from '../components/tabs/PassportAndVisaTab';
import AdditionalTab from '../components/tabs/AdditionalTab';
import PreferencesAndGoalsTab from '../components/tabs/PreferencesAndGoalsTab';

import AccountTab from '../components/tabs/AccountTab';
import SecurityTab from '../components/tabs/SecurityTab';
import NotificationsTab from '../components/tabs/NotificationsTab';

import PastEventsTab from '../components/tabs/PastEventsTab';
import UpcomingEventsTab from '../components/tabs/UpcomingEventsTab';
import CancelledEventsTab from '../components/tabs/CancelledEventsTab';

import PastProgramsTab from '../components/tabs/PastProgramsTab';
import FavoriteProgramsTab from '../components/tabs/FavoriteProgramsTab';
import UpcomingProgramsTab from '../components/tabs/UpcomingProgramsTab';

const profileComponentMap = {
  contact: ContactTab,
  academic: AcademicTab,
  personal: PersonalTab,
  emergency: EmergencyTab,
  passportAndVisa: PassportAndVisaTab,
  additional: AdditionalTab,
  preferencesAndGoals: PreferencesAndGoalsTab,
};

const settingsComponentMap = {
  account: AccountTab,
  security: SecurityTab,
  notifications: NotificationsTab,
};

const eventsComponentMap = {
  past: PastEventsTab,
  upcoming: UpcomingEventsTab,
  cancelled: CancelledEventsTab,
};

const programsComponentMap = {
  past: PastProgramsTab,
  upcoming: UpcomingProgramsTab,
  favorites: FavoriteProgramsTab,
};

export { eventsComponentMap, profileComponentMap, programsComponentMap, settingsComponentMap };
