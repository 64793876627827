import _capitalize from 'lodash/capitalize';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ProgramMatchSection from './ProgramMatchSection';
import LanguagesGlobeIcon from '../icons/LanguagesGlobeIcon';

import { fetchLanguages } from '../../../../actions/lookupActions';

export default function ProgramLanguagePreferences() {
  const dispatch = useDispatch();
  const programMatch = useSelector(state => state.programMatchForm);
  const programLanguages = useSelector(state => state.lookup.allLanguages.languages);

  const [languages, setLanguages] = useState('');

  useEffect(() => {
    dispatch(fetchLanguages());
  }, []);

  useEffect(
    () => {
      if (programLanguages) {
        const languageValues = programMatch.userLanguages.reduce((accumulator, language) => {
          const value = programLanguages.find(programLanguage => programLanguage.attributes.iso_639_3 === language);

          if (value) {
            accumulator.push(value.attributes.name);
          }

          return accumulator;
        }, []);

        if (languageValues.length > 0) {
          setLanguages(languageValues.join(', '));
        }
      }
    },
    [programLanguages, programMatch.userLanguages],
  );

  return (
    <ProgramMatchSection heading="Language Immersion" icon={LanguagesGlobeIcon}>
      {!programMatch.languageImmersion && !languages ? (
        <span>None selected</span>
      ) : (
        <p>
          {programMatch.languageImmersion && <span>{_capitalize(programMatch.languageImmersion.split('_').join(' '))}</span>}
          {programMatch.languageImmersion && languages && <span>; </span>}
          {languages && <span>{languages}</span>}
        </p>
      )}
    </ProgramMatchSection>
  );
}
