import React from 'react';
const PreviewIcon = (props) => {
  return(
  <div>
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_2134_17714)">
      <rect width="16" height="16" transform="translate(0 0.5)" fill="#BF2929" fill-opacity="0.01"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M12.2362 4.81371C13.4387 5.59027 14.5115 6.67652 15.3384 7.95527C15.4428 8.11706 15.4986 8.3054 15.4991 8.49794C15.4997 8.69048 15.445 8.87914 15.3415 9.04152C14.5178 10.3309 13.4471 11.4212 12.2453 12.1953C10.9206 13.0481 9.44871 13.499 7.98902 13.499C6.49652 13.499 5.06215 13.0615 3.72933 12.1981C2.54558 11.4321 1.4834 10.3443 0.658084 9.05277C0.550933 8.88652 0.495624 8.69218 0.499185 8.49442C0.502746 8.29666 0.565017 8.10443 0.678084 7.94215C1.67965 6.50621 2.83277 5.3909 4.10558 4.62746C5.35371 3.87871 6.66027 3.49902 7.98902 3.49902C9.43715 3.49902 10.9059 3.95371 12.2362 4.81371ZM6.33294 10.9934C6.82628 11.3231 7.4063 11.499 7.99965 11.499C8.79502 11.4981 9.55755 11.1818 10.12 10.6193C10.6824 10.0569 10.9987 9.29439 10.9996 8.49902C10.9996 7.90568 10.8237 7.32566 10.4941 6.83231C10.1644 6.33897 9.69588 5.95445 9.1477 5.72739C8.59952 5.50032 7.99632 5.44091 7.41438 5.55667C6.83243 5.67242 6.29788 5.95815 5.87833 6.3777C5.45877 6.79726 5.17305 7.33181 5.05729 7.91375C4.94153 8.4957 5.00095 9.0989 5.22801 9.64707C5.45507 10.1953 5.83959 10.6638 6.33294 10.9934ZM9.9996 8.49902C9.9996 9.60359 9.10417 10.499 7.9996 10.499C6.89503 10.499 5.9996 9.60359 5.9996 8.49902C5.9996 7.39445 6.89503 6.49902 7.9996 6.49902C9.10417 6.49902 9.9996 7.39445 9.9996 8.49902Z" fill={props.color}/>
      </g>
      <defs>
      <clipPath id="clip0_2134_17714">
      <rect width="16" height="16" fill={props.innerColor} transform="translate(0 0.5)"/>
      </clipPath>
      </defs>
    </svg>
  </div>
  )
}
export default (PreviewIcon);