import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import StyledIcon from '../styles/Icon.styled';
import Tooltip from './Tooltip.styled';
import { stripHtmlString } from '../../shared/helpers/General';
import CurrencyInput from 'react-currency-input-field';

const openSansFont = css`
  font-family: ${({ theme }) => theme.textInput.fontFamilyOpenSans};
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const InputInnerGroup = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 1rem;
`;
const InputField = styled(CurrencyInput)`
  border: ${props =>
    props.errorMsg && props.errorMsg.length > 0
      ? ({ theme }) => theme.textInput.error.border
      : ({ theme }) => theme.textInput.border};
  border-radius: ${({ theme }) => theme.textInput.borderRadius};
  color: ${({ theme }) => theme.textInput.fontColor};
  font-family: ${({ theme }) => theme.textInput.fontFamily};
  font-size: ${({ theme }) => theme.textInput.fontSize};
  font-weight: ${({ theme }) => theme.textInput.fontWeight};
  height: ${({ theme }) => theme.textInput.height};
  margin-bottom: 0;
  line-height: ${({ theme }) => theme.textInput.lineHeight};
  padding: ${({ theme }) => theme.textInput.padding};
  width: 100%;

  ${({ openSans }) => (openSans ? openSansFont : null)} &:disabled {
    border: ${({ theme }) => theme.textInput.disabled.border};
    background-color: ${({ theme }) => theme.textInput.disabled.backgroundColor};
    color: ${({ theme }) => theme.textInput.disabled.fontColor};
    cursor: not-allowed;
  }

  &:focus {
    outline-color: ${({ theme }) => theme.textInput.focus.outlineColor};
  }

  &::placeholder {
    color: ${({ theme }) => theme.textInput.placeholder.fontColor};
    font-family: ${({ theme }) => theme.textInput.placeholder.fontFamily};
    font-size: ${({ theme }) => theme.textInput.placeholder.fontSize};
    font-style: ${({ theme }) => theme.textInput.placeholder.fontStyle};
    font-weight: ${({ theme }) => theme.textInput.placeholder.fontWeight};

    ${({ openSans }) => (openSans ? openSansFont : null)};
  }
`;

const InputLabel = styled.label`
  color: ${({ theme }) => theme.textInput.label.fontColor};
  font-family: ${({ theme }) => theme.textInput.label.fontFamily};
  font-size: ${({ theme }) => theme.textInput.label.fontSize};
  font-weight: ${({ theme }) => theme.textInput.label.fontWeight};

  ${({ openSans }) => (openSans ? openSansFont : null)};
`;

const Instructions = styled.div`
  color: ${({ theme }) => theme.textInput.instructions.fontColor};
  font-family: ${({ theme }) => theme.textInput.instructions.fontFamily};
  font-size: ${({ theme }) => theme.textInput.instructions.fontSize};
  font-weight: ${({ theme }) => theme.textInput.instructions.fontWeight};
  padding-left: 5px;
  ${({ openSans }) => (openSans ? openSansFont : null)};
`;

const Error = styled.div`
  color: ${({ theme }) => theme.textInput.error.fontColor};
  display: inline-block;
  font-family: ${({ theme }) => theme.textInput.error.fontFamily};
  font-size: ${({ theme }) => theme.textInput.error.fontSize};
  font-weight: ${({ theme }) => theme.textInput.error.fontWeight};
  padding-top: 3px;
  vertical-align: middle;
  ${({ openSans }) => (openSans ? openSansFont : null)} svg {
    justify-content: center;
    margin-right: 5px;

    vertical-align: middle;
    display: inline-block;
  }

  span {
    vertical-align: middle;
    display: inline-block;
  }
`;

const Required = styled.span`
  color: ${({ theme }) => theme.defaults.errorColor};
  padding-left: 5px;
`;

const TooltipContainer = styled.span`
  padding-left: 5px;
`;

export default function CurrencyStyledInput({
  disabled,
  errorMsg,
  icon,
  id,
  label,
  placeholder,
  required,
  tooltip,
  value,
  instructions,
  openSans,
  ...props
}) {
  const [openSansFont, setOpenSansFont] = useState(props.openSans);
  const [costValue, setCostValue] = useState(value);
  let cleanseInstructions = null;
  if (instructions && stripHtmlString(instructions).trim().length > 0) {
    cleanseInstructions = instructions;
  }

  return (
    <InputGroup>
      {label &&
        label.length > 0 && (
          <InputLabel htmlFor={id} openSans={openSansFont}>
            {label}
            {required && <Required>*</Required>}
            {tooltip && (
              <TooltipContainer>
                <Tooltip toolTipText={tooltip}>
                  <StyledIcon type={'InformationCircle'} size={'14px'} />
                </Tooltip>
              </TooltipContainer>
            )}
          </InputLabel>
        )}
      {cleanseInstructions &&
        cleanseInstructions.length > 0 && (
          <Instructions
            openSans={openSansFont}
            dangerouslySetInnerHTML={{
              __html: cleanseInstructions,
            }}
          />
        )}

      <InputInnerGroup>
        <InputField
          aria-invalid={errorMsg && errorMsg.length > 0 ? 'true' : 'false'}
          aria-label={label || 'Text input'}
          id={id}
          name={id}
          placeholder={placeholder}
          defaultValue={0.00}
          decimalsLimit={2}
          value={costValue}
          onValueChange={(value) => {
            setCostValue(value);
            props.onChange(value);
          }}
          allowNegativeValue={false}
        />
      </InputInnerGroup>

      {errorMsg &&
        errorMsg.length > 0 && (
          <Error openSans={openSansFont}>
            <StyledIcon type={'Warning'} size={'16px'} />
            <span>{errorMsg}</span>
          </Error>
        )}
    </InputGroup>
  );
}
