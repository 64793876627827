import React, {useState} from 'react';
import { compose } from 'recompose';
import propTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import ViaModal from '../../../../components/library/viaModal';
import Headline from '../../../../components/library/headline';
import Button from '../../../../components/library/button';
import Paragraph from '../../../../components/library/paragraph';
import { getPlan, patchPlanUser } from '../../../../../actions/plans';
import sReinstateTraveler from './reinstateTraveler.scss';

export const enhance = compose(withStyles(sReinstateTraveler));

function ReinstateTraveler(props) {
  const dispatch = useDispatch();
  const [saving, setSaving] = useState(false);

  const reinstateTraveler = () => {
    const submitData = { plans_user:
        { removed: false }
    };

    props.planUsers.forEach((user, index, array) => {
      setTimeout(() => {
        dispatch(patchPlanUser(user, submitData));
      }, 500 * index);
    });

    setTimeout(() => {
      setSaving(false);
      dispatch(getPlan(props.planId));
      props.onClose(false);
    }, 500 * (props.planUsers.length + 1));
  };

  return (
    <>
      <ViaModal
        showing={props.show}
        onClose={() => props.onClose(false)}
      >
        <div className={sReinstateTraveler['reinstate-traveler']}>
          <div className={sReinstateTraveler.subHeader}>
            <Headline centered tag="h1" as="h1">
              Reinstate Traveler to the Group Plan
            </Headline>
          </div>
          <div className={sReinstateTraveler.content}>
            <Paragraph size="large">
              You are about to reinstate one or more Travelers to the Group Plan.  This will:
            </Paragraph>
          </div>
          <div className={sReinstateTraveler['content-list']}>
            <span>
              <ul>
                <li>Notify Travelers of these changes</li>
              </ul>
            </span>
          </div>
          <div className={sReinstateTraveler.content}>
            <Paragraph size="large">
              Reinstating a Traveler to the Group Plan will not automatically reinstate any details to their plan.
            </Paragraph>
          </div>
          <div className={sReinstateTraveler.footer}>
            <div className={sReinstateTraveler['footer-confirm']}>
              <Headline tag="h2" as="h3" centered>
                Would You Still Like To Reinstate Traveler Or Travelers From This Group Plan? 
              </Headline>
            </div>
            <div className={sReinstateTraveler['footer-button-wrapper']}>
              <div className={sReinstateTraveler['button-content']}>
                <Button
                  display="secondary"
                  kind="solid"
                  size="medium"
                  onClick={() => {
                    props.onClose(false);
                  }}
                >
                  No, Do Not Reinstate
                </Button>
              </div>
              <div className={sReinstateTraveler['button-content']}>
                <Button
                  display="primary"
                  kind="solid"
                  size="medium"
                  type="submit"
                  onClick={() => reinstateTraveler()}
                  loading={saving}
                  disabled={saving}
                >
                  Yes, Reinstate Traveler
                </Button>
              </div>
            </div>
          </div>
        </div>
      </ViaModal>
    </>
  )
}

ReinstateTraveler.propTypes = {
  show: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
  planId: propTypes.string.isRequired,
  planUsers: propTypes.array,
};

export default enhance(ReinstateTraveler);
