import { compose } from 'redux';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import history from '../../../history';
import userId from '../../../shared/helpers/userId';
import { fetchCurrentTravelerUser } from '../../../actions/profileActions';
import { getInboundOrganization } from '../../travelerProfile/actions/travelerProfileActions';

import loadedFonts from '../../../shared/fonts/loadFonts.css';
import { themeVia } from '../../../styledComponents/settings/theme';
import { useThemeColors } from '../../../shared/hooks/useThemeColors';
import GlobalStyles from '../../../styledComponents/styles/GlobalStyles';
import { Cookies } from 'react-cookie';
import useSession from '../../../hooks/useSession';
import SessionExpireModal from '../../../components/SessionExpireModal';

function Layout({ children }) {
  const dispatch = useDispatch();
  const isBrandingSet = useThemeColors();
  const userProfile = useSelector(state => state.profile);
  const { clientFeatureList, currentUser } = useSelector(state => state.profile);
  const [loading, setLoading] = useState(true);
  const { showSessionExpire, handleCloseSessionModal, countDownSeconds } = useSession(); 


  const cookies = new Cookies();
  const token = cookies.get('token');

  useEffect(() => {
    let unmounted = false;

    if (!unmounted && token && userId) {
      dispatch(fetchCurrentTravelerUser(userId));
    }

    return () => {
      unmounted = true;
    };
  }, []);

  useEffect(() => {
    let unmounted = false;
    if (!unmounted && token && userId && clientFeatureList?.inbound && currentUser?.via_international) {
      dispatch(getInboundOrganization(userId));
    }
    return () => {
      unmounted = true;
    };
  }, [clientFeatureList?.inbound, currentUser?.via_international]);

  useEffect(() => {
    setLoading(token && userId ? userProfile.userLoading : false);
  }, [userProfile]);

  useEffect(() => {
    if (!loading) {
      if (history.location.pathname === '/traveler/intake/processing') {
        const path =
          userProfile.show_program_match && !userProfile.program_match_complete
            ? '/traveler/program-match'
            : document.referrer.includes('/traveler?tab=preferences')
            ? '/traveler?tab=preferences'
            : '/traveler/program-dashboard';

        setTimeout(() => {
          history.push(path);
        }, 2000);

        return;
      }

      if (history.location.pathname === '/traveler/program-match/processing') {
        setTimeout(() => {
          history.push('/traveler/program-dashboard');
        }, 2000);
      }

      if (history.location.pathname.includes('/traveler/intake') && !userProfile.show_intake) {
        if (userProfile.show_program_match && !userProfile.program_match_complete) {
          history.push('/traveler/program-match');
        } else if (document.referrer.includes('/traveler?tab=preferences')) {
          history.push('/traveler?tab=preferences');
        } else {
          history.push('/traveler/program-dashboard');
        }
      }

      if (history.location.pathname.includes('/traveler/program-match') && !userProfile.show_program_match) {
        history.push('/traveler/program-dashboard');
      }
    }
  }, [userProfile, loading, history.location.pathname]);

  return (
    <ThemeProvider theme={themeVia}>
      <GlobalStyles />
      <div>{isBrandingSet && !loading ? children : null}</div>
      {showSessionExpire && <SessionExpireModal show={showSessionExpire} handleClose={handleCloseSessionModal} countDownSeconds={countDownSeconds} />}
    </ThemeProvider>
  );
}

export default compose(withStyles(loadedFonts))(Layout);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};
