exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@-webkit-keyframes _3fjPa{to{-webkit-transform:rotate(1turn)}}@keyframes _3fjPa{to{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}h1,h2,h3,h4,h5,h6{font-size:16px;margin:0;padding:0;line-height:1.1em;color:#043544}h1._18AYl,h2._18AYl,h3._18AYl,h4._18AYl,h5._18AYl,h6._18AYl{font-family:IntroBoldItalic,sans-serif}h1.BUNXA,h2.BUNXA,h3.BUNXA,h4.BUNXA,h5.BUNXA,h6.BUNXA{font-family:AvenirNextMedium,sans-serif}h1._3m8jp,h2._3m8jp,h3._3m8jp,h4._3m8jp,h5._3m8jp,h6._3m8jp{text-transform:capitalize}h1._1Jpr_,h2._1Jpr_,h3._1Jpr_,h4._1Jpr_,h5._1Jpr_,h6._1Jpr_{text-align:center}h1._1yrKy,h2._1yrKy,h3._1yrKy,h4._1yrKy,h5._1yrKy,h6._1yrKy{border-bottom:1px solid #373737;margin-top:16px}h1._39Prs,h2._39Prs,h3._39Prs,h4._39Prs,h5._39Prs,h6._39Prs{color:#373737}h1._2RAi7,h2._2RAi7,h3._2RAi7,h4._2RAi7,h5._2RAi7,h6._2RAi7{color:#ff2f07}._330U-{font-size:32px;line-height:40px}._2Uwzl{font-size:24px;line-height:32px}._38_bp{font-size:16px;line-height:24px}._3tDeE{font-size:12px}._3hzkZ{font-size:8px}._2uvNL{font-size:6px}", ""]);

// exports
exports.locals = {
	"introBoldItalic": "_18AYl",
	"avenirMedium": "BUNXA",
	"upper": "_3m8jp",
	"centered": "_1Jpr_",
	"underline": "_1yrKy",
	"gray": "_39Prs",
	"red": "_2RAi7",
	"headline-h1": "_330U-",
	"headline-h2": "_2Uwzl",
	"headline-h3": "_38_bp",
	"headline-h4": "_3tDeE",
	"headline-h5": "_3hzkZ",
	"headline-h6": "_2uvNL",
	"spin": "_3fjPa"
};