export default [
  {
    id: 1,
    label: 'Personal savings',
    value: 'personal_savings',
  },
  {
    id: 2,
    label: 'Scholarship / grant',
    value: 'scholarship_grant',
  },
  {
    id: 3,
    label: 'Financial aid',
    value: 'financial_aid',
  },
  {
    id: 4,
    label: 'Parent / family',
    value: 'parent_donor',
  },
  {
    id: 5,
    label: 'Job',
    value: 'job',
  },
  {
    id: 6,
    label: 'Fundraising / crowdfunding',
    value: 'fundraising_crowdfunding',
  },
  {
    id: 7,
    label: `I'm not sure`,
    value: 'not_sure',
  },
  {
    id: 8,
    label: 'Other',
    value: 'other',
  },
];
