import { groupBy } from 'lodash';
import moment from 'moment-timezone';

export default function groupEvents(events) {
  return groupBy(events, event => {
    const date = moment(new Date(event.start));

    return `${date.format('MMM')} ${date.year()}`;
  });
}
