import fetch from 'node-fetch';
import { keys } from '../../../config/keys';
import userId from '../../../shared/helpers/userId';

import { CLEAR_ACTIVE_EVENT, GET_TRAVELER_EVENTS, SET_ACTIVE_EVENT, TOGGLE_EVENT_REGISTRATION_MODAL } from './types';
import { Cookies } from 'react-cookie';

export function getTravelerEvents(userId) {
  if (!userId) {
    window.location.assign('/');

    return;
  }

  const cookies = new Cookies();
  const token = cookies.get('token');

  return async function(dispatch) {
    const response = await fetch(`${keys.baseUri}/api/traveler/traveler_profiles/${userId}/get_traveler_details`, {
      method: 'GET',
      headers: token,
    });

    const { events } = await response.json();

    dispatch({
      type: GET_TRAVELER_EVENTS,
      payload: events,
    });
  };
}

export function toggleEventRegistrationModal() {
  return function(dispatch) {
    dispatch({
      type: TOGGLE_EVENT_REGISTRATION_MODAL,
    });
  };
}

export function setActiveEvent(event) {
  return function(dispatch) {
    dispatch({
      type: SET_ACTIVE_EVENT,
      payload: event,
    });
  };
}

export function clearActiveEvent() {
  return function(dispatch) {
    dispatch({
      type: CLEAR_ACTIVE_EVENT,
    });
  };
}

export function registerForEvent(eventId) {
  return async function(dispatch, getState) {
    const state = getState().travelerProfile;

    const user = {
      email: state.travelerEmail,
      lastName: state.profile.last_name,
      firstName: state.profile.first_name,
    };

    const cookies = new Cookies();
    const token = cookies.get('token');

    await fetch(
      `${keys.baseUri}/api/visitor/events/${eventId}/create_event_traveler?user=${JSON.stringify({
        email: encodeURIComponent(`${user.email}`),
        first_name: encodeURIComponent(`${user.firstName}`),
        last_name: encodeURIComponent(`${user.lastName}`),
      })}`,
      {
        method: 'POST',
        headers: token,
      },
    );

    dispatch({
      type: TOGGLE_EVENT_REGISTRATION_MODAL,
    });

    dispatch(getTravelerEvents(userId));
  };
}
