import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import {
  ApplicationButton,
  GeneralFormButton,
  RecommendationButton,
  PlanFormButton,
} from '../../../../../styledComponents/styles/Action.buttons.styled';
import { ButtonMediumNunito, AccentButtonMediumNunito } from '../../../../../styledComponents/styles/Buttons.styled';
import { createForm } from '../../../../../actions/formsActions';
import {
  GeneralFileIcon,
  GeneralFormIcon,
  RecommendationIcon,
  ApplicationIcon,
  PlanFormIcon,
} from '../../../../../styledComponents/styles/IconCustom.styled';
import GenericStyledModal from './GenericStyledModal.modal';
import { GraySpinner, AccentButtonSpinner } from '../../../../../styledComponents/styles/spinner.styled';
import StyledIcon from '../../../../../styledComponents/styles/Icon.styled';
import TextInput from '../../../../../styledComponents/styles/TextInput.styled';

const BackContainer = styled.main`
  display: flex;
  justify-content: flex-start;
  margin-top: 11px;
`;
const BackIcon = styled.div`
  margin-right: 13.62px;
`;

const ButtonContainer = styled.div`
  color: #373737;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  margin-bottom: 20px;
`;

const ButtonDiv = styled.div`
  display: grid;
  margin-left: 73px;
`;

const Content = styled.div`
  margin-left: 45px;
`;

const FlexContainer = styled.div`
  display: grid;
  grid-template-areas: 'main information';
  grid-template-columns: 1fr;
  transition: all 0.25s ease-in-out;

  @media (max-width: 550px) {
    grid-template-areas:
      'information'
      'main';
  }
`;

const Icon = styled.div`
  margin-bottom: 21px;
  margin-top: 25px;
`;

const IconContainer = styled.div`
  margin: 75px;
`;

const InformationSection = styled.div`
  background: #f0f1f1;
  grid-area: information;
  height: 100%;
  width: 316px;
  border-bottom-right-radius: 15px;
`;

const Instructions = styled.div`
  color: #373737;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.011em;
  line-height: 24px;
  margin-left: 38px;
  margin-right: 36px;
  text-align: center;
`;

const InstructionsDiv = styled.div`
  display: block;
  text-align: center;
`;

const InstructionsTitle = styled.div`
  color: #373737;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 16px;
`;

const Main = styled.main`
  grid-area: main;
`;

const NextContainer = styled.main`
  display: flex;
  justify-content: flex-end;
  margin-right: 30px;
  padding-bottom: 1.2rem;
`;

const Paragraph = styled.div`
  margin-bottom: 23px;
  margin-top: 16px;
  color: #373737;
  font-family: 'Nunito', sans-serif;
  font-size: 14px;
`;

const TextContainer = styled.div`
  color: #373737;
  font-family: 'Nunito', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.015em;
  line-height: 120%;
  margin-bottom: 98px;
  margin-right: 29px;
`;

const Title = styled.div`
  color: #373737;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.005em;
  line-height: 140%;
  margin-bottom: 16px;
  margin-left: 49px;
  margin-top: 20px;
`;

const CreateNewFormModal = ({ showing, setShowCreateModal, submissionType }) => {
  const customAliases = useSelector(state => state.profile.customAliases);
  const clientFeatureList = useSelector(state => state.profile.clientFeatureList);
  const permissions = useSelector(state => state.profile.permissions);
  const dispatch = useDispatch();
  const formCreated = useSelector(state => state.forms.createForm);
  const [instructionType, setInstructionType] = useState(submissionType || '');
  const [instructions, setInstructions] = useState('');
  const [instructionTitle, setInstructionsTitle] = useState('');
  const [showInstructions, setShowInstructions] = useState(false);
  const [showNext, setShowNext] = useState(!!submissionType);
  const [value, setValue] = useState();
  const [errMessage, setErrMessage] = useState('');
  const [disableSubmit, setDisableSubmit] = useState(false);

  const instructionTypeText = {
    general_form: {
      instructions:
        'Create a form that can be added to a traveler after they’ve started an application, or automatically when the application status is committed, approved, conditionally approved, or nominated.',
      title: 'General Form',
    },
    application_form: {
      instructions: `Create an application that can be attached to your program terms. Applications are forms with additional logic (i.e. can determine where your ${customAliases.alias_traveler.toLowerCase()} is in their journey).`,
      title: 'Application Form',
    },
    recommendation_form: {
      instructions:
        'Recommendations are public forms that can be attached to ‘Recommendation’ question types on forms and applications.',
      title: 'Recommendation Form',
    },
    plan_form: {
      instructions:
        'Create forms to collect traveler data in Via Travel Travel Plans like a Passport Form or a Travel Waiver Form.',
      title: 'Plan Form',
    },
  };

  const toggleInstructionType = type => {
    setShowInstructions(true);
    setInstructionType(type);
    setInstructionsTitle(instructionTypeText[type].title);
    setInstructions(instructionTypeText[type].instructions);
  };

  useEffect(() => {
    if (!!formCreated.data) {
      if (formCreated.data.id) {
        setShowInstructions('');
        setInstructionType('');
        setInstructionsTitle('');
        setShowNext(false);
        setShowCreateModal(!showing);
        setDisableSubmit(false);
        window.location.href = `/client/form-builder/${formCreated.data.id}`;
      }
    }
    if (!formCreated.loading) {
      setDisableSubmit(false);
    }
    if (formCreated.error) {
      setErrMessage(formCreated.errorMessage);
    }
  }, [formCreated]);

  useEffect(() => {
    //This is how the Applications/Forms dashboards customize this modal
    if (submissionType) {
      toggleInstructionType(submissionType);
    }
  }, [submissionType]);

  const canCreateApplications = permissions?.application_templates_settings?.includes(
    'create_slash_edit_slash_duplicate',
  );
  const canCreateForms = permissions?.form_template_settings?.includes('create_or_duplicate');
  const canCreateRecommendations = permissions?.recommendation_template_settings?.includes('create_duplicate');
  const canCreatePlan = permissions?.plan_forms_settings?.includes('create_edit_duplicate');

  // If we navigate back, and there isn't a name, let's reset the value to null
  // so that the "required" border does not show before needed
  useEffect(() => {
    if (!value?.trim().length) {
      setValue(null);
      setErrMessage('');
    }
  }, [showNext]);

  const titleText =
    instructionType && showNext ? `Create New ${instructionTypeText[instructionType].title}` : 'Create New Form';

  return (
    <GenericStyledModal
      header={titleText}
      showing={showing}
      width={'750px'}
      paddingBottom={'0px'}
      onClose={() => {
        setShowCreateModal(!showing);
      }}
    >
      {!showNext && (
        <FlexContainer>
          <Main>
            <Title>Select Form Type</Title>
            <ButtonDiv>
              {canCreateForms && (
                <ButtonContainer>
                  <GeneralFormButton
                    disabled={!clientFeatureList.legacy ? true : false}
                    onClick={() => {
                      toggleInstructionType('general_form');
                    }}
                  />
                </ButtonContainer>
              )}
              {canCreateApplications && (
                <ButtonContainer>
                  <ApplicationButton
                    disabled={!clientFeatureList.legacy ? true : false}
                    onClick={() => {
                      toggleInstructionType('application_form');
                    }}
                  />
                </ButtonContainer>
              )}
              {canCreateRecommendations && (
                <ButtonContainer>
                  <RecommendationButton
                    onClick={() => {
                      toggleInstructionType('recommendation_form');
                    }}
                  />
                </ButtonContainer>
              )}
              {canCreatePlan && (
                <ButtonContainer>
                  <PlanFormButton
                    disabled={!clientFeatureList.travel_plans ? true : false}
                    onClick={() => {
                      toggleInstructionType('plan_form');
                    }}
                  />
                </ButtonContainer>
              )}
            </ButtonDiv>
            <NextContainer>
              <AccentButtonMediumNunito
                disabled={instructionType.length === 0}
                onClick={() => {
                  setShowNext(!showNext);
                }}
              >
                Next
                <StyledIcon type={'ChevronForward'} color={'#fff'} />
              </AccentButtonMediumNunito>
            </NextContainer>
          </Main>
          <InformationSection>
            {!showInstructions ? (
              <IconContainer>
                <GeneralFileIcon width={'142px'} height={'185px'} />
              </IconContainer>
            ) : (
              <InstructionsDiv>
                {/* <Icon>
                  {instructionType === 'general_form' ? (
                    <GeneralFormIcon size={'95px'} />
                  ) : instructionType === 'application_form' ? (
                    <ApplicationIcon size={'95px'} />
                  ) : instructionType === 'plan_form' ? 
                    (
                      <PlanFormIcon size={'95px'} />
                    )
                  :
                  (
                    <RecommendationIcon size={'95px'} />
                  )}
                </Icon> */}
                <Icon>
                  {{
                    general_form: <GeneralFormIcon size="95px" />,
                    application_form: <ApplicationIcon size="95px" />,
                    plan_form: <PlanFormIcon size="95px" />,
                  }[instructionType] || <RecommendationIcon size="95px" />}
                </Icon>
                <InstructionsTitle>{instructionTitle}</InstructionsTitle>
                <Instructions>{instructions}</Instructions>
              </InstructionsDiv>
            )}
          </InformationSection>
        </FlexContainer>
      )}

      {showNext && (
        <FlexContainer>
          <Main>
            {formCreated.loading ? (
              <Content>
                <Paragraph>
                  Creating{' '}
                  {instructionType === 'general_form'
                    ? ` general `
                    : instructionType === 'application_form'
                    ? ` application `
                    : instructionType === 'plan_form'
                    ? ` plan `
                    : ` recommendation `}
                  form
                </Paragraph>
                <GraySpinner displayText="Loading..." />
              </Content>
            ) : (
              <Content>
                {!submissionType && (
                  <BackContainer>
                    <ButtonMediumNunito onClick={() => setShowNext(!showNext)}>
                      <BackIcon>
                        <StyledIcon type={'ChevronBack'} color={'#373737'} /> Back
                      </BackIcon>
                    </ButtonMediumNunito>
                  </BackContainer>
                )}
                <Paragraph>
                  Enter your new
                  {instructionType === 'general_form'
                    ? ` general `
                    : instructionType === 'application_form'
                    ? ` application `
                    : instructionType === 'plan_form'
                    ? ` plan `
                    : ` recommendation `}
                  form’s name.
                </Paragraph>

                <TextContainer>
                  <TextInput
                    aria-label="Type in a name for your new form"
                    placeholder={`${
                      instructionType === 'general_form'
                        ? `Eg. Passport Information`
                        : instructionType === 'application_form'
                        ? `Eg. Spring Semester Application`
                        : instructionType === 'plan_form'
                        ? ``
                        : `Eg. Faculty Recommendation`
                    }`}
                    id="add_form_name"
                    name="add_form_name"
                    errorMsg={
                      value?.length <= 1 ? 'General Form name is required' : errMessage.length > 0 ? errMessage : ''
                    }
                    label={`${
                      instructionType === 'general_form'
                        ? ` General`
                        : instructionType === 'application_form'
                        ? ` Application`
                        : instructionType === 'plan_form'
                        ? ` Plan`
                        : ` Recommendation`
                    } Form Name`}
                    value={value || ''}
                    onChange={e => {
                      setValue(e.target.value);
                      setErrMessage('');
                    }}
                  />
                </TextContainer>

                <NextContainer>
                  <AccentButtonMediumNunito
                    disabled={!value || value?.length <= 1}
                    onClick={() => {
                      setDisableSubmit(true);
                      dispatch(
                        createForm(
                          instructionType === 'general_form'
                            ? 'General Form'
                            : instructionType === 'application_form'
                            ? `Application`
                            : instructionType === 'plan_form'
                            ? `Plan Form`
                            : `Recommendation`,
                          value,
                        ),
                      );
                    }}
                  >
                    {disableSubmit ? <AccentButtonSpinner displayText="Creating ..." /> : 'Create'}
                    {!disableSubmit && <StyledIcon type="ChevronForward" color="#fff" />}
                  </AccentButtonMediumNunito>
                </NextContainer>
              </Content>
            )}
          </Main>
          <InformationSection>
            {!showInstructions ? (
              <IconContainer>
                <GeneralFileIcon width={'142px'} height={'185px'} />
              </IconContainer>
            ) : (
              <InstructionsDiv>
                {/* <Icon>
                  {instructionType === 'general_form' ? (
                    <GeneralFormIcon size={'95px'} />
                  ) : instructionType === 'application_form' ? (
                    <ApplicationIcon size={'95px'} />
                  ) : (
                    <RecommendationIcon size={'95px'} />
                  )}
                </Icon> */}
                <Icon>
                  {{
                    general_form: <GeneralFormIcon size="95px" />,
                    application_form: <ApplicationIcon size="95px" />,
                    plan_form: <PlanFormIcon size="95px" />,
                  }[instructionType] || <RecommendationIcon size="95px" />}
                </Icon>
                <InstructionsTitle>{instructionTitle}</InstructionsTitle>
                <Instructions>{instructions}</Instructions>
              </InstructionsDiv>
            )}
          </InformationSection>
        </FlexContainer>
      )}
    </GenericStyledModal>
  );
};

export default CreateNewFormModal;
