import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import downloadIconRead from '../../shared/images/svgs/download-active.svg';
import downloadIconUnread from '../../shared/images/svgs/download-disabled.svg';
import styled from 'styled-components';
import { Dropdown } from 'react-bootstrap';
import NotificationItem from './NotificationItem';
import { markAllReadNotification, getStandardReportNotifications } from '../../actions/reportsActions';

const Container = styled.div`
  width: 35px;
  height: 25px;
  margin: 3px;
`;

const ItemContainer = styled.div`
  border: 1px solid #d7d7d7;
  margin: 3px;
  height: 272px;
  overflow-y: auto;
`;
const HeaderTextContainer = styled.div`
  padding: 15px;
  display: flex;
  justify-content: flex-end;
`;

const MarkAllReadButton = styled.button`
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0.001em;
  text-align: left;
  border: none;
  background: none;
  text-decoration: underline;
  color: #255a94;
`;

const FooterTextContainer = styled.div`
  padding: 15px;
  display: flex;
  justify-content: center;
`;

const NotificationImage = styled.img`
  margin-right: 51px;
  width: 28px !important;
  height: 25px !important;
  margin-bottom: 5px;
  zindex: 0;
  bottom: 2px !important;
`;

const NoDatacontainer = styled.div`
  min-width: 350px;
  min-height: 150px;
`;

const NoDataText = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0.001em;
  text-align: left;
  color: #373737;
`;
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => {
  const { data, loading } = useSelector(state => state.reports.reportDownloadNotifications);
  const profileData = useSelector(state => state.profile);

  return (
    <button
      type="button"
      ref={ref}
      onClick={e => {
        onClick(e);
      }}
      style={{
        background: 'none',
        border: 'none',
        width: '30px',
        height: '30px',
      }}
    >
      {data?.length > 0 && profileData?.standard_report_notification_read === false ? (
        <NotificationImage src={downloadIconRead} />
      ) : (
        <NotificationImage src={downloadIconUnread} />
      )}
      {children}
    </button>
  );
});

export default function NotificationMenu() {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const { data, loading } = useSelector(state => state.reports.reportDownloadNotifications);

  function toggleDropDown() {
    setIsOpen(!isOpen);
  }

  useEffect(() => {
    dispatch(getStandardReportNotifications());
  }, []);

  const markAllRead = () => {
    dispatch(
      markAllReadNotification(status => {
        if (status) {
          // window.location.href = "/reports/downloads";
          dispatch(getStandardReportNotifications());
        }
      }),
    );
  };

  const Link = ({ className, children }) => (
    <a href="/reports/downloads" className={className}>
      {children}
    </a>
  );
  const StyledLink = styled(Link)`
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    letter-spacing: 0.001em;
    text-align: center;
    color: #255a94;
    text-decoration: underline;
  `;

  return (
    <Container>
      <div>
        <Dropdown
          style={{
            position: 'unset',
          }}
          id="custom-toggle-notifications"
        >
          <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components" />
          <Dropdown.Menu align={'end'} style={{ marginTop: '10px', zIndex: 2000, padding: '0px' }}>
            {data.length > 0 ? (
              <>
                <HeaderTextContainer>
                  <MarkAllReadButton onClick={markAllRead}>Mark all as read</MarkAllReadButton>
                </HeaderTextContainer>
                <ItemContainer>
                  {data?.map(item => (
                    <NotificationItem item={item} key={item.id} />
                  ))}
                </ItemContainer>
                <FooterTextContainer>
                  <StyledLink>View All</StyledLink>
                </FooterTextContainer>
              </>
            ) : (
              <NoDatacontainer>
                <NoDataText>You have no new downloads available.</NoDataText>
                <FooterTextContainer>
                  <StyledLink>View Downloads</StyledLink>
                </FooterTextContainer>
              </NoDatacontainer>
            )}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </Container>
  );
}
