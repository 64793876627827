import { orderBy, uniq } from 'lodash';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

export default function useSubjectAreasOptions() {
  const { programs } = useSelector(state => state.programDashboard);

  const [allOptions, setAllOptions] = useState([]);
  const [subjectAreas, setSubjectAreas] = useState([]);

  useEffect(
    () => {
      if (programs.length > 0) {
        const options = programs.reduce((accumulator, program) => {
          program.attributes.program_subject_areas.split('; ').forEach(subjectArea => {
            accumulator.push(subjectArea);
          });

          return accumulator;
        }, []);

        const orderedOptions = orderBy(uniq(options), option => option.toLowerCase(), ['asc']).map(option => ({
          label: option,
          value: option,
        }));

        setAllOptions(orderedOptions);
        setSubjectAreas(orderedOptions);
      }
    },
    [programs],
  );

  return [allOptions, subjectAreas, setSubjectAreas];
}
