import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { getPaymentStatusOptions } from '../../../../../actions/formsActions';
import { Paragraph } from '../../../../../styledComponents/styles/Paragraph.styled';
import PaymentBox from '../../paymentBox/paymentBox';

const Container = styled.div`
  font-family: 'Nunito', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 0.015em;
  color: #373737;
`;

const InputContainer = styled.div`
  flex: 1;
  margin: 13px 0;
  text-align: left;
`;

const Label = styled.label`
  color: ${({ theme }) => theme.textInput.label.fontColor};
  font-family: ${({ theme }) => theme.textInput.label.fontFamily};
  font-size: ${({ theme }) => theme.textInput.label.fontSize};
  font-weight: ${({ theme }) => theme.textInput.label.fontWeight};
`;

const Required = styled.span`
  color: ${({ theme }) => theme.defaults.errorColor};
  padding-left: 5px;
`;

const QuestionQuikpayDisplayPreview = ({ question, isAdmin }) => {
  const dispatch = useDispatch();
  const paymentOptions = useSelector(state => state.forms.paymentStatusOptions);
  useEffect(() => {
    if(isAdmin && !paymentOptions?.data && !paymentOptions?.loading) {
      dispatch(getPaymentStatusOptions());
    }
  }, []);
  const updatedInstruction = !!question.instructions ? question.instructions.replace(/<p><\/p>/g, '<br />') : '';
  return (
    <Container>
      <InputContainer>
        <Label htmlFor={question.id}>
          {question.label}
          {question.required && <Required>*</Required>}
        </Label>
        <Paragraph
          aria-label={question.label}
          id='fee-instructions'
          openSans
          dangerouslySetInnerHTML={{
            __html: updatedInstruction,
          }}
        />
        <PaymentBox
          cost={question.cost}
          currency={question.currency}
          isAdmin={false}
          paymentOptions={paymentOptions}
          questionId={`previewId-${Math.random()}`}
          isReadonly
          onStatusChange={() => {}}
          url=''
        />
      </InputContainer>
    </Container>
  );
}

QuestionQuikpayDisplayPreview.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  question: PropTypes.object.isRequired,
  isAdmin: PropTypes.bool.isRequired,
}

export default QuestionQuikpayDisplayPreview;