exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Pg1-7{display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center;height:100vh}._1C6zF{max-width:500px;padding:20px}._3tbX8{font-family:Nunito;font-size:36px;font-weight:400;line-height:43.2px;letter-spacing:.0025em;text-align:left}._1cvhV{text-align:center;margin-top:20px}._1mGGO{font-family:Open Sans;font-size:14px;font-weight:400;line-height:16.8px;letter-spacing:.0025em;text-align:left}._1gZWb{text-align:center}._3oA2m{margin-top:20px}._3jrAB{position:relative;bottom:40px}._1Qm5y{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center;padding:30px}._1npHd{width:200px;height:200px}._2Z2KH{margin-top:20px}._2t5sm{background:var(--theme_color_accent);border-color:var(--theme_color_accent);color:var(--theme_color_accent_font)}._2t5sm:active,._2t5sm:disabled,._2t5sm:focus,._2t5sm:hover,._2t5sm:not(:disabled):not(.F-Iy8):active{background:var(--theme_color_accent);color:var(--theme_color_accent_font);border-color:var(--theme_color_accent);opacity:.7;-webkit-box-shadow:unset;box-shadow:unset}", ""]);

// exports
exports.locals = {
	"center": "Pg1-7",
	"content": "_1C6zF",
	"heading": "_3tbX8",
	"headingMobile": "_1cvhV",
	"message": "_1mGGO",
	"mesageMobile": "_1gZWb",
	"btnContainer": "_3oA2m",
	"img": "_3jrAB",
	"customBtn": "_1vAxB",
	"contentMobile": "_1Qm5y",
	"imgMobile": "_1npHd",
	"btnMobile": "_2Z2KH",
	"exploreBtn": "_2t5sm",
	"disabled": "F-Iy8"
};