import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleFavorite } from '../../../../actions/programBrochureActions';

import { programIdType } from '../../utils/types';
import { HeaderActionDiv, HeaderIconButton, HeaderLabel } from './Header.styled';

export default function Favorite({ programId, onFavoriteClick }) {
  const dispatch = useDispatch();
  const programBrochure = useSelector(state => state.programBrochure); 
  const updateFavorite = useSelector(state => state.program.favorite);
  const programFavoriteDisclaimerAccepted = useSelector(state => state.profile.program_favorite_disclaimer_accepted);
  const favoriteDisclaimerUpdated = useSelector(state => state.programBrochure.update_favorite_disclaimer);

  const {
    customAliases: {
      alias_programs: programAlias,
      alias_favorite: favoriteAlias = 'Favorite',
      alias_favorites: favoritesAlias = 'Favorites',
    },
    currentUser: { id: userId },
  } = useSelector(state => state.profile);

  const [favorite, setFavorite] = useState(false);


  useEffect(() => {

    setFavorite(programBrochure?.meta?.isFavorite); 

  }, [programBrochure]); 

  const handleToggleFavorite = () => {
    if (programFavoriteDisclaimerAccepted || favoriteDisclaimerUpdated || favorite) {
      setFavorite(!favorite);
      const isFavDisAccepted = false;
      dispatch(toggleFavorite(programId, userId, isFavDisAccepted));
    } else {
      onFavoriteClick();
    }
  };

  useEffect(
    () => {
      if (Object.keys(updateFavorite).length > 0) {
        setFavorite(updateFavorite?.favorite);
      }
    },
    [updateFavorite],
  );


  return (
    <HeaderActionDiv>
      <HeaderLabel>
        {favoriteAlias} {programAlias}:
      </HeaderLabel>
      <HeaderIconButton
        onClick={handleToggleFavorite}
        type="button"
        aria-label={favorite ? `${programAlias} added to ${favoritesAlias}` :`Add ${programAlias} to ${favoritesAlias}`}
      >
        {favorite ? (
          <svg
            style={{ height: '1.5rem', width: '1.5rem' }}
            xmlns="http://www.w3.org/2000/svg"
            height="1.5rem"
            viewBox="0 0 24 24"
            width="1.5rem"
            fill="#C74368"
          >
            <path d="M0 0h24v24H0z" fill="none" />
            <path
              stroke="#373737"
              strokeWidth="2px"
              d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"
            />
          </svg>
        ) : (
          <svg
            style={{ height: '1.5rem', width: '1.5rem' }}
            xmlns="http://www.w3.org/2000/svg"
            height="1.5rem"
            viewBox="0 0 24 24"
            width="1.5rem"
            fill="#373737"
            aria-hidden="true"
          >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M16.5 3c-1.74 0-3.41.81-4.5 2.09C10.91 3.81 9.24 3 7.5 3 4.42 3 2 5.42 2 8.5c0 3.78 3.4 6.86 8.55 11.54L12 21.35l1.45-1.32C18.6 15.36 22 12.28 22 8.5 22 5.42 19.58 3 16.5 3zm-4.4 15.55l-.1.1-.1-.1C7.14 14.24 4 11.39 4 8.5 4 6.5 5.5 5 7.5 5c1.54 0 3.04.99 3.57 2.36h1.87C13.46 5.99 14.96 5 16.5 5c2 0 3.5 1.5 3.5 3.5 0 2.89-3.14 5.74-7.9 10.05z" />
          </svg>
        )}
      </HeaderIconButton>
    </HeaderActionDiv>
  );
}

Favorite.propTypes = {
  programId: programIdType,
};
