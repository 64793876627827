export default [
  {
    id: 1,
    label: 'Africa',
    value: 'africa',
  },
  {
    id: 2,
    label: 'Antarctica',
    value: 'antarctica',
  },
  {
    id: 3,
    label: 'Asia',
    value: 'asia',
  },
  {
    id: 4,
    label: 'Australia / New Zealand',
    value: 'australia',
  },
  {
    id: 5,
    label: 'Europe',
    value: 'europe',
  },
  {
    id: 6,
    label: 'North America',
    value: 'north_america',
  },
  {
    id: 7,
    label: 'South America',
    value: 'south_america',
  },
];
