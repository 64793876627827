import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SelectInput from '../../base/inputs/SelectInput';
import TravelerFormQuestionContainer from '../../base/TravelerFormQuestionContainer';

import userId from '../../../../../shared/helpers/userId';
import countries from '../../../../../../public/data/countries.json';
import { addProgramMatchProgramCountry } from '../../../actions/programMatchFormActions';

export default function ProgramMatchCountry() {
  const dispatch = useDispatch();
  const programMatch = useSelector(state => state.programMatchForm);
  const programAlias = useSelector(state => state.auth.organization.info.alias_program);

  const [value, setValue] = useState([]);
  const [error, setError] = useState('');

  const question = {
    overline: `${programAlias} Country`,
    heading: 'Tell us, where are you interested in going?',
    subheading: 'Select one or more countries that spark your interest.',
    isRequired: true,
  };

  useEffect(
    () => {
      const initialCountries = countries.filter(country => programMatch.programCountries.includes(country.value));

      setValue(initialCountries);
    },
    [programMatch.programCountries],
  );

  const handleNext = () => {
    setError("");
    if (value.length === 0) {
      setTimeout(()=>{
        setError('Select at least one country.');
      },0)
      throw new Error();
    }

    const countries = value.map(country => country.value);

    dispatch(addProgramMatchProgramCountry(userId, countries));
  };

  const handleOnChange = event => {
    if (event) {
      setValue(event);
    } else {
      setValue([]);
    }
  };

  return (
    <TravelerFormQuestionContainer question={question} handleNext={handleNext}>
      <SelectInput
        value={value}
        defaultValue={value}
        id="program_countries"
        label={`${programAlias} Countries`}
        placeholder="Select country"
        options={countries}
        error={error}
        isMulti
        required
        handleOnChange={handleOnChange}
      />
    </TravelerFormQuestionContainer>
  );
}
