import React from 'react';
import { compose } from 'recompose';
import propTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './freemiumCustomBrouchureSection.scss';
import Modal from 'react-responsive-modal';
import sModal from 'react-responsive-modal/styles.css';
import { FreemiumIcon } from '../../../../../styledComponents/styles/IconCustom.styled';
import CloseIcon from '../../../../travelerProfile/components/icons/CloseIcon';
import { ThemeProvider } from 'styled-components';
import { themeViaForms } from '../../../../../styledComponents/settings/theme';
import { AccentButtonMedium } from '../../../../../styledComponents/styles/Buttons.styled';

export const enhance = compose(withStyles(s, sModal));

function FreemiumCustomBrouchureSection(props) {
  return (
    <ThemeProvider theme={themeViaForms}>
      <Modal
        blockScroll={false}
        open={props.show}
        onClose={() => props.onClose(false)}
        center
        closeOnEsc
        closeOnOverlayClick
        showCloseIcon={false}
        styles={{
          modal: {
            padding: '0px',
            borderRadius: '5px',
            width: '500px',
          },
        }}
      >
        <div className={s.header}>
          <h2 className={s.heading}>Premium Feature</h2>
          <button className={s.closeButton} onClick={() => props.onClose(false)}>
            <CloseIcon fillColor={`#9FABAE`} />
          </button>
        </div>
        <div className={s.modalContainer}>
          <div className={s.content}>
            <div className={s.iconWrapper}>
              <FreemiumIcon size="125px" />
            </div>
            <div className={s.paragraphContainer}>
              <div className={s.paragraphWarning}>Custom sections are a premium feature in Via.</div>
              <div className={`${s.paragraphWarning}`} style={{ marginTop: '15px' }}>
                Upgrade your account to access this feature by contacting our sales team at{' '}
                <strong>info@via-trm.com.</strong>
              </div>
            </div>
          </div>
          <div className={s.buttonWrapper}>
            <div className={s.buttonItem}>
              <AccentButtonMedium
                type="button"
                onClick={() => {
                  props.onClose(false);
                  props.setSelectedPrograms();
                }}
              >
                Okay
              </AccentButtonMedium>
            </div>
          </div>
        </div>
      </Modal>
    </ThemeProvider>
  );
}

FreemiumCustomBrouchureSection.propTypes = {
  show: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
};

export default enhance(FreemiumCustomBrouchureSection);
