import React from 'react';
import { useSelector } from 'react-redux';

import BooksAndCapIcon from '../icons/BooksAndCapIcon';
import ProgramMatchSection from './ProgramMatchSection';

export default function ProgramSubjectAreasPreferences() {
  const programMatch = useSelector(state => state.programMatchForm);

  return (
    <ProgramMatchSection heading="Subject Areas" icon={BooksAndCapIcon}>
      <p>{programMatch.subjectAreas.length > 0 ? programMatch.subjectAreas.join('; ') : 'None selected'}</p>
    </ProgramMatchSection>
  );
}
