import React from 'react';
import Select, { components } from 'react-select';
import { useSelector } from 'react-redux';
import propTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getContrastColor } from '../../../../../shared/helpers/General';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import sTravelerActionsSelect from './travelerActions.scss';

function TravelerActionsSelect(props) {
  const customBranding = useSelector(state => state.profile.customBranding.themes);
  const lightTextColor = getContrastColor(customBranding.theme_color_light);

  const DropdownIndicator = props => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <FontAwesomeIcon color={props.isDisabled ? '#ffffff' : lightTextColor} icon={props.selectProps.menuIsOpen ? 'angle-up' : 'angle-down'} size="lg" />
        </components.DropdownIndicator>
      )
    );
  };

  const customStyles = {
    control: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled ? '#c4c4c4' : `${customBranding.theme_color_light}`,
        color: isDisabled ? '#ffffff' : lightTextColor,
        borderRadius: '2px',
        borderColor: isDisabled ? '#c4c4c4' : `${customBranding.theme_color_light}`,
        padding: '8px',
        fontSize: '16px',
        '&:hover': {
          cursor: isDisabled ? 'not-allowed' : 'pointer',
        },
      };
    },
    menuList: styles => {
      return {
        ...styles,
        maxHeight: 'none',
        marginTop: '-8px',
      };
    },
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: '#f6f7f9',
        fontFamily: 'AvenirNextRegular',
        fontSize: '14px',
        color: '#043544',
        cursor: isDisabled ? 'not-allowed' : 'pointer',
        height: '44px',
        paddingTop: '14px',
        '&:hover': {
          backgroundColor: '#ebebeb',
        }
      };
    },
    placeholder: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        color: isDisabled ? '#ffffff' : lightTextColor,
        fontFamily: 'AvenirNextRegular, sans-serif',
      };
    },
    singleValue: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        color: isDisabled ? '#ffffff' : lightTextColor,
        fontFamily: 'AvenirNextRegular, sans-serif',
      };
    },
  };

  return (
    <Select
      {...props}
      options={props.options}
      placeholder="Traveler Actions"
      isSearchable={false}
      styles={customStyles}
      components={{ IndicatorSeparator: () => null, DropdownIndicator }}
    />
  );
}

TravelerActionsSelect.propTypes = {
  options: propTypes.array.isRequired,
};

export default withStyles(sTravelerActionsSelect)(TravelerActionsSelect);
