import styled from 'styled-components';

export const ToggleSwitch = styled.label`
  position: relative;
  display: inline-block;
  width: 36px;
  height: 20px;
  margin-bottom: 0;
  font-size: 1rem;
  color: #373737;

  input {
    opacity: 0;

    &:checked + .toggle-slider {
      background-color: var(--theme_color_dark);
    }

    &:checked + .toggle-slider:before {
      content: '';
      background-image: url("data:image/svg+xml,%3Csvg width='11' height='8' viewBox='0 0 11 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.48828 1.5835L3.98828 7.0835L1.48828 4.5835' stroke='%23446372' stroke-width='1.6666' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      background-color: #ffffff;
      transform: translateX(15px);
    }

    &:focus-visible + .toggle-slider {
      outline: 2px solid #255a94;
      outline-offset: 1px;
    }
  }
`;

export const ToggleSlider = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  background-color: #888888;
  border-radius: 12px;
  transition-property: transform, background-color;
  transition-duration: 0.4s;

  &::before {
    content: '';
    position: absolute;
    left: 3px;
    top: 2px;
    width: 16px;
    height: 16px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.6666' stroke='currentColor' width='11' height='11'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M6 18L18 6M6 6l12 12' /%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center;
    background-color: #ffffff;
    transition-property: transform, background-color;
    transition-duration: 0.4s;
    border-radius: 50%;
  }
`;
