import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Form from 'react-bootstrap/Form';
//import DatePickerInput from '../../base/inputs/DatePickerInput';
import Error from '../../base/inputs/Error';
import TravelerFormQuestionContainer from '../../base/TravelerFormQuestionContainer';

import { breakpoints } from '../../../utils/breakpoints';
import userId from '../../../../../shared/helpers/userId';
import { addProgramMatchProgramTerm } from '../../../actions/programMatchFormActions';
import ScreenReaderLabel from '../../base/inputs/ScreenReaderLabel.styled';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  margin-top: 3.1875rem;

  .date-picker {
    flex-grow: 1;
  }

  @media ${breakpoints.tablet} {
    flex-direction: row;
    align-items: start;
    gap: 2rem;
    margin-top: 5.28571429rem;
    margin-right: 3.3rem;
    margin-left: 2rem;
  }

  @media ${breakpoints.desktop} {
    margin-right: 7.10125rem;
  }
`;

const StartDateContainer = styled.div`
    //display:flex;
`;
const EndDateContainer = styled.div`
  //display:flex;
`;

const Datecontainer = styled.div`
  display:flex;

  .form-select-date {
    display: flex;
    align-items: center;
    flex: 1 0 0;
    align-self: stretch;
    width: 150px;
    height: 45px;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    padding-left:5px;
    border: 1px solid var(--ReactSelectTheme-neutral20, #CCC);
    background: var(--ReactSelectTheme-neutral0, #FFF);
  }

  .select-dropdown {
    width: 150px;
    position: relative;
    height: 45px;
  }

  .separator {
    position: absolute;
    top: 50%;
    right: 31px; /* Adjust as needed */
    transform: translateY(-50%);
    width: 1px;
    height: 70%;
    background-color: #ccc; /* Adjust color as needed */
  }
  
`;

const Separator = styled.div`
  align-self: center;
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  color: #373737;
  text-align: center;
  line-height: 120%;

  text-align: center;
  //width: 50px;
  padding-left: 18px;

  @media ${breakpoints.tablet} {
    text-align: left;
  }
`;

export default function ProgramMatchTerm() {
  const dispatch = useDispatch();
  const programMatch = useSelector(state => state.programMatchForm);
  const programAlias = useSelector(state => state.auth.organization.info.alias_program);

  const initialState = { 
    startDate: null, 
    endDate: null, 
    startDateMonth:null, 
    startDateYear:null,
    endDateMonth:null, 
    endDateYear:null 
  }
  const [value, setValue] = useState(initialState);
  const [years, setYear] = useState([]);

  const [error, setError] = useState({
    endDate: '',
    startDate: '',
    //startDateMonth: '', 
   // startDateYear: '',
    //endDateMonth: '', 
   // endDateYear: '', 
  });

  useEffect(()=>{
    const date = new Date();
    let year = date.getFullYear();
    const yearArray = [];
    const pastYear = year-25;
    const futureYear = parseInt(year)+25;
    for(let i=pastYear; i<=futureYear; i++) {
      yearArray.push(i);
    }
    setYear(yearArray);
  },[])

  const monthsArray = [
    { label: 'Jan', value: "01",  ariaLabel:"January"},
    { label: 'Feb', value: "02" , ariaLabel:"February"},
    { label: 'Mar', value: "03" , ariaLabel:"March"},
    { label: 'Apr', value: "04" , ariaLabel:"April"},
    { label: 'May', value: "05" , ariaLabel:"May"},
    { label: 'Jun', value: "06" , ariaLabel:"June"},
    { label: 'Jul', value: "07" , ariaLabel:"July"},
    { label: 'Aug', value: "08" , ariaLabel:"August"},
    { label: 'Sep', value: "09" , ariaLabel:"September"},
    { label: 'Oct', value: "10" , ariaLabel:"October"},
    { label: 'Nov', value: "11" , ariaLabel:"November"},
    { label: 'Dec', value: "12" , ariaLabel:"December"}
  ];


  const question = {
    overline: `${programAlias} Term`,
    heading: 'When were you thinking of going?',
    subheading: 'Enter or select a timeframe.',
    isRequired: true,
    isSubheadingRead : true,
  };

  useEffect(
    () => {
      if (programMatch.programTerms.length > 0) {
        const startDate = new Date(programMatch.programTerms[0].start_date);
        const startDateYear = startDate.getFullYear();
        const startMonthIndex = startDate.getMonth();
        const startDatemonth = startMonthIndex < 9 ? `0${startMonthIndex + 1}` : startMonthIndex + 1;

        const endDate = new Date(programMatch.programTerms[0].end_date);
        const endDateYear = endDate.getFullYear();
        const endMonthIndex = endDate.getMonth();
        const endDatemonth = endMonthIndex < 9 ? `0${endMonthIndex + 1}` : endMonthIndex + 1;

        setValue({
          endDate: programMatch.programTerms[0].end_date,
          startDate: programMatch.programTerms[0].start_date,
          startDateMonth:startDatemonth, 
          startDateYear:startDateYear,
          endDateMonth:endDatemonth, 
          endDateYear:endDateYear 
        });
      }
    },
    [programMatch.programTerms],
  );

  const handleNext = () => {
    setError("");
    let startDate = "";
    let endDate = "";
    if(value.startDateMonth && value.startDateYear) {
      startDate = `${value.startDateYear}-${value.startDateMonth}-01`;
    }

    if(value.endDateMonth && value.endDateYear) {
      endDate = `${value.endDateYear}-${value.endDateMonth}-01`;
    }
    
    if (!startDate && !endDate) {
      setTimeout(()=>{
        setError({
          endDate: 'Select a return date.',
          startDate: 'Select a departure date.',
        });
      },0)
      throw new Error();
    } 
    else if (!startDate) {
      setTimeout(()=>{
        setError({ startDate: 'Select a departure date.' });
      },0)
      throw new Error();
    } else if (!endDate) {
      setTimeout(()=>{
        setError({ endDate: 'Select a return date.' });
      },0)
      throw new Error();
    }else if(endDate < startDate){
      setTimeout(()=>{
        setError({ endDate: 'Return date must be greater than departure date.' });
      },0)
      throw new Error();
    }else{
      dispatch(
        addProgramMatchProgramTerm(userId, [
          {
            end_date: endDate,
            start_date: startDate,
          },
        ]),
      );
    }




    // if (!value.startDate && !value.endDate) {
    //   setTimeout(()=>{
    //     setError({
    //       endDate: 'Select a return date.',
    //       startDate: 'Select a departure date.',
    //     });
    //   },0)
    //   throw new Error();
    // } else if (!value.startDate) {
    //   setTimeout(()=>{
    //     setError({ startDate: 'Select a departure date.' });
    //   },0)
    //   throw new Error();
    // } else if (!value.endDate) {
    //   setTimeout(()=>{
    //     setError({ endDate: 'Select a return date.' });
    //   },0)
    //   throw new Error();
    // }else if(value.endDate < value.startDate){
    //   setTimeout(()=>{
    //     setError({ endDate: 'Return date must be greater than departure date.' });
    //   },0)
    //   throw new Error();
    // }else{
    //   dispatch(
    //     addProgramMatchProgramTerm(userId, [
    //       {
    //         end_date: value.endDate,
    //         start_date: value.startDate,
    //       },
    //     ]),
    //   );
    // }
  };

  const handleOnChange = (event) => {
    setValue({ ...value, [event.target.name] : event.target.value });
  };

  return (
    <TravelerFormQuestionContainer question={question} handleNext={handleNext}>
      <Container>
        <StartDateContainer>
          <Datecontainer>
            <div className="select-dropdown">
              <ScreenReaderLabel htmlFor="start_date_month">Select Start Date Month</ScreenReaderLabel>
              <Form.Select className="form-select-date" 
                onChange={handleOnChange} 
                aria-label="Select Start Date Month" 
                id="start_date_month"
                name="startDateMonth"
                value={value.startDateMonth}
              >
                <option value="">Select Month</option>
                { monthsArray.map((month)=>{
                    return <option key={`start-${month.value}`} value={month.value} aria-label={month.ariaLabel}>{month.label}</option>
                })}
              </Form.Select>
              <div className="separator"></div>
            </div>
            <div className="select-dropdown">
              <ScreenReaderLabel htmlFor="start_date_year">Select Start Date Year</ScreenReaderLabel>
              <Form.Select 
                value={value.startDateYear} 
                style={{marginLeft:"20px"}} 
                className="form-select-date" 
                id="start_date_year"
                aria-label="Select Start Date Year" 
                onChange={handleOnChange} 
                name="startDateYear"
              >
                <option value="">Select Year</option>
                { years.map((year)=>{
                    return <option key={`start-${year}`} value={year}>{year}</option>
                })}
              </Form.Select>
              <div className="separator" style={{right:"12px"}}></div>
            </div>
        </Datecontainer>
        {error.startDate && <Error message={error.startDate} />}
        </StartDateContainer>
        {/* <DatePickerInput
          id="departure_date"
          value={value.startDate}
          label="Depart"
          className="date-picker"
          error={error.startDate}
          disablePast
          ariaLabel={"Date picker of depart"}
          handleOnChange={event => {
            handleOnChange(event, true);
          }}
        /> */}
        <Separator style={{paddingBottom:error.endDate ? "24px":"0px"}}>to</Separator>

        <EndDateContainer>
          <Datecontainer>
          <div className="select-dropdown">
            <ScreenReaderLabel htmlFor="end_date_month">Select End Date Month</ScreenReaderLabel>
            <Form.Select 
            className="form-select-date" 
            id="end_date_month"
            onChange={handleOnChange} 
            aria-label="Select End Date Month" 
            name="endDateMonth"
            value={value.endDateMonth}
            >
              <option value="">Select Month</option>
              { monthsArray.map((month)=>{
                  return <option key={`end-${month.value}`} value={month.value} aria-label={month.ariaLabel}>{month.label}</option>
              })}
            </Form.Select>
            <div className="separator"></div>
          </div>
          <div className="select-dropdown">
            <ScreenReaderLabel htmlFor="end_date_year">Select End Date Year</ScreenReaderLabel>
            <Form.Select 
              style={{marginLeft:"20px"}} 
              id="end_date_year"
              className="form-select-date" 
              aria-label="Select End Date Year" 
              onChange={handleOnChange} 
              name="endDateYear" 
              value={value.endDateYear}
            >
              <option value="">Select Year</option>
              { years.map((year)=>{
                  return <option key={`end-${year}`} value={year}>{year}</option>
              })}
            </Form.Select>
            <div className="separator" style={{right:"12px"}}></div>
          </div>
          </Datecontainer>
          {error.endDate && <Error message={error.endDate} />}
        </EndDateContainer>
        {/* <DatePickerInput
          id="return_date"
          value={value.endDate}
          label="Return"
          className="date-picker"
          error={error.endDate}
          disablePast
          ariaLabel={"Date picker of return"}
          handleOnChange={event => {
            handleOnChange(event, false);
          }}
        /> */}
      </Container>
    </TravelerFormQuestionContainer>
  );
}
