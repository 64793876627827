import React from 'react';

export default function CheckmarkCircleIcon() {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
      <path
        d="M6.86438 0.5C3.2818 0.5 0.367065 3.41594 0.367065 7C0.367065 10.5841 3.2818 13.5 6.86438 13.5C10.447 13.5 13.3617 10.5841 13.3617 7C13.3617 3.41594 10.447 0.5 6.86438 0.5ZM10.2458 4.82156L6.04753 9.82156C6.00148 9.87644 5.94417 9.92077 5.8795 9.95156C5.81483 9.98234 5.7443 9.99886 5.67269 10H5.66425C5.5942 9.99997 5.52493 9.98522 5.46095 9.95668C5.39696 9.92815 5.33969 9.88648 5.29284 9.83438L3.49359 7.83438C3.44789 7.78589 3.41235 7.72876 3.38904 7.66633C3.36573 7.60391 3.35513 7.53745 3.35786 7.47087C3.36059 7.40429 3.3766 7.33892 3.40495 7.27862C3.43329 7.21831 3.4734 7.16429 3.52291 7.11971C3.57242 7.07514 3.63034 7.04091 3.69327 7.01905C3.75619 6.99719 3.82285 6.98813 3.88932 6.9924C3.95579 6.99668 4.02074 7.01421 4.08034 7.04396C4.13995 7.0737 4.19301 7.11507 4.2364 7.16562L5.65113 8.73812L9.48049 4.17844C9.56638 4.07909 9.68791 4.01754 9.8188 4.00711C9.94969 3.99668 10.0794 4.03819 10.18 4.12268C10.2805 4.20717 10.3438 4.32784 10.3561 4.45862C10.3684 4.5894 10.3288 4.71977 10.2458 4.82156Z"
        fill="#2A8A53"
      />
    </svg>
  );
}
