import styled from 'styled-components';

export const ReadOnlyBanner = styled.div`
  background-color: #a1d8e5;
  color: #373737;
  font-family: AvenirNextMedium, sans-serif;
  font-size: 14px;
  height: 25px;
  line-height: 1.8;
  margin: auto auto 10px auto;
  text-align: center;
  width: 50%;
`;