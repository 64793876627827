import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { ThemeProvider } from 'styled-components';
import {
  AccentButtonMediumOpenSans,
  ButtonMediumOpenSans,
} from '../../../../../styledComponents/styles/Buttons.styled';
import { themeVia } from '../../../../../styledComponents/settings/theme';
import ToastMessage from '../../../../../sites/viaGlobal/components/toastMessage';
import Modal from 'react-responsive-modal';
import { compose } from 'recompose';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import modalCss from 'react-responsive-modal/styles.css';
import hearticon from './../../../../../shared/images/heart-icon.png';

export const enhance = compose(withStyles(modalCss));

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-family: Open Sans, sans-serif;
  font-size: 16px;
  font-weight: 400;
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  background-color: #ebebeb;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  button {
    margin: 15px;
  }
`;

const FooterButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 20px;
  button {
    margin: 15px;
  }
`;
const Header = styled.h1`
  color: #0b3544;
  font-size: 25px;
  padding: 20px 30px;
  padding-bottom: 20px;
  border-bottom: 3px solid #ebebeb;
  font-weight: 300;
`;

const Instructions = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #373737;
  width: 90%;
  margin: 15px auto;
`;

const ImageTag = styled.img`
  width: 100%;
`;
const LeftContainer = styled.div`
  display: flex;
  float: left;
  width: 30%;
  justify-content: center;
  align-items: center;
  height: 118px;
`;
const RightContainer = styled.div`
  display: block;
  float: right;
  width: 70%;
`;
const Clear = styled.div`
  clear: both;
`;
const BoldInstructions = styled.div`
  font-weight: 600;
  margin-bottom: 20px;
`;
const NormalInstructions = styled.div`
  margin-bottom: 20px;
`;

const SmallText = styled.div`
  font-size: 12px;
`;

const LinkTag = styled.a`
  text-decoration: none;
  color: #124a67;
  &:hover {
    text-decoration: none;
  }
`;

const closeIcon = (
  <svg width="20" height="21" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M24.5 2.41714L22.0829 0L12.5 9.58286L2.91714 0L0.5 2.41714L10.0829 12L0.5 21.5829L2.91714 24L12.5 14.4171L22.0829 24L24.5 21.5829L14.9171 12L24.5 2.41714Z"
      fill="#9FABAE"
    />
  </svg>
);

function FavoriteProgramModal(props) {
  const customAliases = useSelector(state => state.profile.customAliases);

  const handleClose = () => {
    props.onClose(false);
  };

  const handleFavoriteSubmit = () => {
    props.onSubmit();
    props.onClose(false);
  };

  return (
    <ThemeProvider theme={themeVia}>
      {/* <ViaModal showing={props.show} onClose={() => handleClose()} width={'519px'}> */}
      <Modal
        open={props.show}
        center
        onClose={handleClose}
        styles={{
          modal: {
            width: '519px',
            padding: '0px',
            borderRadius: '10px',
          },
          closeButton: {
            top: '24px',
            right: '24px',
          },
        }}
        closeIcon={closeIcon}
      >
        <FlexContainer>
          <Header>
            About {customAliases.alias_favorite || 'Favorite'} {customAliases.alias_programs}
          </Header>
          <Instructions>
            <div>
              <LeftContainer>
                <ImageTag src={hearticon} />
              </LeftContainer>
              <RightContainer>
                <BoldInstructions>
                  By selecting "{customAliases.alias_favorite || 'Favorite'}" you can save programs to filter, revisit
                  or let {customAliases.alias_program} admins know you're interested.
                </BoldInstructions>
                <NormalInstructions>
                  This tool will help you find {customAliases.alias_programs} you like, and plan your study abroad
                  journey effectively.
                </NormalInstructions>
              </RightContainer>
              <Clear />
            </div>
            <SmallText>
              By using the favorite tool, you consent to share your contact information to your selected{' '}
              {customAliases.alias_program} administrators to receive additional {customAliases.alias_program}{' '}
              information. Learn more in our{' '}
              <LinkTag href="https://www.viatrm.com/privacy-policy/" target="_blank">
                privacy policy
              </LinkTag>
              .
            </SmallText>
          </Instructions>
          <FooterContainer>
            <FooterButtonContainer>
              <ButtonMediumOpenSans
                aria-label="Cancel"
                onClick={handleClose}
                style={{
                  backgroundColor: '#CCCCCC',
                  borderRadius: '5px',
                  border: '1px solid #CCCCCC',
                }}
              >
                Cancel
              </ButtonMediumOpenSans>
              <AccentButtonMediumOpenSans
                aria-label="Active Automation"
                onClick={handleFavoriteSubmit}
                style={{
                  borderRadius: '5px',
                }}
              >
                Okay
              </AccentButtonMediumOpenSans>
            </FooterButtonContainer>
          </FooterContainer>
        </FlexContainer>
      </Modal>
    </ThemeProvider>
  );
}

export default enhance(FavoriteProgramModal);
