import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { compose } from 'recompose';
import propTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import Headline from '../../../../../components/library/headline';
import Button from '../../../../../components/library/button';
import Paragraph from '../../../../../components/library/paragraph';
import sBatchAddTravelerToPlan from './batchAddTravelerToPlan.scss';
import { addTravelerToPlan } from '../../../../../../actions/plans';
import inviteSampleUrl from '../../../../../../../public/files/traveler_invite.csv';
import Dropzone from 'react-dropzone';
import AddOneTravelerToPlan from '../addOneTravelerToPlan';

export const enhance = compose(withStyles(sBatchAddTravelerToPlan));

function BatchAddTravelerToPlan(props) {
  const [saving, setSaving] = useState(false);
  const [showSoloAdd, setShowSoloAdd] = useState(false);
  const [extensionError, setExtensionError] = useState(false);
  const [file, setFile] = useState(null);
  const dispatch = useDispatch();

  const onDrop = files => {
    let file = files[0];
    let extension = file.name
      .split('.')
      .pop()
      .toLowerCase();

    if (extension !== 'csv') {
      setExtensionError(true);
    } else if (extension === 'csv') {
      setExtensionError(false);
    }

    setFile(file);
  };

  const handleBatchInviteTravelers = () => {
    setSaving(true);
    if (file && !extensionError) {
      const reader = new FileReader();

      reader.readAsBinaryString(file);

      reader.onload = () => {
        const fileAsBinaryString = reader.result;
        dispatch(addTravelerToPlan(props.planId, fileAsBinaryString, 'csv_upload'));
      };
    }
  };

  return (
    <>
      {showSoloAdd ? (
        <AddOneTravelerToPlan
          planId={props.planId}
          onClose={() => {
            props.onClose(false);
          }}
        />
      ) : (
        <div className={sBatchAddTravelerToPlan['batch-add-traveler-to-plan']}>
          <Headline tag="h2" as="h2" centered>
            Upload a .CSV file to add multiple Travelers
          </Headline>
          <div className={sBatchAddTravelerToPlan.downloadContainer}>
            <a href={inviteSampleUrl} target="_blank" download="InvitationTemplate">
              Download Batch Template
            </a>
          </div>
          <div className={sBatchAddTravelerToPlan.dropzoneContainer}>
            <Dropzone onDrop={onDrop} activeStyle={{ backgroundColor: 'rgba(255, 255, 255, 0.4)' }}>
              <Paragraph>{file ? file.name : 'Click or drag a CSV file here'}</Paragraph>
            </Dropzone>
          </div>
          {extensionError && (
            <div style={{ width: '30%', margin: '0 auto' }}>
              <div role="alert">
                <Paragraph color="error" size="small">
                  The file uploaded must have a .CSV extension
                </Paragraph>
              </div>
            </div>
          )}
          <div className={sBatchAddTravelerToPlan.downloadContainer} onClick={() => setShowSoloAdd(true)}>
            <Paragraph>Add a Single Traveler</Paragraph>
          </div>

          <div>
            <div className={sBatchAddTravelerToPlan['button-row']}>
              <div className={sBatchAddTravelerToPlan['button-content']}>
                <Button
                  display="secondary"
                  kind="solid"
                  size="medium"
                  onClick={() => {
                    props.onClose(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
              {file && !extensionError ? (
                <div className={sBatchAddTravelerToPlan['button-content']}>
                  <Button
                    display="primary"
                    kind="solid"
                    size="medium"
                    onClick={() => {
                      handleBatchInviteTravelers();
                      props.onClose(false);
                    }}
                  >
                    Upload
                  </Button>
                </div>
              ) : (
                <div className={sBatchAddTravelerToPlan['button-content']}>
                  <Button display="secondary" kind="solid" size="medium" disabled>
                    Upload
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

BatchAddTravelerToPlan.propTypes = {
  show: propTypes.bool,
  onClose: propTypes.func.isRequired,
  planId: propTypes.string.isRequired,
};

export default enhance(BatchAddTravelerToPlan);
