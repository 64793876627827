exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@-webkit-keyframes _2v7tp{to{-webkit-transform:rotate(1turn)}}@keyframes _2v7tp{to{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}._1B7K1{background-color:#ebebeb;color:#043544;width:100%;padding:16px 96px;font-size:16px;line-height:1;font-weight:500}@media only screen and (max-width:599px){._1B7K1{padding:16px 4px;font-size:12px}}._1B7K1 ._3dQgn{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-wrap:wrap;flex-wrap:wrap;width:100%;-ms-flex-align:center;align-items:center}._1B7K1 ._3dQgn ._1yo_q{padding:0 16px}._1B7K1 ._3dQgn ._3QCwX{color:#043544;text-decoration:none}._1B7K1 ._3dQgn ._3nVGG{color:#1c748a;text-decoration:underline}", ""]);

// exports
exports.locals = {
	"subheader": "_1B7K1",
	"subheader-content": "_3dQgn",
	"item": "_1yo_q",
	"selection": "_3QCwX",
	"selected": "_3nVGG",
	"spin": "_2v7tp"
};