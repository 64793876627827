import styled from 'styled-components';

const DocumentContent = styled.div`
  height: 100%;
  background-color: #fbfbfb;
  border-radius: 0 0 5px 5px;
  box-shadow: 0 10px 20px rgba(132, 131, 131, 0.3);
  font-family: 'Open Sans', sans-serif;

  @media (max-width: 775px) {
    display: block;
    padding: 30px 0 180px 0;
  }

  @media (min-width: 775px) {
    padding: 30px 50px 180px 50px;
  }
`;

export default DocumentContent;
