import Yes from '../../components/icons/Yes';
import Curious from '../../components/icons/Curious';
import Confetti from '../../components/icons/Confetti';
import DoNotKnow from '../../components/icons/DoNotKnow';
import ChatBubbles from '../../components/icons/ChatBubbles';

export default [
  {
    id: 1,
    label: 'Going no matter what',
    value: 'no_matter_what',
    icon: Confetti,
    numberValue: 4,
  },
  {
    id: 2,
    label: 'Likely to go',
    value: 'i_will_go',
    icon: Yes,
    numberValue: 3,
  },
  {
    id: 3,
    label: 'Maybe, if I get answers',
    value: 'have_questions',
    icon: ChatBubbles,
    numberValue: 2,
  },
  {
    id: 4,
    label: `I'm not sure`,
    value: 'possibly_unable',
    icon: DoNotKnow,
    numberValue: 1,
  },
  {
    id: 5,
    label: 'Just curious',
    value: 'just_curious',
    icon: Curious,
    numberValue: 0,
  },
];
