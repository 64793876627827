exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@-webkit-keyframes _2BACe{to{-webkit-transform:rotate(1turn)}}@keyframes _2BACe{to{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}._1trcL ._1m2OT{-ms-flex:1 1;flex:1 1;-ms-flex-direction:row;flex-direction:row;display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;padding:16px}@media only screen and (max-width:599px){._1trcL ._1m2OT{-ms-flex-direction:column;flex-direction:column}}._1trcL ._3i2rs{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;padding:40px 100px;-ms-flex-pack:center;justify-content:center}._1trcL ._3i2rs .qXAw2{padding:12px}._1trcL ._14VDl{-ms-flex:1 1;flex:1 1;-ms-flex-direction:column;flex-direction:column;display:-ms-flexbox;display:flex;border-top:2px solid #ebebeb;padding:32px 20px 0 0;-ms-flex-pack:center;justify-content:center}@media only screen and (max-width:599px){._1trcL ._14VDl{-ms-flex-direction:column;flex-direction:column}}._1trcL ._14VDl ._3b-pX{padding:20px 0;display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-pack:center;justify-content:center}._1trcL ._14VDl ._3b-pX ._2XOlu{padding-left:12px}@media only screen and (max-width:599px){._1trcL ._14VDl ._3b-pX ._2XOlu{padding-top:12px}}._1trcL ._14VDl ._3YIsJ{-ms-flex:1 1;flex:1 1}", ""]);

// exports
exports.locals = {
	"joined-group": "_1trcL",
	"subHeader": "_1m2OT",
	"content": "_3i2rs",
	"toggle": "qXAw2",
	"footer": "_14VDl",
	"footer-button-wrapper": "_3b-pX",
	"button-content": "_2XOlu",
	"footer-confirm": "_3YIsJ",
	"spin": "_2BACe"
};