import removeReadOnlyFields from './removeReadonlyFields';

function getDefaultProfileFields(state) {
  return {
    last_name: state.profile.last_name,
    first_name: state.profile.first_name,
    middle_name: state.profile.middle_name,
  };
}

export default function constructUpdatePayload(state, actionPayload) {
  const defaultProfileFields = getDefaultProfileFields(state);
  const payload = {
    custom_fields: actionPayload.travelerProfile?.customFields || {},
    profile: actionPayload.profile
      ? { ...defaultProfileFields, ...actionPayload.profile }
      : { ...defaultProfileFields },
    traveler_profile: actionPayload.travelerProfile
      ? { ...state.info, ...actionPayload.travelerProfile }
      : { ...state.info },
    passport: actionPayload.passport
      ? { ...state.passportStatus, ...actionPayload.passport }
      : { ...state.passportStatus },
  };

  return removeReadOnlyFields(payload, state.readonly);
}
