import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import RequestToWithdrawAcceptModal from './RequestToWithdrawAcceptModal';
import RequestToWithdrawDeclineModal from './RequestToWithdrawDeclineModal';
import { getRelativeTime } from '../../helpers/RiskAlertHelper';

const Container = styled.div`
  border-radius: 5px;
  background: #ffefd4;
  border-left: 20px solid #ddaf64;
  padding: 8px 10px 15px 10px;
`;

const Timing = styled.div`
  color: #666;
  /* Caption */
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 14.4px */
  letter-spacing: 0.048px;
  text-align: right;
`;

const Heading = styled.h2`
  color: #4d4d4d;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 19.2px */
  letter-spacing: 0.08px;
  margin-bottom: 6px;
`;

const Description = styled.div`
  color: #4d4d4d;
  font-family: Open Sans;
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: 0.08px;
  margin-bottom: 15px;
  overflow-wrap: break-word;
`;

const Separator = styled.span`
  color: #446372;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: 0.07px;
`;

const LinkButton = styled.button`
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: 0.07px;
  text-decoration-line: underline;
  padding: 0;
  outline: none;
  border: none;
  background: transparent;
`;

const ApproveBtn = styled(LinkButton)`
  color: var(--success-text, #0e5b2f);
`;

const DeclineBtn = styled(LinkButton)`
  color: var(--error-text, #8b1313);
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
`;

function RequestToWithdraw({ submissionId, submissionWithdrawalRequestId, reason, date }) {
  const profile = useSelector(state => state.profile);
  const [approve, setApprove] = useState(false);
  const [decline, setDecline] = useState(false);

  return (
    <Container>
      <Timing>{getRelativeTime(date, profile?.orgTimezone)} </Timing>
      <Heading>Requested to Withdraw</Heading>
      {reason && <Description>Reason: “{reason}”</Description>}

      <ButtonContainer>
        <ApproveBtn onClick={() => setApprove(true)} type="button">
          Approve & Withdraw
        </ApproveBtn>
        <Separator>|</Separator>
        <DeclineBtn onClick={() => setDecline(true)} type="button">
          Decline Request
        </DeclineBtn>
      </ButtonContainer>
      <RequestToWithdrawAcceptModal
        open={approve}
        onClose={() => setApprove(false)}
        submissionId={submissionId}
        submissionWithdrawalRequestId={submissionWithdrawalRequestId}
      />
      <RequestToWithdrawDeclineModal
        open={decline}
        onClose={() => setDecline(false)}
        submissionId={submissionId}
        submissionWithdrawalRequestId={submissionWithdrawalRequestId}
      />
    </Container>
  );
}

export default RequestToWithdraw;
