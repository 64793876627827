exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@-webkit-keyframes _1gzD7{to{-webkit-transform:rotate(1turn)}}@keyframes _1gzD7{to{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}._3CP8R ._1UQpK{-ms-flex:1 1;flex:1 1;-ms-flex-direction:row;flex-direction:row;display:-ms-flexbox;display:flex;padding:16px 76px;margin-bottom:16px}@media only screen and (max-width:599px){._3CP8R ._1UQpK{-ms-flex-direction:column;flex-direction:column}}._3CP8R ._2imWM{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;padding:12px 76px;-ms-flex-pack:center;justify-content:center}._3CP8R ._2imWM ._1HVkq{padding-bottom:16px;cursor:pointer}._3CP8R ._2imWM ._2cjC_{padding:5px 0}._3CP8R ._2RfPB{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;padding:12px 76px;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center}._3CP8R ._2RfPB input{margin-right:12px}._3CP8R ._1Xv7u{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;padding:12px 76px}._3CP8R ._1Xv7u input{margin:4px 12px 0 0}._3CP8R .SrdWR{-ms-flex:1 1;flex:1 1;-ms-flex-direction:column;flex-direction:column;display:-ms-flexbox;display:flex;border-top:4px solid #ebebeb;padding:32px 20px 0 0;-ms-flex-pack:center;justify-content:center}@media only screen and (max-width:599px){._3CP8R .SrdWR{-ms-flex-direction:column;flex-direction:column}}._3CP8R .SrdWR ._2nRrO{padding-left:12px}@media only screen and (max-width:599px){._3CP8R .SrdWR ._2nRrO{padding-top:12px}}._3CP8R .SrdWR ._2iClX{padding:20px 0;display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-pack:center;justify-content:center}._3CP8R .SrdWR ._2iClX ._2nRrO{padding-left:12px}@media only screen and (max-width:599px){._3CP8R .SrdWR ._2iClX ._2nRrO{padding-top:12px}}._3CP8R .SrdWR ._3zAAL{-ms-flex:1 1;flex:1 1}", ""]);

// exports
exports.locals = {
	"send-safecheck-message": "_3CP8R",
	"subHeader": "_1UQpK",
	"content": "_2imWM",
	"toggle-link": "_1HVkq",
	"itoggle": "_2cjC_",
	"checkbox": "_2RfPB",
	"emailCheckbox": "_1Xv7u",
	"footer": "SrdWR",
	"button-content": "_2nRrO",
	"footer-button-wrapper": "_2iClX",
	"footer-confirm": "_3zAAL",
	"spin": "_1gzD7"
};