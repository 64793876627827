import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './Table.scss';
import Auxiliary from '../../hoc/Auxiliary';

const Table = props => (
  <Auxiliary>
    {props.displayNoRecordFound ? (
      <h1 className={s.noRecordText}>{props.noRecordText}</h1>
    ) : (
      <table className={props.tableSource === 'tagcontent' ? s.orgSettingTerms : s.table}>
        {props.thead}
        {props.tbody}
      </table>
    )}
  </Auxiliary>
);

export default withStyles(s)(Table);
