import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import _get from 'lodash/get';

import DropdownSelect from '../../../../../styledComponents/styles/DropdownSelect.styled';
import StyledIcon from '../../../../../styledComponents/styles/Icon.styled';
import { AccentButtonMediumNunito } from '../../../../../styledComponents/styles/Buttons.styled';
import { AccentButtonSpinner } from '../../../../../styledComponents/styles/spinner.styled';
import { updateForm, updateQuestion } from '../../../../../actions/formsActions';
import { FORMS } from '../../../../../helpers/constants';

const FormContainer = styled.div`
  font-family: 'Nunito', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 0.015em;
  color: #373737;
`;

const InputContainer = styled.div`
  flex: 1;
  margin: 20px 0;
  text-align: left;
`;

const SubmitContainer = styled.main`
  display: flex;
  justify-content: flex-end;
  margin-top: 35px;
`;

const QuestionDividerAddEdit = ({ question, edit, onClose }) => {
  const { DIVIDER_COLOR } = FORMS;
  const qIdentifier = 'divider';
  const dispatch = useDispatch();
  const fetchForm = useSelector(state => state.forms.fetchForm);
  const questionTypesInfo = useSelector(state => state.forms.questionTypes);
  const updateFormSubmit = useSelector(state => state.forms.updateForm);
  const updateQuestionSubmit = useSelector(state => state.forms.updateQuestion);
  const [formId, setFormId] = useState(null);
  const [disableSubmit, setDisableSubmit] = useState(false);

  const schema = yup.object().shape({
    divider_weight_option: yup.string().required('Divider must have weight'),
    divider_color_option: yup.string().required('Divider must have color'),
  });

  var borderColor = 0;
  if(edit && question?.border_color?.toLowerCase()==="accent"){
    borderColor = 1;
  } else if(edit && question?.border_color?.toLowerCase()==="grey"){
    borderColor = 2;
  }

  const { control, handleSubmit, formState, reset, setValue } = useForm({
    shouldUnregister: false,
    resolver: yupResolver(schema),
    defaultValues: {
      divider_weight_option: edit ? question.weight : '3',
      divider_color_option: edit ? borderColor : DIVIDER_COLOR.PRIMARY,
    },
    mode: 'onChange',
  });

  const lineWeightOptions = [
    { value: 1, label: '1' }, 
    { value: 2, label: '2' }, 
    { value: 3, label: '3' },
    { value: 4, label: '4' }, 
    { value: 5, label: '5' }, 
    { value: 6, label: '6' },
  ];

  const lineColorOptions = [
    { value: DIVIDER_COLOR.PRIMARY, label: 'Primary' }, 
    { value: DIVIDER_COLOR.ACCENT, label: 'Accent' }, 
    { value: DIVIDER_COLOR.GREY, label: 'Grey' },
  ];

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset({}, { keepValues: true });
    }
  }, [formState]);

  useEffect(() => {
    if (!fetchForm?.loading && fetchForm?.data) {
      setDisableSubmit(false);
      setFormId(fetchForm.data.id);
    }
  }, [fetchForm]);

  useEffect(() => {
    if (
      (!updateFormSubmit?.loading && updateFormSubmit?.data) ||
      (!updateQuestionSubmit?.loading && updateQuestionSubmit?.data)
    ) {
      setDisableSubmit(false);
      onClose();
    }
  }, [updateFormSubmit, updateQuestionSubmit]);

  const onSubmitHandler = data => {
    if (questionTypesInfo && questionTypesInfo.data) {
      const questionType = questionTypesInfo.data.reduce((prev, curr) => {
        return prev || curr.attributes.question_types.find(question => question.identifier === qIdentifier);
      }, undefined);

      let selectedWeight = lineWeightOptions
        .filter(option => {
          return option.value.toString() === data.divider_weight_option;
        })[0]
        .label.toLowerCase();

      let selectedColor = lineColorOptions
        .filter(option => {
          return option.value.toString() === data.divider_color_option;
        })[0]
        .label.toLowerCase();

      const values = {
        weight: selectedWeight,
        border_color: selectedColor,
      };

      if (formId && data && (questionType?.id || question?.id)) {
        //update or save new
        const actionToCall = edit ? updateQuestion : updateForm;
        const idToUpdate = edit ? question.id : questionType.id;
        setDisableSubmit(true);
        dispatch(actionToCall(formId, questionType.endpoint, idToUpdate, values));
      }
    }
  };

  return (
    <FormContainer>
      <form id='dividerAdd' onSubmit={handleSubmit(onSubmitHandler)}>
        <Controller
          control={control}
          name='divider_weight_option'
          render={({ field: { onChange, value, name, ref, ...rest } }) => (
            <InputContainer>
              <DropdownSelect
                aria-label="Divider Weight Option Dropdown"
                errorMsg={_get(formState.errors.divider_weight_option, 'message') || ''}
                placeholder='Divider Weight'
                label='Divider Weight'
                id='divider_weight_option'
                options={lineWeightOptions}
                onChange={value => {
                  setValue('divider_weight_option', value, {
                    shouldValidate: true,
                    shouldDirty: true,
                    shouldTouch: true,
                  });
                  onChange(value);
                }}
                defaultValue={
                  edit
                    ? lineWeightOptions.filter(option => option.value === question.weight)
                    : lineWeightOptions[2]
                }
                {...rest}
              />
            </InputContainer>
          )}
        />

        <Controller
          control={control}
          name='divider_color_option'
          render={({ field: { onChange, value, name, ref, ...rest } }) => (
            <InputContainer>
              <DropdownSelect
                aria-label="Border Color Option Dropdown"
                errorMsg={_get(formState.errors.divider_color_option, 'message') || ''}
                placeholder='Border Color'
                label='Border Color'
                id='divider_color_option'
                options={lineColorOptions}
                onChange={value => {
                  setValue('divider_color_option', value, {
                    shouldValidate: true,
                    shouldDirty: true,
                    shouldTouch: true,
                  });
                  onChange(value);
                }}
                defaultValue={
                  edit
                    ? lineColorOptions.filter(option => option.value === borderColor)
                    : lineColorOptions[0]
                }
                {...rest}
              />
            </InputContainer>
          )}
        />

        <SubmitContainer>
          <AccentButtonMediumNunito
            aria-label={edit ? 'Save Divider' : 'Add Divider to Form'}
            form="dividerAdd"
            type='submit'
            disabled={disableSubmit}
          >
            {disableSubmit ? <AccentButtonSpinner displayText='Saving ...' /> : edit ? 'Save' : 'Add to Form'}
            {!disableSubmit && <StyledIcon type='ChevronForward' color='#fff' />}
          </AccentButtonMediumNunito>
        </SubmitContainer>
      </form>
    </FormContainer>
  );
}
QuestionDividerAddEdit.displayName = 'QuestionDividerAddEdit';

export default QuestionDividerAddEdit;
