import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ProfileSelectInput from '../../../base/inputs/ProfileSelectInput';
import ProfileDatePickerInput from '../../../base/inputs/ProfileDatePickerInput';

import { breakpoints } from '../../../../utils/breakpoints';
import filterDateRangeOptions from '../../../../utils/options/filterDateRangeOptions';

const Container = styled.fieldset`
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  margin-bottom: 1.5625rem;
  padding-left: 1.5625rem;

  &:last-of-type {
    margin-bottom: 0;
  }

  @media ${breakpoints.desktop} {
    padding-right: 3rem;
    padding-left: 2rem;

    &:last-of-type {
      margin-bottom: 1.5625rem;
    }
  }
`;

const Heading = styled.legend`
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  color: #373737;
  line-height: 120%;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5625rem;

  @media ${breakpoints.desktop} {
    padding-left: 1rem;
  }
`;

const InclusiveSpan = styled.span`
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  color: #373737;
  text-align: center;
  line-height: 120%;
`;

export default function DatesFilterInputs({
  endDate,
  endRange,
  startDate,
  startRange,
  endDateInclusive,
  setFocusTrapPaused,
  startDateInclusive,
  handleEndDateChange,
  handleEndRangeChange,
  handleStartDateChange,
  handleStartRangeChange,
  handleEndDateInclusiveChange,
  handleStartDateInclusiveChange,
}) {
  const minDate = new Date(new Date().getFullYear() - 20, 0, 1).toString();
  const maxDate = new Date(new Date().getFullYear() + 20, 0, 1).toString();

  return (
    <>
      <Container>
        <Heading>Start Date:</Heading>
        <InputContainer>
          <ProfileSelectInput
            id="start_date_range"
            value={startRange}
            label="Start Date Range"
            hideLabel
            placeholder="Select a date range"
            options={filterDateRangeOptions}
            handleOnChange={handleStartRangeChange}
          />
          <ProfileDatePickerInput
            id="start_date"
            value={startDate}
            label="Start Date"
            hideLabel
            minDate={minDate}
            maxDate={maxDate}
            handleOnChange={handleStartDateChange}
            setFocusTrapPaused={setFocusTrapPaused}
          />
          {startRange.value === 'between_inclusive' && (
            <>
              <InclusiveSpan>and</InclusiveSpan>
              <ProfileDatePickerInput
                id="start_date_inclusive"
                value={startDateInclusive}
                label="Start Date Inclusive"
                hideLabel
                minDate={minDate}
                maxDate={maxDate}
                handleOnChange={handleStartDateInclusiveChange}
                setFocusTrapPaused={setFocusTrapPaused}
              />
            </>
          )}
        </InputContainer>
      </Container>
      <Container>
        <Heading>End Date:</Heading>
        <InputContainer>
          <ProfileSelectInput
            id="end_date_range"
            value={endRange}
            label="End Date Range"
            hideLabel
            placeholder="Select a date range"
            options={filterDateRangeOptions}
            handleOnChange={handleEndRangeChange}
          />
          <ProfileDatePickerInput
            id="end_date"
            value={endDate}
            label="End Date"
            hideLabel
            minDate={minDate}
            maxDate={maxDate}
            handleOnChange={handleEndDateChange}
            setFocusTrapPaused={setFocusTrapPaused}
          />
          {endRange.value === 'between_inclusive' && (
            <>
              <InclusiveSpan>and</InclusiveSpan>
              <ProfileDatePickerInput
                id="end_date_inclusive"
                value={endDateInclusive}
                label="End Date Inclusive"
                hideLabel
                minDate={minDate}
                maxDate={maxDate}
                handleOnChange={handleEndDateInclusiveChange}
                setFocusTrapPaused={setFocusTrapPaused}
              />
            </>
          )}
        </InputContainer>
      </Container>
    </>
  );
}

DatesFilterInputs.propTypes = {
  setFocusTrapPaused: PropTypes.func.isRequired,
  handleEndDateChange: PropTypes.func.isRequired,
  handleEndRangeChange: PropTypes.func.isRequired,
  handleStartDateChange: PropTypes.func.isRequired,
  handleStartRangeChange: PropTypes.func.isRequired,
  handleEndDateInclusiveChange: PropTypes.func.isRequired,
  handleStartDateInclusiveChange: PropTypes.func.isRequired,
  endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  endDateInclusive: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  startDateInclusive: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  endRange: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  startRange: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
};

DatesFilterInputs.defaultProps = {
  endDate: null,
  startDate: null,
  endDateInclusive: null,
  startDateInclusive: null,
};
