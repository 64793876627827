import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ProfileFormContainer from '../base/ProfileFormContainer';
import ProfileFormSubheading from '../base/ProfileFormSubheading';
import ChangePasswordForm from '../forms/security/ChangePasswordForm';
import ToastMessage from '../../../viaGlobal/components/toastMessage';
import { ProfileFormButton, ProfileFormButtonContainer } from '../base/ProfileFormButton.styled';
import { updateTravelerPassword } from '../../actions/travelerProfileActions';

import { Col, Grid, Row } from '../../../../styledComponents/styles/FlexGrid.styled';
import TextInput from '../../../../styledComponents/styles/TextInput.styled';
import { AccentButtonMedium } from '../../../../styledComponents/styles/Buttons.styled';
import { AccentButtonSpinner } from '../../../../styledComponents/styles/spinner.styled';
import { ButtonContainer } from '../../../../styledComponents/styles/Containers.styled';
import PasswordStrength from '../../../../styledComponents/styles/PasswordStrength.styled';
import {useForm, Controller } from 'react-hook-form';
import _get from 'lodash/get';
import { yupResolver } from '@hookform/resolvers/yup';
import { passwordSchema } from '../../../viaGlobal/components/pages/orgSettingsAccount/components/form.properties';


export default function SecurityTab() {
  const dispatch = useDispatch();

  const [error, setError] = useState('');
  const [showToast, setShowToast] = useState(false);
  const [showToastError, setShowToastError] = useState(false);
  const [isSaving ,setIsSaving] = useState(false);
  const [password, setPassword] = useState();

  const [poorPassword, setPoorPassword] = useState(false);
  const [weakPassword, setWeakPassword] = useState(false);
  const [strongPassword, setStrongPassword] = useState(false);
  const [passwordError, setPasswordErr] = useState("");
  const [passwordErrorLabel, setPasswordErrLabel] = useState("");
  const [showScreenReader, setShowScreenReader] = useState(false);

  const { control, handleSubmit, formState, getValues, register, reset, setValue } = useForm({
    shouldUnregister: false,
    resolver: yupResolver(passwordSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const onSubmitHandler = (data) => {
    
    const request = {
      "password":data.password,
      "current_password":data.existing_password
    }
    dispatch(updateTravelerPassword(request,(status, message)=>{
      if (status) {
        setShowToast(true);
      } else {
        setError(message);
        setShowToastError(true)
      }
    }));
    setValue("existing_password","");
    setValue("password","");
    setValue("confirm_password","");
    reset();
  };

  const passwordStrength = (evnt) => {
    const passwordValue = evnt.target.value;
    const passwordLength = passwordValue.length;

    const poorRegExp = /[a-z]/;
    const weakRegExp = /(?=.*?[0-9])/;
    const strongRegExp = /(?=.*?[#?!@$%^&*-])/;
    const whitespaceRegExp = /^$|\s+/;

    const poorPassword = poorRegExp.test(passwordValue);
    const weakPassword = weakRegExp.test(passwordValue);
    const strongPassword = strongRegExp.test(passwordValue);
    const whiteSpace = whitespaceRegExp.test(passwordValue);

    if (passwordValue === "") {
      //setPasswordErrLabel("Password is Empty");
    } 
    else {
      // to check whitespace
      if (whiteSpace) {
        //setPasswordErrLabel("Whitespaces are not allowed");
      }
      // to check poor password
      if (
        passwordLength <= 3 &&
        (poorPassword || weakPassword || strongPassword)
      ) {
        setPoorPassword(true);
        setPasswordErrLabel("Password Indicator");
        setPasswordErr("Poor");
      }
      // to check weak password
      if (
        passwordLength >= 4 &&
        poorPassword &&
        (weakPassword || strongPassword)
      ) {
        setWeakPassword(true);
        setPasswordErrLabel("Password Indicator");
        setPasswordErr("Fair");
      } else {
        setWeakPassword(false);
      }
      // to check strong Password
      if (
        passwordLength >= 6 &&
        poorPassword &&
        weakPassword &&
        strongPassword
      ) {
        setStrongPassword(true);
        setPasswordErrLabel("Password Indicator");
        setPasswordErr("Strong");
      } else {
        setStrongPassword(false);
      }
    }
  };

  const checkError = () => {
    setShowScreenReader(false);
    setTimeout(()=>{
      setShowScreenReader(true);
    },[500]);
  }

  return (
    <form id="traveler-change-password" onSubmit={handleSubmit(onSubmitHandler)}>
      {showScreenReader && 
        <div id="liveRegion"  style={{position: "absolute", left: "-9999px"}}>
          <div role="alert" aria-live="assertive">
              {formState.errors?.existing_password?.message && 
                <span>{formState.errors.existing_password?.message}</span> 
              }
              {formState.errors?.password?.message && 
                <span>{` ${formState.errors.password?.message}`}</span> 
              } 
              {formState.errors?.confirm_password?.message && 
                <span>{` ${formState.errors.confirm_password?.message}`}</span> 
              } 
          </div>
        </div>
      }
      <ProfileFormContainer heading="My Account">
        <div>
          <ProfileFormSubheading text="Change Password" />
          {/* <ChangePasswordForm passwordForm={passwordForm} setPasswordForm={setPasswordForm} error={error} /> */}
          <Grid>
          <Row>
            <Col size={6}>
              <Row>
                <Col size={8}>
                  <div style={{width:"80%"}}>
                  <Controller
                    control={control}
                    name={'existing_password'}
                    render={({ field: { onChange, defaultValue, value, ref } }) => (
                      <TextInput
                        sensitive 
                        id={'existing_password'}           
                        name={"existing_password"}           
                        placeholder={'Enter existing password'}
                        label={`Enter Existing Password`}
                        errors={formState.errors}
                        isDirty={formState.isDirty}
                        getValues={getValues}
                        autoComplete="off"
                        register={register}
                        readAlertCustom={true}
                        errorMsg={_get(formState.errors.existing_password, 'message') || ''}
                        onChange={(value) => { 
                            setValue("existing_password", value, {
                              shouldValidate: true,
                              shouldDirty: true,
                              shouldTouch: true,
                            });
                            onChange(value);
                        }}
                        value={value}
                        isTravelerAccount={true}
                        required    
                        aria-describedby={"password-strength"}                 
                      />
                    )}
                  />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col size={8}>
                  <div style={{width:"80%"}}>
                  <Controller
                    control={control}
                    name={'password'}
                    render={({ field: { onChange, defaultValue, value, ref } }) => (
                    <TextInput
                      sensitive     
                      autoComplete="off"                 
                      placeholder={'Enter new password'}
                      label={`Enter New Password`}
                      id={'password'}           
                      name={"password"}        
                      disabled={isSaving}
                      saving={isSaving}
                      errors={formState.errors}
                      onInput={passwordStrength}
                      isDirty={formState.isDirty}
                      getValues={getValues}
                      register={register}
                      isPasswordField={true}
                      readAlertCustom={true}
                      errorMsg={_get(formState.errors.password, 'message') || ''}
                      onChange={(value) => { 
                          setValue("password", value, {
                            shouldValidate: true,
                            shouldDirty: true,
                            shouldTouch: true,
                          });
                          setPassword(value)
                          onChange(value);
                      }}
                      value={value}
                      isTravelerAccount={true}
                      required
                      aria-describedby={"password-strength"}
                    />
                    )}
                  />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col size={8}>
                  <div style={{width:"80%"}}>
                  <Controller
                    control={control}
                    name={'confirm_password'}
                    render={({ field: { onChange, defaultValue, value, ref } }) => (
                    <TextInput
                      autoComplete="off"
                      sensitive
                      placeholder={'Reenter new password'}
                      id={'confirm-password'} 
                      label={`Reenter New Password`}
                      disabled={isSaving}
                      saving={isSaving}
                      errors={formState.errors}
                      isDirty={formState.isDirty}
                      getValues={getValues}
                      register={register}
                      readAlertCustom={true}
                      errorMsg={_get(formState.errors.confirm_password, 'message') || ''}
                      onChange={(value) => { 
                          setValue("confirm_password", value, {
                            shouldValidate: true,
                            shouldDirty: true,
                            shouldTouch: true,
                          });
                          onChange(value);
                      }}
                      value={value}
                      isTravelerAccount={true}
                      required
                      aria-describedby={"password-strength"}
                    />
                    )}
                  />
                  </div>
                </Col>
              </Row> 
              <Row>
                <Col>
                <div style={{width:"80%"}}>
                <PasswordStrength  
                  poorPassword={poorPassword}
                  weakPassword={weakPassword}
                  strongPassword={strongPassword}
                  passwordError={passwordError} 
                  passwordErrorLabel={passwordErrorLabel}
                />
                </div>
                </Col>
              </Row>
              <Row style={{display:"flex",justifyContent:"end"}}>
                <ButtonContainer >
                  <AccentButtonMedium
                    type='submit'
                    form="traveler-change-password"
                    style={{marginRight:"102px"}}
                    onClick={checkError}
                  >
                  {isSaving ? <AccentButtonSpinner displayText={'Change Password'} /> : ' Change Password '}
                  </AccentButtonMedium>
                </ButtonContainer>
              </Row>               
            </Col>
            <Col size={6}>
              <div className="card" id="password-strength">
                <div className="card-header" style={{
                  color: "#373737",
                  fontFamily: "Nunito",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: "600",
                  lineHeight: "120%",
                  letterSpacing: "0.016px"
                }}>
                  Your password must meet the following requirements:
                </div>
                <div className="card-body" style={{
                  color: "#373737",
                  fontFamily: "Open Sans",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "150%"
                }}>
                  <ul>
                    <li>Contain at least 8 characters (12+ recommended)</li>
                    <li>Contain at least one uppercase letter</li>
                    <li>
                    <p dangerouslySetInnerHTML={{ __html: 'Contain at least one special character (!&ldquo;#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~ )' }} />
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        </Grid>
        </div>
        {/* <ProfileFormButtonContainer>
          <ProfileFormButton type="submit">Change Password</ProfileFormButton>
        </ProfileFormButtonContainer> */}
        {showToast && (
          <ToastMessage
            message="Password updated"
            isSuccess
            show={showToast}
            onClose={() => {
              setShowToast(false);
            }}
          />
        )}
        {showToastError && (
          <ToastMessage
            message={error}
            isSuccess={false}
            show={showToastError}
            onClose={() => {
              setShowToastError(false);
            }}
          />
        )}
      </ProfileFormContainer>
    </form>
  );
}
