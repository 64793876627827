import React from 'react';
const EditIcon = (props) => {
  return(
  <div>
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M11.2065 3.35303C11.389 3.35292 11.5641 3.42536 11.6931 3.55439L12.4462 4.30752C12.5753 4.43655 12.6477 4.61159 12.6476 4.79408C12.6475 4.97656 12.5748 5.15151 12.4456 5.28039L3.92062 13.7844C3.85674 13.8482 3.78095 13.8987 3.69756 13.9331L2.45006 14.4484C2.19328 14.5545 1.8979 14.4956 1.70145 14.2992C1.50499 14.1027 1.44609 13.8073 1.55216 13.5505L2.06747 12.303C2.10192 12.2197 2.15244 12.1439 2.21616 12.08L10.7202 3.55499C10.8491 3.4258 11.0241 3.35314 11.2065 3.35303Z" fill={props.color}/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M12.8127 1.59162C12.9587 1.53113 13.1152 1.5 13.2733 1.5C13.4313 1.5 13.5878 1.53113 13.7338 1.59162C13.8798 1.6521 14.0125 1.74075 14.1242 1.8525C14.1242 1.85251 14.1243 1.85252 14.1243 1.85253L14.1479 1.87612C14.3734 2.10178 14.5 2.40774 14.5 2.72676C14.5 3.04578 14.3734 3.35174 14.1479 3.5774L13.7792 3.94602C13.5108 4.21451 13.0755 4.21451 12.807 3.94602L12.0538 3.1929C11.9249 3.06393 11.8524 2.889 11.8525 2.70661C11.8525 2.52423 11.925 2.34933 12.054 2.22042L12.4223 1.85253C12.4223 1.85248 12.4224 1.85243 12.4224 1.85238C12.4224 1.85235 12.4225 1.85232 12.4225 1.85229C12.5342 1.74064 12.6668 1.65206 12.8127 1.59162ZM13.6615 3.09145L13.7792 2.97375L13.4146 2.60906L13.6615 2.36207L13.4145 2.60897L13.3911 2.58562L13.0261 2.22062" fill={props.color}/>
    </svg>
  </div>
  )
}
export default (EditIcon);