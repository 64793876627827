import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './Note.css';

class Note extends React.Component {
  static propTypes = {
    body: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    time: PropTypes.string.isRequired,
    subject: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      isActive: false,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.setState({ isActive: !this.state.isActive });
  }

  render() {
    const avatarURL = this.props.avatar;

    return (
      <div className={s.container}>
        <img src={avatarURL} className={s.avatar} alt="Admin avatar" />
        <div className="row ml-0 col-12 p-0">
          <div className={`${s.noteContent} ml-4`}>
            <div className={s.header}>
              <span className={s.author}>{this.props.author},</span>
              <span className={s.dateTime}>{`${this.props.date} ${this.props.time}`}</span>
            </div>
            <div className={s.subject}>{this.props.subject}</div>
            <span className={s.body}>{this.props.body}</span>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(s)(Note);
