import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SelectInput from '../../base/inputs/SelectInput';
import TravelerFormQuestionContainer from '../../base/TravelerFormQuestionContainer';

import userId from '../../../../../shared/helpers/userId';
import { fetchSubjectAreas } from '../../../../../actions/lookupActions';
import { addProgramMatchSubjectAreas } from '../../../actions/programMatchFormActions';

export default function ProgramMatchSubjectAreas() {
  const dispatch = useDispatch();
  const programMatch = useSelector(state => state.programMatchForm);
  const subjectAreas = useSelector(state => state.lookup.allSubjectAreas.subjectAreas);

  const [value, setValue] = useState([]);
  const [error, setError] = useState('');
  const [subjects, setSubjects] = useState([]);

  const question = {
    overline: 'Subject Areas',
    heading: `Let's dive in, what subjects would you like to study while abroad?`,
    subheading: 'Select one or more subjects that interest you.',
    isRequired: true,
  };

  useEffect(() => {
    dispatch(fetchSubjectAreas());
  }, []);

  useEffect(
    () => {
      if (subjectAreas) {
        setSubjects(
          subjectAreas.map(subjectArea => ({
            value: subjectArea.attributes.name,
            label: subjectArea.attributes.name,
          })),
        );

        const initialSubjectAreas = subjectAreas
          .filter(subjectArea => programMatch.subjectAreas.includes(subjectArea.attributes.name))
          .map(subject => ({
            value: subject.attributes.name,
            label: subject.attributes.name,
          }));

        setValue(initialSubjectAreas);
      }
    },
    [programMatch.subjectAreas, subjectAreas],
  );

  const handleNext = () => {
    setError("");
    if (value.length === 0) {
      setTimeout(()=>{
        setError('Select at least one subject area.');
      },0)
      throw new Error();
    }

    const subjects = value.map(subject => subject.value);

    dispatch(addProgramMatchSubjectAreas(userId, subjects));
  };

  const handleOnChange = event => {
    if (event) {
      setValue(event);
    } else {
      setValue([]);
    }
  };

  return (
    <TravelerFormQuestionContainer question={question} handleNext={handleNext} showBack={false}>
      <SelectInput
        value={value}
        defaultValue={value}
        id="subject_areas"
        label="Subject Areas"
        placeholder="Select subjects"
        options={subjects}
        error={error}
        isMulti
        required
        handleOnChange={handleOnChange}
      />
    </TravelerFormQuestionContainer>
  );
}
