import React from 'react';
import styled from 'styled-components';
import { breakpoints } from '../../../../utils/breakpoints';

const ViewMoreInfoButton = styled.button`
  background-color: #deecff;
  border: none;
  border-radius: 10px;
  padding: 1rem 2rem;
  display: inline-flex;
  align-items: center;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 120%;
  text-align: center;
  letter-spacing: 0.005em;
  text-decoration-line: underline;
  color: #4d4d4d;
  gap: 1rem;
  margin-bottom: 1.3125rem;
  max-width: 20rem;
  justify-content: center;

  @media ${breakpoints.tablet} {
    font-size: 1rem;
  }

  @media ${breakpoints.desktop} {
    padding: 1rem 5.375rem;
    max-width: none;
  }
`;

export default function ViewMoreInformation({ url }) {
  return (
    <>
      <ViewMoreInfoButton onClick={() => window.open(url.replace(/^(https?:\/\/)?/i, a => a || 'http://'), '_blank')}>
        <span>View More Information</span>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M6.68652 9.36606C6.59385 9.36602 6.50326 9.33949 6.42621 9.28983C6.34917 9.24017 6.28912 9.16961 6.25366 9.08706C6.2182 9.00451 6.20892 8.91367 6.22699 8.82604C6.24505 8.7384 6.28966 8.6579 6.35518 8.5947L10.9823 4.13371C10.7429 4.00836 10.4746 3.94282 10.2021 3.94306H3.1709C2.73592 3.94351 2.31889 4.1103 2.01132 4.40683C1.70374 4.70336 1.53074 5.10541 1.53027 5.52477V12.3035C1.53074 12.7229 1.70374 13.1249 2.01132 13.4215C2.31889 13.718 2.73592 13.8848 3.1709 13.8852H10.2021C10.6371 13.8848 11.0542 13.718 11.3617 13.4215C11.6693 13.1249 11.8423 12.7229 11.8428 12.3035V5.52477C11.843 5.26207 11.775 5.00348 11.645 4.77261L7.01787 9.23359C6.97444 9.27566 6.9228 9.30903 6.86593 9.33176C6.80906 9.3545 6.74809 9.36616 6.68652 9.36606V9.36606Z"
            fill="#373737"
          />
          <path
            d="M13.249 2.13541H9.96777C9.84345 2.13541 9.72422 2.18303 9.63632 2.26778C9.54841 2.35253 9.49902 2.46747 9.49902 2.58733C9.49902 2.70719 9.54841 2.82213 9.63632 2.90688C9.72422 2.99163 9.84345 3.03925 9.96777 3.03925H12.1173L10.9823 4.13373C11.2626 4.28051 11.4928 4.50242 11.645 4.77263L12.7803 3.67843V5.75075C12.7803 5.8706 12.8297 5.98555 12.9176 6.0703C13.0055 6.15505 13.1247 6.20266 13.249 6.20266C13.3733 6.20266 13.4926 6.15505 13.5805 6.0703C13.6684 5.98555 13.7178 5.8706 13.7178 5.75075V2.58733C13.7178 2.46747 13.6684 2.35253 13.5805 2.26778C13.4926 2.18303 13.3733 2.13541 13.249 2.13541Z"
            fill="#373737"
          />
        </svg>
      </ViewMoreInfoButton>
    </>
  );
}
