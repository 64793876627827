import styled from 'styled-components';

import { buttonFocus } from '../../utils/shared/focusStyles';

const ProfileFormButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ProfileFormButton = styled.button`
  padding: 0.8125rem 1.75rem;
  background: var(--theme_color_accent);
  border: 1px solid transparent;
  border-radius: 5px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  color: var(--theme_color_accent_font);
  line-height: 120%;
  letter-spacing: 0.06em;

  &:hover {
    opacity: 0.9;
  }

  ${buttonFocus};
`;

export { ProfileFormButton, ProfileFormButtonContainer };
