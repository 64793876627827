import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import propTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import InputSelect from '../../../../components/library/inputSelect';
import { fetchSafecheckMessageTypes } from '../../../../../actions/lookupActions';

function SafecheckMessageSelect(props) {
  const dispatch = useDispatch();
  const messageTypes = useSelector(state => state.lookup.allSafecheckMessageTypes);
  const [options, setOptions] = useState([]);

  useEffect(
    () => {
      if (!messageTypes.safecheckMessageTypes) {
        dispatch(fetchSafecheckMessageTypes());
      } else {
        let flatData = [];
        messageTypes.safecheckMessageTypes.forEach(item => {
          flatData.push({ category: item.attributes.category, label: item.attributes.text, value: item.id });
        });

        const groupedData = _.chain(flatData)
          .groupBy('category')
          .map((value, key) => {
            return { label: key, options: value };
          })
          .value();

        setOptions(groupedData);
      }
    },
    [messageTypes],
  );

  const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: '#df235d',
    fontSize: '14px',
    fontFamily: 'AvenirNextDemiBold, sans-serif',
  };

  const formatGroupLabel = data => (
    <div style={groupStyles}>
      <span>{data.label}</span>
    </div>
  );
  return (
    <>
      {messageTypes &&
        messageTypes.safecheckMessageTypes && (
          <InputSelect {...props} options={options} formatGroupLabel={formatGroupLabel} />
        )}
    </>
  );
}

SafecheckMessageSelect.propTypes = {
  errorMessage: propTypes.string,
  labelText: propTypes.string,
  helpText: propTypes.string,
  placeholder: propTypes.string,
  isRequired: propTypes.bool,
};

SafecheckMessageSelect.defaultProps = {
  errorMessage: '',
  labelText: 'SafeCheck Message',
  helpText: '',
  placeholder: '',
  isRequired: false,
};

export default SafecheckMessageSelect;
