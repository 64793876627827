import React, { useState, useEffect } from 'react';
import { Formik, Field, Form } from 'formik';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import propTypes from 'prop-types';
import moment from 'moment-timezone';
import _get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import TransportationTypeSelect from '../../selects/transportationTypeSelect';
import Button from '../../../../../sites/components/library/button';
import InputTimePicker from '../../../../../sites/components/library/inputTimePicker';
import InputTextArea from '../../../../../sites/components/library/inputTextArea';
import InputText from '../../../../../sites/components/library/inputText';
import InputDate from '../../../../../sites/components/library/inputDate';
import { addDetail, getPlan, patchDetail } from '../../../../../actions/plans';
import EditDetailSuccessModal from '../../modals/editDetailSuccessModal';
import GroupAddDetailSuccessModal from '../../modals/groupAddDetailSuccessModal';
import Paragraph from '../../../../components/library/paragraph';
import LocationSearch from '../../../../components/library/locationSearch';
import sTransportationForm from './transportationForm.scss';
import ScreenReaderText from '../../../../travelerProfile/components/base/ScreenReaderText.styled';

function TransportationForm(props) {
  const dispatch = useDispatch();
  const [showScreenReader, setShowScreenReader] = useState(false);
  const planDetail = useSelector(state => state.plans.addDetail);
  const isEdit = props.isEdit;
  const defaultTimezone = '11'; // Mountain time (US & Canada)
  const [saving, setSaving] = useState(false);
  const [success, setSuccess] = useState(false);
  const [newSuccess, setNewSuccess] = useState(false);
  const [checked, setChecked] = useState(props.details ? props.details.force_add : false);
  const [departureLocation, setDepartureLocation] = useState();
  const [arrivalLocation, setArrivalLocation] = useState();
  const [defaultValues, setDefaultValues] = useState(() => {
    const departure_lat = _get(props, 'details.departure_location.lat') || '';
    const departure_lng = _get(props, 'details.departure_location.lng') || '';
    const departure_google_place_id = _get(props, 'details.departure_location.google_place_id') || '';
    const departure_location = {
      id: _get(props, 'details.departure_location.id') || '',
      formatted_address: _get(props, 'details.departure_location.formatted_address') || '',
      google_place_id: departure_google_place_id,
      country_alpha2_code: _get(props, 'details.departure_location.country_alpha2_code') || '',
      country_common_name: _get(props, 'details.departure_location.country_common_name') || '',
      county_or_region: _get(props, 'details.departure_location.county_or_region') || '',
      locality: _get(props, 'details.departure_location.locality') || '',
      postal_code: _get(props, 'details.departure_location.postal_code') || '',
      postal_code_suffix: _get(props, 'details.departure_location.postal_code_suffix') || '',
      state_or_province: _get(props, 'details.departure_location.state_or_province') || '',
      state_or_province_code: _get(props, 'details.departure_location.state_or_province_code') || '',
      street: _get(props, 'details.departure_location.street') || '',
      street_number: _get(props, 'details.departure_location.street_number') || '',
      lat: departure_lat,
      lng: departure_lng,
      time_zone: _get(props, 'details.departure_location.time_zone') || '',
      time_zone_offset: _get(props, 'details.departure_location.time_zone_offset') || '',
      mapUrl: `https://www.google.com/maps/search/?api=1&query=${departure_lat},${departure_lng}&query_place_id=${departure_google_place_id}`,
    };
    setDepartureLocation(null);
    setDepartureLocation(departure_location);

    const arrival_lat = _get(props, 'details.arrival_location.lat') || '';
    const arrival_lng = _get(props, 'details.arrival_location.lng') || '';
    const arrival_google_place_id = _get(props, 'details.arrival_location.google_place_id') || '';
    const arrival_location = {
      id: _get(props, 'details.arrival_location.id') || '',
      formatted_address: _get(props, 'details.arrival_location.formatted_address') || '',
      google_place_id: arrival_google_place_id,
      country_alpha2_code: _get(props, 'details.arrival_location.country_alpha2_code') || '',
      country_common_name: _get(props, 'details.arrival_location.country_common_name') || '',
      county_or_region: _get(props, 'details.arrival_location.county_or_region') || '',
      locality: _get(props, 'details.arrival_location.locality') || '',
      postal_code: _get(props, 'details.arrival_location.postal_code') || '',
      postal_code_suffix: _get(props, 'details.arrival_location.postal_code_suffix') || '',
      state_or_province: _get(props, 'details.arrival_location.state_or_province') || '',
      state_or_province_code: _get(props, 'details.arrival_location.state_or_province_code') || '',
      street: _get(props, 'details.arrival_location.street') || '',
      street_number: _get(props, 'details.arrival_location.street_number') || '',
      lat: arrival_lat,
      lng: arrival_lng,
      time_zone: _get(props, 'details.arrival_location.time_zone') || '',
      time_zone_offset: _get(props, 'details.arrival_location.time_zone_offset') || '',
      mapUrl: `https://www.google.com/maps/search/?api=1&query=${arrival_lat},${arrival_lng}&query_place_id=${arrival_google_place_id}`,
    };
    setArrivalLocation(null);
    setArrivalLocation(arrival_location);

    return {
      nickname: _get(props, 'details.nickname') || '',
      tp_transportation_type_id: _get(props, 'details.tp_transportation_type_id') || '',
      tp_transportation_type_name: _get(props, 'details.tp_transportation_type_name') || 'Select detail type...',
      carrier_name: _get(props, 'details.carrier_name') || '',
      route_number: _get(props, 'details.route_number') || '',
      confirmation_number: _get(props, 'details.confirmation_number') || '',
      seat_number: _get(props, 'details.seat_number') || '',
      ticket_number: _get(props, 'details.ticket_number') || '',
      departure_city: _get(props, 'details.departure_city') || '',
      departure_country_id: _get(props, 'details.departure_country_id') || '',
      departure_country_name: _get(props, 'details.departure_country_name') || '',
      departure_date: _get(props, 'details.departure_date') || '',
      departure_time: _get(props, 'details.departure_time') || '',
      departure_tp_time_zone_id: defaultTimezone,
      arrival_city: _get(props, 'details.arrival_city') || '',
      arrival_country_id: _get(props, 'details.arrival_country_id') || '',
      arrival_country_name: _get(props, 'details.arrival_country_name') || '',
      arrival_date: _get(props, 'details.arrival_date') || '',
      arrival_time: _get(props, 'details.arrival_time') || '',
      arrival_tp_time_zone_id: defaultTimezone,
      notes: _get(props, 'details.notes') || '',
      group: _get(props, 'details.group', props.group),
      removed: _get(props, 'details.removed', false),
      force_add: _get(props, 'details.removed', checked),
      arrival_location: arrival_location,
      departure_location: departure_location,
    };
  });

  useEffect(() => {
    if (planDetail) {
      if (planDetail.loading) {
        setSaving(true);
      }

      if (saving && !!planDetail.detail) {
        setSaving(false);

        if (planDetail.detail.attributes.group && isEdit) {
          setSuccess(true);
        } else if (planDetail.detail.attributes.group && !isEdit) {
          setNewSuccess(true);
        } else {
          // dispatch(getPlan(props.planId));
          props.onClose(false);
        }
      }

      if (saving && !!planDetail.error) {
        setSaving(false);
        // alert(planDetail.error);
      }
    }
  }, [planDetail]);

  const customStyles = {
    placeholder: provided => ({
      ...provided,
      color: 'black',
    }),
  };

  return (
    <>
      {success ? (
        <EditDetailSuccessModal
          onClose={() => {
            props.onClose(false);
          }}
        />
      ) : newSuccess ? (
        <GroupAddDetailSuccessModal
          planId={props.planId}
          onClose={() => {
            props.onClose(false);
          }}
        />
      ) : (
        <div className={sTransportationForm['transportation-form']}>
          <Formik
            enableReinitialize
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={defaultValues}
            validate={values => {
              const errors = {};

              setShowScreenReader(false);

              values.notes = values.notes.trim();

              values.departure_date = moment(values.departure_date).isValid()
                ? moment(values.departure_date).format('YYYY-MM-DD')
                : '';

              values.arrival_date = moment(values.arrival_date).isValid()
                ? moment(values.arrival_date).format('YYYY-MM-DD')
                : '';

              if (!values.tp_transportation_type_id) {
                errors.tp_transportation_type_id = 'Transportation Type Required';
              }
              if (!values.carrier_name) {
                errors.carrier_name = 'Carrier Name Required';
              }
              if (!values.departure_date) {
                errors.departure_date = 'Departure Date Required';
              }
              if (!values.departure_time) {
                errors.departure_time = 'Departure Time Required';
              }
              if (!values.arrival_date) {
                errors.arrival_date = 'Arrival Date Required';
              }
              if (values.arrival_date && moment(values.arrival_date).isBefore(moment(values.departure_date), 'day')) {
                errors.arrival_date = 'Arrival Date must be after Departure Date';
              }
              if (!values.arrival_time) {
                errors.arrival_time = 'Arrival Time Required';
              }
              if (!values.departure_location.lat || !values.departure_location.lng) {
                errors.departure_location = 'Select an address';
              }
              if (!values.arrival_location.lat || !values.arrival_location.lng) {
                errors.arrival_location = 'Select an address';
              }

              if (Object.keys(errors).length > 0) {
                setTimeout(() => {
                  setShowScreenReader(true);
                }, 1000);
              }

              return errors;
            }}
            onSubmit={values => {
              const submitValues = {
                plan_transportation: {
                  arrival_city: values.arrival_city,
                  arrival_country_id: values.arrival_country_id,
                  arrival_date: values.arrival_date,
                  arrival_time: moment(values.arrival_time).isValid()
                    ? moment(values.arrival_time).format('h:mm A')
                    : values.arrival_time,
                  arrival_tp_time_zone_id: values.arrival_tp_time_zone_id,
                  carrier_name: values.carrier_name,
                  confirmation_number: values.confirmation_number,
                  departure_city: values.departure_city,
                  departure_date: values.departure_date,
                  departure_country_id: values.departure_country_id,
                  departure_time: moment(values.departure_time).isValid()
                    ? moment(values.departure_time).format('h:mm A')
                    : values.departure_time,
                  departure_tp_time_zone_id: values.departure_tp_time_zone_id,
                  group: values.group,
                  nickname: values.nickname,
                  notes: values.notes,
                  removed: values.removed,
                  route_number: values.route_number,
                  seat_number: values.seat_number,
                  ticket_number: values.ticket_number,
                  tp_transportation_type_id: values.tp_transportation_type_id,
                  force_add: checked,
                  arrival_location_attributes: {
                    id: values.arrival_location.id,
                    country_alpha2_code: values.arrival_location.country_alpha2_code,
                    country_common_name: values.arrival_location.country_common_name,
                    county_or_region: values.arrival_location.county_or_region,
                    formatted_address: values.arrival_location.formatted_address,
                    google_place_id: values.arrival_location.google_place_id,
                    lat: values.arrival_location.lat,
                    locality: values.arrival_location.locality,
                    lng: values.arrival_location.lng,
                    postal_code: values.arrival_location.postal_code,
                    postal_code_suffix: values.arrival_location.postal_code_suffix,
                    state_or_province: values.arrival_location.state_or_province,
                    state_or_province_code: values.arrival_location.state_or_province_code,
                    street: values.arrival_location.street,
                    street_number: values.arrival_location.street_number,
                    time_zone: values.arrival_location.time_zone,
                    time_zone_offset: values.arrival_location.time_zone_offset,
                  },
                  departure_location_attributes: {
                    id: values.departure_location.id,
                    country_alpha2_code: values.departure_location.country_alpha2_code,
                    country_common_name: values.departure_location.country_common_name,
                    county_or_region: values.departure_location.county_or_region,
                    formatted_address: values.departure_location.formatted_address,
                    google_place_id: values.departure_location.google_place_id,
                    lat: values.departure_location.lat,
                    locality: values.departure_location.locality,
                    lng: values.departure_location.lng,
                    postal_code: values.departure_location.postal_code,
                    postal_code_suffix: values.departure_location.postal_code_suffix,
                    state_or_province: values.departure_location.state_or_province,
                    state_or_province_code: values.departure_location.state_or_province_code,
                    street: values.departure_location.street,
                    street_number: values.departure_location.street_number,
                    time_zone: values.departure_location.time_zone,
                    time_zone_offset: values.departure_location.time_zone_offset,
                  },
                },
              };

              if (isEdit) {
                dispatch(patchDetail(props.planId, props.itemId, submitValues, 'plan_transportations'));
              } else {
                dispatch(addDetail(props.planId, submitValues, 'plan_transportations'));
              }
            }}
          >
            {({ values, handleSubmit, errors, handleChange }) => (
              <Form onSubmit={handleSubmit}>
                <div className={sTransportationForm.subHeader}>
                  <Paragraph italic centered>
                    Please complete all required fields
                  </Paragraph>

                  {showScreenReader && (
                    <ScreenReaderText>
                      {/* Rendering error messages in a list */}
                      {Object.keys(errors).length > 0 && (
                        <div role='alert' aria-live='assertive'>
                          <ul>
                            {Object.keys(errors).map((key, index) => (
                              <li key={index}>{errors[key]}</li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </ScreenReaderText>
                  )}
                </div>

                <div className={sTransportationForm.divider}>
                  <Paragraph italic>Transportation Details </Paragraph>
                </div>

                <div className={sTransportationForm.newrow}>
                  <div className={sTransportationForm.item}>
                    <Field name="nickname">
                      {({ form }) => (
                        <InputText
                          id="nickname"
                          name="nickname"
                          labelText="Detail Nickname"
                          value={values.nickname}
                          onChange={e => {
                            form.setFieldValue('nickname', e.target.value);
                          }}
                          placeholder="Add a custom name for this detail..."
                        />
                      )}
                    </Field>
                  </div>
                </div>

                <div className={sTransportationForm.newrow}>
                  <div className={sTransportationForm.item}>
                    <Field name="tp_transportation_type_id">
                      {({ field, form }) => (
                        <TransportationTypeSelect
                          isRequired
                          adjustedWidth="48%"
                          id="tp_transportation_type_id"
                          name="tp_transportation_type_id"
                          styles={customStyles}
                          placeholder={values.tp_transportation_type_name}
                          value={field.tp_transportation_type_id}
                          errorMessage={errors.tp_transportation_type_id}
                          onChange={e => {
                            form.setFieldValue('tp_transportation_type_id', e.value);
                          }}
                          labelText="Transportation Detail Type"
                        />
                      )}
                    </Field>
                  </div>
                </div>
                <div className={sTransportationForm.newrow}>
                  <div className={sTransportationForm.item}>
                    <Field name="carrier_name">
                      {({ form }) => (
                        <InputText
                          id="carrier_name"
                          name="carrier_name"
                          labelText="Carrier Name"
                          isRequired
                          errorMessage={errors.carrier_name}
                          value={values.carrier_name}
                          onChange={e => {
                            form.setFieldValue('carrier_name', e.target.value);
                          }}
                        />
                      )}
                    </Field>
                  </div>
                </div>

                <div className={sTransportationForm.divider}>
                  <Paragraph italic>Departure Information</Paragraph>
                </div>

                <div className={sTransportationForm.newrow}>
                  <div className={sTransportationForm.item}>
                    <Field name="departure_date">
                      {({ field, form }) => (
                        <InputDate
                          labelText="Departure Date"
                          errorMessage={errors.departure_date}
                          id="departure_date"
                          name="departure_date"
                          isRequired
                          value={field.departure_date}
                          onChange={e => {
                            form.setFieldValue('departure_date', e);
                          }}
                          placeholder={
                            values.departure_date
                              ? moment(values.departure_date).format('MMM D, YYYY')
                              : 'Select Date...'
                          }
                        />
                      )}
                    </Field>
                  </div>
                  <div className={sTransportationForm.item}>
                    <Field name="departure_time">
                      {({ field, form }) => (
                        <InputTimePicker
                          labelText="Departure Time"
                          aria-label="Departure Time"
                          id="departure_time"
                          name="departure_time"
                          isRequired
                          value={values.departure_time ? moment(values.departure_time, 'h:mm A') : null}
                          errorMessage={errors.departure_time}
                          onChange={e => form.setFieldValue('departure_time', e)}
                          helpText="Time zone added based on location information"
                        />
                      )}
                    </Field>
                  </div>
                </div>
                <div className={sTransportationForm.newrow}>
                  <div className={sTransportationForm['item-address']}>
                    <Field name="departure_location">
                      {({ form }) => (
                        <LocationSearch
                          id="departure_location"
                          name="departure_location"
                          isRequired
                          labelText="Address Search"
                          placeholder="Type to select a city and country, or address..."
                          searchType="address"
                          selectHandler={e => {
                            if (!e.loading) {
                              values.departure_location.formatted_address = e.formatted_address;
                              values.departure_location.google_place_id = e.google_place_id;
                              values.departure_location.country_alpha2_code = e.country_alpha2_code;
                              values.departure_location.country_common_name = e.country_common_name;
                              values.departure_location.county_or_region = e.county_or_region;
                              values.departure_location.locality = e.locality;
                              values.departure_location.postal_code = e.postal_code;
                              values.departure_location.postal_code_suffix = e.postal_code_suffix;
                              values.departure_location.state_or_province = e.state_or_province;
                              values.departure_location.state_or_province_code = e.state_or_province_code;
                              values.departure_location.street = e.street;
                              values.departure_location.street_number = e.street_number;
                              values.departure_location.lat = e.lat.toString();
                              values.departure_location.lng = e.lng.toString();
                              values.departure_location.time_zone = e.time_zone;
                              values.departure_location.time_zone_offset = e.time_zone_offset;
                              values.departure_location.mapUrl = e.mapUrl;

                              setDepartureLocation(null);
                              setDepartureLocation(values.departure_location);
                            }
                          }}
                          onChange={handleChange}
                          errorMessage={errors.departure_location}
                          value={values.departure_location}
                        />
                      )}
                    </Field>
                  </div>
                  <div className={sTransportationForm.item}>
                    <Paragraph size="small" bold>
                      Selected Address
                    </Paragraph>
                    {!departureLocation ||
                    !departureLocation.formatted_address ||
                    departureLocation.formatted_address.length === 0 ? (
                      <Paragraph size="small" italic>
                        Please search for your departure address.
                      </Paragraph>
                    ) : (
                      <>
                        <Paragraph size="small">Address: {departureLocation.formatted_address}</Paragraph>
                        <Paragraph size="small">Timezone: {departureLocation.time_zone}</Paragraph>
                        <Paragraph size="small">
                          <a href={departureLocation.mapUrl} rel="noopener noreferrer" target="_blank" className={sTransportationForm.mapLink}>
                            See it on a map
                          </a>
                        </Paragraph>
                      </>
                    )}
                  </div>
                </div>

                <div className={sTransportationForm.divider}>
                  <Paragraph italic>Arrival Information</Paragraph>
                </div>

                <div className={sTransportationForm.newrow}>
                  <div className={sTransportationForm.item}>
                    <Field name="arrival_date">
                      {({ field, form }) => (
                        <InputDate
                          labelText="Arrival Date"
                          errorMessage={errors.arrival_date}
                          id="arrival_date"
                          name="arrival_date"
                          isRequired
                          value={field.arrival_date}
                          onChange={e => {
                            form.setFieldValue('arrival_date', e);
                          }}
                          placeholder={
                            values.arrival_date ? moment(values.arrival_date).format('MMM D, YYYY') : 'Select Date...'
                          }
                        />
                      )}
                    </Field>
                  </div>

                  <div className={sTransportationForm.item}>
                    <Field name="arrival_time">
                      {({ field, form }) => (
                        <InputTimePicker
                          labelText="Arrival Time"
                          aria-label="Arrival Time"
                          id="arrival_time"
                          name="arrival_time"
                          isRequired
                          value={values.arrival_time ? moment(values.arrival_time, 'h:mm A') : null}
                          errorMessage={errors.arrival_time}
                          onChange={e => form.setFieldValue('arrival_time', e)}
                          helpText="Time zone added based on location information"
                        />
                      )}
                    </Field>
                  </div>
                </div>
                <div className={sTransportationForm.newrow}>
                  <div className={sTransportationForm['item-address']}>
                    <Field name="arrival_location">
                      {({ form }) => (
                        <LocationSearch
                          id="arrival_location"
                          name="arrival_location"
                          isRequired
                          labelText="Address Search"
                          placeholder="Type to select a city and country, or address..."
                          searchType="address"
                          selectHandler={e => {
                            if (!e.loading) {
                              values.arrival_location.formatted_address = e.formatted_address;
                              values.arrival_location.google_place_id = e.google_place_id;
                              values.arrival_location.country_alpha2_code = e.country_alpha2_code;
                              values.arrival_location.country_common_name = e.country_common_name;
                              values.arrival_location.county_or_region = e.county_or_region;
                              values.arrival_location.locality = e.locality;
                              values.arrival_location.postal_code = e.postal_code;
                              values.arrival_location.postal_code_suffix = e.postal_code_suffix;
                              values.arrival_location.state_or_province = e.state_or_province;
                              values.arrival_location.state_or_province_code = e.state_or_province_code;
                              values.arrival_location.street = e.street;
                              values.arrival_location.street_number = e.street_number;
                              values.arrival_location.lat = e.lat.toString();
                              values.arrival_location.lng = e.lng.toString();
                              values.arrival_location.time_zone = e.time_zone;
                              values.arrival_location.time_zone_offset = e.time_zone_offset;
                              values.arrival_location.mapUrl = e.mapUrl;

                              setArrivalLocation(null);
                              setArrivalLocation(values.arrival_location);
                            }
                          }}
                          onChange={handleChange}
                          errorMessage={errors.arrival_location}
                          value={values.arrival_location}
                        />
                      )}
                    </Field>
                  </div>
                  <div className={sTransportationForm.item}>
                    <Paragraph size="small" bold>
                      Selected Address Details
                    </Paragraph>
                    {!arrivalLocation ||
                    !arrivalLocation.formatted_address ||
                    arrivalLocation.formatted_address.length === 0 ? (
                      <Paragraph size="small" italic>
                        Please search for your arrival address.
                      </Paragraph>
                    ) : (
                      <>
                        <Paragraph size="small">Address: {arrivalLocation.formatted_address}</Paragraph>
                        <Paragraph size="small">Timezone: {arrivalLocation.time_zone}</Paragraph>
                        <Paragraph size="small">
                          <a href={arrivalLocation.mapUrl} rel="noopener noreferrer" target="_blank" className={sTransportationForm.mapLink}>
                            See it on a map
                          </a>
                        </Paragraph>
                      </>
                    )}
                  </div>
                </div>

                <div className={sTransportationForm.divider}>
                  <Paragraph italic>Additional Transportation Information</Paragraph>
                </div>

                <div className={sTransportationForm.newrow}>
                  <div className={sTransportationForm.item}>
                    <Field name="route_number">
                      {({ form }) => (
                        <InputText
                          id="route_number"
                          name="route_number"
                          labelText="Route #"
                          value={values.route_number}
                          onChange={e => {
                            form.setFieldValue('route_number', e.target.value);
                          }}
                        />
                      )}
                    </Field>
                  </div>
                </div>

                <div className={sTransportationForm.newrow}>
                  <div className={sTransportationForm.item}>
                    <Field name="confirmation_number">
                      {({ form }) => (
                        <InputText
                          id="confirmation_number"
                          name="confirmation_number"
                          labelText="Confirmation #"
                          value={values.confirmation_number}
                          onChange={e => {
                            form.setFieldValue('confirmation_number', e.target.value);
                          }}
                        />
                      )}
                    </Field>
                  </div>
                </div>
                <div className={sTransportationForm.newrow}>
                  <div className={sTransportationForm.item}>
                    <Field name="ticket_number">
                      {({ form }) => (
                        <InputText
                          id="ticket_number"
                          name="ticket_number"
                          labelText="Ticket #"
                          value={values.ticket_number}
                          onChange={e => {
                            form.setFieldValue('ticket_number', e.target.value);
                          }}
                        />
                      )}
                    </Field>
                  </div>
                </div>
                <div className={sTransportationForm.newrow}>
                  <div className={sTransportationForm.item}>
                    <Field name="seat_number">
                      {({ form }) => (
                        <InputText
                          id="seat_number"
                          name="seat_number"
                          labelText="Seat #"
                          value={values.seat_number}
                          onChange={e => {
                            form.setFieldValue('seat_number', e.target.value);
                          }}
                        />
                      )}
                    </Field>
                  </div>
                </div>
                <div className={sTransportationForm.newrow}>
                  <div className={sTransportationForm.item}>
                    <Field name="notes">
                      {({ field, form }) => (
                        <InputTextArea
                          id="notes"
                          name="notes"
                          labelText="Notes"
                          value={values.notes}
                          onChange={e => {
                            form.setFieldValue('notes', e.target.value);
                          }}
                        />
                      )}
                    </Field>
                  </div>
                </div>

                {props.group ? (
                  <div className={sTransportationForm.row}>
                    <div className={sTransportationForm.item}>
                      <Field name="force_add">
                        {({ field, form }) => (
                          <div
                            className={
                              isEdit
                                ? sTransportationForm['disabled-force-add-checkbox']
                                : sTransportationForm['force-add-checkbox']
                            }
                          >
                            <input
                              type="checkbox"
                              id="force_add"
                              name="force_add"
                              value={values.force_add}
                              readOnly={isEdit ? true : false}
                              onClick={!isEdit ? () => setChecked(!checked) : null}
                              checked={checked}
                            />
                            <label>
                              Add this detail to all Travelers added to this Group Plan, now and in the future
                            </label>
                          </div>
                        )}
                      </Field>
                    </div>
                  </div>
                ) : null}

                <div className={sTransportationForm['button-row']}>
                  <div className={sTransportationForm['button-content']}>
                    <Button
                      display="secondary"
                      kind="solid"
                      size="medium"
                      ariaLabel="Cancel"
                      onClick={() => {
                        props.onClose(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                  <div className={sTransportationForm['button-content']}>
                    <Button display="primary" kind="solid" size="medium" type="submit" loading={saving} ariaLabel={isEdit ? 'Save' : 'Add'}>
                      {isEdit ? 'Save' : 'Add'}
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </>
  );
}

TransportationForm.propTypes = {
  planId: propTypes.string.isRequired,
  onClose: propTypes.func,
  group: propTypes.bool,
};

TransportationForm.defaultProps = {
  group: false,
};

export default withStyles(sTransportationForm)(TransportationForm);
