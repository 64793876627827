import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import userId from '../../../../shared/helpers/userId';

import history from '../../../../history';
import ToastMessage from '../../../../sites/viaGlobal/components/toastMessage';
import { getProgram, toggleLearnMoreModal } from '../../../../actions/programBrochure';
import { deauthorizePrograms, authorizePrograms } from '../../../../actions/programDashboardActions';
import { AccentButtonMedium as ApplyButton } from '../../../../styledComponents/styles/Buttons.styled';
import moment from 'moment-timezone';

export default function Apply() {
  const dispatch = useDispatch();
  const {
    meta: { id, internal, authorized, occasional_user_assigned },
    terms,
    loading,
  } = useSelector(state => state.programBrochure);
  const { 'is_traveler?': isTraveler, admin_sign_in: isAdmin } = useSelector(state => state.profile.currentUser);
  const programAlias = useSelector(state => state.auth.organization.info.alias_program);
  const userRole = useSelector(state => state.profile.role);
  const programPermissions = useSelector(state => state.profile.permissions.program_settings);

  const [applyStatus, setApplyStatus] = useState();
  const [applyText, setApplyText] = useState('Apply');
  const [showToastSuccess, setShowToastSuccess] = useState(false);
  const [showToastError, setShowToastError] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [adminAuth, setAdminAuth] = useState(true);

  const updatedTerms = terms.map(term => {
    const nowDate = moment().format('MMMM D, YYYY h:mm a');
    const dateToCheck = moment(new Date(term.range.deadline)).format('MMMM D, YYYY h:mm a');

    if (
      !moment(dateToCheck).isSameOrAfter(nowDate) &&
      term.application !== null &&
      term.range.status !== 'cancelled' &&
      term.range.deadline !== null
    ) {
      term.range.parsed_status = 'closed';
    }

    return term;
  });

  useEffect(() => {
    setAdminAuth(true);
    if (isTraveler && !isAdmin) {
      if (terms.filter(term => term.range.parsed_status === 'active').length > 0) {
        setApplyStatus('active');
      } else if (terms.filter(term => term.range.parsed_status === 'coming-soon').length > 0) {
        setApplyStatus('opening-soon');
      } else {
        setApplyStatus('inactive');
      }
    } else if (isAdmin) {
      if (internal) {
        setApplyStatus('edit-program');
      } else {
        if (authorized) {
          setAdminAuth(
            programPermissions?.includes('authorize_programs') &&
              !(userRole === 'occasional_user' && !occasional_user_assigned),
          );
          setApplyStatus('authorized');
        } else {
          setAdminAuth(
            programPermissions?.includes('authorize_programs') &&
              !(userRole === 'occasional_user' && !occasional_user_assigned),
          );
          setApplyStatus('unauthorized');
        }
      }
    } else {
      if (updatedTerms.filter(term => term.range.parsed_status === 'active').length > 0) {
        setApplyStatus('active');
      } else if (updatedTerms.filter(term => term.range.parsed_status === 'coming-soon').length > 0) {
        setApplyStatus('opening-soon');
      } else {
        setApplyStatus('inactive');
      }
    }
  }, [terms]);

  useEffect(() => {
    let text;
    switch (applyStatus) {
      case 'active':
        text = 'Apply';
        break;
      case 'opening-soon':
        text = 'Opening Soon';
        break;
      case 'inactive':
        text = 'Learn More';
        break;
      case 'edit-program':
        text = `Edit ${programAlias}`;
        break;
      case 'authorized':
        text = 'Unauthorize';
        break;
      case 'unauthorized':
        text = 'Authorize';
        break;
    }

    setApplyText(text);
  }, [applyStatus]);

  function handleClick() {
    if (!isAdmin && !isTraveler) {
      window.location.assign(`/?jmp=${window.location.href}`);
    } else if (isTraveler && !isAdmin) {
      if (applyStatus === 'coming-soon' || applyStatus === 'opening-soon' || applyStatus === 'inactive') {
        dispatch(toggleLearnMoreModal());
      } else {
        history.push(`/program_brochure/${id}/apply`);
      }
    } else if (isAdmin) {
      switch (applyStatus) {
        case 'authorized':
          setIsLoading(true);
          dispatch(
            deauthorizePrograms(userId, [id], (status, message) => {
              setIsLoading(false);
              if (status) {
                setApplyStatus('unauthorized');
                dispatch(getProgram(id, 'client'));
                setToastMessage(`${programAlias} has been unauthorized.`);
                setShowToastSuccess(true);
              } else {
                setToastMessage(message); 
                setShowToastError(true); 
              }
            }),
          );

          break;
        case 'unauthorized':
          setIsLoading(true);
          dispatch(authorizePrograms(userId, [id])).then(() => {
            setIsLoading(false);
            setApplyStatus('authorized');
            dispatch(getProgram(id, 'client'));
            setToastMessage(`${programAlias} has been authorized.`);
            setShowToastSuccess(true);
          });

          break;
        case 'edit-program':
          history.push(`/client/programs/${id}/modify`);
          break;
      }
    }
  }

  return (
    <>
      {!loading && (
        <ApplyButton disabled={isLoading || !adminAuth} aria-disabled={isLoading} onClick={handleClick}>
          {isLoading ? 'Loading...' : applyText}
        </ApplyButton>
      )}

      {showToastSuccess && (
        <ToastMessage
          message={toastMessage}
          show
          isSuccess
          onClose={() => {
            setShowToastSuccess(false);
            setToastMessage('');
          }}
        />
      )}

      {showToastError && (
        <ToastMessage
          message={toastMessage}
          show
          isSuccess={false}
          onClose={() => {
            setShowToastError(false);
            setToastMessage('');
          }}
        />
      )}
    </>
  );
}
