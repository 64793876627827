export default [
  { status: 'incomplete', displayTitle: 'Incomplete', dependant: 'init' },
  { status: 'submitted', displayTitle: 'Submitted', dependant: '' },
  { status: 'in_review', displayTitle: 'In Review', dependant: '' },
  { status: 'conditionally_accepted',displayTitle: 'Conditionally Accepted', dependant: ''},
  { status: 'accepted', displayTitle: 'Accepted', dependant: '' },
  { status: 'approved', displayTitle: 'Approved', dependant: '' },
  { status: 'conditionally_approved', displayTitle: 'Conditionally Approved', dependant: '' },
  { status: 'nominated', displayTitle: 'Nominated', dependant: '' },
  { status: 'not_accepted', displayTitle: 'Not Accepted', dependant: '' },
  { status: 'waitlisted', displayTitle: 'Waitlisted', dependant: '' },
  { status: 'commit', displayTitle: 'Committed', dependant: '' },
  { status: 'deferred', displayTitle: 'Deferred', dependant: '' },
  { status: 'withdrawn', displayTitle: 'Withdrawn', dependant: '' },
  { status: 'transferred', displayTitle: 'Transferred', dependant: '' },
];
