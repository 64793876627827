import React from 'react';

export default function Staff() {
  return (
    <svg viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
      <path
        d="M22.0078 21.7345H1.52344C0.876234 21.7345 0.351562 21.2098 0.351562 20.5626V5.82813C0.351562 5.18092 0.876234 4.65625 1.52344 4.65625H10.0305L12.4688 5.125L14.907 4.65625H22.0078C22.655 4.65625 23.1797 5.18092 23.1797 5.82813V20.5626C23.1797 21.2098 22.655 21.7345 22.0078 21.7345Z"
        fill="#EFEDEF"
      />
      <path
        d="M12.0814 19.8593H10.596L7.20329 19.3906L3.81062 19.8593H2.3252V8.85547H12.0814V19.8593Z"
        fill="#6DA6C2"
      />
      <path
        d="M10.0305 4.65625V5.4933C10.0305 6.83992 11.1222 7.93159 12.4688 7.93159C13.8154 7.93159 14.9071 6.83992 14.9071 5.4933V4.65625H10.0305Z"
        fill="#D7D0D6"
      />
      <path
        d="M22.4766 4.65625H21.0703C21.7175 4.65625 22.2422 5.18092 22.2422 5.82813V20.5626C22.2422 21.2098 21.7175 21.7345 21.0703 21.7345H22.4766C23.1238 21.7345 23.6484 21.2098 23.6484 20.5626V5.82813C23.6484 5.18088 23.1238 4.65625 22.4766 4.65625Z"
        fill="#D7D0D6"
      />
      <path
        d="M21.0739 19.263C21.8691 18.4678 21.8691 17.1786 21.0739 16.3835C20.2788 15.5883 18.9896 15.5883 18.1944 16.3835C17.3992 17.1786 17.3992 18.4678 18.1944 19.263C18.9896 20.0582 20.2788 20.0582 21.0739 19.263Z"
        fill="#85AC82"
      />
      <path
        d="M10.5959 19.86H3.81055V17.3359C3.81055 16.6887 4.33522 16.1641 4.98242 16.1641H9.42406C10.0713 16.1641 10.5959 16.6887 10.5959 17.3359V19.86Z"
        fill="#685E68"
      />
      <path
        d="M7.20328 17.5703C6.66679 17.5703 6.23193 17.1354 6.23193 16.5989V13.918H8.17467V16.5989C8.17467 17.1354 7.73976 17.5703 7.20328 17.5703Z"
        fill="#99704D"
      />
      <path
        d="M7.20323 15.7415C6.11343 15.7415 5.22998 14.858 5.22998 13.7683V12.985C5.22998 11.8952 6.11343 11.0117 7.20323 11.0117C8.29303 11.0117 9.17648 11.8952 9.17648 12.985V13.7683C9.17648 14.858 8.29303 15.7415 7.20323 15.7415Z"
        fill="#B38A60"
      />
      <path
        d="M12.0001 7.11478C10.9123 7.11478 10.0305 6.23302 10.0305 5.14523V0.820312C10.0305 0.561422 10.2404 0.351562 10.4993 0.351562H13.4071C13.666 0.351562 13.8758 0.561422 13.8758 0.820312V5.14523C13.8759 6.23297 13.0878 7.11478 12.0001 7.11478Z"
        fill="#EE7A9B"
      />
      <path
        d="M13.5008 0.351562H12.5633C12.8222 0.351562 13.032 0.561422 13.032 0.820312V5.14523C13.032 6.07139 12.3926 6.84783 11.5312 7.05816C11.6815 7.09486 11.8384 7.11473 12 7.11473C13.0877 7.11473 13.9695 6.23297 13.9695 5.14519V0.820312C13.9695 0.561469 13.7596 0.351562 13.5008 0.351562Z"
        fill="#D74B73"
      />
      <path
        d="M12 3.09668C11.6117 3.09668 11.2969 2.78187 11.2969 2.39355C11.2969 2.00524 11.6117 1.69043 12 1.69043C12.3883 1.69043 12.7031 2.00524 12.7031 2.39355C12.7031 2.78187 12.3883 3.09668 12 3.09668Z"
        fill="#685E68"
      />
      <path
        d="M14.051 9.20703H21.6748C21.869 9.20703 22.0264 9.04963 22.0264 8.85547C22.0264 8.66131 21.869 8.50391 21.6748 8.50391H14.051C13.8569 8.50391 13.6995 8.66131 13.6995 8.85547C13.6995 9.04963 13.8569 9.20703 14.051 9.20703Z"
        fill="#373737"
      />
      <path
        d="M14.051 11.8398H21.6748C21.869 11.8398 22.0264 11.6824 22.0264 11.4883C22.0264 11.2941 21.869 11.1367 21.6748 11.1367H14.051C13.8569 11.1367 13.6995 11.2941 13.6995 11.4883C13.6995 11.6824 13.8569 11.8398 14.051 11.8398Z"
        fill="#373737"
      />
      <path
        d="M14.051 14.4736H21.6748C21.869 14.4736 22.0264 14.3162 22.0264 14.1221C22.0264 13.9279 21.869 13.7705 21.6748 13.7705H14.051C13.8569 13.7705 13.6995 13.9279 13.6995 14.1221C13.6995 14.3162 13.8569 14.4736 14.051 14.4736Z"
        fill="#373737"
      />
      <path
        d="M19.6382 15.4355C18.3215 15.4355 17.2502 16.5068 17.2502 17.8235C17.2502 19.1403 18.3215 20.2115 19.6382 20.2115C20.955 20.2115 22.0262 19.1403 22.0262 17.8235C22.0262 16.5068 20.955 15.4355 19.6382 15.4355ZM19.6382 19.5084C18.7092 19.5084 17.9534 18.7526 17.9534 17.8235C17.9534 16.8945 18.7092 16.1386 19.6382 16.1386C20.5673 16.1386 21.3231 16.8945 21.3231 17.8235C21.3231 18.7526 20.5673 19.5084 19.6382 19.5084Z"
        fill="#373737"
      />
      <path
        d="M12 1.33887C11.4184 1.33887 10.9453 1.81198 10.9453 2.39355C10.9453 2.97513 11.4184 3.44824 12 3.44824C12.5816 3.44824 13.0547 2.97513 13.0547 2.39355C13.0547 1.81198 12.5816 1.33887 12 1.33887ZM12 2.74512C11.8061 2.74512 11.6484 2.58743 11.6484 2.39355C11.6484 2.19968 11.8061 2.04199 12 2.04199C12.1939 2.04199 12.3516 2.19968 12.3516 2.39355C12.3516 2.58743 12.1939 2.74512 12 2.74512Z"
        fill="#373737"
      />
      <path
        d="M8.41046 9.20703C8.60462 9.20703 8.76202 9.04963 8.76202 8.85547C8.76202 8.66131 8.60462 8.50391 8.41046 8.50391H2.3252C2.13104 8.50391 1.97363 8.66131 1.97363 8.85547V19.8593C1.97363 20.0534 2.13104 20.2108 2.3252 20.2108H3.81062H10.596H12.0814C12.2756 20.2108 12.433 20.0534 12.433 19.8593V8.85547C12.433 8.66131 12.2756 8.50391 12.0814 8.50391H9.94262C9.74846 8.50391 9.59105 8.66131 9.59105 8.85547C9.59105 9.04963 9.74846 9.20703 9.94262 9.20703H11.7298V19.5077H10.9475V17.3352C10.9475 16.4952 10.2641 15.8118 9.42409 15.8118H8.5262V15.6784C9.13102 15.2582 9.52806 14.5589 9.52806 13.7683V12.985C9.52806 11.7031 8.48513 10.6602 7.20324 10.6602C5.92135 10.6602 4.87843 11.7031 4.87843 12.985V13.7683C4.87843 14.5589 5.27546 15.2582 5.88029 15.6784V15.8118H4.9824C4.14235 15.8118 3.45896 16.4952 3.45896 17.3352V19.5078H2.67676V9.20703H8.41046ZM6.58351 16.1634V16.0088C6.58557 16.0093 6.58773 16.0097 6.58984 16.0103C6.7854 16.0639 6.99099 16.093 7.20334 16.093C7.41568 16.093 7.62127 16.0639 7.81684 16.0103C7.8189 16.0097 7.82105 16.0093 7.82312 16.0088V16.1634V16.5983C7.82312 16.94 7.54505 17.2181 7.20329 17.2181C6.86152 17.2181 6.58346 16.94 6.58346 16.5983V16.1634H6.58351ZM5.58165 12.985C5.58165 12.0908 6.30915 11.3633 7.20334 11.3633C8.09752 11.3633 8.82502 12.0908 8.82502 12.985V13.7683C8.82502 14.3776 8.48701 14.9093 7.98882 15.1864C7.50507 15.455 6.9016 15.455 6.41785 15.1864C5.91966 14.9093 5.58165 14.3776 5.58165 13.7683V12.985ZM4.16218 17.3352C4.16218 16.883 4.53015 16.5149 4.98249 16.5149H5.88038V16.5983C5.88038 17.3277 6.47387 17.9212 7.20334 17.9212C7.9328 17.9212 8.52629 17.3277 8.52629 16.5983V16.5149H9.42418C9.87648 16.5149 10.2445 16.883 10.2445 17.3352V19.5078H4.16218V17.3352Z"
        fill="#373737"
      />
      <path
        d="M15.0671 21.3835H1.52344C1.07114 21.3835 0.703125 21.0155 0.703125 20.5632V14.1221C0.703125 13.9279 0.545719 13.7705 0.351562 13.7705C0.157406 13.7705 0 13.9279 0 14.1221V20.5632C0 21.4032 0.683437 22.0866 1.52344 22.0866H15.0671C15.2612 22.0866 15.4186 21.9292 15.4186 21.7351C15.4186 21.5409 15.2612 21.3835 15.0671 21.3835Z"
        fill="#373737"
      />
      <path
        d="M22.4766 4.30491H14.3211V0.820313C14.3211 0.368016 13.9531 0 13.5008 0H10.4992C10.047 0 9.67894 0.368016 9.67894 0.820313V4.30491H1.52344C0.683437 4.30491 0 4.98834 0 5.82834V12.6109C0 12.805 0.157406 12.9624 0.351562 12.9624C0.545719 12.9624 0.703125 12.805 0.703125 12.6109V5.8283C0.703125 5.376 1.07114 5.00798 1.52344 5.00798H9.67889V5.14519C9.67889 6.42506 10.7201 7.4663 12 7.4663C13.2799 7.4663 14.3211 6.42506 14.3211 5.14519V5.00798H22.4766C22.9289 5.00798 23.2969 5.376 23.2969 5.8283V20.5627C23.2969 21.015 22.9289 21.3831 22.4766 21.3831H16.5993C16.4051 21.3831 16.2477 21.5405 16.2477 21.7346C16.2477 21.9288 16.4051 22.0862 16.5993 22.0862H22.4766C23.3166 22.0862 24 21.4028 24 20.5627V5.8283C24 4.9883 23.3166 4.30491 22.4766 4.30491ZM13.618 5.14519C13.618 6.03736 12.8922 6.76317 12 6.76317C11.1078 6.76317 10.382 6.03736 10.382 5.14519V4.65642V0.820313C10.382 0.755719 10.4346 0.703125 10.4992 0.703125H13.5007C13.5653 0.703125 13.6179 0.755719 13.6179 0.820313V4.65647V5.14519H13.618Z"
        fill="#373737"
      />
      <path
        d="M19.9358 17.0288L18.8435 18.1212C18.7062 18.2585 18.7062 18.4811 18.8435 18.6183C18.9121 18.687 19.0021 18.7213 19.092 18.7213C19.182 18.7213 19.272 18.687 19.3406 18.6183L20.433 17.5259C20.5703 17.3886 20.5703 17.166 20.433 17.0288C20.2957 16.8914 20.0731 16.8914 19.9358 17.0288Z"
        fill="#373737"
      />
    </svg>
  );
}
