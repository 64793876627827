import React from 'react';

export default function ChevronDownIcon() {
  return (
    <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.712087 1.11736C1.0782 0.751244 1.6718 0.751244 2.03791 1.11736L7 6.07945L11.9621 1.11736C12.3282 0.751244 12.9218 0.751244 13.2879 1.11736C13.654 1.48348 13.654 2.07707 13.2879 2.44319L7.66291 8.06819C7.2968 8.4343 6.7032 8.4343 6.33709 8.06819L0.712087 2.44319C0.345971 2.07707 0.345971 1.48348 0.712087 1.11736Z"
        fill="#373737"
      />
    </svg>
  );
}
