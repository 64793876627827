exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@-webkit-keyframes iL_td{to{-webkit-transform:rotate(1turn)}}@keyframes iL_td{to{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}.r5DqU{padding-top:16px;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column}.r5DqU ._2RtcD{padding:12px 52px}.r5DqU ._3YV6X{-ms-flex:1 1;flex:1 1;-ms-flex-direction:row;flex-direction:row;display:-ms-flexbox;display:flex}@media only screen and (max-width:599px){.r5DqU ._3YV6X{-ms-flex-direction:column;flex-direction:column}}.r5DqU ._3YV6X .Go6S4{-ms-flex:1 1;flex:1 1;padding:12px 20px}.r5DqU ._2R6rA{-ms-flex:1 1;flex:1 1;-ms-flex-direction:row;flex-direction:row;display:-ms-flexbox;display:flex;border-top:2px solid #ebebeb;padding:32px 20px 0 0;-ms-flex-pack:center;justify-content:center;margin-top:52px}@media only screen and (max-width:599px){.r5DqU ._2R6rA{-ms-flex-direction:column;flex-direction:column}}.r5DqU ._2R6rA ._39S_4{padding-left:12px}@media only screen and (max-width:599px){.r5DqU ._2R6rA ._39S_4{padding-top:12px}}", ""]);

// exports
exports.locals = {
	"edit-detail-success-modal": "r5DqU",
	"edit-detail-success-modal-content": "_2RtcD",
	"row": "_3YV6X",
	"item": "Go6S4",
	"button-row": "_2R6rA",
	"button-content": "_39S_4",
	"spin": "iL_td"
};