import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import MobileFilterContainer from '../MobileFilterContainer.styled';
import ProfileSelectInput from '../../../base/inputs/ProfileSelectInput';

import useSubjectAreasOptions from '../../../../hooks/useSubjectAreasOptions';

export default function MobileSubjectAreasFilter({ value, setValue, handleOnChange }) {
  const { selectedFilters } = useSelector(state => state.programDashboard);

  const [allOptions] = useSubjectAreasOptions();

  useEffect(() => {
    if (selectedFilters.subjectAreas.length > 0) {
      const mappedValues = selectedFilters.subjectAreas.map(subjectArea => ({
        label: subjectArea,
        value: subjectArea,
      }));

      setValue(mappedValues);
    }
  }, []);

  return (
    <MobileFilterContainer bottomBorder>
      <ProfileSelectInput
        id="subject_areas"
        value={value}
        label="Subject Areas"
        placeholder="Select Subject Area(s)"
        options={allOptions}
        isMulti
        handleOnChange={handleOnChange}
      />
    </MobileFilterContainer>
  );
}

MobileSubjectAreasFilter.propTypes = {
  setValue: PropTypes.func.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
};
