import React from 'react';

export default function AddIcon({width = "none", height = "none"}) {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M8.5 3C8.77614 3 9 3.22386 9 3.5V12.5C9 12.7761 8.77614 13 8.5 13C8.22386 13 8 12.7761 8 12.5V3.5C8 3.22386 8.22386 3 8.5 3Z" fill="#255A94"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M3.5 8C3.5 7.72386 3.72386 7.5 4 7.5H13C13.2761 7.5 13.5 7.72386 13.5 8C13.5 8.27614 13.2761 8.5 13 8.5H4C3.72386 8.5 3.5 8.27614 3.5 8Z" fill="#373737"/>
    </svg>
  );
}
