import PropTypes from 'prop-types';
import styled from 'styled-components';
import React, { useState } from 'react';

import { breakpoints } from '../../utils/breakpoints';
import { buttonFocus } from '../../utils/shared/focusStyles';
import history from '../../../../history';

import SendMessageModal from '../modals/sendMessage';

const Button = styled.button`
  display: inline-flex;
  gap: 0.40625rem;
  align-items: center;
  align-self: flex-start;
  font-family: 'Open Sans', sans-serif;
  font-size: 0.875rem;
  line-height: 120%;
  letter-spacing: 0.04em;
  padding-left: 0.625rem;
  padding-top: 0.59375rem;
  padding-bottom: 0.59375rem;
  padding-right: 0.625rem;
  color: #ffffff;
  font-weight: 600;
  background-color: #446372;
  border-radius: 3px;
  box-shadow: 0 0.3125rem 0.625rem 0 rgba(55, 55, 55, 0.25);
  cursor: pointer;
  background-image: none;
  border: solid 2px transparent;

  span {
    display: none;
  }

  &:focus {
    outline: none;
  }

  &:focus-visible {
    outline-style: solid;
    outline-width: 2px;
    outline-color: #255a94;
    outline-offset: 4px;
  }

  ${props => {
    if (props.isApplyPage) {
      return `
        background-color: #ffffff;
        color: #212121;
        filter: drop-shadow(0 0.3125rem 0.625rem rgba(55, 55, 55, 0.25));
      `;
    }
  }}

  @media ${breakpoints.tablet} {
    padding-right: 0.9375rem;

    span {
      display: inline-block;
    }
  }
`;

export default function Question({ programId, isApplyPage, isTraveler, isAdmin }) {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    if (!isTraveler && !isAdmin) {
      window.location.assign(`/?jmp=${window.location.href}`);
    } else if (isTraveler) {
      setIsOpen(true);
    }
  };
  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Button
        type="button"
        isApplyPage={isApplyPage}
        disabled={isAdmin}
        aria-disabled={isAdmin}
        style={{ cursor: isAdmin ? 'not-allowed' : '' }}
        onClick={handleClick}
      >
        <svg
          style={{ width: '1.5rem', height: '1.5rem', flexShrink: '0' }}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 25 24"
          aria-hidden="true"
        >
          <path
            d="M3.708 18.242c0 .004 0 .01-.003.018l.003-.018ZM21.495 19.012a1.255 1.255 0 0 1-.45-.093l-2.66-.944-.02-.008a.482.482 0 0 0-.353-.005c-.065.025-.648.244-1.259.412-.331.09-1.485.387-2.41.387-2.367 0-4.58-.91-6.23-2.56a8.597 8.597 0 0 1-2.536-6.118c0-.593.063-1.183.19-1.762.412-1.904 1.503-3.636 3.073-4.875A9.13 9.13 0 0 1 14.483 1.5c2.447 0 4.728.937 6.422 2.633 1.601 1.607 2.479 3.719 2.471 5.947a8.337 8.337 0 0 1-1.42 4.648l-.009.013-.034.047-.024.032-.007.013a1.007 1.007 0 0 0-.05.098l.737 2.622a1.126 1.126 0 0 1-1.074 1.46Z"
            fill="currentColor"
          />
          <path
            d="M14.931 19.94a.739.739 0 0 0-.482-.38c-.27-.071-.587-.059-.828-.077a9.458 9.458 0 0 1-6.039-2.754 9.338 9.338 0 0 1-2.657-5.284c-.066-.457-.066-.57-.066-.57a.75.75 0 0 0-1.312-.496s-.37.402-.544.806a7.598 7.598 0 0 0 .516 7.034c.122.203.122.297.094.508l-.468 2.433a1.125 1.125 0 0 0 .375 1.074l.021.019c.202.16.452.247.709.247.145 0 .288-.028.422-.084l2.508-.968a.378.378 0 0 1 .268 0 8.665 8.665 0 0 0 2.99.563 8.25 8.25 0 0 0 3.511-.781c.256-.12.657-.25.891-.521a.703.703 0 0 0 .091-.769Z"
            fill="currentColor"
          />
        </svg>
        <span>Have A Question?</span>
      </Button>
      {isOpen && 
        <SendMessageModal programId={programId} isOpen={isOpen} handleClose={handleClose} />
      }
    </>
  );
}

Question.propTypes = {
  isApplyPage: PropTypes.bool,
};

Question.defaultProps = {
  isApplyPage: false,
};
