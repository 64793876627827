import React from 'react';
import { compose } from 'redux';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { connect } from 'react-redux';

import {
  fetchProfileData,
  fetchProfileHeader,
  selectTravelerProfileTab,
  fetchTravelerMessages,
  selectMessageTab,
  resetMessageCreateCode,
  fetchTravelerPreferences,
  fetchTravelerNotes,
  fetchTravelerDetails,
  checkTravelerMessage,
  fetchGenderIdentities,
} from '../../actions/profileActions';
import TravelerHero from './TravelerHero';
import Sidebar from '../../components/pages/TravelerProfile/Sidebar';
import Profile from '../../components/pages/TravelerProfile/Profile';
import Layout from '../../sites/travelerProfile/layouts/TravelerPrograms';
import ProgramDetailsLayout from '../../sites/travelerProfile/layouts/TravelerProgramDetails';
import TravelerPrograms from '../../sites/travelerProfile/components/TravelerPrograms';
import TravelerProgramDetails from '../../sites/travelerProfile/components/TravelerProgramDetails';
import Preferences from '../../components/pages/TravelerProfile/Preferences';

import Notes from './Notes/Notes';
import TravelerPlans from './TravelerPlans';
import Events from '../../components/pages/TravelerProfile/Events';
import Messages from '../../components/pages/TravelerProfile/Messages';
import LoadingScreen from '../../styledComponents/styles/LoadingScreen';
import Alert from '../../components/AlertsOverlay/Alert';
import AlertsOverlay from '../../components/AlertsOverlay/AlertsOverlay';
import Ability from '../../hoc/Ability';
import ToastMessage from '../../sites/viaGlobal/components/toastMessage';
import s from './admin-traveler-view.css';
import customBranding from '../../hoc/customBranding/customBranding';
import TravelerPlanDetail from './TravelerPlanDetail';
import NoMessageAdminTravelerCard from '../../sites/viaGlobal/components/noMessageAdminTravelerCard';

@customBranding
class AdminTravelerView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      success: false,
      showSuccess: false,
      failure: false,
      showFailure: false,
      toastMessage: '',
      viewPlanDetail: false,
    };

    this.setActiveTab = this.setActiveTab.bind(this);
    this.closeToast = this.closeToast.bind(this);
  }

  componentDidMount() {
    this.props.fetchProfileHeader(this.props.id, 'client');
    this.props.fetchProfileData(this.props.id, 'client');
    this.props.fetchTravelerDetails(this.props.id, 'client');
    this.props.fetchTravelerMessages(this.props.id, 'client');
    this.props.fetchTravelerPreferences(this.props.id, 'client');
    this.props.fetchTravelerNotes(this.props.id);
    this.props.fetchGenderIdentities(this.props.id);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.freemium === true || nextProps.lead === true) {
      window.location.assign(`/client/programs/`);
    } else if (nextProps.updateProfileStatus && !this.state.success) {
      this.setState({ success: true, toastMessage: 'All changes saved' });
      setTimeout(() => {
        this.setState({ success: false, toastMessage: '' });
      }, 4000);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const urlParams = new URLSearchParams(window.location.search);
    const planId = urlParams.get('plan_id');
    if (planId !== null && planId !== prevState.planId) {
      this.setState({ viewPlanDetail: true, planId }); // Store planId to avoid re-parsing
    } else if (planId === null && this.state.viewPlanDetail) {
      // Reset state if planId is removed
      this.setState({ viewPlanDetail: false });
    }

    if (!prevState.failure && prevState.failure !== this.state.failure) {
      setTimeout(() => {
        this.setState({ showFailure: true });
      }, 500);
    } else if (!prevState.success && prevState.success !== this.state.success) {
      setTimeout(() => {
        this.setState({ showSuccess: true });
      }, 500);
    }
  }

  setActiveTab(e, tab) {
    this.props.selectTravelerProfileTab(tab);
  }

  closeToast() {
    this.setState({
      success: false,
      showSuccess: false,
      failure: false,
      showFailure: false,
      toastMessage: '',
    });
  }

  markAllRead = () => {
    let messages = this.props.messages.filter(count => count.is_read == false && count.allow_mark_read == true);

    messages.map(message => this.props.checkTravelerMessage(this.props.id, message.message_id, 'Admin'));
  };

  render() {
    const { forceTab, activeTab } = this.props;
    const selectedTab = activeTab || forceTab;

    if (this.props.loading == true || this.props.headerLoading == true) {
      return <LoadingScreen displayText="Loading..." />;
    } else if (this.props.profile == null || this.props.header == null) {
      return (
        <div>
          <div>error</div>
          <div>Header Info: {this.props.header}</div>
          <div>ProfileInfo: {this.props.profile}</div>
        </div>
      );
    }

    return (
      <div className={`${s.noPadding} container-fluid`}>
        <AlertsOverlay alerts={this.props.alerts}>
          <Alert />
        </AlertsOverlay>
        <div>
          <div>
            <TravelerHero />
          </div>
        </div>
        <div className="row no-gutters">
          <div className="col-md-3">
            <Sidebar
              role="admin"
              activeTab={selectedTab}
              customAliases={this.props.customAliases}
              clientFeatureList={this.props.clientFeatureList}
            />
          </div>
          <div className="col-md-9">
            <div className="content">
              {selectedTab === 'profile' && (
                <Ability module="traveler_information_settings" action="view_traveler_profile">
                  <Profile id={this.props.id} profile={this.props.profile} role="Admin" />
                </Ability>
              )}
              {selectedTab === 'messages' && (
                <Ability module="traveler_information_settings" action="view_and_send_messages">
                  {this.props.profile?.[0]?.admin ? (
                    <NoMessageAdminTravelerCard />
                  ) : (
                    <Messages
                      id={this.props.id}
                      role="Admin"
                      messagesLoading={this.props.messagesLoading}
                      adminsLoading={this.props.adminsLoading}
                      messages={this.props.messages}
                      messageCreate={this.props.messageCreate}
                      assignedAdmins={this.props.assignedAdmins}
                      admins={this.props.admins}
                      activeMessageTab={this.props.activeMessageTab}
                      currentUserAvatar={this.props.currentUserAvatar}
                      markAllRead={this.markAllRead}
                    />
                  )}
                </Ability>
              )}
              {selectedTab === 'preferences' && (
                <Ability module="traveler_information_settings" action="view_program_preferences">
                  <Preferences
                    id={this.props.id}
                    role="Admin"
                    preferences={this.props.preferences}
                    preferencesLoading={this.props.preferencesLoading}
                    customAliases={this.props.customAliases}
                  />
                </Ability>
              )}
              {selectedTab === 'notes' && (
                <Ability module="traveler_information_settings" action="view_and_create_notes">
                  <Notes
                    id={this.props.id}
                    role="Admin"
                    currentUser={this.props.currentUser}
                    notes={this.props.notes}
                    newNote={this.props.newNote}
                    noteCreate={this.props.noteCreate}
                    notesLoading={this.props.notesLoading}
                    currentUserAvatar={this.props.currentUserAvatar}
                  />
                </Ability>
              )}
              {selectedTab === 'plans' && (
                <>
                  {this.state.viewPlanDetail ? (
                    <TravelerPlanDetail
                      planID={this?.props?.plan_id}
                      id={this.props.id}
                      onClick={() => {
                        this.setState({ viewPlanDetail: false });
                      }}
                    />
                  ) : (
                    <TravelerPlans
                      id={this.props.id}
                      role="Admin"
                      onClick={() => {
                        this.setState({ viewPlanDetail: true });
                      }}
                    />
                  )}
                </>
              )}
              {selectedTab === 'events' && (
                <Events
                  id={this.props.id}
                  role="Admin"
                  events={this.props.events}
                  eventsLoading={this.props.eventsLoading}
                  customBranding={this.props.customBranding.themes}
                />
              )}
              {selectedTab === 'programs' && (
                <>
                  {this.props.viewDetails ? (
                    <ProgramDetailsLayout submissionId={this.props.submissionId}>
                      <TravelerProgramDetails legacyWrapper isAdmin />
                    </ProgramDetailsLayout>
                  ) : (
                    <Layout>
                      <TravelerPrograms layout="partial" tab={this.props.tab} isAdmin />
                    </Layout>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        {this.state.showSuccess && (
          <ToastMessage
            message={this.state.toastMessage}
            isSuccess
            id={'successful-update'}
            show={this.state.showSuccess}
            onClose={
              () => this.closeToast
              // this.setState({ success: false, toastMessage: '' });
            }
          />
        )}
        {this.state.showFailure && (
          <ToastMessage
            message={this.state.toastMessage}
            isSuccess={false}
            id={'failure-update'}
            show={this.state.showFailure}
            onClose={
              () => this.closeToast
              // this.setState({ failure: false, toastMessage: '' });
            }
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  signedIn: state.auth.signedIn,
  profile: state.profile.profile,
  loading: state.profile.profileLoading,
  header: state.profile.header,
  headerLoading: state.profile.headerLoading,
  activeTab: state.profile.activeTab,
  updateProfileStatus: state.profile.updateProfileStatus,
  events: state.profile.events,
  forms: state.profile.forms,
  activeMessageTab: state.profile.activeMessageTab,
  messages: state.profile.messages,
  currentUserAvatar: state.profile.currentUserAvatar,
  admins: state.profile.admins,
  assignedAdmins: state.profile.assignedAdmins,
  messagesLoading: state.profile.messagesLoading,
  messageCreate: state.profile.messageCreate,
  preferencesLoading: state.profile.preferencesLoading,
  preferences: state.profile.preferences,
  currentUser: state.profile.currentUser,
  userLoading: state.profile.userLoading,
  favorites: state.profile.favorites,
  detailsLoading: state.profile.detailsLoading,
  notes: state.profile.notes,
  newNote: state.profile.note,
  notesLoading: state.profile.notesLoading,
  alerts: state.alerts,
  customBranding: state.profile.customBranding || {},
  customAliases: state.profile.customAliases || {},
  freemium: state.profile.freemium,
  formsLoading: state.profile.detailsLoading,
  applicationsLoading: state.profile.detailsLoading,
  clientFeatureList: state.profile.clientFeatureList,
  lead: state.profile.lead,
});

export default compose(
  withStyles(s),
  connect(mapStateToProps, {
    fetchProfileData,
    fetchProfileHeader,
    selectTravelerProfileTab,
    fetchTravelerMessages,
    selectMessageTab,
    resetMessageCreateCode,
    fetchTravelerPreferences,
    fetchTravelerNotes,
    fetchTravelerDetails,
    checkTravelerMessage,
    fetchGenderIdentities,
  }),
)(AdminTravelerView);
