import React, { useState, Suspense, lazy } from 'react';
import _uniq from 'lodash/uniq';
import { useSelector } from 'react-redux';
import { compose } from 'recompose';
import propTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Formik, Field, Form } from 'formik';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import ViaModal from '../../../../components/library/viaModal';
import { stripHtmlString } from '../../../../../shared/helpers/General';
import Headline from '../../../../components/library/headline';
import Paragraph from '../../../../components/library/paragraph';
import Button from '../../../../components/library/button';
import FileIcon from '../../../../../components/pages/TravelerProfile/Messages/MessageComponent/CreateMessage/img/fileIcon';
import RemoveIcon from '../../../../../components/pages/TravelerProfile/Messages/MessageComponent/CreateMessage/img/removeIcon.js';
import AttachIcon from '../../../../../components/pages/TravelerProfile/Messages/MessageComponent/CreateMessage/img/attachIcon.js';
import InputText from '../../../../components/library/inputText';
import OneClickModal from '../oneClickModal';
import sBatchSendTravelersMessage from './batchSendTravelersMessage.scss';
const WysiwygEditor = lazy(() => import('../../../../components/library/wysiwygEditor'));

export const enhance = compose(withStyles(sBatchSendTravelersMessage), withRouter);

function BatchSendTravelersMessage(props) {
  const [showConfirm, setShowConfirm] = useState(false);
  const [submitValues, setSubmitValues] = useState();
  const [attachments, setAttachments] = useState([]);
  const messages = useSelector(state => state.client.messageSuccess);

  const resetForm = () => {
    setDefaultValues(() => {
      let values = {
        subject: '',
        body: '',
      };

      return values;
    });
    setAttachments([]);
  };

  const [defaultValues, setDefaultValues] = useState(() => {
    let values = {
      subject: '',
      body: '',
    };

    return values;
  });

  const onDrop = e => {
    setAttachments([...attachments, ...event.target.files]);
  };

  const removeAttachment = removeAttachmentIndex => {
    let filteredArray = attachments.filter((_file, index) => index !== removeAttachmentIndex);
    setAttachments(filteredArray);
  };

  const bodyCharacterCount = value => {
    let result = stripHtmlString(value).replace(/\n|\n|<p[^>]*>/g, '');
    return `Characters Remaining: ${result.length > 0 ? `${6000 - result.length}` : '6000'} `;
  };

  const attachmentsUI =
    attachments.length > 0 &&
    attachments.map((fileAttachment, index) => {
      return (
        <div className={sBatchSendTravelersMessage.attachment} key={index}>
          <FileIcon color="#043544" />
          <div className={sBatchSendTravelersMessage.extension}>{fileAttachment.name.split('.').pop()}</div>
          <div className={sBatchSendTravelersMessage.fileName}>{fileAttachment.name}</div>
          <div className={sBatchSendTravelersMessage.removeAttachment} onClick={() => removeAttachment(index)}>
            <RemoveIcon />
          </div>
        </div>
      );
    });

  return (
    <>
      <ViaModal
        showing={props.show}
        onClose={() => {
          resetForm();
          props.onClose(false);
        }}
        headerText="Send Message"
      >
        <div className={sBatchSendTravelersMessage['send-message']}>
          <Formik
            initialValues={defaultValues}
            validate={values => {
              const errors = {};
              let result = stripHtmlString(values.body).replace(/<p[^>]*>/g, '');

              if (values.subject.length > 70) {
                errors.subject = `Subject can be no longer than 70 characters. (${values.subject.length} / 70 Max)`;
              }

              if (values.body === '<p></p>\n' || values.body.length === 0) {
                errors.body = 'Email body required';
              }

              if (values.subject.length === 0) {
                errors.subject = 'Subject required';
              }

              if (result.length > 6000) {
                errors.body = `Message can be no longer than 6000 characters. (${result.length} / 6000 Max)`;
              }
              return errors;
            }}
            onSubmit={values => {
              let editorBodyText = values.body.replace(/<p><\/p>/g, '<br />');
              editorBodyText = editorBodyText.replace(/\n/g, '');
              editorBodyText = editorBodyText.replace(/<br><\/p>/g, '</p><br>');
              editorBodyText = editorBodyText.replace(/<p><\/p>/g, '<br />');

              const submitValues = {
                subject: values.subject,
                body: editorBodyText,
                attachments: attachments,
                attachmentCount: attachments.length,
                traveler_ids: props.selectedUsers,
              };
              setSubmitValues(submitValues);
              props.onClose(false);
              setShowConfirm(true);
            }}
          >
            {({ values, handleSubmit, errors, setFieldValue }) => (
              <Form>
                <div className={sBatchSendTravelersMessage.section}>
                  <Headline tag="h2" as="h3">
                    Please include a subject and email content that you would like to send to{' '}
                    {_uniq(props.selectedUsers).length}{' '}
                    {_uniq(props.selectedUsers).length === 0 || _uniq(props.selectedUsers).length > 1
                      ? props.customAliases.alias_travelers
                      : props.customAliases.alias_traveler}
                    .
                  </Headline>

                  <Headline tag="h2" as="h3" red={true}>
                    Please note that direct messages are not delivered to {props.customAliases.alias_travelers} who have
                    an Administrator role.
                  </Headline>
                </div>
                <div className={sBatchSendTravelersMessage.row}>
                  <div className={sBatchSendTravelersMessage.item}>
                    <Field name="subject">
                      {({ form }) => (
                        <InputText
                          id="subject"
                          aria-label="Email Subject Line"
                          name="subject"
                          labelText="Subject"
                          helpText={`Characters Remaining: ${
                            values.subject.length >= 0 ? `${70 - values.subject.length}` : null
                          } `}
                          value={values.subject}
                          onChange={e => form.setFieldValue('subject', e.target.value)}
                          placeholder="Type email subject..."
                          maxLength="70"
                          errorMessage={errors.subject}
                        />
                      )}
                    </Field>
                  </div>
                </div>
                <div className={sBatchSendTravelersMessage.row}>
                  <div className={[sBatchSendTravelersMessage.item, 'batchSendEditor'].join(' ')}>
                    <Paragraph size="medium" avenirNextMedium>
                      Email Content
                    </Paragraph>
                    <Suspense fallback={<h3>Still Loading…</h3>}>
                      <WysiwygEditor
                        id="body"
                        name="body"
                        labelText="Message"
                        value={values.body}
                        isRequired
                        errorMessage={errors.body}
                        aria-label="Email Body"
                        helpText={bodyCharacterCount(values.body)}
                        setFieldValue={setFieldValue}
                        editorStyle={{ height: '100px', resize: 'vertical', overflowY: 'hidden', paddingRight: '0px' }}
                        placeholder="Type email content.."
                        contenteditable="true"
                      />
                    </Suspense>
                  </div>
                </div>
                <div className={sBatchSendTravelersMessage.row}>
                  <div className={sBatchSendTravelersMessage.item}>
                    <div className={sBatchSendTravelersMessage.attachmentWrapper}>
                      <label className={sBatchSendTravelersMessage.attachmentLabel} htmlFor="file-input">
                        <AttachIcon />
                      </label>
                      <Paragraph size="small" bold>
                        {attachments.length < 1 ? 'Include Attachments' : `Attachments: ${attachments.length}`}
                      </Paragraph>
                      <input
                        id="file-input"
                        type="file"
                        style={{ display: 'none' }}
                        multiple
                        onChange={e => onDrop(e)}
                      />
                    </div>
                    <div className={sBatchSendTravelersMessage.attachmentsContainer}>{attachmentsUI}</div>
                  </div>
                </div>
                <div className={sBatchSendTravelersMessage.footer}>
                  <div className={sBatchSendTravelersMessage['button-content']}>
                    <Button
                      display="secondary"
                      aria-label="Close Modal Button"
                      kind="solid"
                      size="medium"
                      onClick={() => {
                        props.onClose(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                  <div className={sBatchSendTravelersMessage['button-content']}>
                    <Button
                      display="primary"
                      aria-label="Send Email Button"
                      kind="solid"
                      size="medium"
                      type="submit"
                      disabled={!!errors.subject || !!errors.body || props.selectedUsers.length < 1}
                    >
                      Send
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </ViaModal>
      <OneClickModal
        headline={'Send Message'}
        show={showConfirm}
        onClose={() => {
          resetForm();
          setShowConfirm(false);
          props.setSelectedPrograms();
        }}
        paragraphOne={`You are about to send a message to ${_uniq(props.selectedUsers).length} ${
          _uniq(props.selectedUsers).length === 1
            ? props.customAliases.alias_traveler.toLowerCase()
            : props.customAliases.alias_travelers.toLowerCase()
        }. Messages will send an email to the ${
          _uniq(props.selectedUsers).length === 1
            ? props.customAliases.alias_traveler.toLowerCase()
            : props.customAliases.alias_travelers.toLowerCase()
        } and appear in the Via TRM message center.`}
        paragraphTwo={'Ready to send?'}
        cancelMessage={'Cancel'}
        confirmActionMessage={'Send'}
        source="send_message_to_travelers"
        selectedPrograms={props.selectedUsers}
        actionState={messages}
        sendMessageSuccess={`${_uniq(props.selectedUsers).length} ${
          _uniq(props.selectedUsers).length === 1 ? 'message' : 'messages'
        } sent`}
        resetForm={() => resetForm()}
        submitValues={submitValues}
        failureMessage={'Unable to send message'}
      />
    </>
  );
}

BatchSendTravelersMessage.propTypes = {
  show: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
  planUsers: propTypes.array,
  as: propTypes.string,
  planId: propTypes.string,
};

BatchSendTravelersMessage.defaultProps = {
  planId: '',
};

export default enhance(BatchSendTravelersMessage);
