import React from 'react';

import { MonthYearFormat } from './MonthYearFormat';
import { FullDateFormat } from './FullDateFormat';

const TermDate = props => {
  return props.exactDates ? (
    <FullDateFormat
      startDate={props.startDate}
      endDate={props.endDate}
      addBr={props.addBr}
      timezone={props.timezone || null}
    />
  ) : (
    <MonthYearFormat
      startDate={props.startDate}
      endDate={props.endDate}
      addBr={props.addBr}
      timezone={props.timezone || null}
    />
  );
};

export default TermDate;
