import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import RadioInput from '../../base/inputs/RadioInput';
import SelectInput from '../../base/inputs/SelectInput';
import TravelerFormQuestionContainer from '../../base/TravelerFormQuestionContainer';

import { breakpoints } from '../../../utils/breakpoints';
import userId from '../../../../../shared/helpers/userId';
import { fetchLanguages } from '../../../../../actions/lookupActions';
import { addProgramMatchLanguages } from '../../../actions/programMatchFormActions';
import languageImmersionOptions from '../../../utils/programMatch/languageImmersionOptions';

const RadioGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 0.9375rem;
  margin-top: 2rem;

  .error-message {
    justify-self: center;
  }

  @media ${breakpoints.tablet} {
    margin-top: 1.6875rem;
    margin-right: 3.3rem;
    margin-left: 2.9375rem;
  }

  @media ${breakpoints.desktop} {
    margin-right: 8.10125rem;
  }
`;

const RadioGridColumn = styled.div`
  grid-column: span 2 / span 2;
  display: flex;
  justify-content: center;
  width: 100%;
`;

const SelectContainer = styled.div`
  grid-column: span 2 / span 2;
  margin: 1.25rem 0;

  label {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 0.875rem;
    color: #666666;
  }

  @media ${breakpoints.tablet} {
    label {
      font-size: 1rem;
      text-align: center;
    }
  }
`;

export default function ProgramMatchLanguageImmersion() {
  const dispatch = useDispatch();
  const programMatch = useSelector(state => state.programMatchForm);
  const allLanguages = useSelector(state => state.lookup.allLanguages.languages);

  const [value, setValue] = useState('');
  const [error, setError] = useState({
    general: '',
    languages: '',
  });
  const [languages, setLanguages] = useState([]);
  const [selectedLanguages, setSelectedLanguages] = useState([]);

  const question = {
    overline: 'Language Immersion',
    heading: 'Ok, great. Are you interested in an immersive language experience?',
    subheading: 'Select an option.',
    isRequired: true,
    isSubheadingRead:true,
  };

  useEffect(() => {
    dispatch(fetchLanguages());
  }, []);

  useEffect(
    () => {
      setValue(programMatch.languageImmersion);

      if (allLanguages) {
        setLanguages(
          allLanguages.map(language => ({
            label: language.attributes.name,
            value: language.attributes.iso_639_3,
          })),
        );

        const initialSelectedLanguages = allLanguages
          .filter(language => programMatch.userLanguages.includes(language.attributes.iso_639_3))
          .map(selectedLanguage => ({
            label: selectedLanguage.attributes.name,
            value: selectedLanguage.attributes.iso_639_3,
          }));

        setSelectedLanguages(initialSelectedLanguages);
      }
    },
    [programMatch.languageImmersion, allLanguages],
  );

  const handleNext = () => {
    setError("");
    if (!value) {
      setTimeout(()=>{
        setError({ general: 'Please select an option.' });
      },0)
      throw new Error();
    } else if (value === 'yes' && selectedLanguages.length === 0) {
      setTimeout(()=>{
        setError({ languages: 'Please select an option.' });
      },0)
      throw new Error();
    }

    dispatch(
      addProgramMatchLanguages(userId, {
        languageImmersion: value,
        userLanguages: selectedLanguages.map(language => language.value),
      }),
    );
  };

  return (
    <TravelerFormQuestionContainer question={question} handleNext={handleNext} error={error.general} moreErrorMargin>
      <RadioGrid role="radiogroup">
      <label id="role-label" style={{display: "none"}}>Select an option. Required</label>
        {languageImmersionOptions.slice(0, 1).map(option => (
          <RadioGridColumn key={`language-immersion-option-${option.id}`}>
            <RadioInput
              value={option.value}
              label={option.label}
              icon={option.icon}
              selected={value}
              handleOnChange={() => {
                setValue(option.value);
              }}
            />
          </RadioGridColumn>
        ))}
        {value === 'yes' && (
          <SelectContainer>
            <SelectInput
              id="selected_languages"
              value={selectedLanguages}
              defaultValue={selectedLanguages}
              label="Which languages are you interested in learning?"
              placeholder="Select all that apply"
              options={languages}
              error={error.languages}
              required
              noMarginTop
              isMulti
              handleOnChange={setSelectedLanguages}
            />
          </SelectContainer>
        )}
        {languageImmersionOptions.slice(1).map(option => (
          <RadioGridColumn key={`language-immersion-option-${option.id}`}>
            <RadioInput
              value={option.value}
              label={option.label}
              icon={option.icon}
              selected={value}
              handleOnChange={() => {
                setValue(option.value);
                setSelectedLanguages([]);
              }}
            />
          </RadioGridColumn>
        ))}
      </RadioGrid>
    </TravelerFormQuestionContainer>
  );
}
