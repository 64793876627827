import React, { useEffect, useRef, useState } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './TravelFeedAlertFilterForm.css';
import VectorImage from './Vector.png';
import ToggleImage from './Toggle.png';
import ToggleCrossImage from './ToggleCross.png';
import { useDispatch, useSelector } from 'react-redux';
import { saveRiskFilter } from '../../../actions/riskAlertActions';
import { Formik } from 'formik';
import { Spinner } from '../../../styledComponents/styles/spinner.styled';
import { CircularProgress } from '@material-ui/core';
import Button from '../../../sites/components/library/button';
import reactSelectStyles from '../../../../public/css/react-select-filter.css';
import ProfileSelectInput from '../../../sites/travelerProfile/components/base/inputs/ProfileSelectInput';

function TravelFeedAlertFilterForm({ hideFilter }) {
  const dispatch = useDispatch();
  const {
    riskLevels,
    riskLevelOptions,
    riskTypes,
    riskTypeOptions,
    saveLoading,
    infoLoading,
    filterInfo,
    countriesOptions,
  } = useSelector(state => state.riskAlert);
  const [edit, setEdit] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const filterFormRef = useRef(null);

  const [initialValues, setInititalValues] = useState({
    send_alerts_filter_criteria: false,
    display_if_travelers_impacted: false,
    risk_level_ids: [],
    risk_category_ids: [],
  });

  useEffect(() => {
    if (filterInfo) {
      let includeChecked = filterInfo.include;
      let excludeChecked = filterInfo.exclude;

      if (includeChecked && excludeChecked) {
        includeChecked = false;
        excludeChecked = false;
      }

      setInititalValues({
        ...filterInfo,
        include: includeChecked,
        exclude: excludeChecked,
      });
    }
  }, [filterInfo]);

  useEffect(() => {
    filterFormRef.current.focus();
  }, []);

  return (
    <>
      <div style={{ paddingLeft: '40px', paddingRight: '40px' }} ref={filterFormRef} tabIndex={0}>
        {infoLoading ? (
          <div className="text-center" style={{ padding: '100px', minHeight: '400px' }}>
            <Spinner displayText={`Loading....`} />
          </div>
        ) : (
          <>
            <div className={s.filterMessageParent}>
              <div className={s.filterMessageChildOne}></div>
              <div>
                <img src={VectorImage} />
              </div>
              <div className={s.filterMessageChildTwo}>
                <p className={s.filterMessageP1}>Your filters will remain selected until they are cleared.</p>
                <p className={s.filterMessageP2}>Filtering will not affect other admin alerts in your organization.</p>
              </div>
            </div>
            <Formik
              enableReinitialize={true}
              initialValues={{ ...initialValues }}
              onSubmit={values => {
                let postData = {
                  alert_config: values,
                };
                dispatch(
                  saveRiskFilter(postData, result => {
                    if (result) {
                      hideFilter();
                    }
                  }),
                );
              }}
            >
              {({ values, handleBlur, handleChange, handleSubmit, setFieldValue, submitForm }) => (
                <form onSubmit={handleSubmit}>
                  <div className={s.toggleDiv}>
                    <div
                      className={s.travelerImpactedCheckbox}
                      role="checkbox"
                      tabIndex={0}
                      aria-checked={values.display_if_travelers_impacted}
                      onClick={() =>
                        setFieldValue('display_if_travelers_impacted', !values.display_if_travelers_impacted)
                      }
                      onKeyDown={e => {
                        if (e.key === 'Enter' || e.key === ' ') {
                          if (e.key === ' ') {
                            e.preventDefault();
                          }
                          setFieldValue('display_if_travelers_impacted', !values.display_if_travelers_impacted);
                        }
                      }}
                    >
                      {values.display_if_travelers_impacted ? (
                        <img
                          src={ToggleImage}
                          style={{
                            cursor: 'pointer',
                            transition: 'all 0.3s ease',
                          }}
                          onClick={() => {
                            setFieldValue('display_if_travelers_impacted', !values.display_if_travelers_impacted);
                          }}
                        />
                      ) : (
                        <img
                          src={ToggleCrossImage}
                          style={{
                            cursor: 'pointer',
                            height: '23px',
                            width: '36px',
                            transition: 'all 0.3s ease',
                          }}
                          onClick={() => {
                            setFieldValue('display_if_travelers_impacted', !values.display_if_travelers_impacted);
                          }}
                        />
                      )}
                    </div>
                    <div>
                      <p className={s.togglePMessage}>Only Display Alerts if Travelers are Impacted</p>
                    </div>
                  </div>

                  <div className="form-group">
                    <label className={s.controlLabel}>Risk Levels</label>
                    <br />
                    <label className={s.hintLabel}>Leave blank to show all risk levels.</label>
                    <ProfileSelectInput
                      options={riskLevelOptions}
                      getOptionValue={option => option.value}
                      isMulti={true}
                      placeholder="Select Risk Levels"
                      value={riskLevelOptions.filter(option => values?.risk_level_ids?.includes(option.value))}
                      handleOnChange={selectedOptions => {
                        const levelValues = selectedOptions?.map(option => option.value);
                        if (levelValues) {
                          setFieldValue('risk_level_ids', levelValues);
                        } else {
                          setFieldValue('risk_level_ids', []);
                        }
                      }}
                    />
                  </div>

                  <div className="form-group">
                    <label className={s.controlLabel}>Risk Types</label>
                    <br />
                    <label className={s.hintLabel}>Leave blank to show all risk types.</label>
                    <ProfileSelectInput
                      options={riskTypeOptions}
                      isMulti={true}
                      placeholder="Select Risk Types"
                      value={riskTypeOptions.filter(option => values?.risk_category_ids?.includes(option.value))}
                      handleOnChange={selectedOptions => {
                        const categoryValues = selectedOptions?.map(option => option.value);
                        if (categoryValues) {
                          setFieldValue('risk_category_ids', categoryValues);
                        } else {
                          setFieldValue('risk_category_ids', []);
                        }
                      }}
                    />
                  </div>

                  <div className="form-group">
                    <label className={s.controlLabel}>Country/Region</label>
                    <br />
                    <label className={s.hintLabel}>Leave blank to show all countries and regions.</label>

                    <div className={s.radioGroup}>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="include"
                          defaultChecked={values.include}
                          checked={values.include}
                          onChange={e => {
                            setFieldValue('include', e.target.checked);
                            setFieldValue('exclude', !e.target.checked);
                          }}
                        />
                        <label class={`form-check-label ${s.radioLabel}`} for="Include">
                          Include
                        </label>
                      </div>

                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="exclude"
                          defaultChecked={values.exclude}
                          checked={values.exclude}
                          onChange={e => {
                            setFieldValue('exclude', e.target.checked);
                            setFieldValue('include', !e.target.checked);
                          }}
                        />
                        <label class={`form-check-label ${s.radioLabel}`} for="Exclude">
                          Exclude
                        </label>
                      </div>
                    </div>

                    <ProfileSelectInput
                      options={countriesOptions}
                      isMulti={true}
                      placeholder="Select Countries/Regions"
                      value={countriesOptions.filter(option =>
                        values?.countries?.map(country => Number(country))?.includes(Number(option.value)),
                      )}
                      handleOnChange={selectedOptions => {
                        const countryValues = selectedOptions?.map(option => Number(option.value));
                        if (countryValues) {
                          setFieldValue('countries', countryValues);
                        } else {
                          setFieldValue('countries', []);
                        }
                      }}
                    />
                  </div>

                  <div className={`form-group text-right ${s.checkFormGroup}`}>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                        checked={values.send_alerts_filter_criteria}
                        onChange={e => setFieldValue('send_alerts_filter_criteria', e.target.checked)}
                      />
                      <label class="form-check-label" for="flexCheckDefault" className={s.mailerText}>
                        Send me a mailer when an alert matches these filters
                      </label>
                    </div>
                  </div>

                  <div className={`form-group ${s.btnGroup}`}>
                    <Button
                      type="button"
                      display="secondary"
                      kind="solid"
                      size="medium"
                      ariaLabel="Cancel"
                      onClick={() => hideFilter()}
                    >
                      Cancel
                    </Button>

                    <Button
                      type="button"
                      display="primary"
                      kind="solid"
                      size="medium"
                      ariaLabel="Apply"
                      onClick={submitForm}
                      disabled={saveLoading}
                    >
                      Apply{' '}
                      {saveLoading && (
                        <>
                          &nbsp;
                          <CircularProgress size={15} color="inherit" />
                        </>
                      )}
                    </Button>
                  </div>
                </form>
              )}
            </Formik>
          </>
        )}
      </div>
    </>
  );
}

export default withStyles(s, reactSelectStyles)(TravelFeedAlertFilterForm);
