import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import CreateNote from './CreateNote';
import NoteList from './NoteList';

import Loader from '../../../../components/Loader/Loader';

import s from './TravelerNotes.css';

class TravelerNotes extends React.Component {
  render() {
    return (
      <div className={s.container}>
        <CreateNote id={this.props.id} avatar={this.props.currentUserAvatar} />
        <NoteList
          id={this.props.id}
          notesLoading={this.props.notesLoading}
          notes={this.props.notes}
          noteCreate={this.props.noteCreate}
          newNote={this.props.newNote}
        />
      </div>
    );
  }
}

export default withStyles(s)(TravelerNotes);
