import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import s from './ListErrors.css';
import Auxiliary from '../../hoc/Auxiliary/Auxiliary';

const ListErrors = ({ errors }) => (
  <Auxiliary>
    <p>Following errors occurred while processing your request</p>
    <div className={s.errorsWrapper}>
      {Object.keys(errors).map((key, index) => (
        <ul className={s.errorsUl} key={index}>
          {errors[key].map((error, index) => (
            <li key={index}>
              <strong>{`${key[0].toUpperCase()}${key.slice(1)}`}:</strong>{' '}
              {error}
            </li>
          ))}
        </ul>
      ))}
    </div>
  </Auxiliary>
);

export default withStyles(s)(ListErrors);
