import React from 'react';
import { compose } from 'recompose';
import classnames from 'classnames';
import propTypes from 'prop-types';
import _omit from 'lodash/omit';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import Paragraph from '../paragraph';
import ViaIcon from '../../../plans/components/ViaIcon';
import sToggleButton from './toggleButton.scss';

export const enhance = compose(withStyles(sToggleButton));

function ToggleButton(props) {
  const iconClass = classnames(sToggleButton['row-icon']);

  const buttonClass = classnames(
    sToggleButton['toggle-button'],
    props.active ? sToggleButton['active'] : '',
  );

  return (
    <button {..._omit(props, ['active', 'icon'])} className={buttonClass}>
      <section className={sToggleButton.content}>
        <article className={iconClass}>
          <div className={sToggleButton.mobile}>
            <ViaIcon
              name={props.icon}
              color={props.display === 'primary' ? 'primary' : 'darkText'}
              size="large"
            />
          </div>
          <div className={sToggleButton.notMobile}>
            <ViaIcon
              name={props.icon}
              color={props.display === 'primary' ? 'primary' : 'darkText'}
              size="xlarge"
            />
          </div>
        </article>
        <article className={sToggleButton['row-title']}>
          <Paragraph size="medium">{props.title}</Paragraph>
        </article>
      </section>
    </button>
  );
}

ToggleButton.propTypes = {
  kind: propTypes.oneOf(['square']),
  title: propTypes.string,
  icon: propTypes.string,
  display: propTypes.oneOf(['primary', 'dark']),
  active: propTypes.bool,
};

ToggleButton.defaultProps = {
  kind: 'square',
  title: '',
  icon: '',
  display: 'dark',
  active: false,
};

export default enhance(ToggleButton);
