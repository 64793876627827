import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import _get from 'lodash/get';
import StyledIcon from '../../../../../styledComponents/styles/Icon.styled';
import TextInput from '../../../../../styledComponents/styles/TextInput.styled';
import { AccentButtonMediumNunito } from '../../../../../styledComponents/styles/Buttons.styled';
import { AccentButtonSpinner } from '../../../../../styledComponents/styles/spinner.styled';
import { updateForm, updateQuestion } from '../../../../../actions/formsActions';

const FormContainer = styled.div`
  font-family: 'Nunito', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 0.015em;
  color: #373737;
`;

const InputContainer = styled.div`
  flex: 1;
  margin: 13px 0;
  text-align: left;
`;

const SubmitContainer = styled.main`
  display: flex;
  justify-content: flex-end;
  margin-top: 35px;
`;

const QuestionHeaderAddEdit = ({ question, edit, onClose }) => {
  const MAX_LENGTH = 90;
  const qIdentifier = 'header';
  const dispatch = useDispatch();
  const fetchForm = useSelector(state => state.forms.fetchForm);
  const questionTypesInfo = useSelector(state => state.forms.questionTypes);
  const updateFormSubmit = useSelector(state => state.forms.updateForm);
  const updateQuestionSubmit = useSelector(state => state.forms.updateQuestion);
  const [formId, setFormId] = useState(null);
  const [focusId, setFocusId] = useState(null);
  const [disableSubmit, setDisableSubmit] = useState(false);

  const schema = yup.object().shape({
    header_text: yup
      .string()
      .required('Header must be entered')
      .max(MAX_LENGTH, `Header can not be more than ${MAX_LENGTH} characters`),
  });

  const { control, handleSubmit, formState } = useForm({
    shouldUnregister: false,
    resolver: yupResolver(schema),
    defaultValues: {
      header_text: edit ? question.text : '',
    },
    mode: 'onChange',
  });

  useEffect(() => {
    if (!fetchForm?.loading && fetchForm?.data) {
      setDisableSubmit(false);
      setFormId(fetchForm.data.id);
    }
  }, [fetchForm]);

  useEffect(() => {
    if (
      (!updateFormSubmit?.loading && updateFormSubmit?.data) ||
      (!updateQuestionSubmit?.loading && updateQuestionSubmit?.data)
    ) {
      setDisableSubmit(false);
      onClose();
    }
  }, [updateFormSubmit, updateQuestionSubmit]);

  useEffect(() => {
    // Focus on the newly added rating option list item
    if (focusId) {
      document.getElementById(focusId)?.focus();
      //clear it out of state
      setFocusId(null);
    }
  }, [focusId]);

  useEffect(() => {
    //Focus on the first element in the editor
    setFocusId('header_text');
  }, [])

  const onSubmitHandler = data => {
    if (questionTypesInfo && questionTypesInfo.data) {
      const questionType = questionTypesInfo.data.reduce((prev, curr) => {
        return prev || curr.attributes.question_types.find(question => question.identifier === qIdentifier);
      }, undefined);

      const values = {
        text: data.header_text,
      };

      if (formId && data && (questionType?.id || question?.id)) {
        //update or save new
        const actionToCall = edit ? updateQuestion : updateForm;
        const idToUpdate = edit ? question.id : questionType.id;
        setDisableSubmit(true);
        dispatch(actionToCall(formId, questionType.endpoint, idToUpdate, values));
      }
    }
  };

  return (
    <FormContainer>
      <form id='headerAdd' onSubmit={handleSubmit(onSubmitHandler)}>
        <Controller
          control={control}
          name='header_text'
          render={({ field: { ref, ...rest } }) => (
            <InputContainer>
              <TextInput
                aria-label='Header'
                errorMsg={_get(formState.errors.header_text, 'message') || ''}
                id='header_text'
                label='Header Text'
                openSans
                placeholder='Enter Label'
                required
                {...rest}
              />
            </InputContainer>
          )}
        />
        <SubmitContainer>
          <AccentButtonMediumNunito
            aria-label={edit ? 'Save Header' : 'Add Header to Form'}
            form="headerAdd"
            type='submit'
            disabled={disableSubmit}
          >
            {disableSubmit ? <AccentButtonSpinner displayText='Saving ...' /> : edit ? 'Save' : 'Add to Form'}
            {!disableSubmit && <StyledIcon type='ChevronForward' color='#fff' />}
          </AccentButtonMediumNunito>
        </SubmitContainer>
      </form>
    </FormContainer>
  );
}

export default QuestionHeaderAddEdit;
