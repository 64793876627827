import React from 'react';
import moment from 'moment-timezone';
import 'moment-timezone';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './ProgramViewChart.css';
import TermDate from '../../../../components/TermDate/TermDate';
import currencyToSymbolMap from 'currency-symbol-map/map';
import ProgramRequestModal from '../../../../components/ProgramRequestModal/ProgramRequestModal';
import _trimEnd from 'lodash/trimEnd';

class ProgramViewChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      requestShow: false,
      hoverContentVisible: null,
    };

    this.renderButtons = this.renderButtons.bind(this);
    this.noTerms = this.noTerms.bind(this);
    this.requestMoreInformation = this.requestMoreInformation.bind(this);
    this.requestToSignIn = this.requestToSignIn.bind(this);
    this.requestShowParent = this.requestShowParent.bind(this);
    this.requestHideParent = this.requestHideParent.bind(this);
    this.setHoverContent = this.setHoverContent.bind(this);
  }
  setHoverContent(id) {
    this.setState({ hoverContentVisible: id });
  }

  requestShowParent() {
    this.setState({ requestShow: true });
  }

  requestHideParent() {
    this.setState({ requestShow: false });
  }

  requestToSignIn() {
    window.location.href = `/traveler/sign_in?jmp=${window.location.href.replace('visitor', 'traveler')}`;
  }

  noTerms() {
    return (
      <div>
        <span>
          Currently there are no terms available for this {this.props.aliasProgram.toLowerCase()}. Let us know if
          you&apos;re interested in applying to a future term.
        </span>
        <button className={s.applyButton}>Request to Apply</button>
      </div>
    );
  }

  requestMoreInformation() {
    window.location = window.location.href + '/program_settings';
  }

  isSameOrAfterCurrentDate = date => {
    const nowDate = moment().format('MMMM D, YYYY h:mm a');
    const dateToCheck = moment(new Date(date)).format('MMMM D, YYYY h:mm a');
    return moment(dateToCheck).isSameOrAfter(nowDate);
  };

  isBeforeCurrentDate = date => {
    const nowDate = moment().format('MMMM D, YYYY h:mm a');
    const dateToCheck = moment(new Date(date)).format('MMMM D, YYYY h:mm a');
    return moment(dateToCheck).isSameOrBefore(nowDate);
  };

  applyNowButton = range => {
    if (this.props.role == 'Traveler') {
      return (
        <button className={s.applyButton} onClick={e => this.props.openEligibility(range)}>
          Apply Now
        </button>
      );
    }
    return <button className={s.applyButton}>Apply Now</button>;
  };

  futureApplyButton = range => {
    if (this.props.role == 'Traveler') {
      return <button className={s.futureApplyButton}>Application Opening Soon</button>;
    }
    return <button className={s.futureApplyButton}>Application Opening Soon</button>;
  };

  applicationCancelledButton = () => (
    <button className={s.applyButtonDisabled} disabled>
      Cancelled
    </button>
  );

  applicationClosedButton = () => (
    <button className={s.applyButtonDisabled} disabled>
      Application Closed
    </button>
  );

  applicationOpeningButton = () => (
    <button className={s.applyButton} onClick={this.requestShowParent}>
      Request To Apply
    </button>
  );

  visitorButton = () => (
    <button className={s.applyButton} onClick={this.requestToSignIn}>
      Request to Apply
    </button>
  );

  renderButtons = range => {
    return this.travelerButton(range);
  };

  travelerButton(program_range) {
    if (this.props.role === 'Visitor' && !this.props.internal_program) {
      return this.handleAuthorizedProgramLogic(program_range);
    } else if (this.props.authorized) {
      return this.handleAuthorizedProgramLogic(program_range);
    } else {
      return this.handleInternalProgramLogic(program_range);
    }
  }

  handleAuthorizedProgramLogic(program_range) {
    const { role } = this.props;
    let range = program_range.range;
    let deadline = range.deadline;
    let application = program_range.application;

    if (role == 'Traveler' || role == 'Admin') {
      return this.displayButton(range, deadline, application);
    } else {
      return this.displayVisitorButtton(range, deadline, application);
    }
  }

  handleInternalProgramLogic(program_range) {
    const { role } = this.props;
    let range = program_range.range;
    let deadline = range.deadline;
    let application = program_range.application;

    if (role == 'Traveler' || role == 'Admin') {
      return this.displayButton(range, deadline, application);
    } else {
      return this.displayVisitorButtton(range, deadline, application);
    }
  }

  displayVisitorButtton(range, deadline, application) {
    let rangeDeadline = this.props.internal_program ? range.deadline : range.provider_deadline;

    if (range.status === 'cancelled') {
      return this.applicationCancelledButton(range);
    } else if (
      deadline == null &&
      this.isSameOrAfterCurrentDate(range.start_date) &&
      this.isSameOrAfterCurrentDate(range.end_date)
    ) {
      return this.visitorButton();
    } else if (
      application == null &&
      this.isSameOrAfterCurrentDate(range.start_date) &&
      this.isBeforeCurrentDate(deadline)
    ) {
      return this.applicationClosedButton();
    } else if (application == null && this.isSameOrAfterCurrentDate(range.start_date)) {
      return this.visitorButton();
    } else if (this.isBeforeCurrentDate(range.start_date) && this.isSameOrAfterCurrentDate(range.end_date)) {
      return this.applicationClosedButton();
    } else if (this.isSameOrAfterCurrentDate(range.deadline)) {
      return this.visitorButton();
    } else if (this.isBeforeCurrentDate(range.deadline)) {
      return this.applicationClosedButton();
    } else {
      return this.visitorButton();
    }
  }

  displayButton(range, deadline, application) {
    if (range.status === 'cancelled') {
      return this.applicationCancelledButton(range);
    } else if (
      application &&
      this.isSameOrAfterCurrentDate(range.start_date) &&
      this.isSameOrAfterCurrentDate(deadline)
    ) {
      return this.applyNowButton(range);
    } else if (
      application &&
      deadline == null &&
      this.isSameOrAfterCurrentDate(range.start_date) &&
      this.isSameOrAfterCurrentDate(range.end_date)
    ) {
      return this.applyNowButton(range);
    } else if (application && this.isSameOrAfterCurrentDate(range.start_date) && this.isBeforeCurrentDate(deadline)) {
      return this.applicationClosedButton();
    } else if (application == null && this.isSameOrAfterCurrentDate(range.start_date) && !this.props.authorized) {
      return this.futureApplyButton();
    } else if (application == null && this.isSameOrAfterCurrentDate(range.start_date) && this.props.authorized) {
      return this.applicationOpeningButton();
    } else if (this.isBeforeCurrentDate(range.start_date) && this.isSameOrAfterCurrentDate(range.end_date)) {
      return this.applicationClosedButton();
    }
  }

  convertCurrency = symbol => {
    let auckland =
      window.location.href.includes('360international.ac.nz') || window.location.href.includes('aucklandabroad.ac.nz');

    if (symbol === 'NZD') {
      return 'NZD$';
    } else if (symbol === 'USD' && auckland) {
      return 'USD$';
    } else if (symbol) {
      return currencyToSymbolMap[symbol];
    } else {
      return '$';
    }
  };

  toDollars = cents => {
    const dollars = cents / 100;
    return dollars.toLocaleString();
  };

  formatDate = (date, format) => moment(date).format(format);

  filteredRanges = () =>
    this.props.ranges.filter(current => {
      if (
        current === null ||
        (this.isBeforeCurrentDate(current.range.start_date) && this.isBeforeCurrentDate(current.range.end_date))
      ) {
        return false;
      } else {
        return true;
      }
    });

  truncate = (costNotes, id) => {
    return costNotes.length > 52 && this.state.hoverContentVisible !== id
      ? _trimEnd(costNotes.substring(0, 53), '.') + '...'
      : costNotes;
  };

  authorized = () => {
    return this.filteredRanges().map((range, index) => {
      return (
        <table key={index}>
          <thead>
            <tr>
              <th className={s.rangeHeader}>
                <div className={s.sq}>
                  <TermDate
                    startDate={range.range.start_date}
                    endDate={range.range.end_date}
                    addBr
                    dateType={'monthYear'}
                    exactDates={range.range.use_exact_dates}
                  />
                </div>
                <div className={s.td} />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className={s.weeks}>
                {range.range.name == null || range.range.name == '' ? `Please Inquire` : `${range.range.name}`}
              </td>
            </tr>
            <tr>
              <td className={s.weeks}>{`${range.range.weeks} weeks`}</td>
            </tr>
            {!this.props.internal_program ? (
              <tr>
                <td className={s.weeks}>
                  {range.range.deadline === null
                    ? `Please Inquire`
                    : moment.tz(range.range.deadline, this.props.orgTimeZone).format('MMMM D[,] YYYY')}
                  <br />
                  {range.range.deadline == null
                    ? ``
                    : moment.tz(range.range.deadline, this.props.orgTimeZone).format('h:mm a z')}
                </td>
              </tr>
            ) : null}
            <tr>
              <td className={s.weeks}>
                {range.range.provider_deadline == null
                  ? `Please Inquire`
                  : moment.tz(range.range.provider_deadline, this.props.orgTimeZone).format('MMMM D[,] YYYY')}
                <br />
                {range.range.provider_deadline == null
                  ? ``
                  : moment.tz(range.range.provider_deadline, this.props.orgTimeZone).format('h:mm a z')}
              </td>
            </tr>
            <tr>
              <td className={s.weeks}>
                {range.range.low_cost_cents !== null && range.range.high_cost_cents !== null
                  ? `${this.convertCurrency(range.range.currency_code)}${this.toDollars(
                      range.range.low_cost_cents,
                    )} - ${this.convertCurrency(range.range.currency_code)} ${this.toDollars(
                      range.range.high_cost_cents,
                    )}`
                  : range.range.low_cost_cents == null && range.range.high_cost_cents == null
                    ? `Please Inquire`
                    : `${this.convertCurrency(range.range.currency_code)} ${this.toDollars(
                        range.range.low_cost_cents,
                      )}`}
              </td>
            </tr>
            <tr>
              <td className={s.costNotesWrapper}>
                <div
                  className={s.costNotes}
                  onMouseEnter={() => this.setHoverContent(range.range.id)}
                  onMouseLeave={() => this.setHoverContent(null)}
                >
                  {range.range.cost_notes == null || range.range.cost_notes == ''
                    ? `Please Inquire`
                    : `${this.truncate(range.range.cost_notes, range.range.id)}`}
                </div>
              </td>
            </tr>
            <tr>
              <td className={s.weeks}>
                {range.range.min_course_credits !== null && range.range.max_course_credits !== null
                  ? `${range.range.min_course_credits} - ${range.range.max_course_credits}`
                  : range.range.min_course_credits == null && range.range.max_course_credits == null
                    ? `Please Inquire`
                    : `${range.range.min_course_credits}`}
              </td>
            </tr>
            <tr>
              <td>{this.renderButtons(range)}</td>
            </tr>
          </tbody>
        </table>
      );
    });
  };

  ranges = () => {
    return this.filteredRanges()
      .sort((a, b) => moment(a.start_date).diff(moment(b.start_date)))
      .map((range, index) => (
        <table className={s.tableWidth} key={index}>
          <thead>
            <tr>
              <th className={s.rangeHeader}>
                <div className={s.sq}>
                  <TermDate
                    startDate={range.range.start_date}
                    endDate={range.range.end_date}
                    addBr
                    dateType={'monthYear'}
                    exactDates={range.range.use_exact_dates}
                  />
                </div>
                <div className={s.td} />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className={s.weeks}>
                {range.range.name == null || range.range.name == '' ? `Please Inquire` : `${range.range.name}`}
              </td>
            </tr>
            <tr>
              <td className={s.weeks}>{`${range.range.weeks} weeks`}</td>
            </tr>
            <tr>
              <td className={s.weeks}>
                {range.range.deadline === null
                  ? `Please Inquire`
                  : moment.tz(range.range.deadline, this.props.orgTimeZone).format('MMMM D[,] YYYY')}
                <br />
                {range.range.deadline === null
                  ? ``
                  : moment.tz(range.range.deadline, this.props.orgTimeZone).format('h:mm a z')}
              </td>
            </tr>
            {this.props.role === 'Admin' &&
              !this.props.internal_program && (
                <tr>
                  <td className={s.weeks}>
                    {range.range.deadline !== null
                      ? moment.tz(range.range.deadline, this.props.orgTimeZone).format('MMMM D[,] YYYY h:mm a z')
                      : 'Please Inquire'}
                  </td>
                </tr>
              )}
            <tr>
              <td className={s.weeks}>
                {range.range.low_cost_cents !== null && range.range.high_cost_cents !== null
                  ? `${this.convertCurrency(range.range.currency_code)}${this.toDollars(
                      range.range.low_cost_cents,
                    )} - ${this.convertCurrency(range.range.currency_code)}${this.toDollars(
                      range.range.high_cost_cents,
                    )} ${range.range.currency_code}`
                  : range.range.low_cost_cents == null && range.range.high_cost_cents == null
                    ? `Please Inquire`
                    : `${this.convertCurrency(range.range.currency_code)}${this.toDollars(range.range.low_cost_cents)}`}
              </td>
            </tr>
            <tr>
              <td className={s.costNotesWrapper}>
                <div
                  className={`
                  ${s.costNotes}
                  ${!this.props.internal_program && s.costNotesLight}
                `}
                  onMouseEnter={() => this.setHoverContent(range.range.id)}
                  onMouseLeave={() => this.setHoverContent(null)}
                >
                  {range.range.cost_notes == null || range.range.cost_notes == ''
                    ? `Please Inquire`
                    : `${this.truncate(range.range.cost_notes, range.range.id)}`}
                </div>
              </td>
            </tr>
            <tr>
              <td className={s.weeks}>
                {range.range.min_course_credits !== null && range.range.max_course_credits !== null
                  ? `${range.range.min_course_credits} - ${range.range.max_course_credits}`
                  : range.range.min_course_credits == null && range.range.max_course_credits == null
                    ? `Please Inquire`
                    : `${range.range.min_course_credits}`}
              </td>
            </tr>
            <tr>
              <td>{this.renderButtons(range)}</td>
            </tr>
          </tbody>
        </table>
      ));
  };

  ammenities = () => {
    let rowHeader = !this.props.internal_program
      ? [
          'Term Name',
          'Length',
          `${this.props.home_campus} Application Deadline`,
          `${this.props.provider} Application Deadline`,
          'Cost',
          'Cost Information',
          'Credits',
        ]
      : ['Term Name', 'Length', 'Application Deadline', 'Cost', 'Cost Information', 'Credits'];

    let visitorRowHeader = ['Term Name', 'Length', `Application Deadline`, 'Cost', 'Cost Information', 'Credits'];

    return this.props.role === 'Visitor'
      ? visitorRowHeader.map((am, index) => (
          <tr key={index}>
            <td className={am === 'Cost Information' ? s.costAmmenity : s.ammenity}>
              {am}
              {am === 'Cost Information' && <p className={s.moreInfoHoverMessage}>(Hover for more info)</p>}
            </td>
          </tr>
        ))
      : rowHeader.map((am, index) => (
          <tr key={index}>
            <td className={am === 'Cost Information' ? s.costAmmenity : s.ammenity}>
              {am}
              {am === 'Cost Information' && <p className={s.moreInfoHoverMessage}>(Hover for more info)</p>}
            </td>
          </tr>
        ));
  };

  render() {
    const ammenities = this.ammenities();
    const ranges = this.ranges();
    const { programId, role } = this.props;
    const authorized = this.authorized();

    return (
      <div className={s.wrapper}>
        <div className={s.container}>
          {this.state.requestShow && (
            <ProgramRequestModal requestHideParent={this.requestHideParent} programId={programId} />
          )}
          {ranges.length > 0 ? (
            <div>
              <div className={s.tablewrapper}>
                <table>
                  <thead>
                    <tr>
                      <th className={s.inlcudedLabel}>Term Information</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ammenities}
                    <tr className={s.totalRangesWrapper}>
                      <td>
                        <p className={s.totalRanges}>
                          {ranges.length == 1 ? '1 Option' : `${ranges.length} Total Options`}
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className={s.ranges}>
                {ranges !== null ? (this.props.authorized == true ? authorized : ranges) : this.noTerms()}
              </div>
            </div>
          ) : null}
          {ranges.length == 0 ? (
            <div className={s.section}>
              <h3 className={s.boldH3}>{'Terms & Cost'}</h3>
              <div>
                {role == 'Traveler' ? (
                  <p className={s.contactHeader}>
                    Currently there are no terms available for this {this.props.aliasProgram.toLowerCase()}. Let us know
                    if you&apos;re interested in applying to a future term.
                  </p>
                ) : (
                  <p className={s.contactHeader}>
                    Currently there are no terms available for this {this.props.aliasProgram.toLowerCase()}.
                  </p>
                )}
                <div>
                  <p
                    className={s.requestButton}
                    onClick={role === 'Traveler' ? this.requestShowParent : this.requestMoreInformation}
                  >
                    {role === 'Traveler'
                      ? 'Request More Info'
                      : role === 'Visitor' || this.props.authorized
                        ? ''
                        : `Add Terms in ${this.props.aliasProgram} Settings`}
                  </p>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default withStyles(s)(ProgramViewChart);
