import moment from 'moment-timezone';

export function getProgramTermsFromArray(terms) {
  const termsArray = terms?.split('; ').filter(term => {
    if (term.toLowerCase().includes('application') || term.toLowerCase().includes('deadline')) {
      return false;
    }

    const termNameArray = term.split(':');
    const dateCheck = termNameArray[1] ? termNameArray[1].split('-')[0].trim() : termNameArray[0].split('-')[0].trim();

    return moment(dateCheck).isAfter(moment());
  });

  return termsArray || [];
}

export function getProgramTermsFromRange(programRange, separateWithColon = false) {
  const dateFormat = programRange.exact_dates ? 'MMM D, YYYY' : 'MMM YYYY';
  const formattedEndDate = moment(programRange.end_date).format(dateFormat);
  const formattedStartDate = moment(programRange.start_date).format(dateFormat);

  return programRange.term_name
    ? `${programRange.term_name}${separateWithColon ? ':' : ';'} ${formattedStartDate} - ${formattedEndDate}`
    : `${formattedStartDate} - ${formattedEndDate}`;
}
