import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { GraySpinner } from  './spinner.styled';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${({ width }) => width ? `width: ${width};` : `width: 100vw;`};
  ${({ height }) => height ? `height: ${height};` : `height: 100vh;`};
`;

const LoadingScreen = ({displayText, width, height}) => {
  return (
    <Container width={width} height={height}>
      <GraySpinner displayText={displayText} />
    </Container>
  );
}

LoadingScreen.propTypes = {
  displayText: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
}

LoadingScreen.defaultProps = {
  displayText: '',
  width: '100vw',
  height: '100vh',
}

export default LoadingScreen;