import React from 'react';

export default function Confetti() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_866_21363)">
        <path
          d="M12.366 9.97052C9.26684 6.87172 5.86857 5.24556 4.77539 6.33833C4.65544 6.46398 4.56717 6.61637 4.51789 6.78296L4.5003 6.76537L3.45631 11.0437L2.41671 15.3092L1.18439 20.3601L0.404297 23.5588L5.0229 21.8111L10.8974 19.5884L15.584 17.8151L15.5784 17.8095C15.7353 17.7594 15.8788 17.6746 15.9982 17.5612C17.0914 16.468 15.4652 13.0697 12.366 9.97052Z"
          fill="#D74B73"
        />
        <path
          d="M12.3664 9.96849C9.2673 6.86942 5.86892 5.2432 4.77589 6.33623C3.68286 7.42926 5.30908 10.8276 8.40816 13.9267C11.5072 17.0258 14.9056 18.652 15.9986 17.559C17.0917 16.4659 15.4655 13.0676 12.3664 9.96849Z"
          fill="#D1E3EE"
        />
        <path
          d="M15.1586 17.953C14.599 17.9628 14.0438 17.8526 13.5304 17.6299L9.69988 19.0793L3.82337 21.3001L0.672193 22.4924L0.404297 23.5908L5.0229 21.843L10.8974 19.6203L15.1985 17.9929L15.1586 17.953Z"
          fill="#B21D48"
        />
        <path
          d="M19.9967 14.7935C19.9488 14.7934 19.9013 14.7848 19.8563 14.7684C17.8265 14.0079 15.6323 13.7947 13.494 14.1502L12.0658 14.3881C11.8471 14.4193 11.6446 14.2674 11.6134 14.0487C11.5833 13.8375 11.7243 13.6398 11.9338 13.5996L13.3624 13.3597C15.6393 12.9814 17.9755 13.2083 20.137 14.0174C20.3448 14.0921 20.4527 14.3211 20.3781 14.5289C20.3204 14.6895 20.1672 14.7957 19.9967 14.7935Z"
          fill="#C6795C"
        />
        <path
          d="M12.7994 12.3947C12.5785 12.3934 12.4006 12.2134 12.4019 11.9926C12.4028 11.8401 12.4902 11.7015 12.6274 11.635L21.0242 7.6366C21.2236 7.54164 21.4622 7.62631 21.5571 7.82573C21.6521 8.02515 21.5674 8.26376 21.368 8.35872L12.9713 12.3572C12.9175 12.3823 12.8587 12.3951 12.7994 12.3947Z"
          fill="#C6795C"
        />
        <path
          d="M8.1237 11.5952C8.07097 11.5953 8.01874 11.5849 7.97016 11.5645C7.76634 11.4794 7.67003 11.2453 7.75504 11.0415V11.0415L8.33642 9.64641C9.23092 7.50899 9.43949 5.14651 8.93338 2.88544C8.8815 2.67078 9.0135 2.45476 9.22812 2.40288C9.44278 2.351 9.6588 2.483 9.71068 2.69762C9.71183 2.70237 9.71288 2.70711 9.71388 2.71191C10.2561 5.13402 10.0325 7.66473 9.07413 9.95429L8.49236 11.3493C8.43028 11.4981 8.28494 11.5951 8.1237 11.5952Z"
          fill="#C6795C"
        />
        <path
          d="M21.996 16.3933C22.4376 16.3933 22.7957 16.0353 22.7957 15.5936C22.7957 15.152 22.4376 14.7939 21.996 14.7939C21.5543 14.7939 21.1963 15.152 21.1963 15.5936C21.1963 16.0353 21.5543 16.3933 21.996 16.3933Z"
          fill="#B25771"
        />
        <path
          d="M12.3998 5.19801C12.8414 5.19801 13.1995 4.83998 13.1995 4.39832C13.1995 3.95667 12.8414 3.59863 12.3998 3.59863C11.9581 3.59863 11.6001 3.95667 11.6001 4.39832C11.6001 4.83998 11.9581 5.19801 12.3998 5.19801Z"
          fill="#DD95C1"
        />
        <path
          d="M8.80115 1.59938C8.35948 1.59938 8.00146 1.24137 8.00146 0.799688C8.00146 0.358011 8.35948 0 8.80115 0C9.24283 0 9.60084 0.358011 9.60084 0.799688C9.60084 1.24137 9.24283 1.59938 8.80115 1.59938Z"
          fill="#7FCCCB"
        />
        <path
          d="M19.997 3.19856C19.7762 3.19856 19.5972 3.01953 19.5972 2.79871V2.39887C19.5972 2.17805 19.7762 1.99902 19.997 1.99902C20.2178 1.99902 20.3969 2.17805 20.3969 2.39887V2.79871C20.3969 3.01953 20.2178 3.19856 19.997 3.19856Z"
          fill="#4F5E6B"
        />
        <path
          d="M19.997 1.19953C19.7762 1.19953 19.5972 1.0205 19.5972 0.799689V0.399844C19.5972 0.17903 19.7762 0 19.997 0C20.2178 0 20.3969 0.17903 20.3969 0.399844V0.799689C20.3969 1.0205 20.2178 1.19953 19.997 1.19953Z"
          fill="#4F5E6B"
        />
        <path
          d="M21.1962 1.99891H20.7963C20.5755 1.99891 20.3965 1.81988 20.3965 1.59906C20.3965 1.37825 20.5755 1.19922 20.7963 1.19922H21.1962C21.417 1.19922 21.596 1.37825 21.596 1.59906C21.596 1.81988 21.417 1.99891 21.1962 1.99891Z"
          fill="#4F5E6B"
        />
        <path
          d="M19.1971 1.99891H18.7973C18.5765 1.99891 18.3975 1.81988 18.3975 1.59906C18.3975 1.37825 18.5765 1.19922 18.7973 1.19922H19.1971C19.418 1.19922 19.597 1.37825 19.597 1.59906C19.597 1.81988 19.418 1.99891 19.1971 1.99891Z"
          fill="#4F5E6B"
        />
        <path
          d="M3.57854 11.596C3.53376 11.4248 3.49377 11.2521 3.45659 11.0762L2.41699 15.3417L2.49696 16.3169C2.67124 18.3961 3.5644 20.3495 5.02278 21.8416L10.8977 19.6208C10.8657 19.6108 10.8325 19.6037 10.8005 19.5929C7.23816 18.2571 4.54556 15.2756 3.57854 11.596Z"
          fill="white"
        />
        <path
          d="M10.8977 19.6207C10.8658 19.6107 10.8326 19.6035 10.8006 19.5927C10.4157 19.447 10.0388 19.2811 9.67142 19.0957L4.37988 21.0977C4.5805 21.3579 4.79527 21.6069 5.02323 21.8434L10.8977 19.6207Z"
          fill="#F6F6E7"
        />
        <path d="M17.198 4.79004V6.79766H15.1904V4.79004H17.198Z" fill="#A9CADA" />
        <path
          d="M10.0008 11.9955C9.78 11.9955 9.60102 11.8164 9.60107 11.5956C9.60112 11.4823 9.64916 11.3744 9.73332 11.2986L13.7318 7.70002C13.8987 7.55553 14.1512 7.57377 14.2957 7.74076C14.4362 7.90309 14.4234 8.1474 14.2668 8.29419L10.2683 11.8928C10.1949 11.9589 10.0996 11.9955 10.0008 11.9955Z"
          fill="#C6795C"
        />
        <path
          d="M21.5961 10.3958C21.3753 10.3958 21.1963 10.2168 21.1963 9.99598V9.59613C21.1963 9.37532 21.3753 9.19629 21.5961 9.19629C21.8169 9.19629 21.996 9.37532 21.996 9.59613V9.99598C21.996 10.2168 21.8169 10.3958 21.5961 10.3958Z"
          fill="#EEC88B"
        />
        <path
          d="M23.0097 9.80988C22.9035 9.81018 22.8017 9.768 22.727 9.69272L22.4443 9.41003C22.2909 9.2512 22.2952 8.99804 22.4541 8.84465C22.609 8.69501 22.8547 8.69501 23.0097 8.84465L23.2923 9.12734C23.4485 9.28353 23.4484 9.53668 23.2922 9.69282C23.2173 9.76775 23.1157 9.80988 23.0097 9.80988Z"
          fill="#EEC88B"
        />
        <path
          d="M23.5951 8.39637H23.1953C22.9744 8.39637 22.7954 8.21734 22.7954 7.99652C22.7954 7.77571 22.9744 7.59668 23.1953 7.59668H23.5951C23.8159 7.59668 23.9949 7.77571 23.9949 7.99652C23.9949 8.21734 23.8159 8.39637 23.5951 8.39637Z"
          fill="#EEC88B"
        />
        <path
          d="M22.7274 7.26597C22.5066 7.26592 22.3276 7.08689 22.3276 6.86602C22.3276 6.76001 22.3698 6.65835 22.4447 6.58343L22.7274 6.30074C22.8862 6.1473 23.1393 6.1517 23.2928 6.31054C23.4424 6.46548 23.4424 6.71113 23.2928 6.86612L23.0101 7.14881C22.9351 7.22378 22.8334 7.26597 22.7274 7.26597Z"
          fill="#EEC88B"
        />
        <path
          d="M21.5961 6.79719C21.3753 6.79719 21.1963 6.61816 21.1963 6.39734V5.9975C21.1963 5.77669 21.3753 5.59766 21.5961 5.59766C21.8169 5.59766 21.996 5.77669 21.996 5.9975V6.39734C21.996 6.61816 21.8169 6.79719 21.5961 6.79719Z"
          fill="#EEC88B"
        />
        <path
          d="M20.4647 7.26593C20.3587 7.26593 20.257 7.22375 20.182 7.14878L19.8994 6.86609C19.746 6.70725 19.7503 6.4541 19.9092 6.30071C20.0641 6.15107 20.3097 6.15107 20.4647 6.30071L20.7474 6.5834C20.9036 6.73959 20.9035 6.99274 20.7473 7.14888C20.6724 7.2238 20.5707 7.26593 20.4647 7.26593Z"
          fill="#EEC88B"
        />
        <path
          d="M2.40352 0.399414L3.02128 1.65133L4.40274 1.85205L3.40313 2.82647L3.63904 4.20233L2.40352 3.55259L1.168 4.20233L1.40391 2.82647L0.404297 1.85205L1.78576 1.65133L2.40352 0.399414Z"
          fill="#EEC88B"
        />
        <path
          d="M23.8687 21.6584C23.6785 20.9844 23.2407 20.4073 22.6428 20.0427L22.4781 19.7956C21.5544 18.4142 20.1019 17.475 18.4632 17.1994C18.2446 17.1682 18.0421 17.3201 18.0109 17.5387C17.9808 17.75 18.1217 17.9476 18.3313 17.9879C19.5132 18.1885 20.5903 18.789 21.3825 19.6888C20.7165 19.8086 20.134 20.2086 19.7831 20.7872C19.3525 21.5584 19.6286 22.5327 20.3999 22.9633C21.1711 23.394 22.1454 23.1178 22.576 22.3466C22.7405 22.029 22.8293 21.6777 22.8355 21.3202C23.261 21.9381 23.295 22.745 22.9231 23.3966C22.8104 23.5865 22.8731 23.8318 23.063 23.9444C23.2529 24.0571 23.4982 23.9945 23.6109 23.8045C23.6145 23.7985 23.6179 23.7923 23.6212 23.786C23.9845 23.1383 24.0736 22.3722 23.8687 21.6584ZM21.8787 21.9563C21.6653 22.343 21.1788 22.4835 20.7922 22.27C20.4055 22.0566 20.265 21.5702 20.4784 21.1835C20.4796 21.1814 20.4808 21.1792 20.482 21.177C20.718 20.7907 21.116 20.5317 21.5648 20.4725C21.6343 20.4706 21.7032 20.4866 21.7647 20.5189C22.1022 20.7072 22.1202 21.5237 21.8787 21.9563Z"
          fill="#6DA6C2"
        />
        <path
          d="M15.4072 18.3254C15.7273 18.2763 16.024 18.1282 16.2556 17.9019C16.6523 17.5057 16.9934 16.6888 16.3652 15.0506C16.2903 14.8429 16.0612 14.7352 15.8535 14.81C15.6457 14.8849 15.538 15.114 15.6129 15.3217C15.6147 15.3268 15.6167 15.3319 15.6187 15.3369C15.9833 16.2886 16.0097 17.0163 15.6903 17.3361C15.3032 17.7224 14.3396 17.6016 13.1113 17.0139C11.4667 16.1602 9.97189 15.0447 8.68539 13.7112C5.55301 10.5788 4.26071 7.50518 5.06 6.70589C5.31525 6.50457 5.64682 6.4272 5.96485 6.49477C6.18401 6.52176 6.38358 6.36592 6.41052 6.14676C6.43751 5.92759 6.28167 5.72802 6.06251 5.70108C6.05571 5.70023 6.04886 5.69958 6.04202 5.69908C5.48608 5.60072 4.91571 5.76281 4.49462 6.13891C3.02999 7.60554 5.26912 11.426 8.12161 14.2769C9.47198 15.6745 11.0412 16.8425 12.7674 17.7352C13.0802 17.8849 13.4025 18.0138 13.7322 18.121L10.8893 19.1962C7.47526 17.8943 4.89796 15.0267 3.96643 11.4936C3.87046 11.1278 3.79209 10.7539 3.73332 10.3821C3.70533 10.163 3.50506 10.0081 3.28604 10.0361C3.06698 10.0641 2.91209 10.2644 2.94008 10.4834C2.94103 10.4909 2.94222 10.4985 2.94362 10.506C3.0064 10.9058 3.09037 11.3056 3.19313 11.6967C4.11347 15.1851 6.53812 18.0833 9.80935 19.6048L5.13317 21.3741C5.11188 21.3383 5.08484 21.3061 5.0532 21.279C3.86487 20.325 3.11931 18.9257 2.99041 17.4073L2.80368 15.1605C2.78898 14.9429 2.60061 14.7783 2.38294 14.793C2.37924 14.7932 2.37555 14.7935 2.37185 14.7939C2.15178 14.8121 1.9882 15.0053 2.00639 15.2253L2.19352 17.4728C2.33446 19.0958 3.09147 20.6031 4.30909 21.6852L0.973991 22.9479L1.57376 20.4865C1.62609 20.2718 1.49454 20.0554 1.27987 20.0031C1.06521 19.9507 0.84879 20.0823 0.796461 20.2969L0.0163643 23.4957C-0.0359653 23.7102 0.0955335 23.9266 0.31005 23.9789C0.341038 23.9865 0.372775 23.9904 0.404613 23.9904C0.452944 23.9903 0.500876 23.9817 0.546158 23.9648L15.3404 18.3669C15.3641 18.3555 15.3865 18.3416 15.4072 18.3254Z"
          fill="#373737"
        />
        <path
          d="M13.3634 13.3615L11.9352 13.6015C11.7165 13.6318 11.5638 13.8338 11.5942 14.0525C11.6246 14.2713 11.8265 14.4239 12.0453 14.3935C12.0525 14.3925 12.0597 14.3913 12.0668 14.3899L13.495 14.152C15.6334 13.7965 17.8277 14.0097 19.8577 14.7702C19.9024 14.786 19.9495 14.794 19.9969 14.7938C20.2177 14.7941 20.397 14.6155 20.3974 14.3946C20.3977 14.2274 20.2938 14.0777 20.1372 14.0193C17.976 13.2103 15.64 12.9834 13.3634 13.3615Z"
          fill="#373737"
        />
        <path
          d="M12.4398 12.1671C12.5347 12.3663 12.7731 12.4509 12.9724 12.3562L21.3692 8.35776C21.5675 8.26074 21.6496 8.02124 21.5526 7.82287C21.4571 7.62759 21.223 7.54437 21.0257 7.63564L12.629 11.6341C12.4296 11.729 12.3449 11.9676 12.4398 12.167C12.4398 12.167 12.4398 12.167 12.4398 12.1671Z"
          fill="#373737"
        />
        <path
          d="M7.97155 11.5648C8.02018 11.5853 8.07236 11.5957 8.12509 11.5956C8.28648 11.5956 8.43202 11.4986 8.49415 11.3497L9.07552 9.95625C10.0341 7.66674 10.2577 5.13598 9.71527 2.71387C9.67129 2.49746 9.46022 2.35766 9.2438 2.40164C9.02739 2.44563 8.88759 2.65669 8.93158 2.87311C8.93252 2.87791 8.93362 2.88266 8.93477 2.8874C9.44123 5.14842 9.23276 7.51095 8.33821 9.64837L7.75643 11.0434C7.67247 11.2468 7.76863 11.4798 7.97155 11.5648Z"
          fill="#373737"
        />
        <path
          d="M20.7964 15.5941C20.7964 16.2566 21.3334 16.7936 21.9959 16.7936C22.6584 16.7936 23.1955 16.2566 23.1955 15.5941C23.1955 14.9316 22.6584 14.3945 21.9959 14.3945C21.3334 14.3945 20.7964 14.9316 20.7964 15.5941ZM22.3958 15.5941C22.3958 15.8149 22.2167 15.9939 21.9959 15.9939C21.7751 15.9939 21.5961 15.8149 21.5961 15.5941C21.5961 15.3732 21.7751 15.1942 21.9959 15.1942C22.2167 15.1942 22.3958 15.3732 22.3958 15.5941Z"
          fill="#373737"
        />
        <path
          d="M13.5993 4.39875C13.5993 3.73626 13.0622 3.19922 12.3997 3.19922C11.7372 3.19922 11.2002 3.73626 11.2002 4.39875C11.2002 5.06124 11.7372 5.59828 12.3997 5.59828C13.0622 5.59828 13.5993 5.06124 13.5993 4.39875ZM11.9999 4.39875C11.9999 4.17794 12.1789 3.99891 12.3997 3.99891C12.6205 3.99891 12.7996 4.17794 12.7996 4.39875C12.7996 4.61957 12.6205 4.7986 12.3997 4.7986C12.1789 4.7986 11.9999 4.61957 11.9999 4.39875Z"
          fill="#373737"
        />
        <path
          d="M8.80115 1.59938C9.24281 1.59938 9.60084 1.24134 9.60084 0.799688C9.60084 0.358033 9.24281 0 8.80115 0C8.3595 0 8.00146 0.358033 8.00146 0.799688C8.00146 1.24134 8.3595 1.59938 8.80115 1.59938Z"
          fill="#373737"
        />
        <path
          d="M19.5972 2.79871C19.5972 3.01953 19.7762 3.19856 19.997 3.19856C20.2178 3.19856 20.3969 3.01953 20.3969 2.79871V2.39887C20.3969 2.17805 20.2178 1.99902 19.997 1.99902C19.7762 1.99902 19.5972 2.17805 19.5972 2.39887V2.79871Z"
          fill="#373737"
        />
        <path
          d="M19.997 0C19.7762 0 19.5972 0.17903 19.5972 0.399844V0.799689C19.5972 1.0205 19.7762 1.19953 19.997 1.19953C20.2178 1.19953 20.3969 1.0205 20.3969 0.799689V0.399844C20.3969 0.17903 20.2178 0 19.997 0Z"
          fill="#373737"
        />
        <path
          d="M21.1962 1.19922H20.7963C20.5755 1.19922 20.3965 1.37825 20.3965 1.59906C20.3965 1.81988 20.5755 1.99891 20.7963 1.99891H21.1962C21.417 1.99891 21.596 1.81988 21.596 1.59906C21.596 1.37825 21.417 1.19922 21.1962 1.19922Z"
          fill="#373737"
        />
        <path
          d="M19.1971 1.19922H18.7973C18.5765 1.19922 18.3975 1.37825 18.3975 1.59906C18.3975 1.81988 18.5765 1.99891 18.7973 1.99891H19.1971C19.418 1.99891 19.597 1.81988 19.597 1.59906C19.597 1.37825 19.418 1.19922 19.1971 1.19922Z"
          fill="#373737"
        />
        <path
          d="M17.1978 7.19695C17.4186 7.19695 17.5976 7.01792 17.5976 6.79711V4.78949C17.5976 4.56868 17.4186 4.38965 17.1978 4.38965H15.1913C14.9705 4.38965 14.7915 4.56868 14.7915 4.78949V6.79711C14.7915 7.01792 14.9705 7.19695 15.1913 7.19695H17.1978ZM15.59 5.18934H16.7979V6.39727H15.5912L15.59 5.18934Z"
          fill="#373737"
        />
        <path
          d="M9.70452 11.8632C9.85221 12.0274 10.105 12.0407 10.2692 11.8931C10.2693 11.893 10.2694 11.8929 10.2695 11.8928L14.2679 8.29421C14.4291 8.14322 14.4374 7.89022 14.2864 7.72903C14.1396 7.57234 13.8953 7.55954 13.7329 7.70004L9.73451 11.2986C9.57037 11.4463 9.55693 11.699 9.70452 11.8632Z"
          fill="#373737"
        />
        <path
          d="M21.996 9.99598V9.59613C21.996 9.37532 21.8169 9.19629 21.5961 9.19629C21.3753 9.19629 21.1963 9.37532 21.1963 9.59613V9.99598C21.1963 10.2168 21.3753 10.3958 21.5961 10.3958C21.8169 10.3958 21.996 10.2168 21.996 9.99598Z"
          fill="#373737"
        />
        <path
          d="M22.4452 9.41007L22.7279 9.69276C22.8868 9.8462 23.1399 9.8418 23.2933 9.68296C23.443 9.52802 23.443 9.28237 23.2933 9.12738L23.0106 8.84469C22.8518 8.69125 22.5987 8.69565 22.4452 8.85449C22.2956 9.00947 22.2956 9.25513 22.4452 9.41007Z"
          fill="#373737"
        />
        <path
          d="M23.5956 7.59668H23.1957C22.9749 7.59668 22.7959 7.77571 22.7959 7.99652C22.7959 8.21734 22.9749 8.39637 23.1957 8.39637H23.5956C23.8164 8.39637 23.9954 8.21734 23.9954 7.99652C23.9954 7.77571 23.8164 7.59668 23.5956 7.59668Z"
          fill="#373737"
        />
        <path
          d="M22.7284 7.26593C22.8344 7.26593 22.9361 7.22375 23.0111 7.14878L23.2938 6.86609C23.4471 6.70725 23.4428 6.4541 23.284 6.30071C23.129 6.15107 22.8834 6.15107 22.7284 6.30071L22.4457 6.5834C22.2895 6.73959 22.2896 6.99274 22.4458 7.14888C22.5208 7.2238 22.6224 7.26593 22.7284 7.26593Z"
          fill="#373737"
        />
        <path
          d="M21.5961 5.59766C21.3753 5.59766 21.1963 5.77669 21.1963 5.9975V6.39734C21.1963 6.61816 21.3753 6.79719 21.5961 6.79719C21.8169 6.79719 21.996 6.61816 21.996 6.39734V5.9975C21.996 5.77669 21.8169 5.59766 21.5961 5.59766Z"
          fill="#373737"
        />
        <path
          d="M20.4662 6.30074C20.3073 6.1473 20.0542 6.1517 19.9008 6.31054C19.7512 6.46548 19.7512 6.71113 19.9008 6.86612L20.1835 7.14881C20.3423 7.30225 20.5954 7.29785 20.7489 7.13901C20.8985 6.98408 20.8985 6.73842 20.7489 6.58343L20.4662 6.30074Z"
          fill="#373737"
        />
        <path
          d="M4.78421 1.72847C4.73707 1.58373 4.61182 1.47832 4.46113 1.45658L3.28799 1.28584L2.76339 0.222659C2.64674 0.0245858 2.39159 -0.0414385 2.19351 0.075216C2.13264 0.111052 2.08191 0.161782 2.04607 0.222659L1.52148 1.28584L0.347934 1.45658C0.129419 1.48852 -0.0218217 1.69154 0.0101158 1.91005C0.0228109 1.99682 0.0636449 2.07704 0.12642 2.13831L0.97529 2.96599L0.775368 4.13473C0.737232 4.35225 0.882626 4.55947 1.10014 4.5976C1.18721 4.61285 1.27687 4.59885 1.35514 4.55777L2.40353 4.00438L3.45312 4.55617C3.6486 4.65893 3.89035 4.58381 3.99311 4.38833C4.03405 4.31052 4.04814 4.2214 4.0333 4.13473L3.83338 2.96599L4.68264 2.13831C4.79175 2.03215 4.83114 1.87327 4.78421 1.72847ZM3.12525 2.54016C3.03109 2.63187 2.98806 2.76402 3.0101 2.89362L3.10926 3.47139L2.59066 3.1983C2.47416 3.13702 2.33491 3.13702 2.21841 3.1983L1.69861 3.47099L1.79817 2.89322C1.82026 2.76392 1.77753 2.63197 1.68381 2.54016L1.26478 2.13111L1.84375 2.04675C1.97415 2.02795 2.08691 1.94614 2.14523 1.82803L2.40353 1.30304L2.66263 1.82803C2.72081 1.94594 2.83322 2.0277 2.96331 2.04675L3.54269 2.13111L3.12525 2.54016Z"
          fill="#373737"
        />
        <path
          d="M22.6442 20.0422L22.4795 19.7951C21.5554 18.414 20.1029 17.4749 18.4642 17.1989C18.2475 17.1566 18.0375 17.298 17.9953 17.5148C17.953 17.7316 18.0944 17.9415 18.3112 17.9838C18.3183 17.9852 18.3255 17.9864 18.3327 17.9874C19.5145 18.1879 20.5916 18.7884 21.3835 19.6883C20.7177 19.8085 20.1353 20.2084 19.7841 20.7867C19.3535 21.558 19.6297 22.5324 20.4011 22.963C21.1724 23.3937 22.1468 23.1174 22.5774 22.3461C22.7412 22.0283 22.8295 21.6771 22.8357 21.3197C22.9522 21.4905 23.0416 21.6782 23.1008 21.8763C23.2466 22.3862 23.1827 22.9331 22.9233 23.3957C22.8106 23.5856 22.8733 23.8309 23.0632 23.9436C23.2531 24.0562 23.4984 23.9936 23.6111 23.8037C23.6147 23.7976 23.6181 23.7914 23.6214 23.7851C24.3528 22.4773 23.9218 20.8254 22.6446 20.0418L22.6442 20.0422ZM21.8801 21.9558C21.665 22.3416 21.1779 22.4799 20.7922 22.2648C20.6068 22.1614 20.4701 21.9885 20.4123 21.7843C20.3537 21.5803 20.3793 21.3614 20.4834 21.1765C20.7193 20.7901 21.1173 20.5311 21.5662 20.472C21.6357 20.4702 21.7046 20.4862 21.7661 20.5184C22.1032 20.7067 22.1212 21.5232 21.8801 21.9558Z"
          fill="#373737"
        />
      </g>
      <defs>
        <clipPath id="clip0_866_21363">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
