import confetti from 'canvas-confetti';

export default function showConfetti(primaryColor, accentColor) {
  const colors = [primaryColor, accentColor, '#e7e7e7'];

  confetti({
    particleCount: 200,
    angle: 60,
    spread: 55,
    origin: { x: 0 },
    colors,
  });

  confetti({
    particleCount: 200,
    angle: 120,
    spread: 55,
    origin: { x: 1 },
    colors,
  });

  confetti({
    particleCount: 200,
    angle: 90,
    spread: 55,
    origin: { x: 2 },
    colors,
  });
}
