import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`

  body {
    background-color: ${({ theme }) => theme.defaults.body.backgroundColor};
    box-sizing: border-box;
    color: ${({ theme }) => theme.defaults.body.fontColor};
    font-family: ${({ theme }) => theme.defaults.body.fontFamily};
    font-size: ${({ theme }) => theme.defaults.body.fontSize};
    margin: 0;
  }
`;

export default GlobalStyles;
