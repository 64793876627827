export const size = {
  mobile: '22.5rem',
  tablet: '46.875rem',
  desktop: '68.75rem',
};

export const breakpoints = {
  mobile: `(min-width: ${size.mobile})`,
  tablet: `(min-width: ${size.tablet})`,
  desktop: `(min-width: ${size.desktop})`,
};
