import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import classnames from 'classnames';
import _isString from 'lodash/isString';
import _omit from 'lodash/omit';
import propTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { NavLink } from 'react-router-dom';
import { getContrastColor } from '../../../../shared/helpers/General';
import sButton from './button.scss';

export const enhance = compose(withStyles(sButton));

function Button(props) {
  const [customStyle, setCustomStyle] = useState({});
  useEffect(() => {
    if (props.display === 'clientDark') {
      const colorDark = document.documentElement.style.getPropertyValue(`--theme_color_dark`);
      const darkText = getContrastColor(colorDark);
      setCustomStyle({ color: darkText });
    }

    if (props.display === 'clientAccent') {
      const colorAccent = document.documentElement.style.getPropertyValue(`--theme_color_accent`);
      const accentText = getContrastColor(colorAccent);
      setCustomStyle({ color: accentText });
    }
    if (props.isButtonDisabled === true) {
      setCustomStyle({ backgroundColor: '#D7D7D7', color: '#5A5A5A' });
    }
  }, [props]);

  const workingProps = {
    ..._omit(props, ['isLink', 'isNavLink']),
  };

  if (!!props.loading) {
    workingProps.disabled = true;
  }

  const builtClass = classnames(
    sButton.button,
    workingProps.display === 'primary' ? null : sButton[workingProps.display],
    sButton[workingProps.size],
    sButton[workingProps.kind],
    workingProps.loading ? sButton.loading : '',
  );

  if (props.isLink && !props.disabled) {
    const linkProps = {
      ..._omit(workingProps, ['loading']),
      className: builtClass,
    };
    if (_isString(props.href) && props.href.length && props.href.indexOf('http') === 0) {
      return <a {...linkProps} />;
    }
    linkProps.to = linkProps.href;
    if (props.isNavLink) {
      return <NavLink {..._omit(linkProps, ['href', 'ariaLabel'])} aria-label={props.ariaLabel} />;
    }
    return <NavLink {..._omit(linkProps, ['href', 'ariaLabel'])} aria-label={props.ariaLabel} />;
  }
  return (
    <button
      {..._omit(workingProps, ['loading', 'ariaLabel', 'size', 'kind', 'display', 'loading', 'isLink', 'isNavLink'])}
      className={builtClass}
      disabled={props.disabled}
      aria-label={props.ariaLabel}
      style={customStyle}
    />
  );
}

Button.propTypes = {
  size: propTypes.oneOf([
    'small',
    'normal',
    'medium',
    'large',
    'filterSave',
    'filterApplySaveSize',
    'filterNameSaveSize',
    'dashboardActionSize',
  ]),
  kind: propTypes.oneOf(['solid', 'outline']),
  display: propTypes.oneOf([
    'primary',
    'secondary',
    'navigation',
    'text',
    'darkGrayDisabled',
    'clientDark',
    'clientAccent',
    'filterApplySaveDisplay',
    'filterNameSaveDisplay',
    'dashboardActionDisplay',
  ]),
  loading: propTypes.bool,
  isLink: propTypes.bool,
  isNavLink: propTypes.bool,
  type: propTypes.string,
  disabled: propTypes.bool,
  ariaLabel: propTypes.string,
};

Button.defaultProps = {
  size: 'normal',
  kind: 'solid',
  display: 'primary',
  loading: false,
  isLink: false,
  isNavLink: false,
  type: 'button',
  disabled: false,
  ariaLabel: 'button',
};

export default enhance(Button);
