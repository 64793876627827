import React from 'react';
import PropTypes from 'prop-types';

export default function CloseIcon({ fillColor, width="23", height="23" }) {
  return (
    <svg width={width} height={height} viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
      <path
        d="M14.4886 11.8027L22.1903 4.10096C22.5558 3.73609 22.7614 3.24095 22.7619 2.72448C22.7623 2.20801 22.5576 1.71251 22.1927 1.34699C21.8279 0.981462 21.3327 0.775858 20.8163 0.775402C20.2998 0.774946 19.8043 0.979675 19.4388 1.34455L11.737 9.04629L4.03529 1.34455C3.66977 0.97903 3.17401 0.773682 2.65708 0.773682C2.14016 0.773682 1.6444 0.97903 1.27888 1.34455C0.913357 1.71008 0.708008 2.20583 0.708008 2.72276C0.708008 3.23969 0.913357 3.73544 1.27888 4.10096L8.98062 11.8027L1.27888 19.5044C0.913357 19.87 0.708008 20.3657 0.708008 20.8826C0.708008 21.3996 0.913357 21.8953 1.27888 22.2608C1.6444 22.6264 2.14016 22.8317 2.65708 22.8317C3.17401 22.8317 3.66977 22.6264 4.03529 22.2608L11.737 14.5591L19.4388 22.2608C19.8043 22.6264 20.3 22.8317 20.817 22.8317C21.3339 22.8317 21.8296 22.6264 22.1952 22.2608C22.5607 21.8953 22.766 21.3996 22.766 20.8826C22.766 20.3657 22.5607 19.87 22.1952 19.5044L14.4886 11.8027Z"
        fill={fillColor}
      />
    </svg>
  );
}

CloseIcon.propTypes = {
  fillColor: PropTypes.string,
};

CloseIcon.defaultProps = {
  fillColor: '#446372',
};
