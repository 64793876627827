import React, { useEffect, useState } from 'react';
import Modal from 'react-responsive-modal';
import { compose } from 'recompose';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import styled from 'styled-components';
import modalCss from 'react-responsive-modal/styles.css';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '../../sites/travelerProfile/components/icons/CloseIcon';
import InfoIcon from '../../sites/travelerProfile/components/icons/InfoIcon';
import { toast } from 'react-toastify';
import { CircularProgress } from '@material-ui/core';
import DropdownSelect from '../../styledComponents/styles/DropdownSelect.styled';
import { respondRequestToWithdraw, fetchTravelerSubmission } from '../../actions/formsActions';
import toastMessageStyles from '../../sites/viaGlobal/components/toastMessage/toastMessage.scss';
import { reasonOptions } from '../../helpers';
import TextInput from '../../styledComponents/styles/TextInput.styled';

export const enhance = compose(withStyles(modalCss, toastMessageStyles));

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 25px 25px;
  border-bottom: 1px solid #f0f1f1;
`;

const Container = styled.div`
  padding: 30px 70px;
`;

const Heading = styled.h2`
  color: #373737;
  text-align: center;

  /* Headline 5 */
  font-family: Nunito;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 30px */
`;

const Footer = styled.div`
  border-radius: 0px 0px 10px 10px;
  background: #ebebeb;
  display: flex;
  flex-direction: row;
  justify-content: right;
  padding: 30px;
  gap: 55px;
`;

const CancelBtn = styled.button`
  color: #373737;
  /* Button Primary */
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 19.2px */
  letter-spacing: 0.96px;
  border: none;
`;

const PrimaryBtn = styled.button`
  border-radius: 5px;
  background: var(--theme_color_accent);
  color: var(--theme_color_accent_font);
  text-align: center;
  /* Button Primary */
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 19.2px */
  letter-spacing: 0.96px;
  border: 1px #d74b73;
  padding: 16px 28px;

  &:hover {
    opacity: 0.7;
  }

  &:disabled {
    opacity: 0.7;
  }
`;

const CloseButton = styled.button`
  outline: none;
  border: none;
  padding: 0;
  background: none;
`;

const LabelDescription = styled.div`
  color: #373737;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 16.8px */
  letter-spacing: 0.035px;
  margin-bottom: 40px;
`;

const FieldSection = styled.div`
  padding: 0px 10px;
  margin-bottom: 46px;
`;

function RequestToWithdrawAcceptModal({ open, onClose, submissionId, submissionWithdrawalRequestId }) {
  const dispatch = useDispatch();
  const { loading } = useSelector(state => state.forms.respondRequestToWithdraw);
  const customAliases = useSelector(state => state.profile.customAliases);
  const [reason, setReason] = useState({
    value: '',
    error: '',
  });

  const [otherReason, setOtherReason] = useState({
    value: '',
    error: '',
  });

  const reasonOptionsList = reasonOptions(customAliases.alias_program);
  const handleSubmit = () => {
    let isError = false;
    const reasonName = reasonOptionsList.find(item => item.value === reason.value)?.label;
    if (!reason.value) {
      isError = true;
      setReason({
        ...reason,
        error: 'A reason must be selected',
      });
    }

    if (reasonName === 'Other') {
      if (!otherReason.value) {
        isError = true;
        setOtherReason({
          ...otherReason,
          error: 'Reason is required',
        });
      }
    }

    if (isError) {
      return false;
    }
    let reasonValue = [reason.value];
    if (reasonName === 'Other') {
      reasonValue = [reason.value, otherReason.value];
    }
    dispatch(
      respondRequestToWithdraw(
        submissionId,
        submissionWithdrawalRequestId,
        'accepted',
        reasonValue,
        (status, message) => {
          if (status) {
            toast(message, {
              type: 'success',
              className: toastMessageStyles['successContainer'],
              toastClassName: toastMessageStyles['wrapper'],
              bodyClassName: toastMessageStyles['successBody'],
              progressClassName: toastMessageStyles['progress-bar'],
            });

            dispatch(fetchTravelerSubmission(submissionId));

            onClose();
          } else {
            toast(message, {
              type: 'error',
              className: toastMessageStyles['failureContainer'],
              toastClassName: toastMessageStyles['wrapper'],
              bodyClassName: toastMessageStyles['failureBody'],
              progressClassName: toastMessageStyles['progress-bar'],
            });
          }
        },
      ),
    );
  };

  return (
    <Modal
      blockScroll={false}
      open={open}
      onClose={onClose}
      center
      closeOnEsc
      closeOnOverlayClick
      showCloseIcon={false}
      styles={{
        modal: {
          padding: '0px',
          borderRadius: '5px',
          width: '520px',
        },
      }}
    >
      <Header>
        <Heading>Request to Withdraw</Heading>

        <CloseButton onClick={onClose}>
          <CloseIcon fillColor={`#9FABAE`} />
        </CloseButton>
      </Header>

      <Container>
        <LabelDescription>Select the internal reason for withdrawal.</LabelDescription>

        <FieldSection>
          <DropdownSelect
            id={'withdrawReason'}
            name="withdrawReason"
            label={'Reason'}
            options={reasonOptionsList}
            defaultValue={reason.value}
            onChange={value => {
              setReason({
                value: value,
                error: '',
              });
            }}
            required={true}
            errorMsg={reason.error}
          />
        </FieldSection>

        {reasonOptionsList?.find(item => item.value == reason.value)?.label === 'Other' && (
          //reason.value === 'Other' && (
          <FieldSection>
            <TextInput
              id={'otherReason'}
              name="otherReason"
              label={'Other Reason'}
              value={otherReason.value}
              onChange={e => {
                setOtherReason({
                  value: e.target.value,
                  error: '',
                });
              }}
              required={true}
              errorMsg={otherReason.error}
              placeholder={'Enter reason here'}
            />
          </FieldSection>
        )}
      </Container>

      <Footer>
        <CancelBtn onClick={onClose} type="button">
          Cancel
        </CancelBtn>
        <PrimaryBtn disabled={loading} onClick={handleSubmit}>
          Withdraw
          {loading && (
            <>
              {' '}
              <CircularProgress color="inherit" size={20} />
            </>
          )}
        </PrimaryBtn>
      </Footer>
    </Modal>
  );
}

export default enhance(RequestToWithdrawAcceptModal);
