import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import s from './Expand.scss';

class Expand extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formExpanded: this.props.formExpanded,
    };
  }

  openBlock(event) {
    const block = event.target.getAttribute('data-name');

    this.setState({
      [block]: !this.state[block],
    });
  }
  render() {
    const { headerTitle, count } = this.props;
    return (
      <div>
        <div
          className={s.accordion}
          onClick={this.openBlock.bind(this)}
          data-name="formExpanded"
        >
          <h1 data-name="formExpanded" className={s.sectionHeader}>
            {headerTitle}
            {count !== undefined ? <p>{count}</p> : <div />}
          </h1>
          <div className={s.iconWrapper}>
            <div
              data-name="formExpanded"
              className={
                this.state.formExpanded
                  ? `${s.icon} ${s.iconMinus}`
                  : `${s.icon} ${s.iconPlus}`
              }
            />
          </div>
        </div>
        <div
          className={
            this.state.formExpanded ? 'form form--default' : `${s.hidden}`
          }
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default withStyles(s)(Expand);
