import PropTypes from 'prop-types';
import styled from 'styled-components';
import React, { Fragment, useState } from 'react';

import currencyToSymbolMap from 'currency-symbol-map/map';

import formatDate from '../../../utils/formatDate';
import { breakpoints } from '../../../utils/breakpoints';
import { buttonFocus } from '../../../utils/shared/focusStyles';

const DisclosureItem = styled.div`
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  border-bottom: 0.0625rem solid #eeeeee;

  &:last-of-type {
    border-bottom: none;
  }

  .term {
    grid-column: span 6 / span 6;
    justify-self: start;
    font-weight: 600;
    color: #446372;
    text-align: left;
    display: flex;
    align-items: center;

    @media ${breakpoints.tablet} {
      grid-column: span 8 / span 8;
    }
  }

  .cost {
    grid-column: span 4 / span 4;
    justify-self: start;
    font-weight: 500;
    letter-spacing: 0.0025em;
    color: #373737;
    text-align: left;
    display: flex;
    align-items: center;

    @media ${breakpoints.tablet} {
      grid-column: span 3 / span 3;
    }
  }

  .svg {
    grid-column: span 2 / span 2;
    display: flex;
    align-items: center;

    @media ${breakpoints.tablet} {
      grid-column: span 1 / span 1;
    }
  }

  .arrow-icon {
    width: 1.625rem;
    height: 1.5rem;
    color: #373737;
    transform: rotate(180deg);
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;

    &.open {
      transform: rotate(0deg);
    }
  }
`;

const DisclosureButton = styled.button`
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  gap: 0.25rem;
  grid-column: span 12 / span 12;
  padding: 0.4375rem;
  font-family: 'Nunito', sans-serif;
  font-style: normal;
  font-size: 0.75rem;
  line-height: 120%;
  border: none;
  background: #ffffff;

  ${buttonFocus}

  @media ${breakpoints.tablet} {
    font-size: 0.875rem;
    padding: 0.9375rem;
    gap: 0.5rem;
  }

  &:nth-of-type(even) {
    background: #eeeeee;
  }
`;

const DisclosurePanel = styled.div`
  grid-column: span 12 / span 12;
  padding: 0.5rem 0.75rem 1rem 0.75rem;
  background-color: #ffffff;

  @media ${breakpoints.tablet} {
    padding: 1.375rem 2.125rem 3.0625rem 2.125rem;
  }
`;

const PanelContentsContainer = styled.div`
  padding: 1.125rem 1.4375rem;
  background-color: #eeeeee;
  border-radius: 10px 10px 0 0;
`;

const PanelContentsTitle = styled.p`
  font-family: 'Nunito', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 120%;
  letter-spacing: 0.015em;
  color: #446372;

  @media ${breakpoints.tablet} {
    font-size: 0.875rem;
  }
`;

const PanelContentsText = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 0.875rem;
  line-height: 120%;
  letter-spacing: 0.005em;
  color: #373737;

  @media ${breakpoints.tablet} {
    font-size: 1rem;
  }
`;

const IframeContentsPanel = styled.div`
  grid-column: span 12 / span 12;
  padding: 0 0.75rem;
  background-color: #ffffff;

  @media ${breakpoints.tablet} {
    padding: 0 2.125rem;
  }
`;

const IframeContainer = styled.div`
  padding: 01.4375rem 01.4375rem 0 01.4375rem;
  overflow: scroll;
`;

const IframeTitle = styled.p`
  font-family: 'Nunito', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 120%;
  letter-spacing: 0.015em;
  color: #446372;

  @media ${breakpoints.tablet} {
    font-size: 0.875rem;
  }
`;

export default function TermCostsAccordion({ items }) {
  return (
    <DisclosureItem>
      {items.map(item => {
        const defaultOpen = items?.length === 1;
        const allowToggle = item.range.cost_embedded_url?.length > 0 || item.range.cost_notes?.length > 0;
        const [isOpen, setIsOpen] = useState(defaultOpen && allowToggle);

        return (
          <Fragment key={`accordion-item-${item.range.id}`}>
            <DisclosureButton
              aria-expanded={isOpen}
              aria-controls={`accordion-item-${item.range.id}`}
              onClick={() => {
                if (allowToggle) setIsOpen(!isOpen);
              }}
            >
              <span className="term">
                {`${item.range.name ? item.range.name + '; ' : ''}${formatDate(
                  item.range.start_date,
                  item.range.end_date,
                  item.range.use_exact_dates,
                )}`}
              </span>
              <span className="cost">
                {formatCurrency(
                  item.range.low_cost_cents,
                  item.range.high_cost_cents,
                  item.range.currency_code,
                  item.range.cost_embedded_url,
                  item.range.cost_notes,
                )}
              </span>
              {allowToggle ? (
                <span className="svg">
                  <svg
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 26 24"
                    className={`arrow-icon ${isOpen ? 'open' : ''}`}
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M11.204 7.83c.44-.44 1.152-.44 1.591 0l6.75 6.75a1.125 1.125 0 0 1-1.59 1.59L12 10.216l-5.955 5.955a1.125 1.125 0 0 1-1.59-1.591l6.75-6.75Z"
                      fill="currentColor"
                    />
                  </svg>
                </span>
              ) : null}
            </DisclosureButton>
            {isOpen && (
              <>
                {item?.range?.cost_embedded_url?.length > 0 ? (
                  <IframeContentsPanel>
                    <IframeContainer>
                      <IframeTitle>Term Cost</IframeTitle>
                      <iframe
                        src={item?.range?.cost_embedded_url}
                        scrolling="auto"
                        title="Term Cost"
                        width="725px"
                        height="450px"
                        frameborder="0"
                      />
                    </IframeContainer>
                  </IframeContentsPanel>
                ) : null}

                <DisclosurePanel>
                  <PanelContentsContainer>
                    <PanelContentsTitle>Additional Term Cost Information</PanelContentsTitle>
                    <PanelContentsText
                      id={`accordion-item-${item.range.id}`}
                      dangerouslySetInnerHTML={{
                        __html: item.range.cost_notes || 'There are no additional cost notes.',
                      }}
                    />
                  </PanelContentsContainer>
                </DisclosurePanel>
              </>
            )}
          </Fragment>
        );
      })}
    </DisclosureItem>
  );
}

TermCostsAccordion.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      range: PropTypes.shape({
        name: PropTypes.string.isRequired,
        start_date: PropTypes.string.isRequired,
        end_date: PropTypes.string.isRequired,
        use_exact_dates: PropTypes.bool.isRequired,
        low_cost_cents: PropTypes.number,
        high_cost_cents: PropTypes.number,
        cost_notes: PropTypes.string,
      }),
    }),
  ).isRequired,
};

const formatCurrency = (lowCostCents, highCostCents, currencyCode, embedURL, costNotes) => {
  if (lowCostCents !== null && highCostCents !== null && highCostCents > 0) {
    return `${convertCurrency(currencyCode)}${toDollars(lowCostCents)} - ${convertCurrency(currencyCode)}${toDollars(
      highCostCents,
    )}`;
  }

  if (lowCostCents !== null && lowCostCents > 0) {
    return `${convertCurrency(currencyCode)}${toDollars(lowCostCents)}`;
  }

  if (highCostCents !== null && highCostCents > 0) {
    return `${convertCurrency(currencyCode)}${toDollars(highCostCents)}`;
  }

  if (embedURL?.length > 0 || costNotes?.length > 0) {
    return 'More Details';
  }

  return 'Please Inquire';
};

const toDollars = cents => {
  const dollars = cents / 100;
  return dollars.toLocaleString();
};

const convertCurrency = symbol => {
  let auckland =
    window.location.href.includes('360international.ac.nz') || window.location.href.includes('aucklandabroad.ac.nz');

  if (symbol === 'NZD') {
    return 'NZD$';
  } else if (symbol === 'USD' && auckland) {
    return 'USD$';
  } else if (symbol) {
    return currencyToSymbolMap[symbol];
  } else {
    return '$';
  }
};
