import styled from 'styled-components';

export const Paragraph = styled.p`
  color: ${({ theme }) => theme.paragraph.fontColor};
  font-family: ${({ theme }) => theme.paragraph.fontFamily};
  font-size: ${({ theme }) => theme.paragraph.fontSize};
  font-weight: 400;
  line-height: ${({ theme }) => theme.paragraph.lineHeight};
  p{
    margin-bottom:0px;
  }
  overflow-wrap: anywhere;
`;
