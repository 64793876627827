import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import styles from './index.module.css';  
import loadingIcon from './loading.png'; 

function LoadingIndicator(){
    return (
        <div className={styles.loadingContainer}>
            <img src={loadingIcon} />
            <div className={styles.loadingMessage}>Creating your travel plans.</div>
        </div>
    )
}

export default withStyles(styles)(LoadingIndicator); 