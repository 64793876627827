import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOrganization } from '../../../../../actions/orgSettingsActions';
import { PageHeader } from '../../../../../styledComponents/styles/PageHeader.styled';
import { Paragraph } from '../../../../../styledComponents/styles/Paragraph.styled';
import { PermissionBlockIcon } from '../../../../../styledComponents/styles/IconCustom.styled';
import userId from '../../../../../shared/helpers/userId';

const AccessDeniedContainer = styled.div`
  margin-top: 60px;
  margin-left: 60px;
`;

const ContentContainer = styled.div`
  text-align: center;
`;

const ParagraphMedium = styled(Paragraph)`
  font-family: AvenirNextMedium, sans-serif;
  margin: 10px 0;
`;

const ImageContainer = styled.div`
  margin-top: 40px;
  margin-bottom: 60px;
`;

const Container = styled.div`
  margin-bottom: 30px;
`;

export default function AccessDenied(props) {
  const dispatch = useDispatch();
  const [superAdmins, setSuperAdmins] = useState([]);
  const admins = useSelector(state => state.orgSettings.admins);

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      if (!admins || admins.length === 0) {
        dispatch(fetchOrganization(userId));
      } else {
        let superList = [];
        admins.map(admin => {
          if (admin.role === 'super_user') {
            superList.push({
              id: admin.id,
              fullName: `${admin.first_name} ${admin.last_name}`,
              comma: `${admin.first_name} ${admin.last_name}`.length > 1 ? ',' : '',
              email: admin.email,
            });
          }
        });

        setSuperAdmins(superList);
      }
    }
    return () => {
      unmounted = true;
    };
  }, [admins]);

  return (
    <>
      <AccessDeniedContainer>
        <PageHeader> {props.header}</PageHeader>
        <ContentContainer>
          <ImageContainer>
            <PermissionBlockIcon size={180} />
          </ImageContainer>

          <Container>
            <ParagraphMedium>Your role does not have permission to access this page.</ParagraphMedium>
            <ParagraphMedium>Need access? Contact a Super User of your organization.</ParagraphMedium>
          </Container>
          <Container>
            <ParagraphMedium>Super Users:</ParagraphMedium>
            {superAdmins.map(admin => (
              <Paragraph key={admin.id}>
                {admin.fullName}
                {admin.comma} {admin.email}
              </Paragraph>
            ))}
          </Container>
        </ContentContainer>
      </AccessDeniedContainer>
    </>
  );
}
