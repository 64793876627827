exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".eTFTR{margin-top:18px}._1CkfP{background-image:url(\"/img/sample_bg.jpg\");display:-ms-flexbox;min-height:100vh;height:100%;background-size:contain;display:flex;-ms-flex-pack:center;justify-content:center}._2pl0H{text-align:center;margin-top:40px;width:40%}._1sc7n{font-size:77px;font-style:italic;font-weight:700;margin-bottom:30px}._1jscD{font-size:18px;margin-bottom:45px}.JHXLD{margin-top:20px}._1jscD a{cursor:pointer}@media only screen and (max-width:767px){._1CkfP{display:block;background-position-y:12rem;background-repeat:no-repeat}}", ""]);

// exports
exports.locals = {
	"headerPrimary": "eTFTR",
	"backgroundImg": "_1CkfP",
	"contentDiv": "_2pl0H",
	"titleOfError": "_1sc7n",
	"messageOfError": "_1jscD",
	"mT20": "JHXLD"
};