import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import ProfileFormContainer from '../base/ProfileFormContainer';
import ProfileFormSubheading from '../base/ProfileFormSubheading';
import CurrentAddressForm from '../forms/contact/CurrentAddressForm';
import ToastMessage from '../../../viaGlobal/components/toastMessage';
import PermanentAddressForm from '../forms/contact/PermanentAddressForm';
import PersonalContactInfoForm from '../forms/contact/PersonalContactInfoForm';
import { ProfileFormButton, ProfileFormButtonContainer } from '../base/ProfileFormButton.styled';

import userId from '../../../../shared/helpers/userId';
import { updateTravelerProfile } from '../../actions/travelerProfileActions';

export default function ContactTab() {
  const dispatch = useDispatch();

  const [showToast, setShowToast] = useState(false);

  const [personalContactForm, setPersonalContactForm] = useState({
    phone_number: '',
    personal_email: '',
    on_site_phone_number: '',
  });

  const [currentAddressForm, setCurrentAddressForm] = useState({
    current_address_city: '',
    current_address_state: '',
    current_address_line_1: '',
    current_address_line_2: '',
    current_address_country: '',
    current_address_zip_code: '',
  });

  const [permanentAddressForm, setPermanentAddressForm] = useState({
    permanent_address_city: '',
    permanent_address_state: '',
    permanent_address_line_1: '',
    permanent_address_line_2: '',
    permanent_address_country: '',
    permanent_address_zip_code: '',
  });

  const handleSubmit = event => {
    event.preventDefault();

    const personalContactPayload = {
      ...personalContactForm,
      country_code: null,
    };

    const currentAddressPayload = {
      ...currentAddressForm,
      current_address_country: currentAddressForm.current_address_country.value,
    };

    const permanentAddressPayload = {
      ...permanentAddressForm,
      permanent_address_country: permanentAddressForm.permanent_address_country.value,
    };

    dispatch(
      updateTravelerProfile(userId, {
        travelerProfile: { ...personalContactPayload, ...currentAddressPayload, ...permanentAddressPayload },
      }),
    );

    setShowToast(true);
  };

  return (
    <form method="POST" onSubmit={handleSubmit}>
      <ProfileFormContainer heading="Contact Information">
        <div>
          <div>
            <ProfileFormSubheading text="Personal Contact Information" />
            <PersonalContactInfoForm form={personalContactForm} setForm={setPersonalContactForm} />
          </div>
          <div>
            <ProfileFormSubheading text="Current Address" />
            <CurrentAddressForm form={currentAddressForm} setForm={setCurrentAddressForm} />
          </div>
          <div>
            <ProfileFormSubheading text="Permanent Address" />
            <PermanentAddressForm form={permanentAddressForm} setForm={setPermanentAddressForm} />
          </div>
        </div>
        <ProfileFormButtonContainer>
          <ProfileFormButton type="submit">Save</ProfileFormButton>
        </ProfileFormButtonContainer>
        {showToast && (
          <ToastMessage
            message="All changes saved"
            isSuccess
            show={showToast}
            onClose={() => {
              setShowToast(false);
            }}
          />
        )}
      </ProfileFormContainer>
    </form>
  );
}
