import React from 'react';

export default function ProgramType() {
  return (
    <svg width="24" height="27" viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
      <path
        d="M4.77255 22.1777H1.78482C0.943324 22.1777 0.254883 22.8662 0.254883 23.7077V25.5225C0.254883 25.6934 0.393468 25.832 0.564355 25.832H5.99301C6.1639 25.832 6.30248 25.6935 6.30248 25.5225V23.7077C6.30248 22.8662 5.61404 22.1777 4.77255 22.1777Z"
        fill="#6DA6C2"
      />
      <path
        d="M10.8198 22.1777H7.83218C6.99069 22.1777 6.30225 22.8662 6.30225 23.7077V25.5225C6.30225 25.6934 6.44083 25.832 6.61172 25.832H12.0404C12.2113 25.832 12.3498 25.6935 12.3498 25.5225V23.7077C12.3497 22.8662 11.6613 22.1777 10.8198 22.1777Z"
        fill="#5A869B"
      />
      <path
        d="M16.8673 22.1777H13.8795C13.0381 22.1777 12.3496 22.8662 12.3496 23.7077V25.5225C12.3496 25.6934 12.4882 25.832 12.6591 25.832H18.0877C18.2586 25.832 18.3972 25.6935 18.3972 25.5225V23.7077C18.3972 22.8662 17.7088 22.1777 16.8673 22.1777Z"
        fill="#FBC56D"
      />
      <path
        d="M13.6199 20.061C13.6199 19.4172 13.9678 18.8559 14.485 18.5508C14.2894 18.4596 14.0727 18.4072 13.8437 18.4072H10.8561C10.6271 18.4072 10.4103 18.4596 10.2148 18.5508C10.732 18.8559 11.08 19.4171 11.08 20.061C11.08 21.0295 10.2948 21.8147 9.32617 21.8147V22.1776H10.82C11.6615 22.1776 12.35 22.866 12.35 23.7075C12.35 22.866 13.0384 22.1776 13.8799 22.1776H15.3738V21.8147C14.405 21.8148 13.6199 21.0295 13.6199 20.061Z"
        fill="#71AD81"
      />
      <path
        d="M7.57211 20.061C7.57211 19.4172 7.9201 18.8559 8.43725 18.5508C8.2417 18.4596 8.02485 18.4072 7.79599 18.4072H4.80826C4.57929 18.4072 4.36244 18.4596 4.167 18.5508C4.68414 18.8559 5.03213 19.4171 5.03213 20.061C5.03213 21.0295 4.24699 21.8147 3.27832 21.8147V22.1776H4.77218C5.61368 22.1776 6.30212 22.866 6.30212 23.7075C6.30212 22.866 6.99056 22.1776 7.83206 22.1776H9.32592V21.8147C8.35725 21.8148 7.57211 21.0295 7.57211 20.061Z"
        fill="#FF7B9E"
      />
      <path
        d="M3.27871 18.3076C2.31015 18.3076 1.5249 19.0929 1.5249 20.0614C1.5249 21.03 2.31005 21.8151 3.27871 21.8151C4.24738 21.8151 5.03253 21.0299 5.03253 20.0614C5.03253 19.0929 4.24728 18.3076 3.27871 18.3076Z"
        fill="#F9D0B4"
      />
      <path
        d="M9.32608 18.3076C8.35751 18.3076 7.57227 19.0929 7.57227 20.0614C7.57227 21.03 8.35751 21.8151 9.32608 21.8151C10.2946 21.8151 11.0799 21.0299 11.0799 20.0614C11.0799 19.0929 10.2946 18.3076 9.32608 18.3076Z"
        fill="#F9D0B4"
      />
      <path
        d="M12.35 14.5371C11.3814 14.5371 10.5962 15.3224 10.5962 16.2909C10.5962 17.2595 11.3814 18.0446 12.35 18.0446C13.3186 18.0446 14.1038 17.2594 14.1038 16.2909C14.1038 15.3224 13.3186 14.5371 12.35 14.5371Z"
        fill="#F9D0B4"
      />
      <path
        d="M6.30215 14.5371C5.33359 14.5371 4.54834 15.3224 4.54834 16.2909C4.54834 17.2595 5.33359 18.0446 6.30215 18.0446C7.27072 18.0446 8.05597 17.2594 8.05597 16.2909C8.05586 15.3224 7.27072 14.5371 6.30215 14.5371Z"
        fill="#F9D0B4"
      />
      <path
        d="M15.3734 18.3076C14.4049 18.3076 13.6196 19.0929 13.6196 20.0614C13.6196 21.03 14.4048 21.8151 15.3734 21.8151C16.342 21.8151 17.1273 21.0299 17.1273 20.0614C17.1273 19.0929 16.3421 18.3076 15.3734 18.3076Z"
        fill="#F9D0B4"
      />
      <path
        d="M12.7203 5.14648H11.7907C11.4579 5.14648 11.1855 5.41876 11.1855 5.75167V6.68121C11.1855 7.01412 11.4578 7.2864 11.7907 7.2864H12.7203C13.0531 7.2864 13.3255 7.01412 13.3255 6.68121V5.75167C13.3255 5.41876 13.0532 5.14648 12.7203 5.14648Z"
        fill="#F9D0B4"
      />
      <path
        d="M23.4887 11.1105C23.4664 10.7117 23.1874 10.3737 22.8001 10.2762L21.5478 9.9609C20.884 9.79379 20.362 9.50755 20.3688 9.51091C20.0959 9.3764 19.9231 9.09852 19.9231 8.79424V8.41782C19.6036 8.58504 19.2412 8.68032 18.8574 8.68032C18.4736 8.68032 18.1112 8.58504 17.7918 8.41782V8.79598C17.7918 9.10076 17.6179 9.37875 17.3438 9.51203C16.7366 9.80744 16.0702 9.9609 15.395 9.9609H13.3453C13.2357 9.9609 13.147 9.87205 13.147 9.7626V7.1084C13.0373 7.21784 12.8863 7.28571 12.7201 7.28571H11.7905C11.6486 7.28571 11.5181 7.23598 11.4146 7.15344V10.1059C11.4146 10.9697 12.1124 11.6712 12.9761 11.6759L14.9067 11.6912C15.3429 11.6935 15.6953 12.0478 15.6953 12.484V15.4849C15.6953 15.6525 15.8311 15.7883 15.9987 15.7883H23.4908C23.6365 15.7883 23.7523 15.6659 23.7441 15.5205L23.4887 11.1105Z"
        fill="#D74B73"
      />
      <path
        d="M14.907 1.95955L12.7651 0.888672V3.49274L14.907 2.42177C15.0974 2.32649 15.0974 2.05482 14.907 1.95955Z"
        fill="#D74A73"
      />
      <path
        d="M12.2556 0.254883C11.9741 0.254883 11.7461 0.482937 11.7461 0.764386V5.14836C11.7609 5.14724 11.7757 5.14612 11.7908 5.14612H12.7205C12.7355 5.14612 12.7503 5.14734 12.7652 5.14836V0.764386C12.7651 0.482937 12.537 0.254883 12.2556 0.254883Z"
        fill="#D8A380"
      />
      <path
        d="M12.7205 7.28642H11.7908C11.7757 7.28642 11.761 7.2852 11.7461 7.28418V7.89783C11.7461 8.17928 11.9741 8.40733 12.2556 8.40733C12.537 8.40733 12.7651 8.17928 12.7651 7.89783V7.28418C12.7503 7.2852 12.7355 7.28642 12.7205 7.28642Z"
        fill="#D8A380"
      />
      <path
        d="M20.3692 9.51106C20.0963 9.37655 19.9234 9.09867 19.9234 8.79439V8.41797C19.604 8.58519 19.2416 8.68046 18.8578 8.68046C18.474 8.68046 18.1115 8.58519 17.7922 8.41797V8.79612C17.7922 9.10091 17.6182 9.37889 17.3442 9.51218C17.3134 9.52716 17.2817 9.5402 17.2505 9.55457C17.6675 9.95881 18.2346 10.209 18.8578 10.209C19.4789 10.209 20.0444 9.96044 20.4611 9.55844C20.4004 9.52838 20.3674 9.51024 20.3692 9.51106Z"
        fill="#F9D0B4"
      />
      <path
        d="M19.8033 12.2412H17.9119C17.7678 12.2412 17.6509 12.3581 17.6509 12.5024V14.5438C17.6509 14.6881 17.7678 14.8049 17.9119 14.8049H19.8033C19.9475 14.8049 20.0644 14.6881 20.0644 14.5438V12.5024C20.0644 12.3581 19.9475 12.2412 19.8033 12.2412Z"
        fill="#EDF4FC"
      />
      <path
        d="M19.8033 12.2412H17.9119C17.7678 12.2412 17.6509 12.3581 17.6509 12.5024V13.2979H20.0645V12.5024C20.0644 12.3581 19.9475 12.2412 19.8033 12.2412Z"
        fill="#6DA6C2"
      />
      <path
        d="M3.32815 15.1019C3.01226 15.0408 2.7575 14.7962 2.69636 14.4803C2.69636 14.4701 2.66579 14.4701 2.66579 14.4803C2.60465 14.7962 2.3499 15.0408 2.03401 15.1019C2.02382 15.1019 2.02382 15.1223 2.03401 15.1325C2.3499 15.1936 2.60465 15.4382 2.66579 15.7541C2.66579 15.7642 2.69636 15.7642 2.69636 15.7541C2.7575 15.4382 3.01226 15.1936 3.32815 15.1325C3.33834 15.1223 3.33834 15.1019 3.32815 15.1019Z"
        fill="#71AD81"
      />
      <path
        d="M21.5788 20.5331H21.3342V20.2987C21.3342 20.1153 21.1916 19.9727 21.0081 19.9727C20.8349 19.9727 20.6821 20.1153 20.6821 20.2885V20.5331H20.4477C20.2643 20.5331 20.1216 20.6758 20.1216 20.8592C20.1114 21.0324 20.2541 21.1853 20.4477 21.1853H20.6821V21.4196C20.6821 21.6031 20.8247 21.7457 21.0081 21.7457C21.1814 21.7457 21.3342 21.6031 21.3342 21.4298V21.1853H21.5686C21.752 21.1853 21.8947 21.0426 21.8947 20.8592C21.8947 20.686 21.752 20.5331 21.5788 20.5331Z"
        fill="#6DA6C2"
      />
      <path
        d="M20.0803 23.335C19.889 23.335 19.7339 23.4901 19.7339 23.6814C19.7339 23.8728 19.889 24.0279 20.0803 24.0279C20.2717 24.0279 20.4268 23.8728 20.4268 23.6814C20.4268 23.4901 20.2717 23.335 20.0803 23.335Z"
        fill="#FBC56D"
      />
      <path
        d="M9.68679 0C9.49543 0 9.34033 0.155093 9.34033 0.346462C9.34033 0.537832 9.49543 0.692925 9.68679 0.692925C9.87816 0.692925 10.0333 0.537832 10.0333 0.346462C10.0333 0.155093 9.87816 0 9.68679 0Z"
        fill="#D74A73"
      />
      <path
        d="M8.93287 2.51706C8.47431 2.43554 8.11766 2.07888 8.02595 1.63052C8.02595 1.61014 7.98519 1.61014 7.98519 1.63052C7.89348 2.07888 7.53683 2.43554 7.07827 2.51706C7.05789 2.51706 7.05789 2.54763 7.07827 2.55782C7.53683 2.63934 7.89348 2.99599 7.98519 3.44435C7.98519 3.46473 8.02595 3.46473 8.02595 3.44435C8.11766 2.99599 8.47431 2.63934 8.93287 2.55782C8.95325 2.54763 8.95325 2.51706 8.93287 2.51706Z"
        fill="#FBC56D"
      />
      <path
        d="M20.8405 3.60071C20.6893 3.44205 20.4434 3.42748 20.271 3.5628C19.9984 3.7768 19.5267 4.04123 18.8605 4.04123C18.1927 4.04123 17.7185 3.77547 17.4448 3.56128C17.2726 3.42646 17.0271 3.44154 16.8762 3.5999L16.8754 3.60071C16.6633 3.82316 16.5449 4.11867 16.5449 4.42611V6.36732C16.5449 7.63944 17.5857 8.68036 18.858 8.68036C20.1301 8.68036 21.171 7.63944 21.171 6.36732V4.42611C21.1709 4.11867 21.0526 3.82316 20.8405 3.60071Z"
        fill="#F9D0B4"
      />
      <path
        d="M21.3193 4.60449C21.2688 4.60449 21.2193 4.60877 21.1708 4.61621V6.36727C21.1708 6.41945 21.1685 6.47111 21.165 6.52237C21.2154 6.53052 21.2666 6.53582 21.3193 6.53582C21.8527 6.53582 22.285 6.10345 22.285 5.5701C22.285 5.03675 21.8527 4.60449 21.3193 4.60449Z"
        fill="#F9D0B4"
      />
      <path
        d="M16.5448 6.36727V4.61621C16.4964 4.60867 16.4469 4.60449 16.3964 4.60449C15.863 4.60449 15.4307 5.03686 15.4307 5.5702C15.4307 6.10355 15.863 6.53592 16.3964 6.53592C16.4491 6.53592 16.5003 6.53052 16.5507 6.52247C16.5473 6.47111 16.5448 6.41944 16.5448 6.36727Z"
        fill="#F9D0B4"
      />
      <path
        d="M21.1622 2.63136C21.0848 2.63136 21.0176 2.57165 21.0165 2.4942C20.9988 1.20536 19.5269 0.963246 18.0612 1.21627C16.8185 1.43087 15.9102 2.50847 15.9102 3.76959V4.73663C16.053 4.65307 16.2188 4.60446 16.3963 4.60446C16.4469 4.60446 16.4964 4.60874 16.5448 4.61618V4.42604C16.5448 4.1187 16.6631 3.82309 16.8753 3.60064L16.8761 3.59982C17.027 3.44147 17.2725 3.42639 17.4447 3.5612C17.7185 3.7754 18.1926 4.04116 18.8604 4.04116C19.5266 4.04116 19.9983 3.77662 20.2709 3.56273C20.4433 3.42741 20.6892 3.44198 20.8404 3.60064C21.0526 3.82309 21.1709 4.1186 21.1709 4.42604V4.61618C21.2193 4.60864 21.2688 4.60446 21.3193 4.60446C21.499 4.60446 21.6665 4.65439 21.8106 4.73979C21.8106 4.46995 21.8106 4.19268 21.8106 3.94578C21.8105 2.79165 21.3937 2.63136 21.1622 2.63136Z"
        fill="#5B5D60"
      />
      <path
        d="M21.0249 3.42526C20.7831 3.17194 20.3914 3.14483 20.1136 3.3629C19.8672 3.55631 19.4498 3.78691 18.8604 3.78691C18.2697 3.78691 17.8498 3.55539 17.6016 3.36106C17.3241 3.14401 16.9329 3.17122 16.6908 3.42526C16.4324 3.69632 16.29 4.05185 16.29 4.42644V6.36764C16.29 7.78355 17.442 8.93544 18.8578 8.93544C20.2736 8.93544 21.4255 7.78345 21.4255 6.36764V4.42644C21.4256 4.05195 21.2834 3.69632 21.0249 3.42526ZM20.9161 6.36764C20.9161 7.50261 19.9928 8.42593 18.8579 8.42593C17.7231 8.42593 16.7996 7.50251 16.7996 6.36764V4.42644C16.7996 4.1834 16.892 3.9528 17.0605 3.7761C17.0928 3.74217 17.1363 3.72485 17.1805 3.72485C17.2178 3.72485 17.2558 3.73718 17.2877 3.76225C17.5992 4.00589 18.1247 4.29631 18.8606 4.29631C19.5956 4.29631 20.119 4.0065 20.4283 3.76347C20.4984 3.70834 20.5966 3.71415 20.6563 3.77682C20.824 3.9527 20.9163 4.1833 20.9163 4.42633V6.36764H20.9161Z"
        fill="#373737"
      />
      <path
        d="M21.3195 4.34961C21.2874 4.34961 21.2555 4.35104 21.224 4.35348C21.0838 4.36479 20.9793 4.48768 20.9905 4.6279C21.0017 4.76812 21.1245 4.87175 21.2649 4.86135C21.2829 4.85983 21.301 4.85911 21.3194 4.85911C21.7114 4.85911 22.0303 5.17806 22.0303 5.57018C22.0303 5.96219 21.7114 6.28114 21.3194 6.28114C21.2995 6.28114 21.2799 6.28002 21.2607 6.27818C21.1192 6.2633 20.9957 6.36622 20.9818 6.50613C20.9677 6.64614 21.0698 6.77107 21.2097 6.78504C21.2459 6.7887 21.2824 6.79064 21.3194 6.79064C21.9923 6.79064 22.5398 6.24313 22.5398 5.57018C22.5399 4.89712 21.9924 4.34961 21.3195 4.34961Z"
        fill="#373737"
      />
      <path
        d="M16.7425 6.50359C16.7275 6.36388 16.6027 6.26341 16.462 6.27757C16.4404 6.27981 16.4185 6.28124 16.3961 6.28124C16.0041 6.28124 15.6852 5.96229 15.6852 5.57028C15.6852 5.17816 16.0041 4.85922 16.3961 4.85922C16.4181 4.85922 16.4397 4.86044 16.461 4.86237C16.6017 4.87532 16.7254 4.77301 16.7387 4.633C16.7522 4.49288 16.6494 4.36856 16.5094 4.35521C16.4722 4.35165 16.4345 4.34961 16.3962 4.34961C15.7233 4.34961 15.1758 4.89712 15.1758 5.57018C15.1758 6.24313 15.7233 6.79064 16.3962 6.79064C16.4368 6.79064 16.4771 6.7883 16.5166 6.78402C16.6563 6.76914 16.7575 6.6435 16.7425 6.50359Z"
        fill="#373737"
      />
      <path
        d="M19.6819 6.78322C19.5741 6.69293 19.4135 6.70689 19.323 6.81481C19.2313 6.92425 19.066 6.99232 18.8916 6.99232C18.7173 6.99232 18.5519 6.92425 18.4602 6.81481C18.3696 6.70679 18.209 6.69283 18.1013 6.78322C17.9936 6.8736 17.9794 7.0343 18.0697 7.14211C18.2586 7.36731 18.5658 7.50172 18.8916 7.50172C19.2174 7.50172 19.5246 7.36731 19.7135 7.14211C19.8037 7.0343 19.7896 6.8737 19.6819 6.78322Z"
        fill="#373737"
      />
      <path
        d="M19.9348 5.36621C19.7632 5.36621 19.624 5.50531 19.624 5.67691C19.624 5.84861 19.7631 5.9877 19.9348 5.9877C20.1065 5.9877 20.2456 5.84861 20.2456 5.67691C20.2456 5.50531 20.1064 5.36621 19.9348 5.36621Z"
        fill="#373737"
      />
      <path
        d="M17.8479 5.36621C17.6763 5.36621 17.5371 5.50531 17.5371 5.67691C17.5371 5.84861 17.6762 5.9877 17.8479 5.9877C18.0196 5.9877 18.1587 5.84861 18.1587 5.67691C18.1587 5.50531 18.0195 5.36621 17.8479 5.36621Z"
        fill="#373737"
      />
      <path
        d="M21.2666 2.3838C21.2369 1.9602 21.065 1.61527 20.7546 1.35756C20.0047 0.734848 18.7181 0.844901 18.0177 0.965653C16.6488 1.20196 15.6553 2.38146 15.6553 3.77006V4.68574C15.6553 4.82647 15.7693 4.94049 15.91 4.94049C16.0507 4.94049 16.1648 4.82647 16.1648 4.68574V3.77006C16.1648 2.63 16.9805 1.66184 18.1045 1.46772C19.1489 1.28725 19.9961 1.38997 20.4291 1.74947C20.6484 1.93157 20.7573 2.17644 20.7617 2.49793C20.7645 2.71223 20.9441 2.88648 21.1622 2.88648C21.4086 2.88648 21.5556 3.28267 21.5556 3.94615V4.70215C21.5556 4.84287 21.6697 4.9569 21.8104 4.9569C21.9511 4.9569 22.0651 4.84287 22.0651 4.70215V3.94615C22.065 2.71427 21.6044 2.42783 21.2666 2.3838Z"
        fill="#373737"
      />
      <path
        d="M21.9452 11.8486C21.8045 11.8486 21.6904 11.9627 21.6904 12.1034V15.7397C21.6904 15.8804 21.8045 15.9945 21.9452 15.9945C22.0859 15.9945 22.1999 15.8804 22.1999 15.7397V12.1034C22.1999 11.9628 22.0859 11.8486 21.9452 11.8486Z"
        fill="#373737"
      />
      <path
        d="M12.7205 4.8916H11.791C11.3168 4.8916 10.9312 5.2773 10.9312 5.75144V6.68108C10.9312 7.15522 11.3168 7.54092 11.791 7.54092H12.7205C13.1947 7.54092 13.5805 7.15522 13.5805 6.68108V5.75144C13.5806 5.2773 13.1948 4.8916 12.7205 4.8916ZM13.0711 6.68118C13.0711 6.87438 12.9138 7.03152 12.7206 7.03152H11.7911C11.5979 7.03152 11.4408 6.87428 11.4408 6.68118V5.75154C11.4408 5.55834 11.598 5.40121 11.7911 5.40121H12.7206C12.9138 5.40121 13.0711 5.55844 13.0711 5.75154V6.68118Z"
        fill="#373737"
      />
      <path
        d="M17.7921 8.2319C17.6513 8.2319 17.5373 8.34593 17.5373 8.48665V8.79633C17.5373 9.00217 17.4177 9.19323 17.2327 9.28321C16.6635 9.56008 16.0282 9.7064 15.3954 9.7064H13.4021V7.14635C13.4021 7.00563 13.2881 6.8916 13.1473 6.8916C13.0066 6.8916 12.8926 7.00563 12.8926 7.14635V9.76296C12.8926 10.0128 13.0958 10.216 13.3456 10.216H15.3953C16.1048 10.216 16.8173 10.0519 17.4556 9.74146C17.8147 9.5667 18.0468 9.19578 18.0468 8.79643V8.48675C18.0468 8.34593 17.9328 8.2319 17.7921 8.2319Z"
        fill="#373737"
      />
      <path
        d="M23.7434 11.097C23.7148 10.584 23.3608 10.1551 22.8626 10.0296L21.6104 9.7144C21.0057 9.56226 20.5211 9.30404 20.495 9.28988C20.4907 9.28753 20.4863 9.28519 20.4819 9.28305C20.2946 9.19073 20.1783 9.00364 20.1783 8.79484V8.48394C20.1783 8.34322 20.0643 8.22919 19.9236 8.22919C19.7829 8.22919 19.6688 8.34322 19.6688 8.48394V8.79484C19.6688 9.1944 19.8888 9.55278 20.244 9.73365C20.3141 9.77757 20.4261 9.82873 20.6105 9.90831C20.8127 9.99564 21.1288 10.1186 21.4861 10.2086L22.7384 10.5238C23.0192 10.5944 23.2188 10.8362 23.2349 11.1259L23.4916 15.5341H15.9995C15.9727 15.5341 15.9509 15.5123 15.9509 15.4855V12.4847C15.9509 11.91 15.4835 11.4402 14.9096 11.437L12.9783 11.4217C12.257 11.418 11.6701 10.828 11.6701 10.1066V7.20397C11.6701 7.06325 11.5561 6.94922 11.4154 6.94922C11.2747 6.94922 11.1606 7.06325 11.1606 7.20397V10.1064C11.1606 11.1072 11.9748 11.9257 12.975 11.931L14.9062 11.9463C15.2013 11.948 15.4414 12.1894 15.4414 12.4845V15.4853C15.4414 15.7931 15.6918 16.0434 15.9995 16.0434H23.4916C23.6307 16.0434 23.7655 15.9856 23.861 15.8844C23.9566 15.7832 24.0071 15.6456 23.9993 15.506L23.7434 11.097Z"
        fill="#373737"
      />
      <path
        d="M20.5361 9.42424C20.4004 9.38898 20.2608 9.4703 20.2254 9.60674L19.5566 12.1776C19.5212 12.3137 19.6028 12.4528 19.7391 12.4883C19.7606 12.4939 19.7821 12.4965 19.8034 12.4965C19.9165 12.4965 20.0199 12.4205 20.0498 12.3058L20.7186 9.73493C20.7539 9.5988 20.6723 9.4597 20.5361 9.42424Z"
        fill="#373737"
      />
      <path
        d="M18.1585 12.1775L17.4897 9.6067C17.4543 9.47046 17.315 9.38904 17.179 9.42419C17.0428 9.45965 16.9612 9.59875 16.9965 9.73489L17.6653 12.3057C17.6952 12.4205 17.7986 12.4965 17.9117 12.4965C17.933 12.4965 17.9545 12.4938 17.976 12.4882C18.1122 12.4528 18.1939 12.3137 18.1585 12.1775Z"
        fill="#373737"
      />
      <path
        d="M3.27858 18.0527C2.17113 18.0527 1.27002 18.9538 1.27002 20.0613C1.27002 21.1688 2.17113 22.0699 3.27858 22.0699C4.38604 22.0699 5.28715 21.1688 5.28715 20.0613C5.28715 18.9538 4.38604 18.0527 3.27858 18.0527ZM3.27858 21.5604C2.45207 21.5604 1.77952 20.8878 1.77952 20.0613C1.77952 19.2348 2.45207 18.5622 3.27858 18.5622C4.1051 18.5622 4.77765 19.2348 4.77765 20.0613C4.77765 20.8878 4.1051 21.5604 3.27858 21.5604Z"
        fill="#373737"
      />
      <path
        d="M4.77242 21.9229H1.78479C0.800736 21.9229 0 22.7234 0 23.7075V25.5224C0 25.8335 0.253121 26.0866 0.564224 26.0866H5.99288C6.30398 26.0866 6.55711 25.8335 6.55711 25.5224V23.7075C6.55711 22.7234 5.75647 21.9229 4.77242 21.9229ZM6.0476 25.5223C6.0476 25.5525 6.02304 25.577 5.99288 25.577H0.564224C0.534061 25.577 0.509503 25.5525 0.509503 25.5223V23.7074C0.509503 23.0043 1.08157 22.4323 1.78479 22.4323H4.77242C5.47553 22.4323 6.0476 23.0042 6.0476 23.7074V25.5223Z"
        fill="#373737"
      />
      <path
        d="M5.06335 23.6377C4.92262 23.6377 4.80859 23.7517 4.80859 23.8924V25.6875C4.80859 25.8283 4.92262 25.9423 5.06335 25.9423C5.20407 25.9423 5.3181 25.8283 5.3181 25.6875V23.8924C5.3181 23.7517 5.20407 23.6377 5.06335 23.6377Z"
        fill="#373737"
      />
      <path
        d="M1.49401 23.6377C1.35328 23.6377 1.23926 23.7517 1.23926 23.8924V25.6875C1.23926 25.8283 1.35328 25.9423 1.49401 25.9423C1.63473 25.9423 1.74876 25.8283 1.74876 25.6875V23.8924C1.74876 23.7517 1.63473 23.6377 1.49401 23.6377Z"
        fill="#373737"
      />
      <path
        d="M9.32595 18.0527C8.21849 18.0527 7.31738 18.9538 7.31738 20.0613C7.31738 21.1688 8.21849 22.0699 9.32595 22.0699C10.4334 22.0699 11.3344 21.1688 11.3344 20.0613C11.3344 18.9538 10.4334 18.0527 9.32595 18.0527ZM9.32595 21.5604C8.49943 21.5604 7.82689 20.8878 7.82689 20.0613C7.82689 19.2348 8.49943 18.5622 9.32595 18.5622C10.1525 18.5622 10.8249 19.2348 10.8249 20.0613C10.8249 20.8878 10.1525 21.5604 9.32595 21.5604Z"
        fill="#373737"
      />
      <path
        d="M10.8197 21.9229H7.83205C6.848 21.9229 6.04736 22.7234 6.04736 23.7075V25.5224C6.04736 25.8335 6.30038 26.0866 6.61149 26.0866H12.0401C12.3512 26.0866 12.6043 25.8335 12.6043 25.5224V23.7075C12.6044 22.7234 11.8037 21.9229 10.8197 21.9229ZM12.0949 25.5223C12.0949 25.5525 12.0704 25.577 12.0402 25.577H6.61149C6.58132 25.577 6.55687 25.5525 6.55687 25.5223V23.7074C6.55687 23.0043 7.12884 22.4323 7.83205 22.4323H10.8197C11.5228 22.4323 12.0949 23.0042 12.0949 23.7074V25.5223Z"
        fill="#373737"
      />
      <path
        d="M11.1107 23.6377C10.97 23.6377 10.856 23.7517 10.856 23.8924V25.6875C10.856 25.8283 10.97 25.9423 11.1107 25.9423C11.2514 25.9423 11.3655 25.8283 11.3655 25.6875V23.8924C11.3655 23.7517 11.2514 23.6377 11.1107 23.6377Z"
        fill="#373737"
      />
      <path
        d="M7.54137 23.6377C7.40065 23.6377 7.28662 23.7517 7.28662 23.8924V25.6875C7.28662 25.8283 7.40065 25.9423 7.54137 25.9423C7.6821 25.9423 7.79612 25.8283 7.79612 25.6875V23.8924C7.79612 23.7517 7.6821 23.6377 7.54137 23.6377Z"
        fill="#373737"
      />
      <path
        d="M12.3498 14.2822C11.2423 14.2822 10.3413 15.1833 10.3413 16.2908C10.3413 17.3982 11.2423 18.2994 12.3498 18.2994C13.4572 18.2994 14.3583 17.3982 14.3583 16.2908C14.3583 15.1833 13.4572 14.2822 12.3498 14.2822ZM12.3498 17.7899C11.5233 17.7899 10.8508 17.1173 10.8508 16.2908C10.8508 15.4643 11.5233 14.7917 12.3498 14.7917C13.1763 14.7917 13.8488 15.4643 13.8488 16.2908C13.8488 17.1173 13.1763 17.7899 12.3498 17.7899Z"
        fill="#373737"
      />
      <path
        d="M6.30251 14.2822C5.19505 14.2822 4.29395 15.1833 4.29395 16.2908C4.29395 17.3982 5.19505 18.2994 6.30251 18.2994C7.40997 18.2994 8.31107 17.3982 8.31107 16.2908C8.31107 15.1833 7.40997 14.2822 6.30251 14.2822ZM6.30251 17.7899C5.47599 17.7899 4.80345 17.1173 4.80345 16.2908C4.80345 15.4643 5.47599 14.7917 6.30251 14.7917C7.12903 14.7917 7.80157 15.4643 7.80157 16.2908C7.80157 17.1173 7.12903 17.7899 6.30251 17.7899Z"
        fill="#373737"
      />
      <path
        d="M15.3738 18.0527C14.2663 18.0527 13.3652 18.9538 13.3652 20.0613C13.3652 21.1688 14.2663 22.0699 15.3738 22.0699C16.4813 22.0699 17.3824 21.1688 17.3824 20.0613C17.3824 18.9538 16.4814 18.0527 15.3738 18.0527ZM15.3738 21.5604C14.5473 21.5604 13.8747 20.8878 13.8747 20.0613C13.8747 19.2348 14.5473 18.5622 15.3738 18.5622C16.2003 18.5622 16.8729 19.2348 16.8729 20.0613C16.8729 20.8878 16.2004 21.5604 15.3738 21.5604Z"
        fill="#373737"
      />
      <path
        d="M16.8675 21.9229H13.8799C12.8958 21.9229 12.0952 22.7234 12.0952 23.7075V25.5224C12.0952 25.8335 12.3483 26.0866 12.6594 26.0866H18.088C18.3991 26.0866 18.6522 25.8335 18.6522 25.5224V23.7075C18.6522 22.7234 17.8516 21.9229 16.8675 21.9229ZM18.1427 25.5223C18.1427 25.5525 18.1182 25.577 18.088 25.577H12.6594C12.6293 25.577 12.6047 25.5525 12.6047 25.5223V23.7074C12.6047 23.0043 13.1767 22.4323 13.8799 22.4323H16.8675C17.5706 22.4323 18.1428 23.0042 18.1428 23.7074V25.5223H18.1427Z"
        fill="#373737"
      />
      <path
        d="M17.1586 23.6377C17.0178 23.6377 16.9038 23.7517 16.9038 23.8924V25.6875C16.9038 25.8283 17.0178 25.9423 17.1586 25.9423C17.2993 25.9423 17.4133 25.8283 17.4133 25.6875V23.8924C17.4133 23.7517 17.2993 23.6377 17.1586 23.6377Z"
        fill="#373737"
      />
      <path
        d="M13.5887 23.6377C13.448 23.6377 13.334 23.7517 13.334 23.8924V25.6875C13.334 25.8283 13.448 25.9423 13.5887 25.9423C13.7295 25.9423 13.8435 25.8283 13.8435 25.6875V23.8924C13.8435 23.7517 13.7295 23.6377 13.5887 23.6377Z"
        fill="#373737"
      />
      <path
        d="M14.529 18.2914C14.3096 18.1991 14.079 18.1523 13.8438 18.1523H10.8562C10.6144 18.1523 10.3778 18.2017 10.1531 18.2991C10.0239 18.355 9.96464 18.505 10.0206 18.6341C10.0623 18.7302 10.156 18.7877 10.2544 18.7877C10.2883 18.7877 10.3226 18.781 10.3556 18.7666C10.516 18.697 10.6846 18.6618 10.8562 18.6618H13.8438C14.0108 18.6618 14.1748 18.6953 14.3313 18.7612C14.4608 18.8156 14.6103 18.7548 14.6649 18.6252C14.7196 18.4954 14.6587 18.3461 14.529 18.2914Z"
        fill="#373737"
      />
      <path
        d="M8.51018 18.304C8.28223 18.2034 8.04184 18.1523 7.79586 18.1523H4.80823C4.56611 18.1523 4.32919 18.2019 4.1041 18.2996C3.97499 18.3555 3.91578 18.5055 3.97183 18.6346C4.02767 18.7636 4.17777 18.8227 4.30688 18.7669C4.46757 18.6971 4.63622 18.6618 4.80823 18.6618H7.79586C7.97051 18.6618 8.14171 18.6982 8.30434 18.7702C8.33786 18.7849 8.37271 18.792 8.40716 18.792C8.50498 18.792 8.59832 18.7352 8.64031 18.64C8.69717 18.5111 8.63888 18.3608 8.51018 18.304Z"
        fill="#373737"
      />
      <path
        d="M15.0211 1.73187L12.9219 0.682294C12.7959 0.619218 12.6431 0.670372 12.5801 0.796219C12.5173 0.922066 12.5683 1.07502 12.6941 1.13799L14.7932 2.19389L12.6959 3.24255C12.5701 3.30552 12.5191 3.45848 12.582 3.58432C12.6266 3.67359 12.7166 3.72525 12.81 3.72525C12.8483 3.72525 12.8872 3.71649 12.9237 3.69825L15.021 2.64959C15.196 2.56216 15.3047 2.38628 15.3047 2.19063C15.3047 1.99498 15.196 1.8193 15.0211 1.73187Z"
        fill="#373737"
      />
      <path
        d="M12.256 0C11.8345 0 11.4917 0.342794 11.4917 0.764255V5.10349C11.4917 5.24422 11.6057 5.35824 11.7465 5.35824C11.8872 5.35824 12.0012 5.24422 12.0012 5.10349V0.764255C12.0012 0.623734 12.1154 0.509503 12.256 0.509503C12.3965 0.509503 12.5107 0.623734 12.5107 0.764255V5.10349C12.5107 5.24422 12.6247 5.35824 12.7655 5.35824C12.9062 5.35824 13.0202 5.24422 13.0202 5.10349V0.764255C13.0202 0.342794 12.6773 0 12.256 0Z"
        fill="#373737"
      />
      <path
        d="M12.7655 7.10389C12.6247 7.10389 12.5107 7.21792 12.5107 7.35864V7.8977C12.5107 8.03822 12.3965 8.15245 12.256 8.15245C12.1154 8.15245 12.0012 8.03822 12.0012 7.8977V7.35436C12.0012 7.21364 11.8872 7.09961 11.7465 7.09961C11.6057 7.09961 11.4917 7.21364 11.4917 7.35436V7.8977C11.4917 8.31916 11.8345 8.66195 12.256 8.66195C12.6774 8.66195 13.0202 8.31916 13.0202 7.8977V7.35864C13.0202 7.21792 12.9062 7.10389 12.7655 7.10389Z"
        fill="#373737"
      />
      <path
        d="M20.6102 9.42196C20.5148 9.31863 20.3536 9.31201 20.2501 9.40739C19.8678 9.76027 19.3733 9.95449 18.8578 9.95449C18.3406 9.95449 17.8451 9.75925 17.4625 9.40474C17.3591 9.30916 17.1981 9.31537 17.1025 9.4185C17.0069 9.52172 17.0131 9.68293 17.1163 9.77851C17.5934 10.2206 18.2119 10.464 18.8579 10.464C19.5018 10.464 20.119 10.2218 20.5958 9.78197C20.699 9.6866 20.7055 9.52539 20.6102 9.42196Z"
        fill="#373737"
      />
      <path
        d="M19.8034 11.9863H17.9119C17.6275 11.9863 17.396 12.2177 17.396 12.5023V14.5436C17.396 14.828 17.6274 15.0594 17.9119 15.0594H19.8034C20.0878 15.0594 20.3193 14.828 20.3193 14.5436V12.5023C20.3193 12.2177 20.0878 11.9863 19.8034 11.9863ZM19.8034 14.5498H17.9119L17.9055 14.5435L17.9119 12.4957L19.8097 12.5021L19.8034 14.5498Z"
        fill="#373737"
      />
      <path
        d="M19.9906 13.043H17.7318C17.5911 13.043 17.4771 13.157 17.4771 13.2977C17.4771 13.4384 17.5911 13.5525 17.7318 13.5525H19.9906C20.1314 13.5525 20.2454 13.4384 20.2454 13.2977C20.2454 13.157 20.1314 13.043 19.9906 13.043Z"
        fill="#373737"
      />
    </svg>
  );
}
