import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import styles from './index.module.css';
import { Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import toastMessageStyles from '../../sites/viaGlobal/components/toastMessage/toastMessage.scss';
import { toast } from 'react-toastify';
import SelectInput from '../../sites/travelerForms/components/base/inputs/SelectInput';
import DatePickerDropDownInput from '../../sites/travelerForms/components/base/inputs/DatePickerDropDownInput';
import { Controller, useForm } from 'react-hook-form';
import { addPlanFormsToPlans, getForms } from '../../actions/formsActions';
import WarningModalContent from '../WarningModalContent';
import BaseModalHeader from '../baseModal/BaseModalHeader';
import BaseModalFooter from '../baseModal/BaseModalFooter';

function AddPlanFormsToPlanModal({ show, handleClose, planIds = [], action="", handleSubmitPlan = null }) {
  const dispatch = useDispatch();
  const data = useSelector(state => state.forms);
  const [formOptions, setFormOptions] = useState([]);
  const [formValues, setFormValues] = useState(null);
  const [showWarningContent, setShowWarningContent] = useState(false);

  const { control, handleSubmit, watch } = useForm({
    values: {
      planForms: [],
      formDeadline: null,
    },
  });

  useEffect(() => {
    dispatch(getForms('Plan Form'));
  }, []);

  useEffect(() => {
    if (data?.forms?.data && Array.isArray(data?.forms?.data)) {
      buildFormOptions();
    }
  }, [data?.forms?.data]);

  const buildFormOptions = () => {
    let options = data?.forms?.data.map(item => {
      return {
        label: item.name,
        value: item.id,
      };
    });
    const sortedData = options.sort((a, b) => a.label.localeCompare(b.label));
    
    setFormOptions([...sortedData]);
  };

  const onSubmit = data => {
    setFormValues(data);
    setShowWarningContent(true);
  };

  const onSubmitForm = () => {
    let payload = {
      template_ids: formValues.planForms.map(i => i.value),
      plan_ids: planIds,
      deadline: formValues.formDeadline
    };
    dispatch(
      addPlanFormsToPlans(payload, (status, message) => {

        if (status) {
          
          toast(message, {
            type: 'success',
            className: toastMessageStyles['successContainer'],
            toastClassName: toastMessageStyles['wrapper'],
            bodyClassName: toastMessageStyles['successBody'],
            progressClassName: toastMessageStyles['progress-bar'],
          });

          if(action == "planOverView") {
            handleSubmitPlan();
          }
          
          handleClose(); 

        } else {

          toast(message, {
            type: 'error',
            className: toastMessageStyles['failureContainer'],
            toastClassName: toastMessageStyles['wrapper'],
            bodyClassName: toastMessageStyles['failureBody'],
            progressClassName: toastMessageStyles['progress-bar'],
          });

        }
      }),
    );
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} centered size={showWarningContent ? 'md' : 'lg'}>
        {showWarningContent ? (
          <WarningModalContent
            text={`You are about to add ${watch("planForms").length} plan form${watch("planForms").length > 1 ? "(s)" : ""} to ${planIds.length} plan${planIds.length > 1 ? "(s)" : ""}. Traveler(s) marked as ‘Going’ to the selected plan(s) will have access to these forms. If a form already exists on the plan, a duplicate will not be added.`}
            textBold={`Are you sure you sure you want to continue?`}
            onCancel={() => setShowWarningContent(false)}
            onSubmit={onSubmitForm}
            loadingBtn={data?.addPlanFormsToPlans?.loading}
          />
        ) : (
          <>
            <BaseModalHeader heading={'Add Plan Form(s) to Plan'} onClose={handleClose} />
            <Modal.Body className={styles.modalBody}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Container className={styles.container}>
                  <div className={styles.text}>
                    Select plan form(s) to add to {planIds.length} plan{planIds.length > 1 && "(s)"}. If a plan already has the selected form(s),
                    a duplicate form will not be added but the deadline will be updated.
                  </div>

                  <div>
                    <Controller
                      control={control}
                      name="planForms"
                      rules={{
                        validate: value => value.length > 0 || 'Plan form is required',
                      }}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <SelectInput
                          label="Plan Forms"
                          placeholder={'Select forms'}
                          required={true}
                          options={formOptions}
                          value={value}
                          handleOnChange={onChange}
                          isMulti={true}
                          error={error?.message}
                        />
                      )}
                    />
                  </div>

                  <div>
                    <Controller
                      control={control}
                      name="formDeadline"
                      render={({ field: { onChange, value } }) => (
                        <DatePickerDropDownInput label={'Set Form Deadline'} onChange={onChange} value={value} />
                      )}
                    />
                  </div>
                </Container>
                <BaseModalFooter mainBtnType="submit" mainBtnText="Add" isBtnGroupRight={true} onCancel={handleClose} />
              </form>
            </Modal.Body>
          </>
        )}
      </Modal>
    </>
  );
}

export default withStyles(styles)(AddPlanFormsToPlanModal);
