import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Select, { components } from 'react-select';
import StyledIcon from '../styles/Icon.styled';
import Tooltip from './Tooltip.styled';
import { Chip } from './Chip.styled';

const Required = styled.span`
  color: ${({ theme }) => theme.defaults.errorColor};
  padding-left: 5px;
`;

const TooltipContainer = styled.span`
  padding-left: 5px;
`;

const SelectGroup = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const SelectLabel = styled.label`
  color: ${({ theme }) => theme.selectOptionsOutside.label.fontColor};
  font-family: ${({ theme }) => theme.selectOptionsOutside.label.fontFamily};
  font-size: ${({ theme }) => theme.selectOptionsOutside.label.fontSize};
  font-weight: 500;
`;

const ShowMore = styled.div`
  color: #373737;
  cursor: pointer;
  font-family: AvenirNextMedium, sans-serif;
  font-size: 12px;
  text-decoration: underline;
`;

const ValuesContainer = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  padding-top: 15px;
`;

const XButton = styled.span`
  all: unset;
  margin-left: 15px;
  color: black;
`;

const ChipTextWrap = styled.div`
  display: flex;
  flex-direction: row;
`;
const ChipTextLeft = styled.div`
  flex: 1;
`;
const ChipTextRight = styled.div`
  flex: 0 10%;
  align-self: center;
`;


const customStyles = {
  container: provided => ({
    ...provided,
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: '#ebebeb',
    },
  }),
  indicatorSeparator: provided => ({
    ...provided,
    display: 'none',
  }),
  indicatorContainer: provided => ({
    ...provided,
    display: 'none',
  }),
  placeholder: (provided, { isDisabled }) => ({
    ...provided,
    color: isDisabled ? '#6d6d6d' : '#373737',
    fontSize: '14px',
    fontStyle: isDisabled ? 'normal' : 'italic',
  }),
  menu: provided => ({
    ...provided,
    zIndex: '12',
    marginTop: '0',
    fontSize: '14px',
  }),
  singleValue: provided => ({
    ...provided,
    color: '#373737',

    disabled: {
      color: '#373737',
      backgroundColor: '#F6F7F9',
      cursor: 'not-allowed',
    },
  }),
  multiValueLabel: provided => ({
    ...provided,
    color: '#373737',
    fontSize: '14px',
  }),
  control: (provided, { isDisabled }) => ({
    ...provided,
    backgroundColor: isDisabled ? '#F6F7F9' : '#fff',
    borderColor: '#ebebeb',
    color: isDisabled ? '#6d6d6d' : '#373737',
    cursor: isDisabled ? 'not-allowed' : 'default',
    minHeight: '44px',
  }),
};

const DisableIcon = () => {
  return <StyledIcon type={'LockClosed'} size={'16px'} />;
};

const DropdownIndicator = props => {
  return <components.DropdownIndicator {...props}>{props.isDisabled && <DisableIcon />}</components.DropdownIndicator>;
};

export default function SelectOptionsOutside({ defaultValue, id, options, value, onChange, showCount,required, ...props }) {
  const [selectedOptions, setSelectedOptions] = useState(value);
  const [allOptions, setAllOptions] = useState(options);
  const [showLimit, setShowLimit] = useState(showCount || 999999);

  useEffect(
    () => {
      let difference = allOptions.filter(x => {
        const foundIndex = selectedOptions && selectedOptions.findIndex(s => s.value === x.value && !s._destroy);
        return foundIndex === null || foundIndex === undefined || foundIndex === -1;
      });

      setAllOptions(difference);
    },
    [selectedOptions],
  );

  const addOption = selectedOption => {
    let selOptions = selectedOptions;
    const foundIndex = selectedOptions.findIndex(x => x.value == selectedOption[0].value);

    if (foundIndex !== -1) {
      selOptions[foundIndex]._destroy = false;
    } else {
      selOptions.push(selectedOption[0]);
    }

    setSelectedOptions(selOptions);
    onChange(selOptions);
  };

  const removeOption = e => {
    let selOptions = selectedOptions;
    const { name: buttonName } = e.currentTarget;
    const foundIndex = selectedOptions.findIndex(x => x.value == buttonName);

    selOptions[foundIndex]._destroy = true;
    if (selOptions[foundIndex].id === '') selOptions.splice(foundIndex, 1);

    setSelectedOptions(selOptions);
    onChange(selOptions);
  };

  return (
    <SelectGroup>
      <SelectLabel htmlFor={id}>
        {props.label}
        {required && <Required>*</Required>}
        {props.tooltip && (
          <TooltipContainer>
            <Tooltip toolTipText={props.tooltip}>
              <StyledIcon type={'InformationCircle'} size={'14px'} />
            </Tooltip>
          </TooltipContainer>
        )}
      </SelectLabel>

      <Select
        aria-label={props.label || 'Please select an option'}
        components={{ DropdownIndicator }}
        controlShouldRenderValue={!props.isMulti}
        id={id}
        isClearable={false}
        styles={customStyles}
        onChange={addOption}
        options={allOptions}
        {...props}
      />
      <ValuesContainer>
        {props.isMulti
          ? selectedOptions &&
            selectedOptions.filter(item => !item._destroy).map(
              (val, index) =>
                !val._destroy && index < showLimit && (
                  <Chip disabled={props.isDisabled} key={val.value} name={val.value} toggleOn onClick={removeOption}>
                    <ChipTextWrap>
                      <ChipTextLeft>{val.label}</ChipTextLeft>
                      <ChipTextRight><XButton>X</XButton></ChipTextRight>
                    </ChipTextWrap>
                  </Chip>
                ),
            )
          : null}
      </ValuesContainer>
      {selectedOptions && showLimit && showLimit < selectedOptions.filter(item => !item._destroy).length && (
        <ShowMore
          onClick={()=>{
            setShowLimit(999999);
          }}
        >
          Show {selectedOptions.filter(item => !item._destroy).length - showLimit} more
        </ShowMore>
      )}
    </SelectGroup>
  );
}
