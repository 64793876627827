exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".X7LKM{display:-ms-flexbox;display:flex;-ms-flex-pack:justify;justify-content:space-between;width:100%;-ms-flex-align:center;align-items:center;padding:5px}.yoTda{font-family:Nunito,sans-serif;font-weight:400;font-size:25px;line-height:30px;margin-bottom:0}.V7_pe{border-bottom:5px solid #ddaf64}", ""]);

// exports
exports.locals = {
	"title": "X7LKM",
	"headingtext": "yoTda",
	"yellowBorderStripe": "V7_pe"
};