import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { formInputFocus } from '../../../../utils/shared/focusStyles';

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const InputLabel = styled.label`
  position: absolute;
  width: 0.0625rem;
  height: 0.0625rem;
  padding: 0;
  margin: -0.0625rem;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
`;

const Input = styled.input`
  box-sizing: border-box;
  display: block;
  width: 100%;
  padding: 0.5625rem 0.875rem;
  background-color: #ffffff;
  border: 1px solid transparent;
  border-bottom-color: #000000;
  font-family: 'Open Sans', sans-serif;
  font-size: 0.875rem;
  color: #373737;
  line-height: 120%;
  letter-spacing: 0.015em;

  &::placeholder {
    font-style: italic;
    color: #666666;
  }

  ${formInputFocus};
`;

export default function FilterSearchInput({ id, label, value, placeholder, handleOnChange }) {
  return (
    <InputContainer>
      <InputLabel htmlFor={id}>{label}</InputLabel>
      <Input id={id} value={value} type="text" name={id} placeholder={placeholder} onChange={handleOnChange} />
    </InputContainer>
  );
}

FilterSearchInput.propTypes = {
  placeholder: PropTypes.string,
  handleOnChange: PropTypes.func,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

FilterSearchInput.defaultProps = {
  placeholder: '',
  handleOnChange: () => {},
};
