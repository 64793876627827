import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import MomentUtils from '@date-io/moment';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import InputError from './InputError';
import LockIcon from '../../icons/LockIcon';
import SyncIcon from '../../icons/SyncIcon';
import CalendarIcon from '../../icons/CalendarIcon';
import InputErrorRequired from './InputErrorRequired';

import datePickerTheme from '../../../utils/ProfileDatePickerTheme';

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  color: #373737;
  line-height: 120%;
  letter-spacing: 0.015em;

  svg {
    margin-top: -2px;
    margin-left: 7px;
  }

  ${props => {
    if (props.hideLabel) {
      return `
        position: absolute;
        width: 0.0625rem;
        height: 0.0625rem;
        padding: 0;
        margin: -0.0625rem;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        white-space: nowrap;
        border-width: 0;
      `;
    }
  }};
`;

const Astrik = styled.span`
  color: #8b1313;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: 0.21px;
`;

export default function ProfileDatePickerInput({
  id,
  error,
  label,
  value,
  views,
  format,
  locked,
  synced,
  maxDate,
  minDate,
  required,
  className,
  hideLabel,
  disablePast,
  placeholder,
  disableFuture,
  handleOnChange,
  setFocusTrapPaused,
  showAstrik,
  minDateError = "",
  maxDateError = "",
  invalidDateError = ""
}) {
  const title = synced ? 'Synced Field' : locked ? 'Locked Field' : null;
  const [internalError, setInternalError] = useState(""); 
  const handleOpened = () => {
    if (!setFocusTrapPaused) return;

    setFocusTrapPaused(true);
  };

  const handleClosed = () => {
    if (!setFocusTrapPaused) return;

    setFocusTrapPaused(false);
  };



  const errorMessage = React.useMemo(() => {
    switch (internalError) {
      case 'Date should not be before minimal date':
        return minDateError || internalError; 
      case 'Date should not be after maximal date':
        return maxDateError || internalError; 
      case 'Invalid Date Format': {
        return invalidDateError || internalError;
      }
      default: {
        return internalError;
      }
    }
  }, [internalError]);


  return (
    <Container className={className}>
      <MuiThemeProvider theme={datePickerTheme()}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Label htmlFor={id} hideLabel={hideLabel}>
            {label} {showAstrik && <Astrik>*</Astrik>}
            {(error || errorMessage) && required && <InputErrorRequired />}
            {synced && <SyncIcon />}
            {locked && <LockIcon />}
          </Label>
          <KeyboardDatePicker
            autoOk
            id={id}
            variant="inline"
            openTo="year"
            views={views}
            format={format}
            placeholder={placeholder}
            value={value}
            title={title}
            minDate={minDate}
            maxDate={maxDate}
            disabled={synced || locked}
            disablePast={disablePast}
            disableFuture={disableFuture}
            className={(error || errorMessage) ? 'has-error' : ''}
            onChange={handleOnChange}
            onOpen={handleOpened}
            onClose={handleClosed}
            keyboardIcon={<CalendarIcon />}
            KeyboardButtonProps={{ 'aria-label': 'Open date picker' }}
            helperText={null}
            onError={(newError) => setInternalError(newError)}
            aria-label={label}
            role="form"
            autoComplete='off'
          />
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
      {(error || errorMessage) && <InputError message={ error || errorMessage } />}
    </Container>
  );
}

ProfileDatePickerInput.propTypes = {
  locked: PropTypes.bool,
  synced: PropTypes.bool,
  error: PropTypes.string,
  format: PropTypes.string,
  required: PropTypes.bool,
  hideLabel: PropTypes.bool,
  maxDate: PropTypes.string,
  minDate: PropTypes.string,
  className: PropTypes.string,
  disablePast: PropTypes.bool,
  disableFuture: PropTypes.bool,
  placeholder: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  setFocusTrapPaused: PropTypes.func,
  handleOnChange: PropTypes.func.isRequired,
  views: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

ProfileDatePickerInput.defaultProps = {
  error: '',
  value: null,
  locked: false,
  synced: false,
  className: null,
  required: false,
  hideLabel: false,
  disablePast: false,
  disableFuture: false,
  format: 'MM/DD/yyyy',
  setFocusTrapPaused: null,
  placeholder: 'MM/DD/YYYY',
  maxDate: new Date().toString(),
  views: ['year', 'month', 'date'],
  minDate: new Date(1900, 0, 1).toString(),
};
