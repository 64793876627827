import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { breakpoints } from '../../../utils/breakpoints';
import TransferredIcon from '../../icons/TransferredIcon';
import GraduationCapIcon from '../../icons/GraduationCapIcon';

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-family: 'Nunito', sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  color: #585858;
  letter-spacing: 0.0015em;
  line-height: 120%;
  margin-top: 0;

  @media (min-width: 320px) {
    margin-top: 20px;
  }
  @media ${breakpoints.mobile} {
    margin-top: 20px;
  }
  @media ${breakpoints.tablet} {
    margin-top: 0;
  }

`;

const Indicator = styled.span`
  width: 10px;
  height: 10px;
  border-radius: 50px;

  ${({ status }) => {
    switch (status) {
      case 'Interested':
        return `
          background-color: #5180B1;
        `;
      case 'Applying':
        return `
          background-color: #DDAF64;
        `;
      case 'Predeparture':
      case 'Pre-departure':
        return `
          background-color: #DAFDD7;
          border: 1px solid #0E5B2F;
        `;
      case 'On-site':
        return `
          background-color: #0E5B2F;
        `;
      case 'Closed':
      case 'Withdrawn':
      case 'Deferred':
      case 'Deferred/Withdrawn/Closed':
        return `
          background-color: #848383;
        `;
      default:
        return ``;
    }
  }};
`;

export default function CardTrackingStep({ trackingStep }) {
  return (
    <Container>
      {trackingStep === 'Alumni' && <GraduationCapIcon />}
      {trackingStep === 'Transferred' && <TransferredIcon />}
      {!['Alumni', 'Transferred'].includes(trackingStep) && <Indicator status={trackingStep} />}
      <span>{trackingStep}</span>
    </Container>
  );
}

CardTrackingStep.propTypes = {
  trackingStep: PropTypes.oneOf([
    'Alumni',
    'Closed',
    'On-site',
    'Applying',
    'Deferred',
    'Withdrawn',
    'Interested',
    'Transferred',
    'Predeparture',
    'Pre-departure',
    'Deferred/Withdrawn/Closed',
  ]).isRequired,
};
