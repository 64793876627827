import React from "react";
function PasswordStrength({
  poorPassword,
  weakPassword,
  strongPassword,
  passwordError,
  passwordErrorLabel
}) {
  
  let  textColor = "#A82425";
  if(weakPassword === true) {
    textColor = "#A82425";
  } else if(strongPassword === true) {
    textColor = "#28a745";
  } 
  return (
    <div>
      <p style={{width:"200px"}}>{passwordErrorLabel} <span style={{color:textColor}}>{passwordError}</span></p>
      <ul className="list-group list-group-horizontal">
        {poorPassword === true ? (
          <li
            className="list-group-item col-9"
            style={{ padding: "4px 0px",background: "#A82425" }}
          ></li>
        ) : (
          ""
        )}
        {weakPassword === true ? (
          <li
            className="list-group-item col-9"
            style={{ padding: "4px 0px",background: "#DDAF64"}}
          ></li>
        ) : (
          ""
        )}
        {strongPassword === true ? (
          <li
            className="list-group-item bg-success col-9"
            style={{ padding: "4px 0px" }}
          ></li>
        ) : (
          ""
        )}
      </ul>
      
    </div>
  );
}
export default PasswordStrength;
