import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import moment from 'moment-timezone';

import Message from './Message/Message';
import Loader from '../../../../../Loader/Loader';

import s from './MessageList.css';
import momentTZ from 'moment-timezone';

class MessageList extends React.Component {
  componentWillReceiveProps(nextProps) {
    if (nextProps.newMessage) {
      this.props.messages.unshift(nextProps.newMessage);
    }
  }

  render() {
    const { messagesLoading } = this.props;
    if (messagesLoading == true) {
      return (
        <div className={s.container}>
          <Loader />
        </div>
      );
    } else if (this.props.messages == null) {
      return <div>error</div>;
    }

    const messages = this.props.messages.map(
      (message, index) =>
        message.sender && (
          <Message
            key={index}
            traveler_id={this.props.id}
            message_id={message.message_id}
            message={message}
            body={message.body}
            subject={message.subject}
            attachments={message.attachments}
            avatar={message.sender_avatar}
            new={message.is_read}
            allowMarkRead={message.allow_mark_read}
            checkMessage={this.props.checkTravelerMessage}
            author={message.author}
            date={moment(new Date(message.created_at))
              .tz(this.props.orgTimezone)
              .format('MMMM D, YYYY')}
            time={moment(new Date(message.created_at))
              .tz(this.props.orgTimezone)
              .format('h:mma')}
            role={this.props.role}
            accentColor={this.props.accentColor}
          />
        ),
    );
    return <div className={s.container}>{messages}</div>;
  }
}

export default withStyles(s)(MessageList);
