import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import sFilterNav from './filterNav.scss';

export const enhance = compose(
  withStyles(sFilterNav),
  withRouter,
);

function FilterNav(props) {
  const { children } = props;

  return (
    <div className={sFilterNav['left-nav']}>
      <div>{children}</div>
    </div>
  );
}

export default enhance(FilterNav);
