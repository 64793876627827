import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import s from './PercentMatch.css';

class PercentMatch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      percent: this.props.percent || 0,
    };
  }

  render() {
    const percent = this.props.percent;
    if (percent < 8) {
      return null;
    } else if (percent < 50) {
      return (
        <div className={s.fullBar}>
          <div className={s.percentBar} style={{ width: `${percent}%` }} />
          <div className={s.percentTextRight}>{`${percent}% MATCH`} </div>
        </div>
      );
    } else if (percent >= 100) {
      return (
        <div className={s.fullBar}>
          <div className={s.percentBar} style={{ width: '100%' }}>
            <div className={s.percentTextLeft}>100% MATCH</div>
          </div>
        </div>
      );
    } else {
      return (
        <div className={s.fullBar}>
          <div className={s.percentBar} style={{ width: `${percent}%` }}>
            <div className={s.percentTextLeft}>{`${percent}% MATCH`} </div>
          </div>
        </div>
      );
    }
  }
}

export default withStyles(s)(PercentMatch);
