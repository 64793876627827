import React, { useCallback, useState } from 'react';
import propTypes from 'prop-types';
import Cropper from 'react-easy-crop'
import Slider from '@material-ui/core/Slider'
import styled from 'styled-components';
import { compose } from 'redux';
import sModal from 'react-responsive-modal/styles.css';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import ViaModal from '../../../sites/components/library/viaModal';
import { AccentButtonMedium, ButtonMedium } from '../Buttons.styled';
import { getCroppedImg } from './canvasUtils'
import { breakpoints } from '../../settings/breakpoints';

const Content = styled.div`
  align-self: center;
  margin: 0;
  padding: 0;
  width: 75%;
  text-align: -webkit-center;
`;

const ControlDiv = styled.div`
  align-self: center;
  padding-top: 20px;
  text-align: -webkit-center;
  width: 290px;
  
  p {
    margin-bottom: 0;
  }
`;

const OuterImageDiv = styled.div`
  border: 1px solid #646464;
  position: relative;
  height: 175px;
  width: 175px;
  
  @media only screen and ${breakpoints.laptop} {
    border: 1px solid #646464;
    height: 300px;
    width: 300px;
  }
`;

const ImageDiv = styled.div`
  align-self: center;
  padding-bottom: 15px;
`;

const Modal = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonWrapper = styled.div`
  border-top: 1px solid #ebebeb;
  display: flex;
  justify-content: end;
  margin-top: 25px;
  padding: 30px 30px 10px 0;
`;

const ButtonItem = styled.div`
  margin: 0 25px;
`;

const minZoom = 0.4;
const maxZoom = 3;
const stepZoom = 0.1;
const minRotation = 0;
const maxRotation = 360;
const stepRotation = 1;

function ImageCropModal(props) {
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [imageSrc, setImageSrc] = useState(props.previewImage);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {

    setCroppedAreaPixels(croppedAreaPixels);
  }, [])

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        imageSrc,
        croppedAreaPixels,
        rotation
      )
      props.onCrop(croppedImage);
      props.onClose(false);
    } catch (e) {
      console.error(e)
    }
  }, [imageSrc, croppedAreaPixels, rotation])

  return (
    <ViaModal
      headerText={`Crop ${props.type}`}
      onClose={() => {
        props.onClose(false);
      }}
      showing={props.show}
      width={'80%'}
    >
      <Modal>
        <Content>
          {props.previewImage && (
            <OuterImageDiv>
              <ImageDiv>
                <Cropper
                  aspect={1}
                  crop={crop}
                  cropShape={props.type.toLowerCase() === 'avatar' ? 'round' : 'logo'}
                  image={props.previewImage}
                  minZoom={minZoom}
                  objectFit="horizontal-cover"
                  restrictPosition={false}
                  rotation={rotation}
                  showGrid
                  zoom={zoom}
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  onRotationChange={setRotation}
                  onZoomChange={setZoom}
                />
              </ImageDiv>
            </OuterImageDiv>
          )}
        </Content>
        <ControlDiv>
          <p>ZOOM</p>
          <Slider
            value={zoom}
            min={minZoom}
            max={maxZoom}
            step={stepZoom}
            aria-labelledby="Zoom"
            onChange={(e, zoom) => setZoom(zoom)}
          />
        </ControlDiv>
        <ControlDiv>
          <p>ROTATION</p>
          <Slider
            value={rotation}
            min={minRotation}
            max={maxRotation}
            step={stepRotation}
            aria-labelledby="Rotation"
            onChange={(e, rotation) => setRotation(rotation)}
          />
        </ControlDiv>
          <ButtonWrapper>
            <ButtonItem>
              <ButtonMedium
                aria-label="Cancel"
                onClick = {() => props.onClose(false)}
              >
                Cancel
              </ButtonMedium>
            </ButtonItem>
            <ButtonItem>
              <AccentButtonMedium
                aria-label={`Save image`}
                onClick={showCroppedImage}
              >
                Save
              </AccentButtonMedium>
            </ButtonItem>
          </ButtonWrapper>
      </Modal>
    </ViaModal>
  );
}

ImageCropModal.propTypes = {
  onClose: propTypes.func.isRequired,
  previewImage: propTypes.string.isRequired,
  onCrop: propTypes.func.isRequired,
  show: propTypes.bool.isRequired,
  type: propTypes.oneOf(['logo', 'avatar']).isRequired,
}

export default compose(withStyles(sModal))(ImageCropModal);