import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'recompose';
import moment from 'moment-timezone';
import propTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import Button from '../../../../components/library/button';
import InputSelect from '../../../../components/library/inputSelect';
import Paragraph from '../../../../components/library/paragraph';
import ViaModal from '../../../../components/library/viaModal';
import { batchInviteTravelersToEvent } from '../../../../../actions/reportsActions';
import { fetchClientEvents } from '../../../../../actions/clientActions';
import sBatchInviteTravelerToEvent from './batchInviteTravelerToEvent.scss';
import ToastMessage from '../../toastMessage';

export const enhance = compose(withStyles(sBatchInviteTravelerToEvent));

function BatchInviteTravelerToEvent(props) {
  const dispatch = useDispatch();
  const customAliases = useSelector(state => state.profile.customAliases);
  const orgTimezone = useSelector(state => state.profile.orgTimezone);
  const eventActions = useSelector(state => state.reports.batchInviteTravelersToEvent);
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);
  const [saving, setSaving] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [failureMessage, setFailureMessage] = useState('');
  const [submitValues, setSubmitValues] = useState();
  const [showConfirm, setShowConfirm] = useState(false);
  const userId = useSelector(state => state.profile.currentUser.id);
  const events = useSelector(state => state.client.events)
    .filter(event => moment().isSameOrBefore(event.start))
    .map(event => {
      return {
        value: event.id,
        label: ` ${event.name}: ${moment(event.start)
          .tz(orgTimezone)
          .format('MMM DD, YYYY hh:mm A')}`,
      };
    });

  const resetForm = () => {
    setDefaultValues(() => {
      let values = {
        event: null,
      };
      return values;
    });
  };

  const [defaultValues, setDefaultValues] = useState(() => {
    let values = {
      event: null,
    };
    return values;
  });

  useEffect(
    () => {
      if (!!eventActions) {
        if (eventActions.loading) {
          setSaving(true);
        }
        if (!eventActions.loading) {
          if (!!eventActions.data) {
            setSaving(false);
            setSuccessMessage(eventActions.data);
            setSuccess(true);
          }
          if (!!eventActions.error) {
            setSaving(false);
            setFailureMessage(eventActions.error);
            setFailure(true);
          }
        }
      }
      resetForm();
      props.onClose(false);
    },
    [eventActions],
  );

  useEffect(() => {
    dispatch(fetchClientEvents(userId));
  }, []);

  return (
    <>
      <ViaModal
        showing={props.show}
        onClose={() => {
          props.onClose(false);
        }}
        width={'700px'}
        headerText={`Event Invite`}
      >
        <div className={sBatchInviteTravelerToEvent['batch-invite-traveler']}>
          <Formik
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={defaultValues}
            validate={(values, index) => {
              const errors = {};
              if (values.event_id === '') {
                errors.event_id = 'Error: Event Required';
              }

              return errors;
            }}
            onSubmit={values => {
              const submitValues = {
                event_id: values.event.value,
                traveler_ids: props.selectedUsers,
              };

              setSubmitValues(submitValues);
              props.onClose(false);
              setShowConfirm(true);
            }}
          >
            {({ values, handleSubmit, errors }) => (
              <Form>
                <div className={sBatchInviteTravelerToEvent.row}>
                  <div className={sBatchInviteTravelerToEvent.item}>
                    <Field name="event">
                      {({ form }) => (
                        <InputSelect
                          id={values.event}
                          name={values.event}
                          labelText="Events"
                          aria-label="Select your event from the dropdown"
                          errorMessage={errors.event}
                          isRequired
                          helpText=""
                          isMulti={false}
                          value={values.event}
                          options={events}
                          placeholder={events.label}
                          onChange={e => form.setFieldValue('event', e)}
                        />
                      )}
                    </Field>
                  </div>
                </div>
                <div className={sBatchInviteTravelerToEvent.footer}>
                  <div className={sBatchInviteTravelerToEvent['button-content']}>
                    <Button
                      display="secondary"
                      kind="solid"
                      size="medium"
                      aria-label="Cancel Button"
                      onClick={() => {
                        props.onClose(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                  <div className={sBatchInviteTravelerToEvent['button-content']}>
                    <Button
                      display="primary"
                      kind="solid"
                      size="medium"
                      type="submit"
                      aria-label={`Invite ${customAliases.alias_traveler} Button`}
                    >
                      Invite
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </ViaModal>
      <ViaModal
        headerText={'Event Invite'}
        showing={showConfirm}
        width={'700px'}
        onClose={() => {
          resetForm();
          setShowConfirm(false);
        }}
      >
        <div className={sBatchInviteTravelerToEvent['batch-invite-traveler']}>
          <div className={sBatchInviteTravelerToEvent.row}>
            <Paragraph size="normal">
              {`You are about to invite ${props.userIds.length} ${props.userIds.length === 1
                ? `${customAliases.alias_traveler.toLowerCase()}`
                : `${customAliases.alias_travelers.toLowerCase()}`
                } to register for an event.`}
            </Paragraph>
          </div>
          <div className={sBatchInviteTravelerToEvent.row}>
            <Paragraph size="normal">Are you sure you want to continue?</Paragraph>
          </div>
          <div className={sBatchInviteTravelerToEvent.footer}>
            <div className={sBatchInviteTravelerToEvent['button-content']}>
              <Button
                display="secondary"
                kind="solid"
                size="medium"
                onClick={() => {
                  setShowConfirm(false);
                }}
              >
                Cancel
              </Button>
            </div>
            <div className={sBatchInviteTravelerToEvent['button-content']}>
              <Button
                display="primary"
                kind="solid"
                size="medium"
                type="submit"
                onClick={() => {
                  dispatch(batchInviteTravelersToEvent(submitValues));
                  setShowConfirm(false);
                  props.setSelectedPrograms();
                }}
              >
                Invite
              </Button>
            </div>
          </div>
        </div>
      </ViaModal>
      {success ? (
        <ToastMessage
          message={successMessage}
          show={success}
          isSuccess={true}
          newestOnTop={false}
          onClose={() => {
            setSuccess(false);
          }}
        />
      ) : null}
      {failure ? (
        <ToastMessage
          message={failureMessage}
          isSuccess={false}
          show={failure}
          newestOnTop={false}
          onClose={() => {
            setFailure(false);
          }}
        />
      ) : null}
    </>
  );
}

BatchInviteTravelerToEvent.propTypes = {
  show: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
};

export default enhance(BatchInviteTravelerToEvent);
