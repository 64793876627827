import styled from 'styled-components';

export default styled.input`
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #373737;
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;

  &::placeholder {
    font-style: italic;
    color: #6c6c6c;
  }

  &:focus {
    outline: none;
    border-color: #5a869b;
  }
`;
