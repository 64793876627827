exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ":root{--dark:var(--theme_color_dark);--accent:var(--theme_color_accent);--ocean:var(--theme_color_light);--button-border:var(--theme_border_color)}._2f4ua{position:relative}._2f4ua [type=checkbox]{position:absolute;opacity:0;top:0;cursor:pointer;width:25px;height:25px;z-index:9}._2f4ua ._1xYjy{display:block;height:25px;width:25px;background-color:#fff;border:2px solid #446372;border:2px solid var(--secondary-darkest,#446372)}._2f4ua ._1xYjy:hover{background-color:#ccc}._2f4ua ._1bz3u:checked~._1xYjy{background:#446372;background:var(--secondary-darkest,#446372)}._2f4ua ._1bz3u:checked~._3wgfE{background-color:var(--theme_color_light);background-color:var(--ocean,#64cfce)}._2f4ua ._1xYjy:after{content:\"\";position:absolute;display:none;width:9px;margin-left:4px;height:18px;border:solid #fff;border-width:0 3px 3px 0;-webkit-transform:rotate(45deg);-ms-transform:rotate(45deg);transform:rotate(45deg)}._2f4ua ._1bz3u:checked~._1xYjy:after,._2f4ua [type=checkbox]:checked~._1xYjy:after{display:block}", ""]);

// exports
exports.locals = {
	"checkboxWrapper": "_2f4ua",
	"checkmark": "_1xYjy",
	"checkboxInput": "_1bz3u",
	"checkmarkAlternative": "_3wgfE"
};