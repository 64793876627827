import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import { connect } from 'react-redux';
import { compose } from 'redux';
import { selectMessageTab } from '../../../../../actions/profileActions';

import s from './MessageOptions.css';

import person from './img/person.png';
import PersonIcon from './personIcon';
import MessageIcon from '../../../../../components/Header/messageBox';

class MessageOptions extends React.Component {
  handleClick(e, tab) {
    this.props.selectMessageTab(tab);
  }

  render() {
    return (
      <div className={s.bar}>
        <li
          className={
            this.props.messageTab === 'messages' ? s.activeFilter : s.filter
          }
          onClick={e => this.handleClick(e, 'messages')}
        >
          <span>
            {this.props.messageCount +
              (this.props.messageCount === 1 ? ' MESSAGE ' : ' MESSAGES ')}
          </span>
          <span className={s.newMessageCount}>
            ({this.props.newMessagesCount} NEW)
          </span>
        </li>
        <li
          className={
            this.props.messageTab === 'recipients' ? s.activeFilter : s.filter
          }
          onClick={e => this.handleClick(e, 'recipients')}
        >
          <React.Fragment>
            <PersonIcon color={this.props.accentColor} />
            <span>
              {this.props.assignedAdmins +
                (this.props.assignedAdmins === 1
                  ? ' RECIPIENT'
                  : ' RECIPIENTS')}
            </span>
          </React.Fragment>
        </li>
        <li className={s.filter} onClick={this.props.markAllRead}>
          <>
            <svg
              width="25px"
              height="16px"
              viewBox="0 0 25 16"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g
                id="Traveler-View-of-Dashboard"
                stroke="none"
                strokeWidth="1"
                fillRule="evenodd"
              >
                <g
                  id="Traveler-View-of-Dashboard:-Messages"
                  transform="translate(-613.000000, -1223.000000)"
                  fill={this.props.accentColor}
                >
                  <g id="Meta" transform="translate(357.000000, 1189.000000)">
                    <g id="Group-2" transform="translate(75.000000, 0.000000)">
                      <g id="Group-4">
                        <g
                          id="Total-Messages"
                          transform="translate(181.000000, 30.000000)"
                        >
                          <path
                            d="M0.8,5.25 L12.1375214,15.1 C12.2880342,15.2308547 12.5119658,15.2308547 12.6624786,15.1 L24,5.25 L24,18.8 C24,19.0285043 23.8285043,19.2 23.6,19.2 L1.2,19.2 C0.971495726,19.2 0.8,19.0285043 0.8,18.8 L0.8,5.25 Z M1.5,4.8 L23.3,4.8 L12.4,14.2624786 L1.5,4.8 Z M1.2,4 C0.542136752,4 0,4.54209402 0,5.2 L0,18.8 C0,19.457906 0.542136752,20 1.2,20 L23.6,20 C24.2578632,20 24.8,19.457906 24.8,18.8 L24.8,5.2 C24.8,4.54209402 24.2578632,4 23.6,4 L1.2,4 Z"
                            id="All-Messages"
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
            <span>Mark all as read</span>
          </>
        </li>
      </div>
    );
  }
}

export default compose(
  withStyles(s),
  connect(
    null,
    { selectMessageTab },
  ),
)(MessageOptions);
