import DoNotKnow from '../../components/icons/DoNotKnow';
import NoPassport from '../../components/icons/NoPassport';
import CurrentPassport from '../../components/icons/CurrentPassport';
import ExpiredPassport from '../../components/icons/ExpiredPassport';

export default [
  {
    id: 1,
    label: 'Current',
    value: 'current',
    icon: CurrentPassport,
  },
  {
    id: 2,
    label: 'Expired',
    value: 'expired',
    icon: ExpiredPassport,
  },
  {
    id: 3,
    label: `I don't have a passport`,
    value: 'no_passport',
    icon: NoPassport,
  },
  {
    id: 4,
    label: `I don't know`,
    value: 'unknown',
    icon: DoNotKnow,
  },
];
