import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Paragraph } from '../../../../../styledComponents/styles/Paragraph.styled';
import RadioButton from '../../../../../styledComponents/styles/RadioButton.styled';

const Container = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.015em;
  color: #373737;
`;

const InputContainer = styled.div`
  flex: 1;
  margin: 13px 0;
  text-align: left;
  font-family: ${({ theme }) => theme.textInput.label.fontFamily};
`;

const Label = styled.label`
  color: ${({ theme }) => theme.textInput.label.fontColor};
  font-family: ${({ theme }) => theme.textInput.label.fontFamily};
  font-size: ${({ theme }) => theme.textInput.label.fontSize};
  font-weight: ${({ theme }) => theme.textInput.label.fontWeight};
`;

const RadioContainer = styled.div`
  flex: 1;
  text-align: left;
`;

const Required = styled.span`
  color: ${({ theme }) => theme.defaults.errorColor};
  padding-left: 5px;
`;

const InfoMsg = styled.div`
  background-color: #deecff;
  border-left: 20px solid #5180b1;
  border-radius: 5px;
  color: #4d4d4d;
  font-weight: 400;
  font-family: 'Open Sans', sans-serif;
  line-height: 17px;
  margin-top: 17px;
  padding: 20px;
  width: 100%;

  span {
    font-weight: 600;
  }
`;

export default function QuestionSingleChoiceDisplayPreview({ question }) {
  const [answer, setAnswer] = useState();
  const [isLogicQuestion, setIsLogicQuestion] = useState(false);
  const [trigger, setTrigger] = useState('');
  const [response, setResponse] = useState('');
  const [questionType, setQuestionType] = useState('');

  useEffect(
    () => {
      if (question?.single_choice_question_logic?.child_question?.id) {
        const logic = question?.single_choice_question_logic;

        setTrigger(logic?.trigger?.toLowerCase() === 'equals' ? logic?.trigger : 'does not equal');

        setResponse(logic?.correct_option);

        switch (logic?.child_question?.question_type_identifier.toLowerCase()) {
          case 'short_text':
            setQuestionType('short text question');
            break;
          case 'long_text':
            setQuestionType('long text question');
            break;
          case 'file_upload':
            setQuestionType('file upload question');
            break;
        }
        setIsLogicQuestion(true);
      }
    },
    [question],
  );
  const updatedInstructions = !!question.instructions ? question.instructions.replace(/<p><\/p>/g, '<br />') : '';  
  return (
    <Container>
      <InputContainer>
        <Label htmlFor={question.id}>
          {question.label}
          {question.required && <Required>*</Required>}
        </Label>
        <Paragraph
          aria-label={question.label}
          id="single-choice-instructions"
          openSans
          dangerouslySetInnerHTML={{
            __html: updatedInstructions,
          }}
        />
        <RadioContainer>
          <RadioButton
            name="single-choice-correct-option-yes"
            value={answer === 0}
            errorMsg={question.correct_option !== answer ? 'You must answer "Yes" to this question' : ''}
            onChange={() => setAnswer(0)}
            disabled
          >
            Yes
          </RadioButton>
        </RadioContainer>
        <RadioContainer>
          <RadioButton
            name="single-choice-correct-option-no"
            value={answer === 1}
            errorMsg={question.correct_option !== answer ? 'You must answer "No" to this question' : ''}
            onChange={() => setAnswer(1)}
            disabled
          >
            No
          </RadioButton>
        </RadioContainer>
        {isLogicQuestion && (
          <InfoMsg>
            If response <span>{`${trigger} "${response}"`}</span>, then a <span>{questionType}</span> will display.
          </InfoMsg>
        )}
      </InputContainer>
    </Container>
  );
}
