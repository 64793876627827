import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import _sortedUniq from 'lodash/sortedUniq';
import moment from 'moment-timezone';
import s from './StickyFooter.css';
import ProgramRequestModal from '../../components/ProgramRequestModal/ProgramRequestModal';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withCookies } from 'react-cookie';
import { contactProgramContact, clearIsContacted } from '../../actions/programBrochureActions';
import { addAlert } from '../../actions/alertActions';
import Ability from '../../hoc/Ability/Ability';

class StickyFooter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
      requestShow: false,
    };

    this.renderButtons = this.renderButtons.bind(this);
    this.requestShowParent = this.requestShowParent.bind(this);
    this.requestHideParent = this.requestHideParent.bind(this);
    this.requestRedirectLogin = this.requestRedirectLogin.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isContacted !== null) {
      nextProps.isContacted
        ? this.props.addAlert(`Message has been sent to ${this.props.customAliases.alias_program} Contact`, 'success')
        : this.props.addAlert('Something went wrong!', 'danger');
      this.props.clearIsContacted(); // isContacted needs to be clear out!
      window.scrollTo(0, 0);
    }
  }

  requestHideParent() {
    this.setState({ requestShow: false });
  }

  requestShowParent() {
    this.setState({ requestShow: true });
  }

  componentDidMount() {
    document.addEventListener('scroll', () => {
      const isVisible = window.scrollY > 582;
      if (isVisible !== this.state.isVisible) {
        this.setState({ isVisible });
      }
    });
  }

  requestRedirectLogin() {
    window.location.href = `/traveler/sign_in?jmp=${window.location.href.replace('visitor', 'traveler')}`;
  }

  isSameOrAfterCurrentDate = date => {
    const nowDate = moment().format('MMMM D, YYYY h:mm a');
    const dateToCheck = moment(new Date(date)).format('MMMM D, YYYY h:mm a');
    return moment(dateToCheck).isSameOrAfter(nowDate);
  };

  isBeforeCurrentDate = date => {
    const nowDate = moment().format('MMMM D, YYYY h:mm a');
    const dateToCheck = moment(new Date(date)).format('MMMM D, YYYY h:mm a');
    return moment(dateToCheck).isSameOrBefore(nowDate);
  };

  applyButton = () => (
    <button className={s.saveButton} onClick={this.props.openEligibility}>
      Apply Now
    </button>
  );

  renderButtons = () => {
    let futureTerms = [];
    if (this.termRequestToApply()) {
      return this.requestToApplyButton();
    } else {
      if (this.props.authorized) {
        futureTerms = this.handleAuthorizedProgramLogic();
      } else {
        futureTerms = this.handleInternalProgramLogic();
      }

      if (futureTerms.length > 0) {
        return this.applyButton();
      }

      return this.requestToApplyButton();
    }
  };

  handleAuthorizedProgramLogic() {
    const futureTerms = this.filteredRanges().filter(current => {
      if (
        current.application !== null &&
        this.isSameOrAfterCurrentDate(current.range.start_date) &&
        this.isSameOrAfterCurrentDate(current.range.deadline)
      ) {
        return true;
      } else if (
        current.application !== null &&
        current.range.deadline == null &&
        this.isSameOrAfterCurrentDate(current.range.start_date) &&
        this.isSameOrAfterCurrentDate(current.range.end_date)
      ) {
        return true;
      }
    });
    return futureTerms;
  }

  handleInternalProgramLogic() {
    const futureTerms = this.filteredRanges().filter(current => {
      if (
        current.application &&
        this.isSameOrAfterCurrentDate(current.range.start_date) &&
        this.isSameOrAfterCurrentDate(current.range.deadline)
      ) {
        return true;
      } else if (
        current.application &&
        current.range.deadline == null &&
        this.isSameOrAfterCurrentDate(current.range.start_date) &&
        this.isSameOrAfterCurrentDate(current.range.end_date)
      ) {
        return true;
      }
    });
    return futureTerms;
  }

  requestToApplyButton = () => (
    <button className={s.saveButton} onClick={this.requestShowParent}>
      Request To Apply
    </button>
  );

  filteredRanges = () => {
    return this.props.ranges.filter(current => {
      let range = current.range;
      if (
        (this.isBeforeCurrentDate(range.start_date) && this.isBeforeCurrentDate(range.end_date)) ||
        range.status === 'cancelled'
      ) {
        return false;
      } else {
        return true;
      }
    });
  };

  termRequestToApply = () => {
    let totalTermCount = this.filteredRanges().length;
    let totalTermsBeforeDeadline;

    if (this.props.authorized) {
      let application = this.filteredRanges().map(range => (range.application === null ? true : false));

      totalTermsBeforeDeadline = this.filteredRanges().map(range => {
        return range.range.deadline == null || this.isSameOrAfterCurrentDate(range.range.deadline);
      }).length;

      return (
        _sortedUniq(application).includes(false) === false || totalTermCount == 0 || totalTermsBeforeDeadline == 0
      );
    } else {
      let totalTermsWithApplications = this.filteredRanges().filter(range => range.application != null).length;

      totalTermsBeforeDeadline = this.filteredRanges().filter(
        range => range.range.deadline == null || this.isSameOrAfterCurrentDate(range.range.deadline),
      ).length;

      return totalTermCount == 0 || totalTermsBeforeDeadline == 0 || totalTermsWithApplications == 0;
    }
  };

  render() {
    if (this.props.role == 'Admin') {
      // Admin Section
      if (this.props.status == 'view') {
        // View Section
        return (
          <div className={s.footer} style={this.state.isVisible ? { display: 'flex' } : { display: 'none' }}>
            {this.props.programStatus !== 'inactive' ? (
              <div className={s.editCopy}>
                <p className={s.editQuestion}>
                  Would you like to edit this {this.props.alias_program.toLowerCase()} page?
                </p>
                <p className={s.editStatement}>
                  Make edits to photos, text or anything else on this page. Remember to save when you’re done!
                </p>
              </div>
            ) : (
              <div className={s.editCopy}>
                <p className={s.editQuestion}>This {this.props.alias_program.toLowerCase()} is archived.</p>
                <p className={s.editStatement}>Edits can no longer be made.</p>
              </div>
            )}
            {this.props.programStatus !== 'inactive' && (
              <Ability module="program_settings" action="create_slash_edit_slash_duplicate">
                <a href={`/client/programs/${this.props.id}/edit`} className={s.saveButton}>
                  Edit
                </a>
                <div disable="true">
                  <a href="#" className={s.saveButton + ' ' + s.disabled}>
                    Edit
                  </a>
                </div>
              </Ability>
            )}
            <div className={s.line} />
            <Ability module="program_settings" action="create_slash_edit_slash_duplicate">
              <a
                className={`${s.settingsLink} ${s.saveButton}`}
                href={`/client/programs/${this.props.id}/program_settings`}
              >
                {this.props.alias_program} Settings
              </a>
            </Ability>
          </div>
        );
      } else if (this.props.status == 'new' || this.props.status == 'edit') {
        return (
          <div className={s.footer} style={this.state.isVisible ? { display: 'flex' } : { display: 'none' }}>
            <div className={s.editCopy}>
              <p className={s.smallEditQuestion}>
                You are currently editing this {this.props.customAliases.alias_program.toLowerCase()}
              </p>
              <p className={s.editStatement} />
            </div>
            <button
              className={s.saveButton}
              onClick={() => this.props.saveProgram('draft')}
              disabled={this.props.disableAfterClick}
            >
              Save
            </button>
            <button
              className={s.saveButton}
              onClick={() => this.props.saveProgram('setting')}
              disabled={this.props.disableAfterClick}
            >
              Save & Settings
            </button>
          </div>
        );
      }
    } else if (this.props.role == 'Visitor') {
      return (
        <div className={s.footerTraveler} style={this.state.isVisible ? { display: 'flex' } : { display: 'none' }}>
          <button className={s.saveButton} onClick={this.requestRedirectLogin}>
            Request to Apply
          </button>
        </div>
      );
    } else {
      // Traveler Section
      return (
        <div className={s.footerTraveler} style={this.state.isVisible ? { display: 'flex' } : { display: 'none' }}>
          <div className={s.editCopy} />
          {this.renderButtons()}
          <div className={s.editCopy}>
            <p className={s.Question}>
              {this.filteredRanges().length == 0 ? (
                <span>
                  At this time, there are no future terms listed. To request to apply about future terms, click 'Request
                  to Apply'
                </span>
              ) : (
                <span onClick={this.props.showModal} style={{ cursor: 'pointer' }}>
                  Have a question?
                </span>
              )}
            </p>
          </div>
          {this.state.requestShow && (
            <ProgramRequestModal requestHideParent={this.requestHideParent} programId={this.props.id} />
          )}
        </div>
      );
    }
  }
}

const mapStateToProps = state => ({
  isContacted: state.program.isContacted,
  customAliases: state.profile.customAliases,
});

export default compose(
  withCookies,
  withStyles(s),
  connect(
    mapStateToProps,
    { contactProgramContact, addAlert, clearIsContacted },
  ),
)(StickyFooter);
