import React from 'react'

export default function Marker({ isPrimary = false}) {
  return (
    <>
      <span
        style={{
          position: 'absolute',
          transform: 'translate(-50%, -50%)'}}
      >
        {isPrimary
        ?
          <span>
            <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M17.5 15.3125C18.7081 15.3125 19.6875 14.3331 19.6875 13.125C19.6875 11.9169 18.7081 10.9375 17.5 10.9375C16.2919 10.9375 15.3125 11.9169 15.3125 13.125C15.3125 14.3331 16.2919 15.3125 17.5 15.3125Z"
                fill="var(--theme_color_accent)"
              />
              <path
                d="M17.5 2.1875C11.4693 2.1875 6.5625 6.88037 6.5625 12.6465C6.5625 15.3925 7.81416 19.0442 10.2826 23.5006C12.265 27.0785 14.5585 30.314 15.7514 31.9238C15.9529 32.1989 16.2165 32.4226 16.5206 32.5768C16.8248 32.731 17.161 32.8114 17.5021 32.8114C17.8431 32.8114 18.1793 32.731 18.4835 32.5768C18.7876 32.4226 19.0512 32.1989 19.2527 31.9238C20.4436 30.314 22.7391 27.0785 24.7215 23.5006C27.1858 19.0456 28.4375 15.3938 28.4375 12.6465C28.4375 6.88037 23.5307 2.1875 17.5 2.1875ZM17.5 17.5C16.6347 17.5 15.7888 17.2434 15.0694 16.7627C14.3499 16.2819 13.7892 15.5987 13.458 14.7992C13.1269 13.9998 13.0403 13.1201 13.2091 12.2715C13.3779 11.4228 13.7946 10.6433 14.4064 10.0314C15.0183 9.41955 15.7978 9.00287 16.6465 8.83406C17.4951 8.66525 18.3748 8.75189 19.1742 9.08303C19.9737 9.41416 20.6569 9.97492 21.1377 10.6944C21.6184 11.4138 21.875 12.2597 21.875 13.125C21.8737 14.2849 21.4124 15.397 20.5922 16.2172C19.772 17.0374 18.6599 17.4987 17.5 17.5Z"
                fill="var(--theme_color_accent)"
              />
            </svg>
          </span>
        :
          <span>
            <svg
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.9072 10.5C13.7357 10.5 14.4072 9.82843 14.4072 9C14.4072 8.17157 13.7357 7.5 12.9072 7.5C12.0788 7.5 11.4072 8.17157 11.4072 9C11.4072 9.82843 12.0788 10.5 12.9072 10.5Z"
                fill="var(--theme_color_accent)"
              />
              <path
                d="M12.9072 1.5C8.77191 1.5 5.40723 4.71797 5.40723 8.67188C5.40723 10.5548 6.26551 13.0589 7.95816 16.1147C9.31754 18.5681 10.8902 20.7867 11.7082 21.8906C11.8464 22.0792 12.0271 22.2326 12.2357 22.3384C12.4442 22.4441 12.6748 22.4992 12.9086 22.4992C13.1425 22.4992 13.373 22.4441 13.5816 22.3384C13.7902 22.2326 13.9709 22.0792 14.1091 21.8906C14.9257 20.7867 16.4997 18.5681 17.8591 16.1147C19.5489 13.0598 20.4072 10.5558 20.4072 8.67188C20.4072 4.71797 17.0425 1.5 12.9072 1.5ZM12.9072 12C12.3139 12 11.7339 11.8241 11.2405 11.4944C10.7472 11.1648 10.3627 10.6962 10.1356 10.1481C9.90852 9.59987 9.84911 8.99667 9.96487 8.41473C10.0806 7.83279 10.3663 7.29824 10.7859 6.87868C11.2055 6.45912 11.74 6.1734 12.322 6.05764C12.9039 5.94189 13.5071 6.0013 14.0553 6.22836C14.6035 6.45542 15.072 6.83994 15.4016 7.33329C15.7313 7.82664 15.9072 8.40666 15.9072 9C15.9064 9.79538 15.59 10.5579 15.0276 11.1204C14.4652 11.6828 13.7026 11.9991 12.9072 12Z"
                fill="var(--theme_color_accent)"
              />
            </svg>
          </span>
      }
      </span>
    </>
  )
}
