exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".FzaoX{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center;height:400px;gap:10px}._1aJ0o{font-family:Nunito;font-size:25px;font-weight:400;line-height:30px;text-align:center}", ""]);

// exports
exports.locals = {
	"loadingContainer": "FzaoX",
	"loadingMessage": "_1aJ0o"
};