import PropTypes from 'prop-types';
import styled from 'styled-components';
import FocusTrap from 'focus-trap-react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';

import GearIcon from '../icons/GearIcon';
import ArrowSwitchIcon from '../icons/ArrowSwitchIcon';

import { breakpoints } from '../../utils/breakpoints';
import { buttonFocus } from '../../utils/shared/focusStyles';
import getTravelerDisplayName from '../../utils/getTravelerDisplayName';
import ChangeRoleModal from '../modals/ChangeRoleModal';

const Container = styled.nav`
  position: absolute;
  top: 138%;
  right: -50%;
  z-index: 120;
  min-width: 300px;
  padding: 2.0625rem 1rem 0.8125rem 1rem;
  background-color: #eeeeee;
  border-radius: 0px 0px 25px 25px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  @media ${breakpoints.tablet} {
    top: 100%;
    right: 0;
    border-radius: 25px 0px 25px 25px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 75px;
    height: 75px;
    margin-bottom: 0.625rem;
    border-radius: 50%;
  }
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1.375rem;
`;

const UserName = styled.span`
  font-family: 'Nunito', sans-serif;
  font-weight: 700;
  font-size: 1rem;
  color: #373737;
  letter-spacing: 0.04em;
  line-height: 130%;
`;

const UserRole = styled.span`
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  color: #373737;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  line-height: 130%;
`;

const ChangeRolesButton = styled.button`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-top: 0.375rem;
  background-color: transparent;
  border: none;
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  color: #446372;
  letter-spacing: 0.0015em;
  text-decoration: underline;
  line-height: 120%;

  &:hover {
    color: #255a94;
    text-decoration: none;
  }

  ${buttonFocus};
`;

const AccountSettingsLink = styled(NavLink)`
  display: flex;
  align-items: center;
  gap: 0.144375rem;
  width: 100%;
  margin-bottom: 1.625rem;
  padding: 0.65625rem 0 0.65625rem 1rem;
  background-color: #ffffff;
  border-radius: 3px;
  font-family: 'Nunito', sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  color: #373737;
  letter-spacing: 0.01em;
  line-height: 19px;

  ${buttonFocus};
`;

const SignOutButton = styled.button`
  background-color: transparent;
  border: none;
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  color: #446372;
  letter-spacing: 0.0015em;
  text-decoration: underline;
  line-height: 120%;

  &:hover {
    color: #255a94;
    text-decoration: none;
  }

  ${buttonFocus};
`;

export default function AccountDropdown({ isOpen, buttonRef, onClickOutside }) {
  const { customAliases } = useSelector(state => state.currentOrganization);
  const { adminAccess, info, profile, role } = useSelector(state => state.travelerProfile);

  const ref = useRef(null);

  const [userRole, setUserRole] = useState('');
  const [showModal, setShowModal] = useState(false);

  const travelerDisplayName = getTravelerDisplayName(info, profile);

  useEffect(() => {
    if (customAliases) {
      const formattedRole = role === 'traveler' ? customAliases.alias_traveler : role.replace('_', ' ');

      setUserRole(formattedRole);
    }
  }, [customAliases, role]);

  useEffect(() => {
    const handleClickOutside = event => {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        onClickOutside && onClickOutside();
      }
    };

    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [onClickOutside]);

  const handleSignOut = () => {
    localStorage.clear();

    window.location.assign('/clear_cookies');
  };

  return (
    <>
      <FocusTrap active={isOpen} focusTrapOptions={{ clickOutsideDeactivates: true }}>
        <Container ref={ref}>
          <Content>
            <img src={`${profile.avatar.url}?cb=${new Date().getTime().toString()}`} alt="" />
            <InfoContainer>
              <UserName>{travelerDisplayName}</UserName>
              <UserRole>{userRole}</UserRole>
              {adminAccess && (
                <ChangeRolesButton
                  type="button"
                  onClick={() => {
                    setShowModal(true);
                  }}
                >
                  <ArrowSwitchIcon />
                  <span>Change Roles</span>
                </ChangeRolesButton>
              )}
            </InfoContainer>
            <AccountSettingsLink to="/traveler/traveler-settings">
              <GearIcon />
              <span>Account Settings</span>
            </AccountSettingsLink>
            <SignOutButton type="button" onClick={handleSignOut}>
              Sign Out
            </SignOutButton>
          </Content>
          <ChangeRoleModal show={showModal} setShow={setShowModal} />
        </Container>
      </FocusTrap>
    </>
  );
}

AccountDropdown.propTypes = {
  isOpen: PropTypes.bool,
  onClickOutside: PropTypes.func,
};

AccountDropdown.defaultProps = {
  isOpen: false,
  onClickOutside: () => {},
};
