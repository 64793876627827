import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import { useSelector } from 'react-redux';
import propTypes from 'prop-types';
import _get from 'lodash/get';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { withStyles as uiStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getContrastColor } from '../../../../shared/helpers/General';
import sCopyOpenUrlText from './copyOpenUrlText.scss';

export const enhance = compose(withStyles(sCopyOpenUrlText));

function CopyOpenUrlText(props) {
  const [accentTextColor, setAccentTextColor] = useState('#ffffff');
  const [open, setOpen] = useState(false);
  const organization = useSelector(state => state.orgSettings.organization);
  let copyInput = null;

  useEffect(
    () => {
      if (!!organization && organization.id) {
        const darkColor = _get(organization, 'theme_color_dark') || '#043544';
        const setTextColor = getContrastColor(darkColor);
        setAccentTextColor(setTextColor);
      }
    },
    [organization],
  );

  const OpenTooltip = uiStyles(theme => ({
    tooltip: {
      backgroundColor: '#ffffff',
      color: '#00485d',
      boxShadow: theme.shadows[1],
      fontSize: 12,
      fontFamily: 'AvenirNextRegular, sans-serif',
      marginTop: '1px',
    },
  }))(Tooltip);

  const CopyTooltip = uiStyles(theme => ({
    tooltip: {
      backgroundColor: '#ffffff',
      color: '#00485d',
      boxShadow: theme.shadows[1],
      fontSize: 12,
      fontFamily: 'AvenirNextRegular, sans-serif',
      marginBottom: '1px',
    },
  }))(Tooltip);

  const openLink = () => {
    window.open(props.linkUrl, '_blank');
  };

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    copyInput.select();
    document.execCommand('copy');
    setOpen(true);
  };

  return (
    <div className={sCopyOpenUrlText.wrapper}>
      <div className={sCopyOpenUrlText.linkInput}>
        <div>
          <CopyTooltip
            onClose={handleTooltipClose}
            open={open}
            title={
              <div>
                <FontAwesomeIcon icon="check" color="#219653" />
                &nbsp;&nbsp; Copied to Clipboard
              </div>
            }
            placement="top-end"
          >
            <input
              id="CopyToClipboard"
              name="CopyToClipboard"
              aria-label="Copy to clipboard"
              value={props.linkUrl}
              type="text"
              readOnly
              onClick={() => handleTooltipOpen()}
              ref={ref => (copyInput = ref)}
            />
          </CopyTooltip>
        </div>
      </div>
      <div>
        <OpenTooltip title={'Click to Open in New Window'} placement="bottom-start">
          <button
            id="OpenInNewWindow"
            name="OpenInNewWindow"
            className={sCopyOpenUrlText.linkButton}
            style={{ color: accentTextColor }}
            onClick={() => openLink()}
            aria-label="Click to Open in New Window"
          >
            Open
          </button>
        </OpenTooltip>
      </div>
    </div>
  );
}

CopyOpenUrlText.propTypes = {
  linkUrl: propTypes.string.isRequired,
};

CopyOpenUrlText.defaultProps = {};

export default enhance(CopyOpenUrlText);
