import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import ProfileFormContainer from '../base/ProfileFormContainer';
import ProfileFormSubheading from '../base/ProfileFormSubheading';
import ToastMessage from '../../../viaGlobal/components/toastMessage';
import PassportAndVisaForm from '../forms/passport/PassportAndVisaForm';
import { ProfileFormButton, ProfileFormButtonContainer } from '../base/ProfileFormButton.styled';

import userId from '../../../../shared/helpers/userId';
import { updateTravelerProfile } from '../../actions/travelerProfileActions';

export default function PassportAndVisaTab() {
  const dispatch = useDispatch();

  const [showToast, setShowToast] = useState(false);

  const [passportStatusForm, setPassportStatusForm] = useState({
    status: '',
  });

  const [infoForm, setInfoForm] = useState({
    place_of_birth: '',
    passport_number: '',
    visa_description: '',
    passport_issuing_agency: '',
    countries_of_citizenship: '',
    passport_expiration_date: null,
  });

  const handleSubmit = event => {
    event.preventDefault();

    const passportPayload = {
      status: passportStatusForm.status.value,
    };

    const infoPayload = {
      ...infoForm,
      countries_of_citizenship: infoForm.countries_of_citizenship.map(country => country.value),
    };

    dispatch(
      updateTravelerProfile(userId, {
        passport: passportPayload,
        travelerProfile: infoPayload,
      }),
    );

    setShowToast(true);
  };

  return (
    <form method="POST" onSubmit={handleSubmit}>
      <ProfileFormContainer heading="Passport &amp; Visa Information">
        <div>
          <ProfileFormSubheading text="Passport &amp; Visa" />
          <PassportAndVisaForm
            passportStatusForm={passportStatusForm}
            setPassportStatusForm={setPassportStatusForm}
            infoForm={infoForm}
            setInfoForm={setInfoForm}
          />
        </div>
        <ProfileFormButtonContainer>
          <ProfileFormButton type="submit">Save</ProfileFormButton>
        </ProfileFormButtonContainer>
        {showToast && (
          <ToastMessage
            message="All changes saved"
            isSuccess
            show={showToast}
            onClose={() => {
              setShowToast(false);
            }}
          />
        )}
      </ProfileFormContainer>
    </form>
  );
}
