import React from 'react';
import styled from 'styled-components';
import { ErrorIcon } from '../../../../../styledComponents/styles/IconCustom.styled';
import { PageHeader } from '../../../../../styledComponents/styles/PageHeader.styled';
import { Paragraph } from '../../../../../styledComponents/styles/Paragraph.styled';

const ErrorContainer = styled.div`
  margin-top: 60px;
  margin-left: 60px;
`;

const ContentContainer = styled.div`
  text-align: center;
`;

const ParagraphMedium = styled(Paragraph)`
  font-family: AvenirNextMedium, sans-serif;
  margin: 10px 0;
`;

const ImageContainer = styled.div`
  margin-top: 40px;
  margin-bottom: 60px;
`;

const Container = styled.div`
  margin-bottom: 30px;
`;

export default function ErrorPage(props) {

  return (
    <>
      <ErrorContainer>
        <PageHeader> {props.header}</PageHeader>
        <ContentContainer>
          <ImageContainer>
            <ErrorIcon size={180} />
          </ImageContainer>

          <Container>
            <ParagraphMedium>There was an unexpected error loading this page.</ParagraphMedium>
            <ParagraphMedium>Try again later, or contact Via TRM support.</ParagraphMedium>
          </Container>
          <ContentContainer>
            <ParagraphMedium>Error Message: {props.errorMsg}</ParagraphMedium>
          </ContentContainer>
        </ContentContainer>
      </ErrorContainer>
    </>
  );
}
