import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import Rating from '@material-ui/lab/Rating';
import Box from '@material-ui/core/Box';
import { stripHtmlString } from '../../shared/helpers/General';
import { makeStyles } from '@material-ui/core/styles';
import StyledIcon from './Icon.styled';

const openSansFont = css`
  font-family: ${({ theme }) => theme.textInput.fontFamilyOpenSans};
`;

const InputLabel = styled.label`
  color: ${({ theme }) => theme.textInput.label.fontColor};
  font-family: ${({ theme }) => theme.textInput.label.fontFamily};
  font-size: ${({ theme }) => theme.textInput.label.fontSize};
  font-weight: ${({ theme }) => theme.textInput.label.fontWeight};

  ${({ openSans }) => (openSans ? openSansFont : null)};
`;

const Instructions = styled.div`
  color: ${({ theme }) => theme.textInput.instructions.fontColor};
  font-family: ${({ theme }) => theme.textInput.instructions.fontFamily};
  font-size: ${({ theme }) => theme.textInput.instructions.fontSize};
  font-weight: ${({ theme }) => theme.textInput.instructions.fontWeight};
  padding-left: 5px;
  p {
    margin-bottom:0px;
  }
  ${({ openSans }) => (openSans ? openSansFont : null)};
`;

const Error = styled.div`
  color: ${({ theme }) => theme.textInput.error.fontColor};
  display: inline-block;
  font-family: ${({ theme }) => theme.textInput.error.fontFamily};
  font-size: ${({ theme }) => theme.textInput.error.fontSize};
  font-weight: ${({ theme }) => theme.textInput.error.fontWeight};
  padding-top: 3px;
  vertical-align: middle;
  ${({ openSans }) => (openSans ? openSansFont : null)} svg {
    justify-content: center;
    margin-right: 5px;

    vertical-align: middle;
    display: inline-block;
  }

  span {
    vertical-align: middle;
    display: inline-block;
  }
`;

const Required = styled.span`
  color: ${({ theme }) => theme.defaults.errorColor};
  padding-left: 5px;
`;

const useStyles = makeStyles({
  root: {
    width: 'auto',
    display: 'flex',
    textAlign: 'center',
  },
});

export default function StarRating({
  disabled,
  errorMsg,
  icon,
  id,
  label,
  placeholder,
  required,
  tooltip,
  options,
  instructions,
  maxStars,
  defaultValue,
  ...props
}) {
  const [value, setValue] = useState(0);
  const [hover, setHover] = useState(-1);
  const classes = useStyles();
  const labels = options;
  let cleanseInstructions = null;
  if (instructions && stripHtmlString(instructions).trim().length > 0) {
    const updatedIntro = !!instructions ? instructions.replace(/<p><\/p>/g, '<br />') : '';
    cleanseInstructions = updatedIntro;
  }

  useEffect(
    () => {
      if (defaultValue) {
        setValue(defaultValue);
      }
    },
    [defaultValue],
  );

  useEffect(
    () => {
      if (props.onChange) {
        props.onChange(value);
      }
    },
    [value],
  );

  return (
    <div>
      {label &&
        label.length > 0 && (
          <InputLabel htmlFor={id} openSans={openSansFont}>
            {label}
            {required && <Required aria-label='Required'>*</Required>}
            {tooltip && (
              <TooltipContainer>
                <Tooltip toolTipText={tooltip}>
                  <StyledIcon type={'InformationCircle'} size={'14px'} />
                </Tooltip>
              </TooltipContainer>
            )}
          </InputLabel>
        )}
      {cleanseInstructions &&
        cleanseInstructions.length > 0 && (
          <Instructions
            openSans={openSansFont}
            dangerouslySetInnerHTML={{
              __html: cleanseInstructions,
            }}
          />
        )}

      <div className={classes.root}>
        <Rating
          name={`${id.toString()}-hover-feedback`}
          value={value}
          id={id}
          precision={1}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          onChangeActive={(event, newHover) => {
            setHover(newHover);
          }}
          // defaultValue={2}
          max={maxStars || 5}
          disabled={disabled}
        />
        {value !== null && (
          <Box
            ml={2}
            sx={{
              width: 'auto',
              height: 'auto',
              marginLeft: '30px',
            }}
          >
            {labels[hover !== -1 ? hover : value]}
          </Box>
        )}
      </div>

      {errorMsg &&
        errorMsg.length > 0 && (
          <Error openSans={openSansFont}>
            <StyledIcon type={'Warning'} size={'16px'} />
            <span>{errorMsg}</span>
          </Error>
        )}
    </div>
  );
}
