import React from 'react';
import { PropTypes } from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './RadioButton.css';
const RadioButton = ({ name, value, handleChange, checked, customClass }) => (
  <input
    type="radio"
    name={name}
    value={value}
    onChange={handleChange}
    checked={checked}
    className={`${customClass}`}
  />
);

RadioButton.propTypes = {
  name: PropTypes.string,
  handleChange: PropTypes.func,
  checked: PropTypes.bool,
};

RadioButton.defaultProps = {
  checked: false,
};

export default withStyles(s)(RadioButton);
