import React from 'react';
import PropTypes from 'prop-types';

import { TextBlock, TextBlockRichContent, TextBlockTag } from './ContentBlock.styled';

export default function ContentBlock({ title, tags, children }) {
  return (
    <TextBlock>
      {title && <h3 dangerouslySetInnerHTML={{ __html: title }} />}
      {tags.length > 0 &&
        tags.map((tag, index) => (
          <TextBlockTag key={`tag-${index}`} className="text-box__tag">
            {tag}
          </TextBlockTag>
        ))}
      <TextBlockRichContent>{children}</TextBlockRichContent>
    </TextBlock>
  );
}

ContentBlock.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
};

ContentBlock.defaultProps = {
  tags: [],
  title: '',
  children: [],
};
