import fetch from 'node-fetch';
import { dateInUTC, getDateFromOffsetDays } from '../shared/helpers/General';
import {
  GET_AVAILABLE_REPORTS,
  GET_REPORT,
  FETCH_TRAVELERS_REPORT_REQUEST,
  FETCH_TRAVELERS_REPORT_SUCCESS,
  FETCH_TRAVELERS_REPORT_FAILURE,
  FETCH_TRAVELERS_REPORT_CLEAR,
  FETCH_PROGRAMS_REPORT_REQUEST,
  FETCH_PROGRAMS_REPORT_SUCCESS,
  FETCH_PROGRAMS_REPORT_FAILURE,
  FETCH_PROGRAMS_REPORT_CLEAR,
  FETCH_APPLICATIONS_REPORT_REQUEST,
  FETCH_APPLICATIONS_REPORT_SUCCESS,
  FETCH_APPLICATIONS_REPORT_FAILURE,
  FETCH_APPLICATIONS_REPORT_CLEAR,
  FETCH_FORMS_REPORT_REQUEST,
  FETCH_FORMS_REPORT_SUCCESS,
  FETCH_FORMS_REPORT_FAILURE,
  FETCH_FORMS_REPORT_CLEAR,
  FETCH_REPORT_FILTERS_REQUEST,
  FETCH_REPORT_FILTERS_SUCCESS,
  FETCH_REPORT_FILTERS_CLEAR,
  FETCH_REPORT_FILTERS_FAILURE,
  FETCH_INTERNAL_PROGRAMS_REQUEST,
  FETCH_INTERNAL_PROGRAMS_SUCCESS,
  FETCH_INTERNAL_PROGRAMS_FAILURE,
  FETCH_INTERNAL_PROGRAMS_CLEAR,
  FETCH_EXTERNAL_PROGRAMS_REQUEST,
  FETCH_EXTERNAL_PROGRAMS_SUCCESS,
  FETCH_EXTERNAL_PROGRAMS_FAILURE,
  FETCH_EXTERNAL_PROGRAMS_CLEAR,
  FETCH_PROGRAM_TERMS_REQUEST,
  FETCH_PROGRAM_TERMS_SUCCESS,
  FETCH_PROGRAM_TERMS_FAILURE,
  FETCH_PROGRAM_TERMS_CLEAR,
  CREATE_REPORT_FILTERS_REQUEST,
  CREATE_REPORT_FILTERS_SUCCESS,
  CREATE_REPORT_FILTERS_FAILURE,
  CREATE_REPORT_FILTERS_CLEAR,
  DELETE_REPORT_FILTERS_REQUEST,
  DELETE_REPORT_FILTERS_SUCCESS,
  DELETE_REPORT_FILTERS_FAILURE,
  DELETE_REPORT_FILTERS_CLEAR,
  UPDATE_REPORT_FILTERS_REQUEST,
  UPDATE_REPORT_FILTERS_SUCCESS,
  UPDATE_REPORT_FILTERS_FAILURE,
  UPDATE_REPORT_FILTERS_CLEAR,
  DELETE_ORGANIZATION_USER,
  PUBLISH_INTERNAL_PROGRAM,
  PUBLISH_INTERNAL_PROGRAM_SUCCESS,
  PUBLISH_INTERNAL_PROGRAM_FAILURE,
  PUBLISH_INTERNAL_PROGRAM_CLEAR,
  UNPUBLISH_INTERNAL_PROGRAM,
  UNPUBLISH_INTERNAL_PROGRAM_SUCCESS,
  UNPUBLISH_INTERNAL_PROGRAM_FAILURE,
  UNPUBLISH_INTERNAL_PROGRAM_CLEAR,
  BATCH_ADD_TERM,
  BATCH_ADD_TERM_SUCCESS,
  BATCH_ADD_TERM_FAILURE,
  BATCH_ADD_TERM_CLEAR,
  BATCH_ADD_APPLICATION,
  BATCH_ADD_APPLICATION_SUCCESS,
  BATCH_ADD_APPLICATION_FAILURE,
  BATCH_ADD_APPLICATION_CLEAR,
  BATCH_ADD_FORM,
  BATCH_ADD_FORM_SUCCESS,
  BATCH_ADD_FORM_FAILURE,
  BATCH_ADD_FORM_CLEAR,
  AUTHORIZE_EXTERNAL_PROGRAM,
  AUTHORIZE_EXTERNAL_PROGRAM_SUCCESS,
  AUTHORIZE_EXTERNAL_PROGRAM_FAILURE,
  AUTHORIZE_EXTERNAL_PROGRAM_CLEAR,
  UNAUTHORIZE_EXTERNAL_PROGRAM,
  UNAUTHORIZE_EXTERNAL_PROGRAM_SUCCESS,
  UNAUTHORIZE_EXTERNAL_PROGRAM_FAILURE,
  UNAUTHORIZE_EXTERNAL_PROGRAM_CLEAR,
  FETCH_APPLICATION_DATA_REQUEST,
  FETCH_APPLICATION_DATA_SUCCESS,
  FETCH_APPLICATION_DATA_FAILURE,
  FETCH_APPLICATION_DATA_CLEAR,
  FETCH_UNAUTHORIZED_EXTERNAL_PROGRAMS_REQUEST,
  FETCH_UNAUTHORIZED_EXTERNAL_PROGRAMS_SUCCESS,
  FETCH_UNAUTHORIZED_EXTERNAL_PROGRAMS_FAILURE,
  FETCH_UNAUTHORIZED_EXTERNAL_PROGRAMS_CLEAR,
  TRAVELER_FORMS_REQUEST,
  TRAVELER_FORMS_SUCCESS,
  TRAVELER_FORMS_FAILURE,
  TRAVELER_FORMS_CLEAR,
  BATCH_ADD_AUTH_TERM,
  BATCH_ADD_AUTH_TERM_SUCCESS,
  BATCH_ADD_AUTH_TERM_FAILURE,
  BATCH_ADD_AUTH_TERM_CLEAR,
  BATCH_UPDATE_AUTH_TERM,
  BATCH_UPDATE_AUTH_TERM_SUCCESS,
  BATCH_UPDATE_AUTH_TERM_FAILURE,
  BATCH_UPDATE_AUTH_TERM_CLEAR,
  GET_AUTH_TERMS,
  GET_FORM_TEMPLATES,
  GET_APPLICATION_TEMPLATES,
  FETCH_TRAVELERS_REQUEST,
  FETCH_TRAVELERS_SUCCESS,
  FETCH_TRAVELERS_FAILURE,
  FETCH_TRAVELERS_CLEAR,
  BATCH_UPDATE_CUSTOM_FIELD,
  BATCH_UPDATE_CUSTOM_FIELD_SUCCESS,
  BATCH_UPDATE_CUSTOM_FIELD_CLEAR,
  BATCH_UPDATE_CUSTOM_FIELD_FAILURE,
  BATCH_ADD_MESSAGE_RECIPIENT,
  BATCH_ADD_MESSAGE_RECIPIENT_CLEAR,
  BATCH_ADD_MESSAGE_RECIPIENT_SUCCESS,
  BATCH_ADD_MESSAGE_RECIPIENT_FAILURE,
  BATCH_REMOVE_MESSAGE_RECIPIENT,
  BATCH_REMOVE_MESSAGE_RECIPIENT_CLEAR,
  BATCH_REMOVE_MESSAGE_RECIPIENT_FAILURE,
  BATCH_REMOVE_MESSAGE_RECIPIENT_SUCCESS,
  BATCH_INVITE_TRAVELERS_TO_EVENT,
  BATCH_INVITE_TRAVELERS_TO_EVENT_SUCCESS,
  BATCH_INVITE_TRAVELERS_TO_EVENT_FAILURE,
  BATCH_INVITE_TRAVELERS_TO_EVENT_CLEAR,
  BATCH_ADD_FORMS_TO_TRAVELERS,
  BATCH_ADD_FORMS_TO_TRAVELERS_SUCCESS,
  BATCH_ADD_FORMS_TO_TRAVELERS_FAILURE,
  BATCH_ADD_FORMS_TO_TRAVELERS_CLEAR,
  FETCH_EVENTS_REPORT_REQUEST,
  FETCH_EVENTS_REPORT_SUCCESS,
  FETCH_EVENTS_REPORT_FAILURE,
  FETCH_EVENTS_REPORT_CLEAR,
  FETCH_EVENT_PARTICIPANTS_REPORT_REQUEST,
  FETCH_EVENT_PARTICIPANTS_REPORT_SUCCESS,
  FETCH_EVENT_PARTICIPANTS_REPORT_FAILURE,
  FETCH_EVENT_PARTICIPANTS_REPORT_CLEAR,
  FETCH_TRAVELER_PROGRAMS_REQUEST,
  FETCH_TRAVELER_PROGRAMS_SUCCESS,
  FETCH_TRAVELER_PROGRAMS_FAILURE,
  FETCH_TRAVELER_PROGRAMS_CLEAR,
  UNASSIGN_SUITCASES_REQUEST,
  UNASSIGN_SUITCASES_SUCCESS,
  UNASSIGN_SUITCASES_CLEAR,
  UNASSIGN_SUITCASES_FAILURE,
  FETCH_HORIZONS_BROWN_REQUEST,
  FETCH_HORIZONS_BROWN_SUCCESS,
  FETCH_HORIZONS_BROWN_FAILURE,
  FETCH_HORIZONS_BROWN_CLEAR,
  APPLICATION_STATUS_UPDATE_REQUEST,
  APPLICATION_STATUS_UPDATE_SUCCESS,
  APPLICATION_STATUS_UPDATE_FAILURE,
  APPLICATION_STATUS_UPDATE_CLEAR,
  FORM_STATUS_UPDATE_REQUEST,
  FORM_STATUS_UPDATE_SUCCESS,
  FORM_STATUS_UPDATE_FAILURE,
  FORM_STATUS_UPDATE_CLEAR,
  FETCH_FORM_STATUS_OPTIONS_REQUEST,
  FETCH_FORM_STATUS_OPTIONS_CLEAR,
  FETCH_FORM_STATUS_OPTIONS_FAILURE,
  FETCH_FORM_STATUS_OPTIONS_SUCCESS,
  FETCH_APP_STATUS_OPTIONS_REQUEST,
  FETCH_APP_STATUS_OPTIONS_CLEAR,
  FETCH_APP_STATUS_OPTIONS_FAILURE,
  FETCH_APP_STATUS_OPTIONS_SUCCESS,
  TRAVELER_FORMS_TEMPLATE_REQUEST,
  TRAVELER_FORMS_TEMPLATE_SUCCESS,
  TRAVELER_FORMS_TEMPLATE_FAILURE,
  TRAVELER_FORMS_TEMPLATE_CLEAR,
  FETCH_APPLICATION_TEMPLATE_DATA_REQUEST,
  FETCH_APPLICATION_TEMPLATE_DATA_SUCCESS,
  FETCH_APPLICATION_TEMPLATE_DATA_FAILURE,
  FETCH_APPLICATION_TEMPLATE_DATA_CLEAR,
  FETCH_SFSU_DATA_REQUEST,
  FETCH_SFSU_DATA_SUCCESS,
  FETCH_SFSU_DATA_FAILURE,
  FETCH_SFSU_DATA_CLEAR,
  GET_STANDARD_REPORTS_REQUEST,
  GET_STANDARD_REPORTS_SUCCESS,
  GET_STANDARD_REPORTS_FAILURE,
  GET_STANDARD_REPORT_NOTIFICATION_REQUEST,
  GET_STANDARD_REPORT_NOTIFICATION_SUCCESS,
  GET_STANDARD_REPORT_NOTIFICATION_FAILURE,
  DELETE_STANDARD_REPORT_REQUEST,
  DELETE_STANDARD_REPORT_SUCCESS,
  DELETE_STANDARD_REPORT_FAILURE,
  MARK_READ_NOTIFICATION_REQUEST,
  MARK_READ_NOTIFICATION_SUCCESS,
  MARK_READ_NOTIFICATION_FAILURE,
  MARK_ALL_READ_NOTIFICATION_REQUEST,
  MARK_ALL_READ_NOTIFICATION_SUCCESS,
  MARK_ALL_READ_NOTIFICATION_FAILURE,
  GET_LIVE_REPORT_FLAG_REQUEST,
  GET_LIVE_REPORT_FLAG_SUCCESS,
  GET_LIVE_REPORT_FLAG_FAILURE,
  UPDATE_LIVE_REPORT_FLAG_REQUEST,
  UPDATE_LIVE_REPORT_FLAG_SUCCESS,
  UPDATE_LIVE_REPORT_FLAG_FAILURE,
  ADD_APPLICATION_TAGS_REQUEST,
  ADD_APPLICATION_TAGS_SUCCESS,
  ADD_APPLICATION_TAGS_CLEAR,
  ADD_APPLICATION_TAGS_FAILURE,
  REMOVE_APPLICATION_TAGS_REQUEST,
  REMOVE_APPLICATION_TAGS_SUCCESS,
  REMOVE_APPLICATION_TAGS_CLEAR,
  REMOVE_APPLICATION_TAGS_FAILURE,
  FETCH_EXCHANGE_BALANCE_REQUEST,
  FETCH_EXCHANGE_BALANCE_SUCCESS,
  FETCH_EXCHANGE_BALANCE_CLEAR,
  FETCH_EXCHANGE_BALANCE_FAILURE,
  UPDATE_EXCHANGE_BALANCE_REQUEST,
  UPDATE_EXCHANGE_BALANCE_SUCCESS,
  UPDATE_EXCHANGE_BALANCE_CLEAR,
  UPDATE_EXCHANGE_BALANCE_FAILURE,
  FETCH_BALANCE_VALUE_REQUEST,
  FETCH_BALANCE_VALUE_SUCCESS,
  FETCH_BALANCE_VALUE_CLEAR,
  FETCH_BALANCE_VALUE_FAILURE,
  PLAN_FORM_STATUS_UPDATE_REQUEST,
  PLAN_FORM_STATUS_UPDATE_SUCCESS,
  PLAN_FORM_STATUS_UPDATE_CLEAR,
  PLAN_FORM_STATUS_UPDATE_FAILURE,
  FETCH_TRAVELERS_ACTIVITY_REQUEST,
  FETCH_TRAVELERS_ACTIVITY_SUCCESS,
  FETCH_TRAVELERS_ACTIVITY_CLEAR,
  FETCH_TRAVELERS_ACTIVITY_FAILURE,


} from './types';
import { keys } from '../config/keys';
import { calcTiming } from '../shared/helpers/plans';
import { Cookies } from 'react-cookie';

export function getAvailableReports(type = '') {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/csv_reporting?report_type=${type}`, {
      method: 'GET',
      headers: token,
      // body: JSON.stringify(data),
    })
      .then(res => res.json())
      .then(res => {
        dispatch({
          type: GET_AVAILABLE_REPORTS,
          payload: res,
        });
      });
  };
}

export function getReport(data, report_this) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/csv_reporting`, {
      method: 'POST',
      headers: token,
      body: JSON.stringify(data),
    })
      .then(res => res.json())
      .then(res => {
        report_this.downloadReport(res);
      });
  };
}

export function getParticipationReport(submissionIds, filename = '', callback = null) {
  const dataSet = { submission_ids: submissionIds, filename };
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/reports/standard_reports/participation`, {
      method: 'POST',
      headers: token,
      body: JSON.stringify(dataSet),
    })
      .then(res => res.json())
      .then(res => {
        dispatch(getStandardReportNotifications());
        if (callback && typeof callback === 'function') {
          callback(true, `Standard report is generating.`, res.data);
        }
      })
      .catch(error => {
        if (callback && typeof callback === 'function') {
          callback(false, error.message, null);
        }
      });
  };
}

export function getApplicationCountReport(submissionIds, filename = '', callback = null) {
  const dataSet = { submission_ids: submissionIds, filename };
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/reports/standard_reports/application_status_count`, {
      method: 'POST',
      headers: token,
      body: JSON.stringify(dataSet),
    })
      .then(res => res.json())
      .then(res => {
        dispatch(getStandardReportNotifications());
        if (callback && typeof callback === 'function') {
          callback(true, `Standard report is generating.`, res.data);
        }
      })
      .catch(error => {
        if (callback && typeof callback === 'function') {
          callback(false, error.message, null);
        }
      });
  };
}

export function getApplicationAlternatesReport(submissionIds, filename = '', callback = null) {
  const dataSet = { submission_ids: submissionIds, filename };
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/reports/standard_reports/application_alternates`, {
      method: 'POST',
      headers: token,
      body: JSON.stringify(dataSet),
    })
      .then(res => res.json())
      .then(res => {
        dispatch(getStandardReportNotifications());
        if (callback && typeof callback === 'function') {
          callback(true, `Standard report is generating.`, res.data);
        }
      })
      .catch(error => {
        if (callback && typeof callback === 'function') {
          callback(false, error.message, null);
        }
      });
  };
}

export function getTravelerInformationReport(userIds, filename = '', callback = null) {
  const dataSet = { user_ids: userIds, filename };
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/reports/standard_reports/traveler_information`, {
      method: 'POST',
      headers: token,
      body: JSON.stringify(dataSet),
    })
      .then(res => res.json())
      .then(res => {
        dispatch(getStandardReportNotifications());
        if (callback && typeof callback === 'function') {
          callback(true, `Standard report is generating.`, res.data);
        }
      })
      .catch(error => {
        if (callback && typeof callback === 'function') {
          console.log('error is ', error);
          callback(false, error.message, null);
        }
      });
  };
}

export function getFormStatusByProgramAndTermReport(submissionIds, filename = '', report_by = '', callback = null) {
  let dataSet = { submission_ids: submissionIds, filename };
  const cookies = new Cookies();
  const token = cookies.get('token');

  if (report_by) {
    dataSet = {
      ...dataSet,
      report_by,
    };
  }

  return function(dispatch) {
    fetch(`${keys.baseUri}/api/reports/standard_reports/form_statuses`, {
      method: 'POST',
      headers: token,
      body: JSON.stringify(dataSet),
    })
      .then(res => res.json())
      .then(res => {
        dispatch(getStandardReportNotifications());
        if (callback && typeof callback === 'function') {
          callback(true, `Standard report is generating.`, res.data);
        }
      })
      .catch(error => {
        if (callback && typeof callback === 'function') {
          callback(false, error.message, null);
        }
      });
  };
}

export function getApplicationContentByProgramNameAndTerm(submissionIds, filename = '', callback = null) {
  const dataSet = { submission_ids: submissionIds, filename };
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/reports/standard_reports/application_content_by_program_name_and_term`, {
      method: 'POST',
      headers: token,
      body: JSON.stringify(dataSet),
    })
      .then(res => res.json())
      .then(res => {
        dispatch(getStandardReportNotifications());
        if (callback && typeof callback === 'function') {
          callback(true, `Standard report is generating.`, res.data);
        }
      })
      .catch(error => {
        if (callback && typeof callback === 'function') {
          callback(false, error.message, null);
        }
      });
  };
}

export function getApplicationContentByApplicationTemplate(submissionIds, filename = '', callback = null) {
  const dataSet = { submission_ids: submissionIds, filename };
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/reports/standard_reports/application_content_by_application_template`, {
      method: 'POST',
      headers: token,
      body: JSON.stringify(dataSet),
    })
      .then(res => res.json())
      .then(res => {
        dispatch(getStandardReportNotifications());
        if (callback && typeof callback === 'function') {
          callback(true, `Standard report is generating.`, res.data);
        }
      })
      .catch(error => {
        if (callback && typeof callback === 'function') {
          callback(false, error.message, null);
        }
      });
  };
}

export function getFormContentByProgramNameAndTerm(submissionIds, filename = '', report_by = '', callback = null) {
  let dataSet = { submission_ids: submissionIds, filename };
  const cookies = new Cookies();
  const token = cookies.get('token');

  if (report_by) {
    dataSet = {
      ...dataSet,
      report_by,
    };
  }

  return function(dispatch) {
    fetch(`${keys.baseUri}/api/reports/standard_reports/form_content_by_program_name_and_term`, {
      method: 'POST',
      headers: token,
      body: JSON.stringify(dataSet),
    })
      .then(res => res.json())
      .then(res => {
        dispatch(getStandardReportNotifications());
        if (callback && typeof callback === 'function') {
          callback(true, `Standard report is generating.`, res.data);
        }
      })
      .catch(error => {
        if (callback && typeof callback === 'function') {
          callback(false, error.message, null);
        }
      });
  };
}

export function getFormContentByFormTemplate(submissionIds, filename = '', report_by = '', callback = null) {
  let dataSet = { submission_ids: submissionIds, filename };
  const cookies = new Cookies();
  const token = cookies.get('token');

  if (report_by) {
    dataSet = {
      ...dataSet,
      report_by,
    };
  }

  return function(dispatch) {
    fetch(`${keys.baseUri}/api/reports/standard_reports/form_content_by_form_template`, {
      method: 'POST',
      headers: token,
      body: JSON.stringify(dataSet),
    })
      .then(res => res.json())
      .then(res => {
        dispatch(getStandardReportNotifications());
        if (callback && typeof callback === 'function') {
          callback(true, `Standard report is generating.`, res.data);
        }
      })
      .catch(error => {
        if (callback && typeof callback === 'function') {
          callback(false, error.message, null);
        }
      });
  };
}

// OLD getReports: used by routes/reports/ReportStart/*
export function getReports(data) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/csv_reporting/get_report`, {
      method: 'POST',
      headers: token,
      body: JSON.stringify(data),
    })
      .then(res => res.json())
      .then(res => {
        dispatch({
          type: GET_REPORT,
          payload: res.csv,
        });
      });
  };
}

export function fetchTravelersReport() {
  const cookies = new Cookies();
  const token = cookies.get('token');

  return function(dispatch) {
    dispatch({ type: FETCH_TRAVELERS_REPORT_REQUEST });
    fetch(`${keys.baseUri}/api/reports/travelers`, {
      method: 'GET',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: FETCH_TRAVELERS_REPORT_SUCCESS,
          payload: data,
        });
        dispatch({
          type: FETCH_TRAVELERS_REPORT_CLEAR,
        });
      })
      .catch(error => {
        dispatch({
          type: FETCH_TRAVELERS_REPORT_FAILURE,
          payload: error.toString(),
        });
      });
  };
}

export function fetchFormsReport(dayOffset = null) {
  let priorDate = null;
  if (dayOffset && !isNaN(dayOffset)) {
    const today = dateInUTC();
    priorDate = getDateFromOffsetDays(today, dayOffset);
  }

  const endDateParam = priorDate ? `?end_date=${priorDate}` : '';
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({ type: FETCH_FORMS_REPORT_REQUEST });
    fetch(`${keys.baseUri}/api/reports/form_groupings${endDateParam}`, {
      method: 'GET',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: FETCH_FORMS_REPORT_SUCCESS,
          payload: data,
        });
        dispatch({
          type: FETCH_FORMS_REPORT_CLEAR,
        });
      })
      .catch(error => {
        dispatch({
          type: FETCH_FORMS_REPORT_FAILURE,
          payload: error.toString(),
        });
      });
  };
}

export function fetchProgramsReport() {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({ type: FETCH_PROGRAMS_REPORT_REQUEST });
    fetch(`${keys.baseUri}/api/reports/programs`, {
      method: 'GET',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: FETCH_PROGRAMS_REPORT_SUCCESS,
          payload: data,
        });
        dispatch({
          type: FETCH_PROGRAMS_REPORT_CLEAR,
        });
      })
      .catch(error => {
        dispatch({
          type: FETCH_PROGRAMS_REPORT_FAILURE,
          payload: error.toString(),
        });
      });
  };
}

export function fetchInternalPrograms() {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({ type: FETCH_INTERNAL_PROGRAMS_REQUEST });
    fetch(`${keys.baseUri}/api/programs?source=internal`, {
      method: 'GET',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: FETCH_INTERNAL_PROGRAMS_SUCCESS,
          payload: data,
        });
        dispatch({
          type: FETCH_INTERNAL_PROGRAMS_CLEAR,
        });
      })
      .catch(error => {
        dispatch({
          type: FETCH_INTERNAL_PROGRAMS_FAILURE,
          payload: error.toString(),
        });
      });
  };
}

export function fetchExternalPrograms(type) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({ type: FETCH_EXTERNAL_PROGRAMS_REQUEST });
    fetch(`${keys.baseUri}/api/programs?source=${type}`, {
      method: 'GET',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: FETCH_EXTERNAL_PROGRAMS_SUCCESS,
          payload: data,
        });
        dispatch({
          type: FETCH_EXTERNAL_PROGRAMS_CLEAR,
        });
      })
      .catch(error => {
        dispatch({
          type: FETCH_EXTERNAL_PROGRAMS_FAILURE,
          payload: error.toString(),
        });
      });
  };
}

export function fetchUnauthorizedExternalPrograms(providerId) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({ type: FETCH_UNAUTHORIZED_EXTERNAL_PROGRAMS_REQUEST });
    fetch(`${keys.baseUri}/api/programs/available_for_authorization/${providerId}`, {
      method: 'GET',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: FETCH_UNAUTHORIZED_EXTERNAL_PROGRAMS_SUCCESS,
          payload: data,
        });
        dispatch({
          type: FETCH_UNAUTHORIZED_EXTERNAL_PROGRAMS_CLEAR,
        });
      })
      .catch(error => {
        dispatch({
          type: FETCH_UNAUTHORIZED_EXTERNAL_PROGRAMS_FAILURE,
          payload: error.toString(),
        });
      });
  };
}

export function fetchApplicationData(templateId = null, dayOffset = null, type = "application") {
  let priorDate = null;
  if (dayOffset && !isNaN(dayOffset)) {
    const today = dateInUTC();
    priorDate = getDateFromOffsetDays(today, dayOffset);
  }

  const params = !templateId ? '' : `&template_id=${templateId}`;
  const endDateParam = priorDate ? `&end_date=${priorDate}` : '';
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    if (templateId) {
      dispatch({ type: FETCH_APPLICATION_TEMPLATE_DATA_REQUEST });
    } else {
      dispatch({ type: FETCH_APPLICATION_DATA_REQUEST });
    }

    fetch(`${keys.baseUri}/api/reports/submissions?type=${type}${params}${endDateParam}`, {
      method: 'GET',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        if (templateId) {
          dispatch({
            type: FETCH_APPLICATION_TEMPLATE_DATA_SUCCESS,
            payload: data,
          });
          dispatch({
            type: FETCH_APPLICATION_TEMPLATE_DATA_CLEAR,
          });
        } else {
          dispatch({
            type: FETCH_APPLICATION_DATA_SUCCESS,
            payload: data,
          });
          dispatch({
            type: FETCH_APPLICATION_DATA_CLEAR,
          });
        }
      })
      .catch(error => {
        if (templateId) {
          dispatch({
            type: FETCH_APPLICATION_TEMPLATE_DATA_FAILURE,
            payload: error.toString(),
          });
        } else {
          dispatch({
            type: FETCH_APPLICATION_DATA_FAILURE,
            payload: error.toString(),
          });
        }
      });
  };
}

export function fetchApplicationDataV2(callback = null, dayOffset = null) {
  let priorDate = null;
  if (dayOffset && !isNaN(dayOffset)) {
    const today = dateInUTC();
    priorDate = getDateFromOffsetDays(today, dayOffset);
  }
  const endDateParam = priorDate ? `&end_date=${priorDate}` : '';
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function() {
    fetch(`${keys.baseUri}/api/reports/submissions?type=application&version=2${endDateParam}`, {
      method: 'GET',
      headers: token,
    }).then(res => {
      if (res.status === 200) {
        res.json().then(data => {
          if (callback && typeof callback === 'function') {
            // result, success, data
            const dataset = data.map(app => {
              // tag list
              let tagOptions = [];
              let tagList = app.tags;
              if (tagList?.length > 0) {
                tagOptions = tagList.map(tag => tag.name);
              }

              //Term timing
              const term_timing = calcTiming(app.program_range_start_date, app.program_range_end_date);

              return {
                ...app,
                deadline_field: new Date(app.deadline_raw),
                deferred_at_field: app.deferred_at_raw ? new Date(app.deferred_at_raw) : null,
                withdrawn_at_field: app.withdrawn_at_raw ? new Date(app.withdrawn_at_raw) : null,
                program_range_start_date_field: new Date(app.program_range_start_date),
                program_range_end_date_field: new Date(app.program_range_end_date),
                status_last_updated_at_field: new Date(app.status_last_updated_at_raw),
                submitted_at_field: app.submitted_at_raw ? new Date(app.submitted_at_raw) : null,
                submission_created_at_field: new Date(app.submission_created_at_raw),
                term_timing_field: term_timing,
                tags_field: tagOptions,
              };
            });

            callback(true, true, dataset);
          }
        });
      } else {
        res.json().then(data => {
          const errorMsg = 'Error loading application data.';
          if (callback && typeof callback === 'function') {
            // result, success, data
            callback(true, false, errorMsg);
          }
        });
      }
    });
  };
}

export const fetchTravelerFormsData = (templateId = null, dayOffset = null) => dispatch => {
  let priorDate = null;
  if (dayOffset && !isNaN(dayOffset)) {
    const today = dateInUTC();
    priorDate = getDateFromOffsetDays(today, dayOffset);
  }

  const params = !templateId ? '' : `&template_id=${templateId}`;
  const endDateParam = priorDate ? `&end_date=${priorDate}` : '';

  if (templateId) {
    dispatch({ type: TRAVELER_FORMS_TEMPLATE_REQUEST });
  } else {
    dispatch({ type: TRAVELER_FORMS_REQUEST });
  }

  const cookies = new Cookies();
  const token = cookies.get('token');
  fetch(`${keys.baseUri}/api/reports/submissions?type=form${params}${endDateParam}`, {
    method: 'GET',
    headers: token,
  })
    .then(res => res.json())
    .then(data => {
      if (templateId) {
        dispatch({
          type: TRAVELER_FORMS_TEMPLATE_SUCCESS,
          payload: data,
        });
        dispatch({
          type: TRAVELER_FORMS_TEMPLATE_CLEAR,
        });
      } else {
        dispatch({
          type: TRAVELER_FORMS_SUCCESS,
          payload: data,
        });
        dispatch({
          type: TRAVELER_FORMS_CLEAR,
        });
      }
    })
    .catch(error => {
      if (templateId) {
        dispatch({
          type: TRAVELER_FORMS_TEMPLATE_FAILURE,
          payload: error.toString(),
        });
      } else {
        dispatch({
          type: TRAVELER_FORMS_FAILURE,
          payload: error.toString(),
        });
      }
    });
};

export function fetchApplicationsReport() {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({ type: FETCH_APPLICATIONS_REPORT_REQUEST });
    fetch(`${keys.baseUri}/api/reports/submissions?type=application`, {
      method: 'GET',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: FETCH_APPLICATIONS_REPORT_SUCCESS,
          payload: data,
        });
        dispatch({
          type: FETCH_APPLICATIONS_REPORT_CLEAR,
        });
      })
      .catch(error => {
        dispatch({
          type: FETCH_APPLICATIONS_REPORT_FAILURE,
          payload: error.toString(),
        });
      });
  };
}

export function fetchReportFilters(reportType) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({ type: FETCH_REPORT_FILTERS_REQUEST });
    fetch(`${keys.baseUri}/api/client/search_filters?filter_type=${reportType}`, {
      method: 'GET',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: FETCH_REPORT_FILTERS_SUCCESS,
          payload: data,
        });
        dispatch({
          type: FETCH_REPORT_FILTERS_CLEAR,
        });
      })
      .catch(error => {
        dispatch({
          type: FETCH_REPORT_FILTERS_FAILURE,
          payload: error.toString(),
        });
      });
  };
}

export function createReportFilter(data) {
  const saveData = JSON.stringify({ search_filter: data });
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({ type: CREATE_REPORT_FILTERS_REQUEST });
    fetch(`${keys.baseUri}/api/client/search_filters`, {
      method: 'POST',
      headers: token,
      body: saveData,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: CREATE_REPORT_FILTERS_SUCCESS,
          payload: data,
        });
        dispatch({
          type: CREATE_REPORT_FILTERS_CLEAR,
        });
      })
      .catch(error => {
        dispatch({
          type: CREATE_REPORT_FILTERS_FAILURE,
          payload: error.toString(),
        });
      });
  };
}

export function deleteReportFilter(filterId) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({ type: DELETE_REPORT_FILTERS_REQUEST });
    fetch(`${keys.baseUri}/api/client/search_filters/${filterId}`, {
      method: 'DELETE',
      headers: token,
    })
      .then(data => {
        dispatch({
          type: DELETE_REPORT_FILTERS_SUCCESS,
          payload: { data: { success: true } },
        });
        dispatch({
          type: DELETE_REPORT_FILTERS_CLEAR,
        });
      })
      .catch(error => {
        dispatch({
          type: DELETE_REPORT_FILTERS_FAILURE,
          payload: error.toString(),
        });
      });
  };
}

export function updateReportFilter(filterId, values) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({ type: UPDATE_REPORT_FILTERS_REQUEST });
    fetch(`${keys.baseUri}/api/client/search_filters/${filterId}`, {
      method: 'PATCH',
      headers: token,
      body: JSON.stringify(values),
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: UPDATE_REPORT_FILTERS_SUCCESS,
          payload: { data },
        });
        dispatch({
          type: UPDATE_REPORT_FILTERS_CLEAR,
        });
      })
      .catch(error => {
        dispatch({
          type: UPDATE_REPORT_FILTERS_FAILURE,
          payload: error.toString(),
        });
      });
  };
}

export function publishInternalProgram(values) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    values.program.status === 'published'
      ? dispatch({ type: PUBLISH_INTERNAL_PROGRAM })
      : dispatch({ type: UNPUBLISH_INTERNAL_PROGRAM });

    fetch(`${keys.baseUri}/api/program_batch_actions`, {
      method: 'PATCH',
      headers: token,
      body: JSON.stringify(values),
    })
      .then(res => res.json())
      .then(data => {
        values.program.status === 'published'
          ? (dispatch({
              type: PUBLISH_INTERNAL_PROGRAM_SUCCESS,
              payload: { data: data },
            }),
            dispatch({
              type: PUBLISH_INTERNAL_PROGRAM_CLEAR,
            }),
            dispatch(fetchInternalPrograms()))
          : (dispatch({
              type: UNPUBLISH_INTERNAL_PROGRAM_SUCCESS,
              payload: { data: data },
            }),
            dispatch({
              type: UNPUBLISH_INTERNAL_PROGRAM_CLEAR,
            }),
            dispatch(fetchInternalPrograms()));
      })
      .catch(error => {
        values.program.status === 'published'
          ? dispatch({
              type: PUBLISH_INTERNAL_PROGRAM_FAILURE,
              payload: error.toString(),
            })
          : dispatch({
              type: UNPUBLISH_INTERNAL_PROGRAM_FAILURE,
              payload: error.toString(),
            });
      });
  };
}

export function batchAddTerm(values) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({ type: BATCH_ADD_TERM });
    fetch(`${keys.baseUri}/api/program_batch_actions/add_program_ranges`, {
      method: 'POST',
      headers: token,
      body: JSON.stringify(values),
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: BATCH_ADD_TERM_SUCCESS,
          payload: { data: data },
        });
        dispatch({
          type: BATCH_ADD_TERM_CLEAR,
        });
        dispatch(fetchInternalPrograms());
      })
      .catch(error => {
        dispatch({
          type: BATCH_ADD_TERM_FAILURE,
          payload: error.toString(),
        });
      });
  };
}

export function fetchProgramTerms(type) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({ type: FETCH_PROGRAM_TERMS_REQUEST });
    fetch(`${keys.baseUri}/api/program_ranges?source=${type}`, {
      method: 'GET',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: FETCH_PROGRAM_TERMS_SUCCESS,
          payload: data,
        });
        dispatch({
          type: FETCH_PROGRAM_TERMS_CLEAR,
        });
      })
      .catch(error => {
        dispatch({
          type: FETCH_PROGRAM_TERMS_FAILURE,
          payload: error.toString(),
        });
      });
  };
}
export function batchAddApplication(values, type) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({ type: BATCH_ADD_APPLICATION });
    fetch(`${keys.baseUri}/api/program_range_batch_actions/add_application_template`, {
      method: 'POST',
      headers: token,
      body: JSON.stringify(values),
    })
      .then(response => {
        if (response.status == 401) {
          response.json().then(data => {
            dispatch({
              type: BATCH_ADD_APPLICATION_SUCCESS,
              payload: { message: 'Error saving' },
            });
            dispatch({
              type: BATCH_ADD_APPLICATION_CLEAR,
            });
          });
        } else {
          response.json().then(data => {
            setTimeout(() => {
              dispatch({
                type: BATCH_ADD_APPLICATION_SUCCESS,
                payload: { data: data },
              });
              dispatch({
                type: BATCH_ADD_APPLICATION_CLEAR,
              });
              dispatch(fetchProgramTerms(type));
            }, 1500);
          });
        }
      })
      .catch(error => {
        dispatch({
          type: BATCH_ADD_APPLICATION_FAILURE,
          payload: error.toString(),
        });
      });
  };
}
export function batchAddForm(values, type) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({ type: BATCH_ADD_FORM });
    fetch(`${keys.baseUri}/api/program_range_batch_actions/add_form_templates`, {
      method: 'POST',
      headers: token,
      body: JSON.stringify(values),
    })
      .then(response => {
        if (response.status == 401) {
          response.json().then(data => {
            dispatch({
              type: BATCH_ADD_FORM_SUCCESS,
              payload: { message: 'Error saving' },
            });
            dispatch({
              type: BATCH_ADD_FORM_CLEAR,
            });
          });
        } else {
          response.json().then(data => {
            setTimeout(() => {
              dispatch({
                type: BATCH_ADD_FORM_SUCCESS,
                payload: { data: data },
              });
              dispatch({
                type: BATCH_ADD_FORM_CLEAR,
              });
              dispatch(fetchProgramTerms(type));
            }, 1500);
          });
        }
      })
      .catch(error => {
        dispatch({
          type: BATCH_ADD_FORM_FAILURE,
          payload: error.toString(),
        });
      });
  };
}

export function authorizeExternalPrograms(values, currentOrg) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({ type: AUTHORIZE_EXTERNAL_PROGRAM });
    fetch(`${keys.baseUri}/api/program_batch_actions/authorize_programs`, {
      method: 'PUT',
      headers: token,
      body: JSON.stringify(values),
    })
      .then(res => res.json())
      .then(data => {
        setTimeout(() => {
          dispatch({
            type: AUTHORIZE_EXTERNAL_PROGRAM_SUCCESS,
            payload: { data: data },
          });
          dispatch({
            type: AUTHORIZE_EXTERNAL_PROGRAM_CLEAR,
          });
          dispatch(fetchUnauthorizedExternalPrograms(currentOrg));
        }, 1500);
      })
      .catch(error => {
        dispatch({
          type: AUTHORIZE_EXTERNAL_PROGRAM_FAILURE,
          payload: error.toString(),
        });
      });
  };
}

export function unauthorizeExternalPrograms(values) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({ type: UNAUTHORIZE_EXTERNAL_PROGRAM });
    fetch(`${keys.baseUri}/api/program_batch_actions/deauthorize_programs`, {
      method: 'PUT',
      headers: token,
      body: JSON.stringify(values),
    })
      .then(res => res.json())
      .then(data => {
        setTimeout(() => {
          dispatch({
            type: UNAUTHORIZE_EXTERNAL_PROGRAM_SUCCESS,
            payload: { data: data },
          });
          dispatch({
            type: UNAUTHORIZE_EXTERNAL_PROGRAM_CLEAR,
          });
          dispatch(fetchExternalPrograms('authorized'));
        });
      }, 1500)
      .catch(error => {
        dispatch({
          type: UNAUTHORIZE_EXTERNAL_PROGRAM_FAILURE,
          payload: error.toString(),
        });
      });
  };
}


export const updatePlanFormStatuses = (values, selectedTemplateId, dayOffset = null, fetchApps = true) => {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({ type: PLAN_FORM_STATUS_UPDATE_REQUEST });

    fetch(`${keys.baseUri}/api/submission_batch_actions/update_statuses`, {
      method: 'PATCH',
      headers: token,
      body: JSON.stringify(values),
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: PLAN_FORM_STATUS_UPDATE_SUCCESS,
          data: data,
        });
        dispatch({
          type: PLAN_FORM_STATUS_UPDATE_CLEAR,
        });

        if (fetchApps) {
          dispatch(fetchApplicationData(selectedTemplateId, dayOffset, 'plan_form'));
        }
      })
      .catch(error => {
        dispatch({
          type: PLAN_FORM_STATUS_UPDATE_FAILURE,
          payload: error.toString(),
        });
      });
  };
};

export const updateApplicationStatuses = (values, selectedTemplateId, dayOffset = null, fetchApps = true) => {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({ type: APPLICATION_STATUS_UPDATE_REQUEST });

    fetch(`${keys.baseUri}/api/submission_batch_actions/update_statuses`, {
      method: 'PATCH',
      headers: token,
      body: JSON.stringify(values),
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: APPLICATION_STATUS_UPDATE_SUCCESS,
          data: data,
        });
        dispatch({
          type: APPLICATION_STATUS_UPDATE_CLEAR,
        });

        if (fetchApps) {
          dispatch(fetchApplicationData(selectedTemplateId, dayOffset));
        }
      })
      .catch(error => {
        dispatch({
          type: APPLICATION_STATUS_UPDATE_FAILURE,
          payload: error.toString(),
        });
      });
  };
};

export const updateFormStatuses = (values, selectedTemplateId = null, dayOffset = null, fetchApps = true) => {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({ type: FORM_STATUS_UPDATE_REQUEST });

    fetch(`${keys.baseUri}/api/submission_batch_actions/update_statuses`, {
      method: 'PATCH',
      headers: token,
      body: JSON.stringify(values),
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: FORM_STATUS_UPDATE_SUCCESS,
          data: data,
        });
      })
      .catch(error => {
        dispatch({
          type: FORM_STATUS_UPDATE_FAILURE,
          payload: error.toString(),
        });
      })
      .then(() => {
        dispatch({
          type: FORM_STATUS_UPDATE_CLEAR,
        });

        if (fetchApps) {
          if (selectedTemplateId) {
            dispatch(fetchTravelerFormsData(selectedTemplateId, dayOffset));
          } else {
            dispatch(fetchTravelerFormsData(null, dayOffset));
          }
        }
      });
  };
};

export const addApplicationTags = (values, type, selectedTemplateId = null, dayOffset = null) => {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({ type: ADD_APPLICATION_TAGS_REQUEST });
    fetch(`${keys.baseUri}/api/submission_batch_actions/add_tags`, {
      method: 'PATCH',
      headers: token,
      body: JSON.stringify(values),
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: ADD_APPLICATION_TAGS_SUCCESS,
          data: `${type == 'Application' ? 'Application' : 'Form'} tag(s) added`,
        });
        dispatch({
          type: ADD_APPLICATION_TAGS_CLEAR,
        });
      })
      .catch(error => {
        dispatch({
          type: ADD_APPLICATION_TAGS_FAILURE,
          payload: error.toString(),
        });
      });
  };
};

export const removeApplicationTags = (values, type, selectedTemplateId = null, dayOffset = null) => {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({ type: REMOVE_APPLICATION_TAGS_REQUEST });

    fetch(`${keys.baseUri}/api/submission_batch_actions/remove_tags`, {
      method: 'PATCH',
      headers: token,
      body: JSON.stringify(values),
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: REMOVE_APPLICATION_TAGS_SUCCESS,
          data: `${type == 'Application' ? 'Application' : 'Form'} tag(s) removed`,
        });
        dispatch({
          type: REMOVE_APPLICATION_TAGS_CLEAR,
        });
      })
      .catch(error => {
        dispatch({
          type: REMOVE_APPLICATION_TAGS_FAILURE,
          payload: error.toString(),
        });
      });
  };
};

export function getBatchAuthTerms() {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/suitcases`, {
      method: 'GET',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: GET_AUTH_TERMS,
          payload: data,
        });
      });
  };
}

export function getFormStatusOptions() {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({ type: FETCH_FORM_STATUS_OPTIONS_REQUEST });

    fetch(`${keys.baseUri}/api/submission_statuses?type=form`, {
      method: 'GET',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: FETCH_FORM_STATUS_OPTIONS_SUCCESS,
          payload: data,
        });
      })
      .catch(error => {
        dispatch({
          type: FETCH_FORM_STATUS_OPTIONS_FAILURE,
          payload: error.toString(),
        });
      })
      .then(() => {
        dispatch({
          type: FETCH_FORM_STATUS_OPTIONS_CLEAR,
        });
      });
  };
}

export function getAppStatusOptions() {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({ type: FETCH_APP_STATUS_OPTIONS_REQUEST });

    fetch(`${keys.baseUri}/api/submission_statuses?type=application`, {
      method: 'GET',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: FETCH_APP_STATUS_OPTIONS_SUCCESS,
          payload: data,
        });
      })
      .catch(error => {
        dispatch({
          type: FETCH_APP_STATUS_OPTIONS_FAILURE,
          payload: error.toString(),
        });
      })
      .then(() => {
        dispatch({
          type: FETCH_APP_STATUS_OPTIONS_CLEAR,
        });
      });
  };
}

export function batchAddAuthTerm(values) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({ type: BATCH_ADD_AUTH_TERM });
    fetch(`${keys.baseUri}/api/suitcases`, {
      method: 'POST',
      headers: token,
      body: JSON.stringify(values),
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: BATCH_ADD_AUTH_TERM_SUCCESS,
          payload: { data: data },
        });
        dispatch({
          type: BATCH_ADD_AUTH_TERM_CLEAR,
        });
        dispatch(getBatchAuthTerms());
      })
      .catch(error => {
        dispatch({
          type: BATCH_ADD_AUTH_TERM_FAILURE,
          payload: error.toString(),
        });
      });
  };
}

export function deleteAddAuthTerm(values) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({ type: BATCH_ADD_AUTH_TERM });
    fetch(`${keys.baseUri}/api/authorized_program_ranges/${id}`, {
      method: 'POST',
      headers: token,
      body: JSON.stringify(values),
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: BATCH_ADD_AUTH_TERM_SUCCESS,
          payload: { data: data },
        });
        dispatch({
          type: BATCH_ADD_AUTH_TERM_CLEAR,
        });
      })
      .catch(error => {
        dispatch({
          type: BATCH_ADD_AUTH_TERM_FAILURE,
          payload: error.toString(),
        });
      });
  };
}

export function updateAuthTerm(values, id) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({ type: BATCH_UPDATE_AUTH_TERM });
    fetch(`${keys.baseUri}/api/suitcases/${id}`, {
      method: 'PATCH',
      headers: token,
      body: JSON.stringify(values),
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: BATCH_UPDATE_AUTH_TERM_SUCCESS,
          payload: { data: data },
        });
        dispatch({
          type: BATCH_UPDATE_AUTH_TERM_CLEAR,
        });
        dispatch(getBatchAuthTerms());
      })
      .catch(error => {
        dispatch({
          type: BATCH_UPDATE_AUTH_TERM_FAILURE,
          payload: error.toString(),
        });
      });
  };
}

export function fetchTravelerData() {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({ type: FETCH_TRAVELERS_REQUEST });
    fetch(`${keys.baseUri}/api/report_travelers`, {
      method: 'GET',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: FETCH_TRAVELERS_SUCCESS,
          payload: data,
        });
        dispatch({
          type: FETCH_TRAVELERS_CLEAR,
        });
      })
      .catch(error => {
        dispatch({
          type: FETCH_TRAVELERS_FAILURE,
          payload: error.toString(),
        });
      });
  };
}

export function fetchTravelerActivityData() {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({ type: FETCH_TRAVELERS_ACTIVITY_REQUEST });
    fetch(`${keys.baseUri}/api/client/travelers/traveler_activities`, {
      method: 'GET',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: FETCH_TRAVELERS_ACTIVITY_SUCCESS,
          payload: data,
        });
        // dispatch({
        //   type: FETCH_TRAVELERS_ACTIVITY_CLEAR,
        // });
      })
      .catch(error => {
        dispatch({
          type: FETCH_TRAVELERS_ACTIVITY_FAILURE,
          payload: error.toString(),
        });
      });
  };
}

export function batchUpdateCustomFields(values, fetchApps = true) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({ type: BATCH_UPDATE_CUSTOM_FIELD });
    fetch(`${keys.baseUri}/api/traveler_batch_actions/update_custom_fields`, {
      method: 'PATCH',
      headers: token,
      body: JSON.stringify(values),
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: BATCH_UPDATE_CUSTOM_FIELD_SUCCESS,
          payload: { data: data },
        });
        dispatch({
          type: BATCH_UPDATE_CUSTOM_FIELD_CLEAR,
        });
        if (fetchApps) {
          dispatch(fetchTravelerData());
        }
      })
      .catch(error => {
        dispatch({
          type: BATCH_UPDATE_CUSTOM_FIELD_FAILURE,
          payload: error.toString(),
        });
      });
  };
}

export function batchAddMessageRecipient(values, fetchApps = true) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({ type: BATCH_ADD_MESSAGE_RECIPIENT });
    fetch(`${keys.baseUri}/api/traveler_batch_actions/add_message_recipients`, {
      method: 'POST',
      headers: token,
      body: JSON.stringify(values),
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: BATCH_ADD_MESSAGE_RECIPIENT_SUCCESS,
          payload: { data: data },
        });
        dispatch({
          type: BATCH_ADD_MESSAGE_RECIPIENT_CLEAR,
        });

        if (fetchApps) {
          dispatch(fetchTravelerData());
        }
      })
      .catch(error => {
        dispatch({
          type: BATCH_ADD_MESSAGE_RECIPIENT_FAILURE,
          payload: error.toString(),
        });
      });
  };
}

export function batchRemoveMessageRecipient(values, fetchApps = true) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({ type: BATCH_REMOVE_MESSAGE_RECIPIENT });
    fetch(`${keys.baseUri}/api/traveler_batch_actions/remove_message_recipients`, {
      method: 'DELETE',
      headers: token,
      body: JSON.stringify(values),
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: BATCH_REMOVE_MESSAGE_RECIPIENT_SUCCESS,
          payload: { data: data },
        });
        dispatch({
          type: BATCH_REMOVE_MESSAGE_RECIPIENT_CLEAR,
        });

        if (fetchApps) {
          dispatch(fetchTravelerData());
        }
      })
      .catch(error => {
        dispatch({
          type: BATCH_REMOVE_MESSAGE_RECIPIENT_FAILURE,
          payload: error.toString(),
        });
      });
  };
}

export function batchInviteTravelersToEvent(values) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({ type: BATCH_INVITE_TRAVELERS_TO_EVENT });
    fetch(`${keys.baseUri}/api/traveler_batch_actions/send_event_invitations`, {
      method: 'POST',
      headers: token,
      body: JSON.stringify(values),
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: BATCH_INVITE_TRAVELERS_TO_EVENT_SUCCESS,
          payload: data,
        });
        dispatch({
          type: BATCH_INVITE_TRAVELERS_TO_EVENT_CLEAR,
        });
        dispatch(fetchTravelerData());
      })
      .catch(error => {
        dispatch({
          type: BATCH_INVITE_TRAVELERS_TO_EVENT_FAILURE,
          payload: error.toString(),
        });
      });
  };
}

export function batchAddFormsToTravelers(values, dayOffset = null, fetchApps = true) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({ type: BATCH_ADD_FORMS_TO_TRAVELERS });
    fetch(`${keys.baseUri}/api/submission_batch_actions/add_forms`, {
      method: 'POST',
      headers: token,
      body: JSON.stringify(values),
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: BATCH_ADD_FORMS_TO_TRAVELERS_SUCCESS,
          payload: { data: data },
        });
        dispatch({
          type: BATCH_ADD_FORMS_TO_TRAVELERS_CLEAR,
        });
        if (fetchApps) {
          dispatch(fetchApplicationData(null, dayOffset));
        }
      })
      .catch(error => {
        dispatch({
          type: BATCH_ADD_FORMS_TO_TRAVELERS_FAILURE,
          payload: error.toString(),
        });
      });
  };
}

export function fetchEventsData() {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({ type: FETCH_EVENTS_REPORT_REQUEST });
    fetch(`${keys.baseUri}/api/events`, {
      method: 'GET',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: FETCH_EVENTS_REPORT_SUCCESS,
          payload: data,
        });
        dispatch({
          type: FETCH_EVENTS_REPORT_CLEAR,
        });
      })
      .catch(error => {
        dispatch({
          type: FETCH_EVENTS_REPORT_FAILURE,
          payload: error.toString(),
        });
      });
  };
}

export function fetchEventParticipantsData() {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({ type: FETCH_EVENT_PARTICIPANTS_REPORT_REQUEST });
    fetch(`${keys.baseUri}/api/event_travelers`, {
      method: 'GET',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: FETCH_EVENT_PARTICIPANTS_REPORT_SUCCESS,
          payload: data,
        });
        dispatch({
          type: FETCH_EVENT_PARTICIPANTS_REPORT_CLEAR,
        });
      })
      .catch(error => {
        dispatch({
          type: FETCH_EVENT_PARTICIPANTS_REPORT_FAILURE,
          payload: error.toString(),
        });
      });
  };
}

export function fetchTravelerPrograms() {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({ type: FETCH_TRAVELER_PROGRAMS_REQUEST });
    fetch(`${keys.baseUri}/api/programs`, {
      method: 'GET',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: FETCH_TRAVELER_PROGRAMS_SUCCESS,
          payload: data,
        });
      })
      .catch(error => {
        dispatch({
          type: FETCH_TRAVELER_PROGRAMS_FAILURE,
          payload: error.toString(),
        });
      });
  };
}

export function unassignSuitcases(values) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({ type: UNASSIGN_SUITCASES_REQUEST });
    fetch(`${keys.baseUri}/api/program_range_batch_actions/remove_suitcase`, {
      method: 'DELETE',
      headers: token,
      body: JSON.stringify(values),
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: UNASSIGN_SUITCASES_SUCCESS,
          payload: data,
        });
        dispatch({
          type: UNASSIGN_SUITCASES_CLEAR,
        });
        dispatch(fetchProgramTerms('authorized'));
      })
      .catch(error => {
        dispatch({
          type: UNASSIGN_SUITCASES_FAILURE,
          payload: error.toString(),
        });
      });
  };
}

export function fetchHorizonsBrownReport() {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({ type: FETCH_HORIZONS_BROWN_REQUEST });
    fetch(`${keys.baseUri}/api/brown_horizons_records`, {
      method: 'GET',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: FETCH_HORIZONS_BROWN_SUCCESS,
          payload: data,
        });
        dispatch({
          type: FETCH_HORIZONS_BROWN_CLEAR,
        });
      })
      .catch(error => {
        dispatch({
          type: FETCH_HORIZONS_BROWN_FAILURE,
          payload: error.toString(),
        });
      });
  };
}

export function fetchSFSUData() {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({ type: FETCH_SFSU_DATA_REQUEST });
    fetch(`${keys.baseUri}/api/sfsu_historic_records`, {
      method: 'GET',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: FETCH_SFSU_DATA_SUCCESS,
          payload: data,
        });
        dispatch({
          type: FETCH_SFSU_DATA_CLEAR,
        });
      })
      .catch(error => {
        dispatch({
          type: FETCH_SFSU_DATA_FAILURE,
          payload: error.toString(),
        });
      });
  };
}

export function getStandardReports() {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({ type: GET_STANDARD_REPORTS_REQUEST });
    fetch(`${keys.baseUri}/api/reports/standard_reports`, {
      method: 'GET',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: GET_STANDARD_REPORTS_SUCCESS,
          payload: data,
        });
      })
      .catch(error => {
        dispatch({
          type: GET_STANDARD_REPORTS_FAILURE,
          payload: error.toString(),
        });
      });
  };
}

export function getStandardReportNotifications() {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({ type: GET_STANDARD_REPORT_NOTIFICATION_REQUEST });
    fetch(`${keys.baseUri}/api/reports/standard_reports/notifications`, {
      method: 'GET',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: GET_STANDARD_REPORT_NOTIFICATION_SUCCESS,
          payload: data,
        });
      })
      .catch(error => {
        dispatch({
          type: GET_STANDARD_REPORT_NOTIFICATION_FAILURE,
          payload: error.toString(),
        });
      });
  };
}

export function deleteStandardReportDownload(id, callback = null) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({ type: DELETE_STANDARD_REPORT_REQUEST, payload: { id } });
    fetch(`${keys.baseUri}/api/reports/standard_reports/${id}`, {
      method: 'DELETE',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: DELETE_STANDARD_REPORT_SUCCESS,
          payload: data,
        });

        if (callback && typeof callback === 'function') {
          callback(true, 'Standard report deleted');
        }
      })
      .catch(error => {
        dispatch({
          type: DELETE_STANDARD_REPORT_FAILURE,
          payload: error.toString(),
        });

        if (callback && typeof callback === 'function') {
          callback(false, 'Something went wrong, please try again later');
        }
      });
  };
}

export function markReadNotification(id, callback = null) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({ type: MARK_READ_NOTIFICATION_REQUEST, payload: { id } });
    fetch(`${keys.baseUri}/api/reports/standard_reports/${id}/mark_as_read`, {
      method: 'PATCH',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: MARK_READ_NOTIFICATION_SUCCESS,
          payload: data,
        });

        if (callback && typeof callback === 'function') {
          callback(true, 'Standard report read');
        }
      })
      .catch(error => {
        dispatch({
          type: MARK_READ_NOTIFICATION_FAILURE,
          payload: error.toString(),
        });

        if (callback && typeof callback === 'function') {
          callback(false, 'Something went wrong, please try again later');
        }
      });
  };
}
export function markAllReadNotification(callback = null) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({ type: MARK_ALL_READ_NOTIFICATION_REQUEST });
    fetch(`${keys.baseUri}/api/reports/standard_reports/mark_all_as_read`, {
      method: 'PATCH',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: MARK_ALL_READ_NOTIFICATION_SUCCESS,
          payload: data,
        });

        if (callback && typeof callback === 'function') {
          callback(true, 'Standard report read');
        }
      })
      .catch(error => {
        dispatch({
          type: MARK_ALL_READ_NOTIFICATION_FAILURE,
          payload: error.toString(),
        });

        if (callback && typeof callback === 'function') {
          callback(false, 'Something went wrong, please try again later');
        }
      });
  };
}

export function getLiveReportFlag(callback = null) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({ type: GET_LIVE_REPORT_FLAG_REQUEST });
    fetch(`${keys.baseUri}/api/reports/standard_reports/standard_live_flag_info`, {
      method: 'GET',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: GET_LIVE_REPORT_FLAG_SUCCESS,
          payload: {
            flag: data?.standard_report_live,
          },
        });
      })
      .catch(error => {
        dispatch({
          type: GET_LIVE_REPORT_FLAG_FAILURE,
          payload: error.toString(),
        });
      });
  };
}

export function updateLiveReportFlag(flag, callback = null) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    let body = {
      standard_report: {
        standard_report_live: flag,
      },
    };

    dispatch({ type: UPDATE_LIVE_REPORT_FLAG_REQUEST });
    fetch(`${keys.baseUri}/api/reports/standard_reports/update_standard_live_flag`, {
      method: 'PATCH',
      headers: token,
      body: JSON.stringify(body),
    })
      .then(res => res.json())
      .then(data => {
        // dispatch(getLiveReportFlag());

        dispatch({
          type: UPDATE_LIVE_REPORT_FLAG_SUCCESS,
          payload: data,
        });

        if (callback && typeof callback === 'function') {
          callback(true, 'Standard report read');
        }
      })
      .catch(error => {
        dispatch({
          type: UPDATE_LIVE_REPORT_FLAG_FAILURE,
          payload: error.toString(),
        });

        if (callback && typeof callback === 'function') {
          callback(false, 'Something went wrong, please try again later');
        }
      });
  };
}

export function fetchExchangeBalance() {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({ type: FETCH_EXCHANGE_BALANCE_REQUEST });
    fetch(`${keys.baseUri}/api/client/exchange_balance_trackings`, {
      method: 'GET',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: FETCH_EXCHANGE_BALANCE_SUCCESS,
          payload: data,
        });
        dispatch({
          type: FETCH_EXCHANGE_BALANCE_CLEAR,
        });
      })
      .catch(error => {
        dispatch({
          type: FETCH_EXCHANGE_BALANCE_FAILURE,
          payload: error.toString(),
        });
      });
  };
}

export function fetchBalanceValue(contractID, orgID) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({ type: FETCH_BALANCE_VALUE_REQUEST });
    fetch(`${keys.baseUri}/api/client/exchange_balance_trackings/${contractID}/balance_values?org_guid=${orgID}`, {
      method: 'GET',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: FETCH_BALANCE_VALUE_SUCCESS,
          payload: data,
        });
        dispatch({
          type: FETCH_BALANCE_VALUE_CLEAR,
        });
      })
      .catch(error => {
        dispatch({
          type: FETCH_BALANCE_VALUE_FAILURE,
          payload: error.toString(),
        });
      });
  };
}

export function updateExchangeBalance(values, id) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({ type: UPDATE_EXCHANGE_BALANCE_REQUEST });
    fetch(`${keys.baseUri}/api/client/exchange_balance_trackings/${id}`, {
      method: 'PATCH',
      headers: token,
      body: JSON.stringify(values),
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: UPDATE_EXCHANGE_BALANCE_SUCCESS,
          payload: { data: data },
        });
        dispatch({
          type: UPDATE_EXCHANGE_BALANCE_CLEAR,
        });
        //dispatch(fetchExchangeBalance());
      })
      .catch(error => {
        dispatch({
          type: UPDATE_EXCHANGE_BALANCE_FAILURE,
          payload: error.toString(),
        });
      });
  };
}
