import React from 'react';
import propTypes from 'prop-types';

function SvgFooterLandscape(props) {
  // https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/preserveAspectRatio
  return (
    <svg
      version="1.1"
      id="footerLandscape"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 3873 481.9"
      style={{ enableBackground: 'new 0 0 3873 481.9' }}
      width="100%"
      height="100%"
      preserveAspectRatio="xMaxYMid slice"
    >
      <style>{`.st0{fill:#446372;} .st1{fill:${props.color};}`}</style>
      <metadata />
      <path
        className="st0"
        d="M2820.4,276.9h7.9c0.5,0,1-0.4,1-1s-0.4-1-1-1h-7.9c-0.6,0-1,0.4-1,1S2819.8,276.9,2820.4,276.9z"
      />
      <path
        className="st0"
        d="M2851.9,276.9h7.9c0.6,0,1-0.4,1-1s-0.4-1-1-1h-7.9c-0.5,0-1,0.4-1,1S2851.3,276.9,2851.9,276.9z"
      />
      <path
        className="st0"
        d="M2804.6,276.9h7.9c0.6,0,1-0.4,1-1s-0.4-1-1-1h-7.9c-0.6,0-1,0.4-1,1S2804.1,276.9,2804.6,276.9z"
      />
      <path
        className="st0"
        d="M3025.1,276.9h7.9c0.5,0,1-0.4,1-1s-0.4-1-1-1h-7.9c-0.6,0-1,0.4-1,1S3024.5,276.9,3025.1,276.9z"
      />
      <path
        className="st0"
        d="M2836.1,276.9h7.9c0.5,0,1-0.4,1-1s-0.4-1-1-1h-7.9c-0.5,0-1,0.4-1,1S2835.6,276.9,2836.1,276.9z"
      />
      <path
        className="st0"
        d="M2914.9,276.9h7.9c0.5,0,1-0.4,1-1s-0.4-1-1-1h-7.9c-0.6,0-1,0.4-1,1S2914.3,276.9,2914.9,276.9z"
      />
      <path
        className="st0"
        d="M2962.1,276.9h7.9c0.6,0,1-0.4,1-1s-0.4-1-1-1h-7.9c-0.6,0-1,0.4-1,1S2961.5,276.9,2962.1,276.9z"
      />
      <path
        className="st0"
        d="M2977.8,276.9h7.9c0.6,0,1-0.4,1-1s-0.4-1-1-1h-7.9c-0.6,0-1,0.4-1,1S2977.3,276.9,2977.8,276.9z"
      />
      <path
        className="st0"
        d="M2946.4,276.9h7.9c0.5,0,1-0.4,1-1s-0.4-1-1-1h-7.9c-0.5,0-1,0.4-1,1S2945.8,276.9,2946.4,276.9z"
      />
      <path
        className="st0"
        d="M2930.6,276.9h7.9c0.5,0,1-0.4,1-1s-0.4-1-1-1h-7.9c-0.5,0-1,0.4-1,1S2930.1,276.9,2930.6,276.9z"
      />
      <path
        className="st0"
        d="M2867.6,276.9h7.9c0.6,0,1-0.4,1-1s-0.4-1-1-1h-7.9c-0.6,0-1,0.4-1,1S2867.1,276.9,2867.6,276.9z"
      />
      <path
        className="st0"
        d="M2899.1,276.9h7.9c0.6,0,1-0.4,1-1s-0.4-1-1-1h-7.9c-0.6,0-1,0.4-1,1S2898.6,276.9,2899.1,276.9z"
      />
      <path
        className="st0"
        d="M2883.4,276.9h7.9c0.6,0,1-0.4,1-1s-0.4-1-1-1h-7.9c-0.6,0-1,0.4-1,1S2882.8,276.9,2883.4,276.9z"
      />
      <path
        className="st0"
        d="M2993.6,276.9h7.9c0.6,0,1-0.4,1-1s-0.4-1-1-1h-7.9c-0.6,0-1,0.4-1,1S2993,276.9,2993.6,276.9z"
      />
      <path
        className="st0"
        d="M3009.3,276.9h7.9c0.5,0,1-0.4,1-1s-0.4-1-1-1h-7.9c-0.5,0-1,0.4-1,1S3008.8,276.9,3009.3,276.9z"
      />
      <path
        className="st0"
        d="M3017.3,283.9v5.8c-0.4,0.2-0.6,0.6-0.6,1c0,0.4,0.2,0.8,0.6,1v2.4c-0.7,0.3-1.2,0.9-1.2,1.6
        c0,0.7,0.5,1.3,1.2,1.6v0.8c-0.4,0.3-0.7,0.6-0.7,1.1c0,0.3,0.1,0.5,0.3,0.7c-2,2.2-8.3,8.5-15.7,11.8c-3.9,1.8-16.1,8.7-21,18.8
        c-4.2,8.6-1.4,14.5-0.8,16.6v0.2h77.8V347c0.6-2.1,3.4-8-0.8-16.6c-5-10.1-17.2-17.1-21-18.8c-7.3-3.3-13.7-9.6-15.7-11.8
        c0.2-0.2,0.3-0.4,0.3-0.7c0-0.4-0.3-0.8-0.7-1.1v-0.8c0.7-0.3,1.2-0.9,1.2-1.6c0-0.7-0.5-1.3-1.2-1.6v-2.4c0.4-0.2,0.6-0.6,0.6-1
        c0-0.4-0.2-0.8-0.6-1v-5.8c0,0-0.2-0.5-1-0.5c0,0,0,0-0.1,0c0,0,0,0,0,0C3017.5,283.4,3017.3,283.9,3017.3,283.9z"
      />
      <path
        className="st0"
        d="M1776.9,435.2h18.2c0.1-6.5,5.4-11.7,11.9-11.7c6.5,0,11.8,5.2,11.9,11.7h67.4c0.1-6.5,5.4-11.7,11.9-11.7
        c6.5,0,11.8,5.2,11.9,11.7h4.5l0.1-18.9l-153.2-0.1v4h12.2c1.8,0,3.3,1.5,3.3,3.3L1776.9,435.2
        C1776.9,435.2,1776.9,435.2,1776.9,435.2z"
      />
      <path
        className="st0"
        d="M1761.4,422.6h-1.7c-0.5,0-0.9,0.4-0.9,0.9v3.3c0,7.1,6.9,9,9.6,9.2h5.2c0.5,0,0.9-0.4,0.9-0.8c0,0,0,0,0-0.1
        v-11.6c0-0.5-0.4-0.9-0.9-0.9H1761.4z"
      />
      <path
        className="st0"
        d="M1933.9,423.5v11.6c0,0.5,0.4,0.9,0.9,0.9h5.2c2.7-0.1,9.6-2.1,9.6-9.2v-3.3c0-0.5-0.4-0.9-0.9-0.9h-2h-11.9
        C1934.3,422.6,1933.9,423,1933.9,423.5z"
      />
      <path
        className="st0"
        d="M1607.4,175.3h8c0.6,0,1-0.4,1-1s-0.4-1-1-1h-8c-0.6,0-1,0.4-1,1S1606.9,175.3,1607.4,175.3z"
      />
      <path
        className="st0"
        d="M1623.4,175.3h8c0.6,0,1-0.4,1-1s-0.4-1-1-1h-8c-0.6,0-1,0.4-1,1S1622.9,175.3,1623.4,175.3z"
      />
      <path
        className="st0"
        d="M1639.5,175.3h8c0.5,0,1-0.4,1-1s-0.4-1-1-1h-8c-0.6,0-1,0.4-1,1S1638.9,175.3,1639.5,175.3z"
      />
      <path className="st0" d="M1967.3,362.4c0,0.1,0.1,0.2,0.3,0.2H2178c0.2,0,0.3-0.1,0.3-0.2v-4h-211V362.4z" />
      <path
        className="st0"
        d="M3040.8,276.9h7.9c0.5,0,1-0.4,1-1s-0.4-1-1-1h-7.9c-0.6,0-1,0.4-1,1S3040.3,276.9,3040.8,276.9z"
      />
      <path
        className="st0"
        d="M1671.5,175.3h8c0.6,0,1-0.4,1-1s-0.4-1-1-1h-8c-0.6,0-1,0.4-1,1S1671,175.3,1671.5,175.3z"
      />
      <path
        className="st0"
        d="M1687.6,175.3h4c0.6,0,1-0.4,1-1s-0.4-1-1-1h-4c-0.6,0-1,0.4-1,1S1687,175.3,1687.6,175.3z"
      />
      <path
        className="st0"
        d="M1655.5,175.3h8c0.6,0,1-0.4,1-1s-0.4-1-1-1h-8c-0.6,0-1,0.4-1,1S1655,175.3,1655.5,175.3z"
      />
      <path
        className="st0"
        d="M2038.9,325h2.8v15.2h62.3v-12.3h-0.1V325h0.1V325h2.8c0,0,0.1-0.1,0.1-0.2v-3.2c0-0.1-0.1-0.2-0.3-0.2H2092
        c-0.2-0.3-0.5-0.6-0.5-0.9c-0.1-0.4-0.1-0.5-0.2-0.6c0,0,0-0.1-0.1-0.3c-0.1-0.5,0.1-1,0.2-1.5l0-0.1c0.1-0.4,0.1-0.6,0.1-0.9
        c0-0.1,0-0.2,0-0.4c0-0.4,0-1.5,0.2-1.7c0,0,0.1-0.1,0.7-0.1c0.3,0,0.7,0,1,0.1c0.1,0,0.3,0,0.4,0l0.2,0c0.4,0,0.4,0,0.6,0.2
        c0.3,0.2,0.6,0.6,0.7,0.8c0.1,0.2,0.2,1.3-0.1,1.8c-0.1,0.3-0.2,0.4-0.3,0.5c-0.1,0.1-0.2,0.3-0.3,0.5c-0.2,0.5-0.1,0.9,0.3,1.2
        c0.1,0.1,0.3,0.2,0.4,0.2c0.2,0,0.3-0.1,0.4-0.1c0,0,0.1-0.1,0.2-0.1c0.5-0.2,0.5-0.5,0.5-0.9c0-0.1,0-0.3,0-0.6
        c0-0.5,0.1-1.3,0.1-1.5c0-0.2,0.4-1.9-0.1-2.4c-0.1-0.1-0.3-0.2-0.4-0.4c-0.3-0.2-0.5-0.3-0.6-0.6c-0.1-0.2,0.1-0.7,0.2-1.1
        c0.2-0.7,0.5-1.6,0.2-2.2c-0.2-0.6-0.5-1-0.7-1.4c-0.1-0.2-0.2-0.4-0.2-0.5c0,0,0,0,0-0.1c0,0,0,0,0,0l0.2,0.1
        c0.3,0.2,0.5,0.3,1.1,0.2c0.5-0.1,0.6-0.3,0.6-0.5c0-0.1,0-0.2,0.1-0.2c0.4-0.4,0.3-0.8,0.1-1.2c0-0.1,0-0.2-0.1-0.3
        c-0.1-0.3,0-0.7,0.1-1c0.1-0.3,0.1-0.5,0.1-0.8c0-0.4-0.1-0.9-0.1-1.3c0.3-0.2,0.5-0.6,0.4-0.9c-0.1-0.2-0.3-0.4-0.5-0.4
        c-0.2,0-0.5,0.1-0.7,0.2c-0.1,0-0.1,0-0.2-0.1c-0.1-0.4-0.6-0.5-1.2-0.1c-1.1,0.1-1.7,0.7-2.1,1.2l-0.1,0.1
        c-0.3,0.3-0.7,1.4-1.3,2.9c-0.2,0.6-0.5,1.2-0.5,1.4c-0.1,0.1-0.1,0.2-0.1,0.3c-0.1,0.3-0.2,0.6-0.9,0.6c-3,0.3-3.5,0.6-3.9,1.1
        c0,0-0.1,0.1-0.1,0.1c-0.6,0.7-0.5,2.6-0.3,3c0.1,0.3,0.4,0.7,0.6,1.2c0.3,0.5,0.6,1.1,0.7,1.3c0.1,0.2-0.2,1.4-0.4,2.1
        c-0.1,0.6-0.2,1.1-0.3,1.3c0,0.6,0.6,1.3,1.3,2c0.2,0.2,0.3,0.4,0.4,0.6h-3.9l-0.4-12.1c0-0.1-0.1-0.2-0.3-0.2l-1.3,0
        c-0.6-0.9-1-1.9-0.9-2.1c0.1-0.1,0.1-0.2,0.2-0.3c0.2-0.3,0.4-0.6,0.3-1.2c0-0.1,0-0.2-0.1-0.3c-0.1-0.4-0.2-0.9,0-1.1
        c0.3-0.3,0.1-1.2,0-1.6c-0.2-0.4-0.4-0.9-0.9-1c-0.5-0.1-1-0.1-1.5,0c-0.1-0.4-0.1-1.4-0.2-2.2c-0.1-2.1-0.2-3.1-0.4-3.3l-0.1-0.2
        c-0.3-0.5-0.7-1-1.3-1.2c-0.1,0-0.2-0.1-0.4-0.1c-0.6,0-1.1,0.6-1.1,1.1l0,0.2c0,0.5,0,1-0.4,1.1c-0.5,0.1-1-0.4-1-0.8
        c0-0.3,0.2-0.6,0.4-0.9c0.1-0.2,0.3-0.4,0.3-0.6l0-0.1c0.1-0.3,0.3-0.7,0.1-1c-0.1-0.2-0.4-0.3-0.8-0.4c-0.3,0-0.7,0-1,0
        c-0.7,0-1.2,0.1-1.6,0.2v-2.9c1.7-0.3,3-1.5,3-3.1c0-1.4-1-2.6-2.6-3l0-0.1c0.1-0.1,0.1-0.2,0.1-0.3c0.1,0,0.3-0.1,0.5-0.1
        c0.6-0.1,1.4-0.3,1.6-0.9c0-0.2,0.5-0.6,0.9-0.9c0.9-0.8,2.2-1.9,1.8-3.1c0-0.2-0.2-0.3-0.4-0.3c-0.3,0-0.5,0.2-0.9,0.4
        c-0.1,0-0.1,0.1-0.2,0.1c0,0-0.1,0.1-0.2,0.1c-1.2,0.5-2.6,1.1-2.9,1.6c-0.1,0.1-0.1,0.3-0.2,0.4c-0.1,0.2-0.1,0.3-0.3,0.4
        c0-0.2,0-0.4-0.1-0.5c0,0,0-0.1,0-0.1c0.1-0.3,0.2-0.7-0.1-1c-0.2-0.2-0.4-0.2-0.7-0.2c-0.3,0-0.5,0.1-0.7,0.2
        c-0.3,0.3-0.2,0.6-0.1,0.9c0,0.1,0,0.2,0.1,0.2c0,0.1-0.1,0.3-0.1,0.5c-0.2,0-0.2-0.1-0.3-0.4c0-0.1-0.1-0.3-0.2-0.4
        c-0.4-0.6-1.8-1.2-2.9-1.7c-0.1,0-0.2-0.1-0.2-0.1c0,0-0.1,0-0.2-0.1c-0.4-0.2-0.7-0.4-0.9-0.4c-0.2,0-0.3,0.1-0.4,0.2
        c-0.4,1.2,0.9,2.3,1.8,3.1c0.4,0.4,0.8,0.7,0.9,0.9c0.2,0.6,1,0.8,1.6,0.9c0.2,0,0.4,0.1,0.4,0.1c0,0.1,0.1,0.2,0.1,0.3l0,0.1
        c-1.5,0.4-2.6,1.6-2.6,3c0,1.5,1.3,2.8,3,3.1v3.7c-0.1,0.1-0.2,0.1-0.3,0.2c-0.2,0.2-0.4,0.3-0.5,0.4c-0.1-0.2-0.4-0.4-0.9-0.3
        l-0.2,0c-0.6,0.1-1.3,0.3-1.4,1c-0.1,0.8-0.4,3.9,0.2,5.4c0.5,1.3,0.5,3.5,0.5,4.3c-0.1,0.1-0.3,0.2-0.5,0.3c-0.7,0.4-1.7,1-2.2,1.7
        c0-0.4,0-0.8,0-1c0-0.1,0-0.3,0-0.3c0-0.1,0.1-0.3,0.1-0.5c0.2-0.4,0.4-1,0.2-1.5l-0.1-0.2c-0.2-0.6-0.3-0.8-0.9-1.2
        c-0.1-0.1-0.4-0.2-0.8-0.2c-0.4,0-0.7,0.1-1,0.3c-0.6,0.5-0.7,1-0.8,1.5c0,0.2,0,0.5,0,0.9c0,0.4,0,1-0.1,1.1
        c-0.1,0.1-0.1,0.3-0.2,1.1c-0.1,0.6-0.2,2-0.5,2.2c-0.4,0.3-1.6,1.6-1.4,3.1c0.2,1.1,0.6,2.3,0.9,3c0,0.1,0.1,0.2,0.1,0.3
        c0.1,0.2,0.1,0.6,0.1,1c0,0.5,0.1,1,0.2,1.3c0.2,0.5,0.3,1.9,0.4,2.6l0,0.2c0,0.3,0.1,0.5,0.1,0.7h-5.2c0.1-0.2,0.2-0.4,0.4-0.6
        c0.7-0.7,1.3-1.3,1.3-2c0-0.2-0.1-0.7-0.3-1.3c-0.2-0.7-0.4-1.9-0.4-2.1c0.1-0.2,0.4-0.8,0.6-1.3c0.3-0.5,0.5-0.9,0.6-1.2
        c0.2-0.5,0.3-2.4-0.3-3c0,0-0.1-0.1-0.1-0.1c-0.4-0.5-0.8-0.8-3.9-1.1c-0.7-0.1-0.8-0.4-0.9-0.6c0-0.1-0.1-0.2-0.1-0.3
        c-0.1-0.1-0.3-0.8-0.5-1.3c-0.6-1.6-1-2.7-1.3-2.9l-0.1-0.1c-0.4-0.5-1.1-1.1-2.3-1.2l-0.2,0c-0.6,0-1,0.1-1.3,0.3
        c-0.5-0.2-0.9-0.1-1,0.3c-0.1,0.3,0,0.6,0.3,0.9c-0.1,0.4-0.1,0.8-0.1,1.1c0,0.2,0.1,0.5,0.1,0.8c0.1,0.4,0.2,0.8,0.1,1
        c0,0.1,0,0.2-0.1,0.3c-0.1,0.4-0.2,0.9,0.1,1.2c0.1,0.1,0.1,0.2,0.1,0.2c0.1,0.2,0.2,0.4,0.6,0.5c0.2,0,0.3,0,0.4,0
        c0.3,0,0.5-0.1,0.7-0.2l0.2-0.1c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0.1-0.1,0.3-0.2,0.5c-0.2,0.3-0.4,0.8-0.7,1.4c-0.2,0.6,0,1.4,0.2,2.2
        c0.1,0.4,0.3,0.9,0.2,1.1c-0.1,0.3-0.4,0.4-0.6,0.6c-0.2,0.1-0.3,0.2-0.4,0.4c-0.4,0.5-0.1,2.2-0.1,2.4c0.1,0.2,0.1,1,0.1,1.5
        c0,0.3,0,0.5,0,0.7c0,0.4,0.1,0.7,0.5,0.9c0.1,0,0.1,0.1,0.2,0.1c0.1,0.1,0.2,0.1,0.4,0.1c0.2,0,0.3-0.1,0.5-0.2
        c0.4-0.3,0.5-0.7,0.3-1.2c-0.1-0.3-0.2-0.4-0.3-0.5c-0.1-0.1-0.2-0.3-0.3-0.5c-0.2-0.5-0.2-1.7-0.1-1.8c0.1-0.2,0.4-0.6,0.7-0.8
        c0.3-0.2,0.3-0.2,0.6-0.2l0.2,0c0.1,0,0.2,0,0.4,0c0.3,0,0.6-0.1,1-0.1c0.6,0,0.7,0.1,0.7,0.1c0.2,0.2,0.2,1.3,0.2,1.7
        c0,0.2,0,0.3,0,0.4c0,0.3,0,0.4,0.1,0.9l0,0.1c0.1,0.5,0.3,1.1,0.2,1.5c0,0.2-0.1,0.3-0.1,0.3c-0.1,0.1-0.1,0.2-0.2,0.6
        c-0.1,0.3-0.3,0.6-0.5,0.9H2039c-0.2,0-0.3,0.1-0.3,0.2v3.2C2038.8,324.9,2038.8,325,2038.9,325z M2082.1,317.2
        C2082.1,317.2,2082.1,317.2,2082.1,317.2c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0,0.2,0,0.3c0,0.2,0.2,2.1,0.2,3.7h-0.2
        C2082,319.5,2081.9,317.5,2082.1,317.2z M2078.3,318.9c0.1-0.1,0.1-0.4,0.2-0.8c0-0.2,0.1-0.4,0.1-0.6c0.1,0.2,0.2,0.7,0.3,1.5
        c0,0.4-0.1,1.5-0.2,2.3h-0.4C2078.3,320.2,2078.3,319.1,2078.3,318.9z M2068.5,317.4c0.2,0,0.5,0,0.8,0c0.5,0,1.1,0.1,1.3,0
        c0.3,0,1.2,0,2.1,0c0.9,0,1.7,0,1.9,0c0.2,0,0.7-0.1,1.2-0.1c-0.3,0.4-0.5,0.8-0.2,1.1c0.3,0.3,0.6,0.6,1.2,0.6
        c0.1,0.4-0.1,1.6-0.2,2.1c0,0.1,0,0.2,0,0.2h-8.1c0.1-1,0.2-2.8,0.2-3.1C2068.5,318,2068.5,317.6,2068.5,317.4z M2063.9,319.1
        c0.1-0.2,0.1-0.6,0-1.1c0-0.3,0-0.7,0-0.9c0,0.1,0.4,0.1,0.4,0.3c0,0.1,0,0.7,0.1,0.9c0,0.7,0,1.2,0.1,1.6c0.1,0.1,0,0.8,0.4,1
        l0.1,0c0.4,0.1,0.9,0.2,0.9-0.2c0-0.6,0.1-1.7-0.1-2.1c-0.2-0.4,0.1-1.3,0.1-1.6c0.1,0,0.3,0,0.5,0c0.1,0.7,0.3,3.3,0.2,4l-0.1,0.4
        h-2.6C2063.9,320.5,2063.8,319.4,2063.9,319.1z"
      />
      <path
        className="st0"
        d="M2574.5,427.5h71.4v-9.1h-4.4l-2.4-24.1c0,0,1.8-1.5,2.3-1.6c0.5-0.1,0.5-0.7,0.9-1.2
        c0.4-0.5-1.3-15.1-1.1-15.1c0.2-0.1,0.8-0.5,1-0.9c0.2-0.3-0.7-2.2-0.4-2.3c0.3-0.1,1.5-0.7,1.9-1.2c0.4-0.5-1.1-6.3-0.3-6.6
        c0.7-0.2,1.3-1.6,1.4-2c-1.6-0.4-3.1-0.8-3.8-0.7c-0.3,0-0.4,0-0.7,0.9c-0.6,1.5-2.5,4.7-7.9,3.1c-1.3-0.4-1.6-1.3-1.7-1.7
        c0-0.1,0-0.2,0-0.3c-8.4,4.8-12.3,7-14,8.1c1.4,1,3.7,2.6,6.2,4.3c0.4,0.3,1.8,1.3,1.6,2.6c0,0.3-0.3,1.1-1.3,1.4
        c-1.4,0.5-20.3,9-22.8,11.8c-1.2,1.3-1.3,2.3-1.5,3.3c-0.1,1.1-0.3,2.3-1.7,3.4c-0.6,0.5-1.5,1.5-2.5,2.7c-2.7,3.1-6.3,7.2-9.7,8.2
        c-0.4,0.1-0.9,0.2-1.3,0.3c-0.1,1.4-0.2,2.3-0.2,2.5c-0.2,1-0.8,2.8-2.9,3.5c-1,0.3-1.8,1-2.3,1.7h-3.9V427.5z M2634.8,368.4
        c0-0.6,0.5-1.1,1.1-1.1c0.6,0,1.1,0.5,1.1,1.1c0,1.8-1.2,44.6-1.4,47.9c0,0.6-0.5,1-1.1,1c0,0,0,0,0,0c-0.6,0-1.1-0.5-1-1.1
        C2633.5,413,2634.8,368.8,2634.8,368.4z M2628.4,376.8c0-0.6,0.5-1.1,1.1-1.1c0.6,0,1.1,0.5,1.1,1.1c0,1.4-0.5,29.3-2.3,41.6h-2.2
        C2627.6,408.7,2628.2,386.1,2628.4,376.8z M2621.8,383.6c0.1-0.6,0.6-1,1.2-1c0.6,0.1,1,0.6,1,1.2l-2.7,25.9c-0.1,0.6-0.5,1-1.1,1
        c0,0-0.1,0-0.1,0c-0.6-0.1-1-0.6-1-1.2L2621.8,383.6z M2605.9,411.4c0.4-0.9,0.9-7.9,1.2-12.4c0.2-2.3,0.3-4.6,0.5-6.2
        c0.3-2.7,0.3-3.4,1.1-3.7c0.6-0.2,1.2,0.1,1.4,0.7c0.1,0.3,0.1,0.6-0.1,0.9c-0.2,0.8-0.5,4.9-0.8,8.5c-0.5,7.1-0.9,11.9-1.4,13.1
        c-0.5,1.3-2.3,3.9-4.2,6.3h-2.9C2603.2,415.9,2605.5,412.6,2605.9,411.4z"
      />
      <path
        className="st0"
        d="M2620.4,267c-1.9,3.4-4.7,7.7-8.7,11.6c-2.3,2.3-4.6,4.1-6.7,5.8c-4.9,3.9-9.6,7.6-15.2,17.6
        c-1.9,3.5-4.1,8.5-5.6,12.8c0,0,0,0,0,0s-2.3,9.7-3.1,12.2c-0.8,2.6,0.8,8,0.8,8s1.2,3.7,2.3,8.2c9.1-29.7,12.6-37.3,17.7-48.6
        c0.7-1.6,1.5-3.2,2.3-5c0.2-0.5,0.9-0.8,1.4-0.6c0.6,0.2,0.8,0.9,0.6,1.4c-0.8,1.8-1.6,3.4-2.3,5c-5.3,11.7-8.8,19.4-18.8,52.1
        c0.7,4.1,1,8,0,10.1c-0.8,1.6-1.3,5.3-1.5,9.8c5.5-2.9,7.4-5.3,8.7-7.3c0.2-0.4,0.5-0.9,0.8-1.4c1.3-2.4,3.4-6.4,7.6-9.7
        c4.8-3.9,10-11.8,10.1-11.9c0.3-0.5,1-0.6,1.5-0.3c0.5,0.3,0.7,1,0.3,1.5c-0.2,0.3-5.5,8.3-10.5,12.4c-3.8,3.1-5.8,6.7-7,9.1
        c-0.3,0.6-0.6,1.1-0.9,1.6c-1.4,2.3-3.7,5.1-9.9,8.3c-0.3,0.1-0.5,0.3-0.8,0.4c-0.4,9.7,0.1,21.6,0.6,23.2c0.6,1.7,0.2,9.8-0.1,15.2
        c0.2,0,0.3-0.1,0.5-0.1c2.7-0.8,6.1-4.7,8.6-7.5c1.1-1.3,2-2.3,2.8-3c0.7-0.6,0.8-1.1,0.9-2.1c0.2-1.1,0.4-2.6,2-4.5
        c2.8-3.1,19.8-10.7,23.2-12.2c-0.1-0.1-0.2-0.2-0.3-0.3c-4.1-2.7-7.6-5.3-7.6-5.3c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0,0,0
        c0,0,0,0-0.1-0.1c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0-0.1-0.1-0.1-0.2c0,0,0,0,0,0c0-0.1,0-0.1-0.1-0.2c0,0,0,0,0,0
        c0-0.1,0-0.1,0-0.2c0,0,0,0,0,0c0,0,0,0,0,0l3-60.3c-0.9-0.5-1.2-1.7-1.4-2.6c-0.7-3.5,2-17.9,2.3-19.6
        C2618.4,286.1,2619.7,274.3,2620.4,267z"
      />
      <path
        className="st0"
        d="M2578.6,311.3c0.3,2.3,1.9,3.1,3.2,3.4c1.6-4.5,3.8-10,6-13.8c5.8-10.4,10.9-14.4,15.8-18.3
        c2.1-1.7,4.3-3.4,6.5-5.6c5.9-5.9,9.2-12.6,10.5-15.4c-0.7-0.1-1.4-0.1-2.1-0.2c-0.5,0-1-0.1-1.5-0.1c-0.6,0.2-5.6,2.1-12.1,7
        c-6,4.5-14.4,12.5-21.8,25.6c-1.6,4.4-3.8,10.2-3.9,10.5C2579,304.8,2578,307.1,2578.6,311.3z"
      />
      <path
        className="st0"
        d="M1964.6,346.4l4.4,2.8v6.3h207.6v-6.3l4.4-2.8c0.1,0,0.1-0.1,0.1-0.2V343c0-0.1-0.1-0.2-0.3-0.2h-0.5v0.3
        h-215.8v3C1964.5,346.2,1964.5,346.3,1964.6,346.4z"
      />
      <path
        className="st0"
        d="M2614.5,247.3c0.7-0.9,2.3-7.7,1.6-9.5c-0.5-1.2-1.8-2-3.2-2.8c-2-1.2-3.2-1.9-3.8-2.3
        c-0.6,0.4-1.7,1.1-3.8,2.3c-1.4,0.8-2.7,1.6-3.2,2.8c-0.7,1.8,0.8,8.6,1.6,9.5c0.5,0.6,2.6,1.8,5.5,1.8
        C2611.6,249.1,2614,247.8,2614.5,247.3z"
      />
      <path
        className="st0"
        d="M2622.4,248.6c0-0.1-1-7-1.5-10.9c-0.5-2.1-1.7-5.3-3.8-7c-3.5-2.8-6.3-2.7-8.6-2.6c-4.1,0.1-6.8,1.3-8.7,4.6
        c-0.7,1.2-1.5,3.1-1.9,4.1c-0.3,1.2-1.1,4.3-1.7,6.8c-1.1,4.1-6.2,22.6-16,32.4c-0.4,1-0.9,2.6-0.9,3.5c-0.1,1.4,0.8,1.5,2.1,3
        c0.9,1,2.1,3.5,2.8,5c6.8-10.4,14-17.1,19.3-21.1c3.6-2.7,6.7-4.5,8.9-5.6c-0.5-0.1-1-0.1-1.5-0.2c-6.3-1-9.1-5-9.2-5.2
        c-0.1-0.2-0.2-0.4-0.2-0.6v-6.5c-1.3-2.1-2.7-9-1.7-11.4c0.8-1.9,2.5-3,4.1-3.9c3.4-2,4-2.4,4.1-2.5c0.2-0.2,0.5-0.4,0.8-0.4
        c0.3,0,0.6,0.1,0.9,0.4c0.2,0.2,1.1,0.8,4.1,2.5c1.7,1,3.4,2,4.1,3.9c1,2.6-0.7,10.4-2,11.8c-0.9,1-3.9,2.5-6.9,2.5
        c-2.1,0-3.9-0.5-5.3-1.2v4.4c0.7,0.8,3,3.3,7.6,4c2,0.3,4.6,0.5,7.3,0.8c2.4,0.2,4.8,0.4,7,0.7c0-0.1,0-0.4-0.2-0.9
        c-0.4-0.8-1.6-1.1-1.6-1.1s-3.1-7.1-2.3-7.7C2622.4,249.7,2622.5,248.8,2622.4,248.6z"
      />
      <path
        className="st0"
        d="M2628.2,262.5c-1.6-0.3-3.3-0.5-5.1-0.7c-0.4,4.3-2.3,23.2-3.3,28.7c-1.5,7.7-2.7,16.6-2.3,18.7
        c0.1,0.5,0.2,0.7,0.3,0.9c0.2,0,0.4,0,0.6,0.1c0.4,0.2,0.6,0.6,0.6,1l-2.9,59.4c2.4-1.4,7.2-4.1,14.2-8.1c4.2-2.4,7.7-4.4,8.1-4.6
        c0,0,0,0,0,0c0.5-0.3,1.2-0.1,1.5,0.4c0.3,0.5,0.1,1.2-0.4,1.5c0,0,0,0-0.1,0.1c-2.9,1.7-5.6,3.7-6.1,4.7c0,0,0,0,0.1,0
        c2.7,0.8,4.4,0.2,5.2-1.8c1.1-2.7,2.9-2.6,6.4-1.6l-2.9-30.3c0,0,0.1,0,0.1-0.1l-4.2-37.2c-0.1-0.6,0.4-1.1,1-1.2
        c0.6-0.1,1.1,0.4,1.2,1l3.9,35.1c0.1-0.1,0.1-0.2,0.1-0.2s0.9-0.1,1.3-0.2c0.3-0.1,0.9,0,2.4-0.8c1.6-0.8,2.3-6.9,3.1-8.8
        c0.7-1.9,0.9-9.7,0.9-9.7s0,0,1.1,0.1c1.2,0.1,2.6-0.9,3.6-1.6c0.9-0.7,4.6-8.1,4.6-8.1s0.9-1.4,1.4-1.9c0.4-0.5,0.3-1.7,0.3-1.7
        l-2-2.9l2.7-5l-0.6-0.9l1.8-1.7l-2-1.3l-13-6.1l-0.4,2.4l-1.4-0.8l-3.8,7.1c0,0-0.5,0.1-0.9-0.6c-0.3-0.7-4.5-3.3-5.6-4.2
        c-1-0.9-1.7-5.1-3.7-12.1C2632.7,265.2,2630.1,263.3,2628.2,262.5z M2658.2,289.7l1.4-2.3c0.3-0.5,1-0.7,1.5-0.4
        c0.5,0.3,0.7,1,0.4,1.5l-1.4,2.3c-0.2,0.3-0.6,0.5-0.9,0.5c-0.2,0-0.4,0-0.6-0.1C2658.1,290.9,2657.9,290.2,2658.2,289.7z
         M2644.1,313.8l7-12.6c0.3-0.5,1-0.7,1.5-0.4c0.5,0.3,0.7,1,0.4,1.5l-7,12.6c-0.2,0.4-0.6,0.6-1,0.6c-0.2,0-0.4,0-0.5-0.1
        C2644,315,2643.8,314.3,2644.1,313.8z"
      />
      <path
        className="st0"
        d="M2578.6,171.9c0.6,0.1,1,0.6,1,1.2c-0.1,0.6-0.6,1-1.2,1c-8.1-0.8-11.3,0.7-12.5,1.6c0.3,0.5,1.2,0.9,2.5,1.3
        c0,0,0,0,0,0.1c-0.2,1,2.1,4.4,2.1,4.4s-1,0.2-1.3,1.3c-0.2,1.1,0.7,2,0.7,2s-0.5,0-0.4,1.5c0.1,1.5,1.5,1.2,1.1,1.4
        c-0.4,0.2-0.6-0.3-0.4,2.5c0.2,2.8,2.5,4.4,2.2,5.1c-0.3,0.7-0.7,2-0.7,2.8c0,0.8,1,1.7,1,1.8c0,0.2,0.2,1.5,0.3,2.1
        c0,0.2,0.1,0.4,0.3,0.7c0.1,0.1,0.2,0.1,0.2,0.2l0.1,0.1c0.6,0.6,0.8,0.9,1.6,0.9c0.3,0,0.6-0.2,0.8-0.3c0.5-0.4,0.8-1.1,0.8-1.8
        c0.1-1.6-0.1-11-0.1-11.1c0-0.6,0.5-1.1,1.1-1.1c0,0,0,0,0,0c0.6,0,1.1,0.5,1.1,1.1c0,0.4,0.1,9.5,0.1,11.2c0,1.2-0.6,2.5-1.5,3.3
        c-0.6,0.6-1.4,0.9-2.2,0.9c0,0-0.1,0-0.1,0c-0.5,0-0.9-0.1-1.2-0.2c0.2,3.5,0.5,9.8,0.5,12.7c0.1,4.2,2.3,9.2,2.3,9.2l1.1,7.2
        c0,0,0,0.1-0.1,0.2l1.6,8.8c0.2,1.4,0.7,2.9,1,3.5c2.3-1.8,6.4-10.6,8.8-16.9c-0.8-0.8-1.6-1.7-1.7-2.1c0-0.2-0.1-0.8-0.2-1.7
        c2.6,0.8,7.2,2.2,9.5,2.9c-0.1,0.6-0.2,1.1-0.2,1.7c-1.5,0.2-3.7,0.6-5.7,0.9c-1.8,4.5-6.6,16-10,17.5c-0.2,0.1-0.4,0.1-0.6,0.1
        c-0.3,0-0.5-0.1-0.8-0.2c-1.3-0.7-2-3.6-2.3-5.3l-1.1-6.1c-0.5,0.8-0.9,1.6-1.2,2c-0.7,1-3.1,7.8-3.1,9.1c0,1.3,1.3,7.5,2.4,8.2
        c0.6,0.4,2,0.7,3,0.9c1.5-0.2,3.3-0.7,4.6-1.7c2.6-2.1,6.7-9.4,7.3-11.6c0.2-0.6,0.7-0.9,1.3-0.8c0.6,0.2,0.9,0.7,0.8,1.3
        c-0.6,2.5-5,10.2-8.1,12.7c-1.8,1.4-4.2,2-6,2.2c-0.5,1-1,2.2-1.1,3.1c-0.2,1.6,4.6,3.9,4.6,3.9s-0.9,2.9-0.8,4.6
        c0,0.3,0.1,0.6,0.1,0.9c8.3-9.4,13-25.6,14.2-29.9c-0.5-0.4-1-0.7-1.3-1.1c-0.6-0.8-0.3-3.9-0.2-4.9c0.1-1-0.6-0.9-1-1.4
        c-0.2-0.3-0.4-1.2-0.6-2.1c2,0.2,4.1,0.4,5.5,0.6c0.3-0.8,0.7-1.6,1.1-2.3c2.3-4,5.9-6.1,10.7-6.2c2.4-0.1,5.9-0.2,10,3.1
        c1.5,1.2,2.5,2.9,3.3,4.5c2.5-0.2,12.5-1.3,12.7-1.3c0.2,0,0.1-0.3,0-0.3c-0.4,0-10-1.2-12.9-1.7c-0.1-0.6-0.3-1.3-0.5-1.9
        c2.4-0.9,11.6-4.9,11.8-5c0.2-0.1,0.3-0.4-0.1-0.3c0.6-0.1-11.6,2-13.4,2.3c-0.7-0.8-1.4-1.5-2.3-2.1c2.4-2.8,7.4-8.8,7.5-8.9
        c0.1-0.1,0-0.3-0.2-0.1c0.1-0.1-7.4,5.5-10.2,7.5c-1.2-0.4-2.4-0.7-3.7-0.8c-0.2-1.6-2.4-11.6-2.5-11.9c-0.1-0.2-0.3-0.3-0.3,0
        c0,0.3,0,9.1-0.1,11.9c-1.8,0.2-3.4,0.7-4.9,1.5c-2-1.4-11.1-8.2-10.7-7.9c-0.2-0.2-0.3,0-0.2,0.2c0.1,0.2,6.3,7.6,8.1,9.6
        c-0.8,0.7-1.4,1.5-1.9,2.4c-2.5-0.1-7.9-0.3-10.7-0.5c-0.4-3.2-1-8.6-1.4-9.5c-0.5-1.3-3.6-14.3-3.5-15.4c0.1-1.1-0.6-4.5-0.4-5
        c0.2-0.5-0.1-4.7,0.2-5.6c0.2-0.9-2.2-6.6-2.6-7.2c-0.4-0.6-1.3-1-1.3-1s0.2-2.5,0.5-2.6c0.3-0.1,0.5-0.3,0.9-0.9
        c0.4-0.6,0.1-1.6,0.2-1.7c3-0.4,5-1.2,5-2.2c0-0.1,0-0.1,0-0.1l-0.7-5.5c0-1-2.6-1.9-6.3-2.2c0.6-1.3-1.2-1.2,0.7-2.4
        c2.1-1.3,1.3-4.7,2.1-5.9c0.9-1.1,0.5-4.5,0.1-4.4c-0.5,0.1-1.3,2-1.2,1.8c0.1-0.2-0.3-1.4-0.5-2.4c-0.3-1.1-1.1-0.8-1.4-0.4
        c-0.3,0.4-0.3,0.8-1.4,0.5c-1-0.3-0.8,0.1-0.9,0.9c-0.2,0.8-1.1,1.5-1.4,1.2c-0.3-0.3-1.7-0.8-1.9-0.4c-0.2,0.4,0.8,1.1,0.1,1.7
        c-0.8,0.6-0.9,2.4-0.9,2.4s-0.5-0.4-0.9-0.2c-0.4,0.2,0,0,0.3,2.1c0.2,1.8,1.4,4.6,1.8,5.4c-3.6,0.3-6.3,1.2-6.3,2.2l-0.2,3.6
        C2568,172.1,2571.8,171.2,2578.6,171.9z M2577.5,179c-0.1,0.6-0.7,0.9-1.3,0.8c0,0-1.8-0.4-3.7,0.3c-0.1,0-0.2,0.1-0.4,0.1
        c-0.5,0-0.9-0.3-1-0.7c-0.2-0.6,0.1-1.2,0.7-1.4c2.6-0.9,4.9-0.4,5-0.3C2577.3,177.8,2577.6,178.4,2577.5,179z"
      />
      <path
        className="st0"
        d="M1977.4,334.6v5.7h60.9v-12.3h-64.5c-0.2,0-0.3,0.1-0.3,0.2v3.4c0,0.1,0,0.1,0.1,0.2L1977.4,334.6z"
      />
      <path
        className="st0"
        d="M2168.2,334.6l3.8-2.8c0.1,0,0.1-0.1,0.1-0.2v-3.4c0-0.1-0.1-0.2-0.3-0.2h-64.4v12.3h60.9V334.6z"
      />
      <path
        className="st0"
        d="M1761.4,375.6v37.8l153.3,0.1c1.6,0,2.7,0.5,2.7,2.2c0,1.1-0.1,11.8-0.1,19.5h14.3c0,0,0,0,0-0.1v-11.6
        c0-1.8,1.5-3.3,3.3-3.3h11.6c-0.6-4.6-1.4-10.2-2.4-13.3c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0-8.5-23.4-8.6-23.7c0,0,0-0.1-0.1-0.2
        c-1-2.4-0.4-0.9-1.2-2.3c-4.7-8.3-23.4-10.4-28.9-10.4h-136.5C1762.4,370.1,1761.4,375.5,1761.4,375.6z M1909.5,377.3
        c6.8,0.4,15.5,1.9,22.7,6.3l7.5,21c-8-4-19.5-9-30.3-10.8L1909.5,377.3z M1881.2,377.1c10.1,0,16.8,0,17.1,0c0.1,0,0.2,0,0.4,0
        c0.3,0,0.8,0,1.4,0c1.3,0,3.4,0,6.1,0l-0.1,16.2c-1.4-0.1-2.8-0.2-4.2-0.2c-4,0-11.4,0-20.8,0L1881.2,377.1z M1852.9,377.2
        c9.2,0,17.8,0,25,0l-0.1,15.9c-7.5,0-16.1,0-25-0.1L1852.9,377.2z M1824.6,377.2c8.3,0,16.8,0,25,0l-0.1,15.8c-8.3,0-16.7,0-25-0.1
        L1824.6,377.2z M1796.3,377.3c7.5,0,16.1,0,25,0l-0.1,15.7c-9,0-17.5-0.1-25-0.1L1796.3,377.3z M1769.8,377.3c2.1,0,11,0,23.2,0
        l-0.1,15.5c-12.2,0-21-0.1-23.1-0.1V377.3z"
      />
      <path
        className="st0"
        d="M3499.5,406.8h56.6c-0.3-1.3-0.5-2.5-0.8-3.8h-55.1C3500,404.2,3499.7,405.5,3499.5,406.8z"
      />
      <path className="st0" d="M3501,399.2h53.6c-0.2-1.3-0.5-2.6-0.8-3.8h-52.1C3501.5,396.6,3501.2,397.9,3501,399.2z" />
      <polygon
        className="st0"
        points="3551.3,326.7 3501.5,326.7 3501.5,338 3520.4,338 3520.4,330.4 3532.4,330.4 3532.4,338 3551.3,338 "
      />
      <path className="st0" d="M3496.5,422h62.5c-0.2-1.3-0.5-2.5-0.7-3.8h-61C3497,419.4,3496.8,420.7,3496.5,422z" />
      <path className="st0" d="M3502.5,391.6h50.6c-0.2-1.3-0.5-2.6-0.8-3.8h-49.1C3503,389,3502.7,390.3,3502.5,391.6z" />
      <path className="st0" d="M3550.1,376.4c-0.3-1.3-0.5-2.6-0.8-3.8h-43.2c-0.2,1.3-0.5,2.5-0.7,3.8H3550.1z" />
      <path className="st0" d="M3504,384h47.6c-0.3-1.3-0.5-2.6-0.8-3.8h-46.1C3504.5,381.4,3504.2,382.7,3504,384z" />
      <path className="st0" d="M3498,414.4h59.5c-0.3-1.3-0.5-2.5-0.8-3.8h-58C3498.5,411.8,3498.3,413.1,3498,414.4z" />
      <polygon className="st0" points="3558.3,374 3605.8,374 3596.2,359.1 3554.4,359.1 " />
      <path
        className="st0"
        d="M3599.3,138.6h7.9c0.5,0,1-0.4,1-1c0-0.6-0.4-1-1-1h-7.9c-0.5,0-1,0.4-1,1
        C3598.3,138.1,3598.7,138.6,3599.3,138.6z"
      />
      <path className="st0" d="M3495,429.6h65.5c-0.2-1.2-0.5-2.5-0.7-3.8h-64C3495.5,427,3495.3,428.3,3495,429.6z" />
      <path
        className="st0"
        d="M3583.5,138.6h7.9c0.5,0,1-0.4,1-1c0-0.6-0.4-1-1-1h-7.9c-0.5,0-1,0.4-1,1
        C3582.6,138.1,3583,138.6,3583.5,138.6z"
      />
      <path className="st0" d="M3493.5,437.1h68.4c-0.2-1.2-0.5-2.5-0.7-3.8h-67C3494,434.7,3493.8,435.9,3493.5,437.1z" />
      <path
        className="st0"
        d="M3615,138.6h7.9c0.5,0,1-0.4,1-1c0-0.6-0.4-1-1-1h-7.9c-0.6,0-1,0.4-1,1C3614,138.1,3614.5,138.6,3615,138.6z"
      />
      <path
        className="st0"
        d="M3509.9,353.6h35.7c-0.3-1.4-0.5-2.6-0.7-3.8h-34.2C3510.4,350.9,3510.2,352.2,3509.9,353.6z"
      />
      <path
        className="st0"
        d="M3678,138.6h7.9c0.6,0,1-0.4,1-1c0-0.6-0.4-1-1-1h-7.9c-0.5,0-1,0.4-1,1C3677,138.1,3677.5,138.6,3678,138.6z"
      />
      <path
        className="st0"
        d="M3662.3,138.6h7.9c0.6,0,1-0.4,1-1c0-0.6-0.4-1-1-1h-7.9c-0.6,0-1,0.4-1,1
        C3661.3,138.1,3661.7,138.6,3662.3,138.6z"
      />
      <path
        className="st0"
        d="M3693.8,138.6h7.9c0.5,0,1-0.4,1-1c0-0.6-0.4-1-1-1h-7.9c-0.5,0-1,0.4-1,1
        C3692.8,138.1,3693.2,138.6,3693.8,138.6z"
      />
      <path
        className="st0"
        d="M3725.3,138.6h7.9c0.6,0,1-0.4,1-1c0-0.6-0.4-1-1-1h-7.9c-0.6,0-1,0.4-1,1
        C3724.3,138.1,3724.7,138.6,3725.3,138.6z"
      />
      <polygon className="st0" points="3565,399.3 3570.3,419.4 3675,419.4 3662.1,399.3 " />
      <path
        className="st0"
        d="M3709.5,138.6h7.9c0.5,0,1-0.4,1-1c0-0.6-0.4-1-1-1h-7.9c-0.5,0-1,0.4-1,1
        C3708.5,138.1,3709,138.6,3709.5,138.6z"
      />
      <polygon className="st0" points="3549.9,342.2 3553.3,355.2 3577.7,355.2 3569.3,342.2 " />
      <path className="st0" d="M3512.2,342.2c-0.2,1-0.5,2.3-0.8,3.8h32.7c-0.3-1.5-0.6-2.8-0.8-3.8H3512.2z" />
      <path
        className="st0"
        d="M3506.9,368.8h41.7c-0.3-1.3-0.5-2.6-0.8-3.8h-40.2C3507.4,366.2,3507.2,367.5,3506.9,368.8z"
      />
      <path
        className="st0"
        d="M3508.4,361.2h38.7c-0.3-1.3-0.5-2.6-0.8-3.8h-37.2C3508.9,358.6,3508.7,359.9,3508.4,361.2z"
      />
      <polygon className="st0" points="3564,395.6 3639,395.6 3627.7,378.2 3559.4,378.2 " />
      <path
        className="st0"
        d="M3630.8,138.6h7.9c0.6,0,1-0.4,1-1c0-0.6-0.4-1-1-1h-7.9c-0.6,0-1,0.4-1,1
        C3629.8,138.1,3630.2,138.6,3630.8,138.6z"
      />
      <path
        className="st0"
        d="M3646.5,138.6h7.9c0.6,0,1-0.4,1-1c0-0.6-0.4-1-1-1h-7.9c-0.6,0-1,0.4-1,1
        C3645.5,138.1,3646,138.6,3646.5,138.6z"
      />
      <polygon className="st0" points="3491.6,395.6 3496.2,378.2 3425.1,378.2 3413.8,395.6 " />
      <path
        className="st0"
        d="M3151,422.6h-2c-0.5,0-0.9,0.4-0.9,0.9v3.3c0,7.1,6.9,9,9.6,9.2h5.2c0.5,0,0.9-0.4,0.9-0.9v-11.6
        c0-0.5-0.4-0.9-0.9-0.9H3151z"
      />
      <path
        className="st0"
        d="M3744,444.3c0.4-0.6,0.8-1.2,1.2-1.9c2.7-4.8,1-21.2,3.8-31.7c3.2-12,20.3-18,26.4-24.1c4-4,6-9.5,9.6-10.9
        c3.3,3,16.8,2.2,17.9,3.8c1.7,2.5,1.9,12.5-2.1,15.3c-4,2.8-2.5,2.6-1.7,2.8c0.8,0.2-0.2,3.2,4.1-0.4c4.3-3.6,7.2-10.6,8.9-14.9
        c1.7-4.3,6.2-4.1,10.6-6.8c6.3-4,10.7-14.9,14.7-14.9c0.5,0,3.8,1.7,7.7,0.9c4-0.8,4.7-2.6,8.9-1.9c1.4,0.2,1.3,0.4,2.6,0.7
        c2.6,0.8,8.2-3.5,5.7-5.1c-2-1.3-2.5-1.7-5.6-3.5c-1.1-0.6-4.1-2.1-4.7-4.3c-0.5-2.3-6.3-5-9.1-5.4c-2.8-0.4-2.8-0.1-4,0.5
        c-1.1,0.6-13.9-8.5-16-7.6c-2.1,1,1.9,9.6,8.3,10c6.4,0.4,4.2,0.4,4.2,0.4s-9.1,7.1-14,7.7c-4.9,0.6-10.2-5.3-14.5-8.9
        c-3.7-3.2-16.5-18.1-34.8-17.9c-20.5,0.3-30.4,16.8-33.3,20.4c-5.1,6.2-22.1,28.4-33,31c-11.4,2.8-20.7,0.7-26.7-2.3
        c-6-3-7.4-3.8-1.5,2.8c0,0,14.3,9.8,33.8,4.9c19.4-4.9,31-19.9,32.7-20.6c1.7-0.8,3.3,2.5,3.9,3.6c0.6,1.1,2.5,4.1,8,8.2
        c8.4,6.3-16.1,29.5-14.8,33.9c1.7,6.2,1.9,17.8,0.3,27.1c-3.5,3.7-4,6.8-4.1,8.8h-31.6l-13.5-21.1h-120.9l5.6,21.1h-13.5
        c-0.2-1-0.4-2.1-0.7-3.3h-69.9c-0.2,1.2-0.5,2.3-0.7,3.3h-13.4l5.6-21.1h-123.7l-13.5,21.1h-53.8c3.9-1.1,6.8-4.6,6.8-8.8
        c0-5.1-4.1-9.2-9.2-9.2c-5.1,0-9.2,4.1-9.2,9.2c0,4.2,2.9,7.8,6.8,8.8h-86.4c3.9-1.1,6.8-4.6,6.8-8.8c0-5.1-4.1-9.2-9.2-9.2
        c-5.1,0-9.2,4.1-9.2,9.2c0,4.2,2.9,7.8,6.8,8.8h-60v-14h-4.2l-0.7-13.1c0.7-0.1,1.2-0.5,1.2-1.1c0-0.6-0.6-1.1-1.3-1.1l-0.9-18.6
        c0.6-0.1,1.1-0.5,1.1-1.1c0-0.6-0.6-1.1-1.3-1.1l-1-19.8c0.7-0.1,1.2-0.5,1.2-1.1c0-0.6-0.6-1.1-1.3-1.1l-0.3-5.2h1.2l0.2-0.7
        l-1.2-0.3c0-0.2,0.1-0.6-0.2-1.6c-0.3-1-1.3-1.6-2.1-2.2c-0.6-0.4-1.2-0.8-1.6-1.1v-0.7c0.3-0.2,0.4-0.4,0.4-0.7
        c0-0.3-0.2-0.6-0.4-0.7v-2.2c0,0-0.1-0.3-0.6-0.3c-0.5,0-0.7,0.3-0.7,0.3v2.2c-0.3,0.2-0.4,0.4-0.4,0.7c0,0.3,0.2,0.6,0.4,0.7v0.7
        c-0.4,0.3-0.9,0.7-1.6,1.1c-0.9,0.5-1.8,1.1-2.1,2.2c-0.3,1-0.2,1.4-0.2,1.6l-1.2,0.3l0.2,0.7h1.2l-0.3,5.2c-0.7,0-1.3,0.5-1.3,1.1
        c0,0.6,0.5,1,1.2,1.1l-1,19.8c-0.7,0-1.2,0.5-1.2,1.1c0,0.6,0.5,1,1.1,1.1l-0.9,18.6c-0.7,0-1.3,0.5-1.3,1.1c0,0.6,0.5,1.1,1.2,1.1
        l-0.7,13.1h-9.5v-56.4h0c0,0,0-10.2-0.1-10.5c-0.1-0.3-0.4-0.4-0.6-0.5c-0.2-0.1-1.1-0.1-1.3,0c-0.2,0.1-0.5,0.3-0.6,0.5
        c-0.1,0.2-0.1,8-0.1,10l-14.3-2h-6.3l-0.6-0.1h-3v-9.8l2.8-0.2l0.7-2.2l-3.4-1c0-0.5,0.3-1.8-0.6-4.6c-1-3-3.7-4.6-6.1-6.1
        c-2.5-1.5-4.6-3.1-5.6-3.9c0.2-0.2,0.4-0.4,0.4-0.7c0-0.3-0.2-0.6-0.5-0.8v-0.6c0.5-0.2,0.9-0.6,0.9-1.1c0-0.5-0.4-0.9-0.9-1.1v-1.7
        c0.3-0.2,0.4-0.4,0.4-0.7c0-0.3-0.2-0.6-0.4-0.7v-2.2c0,0-0.2-0.4-0.8-0.4c-0.6,0-0.7,0.4-0.7,0.4v2.2c-0.3,0.2-0.4,0.4-0.4,0.7
        c0,0.3,0.2,0.6,0.4,0.7v1.7c-0.5,0.2-0.9,0.6-0.9,1.1c0,0.5,0.4,0.9,0.9,1.1v0.6c-0.3,0.2-0.5,0.5-0.5,0.8c0,0.3,0.2,0.5,0.4,0.7
        c-1,0.8-3.1,2.4-5.6,3.9c-1.9,1.1-3.9,2.4-5.1,4.2v-0.1h-6.4v0h0h0v0h-65v0h0v0h-6.4v0.1c-1.3-1.8-3.3-3.1-5.1-4.2
        c-2.5-1.5-4.6-3.1-5.6-3.9c0.2-0.2,0.4-0.4,0.4-0.7c0-0.3-0.2-0.6-0.5-0.8v-0.6c0.5-0.2,0.9-0.6,0.9-1.1c0-0.5-0.4-0.9-0.9-1.1v-1.7
        c0.3-0.2,0.4-0.4,0.4-0.7c0-0.3-0.2-0.6-0.4-0.7v-2.2c0,0-0.1-0.4-0.7-0.4c-0.6,0-0.8,0.4-0.8,0.4v2.2c-0.3,0.2-0.4,0.4-0.4,0.7
        c0,0.3,0.2,0.6,0.4,0.7v1.7c-0.5,0.2-0.9,0.6-0.9,1.1c0,0.5,0.4,0.9,0.9,1.1v0.6c-0.3,0.2-0.5,0.5-0.5,0.8c0,0.3,0.2,0.5,0.4,0.7
        c-1,0.8-3.1,2.4-5.6,3.9c-2.4,1.5-5.1,3.2-6.1,6.1c-0.9,2.8-0.6,4.1-0.6,4.6l-3.4,1l0.7,2.2l2.8,0.2v9.8h0h-2.9l-0.1,0h-0.2
        c0,0,0,0,0,0h-3.1v0h-3l-14.7,2.1c0-2-0.1-9.8-0.1-10c-0.1-0.3-0.4-0.4-0.6-0.5c-0.2-0.1-1.1-0.1-1.3,0c-0.2,0.1-0.5,0.3-0.6,0.5
        c-0.1,0.2-0.1,10.5-0.1,10.5h0v56.4h-10.5l-0.7-13.1c0.7-0.1,1.2-0.5,1.2-1.1c0-0.6-0.6-1.1-1.3-1.1l-0.9-18.6
        c0.6-0.1,1.1-0.5,1.1-1.1c0-0.6-0.6-1.1-1.3-1.1l-1-19.8c0.7-0.1,1.2-0.5,1.2-1.1c0-0.6-0.6-1.1-1.3-1.1l-0.3-5.2h1.2l0.2-0.7
        l-1.2-0.3c0-0.2,0.1-0.6-0.2-1.6c-0.3-1-1.3-1.6-2.1-2.2c-0.6-0.4-1.2-0.8-1.6-1.1v-0.7c0.3-0.2,0.4-0.4,0.4-0.7
        c0-0.3-0.2-0.6-0.4-0.7v-2.2c0,0-0.1-0.3-0.6-0.3c-0.5,0-0.7,0.3-0.7,0.3v2.2c-0.3,0.2-0.4,0.4-0.4,0.7c0,0.3,0.2,0.6,0.4,0.7v0.7
        c-0.4,0.3-0.9,0.7-1.6,1.1c-0.8,0.5-1.8,1.1-2.1,2.2c-0.3,1-0.2,1.4-0.2,1.6l-1.2,0.3l0.2,0.7h1.2l-0.3,5.2c-0.7,0-1.3,0.5-1.3,1.1
        c0,0.6,0.5,1,1.2,1.1l-1,19.8c-0.7,0-1.2,0.5-1.2,1.1c0,0.6,0.5,1,1.1,1.1l-0.9,18.6c-0.7,0-1.3,0.5-1.3,1.1c0,0.6,0.5,1.1,1.2,1.1
        l-0.7,13.1h-4.2v14h-66.2v-27.1c1.7,0,1.9-0.1,2-0.1c0.3-0.1,1,0,1.9,0c0.8,0,1.8,0.1,2.9,0.1c4.3,0,8-0.5,11.4-1.5
        c7.7-2.2,36.8-20.3,38-21.1c0.3-0.2,0.5-0.5,0.5-0.9V384c0-0.4-0.2-0.8-0.5-0.9c-0.3-0.2-0.7-0.1-1,0.2c-9.3,9.3-22.8,13-31.5,13.3
        c-7.6,0.3-14.6-2-21.6-4.6c-1.5-0.6-4.7-2.2-8.8-4.3v-17.4c0.3,0,0.6,0,1,0c0.7,0,1.6,0.1,2.7,0.1c4,0,7.4-0.5,10.6-1.4
        c7.2-2.2,34.1-19.7,35.3-20.5c0.3-0.2,0.4-0.5,0.4-0.9v-9.4c0-0.4-0.2-0.7-0.5-0.9c-0.3-0.2-0.6-0.1-0.9,0.2
        c-8.6,9.1-21.1,12.6-29.2,12.9c-7.1,0.3-13.5-2-20.1-4.5c-1.4-0.6-4.5-2.1-8.3-4.2v-16.2c3.3-0.1,6.3-0.4,9-1.2
        c6.6-1.8,31.6-16.7,32.7-17.3c0.2-0.1,0.4-0.4,0.4-0.7v-7.9c0-0.3-0.2-0.6-0.5-0.8c-0.3-0.1-0.6-0.1-0.8,0.1
        c-8,7.7-19.6,10.7-27.1,10.9c-6.6,0.2-12.5-1.7-18.6-3.8c-5-1.8-31.8-16-38.4-19.6v-5.6c1.2-0.6,2.1-1.9,2.1-3.4
        c0-1.5-0.8-2.8-2.1-3.4v-2.8c0.6-0.5,0.9-1.2,0.9-2c0-0.8-0.4-1.5-0.9-2v-4.2c0-0.9-0.7-1.7-1.7-1.7c-0.9,0-1.7,0.7-1.7,1.7v4.2
        c-0.6,0.5-0.9,1.2-0.9,2c0,0.8,0.4,1.5,0.9,2v2.8c-1.2,0.6-2.1,1.9-2.1,3.4c0,1.5,0.8,2.8,2.1,3.4v5.6c-6.6,3.5-33.4,17.8-38.4,19.6
        c-6,2.1-12,4-18.6,3.8c-7.5-0.2-19.1-3.3-27.1-10.9c-0.2-0.2-0.5-0.3-0.8-0.1c-0.3,0.1-0.5,0.4-0.5,0.8v7.9c0,0.3,0.2,0.6,0.4,0.7
        c1.1,0.6,26,15.5,32.7,17.3c2.7,0.7,5.7,1.1,9,1.2v16.2c-3.9,2.1-6.9,3.7-8.3,4.2c-6.5,2.5-13,4.8-20.1,4.5
        c-8.1-0.3-20.6-3.9-29.2-12.9c-0.2-0.3-0.6-0.3-0.9-0.2c-0.3,0.2-0.5,0.5-0.5,0.9v9.4c0,0.4,0.2,0.7,0.4,0.9
        c1.1,0.7,28.1,18.3,35.3,20.5c3.2,0.9,6.6,1.4,10.6,1.4c1,0,1.9,0,2.7-0.1c0.4,0,0.8,0,1,0v17.4c-4.1,2.1-7.3,3.7-8.8,4.3
        c-7,2.6-14,4.9-21.6,4.6c-8.7-0.3-22.2-4-31.5-13.3c-0.3-0.3-0.6-0.3-1-0.2c-0.3,0.2-0.5,0.5-0.5,0.9v9.7c0,0.4,0.2,0.7,0.5,0.9
        c1.2,0.8,30.3,18.9,38,21.1c3.4,1,7.1,1.5,11.4,1.5c1.1,0,2.1,0,2.9-0.1c0.9,0,1.6-0.1,1.9,0c0.1,0,0.3,0.1,2,0.1v27.1h-57.2v-14.4
        h-0.2v-0.1h-87.5v14.4h-6.6c-12.8-7.4-39.1-47.8-58.1-79.1c-12.7-20.9-22.2-37.7-22.2-37.7H2446c0,0-10.8,19.9-24.9,43.3
        c-18.2,30.3-42,66.5-55.1,73.5h-4.1c-16.3-17.8-29.6-35.8-40.3-54.6v-15.7h-8.2c-5.1-10.6-9.4-21.6-13.1-33.1h0.1v-13.6h-4.1
        c-8.7-33.1-12.3-70.2-12.1-114.4h2.5v-12.2h-2.4c0-1.1,0-2.2,0.1-3.3h-4.7v-16.7h-2.4v16.7h-4.7c0,1.1,0,2.2,0.1,3.3h-2.4v12.2h2.5
        c0.2,44.1-3.3,81.3-12.1,114.4h-4.1v13.6h0.1c-3.7,11.5-8,22.4-13.1,33.1h-8.2v15.7c-10.7,18.9-24,36.8-40.3,54.6h-18.5
        c-0.1-0.1-0.2-0.2-0.3-0.3l-1.6-74.3l1.8-1c0.1,0,0.1-0.1,0.1-0.2v-3.3c0-0.1-0.1-0.2-0.3-0.2h-17.2c-0.2,0-0.3,0.1-0.3,0.2v3.3
        c0,0.1,0,0.2,0.1,0.2l1.8,1l-1.6,74.3c-0.1,0.1-0.2,0.2-0.3,0.3h-20c-0.1-0.1-0.2-0.2-0.3-0.3l-1.6-74.3l1.8-1
        c0.1,0,0.1-0.1,0.1-0.2v-3.3c0-0.1-0.1-0.2-0.3-0.2h-17.2c-0.2,0-0.3,0.1-0.3,0.2v3.3c0,0.1,0,0.2,0.1,0.2l1.8,1l-1.6,74.3
        c-0.1,0.1-0.2,0.2-0.3,0.3h-20c-0.1-0.1-0.2-0.2-0.3-0.3l-1.6-74.3l1.8-1c0.1,0,0.1-0.1,0.1-0.2v-3.3c0-0.1-0.1-0.2-0.3-0.2h-17.2
        c-0.2,0-0.3,0.1-0.3,0.2v3.3c0,0.1,0,0.2,0.1,0.2l1.8,1l-1.6,74.3c-0.1,0.1-0.2,0.2-0.3,0.3h-20c-0.1-0.1-0.2-0.2-0.3-0.3l-1.6-74.3
        l1.8-1c0.1,0,0.1-0.1,0.1-0.2v-3.3c0-0.1-0.1-0.2-0.3-0.2h-17.2c-0.2,0-0.3,0.1-0.3,0.2v3.3c0,0.1,0,0.2,0.1,0.2l1.8,1l-1.6,74.3
        c-0.1,0.1-0.2,0.2-0.3,0.3h-20c-0.1-0.1-0.2-0.2-0.3-0.3l-1.6-74.3l1.8-1c0.1,0,0.1-0.1,0.1-0.2v-3.3c0-0.1-0.1-0.2-0.3-0.2h-17.2
        c-0.2,0-0.3,0.1-0.3,0.2v3.3c0,0.1,0,0.2,0.1,0.2l1.8,1l-1.6,74.3c-0.1,0.1-0.2,0.2-0.3,0.3h-20c-0.1-0.1-0.2-0.2-0.3-0.3l-1.6-74.3
        l1.8-1c0.1,0,0.1-0.1,0.1-0.2v-3.3c0-0.1-0.1-0.2-0.3-0.2h-17.2c-0.2,0-0.3,0.1-0.3,0.2v3.3c0,0.1,0,0.2,0.1,0.2l1.8,1l-1.6,74.3
        c-0.1,0.1-0.2,0.2-0.3,0.3h-69.3c3.9-1.1,6.8-4.6,6.8-8.8c0-5.1-4.1-9.2-9.2-9.2c-5.1,0-9.2,4.1-9.2,9.2c0,4.2,2.9,7.8,6.8,8.8
        h-86.4c3.9-1.1,6.8-4.6,6.8-8.8c0-5.1-4.1-9.2-9.2-9.2c-5.1,0-9.2,4.1-9.2,9.2c0,4.2,2.9,7.8,6.8,8.8h-64.2l-0.1-23.1
        c1.8-0.9,2.9-2,2.9-3.1c0-1.2-1.1-2.2-2.9-3.2l0-13.2c1.9-1.5,2.9-3.4,2.9-5.4c0-2-1.1-3.9-3-5.4l0-9.7c-17.4-13.8-68.3-17.2-80-18
        c-11.7-0.8-7.4,2.4-10.4,2.4c-3.3,0-5.1-0.1-9.4,0.8c-6,1.2-2.8,3.3-10.2,2.5c-7.8-0.8-6.4-5-10.7-4.8c-4.8,0.2-4.9,2.8-8,1.5
        c-3.1-1.3-7.4-26.8-9.2-30c-1.8-3.1-7.1-3.5-8.6-6.5c-1.5-3-11.3-21.8-12.3-24.5c-1.7-4.4-5.5-2.6-5.5-2.6
        c-36.2,8.2-53.5,34.9-53.5,34.9v33.3c-1.8,2-2.8,4.3-2.8,6.8c0,2.5,1,4.8,2.8,6.8v7.2c-1.8,1.5-2.8,3.3-2.8,5.2c0,1.9,1,3.7,2.8,5.2
        v13.4c-1.8,0.9-2.8,2-2.8,3.1c0,1.1,1,2.2,2.8,3.1v23.1h-26.1v-89c0,0,0,0,0,0c0,0,0,0,0,0V355h0c-0.3-1.2-3.3-2.4-8.3-3.4v-9.8h3
        v-2.9c7.5-1.9,8.2-7.1,7.8-10.8c-0.8-7.1-9.6-9.2-16.5-15.8c-6.6-6.3-10.1-15.4-11.1-18.2v-11.8h6.9c0.7,0,1.3-0.5,1.3-1.1
        c0-0.6-0.6-1.1-1.3-1.1h-6.9v-4.7h1.7c0.7,0,1.2-0.5,1.2-1c0-0.6-0.5-1-1.2-1h-1.7V271c0-0.7-0.6-1.2-1.4-1.2
        c-0.8,0-1.4,0.5-1.4,1.2v2.4h-1.7c-0.7,0-1.2,0.5-1.2,1c0,0.6,0.5,1,1.2,1h1.7v4.7h-6.9c-0.7,0-1.3,0.5-1.3,1.1
        c0,0.6,0.6,1.1,1.3,1.1h6.9v11.8c-1,2.9-4.5,11.9-11.1,18.2c-4.6,4.4-10,6.8-13.4,10v-5.9h3v-2.9c7.6-1.8,8.2-7.1,7.8-10.8
        c-0.8-7.1-9.6-9.2-16.5-15.8c-6.6-6.3-10.1-15.4-11.1-18.2v-11.8h6.9c0.7,0,1.3-0.5,1.3-1.1c0-0.6-0.6-1.1-1.3-1.1h-6.9V250h1.6
        c0.7,0,1.2-0.5,1.2-1c0-0.6-0.5-1-1.2-1h-1.6v-2.4c0-0.7-0.6-1.2-1.4-1.2c-0.8,0-1.4,0.5-1.4,1.2v2.4h-1.7c-0.7,0-1.2,0.5-1.2,1
        c0,0.6,0.5,1,1.2,1h1.7v4.7h-6.9c-0.7,0-1.3,0.5-1.3,1.1c0,0.6,0.6,1.1,1.3,1.1h6.9v11.8c-1,2.9-4.5,11.9-11.1,18.2
        c-6.8,6.6-15.7,8.7-16.5,15.8c-0.4,3.7,0.3,9,7.8,10.8v2.9h2.5v5.4c-3.4-3-8.5-5.4-12.9-9.5c-6.6-6.3-10.1-15.4-11.1-18.2v-11.8h6.9
        c0.7,0,1.3-0.5,1.3-1.1c0-0.6-0.6-1.1-1.3-1.1h-6.9v-4.7h1.7c0.7,0,1.2-0.5,1.2-1c0-0.6-0.6-1-1.2-1h-1.7V271c0-0.7-0.6-1.2-1.4-1.2
        c-0.8,0-1.4,0.5-1.4,1.2v2.4h-1.6c-0.7,0-1.2,0.5-1.2,1c0,0.6,0.5,1,1.2,1h1.6v4.7h-6.9c-0.7,0-1.3,0.5-1.3,1.1
        c0,0.6,0.6,1.1,1.3,1.1h6.9v11.8c-1,2.9-4.5,11.9-11.1,18.2c-6.8,6.6-15.7,8.7-16.5,15.8c-0.4,3.7,0.3,9,7.8,10.8v2.9h2.9v9.8
        c-5,1-8,2.2-8.3,3.4h-0.1v89.2h-26.2V440h3.9c0.3,0,0.5-0.2,0.5-0.4v-12.7c0-0.2-0.2-0.4-0.5-0.4h-3.9v-20.9h3.9
        c0.3,0,0.5-0.2,0.5-0.4v-12.7c0-0.2-0.2-0.4-0.5-0.4h-3.9v-4.7h3.9c0.3,0,0.5-0.2,0.5-0.4v-2.3h4.6c0.3,0,0.5-0.2,0.5-0.4v-2.7
        c0-0.2-0.2-0.4-0.5-0.4l-15.2-1.1l-14.8,1.1c-0.3,0-0.5,0.2-0.5,0.4v2.7c0,0.2,0.2,0.4,0.5,0.4h4.6v2.3c0,0.2,0.2,0.4,0.5,0.4h3.9
        v4.7h-3.9c-0.3,0-0.5,0.2-0.5,0.4v1.5h-26v-1.5c0-0.2-0.2-0.4-0.5-0.4h-3.7v-28.8c6.3,0.1,12,0.1,15.2,0.1c2.1,0,3.4,0,3.4,0
        c0.3,0,0.5-0.2,0.5-0.4v-10.3c0-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.3-0.1-0.4-0.1c-1.8,0.1-3.9,0.1-6.2,0.1c-3.7,0-7.9-0.1-12.4-0.2
        v-7.4h2c0.3,0,0.5-0.2,0.5-0.4v-4.1c0.7,0,1.3,0,2,0c8.5,0,16.2-0.8,16.3-0.8c0.3,0,0.5-0.2,0.5-0.4l0.1-5.3l6-0.6
        c0.3,0,0.5-0.2,0.5-0.5l-0.2-2.5c-9,0.7-17.7,1-26,1c-64.7,0-109.8-18-110.3-18.2c-0.7-0.3-1-1-0.7-1.7c0.4-0.6,1.2-0.9,2-0.6
        c0.6,0.2,61.7,24.6,143.7,16.2l8.9-1.1c0.2,0,0.4-0.2,0.4-0.3c0-0.2,0-0.4-0.2-0.5l-13.2-7.1l0.9-9.4c0-0.2-0.1-0.4-0.3-0.4
        l-2.4-0.9c-0.2-0.1-0.4,0-0.6,0.1c-0.4,0.3-0.5,1-0.7,2.1c-0.1,0.6-0.3,1.7-0.5,1.9c-7.9,5-28,5.2-38.8,5.3l-2.6,0
        c-10.4,0-43.9-3.3-54.9-5.8c-11.8-2.6-30.5-7.8-35.6-10c-5.2-2.2-13-8.2-13-8.2c-0.1-0.1-0.2-0.1-0.3-0.1l-4.5-0.5
        c-0.2,0-0.3,0-0.5,0.1c-0.1,0.1-0.2,0.3-0.1,0.4l1.5,6.1c0,0.2,0.2,0.3,0.5,0.4l2,0.2l-0.4,3.9l-13.9,9.5c-0.1,0.1-0.2,0.3-0.2,0.4
        c0,0.2,0.2,0.3,0.3,0.3l28.4,8.5c0,1.4,0.1,4.8,0.3,7.6c0,0.2,0.1,0.3,0.3,0.4c4.2,1.6,12.6,3.1,23.1,4.5v4.6c0,0.2,0.2,0.4,0.5,0.4
        h2.1v7.4c-13.1-1.2-22-2.1-22.3-2.1c-0.3,0-0.6,0.2-0.6,0.4c0,0.1,0,0.2,0.1,0.2c0.2,0.8,0.3,7.6,0.4,11.7c0,0.2,0.2,0.4,0.4,0.4
        c1.3,0.3,10.3,0.9,22,1.7v33h-3.9c-0.3,0-0.5,0.2-0.5,0.4v1.9h-23.4v-2c0-0.2-0.2-0.4-0.5-0.4h-3.4V387h3.4c0.3,0,0.5-0.2,0.5-0.4
        v-2.4h4c0.3,0,0.5-0.2,0.5-0.4V381c0-0.2-0.2-0.4-0.5-0.4l-13.3-1.1c0,0-0.1,0-0.1,0l-13,1.1c-0.3,0-0.5,0.2-0.5,0.4v2.8
        c0,0.2,0.2,0.4,0.5,0.4h4v2.4c0,0.2,0.2,0.4,0.5,0.4h3.4v4.9h-3.4c-0.3,0-0.5,0.2-0.5,0.4v2h-3.5c-0.3,0-0.5,0.2-0.5,0.4v8.7
        c0,0.2,0.2,0.4,0.5,0.4h3.5v1.3c0,0.2,0.2,0.4,0.5,0.4h3.4v21.4h-3.4c-0.3,0-0.5,0.2-0.5,0.4v1.8h-4.4c-0.3,0-0.5,0.2-0.5,0.4v8.7
        c0,0.2,0.2,0.4,0.5,0.4h4.4v1.5c0,0.2,0.2,0.4,0.5,0.4h3.4v3.3h-39.1c0.9-1.6,1.9-3.6,2.7-6.1c1.9-5.5,2.8-19.8,3-35.8
        c-4.5,9.3-10.1,19.6-17.2,31c-0.6,1-1.1,1.9-1.3,2.1c-0.1,0.4-0.5,0.7-1,0.7c-0.4,0-0.7-0.2-0.9-0.4c-0.3-0.5-0.3-0.5,1.4-3.3
        c8.1-13.1,14.3-24.8,19.1-35.1c0.1-26.8-1.6-56.4-3.2-57.6c-2.7-2.1-3.2-3.1-4.6-6c-1.5-2.9,12.1-60.9,9.9-63.6
        c-2.2-2.7-21.8-2.4-22.8-2.1c-3.6,1.2-8.7,4.7-11.7,7.4c-0.7,0.6-1.1,1.6-1.3,2.6c6.7,2.6,14.3,6.1,19.4,9.6c3.7,2.6,4.7,3.6,4,4.6
        c-0.3,0.3-0.6,0.6-1.3,0.6c-1.4,0-4.4-0.9-11.1-3.5l-0.9-0.4c-1.1-0.4-1.5-1.1-1.6-1.5c-0.4-1.4,1-2.9,1.2-3.2
        c-3.2-1.6-6.6-3-9.8-4.3c-0.1,3.1,0.7,6.4,0.7,6.4c-7.5,10.1-22.8,16.7-28.1,20.8c-1.9,1.5,10.2,6.2,11.7,6.6l0,0c0,0,0.1,0,0.2,0
        c0,0,0,0,0,0c0,0,0,0,0,0c7.4,1.2,12.7,2,13.3,1.4c0.2-0.2-0.2-1.6-0.4-2.1c-0.3-0.9-0.7-1.7-1.3-2.6c-1.2-2-2.5-4.3-1-7.3
        c1.9-3.9,8.1-8.7,8.3-8.9c0.4-0.3,1.1-0.3,1.5,0.1c0.4,0.4,0.4,0.9-0.1,1.3c-0.1,0-6.1,4.8-7.8,8.3c-1.1,2.2-0.1,3.9,1,5.8
        c0.5,0.9,1.1,1.9,1.4,2.9c0.5,1.5,0.9,2.8-0.1,3.8c-0.5,0.5-1.3,0.7-2.7,0.7c-2.2,0-5.9-0.6-12.7-1.6c-2.6,8.8-5.4,19.9-3.6,23.6
        c0.3,0.6,0.8,1.1,1.5,1.5c15.1-2,27.1-5.6,31.5-7.1c0.2-0.1,0.4-0.1,0.4-0.1c0.6-0.2,1.1,0.1,1.3,0.6c0.2,0.5-0.1,1-0.7,1.1
        l-0.3,0.1c-4.2,1.4-15.2,4.7-29.2,6.8c3.5,1.1,7.9,1.6,9.3,3.4c1.1,1.5,1.7,3.5,1.1,6.6c14.2-9.3,26-10.5,26.7-10.5
        c0.6,0,1.1,0.3,1.2,0.8c0.1,0.5-0.4,0.9-0.9,1c-0.2,0-12.8,1.3-27.5,11.5c-0.7,2.6-2,5.7-3.9,9.7c-5.6,11.7-9.7,62.1-9.5,67.5
        c0.1,3.1,1.7,8.3,2.9,12.1h-26.3c0.4-0.8,0.7-1.6,1.1-2.6c4-11.3,1.8-71.6-0.2-73.1c-2-1.5-2.3-2.3-3.4-4.4
        c-1.1-2.1,9-45.3,7.4-47.3c-1.6-2-16.2-1.8-17-1.5c-2.7,0.9-6.5,3.5-8.7,5.5c-0.5,0.5-0.8,1.2-1,2c4.9,2,10.5,4.5,14.4,7.1
        c2.6,1.8,3.7,2.8,3,3.7c-0.2,0.3-0.6,0.5-1.2,0.5c-1.2,0-3.5-0.7-8.4-2.6l-0.7-0.3c-0.9-0.3-1.2-0.9-1.3-1.3
        c-0.3-0.9,0.4-1.9,0.8-2.3c-2.2-1-4.4-2-6.6-2.9c0,2.1,0.5,4.1,0.5,4.1c-5.6,7.5-17,12.4-20.9,15.5c-1.5,1.2,8.5,4.9,8.8,4.9
        c0,0,0,0,0,0c5.1,0.8,8.8,1.4,9.4,1.2c0.1-0.2-0.2-1.1-0.3-1.4c-0.2-0.6-0.5-1.2-0.9-1.9c-0.9-1.5-1.9-3.3-0.8-5.6
        c1.4-2.9,6.1-6.6,6.3-6.7c0.4-0.3,1.1-0.3,1.5,0.1c0.4,0.4,0.4,0.9-0.1,1.3c0,0-4.5,3.5-5.7,6.1c-0.7,1.5-0.1,2.7,0.7,4.1
        c0.4,0.7,0.8,1.4,1.1,2.2c0.3,1.1,0.7,2.2-0.1,3c-0.5,0.4-1.1,0.6-2.2,0.6c-1.7,0-4.4-0.4-9.3-1.2c-1.9,6.5-3.9,14.4-2.5,17
        c0.2,0.4,0.6,0.8,1,1.1c11.1-1.5,19.9-4.1,23.1-5.2l0.3-0.1c0.5-0.2,1.1,0.1,1.3,0.6c0.2,0.5-0.1,1-0.7,1.1l-0.2,0.1
        c-3,1-10.7,3.3-20.6,4.9c2.4,0.7,5.1,1.1,6,2.3c0.8,1.1,1.2,2.4,0.9,4.6c10.4-6.7,19-7.5,19.5-7.5c0.6,0,1.1,0.3,1.2,0.8
        c0.1,0.5-0.4,0.9-0.9,1c-0.1,0-9.5,1-20.3,8.5c-0.6,1.8-1.5,4.1-2.8,6.8c-4.1,8.7-7.2,46.2-7,50.2c0.1,1.8,0.8,4.6,1.6,7.1h-21.3
        c0.1-0.3,0.2-0.6,0.3-0.9c3.4-9.7,1.5-61.6-0.2-62.9c-1.7-1.3-2-2-3-3.8c-0.9-1.8,7.8-39,6.4-40.7c-1.4-1.7-13.9-1.5-14.6-1.3
        c-2.3,0.8-5.6,3-7.5,4.7c-0.4,0.4-0.7,1-0.8,1.8c4.2,1.7,9,3.9,12.3,6.1c2.4,1.6,3.3,2.5,2.6,3.4c-0.3,0.3-0.6,0.5-1.2,0.5
        c-1.1,0-3.1-0.6-7.3-2.3l-0.6-0.2c-0.7-0.3-1.1-0.7-1.3-1.2c-0.2-0.8,0.2-1.5,0.5-2c-1.7-0.8-3.5-1.6-5.2-2.3
        c0.1,1.7,0.5,3.1,0.5,3.1c-4.8,6.4-14.6,10.7-18,13.3c-1.3,1,7.3,4.2,7.6,4.2c0,0,0,0,0,0c3.9,0.6,6.8,1.1,7.7,1
        c0-0.3-0.2-0.8-0.2-1c-0.2-0.5-0.5-1-0.8-1.6c-0.7-1.3-1.7-2.9-0.7-5c1.2-2.6,5.3-5.7,5.4-5.8c0.5-0.4,1.2-0.3,1.6,0.1
        c0.4,0.4,0.4,1-0.1,1.3c-1.1,0.8-4,3.3-4.9,5.2c-0.6,1.3-0.1,2.2,0.6,3.4c0.3,0.6,0.7,1.2,0.9,1.9c0.3,1,0.6,2-0.2,2.7
        c-0.4,0.4-1,0.6-2,0.6c-1.4,0-3.8-0.4-8-1c-1.6,5.5-3.2,12.1-2.1,14.3c0.2,0.4,0.5,0.7,0.8,0.9c9.4-1.3,16.9-3.5,19.7-4.5l0.3-0.1
        c0.6-0.2,1.2,0.1,1.4,0.6c0.2,0.5-0.1,1-0.7,1.2l-0.2,0.1c-2.5,0.8-8.8,2.7-16.9,4.1c1.9,0.5,3.7,0.9,4.4,1.8c0.7,0.9,1,2,0.8,3.8
        c8.9-5.6,16.2-6.3,16.6-6.4c0.6-0.1,1.2,0.3,1.2,0.8c0.1,0.5-0.4,1-1,1c-0.1,0-8.1,0.8-17.5,7.4c-0.5,1.5-1.2,3.3-2.3,5.5
        c-3.6,7.5-6.2,39.7-6.1,43.2c0.1,1.2,0.5,3,1,4.7h-39.9l4.9-31.5l3,0.5l0.9-6.1l-3-0.5l3.3-21.3l3.1,0.5l1-6.2l-3.1-0.5l3.3-21.7
        l3.1,0.5l1-6.2l-3.1-0.5l3.3-21.7l3.1,0.5l1-6.2l-3.1-0.5l3.3-21.7l3.1,0.5l1-6.2l-3.1-0.5l3.3-21.7l3.1,0.5l1-6.2l-3.1-0.5
        l3.3-21.7l3.1,0.5l1-6.2l-12.6-1.9l5.6-36.2l3.2,0.5l0.7-4.8l-65.7-10.1l-0.7,4.8l3.2,0.5l-5.6,36.2l-12.6-1.9l-1,6.2l3.1,0.5
        l-3.3,21.7l-3.1-0.5l-1,6.2l3.1,0.5l-3.3,21.7l-3.1-0.5l-1,6.2l3.1,0.5l-3.3,21.7l-3.1-0.5l-1,6.2l3.1,0.5l-3.3,21.7l-3.1-0.5
        l-1,6.2l3.1,0.5l-3.3,21.7l-3.1-0.5l-1,6.2l3.1,0.5l-3.3,21.3l-3-0.5l-0.9,6.1l3,0.5l-6.7,43.6h-19.6v-92.7h-56.1v92.7h-17v-3.1
        H522.5v3.1h-6l-80.3-88.8l-80.3,88.8H353l34.7-38.3l-21.4-23.7L339,412.5c3.2,11.1,7.6,22.4,13.5,31.8H350c-0.3-0.5-0.6-0.9-0.8-1.4
        c-0.1-0.2-0.3-0.5-0.4-0.7c-0.6-1.1-1.3-2.3-1.9-3.4c0-0.1-0.1-0.1-0.1-0.2c-1.9-3.8-3.7-7.7-5.2-11.7c-0.1-0.3-0.2-0.5-0.3-0.8
        c-0.4-1.1-0.8-2.1-1.2-3.2c-0.1-0.4-0.3-0.8-0.4-1.2c-0.3-0.9-0.6-1.9-1-2.8c-0.2-0.5-0.3-0.9-0.4-1.4c-0.3-0.9-0.5-1.7-0.8-2.6
        c-7.2-23.9-8.7-47.4-7.8-51.2c0.1-0.3,0.1-0.6,0.1-0.9c3.6-0.4,8.9-0.4,15.2,0.6c9.1,1.4,20.2,7.6,21.3,5.4
        c0.6-1.1-8.5-11-15.8-14.1c9.4-1.5,17.7-0.6,22.7-0.5c2.9,0.1,5.8,0.4,6.2,0c0.9-0.9-4.6-4.2-8.5-5.4c-2.8-0.9-8.9-3.3-18.5-1.1
        c-8.8,2-16.8,6.6-21.2,9.5c0-0.1,0.1-0.2,0.1-0.3c5.4-14,18.8-18.6,24.9-21.7c2.2-1.1,4.7-2,4.7-2.6c-0.1-1.2-7.1-2.2-11-1.8
        c-2.8,0.3-9.5,0.5-14.9,6.5c-2.3,2.6-3.9,5.6-5.2,8.6c-0.4-5.7-1.6-12.5-4.7-17.8c-5.1-8.8-14.7-8.7-16.2-8.6
        c-1.5,0.1-1.2,3.6,0.4,8.1c1.6,4.6,4.6,8.5,8.2,14.9c1.2,2.1,2.8,4.8,4.5,7.6c-2.8-3.2-6.5-6.6-11-8.7c-7.4-3.4-13.6-1-16.3-0.1
        c-3.8,1.2-9.8,4.8-9.4,6c0.2,0.5,2.8,0.4,5.4,0.6c6.9,0.4,21.1-0.6,31.8,10.1c0.1,0.1,0.3,0.3,0.4,0.4c-5.1-1.8-13.4-4.3-21.9-4.3
        c-9.8-0.1-15.2,3.6-17.7,5c-3.5,2-8.2,6.4-7.1,7.1c0.5,0.3,3.3-0.6,6.1-1.3c7.6-1.8,22.2-7,39-3.1c-5.1,1-11.7,3.8-15.6,11.3
        c-3.5,6.8-1.2,22.8,0.2,23.1c2.5,0.5,4.6-11.8,9.3-19.5c3.4-5.6,7-9.8,9.6-12.2c-1.2,23.3,1.3,42.9,4.9,58.4c0,0,0,0,0,0
        c1.4,5.8,2.9,10.9,4.4,15.6c0,0.1,0,0.1,0.1,0.2c0.3,0.8,0.6,1.7,0.8,2.5c0,0.1,0.1,0.2,0.1,0.3c0.3,0.8,0.6,1.6,0.9,2.3
        c0,0,0,0.1,0.1,0.1c0.1,0.2,0.2,0.5,0.3,0.7h-2.2c-2.1-5.6-4.2-12.2-6.1-19.8l-17.9,19.8h-44.7v-41.8c0.2,0,0.3-0.1,0.3-0.3l2.2-4.4
        c0.1-0.1,0.2-0.2,0.2-0.4v-3.7c0-0.3-0.3-0.5-0.6-0.5h-2.1v-20.3c0.1,0,0.3-0.1,0.3-0.3l2.2-4.4c0.1-0.1,0.2-0.2,0.2-0.4v-3.7
        c0-0.3-0.3-0.5-0.6-0.5h-2.5v-18.7c0.2,0,0.4-0.1,0.5-0.3l2.1-4.3c0.1-0.1,0.2-0.2,0.2-0.4v-3.6c0-0.3-0.3-0.5-0.6-0.5h-2.8v-23.7
        c0.2-0.1,0.4-0.3,0.4-0.5v-1.3c0-0.3-0.3-0.5-0.6-0.5h-0.7l-3.5-30.1l0-0.9c0.3-0.2,0.4-0.5,0.4-0.8c0-0.3-0.2-0.6-0.5-0.8l0-0.5
        c0.2-0.2,0.3-0.4,0.3-0.7c0-0.3-0.1-0.5-0.4-0.7l0-0.6c0.2-0.2,0.3-0.4,0.3-0.6c0-0.3-0.1-0.5-0.3-0.7l0-0.6
        c0.2-0.2,0.3-0.4,0.3-0.5c0-0.2-0.1-0.4-0.3-0.5l0-0.6c0.4-0.2,0.7-0.6,0.7-1.1c0-0.3-0.1-0.6-0.3-0.8c-0.1-0.1-0.2-0.2-0.4-0.2
        l0-0.1c0-0.4-0.3-0.6-0.7-0.6c-0.4,0-0.6,0.2-0.7,0.7l0,0.1c-0.1,0-0.3,0.1-0.4,0.2c-0.2,0.2-0.3,0.5-0.3,0.8c0,0.4,0.3,0.8,0.7,1.1
        l0,0.5c-0.2,0.1-0.3,0.4-0.3,0.5c0,0.2,0.1,0.4,0.3,0.5l0,0.6c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.2,0.1,0.5,0.3,0.6l0,0.6
        c-0.2,0.2-0.4,0.5-0.4,0.7c0,0.3,0.1,0.5,0.3,0.7l0,0.5c-0.3,0.2-0.5,0.5-0.5,0.8c0,0.3,0.2,0.6,0.4,0.8l-3.5,31h-0.7
        c-0.3,0-0.6,0.2-0.6,0.5v1.3c0,0.2,0.1,0.4,0.4,0.5v23.7h-2.8c-0.3,0-0.6,0.2-0.6,0.5v3.6c0,0.1,0.1,0.2,0.2,0.3l2.1,4.3
        c0.1,0.2,0.3,0.3,0.5,0.3v18.7h-2.5c-0.3,0-0.6,0.2-0.6,0.5v3.7c0,0.1,0.1,0.3,0.2,0.3l2.2,4.5c0.1,0.1,0.2,0.2,0.3,0.3v20.3h-2.1
        c-0.3,0-0.6,0.2-0.6,0.5v3.7c0,0.1,0.1,0.3,0.2,0.3l2.2,4.5c0.1,0.1,0.2,0.2,0.3,0.3v12.7H249v-5.5h0c0,0,0-0.1,0-0.1
        c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.3-0.1-0.5-0.1c-0.1-0.1-0.2-0.4-0.2-0.5c-0.1-0.1-0.1-0.2-0.1-0.2l-0.1-0.1
        c-0.9-1.9-3.1-3-5.2-2.6v-5.2c0.1,0,0.3-0.1,0.3-0.3l2-4c0.1-0.1,0.2-0.2,0.2-0.4v-3.4c0-0.3-0.3-0.5-0.6-0.5h-1.9v-18.5
        c0.1,0,0.2-0.1,0.3-0.3l2-4c0.1-0.1,0.2-0.2,0.2-0.4v-3.4c0-0.3-0.3-0.5-0.6-0.5h-2.2v-17c0.2,0,0.4-0.1,0.5-0.3l1.9-3.9
        c0.1-0.1,0.2-0.2,0.2-0.4v-3.3c0-0.3-0.3-0.5-0.6-0.5h-2.6v-21.7c0.2-0.1,0.3-0.3,0.3-0.5v-1.2c0-0.3-0.3-0.5-0.6-0.5h-0.6
        l-3.2-27.5l0-0.8c0.3-0.2,0.4-0.5,0.4-0.8c0-0.3-0.2-0.6-0.4-0.8l0-0.4c0.2-0.2,0.3-0.4,0.3-0.7c0-0.3-0.1-0.5-0.4-0.7l0-0.5
        c0.2-0.2,0.3-0.4,0.3-0.6c0-0.2-0.1-0.5-0.3-0.6l0-0.5c0.2-0.1,0.3-0.3,0.3-0.5c0-0.2-0.1-0.4-0.3-0.5l0-0.5c0.4-0.2,0.6-0.6,0.6-1
        c0-0.3-0.1-0.5-0.3-0.8c-0.1-0.1-0.2-0.1-0.3-0.2l0-0.1c0-0.4-0.3-0.6-0.7-0.6c-0.4,0-0.6,0.2-0.7,0.6v0.1c-0.1,0-0.3,0.1-0.4,0.2
        c-0.2,0.2-0.3,0.5-0.3,0.8c0,0.4,0.2,0.8,0.6,1l0,0.4c-0.2,0.1-0.3,0.4-0.3,0.5c0,0.2,0.1,0.4,0.3,0.5l0,0.5
        c-0.2,0.2-0.3,0.4-0.3,0.6c0,0.2,0.1,0.4,0.3,0.6l0,0.5c-0.2,0.2-0.4,0.4-0.4,0.7c0,0.3,0.1,0.5,0.3,0.7l0,0.4
        c-0.3,0.2-0.5,0.5-0.5,0.8c0,0.3,0.1,0.6,0.4,0.8l0,0.7c0,0,0,0,0,0l-3.2,27.6h-0.6c-0.3,0-0.6,0.2-0.6,0.5v1.2
        c0,0.2,0.1,0.4,0.3,0.5v21.7H229c-0.3,0-0.6,0.2-0.6,0.5v3.3c0,0.1,0.1,0.3,0.2,0.3l1.9,4c0.1,0.2,0.3,0.3,0.5,0.3v17h-2.2
        c-0.3,0-0.6,0.2-0.6,0.5v3.4c0,0.1,0.1,0.2,0.2,0.3l2,4.1c0.1,0.1,0.2,0.2,0.3,0.3v18.5h-1.9c-0.3,0-0.6,0.2-0.6,0.5v3.4
        c0,0.1,0.1,0.2,0.2,0.3l2,4.1c0.1,0.1,0.2,0.2,0.3,0.3v6.3h-4.3v-9.2c0,0,0-0.1,0-0.1v-0.1c0-0.1-0.1-0.3-0.2-0.4
        c-0.1-0.1-0.3-0.1-0.5-0.1c0,0-1.2,0.1-2.2-0.3c-0.4-0.2-1.2-1.5-1.5-2.1c-0.2-0.4-0.4-0.7-0.5-0.8c-0.1-0.2-0.2-0.3-0.3-0.5
        c-3.2-5.1-10.3-8.5-18.1-8.5c-0.4,0-0.9,0-1.3,0V380c0,0,0-0.1,0-0.1v-0.3c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.3-0.1-0.5-0.1
        c0,0-0.4,0-0.7-0.1c-0.2-0.2-0.5-1-0.6-1.3c-0.1-0.3-0.2-0.5-0.2-0.5c0-0.1-0.1-0.2-0.1-0.3c-1.3-3.2-4.2-5.4-7.5-5.8l0,0
        c0.3-0.2,0.4-0.5,0.4-0.8c0-0.3-0.2-0.6-0.5-0.8l0-0.3c0.2-0.1,0.3-0.4,0.3-0.6c0-0.3-0.1-0.5-0.4-0.6l0-0.3
        c0.2-0.2,0.3-0.4,0.3-0.6c0-0.2-0.1-0.5-0.3-0.6l0-0.5c0.3-0.2,0.5-0.6,0.5-1c0-0.7-0.7-1.3-1.5-1.3c-0.8,0-1.5,0.6-1.5,1.3
        c0,0.4,0.2,0.7,0.5,0.9l0,0.6c-0.1,0.2-0.2,0.3-0.2,0.6c0,0.2,0.1,0.4,0.2,0.5l0,0.4c-0.2,0.1-0.3,0.4-0.3,0.6
        c0,0.2,0.1,0.4,0.3,0.6l0,0.4c-0.3,0.2-0.4,0.5-0.4,0.8c0,0.3,0.1,0.6,0.4,0.8v0c-1,0.1-2,0.4-2.9,0.8l0-5.1c0-0.1-0.1-0.3-0.2-0.4
        c0,0,0,0,0,0c0,0,0,0,0,0c-0.1-0.1-0.3-0.1-0.5-0.1c0,0-2.4,0.3-4.4-0.5c-0.9-0.3-2.3-2.7-3-4c-0.4-0.7-0.7-1.2-0.9-1.4
        c-0.2-0.3-0.4-0.6-0.6-0.9c-5-7.8-15.2-13.2-27.2-14.7c-0.1-0.5-0.3-1.3-0.7-2c-0.7-1-1.4-1.5-2-1.9c-0.4-0.3-0.8-0.5-1.2-0.9
        c-0.7-0.8-1-1.6-1.1-1.8l0-1.1c0.5-0.2,0.8-0.6,0.8-1.1c0-0.5-0.3-1-0.8-1.2l0-0.2c0.3-0.2,0.5-0.4,0.5-0.8c0-0.3-0.2-0.6-0.6-0.8
        l0-0.6c0.4-0.2,0.6-0.6,0.6-1c0-0.4-0.2-0.8-0.6-1l0-0.7c0.3-0.2,0.4-0.4,0.4-0.8c0-0.3-0.2-0.6-0.5-0.8l0-0.7
        c0.2-0.2,0.3-0.4,0.3-0.7c0-0.3-0.1-0.5-0.4-0.7l0-1c0.4-0.3,0.7-0.8,0.7-1.2c0-0.9-0.8-1.6-1.8-1.6c0,0,0,0,0,0
        c-1.1,0-1.8,0.7-1.8,1.6c0,0.5,0.2,0.9,0.7,1.2l0,1c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.3,0.1,0.5,0.3,0.7l0,0.7c-0.3,0.2-0.5,0.4-0.5,0.8
        c0,0.3,0.2,0.6,0.4,0.8l0,0.7c-0.4,0.2-0.6,0.6-0.6,1c0,0.4,0.2,0.8,0.6,1l0,0.6c-0.3,0.1-0.6,0.5-0.6,0.8c0,0.3,0.2,0.6,0.5,0.8
        l0,0.2c-0.5,0.2-0.8,0.7-0.8,1.2c0,0.5,0.3,0.9,0.8,1.1l0,1c-0.1,0.2-0.3,1.1-1.1,1.9c-0.4,0.4-0.7,0.6-1.2,0.9
        c-0.6,0.4-1.3,0.8-2,1.9c-0.5,0.7-0.7,1.4-0.7,2c-12.1,1.5-22.2,6.9-27.2,14.7c-0.2,0.3-0.4,0.6-0.5,0.8c-0.2,0.3-0.5,0.7-0.9,1.4
        c-0.7,1.3-2.1,3.6-3,4c-2.1,0.8-4.4,0.5-4.4,0.5c-0.1,0-0.2,0-0.2,0c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0.1-0.2,0.2-0.2,0.4v0.4
        c0,0,0,0,0,0.1v4.7c-0.9-0.4-1.9-0.7-2.9-0.8v0c0.2-0.2,0.4-0.5,0.4-0.8c0-0.3-0.2-0.6-0.4-0.8l0-0.4c0.2-0.2,0.3-0.4,0.3-0.6
        c0-0.2-0.1-0.5-0.3-0.6l0-0.4c0.1-0.2,0.2-0.3,0.2-0.5c0-0.2-0.1-0.4-0.2-0.6l0-0.6c0.3-0.2,0.5-0.6,0.5-0.9c0-0.7-0.7-1.3-1.5-1.3
        c-0.8,0-1.5,0.6-1.5,1.3c0,0.4,0.2,0.7,0.5,1l0,0.5c-0.2,0.2-0.3,0.4-0.3,0.6c0,0.2,0.1,0.4,0.3,0.6l0,0.3c-0.2,0.2-0.4,0.4-0.4,0.6
        c0,0.2,0.1,0.5,0.3,0.6l0,0.3c-0.3,0.2-0.5,0.5-0.5,0.8c0,0.3,0.2,0.6,0.4,0.8v0c-3.3,0.4-6.2,2.6-7.5,5.8c0,0.1-0.1,0.2-0.1,0.3
        c-0.1,0.1-0.1,0.3-0.2,0.6c-0.1,0.4-0.5,1.2-0.6,1.3c-0.3,0.2-0.7,0.1-0.7,0.1c-0.2,0-0.3,0-0.5,0.1c-0.1,0.1-0.2,0.2-0.2,0.4v0.3
        c0,0,0,0,0,0.1v4.7c-0.4,0-0.9,0-1.3,0c-7.8,0-14.9,3.3-18.1,8.5c-0.1,0.2-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.4-0.5,0.8
        c-0.3,0.5-1.1,1.9-1.5,2.1c-1,0.4-2.2,0.3-2.2,0.3c-0.2,0-0.3,0-0.5,0.1c-0.1,0.1-0.2,0.2-0.2,0.4v0.1c0,0,0,0.1,0,0.1v9.2h-4.3
        v-6.2c0.1,0,0.3-0.1,0.3-0.3l2-4c0.1-0.1,0.2-0.2,0.2-0.4v-3.4c0-0.3-0.3-0.5-0.6-0.5h-1.9v-18.5c0.1,0,0.2-0.1,0.3-0.3l2-4
        c0.1-0.1,0.2-0.2,0.2-0.4v-3.4c0-0.3-0.3-0.5-0.6-0.5h-2.2v-17c0.2,0,0.4-0.1,0.5-0.3l1.9-3.9c0.1-0.1,0.2-0.2,0.2-0.4v-3.3
        c0-0.3-0.3-0.5-0.6-0.5h-2.6v-21.7c0.2-0.1,0.3-0.3,0.3-0.5v-1.2c0-0.3-0.3-0.5-0.6-0.5H55L51.8,288l0-0.8c0.3-0.2,0.4-0.5,0.4-0.8
        c0-0.3-0.2-0.6-0.5-0.8l0-0.4c0.2-0.2,0.3-0.4,0.3-0.7c0-0.3-0.1-0.5-0.4-0.7l0-0.5c0.2-0.2,0.3-0.4,0.3-0.6c0-0.2-0.1-0.5-0.3-0.6
        l0-0.5c0.2-0.1,0.3-0.3,0.3-0.5c0-0.2-0.1-0.4-0.3-0.5l0-0.4c0.4-0.2,0.6-0.6,0.6-1c0-0.3-0.1-0.5-0.3-0.8c-0.1-0.1-0.2-0.2-0.4-0.2
        v-0.1c-0.1-0.3-0.4-0.6-0.7-0.6c-0.4,0-0.7,0.3-0.7,0.6l0,0.1c-0.1,0-0.3,0.1-0.4,0.2c-0.2,0.2-0.3,0.5-0.3,0.8c0,0.4,0.2,0.8,0.6,1
        l0,0.5c-0.2,0.1-0.3,0.3-0.3,0.5c0,0.2,0.1,0.4,0.3,0.5l0,0.5c-0.2,0.2-0.3,0.4-0.3,0.6c0,0.2,0.1,0.4,0.3,0.6l0,0.5
        c-0.2,0.2-0.4,0.4-0.4,0.7c0,0.2,0.1,0.5,0.3,0.7l0,0.4c-0.3,0.2-0.5,0.5-0.5,0.8c0,0.3,0.2,0.6,0.4,0.8l-3.3,28.3h-0.6
        c-0.3,0-0.6,0.2-0.6,0.5v1.2c0,0.2,0.1,0.4,0.3,0.5v21.7h-2.6c-0.3,0-0.6,0.2-0.6,0.5v3.3c0,0.1,0.1,0.3,0.2,0.3l1.9,3.9
        c0.1,0.2,0.3,0.3,0.5,0.3v17h-2.2c-0.3,0-0.6,0.2-0.6,0.5v3.4c0,0.1,0.1,0.2,0.2,0.3l2,4.1c0.1,0.1,0.2,0.2,0.3,0.3v18.5h-1.9
        c-0.3,0-0.6,0.2-0.6,0.5v3.4c0,0.1,0.1,0.2,0.2,0.3l2,4.1c0.1,0.1,0.2,0.2,0.3,0.3v5.2c-2.2-0.4-4.3,0.8-5.2,2.6l0,0.1
        c0,0-0.1,0.2-0.1,0.3c-0.1,0.1-0.2,0.4-0.1,0.4l0.3,0.4l-0.5-0.4c-0.2,0-0.3,0.1-0.5,0.2c-0.1,0.1-0.2,0.3-0.2,0.5v5.5h-3.6v-12.7
        c0.2,0,0.3-0.1,0.3-0.3l2.2-4.4c0.1-0.1,0.2-0.2,0.2-0.4v-3.7c0-0.3-0.3-0.5-0.6-0.5h-2.1v-20.3c0.2,0,0.3-0.1,0.3-0.3l2.2-4.4
        c0.1-0.1,0.2-0.2,0.2-0.4v-3.7c0-0.3-0.3-0.5-0.6-0.5h-2.5v-18.7c0.2,0,0.4-0.1,0.5-0.3l2.1-4.3c0.1-0.1,0.2-0.2,0.2-0.4v-3.6
        c0-0.3-0.3-0.5-0.6-0.5h-2.8v-23.7c0.2-0.1,0.4-0.3,0.4-0.5v-1.3c0-0.3-0.3-0.5-0.6-0.5h-0.7l-3.5-30.2c0,0,0,0,0,0l0-0.8
        c0.3-0.2,0.4-0.5,0.4-0.8c0-0.3-0.2-0.6-0.5-0.8l0-0.5c0.2-0.2,0.4-0.4,0.4-0.7c0-0.3-0.1-0.5-0.4-0.7l0-0.6
        c0.2-0.2,0.3-0.4,0.3-0.6c0-0.3-0.1-0.5-0.4-0.7l0-0.6c0.2-0.2,0.3-0.4,0.3-0.5c0-0.2-0.1-0.4-0.3-0.5l0-0.5
        c0.4-0.2,0.7-0.6,0.7-1.1c0-0.3-0.1-0.6-0.3-0.8c-0.1-0.1-0.3-0.2-0.4-0.2l0-0.2c-0.1-0.3-0.4-0.6-0.7-0.6c-0.4,0-0.7,0.3-0.7,0.7
        l0,0.1c-0.1,0-0.3,0.1-0.4,0.2c-0.2,0.2-0.3,0.5-0.3,0.8c0,0.4,0.3,0.8,0.7,1.1l0,0.6c-0.2,0.2-0.3,0.4-0.3,0.5
        c0,0.2,0.1,0.4,0.3,0.5l0,0.6c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.2,0.1,0.5,0.3,0.6l0,0.6c-0.2,0.2-0.4,0.4-0.4,0.7
        c0,0.3,0.1,0.5,0.3,0.7l0,0.5c-0.3,0.2-0.5,0.5-0.5,0.8c0,0.3,0.2,0.6,0.4,0.8l-3.5,31h-0.7c-0.3,0-0.6,0.2-0.6,0.5v1.3
        c0,0.2,0.1,0.4,0.3,0.5v23.7h-2.8c-0.3,0-0.6,0.2-0.6,0.5v3.6c0,0.1,0.1,0.2,0.2,0.3l2.1,4.3c0.1,0.2,0.3,0.3,0.5,0.3v18.7h-2.5
        c-0.3,0-0.6,0.2-0.6,0.5v3.7c0,0.1,0.1,0.3,0.2,0.3l2.2,4.5c0.1,0.1,0.2,0.2,0.3,0.3v20.3h-2.1c-0.3,0-0.6,0.2-0.6,0.5v3.7
        c0,0.1,0.1,0.3,0.2,0.3l2.2,4.5c0.1,0.1,0.2,0.2,0.3,0.3v41.8H0v33.8h3873v-33.8H3744z M333.5,358.4c0.4-0.1,10.3-3.5,17.3,0.6
        c0.4,0.2,0.5,0.7,0.3,1.1c-0.1,0.2-0.4,0.4-0.7,0.4c-0.1,0-0.3,0-0.4-0.1c-6.3-3.8-15.8-0.5-15.9-0.5c-0.4,0.1-0.9-0.1-1-0.5
        C332.8,359,333.1,358.5,333.5,358.4z M318.1,366.7c-0.1,0.1-7.8,5.6-9.3,13c-0.1,0.4-0.4,0.6-0.8,0.6c-0.1,0-0.1,0-0.2,0
        c-0.4-0.1-0.7-0.5-0.6-0.9c1.6-8,9.6-13.7,9.9-14c0.4-0.2,0.9-0.2,1.1,0.2C318.5,366,318.4,366.4,318.1,366.7z M325.9,352.4
        C325.9,352.4,325.9,352.4,325.9,352.4c-0.5,0-0.8-0.3-0.8-0.7c-0.7-14.5-11-26.1-11.1-26.2c-0.3-0.3-0.3-0.8,0.1-1.1
        c0.3-0.3,0.8-0.3,1.1,0.1c0.4,0.5,10.7,12.1,11.5,27.2C326.7,352,326.3,352.4,325.9,352.4z M189.7,413c1.5-4.4,6.8-9.6,16.8-9.6
        c9.8,0,15.2,5.1,16.8,9.5c0.3,0.9-0.2,1.9-1.3,2.3c-0.2,0.1-0.4,0.1-0.6,0.1c-0.9,0-1.6-0.5-1.9-1.2c-1.3-3.4-5.3-7.1-13-7.1
        c-7.8,0-11.9,3.8-13,7c-0.3,0.9-1.5,1.5-2.5,1.2C190,414.9,189.4,413.9,189.7,413z M217.4,425.8h-4v-6.1c0-1,0.9-1.8,2-1.8
        c1.1,0,2,0.8,2,1.8L217.4,425.8z M208,425.8h-4v-6.1c0-1,0.9-1.8,2-1.8c1.1,0,2,0.8,2,1.8L208,425.8z M198.6,425.8h-4v-6.6h0.1
        c0.3-0.8,1-1.3,1.9-1.3c1.1,0,2,0.8,2,1.8L198.6,425.8z M163.4,370.7L163.4,370.7c0.3-0.8,1.1-1.3,2-1.3c1.1,0,2,0.8,2,1.8v9.2h-4
        V370.7z M154.1,371.2c0-1,0.9-1.8,2-1.8c1.1,0,2,0.8,2,1.8v9.2h-4V371.2z M144.7,371.2c0-1,0.9-1.8,2-1.8c0.9,0,1.7,0.6,1.9,1.3h0.1
        l0,9.7h-4V371.2z M135.3,371.2c0-1,0.9-1.8,2-1.8c1.1,0,2,0.8,2,1.8v9.2h-4V371.2z M125.9,371.2c0-1,0.9-1.8,2-1.8
        c1.1,0,2,0.8,2,1.8v9.2h-4V371.2z M116.5,370.7L116.5,370.7c0.3-0.8,1.1-1.3,2-1.3c1.1,0,2,0.8,2,1.8v9.2h-4V370.7z M113.8,396.3
        c2.5-5.5,11.5-12,28.3-12c16.5,0,25.4,6.2,28.3,12c0.2,0.4,0.2,0.9,0,1.4c-0.2,0.4-0.6,0.8-1.1,1c-1,0.4-2.2,0-2.6-0.9
        c-2.3-4.5-10.1-9.9-24.7-9.9c-14.9,0-22.6,5.3-24.6,9.8c-0.3,0.7-1,1.1-1.8,1.1c-0.3,0-0.5,0-0.8-0.1
        C113.9,398.3,113.4,397.2,113.8,396.3z M167.5,404.5v9.2h-4V404h0.1c0.3-0.8,1-1.3,1.9-1.3C166.6,402.7,167.5,403.5,167.5,404.5z
         M158.1,404.5v9.2h-4v-9.2c0-1,0.9-1.8,2-1.8C157.2,402.7,158.1,403.5,158.1,404.5z M148.7,413.7h-4v-9.2c0-1,0.9-1.8,2-1.8
        c0.9,0,1.7,0.6,1.9,1.3h0.1L148.7,413.7z M139.3,404.5v9.2h-4v-9.2c0-1,0.9-1.8,2-1.8C138.4,402.7,139.3,403.5,139.3,404.5z
         M129.9,404.5v9.2h-4v-9.2c0-1,0.9-1.8,2-1.8C129,402.7,129.9,403.5,129.9,404.5z M120.6,404.5v9.2h-4V404h0.1
        c0.2-0.8,1-1.3,1.9-1.3C119.6,402.7,120.5,403.5,120.6,404.5z M65.3,413c1.5-4.4,6.8-9.6,16.8-9.6c9.8,0,15.2,5.1,16.8,9.5
        c0.3,0.9-0.2,1.9-1.3,2.3c-0.2,0.1-0.4,0.1-0.6,0.1c-0.9,0-1.6-0.5-1.9-1.2c-1.3-3.4-5.3-7.1-13-7.1c-7.8,0-11.9,3.8-13,7
        c-0.3,0.9-1.5,1.5-2.5,1.2C65.5,414.9,64.9,413.9,65.3,413z M93.3,425.8h-4v-6.1c0-1,0.9-1.8,2-1.8c1.1,0,2,0.8,2,1.8L93.3,425.8z
         M83.9,425.8h-4v-6.1c0-1,0.9-1.8,2-1.8c1.1,0,2,0.8,2,1.8L83.9,425.8z M74.5,425.8h-4v-6.6h0.1c0.3-0.8,1-1.3,1.9-1.3
        c1.1,0,2,0.8,2,1.8L74.5,425.8z M250.1,434.3h-88.7c-1.1,0-2-0.8-2-1.8v-5.1h-34v5.1c0,1-0.9,1.8-2,1.8H37.3c-1.1,0-2-0.8-2-1.8
        c0-1,0.9-1.8,2-1.8h84.2v-5.1c0-1,0.9-1.8,2-1.8h38c1.1,0,2,0.8,2,1.8v5.1h86.7c1.1,0,2,0.8,2,1.8
        C252.1,433.5,251.2,434.3,250.1,434.3z M783.5,444.3h-4.4v-86.5h4.4V444.3z M792.8,444.3h-4.4v-86.5h4.4V444.3z M802.1,444.3h-4.3
        v-86.5h4.3V444.3z M811.4,444.3H807v-86.5h4.4V444.3z M820.7,444.3h-4.3v-86.5h4.3V444.3z M932.5,389.4l-2.6,16.7l-11.5-1.8
        l2.6-16.7c0.5-3.5,3.5-5.9,6.7-5.4C930.9,382.7,933,385.9,932.5,389.4z M877.8,369.9l2.6-17.1c0.5-3.5,3.5-5.9,6.7-5.4
        c3.2,0.5,5.3,3.7,4.8,7.2l-2.6,17.1L877.8,369.9z M887.7,382.5l-2.6,16.7l-11.5-1.8l2.6-16.7c0.5-3.5,3.5-5.9,6.7-5.4
        C886,375.8,888.2,379,887.7,382.5z M925.6,296.1c3.2,0.5,5.3,3.7,4.8,7.2l-2.6,17.1l-11.5-1.8l2.6-17.1
        C919.4,298.1,922.5,295.6,925.6,296.1z M920.6,290.7l2.6-17.1c0.5-3.5,3.5-5.9,6.7-5.4c3.2,0.5,5.3,3.7,4.8,7.2l-2.6,17.1
        L920.6,290.7z M926.1,331.2l-2.6,17.1l-11.5-1.8l2.6-17.1c0.5-3.5,3.5-5.9,6.7-5.4C924.5,324.5,926.7,327.8,926.1,331.2z
         M919.8,273.1l-2.6,17.1l-11.5-1.8l2.6-17.1c0.5-3.5,3.5-5.9,6.7-5.4C918.2,266.4,920.3,269.6,919.8,273.1z M910.7,293.8
        c3.2,0.5,5.3,3.7,4.8,7.2l-2.6,17.1l-11.5-1.8l2.6-17.1C904.5,295.8,907.5,293.3,910.7,293.8z M911.2,328.9l-2.6,17.1l-11.5-1.8
        l2.6-17.1c0.5-3.5,3.5-5.9,6.7-5.4C909.6,322.2,911.7,325.5,911.2,328.9z M904.8,270.8l-2.6,17.1l-11.5-1.8l2.6-17.1
        c0.5-3.5,3.5-5.9,6.7-5.4C903.2,264.1,905.4,267.3,904.8,270.8z M895.8,291.5c3.2,0.5,5.3,3.7,4.8,7.2l-2.6,17.1l-11.5-1.8l2.6-17.1
        C889.6,293.5,892.6,291,895.8,291.5z M891.5,319.4c3.2,0.5,5.3,3.7,4.8,7.2l-2.6,17.1l-11.5-1.8l2.6-17.1
        C885.3,321.4,888.3,319,891.5,319.4z M891.1,383c0.5-3.5,3.5-5.9,6.7-5.4c3.2,0.5,5.3,3.7,4.8,7.2l-2.6,16.7l-11.5-1.8L891.1,383z
         M892.8,372.2l2.6-17.1c0.5-3.5,3.5-5.9,6.7-5.4c3.2,0.5,5.3,3.7,4.8,7.2l-2.6,17.1L892.8,372.2z M906,385.3
        c0.5-3.5,3.5-5.9,6.7-5.4c3.2,0.5,5.3,3.7,4.8,7.2l-2.6,16.7l-11.5-1.8L906,385.3z M907.7,374.5l2.6-17.1c0.5-3.5,3.5-5.9,6.7-5.4
        c3.2,0.5,5.3,3.7,4.8,7.2l-2.6,17.1L907.7,374.5z M936.8,361.5l-2.6,17.1l-11.5-1.8l2.6-17.1c0.5-3.5,3.5-5.9,6.7-5.4
        C935.2,354.8,937.3,358,936.8,361.5z M941.1,333.5l-2.6,17.1l-11.5-1.8l2.6-17.1c0.5-3.5,3.5-5.9,6.7-5.4
        C939.5,326.8,941.6,330.1,941.1,333.5z M945.4,305.6l-2.6,17.1l-11.5-1.8l2.6-17.1c0.5-3.5,3.5-5.9,6.7-5.4
        C943.7,298.9,945.9,302.1,945.4,305.6z M949.7,277.7l-2.6,17.1l-11.5-1.8l2.6-17.1c0.5-3.5,3.5-5.9,6.7-5.4
        C948,271,950.2,274.2,949.7,277.7z M949.2,242.6c3.2,0.5,5.3,3.7,4.8,7.2l-2.6,17.1l-11.5-1.8l2.6-17.1
        C943,244.5,946,242.1,949.2,242.6z M927.7,216.3c0.9-5.7,5.9-9.7,11.2-8.9c5.3,0.8,8.9,6.1,8,11.9l-2.7,17.9l-3.2-0.5l2.7-17.9
        c0.6-3.9-1.8-7.6-5.3-8.1c-3.5-0.5-6.9,2.2-7.5,6.2l-2.7,17.9l-3.2-0.5L927.7,216.3z M927.5,245.7c0.5-3.5,3.5-5.9,6.7-5.4
        c3.2,0.5,5.3,3.7,4.8,7.2l-2.6,17.1l-11.5-1.8L927.5,245.7z M919.3,238c3.2,0.5,5.3,3.7,4.8,7.2l-2.6,17.1l-11.5-1.8l2.6-17.1
        C913.1,239.9,916.1,237.5,919.3,238z M898.5,211.9c0.9-5.7,5.9-9.7,11.2-8.9c5.3,0.8,8.9,6.1,8,11.9l-2.7,17.9l-3.2-0.5l2.7-17.9
        c0.6-3.9-1.8-7.6-5.3-8.1c-3.5-0.5-6.9,2.2-7.5,6.2l-2.7,17.9l-3.2-0.5L898.5,211.9z M897.6,241.1c0.5-3.5,3.5-5.9,6.7-5.4
        c3.2,0.5,5.3,3.7,4.8,7.2l-2.6,17.1l-11.5-1.8L897.6,241.1z M882.7,238.8c0.5-3.5,3.5-5.9,6.7-5.4c3.2,0.5,5.3,3.7,4.8,7.2
        l-2.6,17.1l-11.5-1.8L882.7,238.8z M878.4,266.7c0.5-3.5,3.5-5.9,6.7-5.4c3.2,0.5,5.3,3.7,4.8,7.2l-2.6,17.1l-11.5-1.8L878.4,266.7z
         M874.1,294.7c0.5-3.5,3.5-5.9,6.7-5.4c3.2,0.5,5.3,3.7,4.8,7.2l-2.6,17.1l-11.5-1.8L874.1,294.7z M869.8,322.6
        c0.5-3.5,3.5-5.9,6.7-5.4c3.2,0.5,5.3,3.7,4.8,7.2l-2.6,17.1l-11.5-1.8L869.8,322.6z M865.5,350.5c0.5-3.5,3.5-5.9,6.7-5.4
        c3.2,0.5,5.3,3.7,4.8,7.2l-2.6,17.1l-11.5-1.8L865.5,350.5z M861.2,378.4c0.5-3.5,3.5-5.9,6.7-5.4c3.2,0.5,5.3,3.7,4.8,7.2
        l-2.6,16.7l-11.5-1.8L861.2,378.4z M877.1,417.5l-4.1,26.8h-3.3l4.2-27.3c0.6-3.9-1.8-7.6-5.3-8.1c-3.5-0.5-6.9,2.2-7.5,6.2
        l-4.5,29.2h-3.3l4.6-29.7c0.9-5.7,5.9-9.7,11.2-8.9C874.4,406.4,878,411.7,877.1,417.5z M900.9,421.1l-3.6,23.1h-3.3l3.6-23.6
        c0.6-3.9-1.8-7.6-5.3-8.1c-3.5-0.5-6.9,2.2-7.5,6.2l-3.9,25.6h-3.3l4-26.1c0.9-5.7,5.9-9.7,11.2-8.9
        C898.2,410.1,901.8,415.4,900.9,421.1z M924.8,424.8l-3,19.4h-3.3l3.1-19.9c0.6-3.9-1.8-7.6-5.3-8.1c-3.5-0.5-6.9,2.2-7.5,6.2
        l-3.4,21.9h-3.3l3.4-22.4c0.9-5.7,5.9-9.7,11.2-8.9C922.1,413.8,925.7,419.1,924.8,424.8z M981.6,436.9c-0.2,0.2-0.5,0.3-0.8,0.3
        c-0.3,0-0.5-0.1-0.8-0.2c-0.5-0.4-0.5-1-0.1-1.3c9.7-8.9,8.9-40.3,8.8-40.6c0-0.5,0.5-1,1.1-1c0.6,0,1.1,0.4,1.1,0.9
        C991.1,396.3,991.9,427.4,981.6,436.9z M1035.1,433.8c-0.2,0.2-0.5,0.3-0.8,0.3c-0.3,0-0.5-0.1-0.7-0.2c-0.4-0.3-0.5-0.9-0.1-1.3
        c11.4-10.4,10.4-47,10.4-47.4c0-0.5,0.4-0.9,1-0.9c0.6,0,1.1,0.4,1.1,0.9C1046,386.7,1047,422.9,1035.1,433.8z M1100.4,427.1
        c-0.2,0.2-0.5,0.3-0.8,0.3c-0.3,0-0.5-0.1-0.7-0.2c-0.4-0.3-0.5-0.9-0.1-1.3c15.4-14.1,14-63.5,14-63.9c0-0.5,0.4-0.9,1-0.9
        c0.6,0,1.1,0.4,1.1,0.9C1115,364,1116.3,412.5,1100.4,427.1z M1205,444.3h-31.8V441h3.4c0.3,0,0.5-0.2,0.5-0.4V439h23.4v2
        c0,0.2,0.2,0.4,0.5,0.4h3.9V444.3z M1205,427.5h-3.9c-0.3,0-0.5,0.2-0.5,0.4v1.5h-23.4v-1.8c0-0.2-0.2-0.4-0.5-0.4h-3.4v-21.4h3.4
        c0.3,0,0.5-0.2,0.5-0.4V404h23.4v1.7c0,0.2,0.2,0.4,0.5,0.4h3.9V427.5z M1230.1,444.3h-10.3v-2.7h3.9c0.3,0,0.5-0.2,0.5-0.4v-2h2.2
        v1.2c0,0.2,0.2,0.4,0.5,0.4h3.2V444.3z M1230.1,427.7h-3.2c-0.3,0-0.5,0.2-0.5,0.4v1.3h-2.2v-1.5c0-0.2-0.2-0.4-0.5-0.4h-3.9v-21.4
        h3.9c0.3,0,0.5-0.2,0.5-0.4V404h2.2v2.2c0,0.2,0.2,0.4,0.5,0.4h3.2V427.7z M1247.6,444.3h-7.6v-3.6h3.1v0.2c0,0.2,0.2,0.4,0.5,0.4
        h3.9V444.3z M1247.6,427.1h-3.9c-0.3,0-0.5,0.2-0.5,0.4v0.1h-3.1v-21h3.2c0.3,0,0.5-0.2,0.5-0.4v-1.1h3.9V427.1z M1247.6,390.9h-3.9
        c-0.3,0-0.5,0.2-0.5,0.4v2.2h-3.1v-4.5h3.2c0.3,0,0.5-0.2,0.5-0.4v-2.2h3.7c0.1,0,0.1,0,0.2,0V390.9z M1268.1,444.3h-8.6v-2.9h3.9
        c0.3,0,0.5-0.2,0.5-0.4v-0.2c0.1,0.1,0.2,0.2,0.4,0.2h3.7V444.3z M1268.1,427.6h-3.7c-0.2,0-0.3,0.1-0.4,0.2v-0.2
        c0-0.2-0.2-0.4-0.5-0.4h-3.9v-22h3.9c0.2,0,0.3-0.1,0.4-0.2c0,0.2,0.2,0.4,0.5,0.4h3.7V427.6z M1268.1,392.1h-3.7
        c-0.2,0-0.3,0.1-0.4,0.2v-0.9c0-0.2-0.2-0.4-0.5-0.4h-3.9v-5h3.9c0.3,0,0.5-0.2,0.5-0.4V383h4.2V392.1z M1268.1,379.2l-14.8-1.1
        l-14.8,1.1c-0.3,0-0.5,0.2-0.5,0.4v2.5l-3.1-0.2c0,0-0.1,0-0.1,0l-12.2,1c-0.3,0-0.5,0.2-0.5,0.4v2.6c0,0.2,0.2,0.4,0.5,0.4h3.7v2.2
        c0,0.2,0.2,0.4,0.5,0.4h3.2v4.5h-3.2c-0.3,0-0.5,0.2-0.5,0.4v0.3h-2.2v-1.9c0-0.2-0.2-0.4-0.5-0.4h-3.9V360
        c20,1.2,41.7,2.5,47.2,2.8c0.4,0,0.8,0,1.2,0.1V379.2z M1268.1,351.4c-15.7-0.9-33.3-2.2-48.4-3.5v-8.6h2.1c0.3,0,0.5-0.2,0.5-0.4
        v-2.4c14.4,1.3,30,2.4,43.2,3v4.8c0,0.2,0.2,0.4,0.5,0.4h2V351.4z M1317,444.3h-34.7v-3.4h3.7c0.3,0,0.5-0.2,0.5-0.4V439h26v0.5
        c0,0.2,0.2,0.4,0.5,0.4h3.9V444.3z M1317,426.5h-3.9c-0.3,0-0.5,0.2-0.5,0.4v2.5h-26v-1.4c0-0.2-0.2-0.4-0.5-0.4h-3.7v-22.3h3.7
        c0.3,0,0.5-0.2,0.5-0.4v-1.3h26v1.5c0,0.2,0.2,0.4,0.5,0.4h3.9V426.5z M1390.1,390.2h-15.9v-20.1h0c0.1-3.2,3.6-5.9,7.9-5.9
        c4.3,0,7.8,2.6,7.9,5.9h0V390.2z M1400.3,341.8h2.5v-2.9c1.7-0.4,3.1-1,4.2-1.8v10.7c-2.3,0.1-4.5,0.2-6.7,0.2V341.8z M1446.1,444.3
        h-4.9v-1h-30.9v1h-4.9v-45.6h0c0.2-8.3,9.2-15,20.4-15c11.1,0,20.1,6.7,20.4,15h0V444.3z M1451.1,348.1c-2.3-0.1-4.7-0.2-7.2-0.3
        v-11c1.1,0.9,2.7,1.6,4.7,2.1v2.9h2.5V348.1z M1477.2,390.2h-15.9v-20.1h0c0.1-3.2,3.6-5.9,7.9-5.9c4.3,0,7.8,2.6,7.9,5.9h0V390.2z
         M1540.9,425.5v18.7h-11.7v-18.7h0c0-0.1,0-0.1,0-0.2c0-3.5,2.6-6.3,5.8-6.3s5.8,2.8,5.8,6.3
        C1540.9,425.4,1540.8,425.4,1540.9,425.5L1540.9,425.5z M1540.9,394v15.3c-4,0.6-7.9,1.2-11.7,1.8V394h0c0-0.1,0-0.1,0-0.2
        c0-3.5,2.7-5.7,5.8-6.3c3-0.5,5.8,2.8,5.8,6.3C1540.9,393.8,1540.8,393.9,1540.9,394L1540.9,394z M1541.1,361.5v15
        c-4.1,0.9-8,1.8-11.7,2.7v-15.3h0c0-0.1,0-0.1,0-0.2c-0.4-4.8,2.7-7.4,5.8-8.1c3.2-0.6,5.8,0.9,5.8,5.6
        C1541.1,361.3,1541.1,361.4,1541.1,361.5L1541.1,361.5z M1562.3,422.5v21.8h-13.7v-21.8h0c0-0.1,0-0.1,0-0.2c0-3.5,3.1-6.3,6.8-6.3
        c3.8,0,6.8,2.8,6.8,6.3C1562.3,422.3,1562.3,422.4,1562.3,422.5L1562.3,422.5z M1562.3,389.1v17.6c-4.7,0.5-9.2,1-13.7,1.6v-19.2h0
        c0-0.1,0-0.1,0-0.2c0-3.5,3.1-5.7,6.8-6.3c3.5-0.5,6.6,1.4,6.8,6.3C1562.3,388.9,1562.3,389,1562.3,389.1L1562.3,389.1z
         M1562.5,355.1v17.6c-4.7,0.7-9.3,1.4-13.7,2.2v-18.3c0-0.1,0-0.1,0-0.2c0-4,3.2-6.4,6.8-7.2c3.6-0.8,6.8,1.1,6.8,5.6
        C1562.5,355,1562.5,355.1,1562.5,355.1L1562.5,355.1z M1586.1,420v24.2H1571V420h0c0-0.1,0-0.1,0-0.2c0-3.5,3.4-6.3,7.6-6.3
        c4.2,0,7.6,2.8,7.6,6.3C1586.1,419.9,1586.1,420,1586.1,420L1586.1,420z M1586.1,384.8v19.9c-5.1,0.3-10.2,0.7-15.1,1.2v-21h0
        c0-0.1,0-0.1,0-0.2c0-3.5,3.4-5.8,7.6-6.3c3.9-0.5,7.6,2.8,7.6,6.3C1586.1,384.6,1586.1,384.7,1586.1,384.8L1586.1,384.8z
         M1586.3,351.5V370c-5.2,0.5-10.2,1-15.1,1.6v-20.1h0c0-0.1,0-0.1,0-0.2c0-3.1,3.4-5.2,7.5-5.8c4-0.6,7.6,1.6,7.6,5.8
        C1586.3,351.4,1586.3,351.4,1586.3,351.5L1586.3,351.5z M1612.3,417.5v26.8h-16.9v-26.8h0c0-0.1,0-0.1,0-0.2c0-3.5,3.8-6.3,8.5-6.3
        c4.7,0,8.5,2.8,8.5,6.3C1612.3,417.3,1612.3,417.4,1612.3,417.5L1612.3,417.5z M1612.3,380.5v22.8c-5.7,0.2-11.4,0.4-16.9,0.8v-23.6
        h0c0-0.1,0-0.1,0-0.2c0-3.5,3.9-5.6,8.5-6.3c4.7-0.7,8.5,2.8,8.5,6.3C1612.3,380.3,1612.3,380.4,1612.3,380.5L1612.3,380.5z
         M1639.8,416.5v27.8h-16.9v-27.8h0c0-0.1,0-0.1,0-0.2c0-3.5,3.8-6.3,8.5-6.3c4.7,0,8.5,2.8,8.5,6.3
        C1639.8,416.4,1639.8,416.4,1639.8,416.5L1639.8,416.5z M1686.7,417.5v26.8h-16.9v-26.8h0c0-0.1,0-0.1,0-0.2c0-3.5,3.8-6.3,8.5-6.3
        c4.7,0,8.5,2.8,8.5,6.3C1686.7,417.3,1686.7,417.4,1686.7,417.5L1686.7,417.5z M1686.7,380.5v24.3c-5.5-0.4-11.2-0.7-16.9-1v-23.3h0
        c0-0.1,0-0.1,0-0.2c0-3.5,3.8-6.3,8.5-6.3c4.7,0,8.5,2.8,8.5,6.3C1686.7,380.3,1686.7,380.4,1686.7,380.5L1686.7,380.5z M1711.1,420
        v24.2H1696V420h0c0-0.1,0-0.1,0-0.2c0-3.5,3.4-6.3,7.6-6.3c4.2,0,7.6,2.8,7.6,6.3C1711.1,419.9,1711.1,420,1711.1,420L1711.1,420z
         M1711.1,384.8v22.1c-4.9-0.5-10-1-15.1-1.4v-20.7h0c0-0.1,0-0.1,0-0.2c0-3.5,3.4-6.3,7.6-6.3c4.2,0,7.6,2.8,7.6,6.3
        C1711.1,384.6,1711.1,384.7,1711.1,384.8L1711.1,384.8z M1733.5,422.5v21.8h-13.7v-21.8h0c0-0.1,0-0.1,0-0.2c0-3.5,3.1-6.3,6.8-6.3
        c3.8,0,6.8,2.8,6.8,6.3C1733.5,422.3,1733.5,422.4,1733.5,422.5L1733.5,422.5z M1733.5,390.2v19.5c-4.4-0.7-9-1.3-13.7-1.9v-17.7h0
        c0-0.1,0-0.1,0-0.2c0-3.5,3.1-6.3,6.8-6.3c3.8,0,6.8,2.8,6.8,6.3C1733.5,390.1,1733.5,390.2,1733.5,390.2L1733.5,390.2z
         M1802.3,435.4c0-2.6,2.1-4.7,4.7-4.7c2.6,0,4.7,2.1,4.7,4.7c0,2.6-2.1,4.7-4.7,4.7C1804.4,440.1,1802.3,438,1802.3,435.4z
         M1893.4,435.4c0-2.6,2.1-4.7,4.7-4.7c2.6,0,4.7,2.1,4.7,4.7c0,2.6-2.1,4.7-4.7,4.7C1895.5,440.1,1893.4,438,1893.4,435.4z
         M1982.4,443.2h-6.7c-0.9,0-1.6-0.6-1.6-1.3c0-0.7,0.7-1.3,1.6-1.3h6.7c0.9,0,1.6,0.6,1.6,1.3
        C1983.9,442.6,1983.2,443.2,1982.4,443.2z M2020.1,443.2h-6.8c-0.9,0-1.6-0.6-1.6-1.3c0-0.7,0.7-1.3,1.6-1.3h6.8
        c0.9,0,1.6,0.6,1.6,1.3C2021.6,442.6,2020.9,443.2,2020.1,443.2z M2057.8,443.2h-6.8c-0.9,0-1.6-0.6-1.6-1.3c0-0.7,0.7-1.3,1.6-1.3
        h6.8c0.9,0,1.6,0.6,1.6,1.3C2059.4,442.6,2058.7,443.2,2057.8,443.2z M2095.5,443.2h-6.7c-0.9,0-1.6-0.6-1.6-1.3
        c0-0.7,0.7-1.3,1.6-1.3h6.7c0.9,0,1.6,0.6,1.6,1.3C2097.1,442.6,2096.4,443.2,2095.5,443.2z M2133.2,443.2h-6.7
        c-0.9,0-1.6-0.6-1.6-1.3c0-0.7,0.7-1.3,1.6-1.3h6.7c0.9,0,1.6,0.6,1.6,1.3C2134.8,442.6,2134.1,443.2,2133.2,443.2z M2171,443.2
        h-6.8c-0.9,0-1.6-0.6-1.6-1.3c0-0.7,0.7-1.3,1.6-1.3h6.8c0.9,0,1.6,0.6,1.6,1.3C2172.5,442.6,2171.8,443.2,2171,443.2z
         M2269.3,346.9h18.5l8,25.6c-0.5,0.3-13.3,0.1-17.2,0c-4,0.1-16.7,0.3-17.2,0L2269.3,346.9z M2223,444.3
        c5.2-23.9,28.1-41.8,55.6-41.8c27.5,0,50.4,18,55.6,41.8H2223z M2447.2,331.1h30.9c1.9,3.3,7.1,12.4,14,24.1l-9.7-6.3
        c-0.3-0.2-0.7-0.3-1-0.3c-0.7,0-1.5,0.5-1.9,1.4l-5.5,10.7l-7.9-9.4c-0.4-0.5-1-0.8-1.5-0.8c-0.5,0-1,0.2-1.5,0.7l-14.6,16.2
        c-0.5-8.4-0.8-13.5-0.9-16.4c0-1.3-0.5-2.4-1.3-3c-0.4-0.3-0.7-0.4-1.1-0.4c-0.5,0-0.9,0.2-1.3,0.6c-2.2,2-6.1,5.3-12,10.1
        C2439.5,345.1,2445.2,334.7,2447.2,331.1z M2481.2,394.8c0-0.1,0-0.3,0-0.5c-2.1-0.2-5.1-0.2-8.7,0.3c-5.2,0.8-11.6,4.4-12.2,3.1
        c-0.3-0.7,4.8-6.3,9.1-8.1c-5.4-0.8-10.1-0.4-13-0.3c-1.7,0-3.3,0.2-3.6,0c-0.5-0.5,2.7-2.4,4.9-3.1c1.6-0.5,5.1-1.9,10.6-0.6
        c5,1.1,9.6,3.8,12.2,5.4c0-0.1-0.1-0.1-0.1-0.2c-3.1-8-10.8-10.7-14.3-12.4c-1.3-0.7-2.7-1.2-2.7-1.5c0-0.7,4-1.2,6.3-1
        c1.6,0.2,5.5,0.3,8.5,3.8c1.3,1.5,2.3,3.2,3,4.9c0.2-3.3,0.9-7.2,2.7-10.2c2.9-5,8.5-5,9.3-4.9c0.9,0,0.7,2.1-0.2,4.7
        c-0.9,2.6-2.6,4.9-4.7,8.5c-0.7,1.2-1.6,2.8-2.6,4.4c1.6-1.8,3.7-3.8,6.3-5c4.2-2,7.8-0.6,9.4-0.1c2.2,0.7,5.6,2.8,5.4,3.4
        c-0.1,0.3-1.6,0.2-3.1,0.3c-3.9,0.2-12.1-0.4-18.2,5.8c-0.1,0.1-0.2,0.1-0.2,0.2c2.9-1.1,7.7-2.4,12.5-2.5c5.6,0,8.7,2.1,10.2,2.9
        c2,1.2,4.7,3.7,4.1,4.1c-0.3,0.2-1.9-0.4-3.5-0.7c-4.3-1.1-12.7-4-22.4-1.8c2.9,0.6,6.7,2.2,9,6.5c2,3.9,0.7,13-0.1,13.2
        c-1.4,0.3-2.6-6.8-5.3-11.2c-2-3.2-4-5.6-5.5-7c0.7,13.3-0.7,24.6-2.8,33.5c0,0,0,0,0,0c-0.8,3.3-1.6,6.3-2.5,8.9c0,0,0,0.1,0,0.1
        c-0.2,0.5-0.3,0.9-0.5,1.4c0,0.1,0,0.1-0.1,0.1c-0.2,0.5-0.3,0.9-0.5,1.3c0,0,0,0.1,0,0.1c-0.5,1.4-1,2.6-1.5,3.7h-8.7
        c0.6-0.8,1.2-1.6,1.7-2.5c0.3-0.5,0.6-1,0.9-1.6c0.1-0.1,0.2-0.3,0.3-0.4c0.4-0.6,0.7-1.3,1.1-2c0,0,0-0.1,0.1-0.1
        c1.1-2.2,2.1-4.4,3-6.7c0.1-0.1,0.1-0.3,0.2-0.4c0.2-0.6,0.5-1.2,0.7-1.8c0.1-0.2,0.2-0.4,0.2-0.7c0.2-0.5,0.4-1.1,0.5-1.6
        c0.1-0.3,0.2-0.5,0.3-0.8c0.2-0.5,0.3-1,0.5-1.5C2480.9,410.4,2481.8,397,2481.2,394.8z M2748.6,325.4c9.2,0,19.4-0.1,22.8-0.1
        l0-0.8l0.2,0.8c3.4,0,13.6,0.1,22.8,0.1v16.6c-14,0.2-31.7,0.2-45.8,0V325.4z M2732.1,387.9c-2.5,0-4.9-0.1-6.9-0.1v-17.4
        c1.4,0,3,0,5,0c0.5,0,1.2,0,1.9,0V387.9z M2734.2,325.4c1.4,0,3.5,0,6.9,0V342c-2.5,0-4.8-0.1-6.9-0.1V325.4z M2786.7,444.3h-3.8
        v-5.9h-22.8v5.9h-3.8v-27.1c9.9,0,20.5,0,30.4,0V444.3z M2803.4,388c-18.7,0.2-44.9,0.2-63.7,0v-17.7c16.5,0,47.2,0,63.7,0V388z
         M2808.8,341.8c-2,0.1-4.3,0.1-6.9,0.1v-16.5c3.3,0,5.5,0,6.9,0V341.8z M2817.8,387.8c-2,0.1-4.3,0.1-6.9,0.1v-17.6
        c0.7,0,1.4,0,1.9,0c2,0,3.6,0,5,0V387.8z M2935.9,430.3c0-3.7,0.1-10.8,0.1-12.6c0-2.5,0.9-3.6,1.8-4.5c1.1-1.2,3.1-2.1,3.6-2.4
        c0.1,0,0.2,0,0.3,0c0.5,0.3,2.5,1.2,3.6,2.4c0.9,0.9,1.8,2,1.8,4.5c0,1.8,0.1,8.9,0.1,12.6H2935.9z M2947.3,403.9c0,0-4.4,0-5.5,0
        c0,0-0.1,0-0.3,0c-1.2,0-5.5,0-5.5,0c0,0,0.1-12.4,0.1-14.8c0-2.5,0.9-3.6,1.8-4.5c1.1-1.2,3.1-2.1,3.6-2.4c0.1,0,0.2,0,0.3,0
        c0.5,0.3,2.5,1.2,3.6,2.4c0.9,0.9,1.8,2,1.8,4.5C2947.2,391.6,2947.3,403.9,2947.3,403.9z M2964.6,366.6c0-1.1,0.6-1.6,1.1-2
        c0.7-0.5,1.8-0.9,2.1-1c0,0,0.1,0,0.2,0c0.3,0.1,1.5,0.5,2.1,1c0.5,0.4,1.1,0.9,1.1,2c0,0.4,0,2.3,0,4.7h-3.2h-3.2h-0.3
        C2964.6,368.9,2964.6,366.9,2964.6,366.6z M2958,371.3c0-2.4,0-4.3,0-4.7c0-1.1,0.3-1.6,0.5-2c0.3-0.5,0.9-0.9,1-1c0,0,0.1,0,0.1,0
        c0.2,0.1,0.7,0.5,1,1c0.3,0.4,0.5,0.9,0.5,2c0,0.4,0,2.3,0,4.7h-3.1H2958z M2958.5,430.3c0-3.6,0.2-10.9,0.2-12.7
        c0.1-2.5,1.5-3.6,2.8-4.5c1.8-1.2,4.7-2.1,5.5-2.4c0.1,0,0.3,0,0.4,0c0.8,0.3,3.8,1.2,5.5,2.4c1.4,0.9,2.8,2,2.8,4.5
        c0.1,1.8,0.2,9.1,0.2,12.7H2958.5z M2976,403.9c-0.1,0-6.7,0-8.5,0c0,0-0.2,0-0.4,0c-1.8,0-8.5,0-8.5,0c-0.1,0,0.1-12.4,0.2-14.8
        c0.1-2.5,1.5-3.6,2.8-4.5c1.8-1.2,4.7-2.1,5.5-2.4c0.1,0,0.3,0,0.4,0c0.8,0.3,3.8,1.2,5.5,2.4c1.4,0.9,2.8,2,2.8,4.5
        C2975.8,391.6,2976,403.9,2976,403.9z M2974.4,371.3c0-2.4,0-4.3,0-4.7c0-1.1,0.3-1.6,0.5-2c0.3-0.5,0.9-0.9,1-1c0,0,0.1,0,0.1,0
        c0.2,0.1,0.7,0.5,1,1c0.3,0.4,0.5,0.9,0.5,2c0,0.4,0,2.3,0,4.7H2974.4z M2997.3,430.3c0-7.1,0.4-29.7,0.6-34.9c0.2-6.2,3.6-9,7-11.3
        c4.1-2.8,11.1-5.1,13.4-5.9c2.4,0.8,9.3,3.1,13.4,5.9c3.4,2.3,6.9,5.1,7,11.3c0.2,5.2,0.5,27.8,0.6,34.9H2997.3z M3065.4,366.6
        c0-1.1,0.6-1.6,1.1-2c0.7-0.5,1.8-0.9,2.1-1c0,0,0.1,0,0.2,0c0.3,0.1,1.5,0.5,2.1,1c0.5,0.4,1.1,0.9,1.1,2c0,0.3,0,2.3,0,4.7h-6.7
        C3065.3,368.9,3065.4,366.9,3065.4,366.6z M3058.9,366.6c0-1.1,0.3-1.6,0.5-2c0.3-0.5,0.9-0.9,1-1c0,0,0.1,0,0.1,0
        c0.1,0.1,0.7,0.5,1,1c0.3,0.4,0.5,0.9,0.5,2c0,0.4,0,2.3,0,4.7h-3.3C3058.9,368.9,3058.9,366.9,3058.9,366.6z M3060.6,430.3
        c0-3.6,0.2-10.9,0.2-12.7c0.1-2.5,1.5-3.6,2.8-4.5c1.7-1.2,4.7-2.1,5.5-2.4c0.1,0,0.3,0,0.4,0c0.8,0.2,3.8,1.2,5.5,2.4
        c1.4,0.9,2.8,2,2.8,4.5c0.1,1.8,0.2,9.1,0.2,12.7H3060.6z M3078.1,403.9c-0.1,0-6.7,0-8.5,0c-0.3,0-0.4,0-0.4,0c-1.8,0-8.5,0-8.5,0
        c-0.1,0,0.1-12.4,0.2-14.8c0.1-2.5,1.5-3.6,2.8-4.5c1.7-1.2,4.7-2.1,5.5-2.4c0.1,0,0.3,0,0.4,0c0.8,0.2,3.8,1.2,5.5,2.4
        c1.4,0.9,2.8,2,2.8,4.5C3077.9,391.6,3078.1,403.9,3078.1,403.9z M3075.3,371.3c0-2.4,0-4.3,0-4.7c0-1.1,0.3-1.6,0.5-2
        c0.3-0.5,0.9-0.9,1-1c0,0,0.1,0,0.1,0c0.2,0.1,0.7,0.5,1,1c0.3,0.4,0.5,0.9,0.5,2c0,0.4,0,2.3,0,4.7H3075.3z M3089.3,430.3
        c0-3.7,0.1-10.8,0.1-12.6c0-2.5,0.9-3.6,1.8-4.5c1.1-1.2,3-2.1,3.6-2.4c0.1,0,0.2,0,0.3,0c0.5,0.2,2.4,1.2,3.6,2.4
        c0.9,0.9,1.8,2,1.8,4.5c0,1.8,0.1,8.9,0.1,12.6H3089.3z M3100.6,403.9c0,0-4.4,0-5.5,0c-0.2,0-0.3,0-0.3,0c-1.2,0-5.5,0-5.5,0
        c0,0,0.1-12.4,0.1-14.8c0-2.5,0.9-3.6,1.8-4.5c1.1-1.2,3-2.1,3.6-2.4c0.1,0,0.2,0,0.3,0c0.5,0.2,2.4,1.2,3.6,2.4
        c0.9,0.9,1.8,2,1.8,4.5C3100.5,391.6,3100.7,403.9,3100.6,403.9z M3195,435.4c0-2.6,2.1-4.7,4.7-4.7c2.6,0,4.7,2.1,4.7,4.7
        c0,2.6-2.1,4.7-4.7,4.7C3197.1,440.1,3195,438,3195,435.4z M3286.1,435.4c0-2.6,2.1-4.7,4.7-4.7c2.6,0,4.7,2.1,4.7,4.7
        c0,2.6-2.1,4.7-4.7,4.7C3288.2,440.1,3286.1,438,3286.1,435.4z"
      />
      <path
        className="st0"
        d="M3183.1,413.6l153.3-0.1v-37.8c0-0.1-1-5.5-7.4-5.5h-136.5c-5.5,0-24.2,2.1-28.9,10.4c-0.8,1.4-0.1-0.1-1.2,2.3
        c0,0.1-0.1,0.2-0.1,0.2c-0.1,0.3-8.6,23.7-8.6,23.7c0,0,0,0,0,0.1c0,0,0,0,0,0c-1,3.2-1.8,8.8-2.4,13.3h11.6c1.8,0,3.3,1.5,3.3,3.3
        v11.6c0,0,0,0,0,0.1h14.3c0-7.7-0.1-18.4-0.1-19.5C3180.4,414,3181.5,413.6,3183.1,413.6z M3328,377.3v15.4c-2.1,0-10.9,0-23.1,0.1
        l-0.1-15.5C3317.1,377.3,3326,377.3,3328,377.3z M3301.5,377.3l0.1,15.5c-7.5,0-16,0-25,0.1l-0.1-15.7
        C3285.4,377.2,3294,377.2,3301.5,377.3z M3273.2,377.2l0.1,15.7c-8.2,0-16.7,0-25,0.1l-0.1-15.8
        C3256.4,377.2,3264.9,377.2,3273.2,377.2z M3244.9,377.2l0.1,15.8c-8.9,0-17.5,0-25,0.1l-0.1-15.9
        C3227.1,377.2,3235.7,377.2,3244.9,377.2z M3197.7,377.1c0.6,0,1.1,0,1.4,0c0.2,0,0.3,0,0.4,0c0.3,0,7,0,17.1,0l0.1,15.9
        c-9.4,0-16.8,0-20.8,0c-1.4,0-2.8,0.1-4.2,0.2l-0.1-16.2C3194.3,377,3196.4,377.1,3197.7,377.1z M3158,404.5l7.5-21
        c7.2-4.4,16-5.8,22.7-6.3l0.1,16.5C3177.6,395.5,3166.1,400.4,3158,404.5z"
      />
      <path
        className="st0"
        d="M3324.2,420.2h12.2v-4l-153.2,0.1l0.1,18.9h4.5c0.1-6.5,5.4-11.7,11.9-11.7c6.5,0,11.8,5.2,11.9,11.7h67.4
        c0.1-6.5,5.4-11.7,11.9-11.7c6.5,0,11.8,5.2,11.9,11.7h18.2c0,0,0,0,0-0.1v-11.6C3320.9,421.7,3322.4,420.2,3324.2,420.2z"
      />
      <path
        className="st0"
        d="M3414.2,138.6h4c0.5,0,1-0.4,1-1c0-0.6-0.4-1-1-1h-4c-0.5,0-1,0.4-1,1C3413.2,138.1,3413.7,138.6,3414.2,138.6z
        "
      />
      <path
        className="st0"
        d="M3119.6,276.9h4c0.5,0,1-0.4,1-1s-0.4-1-1-1h-4c-0.5,0-1,0.4-1,1S3119,276.9,3119.6,276.9z"
      />
      <path
        className="st0"
        d="M3088.1,276.9h7.9c0.6,0,1-0.4,1-1s-0.4-1-1-1h-7.9c-0.6,0-1,0.4-1,1S3087.5,276.9,3088.1,276.9z"
      />
      <path
        className="st0"
        d="M3072.3,276.9h7.9c0.6,0,1-0.4,1-1s-0.4-1-1-1h-7.9c-0.6,0-1,0.4-1,1S3071.8,276.9,3072.3,276.9z"
      />
      <path
        className="st0"
        d="M3103.8,276.9h7.9c0.5,0,1-0.4,1-1s-0.4-1-1-1h-7.9c-0.5,0-1,0.4-1,1S3103.3,276.9,3103.8,276.9z"
      />
      <path
        className="st0"
        d="M3324.2,436.1h5.2c2.7-0.1,9.6-2.1,9.6-9.2v-3.3c0-0.5-0.4-0.9-0.9-0.9h-1.7h-12.2c-0.5,0-0.9,0.4-0.9,0.9v11.6
        c0,0,0,0,0,0.1C3323.3,435.7,3323.7,436.1,3324.2,436.1z"
      />
      <polygon className="st0" points="3377.8,419.4 3485.3,419.4 3490.6,399.3 3390.7,399.3 " />
      <rect x="3499.8" y="318.3" className="st0" width="53.2" height="5" />
      <path
        className="st0"
        d="M3504.8,138.6h7.9c0.6,0,1-0.4,1-1c0-0.6-0.4-1-1-1h-7.9c-0.5,0-1,0.4-1,1
        C3503.8,138.1,3504.3,138.6,3504.8,138.6z"
      />
      <path
        className="st0"
        d="M3567.8,138.6h7.9c0.6,0,1-0.4,1-1c0-0.6-0.4-1-1-1h-7.9c-0.6,0-1,0.4-1,1
        C3566.8,138.1,3567.3,138.6,3567.8,138.6z"
      />
      <path
        className="st0"
        d="M3056.6,276.9h7.9c0.6,0,1-0.4,1-1s-0.4-1-1-1h-7.9c-0.6,0-1,0.4-1,1S3056,276.9,3056.6,276.9z"
      />
      <path
        className="st0"
        d="M3520.6,138.6h7.9c0.6,0,1-0.4,1-1c0-0.6-0.4-1-1-1h-7.9c-0.6,0-1,0.4-1,1
        C3519.6,138.1,3520,138.6,3520.6,138.6z"
      />
      <path
        className="st0"
        d="M3552.1,138.6h7.9c0.6,0,1-0.4,1-1c0-0.6-0.4-1-1-1h-7.9c-0.6,0-1,0.4-1,1
        C3551.1,138.1,3551.5,138.6,3552.1,138.6z"
      />
      <path
        className="st0"
        d="M3536.3,138.6h7.9c0.6,0,1-0.4,1-1c0-0.6-0.4-1-1-1h-7.9c-0.6,0-1,0.4-1,1
        C3535.3,138.1,3535.8,138.6,3536.3,138.6z"
      />
      <path
        className="st0"
        d="M3489.1,138.6h7.9c0.5,0,1-0.4,1-1c0-0.6-0.4-1-1-1h-7.9c-0.5,0-1,0.4-1,1
        C3488.1,138.1,3488.5,138.6,3489.1,138.6z"
      />
      <path
        className="st0"
        d="M3441.8,138.6h7.9c0.6,0,1-0.4,1-1c0-0.6-0.4-1-1-1h-7.9c-0.5,0-1,0.4-1,1
        C3440.8,138.1,3441.3,138.6,3441.8,138.6z"
      />
      <polygon className="st0" points="3497.3,374 3501.3,359.1 3456.5,359.1 3447,374 " />
      <path
        className="st0"
        d="M3426.1,138.6h7.9c0.5,0,1-0.4,1-1c0-0.6-0.4-1-1-1h-7.9c-0.5,0-1,0.4-1,1
        C3425.1,138.1,3425.5,138.6,3426.1,138.6z"
      />
      <path
        className="st0"
        d="M3473.3,138.6h7.9c0.6,0,1-0.4,1-1c0-0.6-0.4-1-1-1h-7.9c-0.6,0-1,0.4-1,1
        C3472.3,138.1,3472.8,138.6,3473.3,138.6z"
      />
      <path
        className="st0"
        d="M3457.6,138.6h7.9c0.6,0,1-0.4,1-1c0-0.6-0.4-1-1-1h-7.9c-0.6,0-1,0.4-1,1
        C3456.6,138.1,3457,138.6,3457.6,138.6z"
      />
      <polygon className="st0" points="3502.3,355.2 3505.7,342.2 3483.5,342.2 3475.1,355.2 " />
      <rect x="701.7" y="349.3" className="st0" width="2.4" height="5" />
      <rect x="728.5" y="349.3" className="st0" width="2.4" height="5" />
      <rect x="733.9" y="349.3" className="st0" width="2.4" height="5" />
      <rect x="739.2" y="349.3" className="st0" width="2.4" height="5" />
      <rect x="707.1" y="349.3" className="st0" width="2.4" height="5" />
      <rect x="712.4" y="349.3" className="st0" width="2.4" height="5" />
      <rect x="717.8" y="349.3" className="st0" width="2.4" height="5" />
      <rect x="723.1" y="349.3" className="st0" width="2.4" height="5" />
      <rect x="744.6" y="349.3" className="st0" width="2.4" height="5" />
      <path
        className="st0"
        d="M690.8,368v-3.2H673v3.2h0c0.1,1.2,1.3,2.2,2.9,2.5v0h11.9v0C689.5,370.2,690.6,369.2,690.8,368L690.8,368z"
      />
      <path
        className="st0"
        d="M719.6,368v-3.2h-17.8v3.2h0c0.1,1.2,1.3,2.2,2.9,2.5v0h11.9v0C718.3,370.2,719.5,369.2,719.6,368L719.6,368z"
      />
      <polygon className="st0" points="704.6,373.5 702.1,430.7 710.7,430.7 710.7,430.7 719.3,430.7 716.8,373.5 " />
      <polygon className="st0" points="733.4,373.5 730.9,430.7 739.5,430.7 739.5,430.7 748.1,430.7 745.6,373.5 " />
      <path
        className="st0"
        d="M748.4,368v-3.2h-17.8v3.2h0c0.1,1.2,1.3,2.2,2.9,2.5v0h11.9v0C747.1,370.2,748.3,369.2,748.4,368L748.4,368z"
      />
      <rect x="626.7" y="349.3" className="st0" width="2.4" height="5" />
      <rect x="658.9" y="349.3" className="st0" width="2.4" height="5" />
      <rect x="642.8" y="349.3" className="st0" width="2.4" height="5" />
      <rect x="696.4" y="349.3" className="st0" width="2.4" height="5" />
      <rect x="648.2" y="349.3" className="st0" width="2.4" height="5" />
      <polygon className="st0" points="675.8,373.5 673.3,430.7 681.9,430.7 681.9,430.7 690.5,430.7 688,373.5 " />
      <rect x="632.1" y="349.3" className="st0" width="2.4" height="5" />
      <rect x="637.4" y="349.3" className="st0" width="2.4" height="5" />
      <rect x="653.5" y="349.3" className="st0" width="2.4" height="5" />
      <rect x="685.6" y="349.3" className="st0" width="2.4" height="5" />
      <rect x="664.2" y="349.3" className="st0" width="2.4" height="5" />
      <rect x="680.3" y="349.3" className="st0" width="2.4" height="5" />
      <rect x="691" y="349.3" className="st0" width="2.4" height="5" />
      <rect x="674.9" y="349.3" className="st0" width="2.4" height="5" />
      <rect x="669.6" y="349.3" className="st0" width="2.4" height="5" />
      <path
        className="st0"
        d="M512.1,78.7h7.7c0.6,0,1-0.4,1-1s-0.4-1-1-1h-7.7c-0.6,0-1,0.4-1,1S511.5,78.7,512.1,78.7z"
      />
      <path
        className="st0"
        d="M573.9,78.7h7.7c0.6,0,1-0.4,1-1s-0.4-1-1-1h-7.7c-0.6,0-1,0.4-1,1S573.4,78.7,573.9,78.7z"
      />
      <path
        className="st0"
        d="M635.8,78.7h7.7c0.6,0,1-0.4,1-1s-0.4-1-1-1h-7.7c-0.6,0-1,0.4-1,1S635.2,78.7,635.8,78.7z"
      />
      <path className="st0" d="M543,78.7h7.7c0.6,0,1-0.4,1-1s-0.4-1-1-1H543c-0.6,0-1,0.4-1,1S542.4,78.7,543,78.7z" />
      <path
        className="st0"
        d="M589.4,78.7h7.7c0.6,0,1-0.4,1-1s-0.4-1-1-1h-7.7c-0.5,0-1,0.4-1,1S588.8,78.7,589.4,78.7z"
      />
      <path className="st0" d="M527.5,78.7h7.7c0.5,0,1-0.4,1-1s-0.4-1-1-1h-7.7c-0.6,0-1,0.4-1,1S527,78.7,527.5,78.7z" />
      <path
        className="st0"
        d="M620.3,78.7h7.7c0.6,0,1-0.4,1-1s-0.4-1-1-1h-7.7c-0.6,0-1,0.4-1,1S619.8,78.7,620.3,78.7z"
      />
      <path
        className="st0"
        d="M604.8,78.7h7.7c0.5,0,1-0.4,1-1s-0.4-1-1-1h-7.7c-0.6,0-1,0.4-1,1S604.3,78.7,604.8,78.7z"
      />
      <path
        className="st0"
        d="M558.5,78.7h7.7c0.6,0,1-0.4,1-1s-0.4-1-1-1h-7.7c-0.5,0-1,0.4-1,1S557.9,78.7,558.5,78.7z"
      />
      <rect x="621.4" y="349.3" className="st0" width="2.4" height="5" />
      <rect x="1410.2" y="422.5" className="st0" width="30.9" height="5.5" />
      <path
        className="st0"
        d="M1591.4,175.3h8c0.5,0,1-0.4,1-1s-0.4-1-1-1h-8c-0.6,0-1,0.4-1,1S1590.8,175.3,1591.4,175.3z"
      />
      <g>
        <path
          className="st1"
          d="M3282.6,142.6c5.9,2.2,12.1,2.2,12.3,2.2h0h20.6l9.6,11.1h-1.5c-1,0-1.9,0.4-2.6,1c-0.2,0.2-0.3,0.4-0.3,0.7
          v4.4c0,0.3,0.1,0.5,0.3,0.7c0.7,0.7,1.7,1,2.6,1h8.3l7.9,9.1h-0.8c-1,0-1.9,0.4-2.6,1c-0.2,0.2-0.3,0.4-0.3,0.7v4.3
          c0,0.3,0.1,0.5,0.3,0.7c0.7,0.7,1.7,1,2.6,1h7.6l14.8,17.1c0.2,0.2,0.4,0.3,0.7,0.3h7.4c0.3,0,0.6-0.2,0.8-0.5
          c0.2-0.3,0.2-0.7,0-0.9l-20.3-34.9l-5-16.9h30.5c4.5,0,7.5-0.8,7.6-0.8l4.6-0.9l15.5,16.5c0.2,0.2,0.4,0.3,0.7,0.3h4.6
          c0.3,0,0.6-0.1,0.7-0.4c0.2-0.2,0.2-0.5,0.2-0.8l-4.9-19.1l1.4-0.3c0.2,0,0.5-0.2,0.6-0.4c0.1-0.2,0.2-0.5,0.1-0.7l-0.2-0.7
          l0.2-0.7c0.1-0.2,0-0.5-0.1-0.7c-0.1-0.2-0.4-0.4-0.6-0.4l-1.4-0.3l4.9-19.1c0.1-0.3,0-0.6-0.2-0.8c-0.2-0.2-0.5-0.4-0.7-0.4h-4.6
          c-0.3,0-0.5,0.1-0.7,0.3l-15.5,16.5l-4.5-0.9c-0.1,0-3.1-0.8-7.6-0.8h-30.5l5-16.9l20.3-34.9c0.2-0.3,0.2-0.7,0-0.9
          c-0.2-0.3-0.5-0.5-0.8-0.5h-7.4c-0.3,0-0.5,0.1-0.7,0.3l-14.8,17.1h-7.6c-1,0-1.9,0.4-2.6,1c-0.2,0.2-0.3,0.4-0.3,0.7v4.3
          c0,0.3,0.1,0.5,0.3,0.7c0.7,0.7,1.7,1,2.6,1h0.8l-7.9,9.1h-8.3c-1,0-1.9,0.4-2.6,1c-0.2,0.2-0.3,0.4-0.3,0.7v4.3
          c0,0.3,0.1,0.5,0.3,0.7c0.7,0.7,1.7,1,2.6,1h1.5l-9.6,11.1H3295c-0.3,0-6.4,0-12.3,2.2c-5.9,2.2-7.6,3.3-7.4,5.1
          C3275.1,139.3,3276.7,140.4,3282.6,142.6z"
        />
        <path
          className="st1"
          d="M3127,297.9c0.2,0.2,0.5,0.4,0.7,0.4h4.6c0.3,0,0.5-0.1,0.7-0.3l15.5-16.5l4.5,0.9c0.1,0,3.1,0.8,7.6,0.8h30.5
          l-5,16.9l-20.3,34.9c-0.2,0.3-0.2,0.7,0,0.9c0.2,0.3,0.5,0.5,0.8,0.5h7.4c0.3,0,0.5-0.1,0.7-0.3l14.8-17.1h7.6c1,0,1.9-0.4,2.6-1
          c0.2-0.2,0.3-0.4,0.3-0.7v-4.3c0-0.3-0.1-0.5-0.3-0.7c-0.7-0.7-1.7-1-2.6-1h-0.8l7.9-9.1h8.3c1,0,1.9-0.4,2.6-1
          c0.2-0.2,0.3-0.4,0.3-0.7V296c0-0.3-0.1-0.5-0.3-0.7c-0.7-0.7-1.7-1-2.6-1h-1.5l9.6-11.1h20.6c0.3,0,6.4,0,12.3-2.2
          c5.9-2.2,7.6-3.3,7.4-5.1c0.2-1.8-1.5-2.9-7.4-5.1c-5.9-2.2-12.1-2.2-12.3-2.2h0h-20.6l-9.6-11.1h1.5c1,0,1.9-0.4,2.6-1
          c0.2-0.2,0.3-0.4,0.3-0.7v-4.3c0-0.3-0.1-0.5-0.3-0.7c-0.7-0.7-1.7-1-2.6-1h-8.3l-7.9-9.1h0.8c1,0,1.9-0.4,2.6-1
          c0.2-0.2,0.3-0.4,0.3-0.7v-4.3c0-0.3-0.1-0.5-0.3-0.7c-0.7-0.7-1.7-1-2.6-1h-7.6l-14.8-17.1c-0.2-0.2-0.4-0.3-0.7-0.3h-7.4
          c-0.3,0-0.6,0.2-0.8,0.5c-0.2,0.3-0.2,0.7,0,0.9l20.3,34.9l5,16.9h-30.5c-4.5,0-7.5,0.8-7.6,0.8l-4.6,0.9l-15.5-16.5
          c-0.2-0.2-0.4-0.3-0.7-0.3h-4.6c-0.3,0-0.6,0.1-0.7,0.4c-0.2,0.2-0.2,0.5-0.2,0.8l4.9,19.1l-1.4,0.3c-0.2,0-0.5,0.2-0.6,0.4
          c-0.1,0.2-0.2,0.5-0.1,0.7l0.2,0.7l-0.2,0.7c-0.1,0.2,0,0.5,0.1,0.7c0.1,0.2,0.4,0.4,0.6,0.4l1.4,0.3l-4.9,19.1
          C3126.8,297.4,3126.8,297.7,3127,297.9z"
        />
        <path
          className="st1"
          d="M1693.8,196.3c0.2,0.2,0.5,0.4,0.7,0.4h4.6c0.3,0,0.5-0.1,0.7-0.3l15.5-16.5l4.5,0.9c0.1,0,3.1,0.8,7.6,0.8
          h30.5l-5,16.9l-20.3,34.9c-0.2,0.3-0.2,0.7,0,0.9c0.2,0.3,0.5,0.5,0.8,0.5h7.4c0.3,0,0.5-0.1,0.7-0.3l14.8-17.1h7.6
          c1,0,1.9-0.4,2.6-1c0.2-0.2,0.3-0.4,0.3-0.7v-4.3c0-0.3-0.1-0.5-0.3-0.7c-0.7-0.7-1.7-1-2.6-1h-0.8l7.9-9.1h8.3c1,0,1.9-0.4,2.6-1
          c0.2-0.2,0.3-0.4,0.3-0.7v-4.3c0-0.3-0.1-0.5-0.3-0.7c-0.7-0.7-1.7-1-2.6-1h-1.5l9.6-11.1h20.6c0.3,0,6.4,0,12.3-2.2
          c5.9-2.2,7.6-3.3,7.4-5.1c0.2-1.8-1.5-2.9-7.4-5.1c-5.9-2.2-12.1-2.2-12.3-2.2h0h-20.6l-9.6-11.1h1.5c1,0,1.9-0.4,2.6-1
          c0.2-0.2,0.3-0.4,0.3-0.7v-4.3c0-0.3-0.1-0.5-0.3-0.7c-0.7-0.7-1.7-1-2.6-1h-8.3l-7.9-9.1h0.8c1,0,1.9-0.4,2.6-1
          c0.2-0.2,0.3-0.4,0.3-0.7v-4.3c0-0.3-0.1-0.5-0.3-0.7c-0.7-0.7-1.7-1-2.6-1h-7.6l-14.8-17.1c-0.2-0.2-0.4-0.3-0.7-0.3h-7.4
          c-0.3,0-0.6,0.2-0.8,0.5c-0.2,0.3-0.2,0.7,0,0.9l20.3,34.9l5,16.9h-30.5c-4.5,0-7.5,0.8-7.6,0.8l-4.6,0.9l-15.5-16.5
          c-0.2-0.2-0.4-0.3-0.7-0.3h-4.6c-0.3,0-0.6,0.1-0.7,0.4s-0.2,0.5-0.2,0.8l4.9,19.1l-1.4,0.3c-0.2,0-0.5,0.2-0.6,0.4
          c-0.1,0.2-0.2,0.5-0.1,0.7l0.2,0.7l-0.2,0.7c-0.1,0.2,0,0.5,0.1,0.7c0.1,0.2,0.4,0.4,0.6,0.4l1.4,0.3l-4.9,19.1
          C1693.5,195.8,1693.6,196.1,1693.8,196.3z"
        />
        <path
          className="st1"
          d="M351.5,82.8c5.9,2.2,12.1,2.2,12.3,2.2h20.6l9.6,11.1h-1.5c-1,0-1.9,0.4-2.6,1c-0.2,0.2-0.3,0.4-0.3,0.7v4.3
          c0,0.3,0.1,0.5,0.3,0.7c0.7,0.7,1.7,1,2.6,1h8.3l7.9,9.1h-0.8c-1,0-1.9,0.4-2.6,1c-0.2,0.2-0.3,0.4-0.3,0.7v4.3
          c0,0.3,0.1,0.5,0.3,0.7c0.7,0.7,1.7,1,2.6,1h7.6l14.8,17.1c0.2,0.2,0.4,0.3,0.7,0.3h7.4c0.3,0,0.6-0.2,0.8-0.5
          c0.2-0.3,0.2-0.7,0-0.9l-20.3-34.9l-5-16.9h30.5c4.5,0,7.5-0.8,7.6-0.8l4.6-0.9L472,99.9c0.2,0.2,0.4,0.3,0.7,0.3h4.6
          c0.3,0,0.6-0.1,0.7-0.4c0.2-0.2,0.2-0.5,0.2-0.8l-4.9-19.1l1.4-0.3c0.2,0,0.5-0.2,0.6-0.4c0.1-0.2,0.2-0.5,0.1-0.7l-0.2-0.7
          l0.2-0.7c0.1-0.2,0-0.5-0.1-0.7c-0.1-0.2-0.4-0.4-0.6-0.4l-1.4-0.3l4.9-19.1c0.1-0.3,0-0.6-0.2-0.8c-0.2-0.2-0.5-0.4-0.7-0.4h-4.6
          c-0.3,0-0.5,0.1-0.7,0.3l-15.5,16.5l-4.5-0.9c-0.1,0-3.1-0.8-7.6-0.8h-30.5l5-16.9l20.3-34.9c0.2-0.3,0.2-0.7,0-0.9
          c-0.2-0.3-0.5-0.5-0.8-0.5h-7.4c-0.3,0-0.5,0.1-0.7,0.3l-14.8,17.1h-7.6c-1,0-1.9,0.4-2.6,1c-0.2,0.2-0.3,0.4-0.3,0.7v4.3
          c0,0.3,0.1,0.5,0.3,0.7c0.7,0.7,1.7,1,2.6,1h0.8l-7.9,9.1h-8.3c-1,0-1.9,0.4-2.6,1c-0.2,0.2-0.3,0.4-0.3,0.7v4.3
          c0,0.3,0.1,0.5,0.3,0.7c0.7,0.7,1.7,1,2.6,1h1.5l-9.6,11.1h-20.6c-0.3,0-6.4,0-12.3,2.2c-5.9,2.2-7.6,3.3-7.4,5.1
          C343.9,79.5,345.6,80.6,351.5,82.8z"
        />
      </g>
      <path
        className="st0"
        d="M496.6,78.7h7.7c0.5,0,1-0.4,1-1s-0.4-1-1-1h-7.7c-0.6,0-1,0.4-1,1S496.1,78.7,496.6,78.7z"
      />
      <path className="st0" d="M484.9,78.7h4c0.6,0,1-0.4,1-1s-0.4-1-1-1h-4c-0.6,0-1,0.4-1,1S484.3,78.7,484.9,78.7z" />
      <polygon
        className="st0"
        points="638.7,313.1 638.7,313.1 638.6,313.1 638.6,313.1 526.5,343.9 526.5,346.3 638.6,346.3 638.7,346.3
        750.8,346.3 750.8,343.9 638.7,313.1 "
      />
      <polygon className="st0" points="618.2,373.5 615.7,430.7 624.2,430.7 624.3,430.7 632.8,430.7 630.3,373.5 " />
      <path
        className="st0"
        d="M604.3,368v-3.2h-17.8v3.2h0c0.1,1.2,1.3,2.2,2.9,2.5v0h11.9v0C603,370.2,604.2,369.2,604.3,368L604.3,368z"
      />
      <polygon className="st0" points="589.3,373.5 586.9,430.7 595.4,430.7 595.4,430.7 604,430.7 601.5,373.5 " />
      <path
        className="st0"
        d="M633.2,368v-3.2h-17.8v3.2h0c0.1,1.2,1.3,2.2,2.9,2.5v0h11.9v0C631.8,370.2,633,369.2,633.2,368L633.2,368z"
      />
      <polygon className="st0" points="647,373.5 644.5,430.7 653,430.7 653.1,430.7 661.6,430.7 659.1,373.5 " />
      <path
        className="st0"
        d="M575.5,368v-3.2h-17.8v3.2h0c0.1,1.2,1.3,2.2,2.9,2.5v0h11.9v0C574.2,370.2,575.4,369.2,575.5,368L575.5,368z"
      />
      <rect x="525.3" y="433.7" className="st0" width="226.7" height="4.5" />
      <path
        className="st0"
        d="M651.2,78.7h7.7c0.6,0,1-0.4,1-1s-0.4-1-1-1h-7.7c-0.6,0-1,0.4-1,1S650.7,78.7,651.2,78.7z"
      />
      <path
        className="st0"
        d="M662,368v-3.2h-17.8v3.2h0c0.1,1.2,1.3,2.2,2.9,2.5v0H659v0C660.6,370.2,661.8,369.2,662,368L662,368z"
      />
      <path
        className="st0"
        d="M666.7,78.7h7.7c0.6,0,1-0.4,1-1s-0.4-1-1-1h-7.7c-0.5,0-1,0.4-1,1S666.1,78.7,666.7,78.7z"
      />
      <polygon className="st0" points="560.5,373.5 558,430.7 566.6,430.7 566.6,430.7 575.2,430.7 572.7,373.5 " />
      <polygon className="st0" points="531.7,373.5 529.2,430.7 537.8,430.7 537.8,430.7 546.4,430.7 543.9,373.5 " />
      <path
        className="st0"
        d="M528.9,368L528.9,368c0.1,1.2,1.3,2.2,3,2.5v0h11.9v0c1.6-0.4,2.8-1.3,2.9-2.5h0v-3.2h-17.8V368z"
      />
      <rect x="1410.2" y="432.9" className="st0" width="30.9" height="5.5" />
      <path
        className="st0"
        d="M1270.8,175.3h8c0.6,0,1-0.4,1-1s-0.4-1-1-1h-8c-0.6,0-1,0.4-1,1S1270.3,175.3,1270.8,175.3z"
      />
      <path
        className="st0"
        d="M1286.8,175.3h8c0.6,0,1-0.4,1-1s-0.4-1-1-1h-8c-0.6,0-1,0.4-1,1S1286.3,175.3,1286.8,175.3z"
      />
      <path
        className="st0"
        d="M1254.8,175.3h8c0.6,0,1-0.4,1-1s-0.4-1-1-1h-8c-0.6,0-1,0.4-1,1S1254.2,175.3,1254.8,175.3z"
      />
      <path
        className="st0"
        d="M1238.7,175.3h8c0.6,0,1-0.4,1-1s-0.4-1-1-1h-8c-0.6,0-1,0.4-1,1S1238.2,175.3,1238.7,175.3z"
      />
      <path
        className="st0"
        d="M1334.9,175.3h8c0.6,0,1-0.4,1-1s-0.4-1-1-1h-8c-0.5,0-1,0.4-1,1S1334.4,175.3,1334.9,175.3z"
      />
      <path
        className="st0"
        d="M1318.9,175.3h8c0.5,0,1-0.4,1-1s-0.4-1-1-1h-8c-0.6,0-1,0.4-1,1S1318.3,175.3,1318.9,175.3z"
      />
      <path
        className="st0"
        d="M1302.9,175.3h8c0.6,0,1-0.4,1-1s-0.4-1-1-1h-8c-0.6,0-1,0.4-1,1S1302.3,175.3,1302.9,175.3z"
      />
      <path
        className="st0"
        d="M1222.7,175.3h8c0.6,0,1-0.4,1-1s-0.4-1-1-1h-8c-0.5,0-1,0.4-1,1S1222.2,175.3,1222.7,175.3z"
      />
      <path
        className="st0"
        d="M1174.6,175.3h8c0.5,0,1-0.4,1-1s-0.4-1-1-1h-8c-0.6,0-1,0.4-1,1S1174.1,175.3,1174.6,175.3z"
      />
      <path
        className="st0"
        d="M1350.9,175.3h8c0.6,0,1-0.4,1-1s-0.4-1-1-1h-8c-0.6,0-1,0.4-1,1S1350.4,175.3,1350.9,175.3z"
      />
      <path
        className="st0"
        d="M1142.6,175.3h8c0.6,0,1-0.4,1-1s-0.4-1-1-1h-8c-0.6,0-1,0.4-1,1S1142,175.3,1142.6,175.3z"
      />
      <path
        className="st0"
        d="M1158.6,175.3h8c0.6,0,1-0.4,1-1s-0.4-1-1-1h-8c-0.6,0-1,0.4-1,1S1158,175.3,1158.6,175.3z"
      />
      <path
        className="st0"
        d="M1206.7,175.3h8c0.6,0,1-0.4,1-1s-0.4-1-1-1h-8c-0.6,0-1,0.4-1,1S1206.1,175.3,1206.7,175.3z"
      />
      <path
        className="st0"
        d="M1190.7,175.3h8c0.6,0,1-0.4,1-1s-0.4-1-1-1h-8c-0.6,0-1,0.4-1,1S1190.1,175.3,1190.7,175.3z"
      />
      <path
        className="st0"
        d="M1511.2,175.3h8c0.6,0,1-0.4,1-1s-0.4-1-1-1h-8c-0.6,0-1,0.4-1,1S1510.7,175.3,1511.2,175.3z"
      />
      <path
        className="st0"
        d="M1527.3,175.3h8c0.6,0,1-0.4,1-1s-0.4-1-1-1h-8c-0.6,0-1,0.4-1,1S1526.7,175.3,1527.3,175.3z"
      />
      <path
        className="st0"
        d="M1126.5,175.3h8c0.6,0,1-0.4,1-1s-0.4-1-1-1h-8c-0.6,0-1,0.4-1,1S1126,175.3,1126.5,175.3z"
      />
      <path
        className="st0"
        d="M1495.2,175.3h8c0.6,0,1-0.4,1-1s-0.4-1-1-1h-8c-0.6,0-1,0.4-1,1S1494.7,175.3,1495.2,175.3z"
      />
      <path
        className="st0"
        d="M1479.2,175.3h8c0.6,0,1-0.4,1-1s-0.4-1-1-1h-8c-0.6,0-1,0.4-1,1S1478.6,175.3,1479.2,175.3z"
      />
      <path
        className="st0"
        d="M1575.4,175.3h8c0.6,0,1-0.4,1-1s-0.4-1-1-1h-8c-0.6,0-1,0.4-1,1S1574.8,175.3,1575.4,175.3z"
      />
      <path
        className="st0"
        d="M1559.3,175.3h8c0.6,0,1-0.4,1-1s-0.4-1-1-1h-8c-0.6,0-1,0.4-1,1S1558.8,175.3,1559.3,175.3z"
      />
      <path
        className="st0"
        d="M1543.3,175.3h8c0.6,0,1-0.4,1-1s-0.4-1-1-1h-8c-0.5,0-1,0.4-1,1S1542.7,175.3,1543.3,175.3z"
      />
      <path className="st0" d="M1383,175.3h8c0.6,0,1-0.4,1-1s-0.4-1-1-1h-8c-0.5,0-1,0.4-1,1S1382.5,175.3,1383,175.3z" />
      <path className="st0" d="M1399,175.3h8c0.6,0,1-0.4,1-1s-0.4-1-1-1h-8c-0.6,0-1,0.4-1,1S1398.5,175.3,1399,175.3z" />
      <path
        className="st0"
        d="M1415.1,175.3h8c0.6,0,1-0.4,1-1s-0.4-1-1-1h-8c-0.6,0-1,0.4-1,1S1414.5,175.3,1415.1,175.3z"
      />
      <path
        className="st0"
        d="M1463.2,175.3h8c0.6,0,1-0.4,1-1s-0.4-1-1-1h-8c-0.6,0-1,0.4-1,1S1462.6,175.3,1463.2,175.3z"
      />
      <path className="st0" d="M1367,175.3h8c0.6,0,1-0.4,1-1s-0.4-1-1-1h-8c-0.6,0-1,0.4-1,1S1366.4,175.3,1367,175.3z" />
      <path
        className="st0"
        d="M1447.1,175.3h8c0.6,0,1-0.4,1-1s-0.4-1-1-1h-8c-0.6,0-1,0.4-1,1S1446.6,175.3,1447.1,175.3z"
      />
      <path
        className="st0"
        d="M1431.1,175.3h8c0.5,0,1-0.4,1-1s-0.4-1-1-1h-8c-0.5,0-1,0.4-1,1S1430.5,175.3,1431.1,175.3z"
      />
      <rect x="562.4" y="349.3" className="st0" width="2.4" height="5" />
      <rect x="541" y="349.3" className="st0" width="2.4" height="5" />
      <rect x="578.5" y="349.3" className="st0" width="2.4" height="5" />
      <rect x="573.2" y="349.3" className="st0" width="2.4" height="5" />
      <rect x="616" y="349.3" className="st0" width="2.4" height="5" />
      <rect x="557.1" y="349.3" className="st0" width="2.4" height="5" />
      <rect x="546.4" y="349.3" className="st0" width="2.4" height="5" />
      <rect x="551.7" y="349.3" className="st0" width="2.4" height="5" />
      <rect x="567.8" y="349.3" className="st0" width="2.4" height="5" />
      <rect x="605.3" y="349.3" className="st0" width="2.4" height="5" />
      <rect x="583.9" y="349.3" className="st0" width="2.4" height="5" />
      <rect x="610.7" y="349.3" className="st0" width="2.4" height="5" />
      <rect x="599.9" y="349.3" className="st0" width="2.4" height="5" />
      <rect x="589.2" y="349.3" className="st0" width="2.4" height="5" />
      <rect x="594.6" y="349.3" className="st0" width="2.4" height="5" />
      <path
        className="st0"
        d="M834,287.2h-68.2v59.9H834V287.2z M799.9,339.7c-12.9,0-23.4-10.1-23.4-22.6c0-12.5,10.5-22.6,23.4-22.6
        c12.9,0,23.4,10.1,23.4,22.6C823.3,329.5,812.8,339.7,799.9,339.7z"
      />
      <path
        className="st0"
        d="M1046.4,175.3h8c0.6,0,1-0.4,1-1s-0.4-1-1-1h-8c-0.6,0-1,0.4-1,1S1045.8,175.3,1046.4,175.3z"
      />
      <path
        className="st0"
        d="M1030.4,175.3h8c0.6,0,1-0.4,1-1s-0.4-1-1-1h-8c-0.6,0-1,0.4-1,1S1029.8,175.3,1030.4,175.3z"
      />
      <rect x="535.7" y="349.3" className="st0" width="2.4" height="5" />
      <path
        className="st0"
        d="M1062.4,175.3h8c0.6,0,1-0.4,1-1s-0.4-1-1-1h-8c-0.5,0-1,0.4-1,1S1061.9,175.3,1062.4,175.3z"
      />
      <path
        className="st0"
        d="M1094.5,175.3h8c0.6,0,1-0.4,1-1s-0.4-1-1-1h-8c-0.6,0-1,0.4-1,1S1093.9,175.3,1094.5,175.3z"
      />
      <path
        className="st0"
        d="M1078.5,175.3h8c0.6,0,1-0.4,1-1s-0.4-1-1-1h-8c-0.6,0-1,0.4-1,1S1077.9,175.3,1078.5,175.3z"
      />
      <path
        className="st0"
        d="M1014.3,175.3h8c0.6,0,1-0.4,1-1s-0.4-1-1-1h-8c-0.5,0-1,0.4-1,1S1013.8,175.3,1014.3,175.3z"
      />
      <rect x="529" y="357.3" className="st0" width="219.4" height="4.5" />
      <path
        className="st0"
        d="M1110.5,175.3h8c0.5,0,1-0.4,1-1s-0.4-1-1-1h-8c-0.6,0-1,0.4-1,1S1110,175.3,1110.5,175.3z"
      />
      <path
        className="st0"
        d="M827.9,272h-6l-8.6-20.2h0v-21.7h1.8V223H810c-8-18.5-9-26.3-9-33.5h1.7v-8h-2v-12.3H799v12.3h-2v8h1.7
        c0,7.2-1,15-9,33.5h-5.1v7.1h1.8v21.7h0l-8.6,20.2h-6v10.4h56V272z"
      />
      <rect x="530.3" y="349.3" className="st0" width="2.4" height="5" />
      <path
        className="st0"
        d="M799.9,298.7c-10.5,0-19.1,8.3-19.1,18.4c0,10.1,8.6,18.4,19.1,18.4c10.5,0,19.1-8.3,19.1-18.4
        C819,306.9,810.4,298.7,799.9,298.7z M816.3,317.2c0,0.6-0.5,1-1.1,1h-13.3c-0.4,0.8-1.2,1.3-2.2,1.3c-1.3,0-2.4-1-2.4-2.3
        c0-0.9,0.5-1.7,1.3-2.1v-14.7c0-0.6,0.5-1,1.1-1c0.6,0,1.1,0.5,1.1,1v14.7c0.5,0.2,0.8,0.6,1.1,1h13.3
        C815.8,316.1,816.3,316.6,816.3,317.2z"
      />
      <rect x="2760.1" y="426.6" className="st0" width="22.8" height="6.3" />
    </svg>
  );
}

SvgFooterLandscape.propTypes = {
  color: propTypes.string,
};

SvgFooterLandscape.defaultProps = {
  color: '#df235d',
};

export default SvgFooterLandscape;
