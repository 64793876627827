import React from 'react';
import styled from 'styled-components';
import { Paragraph } from '../../../../../styledComponents/styles/Paragraph.styled';
import Checkbox from '../../../../../styledComponents/styles/Checkbox.styled';

const Container = styled.div`
  font-family: 'Nunito', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 0.015em;
  color: #373737;
`;

const InputContainer = styled.div`
  flex: 1;
  margin: 13px 0;
  text-align: left;
  font-family: ${({ theme }) => theme.textInput.label.fontFamily};
`;

const Label = styled.label`
  color: ${({ theme }) => theme.textInput.label.fontColor};
  font-family: ${({ theme }) => theme.textInput.label.fontFamily};
  font-size: ${({ theme }) => theme.textInput.label.fontSize};
  font-weight: ${({ theme }) => theme.textInput.label.fontWeight};
`;

const Required = styled.span`
  color: ${({ theme }) => theme.defaults.errorColor};
  padding-left: 5px;
`;

const OptionContainer = styled.span`
  display: flex;
  align-items: baseline;
`;

const Option = styled(Paragraph)`
  font-size: 14px;
  font-family: ${({ theme }) => theme.textInput.label.fontFamily};

  ${props =>
    props.disabled &&
    `
    cursor: not-allowed;
    opacity: 0.4;
  `};
`;

export default function QuestionMultipleChoiceDisplayPreview({ question }) {
  question.multiple_choice_question_options.sort((a, b) => {
    if (a.order < b.order) return -1;
    else if (a.order > b.order) return 1;
    return 0;
  });
  const updatedInstructions = !!question.instructions ? question.instructions.replace(/<p><\/p>/g, '<br />') : '';
  return (
    <Container>
      <InputContainer>
        <Label htmlFor={question.id}>
          {question.label}
          {question.required && <Required>*</Required>}
        </Label>
        <Paragraph
          aria-label={question.label}
          id='multiple-choice-instructions'
          openSans
          dangerouslySetInnerHTML={{
            __html: updatedInstructions,
          }}
        />
        <div>
          <Paragraph>Options</Paragraph>
          <div>
            {question.multiple_choice_question_options.map(attribute => (
              <OptionContainer key={attribute.id}>
                <Checkbox disabled />
                <Option
                  disabled
                  aria-label={question.label}
                  id='multiple-choice-options'
                  openSans
                  dangerouslySetInnerHTML={{
                    __html: attribute.name,
                  }}
                />
              </OptionContainer>
            ))}
          </div>
        </div>
      </InputContainer>
    </Container>
  );
}
