import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { buttonFocus } from '../../utils/shared/focusStyles';

const CTALink = styled(Link)`
  padding: 0.75rem;
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0px 10px 15px rgba(132, 131, 131, 0.25);
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
  font-size: 1rem;
  color: #446372;
  text-decoration-line: underline;
  line-height: 120%;

  &:hover {
    color: #446372;
    text-decoration-line: none;
  }

  ${buttonFocus};
`;

export default function VisitorLink() {
  return <CTALink to="/">Sign In / Sign Up</CTALink>;
}
