import _map from 'lodash/map';

export function getPercentage(percent, programId) {
  let percentage = 0;
  let obj = _map(percent.percentages, pert => {
    let splitted = pert.split('=>');
    let obj = {};
    obj[splitted[0].replace('{', '')] = splitted[1].replace('}', '');
    return obj;
  });
  percentage =
    obj.find(x => x[programId]) !== undefined
      ? obj.find(x => x[programId])[programId]
      : 0;
  return percentage;
}
