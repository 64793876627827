import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import s from './BlueModal.css';

class BlueModal extends React.Component {
  constructor(props) {
    super(props);

    this.renderBullets = this.renderBullets.bind(this);
  }

  renderBullets = () => {
    if (this.props.authorized == true) {
      return (
        <ul>
          <li>
            A message will be sent to your Study Abroad Office on your behalf.
          </li>
          <li>
            An email will also be sent to the program provider informing them of
            your interest in their program.
          </li>
          <li>
            Another browser window will open with the external program
            application site
          </li>
        </ul>
      );
    }
    return <div />;
  };

  render() {
    const showHideClassName = this.props.show
      ? s.modalDisplay
      : s.modalDisplayNone;
    const background = this.props.show ? s.backgroundShow : s.backgroundNone;

    return (
      <div id={background}>
        <div id={showHideClassName} className={s.wrapper}>
          <div className={s.closeWrapper}>
            <p className={s.closeX} onClick={this.props.handleClose}>
              <svg
                width="24px"
                height="25px"
                viewBox="0 0 24 25"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g
                  id="Events"
                  stroke="none"
                  strokeWidth="1"
                  fill="none"
                  fillRule="evenodd"
                  strokeLinecap="square"
                >
                  <g
                    id="Register-Modal-Traveler-Logged-In"
                    transform="translate(-1337.000000, -69.000000)"
                    stroke="#FFFFFF"
                    strokeWidth="2"
                  >
                    <g
                      id="xcancel"
                      transform="translate(1327.000000, 59.000000)"
                    >
                      <g
                        id="x-cancel"
                        transform="translate(22.000000, 22.500000) rotate(45.000000) translate(-22.000000, -22.500000) translate(7.000000, 7.000000)"
                      >
                        <path d="M15,0.5 L15,30.5" id="Line" />
                        <path d="M30,15.5 L6.39488462e-13,15.5" id="Line" />
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </p>
            <p className={s.closeText} onClick={this.props.handleClose}>
              close
            </p>
          </div>
          <h1 className={s.header}>{this.props.header}</h1>
          <p className={s.description}>{this.props.description}</p>
          {this.renderBullets()}
          <button className={s.button} onClick={this.props.buttonOneHandle}>
            {this.props.buttonOne}
          </button>
          <button className={s.button} onClick={this.props.buttonTwoHandle}>
            {this.props.buttonTwo}
          </button>
        </div>
      </div>
    );
  }
}

export default withStyles(s)(BlueModal);
