import styled from 'styled-components';

import { breakpoints } from '../../utils/breakpoints';

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  column-gap: 1.875rem;
  row-gap: 2.40625rem;
  margin-bottom: 2.28875rem;
  padding-left: 0.5rem;

  @media ${breakpoints.tablet} {
    grid-template-columns: repeat(2, minmax(0, 1fr));

    .col-span-2,
    .col-span-2--tablet {
      grid-column: span 2 / span 2;
    }
  }

  @media ${breakpoints.desktop} {
    grid-template-columns: repeat(3, minmax(0, 1fr));

    .col-span-2--tablet {
      grid-column: span 1 / span 1;
    }
  }
`;

export default Grid;
