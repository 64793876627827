import React from 'react';

export default function MidTermLength() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
      <path
        d="M5.77032 23.5859H2.32501V18.8893C2.32501 18.6048 2.53595 18.3721 2.79376 18.3721H5.30157C5.55939 18.3721 5.77032 18.6048 5.77032 18.8893V23.5859Z"
        fill="#EE7A9B"
      />
      <path
        d="M11.0719 23.5861H7.62189V16.0602C7.62189 15.7757 7.83282 15.543 8.09064 15.543H10.5984C10.8563 15.543 11.0672 15.7757 11.0672 16.0602V23.5861H11.0719Z"
        fill="#DDAF64"
      />
      <path
        d="M16.3735 23.5863H12.9235V13.2311C12.9235 12.9466 13.1344 12.7139 13.3922 12.7139H15.9C16.1578 12.7139 16.3688 12.9466 16.3688 13.2311V23.5863H16.3735Z"
        fill="#98D893"
      />
      <path d="M2.32501 22.6504H5.77501V23.5814H2.32501V22.6504Z" fill="#EE7A9B" />
      <path d="M7.62659 22.6504H11.0766V23.5814H7.62659V22.6504Z" fill="#DDAF64" />
      <path d="M12.9281 22.6504H16.3781V23.5814H12.9281V22.6504Z" fill="#98D893" />
      <path
        d="M18.3609 4.7017L19.8281 5.82929L19.3359 0.418945L14.5687 1.78963L16.0359 2.91722C16.0359 2.91722 13.35 8.69998 3.63748 12.0052C3.13591 12.1758 3.27654 12.9931 3.79685 12.9517C7.68748 12.6517 14.1094 11.1207 18.3609 4.7017Z"
        fill="#A82425"
      />
      <path
        d="M17.9438 4.37077C17.8406 4.28801 17.6953 4.31387 17.6203 4.42766C13.2516 10.9087 7.64533 11.7208 3.79689 12.0156C3.74064 12.0208 3.68908 12.0156 3.6422 12.0001H3.63752C3.14064 12.1708 3.28127 12.988 3.80158 12.9466C7.6922 12.6466 14.1141 11.1156 18.3656 4.69663L17.9438 4.37077Z"
        fill="#A82425"
      />
      <path
        d="M16.7109 23.6072C16.7109 23.4727 16.725 23.3331 16.7531 23.1986H16.7297V13.2313C16.7297 12.7296 16.3594 12.3262 15.9094 12.3262H13.4016C12.9469 12.3262 12.5812 12.7348 12.5812 13.2313V23.1986H11.4328V16.0607C11.4328 15.5589 11.0625 15.1555 10.6125 15.1555H8.10469C7.65 15.1555 7.28437 15.5641 7.28437 16.0607V19.4589C7.28437 19.671 7.44375 19.8469 7.63594 19.8469C7.83281 19.8469 7.9875 19.671 7.9875 19.4589V16.0607C7.9875 15.9882 8.03906 15.9313 8.10469 15.9313H10.6125C10.6781 15.9313 10.7297 15.9882 10.7297 16.0607V23.1986H7.9875V21.0158C7.9875 20.7986 7.82812 20.6279 7.63594 20.6279C7.44375 20.6279 7.28437 20.8038 7.28437 21.0158V23.1986H6.13594V18.89C6.13594 18.3882 5.76562 17.9848 5.31563 17.9848H2.79375C2.33906 17.9848 1.97344 18.3934 1.97344 18.89V23.1986H0.351562C0.154688 23.1986 0 23.3744 0 23.5865C0 23.8038 0.159375 23.9744 0.351562 23.9744H16.6078C16.6359 23.8503 16.6688 23.7262 16.7109 23.6072ZM5.41875 23.1986H2.67656V18.89C2.67656 18.8176 2.72812 18.7607 2.79375 18.7607H5.30156C5.36719 18.7607 5.41875 18.8176 5.41875 18.89V23.1986ZM16.0219 23.1986H13.2797V13.2313C13.2797 13.1589 13.3312 13.102 13.3969 13.102H15.9047C15.9703 13.102 16.0219 13.1589 16.0219 13.2313V23.1986Z"
        fill="#373737"
      />
      <path
        d="M3.75937 13.3394C3.77812 13.3394 3.79687 13.3394 3.81562 13.3394C6.41249 13.1377 8.85468 12.517 11.0766 11.4825C14.0672 10.0912 16.5422 7.99116 18.4359 5.23944L19.6219 6.14978C19.8562 6.34116 20.2125 6.1084 20.175 5.79288L19.6875 0.377366C19.6734 0.144607 19.4484 -0.0260823 19.2469 0.0411591L14.4797 1.41185C14.1937 1.48426 14.1234 1.93426 14.3672 2.10495L15.5625 3.02564C15.3234 3.43944 14.7984 4.25668 13.8844 5.26012C13.7484 5.41012 13.7437 5.6584 13.8844 5.8084C14.0203 5.9584 14.2453 5.96357 14.3812 5.8084C15.7922 4.26185 16.3266 3.13426 16.35 3.08771C16.4297 2.91702 16.3828 2.69978 16.2375 2.59116L15.3984 1.94978L19.0312 0.904952L19.4062 5.02219L18.5625 4.38081C18.4031 4.26185 18.1922 4.29806 18.075 4.46875C16.2281 7.25668 13.7812 9.37219 10.8047 10.7636C8.12812 12.0101 5.50312 12.4291 3.76874 12.5636C3.66562 12.5946 3.63749 12.3877 3.73593 12.3774C7.59374 11.0636 10.8187 9.21702 13.3172 6.88426C13.4672 6.74461 13.4859 6.5015 13.3594 6.33599C13.2328 6.17047 13.0125 6.14978 12.8625 6.28943C10.4391 8.54978 7.29843 10.3498 3.52968 11.6325C3.14062 11.767 2.91093 12.186 2.98593 12.6308C3.05624 13.0498 3.37968 13.3394 3.75937 13.3394Z"
        fill="#373737"
      />
    </svg>
  );
}
