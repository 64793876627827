import fetch from 'node-fetch';
import { keys } from '../config/keys';
import {
  CREATE_NEW_PROGRAM_CLEAR,
  CREATE_NEW_PROGRAM_REQUEST,
  CREATE_NEW_PROGRAM_SUCCESS,
  CREATE_NEW_PROGRAM_FAILURE,
  DELETE_DRAFT_PROGRAM_REQUEST,
  DELETE_DRAFT_PROGRAM_SUCCESS,
  DELETE_DRAFT_PROGRAM_FAILURE,
  GET_PROGRAM_REQUEST,
  GET_PROGRAM_SUCCESS,
  GET_PROGRAM_FAILURE,
  MODIFY_PROGRAM_CLEAR,
  MODIFY_PROGRAM_REQUEST,
  MODIFY_PROGRAM_SUCCESS,
  MODIFY_PROGRAM_FAILURE,
  ARCHIVE_PROGRAM_SUCCESS,
  ARCHIVE_PROGRAM_CLEAR,
  ARCHIVE_PROGRAM_REQUEST,
  ARCHIVE_PROGRAM_FAILURE,
  ADD_EDIT_PROGRAM_BROCHURE_SECTION_REQUEST,
  ADD_EDIT_PROGRAM_BROCHURE_SECTION_SUCCESS,
  ADD_EDIT_PROGRAM_BROCHURE_SECTION_FAILURE,
  ADD_EDIT_PROGRAM_BROCHURE_SECTION_CLEAR,
  BATCH_ADD_EDIT_PROGRAM_BROCHURE_SECTION_REQUEST,
  BATCH_ADD_EDIT_PROGRAM_BROCHURE_SECTION_SUCCESS,
  BATCH_ADD_EDIT_PROGRAM_BROCHURE_SECTION_FAILURE,
  BATCH_ADD_EDIT_PROGRAM_BROCHURE_SECTION_CLEAR,
  BATCH_DELETE_PROGRAM_BROCHURE_SECTION_REQUEST,
  BATCH_DELETE_PROGRAM_BROCHURE_SECTION_SUCCESS,
  BATCH_DELETE_PROGRAM_BROCHURE_SECTION_FAILURE,
  BATCH_DELETE_PROGRAM_BROCHURE_SECTION_CLEAR,
  DELETE_PROGRAM_BROCHURE_SECTION_REQUEST,
  DELETE_PROGRAM_BROCHURE_SECTION_SUCCESS,
  DELETE_PROGRAM_BROCHURE_SECTION_FAILURE,
  DELETE_PROGRAM_BROCHURE_SECTION_CLEAR,
  PROGRAM_TESTIMONIAL_REQUEST,
  PROGRAM_TESTIMONIAL_SUCCESS,
  PROGRAM_TESTIMONIAL_FAILURE,
  PROGRAM_TESTIMONIAL_CLEAR,

  GET_CONTACT_TITLES_REQUEST,
  GET_CONTACT_TITLES_SUCCESS,
  GET_CONTACT_TITLES_FAILURE,
  GET_CONTACT_TITLES_CLEAR,

  CREATE_CONTACT_TITLES_REQUEST,
  CREATE_CONTACT_TITLES_SUCCESS,
  CREATE_CONTACT_TITLES_FAILURE,
  CREATE_CONTACT_TITLES_CLEAR,

  UPDATE_CONTACT_TITLES_REQUEST,
  UPDATE_CONTACT_TITLES_SUCCESS,
  UPDATE_CONTACT_TITLES_FAILURE,
  UPDATE_CONTACT_TITLES_CLEAR,

  DELETE_CONTACT_TITLES_REQUEST,
  DELETE_CONTACT_TITLES_SUCCESS,
  DELETE_CONTACT_TITLES_FAILURE,
  DELETE_CONTACT_TITLES_CLEAR,

  GET_PROGRAM_IMAGE_REQUEST,
  GET_PROGRAM_IMAGE_SUCCESS,
  GET_PROGRAM_IMAGE_FAILURE,
  GET_PROGRAM_IMAGE_CLEAR,

  UPLOAD_PROGRAM_IMAGE_REQUEST,
  UPLOAD_PROGRAM_IMAGE_SUCCESS,
  UPLOAD_PROGRAM_IMAGE_FAILURE,
  UPLOAD_PROGRAM_IMAGE_CLEAR,

  DELETE_PROGRAM_IMAGE_REQUEST,
  DELETE_PROGRAM_IMAGE_SUCCESS,
  DELETE_PROGRAM_IMAGE_FAILURE,
  DELETE_PROGRAM_IMAGE_CLEAR,

} from './types';
import { Cookies } from 'react-cookie';

export function createNewProgramModify() {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({ type: CREATE_NEW_PROGRAM_REQUEST });
    fetch(`${keys.baseUri}/api/programs`, {
      method: 'POST',
      headers: token,
    }).then(res => {
      if (res.status !== 201 && res.status !== 204) {
        res.json().then(data => {
          dispatch({
            type: CREATE_NEW_PROGRAM_FAILURE,
          });
        });
      } else {
        res.json().then(data => {
          dispatch({
            type: CREATE_NEW_PROGRAM_SUCCESS,
            payload: data,
          });
        });
      }
    });
  };
}

export function createProgramModifyClear() {
  return function(dispatch) {
    dispatch({ type: CREATE_NEW_PROGRAM_CLEAR });
  };
}

export function deleteProgramModify(programId) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({ type: DELETE_DRAFT_PROGRAM_REQUEST });
    fetch(`${keys.baseUri}/api/programs/${programId}`, {
      method: 'DELETE',
      headers: token,
    }).then(res => {
      if (res.status !== 200 && res.status !== 204) {
        dispatch({
          type: DELETE_DRAFT_PROGRAM_FAILURE,
        });
      } else {
        dispatch({
          type: DELETE_DRAFT_PROGRAM_SUCCESS,
        });
      }
    });
  };
}

export function getProgramModify(programId) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({ type: GET_PROGRAM_REQUEST });
    fetch(`${keys.baseUri}/api/programs/${programId}`, {
      method: 'GET',
      headers: token,
    }).then(res => {
      if (res.status !== 200) {
        res.json().then(data => {
          dispatch({
            type: GET_PROGRAM_FAILURE,
          });
        });
      } else {
        res.json().then(data => {
          dispatch({
            type: GET_PROGRAM_SUCCESS,
            payload: data,
          });
        });
      }
    });
  };
}

export function updateProgramModify(programId, data) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({ type: MODIFY_PROGRAM_REQUEST });
    fetch(`${keys.baseUri}/api/programs/${programId}`, {
      method: 'PATCH',
      headers: token,
      body: JSON.stringify({ program: data }),
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: MODIFY_PROGRAM_SUCCESS,
          payload: data,
        });
      })
      .catch(error => {
        dispatch({
          type: MODIFY_PROGRAM_FAILURE,
          payload: error.toString(),
        });
      });
  };
}

export function updateProgramModifyClear() {
  return function(dispatch) {
    dispatch({ type: MODIFY_PROGRAM_CLEAR });
  };
}

export function archivedUnarchivedProgram(programId, data, isArchive) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({ type: ARCHIVE_PROGRAM_REQUEST });
    fetch(`${keys.baseUri}/api/programs/${programId}`, {
      method: 'PATCH',
      headers: token,
      body: JSON.stringify({ program: data }),
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: ARCHIVE_PROGRAM_SUCCESS,
          payload: isArchive ? "archived" :"unarchived",
        });
      })
      .catch(error => {
        dispatch({
          type: ARCHIVE_PROGRAM_FAILURE,
          payload: error.toString(),
        });
      });
  };
}

export function updateProgramArchiveClear() {
  return function(dispatch) {
    dispatch({ type: ARCHIVE_PROGRAM_CLEAR });
  };
}

export function batchAddEditCustomBrochureSection(request) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({ type: BATCH_ADD_EDIT_PROGRAM_BROCHURE_SECTION_REQUEST });
    fetch(`${keys.baseUri}/api/program_batch_actions/add_edit_program_brochure_section`, {
      method: 'PUT',
      headers: token,
      body: JSON.stringify(request),
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: BATCH_ADD_EDIT_PROGRAM_BROCHURE_SECTION_SUCCESS,
          payload: data,
        });
      })
      .catch(error => {
        dispatch({
          type: BATCH_ADD_EDIT_PROGRAM_BROCHURE_SECTION_FAILURE,
          payload: error.toString(),
        });
      });
  };
}

export function batchAddEditCustomBrochureSectionClear() {
  return function(dispatch) {
    dispatch({ type: BATCH_ADD_EDIT_PROGRAM_BROCHURE_SECTION_CLEAR });
  };
}

export function addEditCustomBrochureSection(request) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({ type: ADD_EDIT_PROGRAM_BROCHURE_SECTION_REQUEST });
    fetch(`${keys.baseUri}/api/client/program_brochure_sections`, {
      method: 'POST',
      headers: token,
      body: JSON.stringify(request),
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: ADD_EDIT_PROGRAM_BROCHURE_SECTION_SUCCESS,
          payload: data,
        });
      })
      .catch(error => {
        dispatch({
          type: ADD_EDIT_PROGRAM_BROCHURE_SECTION_FAILURE,
          payload: error.toString(),
        });
      });
  };
}

export function addEditCustomBrochureSectionClear() {
  return function(dispatch) {
    dispatch({ type: ADD_EDIT_PROGRAM_BROCHURE_SECTION_CLEAR });
  };
}

export function batchDeleteCustomBrochureSection(request) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({ type: BATCH_DELETE_PROGRAM_BROCHURE_SECTION_REQUEST });
    fetch(`${keys.baseUri}/api/program_batch_actions/delete_program_brochure_section`, {
      method: 'DELETE',
      headers: token,
      body: JSON.stringify(request),
    }).then(res => {
      if (res.status !== 200 && res.status !== 204) {
        dispatch({
          type: BATCH_DELETE_PROGRAM_BROCHURE_SECTION_FAILURE,
        });
      } else {
        dispatch({
          type: BATCH_DELETE_PROGRAM_BROCHURE_SECTION_SUCCESS,
        });
      }
    });
  };
}
export function batchDeleteCustomBrochureSectionClear() {
  return function(dispatch) {
    dispatch({ type: BATCH_DELETE_PROGRAM_BROCHURE_SECTION_CLEAR });
  };
}
export function deleteCustomBrochureSection(id) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({ type: DELETE_PROGRAM_BROCHURE_SECTION_REQUEST });
    fetch(`${keys.baseUri}/api/client/program_brochure_sections/${id}`, {
      method: 'DELETE',
      headers: token,
    }).then(res => {
      if (res.status !== 200 && res.status !== 204) {
        dispatch({
          type: DELETE_PROGRAM_BROCHURE_SECTION_FAILURE,
        });
      } else {
        dispatch({
          type: DELETE_PROGRAM_BROCHURE_SECTION_SUCCESS,
          payload: 'Brochure section deleted',
        });
      }
    });
  };
}

export function deleteCustomBrochureSectionClear() {
  return function(dispatch) {
    dispatch({ type: DELETE_PROGRAM_BROCHURE_SECTION_CLEAR });
  };
}

export function createProgramTestimonials(programID, data, callback = null) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({ type: PROGRAM_TESTIMONIAL_REQUEST });
    fetch(`${keys.baseUri}/api/client/programs/${programID}/program_reviews/create_or_update_reviews`, {
      method: 'POST',
      headers: token,
      body: JSON.stringify(data),
    })
      .then(res => res.json())
      .then(data => {
        if(callback && typeof callback === "function"){
          callback(true, `Testimonials saved`);
        }
        dispatch({
          type: PROGRAM_TESTIMONIAL_SUCCESS,
          payload: data,
        });
      })
      .catch(error => {
        dispatch({
          type: PROGRAM_TESTIMONIAL_FAILURE,
          payload: error.toString(),
        });
      });
  };
}

export function clearProgramTestimonials() {
  return function(dispatch) {
    dispatch({type: PROGRAM_TESTIMONIAL_CLEAR});
  };
}

export function getProgramContactTitles(programId) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({ type: GET_CONTACT_TITLES_REQUEST });
    fetch(`${keys.baseUri}/api/client/programs/${programId}/program_contact_titles`, {
      method: 'GET',
      headers: token,
    }).then(res => {
      if (res.status !== 200) {
        res.json().then(data => {
          dispatch({
            type: GET_CONTACT_TITLES_FAILURE,
          });
        });
      } else {
        res.json().then(data => {
          dispatch({
            type: GET_CONTACT_TITLES_SUCCESS,
            payload: data,
          });
        });
      }
    });
  };
}

export function createProgramContactTitle(programId, data, callback = null) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({ type: CREATE_CONTACT_TITLES_REQUEST });
    fetch(`${keys.baseUri}/api/client/programs/${programId}/program_contact_titles`, {
      method: 'POST',
      headers: token,
      body: JSON.stringify(data),
    }).then(res => {
      if (res.status !== 200 && res.status !== 201) {
        res.json().then(data => {
          if(callback && typeof callback === "function"){
            callback(false, `${data.title}`);
          }
          dispatch({
            type: CREATE_CONTACT_TITLES_FAILURE,
          });
        });
      } else {
        res.json().then(data => {
          if(callback && typeof callback === "function"){
            callback(true, `${data.title} saved`);
          }
          dispatch({
            type: CREATE_CONTACT_TITLES_SUCCESS,
            payload: data,
          });
        }).catch(error => {
          if(callback && typeof callback === "function"){
            callback(false, error.toString());
          }
          dispatch({
            type: CREATE_CONTACT_TITLES_FAILURE,
            payload: error.toString(),
          });
        });
      }
    });
  };
}

export function clearProgramContactTitleCreate() {
  return function(dispatch) {
    dispatch({type: CREATE_CONTACT_TITLES_CLEAR});
  };
}

export function updateProgramContactTitle(programId, titleID ,data, callback = null) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({ type: UPDATE_CONTACT_TITLES_REQUEST });
    fetch(`${keys.baseUri}/api/client/programs/${programId}/program_contact_titles/${titleID}`, {
      method: 'PATCH',
      headers: token,
      body: JSON.stringify(data),
    }).then(res => {
      if (res.status !== 200 && res.status !== 201) {
        res.json().then(data => {
          if(callback && typeof callback === "function"){
            callback(false, `${data.title}`);
          }
          dispatch({
            type: UPDATE_CONTACT_TITLES_FAILURE,
          });
        });
      } else {
        res.json().then(data => {
          if(callback && typeof callback === "function"){
            callback(true, `${data.title} saved`);
          }
          dispatch({
            type: UPDATE_CONTACT_TITLES_SUCCESS,
            payload: data,
          });
        });
      }
    });
  };
}

export function clearProgramContactTitleUpdate() {
  return function(dispatch) {
    dispatch({type: CREATE_CONTACT_TITLES_CLEAR});
  };
}

export function deleteContactTitle(programID, titleID, title, callback = null ) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({ type: DELETE_CONTACT_TITLES_REQUEST });
    fetch(`${keys.baseUri}/api/client/programs/${programID}/program_contact_titles/${titleID}`, {
      method: 'DELETE',
      headers: token,
    }).then(res => {
      if (res.status !== 200 && res.status !== 204) {
        dispatch({
          type: DELETE_CONTACT_TITLES_FAILURE,
        });
      } else {
        if(callback && typeof callback === "function"){
          callback(true, `${title} deleted`);
        }
        dispatch({
          type: DELETE_CONTACT_TITLES_SUCCESS,
          payload: titleID,
        });
      }
    });
  };
}

export function uploadProgramImages(programId, data, callback = null) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({ type: UPLOAD_PROGRAM_IMAGE_REQUEST });
    fetch(`${keys.baseUri}/api/client/programs/${programId}/program_images/batch_create_or_update`, {
      method: 'POST',
      headers: token,
      body: JSON.stringify(data),
    }).then(res => {
      if (res.status !== 200 && res.status !== 201) {
        res.json().then(data => {
          if(callback && typeof callback === "function"){
            callback(false, `${data.title}`);
          }
          dispatch({
            type: UPLOAD_PROGRAM_IMAGE_FAILURE,
          });
        });
      } else {
        res.json().then(data => {
          if(callback && typeof callback === "function"){
            callback(true, `${data.title} saved`);
          }
          dispatch({
            type: UPLOAD_PROGRAM_IMAGE_SUCCESS,
            payload: data,
          });
        }).catch(error => {
          if(callback && typeof callback === "function"){
            callback(false, error.toString());
          }
          dispatch({
            type: UPLOAD_PROGRAM_IMAGE_FAILURE,
            payload: error.toString(),
          });
        });
      }
    });
  };
}

export function clearUploadProgramImages() {
  return function(dispatch) {
    dispatch({type: UPLOAD_PROGRAM_IMAGE_CLEAR});
  };
}

export function getProgramImages(programId, user_type = "client") {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({ type: GET_PROGRAM_IMAGE_REQUEST });
    fetch(`${keys.baseUri}/api/${user_type}/programs/${programId}/program_images`, {
      method: 'GET',
      headers: token,
    }).then(res => {
      if (res.status !== 200) {
        res.json().then(data => {
          dispatch({
            type: GET_PROGRAM_IMAGE_FAILURE,
          });
        });
      } else {
        res.json().then(data => {
          dispatch({
            type: GET_PROGRAM_IMAGE_SUCCESS,
            payload: data,
          });
        });
      }
    });
  };
}

export function deleteProgramImages(programId, data, callback = null) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({ type: DELETE_PROGRAM_IMAGE_REQUEST });
    fetch(`${keys.baseUri}/api/client/programs/${programId}/program_images/batch_destroy`, {
      method: 'DELETE',
      headers: token,
      body: JSON.stringify(data),
    }).then(res => {
      if (res.status !== 200 && res.status !== 201) {
        res.json().then(data => {
          if(callback && typeof callback === "function"){
            callback(false, `${data.title}`);
          }
          dispatch({
            type: DELETE_PROGRAM_IMAGE_FAILURE,
          });
        });
      } else {
        res.json().then(data => {
          if(callback && typeof callback === "function"){
            callback(true, `${data.title} saved`);
          }
          dispatch({
            type: DELETE_PROGRAM_IMAGE_SUCCESS,
            payload: data,
          });
        }).catch(error => {
          if(callback && typeof callback === "function"){
            callback(false, error.toString());
          }
          dispatch({
            type: DELETE_PROGRAM_IMAGE_FAILURE,
            payload: error.toString(),
          });
        });
      }
    });
  };
}