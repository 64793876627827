import styled from 'styled-components';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import VisitorLink from './VisitorLink';
import CloseIcon from '../icons/CloseIcon';
import MobileNavigation from './MobileNavigation';
import AccountNavigation from './AccountNavigation';
import HamburgerMenuIcon from '../icons/HamburgerMenuIcon';
import OrganizationInfo from '../organization/OrganizationInfo';

import { breakpoints } from '../../utils/breakpoints';

const Container = styled.header`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.0625rem 1.3425rem 1.0625rem 1.375rem;
  background-color: var(--theme_color_dark);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);

  @media ${breakpoints.tablet} {
    padding: 0.70125rem 2.23125rem 0.70125rem 2.23125rem;
  }
`;

const MenuButton = styled.button`
  min-width: 26px;
  background-color: #eeeeee;
  border: none;
  color: #446372;
  padding: 10px;
  line-height: 20px;
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  font-weight: 700;
  border-radius: 5px;
  font-family: 'Open Sans', sans-serif;

  &:focus {
    outline: none;
  }

  svg {
    margin-right: 5px;
  }

  &:focus-visible {
    svg {
      outline: 2px solid #255a94;
      outline-offset: 1px;
    }
  }
`;

const InfoContainer = styled.div`
  display: flex;
  gap: 1.875rem;
`;

export default function DetailHeader() {
  const { adminAccess, role } = useSelector(state => state.travelerProfile);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const isTraveler = role === 'traveler';

  return (
    <Container>
      <InfoContainer>
        {isTraveler ? (
          <MenuButton
            type="button"
            aria-label={menuIsOpen ? 'Close Main Navigation' : 'Open Main Navigation'}
            onClick={() => {
              setMenuIsOpen(!menuIsOpen);
            }}
          >
            {menuIsOpen ? <CloseIcon /> : <HamburgerMenuIcon />} Menu
          </MenuButton>
        ) : null}
      </InfoContainer>
      {isTraveler || adminAccess ? <AccountNavigation /> : <VisitorLink />}
      {menuIsOpen && <MobileNavigation isOpen={menuIsOpen} />}
    </Container>
  );
}
