export default [
  {
    id: 1,
    label: 'Hispanic or Latino of any race',
    value: 'Hispanic or Latino of any race',
  },
  {
    id: 2,
    label: 'Not Hispanic or Latino',
    value: 'Not Hispanic or Latino',
  },
];
