import React from 'react';
import Select from 'react-select';
import styles from './Dropdown.css';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

class Dropdown extends React.Component {
  handleChange = value => {
    const val = value === null ? '' : value.value;
    this.props.onChange(this.props.name, val);
  };

  handlePlaceholder = value => {
    if (value) {
      if (typeof value !== 'number') {
        const selected = !!this.props.options && this.props.options.filter(option => option.value === value);
        if (selected && selected.length > 0) {
          return selected[0].label;
        } else {
          return value.replace(/_/g, ' ');
        }
      } else {
        return value;
      }
    } else {
      return '';
    }
  };

  render() {
    const customStyles = {
      menu: (provided, state) => ({
        ...provided,
        zIndex: '12',
      }),

      control: provided => ({
        ...provided,
        borderTop: 'none',
        borderLeft: 'none',
        borderRight: 'none',
        padding: '5px',
        height: '88px',
      }),

      placeholder: () => ({
        fontFamily: 'AvenirNextRegular, sans-serif',
        color: '#043544',
        position: 'relative',
        padding: '20px 0 10px 20px',
        width: '100%',
        background: 'transparent',
        border: 'none',
        fontSize: '1.5rem',
        transition: '0.3s ease background',
        lineHeight: '2rem',
        textTransform: 'capitalize',
      }),
      input: () => ({
        caretColor: 'transparent',
      }),

      indicatorsContainer: () => ({
        height: '88px',
      }),

      valueContainer: () => ({
        height: '88px',
      }),
    };

    return (
      <div
        className={this.props.className ? `${styles.dateDropdown}  ${styles.inputWrapper}` : `${styles.inputWrapper}`}
      >
        <Select
          options={this.props.options}
          name={this.props.name}
          placeholder={this.handlePlaceholder(this.props.value)}
          isClearable
          isMulti={false}
          value={this.props.value}
          className={`profile-select ${this.props.value != '' && `${styles.fieldAdded}`} `}
          onChange={this.handleChange}
          isDisabled={this.props.disabled}
          styles={customStyles}
        />
        {!!this.props.error &&
          this.props.touched && <div style={{ color: 'red', marginTop: '.5rem' }}>{this.props.error}</div>}
      </div>
    );
  }
}

export default withStyles(styles)(Dropdown);
