import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import moment from 'moment-timezone';

import TravelerEvent from './TravelerEvent/TravelerEvent';
import Expand from '../../../Expand/Expand';
import _difference from 'lodash/difference';

import s from './Events.css';
import Loader from '../../../Loader/Loader';
import Group from '../../../Group/Group';
import { connect } from 'react-redux';
import { compose } from 'redux';

class TravelerEvents extends React.Component {
  constructor(props) {
    super(props);

    this.groupBy = this.groupBy.bind(this);
  }

  groupBy(xs, key) {
    return xs.reduce((rv, x) => {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }

  render() {
    if (this.props.loading == true) {
      return <Loader />;
    } else if (this.props.events == null || this.props.events.length == 0) {
      return (
        <h1 className={s.noMatchHeader}>
          {this.props.role.toLowerCase() === 'admin'
            ? `This ${this.props.customAliases.alias_traveler} has not registered for or attended any Events yet...`
            : 'You have not registered for or attended any Events yet...'
          }
        </h1>
      );
    }

    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    const sortEvents = eventList => {
      return eventList.sort((a, b) => {
        return (
          months.indexOf(moment(new Date(b.start)).format('MMMM')) -
          months.indexOf(moment(new Date(a.start)).format('MMMM'))
        );
      })
      .sort(
        (b, a) =>
          new Date('1970/01/01 ' + b.start) - new Date('1970/01/01 ' + a.start),
      );
    }

    const getEventResults = eventList => {
      const results = [
        ...eventList
          .reduce(
            (r, { start, location, name, public_url, attended, registered, timezone }) => {
              r.has(start) ||
                r.set(start, {
                  start: `${
                    months[months.indexOf(moment(new Date(start)).format('MMMM'))]
                  } ${moment(new Date(start)).format('YYYY')}`,
                  items: [],
                });
              r.get(start).items.push({
                location,
                name,
                start,
                public_url,
                attended,
                registered,
                timezone,
              });
              return r;
            },
            new Map(),
          )
          .values(),
      ];

    return results.map(event => (
      <Group header={event.start}>
        {event.items.map((e, index) => (
          <TravelerEvent
            key={index}
            day={moment(new Date(e.start)).format('D')}
            date={moment(new Date(e.start)).format('MMMM D, YYYY')}
            location={e.location}
            time={moment(e.start).tz(e.timezone).format('hh:mma')}
            timezone={e.timezone}
            name={e.name}
            registered={e.registered}
            attended={e.attended}
            publicUrl={e.public_url}
            customBranding={this.props.customBranding.themes}
          />
        ))}
      </Group>
    ));
    }

    // Cancelled Events
    const cancelledEventList = sortEvents(
      this.props.events.filter(event => event.deleted)
    );


    const cancelledResults = getEventResults(cancelledEventList);

    // Past Events
    const pastEventList = sortEvents(
      _difference(this.props.events, cancelledEventList)
        .filter(event => moment(new Date(event.start)) < moment())
    );

    const pastResults = getEventResults(pastEventList);

    // Future Events
    const futureEventList = sortEvents(
      _difference(this.props.events, cancelledEventList)
        .filter(event => moment(new Date(event.start)) > moment())
    );

    const futureResults = getEventResults(futureEventList);

    return (
      <div className={s.events}>
        <Expand headerTitle="Upcoming" count={futureResults.length} formExpanded>
          {futureResults}
        </Expand>
        <Expand headerTitle="Past" count={pastResults.length} formExpanded>
          {pastResults}
        </Expand>
        <Expand headerTitle="Cancelled" count={cancelledResults.length} formExpanded>
          {cancelledResults}
        </Expand>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  customBranding: state.profile.customBranding || {},
  orgTimezone: state.profile.orgTimezone,
  customAliases: state.profile.customAliases,
});

export default compose(
  withStyles(s),
  connect(mapStateToProps),
)(TravelerEvents);
