import React, { useEffect, useState } from 'react';
import Modal from 'react-responsive-modal';
import { compose } from 'recompose';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import styled from 'styled-components';
import modalCss from 'react-responsive-modal/styles.css';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '../../sites/travelerProfile/components/icons/CloseIcon';
import WarningImageIcon from '../../sites/travelerProfile/components/icons/WarningImageIcon';

import { toast } from 'react-toastify';
import toastMessageStyles from '../../sites/viaGlobal/components/toastMessage/toastMessage.scss';
import { CircularProgress } from '@material-ui/core';
import { respondRequestToWithdraw, fetchTravelerSubmission } from '../../actions/formsActions';

export const enhance = compose(withStyles(modalCss, toastMessageStyles));

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 25px 25px;
  border-bottom: 5px solid #ddaf64;
`;

const Container = styled.div`
  padding: 30px 40px;
  display: flex;
  flex-direction: row;
  gap: 40px;
`;

const Heading = styled.h2`
  color: #373737;
  text-align: center;

  /* Headline 5 */
  font-family: Nunito;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 30px */
`;

const Footer = styled.div`
  border-radius: 0px 0px 10px 10px;
  background: #ebebeb;
  display: flex;
  flex-direction: row;
  justify-content: right;
  padding: 30px;
  gap: 55px;
`;

const CancelBtn = styled.button`
  color: #373737;
  /* Button Primary */
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 19.2px */
  letter-spacing: 0.96px;
  border: none;
  outline: none;
`;

const PrimaryBtn = styled.button`
  border-radius: 5px;
  background: var(--theme_color_accent);
  color: var(--theme_color_accent_font);
  text-align: center;
  /* Button Primary */
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 19.2px */
  letter-spacing: 0.96px;
  border: 1px #d74b73;
  padding: 16px 28px;

  &:hover {
    opacity: 0.7;
  }

  &:disabled {
    opacity: 0.7;
  }
`;

const CloseButton = styled.button`
  outline: none;
  border: none;
  padding: 0;
  background: none;
`;

const WarningImage = styled.div``;

const WarningText = styled.div``;

const WarningDescription = styled.div`
  color: #373737;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 22.4px */
  letter-spacing: 0.08px;
  margin-bottom: 20px;
`;

const WarningDescriptionBold = styled.div`
  color: #373737;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: 0.08px;
`;

function RequestToWithdrawDeclineModal({ open, onClose, submissionId, submissionWithdrawalRequestId }) {
  const dispatch = useDispatch();
  const { loading } = useSelector(state => state.forms.respondRequestToWithdraw);
  const customAliases = useSelector(state => state.profile.customAliases);

  const handleSubmit = () => {
    dispatch(
      respondRequestToWithdraw(submissionId, submissionWithdrawalRequestId, 'rejected', '', (status, message) => {
        if (status) {
          toast(message, {
            type: 'success',
            className: toastMessageStyles['successContainer'],
            toastClassName: toastMessageStyles['wrapper'],
            bodyClassName: toastMessageStyles['successBody'],
            progressClassName: toastMessageStyles['progress-bar'],
          });

          dispatch(fetchTravelerSubmission(submissionId));

          onClose();
        } else {
          toast(message, {
            type: 'error',
            className: toastMessageStyles['failureContainer'],
            toastClassName: toastMessageStyles['wrapper'],
            bodyClassName: toastMessageStyles['failureBody'],
            progressClassName: toastMessageStyles['progress-bar'],
          });
        }
      }),
    );
  };

  return (
    <Modal
      blockScroll={false}
      open={open}
      onClose={onClose}
      center
      closeOnEsc
      closeOnOverlayClick
      showCloseIcon={false}
      styles={{
        modal: {
          padding: '0px',
          borderRadius: '5px',
          width: '520px',
        },
      }}
    >
      <Header>
        <Heading>Warning</Heading>

        <CloseButton onClick={onClose}>
          <CloseIcon fillColor={`#9FABAE`} />
        </CloseButton>
      </Header>

      <Container>
        <WarningImage>
          <WarningImageIcon />
        </WarningImage>
        <WarningText>
          <WarningDescription>
            You’re about to decline the {customAliases?.alias_travelers} request to withdraw from the program. The
            application status will not change and the {customAliases.alias_traveler} will be notified.
          </WarningDescription>

          <WarningDescriptionBold>Are you sure you want to decline this request?</WarningDescriptionBold>
        </WarningText>
      </Container>

      <Footer>
        <CancelBtn onClick={onClose}>Cancel</CancelBtn>
        <PrimaryBtn onClick={handleSubmit} disabled={loading}>
          Decline Request
          {loading && (
            <>
              {' '}
              <CircularProgress color="inherit" size={20} />
            </>
          )}
        </PrimaryBtn>
      </Footer>
    </Modal>
  );
}

export default enhance(RequestToWithdrawDeclineModal);
