import React, { lazy, Suspense, useEffect, useState } from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import _get from 'lodash/get';
import { Controller, useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import CurrencyInput from '../../../../../styledComponents/styles/CurrencyInput.styled';
import { Paragraph } from '../../../../../styledComponents/styles/Paragraph.styled';
import StyledIcon from '../../../../../styledComponents/styles/Icon.styled';
import TextInput from '../../../../../styledComponents/styles/TextInput.styled';
import { AccentButtonMediumNunito } from '../../../../../styledComponents/styles/Buttons.styled';
import { AccentButtonSpinner } from '../../../../../styledComponents/styles/spinner.styled';
import { updateForm, updateQuestion } from '../../../../../actions/formsActions';
import Toggle from '../../../../../styledComponents/styles/Toggle.styled';

const EditTextArea = lazy(() => import('../../../../../styledComponents/styles/EditTextArea.styled'));


const BaseMsg = styled.div`
  background-color: #deecff;
  border-left: 20px solid #5180b1;
  border-radius: 5px;
  color: #4d4d4d;
  line-height: 17px;
  margin-top: 17px;
  padding: 20px;
`;

const FormContainer = styled.div`
  font-family: 'Nunito', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 0.015em;
  color: #373737;
`;

const InputContainer = styled.div`
  flex: 1;
  margin: 20px 0;
  text-align: left;
`;

const CurrencyContainer = styled.div`
  flex: 1;
  margin: 20px 0 20px 20px;
  text-align: left;
`;

const CurrencyDiv = styled.div`
  line-height: 44px;
  font-size: 14px;
  font-weight: 400;
  font-family: Open Sans, sans-serif;

  span {
    display: inline-block;
    line-height: normal;
    vertical-align: middle;
  }
`;

const Label = styled(Paragraph)`
  color: #043544;
  font-size: 16px;
  font-weight: 600;
  font-family: Open Sans, sans-serif;
`;

const CostContainer = styled.div`
  display: flex;
  margin: 20px 0;
  text-align: left;
`;

const SubmitContainer = styled.main`
  display: flex;
  justify-content: flex-end;
  margin-top: 35px;
`;

const ToggleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: inherit;
`;

const ToggleLabel = styled.span`
  align-self: center;
  flex: 1;
  justify-content: center;
  padding-left: 10px;
  padding-bottom: 7px;
`;

export default function QuestionTouchNetAddEdit({ question, edit, onClose }) {
  const MAX_LENGTH = 90;
  const qIdentifier = 'touch_net';
  const dispatch = useDispatch();
  const customAliases = useSelector(state => state.profile.customAliases);
  const fetchForm = useSelector(state => state.forms.fetchForm);
  const questionTypesInfo = useSelector(state => state.forms.questionTypes);
  const updateFormSubmit = useSelector(state => state.forms.updateForm);
  const updateQuestionSubmit = useSelector(state => state.forms.updateQuestion);
  const [formId, setFormId] = useState(null);
  const [disableSubmit, setDisableSubmit] = useState(false);

  const schema = yup.object().shape({
    touchnet_label: yup
      .string()
      .required('Question must have label')
      .max(MAX_LENGTH, `Label can not be more than ${MAX_LENGTH} characters`),
    touchnet_instructions: yup.string(),
    touchnet_admin_only: yup.boolean(),
    touchnet_required: yup.boolean(),
    touchnet_cost: yup.string(),
    touchnet_currency: yup.string(),
    touchnet_detail_code: yup.string(),
  });

  const { control, handleSubmit, formState, setValue } = useForm({
    shouldUnregister: false,
    resolver: yupResolver(schema),
    defaultValues: {
      touchnet_label: edit ? question.label : '',
      touchnet_instructions: edit ? question.instructions || '' : '',
      touchnet_required: edit ? question.required : false,
      touchnet_cost: edit ? Number(question.cost)?.toFixed(2) : '0.00',
      touchnet_currency: 'USD',
      touchnet_detail_code: edit ? question.detail_code || '' : '',
    },
    mode: 'onChange',
  });

  useEffect(() => {
    if (!fetchForm?.loading && fetchForm?.data) {
      setDisableSubmit(false);
      setFormId(fetchForm.data.id);
    }
  }, [fetchForm]);

  useEffect(() => {
    if (
      (!updateFormSubmit?.loading && updateFormSubmit?.data) ||
      (!updateQuestionSubmit?.loading && updateQuestionSubmit?.data)
    ) {
      setDisableSubmit(false);
      onClose();
    }
  }, [updateFormSubmit, updateQuestionSubmit]);

  const onSubmitHandler = data => {
    if (questionTypesInfo && questionTypesInfo.data) {
      const questionType = questionTypesInfo.data.reduce((prev, curr) => {
        return prev || curr.attributes.question_types.find(question => question.identifier === qIdentifier);
      }, undefined);

      const values = {
        instructions: data.touchnet_instructions,
        label: data.touchnet_label,
        required: data.touchnet_required,
        currency: data.touchnet_currency,
        cost: data.touchnet_cost,
        detail_code: data.touchnet_detail_code,
      };

      if (formId && data && (questionType?.id || question?.id)) {
        //update or save new
        const actionToCall = edit ? updateQuestion : updateForm;
        const idToUpdate = edit ? question.id : questionType.id;
        setDisableSubmit(true);
        dispatch(actionToCall(formId, questionType.endpoint, idToUpdate, values));
      }
    }
  };

  return (
    <FormContainer>
      <form id={'touchnetAdd'} onSubmit={handleSubmit(onSubmitHandler)}>
        <Controller
          control={control}
          name={'touchnet_label'}
          render={({ field: { ref, ...rest } }) => (
            <InputContainer>
              <TextInput
                aria-label={'TouchNet Label'}
                errorMsg={_get(formState.errors.touchnet_label, 'message') || ''}
                id={'touchnet_label'}
                label={'TouchNet Payment Label'}
                openSans
                placeholder={'Enter Label'}
                required
                {...rest}
              />
            </InputContainer>
          )}
        />
        <Controller
          control={control}
          name={'touchnet_instructions'}
          render={({ field: { ref, ...rest } }) => (
            <InputContainer>
              <Suspense fallback={<div />}>
                <EditTextArea
                  aria-label="Instructions"
                  errorMsg={_get(formState.errors.touchnet_instructions, 'message') || ''}
                  placeholder={`Enter Instructions`}
                  label={`Instructions`}
                  id={'touchnet_instructions'}
                  {...rest}
                />
              </Suspense>
            </InputContainer>
          )}
        />

        <CostContainer>
          <Controller
            control={control}
            name={'touchnet_cost'}
            render={({ field: { onChange, register, ref, ...rest } }) => (
              <InputContainer>
                <CurrencyInput
                  aria-label={'TouchNet Cost'}
                  errorMsg={_get(formState.errors.touchnet_cost, 'message') || ''}
                  id={'touchnet_cost'}
                  label={'Cost'}
                  openSans
                  required
                  onChange={value => {
                    setValue('touchnet_cost', value);
                  }}
                  {...rest}
                />
              </InputContainer>
            )}
          />
          <CurrencyContainer>
            <Label>Currency</Label>
            <CurrencyDiv>
              <span>USD ($)</span>
            </CurrencyDiv>
          </CurrencyContainer>
        </CostContainer>

        <Controller
          control={control}
          name={'touchnet_detail_code'}
          render={({ field: { ref, ...rest } }) => (
            <InputContainer>
              <TextInput
                aria-label={'TouchNet Label'}
                errorMsg={_get(formState.errors.touchnet_detail_code, 'message') || ''}
                id={'touchnet_detail_code'}
                label={'Detail Code'}
                openSans
                placeholder={'Enter Detail Code'}
                {...rest}
              />
            </InputContainer>
          )}
        />
        <Controller
          control={control}
          name={'touchnet_required'}
          render={({ field: { onChange, register, ref, ...rest } }) => (
            <ToggleContainer>
              <Toggle
                id={'touchnet_required'}
                label={'Required'}
                onChange={value => {
                  setValue('touchnet_required', value);
                }}
                ref={register}
                {...rest}
              />
              <ToggleLabel>Required Field</ToggleLabel>
            </ToggleContainer>
          )}
        />
        <BaseMsg>
          {`Detail Codes are optional and will not be visible to ${customAliases?.alias_travelers?.toLowerCase()}. 
          Depending on your integration, you may decide to include a detail code that ties into your organization's accounting system.`}
        </BaseMsg>
        <SubmitContainer>
          <AccentButtonMediumNunito
            aria-label={edit ? 'Save TouchNet' : 'Add TouchNet to Form'}
            form="touchnetAdd"
            type={'submit'}
            disabled={disableSubmit}
          >
            {disableSubmit ? <AccentButtonSpinner displayText='Saving ...' /> : edit ? 'Save' : 'Add to Form'}
            {!disableSubmit && <StyledIcon type='ChevronForward' color='#fff' />}
          </AccentButtonMediumNunito>
        </SubmitContainer>
      </form>
    </FormContainer>
  );
}
