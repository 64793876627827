const ACCENT_DEFAULT = '#dd2160';
const DARK_DEFAULT = '#043544';
const LIGHT_DEFAULT = '#76ccce';
const LEARN_MORE_URL = 'https://www.viatrm.com/';

export {
  ACCENT_DEFAULT,
  DARK_DEFAULT,
  LIGHT_DEFAULT,
  LEARN_MORE_URL,
}
