import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import { useDispatch, useSelector } from 'react-redux';
import propTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import ViaModal from '../../../../components/library/viaModal';
import Button from '../../../../components/library/button';
import Paragraph from '../../../../components/library/paragraph';
import OneClickModal from '../oneClickModal';
import sBatchUpdateProgramState from './batchUpdateProgramState.scss';
import ToastMessage from '../../toastMessage';
import { publishInternalProgram } from '../../../../../actions/reportsActions';
export const enhance = compose(withStyles(sBatchUpdateProgramState));

function BatchUpdateProgramState(props) {
  const dispatch = useDispatch();
  const [saving, setSaving] = useState(false);
  const [failure, setFailure] = useState(false);
  const [success, setSuccess] = useState(false);
  const [failedPrograms, setFailedPrograms] = useState([]);
  const handleBatchAction = action => {
    const publishValues = {
      program: {
        status: '',
      },
      program_ids: props.selectedPrograms,
      source: 'internal',
    };

    switch (action.toLowerCase()) {
      case '1':
        break;
      case 'publish':
        publishValues.program.status = 'published';
        dispatch(publishInternalProgram(publishValues));

        break;
      case 'unpublish':
        publishValues.program.status = 'unpublished';
        dispatch(publishInternalProgram(publishValues));
        break;
    }

    props.onClose(false);
  };

  useEffect(
    () => {
      if ((props.actionState && props.actionState !== null) || (props.actionState && props.actionState !== undefined)) {
        if (props.actionState.loading) {
          setSaving(true);
        }
        if (saving && !!props.actionState.data) {
          setSaving(false);

          if (!!props.actionState.data.failures && props.actionState.data.failures.length > 0) {
            setFailure(true);
            failedPrograms.push(...props.actionState.data.failures);
            props.onClose(false);
          } else if (!!props.actionState.data.data && props.actionState.data.data.length > 0) {
            setSuccess(true);
            props.onClose(false);
          }
        }
      }
    },
    [props.actionState],
  );

  return (
    <>
      <ViaModal
        showing={props.show}
        onClose={() => {
          props.onClose(false), setFailedPrograms([]);
        }}
        headerText={props.headline}
      >
        <div className={sBatchUpdateProgramState['one-click-modal']}>
          <div className={sBatchUpdateProgramState.content}>
            <Paragraph size="medium">{props.paragraphOne}</Paragraph>
          </div>
          <div className={sBatchUpdateProgramState.bulletedContent}>
            {props.bulletedMessage ? props.bulletedMessage : null}
          </div>

          {props.paragraphTwo ? (
            <div className={sBatchUpdateProgramState.content}>
              <Paragraph size="medium">{props.paragraphTwo}</Paragraph>
            </div>
          ) : null}

          <div className={sBatchUpdateProgramState.footer}>
            <div className={sBatchUpdateProgramState['button-content']}>
              <Button
                display="secondary"
                kind="solid"
                size="medium"
                onClick={() => {
                  props.onClose(false);
                  setFailedPrograms([]);
                }}
              >
                {props.cancelMessage}
              </Button>
            </div>
            <div className={sBatchUpdateProgramState['button-content']}>
              <Button
                display="primary"
                kind="solid"
                size="medium"
                type="submit"
                onClick={() => {
                  handleBatchAction(props.source);
                }}
              >
                {props.confirmActionMessage}
              </Button>
            </div>
          </div>
        </div>
      </ViaModal>
      {success ? (
        <ToastMessage
          message={props.publishSuccessMessage}
          show={success}
          isSuccess={true}
          onClose={() => {
            setSuccess(false);
            setFailedPrograms([]);
            props.onClose(true);
            props.setSelectedPrograms ? props.setSelectedPrograms() : null;
          }}
        />
      ) : null}
      {failure && props.source === 'unpublish' ? (
        <ToastMessage
          message={props.failureMessage}
          isSuccess={false}
          show={failure && !!props.failureMessage}
          source={props.source}
          onClose={() => {
            setFailure(false);
            props.onClose(true);
            setFailedPrograms([]);
            props.setSelectedPrograms ? props.setSelectedPrograms() : null;
          }}
        />
      ) : (
        <ViaModal
          showing={failure && props.source === 'publish'}
          onClose={() => {
            setFailure(false), setFailedPrograms([]);
          }}
          headerText={`Can’t Publish`}
        >
          <div className={sBatchUpdateProgramState['one-click-modal']}>
            <div className={sBatchUpdateProgramState.failureContent}>
              <Paragraph size="medium">{props.publishFailureMessage}</Paragraph>
            </div>

            <div className={sBatchUpdateProgramState.failureContent}>
              <ul>
                {failedPrograms.map(failure => (
                  <li>
                    <Paragraph size="medium">{failure}</Paragraph>
                  </li>
                ))}
              </ul>
            </div>

            <div className={sBatchUpdateProgramState.footer}>
              <div className={sBatchUpdateProgramState['button-content']}>
                <Button
                  display="primary"
                  kind="solid"
                  size="medium"
                  onClick={() => {
                    setFailure(false);
                    setFailedPrograms([]);
                  }}
                >
                  Ok
                </Button>
              </div>
            </div>
          </div>
        </ViaModal>
      )}
    </>
  );
}

BatchUpdateProgramState.propTypes = {
  show: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
};

export default enhance(BatchUpdateProgramState);
