import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import propTypes from 'prop-types';
import styled from 'styled-components';
import AccordionFormCard from '../../../../../../../styledComponents/styles/AccordionFormCard.styled';
import TextToggle from '../../../../../../../styledComponents/styles/TextToggle.styled';
import { Grid, Row, Col } from '../../../../../../../styledComponents/styles/FlexGrid.styled';
import { replaceCustomAliasesHandlebars } from '../../../../../../../shared/helpers/General';
import SectionHeader from '../../../../../../../styledComponents/SectionHeader';
import AccordionRow from '../../../../../../../styledComponents/styles/AccordionRow.styled';
import { TableContainer } from '../../../../../../../styledComponents/styles/Table.styled';
import { breakpoints } from '../../../../../../../styledComponents/settings/breakpoints';

import Toggle from '../../../../../../../styledComponents/styles/Toggle.styled';
import { updateMailerOptOut } from '../../../../../../../actions/orgSettingsActions';

const FormCardContainer = styled.div`
  margin: 20px;
`;

const AdjustedColumn = styled(Col)`
  width: 75%;
  padding-left: 5px;
`;

const HeaderRow = styled(Row)`
  padding: 16px 9px;
  font-family: AvenirNextMedium, sans-serif;
  font-style: normal;
  font-size: 16px;
  line-height: 22px;
  align-items: center;
  letter-spacing: -0.02em;
  color: #043544;
  background-color: #fff;
  flex-flow: row;
`;

const ToggleColumn = styled(Col)`
  justify-content: center;
  display: flex;
  width: 25%;
  text-align: center;
`;

export const NotificationSection = ({
  header,
  mailers,
  customAliases,
  userId,
  archivedMailers,
  category,
  isSaving,
}) => {
  const dispatch = useDispatch();
  const [showContent, setShowContent] = useState(true);
  const mailersArchived = archivedMailers.map(mailer => mailer.attributes.send_grid_mailer_type_id);
  const handleChange = (e, id, bulk) => {
    
    if (bulk === 'archive') {
      dispatch(
        updateMailerOptOut({
          mailer_opt_out: {
            send_grid_mailer_type_id: id,
            archived: true,
            user_id: userId,
          },
        }),
      );
    } else if (bulk === 'unarchive') {
      dispatch(
        updateMailerOptOut({
          mailer_opt_out: {
            send_grid_mailer_type_id: id,
            archived: false,
            user_id: userId,
          },
        }),
      );
    } else if (e === mailersArchived.includes(id)) {
      dispatch(
        updateMailerOptOut({
          mailer_opt_out: {
            send_grid_mailer_type_id: id,
            archived: e,
            user_id: userId,
          },
        }),
      );
    }
  };

  return (
    <Grid>
      <SectionHeader text={header} />
      {category.attributes.send_grid_mailer_minor_categories.map(
        attribute =>
          attribute.send_grid_mailer_types.length > 0 ? (
            <FormCardContainer key={`form-card-${category.id}-${attribute.id}`}>
              <AccordionFormCard
                title={replaceCustomAliasesHandlebars(attribute.display_name, customAliases)}
                key={attribute.id}
                headerComponent={
                  <TextToggle
                    handleOn={e =>
                      attribute.send_grid_mailer_types.map(type => {
                        handleChange(e, type.id, 'archive');
                      })
                    }
                    handleOff={e =>
                      attribute.send_grid_mailer_types.map(type => {
                        handleChange(e, type.id, 'unarchive');
                      })
                    }
                    onCopy="Turn all on"
                    offCopy="Turn all off"
                  />
                }
              >
                <TableContainer>
                  <HeaderRow>
                    <AdjustedColumn>Notification</AdjustedColumn>
                    <ToggleColumn>Notification Settings</ToggleColumn>
                  </HeaderRow>
                  {attribute.send_grid_mailer_types.map((type, index) => (
                    <AccordionRow
                      index={index}
                      key={type.id}
                      title={replaceCustomAliasesHandlebars(type.display_name, customAliases)}
                      information={replaceCustomAliasesHandlebars(type.description, customAliases)}
                      id={type.id}
                      component={
                        <Toggle
                          id={type.id}
                          name={type.name}
                          key={type.id}
                          // disabled={isSaving}
                          onChange={e => {
                            handleChange(e, type.id, null);
                          }}
                          value={!!mailersArchived && mailersArchived.includes(type.id) ? false : true}
                        />
                      }
                    />
                  ))}
                </TableContainer>
              </AccordionFormCard>
            </FormCardContainer>
          ) : null,
      )}
    </Grid>
  );
};

NotificationSection.propTypes = {};
