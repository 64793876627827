import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const Span = styled.span`

`;

const AllProgramsLink = styled(Link)`
  font-family: 'Nunito,sans-serif';
  font-weight: 600;
  display: inline-block;
  color: #373737;
  font-size: 14px;
  line-height: 24px;
  padding: 8px 16px;
  padding-left: 0;
  padding-right: 0;
  text-decoration: underline;
  text-align: center;

  svg {
    align-self: center;
    display: inline-flex;
    margin-bottom: 3px;
    margin-right: 5px;
    position: relative;
  }

  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }

  &:active {
    opacity: 0.9;
  }

  &:disabled,
  &:disabled:active {
    opacity: ${props => (props.saving ? '.7' : '0.3')};
    cursor: not-allowed;
  }

  &:focus {
    border: 1px solid #007bff;
    outline-color: #007bff;
  }
`;

const PlanDetailsBreadcrumbs = ({onClick, planName}) => {
  
  const buildUrl = () => {
    const pathname = window.location.pathname.split('/');
    const travelerId = pathname[3];
    let path = `/client/travelers/${travelerId}?tab=plans`;
    return path;
  }

  return (
    <Container>
      <AllProgramsLink onClick={onClick} to={buildUrl()}>
        My Plans
      </AllProgramsLink>
      {
        planName &&
        <Span>
        / {planName}
        </Span>
      }
    </Container>
  );
}
export default PlanDetailsBreadcrumbs;