import React, { useEffect, useState, useRef } from 'react';
import Modal from 'react-responsive-modal';
import { compose } from 'recompose';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import styled from 'styled-components';
import modalCss from 'react-responsive-modal/styles.css';
import { useDispatch, useSelector } from 'react-redux';
import { toggleRequestToWithdrawModal } from '../../actions/travelerApplicationsActions';
import { requestToWithdraw, fetchTravelerSubmission } from '../../../../actions/formsActions';
import TextInputArea from '../../../../styledComponents/styles/TextInputArea.styled';
import InfoIcon from '../icons/InfoIcon';
import CloseIcon from '../icons/CloseIcon';
import SuccessMessageIcon from '../icons/SuccessMessageIcon';
import { toast } from 'react-toastify';
import toastMessageStyles from '../../../../sites/viaGlobal/components/toastMessage/toastMessage.scss';
import { CircularProgress } from '@material-ui/core';

export const enhance = compose(withStyles(modalCss, toastMessageStyles));

const Header = styled.div`
  display: flex;
  justify-content: end;
  flex-direction: row;
  padding: 25px 25px 0px 0px;
`;

const Container = styled.div`
  padding: 40px 80px;
`;

const Heading = styled.h2`
  color: #373737;
  text-align: center;

  /* Headline 5 */
  font-family: Nunito;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 30px */
`;

const Info = styled.p`
  color: var(--black, #373737);
  text-align: center;
  /* Body 2 */
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 16.8px */
  letter-spacing: 0.035px;
`;

const InfoMessageContainer = styled.div`
  border-radius: 5px;
  background: #deecff;

  display: flex;
  flex-direction: row;
  gap: 22px;
  // height: 77px;
  justify-content: left;
  align-items: center;
  padding: 0px 15px;
  border-left: 20px solid #6ba2dc;

  margin-bottom: 18px;
`;

const InfoMessageIcon = styled.div``;

const InfoMessageText = styled.div`
  color: #4d4d4d;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 19.2px */
  letter-spacing: 0.08px;
  padding: 10px 0px; 
  word-break:break-word;
`;

const Footer = styled.div`
  border-radius: 0px 0px 10px 10px;
  background: #ebebeb;
  display: flex;
  flex-direction: row;
  justify-content: right;
  padding: 30px;
  gap: 55px;
`;

const CancelBtn = styled.button`
  color: #373737;
  /* Button Primary */
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 19.2px */
  letter-spacing: 0.96px;
  border: none;
  outline: none;
  &:focus-visible {
    outline: 2px solid #255a94;
    outline-offset: 1px;
  }
`;

const PrimaryBtn = styled.button`
  border-radius: 5px;
  background: var(--theme_color_accent);
  color: var(--theme_color_accent_font);
  text-align: center;
  /* Button Primary */
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 19.2px */
  letter-spacing: 0.96px;
  border: 1px #d74b73;
  padding: 16px 28px;

  &:hover {
    opacity: 0.7;
  }

  &:disabled {
    opacity: 0.7;
  }
`;

const CloseButton = styled.button`
  outline: none;
  border: none;
  padding: 0;
  background: none;
  &:focus-visible {
    outline: 2px solid #255a94;
    outline-offset: 1px;
  }
`;

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 48px;
  padding: 30px 0px;
`;

function RequestToWithdrawApplicationModal() {
  const dispatch = useDispatch();
  const modalRef = useRef(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const [submissionId, setSubmissionId] = useState('');
  const { applicationStatuses, showRequestToWithdrawModal: show } = useSelector(
    state => state.travelerApplicationSubmissions,
  );

  const travelerApplicationSubmissions = useSelector(state => state.travelerApplicationSubmissions);

  const { loading } = useSelector(state => state.forms.requestToWithdraw);

  const travelerSubmission = useSelector(state => state.forms.travelerSubmission);

  const [reason, setReason] = useState({
    value: '',
    error: '',
  });

  useEffect(() => {
    if (!travelerSubmission.loading && travelerSubmission.data) {
      setSubmissionId(travelerSubmission?.data?.id);
    }
  }, [travelerSubmission]);

  const handleClose = () => {
    if (showSuccess) {
      dispatch(fetchTravelerSubmission(submissionId));
    }
    dispatch(toggleRequestToWithdrawModal());
  };

  const handleSubmit = () => {
    setReason({
      ...reason,
      error: '',
    });

    let isError = false;

    if (!reason.value) {
      isError = true;
      setReason({
        ...reason,
        error: 'Please enter your reason for requesting to withdraw',
      });
    }

    if (isError) {
      return false;
    }

    dispatch(
      requestToWithdraw(submissionId, reason.value, (status, result) => {
        if (status) {
          setShowSuccess(true);
          // dispatch(fetchTravelerSubmission(submissionId));
        } else {
          toast(result, {
            type: 'error',
            className: toastMessageStyles['failureContainer'],
            toastClassName: toastMessageStyles['wrapper'],
            bodyClassName: toastMessageStyles['failureBody'],
            progressClassName: toastMessageStyles['progress-bar'],
          });
        }
      }),
    );
  };

  useEffect(() => {
    if (show && modalRef.current) {
      modalRef.current.focus();
    }
  }, [show]);

  return (
    <Modal
      blockScroll={false}
      open={show}
      onClose={handleClose}
      center
      closeOnEsc
      closeOnOverlayClick
      showCloseIcon={false}
      styles={{
        modal: {
          padding: '0px',
          borderRadius: '5px',
          width: '700px',
        },
      }}
    >
      <div 
        tabIndex="-1"
        ref={modalRef}
      >
      <Header>
        <CloseButton aria-label="Close Modal" tabIndex="0"  onClick={handleClose}>
          <CloseIcon fillColor={`#9FABAE`} />
        </CloseButton>
      </Header>

      {!showSuccess ? (
        <Container>
          <Heading>Request to Withdraw</Heading>

          <Info>
            You are about to send a request to withdraw to your administrators. Sending this request will not
            automatically change your application status.
          </Info>

          {travelerSubmission?.requestToWithdrawData?.instruction && (
            <InfoMessageContainer>
              <InfoMessageIcon>
                <InfoIcon fillColor={'#6BA2DC'} height={'40px'} width={'40px'} />
              </InfoMessageIcon>

              <InfoMessageText>{travelerSubmission?.requestToWithdrawData?.instruction}</InfoMessageText>
            </InfoMessageContainer>
          )}

          <TextInputArea
            label={`Why are you requesting to withdraw?`}
            placeholder={'Enter your reason for requesting to withdraw'}
            key={'instruction'}
            id={'reasons'}
            rows={4}
            charLimit={200}
            required={true}
            value={reason.value}
            errorMsg={reason.error}
            onChange={e => {
              setReason({
                value: e.target.value,
                error: '',
              });
            }}
          />
        </Container>
      ) : (
        <MessageContainer>
          <SuccessMessageIcon />
          <Heading>Your request to withdraw has been sent!</Heading>
          <Info>A message has been sent to your administrators.</Info>
        </MessageContainer>
      )}
      </div>
      <Footer>
        {showSuccess ? (
          <>
            <PrimaryBtn onClick={handleClose}>Okay</PrimaryBtn>
          </>
        ) : (
          <>
            <CancelBtn onClick={handleClose}>Cancel</CancelBtn>
            <PrimaryBtn onClick={handleSubmit} disabled={loading}>
              Request to Withdraw
              {loading && (
                <>
                  {' '}
                  <CircularProgress color="inherit" size={20} />
                </>
              )}
            </PrimaryBtn>
          </>
        )}
      </Footer>
    </Modal>
  );
}

export default enhance(RequestToWithdrawApplicationModal);
