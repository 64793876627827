export default [
  {
    id: 1,
    label: 'How long I have to apply and prepare',
    value: 'planning_time',
  },
  {
    id: 2,
    label: 'Cost',
    value: 'cost',
  },
  {
    id: 3,
    label: 'Academic credit',
    value: 'academic_credit',
  },
  {
    id: 4,
    label: 'Leaving my family / friends behind',
    value: 'people_left_behind',
  },
  {
    id: 5,
    label: 'Missing out on something back home',
    value: 'missing_out_at_home',
  },
  {
    id: 6,
    label: 'Picking the right choice',
    value: 'picking_a_program',
  },
  {
    id: 7,
    label: 'Discrimination / identity concern',
    value: 'discrimination_identity_concern',
  },
  {
    id: 8,
    label: 'Not knowing anyone',
    value: 'not_knowing_anyone',
  },
  {
    id: 9,
    label: 'Housing',
    value: 'housing',
  },
  {
    id: 10,
    label: 'Unsupportive parent / teacher / mentor',
    value: 'unsupportive_parent',
  },
  {
    id: 11,
    label: 'Food issue / dietary restrictions',
    value: 'dietary_issues',
  },
  {
    id: 12,
    label: 'Getting accepted',
    value: 'program_acceptance',
  },
  {
    id: 13,
    label: 'Health or safety issue',
    value: 'health_safety_issue',
  },
  {
    id: 14,
    label: `I'm not sure`,
    value: 'not_sure',
  },
  {
    id: 15,
    label: 'None',
    value: 'no_questions_concerns',
  },
  {
    id: 16,
    label: 'Other',
    value: 'other',
  },
];
