import { orderBy, uniq } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { getTermNames } from '../../../actions/termNamesActions'

export default function useTermNameOptions() {
  const dispatch = useDispatch();
  const { programs } = useSelector(state => state.programDashboard);
  const term_names = useSelector(state => state.termNames.termNames.data);
  const {  organizationId } = useSelector(state => state.currentOrganization);
  const [termNames, setTermNames] = useState([]);
  const [allOptions, setAllOptions] = useState([]);

  useEffect(() => {
    if (!term_names) {
      dispatch(getTermNames('true',organizationId));
    } else {
      let active = [];

      term_names.data.map(item => {
        if (item.attributes.name.length > 0) {
          if (!item.attributes.archived) {
            active.push({
              value: item.attributes.name,
              label: item.attributes.name,
            });
          } 
        }
      });
      setTermNames(active);
      setAllOptions(active);
    }
  }, [term_names]);

  return [allOptions, termNames, setTermNames];
}
