import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import React, { useRef, useState, useEffect } from 'react';

import AccountDropdown from './AccountDropdown';
import envelopeIcon from './icons/envelopeIcon.png';
import envelopeIconAllRead from './icons/markAllReadIcon.png';
import userId from '../../../../shared/helpers/userId';
import { fetchTravelerMessages } from '../../../../actions/profileActions';

const Container = styled.nav`
  position: relative;
  display: inline-block;
  align-items: center;
  margin-right: 20px;
  padding-left: 1rem;
  background-color: #ffffff;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  box-shadow: 0px 10px 15px rgba(132, 131, 131, 0.25);
`;

const MessagesLink = styled(NavLink)`
  &:focus-visible {
    outline: none;

    img {
      outline: 2px solid #255a94;
      outline-offset: 1px;
    }
  }
`;

const MenuButton = styled.button`
  padding: 0;
  background-color: transparent;
  border: none;

  img {
    position: relative;
    right: -20px;
    width: 44px;
    height: 44px;
    background-color: #f1f1f1;
    border-radius: 50%;
  }

  &:focus,
  &:focus-visible {
    outline: none;
  }

  &:focus-visible {
    img {
      outline: 2px solid #255a94;
    }
  }
`;

export default function AccountNavigation() {
  const dispatch = useDispatch();
  const { profile } = useSelector(state => state.travelerProfile);
  const messages = useSelector(state => state?.profile?.messages);
  const [totalNewMessages, setTotalNewMessages] = useState(0);

  const buttonRef = useRef(null);

  const [menuIsOpen, setMenuIsOpen] = useState(false);

  useEffect(() => {
    dispatch(fetchTravelerMessages(userId, 'traveler'));
  }, []);

  useEffect(() => {
    if (messages && Array.isArray(messages)) {
      let totalMessages = messages.filter(
        message => message.is_read === false && Number(message?.sender?.id) !== Number(userId),
      );
      setTotalNewMessages(totalMessages.length);
    }
  }, [messages]);

  return (
    <Container aria-label="Account Navigation">
   
    <MessagesLink tabIndex={0} to="/traveler/messages" aria-label="View Messages">
        {totalNewMessages > 0 ? (
          <img src={envelopeIcon} alt="New Messages" />
        ) : (
          <img src={envelopeIconAllRead} alt="Read All Messages" />
        )}
      </MessagesLink>
    
    
      <MenuButton
        ref={buttonRef}
        type="button"
        aria-label={menuIsOpen ? 'Close Account Settings Menu' : 'Open Account Settings Menu'}
        onClick={() => {
          setMenuIsOpen(!menuIsOpen);
        }}
      >
        <img src={`${profile.avatar.url}?cb=${new Date().getTime().toString()}`} alt="User Profile Image" longdesc={`${profile.avatar.url}?cb=${new Date().getTime().toString()}`} />
      </MenuButton>
      {menuIsOpen && (
        <AccountDropdown
          isOpen={menuIsOpen}
          buttonRef={buttonRef}
          onClickOutside={() => {
            setMenuIsOpen(false);
          }}
        />
      )}
    </Container>
  );
}
