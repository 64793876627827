import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SelectInput from '../../base/inputs/SelectInput';
import TravelerFormQuestionContainer from '../../base/TravelerFormQuestionContainer';
import userId from '../../../../../shared/helpers/userId';
import { addIntakeQuestions, addAdminIntakeFormData } from '../../../actions/intakeFormActions';
import options from '../../../../travelerProfile/utils/options/yearInSchoolOptions';

export default function IntakeCurrentYearInSchool(props) {
  const dispatch = useDispatch();
  const intake = useSelector(state => state.intakeForm);
  const adminIntakeFormData = intake.adminIntakeFormData;
  const [value, setValue] = useState({});
  const [error, setError] = useState('');
  const [disabled, setDisabled] = useState(false);

  const question = {
    overline: 'Current Year in School',
    heading: 'What is your current year in school?',
    subheading: 'Select your year in school.',
    isRequired: intake?.intakeQuestions?.find(item => item.identifier == 'year_in_school').required ?? false,
  };

  useEffect(
    () => {
      if(props?.isAdmin) {
        const initialValue = options.filter(item => adminIntakeFormData?.year_in_school===item.value);
        setValue(initialValue);
      } else {
        const initialValue = options.filter(item => intake.year_in_school===item.value);
        setValue(initialValue);
      }
      setDisabled(intake.ssoTraveler && intake.readonly.includes('year_in_school'));
    },
    [intake, adminIntakeFormData],
  );

  const handleNext = () => {
    setError('');
    if (!disabled && value.length === 0 && question.isRequired) {
      setTimeout(() => {
        setError('Required, please select your year in school');
      }, 0);
      if (props.isAdmin) {
        return;
      }
      throw new Error();
    }
    if (props.isAdmin) {
      dispatch(addAdminIntakeFormData('year_in_school', Array.isArray(value) ? value[0]?.value : value?.value));
      props.handleAdminStep();
      return;
    }

    if (!disabled) {
      dispatch(
        addIntakeQuestions(
          userId,
          { year_in_school: Array.isArray(value) ? value[0]?.value : value?.value },
          'year_in_school',
        ),
      );
    }
  };

  const handleOnChange = event => {
    if (event) {
      setValue(event);
    } else {
      setValue({});
    }
  };

  return (
    <TravelerFormQuestionContainer
      isAdmin={props.isAdmin}
      question={question}
      handleBackStep={() => props.handleAdminBackStep()}
      adminIntakeStep={props.adminIntakeStep}
      handleNext={handleNext}
      disabled={disabled}
      canBeSynced
    >
      <SelectInput
        value={value}
        defaultValue={value}
        id="current_year_in_school"
        label="Year in School"
        placeholder="Select year in school"
        options={options}
        error={error}
        disabled={disabled}
        required={question.isRequired}
        handleOnChange={handleOnChange}
      />
    </TravelerFormQuestionContainer>
  );
}
