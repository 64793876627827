import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Accordion } from 'react-accessible-accordion';

import NotificationSettingsParentItem from './NotificationSettingsParentItem';

import minorCategoryItem from '../../types/minorCategoryItem';

const AccordionContainer = styled(Accordion)`
  display: flex;
  flex-direction: column;
  gap: 1.27375rem;
`;

export default function NotificationSettingsAccordion({ minorCategoryItems }) {
  return (
    <AccordionContainer allowMultipleExpanded allowZeroExpanded>
      {minorCategoryItems.map(minorCategoryItem => (
        <NotificationSettingsParentItem
          key={`accordion-item-${minorCategoryItem.id}`}
          minorCategoryItem={minorCategoryItem}
        />
      ))}
    </AccordionContainer>
  );
}

NotificationSettingsAccordion.propTypes = {
  minorCategoryItems: PropTypes.arrayOf(minorCategoryItem).isRequired,
};
