export const sizes = {
  mobile: '22.5rem',
  tablet: '46.875rem',
  desktop: '68.75rem',
};

export const breakpoints = {
  mobile: `(min-width: ${sizes.mobile})`,
  tablet: `(min-width: ${sizes.tablet})`,
  desktop: `(min-width: ${sizes.desktop})`,
};
