import PropTypes from 'prop-types';
import Cropper from 'react-easy-crop';
import styled from 'styled-components';
import Slider from '@material-ui/core/Slider';
import React, { useCallback, useEffect, useState } from 'react';

import { breakpoints } from '../../../utils/breakpoints';
import { getCroppedImg } from '../../../../../styledComponents/styles/imageCropper/canvasUtils';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const OuterImageContainer = styled.div`
  position: relative;
  width: 175px;
  height: 175px;
  margin: 0 auto;
  border: solid 1px #646464;

  @media ${breakpoints.tablet} {
    width: 300px;
    height: 300px;
  }
`;

const InnerImageContainer = styled.div`
  padding-bottom: 1rem;
`;

const ControlsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 2rem;
`;

const ControlsItem = styled.div`
  span {
    display: block;
    margin-bottom: 0.25rem;
    font-family: 'Open Sans', sans-serif;
    font-size: 1rem;
    color: #373737;
  }
`;

export default function ProfilePictureCropper({ previewImage, shouldCrop, handleCropImage }) {
  const MAX_ZOOM = 3;
  const MIN_ZOOM = 0.4;
  const STEP_ZOOM = 0.1;
  const MIN_ROTATION = 0;
  const STEP_ROTATION = 1;
  const MAX_ROTATION = 360;

  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  useEffect(
    () => {
      if (shouldCrop) {
        cropImage();
      }
    },
    [shouldCrop],
  );

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const cropImage = useCallback(
    async () => {
      try {
        const croppedImage = await getCroppedImg(previewImage, croppedAreaPixels, rotation);

        handleCropImage(croppedImage);
      } catch (e) {
        console.error(e);
      }
    },
    [previewImage, croppedAreaPixels, rotation],
  );

  return (
    <Container>
      <OuterImageContainer>
        <InnerImageContainer>
          <Cropper
            aspect={1}
            crop={crop}
            cropShape="round"
            image={previewImage}
            minZoom={MIN_ZOOM}
            objectFit="horizontal-cover"
            restrictPosition={false}
            rotation={rotation}
            zoom={zoom}
            showGrid
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onRotationChange={setRotation}
            onZoomChange={setZoom}
          />
        </InnerImageContainer>
      </OuterImageContainer>
      <ControlsContainer>
        <ControlsItem>
          <span id="zoom_label">Zoom</span>
          <Slider
            value={zoom}
            min={MIN_ZOOM}
            max={MAX_ZOOM}
            step={STEP_ZOOM}
            aria-labelledby="zoom_label"
            onChange={(event, zoom) => {
              setZoom(zoom);
            }}
          />
        </ControlsItem>
        <ControlsItem>
          <span id="rotation_label">Rotation</span>
          <Slider
            value={rotation}
            min={MIN_ROTATION}
            max={MAX_ROTATION}
            step={STEP_ROTATION}
            aria-labelledby="rotation_label"
            onChange={(event, zoom) => {
              setRotation(zoom);
            }}
          />
        </ControlsItem>
      </ControlsContainer>
    </Container>
  );
}

ProfilePictureCropper.propTypes = {
  shouldCrop: PropTypes.bool,
  previewImage: PropTypes.string.isRequired,
  handleCropImage: PropTypes.func.isRequired,
};

ProfilePictureCropper.defaultProps = {
  shouldCrop: false,
};
