exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2y2F8{padding:30px 45px}._23PkA{font-family:Nunito;font-size:25px;font-weight:400;line-height:30px;letter-spacing:0;text-align:left;margin-bottom:0}._2SUJc{gap:64px;padding:25px 26px}._2eyUL{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row}._3ovmm{font-weight:400}._2WxnF,._3ovmm{font-family:Open Sans,sans-serif;font-size:16px;line-height:22.4px;letter-spacing:.5%}._2WxnF{font-weight:700;margin-top:20px}._2WxnF a,._2WxnF a:hover{color:#255a94}._3jIn2{font-family:Open Sans;font-size:16px;font-weight:400;line-height:22.4px;letter-spacing:.005em;text-align:left}._21E-f{padding:0}", ""]);

// exports
exports.locals = {
	"container": "_2y2F8",
	"heading": "_23PkA",
	"headerTitle": "_2SUJc",
	"modalBodycontainer": "_2eyUL",
	"colspancontent": "_3ovmm",
	"colpcontent": "_2WxnF",
	"text": "_3jIn2",
	"modalBody": "_21E-f"
};