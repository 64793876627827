import React, { useEffect, useState, memo } from 'react';
import { compose } from 'recompose';
import propTypes from 'prop-types';
import { useSelector } from 'react-redux';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import _concat from 'lodash/concat';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { buildSelectOptions } from '../../../../../shared/helpers/General';
import FilterMultiSelect from '../../filterTypes/filterMultiSelect';
import sFilterAccordion from '../../../../../../public/css/filterAccordion.scss';

export const enhance = compose(withStyles(sFilterAccordion));

function GenericMultiFilter(props) {
  const [expanded, setExpanded] = useState(false);
  const [manualExpanded, setManualExpanded] = useState(false);
  const [sourceOptions, setSourceOptions] = useState([]);

  useEffect(() => {
    if (props.selectedValues) {
      const calcExpanded = manualExpanded || props.selectedValues.length > 0 ? true : false;
      setExpanded(calcExpanded);
    }

    let valueNames = [];

    props.sourceData.forEach(item => {
      if (item?.length > 0) {
        valueNames = _concat(valueNames, item);
      }
    });
    const optionValues = buildSelectOptions(valueNames, true, true);

    setSourceOptions(optionValues);
  }, [props]);

  const selectedValues = values => {
    props.change(values);
  };

  return (
    <Accordion
      allowMultipleExpanded
      allowZeroExpanded
      onChange={() => {
        setExpanded(!expanded);
        setManualExpanded(!manualExpanded);
      }}
    >
      <AccordionItem uuid={props.id} dangerouslySetExpanded={expanded}>
        <AccordionItemHeading>
          <AccordionItemButton>{props.displayText}</AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <FilterMultiSelect
            id={props.id}
            sourceData={sourceOptions}
            selectedValues={props.selectedValues}
            change={e => selectedValues(e)}
            labelText={''}
          />
        </AccordionItemPanel>
      </AccordionItem>
    </Accordion>
  );
}

GenericMultiFilter.propTypes = {
  change: propTypes.func.isRequired,
  displayText: propTypes.string,
  id: propTypes.string.isRequired,
  selectedValues: propTypes.array,
  sourceData: propTypes.array.isRequired,
};

GenericMultiFilter.defaultProps = {
  displayText: '',
  selectedValues: [],
};

export default enhance(GenericMultiFilter);

export const CollegeOrFacultyMemo = memo(
  compose(withStyles(sFilterAccordion))(function CollegeOrFacultyMemo({
    selectedValues,
    onFilterChange,
    sourceData = [],
  }) {
    return (
      <GenericMultiFilter
        id={'college_or_faculty'}
        selectedValues={selectedValues}
        displayText={'College or Faculty'}
        change={values => {
          onFilterChange('college_or_faculty', values);
        }}
        sourceData={sourceData}
        // sourceData={_map(props.travelers, 'college_or_faculty')}
      />
    );
  }),
);

export const PreferredProgramMemo = memo(
  compose(withStyles(sFilterAccordion))(function PreferredProgramMemo({
    selectedValues,
    onFilterChange,
    sourceData = [],
  }) {
    const customAliases = useSelector(state => state.profile.customAliases);

    return (
      <GenericMultiFilter
        id={'favs'}
        selectedValues={selectedValues}
        displayText={`Preferred ${customAliases.alias_program || 'Favorites'}`}
        change={values => {
          onFilterChange('favs', values);
        }}
        sourceData={sourceData}
      />
    );
  }),
);

export const AdvisorFilterMemo = memo(
  compose(withStyles(sFilterAccordion))(function AdvisorFilterMemo({
    selectedValues,
    onFilterChange,
    sourceData = [],
  }) {
    return (
      <GenericMultiFilter
        id={'advisor'}
        selectedValues={selectedValues}
        displayText={`Advisor`}
        change={values => {
          onFilterChange('advisor', values);
        }}
        sourceData={sourceData}
      />
    );
  }),
);
