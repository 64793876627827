exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@-webkit-keyframes _2PfHG{to{-webkit-transform:rotate(1turn)}}@keyframes _2PfHG{to{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}._1DoSj{margin:16px 0;margin:1rem 0;width:100%}._1DoSj td{color:#043544;font-family:AvenirNextRegular,sans-serif;border-right:2px solid #fff;padding:0 16px;padding:0 1rem;line-height:36px;line-height:2.25rem}._1DoSj td:last-child{border-right:none}._1DoSj th{color:#043544}._1DoSj thead tr{color:#043544;font-family:AvenirNextDemiBold,sans-serif;font-size:14px;font-size:.875rem;line-height:36px;width:100%;background:transparent}._1DoSj tbody tr:nth-child(odd){background:rgba(118,204,206,.25)}._1DoSj tbody tr:nth-child(2n){background:rgba(118,204,206,.12)}._2JGrn{margin:20px;width:95%}._2JGrn td{color:#043544;font-family:AvenirNextRegular,sans-serif;border-right:2px solid #fff;padding:0 16px;padding:0 1rem;line-height:36px;line-height:2.25rem;width:85%}._2JGrn td:last-child{border-right:none;text-align:center}._2JGrn th{color:#043544}._2JGrn th:last-child{text-align:center}._2JGrn thead tr{color:#043544;font-family:AvenirNextDemiBold,sans-serif;font-size:14px;line-height:32px;width:100%;background:transparent}._2JGrn tbody tr:nth-child(odd){background:rgba(118,204,206,.25)}._2JGrn tbody tr:nth-child(2n){background:rgba(118,204,206,.12)}._2L-wd{font-family:IntroBoldItalic,sans-serif;font-size:32px;color:#043544;text-align:center;line-height:32px}", ""]);

// exports
exports.locals = {
	"table": "_1DoSj",
	"noRecordText": "_2L-wd",
	"orgSettingTerms": "_2JGrn",
	"spin": "_2PfHG"
};