import React from 'react';
import styled from 'styled-components';

const ToolTipText = styled.span`
  background-color: ${({ theme }) => theme.tooltip.backgroundColor};
  border: ${({ theme }) => theme.tooltip.border};
  color: ${({ theme }) => theme.tooltip.fontColor};
  bottom: 90%;
  font-family: ${({ theme }) => theme.tooltip.fontFamily};
  font-size: ${({ theme }) => theme.tooltip.fontSize};
  line-height: ${({ theme }) => theme.tooltip.lineHeight};
  padding: 5px;
  position: absolute;
  left: ${({ positionLeft }) => (positionLeft ? positionLeft : '50%')};
  margin-left: 5px;
  text-align: left;
  visibility: hidden;
  width: ${({ width }) => (width ? `${width}` : '150px')};
  z-index: 1;
`;

const ToolTip = styled.div`
  color: inherit;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  display: inline-block;
  padding-left: 5px;
  position: relative;
  z-index: 1;

  &:hover {
    span {
      visibility: visible;
    }
  }

  &:focus {
    span {
      visibility: visible;
    }
  }

  // &:visited {
  //   span {
  //     visibility: visible;
  //   }
  // }

  // &:focus-visible {
  //   span {
  //     visibility: visible;
  //   }
  // }

  
  // &:target {
  //   span {
  //     visibility: visible;
  //   }
  // }

  
  &:focus-within {
    span {
      visibility: visible;
    }
  }

  
  // &:active {
  //   span {
  //     visibility: visible;
  //   }
  // }

`;

export default function Tooltip({ children, disabled, toolTipText, positionLeft, width }) {
  return (
    <ToolTip disabled={disabled}>
      {children}
      <ToolTipText positionLeft={positionLeft} width={width}>{toolTipText}</ToolTipText>
    </ToolTip>
  );
}
