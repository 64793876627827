import React from 'react';

export default function ArrowSwitchIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.14645 1.14645C9.34171 0.951184 9.65829 0.951184 9.85355 1.14645L13.3536 4.64645C13.5488 4.84171 13.5488 5.15829 13.3536 5.35355L9.85355 8.85355C9.65829 9.04882 9.34171 9.04882 9.14645 8.85355C8.95118 8.65829 8.95118 8.34171 9.14645 8.14645L12.2929 5L9.14645 1.85355C8.95118 1.65829 8.95118 1.34171 9.14645 1.14645Z"
        fill="#446372"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5 5C2.5 4.72386 2.72386 4.5 3 4.5H12.4647C12.7408 4.5 12.9647 4.72386 12.9647 5C12.9647 5.27614 12.7408 5.5 12.4647 5.5H3C2.72386 5.5 2.5 5.27614 2.5 5Z"
        fill="#446372"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.85355 7.14645C7.04882 7.34171 7.04882 7.65829 6.85355 7.85355L3.70711 11L6.85355 14.1464C7.04882 14.3417 7.04882 14.6583 6.85355 14.8536C6.65829 15.0488 6.34171 15.0488 6.14645 14.8536L2.64645 11.3536C2.45118 11.1583 2.45118 10.8417 2.64645 10.6464L6.14645 7.14645C6.34171 6.95118 6.65829 6.95118 6.85355 7.14645Z"
        fill="#446372"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.0625 11C3.0625 10.7239 3.28636 10.5 3.5625 10.5H13C13.2761 10.5 13.5 10.7239 13.5 11C13.5 11.2761 13.2761 11.5 13 11.5H3.5625C3.28636 11.5 3.0625 11.2761 3.0625 11Z"
        fill="#446372"
      />
    </svg>
  );
}
