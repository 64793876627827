import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import history from '../../../../history';
import { breakpoints } from '../../utils/breakpoints';
import { buttonFocus } from '../../utils/shared/focusStyles';
import { isAdminType, programIdType, isTravelerType } from '../../utils/types';

const BreadcrumbsNav = styled.nav`
  width: 100%;
  max-width: 60.375rem;
  margin: 0 auto;
  padding: 1.5rem 0;

  @media ${breakpoints.tablet} {
    width: auto;
    margin: 0;
  }

  @media ${breakpoints.desktop} {
    width: 100%;
    margin: 0 auto;
    padding-right: 0;
  }

  button {
    display: inline-flex;
    align-items: center;
    gap: 0.1875rem;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
    color: #373737;
    text-decoration: none;
    cursor: pointer;
    background-color: transparent;
    background-image: none;
    border: 2px solid transparent;
    border-radius: 2px;
    transform: translateX(-8px);

    @media ${breakpoints.tablet} {
      gap: 0.4375rem;
    }

    &:hover {
      color: #255a94;
    }

    ${buttonFocus};
  }
  span {
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 120%;
    letter-spacing: 0.0025em;
    font-family: 'Nunito', sans-serif;

    @media ${breakpoints.tablet} {
      font-size: 0.875rem;
    }
  }
`;

export const ViewLink = styled.a`
  flex-grow: 1;
  padding:10px;
  background-color: #eeeeee;
  border: none;
  border-top: 1px solid #bbbbbb;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 1rem;
  cursor:pointer;
  color: #373737;
  text-align: center;
  letter-spacing: 0.06em;
  line-height: 120%;
  width: 28%;
  text-align: left;
  
  &:hover {
    opacity: 0.8;
    color: #373737;
    text-decoration: none;
  }

  @media ${breakpoints.desktop} {
    flex-grow: unset;
    border: 1px solid #373737;
    border-radius: 5px;
  }

  &:focus {
    outline: none;
  }

  &:focus-visible {
    outline: 2px solid #255a94;
    outline-offset: 1px;
  }
  
`;

export default function Breadcrumbs({ programId, isAdmin, isTraveler }) {
  const isApplyPage = history.location.pathname.includes('apply');
  const programAlias = useSelector(state => state.auth.organization.info.alias_program);

  const handleClick = () => {
    let route = '/visitor-programs';
    if (isTraveler && !isAdmin) {
      route = '/traveler/program-dashboard';
    }
    else if (isAdmin) {
      if(history.length>1) {
        history.goBack();
      } 
      route = '/client/program-dashboard/internal';
    }
    
    isApplyPage ? history.push(`/program_brochure/${programId}/`) : history.push(route);
  };

  return (
    <BreadcrumbsNav aria-label="Breadcrumb Navigation">
      {isAdmin ? <ViewLink onClick={handleClick} >
          <svg
            style={{ height: '1.5rem', width: '1.5rem' }}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M16.17 4.455c.44.439.44 1.151 0 1.59L10.216 12l5.955 5.954a1.125 1.125 0 0 1-1.591 1.591l-6.75-6.75a1.125 1.125 0 0 1 0-1.59l6.75-6.75c.439-.44 1.151-.44 1.59 0Z"
              fill="currentColor"
            />
          </svg>
          <span>
            {`Go Back`}
          </span>
        </ViewLink> : (
        <button type="button" onClick={handleClick}>
          <svg
            style={{ height: '1.5rem', width: '1.5rem' }}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M16.17 4.455c.44.439.44 1.151 0 1.59L10.216 12l5.955 5.954a1.125 1.125 0 0 1-1.591 1.591l-6.75-6.75a1.125 1.125 0 0 1 0-1.59l6.75-6.75c.439-.44 1.151-.44 1.59 0Z"
              fill="currentColor"
            />
          </svg>
          <span>
            {isApplyPage
              ? `Back to ${programAlias.toLowerCase()}`
              : `Return to ${programAlias.toLowerCase()} dashboard`}
          </span>
        </button>
      )}
    </BreadcrumbsNav>
  );
}

Breadcrumbs.propTypes = {
  programId: programIdType,
  isAdmin: isAdminType,
  isTraveler: isTravelerType,
};

Breadcrumbs.defaultProps = {
  programId: 0,
  isAdmin: false,
  isTraveler: false,
};
