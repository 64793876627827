import React, { useState, useEffect } from 'react';
import { compose } from 'recompose';
import moment from 'moment-timezone';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import propTypes from 'prop-types';
import classnames from 'classnames';
import 'react-dates/initialize';
import { SingleDatePicker } from 'react-dates';
import reactDatesStyles from '../../../../../public/css/filter-react-dates.css';
import sInputDate from './inputDate.scss';

export const enhance = compose(withStyles(sInputDate, reactDatesStyles));

function InputDate(props) {
  const isError = props.errorMessage.length > 0;
  const helpLabelClass = classnames(
    sInputDate['label-date'],
    isError ? sInputDate['error'] : '',
    sInputDate[props.labelSize],
    sInputDate[props.labelFont],
  );
  const [selectDate, setSelectDate] = useState();
  const [focusedInput, setFocusedInput] = useState(null);

  const yearRange = () => {
    const years = [];
    const dateStart = moment().add(props.yearsIncrease, 'y');
    const dateEnd = moment().subtract(props.yearsDecrease, 'y');

    while (dateEnd.diff(dateStart, 'years') <= 0) {
      years.push(dateStart.format('YYYY'));
      dateStart.subtract(1, 'year');
    }
    return years;
  };

  useEffect(
    () => {
      if (props.setAddMargin) {
        if (!!focusedInput) {
          props.setAddMargin(true);
        } else {
          props.setAddMargin(false);
        }
      } else {
        null;
      }
    },
    [focusedInput],
  );

  useEffect(
    () => {
      if (props.forceClear) {
        setSelectDate(null);
      }
    },
    [props],
  );

  return (
    <>
      {!!props.labelText && (
        <label className={helpLabelClass} htmlFor={props.id}>
          {props.labelText} {props.isRequired ? <div className={sInputDate.required}>*</div> : ''}
        </label>
      )}
      <div>
        <SingleDatePicker
          id={props.name}
          date={!selectDate && moment(props.placeholder).isValid() ? moment(props.placeholder) : selectDate}
          onDateChange={date => {
            moment(date).isValid()
              ? (props.onChange(moment(date)), setSelectDate(moment(date)))
              : (props.onChange(null), setSelectDate(null));
          }}
          focused={focusedInput}
          onFocusChange={({ focused }) => {
            setFocusedInput(!focusedInput);
          }}
          numberOfMonths={1}
          displayFormat="MMM D, YYYY"
          placeholder={props.placeholder}
          disabled={props.disabled}
          withPortal={props.withPortal}
          isOutsideRange={() => false}
          showClearDate={props.showClearDate}
          appendToBody={props.appendToBody}
          renderMonthElement={({ month, onMonthSelect, onYearSelect }) => (
            <div className={sInputDate.dateSelectContainer}>
              <div>
                <select
                  value={month.month()}
                  onChange={e => {
                    onMonthSelect(month, e.target.value);
                  }}
                >
                  {moment.months().map((label, value) => (
                    <option value={value} key={value}>
                      {label}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <select
                  value={month.year()}
                  onChange={e => {
                    onYearSelect(month, e.target.value);
                  }}
                >
                  {yearRange().map(range => (
                    <option value={range} key={range}>
                      {range}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          )}
        />
      </div>
      {!!props.helpText && !isError && <span className={sInputDate['help-date']}>{props.helpText}</span>}
      {isError && <span className={sInputDate['error-date']} aria-live='assertive'>{props.errorMessage}</span>}
    </>
  );
}
InputDate.propTypes = {
  errorMessage: propTypes.string,
  labelText: propTypes.string,
  helpText: propTypes.string,
  placeholder: propTypes.string,
  isRequired: propTypes.bool,
  name: propTypes.string.isRequired,
  onChange: propTypes.func.isRequired,
  withPortal: propTypes.bool,
  showClearDate: propTypes.bool,
  size: propTypes.oneOf(['medium', 'normal']),
  labelSize: propTypes.oneOf(['medium', 'normal']),
  labelFont: propTypes.oneOf(['nextMedium', 'regular']),
  forceClear: propTypes.bool,
  appendToBody: propTypes.bool,
  yearsIncrease: propTypes.number,
  yearsDecrease: propTypes.number,
  disabled: propTypes.bool,
};

InputDate.defaultProps = {
  errorMessage: '',
  labelText: '',
  helpText: '',
  placeholder: '',
  isRequired: false,
  withPortal: false,
  showClearDate: false,
  size: 'normal',
  labelSize: 'medium',
  forceClear: false,
  appendToBody: false,
  yearsIncrease: 20,
  yearsDecrease: 20,
  disabled: false,
  labelFont: 'nextMedium',
};

export default enhance(InputDate);
