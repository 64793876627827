import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import PageWrapper from './base/PageWrapper';
import ProfileMenu from './base/ProfileMenu';
import EmptyPrograms from './my-programs/EmptyPrograms';
import { FlexRowHeadingContainer, Heading } from './base/PageHeading.styled';
import LoadingScreen from '../../../styledComponents/styles/LoadingScreen';
import { programsComponentMap } from '../utils/componentMaps';
import travelerProgramsMenuTabs from '../utils/travelerProgramsMenuTabs';

export default function TravelerPrograms({ tab, layout, isAdmin }) {
  const { programs } = useSelector(state => state.programDashboard);
  const { customAliases } = useSelector(state => state.currentOrganization);
  const { applicationSubmissions } = useSelector(state => state.travelerApplicationSubmissions);
  const { favorites: adminFavorites } = useSelector(state => state.profile);

  const [pastPrograms, setPastPrograms] = useState([]);
  const [favoritePrograms, setFavoritePrograms] = useState([]);
  const [upcomingPrograms, setUpcomingPrograms] = useState([]);
  const readyToRender = applicationSubmissions?.data?.length >= 0;
  const {
    customAliases: { alias_favorites: favoritesAlias = 'Favorites' },
  } = useSelector(state => state.profile);

  const [menuTabs, setMenuTabs] = useState(
    travelerProgramsMenuTabs(
      {
        pastCount: pastPrograms.length,
        upcomingCount: upcomingPrograms.length,
        favoritesCount: favoritePrograms.length,
      },
      favoritesAlias,
    ),
  );

  const [activeTabs, setActiveTabs] = useState(menuTabs);
  const [currentTab, setCurrentTab] = useState(menuTabs[0]);

  const Component = programsComponentMap[currentTab.component];

  const programsCategoryMap = {
    favorites: favoritePrograms,
    past: pastPrograms,
    upcoming: upcomingPrograms,
  };

  const defaultPayload = upcomingPrograms.length ? upcomingPrograms : favoritePrograms;

  const filteredPrograms = tab ? programsCategoryMap[tab] : defaultPayload;

  useEffect(() => {
    const hasUpcoming =
      applicationSubmissions?.data?.filter(submission =>
        moment(submission.attributes.program_range.end_date).isAfter(moment()),
      ).length > 0;

    if (!tab && hasUpcoming && !isAdmin) {
      //history.push('/traveler/my-programs/upcoming');
    }
  }, []);

  useEffect(
    () => {
      if (!tab && upcomingPrograms.length) {
        setCurrentTab(menuTabs[1]);
      }
    },
    [upcomingPrograms],
  );

  useEffect(
    () => {
      window.scrollTo(0, localStorage.getItem('scrollY') || 0);
      let currentTabItem = activeTabs.find(activeTab => activeTab.path === (tab || ''));

      if (!currentTabItem) {
        currentTabItem = upcomingPrograms.length ? activeTabs[1] : activeTabs[0];
      }

      setCurrentTab(currentTabItem);
    },
    [tab, activeTabs],
  );

  useEffect(
    () => {
      const past = [];
      let favorites = isAdmin ? adminFavorites : [];
      const upcoming = [];

      if (!isAdmin && programs?.length > 0) {
        favorites = programs.filter(program => program.attributes.program_favorite);

        const mappedFavorites = favorites.map(program => program.attributes);

        setFavoritePrograms(mappedFavorites);
      } else if (isAdmin && adminFavorites?.length > 0) {
        setFavoritePrograms(adminFavorites);
      }

      if (applicationSubmissions?.data?.length > 0) {
        applicationSubmissions.data.forEach(submission => {
          if (moment(submission.attributes.program_range.end_date).isAfter(moment())) {
            upcoming.push(submission);
          } else {
            past.push(submission);
          }
        });

        const mappedUpcoming = upcoming.map(submission => ({
          ...submission.attributes,
          program_id: submission.id,
        }));

        const mappedPast = past.map(submission => ({
          ...submission.attributes,
          program_id: submission.id,
        }));

        setPastPrograms(mappedPast);
        setUpcomingPrograms(mappedUpcoming);
      }

      setMenuTabs(
        travelerProgramsMenuTabs(
          {
            pastCount: past.length,
            upcomingCount: upcoming.length,
            favoritesCount: favorites.length,
          },
          favoritesAlias,
        ),
      );
    },
    [programs, applicationSubmissions],
  );

  useEffect(
    () => {
      setActiveTabs(menuTabs);
    },
    [menuTabs],
  );

  const renderProgramsBlock = () => {
    const defaultTab = upcomingPrograms.length ? 'upcoming' : 'favorites';
    return filteredPrograms?.length > 0 ? (
      <Component programs={filteredPrograms} programsType={tab || defaultTab} isAdmin={isAdmin} />
    ) : (
      <EmptyPrograms programsType={tab || defaultTab} layout={layout} isAdmin={isAdmin} />
    );
  };

  const titleText = isAdmin
    ? `${customAliases.alias_traveler} ${customAliases.alias_programs}`
    : `My ${customAliases.alias_programs}`;

  return (
    <PageWrapper routeSegment="my-programs" layout={layout} noPadding={false}>
      {!readyToRender && <LoadingScreen displayText="Loading..." />}
      {readyToRender && (
        <FlexRowHeadingContainer>
          <Heading>{titleText}</Heading>
          <ProfileMenu
            tabs={activeTabs}
            currentTab={currentTab}
            label="My Programs Menu"
            urlPathSection={layout === 'full' ? 'my-programs' : 'view-programs'}
            autoWidth
            isAdmin={isAdmin}
            individualBorders
            containerBottomBorder
          />
        </FlexRowHeadingContainer>
      )}
      {readyToRender && renderProgramsBlock()}
    </PageWrapper>
  );
}

TravelerPrograms.propTypes = {
  tab: PropTypes.string,
  layout: PropTypes.string,
  isAdmin: PropTypes.bool,
};

TravelerPrograms.defaultProps = {
  tab: '',
  layout: 'full',
  isAdmin: false,
};
