import React from 'react';
import PropTypes from 'prop-types';

export default function ExternalLinkIcon({ fillColor }) {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
      <path
        d="M7.19289 9.5C7.0913 9.49996 6.99201 9.47061 6.90756 9.41566C6.82311 9.36072 6.75729 9.28265 6.71842 9.19131C6.67955 9.09998 6.66937 8.99948 6.68918 8.90252C6.70899 8.80556 6.75788 8.71649 6.82969 8.64656L11.9016 3.71094C11.6391 3.57225 11.3451 3.49973 11.0464 3.5H3.33934C2.86255 3.5005 2.40544 3.68503 2.0683 4.01311C1.73116 4.34119 1.54153 4.78602 1.54102 5.25V12.75C1.54153 13.214 1.73116 13.6588 2.0683 13.9869C2.40544 14.315 2.86255 14.4995 3.33934 14.5H11.0464C11.5232 14.4995 11.9803 14.315 12.3175 13.9869C12.6546 13.6588 12.8443 13.214 12.8448 12.75V5.25C12.845 4.95934 12.7705 4.67325 12.628 4.41781L7.55609 9.35344C7.50848 9.39999 7.45188 9.4369 7.38954 9.46206C7.32721 9.48721 7.26037 9.50011 7.19289 9.5Z"
        fill={fillColor}
      />
      <path
        d="M14.3868 1.5H10.7902C10.6539 1.5 10.5232 1.55268 10.4269 1.64645C10.3305 1.74021 10.2764 1.86739 10.2764 2C10.2764 2.13261 10.3305 2.25979 10.4269 2.35355C10.5232 2.44732 10.6539 2.5 10.7902 2.5H13.1463L11.9022 3.71094C12.2095 3.87333 12.4618 4.11885 12.6286 4.41781L13.873 3.20719V5.5C13.873 5.63261 13.9271 5.75979 14.0235 5.85355C14.1199 5.94732 14.2506 6 14.3868 6C14.5231 6 14.6538 5.94732 14.7501 5.85355C14.8465 5.75979 14.9006 5.63261 14.9006 5.5V2C14.9006 1.86739 14.8465 1.74021 14.7501 1.64645C14.6538 1.55268 14.5231 1.5 14.3868 1.5Z"
        fill={fillColor}
      />
    </svg>
  );
}

ExternalLinkIcon.propTypes = {
  fillColor: PropTypes.string,
};

ExternalLinkIcon.defaultProps = {
  fillColor: '#255A94',
};
